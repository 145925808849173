export function PerformancePontosInteresse() {
  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10' style={{height: '92.1vh'}}>
        <div className='header-report'>
          <h1 className='fw-bolder'>Pontos de Interesse</h1>
          <span>PERFORMANCE 2020 A 2024</span>
        </div>
        <div className='content pt-15'>
          <div className='row g-5 g-xl-8'></div>
        </div>
      </div>
    </>
  )
}
