import {useMutation, useQuery} from '@apollo/client'
import {DELETE_GRUPOS_SERVICOS, PUT_GRUPOS_SERVICOS, SET_GRUPOS_SERVICOS} from '../mutations'
import {GET_GRUPOS_SERVICOS, GET_GRUPO_SERVICO_BY_ID} from '../query'
import {
  TypesDeleteGruposServicosResponse,
  TypesGetGruposServicoByIDResponse,
  TypesGetGruposServicosResponse,
  TypesSetGruposServicosResponse,
} from '../type'
import Swal from 'sweetalert2'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {
  GrupoServicoCreateFormData,
  GrupoServicoCreateFormSchema,
  GrupoServicoUpdateFormData,
  GrupoServicoUpdateFormSchema,
} from '../formValidations/GruposServicos'

interface GruposServicosProps {
  variables?: any
  putCupomId?: number
}

export function QueryGetGruposServicos({variables}: GruposServicosProps) {
  const {data, loading, error} = useQuery<TypesGetGruposServicosResponse>(GET_GRUPOS_SERVICOS, {
    variables: {...variables},
  })

  return {data, loading, error}
}

export function QueryGetGruposServicosByID({variables}: GruposServicosProps) {
  const {data, loading} = useQuery<TypesGetGruposServicoByIDResponse>(GET_GRUPO_SERVICO_BY_ID, {
    variables: {...variables},
  })

  return {data, loading}
}

export function MutationCreateGrupoServico() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: {errors},
  } = useForm<GrupoServicoCreateFormData>({
    resolver: zodResolver(GrupoServicoCreateFormSchema),
  })

  const [CondicaoComercial, {error, loading, data: DataGrupoServico}] =
    useMutation<TypesSetGruposServicosResponse>(SET_GRUPOS_SERVICOS, {
      refetchQueries: [GET_GRUPOS_SERVICOS],
    })

  async function FormGrupoServico(data: GrupoServicoCreateFormData) {
    await CondicaoComercial({
      variables: {
        servicosId: data.servicosId,
        data: {
          name: data.name,
        },
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormGrupoServico,
    loading,
    errors,
    error,
    DataGrupoServico,
    setError,
    setValue,
    control,
  }
}

export function MutationUpdateGrupoServico() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: {errors},
  } = useForm<GrupoServicoUpdateFormData>({
    resolver: zodResolver(GrupoServicoUpdateFormSchema),
  })

  console.log(errors)
  const [GrupoServisos, {error, loading, data: DataGrupoServico}] =
    useMutation<TypesSetGruposServicosResponse>(PUT_GRUPOS_SERVICOS, {
      refetchQueries: [GET_GRUPOS_SERVICOS],
    })

  async function FormUpdateGrupoServico(data: GrupoServicoUpdateFormData) {
    await GrupoServisos({
      variables: {
        servicosId: data.servicosId,
        data: {
          name: data.name,
          id: data.id,
        },
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormUpdateGrupoServico,
    loading,
    errors,
    error,
    DataGrupoServico,
    setError,
    setValue,
    control,
  }
}

export function MutationDeleteGrupoServicos() {
  const [deleteGruposServicos, {loading, data, error}] =
    useMutation<TypesDeleteGruposServicosResponse>(DELETE_GRUPOS_SERVICOS)

  async function HandleDeleteGruposServicos({variables}: GruposServicosProps) {
    try {
      const result = await deleteGruposServicos({
        variables: {...variables},
        refetchQueries: [GET_GRUPOS_SERVICOS],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteGruposServicos, loading, error, data}
}
