import {useLazyQuery} from '@apollo/client'
import {useEffect, useState} from 'react'

import Select from 'react-select'
import {GET_CIDADES, GetCidadesResponse} from '../../../../graphql'

export function GetCidadeChosen({SetValueCidade, defaultValueCidade, estado, disabled}) {
  let cidadesArray = []
  const [cidade, setCidade] = useState(defaultValueCidade)


  const [getCidadesByEstadoId, { data }] = useLazyQuery<GetCidadesResponse>(GET_CIDADES);

  useEffect(() => {
    setCidade(cidade)
    SetValueCidade(cidade)

    return ()=>{
      setCidade('')
      
    }
  }, [cidade, SetValueCidade,defaultValueCidade])

  useEffect(() => {
    if (estado !== '') {
      getCidadesByEstadoId({
        variables: {
          getCidadesByEstadoId: estado,
        },
      });
    }
  }, [estado, getCidadesByEstadoId]);



  if (!data) {
    return (
      <Select
        options={cidadesArray}
        isDisabled
        onChange={(e) => {
          setCidade(e.value)
        }}
        name='id_cidade'
        placeholder='Cidade'
      />
    )
  }
  cidadesArray.push({value:'', label: '--selecione--'})
  data.GetCidadesByEstado.map((Options) => {
    cidadesArray.push({value: Options.id, label: Options.nome})
    return []
  })

  const valueSelected = cidadesArray.filter((e) => {
   return  e.value === parseInt(cidade)

  })[0]
  return (
    <>
      <Select
       
        options={cidadesArray }
        isDisabled={disabled}
        onChange={(e) => {
          setCidade(e.value)
        }}
        name='id_cidade'
        placeholder='Cidade'
        value={valueSelected ? valueSelected : cidadesArray[0]}
      />
    </>
  )
}
