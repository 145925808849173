import {removeColunas, removeItensObj} from '../../../../../../../../components/util'
import {Dicionario} from '../../../../../../../../utils/Dicionario'
import {ExportTable} from '../../../../../../../../utils/ExportXLSX'

interface Props {
  itens: any[]
}
export const TableDetalhes: React.FC<Props> = ({itens}) => {
  function handleExport() {
    const dataToExport = []
    // eslint-disable-next-line array-callback-return
    itens['valor'].map((Response) => {
      dataToExport.push(removeItensObj(Response))
    })
    ExportTable(dataToExport)
  }

  return (
    <>
      <div className='row '>
        <div className='col-12  w-100'>
          <div className='d-flex justify-content-end'>
            <button onClick={handleExport} className='btn btn-primary my-5'>
              Exportar para XLSX
            </button>
          </div>
          <table className='table table-striped'>
            <thead>
              <tr>
                {
                  // eslint-disable-next-line array-callback-return
                  Object.entries(itens['valor'][0]).map((item, index) => {
                    if (removeColunas.indexOf(item[0]) < 0) {
                      return <th key={index}>{Dicionario.translate(item[0])}</th>
                    }
                  })
                }
              </tr>
            </thead>
            <tbody>
              {itens['valor'].map((item, index) => {
                return (
                  <>
                    <tr key={index}>
                      {
                        // eslint-disable-next-line array-callback-return
                        Object.entries(item).map((subItem, subIndex) => {
                          if (removeColunas.indexOf(subItem[0]) < 0) {
                            return <td key={subIndex}>{subItem[1]}</td>
                          }
                        })
                      }
                    </tr>
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
