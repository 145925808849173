import {gql} from '@apollo/client'

export const SET_INTELIGENCIA_MERCADO = gql`
  mutation SetInteligenciaMercado($data: inteligenciaMercado!) {
    SetInteligenciaMercado(data: $data) {
      id
    }
  }
`

export const PUT_INTELIGENCIA_MERCADO = gql`
  mutation PutInteligenciaMercado($data: inteligenciaMercado!, $putInteligenciaMercadoId: Float!) {
    PutInteligenciaMercado(data: $data, id: $putInteligenciaMercadoId) {
      id
    }
  }
`

export const DELETE_INTELIGENCIA_MERCADO = gql`
  mutation DeleteInteligenciaMercado($deleteInteligenciaMercadoId: Float!) {
    DeleteInteligenciaMercado(id: $deleteInteligenciaMercadoId) {
      id
      nome
    }
  }
`

export const SET_INTELIGENCIA_MERCADO_VSO = gql`
  mutation SetInteligenciaMercadoVSO($inteligenciaMercadoVso: vso) {
    SetInteligenciaMercadoVSO(inteligenciaMercadoVSO: $inteligenciaMercadoVso) {
      id
    }
  }
`

export const PUT_INTELIGENCIA_MERCADO_VSO = gql`
  mutation PutInteligenciaMercadoVSO($inteligenciaMercadoVso: vso) {
    PutInteligenciaMercadoVSO(inteligenciaMercadoVSO: $inteligenciaMercadoVso) {
      id
    }
  }
`

export const DELETE_INTELIGENCIA_MERCADO_VSO = gql`
  mutation DeleteInteligenciaMercadoVSO($inteligenciaMercadoVso: vso) {
    DeleteInteligenciaMercadoVSO(inteligenciaMercadoVSO: $inteligenciaMercadoVso) {
      id
    }
  }
`
