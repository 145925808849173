import {Text, View} from '@react-pdf/renderer'
import {styles} from '../style'
// import {Dicionario} from '../../../../utils/Dicionario'

export const BasicData = ({data, estrutura}) => {
  if (typeof data['Result'] !== 'undefined') {
    data = data['Result']
  }

  return (
    <>
      <>
        <View style={[styles.row, {paddingTop: 10}]}>
          <Text style={styles.subtitulo}>{estrutura.titulo}</Text>
        </View>

        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={[styles.h4, styles.bold]}>
                Nome:
                <Text style={styles.h4}>{data[0]['BasicData']['Name']}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={[styles.h4, styles.bold]}>
                Idade:
                <Text style={styles.h4}>{data[0]['BasicData']['Age']}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={[styles.h4, styles.bold]}>
                Documento:
                <Text style={styles.h4}>{data[0]['BasicData']['TaxIdNumber']}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={[styles.h4, styles.bold]}>
                Mãe:
                <Text style={styles.h4}>{data[0]['BasicData']['MotherName']}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={[styles.h4, styles.bold]}>
                Indícios de Óbito:
                <Text style={styles.h4}>
                  {data[0]['BasicData']['HasObitIndication'] ? 'Sim' : 'Não'}
                </Text>
              </Text>
            </View>
          </View>
        </View>
      </>
    </>
  )
}
