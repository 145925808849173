import { APiUrbit } from "../../../../../../services/api/apiUrbit"

export const Endereco = {
  endereco: {
    municipio: {},
    enderecoAtivo: '',
    estado: '',
    endereco: '',
    numero: '',
    bairro: '',
  },
  commposicao: {},
  OnSetEndereco: null,
  setEndereco: function (endereco) {
    Endereco.endereco = endereco
    Endereco.OnSetEndereco(endereco)
  },
  clear: function (id) {
    Endereco.endereco = {
      municipio: {},
      enderecoAtivo: null,
      estado: null,
      endereco: null,
      numero: null,
      bairro: null,
    }
  },
  getList: function () {
    return Endereco.endereco
  },
  getMunicipio: function () {

    if (Array.isArray(Endereco.endereco.municipio)) {
      for (var i in Endereco.endereco.municipio) {
        if (Endereco.endereco.municipio[i].id === Endereco.endereco.enderecoAtivo) {
          return Endereco.endereco.municipio[i]
        }
      }
    }else{
      return Endereco.endereco.municipio
    }
  },
  getMunicipioAPI:async function(lat, lng) {
    const api = new APiUrbit()
    const municipio = await api.get(`municipios/${lng}/${lat}`)
    // setMunicipio(municipio)
    // this.setEndereco({
    //   municipio: municipio,
    //   enderecoAtivo: municipio.id,
    // })
    return municipio
  }
}
