import {useState} from 'react'

import './galery.css'
import {KTSVG} from '../../../../../../../../_metronic/helpers'

type Props = {
  files: {}
}

function downloadPDF(url: string, fileName: string) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = blobURL
      link.target = '_blank'
      link.download = fileName || 'download.pdf'
      link.click()
    })
}

export function Files({files}: Props) {
  return (

          <div className='col-xl-12 pt-10'>
          
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-striped'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-250px'>Documentos</th>
                    <th className='min-w-50px text-end'>Download</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {files &&
                    typeof files === 'object' &&
                    Object.entries(files).map(([fileName, fileUrl], index) => (
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href='#vazio'
                                className='text-dark fw-bolder text-hover-primary fs-6'
                              >
                                {fileName}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <a
                              target='_blank'
                              rel='noreferrer'
                              href={fileUrl}
                              download
                              className='btn btn-icon btn-light-success btn-active-color-white  btn-sm me-2'
                              onClick={() => downloadPDF(fileUrl, fileName)}
                            >
                              <KTSVG
                                path='/media/icons/duotune/files/fil017.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
    
  )
}
