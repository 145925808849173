import { z } from "zod"

export const PutExplorerUserProjectFormSchema = z.object({
    
    id: z.number(),
    nome: z.string()
        .nonempty('O nome do projeto é obrigatório!'),
    json: z.string()
        .nonempty('json é obrigatório!')
})

export type putExplorerUserProjectFormData = z.infer<typeof PutExplorerUserProjectFormSchema>


export const SetExplorerUserProjectFormSchema = z.object({
    nome: z.string()
        .nonempty('O nome do projeto é obrigatório!'),
    json: z.string()
        .nonempty('json é obrigatório!')
})

export type setExplorerUserProjectFormData = z.infer<typeof SetExplorerUserProjectFormSchema>