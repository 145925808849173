import {GetExplorerLayersUsuario} from '../../graphql/type/explorer-layer-usuario'
import {Explorer} from './components/Map/Explorer'
import {RgbCSSToHex} from './components/common'
// import { ContinuousSizeLegend } from 'react-vis'
let instance: object

type JsonLayer = {
  nome: string
  positionMap: number[]
  versao?: number
  layers: {
    layer: {
      data: any[]
      name?: string
      paint: object
      type: string
      visivle: boolean
    }
  }[]
}

export class Projeto {
  json: JsonLayer
  layers: {}
  nome: null
  id: null
  versao: number
  layersOrder: any[]
  usuarioLayers: GetExplorerLayersUsuario

  constructor() {
    if (instance) {
      throw new Error('New instance cannot be created!!')
    }

    instance = this
  }

  abrirProjeto(): void {
    console.log('Abriu projeto')
  }
  getLayers(): {} {
    return this.layers
  }

  novoProjeto() {
    this.reset()
  }
  reset() {
    this.id = null
    this.nome = null
    this.layers = null
    this.layersOrder = null
    this.json = null
    this.versao = null
  }
  setNome(nome) {
    this.nome = nome
  }
  getNome() {
    return this.nome
  }
  setID(id) {
    this.id = id
  }
  getID() {
    return this.id
  }
  setUsuarioLayers(usuarioLayers): void {
    this.usuarioLayers = usuarioLayers
  }
  getLayerUsuario(sourceLayer): any {
    const layerFilter = this.usuarioLayers.GetExplorerLayersUsuario.filter(
      (el) => el.source_layer === sourceLayer
    )
    if (layerFilter) {
      return layerFilter[0]
    } else {
      return false
    }
  }

  setUsuarioProjeto(projeto): void {
    const dodosProjeto = projeto.json
    var temp = dodosProjeto.replace('"', '').replace('"', '').replace('"', '')
    var obj = b64DecodeUnicode(temp)

    this.json = JSON.parse(obj).data
    this.nome = projeto.nome
    this.versao = typeof this.json.versao !== 'undefined' ? this.json.versao : 1
    document.getElementById('nomeprojeto').innerHTML = this.nome
    // this.reposicionaMapa()
    this.loadLayers()
  }
  loadLayers(): void {
    var layersLoad = this.json.layers
    this.layers = layersLoad
    this.layersOrder = Object.keys(layersLoad).sort(function (a, b) {
      return layersLoad[a].layer.position - layersLoad[b].layer.position
    })
  }
  reposicionaMapa(lat, lng): void {
    Explorer.setGoTo(lat, lng)
  }
  getSource(layer) {
    if (typeof layer.source == 'undefined') {
      return false
    } else {
      return layer.source.source
    }
  }
  getType(layer) {
    if (typeof layer.data !== 'undefined') {
      return 'geojson'
    } else {
      if (typeof layer.source == 'undefined') {
        return layer.type
      } else {
        return layer.source.type
      }
    }
  }
  getFeatureType(layer) {

    if (typeof layer.data !== 'undefined' && layer.data) {
      if (layer.type === 'LayerIcon') {
        return 'Point'
      } else if (layer.type === 'LayerPolygon') {
        return 'Polygon'
      } else if (layer.type === 'GeoJSONLayer') {
        return 'Polygon'
      } else {
        
        if (layer.data.length > 0 ){
          return layer.data[0].features[0].geometry.type
        }else{
          return null
        }
      }
    } else {
      if (layer.type === 'MVTLayer' || layer.type==='line' || layer.type==='fill' || layer.type==='circle') {
        return 'MVTLayer'
      } else {
        return layer.source.data.features[0].geometry.type
      }
    }


  }
  getMVTType(layer) {
    return layer.source.type
  }

  loadLayerParent(layersLoad, parentId) {
    for (var i in layersLoad) {
      if (layersLoad[i].layer.id === parentId) {
        return layersLoad[i]
      }
    }
  }
  open(): void {
    
    if (this.versao === 2) {
      this.openv2()
    } else {
      this.openv1()
    }
  }
  openv1(): void {
    if (typeof this.json.positionMap !== 'undefined') {
      this.reposicionaMapa(this.json.positionMap[1], this.json.positionMap[0])
    }
    for (var i in this.layersOrder) {
      let layer = this.layers[this.layersOrder[i]]
      const layerUrbit = this.getLayerUsuario(this.getSource(layer.layer))
      
      if (this.getSource(layer.layer) === 'municipios') {
      } else {
        if (this.getType(layer.layer) === 'geojson') {
         

          const type = this.getFeatureType(layer.layer)
          switch (type) {
            case 'Polygon':
              this.layers[this.layersOrder[i]]['uuid_novo'] = this.addPolygonGeoJSON(
                layer.layer.source.data,
                layer.layer.nome,
                layer.layer.paint
              )
              break
            case 'Point':
              this.layers[this.layersOrder[i]]['uuid_novo'] = this.addPointGeoJSON(layer.layer)
              break
          }
        } else {
          if (layer.layer.source['source-layer'].indexOf('operacao') >= 0) {
            const parent = this.loadLayerParent(this.layers, layer.layer.parentId)
            const geom = parent.layer.source.data
            let urlServico = layer.layer.source['source-layer'].replace('_operacao', '')
            if (urlServico === 'view_explorer_apartamento_venda') {
              urlServico = urlServico + '_'
            }
            const layerUrbit = this.getLayerUsuario(urlServico)
            if (layerUrbit) {
              const parentId = parent.uuid_novo
            
              var jsonEncoded = btoa(JSON.stringify(geom))
              let service = layerUrbit.source
              let schema = layerUrbit.schema_table
              let layerTemp = {...layerUrbit}
              layerTemp['visible'] = layer['visible']
              layerTemp['parentId'] = parentId
              layerTemp['filter'] =  layer['filter']
              layerTemp['source'] = layerUrbit['source'] + '_operacao'
              layerTemp['source_layer'] = layerUrbit['source_layer'] + '_operacao'
              layerTemp['source-layer'] = layerUrbit['source-layer'] + '_operacao'
              layerTemp['type'] = 'MVTLayer'
              layerTemp['tile'] = process.env.REACT_APP_MAP_VECTORTILE+'/operation/intersect/{z}/{x}/{y}.pbf?layer=' +
                service +
                '_operacao&l1=' +
                service +
                '&s=' +
                schema +
                '&l2=&g1=' +
                jsonEncoded
              layerTemp['paint'] = {...layerUrbit.paint}
              layerTemp['style'] = layerUrbit.style ? {...layerUrbit.style} : {...layerUrbit.paint}
              const label = Explorer._getLabel(layer['explorer_tiles'])
              Explorer.addLayerTile(layerTemp, label)
            }
          } else {

            
            const layerUrbit = this.getLayerUsuario(layer.layer.source['source-layer'])
            
            let nomeCamada = layer.layer.nome
            let styleCamada = layer.style ?  this.convertCSS(layer.style) :  this.convertCSS(layer.layer.paint)
          
            if (layerUrbit) {
              
              let layerOpen = {...layerUrbit}
              const label = Explorer._getLabel(layerOpen['explorer_tiles'])
              layerOpen['visible'] =
                typeof layer.layer['layout'] === 'undefined'
                  ? true
                  : layer.layer['layout'].visible === 'visible'
                  ? true
                  : false
              layerOpen['filter'] = layer['filter'] ? this.convertFilter(layer['filter']) : ''
              layerOpen['type'] = 'MVTLayer'
              layerOpen[
                'tile'
              ] = `${process.env.REACT_APP_MAP_VECTORTILE}/tile/${layerOpen['source']}/{z}/{x}/{y}.pbf?s=${layerOpen['schema_table']}`
              layerOpen['paint'] = styleCamada
              layerOpen['style'] = styleCamada

              layerOpen['nome'] = nomeCamada ? nomeCamada : layerUrbit['nome']
              
              const id = Explorer.addLayerTile(layerOpen, `${nomeCamada ? nomeCamada : layerUrbit['nome']}`)
              this.layers[this.layersOrder[i]]['uuid_novo'] = id
            }
          }
        }
      }
    }
  }
  convertFilter(filter){

    let  filterFinal  = {
      range:{},
      select:{},
      text:{}
    }
    if (filter){
      if (filter.length > 1){

        filter.map((item)=>{
    
          
          if (Array.isArray(item) ){
            let campo =''
     
            let operador = item[0]
            let filtro = item[1]
            let valor = item[2]
          
            switch(operador){
              case '<=' : 
                  campo = item[1][1][1]
                  if (typeof filterFinal['range'][campo] === 'undefined'){
                    filterFinal['range'][campo] = []
                  }
                  filterFinal['range'][campo] = [0,valor]
                break;  
              case '>=' : 
                campo = item[1][1][1]
                if (typeof filterFinal['range'][campo] === 'undefined'){
                  filterFinal['range'][campo] = []
                }

                filterFinal['range'][campo] = [valor,0]


                break;  
              case 'in':
                  campo = item[1][1]
                  if (typeof filterFinal['select'][campo] === 'undefined'){
                    filterFinal['select'][campo] = []
                  }
                  filterFinal['select'][campo] = valor[1]
                break;  
            }
          }

    
          // if (filter[0] === 'all') {

      //       let operador = filter[1][0]
      //       let filtro = filter[1][1]
      //       console.log('operador')
      //       console.log(operador)
      //       console.log('filtro')
      //       console.log(filtro)
      //       switch(operador){
      //         case '<=' : 
      //         console.log(filtro[0])
      //             let campo = filtro[1][1]
      //             console.log('campo')
      //             console.log(campo)
      //             filterFinal['range'] = []
      //             // console.log(filtro[1])
      //           break;  
      //       }
      //       console.log(filter[1][0])
  
      //   }
      // }
        })
        // console.log(filterFinal)
      return filterFinal
      // console.log(layerUrbit)
      // console.log(nomeCamada)
      // console.log( layer['filter'])
    }
    }
  }
  convertCSS(style) {

    for (var i in style) {
      if (i === 'fill-outline-color'){
        style['line-color'] = style[i]
        style['line-width'] = 1
      }
    }
    for (var i in style) {

      if (isNaN(style[i])) {
        if (style[i].indexOf('rgb') !== -1) {
          style[i] = RgbCSSToHex(style[i])
        }else if (!Array.isArray(style[i])   ){
          
            style[i] = style[i].substring(0,7)
          
        }
       

      }
    }
    return style
  }
  openv2(): void {
    for (var i in this.layersOrder) {
   
      let layer = this.layers[this.layersOrder[i]]
      if (this.getSource(layer.layer) === 'municipios') {
      } else {

        if (this.getType(layer.layer) === 'geojson') {
          
          const type = this.getFeatureType(layer.layer)
          switch (type) {
            case 'Polygon':
              this.layers[this.layersOrder[i]]['uuid_novo'] = this.addPolygonGeoJSON(
                layer.layer.data,
                layer.layer.nome,
                layer.layer.paint
              )
              break
            case 'Point':
              this.layers[this.layersOrder[i]]['uuid_novo'] = this.addMarker(layer.layer)
              break
            case 'MVTLayer':
              if (layer.layer.source.indexOf('operacao') >= 0) {
                const parent = this.loadLayerParent(this.layers, layer.layer.parentId)
                let geom = parent.layer.data[0]
                if (geom.type !== 'Feature') {
                } else {
                  geom = {
                    type: 'FeatureCollection',
                    features: [geom],
                  }
                }
                let urlServico = layer.layer.source.replace('_operacao', '')
                const layerUrbit = this.getLayerUsuario(urlServico)
                if (layerUrbit) {
                  
                  const parentId = parent.uuid_novo
                  var jsonEncoded = btoa(JSON.stringify(geom))
                  let service = layerUrbit.source
                  let schema = layerUrbit.schema_table
                  let layerTemp = {...layerUrbit}

                  layerTemp['visible'] =  layer.layer.visible
                  layerTemp['parentId'] = parentId
                  layerTemp['filter'] = layer.layer.filter
                  layerTemp['source'] = layerUrbit['source'] + '_operacao'
                  layerTemp['source_layer'] = layerUrbit['source'] + '_operacao'
                  layerTemp['source-layer'] = layerUrbit['source'] + '_operacao'
                  layerTemp['paint'] = layer.layer.paint ? layer.layer.paint : {...layerUrbit.paint}
                  layerTemp['style'] = layer.layer.paint ? layer.layer.paint : {...layerUrbit.paint}
                  layerTemp['type'] = 'MVTLayer'
                  layerTemp['tile'] =process.env.REACT_APP_MAP_VECTORTILE+'/operation/intersect/{z}/{x}/{y}.pbf?layer=' +
                    service +
                    '_operacao&l1=' +
                    service +
                    '&s=' +
                    schema +
                    '&l2=&g1=' +
                    jsonEncoded

                  const label = Explorer._getLabel(layer['explorer_tiles'])
                  Explorer.addLayerTile(layerTemp, label)
                }
              } else {
                const layerUrbit = this.getLayerUsuario(layer.layer.source)
                if (layerUrbit) {
                  let layerOpen = {...layerUrbit}
                  const label = Explorer._getLabel(layerOpen['explorer_tiles'])
                  layerOpen['visible'] =  layer.layer.visible
                  layerOpen['filter'] = layer.layer.filter
                  layerOpen['paint'] = layer.layer.paint ? layer.layer.paint : {...layerUrbit.paint}
                  layerOpen[
                    'tile'
                  ] = `${process.env.REACT_APP_MAP_VECTORTILE}/tile/${layerOpen['source']}/{z}/{x}/{y}.pbf?s=${layerOpen['schema_table']}`
                  layerOpen['paint'] = {...layerOpen['paint']}
                  const id = Explorer.addLayerTile(layerOpen, label)
                  this.layers[this.layersOrder[i]]['uuid_novo'] = id
                }
              }
              break
          }
        } else {
          // if (layer.type == 'MVT' )
        }
      }
    }
  }
  addMarker(layer) {
    return Explorer.addMarker(
      layer.data[0].coordinates[1],
      layer.data[0].coordinates[0],
      layer.nome,
      layer.nome
    )
  }
  addPointGeoJSON(layer) {
    return Explorer.addMarker(
      layer.source.data.features[0].geometry.coordinates[1],
      layer.source.data.features[0].geometry.coordinates[0],
      layer.nome,
      layer.nome
    )
  }
  addPolygonGeoJSON(geom, title, paint={'fill-color': '#004DE5', 'fill-opacity': 100, 'line-color': '#004DE5'}) {
    let layer = {}
    layer['visible'] = true
    layer['data'] = geom
    layer['type'] = 'GeoJSONLayer'
    layer['layout'] = ''
    layer['nome'] = title
    layer['descricao'] = title
    layer['paint'] =paint
    return Explorer.addLayer(layer, title)
  }

  salvar(nome = '') {
    const layerList = Explorer.getLayers()
    var listLayersSave = {}
    for (var i in layerList) {
      let uuid = layerList[i].id
      var layerItens = {
        // color: layerList[i].color,
        // fields: layerList[i].fields,
        id: layerList[i].id,
        nome: layerList[i].nome,
        paint: layerList[i].paint, //typeof listStyle[i] != "undefined" ? listStyle[i].style : layerList[i].point,
        source: layerList[i].source,
        type: layerList[i].type,
        filter: layerList[i].filter,
        // typeGeometry: layerList[i].typeGeometry,
        visible: layerList[i].visible,
        parentId: layerList[i].parentId,
        // position: layerList[i].position,
        data: typeof layerList[i].data !== 'undefined' ? layerList[i].data : null,

        // style:layerList[i].style,
        // filter:layerList[i].filter,
        // legenda:layerList[i].legenda,
        // itensMatch:layerList[i].itensMatch,
        // listItensIterpolate:layerList[i].listItensIterpolate
      }

      if (typeof listLayersSave[i] == 'undefined') {
        listLayersSave[uuid] = {}
      }
      listLayersSave[uuid].layer = layerItens
      // listLayersSave[uuid].style = layerList[i].paint
      // listLayersSave[uuid].filter = layerList[i].filter
      // listLayersSave[i].legenda =layerList[i].legenda;
      // listLayersSave[i].itensMatch =layerList[i].match;
      // listLayersSave[i].listItensIterpolate = layerList[i].interpolate;
    }

    var latLng = Explorer.getCenter()
    var json = {
      data: {
        nome: this.nome,
        id: this.id,
        layers: listLayersSave,
        positionMap: [latLng.lng(), latLng.lat()],
        versao: 2,
      },
    }
    var datajsonEncode = btoa(unescape(encodeURIComponent(JSON.stringify(json))))
    return datajsonEncode
  }

  addLayerMVT() {}
}

export function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

let ProjetoInstance = new Projeto()

export default ProjetoInstance
