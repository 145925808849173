import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useEffect } from 'react'
import { MutationCreateCorretores } from '../../../graphql/services/Corretores'
import { Loader } from '../../../components/UI/Loader/Loader'
import { FormInputAndLabel } from '../../../components/UI/Inputs/FormInputAndLabel'
import { FormInputMaskAndLabel } from '../../../components/UI/Inputs/FormInputMaskAndLabel'
import { MutationSetLogAcessoRecurso } from '../../../graphql/services/LogAcesso'

interface corretoresProps {
  closeModal: any
  show: boolean
}

export function CreateForm({ closeModal, show }: corretoresProps) {
  const {  FormCorretor, errors, handleSubmit, loading, register } =
    MutationCreateCorretores()
    const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

    const onSubmit = async (data) => {
      FormCorretor(data).then((result) => {
        FormSetLogAcessoRecurso({ferramenta: 'cadastro', funcionalidade: 'corretores', action: 'create', url: '/cadastro/corretores'});
        Swal.fire('Sucesso!', 'Corretor criado com sucesso.', 'success')
        closeModal()
      }).catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
    }

  // Tratativa de loading
  if (loading) {
    return <Loader text={'caregando...'}></Loader>
  }

  return (
    <Modal centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Novo Corretor:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            className='form-control form-control-solid'
            placeholder='id'
            hidden={true}
            {...register('id', {
              pattern: /^[0-9]+$/i,
            })}
          />
          <div className='row'>
            <div className='col-md-12'>
              <div className='mb-6'>
                <FormInputAndLabel
                  label='Nome Completo'
                  className='form-control required form-control-solid'
                  placeholder='Nome Completo'
                  register={register("nome")}
                  error={errors ? errors.nome?.message : ''}
                />
              </div>
            </div>

            <div className='col-md-12'>
              <div className='mb-6'>
                <FormInputAndLabel
                  label='Email'
                  className='form-control required form-control-solid'
                  placeholder='Ex: joao@email.com'
                  register={register("email")}
                  error={errors ? errors.email?.message : ''}
                />
              </div>
            </div>

            <div className='col-md-12'>
              <div className='mb-6'>
                <FormInputMaskAndLabel
                  label='Telefone:'
                  maskChar=' '
                  mask='(99) 99999-9999'
                  className='form-control form-control-solid'
                  placeholder='Ex: (00) 00000-0000'
                  register={register("telefone")}
                  error={errors ? errors.telefone?.message : ''}
                />
              </div>
            </div>

            <div className='col-md-12'>
              <div className='mb-6'>
                <FormInputAndLabel
                  label='Empresa'
                  className='form-control required form-control-solid'
                  placeholder='Empresa'
                  register={register("empresa")}
                  error={errors ? errors.empresa?.message : ''}
                />
              </div>
            </div>

            <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
              <button className='btn btnSecondary  pull-right ' type='button' onClick={closeModal}>
                Fechar
              </button>
              <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                Salvar
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
