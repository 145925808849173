import { gql } from "@apollo/client";

export const DELETE_EMPREENDIMENTO_MASSA_USO = gql`
mutation DeleteEmpreendimentoMassaUsos($massaUsos: massaUsos!) {
  DeleteEmpreendimentoMassaUsos(massaUsos: $massaUsos) {
      uso
    }
  }
`
export const SET_EMPREENDIMENTO_MASSA_USO = gql`
mutation SetEmpreendimentoMassaUsos($massaUsos: massaUsos!) {
  SetEmpreendimentoMassaUsos(massaUsos: $massaUsos) {
    nome
  }
}
`

export const PUT_EMPREENDIMENTO_MASSA_USO = gql`
mutation PutEmpreendimentoMassaUsos($massa: Massa!) {
  PutEmpreendimentoMassaUsos(Massa: $massa) {
    nome
  }
}
`