import { gql } from "@apollo/client";


export const GET_VERIFICAR_TOKEN = gql`
query GetCheckTokenEsqueciSenha($tokenTrocaSenha: String!, $email: String!) {
  GetCheckTokenEsqueciSenha(token_troca_senha: $tokenTrocaSenha, email: $email) {
    email
    name
  }
}
`