import { gql } from "@apollo/client";

export const SET_EMPRESA = gql`
  mutation SetEmpresa($data: empresa!) {
    SetEmpresa(data: $data) {
      id
    }
  }
`

export const PUT_EMPRESA = gql`
  mutation PutEmpresa($data: empresa!) {
    PutEmpresa(data: $data) {
      id
    }
  }
`

export const DELETE_EMPRESA = gql`
  mutation DeleteEmpresa($deleteEmpresaId: Float!) {
    DeleteEmpresa(id: $deleteEmpresaId) {
      id
    }
  }
`

// EMPRESA USUARIO


export const SET_EMPRESA_USUARIO = gql`
    mutation SetEmpresaUsuario($data: empresaUsuario!) {
        SetEmpresaUsuario(data: $data) {
            id
        }
    }
`

export const PUT_EMPRESA_USUARIO = gql`
    mutation PutEmpresaUsuario($data: empresaUsuario!) {
        PutEmpresaUsuario(data: $data) {
            id
        }
    }
`

export const DELETE_EMPRESA_USUARIO = gql`
mutation DeleteEmpresaUsuarioAdmin($deleteEmpresaUsuarioAdminId: Float!) {
  DeleteEmpresaUsuarioAdmin(id: $deleteEmpresaUsuarioAdminId) {
    id
  }
}
`

// EMPRESA PRODUTOS


export const SET_EMPRESA_PRODUTO = gql`
   mutation SetEmpresaProduto($data: empresaProduto!) {
    SetEmpresaProduto(data: $data) {
      id
    }
  }
`

export const PUT_EMPRESA_PRODUTO = gql`
   mutation PutEmpresaProduto($data: empresaProduto!) {
    PutEmpresaProduto(data: $data) {
      id
    }
  }
`



export const DELETE_EMPRESA_PRODUTO = gql`
  mutation DeleteEmpresaProduto($deleteEmpresaProdutoId: Float!) {
    DeleteEmpresaProduto(id: $deleteEmpresaProdutoId) {
      id
    }
  }
`





