import { useState } from "react";
import { AvmLancamentos } from "../../../../../components/UI";
import { LatLng } from "../../../../../components/util";
import { EmpreendimentoResponse, TypesGetEmpreendimentoImovelResponse } from "../../../../../graphql";
import { TableAvaliacoesEmpreendimento } from "./partials/TableAvaliacoesEmpreendimento";
import { ModalAvm } from "../../../../../components/UI/Modals/modal-avm";
import { Endereco } from "../../../../explorer/components/common";
type Props = {
  data: EmpreendimentoResponse
  imoveis: TypesGetEmpreendimentoImovelResponse
  param: string
}
export function TabAvaliacoes({ data, imoveis, param }: Props) {

  if (imoveis.GetEmpreendimentoImovel.result.length === 0) {
    return (
      <div
        className={`tab-pane fade ${param === 'avaliacoes' ? ' show active' : ''}`}
        id="avaliacoes" role="tabpanel" aria-labelledby="avaliacoes">
        <div className='card'>
          <div className='card-header'>
            <h3 className='card-title'>Avaliações</h3>
            <div className='card-toolbar'>
              {/* <button type="button" className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#kt_modal_set_avm">
              AVM Usados
            </button> */}

              <button type="button" disabled className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_avm">
                Não possui terrenos nesse empreendimento
              </button>
              {/* <AvmLancamentos origem="empreendimento" lat={imoveis.GetEmpreendimentoImovel[0].terreno.latitude} lng={imoveis.GetEmpreendimentoImovel[0].terreno.longitude} idEmpreendimento={data.GetEmpreendimento.id}></AvmLancamentos> */}


            </div>
          </div>
          <div className='card-body p-6 pt-5'>
            <TableAvaliacoesEmpreendimento idEmpreendimento={data.GetEmpreendimento.id} />
          </div>
        </div>
      </div>
    )
  }

  let endereco:Endereco = {
    latitude: null,
    longitude: null,
    endereco_completo:null,
    logradouro: null,
    numero: null,
    bairro: null,
    cidade: null,
    estado: null,
    cep: null
  }

  if (imoveis ){
  endereco  = {
    latitude: Number(imoveis.GetEmpreendimentoImovel.result[0].terreno.latitude),
    longitude:  Number(imoveis.GetEmpreendimentoImovel.result[0].terreno.longitude),
    endereco_completo:  imoveis.GetEmpreendimentoImovel.result[0].terreno.endereco +', '+ imoveis.GetEmpreendimentoImovel.result[0].terreno.numero,
    logradouro:  imoveis.GetEmpreendimentoImovel.result[0].terreno.endereco,
    numero: String( imoveis.GetEmpreendimentoImovel.result[0].terreno.numero),
    bairro:  imoveis.GetEmpreendimentoImovel.result[0].terreno.bairro,
    cidade: imoveis.GetEmpreendimentoImovel.result[0].terreno.cidade,
    estado:  '',
    cep:  imoveis.GetEmpreendimentoImovel.result[0].terreno.cep
  }
  
}
  
  return (
    <div
      className={`tab-pane fade ${param === 'avaliacoes' ? ' show active' : ''}`}
      id="avaliacoes" role="tabpanel" aria-labelledby="avaliacoes">
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Avaliações</h3>
          <div className='card-toolbar'>
            {/* <button type="button" className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#kt_modal_set_avm">
              AVM Usados
            </button> */}

            {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_avm">
              AVM Lançamentos
            </button> */}

            <ModalAvm endereco={endereco} origem={'empreendimento'} idEmpreendimento={data.GetEmpreendimento.id}  ></ModalAvm>
       
{/* 
            <AvmLancamentos
              origem="empreendimento"
              lat={imoveis.GetEmpreendimentoImovel.result[0].terreno.latitude ? Number(imoveis.GetEmpreendimentoImovel.result[0].terreno.latitude) : 0}
              lng={imoveis.GetEmpreendimentoImovel.result[0].terreno.longitude ? Number(imoveis.GetEmpreendimentoImovel.result[0].terreno.longitude) : 0}
              idEmpreendimento={data.GetEmpreendimento.id}  >

            </AvmLancamentos> */}


          </div>
        </div>
        <div className='card-body p-6 pt-5'>

          <TableAvaliacoesEmpreendimento idEmpreendimento={data.GetEmpreendimento.id} />
        </div>
      </div>
    </div >
  )
}