import { useEffect, useState } from 'react'
import { Footer } from '../../../../_metronic/layout/components/Footer'
import { AsideDefault } from '../../../../_metronic/layout/components/aside/AsideDefault'
import { HeaderWrapperFluid } from '../../../../_metronic/layout/components/header/HeaderWrapperFluid'
import { useParams } from 'react-router-dom'
import { CardHeaderNavigation } from './partials/CardHeader'
import { TabInfos } from './components/TabInfos'
import { TabHistory } from './components/TabHistory'
import { RealEstateLaunch } from '../../../../services/http/urbit-gis/routes/types'
import { addLaunch, getLaunchByID } from '../../../../services/http/urbit-gis/routes'
import { TabMedia } from './components/TabMedia'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { RealEstateLaunchFormData, RealEstateLaunchSchema } from './partials/typologies/formValidations'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';
import { EmpreendimentosProvider } from './context/useEmpreendimentos'
import { Empreendimentos } from './empreendimentos'

export function AdminCreateEmpreendimentos() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [paginacao, setPaginacao] = useState({ pagina: 1, quantidade: 10 });
  const navigate = useHistory();

  const methods = useForm<RealEstateLaunchFormData>({
    resolver: zodResolver(RealEstateLaunchSchema),
  });



  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <EmpreendimentosProvider >
                <Empreendimentos />
              </EmpreendimentosProvider>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}
