import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {
  GoogleMap,
  useJsApiLoader,
  OverlayView,
  Marker,
  Circle,
  Polyline,
} from '@react-google-maps/api'
import {GoogleMapsOverlay} from '@deck.gl/google-maps'
import {Feature, TIsOpen} from './Types'
import * as turf from '@turf/turf'
import {FilterContext} from '../context/FilterContext'
import DataTable from 'react-data-table-component'
import {
  ColorMapboxToKepler,
  formatDistancia,
  formatNumber,
  HexToRgb,
  RgbToHex,
} from '../../explorer/components/common'
import {CoordinatesToPath, customStylesTable} from './Common/common'
import {loadIsocrona} from './Common/Isocrona'

type PropsContextMenu = {
  center: {
    lat: number
    lng: number
  }
  layer: any[]
  zoom?: number
  json: any
  dataImoveis: any
  style: any
}

//TODO: Adicionar legenda no mapa mapview componente dentr do inteligencia de mercado
// const libraries: Libraries = ['drawing', 'places']
export const MapViewRevenda: React.FC<PropsContextMenu> = ({
  dataImoveis,
  center,
  json,
  layer,
  zoom = 16,
  children,
  style,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = React.useState(null)
  // const [dataImoveis, setDataImoveis] = React.useState(null)

  const [isOpen, setIsOpen] = React.useState<TIsOpen>([])
  const {geometria, geometriaSelecionada} = useContext(FilterContext)
  const [iscoronas, setIsocronas] = React.useState<Feature[]>([])
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  })

  const deckOverlay = new GoogleMapsOverlay({
    debug: true,
    style: {zIndex: 30},
    pickingRadius: 10,
    onmousemove: () => {
      console.log('onmouse')
    },
  })
  useEffect(() => {
    loadIsocrona(center, (data) => {
      setIsocronas(data.features)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center])
  deckOverlay.setProps({
    layers: [],
  })
  // function getViewport(viewState) {
  //   const bounds = new deckOverlay.WebMercatorViewport(viewState).getBounds()
  //   return turf.bboxPolygon(bounds)
  // }
  // const getViewportFeatures = debounce((viewState) => {
  //   const viewport = getViewport(viewState)
  //   const viewportFeatures = features.filter((f) => turf.booleanIntersects(f, viewport))
  //   console.log(viewportFeatures) // Your target
  // },500 )

  const onLoad = useCallback(
    (map) => {
      deckOverlay.setMap(map)
      if (deckOverlay) {
        setMap(deckOverlay)
      }

      setMap(deckOverlay)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    null
  )

  const containerStyle = {
    // width: '400px',
    height: '100vh',
  }

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  function click(e, el) {
    clearSelecaoTable()
    let line = document.getElementById('row_' + el.id)
    line.classList.add('active')
  }

  function clearSelecaoTable() {
    document.querySelectorAll('.row-composicao').forEach(function (row) {
      row.classList.remove('active')
    })
  }

  function onclick(e) {
    console.log('oinclick map')
    console.log(e)
    // eslint-disable-next-line array-callback-return
    // paths.map((el) => {
    //   el.editable = false
    // })
    // setPaths([...paths])
    // clearSelecaoTable()
    // const lote = getLote(e.latLng.lat(), e.latLng.lng())
  }
  if (!dataImoveis) {
    return <>Carregando imoveis</>
  }
  const divStyle = {
    background: 'transparent',
    // border: "1px solid #ccc",
    // padding: 15
  }
  // const onClick = () => {
  //   console.info('I have been clicked!')
  // }
  function handleMarkerHover(id: number) {
    if (isOpen.length > 0) {
      let existItem = isOpen.findIndex((item) => item.id === id)
      if (existItem !== -1) {
        isOpen.splice(existItem, 1)
      } else {
        isOpen.push({
          id: id,
          open: true,
        })
      }
      setIsOpen([...isOpen])
    } else {
      isOpen.push({
        id: id,
        open: true,
      })
      setIsOpen([...isOpen])
    }
  }

  let columnsData = [
    {
      name: 'Endereco',
      selector: (row) => row.endereco,
      sortable: true,
      wrap: true,
      width: '300px',
    },
    {
      name: 'Área',
      selector: (row) => row.area,
      sortable: true,
    },
    {
      name: 'Quartos',
      selector: (row) => row.quartos,
      sortable: true,
    },
    {
      name: 'Suítes',
      selector: (row) => row.suites,
      sortable: true,
    },
    {
      name: 'Banheiros',
      selector: (row) => row.banheiros,
      sortable: true,
    },
    {
      name: 'Vaga',
      selector: (row) => row.vaga,
      sortable: true,
    },
    {
      name: 'Valor',
      selector: (row) => formatNumber(row.valor, 'currency'),
      sortable: true,
    },
    {
      name: 'Distância',
      selector: (row) => row.distancia,
      sortable: true,
    },
  ]

  let dataImoveisTable = []

  function clickCircle(index) {
    console.log(index)
  }

  function checkColor(properties): string {
    properties['preco_m2'] = properties.valor / properties.area
    let color = ColorMapboxToKepler(style, properties, 'fill-color')
    if (color) {
      return RgbToHex(color[0], color[1], color[2], color[3])
    } else {
      return '#333'
    }
  }
  var lineSymbol = {
    path: google.maps.SymbolPath.CIRCLE,
    fillOpacity: 1,
    scale: 2,
  }

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{...containerStyle, width: '100%', height: 'calc(60.8vh)'}}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={onclick}
        >
          {dataImoveis ? (
            dataImoveis.map((item, index) => {
              let color = checkColor(item)

              return (
                <Circle
                  key={index}
                  radius={10}
                  options={{fillColor: color, strokeColor: '#fff', strokeWeight: 0}}
                  onClick={() => {
                    clickCircle(index)
                  }}
                  center={{lat: item.geom.coordinates[1], lng: item.geom.coordinates[0]}}
                  // label={{
                  //   text: obj.nome.split('-')[0],
                  //   className: `marker-label`,
                  //   color: '#4d3838',
                  // }}
                  // icon={{
                  //   scaledSize: new google.maps.Size(50, 50),
                  //   url: obj.removed
                  //     ? toAbsoluteUrl('/media/f81039c7-b68a-462c-b90a-ccbf0a11e4ec.png')
                  //     : toAbsoluteUrl('/media/75210de1-444b-4077-8a30-d4aa401ee39c.png'),
                  //   origin: new google.maps.Point(0, 0),
                  //   anchor: new google.maps.Point(25, 50),
                  // }}
                  // animation={google.maps.Animation.DROP}
                >
                  {/* {isOpen && isOpen.find((item) => item.id === obj.id) && (
      <InfoBox
        options={{
          closeBoxURL: `xx`,
          enableEventPropagation: true,
          maxWidth: 440,
          boxClass: 'infoBox2',
        }}
      >
        <Tootip dataImovel={obj} onClose={handleMarkerHover}></Tootip>
      </InfoBox>
    )} */}
                </Circle>
              )
            })
          ) : (
            <></>
          )}
          {iscoronas ? (
            iscoronas.map((item, index) => {
              return (
                <Polyline
                  options={{
                    strokeColor: index === 0 ? '#094e08' : '#e10c0c',
                    strokeOpacity: 0,
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '10px',
                      },
                    ],
                  }}
                  path={CoordinatesToPath(item.geometry.coordinates[0])}
                  key={index}
                  // options={optionsSelected}
                />
              )
            })
          ) : (
            <></>
          )}
          {geometriaSelecionada ? (
            // geometria.map((item, index) => {
            // return (
            <Polyline
              options={{
                strokeColor: '#333',
                strokeOpacity: 0,
                icons: [
                  {
                    icon: lineSymbol,
                    offset: '0',
                    repeat: '2px',
                  },
                ],
              }}
              path={CoordinatesToPath(
                geometria.type === 'MultiPolygon'
                  ? geometriaSelecionada['coordinates'][0]
                  : geometriaSelecionada.geometry.coordinates[0]
              )}
              key={1}
              // options={optionsSelected}
            />
          ) : (
            // )
            // })
            <></>
          )}
          <Marker
            position={center}
            // label={{
            //   text: obj.nome.split('-')[0],
            //   className: `marker-label`,
            //   color: '#4d3838',
            // }}
            // icon={{
            //   scaledSize: new google.maps.Size(50, 50),
            //   url: obj.removed
            //     ? toAbsoluteUrl('/media/f81039c7-b68a-462c-b90a-ccbf0a11e4ec.png')
            //     : toAbsoluteUrl('/media/75210de1-444b-4077-8a30-d4aa401ee39c.png'),
            //   origin: new google.maps.Point(0, 0),
            //   anchor: new google.maps.Point(25, 50),
            // }}
            animation={google.maps.Animation.DROP}
          >
            {/* {isOpen && isOpen.find((item) => item.id === obj.id) && (
                <InfoBox
                  options={{
                    closeBoxURL: `xx`,
                    enableEventPropagation: true,
                    maxWidth: 440,
                    boxClass: 'infoBox2',
                  }}
                >
                  <Tootip dataImovel={obj} onClose={handleMarkerHover}></Tootip>
                </InfoBox>
              )} */}
          </Marker>

          <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
            <div style={divStyle}></div>
          </OverlayView>
          <></>
          {children}
        </GoogleMap>
      ) : (
        <></>
      )}

      <DataTable
        title='Mercado Secundário'
        noHeader={true}
        customStyles={customStylesTable}
        columns={columnsData}
        subHeader={false}
        data={dataImoveis}
        pagination={true}
        highlightOnHover
        pointerOnHover
        striped
        responsive
        paginationPerPage={10}
        onColumnOrderChange={(cols) => console.log(cols)}
      />
    </>
  )
}
