import { gql } from "@apollo/client";

export const SET_CORRETOR = gql`
mutation SetCorretor($corretor: corretor!) {
  SetCorretor(corretor: $corretor) {
    id
    nome
  }
}
`

export const PUT_CORRETOR = gql`
mutation PutCorretor($corretor: corretor!, $putCorretorId: Float!) {
  PutCorretor(corretor: $corretor, id: $putCorretorId) {
    id
    nome
  }
}
`

export const DELETE_CORRETOR = gql`
mutation DeleteCorretor($deleteCorretorId: Float!) {
  DeleteCorretor(id: $deleteCorretorId) {
    nome
  }
}
`