import { gql } from "@apollo/client";

export const SET_EMPREENDIMENTO_IMOVEL = gql`
  mutation SetEmpreendimentoImovel($empreendimentoImovel: EmpreendimentoImovel!) {
    SetEmpreendimentoImovel(empreendimentoImovel: $empreendimentoImovel) {
      empreendimento_id
      id
      uuid
    }
  }
`

export const DELETE_EMPREENDIMENTO_IMOVEL = gql`
  mutation DeleteEmpreendimentoImovel($deleteEmpreendimentoImovelId: Float!) {
    DeleteEmpreendimentoImovel(id: $deleteEmpreendimentoImovelId) {
      id
    }
  }
`