import { AsideDefault } from '../../../../_metronic/layout/components/aside/AsideDefault'
import { HeaderWrapperFluid } from '../../../../_metronic/layout/components/header/HeaderWrapperFluid'
import { Footer } from '../../../../_metronic/layout/components/Footer'
import {Table} from './partials/Table'


export function AdminTermos() {


  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <div
                className='py-6  d-flex align-items-center justify-content-between'
                id='kt_header_container'
              >
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  data-kt-swapper='true'
                  data-kt-swapper-mode='prepend'
                  data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Listagem</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href='/metronic8/demo7/../demo7/index.html' className='text-muted'>
                        <ul className='breadcrumb fw-semibold fs-base my-1'>
                          <li className='breadcrumb-item text-muted'>
                            <a href='/metronic8/demo7/../demo7/index.html' className='text-muted'>
                              Termos e Políticas de Uso
                            </a>
                          </li>
                        </ul>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='d-flex align-items-center ms-3'>
                  <input type='text' className='form-control' placeholder='Pesquisar...' />
                </div>
              </div>
              <div className="content  d-flex flex-column flex-column-fluid" id="kt_content" >
                <div className="card" >
                  <div className="border-0 pt-6 ps-5 pe-5">
                    <div className='row gy-5 gx-xl-8'>
                      <div className='col-xl-12'>
                        <div className='card-xxl-stretch mb-5 mb-xl-8'>
                          <Table />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
