import { KTSVG } from '../../../../../../_metronic/helpers';

type Props = {
    title: string;
    description: string;
    urlVideo?: string;
    video?: boolean;
    index: number;
}

export function FaqItem({ title, description, urlVideo, video, index }: Props) {
    const id = 'faq-item-' + index

    // const [expanded, setExpanded] = useState(false);

    // const toggleExpanded = (id: string) => {
    //     const element = document.getElementById(id);

    //     if (element) {
    //         const hasTheClass = element.classList.contains('collapsed');

    //         if (hasTheClass) {
    //             element.classList.remove('collapsed');
    //             setExpanded(false)
    //         } else {
    //             element.classList.add('collapsed');
    //             setExpanded(true)
    //         }
    //     }
    // };

    return (
        <div className="m-0">
            <div className={`d-flex align-items-center collapsible py-3 toggle cursor-pointer  mb-0 `}
                data-bs-toggle="collapse" data-bs-target={`#${id}`}
                >
                <div className={`btn btn-sm btn-icon mw-20px btn-active-color-primary me-5 `}>
                    <KTSVG
                        path='/media/icons/duotune/general/gen023.svg'
                        className='svg-icon-2 svg-icon-gray-500'
                    />
                </div>

                <h4 className={`text-gray-700 fw-bold  mb-0 `}>
                    {title}
                </h4>
            </div>

            <div id={`${id}`} className={`collapse ps-10 fs-6 ms-1 `} >
                {video ? (
                    <>
                        <iframe className='W-100  h-400px'src={urlVideo} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        <span className='bullet bg-success w-100 h-4px'></span>

                    </>
                ) : ('')}

                <div className="mb-4 text-gray-600 fw-semibold fs-6 ">
                    {video ? `Descrição: ${description}` : description}
                </div>
            </div>


            <div className="separator separator-dashed"></div>
        </div>

    );
};