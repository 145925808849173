import { z } from "zod"

export const CupomFormCreateSchema = z.object({
    codigo: z.string()
        .nonempty('O código é obrigatório!')
        .transform((value) => value.toUpperCase()),
    desconto: z.number()
        .min(0, 'Digite um valor entre 0 e 100.')
        .max(100, 'Digite um valor entre 0 e 100.'),
    tipo: z.string(),
    usado: z.string(),
    data_expiracao: z.string().optional()
});

export type CupomFormCreateData = z.infer<typeof CupomFormCreateSchema>


export const CupomFormPutSchema = z.object({
    id: z.number(),
    codigo: z.string()
        .nonempty('O código é obrigatório!')
        .transform((value) => value.toUpperCase()),
    desconto: z.number()
        .min(0, 'Digite um valor entre 0 e 100.')
        .max(100, 'Digite um valor entre 0 e 100.'),
    tipo: z.string(),
    usado: z.string(),
    data_expiracao: z.string().optional()
})

export type CupomFormPutData = z.infer<typeof CupomFormPutSchema>