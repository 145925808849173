import {Button, Modal} from 'react-bootstrap'
import {Explorer} from '../../../Map/Explorer'
import {useState} from 'react'
import {ModalAbrirProjeto} from './ModalAbrirProjeto'
import { MutationSetLogAcessoRecurso } from '../../../../../../graphql/services/LogAcesso'

type Message = {
  error: boolean
  message: string
  data: any
}

type Props = {
  id: number
  setIdOpen?: any
}

export const ButtonAbrir: React.FC<Props> = ({id, setIdOpen}) => {
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const [show, setShow] = useState(false)
  const [showAbrir, setShowAbrir] = useState(false)

  const [message, setMessage] = useState<Message>({error: null, message: null, data: null})

  function abrirProjeto() {

    FormSetLogAcessoRecurso({
      ferramenta: 'explorer',
      funcionalidade: 'abrir-projeto',
      action: 'click',
      url: '/explorer',
    })

    if (Explorer.getCountLayers()) {
      setShow(true)
    } else {
      setShowAbrir(true)
      setShow(false)
    }
  }
  function continuar() {
    Explorer.removeALlLayers()
    setShowAbrir(true)
    setShow(false)
  }
  const handleClose = () => {
    setMessage({
      error: null,
      message: null,
      data: null,
    })
    setShow(false)
  }
  function closeModalAbrir() {
    setShowAbrir(false)
  }

  return (
    <>
      <button
        className=' btn btn-bg-light btn-active-color-success btn-sm'
        data-id={id}
        onClick={abrirProjeto}
      >
        <i className='fs-5 bi bi-folder2-open'></i>
        Abrir
      </button>
      <Modal centered animation show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Abrir</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message.error ? (
            <>
              <p>{message.message}</p>
            </>
          ) : (
            <>
              <p>
                Ao abrir um projeto você irá perder as informações não salvas deste projeto. Deseja
                continuar?
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' data-dismiss='modal' onClick={handleClose}>
            Fechar
          </Button>
          <Button variant='primary' data-dismiss='modal' onClick={continuar}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalAbrirProjeto show={showAbrir} close={closeModalAbrir}></ModalAbrirProjeto>
    </>
  )
}
