import React from 'react'
import {MutationDeleteNewsFeed} from '../../../graphql'
import {KTSVG} from '../../../../../_metronic/helpers'
import Swal from 'sweetalert2'

type Props = {
  id: number
  setIdEdit?: any
}
export const Buttons: React.FC<Props> = ({id, setIdEdit}) => {
  const {HandleDeleteNewsFeed} = MutationDeleteNewsFeed()

  function confirmDelete(id) {
    Swal.fire({
      title: 'Deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const result = await HandleDeleteNewsFeed({variables: {deleteNewsFeedId: id}})
        if (result) {
          Swal.fire('Sucesso!', 'Notícia deletada com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          className='btn btn-icon btn-light-success btn-sm me-2'
          onClick={(e) => {
            setIdEdit(id)
          }}
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button>
        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          className='btn btn-icon btn-light-danger  btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
