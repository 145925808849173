import { gql } from "@apollo/client";

export const SET_PRODUTOS = gql`
mutation SetProduto($produto: ProdutosModel!) {
  SetProduto(produto: $produto) {
    id
    nome
  }
}
`

export const PUT_PRODUTO = gql`
mutation PutProdutos($produto: produtos!) {
  PutProdutos(produto: $produto) {
    id
    nome
  }
}
`

export const DELETE_PRODUTO = gql`
mutation DeleteCorretor($deleteProdutoId: Float!) {
  DeleteProduto(id: $deleteProdutoId) {
    nome
  }
}
`