import React, {useState} from 'react'
import ListaCamadas from './ListaCamadas'
import {useQuery} from '@apollo/client'
import {GetExplorerTagsGrupoTagsResponse} from '../../../../../../graphql/type/explorer-tags-grupos-tags'
import {FIND_EXPLORER_TAGS_GRUPO_TAGAS} from '../../../../../../graphql/query/explorer-tags-grupo-tags'
import {BiChevronRight} from 'react-icons/bi'

type Props ={
  intersect:boolean
}

export const Camadas: React.FC<Props> = ({intersect}) => {
  const [dataFilter, setDataFilter] = useState([])


  const {data} = useQuery<GetExplorerTagsGrupoTagsResponse>(FIND_EXPLORER_TAGS_GRUPO_TAGAS)
  if (!data) {
    return <div>Carregando...</div>
  }
  const grupoTags = {}
  data?.GetExplorerTagsGrupoTags.map((response) => {
    let nomeGrupo = response.explorer_tags_grupo['nome']
    if (typeof grupoTags[nomeGrupo] == 'undefined') {
      grupoTags[nomeGrupo] = []
    }
    let merge = {...response.explorer_tags, ...response.explorer_tags_grupo}
    grupoTags[nomeGrupo].push(merge)
    return response
  })

  // const filtroTags = (childdata) => {
  //   // setData(childdata)
  // }

  // const handleChange = (eventKey) => {
  //   eventKey.preventDefault()
  //   let idItem = eventKey.currentTarget.getAttribute('id')
  //   let itens = document.querySelectorAll('.list-grupo-item')
  //   for (var i in itens) {
  //     if (typeof itens[i].classList != 'undefined') {
  //       itens[i].classList.remove('list-grupo-active')
  //     }
  //   }

  //   document.getElementById('detalhes_' + idItem).classList.add('list-grupo-active')
  // }
  // const handleChangeOut = () => {
  //   let itens = document.querySelectorAll('.list-grupo-item')
  //   for (var i in itens) {
  //     if (typeof itens[i].classList != 'undefined') {
  //       itens[i].classList.remove('list-grupo-active')
  //     }
  //   }
  // }

//   const handleChange = (eventKey) => {
//     eventKey.preventDefault()
//     let idItem = eventKey.currentTarget.getAttribute('id')
//     let itens = document.querySelectorAll('.list-grupo-item')
//     for (var i in itens) {
//       if (typeof itens[i].classList != 'undefined') {
//         itens[i].classList.remove('list-grupo-active')
//       }
//     }

//     let tagList = []
//     let idItem1 = idItem.replace('item_', '')
//     // eslint-disable-next-line array-callback-return
//     Object.keys(grupoTags).map((response, item) => {
//       // eslint-disable-next-line array-callback-return
//       grupoTags[response].map((response, y) => {
//         if (Number(idItem1) === item) {
//           tagList.push(response['tag'])
//         }
//       })
//     })
// // document.getElementById('detalhes_' + idItem).classList.add('list-grupo-active')
//     setDataFilter(tagList)
//   }

  const filterByTag = (value) => {
    setDataFilter(value)
  }

  let href = '#'
  return (
    <>
      <div className='row'>
        <div className='col-md-3 pe-10'>
          <div className='card card-flush h-lg-50'>
            <div className='card-header pt-5 p-0'>
              <h3 className='card-title text-gray-800 fw-bold'>Camadas</h3>
            </div>

            <div className='card-body pt-5 p-0'>
              <div><div className='d-flex flex-stack'>
                              <a
                                href={href}
                                className='text-primary text-gray-700 fs-6 me-2'
                                onClick={() => {
                                  filterByTag('destaque')
                                }}
                              >
                                <BiChevronRight className='fs-2 aling-center text-gray-500'></BiChevronRight>
                                Destaque
                              </a>
                            </div>
                        
                          <div className='separator separator-dashed my-3'></div></div>
              {Object.keys(grupoTags).map((response, item) => {
                return (
                  // <ul  key={'ul'+item} className='list-grupo-item list-content-tags' id={'detalhes_item_' + item}>
                  <div key={item}> 
                    {grupoTags[response].map((response, y) => {
                      return (
                        <div key={y}>
                          <div>
                            <div className='d-flex flex-stack'>
                              <a
                                href={href}
                                className='text-primary text-gray-700 fs-6 me-2'
                                id={'item_' + item}
                                onClick={() => {
                                  filterByTag(response['tag'])
                                }}
                              >
                                <BiChevronRight className='fs-2 aling-center text-gray-500'></BiChevronRight>{' '}
                                {response['tag']}
                              </a>
                            </div>
                          </div>
                          <div className='separator separator-dashed my-3'></div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}

              {/* {Object.keys(grupoTags).map((response, item) => {
                return (
                  <div key={item}>
                    <div>
                      <div className='d-flex flex-stack'>
                        <a
                          href={href}
                          className='text-primary fw-semibold fs-6 me-2'
                          id={'item_' + item}
                          onClick={handleChange}
                        >
                          {response}
                        </a>
                      </div>
                    </div>
                    <div className='separator separator-dashed my-3'></div>
                  </div>
                )
              })} */}
            </div>
          </div>
        </div>
        <div className='col-md-9 pt-10  px-10 ' style={{borderLeft: '1px solid #e4eaec'}}>
          {/* {Object.keys(grupoTags).map((response, item) => {
              return (
                <ul  key={'ul'+item} className='list-grupo-item list-content-tags' id={'detalhes_item_' + item}>
                  
                  {grupoTags[response].map((response,y) => {
                    return (
                      
                        <li
                          key={'ul_li'+item+'_'+y}
                          className='nav-item btn btn-bg-light btn-color-primary btn-hover-scale me-5'
                          onClick={() => {
                            filterByTag(response['tag'])
                          }}
                          // id={response.id}
                        >
                          {response['tag']}
                        </li>
                    
                    )
                  })}
                </ul>
              )
            })} */}

          
          <ListaCamadas key={'listacamadas_0'} filtroTags={dataFilter} intersect={intersect} />
        </div>
      </div>
    </>
  )
}
