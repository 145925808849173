import React, {useState} from 'react'
// import {MenuItemGrupo} from './MenuItemGrupo'
import {RelatorioTable} from './RelatorioTable'
import {Loader} from '../../../../../../components/UI/Loader/Loader'

// import {RelatorioTable} from './'
// import { Table } from './Table'
// import {MenuItemGrupo} from './MenuItemGrupo'
// import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
// import { Button, ButtonGroup, Dropdown } from 'react-bootstrap'

type GeoJSON = {
  type: string
  coordinates: []
}
type Props = {
  uuid: string
  label: string
  servico: []
  tipoServico: number
  lat: number
  lng: number
  distancia: number
  municipio: string
  intersect: GeoJSON
}

export const Grupo: React.FC<Props> = ({
  uuid,
  label,
  servico,
  tipoServico,
  lat,
  lng,
  distancia,
  municipio,
  intersect,
}) => {
  const [servicoResult, setServicoResult] = useState({})
  let geom = null
  if (
    JSON.stringify(intersect) === '{"type":"Polygon"}' ||
    JSON.stringify(intersect) === '{"type":"Point"}'
  ) {
    geom = {
      type: intersect.type,
      coordinates: intersect.coordinates,
    }
  } else {
    geom = intersect
  }
  const loadService = (servico: any) => {
    const intersectString = btoa(unescape(encodeURIComponent(JSON.stringify(geom))))
    const url =
      servico.url_sistema
        .replace('[LONGITUDE]', lng)
        .replace('[LATITUDE]', lat)
        .replace('[MUNICIPIO]', municipio)
        .replace('[DISTANCIA]', distancia) +
      '&intersect=' +
      intersectString

    fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZDBhODAxNDYwODVjMjA5Zjg1NTVjNWYiLCJyb2xlIjpbIlVzZXIiLCJBZG1pbiJdLCJpYXQiOjE2ODE0MDk0NjR9.PUObCxMX4TyDPyCesyytKG1wW849Dfnt_h_yPDbrRP8',
        // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZTBmZTA2NzkzMmVkODQyODY5OTZjYjMiLCJyb2xlIjpbIlVzZXIiLCJBZG1pbiJdLCJpYXQiOjE2Mjg2OTUwNjR9.qJmY1qf0ioySnj0S9QizKfCSmL3jPYemPGJgEni60Kc',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        } else {
          return null
        }
      })
      .then((data) => {
        if (!data || typeof data === 'undefined') {
          setServicoResult({service: 'erro', message: data, itens: [], total: 0})
        } else {
          setServicoResult(data)
        }
      })
      .catch((err) => {
        setServicoResult({service: 'error', message: err.message, itens: [], total: 0})
      })
  }
  return (
    <div>
      <h2
        className='accordion-header  bg-light'
        id={'flush-' + uuid}
        style={{borderRadius: '15px'}}
      >
        <button
          className='accordion-button collapsed bg-light fs-5 '
          type='button'
          data-bs-toggle='collapse'
          data-bs-target={'#flush-collapseOne-' + uuid}
          aria-expanded='true'
          aria-controls={'flush-collapseOne-' + uuid}
          onClick={(e) => {
            if (!e.target['classList'].contains('collapsed')) {
              loadService(servico)
            }
          }}
        >
          {label}
        </button>
      </h2>
      <div
        id={'flush-collapseOne-' + uuid}
        className='accordion-collapse collapse'
        aria-labelledby={'flush-' + uuid}
        data-bs-parent={'accordionflush-' + uuid}
      >
        <div className='accordion-body p-0'>
          <div className='menu menu-column '>
            {!servicoResult ? (
              <div>Carregando...</div>
            ) : Object.entries(servicoResult).length > 0 ? (
              servicoResult['itens'].length > 0 ? (
                <RelatorioTable
                  header={Object.keys(servicoResult['itens'][0])}
                  body={servicoResult['itens']}
                ></RelatorioTable>
              ) : // eslint-disable-next-line array-callback-return
              typeof servicoResult['message'] !== 'undefined' ? (
                <div>
                  <div className='menu-item my-1'>
                    <div className='menu-link px-4'>
                      <span className='alert alert-danger'>Erro ao carregar o serviço</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className='menu-item my-1'>
                    <div className='menu-link px-4'>Nada Consta</div>
                  </div>
                </div>
              )
            ) : (
              <div className='menu-item my-1'>
                <div className='menu-link px-4'>
                  <Loader text={''}></Loader>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
