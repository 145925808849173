import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { MutationDeleteImovel } from '../../../../graphql'
import { BiCompass } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import { encodeUrl } from '../../../../components/util'
import { MutationSetLogAcessoRecurso } from '../../../../graphql/services/LogAcesso'

type Props = {
  id: number
}

export const Buttons: React.FC<Props> = ({ id }) => {
  const { HandleDeleteImoveis, loading } = MutationDeleteImovel()
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  let history = useHistory()

  if (loading) {
    Swal.fire('Enviando Informações...', '')
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteImoveis({ variables: { deleteImovelId: id } })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Lote deletado com sucesso.', 'success')
        }
      }
    })
  }

  function explorerOpen(id) {
    history.push('/explorer?ids=' + encodeUrl(id))
    // history.go(0)
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          className='btn btn-info me-5'
          onClick={() => {
            explorerOpen(id)
          }}
        >
          <BiCompass className='fs-2'></BiCompass> Explorer
        </button>

        <Link
          onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'informacoes-lote', action: 'list', url: `/meus-imoveis/details/${id}#informacoes-lote` })}
          to={`/meus-imoveis/details/${id}#informacoes-lote`} className='btn all-center btn-light-success  me-2'>
          Ver Mais
        </Link>
        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          disabled={loading}
          className='btn btn-icon btn-light-danger  btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
