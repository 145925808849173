import {useEffect, useState} from 'react'
import 'rc-slider/assets/index.css'
import TooltipSlider, {handleRender} from '../../UI/MultiRangeSlider/Tooltip'
export const SliderSingle = ({defaultValue, min, max, step, onChange, tipFormatter, marks}) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <div className='form-group pt-0 '>
      <div className='row'>
        <div className='col-4 px-0'>
          <input
            type='number'
            name='area_min'
            id='area_min'
            value={value}
            onChange={(e) => {
              setValue(Number(e.target.value))
              onChange(Number(e.target.value))
            }}
            className='form-control '
          />
        </div>
        <div className='col-8  pt-5 pe-5 ps-5'>
          <TooltipSlider
            // defaultValue={value}
            value={value}
            onChange={(value) => {
              setValue(value)
              onChange(value)
            }}
            min={parseInt(min)}
            max={parseInt(max)}
            step={parseInt(step)}
            handleRender={handleRender}
            tipProps={undefined}
            tipFormatter={tipFormatter}
            marks={marks}
          />
        </div>
      </div>
    </div>
  )
}
