import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { MutationSetCupom } from '../../../graphql'
import { FormInputAndLabel } from '../../../../components/UI/Inputs/FormInputAndLabel'
import { ErrorMessageComponent } from '../../../components/UI/ErrorMessage'
import { useEffect, useState } from 'react'
import { FormInputMaskAndLabel } from '../../../../components/UI/Inputs/FormInputMaskAndLabel'

interface situacaoTerrenoProps {
  closeModal: any
  show: boolean
}

export function CreateForm({ closeModal, show }: situacaoTerrenoProps) {

  const { DataSetCupom, FormSetCupom, error, errors, handleSubmit, loading, register, setValue, control } =
    MutationSetCupom()

  useEffect(() => {
    if (DataSetCupom) {
      closeModal()
      Swal.fire('Sucesso!', 'Cupom criado com sucesso.', 'success')
    }
  }, [DataSetCupom, closeModal, error, loading])

  if (loading) {
    Swal.fire(
      'Enviando Informações...', '')
    Swal.showLoading()
  }

  const [isDataExpiracao, setIsDataExpiracao] = useState(false);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setIsDataExpiracao(selectedValue === '2');
  };

  return (
    <Modal centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Novo Cupom:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(FormSetCupom)}>
          <div className='row'>
            <div className='col-md-12'>
              <div className='mb-6'>
                <FormInputAndLabel
                  label='Codigo'
                  className='form-control required form-control-solid'
                  placeholder='Codigo'
                  required
                  register={register("codigo")}
                  error={errors ? errors.codigo?.message : ''}
                />
              </div>
            </div>
            <div className='col-md-12'>
              <div className='row mb-6'>
                <div className='col-md-6'>
                  <FormInputMaskAndLabel
                    placeholder='00.00%'
                    label=' Desconto(%)'
                    max={100}
                    required
                    mask='99.99%'
                    maskChar='_'
                    className='form-control form-control-solid text-right'
                    error={errors ? errors.desconto?.message : ''}
                    onChange={(e) => {
                      setValue('desconto', parseInt(e.target.value))
                    }}
                  />
                </div>
                <div className='col-md-6'>
                  <label className='col-form-label'>Tipos</label>
                  <select
                    className="form-control form-control-solid"
                    {...register('tipo')}
                    required
                    onChange={handleSelectChange}
                  >
                    <option value="1">Uso único</option>
                    <option value="2">Data de expiração</option>
                  </select>
                  {errors.tipo && <ErrorMessageComponent message={errors.tipo?.message} />}
                </div>
              </div>
            </div>
            <div className={!isDataExpiracao ? 'col-md-12' : 'col-6'}>
              <div className='mb-6'>
                <label className='col-form-label'>Cupom já utilizado?</label>
                <select required className="form-control form-control-solid"  {...register("usado")} >
                  <option value="1" >Sim</option>
                  <option value="2" >Não</option>
                </select>
                {errors.usado && <ErrorMessageComponent message={errors.usado?.message} />}
              </div>
            </div>
            <div className={!isDataExpiracao ? 'd-none' : 'col-6'}>
              <div className='mb-6'>
                <label className='col-form-label'>Data de Expiração:</label>
                <input
                  type='date'
                  className="form-control form-control-solid"
                  {...register("data_expiracao", { shouldUnregister: true })} // Use shouldUnregister: true
                  onChange={(e) => {
                    const selectedDate = e.target.value ? new Date(e.target.value) : null; // Converta para Date apenas se houver um valor
                    console.log(selectedDate)
                    // setValue('data_expiracao', selectedDate);
                  }}
                />

                {errors.usado && <ErrorMessageComponent message={errors.data_expiracao?.message} />}
              </div>
            </div>
            <hr />
            <div className="row mt-4">
              <div className="col-12">
                <label htmlFor="">Pedidos registrados com esse cupom</label><br />
                <input type="text" className="form-control text-center" disabled placeholder="Não existem pedidos registrados com esse cupom." />
              </div>
            </div>

            <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
              <button
                className='btn btnSecondary  pull-right '
                type='button'
                onClick={() => closeModal()}
              >
                Fechar
              </button>
              <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                Salvar
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
