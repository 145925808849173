import React from 'react';
import Swal from 'sweetalert2'
import { MutationDeleteAVM } from '../../../../../../graphql/services/AVM';
import { KTSVG } from '../../../../../../../_metronic/helpers';

type Props = {
  id: number
}

export const Buttons: React.FC<Props> = ({ id  }) => {
  
  const { HandleDeleteAVM, loading } = MutationDeleteAVM()
  if (loading) {
    Swal.fire(
      'Enviando Informações...','')
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {

        const result = await HandleDeleteAVM({ variables: { deleteAvmLeadId: id } })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Registro deletado com sucesso.', 'success')
        }
      }
    })
  }



  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        
        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          // disabled={loading}
          className='btn btn-icon btn-light-danger  btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
