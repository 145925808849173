import {BiMapPin} from 'react-icons/bi'
import {
  EmpreendimentoResponse,
  TypesGetEmpreendimentoImovelResponse,
} from '../../../../../graphql/type'
import {GetUsuarioGrupoServicoByUserChosen} from '../../../../../components/UI/GetUsuarioGrupoServicoByUser'
import {useState, useRef} from 'react'
import {ServicoCard} from './ServicoCard'
import {textCidadeToMunicipioUrl} from '../../../../../components/util'
import * as XLSX from 'xlsx'

type Props = {
  data: EmpreendimentoResponse
  imoveis: TypesGetEmpreendimentoImovelResponse
  param: string
}

export const TabFichaResumo: React.FC<Props> = ({data, imoveis, param}) => {
  const [grupo, setGrupo] = useState(null)
  const [result, setResult] = useState({})
  const reportTemplateRef = useRef(null)

  function ChangeGrupoServico(grupo) {
    setGrupo(grupo[0])
  }

  function setResultExport(imovelId, url, resultServico) {
    if (typeof result[imovelId] === 'undefined') {
      // servico[imovelId] = []
      result[imovelId] = []
    }
    if (typeof result[imovelId][url] === 'undefined') {
      result[imovelId][url] = []
    }

    result[imovelId][url].push(resultServico)

    setResult({...result})
  }

  function handleExport2() {
    // Acquire Data (reference to the HTML table)
    var table_elt = reportTemplateRef.current
    let tbl = addWriteText(table_elt)
    const worksheet = XLSX.utils.json_to_sheet(tbl)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Tabela de Dados')
    XLSX.writeFile(wb, 'dados.xlsx')
  }

  function addWriteText(table_elt) {
    var table = table_elt
    // Obtenha todas as linhas da tabela
    const rows = table.querySelectorAll('tbody tr')
    const headers = table.querySelectorAll('thead tr th')

    // Crie um array para armazenar os dados da tabela
    const data = []
    let headerx = []

    headers.forEach((rowHeader) => {
      headerx.push(rowHeader.innerText)
    })

    data.push(headerx)

    // Itere sobre as linhas da tabela e extraia os dados das células
    rows.forEach((row) => {
      const cells = row.querySelectorAll('td')
      const rowData = []
      cells.forEach((cell) => {
        // Use textContent para obter o texto da célula
        rowData.push(cell.innerText)
      })

      // Adicione a linha de dados ao array
      data.push(rowData)
    })

    return data
  }

  return (
    <div
      className={`tab-pane fade ${param === 'ficha-resumo' ? ' show active' : ''}`}
      id='ficha-resumo'
      role='tabpanel'
      aria-labelledby='ficha-resumo'
    >
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Ficha Resumo</h3>
          <div className='card-toolbar'>
            <button className='btn btn-primary my-5' onClick={handleExport2}>
              Exportar XLS
            </button>
          </div>
        </div>
        <div className='card-body p-6 pt-5'>
          <div className='container-fluid2'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='table-responsive ' style={{height: '500px'}}>
                  <table
                    className='table table-bordered table-striped  gy-8 gs-2'
                    style={{maxHeight: '600px', overflowX: 'auto', overflowY: 'auto'}}
                    ref={reportTemplateRef}
                  >
                    <thead>
                      <tr className='row-header border-bottom-1'>
                        <th className='  align-middle  w-200px zIndex-10' style={{minWidth: 180}}>
                          Grupo de Serviços
                          <GetUsuarioGrupoServicoByUserChosen
                            idGrupo={0}
                            onChange={ChangeGrupoServico}
                            idCidade={
                              imoveis
                                ? imoveis.GetEmpreendimentoImovel.result[0]?.terreno.id_cidade
                                : null
                            }
                          ></GetUsuarioGrupoServicoByUserChosen>
                        </th>
                        {imoveis
                          ? // eslint-disable-next-line array-callback-return
                            imoveis.GetEmpreendimentoImovel.result.map((response, item) => {
                              if (response.terreno) {
                                return (
                                  <th key={item} className='text-center header-location'>
                                    <BiMapPin className='fs-1'></BiMapPin>
                                    <br />
                                    <p>
                                      {response.terreno.endereco},{response.terreno.numero}
                                    </p>
                                    {/* <a href='#vazio' className='btn-sync-terrain'>
                                    Sincronizar terreno
                                  </a> */}
                                  </th>
                                )
                              }
                            })
                          : ''}
                      </tr>
                    </thead>
                    <tbody>
                      {grupo ? (
                        grupo.servico.map(function (servico, item) {
                          return (
                            <tr key={item}>
                              <td className='text-center'>{servico.nome}</td>

                              {imoveis
                                ? imoveis.GetEmpreendimentoImovel.result.map((response, item) => {
                                    let cidade = textCidadeToMunicipioUrl(response.terreno.cidade)
                                    console.log(cidade)
                                    return (
                                      <td key={item} className=''>
                                        <ServicoCard
                                          url={servico.url_api}
                                          lat={response.terreno.latitude}
                                          lng={response.terreno.longitude}
                                          municipio={cidade}
                                          servico={servico}
                                          distancia={0}
                                          setResultExport={setResultExport}
                                          imovelId={response.terreno.id}
                                        ></ServicoCard>
                                      </td>
                                    )
                                  })
                                : ''}
                            </tr>
                          )
                        })
                      ) : (
                        <tr key={0}>
                          <td className='text-center'>Selecione um grupo de serviço</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
