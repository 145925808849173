import { RGBAColor, RGBColor } from "../../Types/layers";
import { DEFAULT_COLOR_RANGE } from "./color-range";

export const DEFAULT_LAYER_OPACITY = 0.8;
export const DEFAULT_HIGHLIGHT_COLOR: RGBAColor = [252, 242, 26, 255];


export type ColorLayer = {
    "fill-color": RGBAColor,
    "line-color": RGBAColor,
  };
  
export const DEFAULT_FILL_COLOR: ColorLayer = {
    "fill-color": [255,255,255,50],
    "line-color": [255, 255, 255,50]
  };




export const DEFAULT_COLOR: RGBAColor = [65, 65, 26, 255];
export const DEFAULT_LAYER_LABEL = 'new layer';
export {DEFAULT_COLOR_RANGE};

export const SCALE_TYPES = {
    ordinal: null,
    quantile: null,
    quantize: null,
    linear: null,
    sqrt: null,
    log: null,
  
    // ordinal domain to linear range
    point: null
  };

export type Field = {
    analyzerType: string;
    id?: string;
    name: string;
    displayName: string;
    format: string;
    type: string;
    fieldIdx: number;
    valueAccessor(v: {index: number}): any;
    filterProps?: any;
    metadata?: any;
  };
  
export type LayerTextLabel = {
    field: Field | null;
    color: RGBColor;
    size: number;
    offset: [number, number];
    anchor: string;
    alignment: string;
  };
  

export const DEFAULT_TEXT_LABEL: LayerTextLabel = {
    field: null,
    color: [255, 255, 255],
    size: 18,
    offset: [0, 0],
    anchor: 'start',
    alignment: 'center'
  };

  
export const PROPERTY_GROUPS = {
    color: null,
    stroke: null,
    radius: null,
    height: null,
    angle: null,
    // for heatmap aggregation
    cell: null,
    precision: null,
    display: null
  };
  