export const AddressesExtended = ({data, estrutura}) => {
  let dateReturn = null

  if (
    typeof data['Result'] !== 'undefined' &&
    data['Result'].length > 0 &&
    data['Result'][0]['ExtendedAddresses'] &&
    data['Result'][0]['ExtendedAddresses']['Addresses']
  ) {
    dateReturn = data['Result'][0]['ExtendedAddresses']['Addresses']
  } else if (
    data.length > 0 &&
    data[0]['ExtendedAddresses'] &&
    data[0]['ExtendedAddresses']['Addresses']
  ) {
    dateReturn = data[0]['ExtendedAddresses']['Addresses']
  } else {
    dateReturn = [] // or handle the case where data structure doesn't match expected format
  }
  // console.log(dateReturn)

  return (
    <>
      {dateReturn?.map((item, index) => {
        return (
          <div key={index}>
            <p className='fs-6'>
              Endereço: {item['Typology'] + ' ' + item['AddressMain'] + ' ' + item['Number']} (
              {item['Type']})
            </p>
            <p className='fs-6'>Complemento: {item['Complement']}</p>
            <p className='fs-6'>Bairro: {item['Neighborhood']}</p>
            <p className='fs-6'>Cidade: {item['City']}</p>
            <p className='fs-6'>Estado: {item['State']}</p>
            <p className='fs-6'></p>
            <hr />
          </div>
        )
      })}

      {/* <h3 className="pt-10">{estrutura.titulo}</h3>

      <table className="table fs-6">
        <thead>
        <tr>
          {estrutura['colunsItens'].map((column, index) => {
            return (
              <th key={index} className='fs-bold'>
                {column}
              </th>
            )
          })}
        </tr>
        </thead>
        <tbody>
        {dateReturn?.map((item, index) => {
          return (
            <tr key={index}>{estrutura['colunsItens'].map((column, index2) => {
                return <td key={index2}>{item[column]}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table> */}
    </>
  )
}
