import { gql } from "@apollo/client";

export const GET_TERMOS = gql`
    query GetTermos($pagination: Pagination) {
        GetTermos(pagination: $pagination) {
            id
            nome
            titulo
            texto
            status
            data_cadastro
        }
    }
`

export const GET_TERMO_BY_ID = gql`
    query GetTermoById($termo: termo!) {
        GetTermoById(termo: $termo) {
            id
            nome
            titulo
            texto
            status
            data_cadastro
        }
    }
`