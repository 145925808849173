import {gql} from '@apollo/client'

export const GET_NEWS_FEED = gql`
  query GetNewsFeed($pagination: Pagination) {
    GetNewsFeed(pagination: $pagination) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        id
        title
        description
        created_at
        updated_at
        created_by
        updated_by
        status
        start_date
        end_date
      }
    }
  }
`
