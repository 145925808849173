import FiltroPrincipal from '../../Menu/FiltroPrincipal'

export function ExplorerMenuTopo() {
  // //console.log('ExplorerMenuTopo')

  // useEffect(() => {
  //   DrawerComponent.hideAll()
  // }, [])

  return (
    
      <div className='d-flex align-items-center justify-content-between'  id='menu-topo-filtro'>
        <FiltroPrincipal></FiltroPrincipal>
      </div>
    
  )
}
