import {gql} from '@apollo/client'

export const GET_NEWS_FEED = gql`
  query GetNewsFeed($datas: Datas, $pagination: Pagination) {
    GetNewsFeed(datas: $datas, pagination: $pagination) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        id
        title
        description
        created_at
        updated_at
        created_by
        updated_by
        status
        start_date
        end_date
      }
    }
  }
`

export const GET_NEWS_FEED_BY_ID = gql`
  query GetNewsFeedById($getNewsFeedByIdId: Float) {
    GetNewsFeedById(id: $getNewsFeedByIdId) {
      id
      title
      description
      created_at
      updated_at
      created_by
      updated_by
      status
      start_date
      end_date
    }
  }
`
