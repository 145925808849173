import {useContext, useEffect, useMemo, useState} from 'react'
import {DataContext} from '../../../context/DataContext'
import {FilterContext} from '../../../context/FilterContext'
import {Dicionario} from '../../../../../utils/Dicionario'
import {BarChart} from '../../Charts/BarChart'

type Props = {
  label?: string
}

export default function VSOPrograma({label}: Props) {
  const {data, vsoIdeal, cacheResult, setCacheResult} = useContext(DataContext)
  const {filter} = useContext(FilterContext)
  const [selectedItem, setSelectedItem] = useState(null)

  let {chartDataVSOPrograma, chartDataVSOProgramaTable} = useMemo(() => {
    let totalUnidadesTipologias = Array(7).fill(0, 0, 7)
    let stockUnidadesTipologias = Array(7).fill(0, 0, 7)
    let totalUnidades = Array(7).fill(0, 0, 7)
    let vsoIdealTipologia = Array(7).fill(0, 0, 7)
    let chartDataVSOPrograma = [
      {
        name: 'VSO',
        data: [],
      },
      {
        name: 'Estoque',
        data: [],
      },
    ]
    let chartDataVSOProgramaTable = []

    if (data) {
      // eslint-disable-next-line array-callback-return
      data.map((item) => {
        // eslint-disable-next-line array-callback-return
        item.tipologias.map((itemTipologias) => {
          if (itemTipologias.computed) {
            //if (itemTipologias.original_price > 0.1) {
              let indice = itemTipologias.bedrooms
              if (itemTipologias.bedrooms === 0) {
                indice = 1
              }
              totalUnidadesTipologias[indice] =
                totalUnidadesTipologias[indice] + Number(itemTipologias.total_units)
              stockUnidadesTipologias[indice] =
                stockUnidadesTipologias[indice] + Number(itemTipologias.stock)
              totalUnidades[indice] = totalUnidades[indice] + Number(itemTipologias.total_units)
              // data.fase_projeto_ideal
              vsoIdealTipologia[indice] += Math.floor(
                (vsoIdeal[item.fase_projeto_ideal].vso / 100) * Number(itemTipologias.total_units)
              )
          //  }
          }
        })
      })

      // eslint-disable-next-line array-callback-return
      totalUnidadesTipologias.map((data, index) => {
        const id = `Performance_${index}`

        if (totalUnidadesTipologias[index] > 0) {
          let vso = Math.round(
            ((totalUnidadesTipologias[index] - stockUnidadesTipologias[index]) /
              totalUnidades[index]) *
              100
          )

          let vsoIdeal = Math.round((vsoIdealTipologia[index] / totalUnidades[index]) * 100)
          chartDataVSOPrograma[0].data.push({
            x: `${index}Q ${index === 1 ? 'single' : ''}`,
            y: vso,
            goals: [
              {
                name: 'VSO Ideal',
                value: vsoIdeal,
                strokeHeight: 5,
                strokeColor: '#6e2a2a',
              },
            ],
            total_unidades: totalUnidadesTipologias[index],
            id: id,
          })

          chartDataVSOPrograma[1].data.push({
            x: `${index}Q ${index === 1 ? 'single' : ''}`,
            y: Math.round((stockUnidadesTipologias[index] / totalUnidades[index]) * 100),
            goals: [
              {
                name: 'VSO Ideal',
                value: vsoIdeal,
                strokeHeight: 5,
                strokeColor: '#6e2a2a',
              },
            ],
            total_unidades: totalUnidadesTipologias[index],
            id: id,
          })

          chartDataVSOProgramaTable.push({
            domitorios: `${index}Q ${index === 1 ? 'single' : ''}`,
            total_unidades: totalUnidadesTipologias[index],
            estoque: stockUnidadesTipologias[index],
            vendidas: totalUnidadesTipologias[index] - stockUnidadesTipologias[index],
            id: id,
          })
        }
      })
    }

    return {chartDataVSOPrograma, chartDataVSOProgramaTable}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, vsoIdeal])

  useEffect(() => {
    cacheResult.performanceResumoMercado.vsoPrograma = {
      chartData: chartDataVSOPrograma,
      tableData: chartDataVSOProgramaTable,
    }
    setCacheResult(cacheResult)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDataVSOPrograma])

  if (!data) {
    return <>Carregando..</>
  }
  if (filter.segmentacaoArea === null || filter.segmentacaoPreco === null) {
    return <>Carregando..</>
  }

  return (
    <BarChart
      className={'primary'}
      title={'VSO Por Programa'}
      subtitle={''}
      data={chartDataVSOPrograma}
      onChange={(id: number) => {
        setSelectedItem(id)
      }}
    >
      <table className='table  table-row-dashed table-row-gray-300 table-hover'>
        <thead>
          <tr className='text-dark fw-bold'>
            {chartDataVSOProgramaTable && chartDataVSOProgramaTable.length > 0
              ? Object.entries(chartDataVSOProgramaTable[0]).map((item, index) => {
                  return item[0] !== 'id' ? (
                    <th className='text-center' key={index}>
                      {Dicionario.translate(item[0])}
                    </th>
                  ) : null
                })
              : null}
          </tr>
        </thead>
        <tbody>
          {chartDataVSOProgramaTable
            ? chartDataVSOProgramaTable.map((item, index) => {
                return (
                  <tr key={index} className={item.id === selectedItem ? 'select-data-table' : ''}>
                    <td className='text-center'>{item.domitorios}</td>
                    <td className='text-center'>{item.total_unidades}</td>
                    <td className='text-center'>{item.estoque}</td>
                    <td className='text-center'>{item.vendidas}</td>
                  </tr>
                )
              })
            : null}
        </tbody>
      </table>
    </BarChart>
  )
}
