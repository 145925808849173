import React from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { MutationDeleteGrupoServicos } from '../../../../graphql/services/GruposServicos'
import { MutationSetLogAcessoRecurso } from '../../../../graphql/services/LogAcesso'

type Props = {
    id: number
    setIdEdit?: any
}

export const Buttons: React.FC<Props> = ({ id, setIdEdit }) => {
    const idDelete = id
    const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()
    const { HandleDeleteGruposServicos, loading } = MutationDeleteGrupoServicos()

    if (loading) {
        Swal.fire(
            'Enviando Informações...',
            '',
        )
        Swal.showLoading()
    }

    function confirmDelete(id) {
        Swal.fire({
            title: 'Tem certeza que deseja excluir?',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Excluir',
            showCloseButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const result = await HandleDeleteGruposServicos({ variables: { deleteUsuarioGrupoServicoId: idDelete } })
                if (!result) {
                    Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
                } else {
                    FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'grupos-servicos', action: 'delete', url: '/cadastro/cadastro-grupos' });
                    Swal.fire('Sucesso!', 'Grupo deletado com sucesso.', 'success')
                }
            }
        })
    }

    return (
        <div className='d-flex justify-content-end flex-shrink-0'>
            <button type="button" className="btn btn-icon btn-light-success btn-sm me-2"
                onClick={(e) => {
                    setIdEdit(id)
                }}
            >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </button>
            <button
                onClick={(e) => {
                    confirmDelete(id)
                }}
                className='btn btn-icon btn-light-danger btn-sm'
            >
                <KTSVG
                    path='/media/icons/duotune/general/gen027.svg'
                    className='svg-icon-3'
                />
            </button>
        </div>
    )
}
