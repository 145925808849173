import {useEffect, useState} from 'react'
import {QueryGetAdminEmpresaUsuarios} from '../../../../../graphql/services/Empresas'
import Select from 'react-select'

export function ChosenEmpresaUsuario({setValueUsuario, valueUsuario}) {
  let usuariosArray = []

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paginacao, setPaginacao] = useState({pagina: 0, quantidade: 100})
  const [usuario, setUsuario] = useState(valueUsuario)

  useEffect(() => {
    setValueUsuario(usuario)
  }, [usuario, setValueUsuario])

  const {data} = QueryGetAdminEmpresaUsuarios({
    variables: {
      pagination: {
        pagina: paginacao.pagina,
        quantidade: paginacao.quantidade,
      },
    },
  })

  if (!data) {
    return (
      <>
        <div className='form-group'>
          <label className='col-form-label'>Usuarios Ativos:</label>
          <Select
            options={usuariosArray}
            value={usuariosArray.filter((e) => e.value)[0]}
            name='id_user'
            placeholder='Usuarios Disponiveis'
          />
        </div>
      </>
    )
  }

  data.GetEmpresasUsuarioAdmin.result.map((Options) => {
    usuariosArray.push({
      value: Options.users.email,
      label: Options.users.email ? `${Options.users.name}` : Options.users.name,
    })
    return []
  })

  return (
    <>
      <div className='form-group'>
        <label className='col-form-label'>Usuarios Ativos </label>
        <Select
          options={usuariosArray}
          onChange={(e) => {
            setUsuario(e.value)
          }}
          name='id_user'
          placeholder='Usuarios Disponiveis'
        />
      </div>
    </>
  )
}
