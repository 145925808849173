import {useContext, useEffect, useRef, useState} from 'react'
import {MapboxLayer} from './MapBox/MapBox'
import {loadIsocrona} from '../Common/Isocrona'
import {AllGeoJSON, FeatureCollection} from '@turf/turf'
import {FilterContext} from '../../context/FilterContext'
import {encodeJsonIntersect, getLayer} from '../MapLayers/Layer'
import html2canvas from 'html2canvas'
import { Endereco } from '../Types'

export function Mapa3({data, setMapImageExport, isocronas, geometria, endereco}: {data: any; setMapImageExport: any, isocronas:any, geometria:any, endereco:Endereco}) {
  const refLegenda = useRef<HTMLDivElement>(null)
  let json = geometria as AllGeoJSON
  const layerConfig = getLayer('view_ibge_explorer_renda_mensal_domiciliar')
  const jsonEncoded = encodeJsonIntersect(json)
  let intersect = `${layerConfig.config.tile}layer=${layerConfig.name}_operacao&l1=${layerConfig.name}&s=${layerConfig.schema}&l2=&g1=${jsonEncoded}`
  layerConfig.config.data = intersect

  async function onload(map) {
    html2canvas(map.target.getCanvasContainer(), {
      useCORS: true,
      scale: 2,
      width: 800,
      height: 500,
    }).then((canvas) => {
      let canvasImage = canvas.toDataURL('image/png', 1.0)
      setMapImageExport(canvasImage)
    })
  }

  const layerConfigVenda = getLayer('view_explorer_apartamento_venda_')
  intersect = `${layerConfig.config.tile}layer=${layerConfigVenda.name}_operacao&l1=${layerConfigVenda.name}&s=${layerConfigVenda.schema}&l2=&g1=${jsonEncoded}`
  layerConfigVenda.config.data = intersect
  return (
    // <div style={{position: 'fixed', width: '1000', height: '850', left: '200px', top: 0}}>
      <MapboxLayer
        latLng={[endereco.latitude, endereco.longitude]}
        zoom={14.5}
        onLoad={onload}
        style={{width: 800, height: 500}}
        layers={[
          {
            tiles: [layerConfigVenda.config.data],
            source: 'view_explorer_apartamento_venda__operacao',
            paint: {
              'circle-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'preco_m2']],
                2000,
                '#FF3333',
                4000,
                '#FF3333',
                6000,
                '#FF9933',
                8000,
                '#FFFF33',
                10000,
                '#99FF33',
                12000,
                '#33FF33',
                14000,
                '#33FF99',
                16000,
                '#33FFFF',
                18000,
                '#3399FF',
              ],
              'circle-radius': 5,
              'circle-opacity': 1,
            },
            layout: {},
            paintText: {
              'text-color': '#000000', // Cor do texto
            },
            type: 'vector',
            typeLayer: 'circle',
            legandaTitle: 'Preço por m²',
          },
          {
            data: isocronas,
            paint: {
              'line-color': [
                'case',
                ['==', ['get', 'value'], 300],
                '#3e6b2e',
                ['==', ['get', 'value'], 600],
                '#f10808',
                '#f10808',
              ],
              'line-width': 2,
              'line-dasharray': [3, 5],
            },
            paintText: {
              'text-color': '#000000', // Cor do texto
            },
            type: 'geojson',
            layout: {},
            typeLayer: 'line',
          },
        ]
      }
        areaInfluencia={geometria}
        inputRefLeganda={refLegenda}
      ></MapboxLayer>
    // </div>
  )
}
