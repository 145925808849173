import {Text, View, StyleSheet} from '@react-pdf/renderer'
import {Fragment} from 'react'
import { DecimalFormat } from '../util'


const styles = StyleSheet.create({
  content: {
    flexDirection: 'row',
    borderBottomColor: '#dfdfdf',
    borderBottomWidth: 1,
    // height: 74,
    fontSize: 11,
  },
  hr: {
    width: '100%',
    height: 0.5,
    backgroundColor: '#c7c7c7',
  },
  card: {
    // marginTop: 5,
    paddingVertical: 10,
    border: 0,
    width: '100%',
    // backgroundColor: '#f5f8fa',
    borderRadius: 5,
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  col6: {
    width: '50%',
    // paddingHorizontal: 10,
    paddingTop: 10,
  },
  subtitulo:{
    fontSize: 14,
    paddingTop:20,
    borderBottomWidth:1,
    borderBottomColor:"#d0d0d0"
  },
  col12: {
    width: '100%',
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  title: {
    // paddingHorizontal: 10,
    // paddingBottom: 10,
    fontSize: 16,
    fontWeight: 'bold',
    borderBottomWidth:1,
    borderBottomColor:"#2B8E57"
  },
  groupInfo: {
    marginTop: 2,
  },
  nomeEmpresa: {
    flexDirection: 'column',
    textAlign: 'right',
    // margin: 10,
    paddingBottom: 2,
    fontSize: 13,
  },
  invoiceNoContainer: {
    flexDirection: 'row',
    marginTop: 36,
    justifyContent: 'flex-start',
  },
  invoiceDateContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: 'bold',
  },
  h1: {
    fontSize: 18,
  },
  h2: {
    fontSize: 16,
  },
  h3: {
    fontSize: 14,
  },
  h4: {
    fontSize: 11,
  },
  h5: {
    fontSize: 10,
    backgroundColor: '#fff',
    paddingVertical: 4,
  },
  h6: {
    fontSize: 9,
  },
  p: {
    fontSize: 9,
  },
  bold: {
    fontWeight: 'black',
  },
})

export const Info = ({data}) => {
  return (
    <Fragment>
      <View style={styles.card}>
        <Text style={[styles.h1,styles.title]}>Informações do Empreendimento</Text>
        {/* <Text style={styles.subtitulo}>Dados do Empreendimento</Text> */}
        <View style={styles.row}>
        <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Nome: <Text style={styles.h4}>{data.GetEmpreendimento.nome}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={[styles.h4, styles.bold]}>
                Endereço:
                <Text style={styles.h4}>
                  {data.GetEmpreendimento.endereco},{data.GetEmpreendimento.numero}
                </Text>
              </Text>
            </View>
          </View>
          
        </View>

        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
            <Text style={styles.h4}>
            Área total do terreno: <Text style={styles.h4}>{data.GetEmpreendimento.area_total_terreno}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
              Área Privativa total (m²): <Text style={styles.h4}>{data.GetEmpreendimento.area_total_privativa}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
            <Text style={styles.h4}>
            Preço Médio(R$/m²): <Text style={styles.h4}>{data.GetEmpreendimento.preco_medio_m2}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
              VGV (R$): <Text style={styles.h4}>{data.GetEmpreendimento.vgv}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
            <Text style={styles.h4}>
            Arquiteto: <Text style={styles.h4}>{ DecimalFormat(data.GetEmpreendimento.arquiteto)}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
              Responsável: <Text style={styles.h4}>{DecimalFormat(data.GetEmpreendimento.responsavel)}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
            <Text style={styles.h4}>
            Nome Parceiro: <Text style={styles.h4}>{data.GetEmpreendimento.parceiro_nome}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
              Parceiro % <Text style={styles.h4}>{data.GetEmpreendimento.parceiro_percentual}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
            <Text style={styles.h4}>
            Parceiro % próprio: <Text style={styles.h4}>{data.GetEmpreendimento.parceiro_percentual_proprio}</Text>
              </Text>
            </View>
          </View>
          
        </View>
        
     
      </View>
    </Fragment>
  )
}
