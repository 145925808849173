import React from 'react'

const Content: React.FC = ({ children }) => {
  return (
    <>
      {children}
    </>
  )

}

export { Content }
