import { z } from "zod"

export const putUsuarioFormSchema = z.object({
    id: z.number(),
    name: z.string().optional(),
    email: z.string().optional(),
    telefone: z.string().optional(),
    empresa: z.string().optional(),
    tipo_pessoa: z.string().optional(),
    cor_background: z.string().optional(),
    cor_fonte: z.string().optional()
})

export type putUsuarioFormData = z.infer<typeof putUsuarioFormSchema>


export const recoveryPasswordFormSchema = z.object({
    userId: z.number(),
    newPassword: z.string(),
})

export type recoveryPasswordFormData = z.infer<typeof recoveryPasswordFormSchema>