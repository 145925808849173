// import { QueryGetImoveis } from "../../../../graphql/services"
import { Explorer } from "./Explorer"
import * as turf from '@turf/turf'
export class OpenLocal {
    open(imoveis){
        if (imoveis){
            // eslint-disable-next-line array-callback-return
            imoveis.GetImoveis.result.map((item)=>{
                console.log(item)
                console.log(item.marcacao)
                if (item.marcacao){
                    let GeoJSON = {
                        type: 'Feature',
                        geometry: item.marcacao,
                        properties: {area: 0},
                    }
                    const areaTotal = turf.area(GeoJSON.geometry)
                    GeoJSON.properties.area = areaTotal
                    const id =  Explorer.addGeom(GeoJSON, item.nome)
                    Explorer.addMarker(item.latitude, item.longitude, item.nome,  item.nome,'', id)
                }
            })
            return ''
        }
    }

    openEmpreendimento(imoveis){
        if (imoveis){
            let selecionadosData = []
            let point = null
            // eslint-disable-next-line array-callback-return
            imoveis.GetEmpreendimentos.result.map((item)=>{

                let id= Explorer.addMarkerGeoJson(selecionadosData,item.nome, item.nome)
                // eslint-disable-next-line array-callback-return
                item.empreendimento_terreno.map((imovel) => {
                let GeoJSON = {
                    type: 'Feature',
                    geometry:imovel.terreno.marcacao,
                    properties: {
                    area: 0,
                    endereco:imovel.terreno.endereco,
                    numero:imovel.terreno.numero,
                    zoneamento:imovel.terreno.zoneamento,
                    },
                }

                point =  turf.centroid(GeoJSON.geometry)
                
                Explorer.addGeom(GeoJSON, imovel.terreno.nome, id)
            })

            Explorer.addMarker(point.geometry.coordinates[1], point.geometry.coordinates[0], item.nome, item.nome,'', id)
            })
            
            return ''
        }
    }
}