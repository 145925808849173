import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { MutationDeletePacote } from '../../../graphql'

type Props = {
  id: number
}

export const Buttons: React.FC<Props> = ({ id: idPacote }) => {

  const { HandleDeletePacote, loading } = MutationDeletePacote()

  function confirmDelete(id) {
    Swal.fire({
      title: 'Deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const result = await HandleDeletePacote({ variables: { deletePacoteId: parseInt(id) } })
        if (result) {
          Swal.fire('Sucesso!', 'Cupom deletado com sucesso.', 'success')
        } else {
          Swal.fire('Ocorreu um erro durante o processo.', '', 'error')
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <Link to={`/backoffice/pacotes/edit/${idPacote}`}
          className='btn btn-icon btn-light-success btn-sm me-2'>
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
        <button
          disabled={loading}
          type='button'
          onClick={() => {
            confirmDelete(idPacote)
          }}
          className='btn btn-icon btn-light-danger btn-sm me-1' >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
