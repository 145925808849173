import {gql} from '@apollo/client'

export const GET_EMPREENDIMENTO_TIPO = gql`
  query GetEmpreendimentoTipo($pagination: Pagination!) {
    GetEmpreendimentoTipo(pagination: $pagination) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        id
        uuid
        nome
        situacao
      }
    }
  }
`
