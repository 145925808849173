import {StyleSheet} from '@react-pdf/renderer'
// const borderColor = '#90e5fc'
export const styles = StyleSheet.create({
  rowTopo: {
    flexDirection: 'row',
    borderBottomColor: '#f0f0f0',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 124,
    fontSize: 12,
    marginBottom: 0,
  },
  page: {
    fontFamily: 'Helvetica',
    paddingTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.3,
    flexDirection: 'column',
    color: '#1d1d1deb',
    paddingBottom: 65,
  },

  aviso: {
    color: '#ff0000',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  logo: {
    width: 150,
    height: 'auto',
  },
  mapa: {
    width: 'auto',
    height: '280',
  },
  sobre: {
    marginTop: 10,
    marginRight: 0,
    textAlign: 'right',
    paddingBottom: 2,
    fontSize: 8,
    width: 550,
  },
  nomeEmpresa: {
    flexDirection: 'column',
    textAlign: 'right',
    paddingBottom: 2,
    fontSize: 13,
  },
  space: {
    paddingBottom: 2,
  },
  h1: {
    fontSize: 18,
  },
  h2: {
    fontSize: 16,
  },
  h3: {
    fontSize: 14,
  },
  h4: {
    fontSize: 12,
  },
  h5: {
    fontSize: 10,
  },
  h6: {
    fontSize: 9,
  },
  p: {
    fontSize: 9,
  },
  bold: {
    fontWeight: 'black',
  },
  groupInfo: {
    marginTop: 2,
  },
  col6: {
    width: '50%',
    // paddingHorizontal: 10,
    paddingTop: 3,
  },
  title: {
    marginTop: 12,
    marginBottom: 12,
    // paddingHorizontal: 10,
    // paddingBottom: 10,
    textTransform: 'uppercase',
    fontSize: 15,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: '#2B8E57',
  },
  subtitulo: {
    width: '100%',
    fontSize: 13,
    marginTop: 20,
    marginBottom: 4,
    borderBottomWidth: 1,
    borderBottomColor: '#d0d0d0',
    textTransform: 'uppercase',
  },
  subtituloDestaque: {
    width: '100%',
    fontSize: 13,
    marginTop: 20,
    marginBottom: 4,
    paddingLeft: 5,
    paddingTop: 5,
    paddingBottom: 2,

    borderBottomWidth: 1,
    borderBottomColor: '#d0d0d0',
    backgroundColor: '#e0e0e0',
    textTransform: 'uppercase',
  },
  header: {
    borderTop: 'none',
    // backgroundColor: '#dbdbdb',
    // paddingHorizontal: 10,
  },
  rowDefault: {
    width: '35%',
    padding: 2,
  },
  col12: {
    width: '100%',
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  content: {
    flexDirection: 'row',
    borderBottomColor: '#dfdfdf',
    borderBottomWidth: 1,
    // height: 74,
    fontSize: 11,
  },
  hr: {
    width: '100%',
    height: 0.5,
    backgroundColor: '#c7c7c7',
  },
  card: {
    // marginTop: 5,
    paddingVertical: 10,
    border: 0,
    width: '100%',
    // backgroundColor: '#f5f8fa',
    borderRadius: 5,
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  table: {
    width: '100%',
    fontSize: 11,
    fontWeight: 'light',
  },
  rowErro: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
    textAlign: 'center',
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: '#d0d0d0',
  },
})
