import {z} from 'zod'
import {validarCPF} from '../../components/util'

export const FormCreateProprietariosSchema = z
  .object({
    // id: z.string(),
    nome: z.string(),
    email: z.string(),
    telefone: z.string(),
    // cnpj: z.string(),
    cpf: z.string().refine((cpf) => validarCPF(cpf), {
      message: 'CPF inválido',
    }),
    cnpj: z.string(),
    endereco: z.string(),
    numero: z.string(),
    cep: z.string(),
    complemento: z.string(),
    id_cidade: z.number().nullable(),
    id_estado: z.number().nullable(),
    // estado: z.string(),
    // cidade: z.string(),
    empresa: z.string(),
    situacao: z.number(),
    tipo: z.string(),
  })
  .refine(
    (input) => {
      if (input.tipo === 'pf' && input.nome === '') {
        return false
      }
      return true
    },
    {
      message: 'Nome é obrigatório',
      path: ['nome'],
    }
  )
  .refine(
    (input) => {
      if (input.tipo === 'pj' && input.empresa === '') {
        return false
      }
      return true
    },
    {
      message: 'Nome da empresa é obrigatório',
      path: ['empresa'],
    }
  )

export type FormCreateProprietariosFormData = z.infer<typeof FormCreateProprietariosSchema>

export const FormPutProprietariosSchema = z
  .object({
    id: z.number(),
    nome: z.string().optional(),
    email: z.string().optional(),
    telefone: z.string().optional(),
    cpf: z
      .string()
      .refine((cpf) => validarCPF(cpf), {
        message: 'CPF inválido',
      })
      .optional(),
    cnpj: z.string().optional(),
    endereco: z.string().optional(),
    numero: z.string().optional(),
    cep: z.string().optional(),
    complemento: z.string().optional(),
    id_cidade: z.number().nullable().optional(),
    id_estado: z.number().nullable().optional(),
    empresa: z.string().optional(),
    tipo: z.string().optional(),
    situacao: z.number(),
    php3a_data: z.string().optional(),
  })
  .refine(
    (input) => {
      if (input.tipo === 'pf' && input.nome === '') {
        return false
      }
      return true
    },
    {
      message: 'Nome é obrigatório',
      path: ['nome'],
    }
  )
  .refine(
    (input) => {
      if (input.tipo === 'pj' && input.empresa === '') {
        return false
      }
      return true
    },
    {
      message: 'Nome da empresa é obrigatório',
      path: ['empresa'],
    }
  )

export type FormPutProprietariosFormData = z.infer<typeof FormPutProprietariosSchema>
