import {FillColor, Radius, StrokeColor, StrokeWidth} from '../../../Config'
// import { Opacity } from '../../../Config/Opacity'
// import { Size } from '../../../Config/Size'

type Props = {
  id: any
  defaultValue:any
}
export const TypeGeoJson: React.FC<Props> = ({id, defaultValue}) => {
  // console.log(defaultValue)
  return (
    <>
      <FillColor uuid={id} defaultValue={defaultValue} ></FillColor>
      {/* <Radius uuid={id} ></Radius> */}
      {/* <Opacity uuid={id} defaulValue={60}></Opacity> */}
      <StrokeColor uuid={id} defaultColor={'#333'}></StrokeColor>
      <StrokeWidth uuid={id}></StrokeWidth>
      {/* <Height uuid={id} ></Height> */}
    </>
  )
}
