import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import { produtosArray } from './util'

export function PeriodoChosen({
  setValuePeriodo,
  valuePeriodo,
  setIsPersonalizada, // Adicionando setIsPersonalizada como prop
}) {
  let array = []
  const [periodo, setPeriodo] = useState(valuePeriodo)
  console.log('valuePeriodo dentro de PeriodoChosen:', valuePeriodo);

  useEffect(() => {
    setValuePeriodo(periodo)
  }, [periodo, setValuePeriodo])

  // Mapear produtosArray para options
  produtosArray.forEach((Options) => {
    array.push({ value: Options.id_periodo, label: Options.name })
  })


  return (
    <>
      <div className='form-group'>
        <label className='col-form-label'>Tempo de uso:</label>
        <Select
          options={array}
          onChange={(selectedOption) => {
            if (selectedOption.value === 6) {
              setPeriodo(selectedOption.value)
              setIsPersonalizada(true)
            } else {
              setPeriodo(selectedOption.value)
              setIsPersonalizada(false)
            }
          }}
          name='id_produto'
          placeholder='Opções Disponíveis'
        />
      </div>
    </>
  )
}
