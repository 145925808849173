import {useMutation, useQuery} from '@apollo/client'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {
  GET_AVM_BY_ID,
  GET_AVM_LEAD,
  GET_AVM_LEAD_BY_EMPREENDIMENTO_ID,
  GET_AVM_LEAD_BY_IMOVEL_ID,
} from '../query/AVM'
import {
  TypesDeleteAVMResponse,
  TypesGetAvmLeadByEmpreendimentoIdResponse,
  TypesGetAvmLeadByImovelIdResponse,
  TypesGetAvmLeadResponse,
  TypesPutAVMResponse,
  TypesSetAVMResponse,
} from '../type/AVM'
import {DELETE_AVM, PUT_AVM, SET_AVM, SET_AVM_LEAD} from '../mutations'
import {
  setAVMFormData,
  setAVMFormDataV2,
  setAVMFormSchema,
  setAVMLeadEmpreendimentoFormData,
  setAVMLeadEmpreendimentoFormSchema,
  setAVMLeadImovelFormData,
  setAVMLeadImovelFormSchema,
  setAVMLeadInteligenciaFormData,
  setAVMLeadInteligenciaFormSchema,
} from '../formValidations'
import {watch} from 'fs'

interface QueryAVMProps {
  variables: any
}

export function QueryGetAvmLeads({variables}: QueryAVMProps) {
  const {data, loading, error} = useQuery<TypesGetAvmLeadResponse>(GET_AVM_LEAD, {
    variables: {...variables},
  })
  return {data, loading, error}
}
export function QueryGetAvmLeadInteligenciaMercado({variables}: QueryAVMProps) {
  const {data, loading, error} = useQuery<TypesGetAvmLeadResponse>(GET_AVM_LEAD, {
    variables: {...variables},
  })
  return {data, loading, error}
}

export function MutationCreateAVM() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<setAVMLeadImovelFormData>({
    resolver: zodResolver(setAVMLeadImovelFormSchema),
  })

  const [setAVMBody, {error, loading, data: DataSetAVM}] = useMutation<TypesSetAVMResponse>(
    SET_AVM_LEAD,
    {
      refetchQueries: [
        GET_AVM_BY_ID,
        GET_AVM_LEAD_BY_IMOVEL_ID,
        GET_AVM_LEAD_BY_EMPREENDIMENTO_ID,
        GET_AVM_LEAD_BY_EMPREENDIMENTO_ID,
      ],
    }
  )

  async function FormAVM(data: setAVMLeadImovelFormData) {
    await setAVMBody({
      variables: {
        data: {...data},
      },
    })
  }

  return {register, handleSubmit, FormAVM, setValue, loading, errors, error, DataSetAVM}
}

export function MutationDeleteAVM() {
  const [deleteAVM, {loading, data, error}] = useMutation<TypesDeleteAVMResponse>(DELETE_AVM, {
    refetchQueries: [GET_AVM_LEAD_BY_IMOVEL_ID, GET_AVM_LEAD_BY_EMPREENDIMENTO_ID],
  })

  async function HandleDeleteAVM({variables}: QueryAVMProps) {
    try {
      const result = await deleteAVM({
        variables: {...variables},
        refetchQueries: [GET_AVM_LEAD_BY_IMOVEL_ID, GET_AVM_LEAD_BY_EMPREENDIMENTO_ID],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteAVM, loading, error, data}
}

export function MutationCreateAVMV2() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<setAVMFormDataV2>({
    resolver: zodResolver(setAVMLeadImovelFormSchema),
  })

  const [setAVMBody, {error, loading, data: DataSetAVM}] = useMutation<TypesSetAVMResponse>(
    SET_AVM_LEAD,
    {
      refetchQueries: [
        GET_AVM_BY_ID,
        GET_AVM_LEAD_BY_IMOVEL_ID,
        GET_AVM_LEAD_BY_EMPREENDIMENTO_ID,
        GET_AVM_LEAD_BY_EMPREENDIMENTO_ID,
      ],
    }
  )

  async function FormAVM(data: setAVMFormDataV2) {
    try {
      return await setAVMBody({
        variables: {
          data: {...data},
        },
      })
    } catch (e) {
      console.error('Erro na requisição:', e.message)
      return e.message
    }
  }

  return {register, handleSubmit, FormAVM, setValue, loading, errors, error, DataSetAVM, watch}
}

export function MutationCreateAVMV3() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<setAVMLeadInteligenciaFormData>({
    resolver: zodResolver(setAVMLeadInteligenciaFormSchema),
  })

  const [setAVMBody, {error, loading, data: DataSetAVM}] = useMutation<TypesSetAVMResponse>(
    SET_AVM_LEAD,
    {
      refetchQueries: [
        GET_AVM_BY_ID,
        GET_AVM_LEAD_BY_IMOVEL_ID,
        GET_AVM_LEAD_BY_EMPREENDIMENTO_ID,
        GET_AVM_LEAD_BY_EMPREENDIMENTO_ID,
        GET_AVM_LEAD,
      ],
    }
  )

  async function FormAVM(data: setAVMLeadInteligenciaFormData) {
    try {
      return await setAVMBody({
        variables: {
          data: {...data},
        },
      })
    } catch (e) {
      console.error('Erro na requisição:', e.message)
      return e.message
    }
  }

  return {FormAVM, loading, error, DataSetAVM, watch, register, setValue, errors}
}

export function MutationDeleteAVMLeadInteligenciaMercado() {
  const [DeleteAVMLeadInteligenciaMercado, {loading, data, error}] =
    useMutation<TypesDeleteAVMResponse>(DELETE_AVM, {
      refetchQueries: [GET_AVM_LEAD],
    })
}

// IMOVEL SERVICES

export function QueryGetAvmLeadByImovelId({variables}: QueryAVMProps) {
  const {data, loading, error} = useQuery<TypesGetAvmLeadByImovelIdResponse>(
    GET_AVM_LEAD_BY_IMOVEL_ID,
    {
      variables: {...variables},
    }
  )
  return {data, loading, error}
}

export function MutationCreateAVMLeadImovel() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<setAVMFormData>({
    resolver: zodResolver(setAVMFormSchema),
  })

  const [setAVMBody, {error, loading, data: DataSetAVM}] = useMutation<TypesSetAVMResponse>(
    SET_AVM,
    {
      refetchQueries: [GET_AVM_BY_ID],
    }
  )

  async function FormAVM(data: setAVMFormData) {
    await setAVMBody({
      variables: {
        AVM: {...data},
      },
    })
  }

  return {register, handleSubmit, FormAVM, setValue, loading, errors, error, DataSetAVM}
}

// EMPREENDIMENTO SERVICES

export function QueryGetAvmLeadByEmpreendimentoId({variables}: QueryAVMProps) {
  const {data, loading, error} = useQuery<TypesGetAvmLeadByEmpreendimentoIdResponse>(
    GET_AVM_LEAD_BY_EMPREENDIMENTO_ID,
    {
      variables: {...variables},
      fetchPolicy: 'no-cache',
    }
  )
  return {data, loading, error}
}

export function MutationCreateAVMLeadEmpreendimento() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<setAVMLeadEmpreendimentoFormData>({
    resolver: zodResolver(setAVMLeadEmpreendimentoFormSchema),
  })

  const [setAVMBody, {error, loading, data: DataSetAVM}] = useMutation<TypesSetAVMResponse>(
    SET_AVM_LEAD,
    {
      refetchQueries: [GET_AVM_BY_ID, GET_AVM_LEAD_BY_EMPREENDIMENTO_ID],
    }
  )

  async function FormAVM(data: setAVMLeadEmpreendimentoFormData) {
    await setAVMBody({
      variables: {
        AVM: {...data},
      },
      refetchQueries: [GET_AVM_BY_ID, GET_AVM_LEAD_BY_EMPREENDIMENTO_ID],
    })
  }

  return {register, handleSubmit, FormAVM, setValue, loading, errors, error, DataSetAVM}
}
