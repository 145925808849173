import {useState} from 'react'
import {Buttons} from './ButtonsUsuarios'
import {QueryGetUsuarios} from '../../../graphql/services/Usuarios'
import PaginationComponent from '../../../../components/UI/PaginationComponents'
import LoadingTable from '../../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../../components/UI/CardTables/ErrorTable'
import {CardTables} from '../../../../components/UI/CardTables'
import { DateTimToView } from '../../../../components/util'

type Props = {
  filtro: any[]
}

export function TableAdminUsuarios({filtro}: Props) {
  const [paginacao, setPaginacao] = useState({pagina: 0, quantidade: 10})

  const {data, loading, error} = QueryGetUsuarios({
    variables: {
      pagination: {
        pagina: paginacao.pagina,
        quantidade: paginacao.quantidade,
      },
      filtro: {
        fields: filtro,
      },
    },
  })

  return (
    <>
      <CardTables
        headerTitle={'Usuários'}
        headerSubtTitle={`Listando ${
          data ? data.GetUsuarios.pageInfo.totalItems : 0
        } usuários registrados.`}
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetUsuarios.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({pagina: pagina, quantidade: quantidade})
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='w-50px'>ID</th>
                <th className='min-w-140px'>Nome de Usuário</th>
                <th className='min-w-180px'>Email</th>
                <th className='min-w-100px'>Telefone</th>
                <th className='min-w-100px'>Cadastrado em</th>
                <th className='min-w-100px text-end'>Ação</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetUsuarios.result.length > 0 ? (
                  data.GetUsuarios.result.map((response) => {
                    return (
                      <tr key={response.id}>
                        <td>{response.id}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {response.name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{response.email}</td>
                        <td>{response.telefone}</td>
                        <td>{DateTimToView(response.created_at)}</td>
                        <td className='text-center'>
                          <Buttons id={response.id} />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
    </>
  )
}
