import { Buttons } from './ButtonsCupons'
import { QueryGetCupons } from '../../../graphql'
import { Loader } from '../../../../components/UI/Loader/Loader'
import { useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { CreateForm } from './CreateForm'
import { EditForm } from './EditForm'
import { DateTimToView } from '../../../../components/util'
import PaginationComponent from '../../../../components/UI/PaginationComponents'
import { format, isValid } from 'date-fns'
import { CardTables } from '../../../../components/UI/CardTables'
import LoadingTable from '../../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../../components/UI/CardTables/ErrorTable'

export function TableAdminCupons() {
  const [pagiancao, setPaginacao] = useState({ pagina: 0, quantidade: 10 })
  const { data, loading, error } = QueryGetCupons({
    variables: {
      pagination: {
        pagina: pagiancao.pagina,
        quantidade: pagiancao.quantidade
      },
    }
  })
  const [showCreate, setShowCreate] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [id, setId] = useState(null)

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }
  function CloseModalEdit() {
    setShowEdit(false)
    setId(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setId(id)
  }


  return (
    <>
      <CardTables
        headerTitle={'Cupons'}
        headerSubtTitle={`Listando ${data ? data.GetCupom.pageInfo.totalItems : 0} cupons registrados.`}
        headerToolbar={
          <button
            className='btn btn-sm btn-light-primary'
            onClick={(e) => {
              e.preventDefault()
              OpenModalCreate(null)
            }}
          >
            <BiPlus className='fs-3'></BiPlus>
            Novo Cupom
          </button>
        }
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetCupom.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({ pagina: pagina, quantidade: quantidade })
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-150px'>Código</th>
                <th className='min-w-150px'>Valor do Desconto</th>
                <th className='min-w-140px'>Data de expiração</th>
                <th className='min-w-120px'>Utilizado</th>
                <th className='min-w-120px'>Tipo</th>
                <th className='min-w-100px text-end'>Ação</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetCupom.result.length > 0 ? (
                  data.GetCupom.result.map((response) => {
                    const TipoVerificado = response.tipo === '1' ? 'Uso unico' : 'Data de expiração'
                    const UtilizacaoVerificado = response.usado === '2' ? 'Não utilizado' : 'Usado'
                    const dataExpiracao = new Date(response.data_expiracao);
                    const dataLocal = new Date(dataExpiracao.getTime() + dataExpiracao.getTimezoneOffset() * 60 * 1000);

                    return (
                      <tr key={response.id}>
                        <td>{response.id}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {response.codigo}
                              </span>

                            </div>
                          </div>
                        </td>
                        <td>{response.desconto ? response.desconto + '%' : 'Valor Invalido'}</td>
                        <td>{response.data_expiracao ? format(dataLocal, 'dd/MM/yyyy') : 'Data Inválida'}</td>
                        <td>{UtilizacaoVerificado}</td>
                        <td>
                          {TipoVerificado}
                        </td>
                        <td>
                          <Buttons setIdEdit={OpenModalEdit} id={response.id} />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
      {showEdit ? <EditForm id={id} show={showEdit} closeModal={CloseModalEdit}></EditForm> : <></>}
      {showCreate ? (
        <CreateForm closeModal={CloseModalCreate} show={showCreate}></CreateForm>
      ) : (
        <></>
      )}
    </>
  )
}
