import {useEffect, useState} from 'react'
import {AsideDefault} from '../../../../_metronic/layout/components/aside/AsideDefault'
import {HeaderWrapperFluid} from '../../../../_metronic/layout/components/header/HeaderWrapperFluid'
import {Footer} from '../../../../_metronic/layout/components/Footer'
import {Link, useHistory, useParams} from 'react-router-dom'
import {MutationPutServico, QueryGetServicoByID} from '../../graphql'
import {Loader} from '../../../components/UI/Loader/Loader'
import {PacotesChosen} from './partials/ChosenPacotes'
import {CategoriasChosen} from './partials/ChosenCategoria'
import Swal from 'sweetalert2'
import {TabsContainer} from './partials/TabsContainer'

export function AdminEditServicos() {
  const {id: idService} = useParams<{id: string}>()
  const [valuePacote, setValuePacote] = useState<number[]>([])
  const [valueCategoria, setValueCategoria] = useState<number[]>([])
  const {handleSubmit, register, FormServico, setValue} = MutationPutServico()

  const onSubmit = async (data) => {
    FormServico(data)
      .then((result) => {
        Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }
  const {data} = QueryGetServicoByID({variables: {getServicoId: parseInt(idService)}})

  useEffect(() => {
    if (
      data &&
      data.GetServico &&
      data.GetServico.servico_categoria &&
      data.GetServico.servico_categoria.length > 0 &&
      data.GetServico.servico_pacote &&
      data.GetServico.servico_pacote.length > 0
    ) {
      const pacotes = data.GetServico.servico_pacote
        .filter((item) => item && item.pacote)
        .map((item) => item.pacote.id)

      const categorias = data.GetServico.servico_categoria
        .filter((item) => item && item.id_categoria !== undefined)
        .map((item) => item.id_categoria)

      setValueCategoria(categorias)
      setValuePacote(pacotes)
    }
  }, [data])

  console.log(valuePacote)
  console.log(valueCategoria)

  if (!data) {
    return (
      <>
        <AsideDefault />
        <HeaderWrapperFluid />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <div id='kt_content_container' className='container-fluid'>
                <div
                  className='py-6  d-flex align-items-center justify-content-between'
                  id='kt_header_container'
                >
                  <div
                    className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                    data-kt-swapper='true'
                    data-kt-swapper-mode='prepend'
                    data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                  >
                    <h1 className='text-dark fw-bold my-0 fs-2'>Editar</h1>
                    <ul className='breadcrumb fw-semibold fs-base my-1'>
                      <li className='breadcrumb-item text-muted'>
                        <a href='/metronic8/demo7/../demo7/index.html' className='text-muted'>
                          Servicos
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                  <div className='card p-6'>
                    {/*begin::Card header*/}
                    <div className='border-0'>
                      {/* begin::Row */}
                      <div className='row gy-5 gx-xl-8'>
                        <div className='col-xl-12'>
                          <Loader text='Carregando...' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    )
  }

  // eslint-disable-next-line no-template-curly-in-string
  let clausula_limit = ' ${clausula_limit}'
  // eslint-disable-next-line no-template-curly-in-string
  let clausula_distance = ' ${clausula_distance}'
  // eslint-disable-next-line no-template-curly-in-string
  let clausula_geom = ' ${clausula_geom}'
  // eslint-disable-next-line no-template-curly-in-string
  let clausula_intersect = ' ${clausula_intersect}'

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <div
                className='py-6  d-flex align-items-center justify-content-between'
                id='kt_header_container'
              >
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  data-kt-swapper='true'
                  data-kt-swapper-mode='prepend'
                  data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Editar</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href='/metronic8/demo7/../demo7/index.html' className='text-muted'>
                        Servicos
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                <div className='card p-8'>
                  {/*begin::Card header*/}
                  <div className='border-0'>
                    <form onSubmit={handleSubmit(onSubmit)} className='row'>
                      <div className='row'>
                        <input
                          type='text'
                          defaultValue={idService}
                          {...register('data.id', {required: true, valueAsNumber: true})}
                          hidden
                        />
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <div className='form-group col-md-12 form-line '>
                            <label className='col-form-label'>Nome</label>
                            <input
                              className='form-control form-control-solid'
                              name='nome'
                              type='text'
                              id='nome'
                              placeholder='Nome Completo'
                              defaultValue={data.GetServico.nome}
                              {...register('data.nome')}
                            />
                          </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <PacotesChosen
                            valuePacote={valuePacote}
                            setValuePacote={(value: any) => {
                              setValuePacote(value)
                              setValue('idPacotes', value)
                            }}
                          />
                        </div>

                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <CategoriasChosen
                            valueCategoria={valueCategoria}
                            setValueCategoria={(value: any) => {
                              setValueCategoria(value)
                              setValue('idCategorias', value)
                            }}
                          />
                        </div>
                      </div>
                      <div className='row  pt-4'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Custo</label>
                          <input
                            defaultValue={
                              data.GetServico.custo === null ? 0 : data.GetServico.custo
                            }
                            className='form-control form-control-solid'
                            name='custo_servico'
                            type='number'
                            id='custo_servico'
                            placeholder='Custo'
                            {...register('data.custo', {valueAsNumber: true})}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Vizualização</label>
                          <select
                            defaultValue={data.GetServico.modo_visualizacao}
                            className='form-select form-select-solid '
                            id='select-vizualizacao'
                            name='vizualizacao'
                          >
                            <option value='PUBLICO'>Público</option>
                            <option value='PUBLICO_LOGIN'>Publico Login</option>
                            <option value='PAGO'>Pago</option>
                          </select>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>URL da API</label>
                          <input
                            defaultValue={data.GetServico.url_api}
                            className='form-control form-control-solid'
                            name='url_api'
                            type='text'
                            id='url_api'
                            placeholder='URL da API'
                            {...register('data.url_api')}
                          />
                        </div>
                      </div>
                      <div className='row  pt-4'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Nome da variável na sessão</label>
                          <input
                            defaultValue={data.GetServico.session_name}
                            {...register('data.session_name')}
                            className='form-control form-control-solid'
                            name='session_name'
                            type='text'
                            id='session_name'
                            placeholder='Session name'
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Data de Atualização(dd/mm/aaaa)</label>
                          <input
                            defaultValue={new Date(data.GetServico.updated_at).toLocaleDateString(
                              'pt-BR'
                            )}
                            className='form-control form-control-solid'
                            name='atualizacao_url'
                            type='date'
                            id='atualizacao_url'
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>URL do Sistema</label>
                          <input
                            className='form-control form-control-solid'
                            name='url_sistema'
                            type='text'
                            id='url_sistema'
                            placeholder='URL do Sistema'
                            defaultValue={
                              data.GetServico.url_sistema === null
                                ? ''
                                : data.GetServico.url_sistema
                            }
                            {...register('data.url_sistema')}
                          />
                        </div>
                      </div>

                      <div className='row  pt-4'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Filtro Isócota</label>
                          <select
                            {...register('data.filtro_isocota', {valueAsNumber: true})}
                            defaultValue={data.GetServico.filtro_isocota}
                            className='form-select form-select-solid '
                            id='select-sync_imovel'
                            name='sync_isocota'
                          >
                            <option value='0'>Não</option>
                            <option value='1'>Sim</option>
                          </select>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Filtro Isócrona</label>
                          <select
                            {...register('data.filtro_isocrona', {valueAsNumber: true})}
                            defaultValue={data.GetServico.filtro_isocrona}
                            className='form-select form-select-solid '
                            id='select-sync_imovel'
                            name='sync_isocota'
                          >
                            <option value='0'>Não</option>
                            <option value='1'>Sim</option>
                          </select>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Filtro Limit</label>
                          <select
                            {...register('data.filtro_limit', {valueAsNumber: true})}
                            defaultValue={data.GetServico.filtro_limit}
                            className='form-select form-select-solid '
                            id='select-sync_imovel'
                            name='sync_isocota'
                          >
                            <option value='0'>Não</option>
                            <option value='1'>Sim</option>
                          </select>
                        </div>
                      </div>
                      <div className='row  pt-4'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Limite(Os "X" mais próximos )</label>
                          <input
                            className='form-control form-control-solid'
                            name='limite'
                            type='number'
                            id='limite'
                            defaultValue={
                              data.GetServico.limite === null ? 0 : data.GetServico.limite
                            }
                            {...register('data.limite', {valueAsNumber: true})}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Ordem</label>
                          <input
                            defaultValue={
                              data.GetServico.ordem === null ? 0 : data.GetServico.ordem
                            }
                            className='form-control form-control-solid'
                            name='ordem'
                            type='number'
                            id='ordem'
                            {...register('data.ordem', {valueAsNumber: true})}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Distância em raio</label>
                          <input
                            className='form-control form-control-solid'
                            name='distancia'
                            type='number'
                            id='distancia'
                            defaultValue={
                              data.GetServico.distancia === null ? 0 : data.GetServico.distancia
                            }
                            {...register('data.distancia', {valueAsNumber: true})}
                          />
                        </div>
                      </div>
                      <div className='row  pt-4'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Recorrência</label>
                          <input
                            defaultValue={
                              data.GetServico.recorrencia === null ? 0 : data.GetServico.recorrencia
                            }
                            className='form-control form-control-solid'
                            name='recorrencia'
                            type='number'
                            id='recorrencia'
                            {...register('data.recorrencia', {valueAsNumber: true})}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Situação</label>
                          <select
                            {...register('data.situacao', {valueAsNumber: true})}
                            defaultValue={data.GetServico.situacao}
                            className='form-select form-select-solid '
                            id='select-situacao'
                            name='situacao'
                          >
                            <option value='0'>Não Disponivel</option>
                            <option value='1'>Disponivel</option>
                          </select>
                        </div>
                      </div>
                      <div className='form-group col-md-12 form-line pt-5'>
                        <label className='col-form-label'>Descrição</label>
                        <textarea
                          {...register('data.descricao')}
                          defaultValue={
                            data.GetServico.descricao === null ? '' : data.GetServico.descricao
                          }
                          className='form-control'
                          style={{resize: 'none'}}
                          name='descricao'
                          id='descricao'
                        ></textarea>
                      </div>
                      <div className='w-100 all-end mt-4'>
                        <Link
                          to='/backoffice/servicos'
                          type='button'
                          className='btn btn-danger me-4'
                        >
                          Cancelar
                        </Link>
                        <button className='btn btn-success'>Salvar</button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className=''>
                  <TabsContainer
                    clausula_limit={clausula_limit}
                    clausula_distance={clausula_distance}
                    clausula_geom={clausula_geom}
                    clausula_intersect={clausula_intersect}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
