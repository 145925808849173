import {Modal, Tab, Tabs} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {useState} from 'react'
import {FormInputAndLabel} from '../../../../../../components/UI/Inputs/FormInputAndLabel'
import {FormInputMaskAndLabel} from '../../../../../../components/UI/Inputs/FormInputMaskAndLabel'
import {GetCorretoresChosen} from '../../../../../../components/UI/Chosens/GetCorretoresChosen'
import {
  MutationSetImovelCorretores,
  MutationSetImovelCorretoresByID,
} from '../../../../../../graphql/services/ImovelCorretores'
import {useParams} from 'react-router-dom'
import {MutationSetLogAcessoRecurso} from '../../../../../../graphql/services/LogAcesso'

interface corretoresProps {
  closeModal: any
  show: boolean
}

export function CreateForm({closeModal, show}: corretoresProps) {
  const {id} = useParams<{id: string}>()
  const idTerreno = parseInt(id)
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  const {
    FormImovelCorretor,
    setValue,
    handleSubmit: HabdleSubmitImovelCorretor,
    register: RegisterImovelCorretor,
  } = MutationSetImovelCorretores()

  const {
    errors: ErrorsImovelCorretorByID,
    handleSubmit: HabdleSubmitImovelCorretorByID,
    loading: LoadingImovelCorretorByID,
    register: RegisterImovelCorretorByID,
    FormImovelCorretorByID,
  } = MutationSetImovelCorretoresByID()

  const [valueCorretor, setValueCorretor] = useState(0)

  const onSubmit = async (data) => {
    FormImovelCorretor(data)
      .then((result) => {
        FormSetLogAcessoRecurso({
          ferramenta: 'meus-imoveis',
          funcionalidade: 'corretores',
          action: 'create',
          url: '/meus-imoveis/details/' + idTerreno + '#corretores',
        })
        Swal.fire('Sucesso!', 'Corretor com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  const onSubmitByID = async (data) => {
    FormImovelCorretorByID(data)
      .then((result) => {
        FormSetLogAcessoRecurso({
          ferramenta: 'meus-imoveis',
          funcionalidade: 'corretores',
          action: 'create',
          url: '/meus-imoveis/details/' + idTerreno + '#corretores',
        })
        Swal.fire('Sucesso!', 'Corretor associado com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  return (
    <Modal centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Associar Corretor:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey='search' id='uncontrolled-tab-example' className='mb-3'>
          <Tab eventKey='search' title='Buscar'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group'>
                  <form onSubmit={HabdleSubmitImovelCorretor(onSubmit)}>
                    <input
                      className='form-control form-control-solid'
                      placeholder='id'
                      hidden={true}
                      defaultValue={idTerreno}
                      {...RegisterImovelCorretor('id_terreno', {
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                    <GetCorretoresChosen
                      valueCorretor={valueCorretor}
                      SetValueCorretor={(value: number) => {
                        setValue('id_corretor', value)
                        setValueCorretor(value)
                      }}
                    />
                    <div className='col-md-12 d-flex mrt-1' style={{justifyContent: 'flex-end'}}>
                      <button
                        className='btn btn-danger pull-right'
                        type='button'
                        data-bs-dismiss='modal'
                      >
                        Fechar
                      </button>
                      <button
                        type='submit'
                        className='btn btn-success mx-2'
                        id='btnIncluirCorretor'
                      >
                        Associar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey='novo' title='Incluir Novo'>
            <form onSubmit={HabdleSubmitImovelCorretorByID(onSubmitByID)}>
              <input
                className='form-control form-control-solid'
                placeholder='id'
                hidden={true}
                defaultValue={idTerreno}
                {...RegisterImovelCorretorByID('idImovel', {required: true, valueAsNumber: true})}
              />
              <div className='row'>
                <div className='col-md-12'>
                  <div className='mb-6'>
                    <FormInputAndLabel
                      label='Nome Completo'
                      className='form-control required form-control-solid'
                      placeholder='Nome Completo'
                      register={RegisterImovelCorretorByID('nome')}
                      error={ErrorsImovelCorretorByID ? ErrorsImovelCorretorByID.nome?.message : ''}
                    />
                  </div>
                </div>

                <div className='col-md-12'>
                  <div className='mb-6'>
                    <FormInputAndLabel
                      label='Email'
                      className='form-control required form-control-solid'
                      placeholder='Ex: joao@email.com'
                      register={RegisterImovelCorretorByID('email')}
                      error={
                        ErrorsImovelCorretorByID ? ErrorsImovelCorretorByID.email?.message : ''
                      }
                    />
                  </div>
                </div>

                <div className='col-md-12'>
                  <div className='mb-6'>
                    <FormInputMaskAndLabel
                      label='Telefone:'
                      maskChar=' '
                      mask='(99) 99999-9999'
                      className='form-control form-control-solid'
                      placeholder='Ex: (00) 00000-0000'
                      register={RegisterImovelCorretorByID('telefone')}
                      error={
                        ErrorsImovelCorretorByID ? ErrorsImovelCorretorByID.telefone?.message : ''
                      }
                    />
                  </div>
                </div>

                <div className='col-md-12'>
                  <div className='mb-6'>
                    <FormInputAndLabel
                      label='Empresa'
                      className='form-control required form-control-solid'
                      placeholder='Empresa'
                      register={RegisterImovelCorretorByID('empresa')}
                      error={
                        ErrorsImovelCorretorByID ? ErrorsImovelCorretorByID.empresa?.message : ''
                      }
                    />
                  </div>
                </div>

                <div className='col-md-12 py-6 d-flex' style={{justifyContent: 'flex-end'}}>
                  <button
                    className='btn btnSecondary pull-right '
                    type='button'
                    onClick={closeModal}
                  >
                    Fechar
                  </button>
                  <button
                    disabled={LoadingImovelCorretorByID}
                    type='submit'
                    className='btn btn-success mx-2'
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </form>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  )
}
