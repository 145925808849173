import {MapView} from '../MapView'
import {useContext} from 'react'
import {DataContext} from '../../context/DataContext'
import {getLayer, getLayers} from '../MapLayers/Layer'
import {FilterContext} from '../../context/FilterContext'
import {AllGeoJSON} from '@turf/turf'
import {Legenda} from '../Legenda'
import CheckServices from '../CheckServices'
import {HeaderPerformance} from '../Common/HeaderPerformance'

type Props = {
  label?: string
}

export function PerformanceSocioDemografico({label}: Props) {
  const {data} = useContext(DataContext)
  const {geometriaSelecionada, endereco} = useContext(FilterContext)

  if (!data) {
    return <>Carregando...</>
  }
  if (!geometriaSelecionada) {
    return <></>
  }

  let json = geometriaSelecionada.geometry as AllGeoJSON
  const layersList = getLayers(['view_ibge_explorer_renda_mensal_domiciliar'], json)
  const layerConfig = getLayer('view_ibge_explorer_renda_mensal_domiciliar')
  let center = {
    lat: -46.6,
    lng: -23.6,
  }
  if (endereco) {
    center = {lat: endereco.latitude, lng: endereco.longitude}
  }

  console.log(geometriaSelecionada)
  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10'>
        <HeaderPerformance titulo={'Sóciodemográfico'}></HeaderPerformance>
        <div className='content pt-10'>
          <div className='row'>
            <div className='col-12'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <div className='map-painel d-flex w-100'>
                  <div className='mapa col-8'>
                    <MapView
                      data={data}
                      layer={[layersList]}
                      height={'650px'}
                      zoom={15}
                      center={center}
                      geometria={geometriaSelecionada}
                    >
                      <Legenda style={layerConfig.style} title={'Renda'} visivel={true}></Legenda>
                    </MapView>
                  </div>
                  <div className='painel-lateral col-4 px-5'>
                    <CheckServices onChange={undefined}></CheckServices>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
