import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {Dropdown1} from '../../../../../../../../_metronic/partials'
import {getCSSVariableValue} from '../../../../../../../../_metronic/assets/ts/_utils'

type Props = {
  chartColor: string
  chartHeight: string
  valor:number
}

export const ChartInfoBox: React.FC<Props> = ({valor,  chartColor, chartHeight}) => {

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, valor))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div className='card'>
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Vendas do empreendimento</span>

          <span className='text-muted fw-semibold fs-7'>Percentual de vendas de todo o empreendimento</span>
        </h3>
      </div>
      <div className='card-body p-0 d-flex flex-column'>
        <div className=' d-flex flex-column'>
          <div className='flex-grow-1'>
            <div ref={chartRef} className='mixed-widget-4-chart'></div>
          </div>
          <div className='pt-5'>
            <p className='text-center fs-6 pb-5 '>
              <span className='badge badge-light-danger fs-8'>Notes:</span>&nbsp; Percentual de
              vendas de todas as tipologias
            </p>
            {/* <a href='#' className={`btn btn-${chartColor} w-100 py-3 btn-info`}>
              Ver mais detalhes
            </a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, valor:number): ApexOptions => {
  const baseColor = getCSSVariableValue('--kt-' + chartColor)
  const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--kt-gray-700')

  return {
    series: [valor],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: ['#0a8527'],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}
