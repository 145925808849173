import { z } from "zod"

export const GrupoServicoCreateFormSchema = z.object({
    servicosId:  z.array(z.unknown()),
    name: z.string().nonempty("O nome do grupo é obrigatório."),
})

export type GrupoServicoCreateFormData = z.infer<typeof GrupoServicoCreateFormSchema>


export const GrupoServicoUpdateFormSchema = z.object({
    id: z.number(),
    servicosId:  z.array(z.unknown()),
    name: z.string().nonempty("O nome do grupo é obrigatório."),
})

export type GrupoServicoUpdateFormData = z.infer<typeof GrupoServicoUpdateFormSchema>