import {Text, View, StyleSheet, Image as ImagePDF} from '@react-pdf/renderer'
import {useEffect, useState} from 'react'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row'
  },
  row: {
    marginTop: 10,
    flexDirection: 'row',
    flexGrow: 1,
  },
  col: {
    flexDirection: 'row',
    width: 100,
    alignItems: 'flex-end',
  },
  space: {
    paddingBottom: 2,
  },
  h1: {
    fontSize: 18,
  },
  h2: {
    fontSize: 16,
  },
  h3: {
    fontSize: 14,
  },
  h4: {
    fontSize: 12,
  },
  h5: {
    fontSize: 10,
  },
  h6: {
    fontSize: 9,
  },
  p: {
    fontSize: 9,
    width: 500,
    flexDirection: 'column',
    flexGrow: 1,
    textAlign: 'justify'
  },
  img: {
    width: 150,
    textAlign: 'right',
  },
  logo: {
    width: 150,
    height: 'auto',
  },
  detalhe: {
    width: 500,
  },
  aviso: {
    color: '#ff0000',
    fontWeight: 'bold',
    fontSize: 12,
  },
})

export const HeaderDetalhes = ({data,map}) => {
  const [qrcode, setQrcode] = useState('')

  useEffect(() => {
    async function generateImg() {
      function imgToBase64() {
        const svg = document.getElementById('QRCode')
        const svgData = new XMLSerializer().serializeToString(svg)
        const image = new Image(100, 100)
        image.addEventListener('load', () => {
          const canvas = document.createElement('canvas')
          canvas.setAttribute('width', '100px')
          canvas.setAttribute('height', '100px')
          const context = canvas.getContext('2d')
          context.drawImage(image, 0, 0, 100, 100)
          const dataUrl = canvas.toDataURL('image/png')
          setQrcode(dataUrl)
        })
        image.src = `data:image/svg+xml;base64,${btoa(svgData)}`
        return image.src
      }
      imgToBase64()
    }
    generateImg()
  }, [qrcode])

  if (!data) {
    return <>'Carregando'</>
  }

  if (!qrcode) {
    return <>'Carregando'</>
  }
  return (
    <View>
      <View style={styles.row}>
        <View style={styles.detalhe}>
          <Text style={styles.h1}>Terreno Nº {data.GetImovel.id}</Text>
          <Text style={styles.h5}>Data solicitação: {data.GetImovel.data_cadastro}</Text>
          <Text style={styles.h5}>
            Endereço: {data.GetImovel.endereco}, {data.GetImovel.numero}
          </Text>
          {data.GetImovel.cep ? <Text style={styles.h5}>CEP: {data.GetImovel.cep}</Text> : ''}
          {data.GetImovel.bairro ? (
            <Text style={styles.h5}>Bairro: {data.GetImovel.bairro}</Text>
          ) : (
            ''
          )}
          {data.GetImovel.cidade ? (
            <Text style={styles.h5}>Cidade: {data.GetImovel.cidade}</Text>
          ) : (
            ''
          )}
          {data.GetImovel.estado ? (
            <Text style={styles.h5}>Estado: {data.GetImovel.estado}</Text>
          ) : (
            ''
          )}
          {data.GetImovel.numero_contribuinte ? (
            <Text style={styles.h5}>Número Contribuinte: {data.GetImovel.numero_contribuinte}</Text>
          ) : (
            ''
          )}
        </View>
        <View style={styles.col}>
          <ImagePDF src={qrcode} style={{width: '100px'}}></ImagePDF>
        </View>
      </View>
      <ImagePDF style={{paddingTop:10}} src={`${map}`}/>     
      <View style={styles.row} debug={false}>
        <Text style={styles.aviso}>AVISO</Text>
      </View>

    
      <View style={styles.row} debug={false}>
        <Text style={styles.p} wrap={true}>
          As informações contidas neste relatório foram elaboradas com base em informações públicas,
          disponibilizadas nos respectivos órgãos públicos. A obtenção desta ficha não substitiu as
          diligências normalmente necessárias para fins de compra e venda ou exploração comercial do
          imóvel. Considere que podem existir modificações nos imóveis, objeto da pesquisa, e que
          referidas modificações podem estar pendentes de regularização perante os órgãos públicos.
          A Urbit não assume qualquer responsabilidade ou outorga qualquer garantia de adequação ao
          fim pretendido pelo Cliente.
        </Text>
      </View>
    </View>
  )
}
