import React, { createContext, useContext, useState } from 'react';

interface Market {
  id: string;
  nome: string;
  update_at: Date;
  isFavorite: boolean;
  Nota: number;
  descricao: string;
  image: string;
  submarket?: SubMarkets[];
}

interface SubMarkets {
  id: string;
  nome: string;
  update_at: Date;
  isFavorite: boolean;
  Nota: number;
  descricao: string;
  image: string;
  submarket?: SubMarkets[];
}

interface MarketContextData {
  currentMarket: Market | null;
  marketHistory: Market[];
  overview: boolean;
  goToSubmarket: (submarket: SubMarkets) => void;
  goBack: () => void;
}

const MarketContext = createContext<MarketContextData | null>(null);

export const useMarket = () => {
  const context = useContext(MarketContext);
  if (!context) {
    throw new Error('useMarket must be used within a MarketProvider');
  }
  return context;
};

const MarketProvider: React.FC = ({ children }) => {
  const [currentMarket, setCurrentMarket] = useState<Market | null>(null);
  const [marketHistory, setMarketHistory] = useState<Market[]>([]);
  const [overview, setOverview] = useState<boolean>(false); // Estado inicial true para mostrar a visão geral
  console.log(marketHistory);

  const goToSubmarket = (submarket: SubMarkets) => {
    if (currentMarket) {
      setMarketHistory([...marketHistory, currentMarket]);
    }
    setCurrentMarket(submarket);
    setOverview(true)
  };

  const goBack = () => {
    if (marketHistory.length > 0) {
      const previousMarkets = [...marketHistory];
      const previousMarket = previousMarkets.pop();
      setCurrentMarket(previousMarket || null);
      setMarketHistory(previousMarkets);
    } else {
      setOverview(false);
    }
  };

  return (
    <MarketContext.Provider value={{ currentMarket, marketHistory, overview, goToSubmarket, goBack }}>
      {children}
    </MarketContext.Provider>
  );
};

export default MarketProvider;
