import { gql } from "@apollo/client";

export const GET_SERVICO_PACOTE = gql`
query GetServicoPacote($idMunicipio: Float) {
  GetServicoPacote(idMunicipio: $idMunicipio) {
    id
    nome
    id_agrupamento
    agrupamento_nome
    agrupamento_descricao
    servico {
      nome
      id
      url_sistema
      url_api
      display_config
      visualizacao
      descricao
      distancia
    }
  }
}
`