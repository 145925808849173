import React, {useState} from 'react'
import {Explorer} from '../../../Map/Explorer'
import {TableMeusImoveis} from './TableMeusImoveis'
import {BiChevronRight} from 'react-icons/bi'
import {TableEmpreendimentos} from './TableEmpreendimentos'

export const CamadasCliente: React.FC = () => {
  const [local, setLocal] = useState('')
  function addMeusLocais(data2) {
    Explorer.addMarkerGeoJson(data2, 'Meus Locais', 'Meus Locais')
  }

  let href = '#'
  return (
    <>
      <div className='row'>
        <div className='col-md-3 pe-5'>
          <div className='card card-flush h-lg-50'>
            <div className='card-header pt-5 p-0'>
              <h3 className='card-title text-gray-800 fw-bold'>Meus Locais</h3>
            </div>

            <div className='card-body pt-5 p-0'>
              <div className='d-flex flex-stack'>
                <a
                  href={href}
                  className='text-primary text-gray-700 fs-6 me-2'
                  onClick={() => setLocal('meuslocais')}
                >
                  <BiChevronRight className='fs-2 aling-center text-gray-500'></BiChevronRight> Meus
                  Locais
                </a>
              </div>

              <div className='separator separator-dashed my-3'></div>

              <div className='d-flex flex-stack'>
                <a
                  href={href}
                  className='text-primary text-gray-700 fs-6 me-2'
                  onClick={() => setLocal('empreendimentos')}
                >
                  <BiChevronRight className='fs-2 aling-center text-gray-500'></BiChevronRight>Meus
                  Empreendimentos
                </a>
              </div>

              <div className='separator separator-dashed my-3'></div>
            </div>
          </div>
        </div>
        <div className='col-md-9 pt-10  px-10 ' style={{borderLeft: '1px solid #e4eaec'}}>
          {local === '' || local === 'meuslocais' ? (
            <TableMeusImoveis addMeusLocais={addMeusLocais}></TableMeusImoveis>
          ) : (
            <></>
          )}
          {local === 'empreendimentos' ? <TableEmpreendimentos></TableEmpreendimentos> : ''}
        </div>
      </div>
    </>
  )
}
