import Swal from 'sweetalert2'
import {Loader} from '../../../../components/UI/Loader/Loader'
import {
  MutationDeleteImovelProprietarios,
  QueryGetImovelProprietarios,
} from '../../../../graphql/services'
import {BiPlus} from 'react-icons/bi'
import {useEffect, useState} from 'react'
import {Row} from './Proprietarios/Row'
import {CreateForm} from '../../../pessoas/components/CreateForm'
import {ImovelResponse} from '../../../../graphql'

type Props = {
  idImovel: number
  dataImovel: any
}
export const MeusImoveisProprietarios: React.FC<Props> = ({idImovel, dataImovel}) => {
  // const [updateList, setUpdateList] = useState(false)
  const {HandleDeleteProprietarios, loading} = MutationDeleteImovelProprietarios()
  const [id, setId] = useState(null)
  const [showCreate, setShowCreate] = useState(false)
  const [dataModal, setDataModal] = useState({})
 

  useEffect(() => {
    setDataModal({
      idTerreno: idImovel ? idImovel : '',
      cep: dataImovel.cep ? dataImovel.cep : '',
      endereco: dataImovel.endereco ? dataImovel.endereco : '',
      number: dataImovel.numero ? dataImovel.numero : null,
      name: dataImovel.nome_contribuinte ? dataImovel.nome_contribuinte : null,
    })
  }, [dataImovel, idImovel])

  // console.log(updateList)
  const {data, refetch} = QueryGetImovelProprietarios({variables: {idImovel: idImovel}})

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }

  function clickRow(idEmpreendimentoImovel) {
    if (document.getElementById(`detalhes_${idEmpreendimentoImovel}`).classList.contains('hide')) {
      document.getElementById(`detalhes_${idEmpreendimentoImovel}`).classList.remove('hide')
    } else {
      document.getElementById(`detalhes_${idEmpreendimentoImovel}`).classList.add('hide')
    }
  }

  if (loading) {
    Swal.fire('Enviando Informações...', '')
    Swal.showLoading()
  }

  if (!data) {
    return (
      <>
        <div className='card-header'>
          <h3 className='card-title'>Proprietários</h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Clique para adicionar o proprietário'
          >
            <button className='btn btn-sm btn-light-primary'>
              <BiPlus className='fs-3'></BiPlus>
              Novo Proprietário
            </button>
          </div>
        </div>
        <div className='table-responsive'>
          <table className='table table-striped table-row-dashed  gs-6 gy-4 table-row-bordered  table-hover table-row-gray-300 align-middle'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-50px'>Infos</th>
                <th className='min-w-150px'>Nome</th>
                <th className='min-w-150px'>E-mail</th>
                <th className='min-w-100px text-end'>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={4}>
                  <Loader text={'carregando..'}></Loader>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }

  return (
    <div className='card-body p-0 py-3'>
      <div className='table-responsive'>
        <div className='card-header'>
          <h3 className='card-title'>Proprietários Associados</h3>
          <div className='card-toolbar'>
            <button
              className='btn btn-sm btn-light-primary'
              onClick={(e) => {
                e.preventDefault()
                OpenModalCreate(null)
              }}
            >
              <BiPlus className='fs-3'></BiPlus>
              Nova Consulta
            </button>
          </div>
        </div>
        <table className='table table-striped table-row-dashed  gs-6 gy-4 table-row-bordered  table-hover table-row-gray-300 align-middle'>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-50px'>Infos</th>
              <th className='min-w-150px'>Nome</th>
              <th className='min-w-150px'>E-mail</th>
              <th className='min-w-100px text-center'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data.GetImovelProprietarios.map((response, item) => {
              return (
                <Row
                  key={item}
                  idImovel={idImovel}
                  response={response}
                  clickRow={clickRow}
                  updateList={refetch}
                ></Row>
              )
            })}
          </tbody>
        </table>
        {showCreate ? (
          <CreateForm
            origin='Empreendimentos'
            params={dataModal}
            closeModal={CloseModalCreate}
            show={showCreate}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
