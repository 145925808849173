import React from 'react'
import { useMutation } from '@apollo/client'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { MutationDeleteEmpresaUsuario } from '../../../../../../graphql'
import { EditEmpresaUsuario } from './EditEmpresaUsuario'



export function Buttons({ idAssociacao, idEmpresa }) {
  const { HandleDeleteAdminEmpresaUsuario, loading } = MutationDeleteEmpresaUsuario()

  function confirmDelete(idAssociacao: number, idEmpresa: number) {
    Swal.fire({
      title: 'Deseja remover da empresa?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Remover',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteAdminEmpresaUsuario({
          variables: {
            deleteEmpresaUsuarioAdminId: idAssociacao,
          }
        })
        if (result) {
          Swal.fire('Sucesso!', 'Usuário desassociado com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          onClick={() => {
            confirmDelete(parseInt(idAssociacao), idEmpresa)
          }}
          disabled={loading}
          className='btn btn-icon btn-danger btn-sm' >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
