import { TabAtividades } from './Tab-Atividades'
import { TabAvaliacoes } from './Tab-Avaliacoes/Tab-Avaliacoes-Imovel'
import { TabCondicaoComercial } from './Tab-Condicao-Comercial'
import { TabContribuintes } from './Tab-Contribuintes'
import { TabCorretores } from './Tab-Corretores'
import { TabDocumentos } from './Tab-Documentos'
import { TabFichaTerritorial } from './Tab-Ficha-Territorial'
import { TabFotos } from './Tab-Fotos'
import { TabInformacoesLote } from './Tab-Informacoes-Lote'
import { TabObservacoes } from './Tab-Observacoes'
import { TabProprietarios } from './Tab-Proprietarios'
import { TabPainelMercado } from './Tab-Painel-Mercado'


export function TabsImovelContainer({ data, id, parameterUrl }) {

  return (
    <>
      <div className='tab-content' id='tabContent'>
        <TabInformacoesLote param={parameterUrl} id={id} />
        <TabContribuintes param={parameterUrl} data={data} />
        <TabFichaTerritorial param={parameterUrl} data={data} />
        <TabObservacoes param={parameterUrl} />
        <TabFotos param={parameterUrl}  id={id}/>
        <TabDocumentos param={parameterUrl} />
        <TabPainelMercado param={parameterUrl} data={data} />
        <TabCondicaoComercial param={parameterUrl} />
        <TabCorretores param={parameterUrl} />
        <TabAvaliacoes param={parameterUrl} data={data}/>
        <TabProprietarios param={parameterUrl} data={data} />
        <TabAtividades param={parameterUrl} />
      </div>
    </>
  )
}
