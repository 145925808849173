import { gql } from "@apollo/client";


export const GET_GRUPOS_SERVICOS = gql`
query GetUsuarioGrupoServicosByUserId($pagination: Pagination!) {
  GetUsuarioGrupoServicosByUserId(pagination: $pagination) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      servico {
        id
        nome
        descricao
      }
      name
      id
    }
  }
}
`

export const GET_GRUPO_SERVICO_BY_ID = gql`
query GetUsuarioGrupoServico($getUsuarioGrupoServicoId: Float!) {
  GetUsuarioGrupoServico(id: $getUsuarioGrupoServicoId) {
    id
    name
    servico_id
    servico {
      id
      nome
    }
  }
}
`