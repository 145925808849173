import { z } from "zod"

export const setExplorerLayerFormSchema = z.object({
    nome: z.string().nonempty('O nome é obrigatório!'),
    url: z.string().nonempty('O url é obrigatório!'),
    schema_table: z.string().nonempty('O schema é obrigatório!'),
    type: z.string().nonempty('O type é obrigatório!'),
    source_layer: z.string().nonempty('O type é obrigatório!'),
    source: z.string().nonempty('O type é obrigatório!'),
    situacao: z.number().optional(),
    nacional: z.boolean().transform(value => { return !value ? 0 : 1 }).optional(),
    paint: z.string(),
    id_municipio: z.number().optional(),
    id_estado: z.number().optional(),
    descricao_completa: z.string().optional(),
    descricao: z.string().optional(),
    column: z.array(
        z.object({
            coluna: z.string().optional(),
            select: z.boolean().transform(value => { return !value ? 0 : 1 }).optional(),
            id: z.boolean().transform(value => { return !value ? 0 : 1 }).optional(),
            filtro_principal: z.boolean().transform(value => { return !value ? 0 : 1 }).optional(),
            descricao: z.string().optional(),
            type: z.string().optional(),
            data: z.string().optional(),
            step: z.string().optional(),

        })
    ),
    tags: z.array(
        z.object({
        id_tag: z.number(),
    })),
})

export type setExplorerLayerFormData = z.infer<typeof setExplorerLayerFormSchema>


export const putExplorerLayerFormSchema = z.object({
    id: z.number(),
    nome: z.string().nonempty('O nome é obrigatório!'),
    url: z.string().nonempty('O url é obrigatório!'),
    schema_table: z.string().nonempty('O schema é obrigatório!'),
    type: z.string().nonempty('O type é obrigatório!'),
    source_layer: z.string().nonempty('O type é obrigatório!'),
    source: z.string().nonempty('O type é obrigatório!'),
    situacao: z.number().optional(),
    nacional: z.boolean().transform(value => { return !value ? 0 : 1 }).optional(),
    paint: z.string(),
    id_municipio: z.number().optional(),
    id_estado: z.number().optional(),
    descricao_completa: z.string().optional(),
    descricao: z.string().optional(),
    column: z.array(
        z.object({
            coluna: z.string().optional(),
            select: z.boolean().transform(value => { return !value ? 0 : 1 }).optional(),
            id: z.boolean().transform(value => { return !value ? 0 : 1 }).optional(),
            filtro_principal: z.boolean().transform(value => { return !value ? 0 : 1 }).optional(),
            descricao: z.string().optional(),
            type: z.string().optional(),
            data: z.string().optional(),
            step: z.string().optional(),
        })
    ),
    explorer_layer_tags: z.array(
        z.object({
            id_layer: z.number().optional(),
            id_tag: z.number().optional()
        }).optional()
    )
})

export type putExplorerLayerFormData = z.infer<typeof putExplorerLayerFormSchema>