import {z} from 'zod'

export const SetInteligenciaMercadoFormSchema = z.object({
  nome: z.string(),
  json: z.unknown(),
})

export type SetInteligenciaMercadoFormData = z.infer<typeof SetInteligenciaMercadoFormSchema>

export const PutInteligenciaMercadoFormSchema = z.object({
  id: z.number(),
  nome: z.string(),
  json: z.unknown().optional(),
})

export type PutInteligenciaMercadoFormData = z.infer<typeof PutInteligenciaMercadoFormSchema>

export const SetInteligenciaMercadoVSOFormSchema = z.object({
  nome: z.string(),
  vso: z.any(),
})
export type SetInteligenciaMercadoVSOFormData = z.infer<typeof SetInteligenciaMercadoVSOFormSchema>

export const PutInteligenciaMercadoVSOFormSchema = z.object({
  nome: z.string(),
  id: z.number(),
  vso: z.any(),
})
export type PutInteligenciaMercadoVSOFormData = z.infer<typeof PutInteligenciaMercadoVSOFormSchema>
