/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {RadialBar} from '../../../../../../../components/UI/Charts/RadialBar'
import {formatNumber} from '../../../../common'
import {BiInfoCircle} from 'react-icons/bi'

type Props = {
  titulo: string
  subtitulo: string
  tipologia: any
}

export const BoxTipologia: React.FC<Props> = ({titulo, subtitulo, tipologia}) => {
  const textTooltipo =
    'É o primeiro preço coletado pela Órulo. É o preço do lançamento ou o mais próximo a ele nos registros da Urbit/Órulo.'
  const textTooltipoUltimoPreco = 'É o último preço coletado pela Órulo.'
  const valor = tipologia
    ? (((tipologia.total_units - tipologia.stock) / tipologia.total_units) * 100).toFixed(0)
    : 0
  if (!tipologia.original_price) {
    tipologia.original_price = 0
  }
  return (
    <div className={`card `}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{titulo}</span>

          <span className='text-muted fw-semibold fs-7'>{subtitulo}</span>
        </h3>

        {/* <div className='card-toolbar'> */}

        {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button> */}
        {/* <Dropdown1 /> */}
        {/* </div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body p-0 d-flex flex-column'>
        <div className='row'>
          <div className='col-3 h-150px'>
            <RadialBar
              className={'info'}
              chartColor={'alert'}
              chartHeight={'150'}
              descricao={'% vendas'}
              valor={Number(valor)}
            ></RadialBar>
          </div>

          <div className='col-9'>
            <div className='card-p pt-5 bg-body flex-grow-1'>
              <div className='row g-0'>
                <div className='col me-8'>
                  <div className='fs-7 text-muted fw-semibold'>Total unidades</div>
                  <div className='d-flex align-items-center'>
                    <div className='fs-4 fw-bold'>{tipologia ? tipologia.total_units : 0}</div>
                  </div>
                </div>
                <div className='col '>
                  <div className='fs-7 text-muted fw-semibold'>Estoque</div>
                  <div className='fs-4 fw-bold'>{tipologia ? tipologia.stock : 0}</div>
                </div>
              </div>
              <div className='row g-0 mt-8'>
                <h3>Preço atual</h3>
                <small className='text-muted fs-6 mb-3'>Ultimo preço coletado.</small>
                <div className='col me-8'>
                  <div className='fs-7 text-muted fw-semibold'>
                    {Number(tipologia.stock) === 0 ? (
                      <span>
                        Último preço praticado em {tipologia.ultimo_preco_data}
                        <a href='#' title={textTooltipoUltimoPreco}>
                          <BiInfoCircle className='fs-1'></BiInfoCircle>
                        </a>
                      </span>
                    ) : (
                      'Preço Atual - ' + tipologia.ultimo_preco_data
                    )}{' '}
                  </div>
                  <div className='fs-4 fw-bold'>
                    {tipologia
                      ? Number(tipologia.stock) === 0
                        ? formatNumber(tipologia.ultimo_preco, 'currency')
                        : formatNumber(tipologia.ultimo_preco, 'currency')
                      : 0}
                  </div>
                </div>
                <div className='col'>
                  <div className='fs-7 text-muted fw-semibold'>
                    {Number(tipologia.stock) === 0 ? (
                      <span>
                        Último preço m² praticado em {tipologia.ultimo_preco_data}
                        <a href='#' title={textTooltipoUltimoPreco}>
                          <BiInfoCircle className='fs-1'></BiInfoCircle>
                        </a>
                      </span>
                    ) : (
                      'Preço m² Atual - ' + tipologia.ultimo_preco_data
                    )}{' '}
                  </div>
                  <div className='d-flex align-items-center'>
                    <div className='fs-4 fw-bold'>
                      {tipologia
                        ? Number(tipologia.stock) === 0
                          ? formatNumber(tipologia.ultimo_preco_m2, 'currency')
                          : formatNumber(
                              tipologia.ultimo_preco / tipologia.private_area,
                              'currency'
                            )
                        : 0}
                    </div>
                  </div>
                </div>
                <hr className='mt-5 mb-1' />
              </div>
              <div className='row g-0 mt-8'>
                <h3>Preço lançamento </h3>
                <small className='text-muted fs-6 mb-3'>
                  Preço do lançamento ou o primeiro preço coletado
                </small>
                <div className='col  me-8'>
                  <div className='fs-7 text-muted fw-semibold'>
                    Preço em {tipologia.preco_lancamento_data}
                    <a href='#' title={textTooltipo}>
                      <BiInfoCircle className='fs-1'></BiInfoCircle>
                    </a>
                  </div>
                  <div className='fs-4 fw-bold'>
                    {tipologia ? formatNumber(tipologia.preco_lancamento, 'currency') : 0}
                  </div>
                </div>
                <div className='col'>
                  <div className='fs-7 text-muted fw-semibold'>
                    Preço m² em {tipologia.preco_lancamento_data}{' '}
                    <a href='#' title={textTooltipo}>
                      <BiInfoCircle className='fs-1'></BiInfoCircle>
                    </a>
                  </div>
                  <div className='d-flex align-items-center'>
                    <div className='fs-4 fw-bold'>
                      {tipologia ? formatNumber(tipologia.preco_lancamento_m2, 'currency') : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
