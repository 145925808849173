import { gql } from '@apollo/client'

export const GET_EXPLORER_LAYER = gql`
query GetExplorerLayers($pagination: Pagination, $filter: filter) {
  GetExplorerLayers(pagination: $pagination, filter: $filter) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      nome
      nota_tecnica
      descricao
      descricao_completa
      id
      id_estado
      id_usuario_alteracao
      nacional
      layout
      id_usuario_cadastro
      paint
      schema_table
      source
      source_layer
      tile
      tabela
      situacao
      uuid
      url
      type
      zoom_maximo
      id_municipio
    }
  }
}
`


export const GET_EXPLORER_LAYER_BY_ID = gql`
query GetExplorerLayerById($getExplorerLayerByIdId: Float!) {
  GetExplorerLayerById(id: $getExplorerLayerByIdId) {
    nome
    nota_tecnica
    descricao
    descricao_completa
    id
    id_estado
    id_usuario_alteracao
    nacional
    layout
    id_usuario_cadastro
    paint
    schema_table
    source
    source_layer
    tile
    tabela
    situacao
    uuid
    url
    type
    zoom_maximo
    id_municipio
    explorer_tiles {
      data_return
      description
      id
      id_column
      id_layer
      key_column
      label
      select_columns
      situacao
      step
      table_column
      table_name
      type_data
      user_cadastro
      uuid
    }
    explorer_layer_tags {
      id_layer
      id_tag
      explorer_tags {
        tag
        id
      }
    }
  
  }
}
`

export const GET_ONE_EXPLORER_LAYER = gql`
  query GetProdutosById($getProdutosByIdId: Float!) {
    GetProdutosById(id: $getProdutosByIdId) {
      descricao
      id
      nome
      tipo_produto
      valor
    }
  }
`
