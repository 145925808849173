import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {PiPasswordFill} from 'react-icons/pi'
import {MutationRecoveryPassword} from '../../../graphql'

export function ModalRecoveryPassword({id_user}) {
  const [show, setShow] = useState(false)
  const {FormRecoveryPassword, handleSubmit, register} = MutationRecoveryPassword()
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked)
  }

  async function closeModal() {
    setShow(false)
  }
  function openModal() {
    setShow(true)
  }

  const onSubmit = async (data) => {
    FormRecoveryPassword(data)
      .then((result) => {
        Swal.fire('Sucesso!', 'Alteração feita com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  return (
    <>
      <button
        onClick={openModal}
        className='btn btn-light-info btn-sm d-block me-2'
        style={{width: '100px !important'}}
      >
        <PiPasswordFill className='fs-2' />
      </button>

      <Modal centered animation show={show} onHide={closeModal} size='sm'>
        <Modal.Header closeButton>
          <Modal.Title>Recuperação:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    className='form-control form-control-solid'
                    placeholder='id'
                    hidden={true}
                    defaultValue={id_user}
                    {...register('userId', {required: true, valueAsNumber: true})}
                  />

                  <label className='form-label'>Nova Senha:</label>
                  <input
                    className='form-control form-control-solid'
                    placeholder='Nova Senha'
                    {...register('newPassword', {required: true})}
                  />

                  <div className='mt-6 all-center-start'>
                    <input
                      type='checkbox'
                      className='form-checkbox me-2'
                      onChange={handleCheckboxChange}
                      checked={isCheckboxChecked}
                    />
                    <label htmlFor=''>Confirmo a alteração da senha.</label>
                  </div>

                  <div className='col-md-12 d-flex mrt-1' style={{justifyContent: 'flex-end'}}>
                    <button
                      className='btn btn-danger pull-right'
                      type='button'
                      onClick={closeModal}
                      data-bs-dismiss='modal'
                    >
                      Fechar
                    </button>
                    <button
                      type='submit'
                      className='btn btn-success mx-2'
                      disabled={!isCheckboxChecked}
                    >
                      Confirmar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
