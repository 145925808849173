import React, { useEffect } from 'react';
import { useState } from 'react';
import swal from 'sweetalert2';
import { MutationChangePassword } from '../../../graphql/services/Senha';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import { FormInputAndLabelIcon } from '../../../components/UI/Inputs/FormInputAndIcon';


export function ChangePassword() {
  const navigate = useHistory()


  const { FormAlterarSenha, DataPutAlterarSenha, error, loading: loadingSet, handleSubmit, register, errors, getPasswordStrength, watch } = MutationChangePassword()
  const senha = watch('password', '')
  const forcaSenha = getPasswordStrength(senha);
  const [mostrarSenha, setMostrarSenha] = useState(false);

  useEffect(() => {
    if (DataPutAlterarSenha) {
      localStorage.clear()
      Swal.fire('Sucesso!', 'Senha alterada com sucesso.', 'success')
      navigate.push('/')
    }
    if (error) {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'warning')
    }
  }, [DataPutAlterarSenha, error, navigate]);

  function GoToLogin() {
    localStorage.clear();
    navigate.push('/')
  }

  const toggleMostrarSenha = () => {
    setMostrarSenha((prevState) => !prevState);
  };

  return (
    <>
      <form
        className='form w-100 '
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={handleSubmit(FormAlterarSenha)}
      >
        <div className="p-10">
          <div className="text-center mb-10">
            <h1 className="text-dark fw-bolder mb-3">
              Configurar nova senha
            </h1>

            <div className="text-gray-500 fw-semibold fs-6">
              Você já redefiniu a senha?

              <button onClick={GoToLogin} className='link-success fs-6 fw-bolder' style={{ background: 'none', border: 'none' }}>
                Login
              </button>
            </div>
          </div>

          <div className="fv-row mb-8 fv-plugins-icon-container" data-kt-password-meter="true">
            <div className="mb-1">
              <div className="position-relative mb-3">
                <FormInputAndLabelIcon
                  type={mostrarSenha ? 'text' : 'password'}
                  className='form-control form-control-solid'
                  placeholder='Digite sua nova senha.'
                  register={register('password')}
                  error={errors ? errors.password?.message : ''}
                  mostrarSenha={mostrarSenha}
                  toggleMostrarSenha={toggleMostrarSenha}
                />
              </div>
            </div>
            <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
              <div className={`flex-grow-1 ${forcaSenha >= 20 ? 'bg-success' : 'bgSecondary'} rounded h-5px me-2`}></div>
              <div className={`flex-grow-1 ${forcaSenha >= 40 ? 'bg-success' : 'bgSecondary'} rounded h-5px me-2`}></div>
              <div className={`flex-grow-1 ${forcaSenha >= 60 ? 'bg-success' : 'bgSecondary'} rounded h-5px me-2`}></div>
              <div className={`flex-grow-1 ${forcaSenha >= 80 ? 'bg-success' : 'bgSecondary'} rounded h-5px me-2`}></div>
              <div className={`flex-grow-1 ${forcaSenha === 100 ? 'bg-success' : 'bgSecondary'} rounded h-5px`}></div>
            </div>
            <div className="text-muted text-center">
              Use 8 ou mais caracteres com uma mistura de números, letras maiúsculas, minúsculas &amp; símbolos[@$!%*?&].
            </div>
            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
          {/* <div className="fv-row mb-8 fv-plugins-icon-container">
            <FormInputAndLabel
              className='form-control form-control-solid'
              placeholder='Repita sua senha.'
              register={register('confirmPassword')}
              error={errors ? errors.confirmPassword?.message : ''}
            />
          </div> */}


          <div className="d-grid mb-10">
            <button type="submit" disabled={loadingSet} className="btn btn-primary">
              {loadingSet ? <span className='spinner-border text-info' role='status'></span> : 'Salvar'}
            </button>
          </div>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
