import { TableAdminUsuarios } from './partials/TableAdminUsuarios'
import { AsideDefault } from '../../../../_metronic/layout/components/aside/AsideDefault'
import { HeaderWrapperFluid } from '../../../../_metronic/layout/components/header/HeaderWrapperFluid'
import { Footer } from '../../../../_metronic/layout/components/Footer'
import { useState } from 'react'

export function AdminUsuarios() {

  const [filter, setFilter] = useState([]);
  filter.push({
    field: ["situacao"],
    operator: "=",
    value: "1"
  });

  function handleInputChange(event) {
    const { value } = event.target;

    const filterArray = [];

    if (value) {
      filterArray.push({
        field: ['name', 'email', 'telefone'],
        operator: 'LIKE',
        value: value,
      });
    }




    setFilter(filterArray);
  }

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <div
                className='py-6  d-flex align-items-center justify-content-between'
                id='kt_header_container'
              >
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  data-kt-swapper='true'
                  data-kt-swapper-mode='prepend'
                  data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Listagem</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href='/metronic8/demo7/../demo7/index.html' className='text-muted'>
                        Usuários
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='d-flex align-items-center ms-3'>
                  <input type='text' className='form-control' placeholder='Pesquisar nome, email ou telefone' onChange={handleInputChange} />
                </div>
              </div>
              <div className="content  d-flex flex-column flex-column-fluid" id="kt_content" >
                <div className='col-xl-12'>
                  <div className=''>
                    <TableAdminUsuarios filtro={filter} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
