import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useEffect } from 'react'
import { MutationPutCorretores, QueryGetCorretoresByID } from '../../../graphql/services/Corretores'
import { Loader } from '../../../components/UI/Loader/Loader'
import { FormInputAndLabel } from '../../../components/UI/Inputs/FormInputAndLabel'
import { FormInputMaskAndLabel } from '../../../components/UI/Inputs/FormInputMaskAndLabel'
import { MutationSetLogAcessoRecurso } from '../../../graphql/services/LogAcesso'

interface Props {
  id: number
  show: boolean
  closeModal: any
}

export function EditForm({ id, show, closeModal }: Props) {
  let { data, loading: LoadingGet } = QueryGetCorretoresByID({
    variables: { getCorretorByIdId: id },
  })

  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const { FormCorretor, loading, handleSubmit, register, errors  } =
    MutationPutCorretores()

    const onSubmit = async (data) => {
      FormCorretor(data).then((result) => {
        Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
        FormSetLogAcessoRecurso({ferramenta: 'cadastro', funcionalidade: 'corretores', action: 'update', url: '/cadastro/corretores'});
        closeModal()
      }).catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
    }

  // Tratativa de loading
  if (loading || LoadingGet) {
    return (
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Corretores:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader text={'caregando...'}></Loader>
        </Modal.Body>
      </Modal>
    )

  }

  return (
    <>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Corretor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              className='form-control form-control-solid'
              type='number'
              hidden={true}
              defaultValue={data ? data.GetCorretorById.id : ''}
              {...register('id', { required: true })}
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputAndLabel
                    label='Nome Completo'
                    className='form-control required form-control-solid'
                    placeholder='Nome Completo'
                    defaultValue={data.GetCorretorById.nome}
                    register={register("nome")}
                    error={errors ? errors.nome?.message : ''}
                  />
                </div>
              </div>

              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputAndLabel
                    label='Email'
                    className='form-control required form-control-solid'
                    placeholder='Ex: joao@email.com'
                    defaultValue={data.GetCorretorById.email}
                    register={register("email")}
                    error={errors ? errors.email?.message : ''}
                  />
                </div>
              </div>

              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputMaskAndLabel
                    label='Telefone:'
                    maskChar=' '
                    mask='(99) 99999-9999'
                    className='form-control form-control-solid'
                    placeholder='Ex: (00) 00000-0000'
                    defaultValue={data.GetCorretorById.telefone}
                    register={register("telefone")}
                    error={errors ? errors.telefone?.message : ''}
                  />
                </div>
              </div>

              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputAndLabel
                    label='Empresa'
                    className='form-control required form-control-solid'
                    placeholder='Empresa'
                    defaultValue={data.GetCorretorById.empresa}
                    register={register("empresa")}
                    error={errors ? errors.empresa?.message : ''}
                  />
                </div>
              </div>

              <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={() => closeModal()}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
