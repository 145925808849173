import {Text, View, StyleSheet} from '@react-pdf/renderer'
import { marcadoresMap } from '../util'

const styles = StyleSheet.create({
  table: {
    width: '100%',
    fontSize: 11,
    fontWeight: 'light',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
  },
  rowErro: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
    textAlign: 'center',
  },
  title: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 16,
    fontWeight: 'bold',
    borderBottomWidth: 2,
    borderBottomColor: '#2B8E57',
  },
  header: {
    borderTop: 'none',
    backgroundColor: '#dbdbdb',
    paddingHorizontal: 10,
  },
  bold: {
    fontWeight: 'black',
  },
  rowDefault: {
    width: '40%',
  },
  rowDefault2: {
    width: '25%',
  },
  rowCenter: {
    width: '20%',
    textAlign: 'center',
  },
  decimal: {
    width: '20%',
    textAlign: 'center',
  },
  rowDetalhe: {
    backgroundColor: '#d0d0d0'
  },
  currency: {
    width: '20%',
    textAlign: 'center',
  },
})
let totalArea = 0
let totaTestada = 0
export const Imoveis = ({imoveis}) => {
  if (imoveis) {
    return (
      <View style={styles.table}>
        <Text style={styles.title}>Imóveis</Text>
        <View style={[styles.row, styles.bold, styles.header]}>
          <Text style={{width: '8%'}}>x</Text>
          <Text style={styles.rowDefault}>Endereço</Text>
          <Text style={styles.rowDefault2}>Nº Contribuinte</Text>
          <Text style={styles.rowDefault2}>Zona</Text>
          <Text style={styles.rowDefault2}>Área IPTU</Text>
          <Text style={styles.rowDefault2}>Testada</Text>
        </View>
        {imoveis.GetEmpreendimentoImovel.result.length === 0 ? (
          <Text style={styles.rowErro}>Nenhum registro encontrado</Text>
        ) : (
          ''
        )}
        {imoveis.GetEmpreendimentoImovel.result.map((row, i) => {
          totalArea += row.terreno.area_iptu
          totaTestada += row.terreno.testada
        return (

          <View key={i} style={styles.row} wrap={false}>
            <Text style={{width: '8%',  textAlign: 'center'}}>{marcadoresMap[i]}</Text>
            <Text style={styles.rowDefault}>{row.terreno.endereco},{row.terreno.numero}</Text>
            <Text style={styles.rowDefault2}>{row.terreno.numero_contribuinte}</Text>
            <Text style={styles.rowDefault2}>{row.terreno.zoneamento}</Text>
            <Text style={styles.rowDefault2}>{row.terreno.area_iptu}</Text>
            <Text style={styles.rowDefault2}>{row.terreno.testada}</Text>
          </View>
        )
        }
        )}
         <View key={0} style={[styles.row,styles.rowDetalhe]} wrap={false}>
            <Text style={styles.rowDefault}></Text>
            <Text style={styles.rowDefault2}></Text>
            <Text style={styles.rowDefault2}>Total</Text>
            <Text style={styles.rowDefault2}>{totalArea}</Text>
            <Text style={styles.rowDefault2}>{ totaTestada.toFixed(2) }</Text>
          </View>
      </View>
    )
  }
}
