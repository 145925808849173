/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {QueryGetEmpreendimentos, QueryGetImoveis} from '../../../graphql'
import {BiBuildings, BiMapPin} from 'react-icons/bi'
import {Link} from 'react-router-dom'

type Props = {
  className: string
}

const ImoveisList: React.FC<Props> = ({className}) => {
  const {data: DataImoveis} = QueryGetImoveis({
    variables: {
      pagination: {pagina: 0, quantidade: 6},
    },
  })

  const {data} = QueryGetEmpreendimentos({
    variables: {
      pagination: {pagina: 0, quantidade: 6},
    },
  })

  if (!data || !DataImoveis) {
    return (
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header align-items-center border-0 mt-4'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bold mb-2 text-dark'>Últimos Imoveis Cadastrados</span>
            <span className='text-muted fw-semibold fs-7'>Buscas</span>
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-5'>
          {/* begin::Timeline */}
          <div className='timeline-label'></div>
          {/* end::Timeline */}
        </div>
        {/* end: Card Body */}
      </div>
    )
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
        <div className={`card ${className}`}>
          <div className='card-title pt-8 px-8 '>
            <h3 className=''>Últimos Imoveis Cadastrados</h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-200px'>Nome/Endereço</th>
                    <th className='min-w-150px all-end'></th>
                  </tr>
                </thead>

                <tbody>
                  {DataImoveis.GetImoveis.result.map((Response) => {
                    return (
                      <tr key={Response.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                              <BiMapPin className='fs-1' color='#198c51'></BiMapPin>
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold fs-6'>{Response.nome}</span>

                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {Response.endereco}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <Link
                              to={`/meus-imoveis/details/${Response.id}#informacoes-lote`}
                              className='btn btn-sm btn-light-primary'
                            >
                              Ver mais
                            </Link>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
        <div className={`card ${className}`}>
          <div className='card-title  pt-8 px-8'>
            <h3 className=' '>Últimos Empreendimentos Cadastrados</h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-200px'>Nome/Endereço</th>
                    <th className='min-w-150px all-end'></th>
                  </tr>
                </thead>

                <tbody>
                  {data.GetEmpreendimentos.result.map((Response) => {
                    return (
                      <tr key={Response.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                              <BiBuildings className='fs-1' color='#f14180'></BiBuildings>
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold fs-6'>{Response.nome}</span>

                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {Response.endereco}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <Link
                              to={`/empreendimentos/details/${Response.id}#composicao-terrenos`}
                              className='btn btn-sm btn-light-primary'
                            >
                              Ver mais
                            </Link>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ImoveisList}
