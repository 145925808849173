import { gql } from "@apollo/client";

export const SET_GRUPOS_SERVICOS = gql`
mutation SetUsuarioGrupoServico($servicosId: [Float!]!, $data: UsuarioGrupoServico!) {
    SetUsuarioGrupoServico(servicos_id: $servicosId, data: $data) {
      groupService {
        name
      }
    }
  }
`

export const PUT_GRUPOS_SERVICOS = gql`
mutation PutUsuarioGrupoServico($data: UsuarioGrupoServico!, $servicosId: [Float!]!) {
  PutUsuarioGrupoServico(data: $data, servicos_id: $servicosId) {
    id
    name
  }
}
`

export const DELETE_GRUPOS_SERVICOS = gql`
mutation DeleteUsuarioGrupoServico($deleteUsuarioGrupoServicoId: Float!) {
  DeleteUsuarioGrupoServico(id: $deleteUsuarioGrupoServicoId) {
    id
    name
  }
}
`