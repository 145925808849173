import { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { BiChart } from 'react-icons/bi'
import { MutationDeleteInteligenciaMercadoVSO, QueryGetInteligenciaMercadoVSO } from '../../../graphql/services/inteligencia-mercado'
import Swal from 'sweetalert2'
import { MutationSetLogAcessoRecurso } from '../../../graphql/services/LogAcesso'
import { KTSVG } from '../../../../_metronic/helpers'
import { ModalCreateVSO } from './ModalCreateVSO'
import { ModalEditVSO } from './ModalEditVSO'

export function ModalVSO() {
  const [show, setShow] = useState(false)
  const { FormInteligenciaMercadoVSO, loading: LoadingDelete } = MutationDeleteInteligenciaMercadoVSO()
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  if (LoadingDelete) {
    Swal.fire(
      'Enviando Informações...', '')
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await FormInteligenciaMercadoVSO(id)
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          FormSetLogAcessoRecurso({ ferramenta: 'Inteligencia Mercado', funcionalidade: 'listagem', action: 'delete', url: '/profile#funcionarios' });
          Swal.fire('Sucesso!', 'Projeto deletado com sucesso.', 'success')
        }
      }
    })
  }



  const { data, error, loading } = QueryGetInteligenciaMercadoVSO({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100
      }
    }
  })

  function closeModal() {
    setShow(false)
  }

  function openModal() {
    setShow(true)
  }

  if (!data) {
    return (
      <>
        <button onClick={openModal} className='btn btn-light-primary btn-sm me-2'>
          <BiChart className='fs-2 me-1' />
          Configuração VSO
        </button>
        <Modal centered animation show={show} onHide={closeModal} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Configuração VSO</Modal.Title>
          </Modal.Header>
          <Modal.Body className='modal-xl' style={{ width: '100%' }}>
            <div className='row'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Usuario Cadastrado</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={0}>
                    <td className='text-center' colSpan={4}>
                      <Spinner variant='success' animation='border' role='status'>
                        <span className='visually-hidden'>Carregando...</span>
                      </Spinner>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  return (
    <>
      <button onClick={openModal} className='btn btn-light-primary btn-sm me-2'>
        <BiChart className='fs-2 me-1' />
        Configuração VSO
      </button>
      <Modal centered animation show={show} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Listagem VSO's cadastrados.</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-xl' style={{ width: '100%' }}>
          <div className='w-100 all-end'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Criar novo projeto'
            >
              <ModalCreateVSO />
            </div>
          </div>
          <div className='card-body p-0 py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 px-6 gy-4'>
                <thead>
                  <tr>
                    <th className='min-w-150px'>Nome</th>
                    <th className='min-w-100px text-end'>Ações</th>
                  </tr>
                </thead>
                <tbody className='px-6'>
                  {data.GetInteligenciaMercadoVSO.result.length !== 0 ? (
                    data.GetInteligenciaMercadoVSO.result.map((response) => {
                      return (
                        <tr key={response.id}>
                          <td>{response.nome === null || response.nome === '' ? 'Não informado' : response.nome}</td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <ModalEditVSO id={response.id} />
                              <button
                                onClick={() => {
                                  confirmDelete(response.id)
                                }}
                                disabled={LoadingDelete}
                                className='btn btn-icon btn-light-danger  btn-sm me-1'
                              >
                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr key={0}>
                      <td colSpan={12} className='text-center'>
                        <p className='text-dark fs-6'>Nenhum VSO registrado.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
