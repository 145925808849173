import { z } from 'zod';

// Define the schema for the form
export const TypologiesPriceFormSchema = z.object({
  id_real_estate_launches: z.number().nullable(),
  id_real_estate_launches_typologies: z.number().nullable(),
  price_date: z.string().nullable(),
  price: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  price_m2: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  stock: z.number()
    .refine(value => !isNaN(value) && value !== undefined, { message: 'Estoque é obrigatório.' }) // Required check
    .refine(value => value >= 0, { message: 'Estoque deve ser maior ou igual a zero.' }) // Positive check
    .transform(value => (isNaN(value) ? undefined : value)), // Transform NaN to undefined
  sales: z.number()
    .refine(value => !isNaN(value) && value !== undefined, { message: 'Vendas são obrigatórias.' }) // Required check
    .refine(value => value >= 0, { message: 'Vendas devem ser maiores ou iguais a zero.' }) // Positive check
    .transform(value => (isNaN(value) ? undefined : value)), // Transform NaN to undefined
  source: z.string(),
  launch_price: z.number(),
});

export type TypologiesPriceFormData = z.infer<typeof TypologiesPriceFormSchema>;
