import {formatAdressSeachGoogleMapa} from '../../../common'
import {Endereco} from '../EnderecoControl/endereco'

export const Composicao = {
  composicaoList: [],
  onUpdateComposicao: {},
  setExplorerComposicao: null,
  composicaoLayerId: '',
  cidadeException: ['5351'],
  add: async function (lote) {
    if (this.composicaoList.length > 0) {
      let item = []
      if (lote.gid) {
        item = this.composicaoList.find((el) => el.gid === lote.gid)
        if (typeof item !== 'undefined') {
          alert('Lote já adicionado')
        } else {
          this.addLoteList(lote)
        }
      } else {
        this.addLoteList(lote)
      }
    } else {
      this.addLoteList(lote)
    }
  },
  addLoteList: async function (lote) {
    if (this.cidadeException.indexOf(lote.id_cidade) === -1) {
      await this.executeGeocoder(lote.latitude, lote.longitude, (retorno) => {
        lote = {...lote, ...retorno}
        this.composicaoList.push(lote)
        this.setExplorerComposicao(this.composicaoList)
        this.onUpdateComposicao(this.composicaoList)
      })
    } else {
      this.composicaoList.push(lote)
      this.setExplorerComposicao(this.composicaoList)
      this.onUpdateComposicao(this.composicaoList)
    }


  },
  executeGeocoder: function (lat, lng, callback) {
    const geocoder = new google.maps.Geocoder()
    if (lat && lng) {
      const latLng = new google.maps.LatLng(lat, lng)
      geocoder.geocode({location: latLng}, async function (results, status) {
        if (status === 'OK') {
          var place = results[0]
          var adr_elements = formatAdressSeachGoogleMapa(place)
          let endereco = {
            endereco: adr_elements['logradouro'] + ',' + adr_elements['numero'],
            numero: adr_elements['numero'],
            logradouro: adr_elements['logradouro'],
            bairro: adr_elements['bairro'],
            estado: adr_elements['estado'],
            cidade: adr_elements['cidade'],
            cep: adr_elements['cep']
          }
          callback(endereco)
        }
      })
    }
  },
  remove: function (composicao, id) {
    let itens = [...composicao]
    itens.splice(id, 1)
    return itens
  },
  update: function (composicao, id, key, value) {
    composicao[id][key] = value
    // let itens = [...composicao]
    // itens.splice(id, 1)
    return composicao
  },
  getList: function () {
    return this.composicaoList
  },
  clear: function () {
    this.composicaoList = []
    this.composicao = []
    this.setExplorerComposicao(this.composicaoList)
  },
  clearSelecao: function () {
    Composicao.composicaoList = []
    this.setExplorerComposicao(this.composicaoList)
    this.onUpdateComposicao(this.composicaoList)
  },
  setComposicaoLayer: function (id) {
    this.composicaoLayerId = id
  },
  getComposicaoLayer: function () {
    return this.composicaoLayerId
  },
}
