import React, { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { ErrorMessageComponent } from '../../../../../../../../backoffice/components/UI/ErrorMessage'
import { Loader } from '../../../../../../../../components/UI/Loader/Loader'
import { MutationCreateEmpreendimentoMassaUso } from '../../../../../../../../graphql/services/EmpreendimentoMassaUsos'
import { KTSVG } from '../../../../../../../../../_metronic/helpers'
// import {TouchSpinProps} from 'react-bootstrap-touchspin'

import { FormInputTouchSpin } from '../../../../../../../../components/UI/Inputs/FormInputTouchSpin'
import { APiUrbit } from '../../../../../../../../services/api/apiUrbit'
import { FormInputNumericFormat } from '../../../../../../../../components/UI/Inputs/FormInputNumericFormat'
import { BiAddToQueue } from 'react-icons/bi'
import { FormInputTouchSpinFracao } from '../../../../../../../../components/UI/Inputs/FormInputTouchSpinFracao'
import { MutationSetLogAcessoRecurso } from '../../../../../../../../graphql/services/LogAcesso'
import { useParams } from 'react-router-dom'
type Props = {
  id_massa: number
  imoveis: any
}
const ModalMassaUsoCreate: React.FC<Props> = ({ id_massa, imoveis }) => {
  const {id: idEmpreendimento} = useParams<{id: string}>()

  const [show, setShow] = useState(false)
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  //   const [terreno, setTerreno] = useState(0.0)
  const [areaTerreno, setAreaTerreno] = useState(1000)
  const [areaPrivativa, setAreaPrivativa] = useState<number>(0)
  const [areaComputavel, setAreaComputavel] = useState<number>(0)
  const [areaExcedente, setAreaExcedente] = useState<number>(0)
  const [areaPrivativaMediaPorUnidade, setAreaPrivativaMediaPorUnidade] = useState<number>(0)
  const [manual, setManual] = useState(false)

  const handleChange = (event) => {
    setManual(event.target.checked)
  }
  // const [camax, setCamax] = useState<number>()
  const [cabas, setCabas] = useState<number>()
  const [fatorSocial, setFatorSocial] = useState<number>()
  const {
    FormEmpreendimentoMassaUso,
    loading,
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    getValues,
  } = MutationCreateEmpreendimentoMassaUso()

  useEffect(() => {
    let areaTotal = 0;
    if (imoveis) {
      // eslint-disable-next-line array-callback-return
      imoveis.GetEmpreendimentoImovel.result.map((item, key) => {
        if (item.terreno){
        if(item.terreno.area_real){
          areaTotal += item.terreno.area_real
        }
      }
      })
      setAreaTerreno(areaTotal)
    }
    return () => {
      setAreaPrivativaMediaPorUnidade(0); // This worked for me
      setAreaExcedente(0); // This worked for me
      setAreaComputavel(0); // This worked for me
      setAreaPrivativa(0); // This worked for me
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (data) => {
    FormEmpreendimentoMassaUso(data).then((result) => {
      FormSetLogAcessoRecurso({ferramenta: 'empreendimentos', funcionalidade: 'massa-usos', action: 'update', url: '/empreendimentos/details/' + idEmpreendimento + '#massa'});
      Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })
  }

  useEffect(() => {
    const getFator = async () => {
      const api = new APiUrbit()
      const faoresSociais = await api.get('municipio/sao-paulo/fator-social')
      setFatorSocial(faoresSociais)
    }
    getFator()
  }, [])

  function closeModal() {
    setShow(false)
  }
  function openModal() {
    setShow(true)
  }

  function calculaAreaComputavel() {

    const areaTerrenoInput = getValues('area_terreno')
    const camaxTemp = getValues('camax')
    if (camaxTemp !== 0) {
      let area_terreno = !areaTerrenoInput ? 0 : areaTerrenoInput
      var area_computavel = Number((area_terreno * camaxTemp).toFixed(2))
      setValue('area_computavel', area_computavel)
      setAreaComputavel(area_computavel)
    }
  }

  function calculaAreaExcedente() {
    const cabasIpunt = getValues('cabas')
    if (cabasIpunt && areaComputavel) {
      var area_excedente = (areaComputavel - areaTerreno) * cabasIpunt
      setValue('area_excedente', area_excedente)
      setAreaExcedente(Number(area_excedente.toFixed(2)))
    }
  }

  function calculaAreaExcedenteUso() {
    const cabasIpunt = getValues('cabas')
    const camaxIpunt = getValues('camax')
    const area_terreno = getValues('area_terreno')

    if (camaxIpunt !== 0 && cabasIpunt !== 0 && areaTerreno !== 0) {
      var area_excedente = area_terreno * (camaxIpunt - cabasIpunt)
      setValue('area_excedente', area_excedente)
      setAreaExcedente(Number(area_excedente.toFixed(2)))
    }
  }

  function calculaAreaPrivativaPorUnidadeImob() {
    const unidadesImobiliarias = getValues('unidades_imobiliarias')
    const areaPrivativa = getValues('area_privativa')
    var res = unidadesImobiliarias === 0 ? 0 : areaPrivativa / unidadesImobiliarias
    setValue('area_privativa_por_unidade_imob', Number(res.toFixed(0)))
    setAreaPrivativaMediaPorUnidade(Number(res.toFixed(0)))
  }

  function calculaAreaPrivativa() {
    const fator_area_privativa = getValues('fator_area_privativa')
    var area_computavel = getValues('area_computavel')
    const area_privativa = area_computavel * fator_area_privativa
    setValue('area_privativa', area_privativa)
    setAreaPrivativa(area_privativa)
    calculaAreaPrivativaPorUnidadeImob()
  }

  let fatorCategoria = []
  if (fatorSocial) {
    // eslint-disable-next-line array-callback-return
    fatorSocial['itens'].map(function (element) {
      if (fatorCategoria.indexOf(element.tipo) === -1) {
        fatorCategoria.push(element.tipo)
      }
    })
  }
  function changeUso(id) {
    // eslint-disable-next-line array-callback-return
    fatorSocial['itens'].map(function (element) {
      if (element.id === parseInt(id)) {
        document.getElementById('uso')['value'] = element.uso
        document.getElementById('fs')['value'] = Number(element.fs)
        setValue('fs', Number(element.fs))
        setValue('uso', element.uso)
        return element.uso
      }
    })
  }

  let cabasTemp = []
  let camaxTemp = []

  return (
    <>
      <button onClick={openModal} className='btn  btn-light-info btn-sm me-2 '  style={{    width: '100px !important'}}>
        Adicionar Uso <BiAddToQueue className='fs-2'></BiAddToQueue>
      </button>

      <Modal centered animation show={show} onHide={() => closeModal()} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Nova Massa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} className='form-uso'>
            <input
              type='hidden'
              id='uso'
              className='form-control form-control-solid'
              defaultValue={null}
              {...register('uso', { required: true })}
            />
            <input
              id='fs'
              type="hidden"
              
              className='form-control form-control-solid'
              defaultValue={null}
              {...register('fs', {
                required: true,
                onChange: () => {
                  // calculaAreaComputavel()
                  // calculaAreaExcedente()
                },
                valueAsNumber:true
              })}
            />
            {/* <input
              type='number'
              hidden
              id='fp'
              className='form-control form-control-solid'
              defaultValue={null}
              {...register('fp', {required: true, valueAsNumber: true})}
            /> */}

            <input
              type='hidden'
              className='form-control form-control-solid'
              defaultValue={id_massa}
              {...register('massa_id', { required: true, valueAsNumber: true })}
            />
            <div className='form-group'>
              <div className='container-uso-massa'>
                <h3 className='mb-3  mt-5 pb-5 '>Uso</h3>
                {/* <hr className='border-dashed-default border-2'></hr> */}
                <div className='form-group row mb-4'>
                  <label className='col-sm-3 col-form-label'>Nome do Uso</label>
                  <div className='col-sm-9'>
                    <input
                      type='text'
                      className='form-control form-control-solid '
                      {...register('nome', { required: true })}
                    />
                    {errors.nome && <ErrorMessageComponent message={errors.nome?.message} />}

                  </div>
                </div>
                <div className='form-group row'>
                  <label className='col-sm-3 col-form-label'> Uso destinado:</label>
                  <div className='col-sm-9'>
                    <select
                      name='select-fs'
                      id='select-fs'
                      defaultValue={0}
                      className='form-control form-control-solid select-fs selectpicker'
                      data-live-search='true'
                      tabIndex={-98}
                      {...register('fs_id', {
                        required: true,
                        valueAsNumber: true,
                        onChange: (e) => {
                          changeUso(e.target.value)
                        },
                      })}
                    >
                      <option value="0">--Selecione--</option>
                      {fatorSocial
                        ? fatorCategoria.map(function (element, itemCat) {
                          return (
                            <optgroup label={element} key={itemCat}>
                              {
                                // eslint-disable-next-line array-callback-return
                                fatorSocial['itens'].map(function (fator, item) {
                                  if (fator.tipo === element) {
                                    return (
                                      <option value={fator.id} key={item}>
                                        {fator.uso}
                                      </option>
                                    )
                                  }
                                })
                              }
                            </optgroup>
                          )
                        })
                        : ''}
                    </select>
                  </div>
                </div>
                <div id='uso-group' className='mt-10'>
                  <h3 className=' mt-5 pb-5 pt-3'>
                    Coeficiente de Aproveitamento -{' '}
                    <label>
                      <input type='checkbox' onChange={handleChange} id='change-coeficiente' />{' '}
                      Definir manualmente
                    </label>{' '}
                  </h3>
                  {/* <hr className='border-dashed-default border-2'></hr> */}
                  <div className='row'>
                    <div className='col-md-4'>
                      {manual ? (
                        <div className='form-group row mb-2'>
                          <label className='3 col-form-label'>CABAS</label>
                          <div className=''>
                            <input
                              type='text'
                              name='cabas-input'
                              placeholder='0.0'
                              className='form-control form-control-solid '
                              {...register('cabas', {
                                required: true,
                                valueAsNumber: true,
                                onChange: (e) => {
                                  setValue('cabas', Number(e.target.value))
                                  calculaAreaExcedenteUso()
                                },
                              })}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className='form-group row mb-2'>
                          <label className='col-form-label'>CABAS</label>
                          <div className=''>
                            <select
                              className='form-control form-control-solid'
                              name=''
                              id='select-cabas-input'
                              {...register('cabas', {
                                required: true,
                                valueAsNumber: true,
                                onChange: (e) => {
                                  setValue('cabas', Number(e.target.value))
                                  calculaAreaExcedenteUso()
                                },
                              })}
                            >
                              <option value=''>Nenhum Selecionado</option>
                              {imoveis
                                // eslint-disable-next-line array-callback-return
                                ? imoveis.GetEmpreendimentoImovel.result.map((item, key) => {
                                  if (item.terreno ){
                                  if (cabasTemp.indexOf(item.terreno.cabas) === -1) {
                                    cabasTemp.push(item.terreno.cabas)
                                    return (
                                      <option value={item.terreno.cabas} key={key} >
                                        {item.terreno.cabas}
                                      </option>
                                    )
                                  }
                                }
                                })
                                : ''}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-4'>
                      {manual ? (
                        <div className='form-group row mb-2'>
                          <label className='col-form-label'>CAMAX</label>
                          <div className=''>
                            <input
                              type='text'
                              placeholder='0.0'
                              className='form-control form-control-solid'
                              {...register('camax', {
                                required: true,
                                valueAsNumber: true,
                                onChange: (e) => {
                                  setValue('camax', Number(e.target.value))
                                  calculaAreaComputavel()
                                  calculaAreaExcedenteUso()
                                },
                              })}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className='form-group row mb-2'>
                          <label className=' col-form-label'>CAMAX</label>
                          <div className=''>
                            <select
                              className='form-control form-control-solid'
                              {...register('camax', {
                                required: true,
                                valueAsNumber: true,
                                onChange: (e) => {
                                  setValue('camax', Number(e.target.value))
                                  calculaAreaComputavel()
                                  calculaAreaExcedenteUso()
                                },
                              })}
                            >
                              <option value=''>Nenhum Selecionado</option>

                              {imoveis
                                // eslint-disable-next-line array-callback-return
                                ? imoveis.GetEmpreendimentoImovel.result.map((item, key) => {
                                  if (item.terreno ){
                                  if (camaxTemp.indexOf(item.terreno.camax) === -1) {
                                    camaxTemp.push(item.terreno.camax)
                                    return (
                                      <option value={item.terreno.camax} key={key}>
                                        {item.terreno.camax}
                                      </option>
                                    )
                                  }
                                }

                                })
                                : ''}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <h3 className='py-1 mt-5 pb-5 pt-5'>Áreas</h3>
                  {/* <hr className='border-dashed-default border-2'></hr> */}

                  <div className='row'>
                    <div className='col-md-4'>
                      <FormInputNumericFormat
                        control={control}
                        label='Área de terreno do uso - m²'
                        className='form-control form-control-solid text-right'
                        placeholder='Área total do terreno'
                        register={register('area_terreno')}
                        defaultValue={areaTerreno}
                        error={errors ? errors.area_terreno?.message : ''}
                        onValueChange={(e) => {
                          setValue('area_terreno', e.floatValue)
                          calculaAreaComputavel()
                          // calculaAreaExcedente()
                          calculaAreaExcedenteUso()
                          // calculaAreaExcedente()
                        }}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormInputNumericFormat
                        control={control}
                        label='Área computável do uso - m²'
                        className='form-control form-control-solid text-right'
                        placeholder='Área computável do uso - m²'
                        register={register('area_computavel')}
                        defaultValue={areaComputavel}
                        error={errors ? errors.area_computavel?.message : ''}
                        onValueChange={(e) => {


                          //Utilize esse se form obrigatório
                          // if (value === "") {
                          //    setError("area_total_terreno", "Digite um valor")
                          //  } else {
                          //   setAreaComputavel(e.floatValue)
                          setValue('area_computavel', e.floatValue)
                          calculaAreaPrivativa()


                          // calculaAreaPrivativa();
                          // calculaAreaExcedente();
                          //  }
                        }}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormInputNumericFormat
                        control={control}
                        label='Área excedente do uso - m²'
                        className='form-control form-control-solid text-right'
                        placeholder='Área excedente do uso - m²'
                        register={register('area_excedente')}
                        defaultValue={areaExcedente}
                        error={errors ? errors.area_excedente?.message : ''}
                        onValueChange={(e) => {
                          //Utilize esse se form obrigatório
                          // if (value === "") {
                          //    setError("area_total_terreno", "Digite um valor")
                          //  } else {

                          setValue('area_excedente', e.floatValue)
                          //  }
                        }}
                      />
                    </div>
                  </div>

                  <div className='row mt-10'>
                    <div className='col-md-4'>
                      <FormInputTouchSpinFracao
                        control={control}
                        label=' Fator de área privativa:'
                        defaultValue={0.5}
                        className='form-control form-control-solid text-right'
                        placeholder='fator_area_privativa'
                        register={register('fator_area_privativa')}
                        error={errors ? errors.fator_area_privativa?.message : ''}
                        onValueChange={(e) => {
                          setValue('fator_area_privativa', e)
                          calculaAreaPrivativa()
                          // calculaAreaPrivativaPorUnidadeImob()
                        }}
                        value={0}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormInputNumericFormat
                        control={control}
                        label=' Área privativa do uso - m²'
                        className='form-control form-control-solid text-right'
                        placeholder=' Área privativa do uso - m²'
                        register={register('area_privativa')}
                        defaultValue={areaPrivativa}
                        error={errors ? errors.area_privativa?.message : ''}
                        onValueChange={(e) => {
                          //Utilize esse se form obrigatório
                          // if (value === "") {
                          //    setError("area_total_terreno", "Digite um valor")
                          //  } else {
                          setValue('area_privativa', e.floatValue)
                          //  }
                        }}
                      />
                    </div>
                    <div className='col-md-4'>

                      <FormInputTouchSpin
                        control={control}
                        label='Unidades Imobiliárias:'
                        defaultValue={0}
                        className='form-control form-control-solid text-right'
                        placeholder='Unidades Imobiliárias'
                        register={register('unidades_imobiliarias')}
                        error={errors ? errors.unidades_imobiliarias?.message : ''}
                        onValueChange={(e) => {
                          setValue('unidades_imobiliarias', e)
                          // calculaAreaPrivativa()
                          calculaAreaPrivativaPorUnidadeImob()
                        }}
                        value={0}
                      />
                      {/* 
                      <FormInputNumericFormat
                        control={control}
                        label='Unidades Imobiliárias'
                        className='form-control form-control-solid text-right'
                        placeholder='Unidades Imobiliárias'
                        register={register('unidades_imobiliarias')}
                        error={errors ? errors.unidades_imobiliarias?.message : ''}
                        onValueChange={(e) => {
                          //Utilize esse se form obrigatório
                          // if (value === "") {
                          //    setError("area_total_terreno", "Digite um valor")
                          //  } else {
                          setValue('unidades_imobiliarias', e.floatValue)
                          //  }
                        }}
                      /> */}
                    </div>
                  </div>

                  <div className='row'></div>

                  <div className='form-group '>

                    <FormInputNumericFormat
                      control={control}
                      label=' Área Privativa Média por Unidade'
                      className='form-control form-control-solid text-right'
                      inteiro={true}
                      placeholder='Área Privativa Média por Unidade'
                      register={register('area_privativa_por_unidade_imob')}
                      defaultValue={areaPrivativaMediaPorUnidade}
                      error={errors ? errors.area_privativa_por_unidade_imob?.message : ''}
                      onValueChange={(e) => {
                        //Utilize esse se form obrigatório
                        // if (value === "") {
                        //    setError("area_total_terreno", "Digite um valor")
                        //  } else {
                        setValue('area_privativa_por_unidade_imob', e.floatValue)
                        //  }
                      }}
                    />


                    {errors.nome && (
                      <ErrorMessageComponent
                        message={errors.area_privativa_por_unidade_imob?.message}
                      />
                    )}

                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={closeModal}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export { ModalMassaUsoCreate }
