import { useEffect, useState } from 'react';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { QueryGetPacotes } from '../../../graphql';

type Pacote = {
    id: number;
    nome: string;
}


type Props = {
    setValuePacote: React.Dispatch<React.SetStateAction<number[]>>;
    valuePacote: number[];
}

export function PacotesChosen({
    setValuePacote,
    valuePacote
}: Props) {
    const animatedComponents = makeAnimated();

    const { data, loading } = QueryGetPacotes({
        variables: {
            pagination: {
                pagina: 0,
                quantidade: 400
            }
        }
    });
    console.log(data)

    const [pacotes, setPacotes] = useState<Pacote[]>([]);

    useEffect(() => {
        if (data && data.GetPacotes && data.GetPacotes.result) {
            setPacotes(data.GetPacotes.result);
        }
    }, [data]);

    useEffect(() => {
        if (valuePacote.length > 0 && pacotes.length > 0) {
            const defaultValues = valuePacote.map(id => pacotes.find(pacote => pacote.id === id))
                .filter(pacote => pacote !== undefined)
                .map(categoria => ({
                    value: categoria.id,
                    label: categoria.nome
                }));
            setValuePacote(valuePacote);
            setSelectedOptions(defaultValues);
        }
    }, [valuePacote, pacotes, setValuePacote]);


    const options = pacotes.map(categoria => ({
        value: categoria.id,
        label: categoria.nome,
    }));
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

    return (
        <>
            <div className='form-group'>
                <label className='col-form-label'>Pacotes:</label>
                <Select
                    isDisabled={loading}
                    isMulti
                    components={animatedComponents}
                    options={options}
                    value={selectedOptions}
                    onChange={(selectedOptions: any) => {
                        setSelectedOptions(selectedOptions);
                        const selectedIds = selectedOptions.map((option: any) => option.value);
                        setValuePacote(selectedIds);
                    }}
                    name='id_pacote'
                    placeholder='Pacotes Disponíveis'
                />
            </div>
        </>
    );
}
