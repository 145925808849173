import {useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import {MutationCreateExplorerLayerUser} from '../../../../graphql/services/ExplorerLayerUser'
import Swal from 'sweetalert2'
import {ProdutosChosen} from './ProdutosChosen'
import {UsuariosChosen} from './UsuariosChosen'

interface Props {
  closeModal: any
  show: boolean
}

export function AdminAssociationCreateModal({closeModal, show}: Props) {
  const {id: IdLayer} = useParams<{id: string}>()

  const {FormExplorerLayerUser, handleSubmit, setValue, register} =
    MutationCreateExplorerLayerUser()
  const [valueUsuario, setValueUsuario] = useState(0)
  const [valueUsuarioProduto, setValueUsuarioProduto] = useState('0')
  const onSubmit = async (data) => {
    FormExplorerLayerUser(data)
      .then((result) => {
        Swal.fire('Sucesso!', 'Usuário asssociado com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  return (
    <Modal centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Incluir Permissão</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type='hidden'
            defaultValue={parseInt(IdLayer)}
            {...register('id_layer', {valueAsNumber: true})}
          />
          <UsuariosChosen
            valueUsuario={valueUsuario}
            setValueUsuario={(value: number) => {
              setValueUsuario(value)
              setValue('id_user', Number(value))
            }}
          />
          <ProdutosChosen
            valueUsuarioProduto={valueUsuarioProduto}
            setValueUsuarioProduto={(value: string) => {
              setValueUsuarioProduto(value)
              setValue('cod_plano', value)
            }}
          />

          {/* <MyAsyncSelect /> */}
          <div className='w-100 pt-6 all-end'>
            <Link to='/backoffice/explorer-layers' type='button' className='btn btn-danger me-4'>
              Cancelar
            </Link>
            <button type='submit' className='btn btn-success '>
              Salvar
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
