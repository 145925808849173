import noturnoJson from './styles/noturno.json'
import transporte from './styles/transporte.json'
import comercios from './styles/comercios.json'
import ligth from './styles/ligth.json'
import simples from './styles/simples.json'

export function CreateStyles(map) {
  const comerciosMapType = new google.maps.StyledMapType(comercios, {name: 'Comércios'})
  map.mapTypes.set('comercios_map', comerciosMapType)

  const onibusMapType = new google.maps.StyledMapType(transporte, {name: 'Ônibus'})
  map.mapTypes.set('onibus_map', onibusMapType)

  const noturnoMapType = new google.maps.StyledMapType(noturnoJson, {name: 'Noturno'})
  map.mapTypes.set('noturno', noturnoMapType)

  const ligthMapType = new google.maps.StyledMapType(ligth, {name: 'Ligth'})
  map.mapTypes.set('ligth', ligthMapType)

  const simplesMapType = new google.maps.StyledMapType(simples, {name: 'Simples'})
  map.mapTypes.set('simples', simplesMapType)
}
