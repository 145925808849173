import {Col} from 'react-bootstrap'
import {useContext, useEffect, useState} from 'react'
import {RangeSlider} from '../../../../components/UI'
import {formatNumber} from '../../../explorer/components/common'
import {SelectIncorporadoras} from '../../../explorer/components/Menu/FiltroPrincipal/components/SelectIncorporadoras'
import {SelectEstagioObra} from '../../../explorer/components/Menu/FiltroPrincipal/components/SelectEstagioObra'
import {FilterContext} from '../../context/FilterContext'
import SelectDormitoriosFiltro from '../UI/SelectDormitoriosFiltro'
import {KTSVG} from '../../../../../_metronic/helpers'
import {SelectTipologia} from '../UI/SelectTipologia'
import Swal from 'sweetalert2'
import {AreaInfluencia} from './AreaInfluencia/AreaInfluencia'

export function Filtro({height}: {height: number}) {
  const {endereco, filter, setFilterData} = useContext(FilterContext)
  const [filtroLocal, setFiltroLocal] = useState(null)

  useEffect(() => {
    setFiltroLocal({...filter})
  }, [filter])

  let marks = {}
  marks[2000] = 2000
  marks[2024] = 2024

  function onchangeFilter(filterInput: string, valor: string | number | []) {
    if (filtroLocal) {
      let fiterTemp = filtroLocal
      fiterTemp[filterInput] = valor
      setFiltroLocal(fiterTemp)
    }
  }

  function aplicarFiltro() {
    if (endereco.endereco_completo) {
      setFilterData({...filter, ...filtroLocal})
    } else {
      Swal.fire('', 'Digite um endereço.', 'warning')
    }
  }
  
  return (
    <>
      <div className='filtro2-fixed ' style={{height: height}}>
        <div className='filtro2-form   pt-5  p-0 ps-6 pe-10' style={{height: height - 40}}>
          <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_1'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>Área de influência</h3>
              </div>
              <div
                id='kt_accordion_2_item_1'
                className='fs-6 collapse show ps-1'
                data-bs-parent='#kt_accordion_2'
              >
                <Col sm='12' className='py-5'>
                  <AreaInfluencia
                    setFiltroLocal={setFiltroLocal}
                    filtroLocal={filtroLocal}
                  ></AreaInfluencia>
                </Col>
              </div>
            </div>
            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_2'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>Ano Lançamento</h3>
              </div>
              <div
                id='kt_accordion_2_item_2'
                className='collapse fs-6 ps-3'
                data-bs-parent='#kt_accordion_2'
              >
                <Col sm='12' className='py-2'>
                  <RangeSlider
                    onChange={(e) => {
                      onchangeFilter('anoLancamento', e)
                    }}
                    defaultValue={filter?.anoLancamento || [2019, 2024]}
                    min={2019}
                    max={2024}
                    step={1}
                    formatTip={(value) => `${value} `}
                    showHideImput={true}
                  />
                </Col>
              </div>
            </div>
            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_9'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'> Tipologia</h3>
              </div>
              <div
                id='kt_accordion_2_item_9'
                className='collapse fs-6 ps-3'
                data-bs-parent='#kt_accordion_2'
              >
                <Col sm='12' className='py-10'>
                  <SelectTipologia
                    onChange={(e) => {
                      onchangeFilter('tipologia', e)
                    }}
                    itensSelected={filter?.tipologia || null} // selected={filter?.dormitorios || null}
                  ></SelectTipologia>
                </Col>
              </div>
            </div>
            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_11'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>
                  Programa Minha Casa Minha Vida
                </h3>
              </div>
              <div
                id='kt_accordion_2_item_11'
                className='collapse fs-6 ps-3'
                data-bs-parent='#kt_accordion_2'
              >
                <Col sm='12' className='py-10'>
                  <select
                    onChange={(e) => {
                      if (e.target.value !== 'null') {
                        onchangeFilter('mcmv', Number(e.target.value))
                      } else {
                        onchangeFilter('mcmv', null)
                      }
                      
                    }}
                    defaultValue={  filter?.mcmv+'' || null }
                    className='form-control'
                  >
                    <option value='null'>Todos</option>
                    <option value='1'>Sim</option>
                    <option value='0'>Não</option>
                  </select>
                </Col>
              </div>
            </div>
            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_3'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>Dormitórios</h3>
              </div>
              <div
                id='kt_accordion_2_item_3'
                className='collapse fs-0 ps-3'
                data-bs-parent='#kt_accordion_2'
              >
                <Col sm='12' className='py-10'>
                  <SelectDormitoriosFiltro
                    onChange={(e) => {
                      onchangeFilter('dormitorios', e)
                    }}
                    selected={filter?.dormitorios || null}
                  ></SelectDormitoriosFiltro>
                </Col>
              </div>
            </div>
            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_4'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>Área Útil</h3>
              </div>
              <div
                id='kt_accordion_2_item_4'
                className='collapse fs-6 ps-3'
                data-bs-parent='#kt_accordion_2'
              >
                <Col sm='12' className='py-10'>
                  <RangeSlider
                    onChange={(e) => {
                      onchangeFilter('areaUtil', e)
                    }}
                    defaultValue={filter?.areaUtil}
                    min={10}
                    max={1000}
                    step={10}
                    formatTip={(value) => `${formatNumber(value, 'inteiro')} m²`}
                    showHideImput={true}
                  />
                </Col>
              </div>
            </div>

            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_5'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>Preço Total</h3>
              </div>
              <div
                id='kt_accordion_2_item_5'
                className='collapse fs-6 ps-3'
                data-bs-parent='#kt_accordion_2'
              >
                <Col sm='12' className='py-10'>
                  <RangeSlider
                    onChange={(e) => {
                      onchangeFilter('precoTotal', e)
                    }}
                    defaultValue={filter?.precoTotal}
                    min={100000}
                    max={30000000}
                    step={100000}
                    formatTip={(value) => {
                      if (value > 1000000) {
                        return `${value / 1000000}Mi `
                      } else {
                        return `${formatNumber(value, 'currency')} `
                      }
                    }}
                    showHideImput={true}
                  />
                </Col>
              </div>
            </div>
            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_6'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>Preço m²</h3>
              </div>
              <div
                id='kt_accordion_2_item_6'
                className='collapse fs-6 ps-3'
                data-bs-parent='#kt_accordion_2'
              >
                <Col sm='12' className='py-10'>
                  <RangeSlider
                    onChange={(e) => {
                      onchangeFilter('precoM2', e)
                    }}
                    defaultValue={filter?.precoM2}
                    min={0}
                    max={50000}
                    step={100}
                    formatTip={(value) => `${formatNumber(value, 'currency')} `}
                    showHideImput={true}
                  />
                </Col>
              </div>
            </div>
            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_7'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'> % Vendido</h3>
              </div>
              <div
                id='kt_accordion_2_item_7'
                className='collapse fs-6 ps-3'
                data-bs-parent='#kt_accordion_2'
              >
                <Col sm='12' className='py-10'>
                  <RangeSlider
                    onChange={(e) => {
                      onchangeFilter('percentualVendido', e)
                    }}
                    defaultValue={filter?.percentualVendido}
                    min={0}
                    max={100}
                    step={5}
                    formatTip={(value) => `${formatNumber(value, 'decimal')} % `}
                    showHideImput={false}
                  />
                </Col>
              </div>
            </div>
            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_10'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>Incorporadora</h3>
              </div>
              <div
                id='kt_accordion_2_item_10'
                className='collapse fs-6 ps-3'
                data-bs-parent='#kt_accordion_2'
              >
                <Col sm='12' className='py-10'>
                  <SelectIncorporadoras
                    onChange={(e) => {
                      onchangeFilter('incorporadora', e)
                      // onChangeFiltro('incorporador', e, 'update', 'select')
                    }}
                    itensSelected={filter?.incorporadora || null}
                  ></SelectIncorporadoras>
                </Col>
              </div>
            </div>
            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_8'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'> Estágio da Obra</h3>
              </div>
              <div
                id='kt_accordion_2_item_8'
                className='collapse fs-6 ps-3'
                data-bs-parent='#kt_accordion_2'
              >
                <Col sm='12' className='py-10'>
                  <SelectEstagioObra
                    onChange={(e) => {
                      onchangeFilter('estagioObra', e)
                      // onChangeFiltro('tipo', e, 'update', 'select')
                    }}
                    itensSelected={filter?.estagioObra || null}
                  ></SelectEstagioObra>
                </Col>
              </div>
            </div>
          </div>
        </div>
        <div className='all-start-between button-position px-5'>
          <div></div>
          {/* <button
            type='button'
            className='btn btn-secondary d-flex align-items-end'
            name='Avançar'
            value='Avançar'
          >
            Reiniciar 
          </button> */}
          <button
            type='button'
            className='btn btn-primary d-flex align-items-end'
            name='Avançar'
            value='Avançar'
            onClick={() => aplicarFiltro()}
          >
            Aplicar Filtro
          </button>
        </div>
        {/* : <></>
}  */}
      </div>
    </>
  )
}
