type Props1Item = {
  dadosEmpresa: {
    nomeFantasia: string | null
    cnpj: string | null
    endereco: string | null
    whatsapp: string | null
    emailContato: string | null
  }
}

export const HeaderCliente: React.FC<Props1Item> = ({dadosEmpresa}) => {
  return (
    <div className='d-flex flex-column flex-column-fluid text-right'>
      <h2 className='name'>{dadosEmpresa.nomeFantasia}</h2>
      {/* <div>CNPJ: {dadosEmpresa.cnpj}</div> */}
      {/* <div>{dadosEmpresa.endereco}</div> */}
      <div>Whatsapp: {dadosEmpresa.whatsapp}</div>
      <div>
        <a href='mailto:contato@urbit.com.br'>{dadosEmpresa.emailContato}</a>
      </div>
    </div>
  )
}
