import { useParams } from 'react-router-dom'
import { TableAdminUsuarioProduto } from '../../../partials/TableAdminUsuarioProduto'
import { ModalAssociationProduto } from '../../../partials/AssociationProduto'


export function Tab2() {
  const { id } = useParams<{ id: string }>()
  const idUser = parseInt(id)

  return (
    <div className='tab-pane fade pb-12' id='tab2' role='tabpanel' aria-labelledby='tab2-tab'>
      <div className="card mb-5 mb-xl-10">
        <div className="card-header">
          <div className="card-title">
            <h3>Produtos do Usuário</h3>
          </div>
          <div className="card-toolbar">
            <ModalAssociationProduto id_user={idUser} />
          </div>
        </div>
        <div className="pb-0">
          <TableAdminUsuarioProduto idUser={idUser} />

        </div>
      </div>
    </div>
  )
}

