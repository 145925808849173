import { useLazyQuery, useQuery } from "@apollo/client"
import { GET_LOG_ACESSO_RECURSOS_BY_EMPRESA } from "../querys/logAcessoQuery"
import { TypesGetLogAcessoRecursosByEmpresaResponse } from "../types/TypesLogAcesso"

interface Props {
  variables: any
}


export function QueryGetLogAcessoRecursosByEmpresa({ variables }: Props) {
    const { data, loading, error } = useQuery<TypesGetLogAcessoRecursosByEmpresaResponse>(GET_LOG_ACESSO_RECURSOS_BY_EMPRESA, {
      variables: { ...variables },
    })

    return {data, loading, error}
  }
  

  export function QueryGetLogAcessoRecursosExport() {
    const [getLogs, { loading, error, data }] = useLazyQuery<TypesGetLogAcessoRecursosByEmpresaResponse>(GET_LOG_ACESSO_RECURSOS_BY_EMPRESA)

    return {data, loading, error, getLogs}
  }