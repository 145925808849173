import {MapView} from '../MapView'
import {useContext, useEffect, useMemo, useState} from 'react'
import {DataContext} from '../../context/DataContext'
import {HeaderPerformance} from '../Common/HeaderPerformance'
import DataTable from 'react-data-table-component'
import {formatDistancia, formatNumber} from '../../../explorer/components/common'
import {FilterContext} from '../../context/FilterContext'
import {customStylesTable} from '../Common/common'
import {BiInfoCircle, BiPlus, BiTrash} from 'react-icons/bi'
import {Tipologia} from '../Types'
import styled from 'styled-components'
import {Button, Col, Form, Row} from 'react-bootstrap'
import {MaisInfoEmpreendimentos} from '../MaisInfoEmpreendimentos'
import {
  DefaultColumnFilter,
  FilterRange,
  RangeColumnFilter,
} from '../../../../components/UI/TableContainer/filters'
import TableContainer from '../../../../components/UI/TableContainer'
import {MapViewDesempenhoVendas} from '../MapViewDesempenhoVendas'
import SelectDormitorios from '../UI/SelectDormitorios'

type Props = {
  label?: string
}
type PropMenu = {
  show: boolean
  id: number
}
type PropFilter = {
  empreendimento: string
  incorporadora: string
}
export function PerformanceDesempenhoVendas({label}: Props) {
  const {data, removeItemTipologia, addItemTipologia} = useContext(DataContext)
  const {endereco, geometriaSelecionada} = useContext(FilterContext)
  const [dataComputados, setDataComputados] = useState([])
  const [dormitorios, setDormitorios] = useState(1)
  const [dataMapa, setDataMapa] = useState([])
  const [filterText, setFilterText] = useState('')
  // const [dataNaoComputados, setDataNaoComputados] = useState([])
  const [show, setShow] = useState<PropMenu>({
    show: false,
    id: null,
  })
  const layersList = [] //getLayers(['view_ibge_explorer_renda_mensal_domiciliar'], json)
  const dataTable = []
  // const dataTableNaoComputados = []

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      Filter: (row) => {
        // console.log(row)

        // if (filterText){
        //           row.columns[1].filterValue = filterText
        //           // row.columns[1].setFilter('id',filterText )
        // }
        return DefaultColumnFilter(row, filterText)
      },
    },
    {
      Header: () => 'Detalhes',
      id: 'expander', // 'id' is required
      Cell: ({row}) => {
        return (
          <button
            onClick={() =>
              setShow({
                show: true,
                id: row.original.id_empreendimento,
              })
            }
            className='btn btn-icon-info btn-text-info'
          >
            <BiInfoCircle className='fs-1'></BiInfoCircle>
          </button>
        )
      },
    },
    {
      Header: 'Empreendimento',
      accessor: 'nome',
    },
    {
      Header: 'Data Lanç.',
      accessor: 'data_lancamento',
      sortType: (rowA, rowB, columnId) => {
        var dateString = rowA.values[columnId] // Oct 23
        var dateParts = dateString.split('/')
        var dateObjectA = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

        var dateStringB = rowB.values[columnId] // Oct 23
        var datePartsB = dateStringB.split('/')
        var dateObjectB = new Date(+datePartsB[2], datePartsB[1] - 1, +datePartsB[0])

        // console.log(rowA.values[columnId])
        // console.log(rowB.values[columnId])
        // const dateA = new Date(rowA.values[columnId]);

        // console.log(dateA)
        // const dateB = new Date(rowB.values[columnId]);
        // console.log(dateB)
        return dateObjectA > dateObjectB ? 1 : -1
      },
    },
    {
      Header: 'Incorporadora',
      accessor: 'incorporadora',
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Área Privativa</div>,
      accessor: 'area_privativa',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}>
          {' '}
          {formatNumber(row.original.area_privativa, 'inteiro')}
        </div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Quartos</div>,
      accessor: 'quartos',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}> {formatNumber(row.original.quartos, 'inteiro')}</div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Vagas</div>,
      accessor: 'vagas',
      Cell: ({row}) => <div style={{textAlign: 'center'}}> {row.original.vagas}</div>,
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Preço m²</div>,
      accessor: 'preco_m2',
      Cell: ({row}) => (
        <div style={{textAlign: 'right'}}> {formatNumber(row.original.preco_m2, 'currency')}</div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Preço Total</div>,
      accessor: 'preco_total',
      Cell: ({row}) => {
        return (
          <div style={{textAlign: 'right'}}>
            {' '}
            {formatNumber(row.original.preco_total, 'currency')}
          </div>
        )
      },
      Filter: RangeColumnFilter,
      filter: FilterRange,
      className: 'text-center',
    },

    {
      Header: 'Tpo',
      accessor: 'tipo',
    },
    {
      Header: 'Bairro',
      accessor: 'bairro',
    },
    {
      Header: 'Endereço',
      accessor: 'endereco',
    },

    {
      Header: () => <div style={{textAlign: 'center'}}>Total unidades</div>,
      accessor: 'total_unidades',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}>
          {' '}
          {formatNumber(row.original.total_unidades, 'inteiro')}
        </div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>VSO</div>,
      accessor: 'vso',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}> {formatNumber(row.original.vso, 'inteiro')}</div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Estoque</div>,
      accessor: 'estoque',
      Cell: ({row}) => <div style={{textAlign: 'center'}}> {row.original.estoque}</div>,
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },

    {
      Header: () => <div style={{textAlign: 'center'}}>Distância</div>,
      accessor: 'distancia',
      Cell: ({row}) => {
        return (
          <div style={{textAlign: 'center'}}>
            {' '}
            {Number(formatNumber(row.original.distancia, 'inteiro'))}
          </div>
        )
      },
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
  ]

  useEffect(() => {
    let dataMapaTemp = []
    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      // eslint-disable-next-line array-callback-return
      item.tipologias.map((itemTipologia: Tipologia) => {
        if (itemTipologia.computed) {
          // console.log(itemTipologia.bedrooms)
          // console.log(dormitorios)
          // console.log(dataMapa)
          if (Number(itemTipologia.bedrooms) === dormitorios) {
            dataMapaTemp.push({
              id: itemTipologia.id_tipologia,
              id_empreendimento: item.id_empreendimento,
              nome: item.nome,
              incorporadora: item.nome_incorporador,
              endereco: item.endereco,
              quartos: itemTipologia.bedrooms,
              data_lancamento: item.data_lancamento,
              area_privativa: itemTipologia.private_area,
              total_unidades: itemTipologia.total_units,
              vso: itemTipologia.vendas_perc_tip,
              vendas_perc_tip: itemTipologia.vendas_perc_tip,
              estoque: itemTipologia.stock,
              vagas: itemTipologia.parking,
              preco_m2: itemTipologia.original_price / itemTipologia.private_area,
              preco_total: itemTipologia.original_price,
              bairro: item.bairro,
              distancia: item.distancia,
              tipo: itemTipologia.type,
              fase_projeto: item.fase_projeto,
              geom: item.geom,
            })
          }
        }
      })
      setDataMapa([...dataMapaTemp])
      // setDataComputados(dataTable)
      // setDataNaoComputados(dataTableNaoComputados)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dormitorios])

  if (!data) {
    return <>Carregando...</>
  }
  if (!dataMapa) {
    return <>Carregando...</>
  }
  function onChangeDormitorio(event) {
    setDormitorios(Number(event.target.value))
  }
  // console.log(dataMapa)
  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10'>
        <HeaderPerformance titulo={'Desempenho vendas'}>
          <div className='col-12 d-flex  justify-content-end align-items-center pb-5'>
            <div className='pe-5 pt-2'>
              <h6>Dormitórios</h6>
            </div>
            <SelectDormitorios onChange={onChangeDormitorio} defaultValue={1}></SelectDormitorios>
          </div>
        </HeaderPerformance>
        <div className='content'>
          <div className='row'>
            <div className='col-12'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <div className='map-painel d-flex w-100'>
                  <div className='mapa col-12'>
                    <MapViewDesempenhoVendas
                      data={dataMapa}
                      layer={[layersList]}
                      zoom={14}
                      height='500px'
                      center={{lat: endereco.latitude, lng: endereco.longitude}}
                      geometria={geometriaSelecionada}
                      onClickMarker={(id) => {
                        setFilterText(id + '')
                      }}
                    ></MapViewDesempenhoVendas>

                    <div className=' col-12 p-5 '>
                      <TableContainer columns={columns} data={dataMapa}></TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MaisInfoEmpreendimentos
        setShow={setShow}
        show={show}
        height={'calc(100vh)'}
      ></MaisInfoEmpreendimentos>
    </>
  )
}
