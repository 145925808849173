import React from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {BiInfoCircle, BiLinkExternal} from 'react-icons/bi'
import {CreateFormCorretor} from '../components/CreateFormCorretores'
import Swal from 'sweetalert2'
import {MutationDeleteEmpreendimentoImovel} from '../../../../../../graphql/services/EmpreendimentoImovel'
import {errorHandler} from '../../../../../../graphql/errorHandler'
import { MutationSetLogAcessoRecurso } from '../../../../../../graphql/services/LogAcesso'
import { useParams } from 'react-router-dom'

type Props = {
  id: number
  idEmpreendimentoImovel: number
  response:any
  OpenModalMeusImoveis:any
}

export const Buttons: React.FC<Props> = ({id, idEmpreendimentoImovel, response,OpenModalMeusImoveis}) => {
  const {id: idEmpreendimento} = useParams<{id: string}>()
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()
  const {HandleDeleteEmpreendimentoImovel, loading} = MutationDeleteEmpreendimentoImovel()


  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await HandleDeleteEmpreendimentoImovel({
          variables: {deleteEmpreendimentoImovelId: idEmpreendimentoImovel},
        })
          .then((result) => {
            if (!result.data) {
              let erroText = errorHandler(result.errors)
              Swal.fire('Erro!', erroText, 'warning')
            } else {
              FormSetLogAcessoRecurso({
                action: 'delete',
                ferramenta: 'empreendimento',
                funcionalidade: 'composicao-terrenos',
                url: `/empreendimentos/details/${idEmpreendimento}#composicao-terrenos`
              })
              Swal.fire('Sucesso!', 'Excluído com sucesso.', 'success')
            }
          })
          .catch((err) => {
            Swal.fire('Erro!', 'Ocorreu um erro inesperado, tente novamente mais tarde.', 'warning')
          })
      }
    })
  }

  function clickRow(idEmpreendimentoImovel) {
    if (document.getElementById(`detalhes_${idEmpreendimentoImovel}`).classList.contains('hide')) {
      document.getElementById(`detalhes_${idEmpreendimentoImovel}`).classList.remove('hide')
    } else {
      document.getElementById(`detalhes_${idEmpreendimentoImovel}`).classList.add('hide')
    }
  }

  function OpenVerMais(id) {
    window.open('/meus-imoveis/details/' + id + '#informacoes-lote', '_blank')
    FormSetLogAcessoRecurso({
      action: 'list',
      ferramenta: 'meus-imoveis',
      funcionalidade: 'informacoes-lote',
      url: `/meus-imoveis/details/${id}#informacoes-lote`
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <CreateFormCorretor id_terreno={id} />

        <button
          onClick={() => {
            OpenModalMeusImoveis(response)
            // clickRow(idEmpreendimentoImovel)
          }}
          className='btn btn-icon btn-light-success btn-sm me-2'
        >
          <BiInfoCircle className='fs-2'></BiInfoCircle>
          {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' /> */}
        </button>

        <button
          onClick={() => {
            OpenVerMais(id)
          }}
          className='btn btn-icon btn-light-success btn-sm me-2'
          title='Abrir Meus Imóvel'
        >
          <BiLinkExternal className='fs-2'></BiLinkExternal>
        </button>

        {/* <EditEmpreendimentoObservacao idObs={idObs} /> */}
        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          className='btn btn-icon btn-light-danger btn-sm me-2'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
