// index.tsx
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { AsideDefault } from '../../../_metronic/layout/components/aside/AsideDefault';
import { DrawerComponent } from '../../../_metronic/assets/ts/components';
import '../global.css';
import Header from './components/Header';
import { MapView } from './components/MapView';
import AnaliseMercadoMenu from './components/AnaliseMercadoMenu';
import MarketProvider from './context/MarketContext';

export function AnaliseMercado() {
  const [showMap, setShowMap] = useState(false);
  let latlng = []
  console.log(showMap)
  function handleShowMap() {
    setShowMap(true);
  }

  function handleShowPanel() {
    setShowMap(false);
  }


  return (
    <>
      <AsideDefault />
      <Header />
      <div className='wrapper d-flex flex-column flex-row-fluid' style={{ paddingTop: '70px' }}>
        <div className="row mx-0">
          <div className={`col-md-12 col-lg-5 p-0 ${showMap ? 'd-none d-lg-block' : ''}`}>
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <MarketProvider>
                <AnaliseMercadoMenu toggleShowMap={handleShowMap} showMap={showMap} />
              </MarketProvider>
            </div>
          </div>
          <div className={`col-md-12 col-lg-7 p-0 ${!showMap ? 'd-none d-lg-block' : ''}`}>
            <div className='MapContainer d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <div className='d-flex flex-root' style={{ height: 'calc(100vh - 70px)' }}>
                <div id='map' className='flex-row position-relative' style={{ width: '100%', height: '100%' }}>
                  {showMap && (
                    <button className='btn btn-primary position-absolute top-0 end-0 m-3 z-index-1' onClick={handleShowPanel}>
                      Mostrar Painel
                    </button>
                  )}
                  <MapView latLng={latlng} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
