export const RelatedPeople = ({data, estrutura}) => {
  let dateReturn = null

  if (typeof data['Result'] !== 'undefined') {
    if (data['Result'].length > 0) {
      dateReturn = data['Result'][0]['RelatedPeople']['PersonalRelationships']
    }
  } else {
    if (typeof data[0]['RelatedPeople'] !== 'undefined'){
      if (data[0]['RelatedPeople']['TotalRelationships'] > 0) {
        dateReturn = data[0]['RelatedPeople']['PersonalRelationships']
      }
    }
  }

  if (!dateReturn) {
    return <></>
  }

  return (
    <>
      {/* <h3 className="pt-10">{estrutura.titulo}</h3> */}

      <table className='table fs-7 gs-0'>
        <thead>
          <tr>
            <th className='fs-bold px-0'>Nome</th>
            <th className='fs-bold px-0'>Tipo Relacionamento</th>
            <th className='fs-bold px-0'>CPF</th>
          </tr>
        </thead>
        <tbody>
          {/* 
        {dateReturn?.map((item, index) => {
          return (
            <tr key={index}>{estrutura['colunsItens'].map((column, index2) => {
                return <td key={index2}>{item[column]}</td>
              })}
            </tr>
          )
        })} */}

          {dateReturn?.map((item, index) => {
            return (
              // eslint-disable-next-line array-callback-return
              <tr key={index}>
                {estrutura['colunsItens'].map((column, index2) => {
                  if (item.RelationshipType !== 'COLLEGECLASS') {
                    return (
                      <td key={index2} className='px-0'>
                        {item[column]}
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
