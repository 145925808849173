import { gql } from "@apollo/client";

export const PUT_CUPOM = gql`
mutation PutCupom($putCupomId: Float!, $cupom: cupom!) {
  PutCupom(id: $putCupomId, cupom: $cupom) {
    id
    codigo
  }
}
`

export const SET_CUPOM = gql`
mutation SetCupom($cupom: cupom!) {
  SetCupom(cupom: $cupom) {
    id
    codigo
  }
}
`

export const DELETE_CUPOM = gql`
mutation DeleteCupom($deleteCupomId: Float!) {
  DeleteCupom(id: $deleteCupomId) {
    id
    codigo
  }
}
`


