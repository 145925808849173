import { useContext, useState } from "react";
import { Button } from "../../../../components/UI/Buttons.tsx/Button";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useMarket } from "../../context/MarketContext";

interface Properties {
  title: string;
  description: string;
  image: string;
  onClick: () => void;
}

export function Card({ description, image, title, onClick }: Properties) {
  const [isFavorite, setIsFavorite] = useState(false);
  const toggleView = () => {
    setIsFavorite(!isFavorite);
  };
  return (
    <div className="card border" >
      <div className="position-relative">
        <img src={image} className="card-img-top" width='268px' height='140px' alt="..." />
        <button onClick={toggleView} className="btn position-absolute top-0 end-0" >
          {isFavorite ? <FaHeart size={20} color="#69C05B" /> : <FaHeart size={20} color="#f5f5f5" />}
        </button>
      </div>
      <div className="card-body px-4 pt-4">
        <h5 className="card-title">{title}</h5>
        <div>
          <span className="fs-4">R$53.9k | 62% | R$268.1 </span>
        </div>
      </div>
      <div className="card-footer px-4 pt-4">
        <div className="w-100 flex-row all-center-between">
          <Button onClick={
            onClick
            } className="mt-2 w-100" buttonStyle="button-primary">
            Ver Mais
          </Button>
        </div>
      </div>
    </div>

  )
}