import { gql } from "@apollo/client";


export const SET_RECUPERAR_SENHA = gql`
mutation SetRecuperarSenha($usuario: usuario!) {
    SetRecuperarSenha(usuario: $usuario) {
      token_troca_senha
    }
  }
`

export const PUT_RECUPERAR_SENHA = gql`
mutation PutEsqueciSenha($email: String!, $tokenTrocaSenha: String!, $newPassword: String!) {
  PutEsqueciSenha(email: $email, token_troca_senha: $tokenTrocaSenha, new_password: $newPassword) {
    name
    id
  }
}
`


