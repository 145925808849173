import { z } from "zod"

export const LoginSchema = z.object({
  email: z.string()
    .email('Formato de email incorreto')
    .min(8, 'Mínimo de 8 caracteres')
    .max(50, 'Máximo de 50 caracteres'),
  password: z.string()
    .min(4, 'Mínimo de 4 caracteres')
    .max(50, 'Máximo de 50 caracteres'),
})

export type LoginSchemaData = z.infer<typeof LoginSchema>
