import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  GoogleMap,
  useJsApiLoader,
  Polygon,
  OverlayView,
  Marker,
  InfoBox,
  Polyline,
} from '@react-google-maps/api'
import {APiUrbit} from '../../../services/api/apiUrbit'
import {GoogleMapsOverlay} from '@deck.gl/google-maps'
import Tootip from './Tootip'
import {Feature, TIsOpen} from './Types'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import CheckServices from './CheckServices'
import {loadIsocrona} from './Common/Isocrona'
import {CoordinatesToPath} from './Common/common'
import {CreateStyles} from '../../explorer/components/Map/Style/MapStyles'
import {MaisInfoEmpreendimentos} from './MaisInfoEmpreendimentos'

type PropsContextMenu = {
  data: any[]
  layer: any[]
  zoom?: number
  height: string
  center?: {
    lat: number
    lng: number
  }
  geometria: any
}

export function PathToCoordinates(path) {
  let paths = path.map(function (el) {
    return [el.lng, el.lat]
  })

  return paths
}
type PropMenu = {
  show: boolean
  id: number
}
//TODO: Adicionar legenda no mapa mapview componente dentr do inteligencia de mercado
// const libraries: Libraries = ['drawing', 'places']
export const MapView: React.FC<PropsContextMenu> = ({
  data,
  layer,
  zoom = 16,
  children,
  height = 'calc(65.8vh)',
  center = {
    lat: -23.56834983242366,
    lng: -46.65739491734664,
  },
  geometria,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = React.useState(null)
  // const [paths, setPaths] = React.useState(latLng)
  const polygonRef = useRef(null)
  const listenersRef = useRef([])
  const [isOpen, setIsOpen] = React.useState<TIsOpen>([])
  const [iscoronas, setIsocronas] = React.useState<Feature[]>([])
  const [show, setShow] = useState<PropMenu>({
    show: false,
    id: null,
  })
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  })

  const deckOverlay = new GoogleMapsOverlay({
    debug: true,
    style: {zIndex: 30},
    pickingRadius: 10,
    onmousemove: () => {
      // console.log('onmouse')
    },
  })
  deckOverlay.setProps({
    layers: [layer],
  })

  useEffect(() => {
    loadIsocrona(center, (data) => {
      setIsocronas(data.features)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center])

  const onLoad = useCallback(
    (map) => {
      deckOverlay.setMap(map)
      if (deckOverlay) {
        setMap(deckOverlay)
      }

      CreateStyles(map)
      map.setMapTypeId('simples')

      setMap(deckOverlay)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    null
  )

  const containerStyle = {
    height: '100vh',
  }

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  function onclick(e) {
    console.log('oinclick map')
    console.log(e)
  }

  const divStyle = {
    background: 'transparent',
    // border: "1px solid #ccc",
    // padding: 15
  }
  function handleMarkerHover(id: number) {
    if (isOpen.length > 0) {
      let existItem = isOpen.findIndex((item) => item.id === id)
      if (existItem !== -1) {
        isOpen.splice(existItem, 1)
      } else {
        isOpen.push({
          id: id,
          open: true,
        })
      }
      setIsOpen([...isOpen])
    } else {
      isOpen.push({
        id: id,
        open: true,
      })
      setIsOpen([...isOpen])
    }
  }

  var lineSymbol = {
    path: google.maps.SymbolPath.CIRCLE,
    fillOpacity: 1,
    scale: 2,
  }

  const optionsSelected = {
    fillColor: 'transparent',
    fillOpacity: 0.6,
    strokeColor: 'red',
    strokeOpacity: 2,
    strokeWeight: 2,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
    // strokeOpacity: 0,
    icons: [
      {
        icon: lineSymbol,
        offset: '0',
        repeat: '20px',
      },
    ],
  }

  return (
    <>
      {isLoaded ? (
        <>
          <GoogleMap
            mapContainerStyle={{...containerStyle, width: '100%', height: height}}
            center={center}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={onclick}
            options={{
              // mapId: 'a28cbce2ae82f5e3',
              mapTypeControl: true,
              mapTypeControlOptions: {
                mapTypeIds: [
                  'roadmap',
                  'satellite',
                  'hybrid',
                  'noturno',
                  'comercios_map',
                  'onibus_map',
                  'ligth',
                  'simples',
                  // 'a28cbce2ae82f5e3',
                ],
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                position: google.maps.ControlPosition.LEFT_TOP,
              },
            }}
          >
            <Marker
              position={center}
              animation={google.maps.Animation.DROP}
              icon={{
                scaledSize: new google.maps.Size(35, 35),
                url: toAbsoluteUrl('/media/placeholder.png'),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(25, 25),
              }}
            ></Marker>

            {geometria ? (
              // geometria.map((item, index) => {
              // return (
              <Polyline
                options={{
                  strokeColor: '#333',
                  strokeOpacity: 0,
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: '0',
                      repeat: '2px',
                    },
                  ],
                }}
                path={CoordinatesToPath(
                  geometria.type === 'MultiPolygon'
                    ? geometria.coordinates[0]
                    : geometria.geometry.coordinates[0]
                )}
                key={1}
                // options={optionsSelected}
              />
            ) : (
              // )
              // })
              <></>
            )}

            {iscoronas ? (
              iscoronas.map((item, index) => {
                return (
                  <Polyline
                    options={{
                      strokeColor: index === 0 ? '#094e08' : '#e10c0c',
                      strokeOpacity: 0,
                      icons: [
                        {
                          icon: lineSymbol,
                          offset: '0',
                          repeat: '10px',
                        },
                      ],
                    }}
                    path={CoordinatesToPath(item.geometry.coordinates[0])}
                    key={index}
                    // options={optionsSelected}
                  />
                )
              })
            ) : (
              <></>
            )}
            {data?.map((obj, i) => {
              if (obj.computed) {
                return (
                  <Marker
                    onClick={() => {
                      handleMarkerHover(obj.id)
                    }}
                    key={i}
                    position={{lat: obj.geom.coordinates[1], lng: obj.geom.coordinates[0]}}
                    label={{
                      text: obj.nome.split('-')[0],
                      className: `marker-label`,
                      color: '#4d3838',
                    }}
                    icon={{
                      scaledSize: new google.maps.Size(20, 20),
                      url: obj.removed
                        ? toAbsoluteUrl('/media/f81039c7-b68a-462c-b90a-ccbf0a11e4ec.png')
                        : toAbsoluteUrl('/media/75210de1-444b-4077-8a30-d4aa401ee39c.png'),
                      origin: new google.maps.Point(0, 0),
                      anchor: new google.maps.Point(0, 0),
                    }}
                    animation={google.maps.Animation.DROP}
                  >
                    {isOpen && isOpen.find((item) => item.id === obj.id) && (
                      <InfoBox
                        options={{
                          closeBoxURL: `xx`,
                          enableEventPropagation: true,
                          maxWidth: 440,
                          boxClass: 'infoBox2',
                        }}
                      >
                        <Tootip
                          dataImovel={obj}
                          onClose={handleMarkerHover}
                          showInfo={setShow}
                        ></Tootip>
                      </InfoBox>
                    )}
                  </Marker>
                )
              } else {
                return null
              }
            })}
            <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
              <div style={divStyle}></div>
            </OverlayView>

            {children}
            <div className='legenda-left' style={{right: '61px'}}>
              <ul className='list'>
                <li>
                  <div className='box-legenda' style={{border: '3px dotted #094e08'}}></div>
                  <div> 5min</div>
                </li>
                <li>
                  <div className='box-legenda' style={{border: '3px dotted #e10c0c'}}></div>
                  <div>10 min</div>
                </li>
              </ul>
            </div>
          </GoogleMap>

          <MaisInfoEmpreendimentos
            setShow={setShow}
            show={show}
            height={'calc(100vh)'}
          ></MaisInfoEmpreendimentos>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
