import Swal from 'sweetalert2'
import {
  CloseLoaderInfoOverlay,
  LoaderInfoOverlay,
} from '../../../components/UI/Loader/LoaderOverlay'
import * as turf from '@turf/turf'
import {ItensImoveisProps, Tipologia, TResultAPI} from '../components/Types'
import {useContext, useEffect} from 'react'
import {FilterContext} from '../context/FilterContext'
import {APiUrbit} from '../../../services/api/apiUrbit'
import {DataContext} from '../context/DataContext'

const api = new APiUrbit()

const useImoveis = () => {
  const {setData, data, imoveisAnalisados, naoComputados, checkItemTipologiaExistTrue} =
    useContext(DataContext)
  const {filter} = useContext(FilterContext)

  // useEffect(() => {
  //   if (data) {
  //     atualizaNaoComputados(data)
  //   }
  // }, [data])

  useEffect(() => {
    if (data) {
      let filterDataList = filterData(data)
      setData([...filterDataList])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  async function loadImoveis(geom: any, areaInfluencia: string, action?: any, geomIntersect?: any) {
    LoaderInfoOverlay('Carregando imóveis...')
    const centroid = turf.centroid(geom)
    const lat = centroid.geometry.coordinates[1]
    const lng = centroid.geometry.coordinates[0]

    if (!geom) {
      Swal.fire(
        '',
        'Erro ao tentar carregar um geometria, tente aplicar o filtro novamente.',
        'warning'
      )
      CloseLoaderInfoOverlay()
    }

    if (geom.type === 'Feature') {
      geom = geom.geometry
    }

    const intersectString = btoa(unescape(encodeURIComponent(JSON.stringify(geom))))
    // let url = `service/orulo-empreendimentos-tipologias/${lng}/${lat}?intersect=${intersectString}`
    let url = `service/launches-typologies/${lng}/${lat}?intersect=${intersectString}`
    await api.get(url, async (result: TResultAPI) => {
      
      if (result.itens.length) {
        let newResult = []
        result.itens.map((item, index: number) => {
          let exist =
            newResult.length > 0
              ? newResult.findIndex(
                  (itemFind) => itemFind.id_empreendimento === item.id_empreendimento
                )
              : -1

          let computed = filter.areaInfluencia.tipo === 'zonaValor' ? false : true

          if (exist === -1) {
            let geom = JSON.parse(item.geom)

            let empreendimento = {
              apts_por_andar: Number(item.apts_por_andar),
              area_privativa_maxima: Number(item.area_privativa_maxima),
              area_privativa_minima: Number(item.area_privativa_minima),
              data_lancamento: item.data_lancamento,
              data_preco: item.data_preco,
              distancia: Number(item.distancia),
              endereco: item.endereco,
              estoque: Number(item.estoque),
              geom: geom,
              id: item.id,
              id_empreendimento: item.id_empreendimento,
              id_empreendimento_orulo: item.id_empreendimento_orulo,
              nome: item.nome,
              nome_incorporador: item.nome_incorporador,
              numero_andares: Number(item.numero_andares),
              numero_torres: Number(item.numero_torres),
              preco_medio_por_area_privativa_atual: item.preco_medio_por_area_privativa_atual,
              situacao: item.situacao,
              tipo: item.tipo,
              total_unidades: Number(item.total_unidades),
              vendas: Number(item.vendas),
              vendas_perc: Number(item.vendas_perc),
              webpage: item.webpage,
              removed: filter.areaInfluencia.tipo === 'zonaValor' ? true : false,
              computed: computed,
              ano_lancamento: item.ano_lancamento,
              tipologias: [],
              original_price_minimo: Number(item.original_price_minimo),
              original_price_maximo: Number(item.original_price_maximo),
              breve_lancamento: item.breve_lancamento,
              fase_projeto: Number(item.fase_projeto),
              meses_ate_lancamento: Number(item.meses_ate_lancamento),
              meses_construcao: Number(item.meses_construcao),
              fase_projeto_ideal: Number(item.fase_projeto_ideal),
              data_atualizacao: Number(item.data_atualizacao),
              bairro: item.bairro,
              intersect: true,
              affordable_program: item.affordable_program,
            }
            // console.log(computed)
            empreendimento.tipologias.push({
              id_tipologia: item.id_tipologia,
              type: item.type,
              private_area: Number(item.private_area),
              bedrooms: Number(item.bedrooms),
              bathrooms: Number(item.bathrooms),
              suites: Number(item.suites),
              parking: Number(item.parking),
              stock: Number(item.stock),
              original_price: Number(item.original_price),
              discount_price: Number(item.discount_price),
              total_area: Number(item.total_area),
              total_units: Number(item.total_units),
              tipologia_urbit: item.tipologia_urbit,
              vendas_perc_tip: Number(item.vendas_perc_tip),
              preco_lancamento: Number(item.preco_lancamento),
              computed: computed,
            })

            newResult.push(empreendimento)
          } else {
            let tipologia = {
              id_tipologia: item.id_tipologia,
              type: item.type,
              private_area: Number(item.private_area),
              bedrooms: Number(item.bedrooms),
              bathrooms: Number(item.bathrooms),
              suites: Number(item.suites),
              parking: Number(item.parking),
              stock: Number(item.stock),
              original_price: Number(item.original_price),
              discount_price: Number(item.discount_price),
              total_area: Number(item.total_area),
              total_units: Number(item.total_units),
              tipologia_urbit: item.tipologia_urbit,
              vendas_perc_tip: Number(item.vendas_perc_tip),
              preco_lancamento: Number(item.preco_lancamento),
              computed: computed,
            }
            newResult[exist].tipologias.push(tipologia)
          }
          return item
        })

        if (newResult) {
          if (areaInfluencia === 'zonaValor') {
            let DadosFiltradosTemp = await action(newResult, geomIntersect)
            if (DadosFiltradosTemp) {
              let DadosFiltrados = filterData(DadosFiltradosTemp)
              DadosFiltradosTemp = removeComputados(DadosFiltrados)

              if (DadosFiltradosTemp) {
                setData([...DadosFiltradosTemp])
              } else {
                setData([...DadosFiltrados])
              }
            } else {
              setData([...newResult])
            }
          } else {
            let DadosFiltrados = filterData(newResult)
            DadosFiltrados = removeComputados(DadosFiltrados)
            setData([...DadosFiltrados])
          }
        }
        // let DadosFiltrados = filterData(newResult)

        // if (DadosFiltrados) {
        //   if (areaInfluencia === 'zonaValor') {
        //     let DadosFiltradosTemp = await action(DadosFiltrados, geomIntersect)
        //     DadosFiltradosTemp = removeComputados(DadosFiltradosTemp)
        //     console.log(DadosFiltradosTemp)
        //     if (DadosFiltradosTemp) {
        //       setData([...DadosFiltradosTemp])
        //     } else {
        //       setData([...DadosFiltrados])
        //     }
        //   } else {
        //     DadosFiltrados = removeComputados(DadosFiltrados)
        //     setData([...DadosFiltrados])
        //   }
        // }
      } else {
        Swal.fire(
          '',
          'Nenhum registro encontrado. Escolha outra região ou aumente a área de influência.',
          'warning'
        )
      }
      CloseLoaderInfoOverlay()
    })
  }

  function filterData(data) {
    
    data.map((item: ItensImoveisProps, index: number) => {
      if (item.intersect === true) {
        item.tipologias.map((itemTipologia: Tipologia) => {
          let passouFiltroAno = true
          if (
            item.ano_lancamento >= filter.anoLancamento[0] &&
            item.ano_lancamento <= filter.anoLancamento[1]
          ) {
            passouFiltroAno = true
          } else {
            passouFiltroAno = false
          }

          let passouFiltroArea = true
          if (
            itemTipologia.private_area >= filter.areaUtil[0] &&
            itemTipologia.private_area <= filter.areaUtil[1]
          ) {
            passouFiltroArea = true
          } else {
            passouFiltroArea = false
          }

          let passouMCMV = true
          
          if (item.affordable_program !== null) {
            if (item.affordable_program === Number(filter.mcmv)) {
              passouMCMV = true
            } else {
              passouMCMV = false
            }
          }

          let passouFiltroprecoPrecoM2 = true
          if (filter.precoM2[0] === 1000 && filter.precoM2[1] === 60000) {
            passouFiltroprecoPrecoM2 = true
          } else {
            if (itemTipologia.original_price) {
              if (
                itemTipologia.original_price / itemTipologia.private_area >= filter.precoM2[0] &&
                itemTipologia.original_price / itemTipologia.private_area <= filter.precoM2[1]
              ) {
                passouFiltroprecoPrecoM2 = true
              } else {
                passouFiltroprecoPrecoM2 = false
              }
            } else {
              passouFiltroprecoPrecoM2 = false
            }
          }

          let passouFiltroPreco = true
          if (filter.precoTotal[0] === 100000 && filter.precoTotal[1] === 30000000) {
            passouFiltroprecoPrecoM2 = true
          } else {
            if (
              itemTipologia.original_price >= filter.precoTotal[0] &&
              itemTipologia.original_price <= filter.precoTotal[1]
            ) {
              passouFiltroPreco = true
            } else {
              passouFiltroPreco = false
            }
          }
          let passouFiltroDromitorios = true
          if (filter.dormitorios?.length > 0) {
            if (filter.dormitorios.includes(Number(itemTipologia.bedrooms))) {
              passouFiltroDromitorios = true
            } else {
              passouFiltroDromitorios = false
            }
          }

          let passouFiltroEstagioObra = true
          if (filter.estagioObra?.length > 0) {
            if (filter.estagioObra.includes(item.situacao)) {
              passouFiltroEstagioObra = true
            } else {
              passouFiltroEstagioObra = false
            }
          }

          let passouFiltroType = true

          if (filter.tipologia?.length > 0) {
            if (filter.tipologia.includes(itemTipologia.type)) {
              passouFiltroType = true
            } else {
              passouFiltroType = false
            }
          }
          let passouFiltroIncorporadora = true

          if (filter.incorporadora?.length > 0) {
            if (filter.incorporadora.includes(item.nome_incorporador)) {
              passouFiltroIncorporadora = true
            } else {
              passouFiltroIncorporadora = false
            }
          }

          let passouFiltroprecoVendas_perc = true
          if (
            Number(itemTipologia.vendas_perc_tip) >= filter.percentualVendido[0] &&
            Number(itemTipologia.vendas_perc_tip) <= filter.percentualVendido[1]
          ) {
            passouFiltroprecoVendas_perc = true
          } else {
            passouFiltroprecoVendas_perc = false
          }

          // console.log('nome', item.nome)
          // console.log('tipologia', itemTipologia)
          // console.log('passouFiltroAno', passouFiltroAno)
          // console.log('passouFiltroArea', passouFiltroArea)
          // console.log('passouFiltroprecoPrecoM2', passouFiltroprecoPrecoM2)
          // console.log('passouFiltroprecoVendas_perc', passouFiltroprecoVendas_perc)
          // console.log('passouFiltroPreco', passouFiltroPreco)
          // console.log('passouFiltroDromitorios', passouFiltroDromitorios)
          // console.log('passouFiltroEstagioObra', passouFiltroEstagioObra)
          // console.log('passouFiltroType', passouFiltroType)
          // console.log('passouFiltroIncorporadora', passouFiltroIncorporadora)

          if (
            passouFiltroAno &&
            passouFiltroArea &&
            passouFiltroprecoPrecoM2 &&
            passouFiltroprecoVendas_perc &&
            passouFiltroPreco &&
            passouFiltroDromitorios &&
            passouFiltroEstagioObra &&
            passouFiltroType &&
            passouFiltroIncorporadora &&
            passouMCMV
          ) {
            itemTipologia.computed = true
          } else {
            itemTipologia.computed = false
          }

          return item
        })
      }
      return item
    })

    data.map((item: ItensImoveisProps, index: number) => {
      let find = item.tipologias.filter(
        (itemTipologia: Tipologia) => itemTipologia.computed === true
      )
      if (find.length > 0) {
        item.computed = true
        item.removed = false
        return item
      } else {
        item.computed = false
        item.removed = true
        return item
      }
    })
    return data
  }

  function clearData() {
    setData([])
  }

  function removeComputados(dataResult: ItensImoveisProps[]) {
    
    if (dataResult) {
      dataResult.map((item: ItensImoveisProps) => {
        item.tipologias.map((itemTipologia: Tipologia) => {
          if (naoComputados) {
            let find = naoComputados.find(
              (itemNaoEncontrado) => itemNaoEncontrado === itemTipologia.id_tipologia
            )

            if (find) {
              itemTipologia.computed = false
            } else {
              itemTipologia.computed = true
            }
          }

          return itemTipologia
        })
        if (checkItemTipologiaExistTrue(item.tipologias)) {
          item.computed = true
          item.removed = false
        } else {
          item.computed = false
          item.removed = true
        }

        return item
      })
    }

    return dataResult
  }

  function getIdTipologiasComputadas() {
    let computados = []
    if (data) {
      data.map((item: ItensImoveisProps) => {
        // eslint-disable-next-line array-callback-return
        item.tipologias.filter((itemTipologia: Tipologia) => {
          if (itemTipologia.computed === true) {
            computados.push(itemTipologia.id_tipologia)
          }
        })
        return item
      })
    } else {
      computados = []
    }

    return computados
  }

  function getImoveisAnalisados() {
    return imoveisAnalisados
  }

  return {
    loadImoveis,
    filterData,
    data,
    clearData,
    getIdTipologiasComputadas,
    getImoveisAnalisados,
  }
}

export default useImoveis
