import React from 'react'
import OverlayPopover from '../../../../components/UI/Popover'
import {FaInfoCircle, FaRegFileAlt} from 'react-icons/fa'
import {Dicionario} from '../../../../utils/Dicionario'
import {formatDistancia} from '../../../explorer/components/common'
import ReactHtmlParser from 'react-html-parser'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

type Props = {
  item: any
  keyItem: string
  text: string
  template:string
}

function unicodeToChar(text) {
  return text.replace(/\\u[\dA-F]{4}/gi, function (match) {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
  })
}

type Props1Item = {
  item: any[]
  text: string
  template:string

}
type PropsDisplayConfig = {
  itens:any[],
  template:string
}

export const RenderListElement: React.FC<Props1Item> = ({item, text}) => {
  return (
    <div className='mb-0 alert alert-dismissible bg-light-grey d-flex flex-column  p-0  border-bottom-1 border-bottom-dashed'>
      <div className='p-0 pt-5 pe-sm-10  flex-column pe-0 pe-sm-10'>
        <div className=' list-group-flush'>
          {item.map(function (key, index) {
            return (
              <div key={`item_` + index + '_' + key} className='row bg-light-secondary  mb-0'>
                <div className='col-md-10'>
                  {Object.keys(key).length > 1
                    ? // eslint-disable-next-line array-callback-return
                      Object.keys(key).map(function (list, indexList) {
                       
                        if (list !== 'id' &&  list !== 'geom' && list !== 'gid' && list !== 'geom_closest_point') {
                          return (
                            <p key={indexList}>
                              <FaRegFileAlt color='#d0d0d0' /> {Dicionario.translate(list)}:{' '}
                              {isNaN(key[list]) ? unicodeToChar(key[list]) : key[list]}{' '}
                            </p>
                          )
                        }
                      })
                    : ''}
                </div>
                <div className='col-md-2  text-right'>
                  {key['distancia'] ? (
                    <button
                      type='button'
                      className='position-absolute position-sm-relative  m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                    >
                      <span className='svg-icon svg-icon-1 svg-icon-primary'>
                        {formatDistancia(key['distancia'])}
                      </span>
                    </button>
                  ) : (
                    ''
                  )}
                </div>
                <div className='separator my-5'></div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export const DisplayConfig: React.FC<PropsDisplayConfig> = ({itens, template} ) => { 
  
  let htmlTemplate = template
  // eslint-disable-next-line array-callback-return
  Object.keys(itens).map(function (key, index) {
  if (key !== 'id' && key !== 'geom' && key !== 'distancia' && key !== 'geom_closest_point') {
    // htmlTemplate = template.replace("${"+key+"}",itens[key])
    htmlTemplate = htmlTemplate.replaceAll('${' + key + '}', (itens[key] as string) )

    // if (key !== 'id' && key !== 'geom' && key !== 'distancia' && key !== 'geom_closest_point') {
    //   console.log(item[0][key])
    //   return (
    //     <p className='' key={key + '_' + index}>
    //       <FaRegFileAlt color='#d0d0d0' /> {Dicionario.translate(key)} :{' '}

         
    //       {isNaN(item[0][key]) ? ReactHtmlParser(unicodeToChar(item[0][key])) : ReactHtmlParser(item[0][key])}
    //     </p>
    //   )
    // }
  }
  })

  htmlTemplate= htmlTemplate.replace('Column 1','').replace('Column 2','')
  console.log(htmlTemplate)
  
  // ReactHtmlParser(unicodeToChar(htmlTemplate))
  return (<div>{  <ReactMarkdown
    children={htmlTemplate}
    remarkPlugins={[remarkGfm]}
    rehypePlugins={[rehypeRaw]}
    components={{
      // Map `h1` (`# heading`) to use `h2`s.
      br: 'br',
      li: ({node, ...props}) => {
        console.log(node)
        return <li  {...props} ></li>
      },
      // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
      em: ({node, ...props}) => {
        console.log(node)
        return <i style={{color: 'red'}} {...props} />
      },
    }}
  ></ReactMarkdown>}</div>)
}



export const RenderItem1Element: React.FC<Props1Item> = ({item, text, template}) => {
  return (
    <div className='mb-0 alert alert-dismissible bg-light-grey d-flex flex-column  p-0   border-bottom-1 border-bottom-dashed'>
      <div className='row'>
        <div className='col-md-10'>
          <div className='d-flex flex-column pe-0 pe-sm-10'>
            <span>
              {item
                ? 
                template ==='' ? 
                // eslint-disable-next-line array-callback-return
                  Object.keys(item[0]).map(function (key, index) {
                    if (key !== 'id' && key !== 'geom' && key !== 'distancia' && key !== 'geom_closest_point') {
                      console.log(item[0][key])
                      return (
                        <p className='' key={key + '_' + index}>
                          <FaRegFileAlt color='#d0d0d0' /> {Dicionario.translate(key)} :{' '}

                         
                          {isNaN(item[0][key]) ? ReactHtmlParser(unicodeToChar(item[0][key])) : ReactHtmlParser(item[0][key])}
                        </p>
                      )
                    }
                  })
                
                 : 
                  
                <DisplayConfig itens={item[0]} template={template} />
                  
                : ''}
            </span>
          </div>
        </div>
        <div className='col-md-2  text-right'>
          {item[0]['distancia'] ? (
            <button
              type='button'
              className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
            >
              <span className='svg-icon svg-icon-1 svg-icon-primary'>
                {formatDistancia(item[0]['distancia'])}
              </span>
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export const RenderItem: React.FC<Props> = ({item, keyItem, text, template}) => {
  return (
    <>
      {Array.isArray(item) && item.length === 0 ? (
        <div className=' pt-5 ' style={{display: 'inline-block'}}>
          <OverlayPopover
            key={keyItem + 'overlayPopover'}
            keyChield={keyItem + 'overlayPopover'}
            title={text}
            content={
              'Durante a sincronização não foram encontradas informações sobre este serviços, ele pode não existir.'
            }
          >
            <div>
              <span className='pb-5 m-0'>
                <FaInfoCircle color='#d0d0d0' />
              </span>
              <span className='m-1'>Nada Consta</span>
            </div>
          </OverlayPopover>
          {/* <buttom  data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top" > */}
        </div>
      ) : Array.isArray(item) && item.length === 1 ? (
        <RenderItem1Element item={item} text={text} template={template}></RenderItem1Element>
      ) : Array.isArray(item) && item.length > 1 ? (
        <RenderListElement item={item} text={text} template={template}></RenderListElement>
      ) : (
        ''
      )}
    </>
  )
}
