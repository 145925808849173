import { HexColor, RGBAColor } from "../../Types/layers";
import colorbrewer from 'colorbrewer';

import {VizColorPalette} from './custom-color-range';
export type ColorMap = [string | number | null, HexColor][];
export type ColorLegends = {[key: string]: string};

export type ColorRange = {
    name?: string;
    type?: string;
    category?: string;
    colors: HexColor[];
    reversed?: boolean;
    colorMap?: ColorMap;
    colorLegends?: ColorLegends;
  };
  
const colorBrewerMap = Object.entries(colorbrewer.Spectral  ).reduce(
    (accu, [type, palettes]: [string, any]) => ({
      ...accu,
      ...palettes.reduce(
        (group, name) => ({
          ...group,
          [name]: type
        }),
        {}
      )
    }),
    {}
  );
const colorRanges: ColorRange[] = [...VizColorPalette];

for (const [keyName, colorScheme] of Object.entries(colorbrewer)) {
  if (keyName !== 'schemeGroups') {
    for (const [lenKey, colors] of Object.entries(colorScheme as any)) {
      colorRanges.push({
        name: `ColorBrewer ${keyName}-${lenKey}`,
        type: colorBrewerMap[keyName],
        category: 'ColorBrewer',
        colors: colors as HexColor[]
      });
    }
  }
}
  
export const COLOR_RANGES = colorRanges;
export const DEFAULT_COLOR_UI: HexColor[]  = ['#5A1846', '#900C3F', '#C70039', '#E3611C', '#F1920E', '#FFC300'];

export const DEFAULT_COLOR_RANGE = colorRanges.find(({name}) => name === 'Global Warming') || {
  name: 'Global Warming',
  type: 'SEQ',
  category: 'Uber',
  colors: ['#5A1846', '#900C3F', '#C70039', '#E3611C', '#F1920E', '#FFC300']
};

