import {InputHTMLAttributes, useRef} from 'react'
import {ErrorMessageComponent} from '../../../backoffice/components/UI/ErrorMessage'
import InputMask from 'react-input-mask';


interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  register?: any
  error?: any
  mask: string
  maskChar: string
  onValueChange?: any
  showHide?:boolean
}

export function   FormInputMaskAndLabel({label, register, error, mask, maskChar, onValueChange,showHide=true, ...props}: InputProps) {

  // const handleChange = (v: any) => {
  //   // setValue(v.floatValue)
  //   if (onValueChange) {
  //       onValueChange({...v, floatValue:  v.floatValue ? v.floatValue/100 : 0})
  //   }
  // }

  const myRef = useRef(null)
    return (
      <div className={`form-group ${(showHide ? '' : 'hide') }` }>
        {label ? (
        <label className='col-form-label'>{label}</label>
        ): (
          ''
        )}
        <InputMask 
        // onValueChange={handleChange}
         {...props} ref={myRef} refs={myRef}  {...register} mask={mask} maskChar={maskChar} />
        {error && <ErrorMessageComponent message={error} />}
      </div>
    )
 
}
