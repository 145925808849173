import { useQuery } from '@apollo/client'
import {  GET_EMPREENDIMENTO_CORRETORES} from '../query'

import { TypesGetEmpreendimentoCorretoresResponse } from '../type/empreendiemnto_corretor'

interface Props {
  variables: any
}

export function QueryGetEmpreendimentoCorretores({variables}: Props) {
  const {data} = useQuery<TypesGetEmpreendimentoCorretoresResponse>(GET_EMPREENDIMENTO_CORRETORES, {
    variables: {...variables},
  })
  return data
}
