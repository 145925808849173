import { useMutation, useQuery } from "@apollo/client"
import { GET_ADMIN_EMPRESA_USUARIOS, GET_ADMIN_EMPRESA_USUARIO_BY_ID } from "../query/empresa"
import { TypesDeleteAdminEmpresaUsuarios, TypesGetAdminEmpresaUsuarioByID, TypesGetAdminEmpresaUsuarios, TypesPutAdminEmpresaUsuarios, TypesSetAdminEmpresaUsuarios } from "../type/empresa"
import { useForm } from "react-hook-form"
import { setCorretorFormData } from "../formValidations"
import { zodResolver } from "@hookform/resolvers/zod"
import { putAdminEmpresaUsuarioFormData, putAdminEmpresaUsuarioFormSchema, setAdminEmpresaUsuarioFormData, setAdminEmpresaUsuarioFormSchema } from "../formValidations/Empresas"
import { DELETE_ADMIN_EMPRESA_USUARIO, PUT_ADMIN_EMPRESA_USUARIO, SET_ADMIN_EMPRESA_USUARIO } from "../mutations/empresa"

interface Props {
  variables: any
}

export function QueryGetAdminEmpresaUsuarios({variables}: Props) {
  const {data, loading, error} = useQuery<TypesGetAdminEmpresaUsuarios>(GET_ADMIN_EMPRESA_USUARIOS, {
    variables: {...variables},
  })
  return {data, loading, error}
}

export function QueryGetAdminEmpresaUsuarioByID({ variables }: Props) {
  const { data, error, loading } = useQuery<TypesGetAdminEmpresaUsuarioByID>(GET_ADMIN_EMPRESA_USUARIO_BY_ID, {
    variables: { ...variables }
  })

  return { data, error, loading }
}


export function MutationCreateAdminEmpresaUsuario() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<setAdminEmpresaUsuarioFormData>({
    resolver: zodResolver(setAdminEmpresaUsuarioFormSchema),
  })


  const [setAdminEmpresaUsuario, {error, loading, data: DataSetCorretor}] =
    useMutation<TypesSetAdminEmpresaUsuarios>(SET_ADMIN_EMPRESA_USUARIO, {
      refetchQueries: [GET_ADMIN_EMPRESA_USUARIOS],
    })

  async function FormAdminEmpresaUsuario(data: setAdminEmpresaUsuarioFormData) {
    await setAdminEmpresaUsuario({
      variables: {
        usuario: {...data},
      },
    })

  }

  return {register, handleSubmit, FormAdminEmpresaUsuario, loading, errors, error, DataSetCorretor}
}

export function MutationPutAdminEmpresaUsuario() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<putAdminEmpresaUsuarioFormData>({
    resolver: zodResolver(putAdminEmpresaUsuarioFormSchema),
  })

  const [putAdminEmpresaUsuario, {error, loading, data: DataSetCorretor}] =
    useMutation<TypesPutAdminEmpresaUsuarios>(PUT_ADMIN_EMPRESA_USUARIO, {
      refetchQueries: [GET_ADMIN_EMPRESA_USUARIOS],
    })

  async function FormAdminEmpresaUsuario(data: putAdminEmpresaUsuarioFormData) {
    await putAdminEmpresaUsuario({
      variables: {
        data: {...data},
      },
    })

  }

  return {register, handleSubmit, FormAdminEmpresaUsuario, loading, errors, error, DataSetCorretor}
}


export function MutationDeleteAdminEmpresaUsuario() {
  const [deleteAdminEmpresaUsuario, {loading, data, error}] = useMutation<TypesDeleteAdminEmpresaUsuarios>(
    DELETE_ADMIN_EMPRESA_USUARIO,
    {
      refetchQueries: [GET_ADMIN_EMPRESA_USUARIOS],
    }
  )

  async function HandleDeleteAdminEmpresaUsuario({variables}: Props) {
    try {
      const result = await deleteAdminEmpresaUsuario({
        variables: {...variables},
        refetchQueries: [GET_ADMIN_EMPRESA_USUARIOS],

      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteAdminEmpresaUsuario, loading, error, data}
}

