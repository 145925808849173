/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../../_metronic/partials'
import {getCSS, getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import SelectDormitorios from '../UI/SelectDormitorios'
import {Dicionario} from '../../../../utils/Dicionario'
// import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
// import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  title: string
  subtitle: string
  data: any
  onChange: any
}

export const BarChart: React.FC<Props> = ({
  title,
  subtitle,
  data,
  className,
  onChange,
  children,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(data, height, onChange))

    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data,chartRef, mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{subtitle}</span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          {/* {filtroDomitorio ? <SelectDormitorios onChange={onChange}></SelectDormitorios> : <></>} */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_5_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
        <div>{children}</div>
      </div>

      {/* end::Body */}
    </div>
  )
}

function getChartOptions(data: [], height: number, onChange: any): ApexOptions {
  return {
    series: data,
    // [
    // {
    //   name: 'Actual',
    //   data: [
    //     {
    //       x: '1Q Single',
    //       y: 65,
    //       goals: [
    //         {
    //           name: 'Expected',
    //           value: 70,
    //           strokeHeight: 5,
    //           strokeColor: '#6e2a2a'
    //         }
    //       ]
    //     },
    //     {
    //       x: '2Q',
    //       y: 32,
    //       goals: [
    //         {
    //           name: 'Expected',
    //           value: 60,
    //           strokeHeight: 5,
    //           strokeColor: '#6e2a2a'
    //         }
    //       ]
    //     },
    //     {
    //       x: '3Q',
    //       y: 29,
    //       goals: [
    //         {
    //           name: 'Expected',
    //           value: 70,
    //           strokeHeight: 5,
    //           strokeColor: '#6e2a2a'
    //         }
    //       ]
    //     },
    //     {
    //       x: '4Q',
    //       y: 22,
    //       goals: [
    //         {
    //           name: 'Expected',
    //           value: 60,
    //           strokeHeight: 5,
    //           strokeColor: '#6e2a2a'
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   name: 'Actual',
    //   data: [
    //     {
    //       x: '1Q Single',
    //       y: 10,
    //       goals: [
    //         {
    //           name: 'Expected',
    //           value: 70,
    //           strokeHeight: 5,
    //           strokeColor: '#6e2a2a'
    //         }
    //       ]
    //     },
    //     {
    //       x: '2Q',
    //       y: 8,
    //       goals: [
    //         {
    //           name: 'Expected',
    //           value: 60,
    //           strokeHeight: 5,
    //           strokeColor: '#6e2a2a'
    //         }
    //       ]
    //     },
    //     {
    //       x: '3Q',
    //       y: 8,
    //       goals: [
    //         {
    //           name: 'Expected',
    //           value: 70,
    //           strokeHeight: 5,
    //           strokeColor: '#6e2a2a'
    //         }
    //       ]
    //     },
    //     {
    //       x: '4Q',
    //       y: 8,
    //       goals: [
    //         {
    //           name: 'Expected',

    //           value: 60,
    //           strokeHeight: 5,
    //           strokeColor: '#6e2a2a'
    //         }
    //       ]
    //     }
    //   ]
    // }
    // ]
    chart: {
      height: 350,
      type: 'bar',
      events: {
        dataPointMouseEnter: function (event, chartContext, config) {
          let data = config.w.config.series[0].data[config.dataPointIndex]
          onChange(data.id)
        },
      },
    },

    plotOptions: {
      bar: {
        columnWidth: '60%',
      },
    },
    colors: ['#a7a7a7', '#525252'],
    // dataLabels: {
    //   enabled: false
    // },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + '%'
      },
      offsetY: 0,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['VSO', '% Estoque.'],
      markers: {
        fillColors: ['#a7a7a7', '#525252', '#6e2a2a'],
      },
    },
    yaxis: {
      min: 0,
      max: 100,
    },
  }
}
