import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {BiSync} from 'react-icons/bi'
import {
  MutationCreateProprietario,
  MutationCreateProprietarioAndAssociationImovel,
} from '../../../graphql/services/Proprietarios'
import Swal from 'sweetalert2'
import {AddressesExtended} from './AddressesExtended'
import {dadosTabelas} from './dadosTabelas'
import {PhonesExtended} from './PhonesExtended'
import {EmailsExtended} from './EmailsExtended'
import {RelatedPeople} from './RelatedPeople'
import {RelatedPeopleEmails} from './RelatedPeopleEmails'
import {Loader} from '../../../components/UI/Loader/Loader'

interface proprietarioProps {
  data: any
  origin: 'Pessoas' | 'Imoveis' | 'Empreendimentos'
  idTerreno: number
}

export function ModalPH3aData({data, origin, idTerreno}: proprietarioProps) {
  const [show, setShow] = useState(false)
  const [loadingSync, setLoadingSync] = useState(false)
  const [resultPeapleData, setResultPeapleData] = useState(null)
  const [erroSyncVerify, setErrorVerify] = useState(null)

  const {FormProprietarios, loading} =
    MutationCreateProprietario()
  const {FormProprietarioImovel, loading: LoadingProprietarioImovel} =
    MutationCreateProprietarioAndAssociationImovel()
  function closeModal() {
    setResultPeapleData(null)
    setShow(false)
  }

  function openModal() {
    setShow(true)
  }
  
  async function syncDataPeaplePH3a(id: any) {
    const sendData = {Document: id, Type: 4}
    const response = await fetch(process.env.REACT_APP_API_URL_EXTRA_SERVICES + 'peaple-data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(sendData),
    })

    const result = await response.json()
    if (!result.error) {
      if (result.error) {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
        closeModal()
      } else {
        setLoadingSync(false)
        setResultPeapleData(result.data)
      }
    } else {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      closeModal()
    }
    setLoadingSync(false)
  }
  function savePessoa() {
    const proprietario = {
      nome: resultPeapleData.Data.Name,
      cpf:
        resultPeapleData.Data.DocumentType === 0
          ? resultPeapleData.Data.DocumentFormatted
          : undefined,
      cnpj:
        resultPeapleData.Data.DocumentType === 1
          ? resultPeapleData.Data.DocumentFormatted
          : undefined,
      ph3a_data: JSON.stringify(resultPeapleData.Data),
      basic_data: JSON.stringify(resultPeapleData.basic_data),
      emails_extended: JSON.stringify(resultPeapleData.emails_extended),
      addresses_extended: JSON.stringify(resultPeapleData.addresses_extended),
      phones_extended: JSON.stringify(resultPeapleData.phones_extended),
      related_people: JSON.stringify(resultPeapleData.related_people),
      related_people_emails: JSON.stringify(resultPeapleData.related_people_emails),
      related_people_phones: JSON.stringify(resultPeapleData.related_people_phones),
    }

    FormProprietarios(proprietario)
      .then((result) => {
        if (result) {
          Swal.fire('Sucesso!', 'Proprietário criado com sucesso.', 'success')
          closeModal()
        } else {
          Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
          closeModal()
        }
      })
      .catch((error) => {
        setErrorVerify('Ocorreu um erro durante o processo.')
        closeModal()
      })
  }

  function saveProprietario() {
    const proprietario = {
      nome: resultPeapleData.Data.Name,
      cpf:
        resultPeapleData.Data.DocumentType === 0
          ? resultPeapleData.Data.DocumentFormatted
          : undefined,
      cnpj:
        resultPeapleData.Data.DocumentType === 1
          ? resultPeapleData.Data.DocumentFormatted
          : undefined,
      ph3a_data: JSON.stringify(resultPeapleData.Data),
      basic_data: JSON.stringify(resultPeapleData.basic_data),
      emails_extended: JSON.stringify(resultPeapleData.emails_extended),
      addresses_extended: JSON.stringify(resultPeapleData.addresses_extended),
      phones_extended: JSON.stringify(resultPeapleData.phones_extended),
      related_people: JSON.stringify(resultPeapleData.related_people),
      related_people_emails: JSON.stringify(resultPeapleData.related_people_emails),
      related_people_phones: JSON.stringify(resultPeapleData.related_people_phones),
    }

    FormProprietarioImovel(idTerreno, proprietario)
      .then((result) => {
        if (result) {
          Swal.fire('Sucesso!', 'Proprietário criado com sucesso.', 'success')
          closeModal()
        } else {
          Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
        }
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  let typeDoc = ''
  if (resultPeapleData) {
    typeDoc = resultPeapleData.Data.DocumentType === 0 ? 'cpf' : 'cnpj'
  }

  console.log(resultPeapleData)
  console.log(typeDoc)
  return (
    <>
      <button
        onClick={() => {
          openModal()
          setLoadingSync(true)
          syncDataPeaplePH3a(data)
        }}
        className='btn btn-sm btn-light-primary'
      >
        <BiSync className='fs-2'></BiSync>
      </button>
      <Modal centered animation size='xl' show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Nova Consulta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card-body'>
            {loadingSync ? (
              <div className='w-100 badge '>
                <Loader text='carregando...' />
              </div>
            ) : (
              <></>
            )}
            {resultPeapleData ? (
              !resultPeapleData.error ? (
                <>
                  <div>
                    <h3 className='fw-semibold mb-5'>Dados Pessoais</h3>

                    {resultPeapleData.Data.DocumentType === 0 ? (
                      <>
                        <p className='fs-6'>Nome: {resultPeapleData.Data.Name} </p>
                        <p className='fs-6'>Idade: {resultPeapleData.Data.Age} </p>
                        <p className='fs-6'>
                          Documento: {resultPeapleData.Data.DocumentFormatted}{' '}
                        </p>
                        <p className='fs-6'>Mãe: {resultPeapleData.Data.Person.MotherName} </p>
                        <p className='fs-6'>Renda: {resultPeapleData.Data.Income.Presumed} </p>
                      </>
                    ) : (
                      <>
                        <p className='fs-6'>Nome: {resultPeapleData.Data.Name} </p>
                        <p className='fs-6'>Idade: {resultPeapleData.Data.Age} </p>
                        <p className='fs-6'>
                          Documento: {resultPeapleData.Data.DocumentFormatted}{' '}
                        </p>
                      </>
                    )}

                    <h3 className='fw-semibold mt-15  mb-5'>Telefones</h3>

                    {resultPeapleData.Data.Phones && resultPeapleData.Data.Phones.length > 0 ? (
                      <>
                        {resultPeapleData.Data.Phones.map((phones, index) => {
                          return (
                            <div key={index}>
                              <p className='fs-6'>
                                Telefone: {phones.FormattedNumber} - Operador {phones.Operator}
                                <br />
                                Whatsapp: {phones.IsWhatsapp ? 'Sim' : 'Não'}
                              </p>
                            </div>
                          )
                          // }
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                    {resultPeapleData.phones_extended &&
                    resultPeapleData.phones_extended.length > 0 ? (
                      <PhonesExtended
                        data={resultPeapleData.phones_extended}
                        estrutura={dadosTabelas[typeDoc]['PhonesExtended']}
                      ></PhonesExtended>
                    ) : (
                      <></>
                    )}
                    <h3 className='fw-semibold mt-15  mb-5'>E-mails</h3>
                    {resultPeapleData.emails_extended &&
                    resultPeapleData.emails_extended.length > 0 ? (
                      <EmailsExtended
                        data={resultPeapleData.emails_extended}
                        estrutura={dadosTabelas[typeDoc]['EmailsExtended']}
                      ></EmailsExtended>
                    ) : (
                      <div>-</div>
                    )}

                    <h3 className='fw-semibold mt-15  mb-5'>Pessoas Relacionadas</h3>
                    {resultPeapleData.related_people &&
                    resultPeapleData.related_people.length > 0 ? (
                      <RelatedPeople
                        data={resultPeapleData.related_people}
                        estrutura={dadosTabelas[typeDoc]['RelatedPeople']}
                      ></RelatedPeople>
                    ) : (
                      <>Nada Consta</>
                    )}
                    <h3 className='fw-semibold mt-15  mb-5'>E-mails Pessoas Relacionadas</h3>
                    {resultPeapleData.related_people_emails &&
                    resultPeapleData.related_people_emails.length > 0 ? (
                      <RelatedPeopleEmails
                        data={resultPeapleData.related_people_emails}
                        estrutura={dadosTabelas[typeDoc]['RelatedPeopleEmails']}
                      ></RelatedPeopleEmails>
                    ) : (
                      <>Nada Consta</>
                    )}
                    <h3 className='fw-semibold mt-15  mb-5'>Telefones Pessoas Relacionadas</h3>
                    {resultPeapleData.related_people_phones &&
                    resultPeapleData.related_people_phones.length > 0 ? (
                      <RelatedPeopleEmails
                        data={resultPeapleData.related_people_phones}
                        estrutura={dadosTabelas[typeDoc]['RelatedPeoplePhones']}
                      ></RelatedPeopleEmails>
                    ) : (
                      <div>Nada Consta</div>
                    )}
                    <h3 className='fw-semibold mt-15  mb-5'>Endereços</h3>
                    {resultPeapleData.addresses_extended &&
                    resultPeapleData.addresses_extended.length > 0 ? (
                      <AddressesExtended
                        data={resultPeapleData.addresses_extended}
                        estrutura={dadosTabelas[typeDoc]['AddressesExtended']}
                      ></AddressesExtended>
                    ) : (
                      <></>
                    )}

                    {resultPeapleData.Data.Addresses &&
                    resultPeapleData.Data.Addresses.length > 0 ? (
                      resultPeapleData.Data.Addresses.map((address, index) => {
                        return (
                          <div key={index}>
                            <p className='fs-6'>Endereço: {address.Alias}</p>
                            <p className='fs-6'>Complemento: {address.ComplementFormatted}</p>
                            <p className='fs-6'>Distrito: {address.District}</p>
                            <p className='fs-6'>Endereço: {address.City}</p>
                            <p className='fs-6'>Estado: {address.State}</p>
                            <p className='fs-6'>
                              <hr />
                            </p>
                          </div>
                        )
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : erroSyncVerify ? (
                <div>{erroSyncVerify}</div>
              ) : (
                <div>Carregando....</div>
              )
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {origin === 'Imoveis' || origin === 'Empreendimentos' ? (
            <button
              disabled={LoadingProprietarioImovel}
              className='btn btn-success'
              onClick={saveProprietario}
            >
              {LoadingProprietarioImovel ? (
                <span className='spinner-border text-primary' role='status'></span>
              ) : (
                'Definir Propriétario'
              )}
            </button>
          ) : (
            <button disabled={loading} className='btn btn-primary' onClick={savePessoa}>
              {loading ? (
                <span className='spinner-border text-primary' role='status'></span>
              ) : (
                'Salvar'
              )}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}
