import React, { useEffect } from 'react'

import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import { MenuComponent } from '../assets/ts/components'


const MasterLayout: React.FC = ({ children }) => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <Content>{children}</Content>
        </div>
        <div id="loader" className='hide'>
          {/* <div id='loader-title'></div> */}
          <div id='loader-text'></div>
        </div>
        <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }
