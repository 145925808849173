import {useRef, useState} from 'react'
import {Layout} from '../../Layout'
import { BiCheck, BiCopy } from 'react-icons/bi'

type PropsContextMenu = {
  showHide: boolean
  latLng: google.maps.LatLng | null
  geomList:[]
   layers:any
}

export const ContextMenuGeometry: React.FC<PropsContextMenu> = ({showHide, latLng,geomList,layers}) => {
  const show = useRef<boolean>()
  const [copy,setCopy] = useState(false)
  show.current = showHide

  function onClickTerritorioEmDados() {
    Layout.setShowTerritorioMenu(true, latLng)
    Layout.setShowClickInfo(false)
    Layout.setShowGeometryMenu(false)
    Layout.setShowGeometryMenuArea(false)
  }
 

  function onCopyLocation(text) {
    navigator.clipboard.writeText(text)
    setCopy(true)
    return false
  }

  function onClickEstatistica(layer) {
    Layout.setShowGeometryMenuArea(false)
    Layout.setShowGeometryMenu(false)


    // console.log(layers)
    layers.map(function(layer, y){
      // console.log(layer.type)
      if (layer.type === 'MVTLayer') {
      //  console.log(layer)
      }
    })

    // console.log(geomList)
    // console.log(layer)
    // console.log(geomList)
    // console.log(layers)
    // // eslint-disable-next-line array-callback-return
    // let geomItem  = geomList.filter(function(l){
    //   console.log(l)
    //   console.log(layer)
    //   console.log(layer)
    //   if(l['layer']['id'] === layer || l['id'] === layer){
    //     return  l
    //   }
    // })
    // console.log(geomItem)

    // Layout.setShowEstatisticasMenu(true, latLng, geomItem[0]['object']['geometry'])
  }

  function hideMenuOnClick(){
    Layout.setShowContextMenu(false)
    Layout.setShowGeometryMenu(false)
  }
// console.log(layers)
  const url = '#'
  return (latLng ? 
    <>
      <div>
        <ul className={`dropdown-menu dropdown-context show menu`}   onClick={(e) => {
            e.stopPropagation()
            //console.log('Click na ul do context')
          }}>
          <li className='nav-header'>Mais Informações</li>
          <li>
            <a href={url} className='context-event' onClick={onClickTerritorioEmDados}>
              Território em dados
            </a>
          </li>
          <li>
            <a href={url} className='context-event'        
              data-bs-target='#modal-painel-imobiliario'
              data-bs-toggle='modal' onClick={hideMenuOnClick}>
              Painel de Mercado
            </a>
          </li>
          <li>
            <a href={url} className='context-event'        
              data-bs-target='#kt_modal_avm'
              data-bs-toggle='modal' onClick={hideMenuOnClick} >
              AVM
            </a>
          </li>
          <li className='divider'></li>
          <li className='nav-header'>Operações</li>
          {/* <li> */}
            {/* <a
              href={url}
              className='context-event'
              // data-bs-target='#modal-intersect'
              // data-bs-toggle='modal'
              onClick={()=>{
                onClickEstatistica(1)
              }}
            >
              Estatisticas
            </a> */}
            {/* <ul className='dropdown-menu '>
            <li className='nav-header'>Da camada</li>
              {
              // eslint-disable-next-line array-callback-return
              layers.map(function(layer, y){
                // console.log(layer.type)
                if (layer.type === 'MVTLayer') {
                  return (<li key={y} ><a href={url}   onClick={()=>{
                    onClickEstatistica(layer.id)
                    
                  
                  }  } className='context-event'>{layer.nome}</a></li>)
                }
              })}
              
            </ul> */}
          {/* </li> */}
          <li>
            <a
              href={url}
              className='context-event'
              data-bs-target='#modal-isocrona'
              data-bs-toggle='modal'
            >
              Isócrona
            </a>
          </li>
          <li>
            <a
              href={url}
              className='context-event'
              data-bs-target='#modal-isocota'
              data-bs-toggle='modal'
            >
              Isocota
            </a>
          </li>
          <li>
            <a
              href={url}
              className='context-event'
              data-bs-target='#modal-raio'
              data-bs-toggle='modal'
            >
              Raio
            </a>
          </li>
          <li className='divider'></li>
          <li className='nav-header'>Local</li>
          <li>
          <a href={url} className='context-event' onClick={()=>{
              const text = latLng.lat().toFixed(5) +','+ latLng.lng().toFixed(5)
              onCopyLocation(text)
            }}>
            {copy ? <BiCheck className="fs-1 text-success"></BiCheck> : <BiCopy className="fs-1"></BiCopy>} {latLng.lat().toFixed(5)}, {latLng.lat().toFixed(5)} 
              
            </a>
            </li>
        </ul>
      </div>
    </>: <></>
  )
}
