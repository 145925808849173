import {FC, useContext, useEffect, useState} from 'react'
import {AvmContext} from './context/AvmContext'
import {BiCheck, BiX} from 'react-icons/bi'
import {Loader} from '../../Loader/Loader'
import {useAvm} from './hooks/useAvm'

type Props = {}

export const AvmPasso3: FC<Props> = () => {
  const {formAvm, result, setResult, dummies, avmConfig} = useContext(AvmContext)
  const [loading, setLoading] = useState(true)
  const {syncAvm, syncAvmGam} = useAvm()

  useEffect(() => {
    setLoading(true)
  }, [])

  useEffect(() => {
    async function calcAvm() {
      if (avmConfig.avm === 'tajmahal') {
        const result = await syncAvm(formAvm)
        setResult(result)
      } else {
        let withDummies = false
        let formWithDummies = formAvm

        // eslint-disable-next-line array-callback-return

        if (!avmConfig.ignoreDummies) {
          // eslint-disable-next-line array-callback-return
          dummies.map((dummie) => {
            if (dummie.selecionado) {
              withDummies = true
            }
            formWithDummies[dummie.key] = dummie.selecionado ? 1 : 0
          })

          // eslint-disable-next-line array-callback-return
          dummies.map((dummie) => {
            if (dummie.naoSei) {
              withDummies = false
            }
          })
        } else {
          // eslint-disable-next-line array-callback-return
          dummies.map((dummie) => {
            delete formWithDummies[dummie.key]
          })
        }

        const response = await syncAvmGam({
          dados: withDummies ? formWithDummies : formAvm,
          finalidade: 'venda',
          dummies: withDummies ? 'cd' : 'sd',
        })

        try {
          const result = {
            error: response.error,
            data: [
              {
                central: response.data[0].central,
                minimo: response.data[0].minimo,
                maximo: response.data[0].maximo,
                qtde_amostras: response.data[0].qtde_amostras,
                modelo: response.data[0].modelo,
              },
            ],
            message: response.message,
          }

          setResult(result)
        } catch (e) {
          setResult({error: true, data: []})
        }
      }
      setLoading(false)
    }
    calcAvm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!result) {
    return <Loader text={'Avaliando Imóvel'}></Loader>
  }

  if (result.error) {
    return <div className='alert alert-warning'>Erro ao retornar os dados.</div>
  }

  // if (result.error) {
  //   return <div className='alert alert-warning'>Erro ao retornar os dados</div>
  // }

  if (loading) {
    return <Loader text={'Avaliando Imóvel'}></Loader>
  }
  const resultAvaliacao = result.data[0]

  return (
    <div>
      <div className=' pb-1 lh-lg '>
        <div className='pb-5'>
          <h1>Modelo:{resultAvaliacao.modelo === 'gam' ? 'GAM' : 'TAJMAHAL'}</h1>
          {/* {resultAvaliacao.modelo === 'gam' ?  <p>Modelo gam consiste em .....</p> : ''}
        {resultAvaliacao.modelo === 'tajmahal' ?  <p>Modelo tajmahal consiste em .....</p> : ''} */}
        </div>

        <div className=' bg-ligth-primary text-center alert alert-success'>
          <p className='font-destaque'>Preço médio</p>
          <div className=''>
            <span className='fs-1'>
              <span>
                {resultAvaliacao.central
                  ? resultAvaliacao.central.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 'R$ - '}
              </span>
              /m2
            </span>
          </div>
          <div className=' text-center'>
            <div>
              <span className='fs-5'>
                {resultAvaliacao.central
                  ? (resultAvaliacao.central * formAvm.area).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 'R$ - '}
              </span>
            </div>
          </div>
        </div>
        <div className='bg-ligth-primary text-center'>
          <div className='row '>
            <div className='col-md-6 '>
              <div className='alert alert-primary'>
                <div className=' font-destaque'>R$/m² privativo - Margem de erro </div>
                <div className=''>
                  {/* <i className='wb-triangle-down font-size-20 margin-right-5 red-600'></i> */}
                  <span className='fs-1'>
                    {resultAvaliacao.minimo
                      ? resultAvaliacao.minimo.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 'R$ - '}
                  </span>
                  /m²
                </div>
                <div className='text-center'>
                  <span className='fs-5'>
                    {resultAvaliacao.minimo
                      ? (resultAvaliacao.minimo * formAvm.area).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 'R$ - '}
                  </span>
                </div>
              </div>
            </div>
            <div className='col-md-6 '>
              <div className='alert alert-primary'>
                <div className=' font-bold text-center'>R$/m² privativo - Margem de erro </div>
                <div className='fs-1'>
                  <span className=''>
                    {resultAvaliacao.maximo
                      ? resultAvaliacao.maximo.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 'R$ - '}
                  </span>
                  /m²
                </div>
                <div className='text-center'>
                  <span className='fs-5'>
                    {resultAvaliacao.maximo
                      ? (resultAvaliacao.maximo * Number(formAvm.area)).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 'R$ - '}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {result.message.length > 0 && result.message[0] !== 'ok' ? (
        <div className='alert alert-info'>
          <h3>Possíveis inconsistências:</h3>
          {result.message.map((mensagem) => {
            return <p key={mensagem}>{mensagem}</p>
          })}
        </div>
      ) : (
        <></>
      )}
      {avmConfig.features ? (
        <>
          <div className='row pb-5'>
            <h5 className='col-6'>Computados</h5>
            <h5 className='col-6'>Não Computados</h5>
          </div>
          <div className='row'>
            <div className='col-6'>
              {
                // eslint-disable-next-line array-callback-return
                dummies.map((item) => {
                  if (item.selecionado) {
                    return (
                      <div
                        key={item.name}
                        className=' p-3 d-inline-flex bg-success text-white me-2 mb-2'
                      >
                        <BiCheck className='text-white  fs-2'></BiCheck>
                        {item.name}
                      </div>
                    )
                  }
                })
              }
            </div>
            <div className='col-6'>
              {
                // eslint-disable-next-line array-callback-return
                dummies.map((item) => {
                  if (!item.selecionado) {
                    return (
                      <div key={item.name} className='p-3  d-inline-flex me-2 mb-2 bg-light'>
                        <BiX className='text-danger fs-1'></BiX>
                        {item.name}
                      </div>
                    )
                  }
                })
              }
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
