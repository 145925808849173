import { gql } from "@apollo/client";

export const GET_ESTADOS = gql`
query GetEstados {
    GetEstados {
      estado
      id
      nome
    }
  }
`

export const GET_ESTADOS_BY_ID = gql`
query GetByIdEstado($getByIdEstadoId: Float!) {
  GetByIdEstado(id: $getByIdEstadoId) {
    id
    nome
    estado
  }
}
`