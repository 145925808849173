import {Loader} from '../Loader/Loader'

const LoadingTable = ({loading}) => {
  return loading ? (
    <tr>
      <td colSpan={10} className='text-center'>
        <Loader text={'Carregando...'}></Loader>
      </td>
    </tr>
  ) : (
    <></>
  )
}

export default LoadingTable
