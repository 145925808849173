import {FC} from 'react'
import {Col, Form, Row} from 'react-bootstrap'

type Props = {
  indice: string
  nome: string
  simNao: number
  checked: boolean
  onChange: any
  type: 'switch' | 'select'
  naoSei: boolean
  onChangeNaoSei: any
}

export const ItemList: FC<Props> = ({
  indice,
  nome,
  simNao = 0,
  checked,
  onChange,
  onChangeNaoSei,
  type = 'switch',
  naoSei,
}) => {
  return (
    <div className='col-4 mb-3'>
      {type === 'switch' ? (
        <div className='d-flex flex-row all-start-start'>
          <Form.Check
            className='ms-0 me-2 w-50px'
            checked={naoSei}
            onChange={(e) => {
              onChangeNaoSei(e.target.checked ? true : false, indice)
            }}
          ></Form.Check>
          <Form.Check
            type='switch'
            id={indice}
            name={indice}
            label={nome}
            checked={checked}
            onChange={(e) => {
              onChange(e.target.checked ? true : false, indice)
            }}
          />
        </div>
      ) : (
        <>
          <Form.Group as={Row} className='mb-3 w-100' controlId='formHorizontalEmail'>
            <Col sm={5}>
              <Form.Select
                aria-label='Sim/Não'
                size='sm'
                onChange={(e) => {
                  onChange(Number(e.target.value), indice)
                }}
              >
                <option>selecione</option>
                <option value='1' selected={checked}>
                  Sim
                </option>
                <option value='0' selected={!checked}>
                  Não
                </option>
              </Form.Select>
            </Col>
            <Form.Label column sm={7}>
              {nome}
            </Form.Label>
          </Form.Group>
        </>
      )}
    </div>
  )
}
