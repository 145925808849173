import { gql } from "@apollo/client";

export const GET_EMPREENDIMENTOS_OBSERVACOES = gql` 
query GetEmpreendimentosObservacao($pagination: Pagination!, $idEmpreendimento: Float!) {
  GetEmpreendimentosObservacao(pagination: $pagination, id_empreendimento: $idEmpreendimento) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      id
      uuid
      id_empreendimento
      id_usuario_cadastro
      id_usuario_alteracao
      data_cadastro
      data_alteracao
      titulo
      descricao
    }
  }
}
`

export const GET_EMPREENDIMENTO_OBSERVACAO_BY_ID = gql`
query GetEmpreendimentoObservacao($getEmpreendimentoObservacaoId: Float!) {
  GetEmpreendimentoObservacao(id: $getEmpreendimentoObservacaoId) {
    descricao
    titulo
    data_alteracao
    data_cadastro
    id_empreendimento
    id
  }
}
`