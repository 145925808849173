import { FC, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './main.css'
import { MenuSecondary } from './MenuSecondary'
import {
  BiBuildings,
  BiCog,
  BiCompass,
  BiHome,
  BiLogOut,
  BiMapPin,
  BiWrench,
  BiFile,
  BiBarChartSquare,
  BiBarChart,
  BiPurchaseTagAlt
} from 'react-icons/bi'

import {
  FiHelpCircle
} from 'react-icons/fi'
import secureLocalStorage from 'react-secure-storage'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { KTSVG } from '../../../helpers'
import { toAbsoluteUrl } from '../../../helpers/Utils'
import { AsideMenu } from './Mobile/AsideMenu'
import { AiOutlineUserSwitch } from 'react-icons/ai'
import { MutationSetLogAcessoRecurso } from '../../../../app/graphql/services/LogAcesso'

const AsideDefault: FC = () => {
  const asideRef = useRef<HTMLDivElement | null>(null)
  const asideFooter = useRef<HTMLDivElement | null>(null)
  const asideMenu = useRef<HTMLDivElement | null>(null)
  let history = useHistory()
  const { config, classes } = useLayout()
  const { aside } = config
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  const minimize = () => {
    asideRef.current?.classList.add('animating')
    setTimeout(() => {
      asideRef.current?.classList.remove('animating')
    }, 300)
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 990);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth < 990);
    // document.get  window.innerWidth
    asideRef.current.style.height = window.innerHeight + 'px'
    if (asideFooter.current) {
      // asideFooter.current.style.height = 200 + 'px'
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  function handleDashboard() {
    history.push('/')
  }

  function handleExplorer() {
    history.push('/explorer')
    FormSetLogAcessoRecurso({ ferramenta: 'explorer', funcionalidade: 'inicio', action: 'list', url: '/explorer' });

  }

  function handlePessoas() {
    history.push('/pessoas')
    FormSetLogAcessoRecurso({ ferramenta: 'pessoas', funcionalidade: 'listagem', action: 'list', url: '/pessoas' });
  }

  function handleAvaliacoes() {
    history.push('/avaliacoes')
    FormSetLogAcessoRecurso({ ferramenta: 'pessoas', funcionalidade: 'listagem', action: 'list', url: '/pessoas' });
  }


  function handleMeusLocais() {
    history.push('/meus-imoveis')
    FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'listagem', action: 'list', url: '/meus-imoveis' });

  }

  function handleEmpreendimentos() {
    history.push('/empreendimentos')
    FormSetLogAcessoRecurso({ ferramenta: 'empreendimentos', funcionalidade: 'listagem', action: 'list', url: '/empreendimentos' });
  }

  function handleAnaliseMercado() {
    history.push('/analise-mercado')
    FormSetLogAcessoRecurso({ ferramenta: 'analise-mercado', funcionalidade: 'listagem', action: 'list', url: '/analise-mercado' });
  }
  function handleInteligenciaMercado() {
    history.push('/inteligencia-mercado')
    FormSetLogAcessoRecurso({ ferramenta: 'inteligencia-mercado', funcionalidade: 'listagem', action: 'list', url: '/inteligencia-mercado' });
  }

  function activetab(tab, showHide) {
    if (showHide === 'show') {
      document.getElementById(tab).classList.add('show')
      document.getElementById(tab).classList.remove('hide')
    } else {
      document.getElementById(tab).classList.add('hide')
      document.getElementById(tab).classList.remove('show')
    }
  }
  // Funções responsaveis pelos efeitos do Menu
  function onMouserHoverMenuDashboard() {
    let addClassAsideSecondary = document.getElementById('AsideSecondary')
    addClassAsideSecondary.classList.add('show-menu')
    addClassAsideSecondary.classList.remove('hide-menu')
  }

  function onMouserHoverOut() {
    let removeClassAsideSecondary = document.getElementById('AsideSecondary')
    removeClassAsideSecondary.classList.remove('show-menu')
    removeClassAsideSecondary.classList.add('hide-menu')
  }

  return (
    <>
      {isMobile ? (
        <div
          id='kt_aside'
          className={clsx('aside all-start-between-column', classes.aside.join(' '))}
          data-kt-drawer='true'
          data-kt-drawer-name='aside'
          data-kt-drawer-activate='{default: true, lg: false}'
          data-kt-drawer-overlay='true'
          data-kt-drawer-direction='start'
          data-kt-drawer-toggle='#kt_aside_mobile_toggle'
          ref={asideRef}
        >
          {/* begin::Brand */}
          <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
            {/* begin::Logo */}
            {aside.theme === 'dark' && (
              <Link to='/dashboard'>
                <img
                  alt='Logo'
                  className='h-25px logo'
                  src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                />
              </Link>
            )}
            <Link to='/dashboard'>
              <img
                alt='Logo'
                className='h-25px logo'
                src={toAbsoluteUrl('/media/logos/default.svg')}
              />
            </Link>
            {/* end::Logo */}

            {/* begin::Aside toggler */}
            {aside.minimize && (
              <div
                id='kt_aside_toggle'
                className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
                data-kt-toggle='true'
                data-kt-toggle-state='active'
                data-kt-toggle-target='body'
                data-kt-toggle-name='aside-minimize'
                onClick={minimize}
              >
                <KTSVG
                  path={'/media/icons/duotune/arrows/arr080.svg'}
                  className={'svg-icon-1 rotate-180'}
                />
              </div>
            )}
            {/* end::Aside toggler */}
          </div>
          {/* end::Brand */}

          {/* begin::Aside menu */}
          <div className='aside-menu flex-column-fluid'>
            <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
          </div>
          {/* end::Aside menu */}
          {/* begin::Footer */}
          <div className='aside-footer flex-column gap-3 pt-5 pb-7 px-5 w-100 all-center' id='kt_aside_footer'>
            <Link to='/tutorial'
              className='btn btn-custom btn-success w-100'
            >
              <span className='btn-label'>Tutoriais</span>
            </Link>

            <Link to='/logout'
              className='btn btn-custom btn-primary w-100'
            >
              <span className='btn-label'>Sair</span>
            </Link>
          </div>
          {/* end::Footer */}
        </div>
      ) : (
        <div
          id='kt_aside'
          className='aside'
          data-kt-drawer='false'
          data-kt-drawer-name='aside'
          data-kt-drawer-activate='{default: true, lg: false}'
          data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'200px', '300px': '250px'}"
          data-kt-drawer-direction='start'
          ref={asideRef}
          // heigth={window.innerHeight}
          style={{ height: window.innerHeight }}
        >
          {/*begin::Primary*/}
          <div className='aside-primary d-flex flex-column align-items-lg-center flex-row-auto'>
            {/*begin::Logo*/}

            {/*end::Logo*/}

            {/*begin::Nav*/}
            <div
              className='aside-nav d-flex flex-column align-items-center flex-column-fluid justify-content-center'
              id='kt_aside_nav'
              //  style={{ height: window.innerHeight - 400 }}
            >
              {/*begin::Wrapper*/}
              <div
                className=' d-flex flex-column align-items-center flex-column-fluid justify-content-center'
              // data-kt-scroll='true'
              // data-kt-scroll-activate='{default: false, lg: true}'
              // data-kt-scroll-height='auto'
              // data-kt-scroll-wrappers='#kt_aside_nav'
              // data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
              // data-kt-scroll-offset='0px'
              >
                <ul className='nav flex-column text-center pt-3'

                  id='menu-principal'>
                  <li className='nav-item  mb-3' title='Dashboard'>
                    {/*begin::Nav link*/}
                    <a
                      onClick={() => {
                        handleDashboard()
                        onMouserHoverOut()
                      }}
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                      href='#dashboard'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Dashboard</title>
                        <BiHome className='text-gray-700 btn-active-light'></BiHome>
                      </span>
                      {/*end::Svg Icon*/}
                    </a>
                    {/*end::Nav link*/}
                  </li>
                  <li
                    className='nav-item  mb-3 menu-hover'
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Explorer'
                  >

                    <a
                      onClick={() => {
                        handleExplorer()
                        onMouserHoverOut()
                      }}
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 text-gray-600 btn-active-light'
                      data-bs-toggle='tab'
                      href='#explorer'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Explorer</title>
                        <BiCompass className='text-gray-700 btn-active-light'></BiCompass>
                        {/* <FaSearchLocation></FaSearchLocation> */}
                        {/* <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='bi bi-compass'
                      viewBox='0 0 16 16'
                    >
                      <path d='M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z' />
                      <path d='m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z' />
                    </svg> */}
                      </span>
                      {/*end::Svg Icon*/}
                    </a>
                    {/*end::Nav link*/}
                  </li>
                  <li
                    className='nav-item  mb-3'
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Meus Imóveis'
                  >
                    {/*begin::Nav link*/}
                    <a
                      href='#meus-imoveis'
                      onClick={() => {
                        handleMeusLocais()
                        onMouserHoverOut()
                      }}
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light btnHover'
                      data-bs-toggle='tab'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Meus Imóveis</title>
                        <BiMapPin className='text-gray-700'></BiMapPin>
                      </span>
                      {/*end::Svg Icon*/}
                    </a>

                    {/*end::Nav link*/}
                  </li>
                  <li
                    className='nav-item  mb-3'
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Empreendimentos'
                  >
                    {/*begin::Nav link*/}
                    <a
                      href='#empreendimentos'
                      onClick={() => {
                        handleEmpreendimentos()
                        onMouserHoverOut()
                      }}
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Empreendimentos</title>
                        <BiBuildings className='text-gray-700'></BiBuildings>
                      </span>
                      {/*end::Svg Icon*/}
                    </a>
                    {/*end::Nav link*/}
                  </li>
                    <li
                    className='nav-item  mb-3'
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Inteligência de mercado'
                  >
                    <a
                      href='#inteligencia-mercado'
                      onClick={() => {
                        handleInteligenciaMercado()
                        onMouserHoverOut()
                      }}
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Inteligência de mercado</title>
                        <BiBarChart className='text-gray-700' />
                      </span>
                    </a>
                  </li>
                  {/* <li
                    className='nav-item  mb-3'
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Análise de mercado'
                  >
                    <a
                      href='#analise-mercado'
                      onClick={() => {
                        handleAnaliseMercado()
                        onMouserHoverOut()
                      }}
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                    >
                      <span className='svg-icon svg-icon-3x'>
                        <title>Análise de mercado</title>
                        <BiBarChart className='text-gray-700' />
                      </span>
                    </a>
                  </li> */}
                  <li
                    className='nav-item  mb-3 hoverContent '
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Cadastro'
                    data-toggle='dropdown'
                    role='button'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    {/*begin::Nav link*/}
                    <a
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                      href='#configuracoes'
                      aria-selected='true'
                      role='tab'
                      onClick={() => {
                        onMouserHoverMenuDashboard()
                        activetab('configuracoes', 'show')
                      }}

                    // onMouseEnter={()=>{
                    //   activetab('kt_aside_nav_tab_config', 'show')
                    //  } }

                    //  onMouseLeave={()=>{
                    //   activetab('kt_aside_nav_tab_config', 'hide')
                    //  } }
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Cadastro</title>
                        <BiCog className='text-gray-700'></BiCog>
                      </span>
                    </a>
                  </li>
                  <li
                    className='nav-item  mb-3 hoverContent '
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Pessoas'
                    data-toggle='dropdown'
                    role='button'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >

                    <a
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                      href='#pessoas'
                      aria-selected='true'
                      role='tab'
                      onClick={() => {
                        handlePessoas()
                      }}
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Pessoas</title>
                        <AiOutlineUserSwitch className='text-gray-700'></AiOutlineUserSwitch>
                      </span>
                    </a>
                  </li>
                  <li
                    className='nav-item  mb-3 hoverContent '
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Avaliações'
                    data-toggle='dropdown'
                    role='button'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    {/*begin::Nav link*/}
                    <a
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                      href='#relatorios'
                      aria-selected='true'
                      role='tab'
                      onClick={() => {
                        handleAvaliacoes()
                      }}
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Avaliações</title>
                        <BiPurchaseTagAlt className='text-gray-700'></BiPurchaseTagAlt>
                      </span>
                    </a>
                  </li>

                  <li
                    className='nav-item  mb-3 hoverContent '
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Relatórios'
                    data-toggle='dropdown'
                    role='button'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    {/*begin::Nav link*/}
                    <a
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                      href='#relatorios'
                      aria-selected='true'
                      role='tab'
                      onClick={() => {
                        onMouserHoverMenuDashboard()
                        activetab('relatorios', 'show')
                      }}

                    // onMouseEnter={()=>{
                    //   activetab('kt_aside_nav_tab_config', 'show')
                    //  } }

                    //  onMouseLeave={()=>{
                    //   activetab('kt_aside_nav_tab_config', 'hide')
                    //  } }
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Relatórios</title>
                        <BiFile className='text-gray-700'></BiFile>
                      </span>
                    </a>
                  </li>

                  {secureLocalStorage.getItem('profile_id') === '1' ? (
                    <li
                      className='nav-item  mb-3 hoverContent '
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      data-bs-placement='right'
                      data-bs-dismiss='click'
                      title='Backoffice'
                      data-toggle='dropdown'
                      role='button'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      {/*begin::Nav link*/}
                      <a
                        className='nav-link btn btn-icon btn-active-color-success btn-color-green-900 btn-active-light'
                        data-bs-toggle='tab'
                        href='#backoffice'
                        aria-selected='true'
                        role='tab'
                        onClick={() => {
                          onMouserHoverMenuDashboard()
                          activetab('backoffice', 'show')
                        }}
                      >
                        <span className='svg-icon svg-icon-2x'>
                          <title>Backoffice</title>
                          <BiWrench className='text-gray-700 '></BiWrench>
                        </span>
                        {/*end::Svg Icon*/}
                      </a>
                    </li>
                  ) : (
                    ''
                  )}

                </ul>

              </div>

            </div>

            <div
              className='aside-footer d-flex flex-column align-items-center flex-column-auto'
              id='kt_aside_footer'
              // style={{ height: 200 }}
              ref={asideFooter}
            >
              {/*begin::Wrapper*/}
              <div
                className=' d-flex flex-column align-items-start flex-column-fluid justify-content-center'
              // data-kt-scroll='true'
              // data-kt-scroll-activate='{default: false, lg: true}'
              // data-kt-scroll-height='auto'
              // data-kt-scroll-wrappers='#kt_aside_nav'
              // data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
              // data-kt-scroll-offset='0px'
              >
                <ul className='nav flex-column  text-center'>
                  {/*begin::Nav item*/}
                  <li className='nav-item' title='Tutoriais'>
                    <Link
                      to='/tutorial'
                      className='nav-link btn btn-icon btn-active-color-success btn-color-green-900 btn-active-light'
                      data-kt-menu-trigger='click'
                      data-kt-menu-overflow='true'
                      data-kt-menu-placement='top-start'
                      data-bs-toggle='tooltip'
                      data-bs-placement='right'
                      data-bs-dismiss='click'
                      title='Tutoriais'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <FiHelpCircle></FiHelpCircle>
                      </span>
                    </Link>
                  </li>
                  {/*end::Nav item*/}
                  {/*begin::Nav item*/}
                  <li className='nav-item' title='Logout'>
                    <Link
                      to='/logout'
                      className='nav-link btn btn-icon btn-active-color-success btn-color-green-900 btn-active-light'
                      data-kt-menu-trigger='click'
                      data-kt-menu-overflow='true'
                      data-kt-menu-placement='top-start'
                      data-bs-toggle='tooltip'
                      data-bs-placement='right'
                      data-bs-dismiss='click'
                      title='Sair'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <BiLogOut></BiLogOut>
                        {/* <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                  <path
                    fill='black'
                    d='M96 480h64C177.7 480 192 465.7 192 448S177.7 416 160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64C177.7 96 192 81.67 192 64S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256C0 437 42.98 480 96 480zM504.8 238.5l-144.1-136c-6.975-6.578-17.2-8.375-26-4.594c-8.803 3.797-14.51 12.47-14.51 22.05l-.0918 72l-128-.001c-17.69 0-32.02 14.33-32.02 32v64c0 17.67 14.34 32 32.02 32l128 .001l.0918 71.1c0 9.578 5.707 18.25 14.51 22.05c8.803 3.781 19.03 1.984 26-4.594l144.1-136C514.4 264.4 514.4 247.6 504.8 238.5z'
                  />
                </svg> */}
                      </span>
                    </Link>
                  </li>
                  {/*end::Nav item*/}
                </ul>
              </div>


            </div>
            {/*end::Footer*/}
          </div>
          {/*end::Primary*/}

          <MenuSecondary onMouserHoverOut={onMouserHoverOut} />
        </div>
      )}

    </>
  )
}

export { AsideDefault }
