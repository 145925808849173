import React, {useState} from 'react'
import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import '../global.css'
import {Footer} from '../../../_metronic/layout/components/Footer'

import {HeaderWrapperFluid} from '../../../_metronic/layout/components/header/HeaderWrapperFluid'
import {Table} from './partials/Table'
import {KTSVG} from '../../../_metronic/helpers'
import {Form, FormLabel} from 'react-bootstrap'

export function Empreendimento() {
  const [toogleFilter, setToogleFilter] = useState(true)
  const [descart, setDescart] = React.useState(false)

  const [filter, setFilter] = useState([])

  function addFilter(e) {
    e.preventDefault()
    const data = new FormData(e.target)
    const filterArray = []

    if (data.get('nome')) {
      filterArray.push({
        field: ['nome', 'endereco'],
        operator: 'LIKE',
        value: data.get('nome'),
      })
    }

    setFilter([...filterArray])
    return false
    // const value = e.target.value

    // filterArray.push({
    //   "field": ["nome","endereco"],
    //   "operator": "LIKE",
    //   "value": value
    // })
  }

  // const handleChecked = (e) => {
  //   setDescart(e.target.checked)
  // }
  function toggleTeste() {
    if (toogleFilter === false) {
      setToogleFilter(true)
    } else if (toogleFilter === true) {
      setToogleFilter(false)
    }
  }

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid title='Empreendimentos' />
      {/* <Empreendimentos /> */}
      <div className='wrapper d-flex flex-column flex-column-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            {/* <HeaderPage /> */}
            <div id='kt_content_container' className='container-fluid'>
              <div
                className='py-6  d-flex align-items-center justify-content-between'
                id='kt_header_container'
              >
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  // data-kt-swapper='true'
                  // data-kt-swapper-mode='prepend'
                  // data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Listagem</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href='/dashboard' className='text-muted'>
                        Dashboard
                      </a>
                    </li>
                    <li className='breadcrumb-item text-muted'>Empreendimentos</li>
                  </ul>
                </div>
                <div className='d-flex align-items-center ms-3'>
                  <button
                    className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={toggleTeste}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filtros
                  </button>
                </div>
              </div>
              <div className='content  d-flex flex-column flex-column-fluid '>
                <div className={toogleFilter === true ? 'card show mb-10' : ' card hide mb-10'}>
                  <div className='border-0 pt-6 ps-5 pe-5'>
                    <div className='row gy-5 gx-xl-8'>
                      <div className='col-xl-12'>
                        <div className='card-xxl-stretch '>
                          <div className='card-form px-12 py-12 mb-6'>
                            <Form className='row form form-horizontal' onSubmit={addFilter}>
                              <div className='row'>
                                <div className='col-md-3 mb-2'>
                                  <FormLabel className='form-label'>Nome/Endereço</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Digite o nome ou Endereço'
                                    name='nome'
                                  />
                                </div>
                                {/* <div className='col-md-4 mb-2'>
                                  <FormLabel className='form-label'>Situação</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='name@example.com'
                                  />
                                </div> */}
                                <div className='col-md-3 mb-2'>
                                  <FormLabel className='form-label'>Zoneamento</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='name@example.com'
                                  />
                                </div>
                                {/* <div className='col-md-2'>
                                  <div className='form-check'>
                                    <label className='form-check-label'>
                                      <input
                                        className='form-check-input'
                                        // checked={check}
                                        // onChange={handleChecked}
                                        type='checkbox'
                                        id='gridCheck'
                                      />
                                      Exibir Descartados
                                    </label>
                                  </div>
                                </div> */}
                                <div className='col-md-6 all-end pt-5'>
                                  <button type='submit' className='btn btn-primary'>
                                    Buscar
                                  </button>
                                </div>
                              </div>

                              <div className='row  mt-3'>
                                {/* <div className='col-md-4'>
                                  <FormLabel className='form-label'>Tipo</FormLabel>
                                  <GetEmpreendimentoTipoChosen />
                                </div>
                                <div className='col-md-4'>
                                  <FormLabel className='form-label'>Tags</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='name@example.com'
                                  />
                                </div> */}
                              </div>
                              <div className='row  mt-3'>
                                <div className='col-md-4'></div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='content  d-flex flex-column flex-column-fluid'>
                <div className='col-xl-12'>
                  <Table active={descart} filtro={filter} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
