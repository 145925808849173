import { z } from 'zod';

export const TypologyFormSchema = z.object({
  id: z.number().optional(),
  type: z.string().nonempty('Selecione o tipo de tipologia.'),
  private_area: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  total_units: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  bedrooms: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  bathrooms: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  suites: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  parking: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  condominium_value: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }).optional(),
  urban_land_tax: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }).optional(),
  id_real_estate_launches: z.number().optional(),
  stock: z.number().optional(),
  sales: z.number().optional()
});

export type TypologyFormData = z.infer<typeof TypologyFormSchema>;


export const TypologyUpdateFormSchema = z.object({
  id: z.number().optional(),
  type: z.string().nonempty('Selecione o tipo de tipologia.'),
  private_area: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  })
    .min(1, { message: "O valor deve ser pelo menos 1." }) // Adiciona a validação de valor mínimo
    .optional(),
  total_units: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  bedrooms: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  bathrooms: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  suites: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  parking: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }),
  condominium_value: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }).optional(),
  urban_land_tax: z.number({
    required_error: "O campo é obrigatório.",
    invalid_type_error: "O campo deve ser preenchido.",
  }).optional(),
  id_real_estate_launches: z.number().optional(),
  stock: z.number().optional(),
  sales: z.number().optional()
});

export type TypologyUpdateFormData = z.infer<typeof TypologyUpdateFormSchema>;


// Schema com campos obrigatórios e validação de números
export const RealEstateLaunchSchema = z.object({
  name: z.string().nonempty('O campo é obrigatório.'),
  number_of_towers: z.number().int().positive('O campo deve ser um número positivo.'),
  finality: z.string().nonempty('O campo é obrigatório.'),
  number_of_floors: z.number().int().positive('O campo deve ser um número positivo.'),
  apts_per_floor: z.number().int().positive('O campo deve ser um número positivo.'),
  total_units: z.number().int().positive('O campo deve ser um número positivo.'),
  status: z.string().nonempty('O campo é obrigatório.'),
  id_status: z.number().int().positive('O campo deve ser um número positivo.'),
  id_developer: z.number().int().positive('O campo deve ser um número positivo.'),
  launch_date: z.string().nonempty('O campo é obrigatório.'),
  opening_date: z.string().nonempty('O campo é obrigatório.'),
  future_launch: z.number().optional(),
  address: z.string().nonempty('O campo é obrigatório.'),
  zipcode: z.string().nonempty('O campo é obrigatório.'),
  id_state: z.number().int().positive('Selecione um Estado.'),
  id_city: z.number().int().positive('Selecione uma Cidade.'),
  neighborhood: z.string().nonempty('O campo é obrigatório.'),
  number: z.string().nonempty('O campo é obrigatório.'),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  complement: z.string().optional(),
  features: z.array(z.string()).optional(),
  floor_area: z.number().optional(),
  total_area: z.number().optional(),
  description: z.string().optional(),
  notary_registration_number: z.string().optional(),
  webpage: z.string().optional(),
  opening_months: z.number().optional(),
  affordable_program: z.number().optional()
});

// Definição do esquema para arquivos
export const FilesSchema = z.array(z.any())
// Exemplo de como combinar os esquemas se necessário
export const CombinedSchema = z.object({
  data: RealEstateLaunchSchema,
  files: FilesSchema
});
export type RealEstateLaunchFormData = z.infer<typeof CombinedSchema>;