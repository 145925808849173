

export function errorHandler(error){

        return error[0].message 
    //     { error.graphQLErrors.map(({ message }, i) => (
    //     <span key={i}>{message}</span>
    //   )}
    //   return (erros)
    

}