import { useMutation } from "@apollo/client"
import { TypesDeleteExplorerLayerUserFavorito, TypesSetExplorerLayerUserFavorito } from "../type/explorer-layer-usuario"
import { DELETE_EXPLORER_LAYER_USUARIO, SET_EXPLORER_LAYER_USUARIO } from "../mutations"
import { FIND_EXPLORER_LAYER_USUARIO } from "../query/explorer-layer-usuario"

interface Props {
  variables: any
}

export function MutationSetExplorerLayerUserFavorito() {

  const [setExplorerLayerUserFavorito, { error, loading, data: DataSetExplorerLayerUserFavorito }] =
    useMutation<TypesSetExplorerLayerUserFavorito>(SET_EXPLORER_LAYER_USUARIO, {
      refetchQueries: [FIND_EXPLORER_LAYER_USUARIO]
    })

  async function FormExplorerLayerUserFavorito(id: number) {
    await setExplorerLayerUserFavorito({
      variables: {
        explorerLayerUserFavorito: {
          id_layer: id,
        }
      }
    })

  }

  return { FormExplorerLayerUserFavorito, loading, error, DataSetExplorerLayerUserFavorito }
}


export function MutationDeleteExplorerLayerUserFavorito() {

  const [deleteExplorerLayerUserFavorito, { error, loading, data: DataDeleteExplorerLayerUserFavorito }] =
    useMutation<TypesDeleteExplorerLayerUserFavorito>(DELETE_EXPLORER_LAYER_USUARIO, {
      refetchQueries: [FIND_EXPLORER_LAYER_USUARIO]
    })

  async function FormDeleteExplorerLayerUserFavorito(id: number) {
    await deleteExplorerLayerUserFavorito({
      variables: {
        deleteExplorerLayerUserFavoritoId: id
      }
    })

  }

  return { FormDeleteExplorerLayerUserFavorito, loading, error, DataDeleteExplorerLayerUserFavorito }
}

