import {GeoJsonLayer} from '@deck.gl/layers'
import {ColorMapboxToKepler} from '../../../explorer/components/common'

export const LayersData = [
  {
    name: 'view_ibge_explorer_renda_mensal_domiciliar',
    schema: 'ibge',
    style: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['to-number', ['get', 'renda_mensal_domiciliar']],
        0,
        '#FF6666',
        1000,
        '#FFB266',
        2500,
        '#FFFF66',
        5000,
        '#B2FF66',
        7500,
        '#66ff66',
        9000,
        '#66ffff',
        15000,
        '#66b2ff',
        20000,
        '#6666ff',
      ],
      'fill-opacity': 0.8,
    },
    config: {
      tile: `${process.env.REACT_APP_MAP_VECTORTILE}/operation/intersect/{z}/{x}/{y}.pbf?`,
      minZoom: 0,
      maxZoom: 23,
      // getLineColor: [255, 0, 0],
      lineWidthMinPixels: 1,
      pickable: true,
      stroked: true,
      lineWidthScale: 1,
      onClick: (e) => {
        // console.log('click layer')
        // console.log(e)
      },
      // getFillColor: [140, 170, 180,0],
      getLineColor: (info) => {
        return [255, 255, 255]
      },
      getFillColor: (f) => {
        let colorFinal = ColorMapboxToKepler(
          {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['to-number', ['get', 'renda_mensal_domiciliar']],
              0,
              '#FF6666',
              1000,
              '#FFB266',
              2500,
              '#FFFF66',
              5000,
              '#B2FF66',
              7500,
              '#66ff66',
              9000,
              '#66ffff',
              15000,
              '#66b2ff',
              20000,
              '#6666ff',
            ],
            'fill-opacity': 0.6,
          },
          f.properties,
          'fill-color'
        )
        return colorFinal
        // console.log('fill')
        // console.log(info)
        // return [140, 170, 180, 0]
      },
      renderSubLayers: (props) => {
        return [
          new GeoJsonLayer(props, {
            autoHighlight: true,
            pickable: true,
            stroked: true,
            highlightColor: [0, 200, 255, 128],
            onHover: (info) => console.log('Hovered:', info),
            onClick: (info) => console.log('Clicked:', info),

            updateTriggers: {
              lineColor: props.getLineColor,
              fillColor: props.getFillColor,
            },
          }),
        ]
      },
    },
  },
  {
    name: 'bairros',
    schema: 'nacional',
    style: {'fill-color': '#FFF'},
    config: {
      data: `${process.env.REACT_APP_MAP_VECTORTILE}/operation/intersect/{z}/{x}/{y}.pbf?`,
      minZoom: 0,
      maxZoom: 23,
      lineWidthMinPixels: 4,
      pickable: true,
      stroked: true,
      lineWidthScale: 1,
      onClick: (e) => {
        // console.log('click layer')
        // console.log(e)
      },
      // getFillColor: [140, 170, 180,0],
      getLineColor: (info) => {
        return [255, 0, 0]
      },
      getFillColor: (info) => {
        return [140, 170, 180, 0]
      },
      renderSubLayers: (props) => {
        return [
          new GeoJsonLayer(props, {
            autoHighlight: true,
            pickable: true,
            stroked: true,
            highlightColor: [0, 200, 255, 128],
            onHover: (info) => console.log('Hovered:', info),
            onClick: (info) => console.log('Clicked:', info),

            updateTriggers: {
              lineColor: props.getLineColor,
              fillColor: props.getFillColor,
            },
          }),
        ]
      },
    },
  },
  {
    name: 'view_explorer_apartamento_venda_',
    schema: 'nacional',
    style: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['to-number', ['get', 'preco_m2']],
        2000,
        '#FF3333',
        4000,
        '#FF3333',
        6000,
        '#FF9933',
        8000,
        '#FFFF33',
        10000,
        '#99FF33',
        12000,
        '#33FF33',
        14000,
        '#33FF99',
        16000,
        '#33FFFF',
        18000,
        '#3399FF',
      ],
      'circle-radius': 15,
      'circle-opacity': 1,
    },
    config: {
      tile: `${process.env.REACT_APP_MAP_VECTORTILE}/operation/intersect/{z}/{x}/{y}.pbf?`,
      minZoom: 0,
      maxZoom: 23,
      // getLineColor: [255, 0, 0],
      lineWidthMinPixels: 1,
      pickable: true,
      stroked: true,
      lineWidthScale: 1,
      onClick: (e) => {
        // console.log('click layer')
        // console.log(e)
      },
      // getFillColor: [140, 170, 180,0],
      getLineColor: (info) => {
        return [255, 255, 255]
      },
      getFillColor: (f) => {
        let colorFinal = ColorMapboxToKepler(
          {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['to-number', ['get', 'preco_m2']],
              2000,
              '#FF3333',
              4000,
              '#FF3333',
              6000,
              '#FF9933',
              8000,
              '#FFFF33',
              10000,
              '#99FF33',
              12000,
              '#33FF33',
              14000,
              '#33FF99',
              16000,
              '#33FFFF',
              18000,
              '#3399FF',
            ],
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['to-number', ['get', 'quartos']],
              2,
              3,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
            ],
            'circle-opacity': 1,
          },
          f.properties,
          'fill-color'
        )
        return colorFinal
        // console.log('fill')
        // console.log(info)
        // return [140, 170, 180, 0]
      },
      renderSubLayers: (props) => {
        return [
          new GeoJsonLayer(props, {
            autoHighlight: true,
            pickable: true,
            stroked: true,
            radiusMinPixels: 6,
            radiusMaxPixels: 20,
            highlightColor: [0, 200, 255, 128],
            getPointRadius: props.getPointRadius || 10,
            onHover: (info) => console.log('Hovered:', info),
            onClick: (info) => console.log('Clicked:', info),

            updateTriggers: {
              lineColor: props.getLineColor,
              fillColor: props.getFillColor,
              getPointRadius: props.getPointRadius || 10,
            },
          }),
        ]
      },
    },
  },
]
