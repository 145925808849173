import {Circle} from '@react-google-maps/api'
import * as turf from '@turf/turf'
import {useContext, useEffect, useState} from 'react'
import {FilterContext} from '../../../context/FilterContext'
import {TRaio} from '../../Types'
import { CoordinatesToPath } from '../../Common/common'
import useImoveis from '../../../hooks/useImoveis'


const options = {
  fillColor: 'lightgreen',
  fillOpacity: 0.6,
  strokeColor: 'green',
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
}

type Props = {
  lat: any
  lng: any
  map: any
  distancia: number
}

function Raio({lat, lng, map, distancia}: Props) {
  const {setGeometria,setGeometriaSelecionada} = useContext(FilterContext)
  const [paths, setPaths] = useState<TRaio>(null)
  const {loadImoveis} = useImoveis()
  useEffect(() => {
    if (lat && lng && map && distancia) {
      loadRaio(map, lat, lng, distancia)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng, distancia, map])

  async function loadRaio(map: any, lat: number, lng: number, distancia: number) {
    map.panTo(new google.maps.LatLng(lat, lng))
    map.setZoom(15)
    let coordinates = [lng, lat]
    var circle = turf.circle(coordinates, distancia, {units: 'meters'})
    let raio: TRaio = {
      geojson: circle,
      id: Math.random(),
      paths: CoordinatesToPath(circle.geometry.coordinates[0]),
      raio: Number(distancia),
      selected: false,
      editable: false,
    }
    setGeometria({...circle.geometry})
    setGeometriaSelecionada({...circle})
    setPaths(raio)
    loadImoveis(circle.geometry, 'raio')
  }
  if (paths) {
    let center = turf.center(paths.geojson)

    return (
      <>
        {paths ? (
          <Circle
            radius={distancia}
            center={{lat: center.geometry.coordinates[1], lng: center.geometry.coordinates[0]}}
            options={options}
          />
        ) : (
          ''
        )}
      </>
    )
  } else {
    return <></>
  }
}

export default Raio
