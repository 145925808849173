interface VideoInfo {
    id: number;
    link: string;
    link2: string;
    title: string;
    name: string;
    duration: string;
}

export const dataVideos: VideoInfo[] = [
    {
        id: 1,
        link: '869320985',
        link2: 'df124144ce',
        title: 'Relatório de ITBI on-line e Excel',
        name: 'Urbit Explorer',
        duration: '01:15m',
    },
    {
        id: 3,
        link: '867171313',
        link2: '95f26cc986',
        title: 'Como obter detalhes de um lançamento na plataforma e exportar a informação ao Excel',
        name: 'Urbit Explorer',
        duration: '00:38m',

    },
    {
        id: 4,
        link: '866525322',
        link2: '7b16115b6e',
        title: 'Consultar a Fonte e Origem de Camadas',
        name: 'Urbit Explorer',
        duration: '00:50m',

    },
    {
        id: 5,
        link: '866077880',
        link2: 'a6448a2b2e',
        title: 'Como obter o CPF a partir do nº do contribuinte de um lote',
        name: 'Urbit Explorer',
        duration: '00:36m',

    },
    {
        id: 6,
        link: '865717547',
        link2: 'a589d700ec',
        title: 'Enriquecimento de dados de Pessoas Físicas - CPF',
        name: 'Urbit Explorer',
        duration: '00:50m',
    },
    {
        id: 7,
        link: '865690584',
        link2: 'b06ea90e81',
        title: 'Como criar um polígono, adicionar uma subcamada e extrair o relatório em Excel',
        name: 'Urbit Explorer',
        duration: '01:20m',
    },
    {
        id: 8,
        link: '865687321',
        link2: '9e0e085203',
        title: 'Criação de múltiplos endereços e visualização no mapa.',
        name: 'Urbit Explorer',
        duration: '01:05m',
    },
    {
        id: 9,
        link: '865681801',
        link2: '68e3407757',
        title: 'Como criar uma ficha resumo personalizada',
        name: 'Urbit Explorer',
        duration: '01:39m',

    },
    {
        id: 11,
        link: '880356339',
        link2: '97e3419a3d',
        title: 'Informaçóes sociodemográficas de isócronas, isocotas, raios e polígonos',
        name: 'Urbit Explorer',
        duration: '00:27m',
    },
    {
        id: 12,
        link: '879924964',
        link2: '4f15d8b0e1',
        title: 'Consulta de zoneamento',
        name: 'Urbit Explorer',
        duration: '00:51m',

    },
    {
        id: 13,
        link: '869181819',
        link2: '9193e393a8',
        title: 'Consulta da minuta da revisão da lei de uso e ocupação do solo de São Paulo',
        name: 'Urbit Explorer',
        duration: '00:31m'
    },
    {
        id: 14,
        link: '880356339',
        link2: '97e3419a3d',
        title: 'Nova plataforma  - Introdução geral',
        name: 'Urbit Explorer',
        duration: '00:27m'
    },
    {
        id: 15,
        link: '883390907',
        link2: 'c0b4f994c3',
        title: 'Amostras de apartamentos à venda ou locação para Excel',
        name: 'Urbit Explorer',
        duration: '00:27m'
    }
];
