import { useState } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Button } from "../../../../components/UI/Buttons.tsx/Button";
interface Properties {
  title: string;
  description: string;
  image: string;
  onClick: () => void;
}


export function CardHorizontal({ description, image, title, onClick}: Properties) {



  const [isFavorite, setIsFavorite] = useState(false);

  const handleClick = () => {
    setIsFavorite(!isFavorite);
  };

  return (
    <>
      <div className="card flex-row d-flex border 100%">
        <img src={image} className="radius-4" style={{ minWidth: '280px' }} width='280px' height='180px' alt="..." />
        <div className="card-body">
          <div className="w-100 all-center-between">
            <h5 className="card-title fs-2 ">{title}</h5>
            <div className="d-flex">
              <div className='rating'>
                <div className='rating-label me-2 checked'>
                  <i className='bi bi-star-fill fs-5'></i>
                </div>
                <div className='rating-label me-2 checked'>
                  <i className='bi bi-star-fill fs-5'></i>
                </div>
                <div className='rating-label me-2 checked'>
                  <i className='bi bi-star-fill fs-5'></i>
                </div>
                <div className='rating-label me-2 checked'>
                  <i className='bi bi-star-fill fs-5'></i>
                </div>
                <div className='rating-label me-2 '>
                  <i className='bi bi-star-fill fs-5'></i>
                </div>
              </div>

              <button className="btn" onClick={handleClick}>
                {isFavorite ? <FaHeart size={18} /> : <FaRegHeart size={18} color="#69C05B" />}
              </button>
            </div>
          </div>
          <p className="card-text fs-3">{description}</p>
          <div className="w-100 all-center-between">
            <div>
              <span className="fs-4">R$53.9k | 62% | R$268.1 </span>
            </div>
            <Button buttonStyle="button-primary" onClick={onClick}>
              Ver Mais
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
