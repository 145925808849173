import {useMutation, useQuery} from '@apollo/client'
import {
  GetServicoCategoriaResponse,
  TypesGetServicoByIDResponse,
  TypesGetServicosResponse,
  TypesPutServicoResponse,
  TypesSetServicoResponse,
} from '../types'
import {GET_SERVICOS, GET_SERVICO_BY_ID, GET_SERVICO_CATEGORIA} from '../querys'
import {ADMIN_PUT_SERVICO, ADMIN_SET_SERVICO} from '../mutation/servicosMutation'
import {
  putServicoFormData,
  putServicoFormSchema,
  setServicoFormData,
  setServicoFormSchema,
} from '../../formValidations/Servicos'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'

interface QueryServicosProps {
  variables: any
}

export function QueryGetServicos({variables}: QueryServicosProps) {
  const {data, loading, error} = useQuery<TypesGetServicosResponse>(GET_SERVICOS, {
    variables: {...variables},
  })

  return {data, loading, error}
}

export function QueryGetServicoByID({variables}: QueryServicosProps) {
  const {data, loading, error} = useQuery<TypesGetServicoByIDResponse>(GET_SERVICO_BY_ID, {
    variables: {...variables},
  })
  return {data, loading, error}
}

export function QueryGetServicosCategoria() {
  const {data: dataServcoCategoria} = useQuery<GetServicoCategoriaResponse>(GET_SERVICO_CATEGORIA)

  return dataServcoCategoria
}

export function MutationSetServico() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
    formState: {errors},
  } = useForm<setServicoFormData>({
    resolver: zodResolver(setServicoFormSchema),
  })

  console.log(errors)

  const [servicoBody, {loading, data: DataServico, error}] = useMutation<TypesSetServicoResponse>(
    ADMIN_SET_SERVICO,
    {
      refetchQueries: [GET_SERVICOS],
    }
  )

  async function FormServico(data: setServicoFormData) {
    const idCategorias = data.categorias
    const idPacotes = data.categorias
    console.log(idCategorias)
    console.log(idPacotes)

    delete data.categorias
    delete data.pacotes

    await servicoBody({
      variables: {
        data: data,
        idCategorias: idCategorias,
        idPacotes: idPacotes,
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormServico,
    loading,
    errors,
    setError,
    setValue,
    getValues,
    control,
    error,
    DataServico,
  }
}

export function MutationPutServico() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
    formState: {errors},
  } = useForm<putServicoFormData>({
    resolver: zodResolver(putServicoFormSchema),
  })

  console.log('Erro:', errors)

  const [servicoBody, {loading, data: DataServico, error}] = useMutation<TypesPutServicoResponse>(
    ADMIN_PUT_SERVICO,
    {
      refetchQueries: [GET_SERVICOS, GET_SERVICO_BY_ID],
    }
  )

  async function FormServico(data: putServicoFormData) {
    await servicoBody({
      variables: {
        data: data.data,
        idPacotes: data.idPacotes,
        idCategorias: data.idCategorias,
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormServico,
    loading,
    errors,
    setError,
    setValue,
    getValues,
    control,
    error,
    DataServico,
  }
}
