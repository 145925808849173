import html2canvas from 'html2canvas'
import {useEffect, useRef} from 'react'

function LegendaIsocronas({setLegendaDistancia}: {setLegendaDistancia: any}) {
  const refLegenda = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (refLegenda.current) {
      html2canvas(refLegenda.current, {width: 85, height: 75, scale: 2})
        .then((legenda1) => {
          var imageLegenda1 = legenda1.toDataURL('image/png', 1.0)
          setLegendaDistancia(imageLegenda1)
        })
        .catch(function (error) {
          /* This is fired when the promise executes without the DOM */
          console.log(error)
        })
    }
  }, [refLegenda, setLegendaDistancia])

  return (
    <div className='legenda-left' style={{right: '61px'}} ref={refLegenda}>
      <ul className='list'>
        <li>
          <div className='box-legenda' style={{border: '3px dotted #094e08'}}></div>
          <div> 5min</div>
        </li>
        <li>
          <div className='box-legenda' style={{border: '3px dotted #e10c0c'}}></div>
          <div>10 min</div>
        </li>
      </ul>
    </div>
  )
}

export default LegendaIsocronas
