import { Modal } from 'react-bootstrap'

import Swal from 'sweetalert2'
import React, { useEffect } from 'react'
import { MutationCreateImovelObservacoes } from '../../../../../../graphql/services/Observacoes'
import { ErrorMessageComponent } from '../../../../../../backoffice/components/UI/ErrorMessage'
import { Loader } from '../../../../../../components/UI/Loader/Loader'
import { FormInputAndLabel } from '../../../../../../components/UI/Inputs/FormInputAndLabel'
import { FormTextareaAndLabel } from '../../../../../../components/UI/Inputs/FormTextareaAndLabel'
import { MutationSetLogAcessoRecurso } from '../../../../../../graphql/services/LogAcesso'

interface situacaoTerrenoProps {
  closeModal: any
  show: boolean
  idTerreno: string
}

export function CreateForm({ closeModal, show, idTerreno }: situacaoTerrenoProps) {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()


  const {
    FormImovelObservacoes, loading, handleSubmit, register,
    errors, error, DataImovelObservacao
  } = MutationCreateImovelObservacoes()

  useEffect(() => {
    if (DataImovelObservacao) {
      closeModal()
      Swal.fire('Sucesso!', 'Observação criada com sucesso.', 'success')
    }
  }, [DataImovelObservacao, closeModal, error, loading])

  // Tratativa de loading
  if (loading) {
    return (
      <Loader text={'caregando...'}></Loader>
    )
  }

  const onSubmit = async (data) => {
    FormImovelObservacoes(data).then((result) => {
      FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'observacoes', action: 'create', url: '/meus-imoveis/details/' + idTerreno + '#observacoes' });
      Swal.fire('Sucesso!', 'Observação criada com sucesso.', 'success')
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })
  }

  return (
    <Modal centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Criar nova observação:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-12">
              <input
                type='number'
                hidden
                className='form-control'
                defaultValue={parseInt(idTerreno)}
                {...register('id_terreno', { required: true, valueAsNumber: true })}
              />

              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputAndLabel
                    label='Titulo'
                    className='form-control form-control-solid'
                    placeholder='Titulo'
                    register={register("titulo")}
                    error={errors ? errors.titulo?.message : ''}
                  />
                  {errors.titulo && <ErrorMessageComponent message={errors.titulo?.message} />}
                </div>
              </div>

              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormTextareaAndLabel
                    label='Descrição'
                    rows={6}
                    className='form-control form-control-solid'
                    placeholder='Descreva sua observação...'
                    register={register("descricao")}
                    error={errors ? errors.descricao?.message : ''}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 py-6 d-flex" style={{ justifyContent: 'flex-end' }}>
              <button onClick={closeModal} className="btn btnSecondary  pull-right " type="button" data-bs-dismiss="modal">Fechar</button>
              <button disabled={loading} type="submit" className="btn btn-success mx-2">
                Salvar
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
