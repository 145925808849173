import {useEffect, useState} from 'react'
import {ChartInfoBox} from './Lancamentos/ChartInfoBox'
import {Tab, Tabs} from 'react-bootstrap'
import {Loader} from '../../../../../../components/UI/Loader/Loader'
import {Property} from '../../../../../lancamento/partials/types'
import {Fotos} from './Lancamentos/Fotos'
import {Estatisticas} from './Lancamentos/Estatisticas'
import {Infos} from './Lancamentos/Infos'
import {Resumo} from './Lancamentos/Resumo'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import Chart from 'react-apexcharts'
import {TipologiasTab} from './Lancamentos/TipologiasTab'
import {BoxTipologiaResumo} from './Lancamentos/BoxTipologiaResumo'
import {ApiUrbit} from '../../../../../../../services/http/urbit'

type PropsClickInfo = {
  id_empreendimento: any
}

export const ClickInfoLancamento: React.FC<PropsClickInfo> = ({id_empreendimento}) => {
  const [key, setKey] = useState('resumo-empreendimento')
  const [result, setResult] = useState<Property>(null)
  const [estatisticasTipologia, setEstatisticasTipologia] = useState(null)
  const [tipologias, setTipologias] = useState(null)

  // const municipio = Endereco.getMunicipio()
  // let idMunicipio = 5351
  // if (municipio) {
  //   idMunicipio = municipio.id
  // }
  // const {data} = useQuery<GetServicoPacotePainelResponse>(GET_SERVICO_PAINEL_LANCAMENTO, {
  //   variables: {
  //     idProduto: 27,
  //     idMunicipio: idMunicipio,
  //   },
  //   fetchPolicy: 'no-cache',
  // })

  const id = id_empreendimento
  useEffect(() => {
    async function fetchData() {
      try {
        const response: Property = await ApiUrbit.get('real-estate-launches/' + id)
        setResult(response)
      } catch (error) {
        console.error('Erro ao buscar os dados:', error)
      }
    }
    fetchData()
  }, [id])

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ApiUrbit.get(
          'real-estate-launches-typologies/' + id
          //  'lancamento-tipologias/' + id
        )
        setTipologias(response)
      } catch (error) {
        console.error('Erro ao buscar os dados:', error)
      }
    }
    fetchData()
  }, [id])

  useEffect(() => {
    async function fetchData() {
      try {
        if (result) {
          const resultEstatistica = await ApiUrbit.get(
            // `service/estoque-e-precos-por-dormitorios/${result[0].longitude}/${result[0].latitude}?distance=0`
            `service/inventory-prices-for-bedrooms/${result[0].longitude}/${result[0].latitude}?id=${id}`
          )
          setEstatisticasTipologia(resultEstatistica)
        }
      } catch (error) {
        console.error('Erro ao buscar os dados:', error)
      }
    }
    fetchData()
  }, [result,id])

  if (!result) {
    return <Loader text={'Carregando..'}></Loader>
  }
  if (Array.isArray(result) && result.length === 0) {
    return <div className='text-center'>Lançamento sem informações </div>
  }

  let valor = 0
  let config = {
    options: {labels: []},
    series: [],
  }
  if (result) {
    valor = Number(
      (((result[0].total_units - result[0].stock) / result[0].total_units) * 100).toFixed(0)
    )
    if (tipologias) {
      // eslint-disable-next-line array-callback-return
      tipologias.map((tipologia: any) => {
        config.options.labels.push(tipologia.type + ' - ' + tipologia.private_area + 'm2')
        config.series.push(tipologia.total_units)
      })
    }
  }
  return (
    <div>
      <Tabs
        defaultActiveKey='resumo-empreendimento'
        activeKey={key}
        className='tab-report'
        fill
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey='resumo-empreendimento' title='Resumo'>
          <div className='row pt-10  px-10 bg-grey'>
            <h1>Empreendimento: {result ? result[0].name : ''}</h1>

            <div className='row p-2 mt-5 bg-grey '>
              <div className='col-6'>
                <Resumo result={result}></Resumo>

                <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                  <KTSVG
                    path='icons/duotune/general/gen044.svg'
                    className='svg-icon-2tx svg-icon-warning me-4'
                  />
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>Observações</h4>
                      <div className='fs-6 text-gray-600'>
                        1. Informações provenientes da empresa ÓRULO.
                        <br /> 2. Empreendimentos lançados antes de 2022 podem não conter dados
                        históricos e estatísticos .
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <ChartInfoBox
                  valor={valor}
                  chartColor={'success'}
                  chartHeight={'200'}
                ></ChartInfoBox>
                <div className='text-center pt-10  '>
                  <div className='card'>
                    <div className='card-header border-0 py-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Tipologias</span>

                        <span className='text-muted fw-semibold fs-7'>
                          Tipologias do empreendimento
                        </span>
                      </h3>
                    </div>
                    <div className='card-body p-0 d-flex flex-column'>
                      <div className=' d-flex flex-column'>
                        <div className='flex-grow-1'>
                          <Chart
                            options={config.options}
                            series={config.series}
                            type='donut'
                            height={300}
                            width='100%'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row p-2 mt-5 bg-grey'>
              <h1>Preço agrupado por Dormitórios</h1>
            </div>
            <div className='row p-2 bg-grey'>
              {estatisticasTipologia !== null
                ? estatisticasTipologia.itens.map((tipologia, index) => {
                    return (
                      <div className='col-6 pb-5' key={index}>
                        <BoxTipologiaResumo
                          titulo={tipologia.bedrooms + ' - Dormitórios'}
                          subtitulo={`de  ${tipologia.area_privativa_minima}m² até ${tipologia.area_privativa_maxima}m² privativo`}
                          tipologia={tipologia}
                        ></BoxTipologiaResumo>
                      </div>
                    )
                  })
                : ''}
            </div>
          </div>
        </Tab>
        <Tab eventKey='sobre' title='Sobre o empreendimento'>
          <Infos result={result} features={result ? result[0].features : '0'}></Infos>
        </Tab>
        <Tab eventKey='tipologias' title='Tipologias'>
          {tipologias ? <TipologiasTab tipologias={tipologias} /> : 'Carregando...'}
        </Tab>
        <Tab eventKey='historico' title='Histórico'>
          <Estatisticas id={id}></Estatisticas>
        </Tab>
        <Tab eventKey='fotos' title='Fotos'>
          <Fotos images={result ? result[0].images : null} />
        </Tab>
      </Tabs>
    </div>
  )
}
