import { useMutation, useQuery } from "@apollo/client"
import { TypesDeleteTermoResponse, TypesGetTermoByIDResponse, TypesGetTermosResponse, TypesPutTermoResponse, TypesSetTermoResponse } from "../types/TypesTermos"
import { GET_TERMOS, GET_TERMO_BY_ID } from "../querys"
import { TermosFormData, TermosFormSchema, TermosPutFormData, TermosPutFormSchema } from "../../formValidations/Termos"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { DELETE_TERMO, PUT_TERMO, SET_TERMO } from "../mutation/termosMutation"



interface Props {
    variables: any
  }
  

export function QueryGetTermos({ variables }:Props) {
    const { data, loading } = useQuery<TypesGetTermosResponse>(GET_TERMOS, {
        variables: { ...variables },
    })

    return {data, loading}
}


export function QueryGetTermoByID({ variables }:Props) {
    const { data, loading } = useQuery<TypesGetTermoByIDResponse>(GET_TERMO_BY_ID, {
        variables: { ...variables },
    })

    return {data, loading}
}

export function MutationSetTermo() {
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        control,
        formState: { errors }
    } = useForm<TermosFormData>({
        resolver: zodResolver(TermosFormSchema)
    });

    console.log(errors)

    const [setTermo, { loading, error, data: DataSetTermos }] = useMutation<TypesSetTermoResponse>(SET_TERMO)

    
    async function FormSetTermo(data: TermosFormData) {
        await setTermo({
            variables: {
                termo: {...data}
            },
            refetchQueries: [GET_TERMOS]
        })
    };

    return { register, handleSubmit, errors, FormSetTermo, loading, error, 
        DataSetTermos, setValue, setError, control }
}

export function MutationPutTermo() {
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        control,
        formState: { errors }
    } = useForm<TermosPutFormData>({
        resolver: zodResolver(TermosPutFormSchema)
    });

    console.log(errors)

    const [putTermo, { loading, error, data: DataPutTermos }] = useMutation<TypesPutTermoResponse>(PUT_TERMO)

    
    async function FormPutTermo(data: TermosPutFormData) {
        await putTermo({
            variables: {
                termo: {...data}
            },
            refetchQueries: [GET_TERMOS]
        })
    };

    return { register, handleSubmit, errors, FormPutTermo, loading, error, 
        DataPutTermos, setValue, setError, control }
}

export function MutationDeleteTermo() {
    const [deleteTermos, { loading, error }] = useMutation<TypesDeleteTermoResponse>(DELETE_TERMO)
  
    async function HandleDeleteTermos({ variables }: Props) {
      try {
        const result = await deleteTermos({
          variables: {...variables},
          refetchQueries: [GET_TERMOS],
        })
        return result
      } catch (e) {
        if (e) {
          return false
        }
      }
    }
  
    return { HandleDeleteTermos, loading, error }
  }
  