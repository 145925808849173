import { useMutation, useQuery } from "@apollo/client";
import {
    TypesDeleteEmpreendimentoObservacao,
    TypesDeleteImovelObservacaoResponse, TypesGetEmpreendimentoObservacaoByID, TypesGetEmpreendimentoObservacaoResponse, TypesGetImovelObservacaoByIDResponse, TypesGetImovelObservacaoResponse,
    TypesPutEmpreendimentoObservacao,
    TypesPutImovelObservacao, TypesSetEmpreendimentoObservacaoResponse, TypesSetImovelObservacaoResponse
} from "../type";
import { GET_EMPREENDIMENTOS_OBSERVACOES, GET_EMPREENDIMENTO_OBSERVACAO_BY_ID, GET_IMOVEIS_OBSERVACOES, ONE_IMOVEL_OBSERVACAO } from "../query";
import { DELETE_EMPREENDIMENTO_OBSERVACAO, DELETE_IMOVEL_OBVERVACOES, PUT_EMPREENDIMENTO_OBSERVACAO, PUT_IMOVEL_OBSERVACAO, SET_EMPREENDIMENTO_OBVERVACOES, SET_IMOVEL_OBSERVACAO } from "../mutations";
import {
    EmpreendimentoCreateObservacaoFormData, EmpreendimentoCreateObservacaoFormSchema, EmpreendimentoObservacaoFormData, EmpreendimentoObservacaoFormSchema,
    ImovelObservacaoCreateFormData, ImovelObservacaoCreateFormSchema,
    ImovelObservacaoPutFormData, ImovelObservacaoPutFormSchema
} from "../formValidations/Observacoes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Data } from "@react-google-maps/api";
import { ContinuousColorLegend } from "react-vis";

interface QueryProps {
    variables: any;
}


export function QueryGetImovelObservacoes({ variables }: QueryProps) {
    const { data } = useQuery<TypesGetImovelObservacaoResponse>(GET_IMOVEIS_OBSERVACOES, {
        variables: { ...variables },
    })


    return { data }
}

export function QueryGetImovelObservacoesByID({ variables }: QueryProps) {
    const { data, loading } = useQuery<TypesGetImovelObservacaoByIDResponse>(ONE_IMOVEL_OBSERVACAO, {
        variables: { ...variables }
    })
    return { data, loading }
}

export function MutationDeleteImovelObservaçoes() {
    const [deleteEmpreendimento, { data, error, loading }] =
        useMutation<TypesDeleteImovelObservacaoResponse>(DELETE_IMOVEL_OBVERVACOES)

    async function HandleDeleteImovelObservacoes({ variables }: QueryProps) {
        try {
            const result = await deleteEmpreendimento({
                variables: { ...variables },
                refetchQueries: [GET_IMOVEIS_OBSERVACOES],
            })
            return result
        } catch (e) {
            if (e) {
                return false
            }
        }
    }

    return { HandleDeleteImovelObservacoes, loading, error, data }
}

export function MutationCreateImovelObservacoes() {
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm<ImovelObservacaoCreateFormData>({
        resolver: zodResolver(ImovelObservacaoCreateFormSchema),
    })


    const [bodyRequest, { error, loading, data: DataImovelObservacao }] =
        useMutation<TypesSetImovelObservacaoResponse>(SET_IMOVEL_OBSERVACAO, {
            refetchQueries: [GET_IMOVEIS_OBSERVACOES],
        })

    async function FormImovelObservacoes(data: ImovelObservacaoCreateFormData) {
        await bodyRequest({
            variables: {
                imovelObservacao: { ...data }
            },
            refetchQueries: [GET_IMOVEIS_OBSERVACOES]
        })
    }

    return {
        register, handleSubmit, FormImovelObservacoes, loading,
        errors, error, DataImovelObservacao, setError,
        setValue
    }
}


export function MutationPutImovelObservacoes() {
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm<ImovelObservacaoPutFormData>({
        resolver: zodResolver(ImovelObservacaoPutFormSchema),
    })

    const [bodyRequest, { error, loading, data: DataImovelObservacao }] =
        useMutation<TypesPutImovelObservacao>(PUT_IMOVEL_OBSERVACAO, {
            refetchQueries: [GET_IMOVEIS_OBSERVACOES],
        })

    async function FormImovelObservacoes(data: ImovelObservacaoPutFormData) {
        await bodyRequest({
            variables: {
                imovelObservacao: { ...data }
            },
            refetchQueries: [GET_IMOVEIS_OBSERVACOES]
        })
    }

    return {
        register, handleSubmit, FormImovelObservacoes, loading, errors,
        error, DataImovelObservacao, setError, setValue
    }
}




export function QueryGetEmpreendimentoObservacoes({ variables }: QueryProps) {
    const { data, loading } = useQuery<TypesGetEmpreendimentoObservacaoResponse>(GET_EMPREENDIMENTOS_OBSERVACOES, {
        variables: { ...variables },
    })

    return { data, loading }
}

export function QueryGetEmpreendimentoObservacoesByID({ variables }: QueryProps) {
    const { data, loading } = useQuery<TypesGetEmpreendimentoObservacaoByID>(GET_EMPREENDIMENTO_OBSERVACAO_BY_ID, {
        variables: { ...variables },
    })
    return { data, loading }
}

export function MutationDeleteEmpreendimentoObservaçoes() {
    const [deleteEmpreendimento, { data, error, loading }] =
        useMutation<TypesDeleteEmpreendimentoObservacao>(DELETE_EMPREENDIMENTO_OBSERVACAO)


    async function HandleDeleteEmpreendimentoObservacao({ variables }: QueryProps) {
        try {
            const result = await deleteEmpreendimento({
                variables: { ...variables },
                refetchQueries: [GET_EMPREENDIMENTOS_OBSERVACOES],
            })
            return result
        } catch (e) {
            if (e) {
                return false
            }
        }
    }

    return { HandleDeleteEmpreendimentoObservacao, loading, error, data }
}

export function MutationCreateEmpreendimentoObservacoes() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EmpreendimentoCreateObservacaoFormData>({
        resolver: zodResolver(EmpreendimentoCreateObservacaoFormSchema),
    })

    const [bodyRequest, { data: DataEmpreendimentoObservacao, loading, error }] =
        useMutation<TypesSetEmpreendimentoObservacaoResponse>(SET_EMPREENDIMENTO_OBVERVACOES, {
            refetchQueries: [GET_EMPREENDIMENTOS_OBSERVACOES]
        })

    async function FormCreateEmpreendimentoObservacoes(data: EmpreendimentoCreateObservacaoFormData) {

        await bodyRequest({
            variables: {
                empreendimentoObservacao: {
                    id_empreendimento: data.id_empreendimento,
                    titulo: data.titulo,
                    descricao: data.descricao
                }
            },
            refetchQueries: [GET_EMPREENDIMENTOS_OBSERVACOES]
        })
    }

    return { register, handleSubmit, FormCreateEmpreendimentoObservacoes, loading, errors, error, DataEmpreendimentoObservacao }
}


export function MutationPutEmpreendimentoObservacoes() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EmpreendimentoObservacaoFormData>({
        resolver: zodResolver(EmpreendimentoObservacaoFormSchema),
    })
    const [bodyRequest, { data: DataEmpreendimentoObservacao, loading, error }] =
        useMutation<TypesPutEmpreendimentoObservacao>(PUT_EMPREENDIMENTO_OBSERVACAO, {
            refetchQueries: [GET_EMPREENDIMENTOS_OBSERVACOES]
        })

    async function FormEmpreendimentoObservacoes(data: EmpreendimentoObservacaoFormData) {
        await bodyRequest({
            variables: {
                empreendimentoObservacao: {
                    id: data.id,
                    id_empreendimento: data.id_empreendimento,
                    titulo: data.titulo,
                    descricao: data.descricao
                }
            },
            refetchQueries: [GET_EMPREENDIMENTOS_OBSERVACOES]
        })
    }

    return { register, handleSubmit, FormEmpreendimentoObservacoes, loading, errors, error, DataEmpreendimentoObservacao }
}
