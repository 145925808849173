import {Container, Nav, Navbar} from 'react-bootstrap'
import {useEndereco} from '../../../../../../hooks/useEndereco'
import {BiChart} from 'react-icons/bi'
import {ListLayers} from './components/ListLayers'
import {LoteBtn} from './components/LoteBtn'
import {GetEmpresaUsuario} from '../../../../../utils/PermissoesAcesso'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {Endereco} from '../ComponentsControl/EnderecoControl/endereco'

export function MenuBottom() {
  const {endereco} = useEndereco()
  let municipio = Object.keys(endereco.municipio).length === 0 ? null : endereco.municipio

  function changeMunicipio() {}

  let dadosEmpresa = GetEmpresaUsuario()
  if (!municipio) {
    return <>Clique sobre o mapa</>
  }
  MenuComponent.bootstrap()

  function changeMunicipioActive(value) {
    console.log(value)
    console.log(municipio)

    municipio.map((mun) => {
      if (mun.id === Number(value)) {
        Endereco.setEndereco({
          municipio: mun,
          enderecoAtivo: mun.id,
        })
      }
    })
    // console.log(retorno)
  }

  return (
    <div className='d-flex align-items-center justify-content-between' id='menu-bottom-filtro'>
      <div className='bottom-bar'>
        <Navbar expand='lg' className=' pt-2 pb-2 '>
          <Container fluid>
            {/* <Navbar.Brand href='#'>Atalhos</Navbar.Brand> */}
            {/* <Navbar.Toggle aria-controls='navbarScroll' /> */}
            {dadosEmpresa ? (
              <Navbar.Brand href='#home' className='pe-5 w-100px'>
                <img alt='Logo' src={dadosEmpresa.user_logo_pic} className='h-30px' />
              </Navbar.Brand>
            ) : (
              <Navbar.Brand href='#home' className='pe-5 w-100px'>
                <img alt='Logo' src='/media/logos/logo.png' className='h-20px' />
              </Navbar.Brand>
            )}
            <LoteBtn></LoteBtn>

            <Nav className=' ms-3 my-2 my-lg-0  w-110px' style={{maxHeight: '100px'}}>
              <button
                type='button'
                className='btn btn-bg-light btn-color-succes fw-bold'
                data-kt-menu-trigger='hover'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-flip='bottom-top'
                style={{fontSize: '16px'}}
                id='menu-list-layers'
              >
                <BiChart className='fs-1 me-2'></BiChart>
                Índices
              </button>
              <div className='menu menu-sub menu-sub-dropdown ' data-kt-menu='true'>
                <ListLayers />
              </div>
            </Nav>

            {/* <Nav className=' ms-3 my-2 my-lg-0'>
              <button
                type='button'
                className='btn btn-bg-light btn-color-succes fw-bold'
                data-kt-menu-trigger='hover'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-flip='top-end'
                style={{fontSize: '16px'}}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-graph-up-arrow'
                  viewBox='0 0 16 16'
                >
                  <path
                    fillRule='evenodd'
                    d='M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z'
                  />
                </svg>{' '}
                Qualidade e Oferta
              </button>
              <div className='menu menu-sub menu-sub-dropdown ' data-kt-menu='true'>
                <div className=''>
                  <CardIndices
                    image='/media/svg/brand-logos/twitch.svg'
                    title='Indices Urbit - IVS'
                    description='0.6'
                    status='up'
                    statusValue={40.5}
                    statusDesc='more impressions'
                    progress={0.5}
                    progressType='MRR'
                  />
                </div>
              </div>
            </Nav> */}
            <Navbar.Brand className='  w-160px  ms-3 pe-5 ms-auto d-flex '>
              <div className=' pt-3 pe-2'>Município:</div>
              <div className=''>
                <select
                  // onChange={changeMunicipio}
                  onChange={(e) => {
                    console.log(e.target.value)
                    changeMunicipioActive(e.target.value)
                  }}
                  className='form-control'
                  value={endereco.enderecoAtivo}
                >
                  {
                    // eslint-disable-next-line array-callback-return
                    municipio ? (
                      Array.isArray(municipio) ? (
                        municipio?.map((el) => {
                          return (
                            <option value={el.id} key={el.id}>
                              {el.nome}
                            </option>
                          )
                        })
                      ) : (
                        <option
                          value={municipio.id}
                          key={municipio.id}
                          defaultValue={endereco.enderecoAtivo}
                        >
                          {municipio.nome}
                        </option>
                      )
                    ) : (
                      ''
                    )
                  }
                </select>
                {/* <strong> {municipio} </strong> */}
              </div>
            </Navbar.Brand>
            {/* <Form className='d-flex'>
              <Form.Control
                type='search'
                placeholder='Search'
                className='me-2'
                aria-label='Search'
              />
              <Button variant='outline-success'>Search</Button>
            </Form> */}
            {/* </Navbar.Collapse> */}
          </Container>
        </Navbar>

        {/* <div className='row g-6 g-xl-9'>
          <div className='col-sm-6 col-xl-4'>
            <Card5
              image='/media/svg/brand-logos/twitch.svg'
              title='Indices Urbit - IVS'
              description='0.6'
              status='up'
              statusValue={40.5}
              statusDesc='more impressions'
              progress={0.5}
              progressType='MRR'
            />
          </div>
          <div className='col-sm-6 col-xl-4'>
            <Card5
              image='/media/svg/brand-logos/twitter.svg'
              title='Indices Urbit - Qualidade e Oferta'
              description='0.5'
              status='up'
              statusValue={17.62}
              statusDesc='Followers growth'
              progress={5}
              progressType='New trials'
            />
          </div>
        </div> */}

        {/* <div className='mb-2 '>
          <button
            type='button'
            className='btn btn-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-start'
            data-kt-menu-flip='top-end'
          >
            Indices Urbit
            
          </button>

          <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>Indices Urbit</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
              <ListGroup>
                <ListGroup.Item>
                  <div className='row g-4 align-items-center ps-3'>
                    <div className='col-auto'>
                      <label className='col-form-label'>IVS</label>
                    </div>
                    <div className='col-auto'>
                      <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='notifications'
                          defaultChecked={true}
                        />
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className='row g-3 align-items-right ps-3'>
                    <div className='col-auto'>
                      <label className='col-form-label'>Qualidade e Oferta</label>
                    </div>
                    <div className='col-auto pull-right'>
                      <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='notifications'
                          defaultChecked={true}
                        />
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
