// import {LayerMVTFactory} from '../Factory/LayerMVTFactory'
// import {LayerGeoJSONFactory} from '../Factory/LayerGeoJSON'
import { MVTLayer,GeoJsonLayer,IconLayer } from './src'
import './LayerProps'
// import {LayerIconFactory} from '../Factory/LayerIconFactory'
// import {LayerPolygonFactory} from '../Factory/LayerPolygonFactory'
// import {LayerScatterplotFactory} from '../Factory/LayerScatterplotFactory'
// import { LayerPathLayerFactory } from '../Factory/LayerPathLayerFactory'
// import { LayerPathLayerRaioFactory } from '../Factory/LayerPathLayerRaioFactory'

import { DefaultLayerProps } from './src/base-layer'
import { IconLayerText } from './src/IconLayer/IconLayerText'

export function  createLayer(layer: DefaultLayerProps, type: string) {
  let layerCreate: any
  switch (type) {
    // case 'MVTLayer': //VectorLayer
    //     layerCreate =  new MVTLayer(layer)
    //   break
    case 'MVTLayer': //VectorLayer
      layerCreate =  new MVTLayer(layer)
      break
    case 'line': //VectorLayer
      layerCreate =  new MVTLayer(layer)
      break
    case 'GeoJSONLayer':
      layerCreate = new GeoJsonLayer(layer)
      break
    case 'LayerIcon':
      layerCreate = new IconLayer(layer)
      break
    case 'IconeLayerText':
      layerCreate = new IconLayerText(layer)
      break
    case 'LayerPolygon':
      layerCreate = new GeoJsonLayer(layer)
      break
    case 'circle':
      layerCreate = new MVTLayer(layer)
      break
    // case 'LayerPath':
    //     layerCreate = new LayerPathLayerFactory()
    //     break
    // case 'LayerPathRaio':
    //     layerCreate = new LayerPathLayerRaioFactory()
    //     break
  }

  if (layerCreate){
    return layerCreate.draw(layer)
  }
}
