import { gql } from '@apollo/client'

export const GET_PEDIDOS = gql`
query GetPedido($pedido: PedidoInput!, $pagination: Pagination!) {
    GetPedido(pedido: $pedido, pagination: $pagination) {
      id
      id_user
      data_cadastro
      total
      qtde_itens
      desconto
      id_situacao
      mp_order_id
      mp_pref_id
      mp_collection_status
      mp_payment_type
      mp_merchant_order_id
      mp_collection_id
      mp_data_retorno
      mp_status_detail
      mp_payment_method_id
      hash_id
    }
  }
`