import { useMutation } from "@apollo/client"
import { TypesSetLogAcessoRecursos } from "../type/log-acesso"
import { SET_LOG_ACESSO_RECURSOS } from "../mutations/log-acesso"

export function MutationSetLogAcessoRecurso() {
  const [bodyLog] = useMutation<TypesSetLogAcessoRecursos>(SET_LOG_ACESSO_RECURSOS)

  interface LogProps {
    ferramenta: string,
    funcionalidade: string,
    action: 'list' | 'create' | 'update' | 'delete' | 'print' | 'export' | 'add' | 'click',
    url?: string
  }

  async function FormSetLogAcessoRecurso({action, ferramenta, funcionalidade, url}: LogProps) {
    await bodyLog({
      variables: {
        log: {
          action: action,
          ferramenta: ferramenta,
          funcionalidade: funcionalidade,
          url: url
        }
      },
    })
  }
  return {FormSetLogAcessoRecurso}
}