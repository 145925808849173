import {Link} from 'react-router-dom'
import {Loader} from '../../../../components/UI/Loader/Loader'
import {QueryGetImovelEmpreendimento} from '../../../../graphql/services'

type Props = {
  id: string
  nome: string
}

export const HeaderImovel: React.FC<Props> = ({id, nome}) => {
  const {data} = QueryGetImovelEmpreendimento({
    variables: {
      idImovel: parseInt(id),
      pagination: {
        quantidade: null,
        pagina: null,
      },
    },
  })
  
  if (!data) {
    return <Loader text={''}></Loader>
  }

  if (data.GetImovelEmpreendimento.id) {
    return (
      <>
        <h1 className='text-white'>{nome}</h1>
        <span className='p-1 border-rounded'>
          Empreendimento:{' '}
          {data ? (
            <Link className='text-white btn btn-sm btn-light-success active'
              target='_blank'
              to={`/empreendimentos/details/${data.GetImovelEmpreendimento.id}`}
            >
              {data.GetImovelEmpreendimento.nome}{' '}
            </Link>
          ) : (
            ''
          )}
        </span>
      </>
    )
  } else {
    return (
      <>
        <h1 className='text-white'>{nome}</h1>
      </>
    )
  }
}
