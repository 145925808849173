import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useEffect } from 'react'
import {
  MutationPutEmpreendimentoObservacoes,
  QueryGetEmpreendimentoObservacoesByID,
} from '../../../../../../graphql/services/Observacoes'
import { ErrorMessageComponent } from '../../../../../../backoffice/components/UI/ErrorMessage'
import { Loader } from '../../../../../../components/UI/Loader/Loader'
import { MutationSetLogAcessoRecurso } from '../../../../../../graphql/services/LogAcesso'

interface Props {
  id: number
  show: boolean
  closeModal: any
  id_empreendimento: number
}

export function EditForm({ id, show, closeModal, id_empreendimento }: Props) {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  let { data, loading: LoadingGet } = QueryGetEmpreendimentoObservacoesByID({
    variables: { getEmpreendimentoObservacaoId: id },
  })

  const {
    FormEmpreendimentoObservacoes,
    loading,
    handleSubmit,
    register,
    errors,
  } = MutationPutEmpreendimentoObservacoes()

  const onSubmit = async (data) => {
    FormEmpreendimentoObservacoes(data).then((result) => {
      FormSetLogAcessoRecurso({ ferramenta: 'empreendimentos', funcionalidade: 'observacoes', action: 'update', url: '/empreendimentos/details/' + id_empreendimento + '#observacoes' });
      Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })
  }

  // Tratativa de loading
  if (loading || LoadingGet) {
    return <Loader text={'caregando...'}></Loader>
  }

  return (
    <>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Observação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              className='form-control form-control-solid'
              placeholder='id_empreeendimento'
              hidden={true}
              defaultValue={id_empreendimento}
              {...register('id_empreendimento', {
                valueAsNumber: true
              })}
            />
            <input
              className='form-control form-control-solid'
              type='number'
              hidden={true}
              value={id}
              {...register('id', { required: true, valueAsNumber: true })}
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <label htmlFor='nome' className='required form-label'>
                    Titulo:
                  </label>
                  <input
                    className='form-control form-control-solid'
                    placeholder='Titulo'
                    defaultValue={data ? data.GetEmpreendimentoObservacao.titulo : ''}
                    {...register('titulo', {
                      pattern: /^[A-Za-z]+$/i,
                    })}
                  />
                  {errors.titulo && <ErrorMessageComponent message={errors.titulo?.message} />}
                </div>
              </div>

              <div className='col-md-12'>
                <div className='mb-6'>
                  <label htmlFor='email' className='required form-label'>
                    Descrição:
                  </label>
                  <textarea
                    rows={6}
                    style={{ resize: 'none' }}
                    className='form-control form-control-solid'
                    placeholder='Descreva sua observação...'
                    defaultValue={data ? data.GetEmpreendimentoObservacao.descricao : ''}
                    {...register('descricao', {
                      pattern: /^[A-Za-z]+$/i,
                    })}
                  />
                  {errors.descricao && (
                    <ErrorMessageComponent message={errors.descricao?.message} />
                  )}
                </div>
              </div>

              <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={() => closeModal()}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
