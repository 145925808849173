import React from 'react'
import {
  TabInformacoesGerais,
  TabFichaResumo,
  TabAvaliacoes,
  TabPainelMercadoEmpreendimento,
  TabObservacoes,
  TabComposicaoTerrenos,
  TabCorretores,
  TabMassa,
} from './Tabs'
import {useParams} from 'react-router-dom'
import {Loader} from '../../../components/UI/Loader/Loader'
import {QueryGetEmpreendimentoById, } from '../../../graphql'
import { QueryGetEmpreendimentoImoveis } from '../../../graphql/services/EmpreendimentoImovel'
type Props = {
  OpenModalMeusImoveis:any
  parametroDaURL:string
}
const TabsEmpreendimentoContainer: React.FC<Props> =  ({OpenModalMeusImoveis, parametroDaURL})=> {
  const {id} = useParams<{id: string}>()
  const data = QueryGetEmpreendimentoById({variables: {idEmpreendimento: parseInt(id)}})
  const {data: dataImoveis} = QueryGetEmpreendimentoImoveis({
    variables: {
      idEmpreendimento: parseInt(id),
      pagination: {
        quantidade: null,
        pagina: null,
      },
    },
  })

  if (!data || !dataImoveis) {
    return <Loader text={'caregando...'}></Loader>
  }

  return (
    <>
      <div className='tab-content' id='myTabContent'>
        <TabInformacoesGerais param={parametroDaURL}  id={data.GetEmpreendimento.id} imoveis={dataImoveis} />
        <TabComposicaoTerrenos param={parametroDaURL}  idEmpreendimento={data.GetEmpreendimento.id}  infoEmp={data.GetEmpreendimento} imoveis={dataImoveis} OpenModalMeusImoveis={OpenModalMeusImoveis} />
        <TabFichaResumo param={parametroDaURL}  data={data} imoveis={dataImoveis} />
        <TabMassa param={parametroDaURL}  data={data} imoveis={dataImoveis} />
        <TabObservacoes  param={parametroDaURL}  data={data} />
        <TabCorretores param={parametroDaURL}  data={data} />
        <TabAvaliacoes param={parametroDaURL}  data={data}  imoveis={dataImoveis} />
        {/* <TabSocioeconomico /> */}
        <TabPainelMercadoEmpreendimento param={parametroDaURL}  data={data} imoveis={dataImoveis} /> 
      </div>
    </>
  )
}

export {TabsEmpreendimentoContainer}
