import {useRef, useState} from 'react'
import './pdf.css'
import {useParams} from 'react-router-dom'
import {QueryGetEmpreendimentoById, QueryGetEmpreendimentoImoveis} from '../../graphql/services'
import {Loader} from '../../components/UI/Loader/Loader'
import html2canvas from 'html2canvas'
import MeuEmpreendimentoReport from '../../components/ReportsEmpreendimento/MeuEmpreendimento'
import {Mapbox} from '../../components/UI/Mapbox'

type Props = {
  closeModal: any
}

export function ExportPdf({closeModal}: Props) {
  const {id: idEmp} = useParams<{id: string}>()
  const [mapImage, setMapImage] = useState(null)
  const mapRef = useRef()
  const data = QueryGetEmpreendimentoById({
    variables: {
      idEmpreendimento: parseInt(idEmp),
      pagination: {pagina: 0, quantidade: 50},
    },
  })

  const {data: dataImoveis} = QueryGetEmpreendimentoImoveis({
    variables: {
      idEmpreendimento: parseInt(idEmp),
      pagination: {
        quantidade: 50,
        pagina: 0,
      },
    },
  })

  if (!data) {
    return <Loader text='Carregando empreendimento'></Loader>
  }

  if (!dataImoveis) {
    return <Loader text={'Carregando imóveis'}></Loader>
  }

  function onload(image) {
    const features: any[] = []
    // eslint-disable-next-line array-callback-return
    dataImoveis.GetEmpreendimentoImovel.result.map((imoveis) => {
      features.push({type: 'Feature', geometry: imoveis.terreno.marcacao, properties: {}})
    })

    html2canvas(image.target.getCanvasContainer(), {useCORS: true, width: 600, height: 350}).then(
      (canvas) => {
        let map1Image = canvas.toDataURL('image/png', 1.0)
        setMapImage(map1Image)
      }
    )
  }

  const features: any[] = []
  // eslint-disable-next-line array-callback-return
  dataImoveis.GetEmpreendimentoImovel.result.map((imoveis) => {
    features.push({type: 'Feature', geometry: imoveis.terreno.marcacao, properties: {}})
  })

  return (
    <>
      <div style={{width: '148rem'}}>
        {!mapImage ? (
          <div style={{position: 'fixed', top: 0, zIndex: -1}}>
            <Mapbox
              latLng={[-46.6, -23.6]}
              inputRef={mapRef}
              onLoad={onload}
              features={features}
            ></Mapbox>
          </div>
        ) : (
          ''
        )}

        {mapImage ? (
          <MeuEmpreendimentoReport data={data} mapImage={mapImage}></MeuEmpreendimentoReport>
        ) : (
          <div className='overlay loading'>
            <div className='loading-fullscreen'>Carregando mapa...</div>
          </div>
        )}
      </div>
    </>
  )
}
