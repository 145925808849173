import {create} from 'zustand'
import {HexColor, RGBAColor, RGBColor} from '../app/pages/explorer/components/Map/Layer/Types/layers'
import {mapStore} from './mapStore'

interface FillColor {
  'fill-color'?: HexColor | RGBColor | RGBAColor 
  'line-color'?: HexColor | RGBColor | RGBAColor 
  'text-color'?: HexColor | RGBColor | RGBAColor 
  'fill-opacity'?:number
  'line-width'?:number
  opacity?: number
}

export interface Layer {
  uuid: string
  nome: string
  descricao:string
  source:string
  data?: any
  visible: boolean
  tile: string
  style: FillColor
  id: number
  label: string
  filter: Object
  distance?: number
  // color: RGBAColor
  intersect?: JSON
  intersectId?: string
  operation?: string | null
  composite: any[]
  selectedPolygon?: []
  highlightColor?: RGBAColor
  pickable?: boolean
  extruded?: false
  tooltip?: any
  positionZ?: number
  icon?: string
  propriedades?: any[]
  parentId?: string
  type: 'MVTLayer' | 'GeoJSONLayer' | 'LayerIcon' | 'LayerPolygon' 
  // paint: FillColor
  opacity?: number
  extrude?: boolean
  explorer_layer_tags:any | null
  explorer_tiles:any | null
  schema_table:string



}

export interface LayerList {
  layers: Layer[] | null
  currentLayer: number
  getLayer: (index: number) => Layer
  getLayers: () => Layer[]
  addLayer: (layer: Layer) => void
  clear: () => void
  addEvent: (eventType: string, param: any) => any
  updateLayer:(index:number,key:string, value:any)=>void
  updateStyleLayer:(index:number,key:string, value:any)=>void
}

export const layerStore = create<LayerList>((set, get) => {
  return {
    layers: [],
    currentLayer: 0,
    getLayer: (index: number) => {
      const {layers} = get()
      return layers[index] 
      // .filter(function(l){ return l.uuid === uuid} )[0]
    },
    getLayers: () => {
      const {layers} = get()
      return layers
    },
    addLayer: (layer: Layer) => {
      const {layers} = get()
    
      set({
        layers: [...layers, layer],
      })

    },
    updateStyleLayer:(index:number,key:string, value:any)=>{
      const {layers} = get()
      if (typeof  layers[index] !== 'undefined'){
        let style = {}
        style[key] = value
        const l = Object.assign({}, layers[index].style , style) 
        layers[index].style = l 
      }
      set({layers:layers})
    },
    updateLayer:(index:number,key:string, value:any)=>{
      const {layers} = get()

      // if (layers[index].length > 0) {

      // }
      // console.log(layers[index])
      // // layers[index]
      // // eslint-disable-next-line array-callback-return
      // const result = layers.map((item)=>{

      //   if (item.uuid === uuid){
      //     if (layers[index].length > 0) {
      //       if (layers[index].indexOf(value) !== -1) {
      //       } else {
      //         layers[index].push(value)
      //       }
      //     } else {
      //       layers[index].push(value)
      //     }
      //   }
      // })[0]

      set({layers:layers})
    },
    clear: () => {
      set({
        layers: [],
      })
    },
    addEvent: (eventType: string, param: any) => {
      const map = mapStore.getState().map
      switch (eventType) {
        case 'click': {
          const pickInfo = map.pickMultipleObjects(param)
          return pickInfo
        }
      }
    },
    // setSelectedPolygon:(uuid,id)=>{
    //   Explorer.layers
    //   updateLayer(uuid, selectedPolygon,value)
    // }
  }
})