import React from 'react'

import {Link} from 'react-router-dom'
import {FaHospitalUser, FaServer, FaCaretRight} from 'react-icons/fa'
import { MutationSetLogAcessoRecurso } from '../../../../../app/graphql/services/LogAcesso'

export function MenuCadastro() {
const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  return (
    <>
      <span className='menu-link'>
        <span className='menu-icon'>
          <i className='ki-duotone ki-address-book fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        </span>
        <span className='menu-title'>Cadastros</span>
        <span className='menu-arrow'></span>
      </span>
      <div className='m-0'>
        <ul className='nav nav-pills nav-pills-custom flex-column border-transparent fs-5 fw-bold'>
          <li className='nav-item mt-5'>
            <Link
              to='/cadastro/corretores'
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'corretores', action: 'list', url: '/cadastro/corretores' })}
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-secondary p-0 border-0 '
            >
              <FaHospitalUser
                size={20}
                color='#8a8a8a'
                className='pe-2 pb-1 text-active-primary text-hover-secondary'
              />
              Corretores
            </Link>
          </li>
          <li className='nav-item mt-2'>
            <Link
              to='/cadastro/proprietarios'
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'proprietarios', action: 'list', url: '/cadastro/proprietarios' })}
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-secondary p-0 border-0 '
            >
              <FaHospitalUser
                size={20}
                color='#8a8a8a'
                className='pe-2 pb-1 text-active-primary text-hover-secondary'
              />
              Proprietários
            </Link>
          </li>
          <li className='nav-item mt-2'>
            <Link
              to='/cadastro/cadastro-grupos'
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'grupos-servicos', action: 'list', url: '/cadastro/cadastro-grupos' })}
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-secondary p-0 border-0 '
            >
              <FaServer
                size={20}
                color='#8a8a8a'
                className='pe-2 pb-1 text-active-primary text-hover-secondary'
              />
              Grupo de Serviços
            </Link>
          </li>
          <li className='nav-item mt-2'>
            <Link
              to='/cadastro/situacao-terrenos'
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'situacao-terrenos', action: 'list', url: '/cadastro/situacao-terrenos' })}
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-secondary p-0 border-0 '
            >
              <FaCaretRight
                size={20}
                color='#8a8a8a'
                className='pe-2 pb-1 text-active-primary text-hover-secondary'
              />
              Situações de Terreno
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
