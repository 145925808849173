import { z } from "zod"


const FormEditObservacoesCampos = {
    id_terreno: z.number(),
    id: z.number(),
    titulo: z.string()
        .nonempty('O titulo é obrigatório!'),
    descricao: z.string()
        .nonempty('Descrição é obrigatório!'),
}
export const ImovelObservacaoPutFormSchema = z.object(FormEditObservacoesCampos)
export type ImovelObservacaoPutFormData = z.infer<typeof ImovelObservacaoPutFormSchema>


const FormCreateObservacoesCampos = {
    id_terreno: z.number(),
    titulo: z.string()
        .nonempty('O titulo é obrigatório!'),
    descricao: z.string()
        .nonempty('Descrição é obrigatório!'),
}

export const ImovelObservacaoCreateFormSchema = z.object(FormCreateObservacoesCampos)
export type ImovelObservacaoCreateFormData = z.infer<typeof ImovelObservacaoCreateFormSchema>




const camposEmpreendimento = {
    id: z.number(),
    id_empreendimento: z.number(),
    titulo: z.string()
        .nonempty('O titulo é obrigatório!'),
    descricao: z.string()
        .nonempty('Descrição é obrigatório!'),
}

export const EmpreendimentoObservacaoFormSchema = z.object(camposEmpreendimento)
export type EmpreendimentoObservacaoFormData = z.infer<typeof EmpreendimentoObservacaoFormSchema>

const camposCreateEmpreendimento = {
    id_empreendimento: z.number(),
    titulo: z.string()
        .nonempty('O titulo é obrigatório!'),
    descricao: z.string()
        .nonempty('Descrição é obrigatório!'),
}

export const EmpreendimentoCreateObservacaoFormSchema = z.object(camposCreateEmpreendimento)
export type EmpreendimentoCreateObservacaoFormData = z.infer<typeof EmpreendimentoCreateObservacaoFormSchema>