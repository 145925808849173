import React, { useEffect } from "react";
import { useLocation } from 'react-router'
import clsx from 'clsx'
import { useLayout } from '../../../_metronic/layout/core'
import { DrawerComponent } from '../../../_metronic/assets/ts/components'
import { Footer } from '../../../_metronic/layout/components/Footer'
import { HeaderPage } from '../../../_metronic/layout/components/HeaderPage'
import { CardHeader } from "../../components/UI/CardHeader";
import { TableWidgetPacotes } from "../../components/UI/tables/TableWidgetPacotes";


import '../global.css'

export function Pacotes() {
  const { classes } = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])
  return (
    <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>
            <HeaderPage title="Todos os Pacotes" subtitle='Pacotes' />
            <div className="content  d-flex flex-column flex-column-fluid" id="kt_content" >
              <CardHeader title='Gestão de Pacotes'/>
              <br />
              {/*begin::Card*/}
              <div className="card" style={{ width: '100%' }}>
                {/*begin::Card header*/}
                <div className="card-header  border-0 pt-6">
                  {/*begin::Card title*/}
                  <div className="inboxContent" style={{ width: '100%' }}>
                    <TableWidgetPacotes className='card-xxl-stretch mb-5 mb-xl-8' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}