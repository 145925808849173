import {useEffect, useState} from 'react'
import {useQuery} from '@apollo/client'
import {GetExplorerLayersUsuario} from '../../../../../../graphql/type/explorer-layer-usuario'
import {FIND_EXPLORER_LAYER_USUARIO} from '../../../../../../graphql/query/explorer-layer-usuario'
import uuid from 'react-uuid'
import clearText from '../../../../../../components/util'
import {Explorer} from '../../../Map/Explorer'
import {BiLayerPlus, BiStar} from 'react-icons/bi'
import {AiFillStar} from 'react-icons/ai'
import {useEndereco} from '../../../../../../../hooks/useEndereco'
import {
  MutationDeleteExplorerLayerUserFavorito,
  MutationSetExplorerLayerUserFavorito,
} from '../../../../../../graphql/services/ExplorerLayerFavorito'
import {useMapaConfig} from '../../../../../../../hooks/useMapaConfig'
import {MutationSetLogAcessoRecurso} from '../../../../../../graphql/services/LogAcesso'
import {ButtonHandleClickChangeIcon} from './components/ButtonHandleClickChangeIcon'

export default function ListaCamadas({filtroTags, intersect = false}) {
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const [search, setSearch] = useState('')
  const {mapaConfig, onMapaConfig} = useMapaConfig()
  const {endereco} = useEndereco()
  const [municipio, setMunicipio] = useState(endereco.enderecoAtivo)
  const {data} = useQuery<GetExplorerLayersUsuario>(FIND_EXPLORER_LAYER_USUARIO, {
    variables: {idMunicipio: Number(municipio)},
  })

  const {FormExplorerLayerUserFavorito} = MutationSetExplorerLayerUserFavorito()
  const {FormDeleteExplorerLayerUserFavorito} = MutationDeleteExplorerLayerUserFavorito()

  useEffect(() => {
    setMunicipio(endereco.enderecoAtivo ? endereco.enderecoAtivo : 5351)
  }, [endereco])

  if (!data) {
    return <div>Carregando..</div>
  }

  let layersList = data?.GetExplorerLayersUsuario

  const addLayersListIntersect = function (id) {
    const layerInfo = Explorer.getInfoLayer(mapaConfig.layerSelected)
    let featureCollection = {
      type: 'FeatureCollection',
      features: [],
    }
    var jsonEncoded = ''
    if (layerInfo['type'] === 'MVTLayer') {
      let feature = {
        type: 'Feature',
        geometry: {
          type: mapaConfig.geom.type,
          coordinates: mapaConfig.geom.coordinates,
        },
      }
      featureCollection.features.push(feature)
      jsonEncoded = btoa(JSON.stringify(featureCollection))
    } else {
      if (layerInfo['data'].type === 'FeatureCollection') {
        featureCollection = layerInfo['data']
      } else {
        let feature = layerInfo['data'][0]
        featureCollection.features.push(feature)
      }

      jsonEncoded = btoa(JSON.stringify(featureCollection))
    }
    let newLayer = data?.GetExplorerLayersUsuario.filter(function (l) {
      return l.id === id
    })
    let service = newLayer[0].source
    let schema = newLayer[0].schema_table
    let layer = {...newLayer[0]}
    layer['uuid'] = uuid()
    layer['visible'] = true
    layer['parentId'] = mapaConfig.layerSelected
    layer['source'] = newLayer[0]['source'] + '_operacao'
    layer['source-layer'] = newLayer[0]['source-layer'] + '_operacao'

    layer['type'] = 'MVTLayer'
    layer['tile'] =
      `${process.env.REACT_APP_MAP_VECTORTILE}/operation/intersect/{z}/{x}/{y}.pbf?layer=` +
      service +
      '_operacao&l1=' +
      service +
      '&s=' +
      schema +
      '&l2=&g1=' +
      jsonEncoded
    layer['paint'] = {...newLayer[0].paint}
    const label = Explorer._getLabel(layer['explorer_tiles'])
    const lId = Explorer.addLayerTile(layer, label)
    onMapaConfig({legendaLayerSelected: lId})
    FormSetLogAcessoRecurso({
      ferramenta: 'explorer',
      funcionalidade: 'add-layer-intersect-' + layer.nome,
      action: 'add',
      url: `/explorer`,
    })

  }

  const addLayersList = function (id) {
    let newLayer = data?.GetExplorerLayersUsuario.filter(function (l) {
      return l.id === id
    })
    let layer = {...newLayer[0]}
    layer['uuid'] = uuid()
    layer['visible'] = true
    // layer['type'] = 'VectorLayer'
    layer[
      'tile'
    ] = `${process.env.REACT_APP_MAP_VECTORTILE}/tile/${newLayer[0].source}/{z}/{x}/{y}.pbf?s=${newLayer[0].schema_table}`
    layer['paint'] = {...newLayer[0].paint}
    const label = Explorer._getLabel(layer['explorer_tiles'])
    const lId = Explorer.addLayerTile(layer, label)
    onMapaConfig({legendaLayerSelected: lId})
    FormSetLogAcessoRecurso({
      ferramenta: 'explorer',
      funcionalidade: 'add-layer-' + layer.nome,
      action: 'add',
      url: `/explorer`,
    })
  }

  const filterTag = (tagsList, busca) => {
    for (const i in tagsList) {
      if (tagsList[i]['explorer_tags']['tag'] === busca) {
        return true
      }
    }
    return false
  }

  const filtrarLayer = (busca, tipo) => {
    let filtered = []
    filtered = layersList.filter((item) => {
      if (tipo === 'texto') {
        if (
          clearText(item.nome).search( clearText(busca.toLowerCase())) >= 0 ||
          clearText(item.descricao).search( clearText(busca.toLowerCase())) >= 0
        ) {
          return true
        } else {
          return false
        }
      } else {
        if (item['explorer_layer_tags'].length > 0) {
          if (busca === 'destaque') {
            if (item.destaque) {
              return true
            } else {
              return false
            }
          } else {
            return filterTag(item['explorer_layer_tags'], busca)
          }
        } else {
          return false
        }
      }
    })

    filtered = [...filtered].sort((a, b) => {
      let one = a.is_favorite ? 1 : 0
      let two = b.is_favorite ? 1 : 0

      if (one > two) {
        return -1
      } else {
        return 1
      }
      // eslint-disable-next-line no-unreachable
      return 0
    })

    return filtered
  }

  let filteredLayers = layersList
  if (filtroTags !== '' && search.length === 0) {
    filteredLayers =
      filtroTags !== '' && filtroTags.length > 0 ? filtrarLayer(filtroTags, 'tag') : []
  } else {
    filteredLayers = search.length > 0 ? filtrarLayer(search, 'texto') : []
  }

  layersList = [...layersList].sort((a, b) => {
    let one = a.is_favorite ? 1 : 0
    let two = b.is_favorite ? 1 : 0

    if (one > two) {
      return -1
    } else {
      return 1
    }
    // eslint-disable-next-line no-unreachable
    return 0
  })
  const municipioList = Object.keys(endereco.municipio).length === 0 ? null : endereco.municipio
  function changeMunicipio(event) {
    setMunicipio(event.target.value)
  }

  function favoritar(e, id: number) {
    e.target.innerHTML = <AiFillStar className='fs-1 text-warning pointer'></AiFillStar>
    FormExplorerLayerUserFavorito(id)
  }

  function desfavoritar(e, id) {
    e.target.innerHTML = <AiFillStar className='fs-1 text-warning pointer'></AiFillStar>
    FormDeleteExplorerLayerUserFavorito(id)
  }
  
  return (
    <>
      <div className='row'>
        <div className='col-md-8'>
          <input
            type='text'
            className='form-control'
            placeholder='Buscar Camada'
            name='busca'
            id='busca'
            onChange={(e) => {
              filtroTags = ''
              setSearch(e.target.value)
            }}
          />
        </div>
        <div className='col-md-4 d-flex'>
          <div className='col-4 pt-3 pe-2'>Município:</div>
          <div className='col-8'>
            <select onChange={changeMunicipio} className='form-control'>
              {
                // eslint-disable-next-line array-callback-return
                municipioList
                  ? municipioList.map((el) => {
                      return (
                        <option value={el.id} key={el.id} defaultValue={endereco.enderecoAtivo}>
                          {el.nome}
                        </option>
                      )
                    })
                  : ''
              }
            </select>
          </div>
        </div>
      </div>

      <div className='table-responsive'>
        <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
          <tbody className='text-gray-600 '>
            {search.length > 0 || filtroTags.length > 0
              ? filteredLayers.map(function (layer, y) {
                  return (
                    <tr key={layer.id + '_' + y} data-id={layer.id}>
                      <td className=' align-items-center'>
                        {layer.is_favorite ? (
                          <span
                            onClick={(e) => desfavoritar(e, layer.id)}
                            style={{display: 'block'}}
                          >
                            <AiFillStar className='fs-1 text-warning pointer'></AiFillStar>
                          </span>
                        ) : (
                          <span onClick={(e) => favoritar(e, layer.id)} style={{display: 'block'}}>
                            <BiStar className='fs-1 pointer'></BiStar>
                          </span>
                        )}
                      </td>
                      <td key={'td_' + layer.id + '_' + y} className=' align-items-center'>
                        <div className='d-flex flex-column'>
                          <span className='fw-semibold'>{layer.nome}</span>
                          <span key={layer.id + '_' + y}>{layer.descricao}</span>
                        </div>
                      </td>
                      <td></td>
                      <td style={{width: '140px'}}>
                        <ButtonHandleClickChangeIcon
                          variante={'outline-info'}
                          icone={''}
                          texto={'texto'}
                          onClick={() => {
                            if (intersect) {
                              addLayersListIntersect(layer.id)
                            } else {
                              addLayersList(layer.id)
                            }
                          }}
                        >
                          <BiLayerPlus className='fs-1'></BiLayerPlus> Adicionar
                        </ButtonHandleClickChangeIcon>
                      </td>
                    </tr>
                  )
                })
              : layersList.map(function (layer, y) {
                  return (
                    <tr key={layer.id + '_' + y} data-id={layer.id} className='border-bottom-tr '>
                      <td className=' align-items-center'>
                        {layer.is_favorite ? (
                          <span
                            onClick={(e) => desfavoritar(e, layer.id)}
                            style={{display: 'block'}}
                          >
                            <AiFillStar className='fs-1 text-warning pointer'></AiFillStar>
                          </span>
                        ) : (
                          <span onClick={(e) => favoritar(e, layer.id)} style={{display: 'block'}}>
                            <BiStar className='fs-1 pointer'></BiStar>
                          </span>
                        )}
                      </td>
                      <td key={'td_' + layer.id + '_' + y} className=' align-items-center'>
                        <div className='d-flex flex-column'>
                          <span className='fw-semibold'>{layer.nome}</span>
                          <span>{layer.descricao}</span>
                        </div>
                      </td>
                      <td></td>
                      <td style={{width: '140px'}}>
                        <ButtonHandleClickChangeIcon
                          variante={'outline-info'}
                          icone={''}
                          texto={'texto'}
                          onClick={() => {
                            if (intersect) {
                              addLayersListIntersect(layer.id)
                            } else {
                              addLayersList(layer.id)
                            }
                          }}
                        >
                          <BiLayerPlus className='fs-1'></BiLayerPlus> Adicionar
                        </ButtonHandleClickChangeIcon>
                        {/* <button
                          onClick={() => {
                            if (intersect) {
                              addLayersListIntersect(layer.id)
                              FormSetLogAcessoRecurso({
                                ferramenta: 'explorer',
                                funcionalidade: 'add-layer' + layer.nome,
                                action: 'add',
                                url: `/explorer`,
                              })
                            } else {
                              addLayersList(layer.id)
                              FormSetLogAcessoRecurso({
                                ferramenta: 'explorer',
                                funcionalidade: 'add-layer-' + layer.nome,
                                action: 'add',
                                url: `/explorer`,
                              })
                            }
                          }}
                          className='btn btn-icon btn-sm btn-light-primary'
                        > 
                          <BiLayerPlus className='fs-1'></BiLayerPlus>
                        </button> */}
                      </td>
                    </tr>
                  )
                })}
          </tbody>
        </table>

        {/* <Tree dataExplorer={data?.GetExplorerLayersUsuario} /> */}
      </div>
    </>
  )
}
