import React from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'

import { MutationDeleteExplorerLayer } from '../../../graphql'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { APiUrbit } from '../../../../services/api/apiUrbit'

type Props = {
  id: number;
  source:string
  schema:string
}

export const Buttons: React.FC<Props> = ({ id,source,schema }) => {

  const { HandleDeleteExplorerLayer, loading } = MutationDeleteExplorerLayer();

  if (loading) {
    Swal.fire(
      'Enviando Informações...', '')
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {

        const result = await HandleDeleteExplorerLayer({ variables: { deleteExplorerLayerId: id } })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Layer deletado com sucesso.', 'success')
        }
      }
    })
  }
  async function clearCache(schema:string,source:string) {

    const api = new APiUrbit();
    const result = await api.get(`explorer/operation/clear-cache?schema=${schema}&source=${source}`);
    if (typeof result.erro === 'undefined'){
      Swal.fire('ERRO!', 'Erro ao executar a operação ou o diretório ja está vazio.', 'warning')
    }else{
      if (result.erro){
        Swal.fire('ERRO!', result.mensagem, 'warning')
      }else{
        Swal.fire('Sucesso!', result.mensagem, 'success')
      }
    }
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button className='btn btn-warning me-2' onClick={()=>{
          // eslint-disable-next-line no-restricted-globals
          if (confirm('Deseja apagar o cache?')){
            clearCache(schema,source)
          }
        }}>Clear Cache</button>
        <Link
          to={`/backoffice/explorer-layers/edit/${id}`}
          className='btn btn-icon btn-light-success me-2'>
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
        <button
          onClick={() => {
            confirmDelete(id)
          }}
          disabled={loading}
          className='btn btn-icon btn-light-danger  btn-sm me-1' >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
