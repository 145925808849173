import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ModalAction} from './ModalAction'

type Props = {
  id: number
}

export const ButtonsAction: React.FC<Props> = ({id}) => {
  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          type='button'
          className='btn btn-success'
          data-bs-toggle='modal'
          data-bs-target='#modalDetailsPedidos'
        >
          <KTSVG path='/media/invoice.svg' className='svg-icon-3' />
          Ver Detalhes
        </button>

        <ModalAction />
      </div>
    </>
  )
}
