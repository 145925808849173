import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {HeaderUserMenu} from '../../../partials'
import {FaUserCircle} from 'react-icons/fa'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  // toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
// toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const [images, setImages] = useState(null)
  useEffect(() => {
    const profileIMG = localStorage.getItem('user_pic')
    if (profileIMG !== null && profileIMG !== 'null') {
      setImages(profileIMG)
    }
  }, [images])

  return (
    <>
      {/* <MyApps /> */}

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {images !== 'null' && images !== null ? (
            <img src={images} alt='' className='logo-topo' />
          ) : (
            <FaUserCircle size={24} className='fs-4 text-secondary border-1 p-0' />
          )}
          {/* <img src="https://avatars.githubusercontent.com/u/57899212?v=4" alt='metronic' /> */}
        </div>
        <HeaderUserMenu imageProfile={images} />

        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </>
  )
}

export {Topbar}
