import { z } from "zod"

export const getCheckTokenVerifyFormSchema = z.object({
    tokenCode: z.string()
        .nonempty('Preencha o campo, é obrigatório!'),
})

export type getCheckTokenVerifyFormData = z.infer<typeof getCheckTokenVerifyFormSchema>



export const setRecuperarSenhaFormSchema = z.object({
    email: z.string()
        .nonempty('Preencha o campo, é obrigatório!'),
})

export type setRecuperarSenhaFormData = z.infer<typeof setRecuperarSenhaFormSchema>


export const putEsqueciSenhaFormSchema = z.object({
    password: z.string()
        .nonempty('Preencha o campo, é obrigatório!')
        .min(8, 'A senha precisa ter no minimo 8 caracteres')
        .refine(value => /[a-z]/.test(value), 'A senha deve conter pelo menos uma letra minúscula.')
        .refine(value => /[A-Z]/.test(value), 'A senha deve conter pelo menos uma letra maiúscula.')
        .refine(value => /\d/.test(value), 'A senha deve conter pelo menos um número.')
        .refine(value => /[@$!%*?&]/.test(value), 'A senha deve conter pelo menos um caractere especial (@ $ ! % * ? &).'),
})

export type putEsqueciSenhaFormData = z.infer<typeof putEsqueciSenhaFormSchema>