import {useExplorerComposicao} from '../../../../../../../hooks/useExplorerComposicao'
import {Composicao} from './composicao'
type PropsContextMenu = {}

export const ComposicaoControl: React.FC<PropsContextMenu> = () => {
  const {OnSetExplorerComposicao, composicao, onUpdateComposicao} = useExplorerComposicao()
  Composicao.composicaoList = composicao
  Composicao.setExplorerComposicao = OnSetExplorerComposicao
  Composicao.onUpdateComposicao = onUpdateComposicao
  return <></>
}
