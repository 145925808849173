import {useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Explorer} from '../Explorer'
import {CardMapBase} from './CardMapBase'
export function MapTypes() {
  const [active, setActive] = useState('roadmap')
  const mapaBaseList = [
    {
      id: '1',
      nome: 'Cinza',
      typeId: 'ligth',
      posicao: 6,
    },
    {
      id: '2',
      nome: 'Comércio',
      typeId: 'roadmap',
      posicao: 2,
    },
    {
      id: '3',
      nome: 'Satélite',
      typeId: 'hybrid',
      posicao: 1,
    },
    {
      id: '4',
      nome: 'Ruas',
      typeId: 'comercios_map',
      posicao: 4,
    },
    {
      id: '5',
      nome: 'Noturno',
      typeId: 'noturno',
      posicao: 5,
    },
  
  ]
  const onMapChange = (id) => {
    Explorer.changeMapType(id)
    // eslint-disable-next-line array-callback-return
    mapaBaseList.map((item) => {
      if (item.typeId === id) {
        setActive(item.typeId)
      }
    })
  }

  return (
    <div className='d-flex align-items-center justify-content' id='maptype'>
      <div className='maptype-list d-flex align-items-center justify-content'>
        {
          // eslint-disable-next-line array-callback-return
          mapaBaseList?.map((mapa, id) => {
            return active !== mapa.typeId ? (
              <div className='maptype-item' key={id}>
                <CardMapBase
                  title={mapa.nome}
                  key={mapa.id}
                  id={mapa.id}
                  src={toAbsoluteUrl('/media/icones/' + mapa.typeId + '.png')}
                  info={mapa.nome}
                  onClick={() => {
                    onMapChange(mapa.typeId)
                  }}
                ></CardMapBase>
              </div>
            ) : (
              ''
            )
          })
        }
      </div>
      <div className='maptype-active'>
        {mapaBaseList?.map((mapa, id) => {
          return active === mapa.typeId ? (
            <CardMapBase
              title={mapa.nome}
              key={mapa.id}
              id={mapa.id}
              src={toAbsoluteUrl('/media/icones/' + mapa.typeId + '.png')}
              info={mapa.nome}
              onClick={() => {
                onMapChange(mapa.typeId)
              }}
            ></CardMapBase>
          ) : (
            ''
          )
        })}
      </div>
    </div>
  )
}
