import {latLng} from '../app/components/util'
import {layerStore} from './layerStore'
import {mapStore} from './mapStore'

export const MapControll = {
  actions: [],
  panTo: (latLng: latLng) => {
    return mapStore((state) => {
      const map = state.map
      if (map) {
        map._map.panTo(new google.maps.LatLng(latLng.lat, latLng.lng))
      }
    })
  },
  goToMapItem: (index: number) => {
    const {getLayer} = layerStore.getState()
    const {map} = mapStore.getState().map
    const layer = getLayer(index)

    if (map) {
      if (layer['type'] === 'LayerIcon') {
        const [lng,lat] = layer['data'][0].coordinates
        // const lng = layer['data'][0].coordinates[0]
        map._map.panTo(new google.maps.LatLng(lat, lng))
      }
    }
  },
  changeVisibility: (index: number, visible: boolean) => {
    layerStore.getState().updateLayer(index, 'visible', visible)
  },
  pickObjects: (param: any) => {
    const {map} = mapStore.getState()
    const pickInfo = map.pickMultipleObjects(param)
    return pickInfo
  },
  mapActionsOnClick: (event, callback) => {

    const  onClickEnventFunc=  mapStore.getState().getOnClickEventFuncs()
    if (onClickEnventFunc){
      // eslint-disable-next-line array-callback-return
      Object.entries(onClickEnventFunc).map((element:object) => {
          const func = element[1]
          func(event, callback)
      })
    }
  },
    mapActionsOnMove: (event, callback) => {

    const  onMoveEnventFunc=  mapStore.getState().getOnMoveEventFuncs()
    if (onMoveEnventFunc){
      // console.log('mousemove')
      // console.log(onMoveEnventFunc)
      // eslint-disable-next-line array-callback-return
      Object.entries(onMoveEnventFunc).map((element:object) => {
          const func = element[1]
          // console.log(func)
          func(event, callback)
      })
    }
  },
  setMapActionClick: (name, func) => {
    mapStore.getState().setOnClickEventFunc(name, func)
  },

  setMapActionMove: (name, func) => {
    mapStore.getState().setOnClickEventFunc(name, func)
  },
  setDraw: (draw) => {
    mapStore.getState().setDraw(draw)
  },
  removeMapActionMove:(name)=>{
    mapStore.getState().removeOnMoveEventFunc(name)
  },
  removeMapActionClick:(name)=>{
    mapStore.getState().removeOnClickEventFunc(name)
  },
  setInfoBox:(componente: string, info: string, show: boolean)=>{
    mapStore.getState().setInfoBox(componente,info,show)
  },
  setMode:(mode: string)=>{
    mapStore.getState().setMode(mode)
  },
 getMode:()=>{
    return mapStore.getState().getMode()
  },
getMap:()=>{
   return mapStore.getState().getMap()
 },
  getInfoBox:()=>{
    return mapStore.getState().getInfoBox()
  },
  getMeassure: () => {
    return mapStore.getState().meassure
  }
}
