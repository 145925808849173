import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import '../global.css'
import { DrawerComponent } from '../../../_metronic/assets/ts/components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Tab1 } from './partials/SupportPage/Tabs/Tab1'
import { Tab2 } from './partials/SupportPage/Tabs/Tab2'
import { Tab3 } from './partials/SupportPage/Tabs/Tab3'
import { Tab4 } from './partials/SupportPage/Tabs/Tab4'
import { Tab5 } from './partials/SupportPage/Tabs/Tab5'
import { RecentPosts } from './partials/SupportPage/partials/RecentPosts'

export function SupportPage() {
    const location = useLocation()
    useEffect(() => {
        DrawerComponent.hideAll()
    }, [location])


    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                <div className='max-w-1200px py-10'>
                    <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                        <div className='post d-flex flex-column-fluid' id='kt_post'>
                            {/* <HeaderPage /> */}
                            <div id='kt_content_container' className='container-fluid'>

                                <div className='kt_content_container  d-flex flex-column flex-column-fluid'>
                                    <div className="card">
                                        <div className="card-body p-lg-15">
                                            <div className="d-flex flex-column flex-lg-row">
                                                <div className="flex-column flex-lg-row-auto w-100 mb-10 me-lg-20">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <div className="mb-15">
                                                                <h4 className="text-dark mb-7">Categorias</h4>

                                                                <div className="menu menu-rounded menu-column menu-title-gray-700 menu-state-title-primary menu-active-bg-light-primary fw-semibold">

                                                                </div>
                                                                <div className="d-flex align-items-start">
                                                                    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                                                                        <div className="menu-item mb-1">
                                                                            <a href="#a" className=" menu-link py-3 text-black text-active-success active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                                                                Meus Imoveis
                                                                            </a>

                                                                        </div>
                                                                        <div className="menu-item mb-1">
                                                                            <a href="#a" className="menu-link py-3 text-black text-hover-success text-active-success" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                                                                Empreendimentos
                                                                            </a>
                                                                        </div>
                                                                        <div className="menu-item mb-1">
                                                                            <a href="#a" className="menu-link py-3 text-black text-hover-success text-active-success" id="v-pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-disabled" role="tab" aria-controls="v-pills-disabled" aria-selected="false">
                                                                                Lançamentos
                                                                            </a>
                                                                        </div>
                                                                        <div className="menu-item mb-1">
                                                                            <a href="#a" className="menu-link py-3 text-black text-hover-success text-active-success" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                                                                Corretores
                                                                            </a>
                                                                        </div>
                                                                        <div className="menu-item mb-1">
                                                                            <a href="#a" className="menu-link py-3 text-black text-hover-success text-active-success" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                                                                AVM
                                                                            </a>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="mb-16">
                                                                <h4 className="text-dark mb-7">Search Blog</h4>

                                                                <div className="position-relative">
                                                                    <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-6"><span className="path1"></span><span className="path2"></span></i>
                                                                    <input type="text" className="form-control form-control-solid ps-10" name="search" value="" placeholder="Search" />
                                                                </div>
                                                            </div>

                                                           <RecentPosts />
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="tab-content" id="v-pills-tabContent">
                                                                <Tab1 />
                                                                <Tab2 />
                                                                <Tab3 />
                                                                <Tab4 />
                                                                <Tab5 />
                                                            </div>
                                                        </div>
                                                    </div>








                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end::Content */}
                    {/* begin::Footer */}
                    <div className='d-flex flex-center flex-column-auto p-10'>
                        <div className='d-flex align-items-center fw-bold fs-6'>
                            <label className='text-muted text-hover-primary px-2'>
                                Sobre Nós
                            </label>

                            <label className='text-muted text-hover-primary px-2'>
                                Contato
                            </label>
                        </div>
                    </div>
                    {/* end::Footer */}
                </div>
            </div>
        </div>
    )
}
