import { Buttons } from './Buttons'
import { QueryGetNewsFeed } from '../../../graphql'
import { Loader } from '../../../../components/UI/Loader/Loader'
import { useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { CreateForm } from './CreateForm'
import { EditForm } from './EditForm'
import { DateTimToView } from '../../../../components/util'
import TextoCurto from '../../servicos/partials/TextoReduzido'
import { CardTables } from '../../../../components/UI/CardTables'
import PaginationComponent from '../../../../components/UI/PaginationComponents'
import LoadingTable from '../../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../../components/UI/CardTables/ErrorTable'

type Props = {
  datas: any;
};


export function TableAdminNewsFeed({ datas }: Props) {
  const [paginacao, setPaginacao] = useState({ pagina: 0, quantidade: 10 })

  const { data, loading, error } = QueryGetNewsFeed({
    variables: {
      pagination: {
        pagina: paginacao.pagina,
        quantidade: paginacao.quantidade,
      },
      datas,
    }
  })
  console.log(datas)

  const [showCreate, setShowCreate] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [id, setId] = useState(null)

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }
  function CloseModalEdit() {
    setShowEdit(false)
    setId(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setId(id)
  }

  if (!data) {
    return <Loader text={'caregando...'}></Loader>
  }

  return (
    <>
      <CardTables
        headerTitle={'Notícias'}
        headerToolbar={
          <button
            className='btn btn-sm btn-light-primary'
            onClick={(e) => {
              e.preventDefault()
              OpenModalCreate(null)
            }}
          >
            <BiPlus className='fs-3'></BiPlus>
            Nova Notícia
          </button>
        }
        headerSubtTitle={`Listando ${data ? data.GetNewsFeed.pageInfo.totalItems : 0} notícias registrados.`}
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetNewsFeed.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({ pagina: pagina, quantidade: quantidade })
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-150px'>Titulo</th>
                <th className='min-w-150px'>Descrição</th>
                <th className='min-w-140px'>Data de Inicio</th>
                <th className='min-w-140px'>Data de Termino</th>
                <th className='min-w-100px text-end'>Ação</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetNewsFeed.result.length > 0 ? (
                  data.GetNewsFeed.result.map((response) => {
                    return (
                      <tr key={response.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {response.title}
                              </span>

                            </div>
                          </div>
                        </td>
                        <td>
                          <p>
                            <TextoCurto texto={response.description} limiteCaracteres={70} />
                          </p>
                        </td>
                        <td>
                          <p>{DateTimToView(response.start_date)}</p>
                        </td>
                        <td className='text-start'>
                          <p>{DateTimToView(response.end_date)}</p>

                        </td>

                        <td>
                          <Buttons setIdEdit={OpenModalEdit} id={response.id} />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>

      {showEdit ? <EditForm id={id} show={showEdit} closeModal={CloseModalEdit}></EditForm> : <></>}
      {showCreate ? (
        <CreateForm closeModal={CloseModalCreate} show={showCreate}></CreateForm>
      ) : (
        <></>
      )}
    </>
  )
}
