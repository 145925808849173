import { gql } from "@apollo/client";

export const GET_EMPREENDIMENTO_MASSA_USOS = gql`
query GetEmpreendimentoMassaUsos($pagination: Pagination!, $massaId: Float!) {
  GetEmpreendimentoMassaUsos(pagination: $pagination, massa_id: $massaId) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      id
      uuid
      massa_id
      area_terreno
      area_computavel
      area_excedente
      area_privativa
      fator_area_privativa
      area_oodc
      valor_oodc
      fs_id
      fs
      fp
      cabas
      camax
      terreno_id
      uso
      has_beneficio
      quadro14
      ben_melhoramento
      ben_calcada
      ben_fruicao
      unidades_imobiliarias
      area_privativa_por_unidade_imob
      cepac_ben
      cepac_area_onerosa
      cepac_fc
      cepac_qtd_cepac
      cepac_valor_cepac
      cepac_valor_total
      uso_residencial
      manual
      nome
    }
  }
  }
  
`


export const GET_EMPREENDIMENTO_MASSA_USOS_BY_ID = gql`
query GetEmpreendimentoMassaUsosById($getEmpreendimentoMassaUsosByIdId: massaUsos!) {
  GetEmpreendimentoMassaUsosById(id: $getEmpreendimentoMassaUsosByIdId) {
      id
      uuid
      massa_id
      area_terreno
      area_computavel
      area_excedente
      area_privativa
      fator_area_privativa
      area_oodc
      valor_oodc
      fs_id
      fs
      fp
      cabas
      camax
      terreno_id
      uso
      has_beneficio
      quadro14
      ben_melhoramento
      ben_calcada
      ben_fruicao
      unidades_imobiliarias
      area_privativa_por_unidade_imob
      cepac_ben
      cepac_area_onerosa
      cepac_fc
      cepac_qtd_cepac
      cepac_valor_cepac
      cepac_valor_total
      uso_residencial
      manual
      nome
    }
  }
  
`