import {useState} from 'react'
import {Buttons} from './Buttons'
import {QueryGetEmpresas} from '../../../graphql/services/Empresas'
import {ModalSetEmpresa} from './CreateForm'
import PaginationComponent from '../../../../components/UI/PaginationComponents'
import {CardTables} from '../../../../components/UI/CardTables'
import LoadingTable from '../../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../../components/UI/CardTables/ErrorTable'

type Props = {
  filtro: any[]
}

export function TableAdminEmpresas({filtro}: Props) {
  const [paginacao, setPaginacao] = useState({pagina: 0, quantidade: 10})

  const [stateSwitch, setStateSwitch] = useState(1)

  const {data, error, loading} = QueryGetEmpresas({
    variables: {
      pagination: {
        pagina: paginacao.pagina,
        quantidade: paginacao.quantidade,
      },
      filter: {
        fields: filtro,
      },
      situacao: stateSwitch,
    },
  })

  return (
    <>
      <CardTables
        headerTitle={'Empresas'}
        headerSubtTitle={`
                Listando ${data ? data.GetEmpresas.pageInfo.totalItems : 0} empresas registrados.`}
        headerToolbar={<ModalSetEmpresa />}
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetEmpresas.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({pagina: pagina, quantidade: quantidade})
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='w-50px'>ID</th>
                <th className='min-w-140px'>Nome da Empresa</th>
                <th className='min-w-180px'>Email</th>
                <th className='min-w-100px'>Telefone</th>
                <th className='min-w-100px text-end'>Ação</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetEmpresas.result.length > 0 ? (
                  data.GetEmpresas.result.map((response) => {
                    return (
                      <tr key={response.id}>
                        <td>{response.id}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {response.nome}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{response.email ? response.email : 'Não informado'}</td>
                        <td>{response.telefone ? response.telefone : 'Não informado'}</td>
                        <td>
                          <Buttons situacao={response.situacao} id={parseInt(response.id)} />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
    </>
  )
}
