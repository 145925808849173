
import { GetProfile } from '../../../graphql/type/user'
import { useState } from 'react'
import { UploadImage } from '../../../../services/api'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import secureLocalStorage from 'react-secure-storage'

type Props = {
  data: GetProfile
}

export function CardHeaderNavigation({ data }: Props) {

  const [images, setImages] = useState(null)
  const [imagesP, setImagesP] = useState((data.GetProfile?.user_pic ? data.GetProfile.user_pic : ''))
  const empresaData: any = secureLocalStorage.getItem('empresa') || null

  let dadosEmpresa = null
  if (empresaData) {
    dadosEmpresa = JSON.parse(empresaData)
  }


  const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    imageList.forEach(async (item) => {
      let returnData = await UploadImage(item.dataURL)
      console.log(item.dataURL)

      console.log(item)
      setImages(returnData)
      setImagesP(returnData)

    })
  }

  // profile.png

  if (!data) {
    return (
      <>
        <div className="card mb-5 mt-6 mb-xl-10">
          <div className="card-body pb-0">
            <div className="row">
              <div className="col-2">
                <div className="me-7 mb-4">
                  <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative pt-8">
                    <ImageUploading value={images} onChange={onChange} maxNumber={1}>
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        <>
                          {imagesP !== null ? <img src={imagesP !== null ? imagesP : 'https://github.com/EdLoth.png'} alt="user" className='logo' />
                            : <svg xmlns="http://www.w3.org/2000/svg" width="240" height="240" viewBox="0 0 24 24" style={{ fill: "rgba(0, 0, 0, 1)", "transform": "msFilter" }}><path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"></path></svg>}
                          <button
                            className="position-absolute mb-2 all-center translate-middle bottom-0 start-100 btn btnSuccess rounded-circle  h-40px w-40px"
                            // data-bs-toggle="modal" data-bs-target="#kt_modal_add_image_upload"
                            onClick={onImageUpload}
                            {...dragProps}
                            style={isDragging ? { color: 'red' } : undefined}
                          >
                            <span className="svg-icon svg-icon-1 svg-icon-primary" style={{ margin: 0 }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" viewBox="0 0 512 512">
                                <path className="permanent" fill="#FFF" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            </span>
                          </button>
                        </>

                      )}
                    </ImageUploading>

                  </div>
                </div>
              </div>
              <div className="col-10">
                <div className="card-header w-100 all-center-between cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Detalhes do Perfil</h3>
                  </div>
                  <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-2">
                        <a href="#vazio">
                          <span className="svg-icon svg-icon-1 svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                              <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF"></path>
                              <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"></path>
                            </svg>
                          </span>
                        </a>
                        <button className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_produtos_shop">Usuario Pro</button>
                        {/* <ProdutoShop /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ul className="nav nav-map-info" id="tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link text-active-primary ms-0 me-10 py-1  active" id="tab-ab" data-bs-toggle="pill" data-bs-target="#tab-perfil" type="button" role="tab" aria-controls="tab-perfil" aria-selected="false">Visão geral</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link text-active-primary ms-0 me-10 py-1" id="tab-payments-tab" data-bs-toggle="pill" data-bs-target="#tab-payments" type="button" role="tab" aria-controls="tab-payments" aria-selected="false">Produtos</button>
              </li>

              {secureLocalStorage.getItem('tipo_usuario') === 'ADMIN' ? (
                <>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link text-active-primary ms-0 me-10 py-1" id="tab-funcionarios-tab" data-bs-toggle="pill" data-bs-target="#tab-funcionarios" type="button" role="tab" aria-controls="tab-funcionarios" aria-selected="false">Funcionários</button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button className="nav-link text-active-primary ms-0 me-10 py-1" id="tab-logs-tab" data-bs-toggle="pill" data-bs-target="#tab-logs" type="button" role="tab" aria-controls="tab-logs" aria-selected="false">Logs</button>
                  </li>
                </>
              ) : (
                ''
              )}
            </ul>
          </div>
        </div>
      </>
    )
  }

  console.log(imagesP)
  return (
    <div className="card mb-5  mt-6 mb-xl-10">
      <div className="card-body pb-0">
        <div className="row">
          <div className="col-2">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <ImageUploading value={images} onChange={onChange} maxNumber={1}>
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <>
                      {imagesP !== null && imagesP !== '' ? <img src={imagesP !== null ? imagesP : ''} alt="user" className='logo' />
                        : <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 24 24" style={{ fill: "rgba(0, 0, 0, 1)", "transform": "msFilter" }}><path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"></path></svg>}

                      <button
                        className="position-absolute mb-2 all-center translate-middle bottom-0 start-100 btn btnSuccess rounded-circle  h-40px w-40px"
                        // data-bs-toggle="modal" data-bs-target="#kt_modal_add_image_upload"
                        onClick={onImageUpload}
                        {...dragProps}
                        style={isDragging ? { color: 'red' } : undefined}
                      >
                        <span className="svg-icon svg-icon-1 svg-icon-primary" style={{ margin: 0 }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" viewBox="0 0 512 512">
                            <path className="permanent" fill="#FFF" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                          </svg>
                        </span>
                      </button>
                    </>

                  )}
                </ImageUploading>

              </div>
            </div>
          </div>
          <div className="col-10">
            <div className="card-header w-100 all-center-between cursor-pointer">
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Detalhes do Perfil</h3>
              </div>
              <div className='logo-company'>
                {dadosEmpresa !== null ? <img height={160} src={`${dadosEmpresa.user_logo_pic}`} alt='' /> : ''}
              </div>
            </div>
            <div className="card-body w-100 px-9">
              <div className="row">
                <div className="col-6">
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Nome Completo</label>
                    <div className="col-lg-8">
                      <span className="fw-bolder fs-6 text-gray-800">{data.GetProfile.name}</span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Empresa</label>
                    <div className="col-lg-8 fv-row">
                      <span className="fw-bolder text-gray-800 fs-6"> {dadosEmpresa !== null ? dadosEmpresa.nome : '-'}</span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Telefone
                      <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Phone number must be active" aria-label="Phone number must be active"></i></label>
                    <div className="col-lg-8 d-flex align-items-center">
                      <span className="fw-bolder fs-6 text-gray-800 me-2">{data.GetProfile.telefone ? data.GetProfile.telefone : 'Não Informado'}</span>
                      {/* <span className="badge badge-success">Verified</span> */}
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">E-mail</label>
                    <div className="col-lg-8">
                      <span className="fw-bolder fs-6 text-gray-800">{`${data.GetProfile.email}`}</span>
                    </div>
                  </div>
                </div>

                {/* <div className="col-6">
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Company Site</label>
                    <div className="col-lg-8">
                      <a href="#vazio" className="fw-bold fs-6 text-gray-800 text-hover-primary">keenthemes.com</a>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">País
                      <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Country of origination" aria-label="Country of origination"></i></label>
                    <div className="col-lg-8">
                      <span className="fw-bolder fs-6 text-gray-800">Brasil</span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Communication</label>
                    <div className="col-lg-8">
                      <span className="fw-bolder fs-6 text-gray-800">{`${data.GetProfile.email}`}</span>
                    </div>
                  </div>
                </div> */}

              </div>

            </div>
          </div>
        </div>
        <ul className="nav nav-map-info" id="tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link text-active-primary ms-0 me-10 py-1  active" id="tab-ab" data-bs-toggle="pill" data-bs-target="#tab-perfil" type="button" role="tab" aria-controls="tab-perfil" aria-selected="false">Visão geral</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link text-active-primary ms-0 me-10 py-1" id="tab-payments-tab" data-bs-toggle="pill" data-bs-target="#tab-payments" type="button" role="tab" aria-controls="tab-payments" aria-selected="false">Produtos</button>
          </li>

          {secureLocalStorage.getItem('tipo_usuario') === 'ADMIN' ? (
            <>
              <li className="nav-item" role="presentation">
                <button className="nav-link text-active-primary ms-0 me-10 py-1" id="tab-funcionarios-tab" data-bs-toggle="pill" data-bs-target="#tab-funcionarios" type="button" role="tab" aria-controls="tab-funcionarios" aria-selected="false">Funcionários</button>
              </li>

              <li className="nav-item" role="presentation">
                <button className="nav-link text-active-primary ms-0 me-10 py-1" id="tab-logs-tab" data-bs-toggle="pill" data-bs-target="#tab-logs" type="button" role="tab" aria-controls="tab-logs" aria-selected="false">Logs</button>
              </li>
            </>
          ) : (
            ''
          )}
        </ul>
      </div>
    </div>
  )
}