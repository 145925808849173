import {Link} from 'react-router-dom'
import {About} from '../../partials/about'
import {Localization} from '../../partials/localization'
import {MapComponent} from '../../partials/map'
import {Typologies} from '../../partials/typologies'
import {RealEstateLaunch} from '../../../../../../services/http/urbit-gis/routes/types'
import {useState} from 'react'
import {latLng} from '../../../../../components/util'
import {Endereco} from '../../../../../pages/explorer/components/common'
import {coordAll} from '@turf/turf'
import {ApiUrbit} from '../../../../../../services/http/urbit'

interface Props {
  data?: RealEstateLaunch
}

export function TabInfos({data}: Props) {

  return (
    <div
      className='tab-pane fade active show'
      id='tab-perfil'
      role='tabpanel'
      aria-labelledby='tab-perfil-tab'
    >
      <div className='row g-5 g-xl-8' style={{alignItems: 'stretch'}}>
        <div className='col-xl-6 d-flex'>
          <div className='card flex-grow-1 mb-5 mb-xl-8'>
            <div className='card-body py-3 pb-8'>
              <h2 className='fw-bolder py-2 m-0 mb-5'>Localização</h2>
              <Localization data={ data}  />
            </div>
          </div>
        </div>
        <div className='col-xl-6 d-flex'>
          <div className='card flex-grow-1 mb-5 mb-xl-8'>
            <div className='card-body py-3'>
              <h2 className='fw-bolder py-4 m-0'>Mapa</h2>
              <MapComponent  />
            </div>
          </div>
        </div>
      </div>

      <div className='row g-5 g-xl-8' style={{alignItems: 'stretch'}}>
        <div className='col-xl-6 d-flex'>
          <div className='card flex-grow-1 mb-5 mb-xl-8'>
            <div className='card-body py-3'>
              <h2 className='fw-bolder py-4 m-0'>Tipologias</h2>
              <Typologies />
            </div>
          </div>
        </div>
        <div className='col-xl-6 d-flex'>
          <div className='card flex-grow-1 mb-5 mb-xl-8'>
            <div className='card-header border-0 pt-5'>
              <h2 className='fw-bolder pt-4 px-8 m-0'>Características</h2>
              <hr />
            </div>
            <div className='card-body py-3'>
              <About data={data} />
            </div>
          </div>
        </div>
      </div>

      <div className='w-100 all-end mt-4'>
        <Link to='/backoffice/empreendimentos' type='button' className='btn btn-danger me-4'>
          Cancelar
        </Link>

        <button type='submit' className='btn btn-success'>
          {data?.id ? 'Salvar' : 'Cadastrar'}
        </button>
      </div>
    </div>
  )
}
