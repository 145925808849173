import React from 'react'

type Props = {
  text: string | null
}

export const LoaderButton: React.FC<Props> = ({ text = 'Enviando...' }) => {
  return (
    <div className="spinner all-center">
      <span className='spinner-border text-white ' role='status'></span>
      <span className='mrl-1'>{text}</span>
    </div>
  )
}
