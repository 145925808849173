import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { PeriodoChosen } from './PeriodoChosen'
import {
  MutationPutEmpresaProduto,
  QueryGetProdutosByEmpresaID,
} from '../../../graphql/services/Empresas'
import DateRangePickerComponent from '../profile/components/partials/DataPickerProducts'
import moment from 'moment'
import { KTSVG } from '../../../../../_metronic/helpers'
import { PutProdutosChosen } from './ProdutosChosenDefault'

export function ModalPutAssociationProduto({ id_produto, id_empresa, id_association }) {
  const [show, setShow] = useState(false)
  const { FormEmpresaProduto, handleSubmit, register, setValue, errors } = MutationPutEmpresaProduto()
  const [valueUsuarioProduto, setValueUsuarioProduto] = useState(null)
  const [valuePeriodo, setValuePeriodo] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const [isPersonalizada, setIsPersonalizada] = useState(false)

  console.log(errors)

  let isDisable = true

  if (valuePeriodo === null || valueUsuarioProduto === null) {
    isDisable = true
  } else if (valuePeriodo === 6 && (startDate === null || endDate === null)) {
    isDisable = true
  } else {
    isDisable = false
  }

  const { data: dataProduto } = QueryGetProdutosByEmpresaID({
    variables: { empresaId: parseInt(id_empresa) },
  })

  function closeModal() {
    setShow(false)
  }
  function openModal() {
    setShow(true)
  }

  function converterData(dataString, formato = 'YYYY-MM-DD') {
    return moment(dataString).toDate()
  }

  if (startDate && endDate) {
    const startDateConverted = converterData(startDate)
    const endDateConverted = converterData(endDate)

    setValue('data_inicio', startDateConverted)
    setValue('data_fim', endDateConverted)
  }

  const onSubmit = async (data) => {
    FormEmpresaProduto(data)
      .then((result) => {
        Swal.fire('Sucesso!', 'Nova periodo associado com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  if (!dataProduto) {
    return <></>
  }

  return (
    <>
      <button onClick={openModal} className='btn btn-icon btn-light-success btn-sm me-2'>
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </button>

      <Modal centered animation show={show} onHide={() => closeModal()} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Alterar periodo do produto:</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-lg'>
          <div className='row'>
            <div className='form-group'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <input type="number" value={id_association} hidden {...register("id", {valueAsNumber: true})}/>
                <input type="number" value={id_empresa} hidden {...register("id_empresa", {valueAsNumber: true})}/>

                <div className='row'>
                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <PutProdutosChosen
                      id_produto={id_produto}
                      valueUsuarioProduto={valueUsuarioProduto}
                      setValueUsuarioProduto={(value: number) => {
                        setValue('id_produto', value)
                        setValueUsuarioProduto(value)
                      }}
                    />
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <PeriodoChosen
                      valuePeriodo={valuePeriodo}
                      setValuePeriodo={(value: number) => {
                        setValue('id_periodo', value)
                        setValuePeriodo(value)
                      }}
                      setIsPersonalizada={setIsPersonalizada} // Passando setIsPersonalizada como prop
                    />
                  </div>
                </div>

                {isPersonalizada ? (
                  <DateRangePickerComponent
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={setStartDate}
                    onEndDateChange={setEndDate}
                    focusedInput={focusedInput}
                    setFocusedInput={setFocusedInput}
                  />
                ) : (
                  <></>
                )}

                <div className='col-md-12 d-flex mrt-1' style={{ justifyContent: 'flex-end' }}>
                  <button
                    onClick={() => closeModal()}
                    className='btn btn-danger pull-right'
                    type='button'
                    data-bs-dismiss='modal'
                  >
                    Fechar
                  </button>
                  <button disabled={isDisable} type='submit' className='btn btn-success mx-2'>
                    Salvar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
