import {ImovelResponse} from '../../../../../graphql'
import { FichaContent } from '../../../../relatorios/ficha/fichaContent'

type Props = {
  data: ImovelResponse
  param: string
}
export const TabFichaTerritorial: React.FC<Props> = ({data, param}) => {
  return (
    <div
    className={`tab-pane fade ${param === 'ficha-territorial' ? 'show active' : ''}`}
      id='ficha-territorial'
      role='tabpanel'
      aria-labelledby='ficha-territorial'
    >
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Ficha Territorial</h3>

          <div className='card-toolbar'>
          </div>
        </div>
        <div className='card-body p-6 pt-5'>
            <FichaContent data={data} ></FichaContent>
        </div>
      </div>
    </div>
  )
}
