import {useEffect, useState} from 'react'

// import de components
import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import {TabsImovelContainer} from './components'
import '../global.css'
import './components/partials/partials.css'
import {HeaderWrapperFluid} from '../../../_metronic/layout/components/header/HeaderWrapperFluid'
import {useHistory} from 'react-router-dom'

import {
  BiBuilding,
  BiCart,
  BiChat,
  BiCompass,
  BiFile,
  BiGroup,
  BiIdCard,
  BiImages,
  BiListCheck,
  BiMoney,
  BiPurchaseTagAlt,
} from 'react-icons/bi'
import {Link, useParams} from 'react-router-dom'
import {QueryGetImovel} from '../../graphql/services'
import {Loader} from '../../components/UI/Loader/Loader'
import {HeaderImovel} from './components/partials/HeaderImovel'
import {ImprimirModal} from './components/Modals/imprimir'
import {encodeUrl} from '../../components/util'
import {MutationSetLogAcessoRecurso} from '../../graphql/services/LogAcesso'

export function DetailsImovel() {
  const loadTabFichasImovel = () => {}

  const {id} = useParams<{id: string}>()
  const {data} = QueryGetImovel({variables: {getImovelId: parseInt(id)}})
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  const [showCreate, setShowCreate] = useState(false)
  const [idModal, setIdModal] = useState(null)

  let history = useHistory()

  const [parametroDaURL, setParametroDaURL] = useState(null)

  useEffect(() => {
    const obterParametroHash = () => {
      const url = window.location.href
      const indiceHash = url.indexOf('#')

      if (indiceHash !== -1) {
        const parametroHash = url.substring(indiceHash + 1)
        return parametroHash
      }

      return null
    }

    const parametroAtual = obterParametroHash()

    if (parametroAtual !== parametroDaURL) {
      // Remove a classe 'active' e 'show' de todos os botões e divs
      document.querySelectorAll('.nav-link').forEach((button) => {
        button.classList.remove('active')
      })

      document.querySelectorAll('.tab-pane').forEach((div) => {
        div.classList.remove('active', 'show')
      })

      // Atualiza o estado apenas se o parâmetro for diferente do estado atual
      if (parametroAtual !== parametroDaURL) {
        setParametroDaURL(parametroAtual)
      }
    }
  }, [parametroDaURL])

  // Função para lidar com as mudanças de tab
  const handleTabClick = (parametro: string) => {
    // Atualiza o estado
    setParametroDaURL(parametro)

    // Atualiza a URL usando a API de histórico do navegador
    window.history.pushState(null, '', `#${parametro}`)
  }

  function CloseModalPrint() {
    setShowCreate(false)
    setIdModal(null)
  }

  function OpenModalPrint(id) {
    setShowCreate(true)
    setIdModal(id)
  }

  if (!data) {
    return (
      <>
        <AsideDefault />
        <HeaderWrapperFluid />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <div id='kt_content_container' className='container-fluid'>
                <div className='container-fluid p-0'>
                  <div
                    className='d-flex align-items-center flex-lg-row flex-sm-row flex-md-row flex-column rounded'
                    id='topo'
                  >
                    <Loader text={'Carregando..'}></Loader>
                  </div>
                </div>
                <div id='kt_content' className='row'></div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  let tags = []
  if (data) {
    if (data.GetImovel.tag) {
      tags = data.GetImovel.tag.split(',')
    }
  }
  function explorerOpen(id) {
    FormSetLogAcessoRecurso({
      ferramenta: 'explorer',
      funcionalidade: 'inicio',
      action: 'list',
      url: `/explorer?ids=${encodeUrl(id)}`,
    })
    history.push('/explorer?ids=' + encodeUrl(id))
    // history.go(0)
  }
  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />
      {showCreate ? (
        <ImprimirModal id={data.GetImovel.id} closeModal={CloseModalPrint} show={showCreate} />
      ) : (
        <></>
      )}
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <div
                className='py-6  d-flex align-items-center justify-content-between'
                id='kt_header_container'
              >
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  data-kt-swapper='false'
                  data-kt-swapper-mode='prepend'
                  data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Informações do Imovel</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href='/' className='text-muted'>
                        Home
                      </a>
                    </li>
                    <li className='breadcrumb-item text-muted'>Dashboards</li>
                    <li className='breadcrumb-item text-dark'>
                      <Link to='/meus-imoveis'>Imóveis</Link>
                    </li>
                  </ul>
                </div>
                <div className='d-flex align-items-center ms-3'>
                  <Link
                    to='/meus-imoveis'
                    onClick={() =>
                      FormSetLogAcessoRecurso({
                        ferramenta: 'meus-imoveis',
                        funcionalidade: 'listagem',
                        action: 'list',
                        url: `/meus-imoveis`,
                      })
                    }
                    className='btn btn-secondary me-10'
                  >
                    Voltar
                  </Link>
                  <button
                    className='btn btn-info me-5'
                    onClick={() => {
                      explorerOpen(id)
                    }}
                  >
                    <BiCompass className='fs-2'></BiCompass> Explorer
                  </button>
                  <button
                    className='btn btn-primary'
                    onClick={(e) => {
                      e.preventDefault()
                      OpenModalPrint(null)
                    }}
                  >
                    Imprimir
                  </button>
                </div>
              </div>

              <div className='container-fluid p-0'>
                <div
                  className='d-flex align-items-center flex-lg-row flex-sm-row flex-md-row flex-column rounded'
                  id='topo'
                >
                  <div className='col-lg-9 col-md-9 col-sm-6'>
                    <HeaderImovel
                      id={id}
                      nome={data ? data.GetImovel.nome + ', ' + data.GetImovel.numero : ''}
                    ></HeaderImovel>
                  </div>
                  <div className='col-lg-3 col-md-3 col-sm-6 mt-4'>
                    <h3 className='text-white'>Tags:</h3>
                    {tags.map(function (tag, y) {
                      return (
                        <span className='badge text-bg-info me-2 mb-2' key={y}>
                          {tag}
                        </span>
                      )
                    })}

                    {/* <span className='badge text-bg-info me-2 mb-2'>Testex</span>
                    <span className='badge text-bg-info me-2 mb-2'>Controle</span> */}
                  </div>
                </div>
              </div>
              <div id='kt_content' className='row'>
                <div className='col-lg-3 col-md-3 col-xs-12 xs-mt-15 mb-4'>
                  <div className='card mt-10 rounded' style={{minWidth: 250}}>
                    <h3 className='py-5 px-5'>Informações</h3>

                    <ul
                      className='nav nav-pills d-flex flex-column flex-column-fluid '
                      role='tablist'
                      id='menu-interno'
                    >
                      <li className='nav-item' role='presentation'></li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${
                            parametroDaURL === 'informacoes-lote' ? 'active' : ''
                          }`}
                          onClick={() => {
                            loadTabFichasImovel()
                            handleTabClick('informacoes-lote')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'meus-imoveis',
                              funcionalidade: 'informacoes-lote',
                              action: 'list',
                              url: `/meus-imoveis/details/${id}#informacoes-lote`,
                            })
                          }}
                          id='informacoes-lote'
                          data-bs-toggle='pill'
                          data-bs-target='#informacoes-lote'
                          type='button'
                          role='tab'
                          aria-controls='informacoes-lote'
                          aria-selected='false'
                        >
                          <BiFile className='fs-2'> </BiFile>

                          <span>Informações do Lote</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${
                            parametroDaURL === 'contribuintes' ? 'active' : ''
                          }`}
                          onClick={() => {
                            handleTabClick('contribuintes')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'meus-imoveis',
                              funcionalidade: 'contribuintes',
                              action: 'list',
                              url: `/meus-imoveis/details/${id}#contribuintes`,
                            })
                          }}
                          id='contribuintes'
                          data-bs-toggle='pill'
                          data-bs-target='#contribuintes'
                          type='button'
                          role='tab'
                          aria-controls='contribuintes'
                          aria-selected='false'
                        >
                          <BiBuilding className='fs-2'> </BiBuilding>
                          <span>Contribuintes</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${
                            parametroDaURL === 'ficha-territorial' ? 'active' : ''
                          }`}
                          onClick={() => {
                            loadTabFichasImovel()
                            handleTabClick('ficha-territorial')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'meus-imoveis',
                              funcionalidade: 'ficha-territorial',
                              action: 'list',
                              url: `/meus-imoveis/details/${id}#ficha-territorial`,
                            })
                          }}
                          id='ficha-territorial'
                          data-bs-toggle='pill'
                          data-bs-target='#ficha-territorial'
                          type='button'
                          role='tab'
                          aria-controls='ficha-territorial'
                          aria-selected='false'
                        >
                          <BiFile className='fs-2'></BiFile>
                          <span>Ficha Territorial</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${parametroDaURL === 'observacoes' ? 'active' : ''}`}
                          onClick={() => {
                            loadTabFichasImovel()
                            handleTabClick('observacoes')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'meus-imoveis',
                              funcionalidade: 'observacoes',
                              action: 'list',
                              url: `/meus-imoveis/details/${id}#observacoes`,
                            })
                          }}
                          id='observacoes'
                          data-bs-toggle='pill'
                          data-bs-target='#observacoes'
                          type='button'
                          role='tab'
                          aria-controls='observacoes'
                          aria-selected='false'
                        >
                          <BiChat className='fs-2'></BiChat>

                          <span>Observações</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${parametroDaURL === 'fotos' ? 'active' : ''}`}
                          onClick={() => {
                            loadTabFichasImovel()
                            handleTabClick('fotos')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'meus-imoveis',
                              funcionalidade: 'fotos',
                              action: 'list',
                              url: `/meus-imoveis/details/${id}#fotos`,
                            })
                          }}
                          data-bs-toggle='pill'
                          data-bs-target='#fotos'
                          type='button'
                          role='tab'
                          aria-controls='fotos'
                          aria-selected='false'
                        >
                          <BiImages className='fs-2'></BiImages>

                          <span>Fotos </span>
                        </button>
                      </li>

                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${
                            parametroDaURL === 'painel-mercado' ? 'active' : ''
                          }`}
                          onClick={() => {
                            loadTabFichasImovel()
                            handleTabClick('painel-mercado')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'meus-imoveis',
                              funcionalidade: 'painel-mercado',
                              action: 'list',
                              url: `/meus-imoveis/details/${id}#painel-mercado`,
                            })
                          }}
                          id='painel-mercado'
                          data-bs-toggle='pill'
                          data-bs-target='#painel-mercado'
                          type='button'
                          role='tab'
                          aria-controls='painel-mercado'
                          aria-selected='false'
                        >
                          <BiCart className='fs-2'></BiCart>
                          <span>Painel de Mercado</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${
                            parametroDaURL === 'condicao-comercial' ? 'active' : ''
                          }`}
                          onClick={() => {
                            loadTabFichasImovel()
                            handleTabClick('condicao-comercial')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'meus-imoveis',
                              funcionalidade: 'condicao-comercial',
                              action: 'list',
                              url: `/meus-imoveis/details/${id}#condicao-comercial`,
                            })
                          }}
                          id='condicao-comercial'
                          data-bs-toggle='pill'
                          data-bs-target='#condicao-comercial'
                          type='button'
                          role='tab'
                          aria-controls='condicao-comercial'
                          aria-selected='false'
                        >
                          <BiMoney className='fs-2'></BiMoney>
                          <span>Condição Comercial</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${parametroDaURL === 'corretores' ? 'active' : ''}`}
                          onClick={() => {
                            loadTabFichasImovel()
                            handleTabClick('corretores')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'meus-imoveis',
                              funcionalidade: 'corretores',
                              action: 'list',
                              url: `/meus-imoveis/details/${id}#corretores`,
                            })
                          }}
                          id='corretores'
                          data-bs-toggle='pill'
                          data-bs-target='#corretores'
                          type='button'
                          role='tab'
                          aria-controls='corretores'
                          aria-selected='false'
                        >
                          <BiIdCard className='fs-2'></BiIdCard>

                          <span>Corretores</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${parametroDaURL === 'avaliacoes' ? 'active' : ''}`}
                          onClick={() => {
                            loadTabFichasImovel()
                            handleTabClick('avaliacoes')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'meus-imoveis',
                              funcionalidade: 'avaliacoes',
                              action: 'list',
                              url: `/meus-imoveis/details/${id}#avaliacoes`,
                            })
                          }}
                          id='avaliacoes'
                          data-bs-toggle='pill'
                          data-bs-target='#avaliacoes'
                          type='button'
                          role='tab'
                          aria-controls='avaliacoes'
                          aria-selected='false'
                        >
                          <BiPurchaseTagAlt className='fs-2'></BiPurchaseTagAlt>
                          <span>Avaliações</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${
                            parametroDaURL === 'proprietarios' ? 'active' : ''
                          }`}
                          onClick={() => {
                            loadTabFichasImovel()
                            handleTabClick('proprietarios')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'meus-imoveis',
                              funcionalidade: 'proprietarios',
                              action: 'list',
                              url: `/meus-imoveis/details/${id}#proprietarios`,
                            })
                          }}
                          id='proprietarios'
                          data-bs-toggle='pill'
                          data-bs-target='#proprietarios'
                          type='button'
                          role='tab'
                          aria-controls='proprietarios'
                          aria-selected='false'
                        >
                          <BiGroup className='fs-2'></BiGroup>
                          <span>Proprietários</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link disabled ${
                            parametroDaURL === 'atividades' ? 'active' : ''
                          }`}
                          onClick={() => {
                            loadTabFichasImovel()
                            handleTabClick('atividades')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'meus-imoveis',
                              funcionalidade: 'atividades',
                              action: 'list',
                              url: `/meus-imoveis/details/${id}#atividades`,
                            })
                          }}
                          id='atividades'
                          data-bs-toggle='pill'
                          data-bs-target='#atividades'
                          type='button'
                          role='tab'
                          aria-controls='atividades'
                          aria-selected='false'
                        >
                          <BiListCheck className='fs-2'></BiListCheck>
                          <span>Atividades (Em breve)</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link disabled ${
                            parametroDaURL === 'documentos' ? 'active' : ''
                          }`}
                          onClick={() => {
                            loadTabFichasImovel()
                            handleTabClick('documentos')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'meus-imoveis',
                              funcionalidade: 'documentos',
                              action: 'list',
                              url: `/meus-imoveis/details/${id}#documentos`,
                            })
                          }}
                          id='documentos'
                          data-bs-toggle='pill'
                          data-bs-target='#documentos'
                          type='button'
                          role='tab'
                          aria-controls='documentos'
                          aria-selected='false'
                        >
                          <BiFile className='fs-2'></BiFile>
                          <span>Documentos (Em breve)</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-9 col-md-9 col-xs-12'>
                  <div className='card mb-6  mt-10 '>
                    {!data ? (
                      <Loader text={'caregando...'}></Loader>
                    ) : (
                      <TabsImovelContainer
                        parameterUrl={parametroDaURL}
                        data={data}
                        id={data.GetImovel.id}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
