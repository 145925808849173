import {Radius} from '../../../Config'

type Props = {
  id: any
}
export const TypeScaterPlot: React.FC<Props> = ({id}) => {
  return (
    <>
      {/* <FillColor uuid={id} defaultValue={undefined}></FillColor> */}
      {/* <StrokeColor uuid={id}></StrokeColor>
      <StrokeWidth uuid={id}></StrokeWidth> */}
       <Radius uuid={id} ></Radius>
    </>
  )
}
