import {useState} from 'react'
import {SliderSingle} from '../../../../../components/UI/Slider'
import {Explorer} from '../Explorer'
import {Layout} from '../Layout'
import * as turf from '@turf/turf'
import {Loader} from '../../../../../components/UI/Loader/Loader'
import {LoaderButton} from '../../../../../components/UI/Loader/LoaderButton'

type PropsContextMenu = {
  latLng: google.maps.LatLng | null
  defaultValue: number
  addMarker: boolean
}

export const Raio: React.FC<PropsContextMenu> = ({latLng, defaultValue, addMarker = false}) => {
  const [raio, setRaio] = useState(defaultValue)
  const [showLoader, setShowLoader] = useState(false)
  const onClickAddRaio = () => {
    const lng = latLng.lng()
    const lat = latLng.lat()
    if (addMarker) {
      Explorer.addMarker(lat, lng, 'Centro Raio ', 'Local')
    }

    const point = turf.point([lng, lat])
    // const options =  {units:'meters'}
    // var options = {steps: 10, units: 'kilometers', properties: {foo: 'bar'}};
    const geom = turf.circle(point, raio, {units: 'meters'})
    const totalLayer = Explorer.countQtdeLayer('LayerPolygon')
    Explorer.addGeom(geom, `Raio de ${raio}m - ${totalLayer + 1}`)

    // Explorer.addRadius(lat, lng, raio, `Raio de  ${raio}`)
    Layout.setShowGeometryMenu(false)
    setShowLoader(false)
  }
  function onChange(value: any) {
    setRaio(value)
  }

  let marks = {}
  marks[0] = '0 metros'
  marks[2000] = '2000 metros'
  // for (var i = 1; i <= 4; i++) {
  //   let valor = i * 500
  //   marks[valor] = i * 500 + 'm'
  // }

  return (
    <div className='modal fade' id='modal-raio' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='modal-raui-toogle-label'>
              Raio - Distância Linar
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <p>Distância linear a partir de um ponto metro que delimita uma área de influência.</p>

            <div className='row'>
              <label className='col-2  d-flex align-items-center'>Distância em metros:</label>
              <div className='col-10'><SliderSingle
                defaultValue={defaultValue}
                min={0}
                max={2000}
                step={50}
                onChange={onChange}
                tipFormatter={(val) => `${val} m`}
                marks={marks}
              ></SliderSingle>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              className='btn btn-primary'
              onClick={(e) => {
                setShowLoader(true)
                setTimeout(() => {
                  onClickAddRaio()
                }, 100)
              }}
            >
              {showLoader ? <LoaderButton text={'Gerando...'}></LoaderButton> : 'Adicionar'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
