import {Buttons} from './ButtonsCadastrodeGrupos'
import {QueryGetGruposServicos} from '../../../../graphql/services/GruposServicos'
import {Loader} from '../../../../components/UI/Loader/Loader'
import {BiPlus} from 'react-icons/bi'
import {EditForm} from '../Modal/EditForm'
import {CreateForm} from '../Modal/CreateForm'
import {useState} from 'react'
import {CardTables} from '../../../../components/UI/CardTables'
import PaginationComponent from '../../../../components/UI/PaginationComponents'
import LoadingTable from '../../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../../components/UI/CardTables/ErrorTable'

export function TableCadastroGrupos() {
  const [pagiancao, setPaginacao] = useState({pagina: 0, quantidade: 10})

  const {data, loading, error} = QueryGetGruposServicos({
    variables: {pagination: {pagina: null, quantidade: null}},
  })
  const [showCreate, setShowCreate] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [id, setId] = useState(null)

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }
  function CloseModalEdit() {
    setShowEdit(false)
    setId(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setId(id)
  }

  return (
    <>
      <CardTables
        headerTitle={'Grupos de Serviços'}
        headerSubtTitle={`Listando ${
          data ? data.GetUsuarioGrupoServicosByUserId.pageInfo.totalItems : 0
        } grupos registrados.`}
        headerToolbar={
          <button
            className='btn btn-sm btn-light-primary fw-bolder'
            onClick={(e) => {
              e.preventDefault()
              OpenModalCreate(null)
            }}
          >
            <BiPlus className='fs-3'></BiPlus> Adicionar Grupo
          </button>
        }
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetUsuarioGrupoServicosByUserId.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({pagina: pagina, quantidade: quantidade})
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-150px'>Nome</th>
                <th className='min-w-100px text-end'>Ações</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetUsuarioGrupoServicosByUserId.result.length > 0 ? (
                  data.GetUsuarioGrupoServicosByUserId.result.map((response) => {
                    return (
                      <tr key={response.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {response.name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <Buttons setIdEdit={OpenModalEdit} id={response.id} />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>

      {showEdit ? <EditForm id={id} show={showEdit} closeModal={CloseModalEdit}></EditForm> : <></>}
      {showCreate ? (
        <CreateForm closeModal={CloseModalCreate} show={showCreate}></CreateForm>
      ) : (
        <></>
      )}
    </>
  )
}
