import Swal from 'sweetalert2'
import { Loader } from '../../../../components/UI/Loader/Loader'
import { QueryGetImovelObservacoes } from '../../../../graphql/services'
import { BiPlus } from 'react-icons/bi'
import { useState } from 'react'
import { CreateFormImovelProprietarios } from './Proprietarios/Meus-Imoveis-Proprietarios'
import { Row } from './Proprietarios/Row'
import { DateTimToView } from '../../../../components/util'
import { Buttons } from './Observacoes/Buttons'
import { EditForm } from './Observacoes/EditForm'
import { CreateForm } from './Observacoes/CreateForm'

type Props = {
  idImovel: number
}
export const MeusImoveisObservacoes: React.FC<Props> = ({ idImovel }) => {

  const [id, setId] = useState(null)
  const [showCreate, setShowCreate] = useState(false)
  const [showEdit, setShowEdit] = useState(false)

  // console.log(updateList)

  const { data } = QueryGetImovelObservacoes({
    variables: {
      imovelObservacao: {
        id_terreno: idImovel,
      },
      pagination: {
        quantidade: null,
        pagina: null,
      },
    }
  })

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }
  function CloseModalEdit() {
    setShowEdit(false)
    setId(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setId(id)
  }



  if (!data) {
    return (
      <>
        <div className='card-header'>
          <h3 className='card-title'>Observações</h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Clique para adicionar uma Observação'
          >
            <button
              className='btn btnSuccess btn-round'
              onClick={(e) => {
                e.preventDefault()
                OpenModalCreate(null)
              }}
              style={{ color: 'white' }}
            >
              {/*begin::Svg Icon | path: icons/duotune/general/gen005.svg*/}
              <span className='svg-icon btn-icon svg-icon-4 ms-2'>
                +<span className='btn-label pd-left-1'>Adicionar Observação</span>
              </span>
              {/*end::Svg Icon*/}
            </button>
          </div>
        </div>
        <div className='table-responsive'>
          <table className='table table-striped table-row-dashed  gs-6 gy-4 table-row-bordered  table-hover table-row-gray-300 align-middle'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Titulo</th>
                <th className='min-w-120px'>Data de Criação</th>
                <th className=''>Descrição</th>
                <th className='min-w-100px text-end'>Ações</th>
              </tr>
            </thead>
            <tbody>
              <Loader text={'carregando..'}></Loader>
            </tbody>
          </table>
        </div>
      </>
    )
  }

  return (
    <div className='card-body p-0 py-3'>
      <div className='table-responsive'>
        <div className='card-header'>
          <h3 className='card-title'>Observações do Imovel</h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Clique para adicionar uma Observação'
          >
            <button
              className='btn btnSuccess btn-round'
              onClick={(e) => {
                e.preventDefault()
                OpenModalCreate(null)
              }}
              style={{ color: 'white' }}
            >
              {/*begin::Svg Icon | path: icons/duotune/general/gen005.svg*/}
              <span className='svg-icon btn-icon svg-icon-4 ms-2'>
                +<span className='btn-label pd-left-1'>Adicionar Observação</span>
              </span>
              {/*end::Svg Icon*/}
            </button>
          </div>
        </div>
        <table className='table table-striped table-row-dashed  gs-6 gy-4 table-row-bordered  table-hover table-row-gray-300 align-middle'>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-50px'>Titulo</th>
              <th className='min-w-50px'>Data de Criação</th>
              <th className='min-w-150px'>Descrição</th>
              <th className='min-w-50px text-end'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data.GetImovelsObservacao.result.map((Obs) => {
              return (
                <tr key={Obs.id}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <p className='text-dark fs-6'>{Obs.titulo}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className='text-dark  d-block fs-6'>
                      {DateTimToView(Obs.data_cadastro)}
                    </p>
                  </td>
                  <td>
                    <p className='text-dark  d-block fs-6'>{Obs.descricao}</p>
                  </td>
                  <td>
                    <div className='text-dark fs-6'>
                      <Buttons setIdEdit={OpenModalEdit} id={Obs.id} />
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {showEdit ? <EditForm id={id} idTerreno={idImovel} show={showEdit} closeModal={CloseModalEdit}></EditForm> : <></>}
      {showCreate ? <CreateForm idTerreno={idImovel} closeModal={CloseModalCreate} show={showCreate}></CreateForm> : <></>}
    </div>
  )
}
