import {useState} from 'react'
import {formatAdressSeachGoogleMapa} from '../../common'
import {Explorer} from '../Explorer'
import {Layout} from '../Layout'
import {Autocomplete} from '@react-google-maps/api'

const BuscaAutocomplete = () => {
  const [autocomplete, setAutocomplete] = useState(null)

  const onLoadAutocomplete = (autocomplete) => {
    setAutocomplete(autocomplete)
  }

  function resetLayout() {
    Layout.setShowContextMenu(false)
    Layout.setShowGeometryMenu(false)
    Layout.setShowTerritorioMenu(false)
  }

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace()

      if (typeof place.geometry !== 'undefined') {
        const enderecoFormatado = formatAdressSeachGoogleMapa(autocomplete.getPlace())
        let totalIcon = Explorer.countQtdeLayer('LayerIcon')
        Explorer.addMarker(
          enderecoFormatado.latitude,
          enderecoFormatado.longitude,
          `Local ${totalIcon}`,
          `Local ${totalIcon}`,
          enderecoFormatado
        )
        // let mapTemp = Explorer.map;
        if (Object.entries(Explorer.deckgl).length > 0) {
          ;(Explorer.deckgl['_map'] as any).panTo(
            new google.maps.LatLng(enderecoFormatado.latitude, enderecoFormatado.longitude)
          )
        }
        const latlng = new google.maps.LatLng(
          enderecoFormatado.latitude,
          enderecoFormatado.longitude
        )
        Layout.setShowTerritorioMenu(true, latlng)
      }
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  return (
    <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChanged}>
      <input
        type='text'
        placeholder='Buscar Endereço'
        className='form-control'
        style={
          {
            // boxSizing: `border-box`,
            // border: `1px solid #d0d0d0`,
            // padding: `10px 19px`,
            // margin: '10px 13px',
            // position: 'absolute',
            //width: '33%',
            // zIndex: 99,
            // borderRadius: `5px`,
            // boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3) !important`,
            // fontSize: `17px`,
            // textOverflow: `ellipses`,
          }
        }
        onClick={resetLayout}
      />
    </Autocomplete>
  )
}

export default BuscaAutocomplete
