import React from 'react'
type Props = {
  title: string
  src: string
  info: string
  id: string,
  onClick?: React.MouseEventHandler;
}

export const CardMapBase: React.FC<Props> = ({title, src, info, id,onClick }) => {
  return (
    <div className='' data-id={id}>
      <img src={src} alt='BannerCard' className='img-responsive' onClick={(e)=>{ onClick(e) }} id={id} style={{height:'80px'}} />
      <div className='map-name'>{title}</div>
    </div>
  )
}
