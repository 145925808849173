import { useEffect, useState } from 'react'
import { Autocomplete } from '@react-google-maps/api'
import { formatAdressSeachGoogleMapa } from '../../../pages/explorer/components/common'

export function AutocompleteGoogle({ onChangeAddress,endereco }) {
  const [showMap, setShowMap] = useState(false)
  const [enderecoBusca, setEnderecoBusca] = useState(null)
  
  useEffect(() => {
    setEnderecoBusca(endereco)
  },[endereco])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMap(true)
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  })
  const [searchResult, setSearchResult] = useState(null)

  function onLoadAutocomplete(autocomplete) {
    setSearchResult(autocomplete)
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const enderecoFormatado = formatAdressSeachGoogleMapa(searchResult.getPlace())
      onChangeAddress(enderecoFormatado)
    }
  }

  return (
    <>
      {showMap ? (
        <>
          <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChanged}>
            <input
              type='text'
              placeholder='Buscar Endereço'
              className='form-control form-control-solid'
              value={enderecoBusca}
              onChange={(e)=>{setEnderecoBusca(e.target.value)} }
            />
          </Autocomplete>
        </>
      ) : (
        ''
      )}
    </>
  )
}
