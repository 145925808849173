import { useMutation, useQuery } from "@apollo/client"
import { GET_IMOVEL_CORRETORES } from "../query/imovel-corretores"
import { TypesDeleteImovelCorretorResponse, TypesGetImovelCorretoresResponse, TypesSetImovelCorretorByIDResponse, TypesSetImovelCorretorResponse } from "../type/imovel-corretores"
import { useForm } from "react-hook-form"
import { setImovelCorretorByIDFormData, setImovelCorretorByIDFormSchema, setImovelCorretorFormData, setImovelCorretorFormSchema } from "../formValidations/ImovelCorretor"
import { zodResolver } from "@hookform/resolvers/zod"
import { DELETE_IMOVEL_CORRETOR, SET_IMOVEL_CORRETOR, SET_IMOVEL_CORRETOR_BY_ID } from "../mutations/imovel-corretor"
import { GET_CORRETORES, GET_CORRETOR_BY_ID, GET_EMPREENDIMENTO_CORRETORES } from "../query"
import { errorHandler } from "../errorHandler"
import Swal from "sweetalert2"

interface Props {
  variables: any
}

export function QueryGetImovelCorretores({ variables }: Props) {
  const { data, loading, error } = useQuery<TypesGetImovelCorretoresResponse>(GET_IMOVEL_CORRETORES, {
    variables: { ...variables },
  })

  return { data, loading, error }
}


export function MutationSetImovelCorretores() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<setImovelCorretorFormData>({
    resolver: zodResolver(setImovelCorretorFormSchema),
  })


  const [setCorretorBody, { error, loading, data: DataSetImovelCorretor }] =
    useMutation<TypesSetImovelCorretorResponse>(SET_IMOVEL_CORRETOR, {
      refetchQueries: [GET_IMOVEL_CORRETORES, GET_EMPREENDIMENTO_CORRETORES, GET_CORRETORES],
    })

  async function FormImovelCorretor(data: setImovelCorretorFormData) {
    await setCorretorBody({
      variables: {
        data: { ...data }
      },
      refetchQueries: [GET_IMOVEL_CORRETORES, GET_EMPREENDIMENTO_CORRETORES, GET_CORRETORES],
    }).then((result) => {
      if (!result.data) {
        let erroText = errorHandler(result.errors)
        Swal.fire('Erro!', erroText, 'warning')
      } else {
        Swal.fire('Sucesso!', 'Corretor criado com sucesso!', 'success')

      }
    })
      .catch((err) => {
        Swal.fire('Erro!', 'Ocorreu um erro inesperado, tente novamente mais tarde.', 'warning')
      })

  }

  return { register, handleSubmit, setValue, FormImovelCorretor, loading, errors, error, DataSetImovelCorretor }
}

export function MutationSetImovelCorretoresByID() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<setImovelCorretorByIDFormData>({
    resolver: zodResolver(setImovelCorretorByIDFormSchema),
  })


  const [setCorretorBody, { error, loading, data: DataSetImovelCorretorByID }] =
    useMutation<TypesSetImovelCorretorByIDResponse>(SET_IMOVEL_CORRETOR_BY_ID, {
      refetchQueries: [GET_IMOVEL_CORRETORES, GET_EMPREENDIMENTO_CORRETORES, GET_CORRETORES],
    })

  async function FormImovelCorretorByID(data: setImovelCorretorByIDFormData) {
    await setCorretorBody({
      variables: {
        corretor: {
          nome: data.nome,
          email: data.email,
          telefone: data.telefone,
          empresa: data.empresa
        },
        idImovel: data.idImovel
      },
      refetchQueries: [GET_IMOVEL_CORRETORES, GET_EMPREENDIMENTO_CORRETORES, GET_CORRETORES],
    },
    ).then((result) => {
      if (!result.data) {
        let erroText = errorHandler(result.errors)
        Swal.fire('Erro!', erroText, 'warning')
      } else {
        Swal.fire('Sucesso!', 'Corretor associado com sucesso!', 'success')

      }
    })
      .catch((err) => {
        Swal.fire('Erro!', 'Ocorreu um erro inesperado, tente novamente mais tarde.', 'warning')
      })
  }

  return { register, handleSubmit, setValue, FormImovelCorretorByID, loading, errors, error, DataSetImovelCorretorByID }
}


export function MutationDeleteImovelCorretores() {
  const [deleteCorretor, { loading, data, error }] = useMutation<TypesDeleteImovelCorretorResponse>(
    DELETE_IMOVEL_CORRETOR,
    {
      refetchQueries: [GET_IMOVEL_CORRETORES, GET_CORRETORES, GET_CORRETOR_BY_ID],
    }
  )

  async function HandleDeleteImovelCorretores({ variables }: Props) {
    try {
      const result = await deleteCorretor({
        variables: { ...variables }
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return { HandleDeleteImovelCorretores, loading, error, data }
}
