// import { useQuery } from "@apollo/client";
import React from 'react'
import { MutationDeleteUsuarioProduto, QueryGetUsuarioProdutoByID } from '../../../graphql'
import { Loader } from '../../../../components/UI/Loader/Loader'
import { KTSVG } from '../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { DateTimToView, DifferenceDate } from '../../../../components/util'
import { ModalPutAssociationProduto } from './PutAssociationProduto'


export function TableAdminUsuarioProduto({ idUser }) {

    const { data
    } = QueryGetUsuarioProdutoByID({
        variables: { idUser: parseInt(idUser) }
    })
    console.log(data)


    const { HandleDeleteUsuarioProduto, loading } = MutationDeleteUsuarioProduto()
    if (loading) {
        Swal.fire(
            'Enviando Informações...', '')
        Swal.showLoading()
    }

    function confirmDelete(id: number) {
        Swal.fire({
            title: 'Tem certeza que deseja excluir?',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Excluir',
            showCloseButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {

                const result = await HandleDeleteUsuarioProduto({ variables: { deleteUsuarioProdutosId: id } })
                if (!result) {
                    Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
                } else {
                    Swal.fire('Sucesso!', 'Produto desassociado com sucesso.', 'success')
                }
            }
        })
    }

    if (!data) {

        return (
            <>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                        <tr className='fw-bolder text-muted'>
                            <th className='min-w-50px'>ID</th>
                            <th className='min-w-100px'> Nome</th>
                            <th className='min-w-50px '>Data de Ínicio - Fim</th>
                            <th className='min-w-100px text-end'>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={12} className='text-center all-center'>
                                <Loader text={'caregando...'}></Loader>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }

    return (
        <div className='table-responsive card-xxl-stretch mb-5 mb-xl-8'>
            <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                    <tr className='fw-bolder text-muted'>
                        <th className='min-w-50px'>ID</th>
                        <th className='min-w-100px'> Nome</th>
                        <th className='min-w-50px '>Data de Ínicio - Fim</th>
                        <th className='min-w-100px text-end'>Ações</th>
                    </tr>
                </thead>
                {/* end::Table head */}

                <tbody>
                    {data.GetUsuarioProduto.user_produto.length !== 0 ? (
                        data.GetUsuarioProduto.user_produto.map((response, item) => {
                            let diasRestantes  = DifferenceDate(response.data_fim)

                            return (
                                <tr key={item}>
                                    <td>
                                        <p className='text-dark fs-6'>{response.id}</p>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                                <a href='#vazio' className='text-dark fs-6'>
                                                    {response.produto.nome}
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p className='text-dark fs-6'>{DateTimToView(response.data_inicio)} - {DateTimToView(response.data_fim)}</p>
                                        <span>
                                            {diasRestantes}
                                        </span>
                                    </td>
                                    <td className='all-end'>
                                        <ModalPutAssociationProduto id_user={response.id_user} id_association={response.id} id_produto={response.id_produto} />
                                        <button
                                            onClick={() => {
                                                confirmDelete(response.id)
                                            }}
                                            className='btn btn-icon btn-light-danger me-1' >
                                            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    ) : (
                        <tr key={0}>
                            <td colSpan={12} className='text-center'>
                                <p className='text-dark fs-6'>Nenhum Produto cadastrado</p>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>

    )
}
