import { AsideDefault } from '../../../../_metronic/layout/components/aside/AsideDefault'
import { HeaderWrapperFluid } from '../../../../_metronic/layout/components/header/HeaderWrapperFluid'
import { Footer } from '../../../../_metronic/layout/components/Footer'
import { useEffect, useState } from 'react'
import { TableAdminEmpreendimentos } from './partials/TableAdminEmpreendimentos'
import { KTSVG } from '../../../../_metronic/helpers'
import { Form, FormLabel } from 'react-bootstrap'
import { GetEstadoCidadeChosen } from '../../../components/UI/Chosens/GetEstadoCidadeChosen'
import { RealEstateLaunchPartners } from '../../../../services/http/urbit-gis/routes/types'
import { getLaunchesPartners } from '../../../../services/http/urbit-gis/routes'
import { PageInfo } from '../../../components/UI/PaginationComponents'

export function AdminEmpreendimentos() {
  const [toogleFilter, setToogleFilter] = useState(true)
  const [descart, setDescart] = useState(false)

  const [filter, setFilter] = useState([])
  const [estado, setEstado] = useState(null)
  const [cidade, setCidade] = useState(null)

  function addFilter(e) {
    e.preventDefault()
    const data = new FormData(e.target)
    const filterArray = []

    // Nome/Endereço
    if (data.get('nome')) {
      filterArray.push({
        field: ['name'],
        operator: 'LIKE',
        value: [data.get('nome')],
      })
    }

    // Construtoras
    if (data.get('construtora')) {
      filterArray.push({
        field: ['id_developer'],
        operator: '=',
        value: [data.get('construtora')],
      })
    }

    // Finalidade
    if (data.get('finalidade')) {
      filterArray.push({
        field: ['finality'], // Supondo que o campo no backend seja 'purpose'
        operator: 'LIKE',
        value: [data.get('finalidade')],
      })
    }

    if (data.get('id_estado')) {
      filterArray.push({
        field: ['id_state'],
        operator: '=',
        value: [data.get('id_estado')],
      });
    } 

    // Cidade
    if (data.get('id_cidade')) {
      filterArray.push({
        field: ['id_city'],
        operator: '=',
        value: [data.get('id_cidade')],
      })
    }

    setFilter([...filterArray])
    return false
  }

  function toggleTeste() {
    if (toogleFilter === false) {
      setToogleFilter(true)
    } else if (toogleFilter === true) {
      setToogleFilter(false)
    }
  }

  const [dataPartners, setDataPartners] = useState<RealEstateLaunchPartners[]>([])
  const [paginacao, setPaginacao] = useState({ pagina: 1, quantidade: 100 })
  const [pageInfo, setPageInfo] = useState<PageInfo>()

  useEffect(() => {
    async function fetchLaunches() {
      try {
        const launches = await getLaunchesPartners(paginacao.pagina, paginacao.quantidade)
        console.log('Lançamentos:', launches)

        // Ordenar os lançamentos pelo campo created_at em ordem decrescente
        const sortedLaunches = launches.data.sort((a, b) => {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        })

        setDataPartners(sortedLaunches)
        setPageInfo(launches.pagination)
      } catch (error) {
        console.error('Erro ao buscar partners:', error)
      }
    }
    fetchLaunches()
  }, [paginacao])

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid title='Lançamentos' />
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <div
                className='py-6  d-flex align-items-center justify-content-between'
                id='kt_header_container'
              >
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  data-kt-swapper='true'
                  data-kt-swapper-mode='prepend'
                  data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Listagem</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href='/metronic8/demo7/../demo7/index.html' className='text-muted'>
                        Empreendimentos
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='d-flex align-items-center ms-3'>
                  <button
                    className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={toggleTeste}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filtros
                  </button>
                </div>
              </div>

              <div className='content  d-flex flex-column flex-column-fluid '>
                <div className={toogleFilter === true ? 'card show mb-10' : ' card hide mb-10'}>
                  <div className='border-0 pt-6 ps-5 pe-5'>
                    <div className='row gy-5 gx-xl-8'>
                      <div className='col-xl-12'>
                        <div className='card-xxl-stretch '>
                          <div className='card-form px-12 py-12 mb-6'>
                            <Form className='row form form-horizontal' onSubmit={addFilter}>
                              <div className='row'>
                                <div className='col-md-4 mb-2'>
                                  <FormLabel className='form-label'>Nome/Endereço</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Digite o nome ou endereço'
                                    name='nome'
                                  />
                                </div>

                                <div className='col-md-4 mb-2'>
                                  <FormLabel className='form-label'>Construtoras</FormLabel>
                                  <select
                                    className='form-select form-select-solid'
                                    name='construtora'
                                  >
                                    <option value=''>Selecione</option>
                                    {dataPartners.map((response) => (
                                      <option key={response.id} value={response.id}>
                                        {response.nome}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className='col-md-4 mb-2'>
                                  <FormLabel className='form-label'>Finalidade</FormLabel>
                                  <select name='finalidade' id='' className='form-select form-select-solid'>
                                    <option value=''>Selecione</option>
                                    <option value=''>Residêncial</option>
                                    <option value=''>Não Residêncial</option>
                                  </select>
                                </div>
                              </div>

                              <div className='row  mt-3'>
                                <GetEstadoCidadeChosen
                                  defaultValueCidade={null}
                                  defaultValueEstado={null}
                                  classNameCity={'col-4 col-xs-12 mt-1'}
                                  classNameEstado={'col-4 col-xs-12  mt-1'}
                                  SetValueEstado={(value) => {
                                    setEstado(value)
                                  }}
                                  SetValueCidade={(value) => {
                                    setCidade(value)
                                  }}
                                />

                                <div className='col-4 col-xs-12 all-end'>
                                  <button type='submit' className='btn btn-primary'>
                                    Buscar
                                  </button>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                <div className='col-xl-12'>
                  <TableAdminEmpreendimentos filtro={filter} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
