import { gql } from "@apollo/client";

export const GET_SERVICO_CATEGORIA = gql`
  query GetServicoCategoria($idMunicipio: Float) {
    GetServicoCategoria(idMunicipio: $idMunicipio) {
      nome
      id
      servico {
        id
        nome
        exibe_planilha
        exibe_filtro_incorporadora
        session_name
        modo_visualizacao
        observacoes
        nacional
        descricao
        url_api
        distancia
        icone
        display_config
      }
      
    }
  }
`


