import { gql } from "@apollo/client";

export const GET_USUARIO_PRODUTOS = gql`
query GetUsuarioProdutos {
  GetUsuarioProdutos {
    id
    id_produto
    id_user
    uuid
    produto {
      nome
      ordem
      id
      produto_id
      tipo_produto
      valor
      loja_id
      descricao
      desconto
    }
    
  }
}
`
