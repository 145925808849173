import {Dicionario} from '../../../utils/Dicionario'

export const RelatedPeopleEmails = ({data, estrutura}) => {
  let dateReturn = null

  if (typeof data['Result'] !== 'undefined') {
    if (data['Result'].length > 0) {
      dateReturn = data['Result'][0]['RelatedPeopleEmails']
    }
  } else {
    if (data.length > 0) {
      if (typeof data[0]['RelatedPeopleEmails'] !== 'undefined'){
        dateReturn = data[0]['RelatedPeopleEmails']
      }
    }
  }
  if (!dateReturn) {
    return <></>
  }

  return (
    <>
      {/* <h3 className="pt-10">{estrutura.titulo}</h3> */}

      <table className='table fs-7'>
        <thead>
          <tr>
            <th className='fs-bold px-0'> Email</th>
            <th className='fs-bold px-0'> Tipo Relacionamento</th>
          </tr>
        </thead>
        <tbody>
          {dateReturn?.map((item, index) => {
            return (
              <tr key={index}>
                {estrutura['colunsItens'].map((column, index2) => {
                  return (
                    <td key={index2} className='px-0'>
                      {Dicionario.translate(item[column])}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
