import { z } from "zod"

export const NewsFeedFormCreateSchema = z.object({
    title: z.string()
        .nonempty('O código é obrigatório!'),
    description: z.string()
        .nonempty('A descrição é obrigatório!'),
    start_date: z.string().optional(),
    end_date: z.string().optional()
});

export type NewsFeedFormCreateData = z.infer<typeof NewsFeedFormCreateSchema>


export const NewsFeedFormPutSchema = z.object({
    id: z.number(),
    title: z.string()
        .nonempty('O código é obrigatório!'),
    description: z.string()
        .nonempty('A descrição é obrigatório!'),
    start_date: z.string().optional(),
    end_date: z.string().optional()
})

export type NewsFeedFormPutData = z.infer<typeof NewsFeedFormPutSchema>