import {BiLinkExternal} from 'react-icons/bi'
import {CurrencyFormat, DateTimToView} from '../../../../../../../components/util'
import {Property} from '../../../../../../lancamento/partials/types'
import { formatNumber } from '../../../../common'

type Props = {
  result: Property
}

export const Resumo: React.FC<Props> = ({result}) => {
  // const adressString = result[0].address
  // const adressArray = adressString.split('-')
  // const InfosLoteSplited = adressArray.map((adress) => {
  //   return adress.trim()
  // })

  return (
    <div className={`card `}>
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Detalhes</span>
          <span className='text-muted fw-semibold fs-7'>Informações do Empreendimento</span>
        </h3>
      </div>
      <div className='card-body d-flex flex-column'>
        <div className='row'>
          <h5 className='ps-0'>Preço coletado em {result[0].preco_lancamento_data} </h5>
          <div className='col-6  p-0'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='d-flex align-items-center'>
                <div className='fs-2 fw-bolder'>
                  {result ? formatNumber(result[0].preco_lancamento_m2, 'currency') : '0'}
                </div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>Por m2</div>
            </div>
          </div>
          <div className='col-6 p-0'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='d-flex align-items-center'>
                <div className='fs-2 fw-bolder'>
                  {' '}
                  {result ? formatNumber(result[0].preco_lancamento, 'currency') : '0'}
                </div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>A partir</div>
            </div>
          </div>
        </div>

        <div className='row pt-4'>
          <h5 className='ps-0'>Preço do estoque atual</h5>

          <div className='col-6  p-0'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='d-flex align-items-center'>
                <div className='fs-2 fw-bolder'>
                  {result ?  formatNumber(result[0].price_m2, 'currency') : '0'}
                </div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>Por m2</div>
            </div>
          </div>
          <div className='col-6 p-0'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='d-flex align-items-center'>
                <div className='fs-2 fw-bolder'>
                  {' '}
                  {result ?  formatNumber(result[0].price, 'currency') : '0'}
                </div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>A partir</div>
            </div>
          </div>
        </div>
        <div className='row mt-5 mb-7'>
          <label className='col-lg-6 fw-bold text-muted'>Nome: </label>

          <div className='col-lg-6'>
            <span className='fw-bolder fs-6 text-dark'> {result ? result[0].name : '-'}</span>
          </div>
        </div>
        <div className='row  mb-7'>
          <label className='col-lg-6 fw-bold text-muted'>Situação: </label>

          <div className='col-lg-6'>
            <span className='badge badge-success ms-0'>{result ? result[0].situacao : '-'}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-6 fw-bold text-muted'>Data Lançamento: </label>

          <div className='col-lg-6'>
            <span className='fw-bolder fs-6 text-dark'>
              {' '}
              {result ? DateTimToView(result[0].launch_date) : '-'}
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-6 fw-bold text-muted'>Data Ultima Atualização Órulo: </label>

          <div className='col-lg-6'>
            {/* <span className='fw-bolder fs-6 text-dark'> 29/06/1984</span>
              <span> */}
            <span className='badge badge-info ms-0'>
              {' '}
              {result ? DateTimToView(result[0].date_last_price) : 'Não informado'}
            </span>

            {/* </span> */}
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-6 fw-bold text-muted'>Incorporadora</label>

          <div className='col-lg-6 fv-row'>
            <span className='fw-bold fs-6'>
              {' '}
              {result ? result[0].incorporadora : 'Não informado'}
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-6 fw-bold text-muted'>Total Unidades</label>

          <div className='col-lg-6 fv-row'>
            <span className='fw-bold fs-6'>
              {' '}
              {result ? result[0].total_units : 'Não informado'}
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-6 fw-bold text-muted'>Unidades vendidas</label>

          <div className='col-lg-6 fv-row'>
            <span className='fw-bold fs-6'>
              {' '}
              {result ? result[0].total_units - result[0].stock : 'Não informado'}
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-6 fw-bold text-muted'>Estoque</label>

          <div className='col-lg-6 fv-row'>
            <span className='fw-bold fs-6'>
              {' '}
              {result
                ? result[0].total_units - (result[0].total_units - result[0].stock)
                : 'Não informado'}
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-6 fw-bold text-muted'>% Vendas</label>

          <div className='col-lg-6 fv-row'>
            <span className='fw-bold fs-6'>
              {result
                ? (
                    ((result[0].total_units - result[0].stock) / result[0].total_units) *
                    100
                  ).toFixed(0) + '%'
                : 'Não informado'}
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-6 fw-bold text-muted'>Site</label>
          <div className='col-lg-6 fv-row'>
            <span className='fw-bold fs-6'>
              <a href={result[0].webpage} target='_blank' rel='noreferrer'>
                <BiLinkExternal></BiLinkExternal> Abrir site externo
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
