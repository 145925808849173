import { useParams } from 'react-router-dom'
import { QueryGetCondicaoComercial } from '../../../../../graphql/services/CondicaoComercial'
import { useState } from 'react'
import { CreateForm } from './partials/CreateForm'
import { Buttons } from './partials/Buttons'
import { EditForm } from './partials/EditForm'
import { Loader } from '../../../../../components/UI/Loader/Loader'
import { CurrencyFormat } from '../../../../../components/util'


type Props = {
  param: string
}

export function TabCondicaoComercial({ param }: Props) {
  const { id: idTerreno } = useParams<{ id: string }>()
  const { data } = QueryGetCondicaoComercial({
    variables: {
      idImovel: parseInt(idTerreno),
      pagination: {
        pagina: null,
        quantidade: null,
      },
    },
  })

  const [showCreate, setShowCreate] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [id, setId] = useState(null)

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }
  function CloseModalEdit() {
    setShowEdit(false)
    setId(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setId(id)
  }

  // Tratativa de loading
  if (!data) {
    return (
      <div
        className={`tab-pane fade ${param === 'condicao-comercial' ? 'show active' : ''}`}
        id='condicao-comercial'
        role='tabpanel'
        aria-labelledby='condicao-comercial'
      >
        <div className='card'>
          <div className='card-header'>
            <h3 className='card-title'>Condição Comercial</h3>
            <div className='card-toolbar'>
              <button
                className='btn btnSuccess btn-round'
                onClick={(e) => {
                  e.preventDefault()
                  OpenModalCreate(null)
                }}
                style={{ color: 'white' }}
              >
                {/*begin::Svg Icon | path: icons/duotune/general/gen005.svg*/}
                <span className='svg-icon btn-icon svg-icon-4 ms-2'>
                  +<span className='btn-label pd-left-1'>Adicionar Condição Comercial</span>
                </span>
                {/*end::Svg Icon*/}
              </button>
            </div>
          </div>
          <div className='card-body p-0 pt-5'>
            <div className='row '>
              <div className='col-12'>
                <div className='row'>
                  {/* begin::Table container */}
                  <div className='table-responsive'>
                    <Loader text={'caregando...'}></Loader>
                  </div>
                  {/* end::Table container */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  return (
    <div
    className={`tab-pane fade ${param === 'condicao-comercial' ? 'show active' : ''}`}
    id='condicao-comercial'
    role='tabpanel'
    aria-labelledby='condicao-comercial'
  >
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Condição Comercial</h3>
          <div className='card-toolbar'>
            <button
              className='btn btnSuccess btn-round'
              onClick={(e) => {
                e.preventDefault()
                OpenModalCreate(null)
              }}
              style={{ color: 'white' }}
            >
              {/*begin::Svg Icon | path: icons/duotune/general/gen005.svg*/}
              <span className='svg-icon btn-icon svg-icon-4 ms-2'>
                +<span className='btn-label pd-left-1'>Adicionar Condição Comercial</span>
              </span>
              {/*end::Svg Icon*/}
            </button>
          </div>
        </div>
        <div className='card-body p-0 pt-5'>
          <div className='row '>
            <div className='col-12'>
              <div className='row'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}

                  <table className='table table-striped gs-7'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-50px'>Dinheiro</th>
                        <th className='min-w-50px'>Permuta Física</th>
                        <th className='min-w-140px'>Permuta Financeira</th>
                        <th className='min-w-140px'>Valor Total</th>
                        <th className='min-w-140px'>Observações</th>
                        <th className='min-w-100px text-end'>Ações</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {data.GetImovelCondicaoComercialByTerreno.result.map((Response) => {
                        return (
                          <tr key={Response.id}>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <p className='text-dark  fs-6'>{!Response.dinheiro_valor ? 'Não informado' : CurrencyFormat(Response.dinheiro_valor)}</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className='text-dark  d-block fs-6'>{!Response.permuta_fisica_pecentual ? 'Não informado' : CurrencyFormat(Response.permuta_fisica_pecentual)}</p>
                            </td>
                            <td>
                              <p className='text-dark  d-block fs-6'>
                                {!Response.valor_total ? 'Não informado' : CurrencyFormat(Response.permuta_financeira_percentual)}
                              </p>
                            </td>
                            <td>
                              <p className='text-dark  d-block fs-6'>{!Response.valor_total ? 'Não informado' : CurrencyFormat(Response.valor_total)}</p>
                            </td>
                            <td>
                              <p className='text-dark  d-block fs-6'>{Response.observacoes}</p>
                            </td>
                            <td>
                              <Buttons idTerreno={idTerreno} setIdEdit={OpenModalEdit} id={Response.id} />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
              {showEdit ? (
                <EditForm
                  idTerreno={idTerreno}
                  id={id}
                  show={showEdit}
                  closeModal={CloseModalEdit}
                ></EditForm>
              ) : (
                <></>
              )}
              {showCreate ? (
                <CreateForm
                  idTerreno={idTerreno}
                  closeModal={CloseModalCreate}
                  show={showCreate}
                ></CreateForm>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
