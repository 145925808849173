import { Chart1 } from "./Chart1"
import { Chart2 } from "./Chart2"
import { Chart3 } from "./Chart3"
import { Chart4 } from "./Chart4"

type Props = {
    id: string
}

export function Estatisticas({id}: Props) {
  return (
    <div className='kt_content_container  d-flex flex-column flex-column-fluid'>
      <div className='card card-custom'>
        <div className='card-header all-start'>
          <h1 className='fts-2 pt-5'>Estatísticas</h1>
        </div>
        
        <div className='card-body'>
        <div className='row'>
                      <div className='col-6'>
                        <Chart1 id={id}></Chart1>
                      </div>
                      <div className='col-6'>
                        <Chart2 id={id}></Chart2>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <Chart3 id={id}></Chart3>
                      </div>
                      <div className='col-6'>
                        <Chart4 id={id}></Chart4>
                      </div>
                    </div>
            
        </div>
      </div>
    </div>
  )
}
