// import {isArray} from '@apollo/client/cache/inmemory/helpers'
import React from 'react'

// import Tooltip from '../../../../components/UI/Tooltip'
// import OverlayPopover from '../../../../components/UI/Popover'
// import {GET_SERVICO_CATEGORIA} from '../../../../graphql/query/servico-categoria'
// import {GetServicoCategoriaResponse} from '../../../../graphql/type/servicoCategoria'

// import {GET_IMOVEL_FICHAS} from '../../../../graphql/query/imovel-fichas'
// import {GetImovelFichasResponse} from '../../../../graphql/type/imovel-fichas'
// import {useQuery} from '@apollo/client'
import {FaInfoCircle, FaRegFileAlt } from 'react-icons/fa'
import OverlayPopover from '../../../../../components/UI/Popover'
import { Dicionario } from '../../../../../utils/Dicionario'
import { BiPin } from 'react-icons/bi'
type Props = {
  item: any
  keyItem: string
  text: string

}


function unicodeToChar(text) {
  return text.replace(/\\u[\dA-F]{4}/gi, function (match) {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
  })
}
type Props1Item = {
  item: any[]
  text: string
}
export const RenderListElement: React.FC<Props1Item> = ({item,text}) => {
  return (
    <div className='mb-0 alert alert-dismissible bg-light-grey d-flex flex-column  p-0  border-bottom-1 border-bottom-dashed'>
      <div className='p-0  flex-column'>
        <div className=' list-group-flush'>
          {item.map(function (key, index) {
                     return (
              <div
                key={`item_` + index + '_' + key}
                className='row bg-light-secondary  mb-5'
              >
                <div className='col-md-12'>
                  {
                    Object.keys(key).length > 1
                      ? // eslint-disable-next-line array-callback-return
                        Object.keys(key).map(function (list, indexList) {
                          if (list !== 'geom' && list !== 'gid' && list !=='geom_closest_point') {
                            return <p key={indexList} ><FaRegFileAlt color='#d0d0d0' /> {list}: {isNaN(key[list]) ? unicodeToChar(key[list]) : key[list]}</p>
                          }
                        })
                      : ''
                  }
                </div>
                
                <div className="separator my-5"></div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export const RenderItem1Element: React.FC<Props1Item> = ({item,text}) => {
  return (
    
      <div className='mb-0 alert alert-dismissible bg-light-grey d-flex flex-column  p-0   border-bottom-1 border-bottom-dashed'>
        <div className="row">
        {/* <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            className='bi bi-info-circle'
            viewBox='0 0 16 16'
          >
            <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
            <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z' />
          </svg>
        </span> */}
        <div className='col-md-12'>
            <div className='d-flex flex-column pe-0 pe-sm-10'>
            {/* <h4 className='fw-semibold'>This is an alert</h4> */}

            <span>
                
                {item ? 
                // eslint-disable-next-line array-callback-return
                Object.keys(item[0]).map(function (key, index) {
                if (key !== 'geom' && key !== 'distancia'  && key !=='geom_closest_point') {
                    return (
                    <p className='' key={key+'_'+index}>
                       <FaRegFileAlt color='#d0d0d0' />{Dicionario.translate(key)} : {isNaN(item[0][key]) ? unicodeToChar(item[0][key]) : item[0][key]}
                    </p>
                    )
                }
                }) : ''}
            </span>
            <br />
            {item[0]['distancia'] ? (
          <button
            type='button'
            className=' btn btn-icon '
          >
          
            <span className='svg-icon svg-icon-1 svg-icon-primary'>
            <BiPin className="fs-1"></BiPin>
              {item[0]['distancia']} metros
            </span>
          </button>
        ) : (
          ''
        )}
            </div>
        </div>
      </div>
    </div>
  )
}

export const RenderItem: React.FC<Props> = ({item, keyItem, text}) => {
  return (
    <>
      {
      Array.isArray(item) && item.length === 0 ? 
        <div className=" pt-5 " style={{"display":"inline-block"}} >
            <OverlayPopover key={keyItem+'overlayPopover'} keyChield={keyItem+'overlayPopover'}  title={text} content={"Durante a sincronização não foram encontradas informações sobre este serviços, ele pode não existir."} >
                <div >
                    {/* <span  className="pb-5 m-0"><FaInfoCircle color='#d0d0d0' /></span> */}
                    <span className="m-1">Nada Consta</span>
                </div>
            </OverlayPopover>
            {/* <buttom  data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top" > */}
          </div> :  Array.isArray(item) && item.length === 1 ? (
        <RenderItem1Element item={item} text={text}></RenderItem1Element>
      ) : Array.isArray(item) && item.length > 1 ? (
        <RenderListElement item={item} text={text}></RenderListElement>
      ) : (
        ''
      ) }
    </>

    // //   {Array.isArray(item) && item.length > 0 ? (
    // //     item.map(function (key, index) {
    // //       if (key !== 'geom') {
    // //         return (
    // //           <li
    // //             key={`item_` + index + '_' + keyItem}
    // //             className=' list-group-item bg-light-secondary'
    // //           >
    // //             <p></p>
    // //             {/* {key} :{item[key]}{' '} */}
    // //           </li>
    // //         )
    // //       }
    // //     })
    //   ) : (
    //     <div>{item}</div>
    //   )}
  )
}

// function RenderFicha({chave, dados}) {
//   const {data} = useQuery<GetImovelFichasResponse>(GET_IMOVEL_FICHAS, {
//     variables: {
//       getImovelFichaId: id_imovel,
//     },
//   })
//   let resultFicha = null
//   if (!data) {
//     return (
//       <div>
//         <p>Nenhuma ficha</p>
//       </div>
//     )
//   } else {
//     resultFicha = data.GetImovelFicha['terreno_fichas'][0]['json_params']['output']

//   }

//   if (!Array.isArray(dados.result) || dados.result.length === 0) {
//     return ''
//   } else {
//     return (
//       <>
//         {resultFicha
//           ? Object.keys(resultFicha).map(function (key, index) {
//               var chave = key
//               return (
//                 <div className='accordion accordion-icon-toggle' id='kt_accordion_1'>
//                   <div className='accordion-item'>
//                     <h2 className='accordion-header' id={'kt_accordion_1_header_1' + chave}>
//                       <button
//                         className='accordion-button fs-4 fw-semibold'
//                         type='button'
//                         data-bs-toggle='collapse'
//                         data-bs-target={'#kt_accordion_1_body_1' + chave}
//                         aria-expanded='true'
//                         aria-controls={'#kt_accordion_1_body_1' + chave}
//                       >
//                         {chave}
//                       </button>
//                     </h2>
//                     <div
//                       id={'kt_accordion_1_body_1' + chave}
//                       className='accordion-collapse collapse '
//                       aria-labelledby={'kt_accordion_1_header_1' + chave}
//                       data-bs-parent={'#kt_accordion_1' + chave}
//                     >
//                       <div className='accordion-body'>
//                         {dados.result.map(function (result) {
//                           return (

//                           )
//                         //   <RenderItem item={result}></RenderItem>
//                         })}
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 // <RenderFicha chave={key} dados={resultFicha[key]}></RenderFicha>)
//               )
//             })
//           : ''}
//       </>
//     )
//   }
// }

// export {RenderItem}
