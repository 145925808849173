import {Php3aData} from './Php3aData'

export const InfoDadosPessoas = ({response, typeDoc}) => {
  return (
    <>
      {response.ph3a_data || response.basic_data ? (
        <Php3aData
          resultPeapleData={response.ph3a_data}
          response={response}
          typeDoc={typeDoc}
        ></Php3aData>
      ) : (
        ''
      )}
      {/* {response.basic_data ? (
        <BasicData
          data={response.basic_data}
          estrutura={dadosTabelas[typeDoc]['BasicData']}
        ></BasicData>
      ) : (
        ''
      )} */}
    </>
  )
}
