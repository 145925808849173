import React from 'react'
import Swal from 'sweetalert2'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { MutationDeleteCondicaoComercial } from '../../../../../../graphql/services/CondicaoComercial'
import { MutationSetLogAcessoRecurso } from '../../../../../../graphql/services/LogAcesso'

type Props = {
  id: number
  idTerreno: string
  setIdEdit?: any
}

export const Buttons: React.FC<Props> = ({ id, setIdEdit, idTerreno }) => {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()
  const { HandleDeleteCondicaoComercial, loading } = MutationDeleteCondicaoComercial()
  if (loading) {
    // Swal.fire(
    //   'Enviando Informações...','')
    // Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {

        // console.log({
        //   id: id,
        //   id_imovel: parseInt(idTerreno)
        // })

        const result = await HandleDeleteCondicaoComercial({
          variables: {
            deleteImovelCondicaoComercialId: id,
            idImovel: parseInt(idTerreno)
          }
        }
        )
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'condicao-comercial', action: 'delete', url: '/meus-imoveis/details/' + idTerreno + '#condicao-comercial' });
          Swal.fire('Sucesso!', 'Condição comercial deletada com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          className='btn btn-icon btn-light-success btn-sm me-2'
          onClick={(e) => {
            setIdEdit(id)
          }}
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button>
        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          // disabled={loading}
          className='btn btn-icon btn-light-danger  btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
