import React, { useEffect, useState } from 'react';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { QueryGetExplorerTags, TypesGetExplorerLayerByIDResponse } from '../../../graphql';

type Tag = {
  value: number;
  label: string;
};

type TagWithId = {
  id_tag: number;
} & Tag;

type Props = {
  setValueTags: React.Dispatch<React.SetStateAction<TagWithId[]>>;
  valueTags: TagWithId[];
  defaultValue?: TypesGetExplorerLayerByIDResponse;
};

export function MultiSelectTags({
  setValueTags,
  valueTags,
  defaultValue
}: Props) {
  const animatedComponents = makeAnimated();

  const { data, loading } = QueryGetExplorerTags();
  const [tags, setTags] = useState<TagWithId[]>([]);

  useEffect(() => {
    if (defaultValue && !loading) {
     
      if (defaultValue){
      // eslint-disable-next-line array-callback-return
      const defaultTags = defaultValue.GetExplorerLayerById.explorer_layer_tags.map(( explorer_tags ) => {
          if (explorer_tags.explorer_tags){
            return {
              id_tag: explorer_tags.explorer_tags.id,
              value: explorer_tags.explorer_tags.id,
              label: explorer_tags.explorer_tags.tag,
            }
          }
        } 
      );
      setTags(defaultTags);
      setValueTags(defaultTags);
    }
    }
  }, [loading, defaultValue, setValueTags]);

  let array: Tag[] = [];
  if (data) {
    array = data.GetExplorerTags.map((Options) => ({
      value: Options.id,
      label: Options.tag,
    }));
  }

  return (
    <>
      <div className='form-group'>
        <label className='col-form-label'>Tags:</label>
        <Select
          isDisabled={loading}
          isMulti
          value={tags}
          components={animatedComponents}
          options={array}
          onChange={(selectedTags: TagWithId[]) => {
            setTags(selectedTags);
            setValueTags(selectedTags);
          }}
          name='id_tag'
          placeholder='Opções Disponíveis'
        />
      </div>
    </>
  );
}
