import React from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { ModalPutTermos } from './ModalPutTermos'
import Swal from 'sweetalert2'
import { MutationDeleteTermo } from '../../../graphql/services/Termos'

type Props = {
  id: number
}

export const ButtonsAction: React.FC<Props> = ({ id }) => {
  const idDelete = id

  const {HandleDeleteTermos, loading} = MutationDeleteTermo();

  if (loading) {
    Swal.fire(
      'Enviando Informações...','')
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {

        const result = await HandleDeleteTermos({ variables: { deleteTermoId: id } })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Termo deletado com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <ModalPutTermos id={id} />
        <button
          onClick={() => {
            confirmDelete(idDelete)
          }}
          disabled={loading}
          className='btn btn-icon btn-light-danger  btn-sm me-1' >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
