import React, {useState} from 'react'
import {QueryGetImoveis} from '../../../../../../graphql/services'
import {Loader} from '../../../../../../components/UI/Loader/Loader'
import {Form, FormLabel} from 'react-bootstrap'
import {GetSituacaoClienteChosen} from '../../../../../meus-imoveis/components/Chosen/GetSituacaoClienteChosen'
import GetTerrenoTagsCreatableSelect from '../../../../../meus-imoveis/components/Chosen/GetImovelTagsCreatableSelect'
import './boxIcons.css'

export const TableMeusImoveis = ({addMeusLocais}) => {
  const [descart, setDescart] = useState(false)
  const [filter, setFilter] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pages, setPages] = useState(0)
  const [tags, setTags] = useState('')
  let result = 0

  if (descart === true) {
    result = 0
  } else if (descart === false) {
    result = 1
  }

  const {data} = QueryGetImoveis({
    variables: {
      pagination: {pagina: pages, quantidade: 100},
      filtro: {
        fields: filter,
      },
      situation: result,
    },
  })
  // const handleChecked = (e) => {
  //   setDescart(e.target.checked)
  // }

  function addFilter(e) {
    e.preventDefault()
    const data = new FormData(e.target)
    const filterArray = []

    if (data.get('nome')) {
      filterArray.push({
        field: ['nome', 'endereco'],
        operator: 'LIKE',
        value: data.get('nome'),
      })
    }

    if (tags) {
      filterArray.push({
        field: ['tag'],
        operator: 'LIKE',
        value: tags.split(','),
      })
    }

    // if (data.getAll('tags').length > 0 && data.getAll('tags')[0] !== '') {
    //   filterArray.push({
    //     field: ['tag'],
    //     operator: 'LIKE',
    //     value: data.getAll('tags'),
    //   })
    // }
    if (data.get('zoneamento')) {
      filterArray.push({
        field: ['zoneamento'],
        operator: 'LIKE',
        value: data.get('zoneamento'),
      })
    }

    if (data.get('status-cliente')) {
      filterArray.push({
        field: ['status_cliente'],
        operator: 'and',
        value: data.get('status-cliente'),
      })
    }
    if (e.target['descartados'].value) {
      filterArray.push({
        field: ['id_situacao'],
        operator: '=',
        value: ['3'],
      })
    }

    setFilter([...filterArray])
    return false
    // const value = e.target.value

    // filterArray.push({
    //   "field": ["nome","endere=-o"],
    //   "operator": "LIKE",
    //   "value": value
    // })
  }
  function selectAll(event) {
    if (event.target.checked) {
      document.getElementsByName('check').forEach((el) => {
        el['checked'] = true
      })
    } else {
      document.getElementsByName('check').forEach((el) => {
        el['checked'] = false
      })
    }
  }
  function adicionarSelecionados() {
    let selecionados = []
    document.getElementsByName('check').forEach((el) => {
      // el.checked = true
      if (el['checked']) {
        selecionados.push(Number(el['value']))
      }
    })
    let selecionadosData = []
    // eslint-disable-next-line array-callback-return
    data.GetImoveis.result.map((el) => {
      if (selecionados.indexOf(el.id) !== -1) {
        let item = {
          name: el.nome,
          address: el.endereco,
          coordinates: [el.longitude, el.latitude],
          properties: {
            nome: el.nome,
            endereco: el.endereco,
            numero: el.numero,
            operacao_urbana: el.operacao_urbana,
            zoneamento: el.zoneamento,
          },
        }

        selecionadosData.push(item)
      }
    })

    addMeusLocais(selecionadosData)
  }
  if (!data) {
    return <Loader text={'Carregando'}></Loader>
  }
  return (
    <div>
      <div className='card-form px-4 py-12 mb-6'>
        <Form className='row form form-horizontal' onSubmit={addFilter}>
          <div className='row'>
            <div className='col-md-4 mb-2'>
              <FormLabel className='form-label'>Nome/Endereço</FormLabel>
              <input
                type='text'
                className='form-control'
                placeholder='Digite o nome ou Endereço'
                name='nome'
              />
            </div>
            <div className='col-md-4 mb-2'>
              <FormLabel className='form-label'>Situação</FormLabel>
              <GetSituacaoClienteChosen onValueChange={(value) => {}} defaultValue={0} />
            </div>
            <div className='col-md-4 mb-2'>
              <FormLabel className='form-label'>Zoneamento</FormLabel>
              <input type='text' className='form-control' placeholder='ZM' name='zoneamento' />
            </div>
          </div>

          <div className='row mt-3'>
            {/* <div className='col-md-4'>
                          <FormLabel className='form-label'  >Tipo</FormLabel>
                          <GetImoveTiposChosen />
                        </div> */}
            <div className='col-md-4'>
              <FormLabel className='form-label'>Tags</FormLabel>
              <GetTerrenoTagsCreatableSelect
                defaultValue={[]}
                onValueChange={(tags) => {
                  setTags(tags)
                }}
              ></GetTerrenoTagsCreatableSelect>
            </div>

            <div className='col-md-4 d-flex align-items-center'>
              <input
                type='checkbox'
                value='1'
                name='descartados'
                id='descartados'
                className='checkbox mt-8'
              ></input>
              <label className='form-label fw-bold  mt-10 ms-1'> Descartados</label>
            </div>

            {/* <div className='col-2 pt-11'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  checked={descart}
                  onChange={handleChecked}
                  type='checkbox'
                  id='gridCheck'
                  name='situacao'
                />

                <label className='form-check-label'>Exibir Descartados</label>
              </div>
            </div> */}
            <div className='col-2 all-full-end'>
              <button type='submit' className='btn btn-primary'>
                Buscar
              </button>
            </div>
          </div>
        </Form>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <button className='btn btn-light-primary pull-right' onClick={adicionarSelecionados}>
            Adicionar Selecionados
          </button>
          {/* <button  className='btn btn-light-primary'>Adicionar Selecionados</button> */}
        </div>
      </div>
      <div>
        {/* begin::Table container */}
        <div className='table-responsive  '>
          {/* begin::Table */}
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-10px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input widget-9-check'
                      type='checkbox'
                      value='all'
                      onClick={selectAll}
                    />
                  </div>
                </th>
                <th className='min-w-50px'>ID</th>
                <th className='min-w-50px'>Nome</th>
                <th className='min-w-140px'>Endereço</th>
                <th className='min-w-50px'>Numero</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data.GetImoveis.result.map((Response) => {
                return (
                  <tr key={Response.id}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          name='check'
                          value={Response.id}
                        />
                      </div>
                    </td>
                    <td>{Response.id}</td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {Response.nome}
                          </span>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {Response.users.name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>{Response.endereco}</td>
                    {/* <td>{Response.cep}</td> */}
                    <td>{Response.numero}</td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>

          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}
