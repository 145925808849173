import { Buttons } from './Button'
import { TypologyPrice } from '../../../../../../../services/http/urbit-gis/routes/types'
import { DateTimToView, formatNumber } from '../../../../../../components/util'
import { PageInfo } from '../../../../../../components/UI/PaginationComponents'
import { FeaturesHistory } from './features'

interface Props {
  dataTypologyPrices: TypologyPrice[]
  pageInfoTypologyPrices?: PageInfo
  handleRefetch: () => void
  setId: any
  setShowUpdate:any
}

export function TableHistory({ dataTypologyPrices, handleRefetch, setShowUpdate, setId }: Props) {

  return (
    <table className='table table-striped table-row-dashed gy-2 gs-6 table-row-bordered table-hover table-row-gray-300 align-middle gs-0 gy-4'>
      <thead>
        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
          <th className='min-w-100px'>Data</th>
          <th className='min-w-40px text-center'>Preço m2</th>
          <th className='min-w-40px text-center'>Preço</th>
          <th className='min-w-40px text-center'>Estoque</th>
          <th className='min-w-40px text-center'>Vendas</th>
          <th className='min-w-40px all-end'>Ações</th>
        </tr>
      </thead>
      <tbody>
        {dataTypologyPrices && dataTypologyPrices.length > 0 ? (
          dataTypologyPrices.map((response) => (
            <tr key={response.id}>
              <td className='fs-6'>{DateTimToView(response.price_date)}</td>
              <td className='fs-6 text-center'>{formatNumber(response.price_m2)}</td>
              <td className='fs-6 text-center'>{formatNumber(response.price)}</td>
              <td className='fs-6 text-center'>{response.stock}</td>
              <td className='fs-6 text-center'>{response.sales}</td>
              <td className='all-end'>
                <Buttons id={response.id} setShowUpdate={setShowUpdate} setId={setId} handleRefetch={handleRefetch} />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={12} className='text-center'>
              Nenhum registro encontrado
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
