import booleanIntersects from '@turf/boolean-intersects'
import {RGBColor, RGBAColor, HexColor} from '../../Types/layers'
import uuid from 'react-uuid'
import {
  ColorLayer,
  // DEFAULT_COLOR,
  DEFAULT_FILL_COLOR,
  DEFAULT_HIGHLIGHT_COLOR,
  // DEFAULT_TEXT_LABEL,
  Field,
  SCALE_TYPES,
} from '../constants/layers'
import {Explorer} from '../../../Explorer'
import {Layout} from '../../../Layout'
import {Endereco} from '../../../ComponentsControl/EnderecoControl/endereco'
import {APiUrbit} from '../../../../../../../services/api/apiUrbit'
import {Composicao} from '../../../ComponentsControl/ComposicaoControl/composicao'
import {Layer} from '../Layer'
import Swal from 'sweetalert2'
import secureLocalStorage from 'react-secure-storage'
import { CloseLoaderInfo, LoaderInfo } from '../../../../../../../components/UI/Loader/LoaderInfo'
import { MapControll } from '../../../../../../../../store/mapControll'

export type LayerColumn = {value: string | null; fieldIdx: number; optional?: boolean}

export type LayerColumns = {
  [key: string]: LayerColumn
}

export type LayerBaseConfig = {
  id: string | null
  label: string
  color: RGBAColor
  filter: Object
  columns: {} // LayerColumns;
  isVisible: boolean
  isConfigActive: boolean
  highlightColor: RGBColor | RGBAColor
  hidden: boolean
  //  ; textLabel: LayerTextLabel[];

  colorUI: {
    color: RGBAColor // ColorUI;
    colorRange: HexColor[] //ColorUI;
  }
  animation: {
    enabled: boolean
    domain?: null
  }
}

export type DefaultLayerProps = {
  data?: any
  visible: boolean
  tile: string
  style: ColorLayer
  id: string
  label: string
  filter: Object
  distance?: number
  color: RGBAColor
  intersect?: JSON
  intersectId?: string
  operation: string
  composite: []
  selectedPolygon: []
  highlightColor: RGBAColor
  colorField: string
  pickable: boolean
  extruded: false
  tooltip: any
  positionZ: number
  icon: string
  propriedades: any[]
  nome:string
  parentId:string
}
export type VisualChannelDomain = number[] | string[]
export type VisualChannelField = Field | null
export type VisualChannelScale = keyof typeof SCALE_TYPES

export type LayerColorConfig = {
  colorField: VisualChannelField
  colorDomain: VisualChannelDomain
  colorScale: VisualChannelScale
}
export type LayerSizeConfig = {
  // color by size, domain is set by filters, field, scale type
  sizeDomain: VisualChannelDomain
  sizeScale: VisualChannelScale
  sizeField: VisualChannelField
}

export class BaseLayer {
  id: string
  config: DefaultLayerProps
  meta: {}
  constructor(
    props: {
      id?: string
    } & Partial<DefaultLayerProps> = {}
  ) {
    this.id = props.id || uuid()

    // meta
    this.meta = {}

    this.config = this.getDefaultLayerConfig({
      //   columns: this.getLayerColumns(),
      ...props,
    })
  }

  getDefaultLayerConfig(props: Partial<DefaultLayerProps> = {}): DefaultLayerProps {
    return {
      id: props.id || null,
      label: props.label,
      pickable: true,
      color: props.color,
      style: props.style || DEFAULT_FILL_COLOR,
      filter: props.filter,
      tile: props.tile,
      operation: props.operation,
      composite: props.composite,
      selectedPolygon: props.selectedPolygon,
      extruded: false,
      tooltip: props.tooltip,
      //   columns: props.columns || {},
      visible: props.visible || false,
      //   isConfigActive: props.isConfigActive || false,
      highlightColor: props.highlightColor || DEFAULT_HIGHLIGHT_COLOR,
      //   hidden: props.hidden || false,
      positionZ: props.positionZ || 10,
      // TODO: refactor this into separate visual Channel config
      // color by field, domain is set by filters, field, scale type
      colorField: null,
      propriedades: props.propriedades,
      icon:props.icon,
      parentId:props.parentId,
      nome:props.nome
      //   colorDomain: [0, 1],
      //   colorScale: SCALE_TYPES.quantile,

      // color by size, domain is set by filters, field, scale type
      //   sizeDomain: [0, 1],
      //   sizeScale: SCALE_TYPES.linear,
      //   sizeField: null,

      //   visConfig: {},

      //   textLabel: [DEFAULT_TEXT_LABEL],

      //   colorUI: {
      //     color: DEFAULT_COLOR,
      //     colorRange: DEFAULT_COLOR_UI
      //   },
      //   animation: {enabled: false}
    }
  }

  getLayerColumns() {
    return [{value: null, fieldIdx: null}]
    // const columnValidators = this.columnValidators;
    // const required = this.requiredLayerColumns.reduce(
    //   (accu, key) => ({
    //     ...accu,
    //     [key]: columnValidators[key]
    //       ? {value: null, fieldIdx: -1, validator: columnValidators[key]}
    //       : {value: null, fieldIdx: -1}
    //   }),
    //   {}
    // );
    // const optional = this.optionalColumns.reduce(
    //   (accu, key) => ({
    //     ...accu,
    //     [key]: {value: null, fieldIdx: -1, optional: true}
    //   }),
    //   {}
    // );

    // return {...required, ...optional};
  }
  
  clickEvent(info, event) {
    const botao = event.srcEvent.domEvent.button
    // const lat = info.object.geometry.coordinates[1]
    // const lng = info.object.geometry.coordinates[0]
    const lat = info.coordinate[1]
    const lng = info.coordinate[0]

    
    const pickInfo =  MapControll.pickObjects({
      x: info.x,
      y: info.y,
      depth:500
    })

    const layerClick = BaseLayer.validateLayerClick(pickInfo)
    
    if (layerClick.length > 0) {
     
      const lay = layerClick[layerClick.length-1].layer.id
      const infoLayer = Explorer.getInfoLayer(lay)
      const latlng = new google.maps.LatLng(lat, lng)

      switch (botao) {
        
        case 0:
          if (Layout.getComposicaoStatus()) {

            // document.body.style.cursor='wait'; 
            let idLayerComp = secureLocalStorage.getItem('layerComp')
            let layerComposicao = this.getLayerComposicao(idLayerComp,layerClick)
            const municipio = Endereco.getMunicipio()
            const api = new APiUrbit()
            
            LoaderInfo('Carregando...')
            
            api.getServico('lote-fiscal', lat, lng, municipio['municipio_url'], 0, function (result) {
              if (result) {
                if (typeof layerComposicao !== 'undefined'){
                  const lote = {...layerComposicao.object.properties, ...result.itens[0]}
                  lote.latitude  = lat
                  lote.longitude = lng
                  Composicao.add(lote)
                  Composicao.setComposicaoLayer(lay)
                  Swal.close()
                  Explorer.setClickPolygon(idLayerComp, layerComposicao.object.properties.gid)
                }
              }
         
              CloseLoaderInfo()
            
            })

          } else {
            Layout.setShowClickInfo(true, latlng, layerClick)
            Layout.setShowTerritorioMenu(false,latlng)
            Layout.setShowContextMenu(false, latlng)
            if (infoLayer['type'] === 'LayerPolygon' || infoLayer['type'] === 'MVTLayer') {
              Layout.setShowGeometryMenu(false, latlng)
            } else {
              Layout.setShowGeometryMenu(true, latlng)
            }
            Layout.setShowGeometryMenuArea(false, latlng)
          }
          break
        
        case 2:
          Layout.setShowContextMenu(false, latlng)
          if (infoLayer['type'] === 'MVTLayer' ||infoLayer['type'] === 'LayerPolygon' || infoLayer['type'] === 'GeoJSONLayer') {
            const geom = layerClick[0].object.geometry
            let layIntersect = layerClick[0].layer.id
            Layout.setShowGeometryMenu(false, latlng)
            Layout.setShowGeometryMenuArea(true, latlng, geom, layIntersect, pickInfo)
            
          } else {
            Layout.setShowGeometryMenuArea(false, latlng)
            Layout.setShowGeometryMenu(true, latlng, pickInfo)
          }
          break
      }
      //   }
    }
  }

  getLayerComposicao(layerComp:any,layers){

    return layers.filter((item)=>{
      return item.layer.id === layerComp
    })[0]
  }

  static validateLayerClick(clickElements: any) {
    if (clickElements.length === 1) {
      if (clickElements[0].layer.id === 'municipio') {
        Endereco.setEndereco({
          enderecoAtivo: clickElements[0].object.properties.id,
        })
      } 

      return []
    } else {
      // eslint-disable-next-line array-callback-return
      return clickElements.filter(function (el,index) {
        if (el.layer.id === 'municipio') {
          Endereco.setEndereco({
            enderecoAtivo: el.object.properties.id,
          })
        } else {
          return   el 
        }
      })
    }
  }
  clickEventMarker(info, event, action) {
    action()
    // //console.log(info)
    // //console.log(event)
    // //console.log(event.srcEvent.domEvent.button)
    // //console.log('Click No LAYER')
    // if (event.srcEvent.domEvent.button === 0) {
    //   const lng = info.coordinate[0]
    //   const lat = info.coordinate[1]
    //   const latlng = new google.maps.LatLng(lat, lng)
    //   Layout.setShowTerritorioMenu(true, latlng)
    // }

    // if (event.srcEvent.domEvent.button === 2) {
    //   const lng = info.coordinate[0]
    //   const lat = info.coordinate[1]
    //   const latlng = new google.maps.LatLng(lat, lng)
    //   Layout.setShowContextMenu(false, latlng)
    //   Layout.setShowGeometryMenu(true, latlng)
    // }

    // Layout.setShowContextMenu(true,latlng)

    // const botao = event.srcEvent.domEvent.button
    // const lat = info.object.geometry.coordinates[1]
    // const lng = info.object.geometry.coordinates[0]
  }
  project(latLng: google.maps.LatLng) {
    let siny = Math.sin((latLng.lat() * Math.PI) / 180)
    const TILE_SIZE = 128
    // Truncating to 0.9999 effectively limits latitude to 89.189. This is
    // about a third of a tile past the edge of the world tile.
    siny = Math.min(Math.max(siny, -0.9999), 0.9999)

    return new google.maps.Point(
      TILE_SIZE * (0.5 + latLng.lng() / 360),
      TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
    )
  }

  getFilterData(data, filter, type, geom, dataWGS84?): [] {
    // console.log(data)
    if (
      (typeof filter !== 'undefined' && filter !== null && Object.keys(filter).length > 0) ||
      typeof dataWGS84 !== 'undefined'
    ) {
      const filtros = filter
      let items = data

      if (items) {
        items = items.filter((item, pos) => {
          let passouFiltroRange = true
          let passouFiltroSelect = true
          let passouFiltroText = true
          let passouGeom = true
          if (typeof geom !== 'undefined' && geom !== null) {
            let intersect = false
            intersect = booleanIntersects(geom, {
              type: 'Feature',
              geometry: dataWGS84[pos].geometry,
              properties: {},
            })
            if (!intersect) {
              passouGeom = false
            }
          }
          if (typeof filtros['range'] !== 'undefined') {
            if (Object.keys(filtros['range']).length > 0) {
              let chk = this.checkFiltroRange(filtros['range'], item)
              if (!chk) {
                passouFiltroRange = false
              }
            }
          }

          if (
            typeof filtros['select'] !== 'undefined' &&
            Object.keys(filtros['select']).length > 0
          ) {
            let chk = this.checkFiltroSelect(filtros['select'], item)

            if (!chk) {
              passouFiltroSelect = false
            }
          }

          if (typeof filtros['text'] !== 'undefined' && Object.keys(filtros['text']).length > 0) {
            let chk = this.checkFiltroText(filtros['text'], item)
            if (!chk) {
              passouFiltroText = false
            }
          }
          if (passouFiltroSelect && passouFiltroRange && passouFiltroText && passouGeom) {
            return true
          } else {
            return false
          }
        })
      }
      return items
    } else {
      return data
    }
  }
  point2LatLng(point, map) {
    var topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast())
    var bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest())
    var scale = 1 << map.getZoom()

    var worldPoint = new google.maps.Point(
      point.x / scale + bottomLeft.x,
      point.y / scale + topRight.y
    )
    return map.getProjection().fromPointToLatLng(worldPoint)
    // return '';
  }
  checkFiltroRange(filtro: object, item: any): boolean {
    const resultado = Object.keys(filtro).filter(function (data) {
      if (item.properties[data] !== null) {
        if (
          Number(item.properties[data]) >= filtro[data][0] &&
          Number(item.properties[data]) <= filtro[data][1]
        ) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    })
    return resultado.length > 0 && resultado.length === Object.keys(filtro).length ? true : false
  }

  checkFiltroText(filtro: object, item: any) {
    const resultado = Object.keys(filtro).filter(function (data) {
      if (item.properties[data] !== null) {
        if (item.properties[data].toLowerCase().indexOf(filtro[data].toLowerCase()) !== -1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    })
    return resultado.length > 0 && resultado.length === Object.keys(filtro).length ? true : false
  }

  checkFiltroSelect(filtro: object, item: any) {
    const resultado = Object.keys(filtro).filter(function (data) {
      if (item.properties[data] !== null) {
        if (!isNaN(item.properties[data])) {
          if (filtro[data].includes(item.properties[data].toString())) {
            return true
          } else {
            return false
          }
        } else {
          if (filtro[data].includes(item.properties[data])) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    })
    return resultado.length > 0 && resultado.length === Object.keys(filtro).length ? true : false
  }
}
