import { gql } from "@apollo/client";

export const GET_CORRETORES = gql`
query GetCorretores($pagination: Pagination!) {
  GetCorretores(pagination: $pagination) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      id
      nome
      telefone
      empresa
      email
    }
    
  }
}
`

export const GET_CORRETOR_BY_ID = gql`
query GetCorretorById($getCorretorByIdId: Float!) {
  GetCorretorById(id: $getCorretorByIdId) {
    id
    nome
    telefone
    email
    situacao
    empresa
  }
}
`
