import {useContext} from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import {BiArrowBack, BiExport, BiFolderOpen, BiSave} from 'react-icons/bi'
import {FilterContext} from '../context/FilterContext'
import Swal from 'sweetalert2'
import {
  MutationCreateInteligenciaMercado,
  MutationPutInteligenciaMercado,
} from '../../../graphql/services/inteligencia-mercado'
import {MutationSetLogAcessoRecurso} from '../../../graphql/services/LogAcesso'
import {ExportarEstudo} from '../components/Export/ExportarEstudo'
import {Link, useParams} from 'react-router-dom'
import {DataContext} from '../context/DataContext'
import useImoveis from '../hooks/useImoveis'

export default function MenuTopo({nome}: {nome: string}) {
  const {id: idProjetoURL} = useParams<{id: string}>()

  const {endereco, filter, geometria} = useContext(FilterContext)
  const {getNaoComputados} = useContext(DataContext)
  const {setValue, FormInteligenciaMercado} = MutationPutInteligenciaMercado()
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const {getIdTipologiasComputadas} = useImoveis()

  const nComputados = getNaoComputados()

  const dataSave = {
    endereco: endereco,
    filter: filter,
    geometria: geometria,
    imoveisNaoComputados: nComputados,
  }

  function save(idParam: number) {
    Swal.fire({
      title: 'Deseja salvar as alterações?',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
    }).then((result) => {
      if (result.isConfirmed) {
        const id = idParam
        // const nome =  result.value
        let json = dataSave

        setValue('id', id)
        setValue('nome', nome)

        FormInteligenciaMercado({id, json})
          .then((retorno) => {
            // FormSetLogAcessoRecurso({
            //   ferramenta: 'Inteligencia Mercado',
            //   funcionalidade: 'Atualizar Projeto',
            //   action: 'update',
            //   url: `inteligencia-mercado/${id}`,
            // })
            Swal.fire('Sucesso!', 'Projeto atualizado com sucesso.', 'success')
          })
          .catch((error) => {
            Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
          })
      }
    })
  }

  return (
    <div
      className='header align-items-stretch w-100'
      style={{borderBottom: '1px solid #eff2f5', backgroundColor: '#fff'}}
    >
      <Navbar expand='lg' className=' ' style={{width: '94%'}}>
        <Container fluid>
          <Navbar.Brand href='#'>
            <h2>Inteligência de Mercado</h2>
            <small>{nome}</small>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll ' className='justify-content-end'>
            <Nav>
              {/* <Nav.Link > */}

              {/* </Nav.Link> */}
              {/* <Nav.Link
                // href='#action2'
              > */}
              {filter ? (
                filter.segmentacaoArea !== null && filter.segmentacaoPreco !== null ? (
                  <ExportarEstudo id={0}></ExportarEstudo>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}

              {/* </Nav.Link> */}
              {/* <Nav.Link > */}
              <button
                onClick={(e) => {
                  save(parseInt(idProjetoURL))
                }}
                className='btn  btn-primary ms-2'
                style={{width: '100% !important'}}
              >
                <BiFolderOpen /> Salvar Estudo
              </button>
              <Link to='/inteligencia-mercado' className='btn btn-sencondary ms-10'>
                <BiArrowBack></BiArrowBack> Voltar
              </Link>
              {/* </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}
