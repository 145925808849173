/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { QueryGetImoveisRelatorio } from '../../../../graphql/services'
import { Loader } from '../../../../components/UI/Loader/Loader'
import { DecimalFormat } from '../../../../components/util'

type Props = {
  className: string
  title: string
  icon: string
  filter: any[]
}

const InfosRelatorios: React.FC<Props> = ({ className, title, icon, filter }) => {


  
  const {data} = QueryGetImoveisRelatorio({
    variables: {
      "filtro": {
        "fields": filter
      }
    },
  })

  if (!data){
    return (<Loader text={'Carregando...'} ></Loader>)
  }
  
  return (
    <div className='card card-xl-stretch '>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <div className='d-flex all-center'>
          <span className='svg-icon svg-icon-1 d-flex mb-1'>
            <KTSVG
              path={icon}
              className='svg-icon-1 svg-icon-gray-500 me-1'
            />
          </span>
          <h3 className='card-title fw-bolder fs-3 text-dark all-center'>
            {title}
          </h3>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-0'>
        <div className="row">
          <div className="col-6">
            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-success rounded p-5'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-success me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                  Número total de lotes: 
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bold fs-2 text-success py-1'>{DecimalFormat(data.GetImoveisRelatorio.totalImoveis) }</span>
              {/* end::Lable */}
            </div>
            {/* end::Item */}
          </div>
          <div className="col-6">
            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-danger rounded p-5'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-danger me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                <span className='text-muted fw-semibold d-block'>Área total de lotes:</span>
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bold  fs-2 text-danger py-1'>{DecimalFormat(data.GetImoveisRelatorio.areaTotal)} m²</span>
              {/* end::Lable */}
            </div>
            {/* end::Item */}
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export { InfosRelatorios }
