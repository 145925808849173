
import { useParams } from 'react-router-dom'
import '../../global.css'
import styles from './ficha.module.css'
import { FichaContent } from './fichaContent'
import { Footer } from '../../../../_metronic/layout/components/Footer'
import { QueryGetImovel } from '../../../graphql'
import { Loader } from '../../../components/UI/Loader/Loader'

export function Fichas() {
  
  const {uuid} = useParams<{uuid: string}>()

  // const {uuid} = useParams<{uudi: string}>()
  const {data} = QueryGetImovel({variables: {uuid: (uuid)}})
  // Tratativa de loading
  if (!data) {
    return (
      <Loader text={'caregando...'}></Loader>
    )
  }
  return (
    <div className={styles['page-init']}>
      <div className={styles['page-relatorio']} >
        <div className={styles['subpage']}>
          <div className='content d-flex flex-column flex-column-fluid'>
            <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                <FichaContent  data={data} ></FichaContent>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

