import React, {useEffect, useState} from 'react'
import {MenuItemGrupo} from './MenuItemGrupo'
import {Loader} from '../../../../../../components/UI/Loader/Loader'
import {MenuItemGrupoLegal} from './MenuItemGrupoLegal'
import {BtnActions} from './BtnActions'
import {Explorer} from '../../Explorer'
import ReactHtmlParser from 'react-html-parser'
import {formatDistancia} from '../../../common'
// import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
// import { Button, ButtonGroup, Dropdown } from 'react-bootstrap'
type Props = {
  uuid: string
  label: string
  servico: []
  tipoServico: number
  lat: number
  lng: number
  distancia: number
  municipio: string
}

export const Grupo: React.FC<Props> = ({
  uuid,
  label,
  servico,
  tipoServico,
  lat,
  lng,
  distancia,
  municipio,
}) => {
  const [serivcoResult, setServicoResult] = useState({})
  const [activeAll, setActiveAll] = useState(false)
  const [distanciaUser, setDistanciaUser] = useState(distancia)

  useEffect(() => {
    if (distancia) {
      setDistanciaUser(distancia)
    }
    return () => {}
  }, [distancia])

  const loadService = (servico: any, distancia) => {
    const url = servico.url_sistema
      .replace('[LONGITUDE]', lng)
      .replace('[LATITUDE]', lat)
      .replace('[MUNICIPIO]', municipio)
      .replace('[DISTANCIA]', distancia)

    fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZDBhODAxNDYwODVjMjA5Zjg1NTVjNWYiLCJyb2xlIjpbIlVzZXIiLCJBZG1pbiJdLCJpYXQiOjE2ODE0MDk0NjR9.PUObCxMX4TyDPyCesyytKG1wW849Dfnt_h_yPDbrRP8',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        } else {
          return null
        }
      })
      .then((data) => {
        if (!data || typeof data === 'undefined') {
          setServicoResult({service: 'erro', message: data, itens: [], total: 0})
        } else {
          setServicoResult(data)
        }
      })
      .catch((err) => {
        setServicoResult({service: 'error', message: err.message, itens: [], total: 0})
      })
  }

  function chagenDistancia(distancia) {
    setDistanciaUser(distancia)
    loadService(servico, distancia)
  }

  function addGeom(label, event) {
    if (event.target.checked) {
      let selecionadosData = []

      label = label.replace(/\((\w+)\)/gm, '') + ` em ${formatDistancia(distanciaUser)} `
      let id = Explorer.addMarkerGeoJson(selecionadosData, label, label)
      if (serivcoResult) {
        // eslint-disable-next-line array-callback-return
        serivcoResult['itens'].map((item, x) => {
          let templateResult = servico['display_config']
          Object.entries(item).map(function (x) {
            let chave = x[0]
            let valor: any
            valor = x[1]
            templateResult = templateResult.replace('${' + chave + '}', valor)
            return x
          })
          const templateParsed = ReactHtmlParser(templateResult)
          if (typeof item['geom'] !== 'undefined') {
            const geom = JSON.parse(item['geom'])
            const type = geom.type
            let GeoJSON = {
              type: 'Feature',
              geometry: geom,
              properties: {area: 0},
            }
            let layer = ''
            if (type === 'Point') {
              layer = Explorer.addMarker(
                geom.coordinates[1],
                geom.coordinates[0],
                Array.isArray(templateParsed) ? templateParsed[0] : templateResult,
                templateResult,
                null,
                id
              )
            } else if (type === 'MultiLineString') {
              layer = Explorer.addGeom(
                GeoJSON,
                ` ${Array.isArray(templateParsed) ? templateParsed[0] : templateResult}`,
                id,
                {
                  'fill-color': '#004DE5',
                  'line-width': 2,
                  'line-color': '#004DE5',
                }
              )
            } else {
              layer = Explorer.addGeom(
                GeoJSON,
                ` ${Array.isArray(templateParsed) ? templateParsed[0] : templateResult}`,
                id
              )
            }
            event.target.dataset.id = id
          }
        })
        setActiveAll(true)
      }
    } else {
      Explorer.removeLayerByParentId(event.target.dataset.id)
      delete event.target.dataset.id
      setActiveAll(false)
    }
  }

  return (
    <div>
      <h2
        className='accordion-header  bg-light border-radius-15 '
        style={{borderRadius: '15px'}}
        id={'flush-' + uuid}
      >
        <button
          className='accordion-button collapsed bg-light fs-5'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target={'#flush-collapseOne-' + uuid}
          aria-expanded='true'
          aria-controls={'flush-collapseOne-' + uuid}
          onClick={(e) => {
            if (!e.target['classList'].contains('collapsed')) {
              loadService(servico, distancia)
            }
          }}
          style={{borderRadius: '15px'}}
        >
          {label}{' '}
          {distancia ? (
            <div className=' d-flex justify-content-between fs-7 ms-1'>
              {' '}
              em {formatDistancia(distanciaUser)}{' '}
            </div>
          ) : (
            ''
          )}
        </button>
      </h2>
      <div
        id={'flush-collapseOne-' + uuid}
        className='accordion-collapse collapse'
        aria-labelledby={'flush-' + uuid}
        data-bs-parent={'accordionflush-' + uuid}
      >
        <div className='accordion-body p-0'>
          <div className='menu menu-column '>
            {!serivcoResult ? (
              <div>Carregando...</div>
            ) : Object.entries(serivcoResult).length > 0 ? (
              [
                <div className='d-flex justify-content-between pt-5 pb-5'>
                  <span className='d-flex justify-content-start align-items-center '>
                    {serivcoResult['itens'].length > 0 ? (
                      <>
                        <span className='menu-icon  d-flex justify-content-between pe-4 ps-5'>
                          <div className='form-check form-switch form-check-custom form-check-solid'>
                            <input
                              className='form-check-input h-20px w-30px'
                              type='checkbox'
                              value={1}
                              id='flexSwitchDefault'
                              onClick={(e) => {
                                addGeom(label, e)
                              }}
                            />
                          </div>
                        </span>
                        Todos
                      </>
                    ) : (
                      <></>
                    )}
                  </span>

                  <span className='d-flex justify-content-start align-items-center '>
                    Distância:
                    <select
                      className='form-control ms-3'
                      onChange={(e) => chagenDistancia(Number(e.target.value))}
                    >
                      <option value='500'>500m</option>
                      <option value='1000'>1 km</option>
                      <option value='1500'>1,5 km</option>
                      <option value='2000'>2 km</option>
                    </select>
                  </span>
                </div>,
                serivcoResult['itens'].length > 0 ? (
                  <BtnActions key={-1} data={serivcoResult['itens']} nome={label}></BtnActions>
                ) : (
                  ''
                ),
                serivcoResult['itens'].length > 0 ? (
                  // eslint-disable-next-line array-callback-return
                  serivcoResult['itens'].map(function (item, x) {
                    switch (tipoServico) {
                      case 2:
                        return (
                          <MenuItemGrupo
                            key={uuid + '_' + servico['id'] + '_' + x}
                            distancia={distancia}
                            template={servico['display_config']}
                            servico={item}
                            label={label}
                            activeAll={activeAll}
                          ></MenuItemGrupo>
                        )
                      case 5:
                        return (
                          <MenuItemGrupoLegal
                            key={uuid + '_' + servico['id'] + '_' + x}
                            distancia={0}
                            template={servico['display_config']}
                            servico={item}
                          ></MenuItemGrupoLegal>
                        )
                      case 8:
                        return (
                          <MenuItemGrupoLegal
                            key={uuid + '_' + servico['id'] + '_' + x}
                            distancia={0}
                            template={servico['display_config']}
                            servico={item}
                          ></MenuItemGrupoLegal>
                        )
                    }
                  })
                ) : typeof serivcoResult['message'] !== 'undefined' ? (
                  <div>
                    <div className='menu-item my-1'>
                      <div className='menu-link px-4'>
                        <span className='alert alert-danger'>Erro ao carregar o serviço</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className='menu-item my-1'>
                      <div className='menu-link px-4 fs-5'>Nada consta</div>
                    </div>
                  </div>
                ),
              ]
            ) : (
              <div className='menu-item my-1'>
                <div className='menu-link px-4'>
                  <Loader text={''}></Loader>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
