import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormComponent } from './partials/FormComponent';
import { setOpenModalCallback } from '../../../../services/connection/apollo';

export function ModalLogin() {
  useEffect(() => {
    setOpenModalCallback((path) => {
      console.log(path)
      setPath(path)
      setShowModal(true);
    });
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [path, setPath] = useState(null);

  return (
    <Modal centered animation show={showModal}   backdrop="static"
    keyboard={false} size='sm'>
      <Modal.Header className=''>
        <Modal.Title className='h3'>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-lg'>
        <FormComponent modalResult={setShowModal}  path={path} />
      </Modal.Body>
    </Modal>
  );
}
