import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {client} from './services/connection/apollo'
import {AuthProvider} from './modules/auth/components/Context/authcontext'
import {ApolloProvider} from '@apollo/client'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <AuthProvider>
      <ApolloProvider client={client}>
        <BrowserRouter basename={basename}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Routes />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </BrowserRouter>
      </ApolloProvider>
    </AuthProvider>
  )
}

export {App}
