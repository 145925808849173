import React, {useEffect, useState} from 'react'
import Select, {ActionMeta} from 'react-select'

type Props = {
  itensSelected: [string] | null
  onChange?: any
}
export interface Options {
  readonly value: string
  readonly label: string
  readonly color?: string
  readonly isFixed?: boolean
  readonly isDisabled?: boolean
}
export const SelectEstagioObra: React.FC<Props> = ({onChange, itensSelected}) => {
  const options = [
    {label: 'Pronto Novo', value: 'Pronto novo'},
    {label: 'Em Construção', value: 'Em construção'},
    // { label: "Lançamento", value: "Lançamento" },
  ]

  const [selected, setSelected] = useState([])

  useEffect(() => {
    if (selected) {
      let itens = selected.map((item) => {
        return item.value
      })
      onChange(itens)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  useEffect(() => {
    if (itensSelected) {
      const residencialSelecionados = options.filter((itens) => {
        return itensSelected.indexOf(itens.value) !== -1 ? true : false
      })
      setSelected([...residencialSelecionados])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itensSelected])

  return (
    <>
      <Select
        options={options}
        isMulti
        onChange={(option: Options[], actionMeta: ActionMeta<Options>) => {
          setSelected(option)
        }}
        value={selected}
      />
    </>
  )
}
