import { gql } from "@apollo/client";

export const GET_USUARIO_GRUPO_SERVICO = gql`
  query GetUsuarioGrupoServicoByGrupo {
    GetUsuarioGrupoServicoByGrupo {
      id
      id_user
      name
      servico {
        id
        nome
        session_name
      }
      users_grupo_servico {
        name
        id
      }
    }
  }
`



export const GET_USUARIO_GRUPO_SERVICO_BY_USER = gql`
query GetUsuarioGrupoServicosByUserId($idMunicipio: Float, $pagination: Pagination!) {
  GetUsuarioGrupoServicosByUserId(idMunicipio: $idMunicipio, pagination: $pagination) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      name
      id
      situacao
      servico {
        id
        nome
        session_name
        descricao
        url_api
        distancia
      }
    }
  }
}
`