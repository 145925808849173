import {Dicionario} from '../../../utils/Dicionario'

export const RelatedPeoplePhones = ({data, estrutura}) => {
  let dateReturn = null

  if (typeof data['Result'] !== 'undefined') {
    if (data['Result'].length > 0) {
      dateReturn = data['Result'][0]['RelatedPeoplePhones']
    }
  } else {
    if (data.length > 0) {
      if (typeof data[0]['RelatedPeoplePhones'] !== 'undefined'){
        dateReturn = data[0]['RelatedPeoplePhones']
      }
    }
  }
  if (!dateReturn) {
    return <></>
  }

  return (
    <>
      {/* {dateReturn?.map((item, index) => {
            return (
<div key={index}>
                <p className='fs-6'>
                  Telefone: ({item['AreaCode']}) {item.Number} ({item.RelationshipType}) <br />
                 Whatsapp: {item.IsWhatsapp ? 'Sim' : 'Não'} 
                </p>
                <hr />
              </div>   )
          })} */}

      {/* <h3 className='pt-10'>{estrutura.titulo}</h3> */}
      <table className='table fs-7'>
        <thead>
          <tr>
            <th className='fs-bold px-0'> Telefone</th>
            <th className='fs-bold px-0'> Tipo Relacionamento</th>

            {/* {estrutura['colunsItens'].map((column, index) => {
              return (
                <th key={index} className='fs-bold px-0'>
                  {column}
                </th>
              )
            })} */}
          </tr>
        </thead>
        <tbody>
          {dateReturn?.map((item, index) => {
            var number = item['AreaCode'] + '' + item.Number
            return (
              <tr key={index}>
                {/* {estrutura['colunsItens'].map((column, index2) => {
                      
                      number = formatPhone(number) */}
                <td key={index} className='px-0'>
                  Telefone: {number}{' '}
                </td>
                <td>{Dicionario.translate(item['RelationshipType'])}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
