import {Text, View, StyleSheet} from '@react-pdf/renderer'
import { CurrencyFormat, DecimalFormat } from '../util'
// const borderColor = '#838383'

const styles = StyleSheet.create({
  table: {
    width: '100%',
    fontSize:11,
    fontWeight:'light'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
  },
  title: {
    marginTop:25,
    marginBottom:15,
    fontSize: 16,
    fontWeight: 'bold',
    borderBottomWidth:2,
    borderBottomColor:"#2B8E57"
  },
  header: {
    borderTop: 'none',
    backgroundColor:"#dbdbdb"
  },
  bold: {
    fontWeight: 'black'
  },
  rowDefault: {
    width: '20%',
  },
  rowCenter: {
    width: '20%',
    textAlign:"center"
  },
  decimal: {
    width: '20%',
    textAlign:'center'
  },
  currency: {
    width: '20%',
    textAlign:'center'
  },
  rowErro: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
    textAlign:'center'
  }
})
export const Avaliacoes = ({avaliacoes}) => {
if (avaliacoes) {

    
  return (
    <View style={styles.table}>
      <Text style={styles.title}>Avaliações</Text>
      <View style={[styles.row, styles.bold, styles.header]}>
        <Text style={styles.rowCenter}>Quartos/Suítes</Text>
        <Text style={styles.rowCenter}>Banheiros</Text>
        <Text style={styles.rowCenter}>Vagas</Text>
        <Text style={styles.rowCenter}>Área</Text>
        <Text style={styles.rowCenter}>Valor</Text>
      </View>
      {avaliacoes.GetAvmLeadByEmpreendimentoId.length === 0 ?    <Text style={styles.rowErro}>Nenhum registro encontrado</Text> : '' }
    
      {avaliacoes.GetAvmLeadByEmpreendimentoId.map((row, i) => (
        <View key={i} style={styles.row} wrap={false}>
          <Text style={styles.rowCenter}>
            <Text style={styles.bold}>{row.quartos}</Text>/{row.suites}
          </Text>
          <Text style={styles.rowCenter}>{row.banheiros}</Text>
          <Text style={styles.rowCenter}>{row.vagas}</Text>
          <Text style={styles.decimal}>{DecimalFormat(row.area)}</Text>
          <Text style={styles.currency}>{CurrencyFormat(row.result_medio)}</Text>
        </View>
      ))}
    </View>
  )
  }
}