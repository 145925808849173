import {useContext} from 'react'
import {BiLinkExternal, BiPlus, BiStreetView, BiTrash} from 'react-icons/bi'
import {DataContext} from '../context/DataContext'
import {ItensImoveisProps} from './Types'
import {formatDate, formatNumber} from '../../explorer/components/common'

function Tootip({
  dataImovel,
  onClose,
  showInfo,
}: {
  dataImovel: ItensImoveisProps
  onClose: any
  showInfo: any
}) {
  const {data, setData, removeItem, addItem} = useContext(DataContext)

  function removeSamples(id: number) {
    console.log('remove id:', id)
    removeItem(id)
    // // eslint-disable-next-line array-callback-return
    // let newData = data.map((item) => {
    //   if (item.id === id) {
    //     item.tipologias.map((itemTipologia) => {
    //       itemTipologia.computed = false
    //     })
    //     item.removed = true
    //   }
    //   return item
    // })

    // setData([...newData])
    onClose(dataImovel.id)
  }

  function addSamples(id: number) {
    addItem(id)
    // // eslint-disable-next-line array-callback-return
    // let newData = data.map((item) => {
    //   if (item.id === id) {
    //     item.tipologias.map((itemTipologia) => {
    //       itemTipologia.computed = true
    //     })
    //     item.removed = false
    //   }
    //   return item
    // })

    // setData([...newData])
    onClose(dataImovel.id)
  }


  function checkMaxM2(dataImovel){
    return  dataImovel.tipologias.reduce((accumulator, currentValue)=>{
       return currentValue.private_area > accumulator    ? currentValue.private_area : accumulator
     },0)
   }
   
   function checkMinM2(dataImovel){
     return  dataImovel.tipologias.reduce((accumulator, currentValue)=>{
       if (accumulator === 0 && currentValue.private_area > 0  ){
         return currentValue.private_area
       }else{
         return currentValue.private_area < accumulator    ? currentValue.private_area : accumulator
       }
     },0)
   }
  
function checkMaxValue(dataImovel){
 return  dataImovel.tipologias.reduce((accumulator, currentValue)=>{
    return currentValue.original_price > accumulator    ? currentValue.original_price : accumulator
  },0)
}

function checkMinValue(dataImovel){
  return  dataImovel.tipologias.reduce((accumulator, currentValue)=>{
    if (accumulator === 0 && currentValue.original_price > 0.10  ){
      return currentValue.original_price
    }else{
      if (currentValue.original_price > 0 ){
        return currentValue.original_price < accumulator    ? currentValue.original_price : accumulator
      }else{
        return accumulator
      }
    }
  },0)
}

function precoMedioM2(dataImovel){
  return  dataImovel.tipologias.reduce((accumulator, currentValue)=>{
       if (currentValue.original_price > 0.1 ){
         return {valor:accumulator ? accumulator.valor + currentValue.original_price : currentValue.original_price , m2: accumulator ? accumulator.m2 +currentValue.private_area : currentValue.private_area }
       }else{
        return accumulator
       }
   },{valor:0,m2:0})
 }
const ValorM2 = precoMedioM2(dataImovel)

  return (
    <>
      <div
        className='task'
        onClick={(e) => {
          e.stopPropagation()
          return false
        }}
      >
        <div className='tags'>
          <span className='tag'>{dataImovel.situacao}</span>
          <div>
            {dataImovel.removed === false ? (
              <button
                className='options btn-sm btn btn-danger me-5 '
                onClick={() => removeSamples(dataImovel.id)}
              >
                <BiTrash className='fs-1' color='#fff'></BiTrash>
              </button>
            ) : (
              <button
                className='options btn btn-primary me-5 '
                onClick={() => addSamples(dataImovel.id)}
              >
                <BiPlus className='fs-1' color='#fff'></BiPlus>
              </button>
            )}
            <button className=' btn btn-secondary  ' onClick={() => onClose(dataImovel.id)}>
              X
            </button>
          </div>
        </div>

        <div className='d-flex all-start-between py-5 '>
          <h3 className='pt-5'>{dataImovel.nome}</h3>
          <button
            className='btn btn-success mt-2'
            onClick={() => {
              showInfo({
                show: true,
                id: dataImovel.id_empreendimento_orulo,
              })
            }}
          >
            <BiPlus></BiPlus> Ver detalhes
          </button>
        </div>

        <div className=' my-0 pt-5 pb-10'>
          <div className='py-1'>
            <span className='text-gray-900 fs-1 fw-bold me-2'>{`${dataImovel.vendas_perc} %`}</span>
            <span className='fw-semibold  fs-7'>de {dataImovel.total_unidades} Unidades</span>
          </div>
          <div className='progress h-7px bg-primary bg-opacity-50 mt-7'>
            <div
              className='progress-bar bg-primary'
              role='progressbar'
              style={{width: `${dataImovel.vendas_perc} %`}}
            ></div>
          </div>
        </div>

        <div className=' my-0 pt-5'>
          <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Tipo</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>{dataImovel.tipo}</span>
            </div>
          </div>
          <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Endereço</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>{dataImovel.endereco}</span>
            </div>
          </div>
          <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Incorporadora</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>{dataImovel.nome_incorporador}</span>
            </div>
          </div>
          {/* <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Data Lançamento</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>{dataImovel.data_lancamento}</span>
            </div>
          </div> */}
          <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Área Privativa</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>
                {checkMinM2(dataImovel)}m² até {checkMaxM2(dataImovel)}m²{' '}
              </span>
            </div>
          </div>

          <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Preço médio m²</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>
                {ValorM2 ? ValorM2.valor/ValorM2.m2 > 0.1
                  ? formatNumber(ValorM2.valor/ValorM2.m2, 'currency')
                  : '-' : '-'}
              </span>
            </div>
          </div>

          <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Ticket</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>
                
              {checkMinValue(dataImovel) === checkMaxValue(dataImovel) ? 
                  formatNumber(checkMinValue(dataImovel))
                :  `de ${formatNumber(checkMinValue(dataImovel), 'currency')} até  ${formatNumber(checkMaxValue(dataImovel), 'currency') }`
              }
              </span>
            </div>
          </div>

          <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Data do Preço</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>
                {formatDate(dataImovel.data_preco)}
              </span>
            </div>
          </div>
          <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Número torres</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>{dataImovel.numero_torres}</span>
            </div>
          </div>
          <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Total Unidades</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>{dataImovel.total_unidades}</span>
            </div>
          </div>

          {/* <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Estoque</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>{dataImovel.estoque}</span>
            </div>
          </div>

          <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Vendas</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>{dataImovel.vendas}</span>
            </div>
          </div> */}
          <div className='row mb-3'>
            <label className='col-lg-4 fs-6 '>Estágio da obra</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>{dataImovel.situacao}</span>
            </div>
          </div>
        </div>
        <div className=' my-0 pt-5+'></div>

        <div className='row mb-3'>
          <label className='col-lg-4 fs-6 '>Percentual Vendas</label>
          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-gray-900'>{dataImovel.vendas_perc}</span>
          </div>
        </div>
        <div className='stats'>
          <div>
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
              <g strokeWidth='0' id='SVGRepo_bgCarrier'></g>
              <g strokeLinejoin='round' strokeLinecap='round' id='SVGRepo_tracerCarrier'></g>
              <g id='SVGRepo_iconCarrier'>
                {' '}
                <path strokeLinecap='round' strokeWidth='2' d='M12 8V12L15 15'></path>{' '}
                <circle strokeWidth='2' r='9' cy='12' cx='12'></circle>{' '}
              </g>
            </svg>
            {formatDate(dataImovel.data_preco)}
          </div>
          <div>
            <BiStreetView className='fs-1'></BiStreetView>
            {dataImovel.distancia} metros distante
          </div>
          <div className='viewer'>
            <a href={dataImovel.webpage} target='_blank' rel='noreferrer'>
              <BiLinkExternal className='fs-1'></BiLinkExternal>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Tootip
