import {BiLinkExternal} from 'react-icons/bi'
import dicionario from './dicionario.json'

export const Dicionario = {
  translate: function (key) {
    if (typeof dicionario[key] != 'undefined') {
      return typeof dicionario[key] == 'undefined' ? '-' : dicionario[key].descricao
    } else {
      if (typeof key !== 'undefined') {
        return key.charAt(0).toUpperCase() + key.slice(1)
      }
    }
  },

  getType: function (key) {
    if (typeof dicionario[key] != 'undefined') {
      if (typeof dicionario[key].format != 'undefined') {
        return dicionario[key].format
      }
    }
  },
  formatValue: function (key, value) {
    if (typeof dicionario[key] != 'undefined') {
      if (typeof dicionario[key].format != 'undefined') {
        let valorFinal = ''
        switch (dicionario[key].format) {
          case 'moeda':
            valorFinal = this.moeda(Number(value))
            break
          case 'decimal':
            valorFinal = this.decimal(Number(value))
            break
          case 'percentil':
            valorFinal = this.percentil(value)
            break
          case 'link':
            valorFinal = this.link(value)
            break
          case 'data':
            valorFinal = this.data(value)
            break
          case 'jsonToTable':
            valorFinal = this.jsonToTable(value)
            break
          case 'simNao':
            valorFinal = this.simNao(value)
            break
        }
        return valorFinal
      } else {
        return value
      }
    } else {
      return value
    }
  },
  moeda: (valor) => {
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 2,
    })
  },
  decimal: (valor) => {
    return valor.toLocaleString('pt-BR', {style: 'decimal', maximumFractionDigits: 2})
  },
  percentil: (valor) => {
    return valor.toLocaleString('pt-BR', {style: 'currdecimalency', maximumFractionDigits: 2})
  },
  link: (valor) => {
    return (
      <a href={valor} target='_blank' rel='noreferrer'>
        <BiLinkExternal className='fs-1'></BiLinkExternal> Abrir
      </a>
    )
  },
  data: function (valor) {
    if (valor != null) {
      if (valor.length > 10) {
        let data = valor.replace('T00:00:00.000Z', '')
        data = data.replace('"', '')
        data = data.replace('"', '')

        var dia = data.split('-')[2]
        var mes = data.split('-')[1]
        var ano = data.split('-')[0]

        var dataFinal = dia + '/' + mes + '/' + ano
        return dataFinal
      } else {
        return valor
      }
    } else {
      return '-'
    }
  },
  jsonToTable: function (data) {
    data = JSON.parse(data)

    var table = '<table>'
    for (var t in data) {
      table += `<tr  bgcolor="#f0f0f0" ><td colspan="2"  bgcolor="#f0f0f0">${data[t]['service']}</td></tr>`
      for (var j in data[t].itens[0]) {
        table += `<td><td>${j}</td><td>${data[t].itens[0][j]}</td></tr>`
      }
    }
    table += '</table>'
    return table
  },
  simNao: function (data) {
    if (data === 't' || data === '1') {
      return 'SIM'
    } else {
      return 'NÃO'
    }
  },
}
