import {useQuery} from '@apollo/client'
import {ImoveisResponse} from '../../../../../graphql/type/imovel'
import {GET_IMOVEIS} from '../../../../../graphql/query/imoveis'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {DateTimToView} from '../../../../../components/util'
import {useState} from 'react'
import {QueryGetImoveis} from '../../../../../graphql/services'
import {BiFilter, BiPin} from 'react-icons/bi'
import {Explorer} from '../../Map/Explorer'
import {Dropdown} from './Dropdown'
import GetTerrenoTagsCreatableSelect from '../../../../meus-imoveis/components/Chosen/GetImovelTagsCreatableSelect'
import {GetImovelTagsChosen} from '../../../../meus-imoveis/components/Chosen/GetImovelTagsAsyncSelect'
import {GetSituacaoClienteChosen} from '../../../../meus-imoveis/components/Chosen/GetSituacaoClienteChosen'

// import {Scrollbars} from 'react-custom-scrollbars-2'
// import {useState} from 'react'
// import {Scrollbars} from 'react-custom-scrollbars-2'
type Props = {}
const href = '#'
export const MeusLocais: React.FC<Props> = () => {
  const [filter, setFilter] = useState([])
  const [pages, setPages] = useState(0)
  const [active, setActive] = useState([])
  const [tags, setTags] = useState('')
  const {data} = QueryGetImoveis({
    variables: {
      pagination: {pagina: pages, quantidade: 20},
      filtro: {
        fields: filter,
      },
    },
  })
  // if (!data) {
  //   return <div>Carregando...</div>
  // }

  function buscar(texto) {
    const filterArray = []
    filterArray.push({
      field: ['nome', 'endereco'],
      operator: 'LIKE',
      value: texto,
    })
    setFilter([...filterArray])
  }

  function onClick(e, id) {
    if (e.target.checked) {
      // eslint-disable-next-line array-callback-return
      const item = data.GetImoveis.result.filter((item, index) => {
        if (item.id === id) {
          return item
        }
      })
      Explorer.addMarker(item[0].latitude, item[0].longitude, `${item[0].nome}`, `${item[0].nome}`)

      Explorer.setGoTo(item[0].latitude, item[0].longitude)

      setActive([...active, id])
    } else {
      const index = active.indexOf(id)
      setActive([...active.splice(index, 1)])
    }
  }

  function showFilter() {
    if (document.getElementById('filtro-meus-locais').classList.contains('hide')) {
      document.getElementById('filtro-meus-locais').classList.remove('hide')
    } else {
      document.getElementById('filtro-meus-locais').classList.add('hide')
    }
  }

  function filterForm(e) {
    e.preventDefault()
    const filterArray = []
    if (e.target['status-cliente'].value) {
      filterArray.push({
        field: ['status_cliente'],
        operator: 'IN',
        value: [e.target['status-cliente'].value],
      })
    }
    if (tags) {
      filterArray.push({
        field: ['tag'],
        operator: 'LIKE',
        value: tags.split(','),
      })
    }

    if (e.target['descartados'].value) {
      filterArray.push({
        field: ['id_situacao'],
        operator: '=',
        value: ['3'],
      })
    }

    setFilter([...filterArray])
  }

  function resetForm() {
    setTags('')
    setFilter([])
  }
  return (
    <>
      <div className='tab-pane fade ' id='mapa-base' role='tabpanel' aria-labelledby='contact-tab'>
        <div className={`card card-xl-stretch mb-5 mb-xl-8`}>
          {/* begin::Header */}
          <div className='card-header border-0 mt-5'>
            <h3 className='card-title fw-bold text-dark'>Meus Locais</h3>
            <div className='card-toolbar'>
              {/* begin::Menu */}
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                // data-kt-menu-trigger='click'
                // data-kt-menu-overflow='true'
                // data-kt-menu-placement='bottom-end'
                // data-kt-menu-flip='top-end'
                // data-kt-menu-static="true"
                // data-kt-menu-attach="filtro-meus-locais"
                onClick={showFilter}
              >
                <BiFilter className='fs-1'></BiFilter>
              </button>

              {/* end::Menu */}
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='hide' id='filtro-meus-locais' data-kt-menu='true'>
            <form onSubmit={filterForm}>
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filtros</div>
              </div>

              <div className='separator border-gray-200'></div>

              <div className='px-7 py-5'>
                <div className='mb-10'>
                  <label className='form-label fw-bold'>Tags:</label>

                  <div>
                    <GetTerrenoTagsCreatableSelect
                      defaultValue={[]}
                      onValueChange={(tags) => {
                        setTags(tags)
                      }}
                    ></GetTerrenoTagsCreatableSelect>
                  </div>
                </div>

                <div className='mb-10'>
                  <label className='form-label fw-bold'>Situação:</label>

                  <div>
                    <GetSituacaoClienteChosen defaultValue={0} onValueChange={() => {}} />
                  </div>
                </div>

                <div className='mb-10'>
                  <input type='checkbox' value='1' name='descartados' id='descartados'></input>
                  <label className='form-label fw-bold'> Descartados</label>
                </div>

                <div className='d-flex justify-content-end'>
                  <button
                    type='reset'
                    className='btn btn-sm btn-warning btn-active-light-primary me-2'
                    data-kt-menu-dismiss='true'
                    onClick={resetForm}
                  >
                    Limpar
                  </button>

                  <button
                    type='submit'
                    className='btn btn-sm btn-primary'
                    data-kt-menu-dismiss='true'
                  >
                    Aplicar
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className='card-body pt-2 p-0 p-5'>
            <input
              className='form-control border-0 p-4 mb-2'
              onChange={(e) => buscar(e.target.value)}
              placeholder='Buscar Camada'
            />

            {/* <Scrollbars autoHide style={{ width: '100%', height: wsize }}> */}

            {data
              ? data.GetImoveis.result.map((item, index) => {
                  // let theDate = new Date(item.data_cadastro)
                  // var todate = theDate.getDate()
                  // var tomonth = theDate.getMonth() + 1
                  // var toyear = theDate.getFullYear()
                  // const data_cadastro = theDate.toUTCString()
                  // todate + '/' + tomonth + '/' + toyear
                  var original_date = DateTimToView(item.data_cadastro)

                  return (
                    <div className='d-flex align-items-center mb-8' key={index}>
                      {/* <span className='bullet bullet-vertical h-40px bg-success'></span> */}

                      <div className='form-check form-check-custom form-check-solid mx-5'>
                        {/* <input className='form-check-input' type='checkbox' value='' /> */}
                        <BiPin className='fs-1'></BiPin>
                      </div>

                      <div className='flex-grow-1'>
                        <a href={href} className='text-gray-800 text-hover-primary fw-bold fs-6'>
                          {item.endereco + ',nº ' + item.numero}
                        </a>
                        <span className='text-muted fw-semibold d-block fs-7'>
                          Cadastrado em {original_date}
                        </span>
                      </div>
                      <div className='flex-grow-0 mx-3'>
                        <span className='menu-badge'>
                          <div className='form-check form-switch form-check-custom form-check-solid'>
                            <input
                              className='form-check-input h-20px w-30px'
                              type='checkbox'
                              value={item.id}
                              defaultChecked={active.indexOf(item.id) !== -1 ? true : false}
                              id='flexSwitchDefault'
                              onChange={(e) => {
                                onClick(e, item.id)
                              }}
                            />
                          </div>
                        </span>
                      </div>

                      {/* <span className='badge badge-light-success fs-8 fw-bold'>New</span> */}
                    </div>
                  )
                })
              : ''}

            {/* <Scrollbars /> */}
          </div>

          {/* end::Body */}
        </div>
      </div>
    </>
  )
}
