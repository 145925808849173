import { TableEmpreendimentosMassa } from './components/TableEmpreendimentosMassa'
import { EmpreendimentoResponse } from '../../../../../graphql/type'
import { CreateForm } from './components/ModalEmpreendimentoMassa/Create'
type Props = {
  data: EmpreendimentoResponse
  imoveis: any
  param: string
}

export const TabMassa: React.FC<Props> = ({ data, imoveis, param }) => {
  const id = data.GetEmpreendimento.id
  return (
    <div
      className={`tab-pane fade ${param === 'massa' ? ' show active' : ''}`}
      id='massa' role='tabpanel' aria-labelledby='massa'>
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Massa</h3>
          <div className='card-toolbar'>
            <CreateForm id_empreendimento={id} />
          </div>
        </div>
        <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
          <div className='card'>
            <div className='border-0 ps-5 pe-5'>
              <div className='row gy-5 gx-xl-8'>
                <div className='col-xl-12'>
                  <div className='card-xxl-stretch mb-5 mb-xl-8'>
                    <TableEmpreendimentosMassa id_empreendimento={id} imoveis={imoveis} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
