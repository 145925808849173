import {useQuery} from '@apollo/client'
import {GET_EMPREENDIMENTO_MASSA} from '../../../../../../graphql/query/empreendimento-massa'
import {TypeEmpreendimentoMassaResponse} from '../../../../../../graphql/type/empreendimento-massa'
import {Buttons} from './Buttons'
import {TableEmpreendimentosMassaUsos} from './EmpreendimentoMassaUso/TableEmpreendimentosMassaUsos'
import {Loader} from '../../../../../../components/UI/Loader/Loader'
import {QueryGetEmpreendimentoMassa} from '../../../../../../graphql/services/EmpreendimentoMassa'
import {useState} from 'react'
import {EditForm} from './ModalEmpreendimentoMassa/Edit'
import {BiListCheck} from 'react-icons/bi'

type Props = {
  id_empreendimento: number
  imoveis: any
}

const TableEmpreendimentosMassa: React.FC<Props> = ({id_empreendimento, imoveis}) => {
  const {data} = QueryGetEmpreendimentoMassa({
    variables: {
      pagination: {pagina: null, quantidade: null},
      massa: {id_empreendimento: id_empreendimento},
    },
  })

  const [id, setId] = useState(null)
  const [showEdit, setShowEdit] = useState(false)

  function CloseModalEdit() {
    setShowEdit(false)
    setId(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setId(id)
  }

  if (!data) {
    return <Loader text={'caregando...'}></Loader>
  }
  function showTable(id) {
    var linha = document.getElementById('detalheTable_' + id)
    if (linha.classList.contains('hide')) {
      linha.classList.remove('hide')
    } else {
      linha.classList.add('hide')
    }
  }
  return (
    <div className='card-body p-0 py-3'>
      <div className='table-responsive'>
        <table className='table table-bored table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='min-w-50px'>Massa</th>

              <th className='text-right'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data.GetEmpreendimentoMassa.result.map((response, item) => {
              return (
                <>
                  <tr key={item}>
                    <td className='w-10 '>
                      <button
                        onClick={() => {
                          showTable(response.id)
                        }}
                        className='btn  btn-light-primary btn-sm d-block w-100px '
                        style={{    width: '100px !important'}}
                      >
                        <BiListCheck className='fs-2'></BiListCheck>Usos
                      </button>
                    </td>

                    <td>
                      <p className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {response.nome}
                      </p>
                    </td>
                    <td className='text-right'>
                      <Buttons id={response.id} setIdEdit={OpenModalEdit} imoveis={imoveis} />
                    </td>
                  </tr>
                  <tr
                    key={item+'_detalhe'}
                    id={'detalheTable_' + response.id}
                    className='hide'
                  >
                    <td colSpan={8}>
                      <TableEmpreendimentosMassaUsos imoveis={imoveis} id_massa={response.id} />
                    </td>
                  </tr>
                </>
              )
            })}
          </tbody>
        </table>
        {showEdit ? (
          <EditForm
            id_empreendimento={id_empreendimento}
            id={id}
            show={showEdit}
            closeModal={CloseModalEdit}
          ></EditForm>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export {TableEmpreendimentosMassa}
