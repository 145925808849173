import { useFormContext } from 'react-hook-form';
import { RealEstateLaunch } from '../../../../../../services/http/urbit-gis/routes/types';
import { GetEstadoCidadeChosen } from '../../../../../components/UI/Chosens/GetEstadoCidadeChosen';
import { FormInputAndLabel } from '../../../../../components/UI/Inputs/FormInputAndLabel';
import { RealEstateLaunchFormData } from '../typologies/formValidations';
import { AutocompleteGoogle } from '../../../../../components/UI/Autocomplete/Autocomplete';
import { ApiUrbit } from '../../../../../../services/http/urbit';
import { useEmpreendimentos } from '../../context/useEmpreendimentos';

interface Props {
  data?: RealEstateLaunch;
}

export function Localization({ data }: Props) {
  const {register, setValue, formState: { errors } } = useFormContext<RealEstateLaunchFormData>();
  const {setLaunchData} = useEmpreendimentos()
    
  async function getMunicipio(lat:number,lng:number){
    // TODO: rever consulta do municipio
    const result = await ApiUrbit.get(`municipio/sao-paulo/lote-fiscal/${lat}/${lng}?distancia=300`)
    return result
  }
  async function onChangeAddres (endereco){
    const municipio =  await getMunicipio(endereco.longitude,endereco.latitude)
    setLaunchData({
     'address':endereco.endereco_completo,
     'zipcode':endereco.cep,
     'id_state': Number(municipio.itens[0].id_estado),
     'id_city':Number(municipio.itens[0].id_cidade),
     'neighborhood':endereco.bairro,
     'number':endereco.numero,
     'latitude':Number(endereco.latitude),
     'longitude':Number(endereco.longitude),
     'geom': {
          type: 'Point',
          coordinates: [Number(endereco.longitude), Number(endereco.latitude)],
        }
    })

  }
  
  if (!data) {
    return (
      <div className="row">
        <div className='form-group col-md-12 form-line'>
          <div className="row">

          <div className='form-group'>
            <label className='col-form-label'>Endereço</label> 
            <AutocompleteGoogle onChangeAddress={onChangeAddres} endereco={null}></AutocompleteGoogle>
          </div>

          
       
            {/* <FormInputAndLabel
              label='Endereço'
              className='form-control form-control-solid'
              placeholder='Ex: Rua Alameda dos Santos'
              register={{ ...register('address') }}
              error={errors ? errors.address?.message : ''}
            /> */}
          </div>
          <div className="row mt-4">
            <div className="col-4">
              <FormInputAndLabel
                label='CEP'
                className='form-control form-control-solid'
                placeholder='Ex: 11111-111'
                register={{ ...register('data.zipcode') }}
                error={errors ? errors.data.zipcode?.message : ''}
              />
            </div>
            <GetEstadoCidadeChosen
              defaultValueCidade={null}
              defaultValueEstado={null}
              classNameCity={'col-4 col-xs-12 mt-1'}
              classNameEstado={'col-4 col-xs-12 mt-1'}
              SetValueEstado={(value) => {
                setValue('data.id_state', Number(value))
              }}
              SetValueCidade={(value) => {
                setValue('data.id_city', Number(value))
              }}
              errorCity={errors ? errors.data.id_city?.message : ''}
              errorState={errors ? errors.data.id_state?.message : ''}
            />

          </div>
          <div className="row mt-4">
            <div className="col-4">
              <FormInputAndLabel
                label='Bairro'
                className='form-control form-control-solid'
                placeholder='Ex: Vila Madalena, Pinheiros, Liberdade...'
                register={{ ...register('data.neighborhood') }}
                error={errors ? errors.data.neighborhood?.message : ''}
              />
            </div>
            <div className="col-4">
              <FormInputAndLabel
                label='Numero'
                className='form-control form-control-solid'
                placeholder='Ex: 123'
                register={{ ...register('data.number') }}
                error={errors ? errors.data.number?.message : ''}
              />
            </div>
            <div className="col-4">
              <FormInputAndLabel
                label='Complemento'
                className='form-control form-control-solid'
                placeholder='Ex: Casa, Andar, Apartamento'
                register={{ ...register('data.complement') }}
                error={errors ? errors.data.complement?.message : ''}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="row">
      <div className='form-group col-md-12 form-line'>
        <div className="row">
        <div className='form-group'>
          <label className='col-form-label'>Endereço</label> 
          <AutocompleteGoogle onChangeAddress={onChangeAddres} endereco={data?.address}></AutocompleteGoogle>
         </div>
        </div>
        <div className="row mt-4">
          <div className="col-4">
            <FormInputAndLabel
              label='CEP'
              className='form-control form-control-solid'
              placeholder='Ex: 11111-111'
              defaultValue={data.zipcode}
              register={{ ...register('data.zipcode') }}
            />
          </div>
          <GetEstadoCidadeChosen
            defaultValueCidade={data.id_city}
            defaultValueEstado={data.id_state}
            classNameCity={'col-4 col-xs-12 mt-1'}
            classNameEstado={'col-4 col-xs-12 mt-1'}
            SetValueEstado={(value) => {
              setValue('data.id_state', Number(value))
            }}
            SetValueCidade={(value) => {
              setValue('data.id_city', Number(value))
            }}
          />
        </div>
        <div className="row mt-4">
          <div className="col-4">
            <FormInputAndLabel
              label='Bairro'
              className='form-control form-control-solid'
              placeholder='Ex: Vila Madalena, Pinheiros, Liberdade...'
              defaultValue={data.neighborhood}
              register={{ ...register('data.neighborhood') }}
            />
          </div>
          <div className="col-4">
            <FormInputAndLabel
              label='Numero'
              className='form-control form-control-solid'
              placeholder='Ex: 123'
              defaultValue={data.number}
              register={{ ...register('data.number') }}
            />
          </div>
          <div className="col-4">
            <FormInputAndLabel
              label='Complemento'
              className='form-control form-control-solid'
              placeholder='Ex: Casa, Andar, Apartamento'
              defaultValue={data.complement}
              register={{ ...register('data.complement') }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
