import {Page, Document, Image, StyleSheet, PDFViewer} from '@react-pdf/renderer'
import {Corretores, Footer, Header, Info} from './partials'
import {Observacoes} from './partials/Observacoes'
import {Avaliacoes} from './partials/Avaliacoes'
import {CondicaoComercial} from './partials/CondicaoComercial'
import {Proprietarios} from './partials/Proprietarios'
import {QueryGetAvmLeadByImovelId} from '../../graphql/services/AVM'
import {QueryGetImovelCorretores} from '../../graphql/services/ImovelCorretores'
import {QueryGetImovelObservacoes} from '../../graphql/services/Observacoes'
import {QueryGetCondicaoComercial} from '../../graphql/services/CondicaoComercial'
import {QueryGetImovelProprietarios} from '../../graphql/services/ImovelProprietarios'
import {useLocation} from 'react-router-dom'
import {useQuery} from '@apollo/client'
import {GetImovelImagensResponse} from '../../graphql/type/imovel-imagens'
import {GET_IMOVEL_IMAGENS} from '../../graphql/query/imovel-imagens'
import {Fotos} from './partials/Fotos'
import {styles} from './partials/style'

const MeuImovelReport = ({data, map}) => {
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  let printIntes = []
  if (searchParams.get('param')) {
    printIntes = searchParams.get('param').split(',')
  }
  const printElement = {
    info: printIntes.find((i) => i === 'info') ? true : false,
    corretores: printIntes.find((i) => i === 'corretores') ? true : false,
    proprietarios: printIntes.find((i) => i === 'proprietarios') ? true : false,
    observacoes: printIntes.find((i) => i === 'obs') ? true : false,
    fotos: printIntes.find((i) => i === 'fotos') ? true : false,
    condicaoComercial: printIntes.find((i) => i === 'condicaoComercial') ? true : false,
    avaliacoes: printIntes.find((i) => i === 'avaliacoes') ? true : false,
    // atividades:false,
    // fichaTerritorial:false,
  }

  const {data: avaliacoes} = QueryGetAvmLeadByImovelId({
    variables: {
      idImovel: data.GetImovel.id,
      pagination: {
        pagina: null,
        quantidade: null,
      },
    },
  })
  const {data: corretores} = QueryGetImovelCorretores({variables: {idTerreno: data.GetImovel.id}})

  const {data: observacoes} = QueryGetImovelObservacoes({
    variables: {
      imovelObservacao: {
        id_terreno: data.GetImovel.id,
      },
      pagination: {
        quantidade: null,
        pagina: null,
      },
    },
  })

  const {data: condicaoComercial} = QueryGetCondicaoComercial({
    variables: {
      idImovel: data.GetImovel.id,
      pagination: {
        pagina: null,
        quantidade: null,
      },
    },
  })
  const {data: proprietarios} = QueryGetImovelProprietarios({
    variables: {idImovel: data.GetImovel.id},
  })

  const {data: fotos} = useQuery<GetImovelImagensResponse>(GET_IMOVEL_IMAGENS, {
    variables: {idImovel: data.GetImovel.id},
  })

  if (!avaliacoes) {
    return <div>Carregando avaliações</div>
  }

  if (!corretores) {
    return <div>Carregando corretores</div>
  }

  if (!observacoes) {
    return <div>Carregando corretores</div>
  }

  if (!condicaoComercial) {
    return <div>Carregando condição comercial</div>
  }

  if (!proprietarios) {
    return <div>Carregando Proprietários</div>
  }
  if (!fotos) {
    return <div>Carregando Proprietários</div>
  }
  return (
    <PDFViewer>
      <Document>
        <Page size='A4' style={styles.page}>
          <Header />
          {/* <Image src={`${fileBase64}`}/>      */}
          <Image src={`${map}`} style={styles.mapa} />
          {printElement.info ? <Info data={data} /> : ''}
          {printElement.corretores ? <Corretores corretores={corretores} /> : ''}
          {printElement.observacoes ? <Observacoes observacoes={observacoes} /> : ''}
          {printElement.avaliacoes ? <Avaliacoes avaliacoes={avaliacoes} /> : ''}

          {printElement.condicaoComercial ? (
            <CondicaoComercial condicaoComercial={condicaoComercial} />
          ) : (
            ''
          )}
          {/* {printElement.condominios ? <Condominios data={data.corretores} /> : '' }  */}
          {/* {printElement.atividades ? <Atividades data={data.corretores} /> : '' }  */}
          {printElement.proprietarios ? <Proprietarios proprietarios={proprietarios} /> : ''}
          {printElement.fotos ? <Fotos fotos={fotos} /> : ''}

          <Footer />
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default MeuImovelReport
