import {mapStore} from '../../../../../../store/mapStore'

const MyComponents = {
  Distancia: function DatePicker(props) {
    return <div className='map-info'> Distância</div>
  },
}

export const MapInfoBox: React.FC = () => {
  const {getInfoBox} = mapStore()
  const {show, componente, info} = getInfoBox()

  function renderSwitch(componente, info) {
    switch (componente) {
      case 'distancia':
        return <MyComponents.Distancia>Distância: {info}</MyComponents.Distancia>
      default:
        return ''
    }
  }

  return <>{show ? renderSwitch(componente, info) : ''}</>
}
