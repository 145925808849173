
export function DocumentsCard() {

  return (
    <div className="row px-6">
      <div className='form-group col-md-12 form-line '>
        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
          <div className="col-12 col-sm-12 col-xl">
            <div className="card h-100">
              <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                <a href="#" className="text-gray-800 text-hover-primary d-flex flex-column">
                  <div className="symbol symbol-75px mb-6">
                    <img src="/media/svg/files/pdf.svg" alt="" />
                  </div>
                  <div className="fs-5 fw-bolder mb-2">Finance</div>
                </a>
                <div className="fs-7 fw-bold text-gray-500 mt-auto">7 files</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-xl">
            <div className="card h-100"><div className="card-body d-flex justify-content-center text-center flex-column p-8">
              <a href="#" className="text-gray-800 text-hover-primary d-flex flex-column">
                <div className="symbol symbol-75px mb-6">
                  <img src="/media/svg/files/pdf.svg" alt="" />
                </div>
                <div className="fs-5 fw-bolder mb-2">Customers
                </div>
              </a>
              <div className="fs-7 fw-bold text-gray-500 mt-auto">3 files
              </div>
            </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-xl">
            <div className="card h-100">
              <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                <a href="#" className="text-gray-800 text-hover-primary d-flex flex-column">
                  <div className="symbol symbol-75px mb-6">
                    <img src="/media/svg/files/pdf.svg" alt="" />
                  </div>
                  <div className="fs-5 fw-bolder mb-2">CRM Project</div>
                </a>
                <div className="fs-7 fw-bold text-gray-500 mt-auto">25 files</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}