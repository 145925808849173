import React from 'react'
import { BiLayerPlus, BiMapPin } from 'react-icons/bi'
import { Explorer } from '../../Explorer'
// import {MenuItemGrupo} from './MenuItemGrupo'
type Props = {
  data: any
  nome:string
}

export const BtnActions: React.FC<Props> = ({data,nome}) => {

  function addLayer(data){

    let featureCollection = {
      type: 'FeatureCollection',
      features: [],
    }
    // eslint-disable-next-line array-callback-return
    data.map((item)=>{
      if (item.geom){
        featureCollection.features.push(JSON.parse(item.geom) )
      }
    })
    // console.log(featureCollection)
    // Explorer.addGeoJSONLayer(featureCollection,'','', ` ${nome}`)


  }
  return (
    <>
      {/* <span className='menu-actions'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          className='bi bi-sign-turn-left'
          viewBox='0 0 16 16'
        >
          <path d='M11 8.5A2.5 2.5 0 0 0 8.5 6H7V4.534a.25.25 0 0 0-.41-.192L4.23 6.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 7 8.466V7h1.5A1.5 1.5 0 0 1 10 8.5V11h1V8.5Z' />
          <path
            fillRule='evenodd'
            d='M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435Zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134Z'
          />
        </svg>
      </span> */}
      {/* {(data ? 
      <span className='menu-actions'>
        <BiLayerPlus className="fs-1" onClick={()=>addLayer(data)}></BiLayerPlus>
      </span>
       : '')} */}
    </>
  )
}
