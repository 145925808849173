import {useParams} from 'react-router-dom'
import {MediaCard} from './components/Media'
import {DocumentsCard} from './components/Docs'

export function TabMedia() {
  const {id} = useParams<{id: string}>()

  return (
    <div className='tab-pane fade' id='tab-media' role='tabpanel' aria-labelledby='tab-media-tab'>
      <div className='row g-5 g-xl-8' style={{alignItems: 'stretch'}}>
        <div className='col-xl-6 d-flex'>
          <MediaCard />
        </div>
        <div className='col-xl-6 d-flex'>
          <div className='card flex-grow-1 mb-5 mb-xl-8'>
            <div className='card-header border-0 pt-5'>
              <h2 className='fw-bolder pt-4 px-8 m-0'>Documentos</h2>
              <hr />
            </div>
            <div className='card-body py-3'>
              <DocumentsCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
