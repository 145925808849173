import {ColorMapboxToKepler, GetPropert} from '../../../../../common'
import {BaseLayer, DefaultLayerProps} from '../base-layer'
import {IconLayer as DeckIconLayer} from '@deck.gl/layers'
// import Pin from '../../../../../../../../../img/icon-atlas.png'
import {Explorer} from '../../../../Explorer'
import {Layout} from '../../../../Layout'
import {ICON_MAPPING, Pin} from '../Icones'

export class IconLayer extends BaseLayer {

  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-useless-constructor
  constructor(props: DefaultLayerProps | any) {
    super(props)
  }
  onDragNode(event) {
    console.log(event)
  }
  onDragNodeStart(){
    
      Explorer.deckgl.setProps({
        controller: {dragPan: false}
      });
    
  }
  onDragNodeEnd() {
    Explorer.deckgl.setProps({
      controller: {dragPan: true}
    });
  }
  draw(propsInput: DefaultLayerProps | any) {
    let props = {...this.getDefaultLayerConfig(propsInput)}
    props = {...propsInput}
    
    if (props.tooltip) {
      if (props.visible){
        props.tooltip.setContent(props.label)
        // props.tooltip.apen()
      }else{
          props.tooltip.close()
        }
    }

    

    const icone = [
      new DeckIconLayer({
        id: props.id,
        data: props.data,
        pickable: true,
        visible: props.visible,
        //TODO : InconLayer - Incluir posibilidade de escolher o PIN
        iconAtlas: Pin,
        iconMapping: ICON_MAPPING,
        getIcon: (d) => {
          return typeof props.icon !== 'undefined' ? props.icon : 'marker'
        },
        zIndex: 999999999,
        sizeScale: 1.5,
        opacity: 1,
        onDragStart: this.onDragNodeStart,
        onDrag: (e)=>{
          // this.onDragNode(e)
          console.log(e)
        },
        onDragEnd : this.onDragNodeEnd,

        getPosition: (d: any) => {
          let value = GetPropert(props.style, 'positionZ')
          if (typeof d.coordinates ==='undefined'){
            if (d.geometry.coordinates.length === 2) {
              d.geometry.coordinates.push(value || 20)
            } else {
              d.geometry.coordinates[2] = value || 20
            }
            return d.geometry.coordinates
          }else{
            if (d.coordinates.length === 2) {
              d.coordinates.push(value || 20)
            } else {
              d.coordinates[2] = value || 20
            }
            return d.coordinates
          }
        },
        getSize: (f: any) => {
          let value = GetPropert(props.style, 'size')
          return value * 10 || 30
        },
        getColor: (f: any) => {
          let colorFinal = ColorMapboxToKepler(props.style, f.properties,'fill-color')
          return colorFinal
        },
        parameters: {depthTest: false},

        // onDragStart: onDragNodeStart,
        // eslint-disable-next-line no-dupe-keys
        // onDrag: this.onDragNode,
        // onDragEnd: onDragNodeEnd,

        //TODO : InconLayer - Incluir Opções de edição no click do ícone
        onClick: (info: any, event: any) => {
          this.clickEventMarker(info, event, () => {
            // if (event.srcEvent.domEvent.button === 0) {
            //   const lng = info.coordinate[0]
            //   const lat = info.coordinate[1]
            //   const latlng = new google.maps.LatLng(lat, lng)
            //   Layout.setShowTerritorioMenu(true, latlng)
            // }
            if (event.srcEvent.domEvent.button === 2) {
              const lng = info.coordinate[0]
              const lat = info.coordinate[1]
              const latlng = new google.maps.LatLng(lat, lng)

              Layout.setShowContextMenu(false, latlng)
              Layout.setShowGeometryMenu(true, latlng)
            }

            props.tooltip.setMap(Explorer.map)
            Explorer.goToIconToMenu(info.layer.id)
          })
        },
        
        updateTriggers: {
          getColor: (f: any) => {
            let colorFinal = ColorMapboxToKepler(props.style, f.properties,'fill-color')
            return colorFinal
          },
          getSize: (f: any) => {
            let value = GetPropert(props.style, 'size')
            return value || 24
          },
          getPosition: (d: any) => {
            let value = GetPropert(props.style, 'positionZ')
            d.coordinates.push(value)
            return d.coordinates
          },
        },
      }),
    ]
    
    return icone
  }
}
