import '../global.css'
import './explorer.css'
import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import {ExplorerMenu} from './components/Menu'
import {Maps} from './components/Map/MapContainer'
import {ExplorerMenuTopo} from './components/Map/MenuTopo'
import { MenuBottom } from './components/Map/MenuBottom'

export function Explorer() {

  return (
    <>
      <AsideDefault />
      <ExplorerMenu />
      <div
        className='MapContainer d-flex flex-column flex-row-fluid'
        id='kt_wrapper'
        style={{width: '100%', height: '100%', overflow: 'hidden'}}
      >
        <ExplorerMenuTopo />
        <div className=' d-flex flex-root'>
          <div
            id='map'
            className=' flex-row'
            style={{width: '100%', height: '100%', position: 'relative'}}
          >
            <Maps />
          </div>
        </div>
        <MenuBottom />
      </div>
    </>
  )
}
