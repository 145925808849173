import { useQuery } from '@apollo/client'
import { TypesGetGetEmpreendimentoProprietariosResponse } from '../type/empreendimento_proprietario'
import { GET_EMPREENDIMENTO_PROPRIETARIOS } from '../query/empreendimento-proprietario'

interface Props {
  variables: any
}

export function QueryGetEmpreendimentoProprietarios({variables}: Props) {
  const {data} = useQuery<TypesGetGetEmpreendimentoProprietariosResponse>(GET_EMPREENDIMENTO_PROPRIETARIOS, {
    variables: {...variables},
  })
  return data
}
