import {useState} from 'react'
import {Footer} from '../../../../_metronic/layout/components/Footer'
import {AsideDefault} from '../../../../_metronic/layout/components/aside/AsideDefault'
import {HeaderWrapperFluid} from '../../../../_metronic/layout/components/header/HeaderWrapperFluid'
import {PacotesChosen} from './partials/ChosenPacotes'
import {CategoriasChosen} from './partials/ChosenCategoria'
import {MutationSetServico} from '../../graphql'
import Swal from 'sweetalert2'
import {Link, useHistory} from 'react-router-dom'
import {FormInputAndLabel} from '../../../components/UI/Inputs/FormInputAndLabel'
import {setServicoFormData} from '../../formValidations/Servicos'
import {GetEstadoCidadeChosen} from '../../../components/UI/Chosens/GetEstadoCidadeChosen'
import {Nav, Tab} from 'react-bootstrap'

export default function AdminCreateServicos() {
  const [valuePacote, setValuePacote] = useState([])
  const [valueCategoria, setValueCategoria] = useState([])
  const navigate = useHistory()
  const {errors, handleSubmit, register, setValue, FormServico} = MutationSetServico()

  console.log(valuePacote)
  console.log(valueCategoria)

  function GoBack() {
    navigate.goBack()
  }

  const onSubmit = async (data: setServicoFormData) => {
    data['servico_categoria'] = []
    data['servico_pacotes'] = []

    FormServico(data)
      .then((result) => {
        Swal.fire('Sucesso!', 'Servico criado com sucesso.', 'success')
        GoBack()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  // eslint-disable-next-line no-template-curly-in-string
  let clausula_limit = ' ${clausula_limit}'
  // eslint-disable-next-line no-template-curly-in-string
  let clausula_distance = ' ${clausula_distance}'
  // eslint-disable-next-line no-template-curly-in-string
  let clausula_geom = ' ${clausula_geom}'
  // eslint-disable-next-line no-template-curly-in-string
  let clausula_intersect = ' ${clausula_intersect}'
  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <div
                className='py-6  d-flex align-items-center justify-content-between'
                id='kt_header_container'
              >
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  data-kt-swapper='true'
                  data-kt-swapper-mode='prepend'
                  data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Criar</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href='/metronic8/demo7/../demo7/index.html' className='text-muted'>
                        Servicos
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                <div className='card p-8'>
                  {/*begin::Card header*/}
                  <div className='border-0'>
                    <form onSubmit={handleSubmit(onSubmit)} className='row'>
                      <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          {/* Name Field */}
                          <div className='form-group col-md-12 form-line '>
                            <FormInputAndLabel
                              label='Source'
                              className='form-control form-control-solid'
                              placeholder='Source '
                              error={errors ? errors.nome?.message : ''}
                              register={register('nome', {required: true})}
                            />
                          </div>
                        </div>

                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <CategoriasChosen
                            valueCategoria={valueCategoria}
                            setValueCategoria={(value: []) => {
                              setValueCategoria(value)
                              console.log(value)
                              setValue('categorias', value)
                            }}
                          />
                        </div>

                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <PacotesChosen
                            valuePacote={valuePacote}
                            setValuePacote={(value: []) => {
                              setValuePacote(value)
                              console.log(value)
                              setValue('pacotes', value)
                            }}
                          />
                        </div>
                      </div>

                      <div className='row  pt-4'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Custo</label>
                          <input
                            className='form-control form-control-solid'
                            name='custo_servico'
                            type='number'
                            id='custo_servico'
                            placeholder='Custo'
                            {...register('custo', {valueAsNumber: true})}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Vizualização</label>
                          <select
                            className='form-select form-select-solid '
                            id='select-vizualizacao'
                            name='vizualizacao'
                          >
                            <option value='PUBLICO'>Público</option>
                            <option value='PUBLICO_LOGIN'>Publico Login</option>
                            <option value='PAGO'>Pago</option>
                          </select>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>URL da API</label>
                          <input
                            className='form-control form-control-solid'
                            name='url_api'
                            type='text'
                            id='url_api'
                            placeholder='URL da API'
                            {...register('url_api')}
                          />
                        </div>
                      </div>
                      <div className='row  pt-4'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Nome da variável na sessão</label>
                          <input
                            className='form-control form-control-solid'
                            name='session_name'
                            type='text'
                            id='session_name'
                            placeholder='Session name'
                            {...register('session_name')}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Data de Atualização(dd/mm/aaaa)</label>
                          <input
                            className='form-control form-control-solid'
                            name='atualizacao_url'
                            type='date'
                            id='atualizacao_url'
                            {...register('updated_at')}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>URL do Sistema</label>
                          <input
                            className='form-control form-control-solid'
                            name='url_sistema'
                            type='text'
                            id='url_sistema'
                            placeholder='URL do Sistema'
                            {...register('url_sistema')}
                          />
                        </div>
                      </div>

                      <div className='row  pt-4'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Filtro Isócota</label>
                          <select
                            {...register('filtro_isocota', {valueAsNumber: true})}
                            className='form-select form-select-solid '
                            id='select-sync_imovel'
                            name='sync_isocota'
                          >
                            <option value='0'>Não</option>
                            <option value='1'>Sim</option>
                          </select>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Filtro Isócrona</label>
                          <select
                            {...register('filtro_isocrona', {valueAsNumber: true})}
                            className='form-select form-select-solid '
                            id='select-sync_imovel'
                            name='sync_isocota'
                          >
                            <option value='0'>Não</option>
                            <option value='1'>Sim</option>
                          </select>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Filtro Limit</label>
                          <select
                            {...register('filtro_limit', {valueAsNumber: true})}
                            className='form-select form-select-solid '
                            id='select-sync_imovel'
                            name='sync_isocota'
                          >
                            <option value='0'>Não</option>
                            <option value='1'>Sim</option>
                          </select>
                        </div>
                      </div>
                      <div className='row  pt-4'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Limite(Os "X" mais próximos )</label>
                          <input
                            className='form-control form-control-solid'
                            name='limite'
                            type='number'
                            id='limite'
                            {...register('limite', {valueAsNumber: true})}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Ordem</label>
                          <input
                            className='form-control form-control-solid'
                            name='ordem'
                            type='number'
                            id='ordem'
                            {...register('ordem', {valueAsNumber: true})}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Distância em raio</label>
                          <input
                            className='form-control form-control-solid'
                            name='distancia'
                            type='text'
                            id='distancia'
                            {...register('distancia', {valueAsNumber: true})}
                          />
                        </div>
                      </div>
                      <div className='row  pt-4'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Recorrência</label>
                          <input
                            className='form-control form-control-solid'
                            name='recorrencia'
                            type='number'
                            id='recorrencia'
                            {...register('recorrencia', {valueAsNumber: true})}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                          <label className='col-form-label'>Situação</label>
                          <select
                            {...register('situacao', {valueAsNumber: true})}
                            className='form-select form-select-solid '
                            id='select-situacao'
                            name='situacao'
                          >
                            <option value='0'>Disponivel</option>
                            <option value='1'>Não Disponivel</option>
                          </select>
                        </div>
                      </div>

                      <div className='form-group col-md-12 form-line pt-5'>
                        <label className='col-form-label'>Descrição</label>
                        <textarea
                          {...register('descricao')}
                          className='form-control form-control-solid'
                          style={{resize: 'none'}}
                          name='descricao'
                          id='descricao'
                        ></textarea>
                      </div>
                      <div className='w-100 all-end mt-4'>
                        <Link
                          to='/backoffice/servicos'
                          type='button'
                          className='btn btn-danger me-4'
                        >
                          Cancelar
                        </Link>
                        <button type='submit' className='btn btn-success'>
                          Cadastrar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header border-0 pt-9'>
                      <div className='card-title m-0'>
                        Configurações do serviço
                        <div className='symbol symbol-50px w-50px bg-light'></div>
                      </div>

                      <div className='card-toolbar'>
                        <span
                          className={`badge badge-light-succes fw-bolder me-auto px-4 py-3`}
                        ></span>
                      </div>
                    </div>

                    <div className='card-body p-9'>
                      <div className='fs-3 fw-bolder text-dark'>Local</div>
                      <div className='row'>
                        <GetEstadoCidadeChosen
                          classNameCity={'col-6 mt-1'}
                          classNameEstado={'col-6   mt-1'}
                          defaultValueEstado={0}
                          defaultValueCidade={0}
                          SetValueEstado={(value) => {
                            // console.log('SetValueEstado', value)
                            // setValueEstado(value)
                            // console.log('estado')
                            // console.log(value)
                            // setValue('id_estado', value)
                            // setValue('id_cidade', null)
                          }}
                          SetValueCidade={(value) => {
                            // setValueCidade(value)
                            // setValue('id_cidade', value)
                          }}
                        />
                      </div>
                      <div className='row'>
                        <div className='col-12 mt-15' id='vertical-tabs-example'>
                          <Tab.Container defaultActiveKey='first'>
                            <div className='d-flex'>
                              <Nav variant='pills' className='flex-column'>
                                <Nav.Item>
                                  <Nav.Link eventKey='first'>Query</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey='second'>
                                    Colunas para retorno ao cliente
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey='third'>Template Exibição</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey='fourt'>Replicar consulta </Nav.Link>
                                </Nav.Item>
                              </Nav>
                              <Tab.Content className='p-3 w-80'>
                                <Tab.Pane eventKey='first'>
                                  <div className='col-md-12'>
                                    <h3 className='mb-5'>Consulta</h3>
                                    <div className='well'>
                                      <div className=''>
                                        <textarea
                                          rows={10}
                                          className='form-control w-100'
                                          name='servico-cidade-api-query'
                                          id='servico-cidade-api-query'
                                          placeholder='Consulta do serviço'
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-md-12'>
                                    <h3 className='mt-10 mb-5'>Variáveis da query</h3>
                                    <div className='well' id='fixed-variables'>
                                      <table className='table table-hover'>
                                        <thead>
                                          <tr>
                                            <th>Variável</th>
                                            <th>Valor</th>
                                          </tr>
                                        </thead>
                                        <tbody id='fixed-variables-tbody'>
                                          <tr data-name='longitude'>
                                            <td>
                                              {' '}
                                              <span className='var-status'>
                                                <i className='fa fa-times text-danger'></i>
                                              </span>{' '}
                                              longitude
                                            </td>
                                            <td className='d-flex align-items-center '>
                                              <div className='col-md-8'>
                                                <input
                                                  className='form-control fixed-query-variable-value'
                                                  id='input-lng'
                                                  type='text'
                                                  value=''
                                                />
                                              </div>
                                              <div className='col-md-4 ms-5'>
                                                <input
                                                  className='fixed-query-variable-required'
                                                  type='checkbox'
                                                  value='1'
                                                />
                                                Obrigatório
                                              </div>
                                            </td>
                                          </tr>
                                          <tr data-name='latitude'>
                                            <td>
                                              {' '}
                                              <span className='var-status'>
                                                <i className='fa fa-times text-danger'></i>
                                              </span>{' '}
                                              latitude
                                            </td>
                                            <td className='d-flex align-items-center '>
                                              <div className='col-md-8'>
                                                <input
                                                  className='form-control fixed-query-variable-value'
                                                  id='input-lat'
                                                  type='text'
                                                  value=''
                                                />
                                              </div>
                                              <div className='col-md-4 ms-5'>
                                                <input
                                                  className='fixed-query-variable-required'
                                                  type='checkbox'
                                                  value='1'
                                                />
                                                Obrigatório
                                              </div>
                                            </td>
                                          </tr>
                                          <tr data-name='clausula_limit'>
                                            <td>
                                              {' '}
                                              <span className='var-status'>
                                                <i className='fa fa-times text-danger'></i>
                                              </span>{' '}
                                              limit
                                              {clausula_limit}
                                            </td>
                                            <td className='d-flex align-items-center '>
                                              <div className='col-md-8'>
                                                <input
                                                  className='form-control fixed-query-variable-value'
                                                  type='number'
                                                  value=''
                                                />
                                              </div>
                                              <div className='col-md-4 ms-5'>
                                                <input
                                                  className='fixed-query-variable-required'
                                                  type='checkbox'
                                                  value='1'
                                                />
                                                Obrigatório
                                              </div>
                                            </td>
                                          </tr>
                                          <tr data-name='clausula_distance'>
                                            <td>
                                              {' '}
                                              <span className='var-status'>
                                                <i className='fa fa-times text-danger'></i>
                                              </span>{' '}
                                              distance
                                              {clausula_distance}
                                            </td>
                                            <td className='d-flex align-items-center '>
                                              <div className='col-md-8'>
                                                <input
                                                  className='form-control fixed-query-variable-value'
                                                  type='number'
                                                  value=''
                                                />
                                              </div>
                                              <div className='col-md-4 ms-5'>
                                                <input
                                                  className='fixed-query-variable-required'
                                                  type='checkbox'
                                                  value='1'
                                                />
                                                Obrigatório
                                              </div>
                                            </td>
                                          </tr>
                                          <tr data-name='clausula_geom'>
                                            <td>
                                              {' '}
                                              <span className='var-status'>
                                                <i className='fa fa-times text-danger'></i>
                                              </span>{' '}
                                              geom
                                              {clausula_geom}
                                            </td>
                                            <td className='d-flex align-items-center '>
                                              <div className='col-md-8'>
                                                <select className='form-control fixed-query-variable-value'>
                                                  <option value=''> -- selecione --</option>
                                                  <option value='1'>true</option>
                                                  <option value='0'>false</option>
                                                </select>
                                              </div>
                                              <div className='col-md-4 ms-5'>
                                                <input
                                                  className='fixed-query-variable-required'
                                                  type='checkbox'
                                                  value='1'
                                                />
                                                Obrigatório
                                              </div>
                                            </td>
                                          </tr>
                                          <tr data-name='clausula_intersect'>
                                            <td>
                                              {' '}
                                              <span className='var-status'>
                                                <i className='fa fa-times text-danger'></i>
                                              </span>{' '}
                                              geom intersect
                                              {clausula_intersect}
                                            </td>
                                            <td className='d-flex align-items-center '>
                                              <div className='col-md-8'>
                                                <input
                                                  className='form-control fixed-query-variable-value'
                                                  type='text'
                                                  value=''
                                                />
                                              </div>
                                              <div className='col-md-4 ms-5'>
                                                <input
                                                  className='fixed-query-variable-required'
                                                  type='checkbox'
                                                  value='1'
                                                />
                                                Obrigatório
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                        <tbody id='query-variables-tbody'>
                                          <tr data-name='none'>
                                            <td colSpan={2}>
                                              Nenhuma variável adicional encontrada.
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey='second'>
                                  <div className='well'>
                                    <h3>Resultado da consulta</h3>
                                    <pre id='result-query-preview' style={{maxHeight: 300}}>
                                      "Vazio"
                                    </pre>
                                    <h3>Colunas retornadas</h3>
                                    <table id='' className='table table-hover'>
                                      <tbody id='tbody-colunas'>
                                        <tr>
                                          <td> — </td>
                                        </tr>
                                      </tbody>
                                    </table>

                                    <div className='form-group'>
                                      <h3>Colunas para retorno</h3>
                                      <div className='table-container' style={{overflow: 'auto'}}>
                                        <table
                                          id='table-colums-config'
                                          className='table table-hover'
                                        >
                                          <thead>
                                            <tr>
                                              <th>Coluna</th>
                                              <th>Descrição</th>
                                              <th>Tipo de retorno</th>
                                              <th style={{minWidth: 250}}>Valores</th>
                                              <th>Status</th>
                                              <th>&nbsp;</th>
                                            </tr>
                                          </thead>
                                          <tbody id='tbody-colunas-retorno'>
                                            <tr>
                                              <td colSpan={6}> Nenhuma coluna para retorno </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <div className='form-group'>
                                          <button
                                            className='btn btn-block btn-primary'
                                            type='button'
                                            id='btn-save-return-configs'
                                          >
                                            {' '}
                                            Salvar configurações de retorno
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='form-group'>
                                      <button
                                        className='btn btn-block btn-primary'
                                        data-loading-text='Salvando query...'
                                        type='button'
                                        id='btn-save-query'
                                      >
                                        {' '}
                                        Salvar consulta &amp; colunas para retorno
                                      </button>
                                    </div>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey='third'>
                                  <h3>Template de exibição</h3>

                                  <div className='row'>
                                    <div className='col-md-4 '>
                                      <div className='col-md-6 '>
                                        <div className='form-group'>
                                          <label>Latitude</label>
                                          <input
                                            type='text'
                                            className='form-control'
                                            id='display-config-lat'
                                          />
                                        </div>
                                      </div>
                                      <div className='col-md-6'>
                                        <div className='form-group'>
                                          <label>Longitude</label>
                                          <input
                                            type='text'
                                            className='form-control'
                                            id='display-config-lng'
                                          />
                                        </div>
                                      </div>
                                      <div className='col-md-12'>
                                        <div className='form-group'>
                                          <button
                                            className='btn btn-block btn-primary'
                                            type='button'
                                            id='btn-test-service'
                                          >
                                            {' '}
                                            Testar retorno do serviço{' '}
                                          </button>
                                        </div>
                                      </div>
                                      <div className='col-md-12'>
                                        <div className='well'>
                                          <pre id='result-preview'>vazio</pre>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-md-4'>
                                      Valor dinâmico para exibição no painel Urbit
                                      <textarea
                                        className='form-control'
                                        cols={20}
                                        rows={5}
                                      ></textarea>
                                    </div>
                                    <div className='col-md-4'>
                                      Informações adicionais do serviço
                                      <textarea
                                        className='form-control'
                                        cols={20}
                                        rows={5}
                                      ></textarea>
                                    </div>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey='fourt'>
                                  <h3>Replicar serviços</h3>

                                  <div className='row'>
                                    <div className='col-2'>
                                      <button
                                        className='btn btn-warning'
                                        type='button'
                                        id='btn-replicate-query'
                                        data-servico-cidade-id=''
                                        data-servico-cidade-nome=''
                                      >
                                        <i className='fa fa-copy'></i> Replicar consulta{' '}
                                      </button>
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-12'>
                                      <div className='mirrors-table'>
                                        <table className='table table-hover' id='table-mirrors'>
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>Criado em</th>
                                              <th>Criado por</th>
                                              <th>status</th>
                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td colSpan={5}> - </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  )
}
