export const EmailsExtended = ({data, estrutura}) => {
  let dateReturn = null
  if (typeof data['Result'] !== 'undefined') {
    if (data['Result'].length > 0 && data['Result'][0]['ExtendedEmails'] && data['Result'][0]['ExtendedEmails']['Emails']) {
      dateReturn = data['Result'][0]['ExtendedEmails']['Emails'];
    }
  } else {
    if (data.length > 0 && data[0]['ExtendedEmails'] && data[0]['ExtendedEmails']['TotalPersonalEmails'] > 0 && data[0]['ExtendedEmails']['Emails']) {
      dateReturn = data[0]['ExtendedEmails']['Emails'];
    }
  }
  

  // console.log(dateReturn)
  return (
    <>
      {dateReturn?.map((item: any, index: number) => {
        return (
          <div key={index}>
            <p className='fs-6'>
              Email: {item.EmailAddress} ({item.Type}){' '}
            </p>
          </div>
        )
      })}

      {/* <h3 className="pt-10">{estrutura.titulo}</h3>

      <table className="table fs-6">
      <thead>
        <tr>
          {estrutura['colunsItens'].map((column, index) => {
            return (
              <th key={index} className='fs-bold'>
                {column}
              </th>
            )
          })}
        </tr>
        </thead>
        <tbody>
        {dateReturn?.map((item, index) => {
          return (
            <tr key={index}>{estrutura['colunsItens'].map((column, index2) => {
                return <td key={index2}>{item[column]}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table> */}
    </>
  )
}
