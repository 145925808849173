import {useState} from 'react'
import {APiUrbit} from '../../../../services/api/apiUrbit'

type Props = {
  index: number
  column: any
  tableSchema: string
  table_name: string
  onUpdate: any
  register: any
  values?: any
}
interface TableColunas {
  character_maximum_length: number
  column_name: string
  data_type: string
  type_data?: string
  data_return?: string
  step?: string
}

export const Tr: React.FC<Props> = ({
  index,
  column,
  table_name,
  tableSchema,
  onUpdate,
  register,
  values,
}) => {
  const [value, setValue] = useState<any>(null)

  const callApi = async (
    type: string,
    table: string,
    schema: string,
    column: string,
    index: number
  ) => {
    let apiEndpoint = ''
    switch (type) {
      case 'group-by':
        apiEndpoint = `explorer/operation/${type}?table=${table}&schema=${schema}&column=${column}`
        break
      case 'min-max':
        apiEndpoint = `explorer/operation/${type}?table=${table}&schema=${schema}&column=${column}`
        break
      default:
        break
    }
    try {
      const api = new APiUrbit()
      const response = await api.get(apiEndpoint)
      if (response) {
        if (response !== null) {
          let dataCampoSelect = []
            if (type === 'group-by'){
            response.map((el)=>{
              dataCampoSelect.push(el[column])
            })
          }else{
            dataCampoSelect = response
          }
          setValue(dataCampoSelect)
          onUpdate(`column.${index}.data`, JSON.stringify(dataCampoSelect, undefined, 4))
        } else {
          console.error('API returned null')
        }
      } else {
        console.error('Empty response from API')
      }
    } catch (error) {
      console.error('Error fetching data from API:', error)
    }
  }

  return (
    <tr key={index}>
      <td className=''>
        <input
          type='hidden'
          defaultValue={column.column_name}
          {...register(`column.${index}.coluna`)}
        ></input>
        {column.column_name}
      </td>
      <td className='justify-content-center '>
        <input
          type='checkbox'
          className='form-check text-center'
          defaultChecked={
            values ? (values.select_columns === column.column_name ? true : false) : false
          }
          id={`column_select_${column.column_name}`}
          {...register(`column.${index}.select`)}
        />
      </td>
      <td className='justify-content-center '>
        <input
          type='checkbox'
          className='form-check'
          defaultChecked={values ? (values.id_column === column.column_name ? true : false) : false}
          onChange={(e) => {
            let value = e.target.value
            onUpdate('column_filter', index, value)
          }}
          {...register(`column.${index}.id`)}
        />
      </td>
      <td className=' '>
        <input
          type='checkbox'
          className='form-check d-flex justify-content-center'
          defaultChecked={
            values ? (values.filtro_principal === column.column_name ? true : false) : false
          }
          id={`filtro_principal_${column.column_name}`}
          {...register(`column.${index}.filtro_principal`)}
        />
      </td>
      <td>
        <input
          type='text'
          className='form-control'
          defaultValue={values ? values.description : column.column_name}
          {...register(`column.${index}.descricao`)}
        />
      </td>
      <td>
        <select
          className='selectType form-control'
          defaultValue={values ? values.type_data : undefined}
          {...register(`column.${index}.type`)}
          onChange={(e) => {
            let value = e.target.value
            onUpdate('type', index, value)
          }}
        >
          <option value=''>--selecione--</option>
          {column.data_type === 'integer' ||
          column.data_type === 'bigint' ||
          column.data_type === 'numeric' ||
          column.data_type === 'double precision' ? (
            <option value='range'>Range</option>
          ) : (
            <>
              <option value='select'>Select</option>
              <option value='text'>Text</option>
            </>
          )}
        </select>
      </td>
      <td>
        <textarea
          defaultValue={values ? values.data_return : value ? JSON.stringify(value) : ''}
          // value={values ? values.data_return : value ? JSON.stringify(value) : ''}
          onChange={(e) => {
            let value = e.target.value
            onUpdate('data', index, value)
          }}
          className='form-control'
          {...register(`column.${index}.data`)}
        ></textarea>
      </td>
      <td>
        <input
          type='text'
          className='form-control'
          defaultValue={values ? values.step : undefined}
          {...register(`column.${index}.step`)}
        />
      </td>
      <td>
        <button
          type='button'
          className='btn btn-success'
          onClick={() =>
            callApi(
              column.data_type === 'integer' ||
                column.data_type === 'bigint' ||
                column.data_type === 'numeric' ||
                column.data_type === 'double precision'
                ? 'min-max'
                : 'group-by',
              table_name,
              tableSchema,
              column.column_name,
              index
            )
          }
        >
          Sync
        </button>
      </td>
    </tr>
  )
}
