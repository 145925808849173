import {useState} from 'react'
import {Form} from 'react-bootstrap'
import {SliderSingle} from '../../../../../components/UI/Slider'

export function AreaInfluencia({
  setFiltroLocal,
  filtroLocal,
}: {
  setFiltroLocal: any
  filtroLocal: any
}) {
  const [areaInfluencia, setAreaInfluencia] = useState<String>('')

  function selectAreaInfluencia(tipo: string, valor: any) {
    setAreaInfluencia(tipo)
    setFiltroLocal({...filtroLocal, ...{areaInfluencia: {tipo: tipo, valor: valor}}})
  }

  let marksDistancia = {}
  marksDistancia[0] = '0 metros'
  marksDistancia[3000] = '3km'

  let marksTempo = {}
  marksTempo[0] = '0 min'
  marksTempo[30] = '30 min'

  return (
    <>
      <div className='radio-inputs'>
        <label>
          <input
            className='radio-input'
            type='radio'
            name='areainfluencia'
            checked={filtroLocal?.areaInfluencia.tipo === 'raio' ? true : false}
            onChange={() => {
              selectAreaInfluencia(
                'raio',
                filtroLocal?.areaInfluencia.tipo === 'raio'
                  ? filtroLocal?.areaInfluencia.valor
                  : 1500
              )
            }}
          />
          <span className='radio-tile'>
            <span className='radio-label'>Raio</span>
          </span>
        </label>
        <label>
          <input
            className='radio-input disabled'
            type='radio'
            name='areainfluencia'
            checked={filtroLocal?.areaInfluencia.tipo === 'zonaValor' ? true : false}
            onChange={() => {
              selectAreaInfluencia('zonaValor', null)
            }}
          />
          <span className='radio-tile'>
            <span className='radio-label'>Zona de Valor</span>
          </span>
        </label>

        <label>
          <input
            className='radio-input'
            type='radio'
            name='areainfluencia'
            checked={filtroLocal?.areaInfluencia.tipo === 'isocrona' ? true : false}
            onChange={() => {
              selectAreaInfluencia(
                'isocrona',
                filtroLocal?.areaInfluencia.tipo === 'isocrona'
                  ? filtroLocal?.areaInfluencia.valor
                  : 15
              )
            }}
          />
          <span className='radio-tile'>
            <span className='radio-label'>Isocrona</span>
          </span>
        </label>

        <label>
          <input
            className='radio-input disabled'
            type='radio'
            name='definirManualmente'
            checked={filtroLocal?.areaInfluencia.tipo === 'definirManualmente' ? true : false}
            onChange={() => {
              selectAreaInfluencia('definirManualmente', null)
            }}
          />
          <span className='radio-tile'>
            <span className='radio-label'>Desenhar Área</span>
          </span>
        </label>
      </div>
      {areaInfluencia === 'raio' || filtroLocal?.areaInfluencia.tipo === 'raio' ? (
        <div className='row row ps-4'>
          <Form.Label column sm='12' className='fs-7 px-0'>
            Distância (Metros)
          </Form.Label>

          <SliderSingle
            defaultValue={
              filtroLocal?.areaInfluencia.tipo === 'raio' ? filtroLocal?.areaInfluencia.valor : 1500
            }
            min={0}
            max={3000}
            step={100}
            onChange={(e) => {
              selectAreaInfluencia('raio', e)
            }}
            tipFormatter={(value) => `${value} metros `}
            marks={marksDistancia}
          ></SliderSingle>
        </div>
      ) : (
        <></>
      )}
      {areaInfluencia === 'isocrona' || filtroLocal?.areaInfluencia.tipo === 'isocrona' ? (
        <div className='row row ps-4'>
          <Form.Label column sm='12' className='fs-7 px-0'>
            Tempo (minutos)
          </Form.Label>
          <SliderSingle
            defaultValue={
              filtroLocal?.areaInfluencia.tipo === 'isocrona'
                ? filtroLocal?.areaInfluencia.valor
                : 15
            }
            min={0}
            max={30}
            step={1}
            onChange={(e) => {
              selectAreaInfluencia('isocrona', e)
            }}
            tipFormatter={(value) => `${value} min `}
            marks={marksTempo}
          ></SliderSingle>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
