import {useState} from 'react'
import {Buttons} from './ButtonsServicos'
import {QueryGetServicos} from '../../../graphql'
import TextoCurto from './TextoReduzido'
import {BiPlus} from 'react-icons/bi'
import {Link} from 'react-router-dom'
import {CardTables} from '../../../../components/UI/CardTables'
import LoadingTable from '../../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../../components/UI/CardTables/ErrorTable'

export function TableAdminServicos() {
  const [pages, setPages] = useState(0)
  const {data, loading, error} = QueryGetServicos({
    variables: {variables: {pagination: {pagina: null, quantidade: 10}}},
  })

  return (
    <>
      <CardTables
        headerTitle={'Serviços'}
        headerToolbar={
          <Link className='btn btn-sm btn-light-primary' to='/backoffice/servicos/create'>
            <BiPlus className='fs-3'></BiPlus>
            Novo Serviço
          </Link>
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-150px'>Nome</th>
                <th className='min-w-140px'>URL</th>
                <th className='min-w-200px'>Descrição</th>
                <th className='min-w-100px'>Situação</th>
                <th className='min-w-100px'>Visualização</th>
                <th className='min-w-100px text-end'>Ação</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetServicoCategoria.length > 0 ? (
                  data.GetServicoCategoria.map((response, y) => {
                    return (
                      <>
                        <tr key={y} style={{backgroundColor: '#d0d0d0'}}>
                          <td colSpan={7}>
                            <span className='text-dark fw-bold d-block fs-6'>{response.nome}</span>
                          </td>
                        </tr>

                        {response.servico.map(function (servico, j) {
                          return (
                            <tr key={j}>
                              <td>
                                <span className='d-block fs-6'>{servico.nome}</span>
                              </td>
                              <td>
                                <span className='d-block fs-6'>{servico.url_api}</span>
                              </td>{' '}
                              <td>
                                <span className='d-block fs-6'>
                                  <TextoCurto texto={servico.descricao} limiteCaracteres={40} />
                                </span>
                              </td>{' '}
                              <td>
                                <span className=' text-center fs-6'>
                                  {servico.situacao === 1 ? 'Ativo' : 'Inativo'}
                                </span>
                              </td>
                              <td className='text-start'>
                                <span className=' text-center fs-6'>{servico.visualizacao}</span>
                              </td>
                              <td>
                                <Buttons id={servico.id} />
                              </td>
                            </tr>
                          )
                        })}
                      </>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
    </>
  )
}
