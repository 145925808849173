import { gql } from "@apollo/client"

export const SET_TERRENO_TAGS = gql`
mutation SetTerrenoTags($terrenoId: Float!, $data: TerrenoTagsInput!) {
  SetTerrenoTags(terreno_id: $terrenoId, data: $data) {
    nome
  }
}
`

export const DELETE_TERRENO_TAGS = gql`
mutation DeleteTerrenoTags($deleteTerrenoTagsId: Float!) {
  DeleteTerrenoTags(id: $deleteTerrenoTagsId) {
    id
    nome
    situacao
  }
}
`

// EMPREENDIMENTO TAGS

export const SET_EMPREENDIMENTO_TAGS = gql`
mutation SetEmpreendimentoTags($empreendimentoId: Float!, $empreendimentoTags: tags!) {
  SetEmpreendimentoTags(empreendimento_id: $empreendimentoId, empreendimentoTags: $empreendimentoTags) {
    tag
  }
}
`

export const DELETE_EMPREENDIMENTO_TAGS = gql`
mutation DeleteEmpreendimentoTags($deleteEmpreendimentoTagsId: Float!) {
  DeleteEmpreendimentoTags(id: $deleteEmpreendimentoTagsId) {
    tag
  }
}
`
