import { useMutation, useQuery } from "@apollo/client"
import { TypesDeleteExplorerLayerUserResponse, TypesGetExplorerLayerUserResponse, TypesSetExplorerLayerUser } from "../type/explorer-layer-usuario"
import { GET_EXPLORER_LAYER_USER } from "../query/explorer-layer-usuario"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { DELETE_CORRETOR, DELETE_EXPLORER_LAYER_USER, SET_EXPLORER_LAYER_USER } from "../mutations"
import { setExplorerLayerFormData, setExplorerLayerFormSchema } from "../formValidations/ExplorerLayerUsuario"

interface Props {
  variables: any
}

export function QueryGetExploreLayerUser({variables}: Props) {
  const { data, loading, error } = useQuery<TypesGetExplorerLayerUserResponse>(GET_EXPLORER_LAYER_USER,{
    variables: {...variables}
  })

  return { data, loading, error }
}

export function MutationCreateExplorerLayerUser() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<setExplorerLayerFormData>({
    resolver: zodResolver(setExplorerLayerFormSchema),
  })

  console.log(errors)

  const [setExplorerLayerUserBody, {error, loading, data: DataSetExplorerLayerUser}] =
    useMutation<TypesSetExplorerLayerUser>(SET_EXPLORER_LAYER_USER, {
      refetchQueries: [GET_EXPLORER_LAYER_USER],
    })

  async function FormExplorerLayerUser(data: setExplorerLayerFormData) {
    await setExplorerLayerUserBody({
      variables: {
        explorerLayerUser: {...data},
      },
    })

  }

  return {register, handleSubmit, FormExplorerLayerUser, loading, errors, error, DataSetExplorerLayerUser, setValue}
}

export function MutationDeleteExplorerLayerUser() {
  const [deleteExplorerLayer, {loading, data, error}] = useMutation<TypesDeleteExplorerLayerUserResponse>(
    DELETE_EXPLORER_LAYER_USER,
    {
      refetchQueries: [GET_EXPLORER_LAYER_USER],
    }
  )

  async function HandleDeleteExplorerLayerUser({variables}: Props) {
    try {
      const result = await deleteExplorerLayer({
        variables: {...variables},
        refetchQueries: [GET_EXPLORER_LAYER_USER],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteExplorerLayerUser, loading, error, data}
}