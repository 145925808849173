import {useCallback, useState} from 'react'
import {TIsOpen} from '../components/Types'
import { CreateStyles } from '../../explorer/components/Map/Style/MapStyles'

const useMap = () => {
  const [map, setMap] = useState(null)
  const [isOpen, setIsOpen] = useState<TIsOpen>([])
  const [isOpenSingle, setIsOpenSingle] = useState<TIsOpen>([])

  const onLoad = useCallback(
    (map) => {
      setMap(map)

      CreateStyles(map)
      // CreateStreeStyle(map)
      changeStyle('simples', map)
      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    null
  )

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])


  function changeStyle (style: string, map){
    map.setMapTypeId(style)
  }

  function handleMarkerClick(id: number) {
    if (isOpen.length > 0) {
      let existItem = isOpen.findIndex((item) => item.id === id)
      if (existItem !== -1) {
        isOpen.splice(existItem, 1)
      } else {
        isOpen.push({
          id: id,
          open: true,
        })
      }
      setIsOpen([...isOpen])
    } else {
      isOpen.push({
        id: id,
        open: true,
      })
      setIsOpen([...isOpen])
    }
  }
  function handleMarkerMOuseOut(id: number) {
    let existItem = isOpenSingle.findIndex((item) => item.id === id)

    if (existItem !== -1) {
      isOpenSingle.splice(existItem, 1)
    }
    setIsOpenSingle([...isOpenSingle])
  }

  return {onLoad, onUnmount, map, handleMarkerClick, isOpen, handleMarkerMOuseOut}
}

export default useMap
