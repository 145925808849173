export const PhonesExtended = ({ data, estrutura }) => {

  let dateReturn = null

  if (typeof data['Result'] !== 'undefined') {
    if (data['Result'].length > 0 && data['Result'][0]['ExtendedPhones'] && data['Result'][0]['ExtendedPhones']['Phones']) {
      dateReturn = data['Result'][0]['ExtendedPhones']['Phones'];
    }
  } else {
    if (data.length > 0 && data[0]['ExtendedPhones'] && data[0]['ExtendedPhones']['Phones']) {
      console.log(data[0]);
      console.log(data[0]['ExtendedPhones']['Phones']);
      dateReturn = data[0]['ExtendedPhones']['Phones'];
    }
  }
  

  return (
    <>
      <h3 className="pt-10">{estrutura.titulo}</h3>

      <table>
        <thead>
          <tr key={-1}>
            {estrutura['colunsItens'].map((column, index) => {
              return (
                <th key={index} className='fs-bold'>
                  {column}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {dateReturn?.map((item, index) => {
            return (
              <tr key={index}>
                {estrutura['colunsItens'].map((column, index2) => {
                  return <td key={index2}>{item[column]}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
