import {gql} from '@apollo/client'

export const GET_AVM_LEAD = gql`
  query GetAvmLead($pagination: Pagination, $filtro: filter) {
    GetAvmLead(pagination: $pagination, filtro: $filtro) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        id
        id_user
        nome
        email
        id_tipo_imovel
        id_finalidade
        latitude
        longitude
        endereco
        logradouro
        numero
        bairro
        cidade
        estado
        quartos
        suites
        banheiros
        vagas
        area
        condominio
        id_situacao
        created_at
        response_url
        ano_construcao_cliente
        tipo
        servicos_esporte
        servicos_social
        servicos_entreterimento
        servicos_condominio
        aptos_andar
        numero_andares
        numero_torres
        padrao
        tipo_apartamento
        features
        id_empreendimento
        origem_lead
        id_terreno
        result_minimo
        result_maximo
        result_medio
        json_output
        origem_operacao
      }
    }
  }
`

export const GET_AVM_LEAD_BY_IMOVEL_ID = gql`
  query GetAvmLeadByImovelId($idImovel: Float!, $pagination: Pagination) {
    GetAvmLeadByImovelId(id_imovel: $idImovel, pagination: $pagination) {
      id
      result_maximo
      result_medio
      result_minimo
      tipo
      quartos
      suites
      banheiros
      vagas
      area
      id_tipo_imovel
    }
  }
`

export const GET_AVM_LEAD_BY_EMPREENDIMENTO_ID = gql`
  query GetAvmLeadByEmpreendimentoId($idEmpreendimento: Float!, $pagination: Pagination) {
    GetAvmLeadByEmpreendimentoId(id_empreendimento: $idEmpreendimento, pagination: $pagination) {
      id
      result_maximo
      result_medio
      result_minimo
      tipo
      quartos
      suites
      banheiros
      vagas
      area
      id_tipo_imovel
    }
  }
`

export const GET_AVM_BY_ID = gql`
  query GetCorretorById($getCorretorByIdId: Float!) {
    GetCorretorById(id: $getCorretorByIdId) {
      id
      nome
      telefone
      email
      situacao
      empresa
    }
  }
`
