import React from 'react'
import Map, {Layer, Marker, Source} from 'react-map-gl'
import type {FeatureCollection} from 'geojson'
import type {FillLayer} from 'react-map-gl'
import * as turf from '@turf/turf'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
// import Pin from './Pin'
import 'mapbox-gl/dist/mapbox-gl.css'
import {marcadoresMap} from '../../util'
import mapboxgl from "mapbox-gl"; 

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl['workerClass'] = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

type PropsContextMenu = {
  latLng: any[]
  showLotes?: boolean
  showMarker?: boolean
  inputRef: any
  onLoad: any
  features: any[]
}

const geojson: FeatureCollection = {
  type: 'FeatureCollection',
  features: [],
}

const layerStyle: FillLayer = {
  id: 'point',
  type: 'fill',
  paint: {
    'fill-color': '#8a8a8a',
    'fill-outline-color': '#333',
    'fill-opacity': 0.5,
  },
}
async function fit(map, callback) {
  if (geojson) {
    const envelope = turf.bbox(geojson)
    
    map.target.fitBounds(
      [
        [envelope[0], envelope[1]],
        [envelope[2], envelope[3]],
      ],
      {padding: 10}
    )
  }
  callback(map)
}
export const Mapbox: React.FC<PropsContextMenu> = ({
  inputRef,
  onLoad,
  features,
  latLng,
  showLotes = true,
  showMarker = false,
}) => {
  geojson.features = features
  const envelope = turf.envelope( (geojson as turf.AllGeoJSON ))
  const center = turf.centerOfMass(envelope)
  return (
    <div>
      <Map
        ref={inputRef}
        mapboxAccessToken='pk.eyJ1Ijoia29seCIsImEiOiJjajI0eHA1MzMwMDVuMzNvNWRlMDBnd2U0In0.ZPJhRHqplNUji9ZRi89wCg'
        initialViewState={{
          longitude: center.geometry.coordinates[0],
          latitude: center.geometry.coordinates[1],
          zoom: 17,
        }}
        style={{width: 600, height: 350}}
        mapStyle='mapbox://styles/kolx/cko8xwo2p07g218mpjig6a0fd'
        onIdle={(map) => {
          onLoad(map)
        }}
        onLoad={async (map) => {
          await fit(map, (map2) => {})
        }}
      >
        <Source id='my-data' type='geojson' data={geojson}>
          <Layer {...layerStyle} />
          {geojson.features.map((geom: any, index) => {
            let center = turf.centroid(geom)

            return (
              <Marker
                key={index}
                longitude={center.geometry.coordinates[0]}
                latitude={center.geometry.coordinates[1]}
                anchor='center'
                scale={0.5}
                color={'#fff'}
              >
                {/* <Pin size={16} /> */}
                <div style={{color: '#174b08', cursor: 'pointer'}}>
                  <span className='pointerPosition'>{marcadoresMap[index]}</span>
                </div>
              </Marker>
            )
          })}
        </Source>
      </Map>
    </div>
  )
}
