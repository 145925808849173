import { gql } from "@apollo/client";

export const SET_IMOVEL = gql`
mutation SetImovel( $imovel: imovel!) {
  SetImovel( imovel: $imovel) {
    uuid
    id
    nome
    hash_id
  }
}
`
export const SET_IMOVEL_EMPREENDIMENTO = gql`
mutation SetNovoImovelEmpreendimento($idEmpreendimento: Float, $imovel: imovel!) {
  SetNovoImovelEmpreendimento(id_empreendimento: $idEmpreendimento, imovel: $imovel) {
    uuid
    id
    nome
    hash_id
  }
}
`

export const PUT_IMOVEL = gql`
mutation PutImovel($putImovelId: Float!, $imovel: imovel!) {
  PutImovel(id: $putImovelId, imovel: $imovel) {
    nome
    id
  }
}
`

// Deletar Situação de terreno pelo ID do terreno
export const DELETE_IMOVEL = gql`
mutation DeleteImovel($deleteImovelId: Float!) {
  DeleteImovel(id: $deleteImovelId) {
    nome
  }
}
`
