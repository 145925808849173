import { gql } from '@apollo/client';

export const GET_IMOVEL_CORRETORES = gql`
query GetImovelCorretor($idTerreno: Float!) {
  GetImovelCorretor(id_terreno: $idTerreno) {
    id
    corretor {
        id
        nome
        telefone
        email
        situacao
        empresa
    }
  }
}
`