import {Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {MutationCreateEmpreendimentoObservacoes} from '../../../../../../graphql/services/Observacoes'
import {ErrorMessageComponent} from '../../../../../../backoffice/components/UI/ErrorMessage'
import {Loader} from '../../../../../../components/UI/Loader/Loader'
import { MutationSetLogAcessoRecurso } from '../../../../../../graphql/services/LogAcesso'

interface Props {
  closeModal: any
  show: boolean
  id_empreendimento:number
}

export function CreateForm({closeModal, show,id_empreendimento}: Props) {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  const {
    FormCreateEmpreendimentoObservacoes,
    loading,
    handleSubmit,
    register,
    errors,
  } = MutationCreateEmpreendimentoObservacoes()

  const onSubmit = async (data) => {
    FormCreateEmpreendimentoObservacoes(data).then((result) => {
      FormSetLogAcessoRecurso({ ferramenta: 'empreendimentos', funcionalidade: 'observacoes', action: 'create', url: '/empreendimentos/details/' + id_empreendimento + '#observacoes' });
      Swal.fire('Sucesso!', 'Observação criada com sucesso.', 'success')
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })
  }

  // Tratativa de loading
  if (loading) {
    return <Loader text={'caregando...'}></Loader>
  }

  return (
    <Modal centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Adicionar Observação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>
            <div className='col-md-12'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <label htmlFor='email' className='required form-label'>
                    Titulo:
                  </label>
                  <input
                    className='form-control form-control-solid'
                    placeholder='id_empreeendimento'
                    hidden={true}
                    defaultValue={id_empreendimento}
                    {...register('id_empreendimento', {
                     valueAsNumber:true
                    })}
                  />
                  <input
                    className='form-control form-control-solid'
                    placeholder='Titulo'
                    defaultValue={''}
                    {...register('titulo', {
                      pattern: /^[A-Za-z]+$/i,
                    })}
                  />
                  {errors.titulo && <ErrorMessageComponent message={errors.titulo?.message} />}
                </div>
              </div>

              <div className='col-md-12'>
                <div className='mb-6'>
                  <label htmlFor='email' className='required form-label'>
                    Descrição:
                  </label>
                  <textarea
                    rows={6}
                    style={{resize: 'none'}}
                    className='form-control form-control-solid'
                    placeholder='Descreva sua observação...'
                    defaultValue={''}
                    {...register('descricao', {
                      pattern: /^[A-Za-z]+$/i,
                    })}
                  />
                  {errors.descricao && (
                    <ErrorMessageComponent message={errors.descricao?.message} />
                  )}
                </div>
              </div>
            </div>
            <div className='col-md-12 py-6 d-flex' style={{justifyContent: 'flex-end'}}>
              <button
                onClick={closeModal}
                className='btn btnSecondary  pull-right '
                type='button'
                data-bs-dismiss='modal'
              >
                Fechar
              </button>
              <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                Salvar
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
