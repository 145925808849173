import { gql } from "@apollo/client";

export const DELETE_PACOTE = gql`
mutation DeletePacote($deletePacoteId: Float!) {
  DeletePacote(id: $deletePacoteId) {
    nome
    id
  }
}
`

export const SET_PACOTE = gql`
mutation SetPacote($data: pacote!, $idServicos: [Float!]!) {
  SetPacote(data: $data, id_servicos: $idServicos) {
      id
    }
  }
`

export const PUT_PACOTE = gql`
mutation PutPacote($idServicos: [Float!]!, $data: pacote!) {
  PutPacote(id_servicos: $idServicos, data: $data) {
    pacote {
      id
    }
  }
}
`