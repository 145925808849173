import { BoxTipologia } from "./BoxTipologia"


type Props = {
  tipologias: any
}

export function TipologiasTab({tipologias}: Props) {
  return (
    <div className='kt_content_container  d-flex flex-column flex-column-fluid'>
      <div className='card card-custom'>
        <div className='card-header all-start'>
          <h1 className='fts-2 pt-5'>Estatísticas</h1>
        </div>
        
        <div className='card-body p-0'>
          <div className='row  py-5 px-5 bg-grey'>
            {tipologias.map((tipologia,index) => {
              return (
                <div className='col-6 pb-5' key={index}>
                  <BoxTipologia
                    titulo={tipologia.type + ' - ' + tipologia.private_area + 'm2'}
                    subtitulo={` ${tipologia.bedrooms} dorm / ${tipologia.parking} vaga/ ${tipologia.suites} suítes`}
                    tipologia={tipologia}
                  ></BoxTipologia>
                </div>
              )
            })}
          </div>
            
        </div>
      </div>
    </div>
  )
}
