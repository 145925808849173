import {useMutation, useQuery} from '@apollo/client'
import {GET_CORRETORES, GET_CORRETOR_BY_ID} from '../query'
import {TypesDeleteCorretorResponse, TypesGetCorretoresResponse, TypesSetCorretorResponse, TypesPutCorretorResponse, TypesGetCorretorById} from '../type'
import {DELETE_CORRETOR, PUT_CORRETOR, SET_CORRETOR} from '../mutations'
import {putCorretorFormData, putCorretorFormSchema, setCorretorFormData, setCorretorFormSchema} from '../formValidations'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'

interface QueryCorretoresProps {
  variables: any
}

export function QueryGetCorretores({variables}: QueryCorretoresProps) {
  const {data, loading, error} = useQuery<TypesGetCorretoresResponse>(GET_CORRETORES, {
    variables: {...variables},
  })
  return {data, loading, error}
}

export function QueryGetCorretoresByID({variables}: QueryCorretoresProps) {
  const {data, loading} = useQuery<TypesGetCorretorById>(GET_CORRETOR_BY_ID, {
    variables: {...variables},
  })
  return {data, loading}
}

export function MutationDeleteCorretores() {
  const [deleteCorretor, {loading, data, error}] = useMutation<TypesDeleteCorretorResponse>(
    DELETE_CORRETOR,
    {
      refetchQueries: [GET_CORRETORES, GET_CORRETOR_BY_ID],
    }
  )

  async function HandleDeleteCorretores({variables}: QueryCorretoresProps) {
    try {
      const result = await deleteCorretor({
        variables: {...variables},
        refetchQueries: [GET_CORRETORES],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteCorretores, loading, error, data}
}

export function MutationCreateCorretores() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<setCorretorFormData>({
    resolver: zodResolver(setCorretorFormSchema),
  })

  const [setCorretorBody, {error, loading, data: DataSetCorretor}] =
    useMutation<TypesSetCorretorResponse>(SET_CORRETOR, {
      refetchQueries: [GET_CORRETORES, GET_CORRETOR_BY_ID],
    })

  async function FormCorretor(data: setCorretorFormData) {
    delete data.id
    await setCorretorBody({
      variables: {
        corretor: {...data},
      },
    })

  }

  return {register, handleSubmit, FormCorretor, loading, errors, error, DataSetCorretor}
}


export function MutationPutCorretores() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<putCorretorFormData>({
    resolver: zodResolver(putCorretorFormSchema),
  })

  const [setCorretorBody, {error, loading, data: DataSetCorretor}] =
    useMutation<TypesPutCorretorResponse>(PUT_CORRETOR, {
      refetchQueries: [GET_CORRETORES, GET_CORRETOR_BY_ID],
    })

  async function FormCorretor(data: putCorretorFormData) {
    const id = data.id
    delete data.id
    await setCorretorBody({
      variables: {
        putCorretorId: parseInt(id),
        corretor: {...data},
      },
    })

  }


  return {register, handleSubmit, FormCorretor, loading, errors, error, DataSetCorretor}
}
