
const SwitchSituationComponent = ({ label = '', onChange, situation }) => {

  const handleSwitchChange = () => {
    onChange(!situation);
  };

  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckChecked"
        checked={situation} 
        onChange={handleSwitchChange}
      />
      {label && (
        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
          {label}
        </label>
      )}
    </div>
  );
};

export default SwitchSituationComponent;
