import { AsideDefault } from "../../../../_metronic/layout/components/aside/AsideDefault";
import { CardHeaderNavigation } from "./profile/components/CardHeader";
import {  TabLogsAcessoRecurso,TabFuncionarios,TabInfosProfile,TabProdutos } from "./profile";
import { Footer } from "../../../../_metronic/layout/components/Footer";
import { HeaderWrapperFluid } from "../../../../_metronic/layout/components/header/HeaderWrapperFluid";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "../../../components/UI/Loader/Loader";
import clsx from "clsx";
import { useLayout } from "../../../../_metronic/layout/core";
import { useEffect } from "react";
import { DrawerComponent } from "../../../../_metronic/assets/ts/components";
import { QueryGetEmpresaByID } from "../../graphql/services/Empresas";


export function AdminEditEmpresas() {
  const { id: IdEmpresa } = useParams<{ id: string }>()

  const { classes } = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])


  const { data, error, loading } = QueryGetEmpresaByID({ variables: { getEmpresaByIdId: parseInt(IdEmpresa) } });

  if (!data) {
    return (
      <>
        <AsideDefault />
        <HeaderWrapperFluid />

        <div className='wrapper d-flex flex-column flex-row-fluid'>
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>

                <CardHeaderNavigation data={data} />

                {/*begin::Card*/}
                <div className="card" style={{ width: '100%' }}>
                  {/*begin::Card header*/}
                  <div className="card-header  border-0 pt-6">
                    {/*begin::Card title*/}
                    <div className="inboxContent" style={{ width: '100%' }}>
                      {/* begin::Row */}
                      <div className='row gy-5 gx-xl-8'>
                        <div className='col-xl-12'>
                          <div className="tab-content" id="tabContent">
                          <TabInfosProfile data={data} />
                          </div>
                        </div>
                      </div>
                      {/* end::Row */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    )
  }

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />

      <div className='wrapper d-flex flex-column flex-row-fluid'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>

              <CardHeaderNavigation data={data} />

              {/*begin::Card*/}
              <div className="card" style={{ width: '100%' }}>
                {/*begin::Card header*/}
                <div className="card-header  border-0 pt-6">
                  {/*begin::Card title*/}
                  <div className="inboxContent" style={{ width: '100%' }}>
                    {/* begin::Row */}
                    <div className='row gy-5 gx-xl-8'>
                      <div className='col-xl-12'>
                        <div className="tab-content" id="tabContent">
                          <TabInfosProfile data={data} />
                          <TabFuncionarios  />
                          <TabProdutos  />
                          <TabLogsAcessoRecurso />
                        </div>
                        <br /><br />
                      </div>
                    </div>
                    {/* end::Row */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}