import { z } from "zod"

export const setProdutoFormSchema = z.object({
    nome: z.string(),
    subtitulo: z.string(),
    desconto: z.number(),
    descricao: z.string(),
    valor: z.number(),
    tipo_produto: z.string(),
    situacao: z.number()
})

export type setProdutoFormData = z.infer<typeof setProdutoFormSchema>

export const putProdutoFormSchema = z.object({
    id: z.number(),
    nome: z.string(),
    subtitulo: z.string(),
    desconto: z.number(),
    descricao: z.string(),
    valor: z.number(),
    tipo_produto: z.string(),
    situacao: z.number()
})

export type putProdutoFormData = z.infer<typeof putProdutoFormSchema>