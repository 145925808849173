import { InputHTMLAttributes, useRef } from 'react'
import { ErrorMessageComponent } from '../../../backoffice/components/UI/ErrorMessage'
import { FormControl, InputGroup } from 'react-bootstrap'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  register?: any
  error?: any
  mostrarSenha: boolean
  toggleMostrarSenha: any
}

export function FormInputAndLabelIcon({  register, error, mostrarSenha, toggleMostrarSenha }: InputProps) {
  const myRef = useRef(null)
  return (
    <div className='form-group'>
      <InputGroup className='py-3'> 
        <FormControl
          id={'Password'}
          size="lg"
          type={mostrarSenha ? 'text' : 'password'}
          placeholder="Password"
          className="pass-wrapper form-control-solid"
          ref={myRef}
          refs={myRef}
          {...register}
        />
        <InputGroup.Text className='p-0 m-0 no-border-radius'>
          <button className="btn btn-icon btn-outline-success btnEye no-border-radius" type="button" onClick={toggleMostrarSenha}>
            {mostrarSenha ?  <AiFillEyeInvisible size={22} /> : <AiFillEye size={22} />}
          </button>
        </InputGroup.Text>
      </InputGroup>

      {error && <ErrorMessageComponent message={error} />}
    </div>
  )

}
