import {GoogleMap} from '@react-google-maps/api'
import {useCallback, useState} from 'react'
import {latLng} from '../../util'

type PropsContextMenu = {
  latLng: latLng
  onClickMap?: any
  children?: React.ReactChild
}

export const GoogleMaps: React.FC<PropsContextMenu> = ({latLng, onClickMap, children}) => {
  const [map, setMap] = useState(null)

  const containerStyle = {
    height: '400px',
  }

  const center = latLng.lat ?  {
    lat: latLng.lat,
    lng: latLng.lng
  } :  {
    lat: -23.56834983242366,
    lng: -46.65739491734664,
  }

  const onLoad = useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={onClickMap}
    >
      {children}
    </GoogleMap>
  )
}
