// import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {GetUsuarioGrupoServicoByUserChosen} from '../../../../components/UI/GetUsuarioGrupoServicoByUser'

type Props1Item = {
  text: string
  onChangeUsuarioGrupo: any
  expandAll: any
  onBusca:any
  idCidade:any
}

export const Filtro: React.FC<Props1Item> = ({text, onChangeUsuarioGrupo, expandAll,onBusca,idCidade}) => {
 
 
  
 
  return (
    <>
      <div className='row no-print pb-5 mt-10 '>
        <div className='col-md-4'>
          <GetUsuarioGrupoServicoByUserChosen
            idGrupo={0}
            onChange={onChangeUsuarioGrupo}
            idCidade={idCidade}
          ></GetUsuarioGrupoServicoByUserChosen>
        </div>

        <div className='col-md-4'>
          <input type='text' className='form-control' name='Busca' placeholder='Busca' onKeyUp={onBusca}></input>
        </div>
        {/* <div className='col-md-4'>
          <div className='card-toolbar pull-right'>
            <button className='btn btn-primary showall' onClick={expandAll}>
              + Expandir todos
            </button>
          </div>
        </div> */}
      </div>
    </>
  )
}
