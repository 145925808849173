import {Text, View, StyleSheet} from '@react-pdf/renderer'
import {EmailsExtended} from './Proprietario/EmailsExtended'
import {BasicData} from './Proprietario/BasicaData'
import {PhonesExtended} from './Proprietario/PhonesExtended'
import {AddressesExtended} from './Proprietario/AddressesExtended'
import {RelatedPeople} from './Proprietario/RelatedPeople'
import {RelatedPeopleEmails} from './Proprietario/RelatedPeopleEmails'
import {RelatedPeoplePhones} from './Proprietario/RelatedPeoplePhones'
import {Ph3aData} from './Proprietario/Ph3aData'
import {dadosTabelas} from '../../../pages/pessoas/components/dadosTabelas'
import {styles} from './style'
// import { dadosTabelas } from './EstrtuturaBDC'

// const styles = StyleSheet.create({
//   table: {
//     width: '100%',
//     fontSize: 11,
//     fontWeight: 'light',
//   },
//   row: {
//     display: 'flex',
//     flexDirection: 'row',
//     borderTop: '1px solid #EEE',
//     paddingTop: 8,
//     paddingBottom: 5,
//   },
//   rowErro: {
//     display: 'flex',
//     flexDirection: 'row',
//     borderTop: '1px solid #EEE',
//     paddingTop: 8,
//     paddingBottom: 5,
//     textAlign: 'center',
//   },
//   title: {
//     marginTop: 25,
//     marginBottom: 15,
//     fontSize: 16,
//     fontWeight: 'bold',
//     borderBottomWidth: 1,
//     borderBottomColor: '#2B8E57',
//   },
//   header: {
//     borderTop: 'none',
//     backgroundColor: '#dbdbdb',
//     paddingHorizontal: 10,
//   },
//   bold: {
//     fontWeight: 'black',
//   },
//   rowDefault: {
//     width: '30%',
//   },
//   rowCenter: {
//     width: '30%',
//     textAlign: 'center',
//   },
//   decimal: {
//     width: '20%',
//     textAlign: 'center',
//   },
//   currency: {
//     width: '20%',
//     textAlign: 'center',
//   },
// })
export const Proprietarios = ({proprietarios}) => {
  if (proprietarios) {
    return (
      <View style={styles.table} break>
        <Text style={styles.title}>Proprietários</Text>

        {proprietarios.GetImovelProprietarios.length === 0 ? (
          <Text style={styles.rowErro}>Nenhum registro encontrado</Text>
        ) : (
          ''
        )}
        {proprietarios.GetImovelProprietarios.map((row, i) => {
          let typeDoc = row.cpf ? 'cpf' : 'cnpj'
          return (
            <View key={i}>
              {row.ph3a_data || row.basic_data ? (
                <Ph3aData
                  resultPeapleData={row.ph3a_data}
                  result={row}
                  typeDoc={typeDoc}
                ></Ph3aData>
              ) : (
                <></>
              )}

              {/* {row.basic_data ? (
                <BasicData
                  data={row.basic_data}
                  estrutura={dadosTabelas[typeDoc]['BasicData']}
                ></BasicData>
              ) : (
                ''
              )} */}
              {/* {row.phones_extended ? (
                <PhonesExtended
                  data={row.phones_extended}
                  estrutura={dadosTabelas[typeDoc]['PhonesExtended']}
                ></PhonesExtended>
              ) : (
                ''
              )}
              {row.emails_extended ? (
                <EmailsExtended
                  data={row.emails_extended}
                  estrutura={dadosTabelas[typeDoc]['EmailsExtended']}
                ></EmailsExtended>
              ) : (
                ''
              )}
              {row.addresses_extended ? (
                <AddressesExtended
                  data={row.addresses_extended}
                  estrutura={dadosTabelas[typeDoc]['AddressesExtended']}
                ></AddressesExtended>
              ) : (
                ''
              )} */}

              {/* {typeDoc === 'cpf' ? (
                <>
                  {row.related_people ? (
                    <RelatedPeople
                      data={row.related_people}
                      estrutura={dadosTabelas[typeDoc]['RelatedPeople']}
                    ></RelatedPeople>
                  ) : (
                    ''
                  )}
                  {row.related_people_emails ? (
                    <RelatedPeopleEmails
                      data={row.related_people_emails}
                      estrutura={dadosTabelas[typeDoc]['RelatedPeopleEmails']}
                    ></RelatedPeopleEmails>
                  ) : (
                    ''
                  )}
                  {row.related_people_phones ? (
                    <RelatedPeoplePhones
                      data={row.related_people_phones}
                      estrutura={dadosTabelas[typeDoc]['RelatedPeoplePhones']}
                    ></RelatedPeoplePhones>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )} */}
            </View>
          )
        })}
      </View>
    )
  }
}
