import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { MutationPutCupom, QueryGetCupomByID } from '../../../graphql';
import { ErrorMessageComponent } from '../../../components/UI/ErrorMessage';
import { Loader } from '../../../../components/UI/Loader/Loader';
import { FormInputAndLabel } from '../../../../components/UI/Inputs/FormInputAndLabel';
import { FormInputNumericFormat } from '../../../../components/UI/Inputs/FormInputNumericFormat';

interface situacaoTerrenoProps {
  id: number;
  show: boolean;
  closeModal: any;
}

export function EditForm({ id, show, closeModal }: situacaoTerrenoProps) {
  const [tipo, setTipo] = useState<string>("");

  let { data, loading: LoadingGet } = QueryGetCupomByID({ variables: { getCupomByIdId: id } });

  const {
    FormPutCupom, loading, handleSubmit, register, errors, DataPutCupom, error,
    control, setValue, setError
  } = MutationPutCupom();

  useEffect(() => {
    if (data && data.GetCupomById) {
      setTipo(data.GetCupomById.tipo);
    }
  }, [DataPutCupom, closeModal, error, id, show, data]);

  const formatDate = (date) => {
    if (!date) return ''; // Retorna uma string vazia se a data for nula ou indefinida

    // Converte a data para o formato "YYYY-MM-DD"
    return new Date(date).toISOString().split('T')[0];
  };

  const onSubmit = async (data) => {
    console.log(data)
    FormPutCupom(data).then((result) => {
      Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })
  }

  if (loading || LoadingGet || !data) {
    return (
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Cupons:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader text={'caregando...'}></Loader>
        </Modal.Body>
      </Modal>
    );
  }

  const dataExpiracaoDefault = data.GetCupomById.data_expiracao
    ? new Date(data.GetCupomById.data_expiracao).toISOString().split('T')[0]
    : null;

  return (
    <>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Cupons:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type='number'
              hidden
              className='form-control'
              defaultValue={data.GetCupomById.id}
              {...register('id', { required: true, valueAsNumber: true })}
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputAndLabel
                    label='Codigo'
                    className='form-control required form-control-solid'
                    placeholder='Codigo'
                    defaultValue={data.GetCupomById.codigo}
                    register={register("codigo")}
                    error={errors ? errors.codigo?.message : ''}
                  />
                </div>
              </div>

              <div className="row">
                <div className='col-md-6'>
                  <div className='mb-6'>
                    <FormInputNumericFormat
                      control={control}
                      label='Desconto(%)'
                      defaultValue={data.GetCupomById.desconto}
                      className='form-control form-control-solid text-right'
                      placeholder='00.00'
                      register={register('desconto')}
                      error={errors ? errors.desconto?.message : ''}
                      onValueChange={(e) => {
                        setValue('desconto', e.floatValue)
                      }}
                    />
                  </div>
                </div>
                
                <div className='col-md-6'>
                  <div className='mb-6'>
                    <label className='col-form-label'>Tipo</label>
                    <select
                      className="form-control  form-control-solid"
                      value={tipo}
                      onChange={(e) => {
                        setTipo(e.target.value);
                        setValue('tipo', String(e.target.value));

                        if (e.target.value === "1") {
                          setValue('data_expiracao', ''); 
                        }
                      }}
                    >
                      <option value="" disabled>Selecione</option>
                      <option value="1">Uso único</option>
                      <option value="2">Data de expiração</option>
                    </select>
                  </div>
                </div>

                {tipo === "1" && (
                  <>
                    {errors.tipo && <ErrorMessageComponent message={errors.tipo?.message} />}
                    {errors.usado && <ErrorMessageComponent message={errors.usado?.message} />}
                  </>
                )}
              </div>

              {tipo === "2" && (
                <div className="row">
                  <div className={tipo === '2' ? 'col-sm-12 col-lg-6 ' : 'col-lg-12'}>
                    <div className='mb-6'>
                      <label className='col-form-label'>Cupom já utilizado?</label>
                      <select className="form-control form-control-solid"  {...register("usado")} >
                        <option value="1" >Sim</option>
                        <option value="2" >Não</option>
                      </select>
                      {errors.usado && <ErrorMessageComponent message={errors.usado?.message} />}
                    </div>
                  </div>
                  <div className={tipo === '2' ? 'col-sm-12 col-lg-6 ' : 'd-none'}>
                    <div className='mb-6'>
                      <label className='col-form-label'>Data de Expiração:</label>
                      <input
                        type='date'
                        className="form-control form-control-solid"
                        {...register("data_expiracao", { shouldUnregister: true })}
                        defaultValue={formatDate(dataExpiracaoDefault)}
                        onChange={(e) => {
                          const selectedDate = e.target.value ? new Date(e.target.value) : null;
                          console.log(selectedDate);
                          // setValue('data_expiracao', selectedDate);
                        }}
                      />
                      {errors.usado && <ErrorMessageComponent message={errors.data_expiracao?.message} />}
                    </div>
                  </div>
                </div>
              )}

              <hr />
              <div className="row mt-4">
                <div className="col-12">
                  <label htmlFor="">Pedidos registrados com esse cupom</label><br />
                  <input type="text" className="form-control text-center" disabled placeholder="Não existem pedidos registrados com esse cupom." />
                </div>
              </div>

              <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={() => closeModal()}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

