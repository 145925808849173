import '../global.css'
import {FilterContextProvider} from './context/FilterContext'
import {DataContextProvider} from './context/DataContext'
import {InteligenciMercado} from './InteligenciaMercado'

export function Mercado() {
  return (
    <>
      <FilterContextProvider>
        <DataContextProvider>
          <InteligenciMercado></InteligenciMercado>
        </DataContextProvider>
      </FilterContextProvider>
    </>
  )
}
