import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useEffect, useState } from 'react'
import { MutationCreateCondicaoComercial } from '../../../../../../graphql/services/CondicaoComercial'
import { Loader } from '../../../../../../components/UI/Loader/Loader'
import { FormInputCurrencyFormat } from '../../../../../../components/UI/Inputs/FormInputCurrencyFormat'
import { FormTextareaAndLabel } from '../../../../../../components/UI/Inputs/FormTextareaAndLabel'
import { FormInputPercentFormat } from '../../../../../../components/UI/Inputs/FormInputPercentFormat'
import { MutationSetLogAcessoRecurso } from '../../../../../../graphql/services/LogAcesso'

interface Props {
  closeModal: any
  show: boolean
  idTerreno: string;
}

export function CreateForm({ closeModal, show, idTerreno }: Props) {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()
  const [valorTotal,setValorTotal] = useState(0)
  const [valorDinheiro,setDinheiro] = useState(0)
  const [valorPermutaFisica,setValorPermutaFisica] = useState(0)
  const [valorPermutaFinanceira,setValorPermutaFinanceira] = useState(0)
  
  const {
    FormCondicaoComercial, loading, handleSubmit, register,
    errors, error, DataCondicaoComercial, control, setValue
  } = MutationCreateCondicaoComercial()

  const onSubmit = async (data) => {
    FormCondicaoComercial(data).then((result) => {
      FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'condicao-comercial', action: 'create', url: '/meus-imoveis/details/' + idTerreno + '#condicao-comercial' });
      Swal.fire('Sucesso!', 'Condição criada com sucesso.', 'success')
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })
  }

  // Tratativa de loading
  if (loading) {
    return (
      <Loader text={'caregando...'}></Loader>
    )
  }


  return (
    <Modal centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Nova Condição Comercial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id='kt_account_profile_details_form'
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
        >
         <div className='card-body'>
              <div className='row mb-6'>
               
                <input
                  type='number'
                  hidden
                  className='form-control'
                  defaultValue={parseInt(idTerreno)}
                  {...register('id_terreno', { required: true, valueAsNumber: true
                    }
                  )}
                />

                <label className='col-md-4 col-form-label fw-bold fs-6'>
                  Dinheiro:
                </label>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-lg-12 '>
                      <FormInputCurrencyFormat
                        control={control}
                        className='form-control form-control-solid text-right'
                        placeholder='R$ 00,00'
                        register={register('dinheiro_valor')}
                        error={errors ? errors.dinheiro_valor?.message : ''}
                        onValueChange={(e) => {
                          setValue('dinheiro_valor', e.floatValue)
                          setDinheiro(e.floatValue)
                        }}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-md-4 col-form-label fw-bold fs-6' htmlFor='permuta_fisica'>
                  Permuta Fisica:
                </label>

                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-lg-12 '>
                      <FormInputPercentFormat
                      control={control}
                      className='form-control form-control-solid text-right'
                      placeholder='R$ 00,00'
                      register={register('permuta_fisica_pecentual')}
                      error={errors ? errors.permuta_fisica_pecentual?.message : ''}
                      onValueChange={(e) => {
                        setValue('permuta_fisica_pecentual', e.floatValue)
                        setValorPermutaFisica(e.floatValue)
                      } } label={''}              showlabel={false}             />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-md-4 col-form-label fw-bold fs-6' htmlFor='permuta_financeira'>
                  Permuta Financeira:
                </label>

                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-lg-12 '>
                      <FormInputPercentFormat
                      control={control}
                      className='form-control form-control-solid text-right'
                      placeholder='0%'
                      register={register('permuta_financeira_percentual')}
                      error={errors ? errors.permuta_financeira_percentual?.message : ''}
                      onValueChange={(e) => {
                        setValue('permuta_financeira_percentual', e.floatValue)
                        setValorPermutaFinanceira(e.floatValue)
                      } } label={''}  showlabel={false}                     />

                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-md-4 col-form-label fw-bold fs-6' htmlFor='valor_total'>
                  Valor Total:
                </label>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-lg-12 '>

                      <FormInputCurrencyFormat
                        control={control}
                        disabled
                        className='form-control form-control-solid text-right'
                        placeholder='R$ 00,00'
                        register={register('valor_total')}
                        error={errors ? errors.valor_total?.message : ''}
                        onValueChange={(e) => {
                          setValue('valor_total', e.floatValue)
                        }}
                        defaultValue={valorTotal}
                      />

                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-md-4 col-lg-4 col-form-label fw-bold fs-6'>
                  Observações:
                </label>
                <div className='col-lg-8 '>
                  <FormTextareaAndLabel
                    rows={6}
                    className='form-control form-control-solid'
                    placeholder='Descreva suas observações...'
                    register={register("observacoes")}
                    error={errors ? errors.observacoes?.message : ''}
                  />
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
              </div>
            </div>
          <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
            <button className='btn btnSecondary  pull-right ' type='button' onClick={closeModal}>
              Fechar
            </button>
            <button disabled={loading} type='submit' className='btn btn-success mx-2'>
              Salvar
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
