import { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { ModalCreateTypologies } from './create';
import { PageInfo } from '../../../../../components/UI/PaginationComponents';
import { deleteTypology, getTypologiesByID } from '../../../../../../services/http/urbit-gis/routes';
import { useParams } from 'react-router-dom';
import { TypologyData } from '../../../../../../services/http/urbit-gis/routes/types';
import Swal from 'sweetalert2';
import { useEmpreendimentos } from '../../context/useEmpreendimentos';
import { ModalUpdateTypologies } from './update';

export function TypologiesTable() {
  const { id: idEmpreendimento } = useParams<{ id: string }>();
  const { typologies, fetchTypologies, loading, error, refetchTypologies } = useEmpreendimentos();
  const [paginacao, setPaginacao] = useState({ pagina: 1, quantidade: 10 });

  useEffect(() => {
    if (idEmpreendimento && !isNaN(parseInt(idEmpreendimento))) {
      fetchTypologies(parseInt(idEmpreendimento), paginacao.pagina, paginacao.quantidade);
    }
  }, [idEmpreendimento, paginacao.pagina, paginacao.quantidade, fetchTypologies]);

  const handleDelete = async (typologyId: number) => {
    const confirmed = await Swal.fire({
      title: 'Deseja deletar?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    });

    if (confirmed.isConfirmed) {
      Swal.fire({
        title: 'Enviando Informações...',
        text: 'Aguarde um momento, estamos processando sua solicitação.',
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const result = await deleteTypology(typologyId); // Certifique-se de ter `deleteTypology` importado e implementado
        if (result) {
          Swal.fire('Sucesso!', 'Tipologia deletada com sucesso.', 'success');
          if (idEmpreendimento) {
            refetchTypologies(parseInt(idEmpreendimento)); // Atualizar a lista de tipologias após exclusão
          }
        } else {
          Swal.fire('Erro!', 'Não foi possível deletar a tipologia.', 'error');
        }
      } catch (error) {
        Swal.fire('Erro!', 'Houve um erro ao tentar deletar a tipologia.', 'error');
      }
    }
  };
  return (
    <div className='table-responsive card-xxl-stretch mb-5 mb-xl-8'>
      <div className='w-100 all-end'>
        <ModalCreateTypologies />
      </div>
          <table className='table table-striped table-row-dashed gy-2 gs-6 table-row-bordered table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className=' text-muted'>
                <th className='min-w-150px'>Tipo</th>
                <th className='min-w-140px'>Área</th>
                <th className='min-w-140px'>Unidades</th>
                <th className='min-w-140px'>Quartos</th>
                <th className='min-w-140px'>Vagas</th>
                <th className='min-w-140px'>Banheiros</th>
                <th className='min-w-140px'>Suítes</th>
                <th className='min-w-140px all-end'>Ações</th>
              </tr>
            </thead>
            <tbody>
              {typologies ? typologies.length > 0 ? (
                typologies.map((response, index) => (
                  <tr key={response.id_typologies_orulo || index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <span>{response.type}</span>
                      </div>
                    </td>
                    <td className='text-start'>
                      <span>{response.private_area}m²</span>
                    </td>
                    <td className='text-start'>
                      <span>{response.total_units}</span>
                    </td>
                    <td className='text-start'>
                      <span>{response.bedrooms}</span>
                    </td>
                    <td className='text-start'>
                      <span>{response.parking}</span>
                    </td>
                    <td className='text-start'>
                      <span>{response.bathrooms}</span>
                    </td>
                    <td className='text-start'>
                      <span>{response.suites}</span>
                    </td>
                    <td className='all-end'>
                      <ModalUpdateTypologies id={response.id} />
                      <button
                        onClick={() => handleDelete(response.id)}
                        type='button'
                        className='btn btn-icon btn-danger btn-sm'
                      >
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className='text-center'>
                    {idEmpreendimento
                      ? 'Nenhum registro encontrado'
                      : 'Esse recurso será habilitado posteriormente após criação do Empreendimento.'}
                  </td>
                </tr>
              ) 
            :<></>
            }
            </tbody>
          </table>
    </div>
  );
}
