import {useRef} from 'react'
import {MapboxLayer} from './MapBox/MapBox'
import {AllGeoJSON} from '@turf/turf'
import {encodeJsonIntersect, getLayer} from '../MapLayers/Layer'
import html2canvas from 'html2canvas'
import {Endereco} from '../Types'

export function Mapa1({
  data,
  setMapImageExport,
  isocronas,
  geometria,
  endereco,
}: {
  data: any
  setMapImageExport: any
  isocronas: any
  geometria: any
  endereco: Endereco
}) {
  const refLegenda = useRef<HTMLDivElement>(null)

  let json = geometria as AllGeoJSON
  const layerConfig = getLayer('view_ibge_explorer_renda_mensal_domiciliar')
  const jsonEncoded = encodeJsonIntersect(json)
  let intersect = `${layerConfig.config.tile}layer=${layerConfig.name}_operacao&l1=${layerConfig.name}&s=${layerConfig.schema}&l2=&g1=${jsonEncoded}`
  layerConfig.config.data = intersect

  async function onload(map) {
    html2canvas(map.target.getCanvasContainer(), {
      useCORS: true,
      scale: 2,
      width: 700,
      height: 650,
    }).then((canvas) => {
      let canvasImage = canvas.toDataURL('image/png', 1.0)
      setMapImageExport(canvasImage)
    })
  }

  return (
   <MapboxLayer
      latLng={[endereco.latitude, endereco.longitude]}
      zoom={14.5}
      onLoad={onload}
      style={{width: 700, height: 650}}
      layers={[
        {
          tiles: [layerConfig.config.data],
          source: 'view_ibge_explorer_renda_mensal_domiciliar_operacao',
          paint: layerConfig.style,
          layout: {},
          paintText: {
            'text-color': '#000000', // Cor do texto
          },
          type: 'vector',
          typeLayer: 'fill',
          legandaTitle: 'Renda',
        },
        {
          data: isocronas,
          paint: {
            'line-color': [
              'case',
              ['==', ['get', 'value'], 300],
              '#3e6b2e',
              ['==', ['get', 'value'], 600],
              '#f10808',
              '#f10808',
            ],
            'line-width': 2,
            'line-dasharray': [3, 5],
          },
          paintText: {
            'text-color': '#000000', // Cor do texto
          },
          type: 'geojson',
          layout: {},
          typeLayer: 'line',
        },
      ]}
      inputRefLeganda={refLegenda}
      areaInfluencia={geometria}
    ></MapboxLayer>
  )
}
