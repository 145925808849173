import {OverlayTrigger, Popover } from 'react-bootstrap';

const popover = (title:any,content:any)=> {
  return (
  <Popover id="opover-basic">
    {/* <Popover as="h3">
      <Title>{title}</Popover.Title>
    <Popover.Content>
    {content}

    </Popover.Content> */}
  </Popover>)

  };

const OverlayPopover = ({keyChield, children, title, content}) => {
  

  return(
    <OverlayTrigger target="body" key={keyChield+'overlayTriger'} id={keyChield+'overlayTriger'} trigger={["click","hover"]} placement="bottom" overlay={popover(title, content)}   {...title}  {...content}    >
     {children}
    
    </OverlayTrigger>
    )
};
  
export default OverlayPopover;