import {InputHTMLAttributes, useEffect, useState} from 'react'
import {ErrorMessageComponent} from '../../../backoffice/components/UI/ErrorMessage'
import {NumberFormatBase} from 'react-number-format'
import {Controller} from 'react-hook-form'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  register?: any
  error?: any
  control: any
  onValueChange: any
  showlabel?:boolean
}

export function FormInputPercentFormat({
  label,
  register,
  error,
  control,
  onValueChange,
  showlabel=true,
  ...props
}: InputProps) {
  const [value, setValue] = useState(null)

  useEffect(() => {
    let valor = (Number(props.defaultValue) *100).toLocaleString('pt-br',{style: 'decimal', minimumFractionDigits: 0,maximumFractionDigits: 0})
    setValue(valor)
  }, [props.defaultValue])

  const {name} = register

  const currencyFormatter = (formatted_value: any) => {
    if (!Number(formatted_value)) return '0,00%'
    let valor = Number(formatted_value / 100).toLocaleString('pt-br',{style: 'decimal', minimumFractionDigits: 2,maximumFractionDigits: 2})
    return valor + ' %'
    }

  const keyDown = (e: any) => {
    if (e.code === 'Backspace' && !e.target.value) {
      e.preventDefault()
    }
    if (e.code === 'Backspace' && e.target.value < 1000) {
      e.preventDefault()
      setValue(0)
    }
  }

  const handleChange = (v: any) => {
    // setValue(v.floatValue)
    if (onValueChange) {
        onValueChange({...v, floatValue:  v.floatValue ? v.floatValue/100 : 0})
    }
  }
  
  function posicionarNofinal(e){

    var len = e.target.value.length;
              
    // Mostly for Web Browsers
    if (e.target.setSelectionRange) {
        e.target.focus();
        e.target.setSelectionRange(len, len);
    } else if (e.target.createTextRange) {
        var t = e.target.createTextRange();
        t.collapse(true);
        t.moveEnd('character', len);
        t.moveStart('character', len);
        t.select();
    }
  }
  if (showlabel){
    return (
      <div className='form-group'>
        <label className='col-form-label'>{label}</label>
  
        <Controller
          control={control}
          name={name}
          render={({field}) => {
            return (
              <NumberFormatBase
                value={value}
                className={props.className}
                format={currencyFormatter}
                onValueChange={handleChange}
                onKeyDown={keyDown}
                placeholder={props.placeholder}
                onClick={posicionarNofinal}
                onFocus={posicionarNofinal}
              />
            )
          }}
          {...props}
        />
  
        {error && <ErrorMessageComponent message={error} />}
      </div>
    )
  }else{
return (<><Controller
          control={control}
          name={name}
          render={({field}) => {
            return (
              <NumberFormatBase
                value={value}
                className={props.className}
                format={currencyFormatter}
                onValueChange={handleChange}
                onKeyDown={keyDown}
                placeholder={props.placeholder}
                onClick={posicionarNofinal}
                onFocus={posicionarNofinal}
              />
            )
          }}
          {...props}
        />
  
        {error && <ErrorMessageComponent message={error} />}
        </>
        )
  }
  
}
