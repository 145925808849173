import {useContext, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ItensImoveisProps, Tipologia} from '../components/Types'
import {BiAddToQueue, BiLinkExternal} from 'react-icons/bi'
import {DataContext} from '../context/DataContext'
import { formatDate, formatNumber } from '../../explorer/components/common'

export function ModalDados() {
  const [show, setShow] = useState(false)
  const {data} = useContext(DataContext)
  function closeModal() {
    setShow(false)
  }

  function openModal() {
    setShow(true)
  }
  
  return (
    <>
      <button
        onClick={openModal}
        className='btn btn-light-primary btn-sm d-block me-2 w-100'
        style={{width: '100% !important'}}
      >
        <BiAddToQueue className='fs-2 me-1' />
        Tabela de Dados
      </button>

      <Modal centered animation show={show} onHide={() => closeModal()} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>Tabela de empreendimentos</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-xl' style={{width: '100%'}}>
          <div className='row'>
            <div className='col-md-12'>
              <table className='table  gs-5 '>
                <thead>
                  <tr>
                    <th>
                      <input type='checkbox' />
                    </th>

                    <th>Nome</th>
                    <th>Incorporadora</th>
                    <th>Área Máxima</th>
                    <th>Área Mínima</th>
                    <th>Data Lançamento</th>
                    <th>Data Preço</th>
                    <th>Endereço</th>
                    <th>Estoque</th>
                    <th>Preço médio m²</th>
                    <th>Situação</th>
                    <th>Tipo</th>
                    <th>Total Unidades</th>
                    <th>Vendas</th>
                    <th>% Vendas </th>
                    <th>Site</th>
                  </tr>
                </thead>
                <tbody>
                  {data ? (
                     data.map((item: ItensImoveisProps) => {
                      return item.tipologias.map((itemTipologia:Tipologia)=>{
                        let background = item.removed || itemTipologia.computed === false ? '#ffc3c3' : 'white'
                        return (

                           <tr key={item.id} style={{backgroundColor: background, border:1, borderColor:'#333'}}>
                            <td>
                               <input type='checkbox' />
                             </td>
                             <td>{item.nome}</td>
                             <td>{item.nome_incorporador}</td>
                             <td className='text-right'>{formatNumber(itemTipologia.private_area,'decimal')}</td>
                             <td>{item.area_privativa_minima}</td>
                             <td>{formatDate(item.data_lancamento)}</td>
                             <td>{formatDate(item.data_preco)}</td>
                             <td>{item.endereco}</td>
                             <td  className='text-center'>{itemTipologia.stock}</td>
                            <td  className='text-right'>{ formatNumber(itemTipologia.original_price / itemTipologia.private_area,'currency') }</td>
                             <td>{item.situacao}</td>
                             <td>{itemTipologia.type}</td>
                             <td>{itemTipologia.total_units}</td>
                             <td>{itemTipologia.total_units - itemTipologia.stock}</td>
                             <td>{itemTipologia.vendas_perc_tip}</td>
                             <td>
                               <a href={item.webpage} target='_blank' rel='noreferrer'>
                                 <BiLinkExternal></BiLinkExternal>
                               </a>
                           </td>
                           </tr>
                         )
                      })
                   
                     })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
