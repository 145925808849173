import { BiPlus } from "react-icons/bi";
import { CreateForm } from "./partials/CreateForm";
import { Buttons } from "./partials/Buttons";
import { useState } from "react";
import { Loader } from "../../../../../components/UI/Loader/Loader";
import { QueryGetImovelCorretores } from "../../../../../graphql/services/ImovelCorretores";
import { useParams } from "react-router-dom";

type Props = {
  param: string
}

export function TabCorretores({param}: Props) {
  const { id: idTerreno } = useParams<{ id: string }>()


  const { data } = QueryGetImovelCorretores({ variables: { idTerreno: parseInt(idTerreno) } })

  // console.log(data)

  const [showCreate, setShowCreate] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [id, setId] = useState(null)

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }
  function CloseModalEdit() {
    setShowEdit(false)
    setId(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setId(id)
  }

  if (!data) {
    return (
      <Loader text={'caregando...'}></Loader>
    )
  }

  return (
    <div
    className={`tab-pane fade ${param === 'corretores' ? 'show active' : ''}`}
     id="corretores" role="tabpanel" aria-labelledby="corretores">
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Corretores</h3>
          <div className='card-toolbar'>
            <button
              className='btn btn-sm btn-light-primary'
              onClick={(e) => {
                e.preventDefault()
                OpenModalCreate(null)
              }}
            >
              <BiPlus className='fs-3'></BiPlus>
              Novo Corretor
            </button>

          </div>
        </div>
        <div className='card-body p-0 py-3'>
          <div className='table-responsive'>
            <table className='table table-striped table-row-dashed  gs-6 gy-4 table-row-bordered  table-hover table-row-gray-300 align-middle'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Nome</th>
                  <th className='min-w-140px'>Email</th>
                  <th className='min-w-120px'>Telefone</th>
                  <th className='min-w-100px text-end'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {
                  data ? data.GetImovelCorretor.map((Response, item) => {
                    return (
                      <tr key={Response.corretor.id}  >
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {Response.corretor.nome}
                              </span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {Response.corretor.empresa}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {Response.corretor.email}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {Response.corretor.telefone}
                          </span>
                        </td>

                        <td>
                          <Buttons setIdEdit={OpenModalEdit} id={Response.id} />
                        </td>
                      </tr>
                    )
                  })
                    :
                    ''
                }

              </tbody>
            </table>
          </div>
        </div>
        {/* {showEdit ? <EditForm id={id} show={showEdit} closeModal={CloseModalEdit}></EditForm> : <></>}*/}
        {showCreate ? <CreateForm closeModal={CloseModalCreate} show={showCreate}></CreateForm> : <></>} 
      </div>
    </div>
  )
}