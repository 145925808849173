type Props1Item = {}

export const HeaderUrbit: React.FC<Props1Item> = () => {
  return (
    <div className='d-flex flex-column flex-column-fluid text-right'>
      <h2 className='name'>Urbit Serviços de Informática Ltda.</h2>
      <div>CNPJ: 31.687.326/0001-62</div>
      <div>R. Dr. Antônio Bento, 746 - Santo Amaro, São Paulo - SP, 04750-001</div>
      <div>Whatsapp: (11) 94813-4631</div>
      <div>
        <a href='mailto:contato@urbit.com.br'>contato@urbit.com.br</a>
      </div>
    </div>
  )
}
