import {formatPhone} from '../../../components/util'

export const PhonesExtended = ({data, estrutura}) => {
  let dateReturn = null

  if (typeof data['Result'] !== 'undefined') {
    dateReturn = data['Result'][0]['ExtendedPhones']['Phones']
  } else {
    dateReturn = data[0]['ExtendedPhones']['Phones']
  }

  return (
    <>
      {/* <h3 className='pt-10'>{estrutura.titulo}</h3> */}

      {dateReturn?.map((item: any, index: number) => {
        var number = item['AreaCode'] + '' + item.Number
        number = formatPhone(number)

        return (
          <div key={index}>
            <p className='fs-6'>
              Telefone: {number} ({item.Type}) <br />
              {/* Whatsapp: {item.IsWhatsapp ? 'Sim' : 'Não'} */}
            </p>
            {/* <hr /> */}
          </div>
        )
      })}

      {/* <table className="table fs-6">
        <thead>
          <tr key={-1}>
            {estrutura['colunsItens'].map((column, index) => {
              return (
                <th key={index} className='fs-bold'>
                  {column}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {dateReturn?.map((item, index) => {
            return (
              <tr key={index}>
                {estrutura['colunsItens'].map((column, index2) => {
                  return <td key={index2}>{item[column]}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table> */}
    </>
  )
}
