import {useForm} from 'react-hook-form'
import {
  FormSetUsuarioAdminFormData,
  FormSetUsuarioAdminFormSchema,
  FormSetUsuarioFormData,
  FormSetUsuarioFormSchema,
  putUsuarioFormData,
  putUsuarioFormSchema,
} from '../formValidations/User'
import {zodResolver} from '@hookform/resolvers/zod'
import {useMutation, useQuery} from '@apollo/client'
import {
  TypesPutUsuarioByIDResponse,
  TypesSetUsuario,
  GetProfile,
  TypesGetEmpresaUsuario,
} from '../type'
import {PUT_USUARIO, SET_USUARIO, SET_USUARIO_ADMIN} from '../mutations'
import {GET_EMPRESA_USUARIO, GET_PROFILE} from '../query'

interface QueryProps {
  variables: any
}

export function GetProfileData() {
  const {data, error, loading} = useQuery<GetProfile>(GET_PROFILE)

  return {data, error, loading}
}

export function GetEmpresaUsuario({variables}: QueryProps) {
  const {data, error, loading} = useQuery<TypesGetEmpresaUsuario>(GET_EMPRESA_USUARIO, {
    variables: {...variables},
  })

  return {data, error, loading}
}

export function MutationSetUsuario() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: {errors},
  } = useForm<FormSetUsuarioFormData>({
    resolver: zodResolver(FormSetUsuarioFormSchema),
  })

  const [bodyRequest, {error, loading, data: DataSetUsuario}] =
    useMutation<TypesSetUsuario>(SET_USUARIO)

  async function FormSetUsuario(data: FormSetUsuarioFormData) {
    return await bodyRequest({
      variables: {
        usuario: {
          name: data.nome,
          email: data.email,
          password: data.senha,
          telefone: data.telefone,
        },
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormSetUsuario,
    loading,
    errors,
    error,
    DataSetUsuario,
    setError,
    setValue,
  }
}

export function MutationSetUsuarioAdmin() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: {errors},
  } = useForm<FormSetUsuarioAdminFormData>({
    resolver: zodResolver(FormSetUsuarioAdminFormSchema),
  })

  const [bodyRequest, {error, loading, data: DataSetUsuarioAdmin}] =
    useMutation<TypesSetUsuario>(SET_USUARIO_ADMIN)

  async function FormSetUsuarioAdmin(data: FormSetUsuarioAdminFormData) {
    await bodyRequest({
      variables: {
        usuario: {
          name: data.nome,
          email: data.email,
          telefone: data.telefone,
          visualizacao: data.visualizacao,
          edicao: data.edicao,
          exclusao: data.exclusao,
        },
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormSetUsuarioAdmin,
    loading,
    errors,
    error,
    DataSetUsuarioAdmin,
    setError,
    setValue,
  }
}

export function MutationPutUsuario() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<putUsuarioFormData>({
    resolver: zodResolver(putUsuarioFormSchema),
  })

  const [bodyRequest, {loading, data: DatePutUser, error}] =
    useMutation<TypesPutUsuarioByIDResponse>(PUT_USUARIO, {
      refetchQueries: [GET_PROFILE],
    })

  async function FormUpdateUser(data: putUsuarioFormData) {
    await bodyRequest({
      variables: {
        usuario: {...data},
      },
    })
  }

  return {register, handleSubmit, FormUpdateUser, loading, errors, error, DatePutUser}
}
