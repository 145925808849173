import {useState} from 'react'
import {Footer} from '../../../_metronic/layout/components/Footer'
import {TableAvaliacoes} from './components/TableAvaliacoes'
import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import {HeaderWrapperFluid} from '../../../_metronic/layout/components/header/HeaderWrapperFluid'
import '../global.css'

export function Avaliacoes() {
  const [filter, setFilter] = useState([
    {
      field: ['origem_lead'],
      operator: 'IN',
      value: ['explorer', 'avaliacoes'],
    },
  ])

  const [updateList, setUpdateList] = useState(false)
  function handleInputChange(event) {
    const {value} = event.target
    const filterArray = []

    if (value) {
      filterArray.push({
        field: ['endereco'],
        operator: 'LIKE',
        value: value,
      })
    }

    setFilter(filterArray)
  }

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid title='Avaliações' />

      <div className='wrapper d-flex flex-column flex-row-fluid'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div className='container-fluid'>
              <div
                className='py-6  d-flex align-items-center justify-content-between'
                // id='kt_header_container'
              >
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  data-kt-swapper='true'
                  data-kt-swapper-mode='prepend'
                  data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Minhas Avaliações</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href='/' className='text-muted'>
                        Dashboard
                      </a>
                    </li>
                    <li className='breadcrumb-item text-muted'>Avaliações</li>
                  </ul>
                </div>
                <div className='col-3'>
                  <div className='d-flex align-items-center ms-3'>
                    <input
                      type='text'
                      className='form-control me-2'
                      placeholder='Pesquisar por Endereço'
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                <div className='col-xl-12'>
                  <TableAvaliacoes filtro={filter} setUpdateList={setUpdateList} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
