import { useMutation, useQuery } from "@apollo/client";
import { GET_EXPLORER_LAYER, GET_EXPLORER_LAYER_BY_ID } from "../querys";
import { TypesDeleteExplorerLayerResponse, TypesGetExplorerLayerByIDResponse, TypesGetExplorerLayersResponse, TypesPutExplorerLayerResponse, TypesSetExplorerLayerResponse } from "../types";
import { DELETE_EXPLORER_LAYER, PUT_EXPLORER_LAYER, SET_EXPLORER_LAYER } from "../mutation";
import { putExplorerLayerFormData, putExplorerLayerFormSchema, setExplorerLayerFormData, setExplorerLayerFormSchema } from "../../formValidations";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

interface Props {
  variables: any;
}

export function QueryGetExplorerLayer({ variables }: Props) {
  const { data, loading, error } = useQuery<TypesGetExplorerLayersResponse>(GET_EXPLORER_LAYER, {
    variables: { ...variables }
  })

  return {data, loading, error}
}

export function QueryGetExplorerLayerByID({ variables }: Props) {
  const { data, error, loading } = useQuery<TypesGetExplorerLayerByIDResponse>(GET_EXPLORER_LAYER_BY_ID, {
    variables: { ...variables }
  })

  return { data, error, loading }
}

export function MutationDeleteExplorerLayer() {
  const [deleteExplorerLayer, { loading, error }] = useMutation<TypesDeleteExplorerLayerResponse>(DELETE_EXPLORER_LAYER, {
    refetchQueries: [GET_EXPLORER_LAYER]
  })

  async function HandleDeleteExplorerLayer({ variables }: Props) {
    try {
      const result = await deleteExplorerLayer({
        variables: { ...variables },
        refetchQueries: [GET_EXPLORER_LAYER],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return { HandleDeleteExplorerLayer, loading, error }
}

export function MutationCreateExplorerLayer() {

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<setExplorerLayerFormData>({
    resolver: zodResolver(setExplorerLayerFormSchema)
  });

  const [setExplorerLayerBody, { loading, data, error }] = useMutation<TypesSetExplorerLayerResponse>(SET_EXPLORER_LAYER, {
    refetchQueries: [GET_EXPLORER_LAYER]
  })

  async function submitForm(data: setExplorerLayerFormData) {
    return await setExplorerLayerBody({
      variables:  { 
        explorerLayer:data, 
      }
    })
  };


  return { register, handleSubmit, submitForm, loading, errors, data, error,setValue }
}

export function MutationEditExplorerLayer() {

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<putExplorerLayerFormData>({
    resolver: zodResolver(putExplorerLayerFormSchema)
  });

  console.log(errors)

  const [setExplorerLayerBody, { loading, data, error }] = useMutation<TypesPutExplorerLayerResponse>(PUT_EXPLORER_LAYER, {
    refetchQueries: [GET_EXPLORER_LAYER, GET_EXPLORER_LAYER_BY_ID]
  })

  async function submitForm(data: putExplorerLayerFormData) {
    return await setExplorerLayerBody({
      variables:  { explorerLayer:data }
    })
  };


  return { register, handleSubmit, submitForm, loading, errors, data, error,setValue }
}