/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useEffect} from 'react'
import { MutationPutEmpreendimentoMassa, QueryGetEmpreendimentoMassaById } from '../../../../../../../graphql/services/EmpreendimentoMassa'
import { Modal } from 'react-bootstrap'
import { Loader } from '../../../../../../../components/UI/Loader/Loader'
import Swal from 'sweetalert2'
import { FormInputAndLabel } from '../../../../../../../components/UI/Inputs/FormInputAndLabel'
import { MutationSetLogAcessoRecurso } from '../../../../../../../graphql/services/LogAcesso'

type Props = {
  show: boolean
  closeModal: any
  id_empreendimento: number
  id: number
}
const EditForm: React.FC<Props> = ({ id, show, closeModal, id_empreendimento }) => {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()
  const { data, loading: LoadingGet } = QueryGetEmpreendimentoMassaById({
    variables: {
      massa: {
        id: id,
        id_empreendimento: id_empreendimento
      }
    }
  })

  const {
    loading,
    handleSubmit,
    register,
    errors,
    error,
    DataPutEmpreendimentoMassa,
    FormEmpreendimentoPutMassa
  } = MutationPutEmpreendimentoMassa()

  useEffect(() => {
    if (DataPutEmpreendimentoMassa) {
      closeModal()
      Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
    }
  }, [DataPutEmpreendimentoMassa, error, loading, closeModal])


  if (loading || LoadingGet) {
    <Modal centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Massa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        return <Loader text={'caregando...'}></Loader>

      </Modal.Body>
    </Modal>
  }

  const onSubmit = async (data) => {
    FormEmpreendimentoPutMassa(data).then((result) => {
      FormSetLogAcessoRecurso({ferramenta: 'empreendimentos', funcionalidade: 'massa ', action: 'update', url: '/empreendimentos/details/'+ id_empreendimento +'#massa'});
      Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })
  }

  return (
    <>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Massa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              className='form-control form-control-solid'
              placeholder='id'
              defaultValue={id}
              hidden={true}
              {...register('id', { required: true, valueAsNumber: true })}
            />
            <input
              className='form-control form-control-solid'
              placeholder='id'
              defaultValue={id_empreendimento}
              hidden={true}
              {...register('id_empreendimento', { required: true, valueAsNumber: true })}
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputAndLabel
                    label='Nome'
                    type='text'
                    defaultValue={data ? data.GetEmpreendimentoMassaById.nome : ''}
                    className='form-control form-control-solid'
                    placeholder='Nome da Massa'
                    register={register('nome')}
                    error={errors ? errors.nome?.message : ''}
                  />
                </div>
              </div>

              <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={closeModal}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export { EditForm }
