/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { QueryGetImovelProprietarios } from '../../../../../../../graphql/services/ImovelProprietarios'
import { Row } from './Row'
import { CreateForm } from '../../../../../../pessoas/components/CreateForm'
import { ImovelResponse } from '../../../../../../../graphql'

type Props = {
  id: number
  data: ImovelResponse
  setUpdateList: any
}

export const TableProrietariosImovel: React.FC<Props> = ({ id: idImovel, setUpdateList, data: dataImovel }) => {
  const {data, refetch} = QueryGetImovelProprietarios({ variables: { idImovel: idImovel } })
  const [showCreate, setShowCreate] = useState(false)
  const [id, setId] = useState(null)

  const [dataModal, setDataModal] = useState({})

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }

  useEffect(() => {
    setDataModal({
      idTerreno: idImovel ? String(idImovel) : "",
      id: idImovel ? String(idImovel) : "",
      cep: dataImovel.GetImovel.cep ? dataImovel.GetImovel.cep : "",
      endereco: dataImovel.GetImovel.endereco ? dataImovel.GetImovel.endereco : "",
      number: dataImovel.GetImovel.numero ? dataImovel.GetImovel.numero : null
    })
  }, [dataImovel, idImovel])

  function clickRow(idEmpreendimentoImovel) {
    if (document.getElementById(`detalhes_${idEmpreendimentoImovel}`).classList.contains('hide')) {
      document.getElementById(`detalhes_${idEmpreendimentoImovel}`).classList.remove('hide')
    } else {
      document.getElementById(`detalhes_${idEmpreendimentoImovel}`).classList.add('hide')
    }
  }

  if (!data) {
    return (
      <>
        <div className='card-header'>
          <h3 className='card-title'>Proprietários</h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Clique para adicionar o proprietário'
          >
            <button
              className='btn btn-sm btn-light-primary'
            >
              <BiPlus className='fs-3'></BiPlus>
              Novo Proprietário
            </button>
          </div>
        </div>
        <div className='table-responsive'>
          <table className='table table-striped table-row-dashed  gs-6 gy-4 table-row-bordered  table-hover table-row-gray-300 align-middle'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-50px'>Infos</th>
                <th className='min-w-150px'>Nome</th>
                <th className='min-w-150px'>E-mail</th>
                <th className='min-w-100px text-end'>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={4} >Carregando...</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }

  return (
    <>
      <div className='card-header'>
        <h3 className='card-title'>Proprietários</h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Clique para adicionar o proprietário'
        >
          <button
            className='btn btn-sm btn-light-primary'
            onClick={(e) => {
              e.preventDefault()
              OpenModalCreate(null)
            }}
          >
            <BiPlus className='fs-3'></BiPlus>
            Novo Proprietário
          </button>
        </div>
      </div>
      <div className='table-responsive'>
        <table className='table table-striped table-row-dashed  gs-6 gy-4 table-row-bordered  table-hover table-row-gray-300 align-middle'>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-50px'>Infos</th>
              <th className='min-w-150px'>Nome</th>
              <th className='min-w-150px'>E-mail</th>
              <th className='min-w-100px text-end'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data.GetImovelProprietarios.map((response, item) => {
              return (
                <Row
                  key={item}
                  response={response}
                  idImovel={idImovel}
                  clickRow={clickRow}
                  updateList={refetch}
                ></Row>
              )
            })}
          </tbody>
        </table>
      </div>
      {showCreate ? <CreateForm origin='Imoveis' params={dataModal} closeModal={CloseModalCreate} show={showCreate} /> : <></>}
    </>
  )
}
