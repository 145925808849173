import {useState} from 'react'
import {TwitterPicker,PhotoshopPicker, HuePicker} from 'react-color'
import {HexToRgb, RgbToHex} from '../../pages/explorer/components/common'

export const ColorPicker = ({hex, hsl, onChange}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(1)
  function getColocar(cor) {
    if (Array.isArray(cor)) {
      return {
        r: cor[0],
        g: cor[1],
        b: cor[2],
        a: cor[3],
      }
    } else {
      let corArray = HexToRgb(cor)
      if (corArray) {
        return {
          r: corArray[0],
          g: corArray[1],
          b: corArray[2],
          a: corArray[3],
        }
      }
      return {
        r: 241,
        g: 112,
        b: 19,
        a: 255,
      }
    }
  }
  let corRba = getColocar(hex)
  const defaultColocar = hex
    ? corRba
    : {
        r: 241,
        g: 112,
        b: 19,
        a: 255,
      }
  const [color, setColor] = useState(defaultColocar)

  const handleClick = () => {
    setDisplayColorPicker(1)
  }

  const handleChange = (color) => {
    setColor({r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: 100})
  }

  const styles = {
    default: {
      color: {
        width: '36px',
        height: '28px',
        borderRadius: '5px',
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  }
  return (
    <div className='p-2'>
      {/* <div className='d-flex flex hide' style={{display:'none'}}>
        <input
          type='text'
          name='color'
          className='form-control'
          defaultValue={RgbToHex(color.r, color.g, color.b, color.a)}
        ></input>
        <div style={styles.default.swatch} onClick={handleClick}>
          <div style={styles.default.color} />
        </div>
      </div> */}
      {displayColorPicker ? (
        <div className='mt-3'>
          {/* <div
              style={{position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'}}
              onClick={handleClose}
            /> */}
          {/* <SketchPicker 
              color={color}
              onChangeComplete={(color) => {
                handleChange(color)
                //         setBlockPickerColor(color.rgb)
                let cor = Object.values(color.rgb)
                cor.splice(3,3)
                // cor[3] = 255
                onChange(cor)
              }}
            /> */}

          {/* <PhotoshopPicker  color={color}
            onChangeComplete={(color) => {
              handleChange(color)
              //         setBlockPickerColor(color.rgb)
              let cor = Object.values(color.rgb)
              cor.splice(3, 3)
              // cor[3] = 255
              onChange(cor)
            }} >

          </PhotoshopPicker> */}
          {/* <TwitterPicker
            color={color}
            onChangeComplete={(color) => {
              handleChange(color)
              //         setBlockPickerColor(color.rgb)
              let cor = Object.values(color.rgb)
              cor.splice(3, 3)
              // cor[3] = 255
              onChange(cor)
            }}
          /> */}
          {/* <BlockPicker 
              color={color}
              onChangeComplete={(color) => {
                handleChange(color)
                //         setBlockPickerColor(color.rgb)
                let cor = Object.values(color.rgb)
                cor.splice(3,3)
                // cor[3] = 255
                onChange(cor)
              }}
            />
          <SliderPicker 
              color={color}
              onChangeComplete={(color) => {
                handleChange(color)
                //         setBlockPickerColor(color.rgb)
                let cor = Object.values(color.rgb)
                cor.splice(3,3)
                // cor[3] = 255
                onChange(cor)
              }}
            />*/}
          <HuePicker
            color={color}
            width='100%'

            onChangeComplete={(color) => {
              handleChange(color)

              //         setBlockPickerColor(color.rgb)
              let cor = Object.values(color.rgb)
              cor.splice(3, 3)
              // cor[3] = 255
              onChange(cor)
            }}
          /> 
        </div>
      ) : null}
    </div>
  )
}
