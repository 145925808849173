export const Layout = {
  mapaConfig: {},
  onMapaConfig: null,
  positionClick: {
    lat: null,
    lng: null,
    x: null,
    y: null,
  },
  setMapaConfig:function (mapaConfig){
    this.mapaConfig = mapaConfig
  },
  getMapaConfig:function (){
    return this.mapaConfig;
  },
  getComposicaoStatus:function (){
    return this.mapaConfig['modoComposicao'];
  },
  setShowClickInfo:(showhide: boolean, latLng?: any, info?: any) => {
    Layout.onMapaConfig({showClickInfo: showhide, latLng: latLng,  info: info})
  },
  setShowMenus: (showClickInfo: boolean, showContextMenu: boolean, info?: any, latLng?: any) => {
    let params = {
      showClickInfo: showClickInfo,
      showContextMenu: showContextMenu,
      showTerritorioMenu: false,
      showRelatorioMenu: false,
      info: info,
      latLng: latLng,
    }

    Layout.onMapaConfig(params)
  },
  setShowOffsetMenu: (showhide: boolean, info?: any) => {
      if (typeof info === 'undefined' || info === '') {
      Layout.onMapaConfig({showClickInfo: showhide})
    } else {
      Layout.onMapaConfig({showClickInfo: showhide, info: info})
    }
  },
  setShowTerritorioMenu: (showhide: boolean, latLng?: any) => {
    Layout.onMapaConfig({showTerritorioMenu: showhide, latLng: latLng})
  },
  setShowComposicaoMenu: (showhide: boolean, latLng?: any) => {
    Layout.onMapaConfig({showComposicaoMenu: showhide, latLng: latLng})
  },
  setShowRelatorioMenu: (showhide: boolean, latLng?: any, geom?:JSON) => {
    Layout.onMapaConfig({showRelatorioMenu: showhide, latLng: latLng, geom:geom})
  },
  setShowEstatisticasMenu: (showhide: boolean, latLng?: any, geom?:JSON) => {
    Layout.onMapaConfig({showEstatisticasMenu: showhide, latLng: latLng, geom:geom})
  },
  setShowContextMenu: (showhide: boolean, latLng?: any) => {
    Layout.onMapaConfig({showContextMenu: showhide, latLng: latLng})
  },

  setShowContextAVM: (showhide: boolean, latLng?: any) => {
    Layout.onMapaConfig({showAVM: showhide, latLng: latLng})
  },
  setShowGeometryMenu: (showhide: boolean, latLng?: any, geomList?:JSON) => {
    Layout.onMapaConfig({showGeometryContextMenu: showhide, latLng: latLng, geomList:geomList})
  },
  setModoComposicao: (showhide: boolean, latLng?: any) => {
    Layout.onMapaConfig({modoComposicao: showhide, latLng: latLng})
  },
  setShowGeometryMenuArea: (showhide: boolean, latLng?: any, geom?:JSON, layer?:string, geomList?:JSON) => {
    Layout.onMapaConfig({showGeometryContextMenuArea: showhide, 
      latLng: latLng,
      geom:geom,
      layerSelected:layer, 
      geomList:geomList
    })
  },
  getGeomClick(){
    
    console.log( this.mapaConfig)
    return []
  }
  // setComposicao: (composicao) => {
  //   Layout.onMapaConfig({composicao: composicao})
  // },
}
