import { gql } from '@apollo/client'

export const PUT_USUARIO = gql`
mutation Mutation($usuario: usuario!) {
  PutUsuario(usuario: $usuario) {
    id
    name
  }
}
`

export const SET_USUARIO_PRODUTO = gql`
  mutation SetUsuarioProdutos($usuarioProduto: usuarioProduto!) {
    SetUsuarioProdutos(usuarioProduto: $usuarioProduto) {
      id_produto
      id_user
      id
    }
  }
`


export const PUT_USUARIO_PRODUTO = gql`
mutation PutUsuarioProdutos($usuarioProduto: usuarioProduto!) {
  PutUsuarioProdutos(usuarioProduto: $usuarioProduto) {
    id
  }
}
`



export const DELETE_USUARIO_PRODUTO = gql`
mutation DeleteUsuarioProdutos($deleteUsuarioProdutosId: Float!) {
  DeleteUsuarioProdutos(id: $deleteUsuarioProdutosId) {
    id_user
  }
}
`

export const DELETE_USUARIO = gql`
mutation DeleteUsuario($deleteUsuarioId: Float) {
  DeleteUsuario(id: $deleteUsuarioId) {
    name
    id
  }
}
`


export const RECOVERY_PASSWORD = gql`
mutation PutTrocaSenha($userId: Float!, $newPassword: String!) {
  PutTrocaSenha(user_id: $userId, new_password: $newPassword) {
    name
  }
}
`