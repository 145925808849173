import React, { useState } from 'react'
// import { ImovelResponse, QueryGetImovel } from '../../../graphql';
import { LatLng } from '../../../components/util';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../modules/auth/components/Context/authcontext';
import { MapStatic } from '../../../components/UI/MapStatic';
import { PathToCoordinates } from '../../../components/UI/MapView';
import { PainelImobiliarioContent } from './partials/painel';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

export function PainelMercado() {
  const { lat } = useParams<{ lat: string }>()
  const { lng } = useParams<{ lng: string }>()
  const { token } = useParams<{ token: string }>()


  localStorage.setItem('token', token)
  localStorage.setItem('gtoken', token)

  const { authenticated } = useAuth()
  let isAuthenticated = false
  if (authenticated) {
    isAuthenticated = true
  }

  // const { data, error, loading } = QueryGetImovel({
  //   variables: {
  //     getImovelId: parseInt(id)
  //   }
  // })


  const [mapVisible, setMapVisible] = useState(false)

  function changeMapVisible() {
    setMapVisible(!mapVisible)
  }

  function onChangeMap(geom) {
    if (geom) {
      const geometria = PathToCoordinates(geom)
      const multiPolygon = {
        type: 'MultiPolygon',
        coordinates: [],
      }

      multiPolygon.coordinates.push([geometria])
      document.getElementById('marcacao')['value'] = JSON.stringify(multiPolygon)
    }
  }

  // if (!data) {
  //   return (
  //     <Loader text='Carregando...' />
  //   )
  // }
  const latlnG = new LatLng(Number(lat), Number(lng) )
  const latlng = []
  // const latlng = LatLng(data.GetImovel.latitude,data.GetImovel.longitude)
  // const latlng = (data.GetImovel.latitude,data.GetImovel.longitude)
  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='max-w-1200px py-10'>
          {isAuthenticated ? (
            <div className='card'>
              <div className='card-header'>
                <h3 className='card-title fw-bolder fs-2 text-black'>Painel de Mercado</h3>
                <div className='card-toolbar'>
                  <button onClick={changeMapVisible} className={!mapVisible ? 'btn btn-primary' : 'btn btn-danger'}>
                    {!mapVisible ? 'Ver no Mapa' : 'Fechar Mapa'}
                  </button>
                </div>

                {/* <div className="col-2 all-center-space">
                <label className='form-label me-5 all-center-center'> Distância</label>
                <select className='form-control' >
                  <option value="500">500m</option>
                  <option value="1000">1000m</option>
                  <option value="1500">1500m</option>
                  <option value="2000">2000m</option>
                </select>
                
              </div> */}
              </div>
              <div className='card-body p-6 pt-5'>
                <div className="row ">
                  {!mapVisible ? (
                    <>
                    </>
                  ) : (
                    <div className='col-lg-12 col-md-12 col-sm-12 rounded'>
                      {lat ? <MapStatic latLng={latlng} onChange={onChangeMap}></MapStatic> : ''}
                    </div>
                  )}
                  <PainelImobiliarioContent itens={[]} latLng={latlnG}></PainelImobiliarioContent>

                </div>
              </div>
            </div >

          ) : (
            <>
              <h1>Carregando</h1>
            </>
          )
          }
        </div>
      </div>
    </div>

  )
}