import {Dicionario} from '../../../../../../utils/Dicionario'
import {Explorer} from '../../Explorer'
import React, {useState} from 'react'
import colorbrewer from 'colorbrewer'

export function FillCategory({id, layer, defaultValue}) {
  type PropsCondig = {
    campo: string
    valores: any[]
  }

  const [config, setConfig] = useState<PropsCondig>({
    campo: null,
    valores: [],
  })

  const [steps, setSteps] = useState(3)
  // const [color, setColor] = useState()

  function quartiles(arr, numQuartis) {
    arr.sort((a, b) => a - b) // Ordena o array em ordem crescente

    const quartis = []
    for (let i = 0; i < numQuartis; i++) {
      const index = Math.floor((i * (arr.length - 1)) / numQuartis) // Índice do quartil
      quartis.push(arr[index]) // Adiciona o valor do quartil ao array
    }

    return quartis
  }

  function onClickColor(colorGroup: string, steps: number) {
    const start = Number(config.valores[0]['minimo'])
    const finish = Number(config.valores[0]['maximo'])
    const arr = Array.from({length: finish - start + 1}, (_, a) => a + start)
    let valores = quartiles(arr, Number(steps))
    const cores = colorbrewer[colorGroup][steps]
    let mergedArray = []

    if (valores.length !== cores.length) {
      console.error('Os arrays não têm o mesmo comprimento.')
    } else {
      for (let i = 0; i < valores.length; i++) {
        mergedArray.push(valores[i], cores[i])
      }
    }
    let fillcolor = [
      'interpolate',
      ['linear'],
      ['to-number', ['get', config.campo]],
      ...mergedArray,
    ]
    Explorer.changeStyle(layer.id, 'fill-color', fillcolor)
  }

  function onChangeSteps(event) {
    setSteps(event.target.value)
  }

  function onChangeCampo(event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) {
    let index = event.target.value
    let campos = layer.explorer_tiles[index]
    setConfig({
      campo: campos.select_columns,
      valores: JSON.parse(campos.data_return),
    })
  }

  return (
    <div className='item-config'>
      <div className='item-config-align'>
        <div
          data-bs-toggle='collapse'
          data-bs-target={`#collapseFilter_${id}`}
          aria-expanded='false'
          aria-controls={`collapseFilter_${id}`}
        >
          <div className='item-config-align-title'>Categorizar</div>
        </div>
        <div className='border-left-layers w-100 collapse' id={`collapseFilter_${id}`}>
          <div className=' pb-5'>
            <label> Campos</label>
            <select
              className='form-control'
              onChange={(e) => {
                onChangeCampo(e)
              }}
            >
              <option value=''>--selecione--</option>
              {
                // eslint-disable-next-line array-callback-return
                layer.explorer_tiles.map((campos, index) => {
                  if (campos.type_data === 'range') {
                    return (
                      <option value={index} key={index}>
                        {Dicionario.translate(campos.description)}
                      </option>
                    )
                  }
                })
              }
            </select>

            {config.campo ? (
              <>
                <label> Número de grupos</label>
                <select className='form-control' onChange={(e) => onChangeSteps(e)}>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                  <option value='7'>7</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                  <option value='10'>10</option>
                </select>
                <label> Cores</label>
                <ul className='color-palette-group'>
                  {Object.keys(colorbrewer).map((colorType, index1) => {
                    return typeof colorbrewer[colorType][steps] !== 'undefined' ? (
                      <li className='color-palette-outer' key={index1}>
                        <div className='color-range-palette__inner'>
                          {colorbrewer[colorType][steps].map((x, index) => {
                            return (
                              <div
                                key={index}
                                className='color-range-palette__block'
                                style={{background: x}}
                                onClick={(e) => {
                                  onClickColor(colorType, steps)
                                }}
                              ></div>
                            )
                          })}
                        </div>
                      </li>
                    ) : null
                  })}
                </ul>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
