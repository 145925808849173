import { BiExport } from 'react-icons/bi'
import { ImovelResponse } from '../../../../../graphql'
import { TableCondominios } from './partials/TableCondominios'
import { APiUrbit } from '../../../../../services/api/apiUrbit'
import { useEffect, useRef, useState } from 'react'
import * as XLSX from 'xlsx'
import { MutationSetLogAcessoRecurso } from '../../../../../graphql/services/LogAcesso'

type Props = {
  data: ImovelResponse
  param: string
}

interface Params {
  idTerreno?: string;
  id?: number | null;
  endereco?: string;
  cep?: string;
  number?: number;
  name?: string;
}

export const TabContribuintes: React.FC<Props> = ({ data, param }) => {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()
  const [result, setResult] = useState(null)
  const reportTemplateRef = useRef(null)
  const [dataModal, setDataModal] = useState<Params>({})

  useEffect(() => {
    async function loadCondominos(data) {
      const api = new APiUrbit()
      const result = await api.get(
        `municipio/sao-paulo/contribuintes/${data.GetImovel.longitude}/${data.GetImovel.latitude}?latitude=${data.GetImovel.latitude}&longitude=${data.GetImovel.longitude}`
      )

      if (result) {
        setResult(result)
      }
    }
    loadCondominos(data)
  }, [data])

  useEffect(() => {
    setDataModal({
      idTerreno: data.GetImovel.id ? String(data.GetImovel.id) : "",
      id: data.GetImovel.id ? data.GetImovel.id : null,
      cep: data.GetImovel.cep ? data.GetImovel.cep : "",
      endereco: data.GetImovel.endereco ? data.GetImovel.endereco : "",
      number: data.GetImovel.numero ? data.GetImovel.numero : null
    })
  }, [data])


  function exportTable() {
    var table_elt = reportTemplateRef.current
    let tbl = addWriteText(table_elt)
    const worksheet = XLSX.utils.json_to_sheet(tbl)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Tabela de Dados')
    XLSX.writeFile(wb, 'dados.xlsx')
    FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'contribuintes', action: 'update', url: '/empreendimentos/details/' + data.GetImovel.id + '#contribuintes' });

  }

  function addWriteText(table_elt) {
    var table = table_elt
    // Obtenha todas as linhas da tabela
    const rows = table.querySelectorAll('tbody tr')
    const headers = table.querySelectorAll('thead tr th')

    // Crie um array para armazenar os dados da tabela
    const data = []
    let headerx = []

    headers.forEach((rowHeader) => {
      headerx.push(rowHeader.innerText)
    })

    data.push(headerx)

    // Itere sobre as linhas da tabela e extraia os dados das células
    rows.forEach((row) => {
      const cells = row.querySelectorAll('td')
      const rowData = []
      cells.forEach((cell) => {
        // Use textContent para obter o texto da célula
        rowData.push(cell.innerText)
      })

      // Adicione a linha de dados ao array
      data.push(rowData)
    })

    return data
  }
  return (
    <div
      className={`tab-pane fade ${param === 'contribuintes' ? 'show active' : ''}`}
      id='contribuintes'
      role='tabpanel'
      aria-labelledby='contribuintes'
    >
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Contribuintes</h3>
          <div className='card-toolbar'>
            <button className='btn  btn-sm btn-light-primary btn-round  me-2' >
              <span className='svg-icon btn-icon svg-icon-4  ' onClick={exportTable}>
                <BiExport className='fs-1'></BiExport>
                <span className='btn-label pd-left-1'>Exportar XLS</span>
              </span>
            </button>
            {/* <button className='btn btnSuccess btn-round' style={{color: 'white'}}>
              <span className='svg-icon btn-icon svg-icon-4 me-2' >
                <BiSync></BiSync>
                <span className='btn-label pd-left-1'>Sincronizar</span>
              </span>

             
            </button> */}
          </div>
        </div>
        <div className='card-body p-0 pt-5'>
          <div className='row '>
            <div className='col-12'>
              <div className='row'>
                <TableCondominios
                  data={result}
                  reportTemplateRef={reportTemplateRef}
                  params={dataModal}
                ></TableCondominios>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
