import {useEffect, useState} from 'react'
import { APiUrbit } from '../../../../../../../services/api/apiUrbit'
import { AreaChartPercentual } from './AreaChartPercentual'


type Props = {
  id: string
}

type ChartProps = {
  series: any[]
  categories: any[]
}

export const Chart2: React.FC<Props> = ({id}) => {
  const [result, setresult] = useState<ChartProps>({
    series: [],
    categories: [],
  })

  
  let seriesTemp = []
  var dataset = []

  async function formatResult(result) {
    let labels = []
    // let seriesTemp = []
    // eslint-disable-next-line array-callback-return
    result.itens.map(function (x) {
      if (labels.indexOf(x.data_cadastro) === -1) {
        labels.push(x.data_cadastro)
      }
      // series.push(Number(x.vendas_perc))
    })

    // eslint-disable-next-line array-callback-return
    result.itens.map(function (x) {
    
      // if (typeof seriesTemp[x.data_cadastro]=='undefined' ){
      //     seriesTemp[x.data_cadastro] = []
      // }
      if (typeof seriesTemp[x.tipo] == 'undefined') {
        seriesTemp[x.tipo] = []
      }
      seriesTemp[x.tipo][x.data_cadastro] = ( ((Number(x.total_units ) - Number(x.stock)) / x.total_units) * 100).toFixed(0)
    })

    let series1 = []
    for (var i in seriesTemp) {
      series1 = []
      for (var j in seriesTemp[i]) {

        
        series1.push(seriesTemp[i][j])
      }
      dataset.push({
        name: i,
        data: series1,
      })
    }

    return {series: dataset, categories: labels}
  }
  
  useEffect(() => {
    async function getServico(idLancamento) {
      const api = new APiUrbit()
      const result = await api.get(
        // 'municipio/sao-paulo/fluxo-preco-lancamento/-46.6/-23.6/?id=' + idLancamento
        'service/price-flow-launches/-46.6/-23.6/?id=' + idLancamento
      )
      setresult(await formatResult(result))
      return result
    }

    getServico(id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  
  return ( 
    <>{result ? 
    <AreaChartPercentual
      series={result.series}
      categories={result.categories}
      title={'Percentual de venda por tipologia'}
      subtitle={'Percentual de venda por tipologia'}
    ></AreaChartPercentual> : 'Carregando....'}
    </>
  )
}
