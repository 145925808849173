import React from 'react'
import { PainelImobiliarioContent } from '../../../../explorer/components/Map/LayoutControl/Relatorios/painelImobiliario/painel'
import { ImovelResponse } from '../../../../../graphql'
import { LatLng } from '../../../../../components/util';

type Props = {
  data: ImovelResponse
  param: string
}
export const TabPainelMercado: React.FC<Props> = ({ data, param }) => {

  const latlng = new LatLng(data.GetImovel.latitude, data.GetImovel.longitude)

  return (
    <div
      className={`tab-pane fade ${param === 'painel-mercado' ? 'show active' : ''}`}
      id="painel-mercado" role="tabpanel" aria-labelledby="painel-mercado">
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Painel de Mercado</h3>
        </div>
        <div className='card-body p-6 pt-5'>
          <div className="row ">

            <PainelImobiliarioContent itens={[]} latLng={latlng}></PainelImobiliarioContent>
          </div>
        </div>
      </div>




    </div>
  )
}