/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {BiPlus} from 'react-icons/bi'
import PaginationComponent from '../../../components/UI/PaginationComponents'
import {CardTables} from '../../../components/UI/CardTables'
import LoadingTable from '../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../components/UI/CardTables/ErrorTable'
import {QueryGetAvmLeads} from '../../../graphql'
import {formatNumber} from '../../../components/util'
import {Buttons} from './Buttons'
import {Row} from './Row'
import {ModalAvm} from '../../../components/UI/Modals/modal-avm'
import {Endereco} from '../../explorer/components/common'

type Props = {
  setUpdateList: any
  filtro: any[]
}

export const TableAvaliacoes: React.FC<Props> = ({setUpdateList, filtro}) => {
  const [pagiancao, setPaginacao] = useState({pagina: 0, quantidade: 10})

  const {data, loading, error} = QueryGetAvmLeads({
    variables: {
      pagination: {
        pagina: pagiancao.pagina,
        quantidade: pagiancao.quantidade,
      },
      filtro: {
        fields: filtro,
      },
    },
  })

  const [showCreate, setShowCreate] = useState(false)
  const [id, setId] = useState(null)

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }

  function clickRow(idAvaliacao) {
    if (document.getElementById(`detalhes_${idAvaliacao}`).classList.contains('hide')) {
      document.getElementById(`detalhes_${idAvaliacao}`).classList.remove('hide')
    } else {
      document.getElementById(`detalhes_${idAvaliacao}`).classList.add('hide')
    }
  }

  const endereco: Endereco = {
    latitude: null,
    longitude: null,
    endereco_completo: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    cep: '',
  }
  return (
    <>
      <CardTables
        headerTitle={'Avaliações'}
        headerSubtTitle={`Até o momento ${
          data ? data.GetAvmLead.pageInfo.totalItems : 0
        } registrados.`}
        headerToolbar={<ModalAvm endereco={null} origem={'avaliacoes'}></ModalAvm>}
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetAvmLead.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({pagina: pagina, quantidade: quantidade})
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-50px'>Tipo de Avalição</th>
                <th className='min-w-50px'>Origem</th>
                <th className='min-w-50px text-center '>Quartos/Suítes</th>
                <th className='min-w-50px text-center '>Banheiros</th>
                <th className='min-w-50px text-center '>Vagas</th>
                <th className='min-w-50px text-center '>Area</th>
                <th className='min-w-50px text-center '>Ano de construção</th>
                <th className='min-w-150px text-center '>Valor Max</th>
                <th className='min-w-100px text-center '>Valor Med</th>
                <th className='min-w-100px text-center '>Valor Min</th>
                <th className='min-w-100px text-end'>Ações</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />

              {data ? (
                data.GetAvmLead.result.length > 0 ? (
                  data.GetAvmLead.result.map((response, item) => {
                    const anoAtual = new Date().getFullYear()
                    return (
                      <>
                        <tr key={`avaliacao_${response.id}`}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <p className='text-dark  fs-6'>
                                  {response.id_tipo_imovel === 2 ? 'Apartamento' : ''}
                                  {response.id_tipo_imovel === 1 ? 'Casa' : ''}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className='text-dark  d-block fs-6'>
                              {response.origem_lead === null ? 'Indefinido' : response.origem_lead}
                            </p>
                          </td>
                          <td>
                            <p className='text-dark text-center d-block fs-6'>
                              {response.quartos}/{response.suites}
                            </p>
                          </td>
                          <td>
                            <p className='text-dark text-center d-block fs-6'>
                              {response.banheiros}
                            </p>
                          </td>
                          <td>
                            <p className='text-dark text-center d-block fs-6'>{response.vagas}</p>
                          </td>
                          <td>
                            <p className='text-dark text-center  d-block fs-6'>{response.area}</p>
                          </td>
                          <td>
                            <p className='text-dark text-center d-block fs-6'>
                              {response.ano_construcao_cliente === anoAtual ? (
                                <span className='badge badge-light-success'>Lançamento</span>
                              ) : (
                                response.ano_construcao_cliente
                              )}
                            </p>
                          </td>
                          <td>
                            <p className='text-dark  text-center d-block fs-6'>
                              {formatNumber(response.result_maximo, 'currency_inteiro')}/m²
                              <br />
                              <small>
                                {formatNumber(
                                  response.result_maximo * response.area,
                                  'currency_inteiro'
                                )}
                              </small>
                            </p>
                          </td>
                          <td>
                            <p className='text-dark  text-center d-block fs-6'>
                              {formatNumber(response.result_medio, 'currency_inteiro')}/m²
                              <br />
                              <small>
                                {formatNumber(
                                  response.result_medio * response.area,
                                  'currency_inteiro'
                                )}
                              </small>
                            </p>
                          </td>
                          <td>
                            <p className='text-dark text-center d-block fs-6'>
                              {formatNumber(response.result_minimo, 'currency_inteiro')}/m²
                              <br />
                              <small>
                                {' '}
                                {formatNumber(
                                  response.result_minimo * response.area,
                                  'currency_inteiro'
                                )}
                              </small>
                            </p>
                          </td>
                          <td>
                            <Buttons clickRow={clickRow} id={response.id} />
                          </td>
                        </tr>
                        <Row features={response.features} id={response.id} />
                      </>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
      {/* {showCreate ? <CreateForm closeModal={CloseModalCreate} show={showCreate} /> : <></>} */}
    </>
  )
}
