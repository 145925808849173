import React, {useState} from 'react'
import {QueryGetEmpreendimentos} from '../../../graphql'
import Pagination from '../../../components/UI/Pagination'
import {Buttons} from './ButtonsEmpreendimentos'
import {Loader} from '../../../components/UI/Loader/Loader'
import {CreateEmpreendimentos} from '../create'
import {BiCompass} from 'react-icons/bi'
import {encodeUrl} from '../../../components/util'
import {useHistory} from 'react-router-dom'
import PaginationComponent from '../../../components/UI/PaginationComponents'
import {CardTables} from '../../../components/UI/CardTables'
import LoadingTable from '../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../components/UI/CardTables/ErrorTable'
type Props = {
  active: boolean
  filtro: any[]
}

const Table: React.FC<Props> = ({active, filtro}) => {
  let result

  if (active === true) {
    result = 0
  } else if (active === false) {
    result = 1
  }

  const [pages, setPages] = useState(0)
  const [selecionado, setselecionado] = useState([])
  const history = useHistory()

  const [pagiancao, setPaginacao] = useState({pagina: 0, quantidade: 10})

  const {data, loading, error} = QueryGetEmpreendimentos({
    variables: {
      pagination: {
        pagina: pagiancao.pagina,
        quantidade: pagiancao.quantidade,
      },
      filtro: {
        fields: filtro,
      },
      situation: result,
    },
  })

  function explorerOpen() {
    history.push('/explorer?eids=' + encodeUrl(selecionado.join(',')))
  }

  // Tratativa de loading
  if (!data) {
    return (
      <div className='card  px-10 py-6'>
        <div className='border-0'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='card-xxl-stretch '>
                <div className='card-header border-0    p-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Empreendimentos</span>
                    {/* <span className='text-muted mt-1 fw-bold fs-7'>
                      Até o momento  empreendimentos
                      registrados
                    </span> */}
                  </h3>
                  <div
                    className='card-toolbar all-end'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                  >
                    <button
                      className='btn btn-info me-5'
                      onClick={() => {
                        explorerOpen()
                      }}
                    ></button>
                    <CreateEmpreendimentos />
                  </div>
                  {/* <div className='all-end'></div> */}
                </div>
                {/* end::Header */}
                <div className='separator my-2'></div>
                <Loader text={'caregando...'}></Loader>
              </div>
              <div className='card-footer all-end'>
                <Pagination setPages={setPages} pages={pages} total={100} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const isChecked = (id: number) => {
    return selecionado.includes(id)
  }

  const handleCheckboxAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    if (!checked) {
      setselecionado([])
    } else {
      const checkdAllItens = data.GetEmpreendimentos.result.map((item) => {
        return item.id
      })
      setselecionado(checkdAllItens)
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const checked = event.target.checked
    if (checked) {
      setselecionado((prevselecionado) => [...prevselecionado, id])
    } else {
      setselecionado((prevselecionado) => prevselecionado.filter((item) => item !== id))
    }
  }
  return (
    <>
      <CardTables
        headerTitle={'Empreendimentos'}
        headerSubtTitle={`Até o momento ${
          data ? data.GetEmpreendimentos.pageInfo.totalItems : 0
        } empreendimentos registrados.`}
        headerToolbar={
          <button
            className='btn btn-info me-5'
            onClick={() => {
              explorerOpen()
            }}
          >
            <BiCompass className='fs-2'></BiCompass> Explorer
          </button>
        }
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetEmpreendimentos.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({pagina: pagina, quantidade: quantidade})
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className=''>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input widget-9-check'
                      type='checkbox'
                      value='all'
                      onChange={handleCheckboxAll}
                    />
                  </div>
                </th>
                <th className=''>Endereço</th>
                <th className=''>Bairro</th>
                <th className=''>Área</th>
                <th className=''>Zoneamento</th>
                <th className=''>Tags</th>
                <th className=' text-end'>Ações</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetEmpreendimentos.result.length > 0 ? (
                  data.GetEmpreendimentos.result.map((response) => {
                    let tags = []
                    if (response.tag && typeof response.tag === 'string') {
                      tags = response.tag.split(',')
                    }
                    return (
                      <tr key={response.id}>
                        <td className=''>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              checked={isChecked(response.id)}
                              onChange={(event) => handleCheckboxChange(event, response.id)}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {response.nome}
                              </span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {response.users.name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          {response.empreendimento_terreno?.length > 0
                            ? response.empreendimento_terreno[0].terreno.bairro
                            : '-'}{' '}
                        </td>
                        <td>{response.area_total_terreno}</td>
                        <td>
                          {response.empreendimento_terreno.length > 0
                            ? response.empreendimento_terreno[0].terreno.zoneamento
                            : '-'}{' '}
                        </td>
                        <td>
                          {tags.length > 0
                            ? tags.map(function (tag, y) {
                                return (
                                  <span className='badge text-bg-info me-2 mb-2' key={y}>
                                    {tag}
                                  </span>
                                )
                              })
                            : '-'}
                        </td>

                        <td>
                          <Buttons id={response.id} />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
    </>
  )
}

export {Table}
