import {create} from 'zustand'
import {GoogleMapsOverlay} from '../app/pages/explorer/components/Map'
import {Layer, layerStore} from './layerStore'
import uuid from 'react-uuid'

interface LatLng {
  lat: number
  lng: number
}

export interface MapGoogle {
  //   setDrawingMode(mode: any): unknown
  //   panTo(latLng: LatLng): void
  map: GoogleMapsOverlay | null
  draw: MapGoogle
  currentType: string
  center: LatLng
  legendaLayerSelected: number
  mode: string
  getMap: () => object
  setMap: (map: object) => void
  changeStyle: (style: string) => void
  loadDefaultLayers: () => void
  setDraw: (draw: MapGoogle) => void
  setLegendaActive: (index: number) => void
  loadLayer: () => void
  meassure: boolean
  onClickEnventFunc: {}
  getOnClickEventFuncs: () => any
  setOnClickEventFunc: (name: string, func: any[]) => void
  setOnMoveEventFunc: (name: string, func: any[]) => void
  removeOnClickEventFunc: (name: string) => void
  infoBox: {
    show: boolean
    componente: string
    info: string
  }
}

export const mapStore = create<MapGoogle>((set, get) => {
  return {
    map: null,
    draw: null,
    currentType: 'ligth',
    legendaLayerSelected: null,
    isLoading: false,
    mode:null,
    // RJ
    // center: {
    //   lat: -22.90844,
    //   lng: -43.17495
    // },
    center: {
      lat: -23.550628,
      lng: -46.633243,
    },
    // center: {
    //   lat: -23.58556,
    //   lng: -46.68596,
    // },
    infoBox: {
      show: false,
      componente: null,
      info: null,
    },
    meassure: false,
    onClickEnventFunc: {},
    onMoveEnventFunc: {},
    changeStyle: (style: string) => {
      const {map} = get()
      map._map.setMapTypeId(style)
    },
    removeOnClickEventFunc: (name) => {
      const itens = mapStore.getState().onClickEnventFunc
      for (const key in itens) {
        if (key === name) {
          delete itens[key]
        }
      }
    },
    removeOnMoveEventFunc: (name) => {
      const itens = mapStore.getState().onMoveEnventFunc
      for (const key in itens) {
        if (key === name) {
          delete itens[key]
        }
      }
    },
    setOnClickEventFunc: (name, func) => {
      mapStore.setState((store) => {
        const obj = {}
        obj[name] = func
        return {
          onClickEnventFunc: {...store.onClickEnventFunc, ...obj},
        }
      })
    },
    setOnMoveEventFunc: (name, func) => {
      mapStore.setState((store) => {
        const obj = {}
        obj[name] = func
        return {
          onMoveEnventFunc: {...store.onMoveEnventFunc, ...obj},
        }
      })
    },
    getOnMoveEventFuncs: () => {
      return mapStore.getState().onMoveEnventFunc
    },
    getOnClickEventFuncs: () => {
      return mapStore.getState().onClickEnventFunc
    },
    setMap: (map: object) => {
      // console.log(map)
      set({map: map})
      // mapStore.getState().loadLayer()
    },
    setMode: (mode: string) => {
      // console.log(map)
      set({mode: mode})
      // mapStore.getState().loadLayer()
    },
    getMode: () => {
      // console.log(map)
      return  mapStore.getState().mode;
      // mapStore.getState().loadLayer()
    },
    setInfoBox: (componente: string, info: string, show: boolean) => {
      set({
        infoBox: {
          componente: componente,
          info: info,
          show: show,
        },
      })
    },
    getInfoBox:()=>{
      return  mapStore.getState().infoBox;
    },
    getMap: () => {
      const {map} = get()
      return map
    },
    setDraw: (draw: any) => {
      set({draw: draw})

      // if (typeof Explorer.draw['setDrawingMode'] !== 'undefined') {
      //     Explorer.draw['setDrawingMode'](type)
      //   }
    },

    setDrawMode: (mode: any) => {
      // const {draw} = get()
      // draw.setDrawingMode(mode)
      // set({draw: draw})
      // if (typeof Explorer.draw['setDrawingMode'] !== 'undefined') {
      //     Explorer.draw['setDrawingMode'](type)
      //   }
    },
    setLegendaActive: (index: number) => {
      set({legendaLayerSelected: index})
    },
    loadLayer: () => {
      // const {map} = mapStore.getState().map
      // const {getLayers} = layerStore.getState()
      // const layers = getLayers()
      // const layerList = layers.map((item) => {
      //     const l = createLayer(item,'')
      //     // console.log(l)
      //     return l
      //   })
      //   if (map && layerList.length > 0){
      //     map.setProps({
      //       layers: [...layerList]
      //     })
      //   }
    },
    loadDefaultLayers: () => {
      const layer: Layer = {
        id: 0,
        tile: 'https://tile.urbit.com.br/tile/municipios/{z}/{x}/{y}.pbf?s=nacional',
        // style: JSON.parse('{"fill-color":"#ffffff","fill-opacity": 0,"stroke-color":"#d0d0d0" }'),
        visible: true,
        label: '',
        filter: {},
        data: '',
        distance: null,
        intersectId: null,
        intersect: null,
        operation: null,
        selectedPolygon: [],
        composite: [],
        // color: [0, 0, 0, 0],
        highlightColor: [0, 0, 0, 0],
        type: 'MVTLayer',
        style: {
          'fill-color': [255, 255, 255, 100],
          'fill-opacity': 1,
          'line-color': [255, 255, 255, 0],
          // 'fill-color': [255, 255, 255, 0],
          // 'line-color': [0, 0, 0]
        },
        opacity: 0.1,
        pickable: true,
        uuid: uuid(),
        nome: 'municipios',
        extrude: false,
        extruded: false,
        tooltip: undefined,
        positionZ: 0,
        icon: '',
        propriedades: [],
        parentId: '',
        descricao: '',
        source: '',
        explorer_layer_tags: undefined,
        explorer_tiles: undefined,
        schema_table: '',
      }
      // console.log(layer)
      layerStore.getState().addLayer(layer)
      // console.log(layer)
      // var municipio = createLayer(layer)
      // return municipio
    },
  }
})
