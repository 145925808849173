import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  TypesDeleteUsuario,
  TypesDeleteUsuarioProdutos,
  TypesGetFuncionarioByIDResponse,
  TypesGetUsuarioProdutoByIDResponse,
  TypesGetUsuariosResponse,
  TypesPutUsuarioProdutos,
  TypesRecoveryPassword,
  TypesSetUsuarioProdutos,
} from '../types'
import {
  GET_USUARIOS,
  ADMIN_GET_USUARIOS_PRODUTOS,
  GET_USUARIO_BY_ID,
  ADMIN_GET_USUARIO_PRODUTO_BY_ID,
  GET_FUNCIONARIO_BY_ID,
} from '../querys'
import { DELETE_USUARIO, DELETE_USUARIO_PRODUTO, PUT_USUARIO, PUT_USUARIO_PRODUTO, RECOVERY_PASSWORD, SET_USUARIO_PRODUTO } from '../mutation'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { putUsuarioProdutoFormData, putUsuarioProdutoFormSchema, setUsuarioProdutoFormData, setUsuarioProdutoFormSchema } from '../../formValidations'
import Swal from 'sweetalert2'
import { errorHandler } from '../../../graphql/errorHandler'
import { putUsuarioFormData, putUsuarioFormSchema, recoveryPasswordFormData, recoveryPasswordFormSchema } from '../../formValidations/User'
import { GET_EMPRESA_USUARIO, TypesGetUsuarioByID } from '../../../graphql'

interface UsuariosProps {
  variables: any
}

export function QueryGetUsuarios({ variables }: UsuariosProps) {
  const { data, error, loading } = useQuery<TypesGetUsuariosResponse>(GET_USUARIOS, {
    variables: { ...variables },
  })

  return { data, error, loading }
}

export function QueryGetUsuarioByID({ variables }: UsuariosProps) {
  const { data, error, loading } = useQuery<TypesGetUsuarioByID>(GET_USUARIO_BY_ID, {
    variables: { ...variables },
  })

  return { data, error, loading }
}
export function QueryGetFuncionarioByID({ variables }: UsuariosProps) {
  const { data, error, loading } = useQuery<TypesGetFuncionarioByIDResponse>(GET_FUNCIONARIO_BY_ID, {
    variables: { ...variables },
  })

  return { data, error, loading }
}

export function QueryGetUsuarioProdutoByID({ variables }: UsuariosProps) {
  const { data, error, loading } = useQuery<TypesGetUsuarioProdutoByIDResponse>(
    ADMIN_GET_USUARIO_PRODUTO_BY_ID,
    {
      variables: { ...variables },
    }
  )

  return { data, error, loading }
}

export function MutationPutUsuarioByID() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<putUsuarioFormData>({
    resolver: zodResolver(putUsuarioFormSchema),
  })

  const [bodyUsuario, { data, loading, error }] = useMutation<TypesSetUsuarioProdutos>(PUT_USUARIO, {
    refetchQueries: [
      ADMIN_GET_USUARIO_PRODUTO_BY_ID,
      ADMIN_GET_USUARIOS_PRODUTOS,
      GET_USUARIOS,
      ADMIN_GET_USUARIO_PRODUTO_BY_ID,
    ],
  })

  async function FormUpdateUser(data: putUsuarioFormData) {
    await bodyUsuario({
      variables: {
        usuario: { ...data },
      },
      refetchQueries: [
        ADMIN_GET_USUARIO_PRODUTO_BY_ID,
        ADMIN_GET_USUARIOS_PRODUTOS,
        GET_USUARIOS,
        ADMIN_GET_USUARIO_PRODUTO_BY_ID,
      ],
    })
  }

  return { register, handleSubmit, setValue, FormUpdateUser, loading, errors, error, data }
}

export function MutationSetUsuarioProduto() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<setUsuarioProdutoFormData>({
    resolver: zodResolver(setUsuarioProdutoFormSchema),
  })

  const [bodyUsuarioProdutos, { data, loading, error }] = useMutation<TypesSetUsuarioProdutos>(
    SET_USUARIO_PRODUTO,
    {
      refetchQueries: [ADMIN_GET_USUARIO_PRODUTO_BY_ID, ADMIN_GET_USUARIOS_PRODUTOS],
    }
  )

  async function FormUsuarioProduto(data: setUsuarioProdutoFormData) {
    return await bodyUsuarioProdutos({
      variables: {
        usuarioProduto: { ...data },
      },
      refetchQueries: [ADMIN_GET_USUARIO_PRODUTO_BY_ID, ADMIN_GET_USUARIOS_PRODUTOS],
    })
      // .then((result) => {
      //   if (!result.data) {
      //     let erroText = errorHandler(result.errors)
      //     Swal.fire('Erro!', erroText, 'warning')
      //   } else {
      //     Swal.fire('Sucesso!', 'Produto associado com sucesso!', 'success')
      //   }
      // })
      // .catch((err) => {
      //   Swal.fire('Erro!', 'Ocorreu um erro inesperado, tente novamente mais tarde.', 'warning')
      // })
  }

  return { register, handleSubmit, setValue, FormUsuarioProduto, loading, errors, error, data }
}

export function MutationPutUsuarioProduto() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<putUsuarioProdutoFormData>({
    resolver: zodResolver(putUsuarioProdutoFormSchema),
  })

  const [bodyUsuarioProdutos, { data, loading, error }] = useMutation<TypesPutUsuarioProdutos>(
    PUT_USUARIO_PRODUTO,
    {
      refetchQueries: [ADMIN_GET_USUARIO_PRODUTO_BY_ID, ADMIN_GET_USUARIOS_PRODUTOS],
    }
  )

  async function FormUsuarioProduto(data: setUsuarioProdutoFormData) {
   return  await bodyUsuarioProdutos({
      variables: {
        usuarioProduto: { ...data },
      },
      refetchQueries: [ADMIN_GET_USUARIO_PRODUTO_BY_ID, ADMIN_GET_USUARIOS_PRODUTOS],
    })
     
  }

  return { register, handleSubmit, setValue, FormUsuarioProduto, loading, errors, error, data }
}

export function MutationDeleteUsuario() {
  const [deleteUsuario, { loading, data, error }] = useMutation<TypesDeleteUsuario>(
    DELETE_USUARIO,
    {
      refetchQueries: [GET_EMPRESA_USUARIO],
    }
  )

  async function HandleDeleteUsuario({ variables }: UsuariosProps) {
    try {
      const result = await deleteUsuario({
        variables: { ...variables }
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return { HandleDeleteUsuario, loading, error, data }
}

export function MutationDeleteUsuarioProduto() {
  const [deleteUsuarioProduto, { loading, data, error }] = useMutation<TypesDeleteUsuarioProdutos>(
    DELETE_USUARIO_PRODUTO,
    {
      refetchQueries: [ADMIN_GET_USUARIO_PRODUTO_BY_ID, ADMIN_GET_USUARIOS_PRODUTOS],
    }
  )

  async function HandleDeleteUsuarioProduto({ variables }: UsuariosProps) {
    try {
      const result = await deleteUsuarioProduto({
        variables: { ...variables },
        refetchQueries: [ADMIN_GET_USUARIO_PRODUTO_BY_ID, ADMIN_GET_USUARIOS_PRODUTOS],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return { HandleDeleteUsuarioProduto, loading, error, data }
}

export function MutationRecoveryPassword() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<recoveryPasswordFormData>({
    resolver: zodResolver(recoveryPasswordFormSchema),
  })
  console.log(errors)
  const [recoveryBody, { loading, data, error }] = useMutation<TypesRecoveryPassword>(RECOVERY_PASSWORD)

  async function FormRecoveryPassword(data: recoveryPasswordFormData) {
    console.log('teste')
    try {
      const result = await recoveryBody({
        variables: {
          userId: data.userId,
          newPassword: data.newPassword
        },
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {
    FormRecoveryPassword, loading, error, data, register,
    handleSubmit,
    setValue, errors
  }
}

