import {useMutation} from '@apollo/client'
import {Endereco} from '../../ComponentsControl/EnderecoControl/endereco'
import {APiUrbit} from '../../../../../../services/api/apiUrbit'
import {SetImovelResponse} from '../../../../../../graphql/type/imovel'
import {SET_IMOVEL} from '../../../../../../graphql/mutations/imovel'
import {FaRegClipboard} from 'react-icons/fa'
import {GET_IMOVEIS} from '../../../../../../graphql'
import {errorHandler} from '../../../../../../graphql/errorHandler'
import {formatAdressSeachGoogleMapa} from '../../../common'
import {useEffect, useRef, useState} from 'react'
import {Loader} from '../../../../../../components/UI/Loader/Loader'
import {Button, Modal} from 'react-bootstrap'
import {ApiUrbit} from '../../../../../../../services/http/urbit'

interface Props {
  itens: []
  latLng: google.maps.LatLng
  municipio: any
}
interface Lote {
  ano_da_construcao_corrigido: number | null
  area_construida: number | null
  area_lote: number | null
  area_ocupada: number | null
  bairro: string
  cartorio: string
  cep: string
  cidade: string
  codlog: string
  complemento: string
  data_do_cadastramento: string
  distancia: number
  estado: string
  geom: string
  geom_closest_point: string
  id: string
  id_cidade: string
  id_estado: string
  logradouro: string
  lote: string
  matricula: string
  nome_contribuinte: string
  numero: string
  numero_contribuinte: string
  quadra: string
  quantidade_de_esquinas_frentes: number
  quantidade_de_pavimentos: number
  setor: string
  testada: string
  tipo_de_terreno: string
  uso_atual: string
  valor_do_m2_do_terreno: string
}
type Message = {
  error: boolean
  message: string
  data: any
}

export const ModalFicha: React.FC<Props> = ({itens, latLng, municipio}) => {
  const [lote, setLote] = useState<Lote>()
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState<Message>({error: null, message: null, data: null})
  const isMounted = useRef(true)

  useEffect(() => {
    // isMounted.current = true;
    async function getLote(latLng, municipio) {
      const lat = latLng.lat()
      const lng = latLng.lng()

      console.log('asdasdas')
      if (municipio.lote_fiscal === 1) {
        // const api = new APiUrbit()
        const result = await ApiUrbit.getServico(
          'lote-fiscal',
          lat,
          lng,
          municipio['municipio_url'],
          0
        )
        console.log(result)
        if (result.service !== 'error') {
          setLote(result.itens[0])
        } else {
          console.log(result)
        }
      }
    }

    if (isMounted.current) {
      getLote(latLng, municipio)
    }
    return () => {
      isMounted.current = false
      setLote(null)
    }
  }, [latLng, municipio])

  const [createImovel] = useMutation<SetImovelResponse>(SET_IMOVEL, {
    refetchQueries: [GET_IMOVEIS],
  })

  if (municipio.lote_fiscal === 1) {
    if (!lote) {
      return (
        <>
          <button className='btn btn-flex fs-1 flex-center bg-body btn-color-gray-700 btn-active-light-success w-md-auto h-40px px-0 px-md-6 disabled'>
            <FaRegClipboard className='fs-3 me-1' /> <span className='mt-1'>FICHA</span>
          </button>{' '}
        </>
      )
    }
  }

  // console.log(municipio.lote_fiscal )
  // if (!lote && municipio.lote_fiscal === 1) {
  //   return
  // }

  async function salvar(imovel) {
    await createImovel({
      variables: {
        imovel: imovel,
      },
    })
      .then(async (result) => {
        if (!result.data) {
          setMessage({
            error: true,
            message: errorHandler(result.errors),
            data: result.errors[0]['extensions']['exception']['data'],
          })
        } else {
          setMessage({
            error: true,
            message: 'Terreno cadastrado com sucesso Endereço:' + result.data.SetImovel.nome,
            data: result.data.SetImovel,
          })
        }
      })
      .catch((result) => {
        setMessage({
          error: true,
          message: errorHandler(result.errors),
          data: null,
        })
      })
  }

  function salvarLocal() {
    const lat = latLng.lat()
    const lng = latLng.lng()
    const municipio = Endereco.getMunicipio()

    if (municipio.municipio_url === 'sao-paulo') {
      // if (municipio.lote_fiscal === 1 && lote) {
      let loteFiscal = lote
      salvar({
        numero: loteFiscal.numero,
        nome: loteFiscal.logradouro + ',' + loteFiscal.numero,
        endereco: loteFiscal.logradouro,
        cidade: municipio['nome'],
        terrain_type: 'IMOVEL',
        postgis_municipio_id: municipio['id'],
        complemento: loteFiscal.complemento,
        cep: loteFiscal.cep,
        area_iptu: Number(loteFiscal.area_construida),
        area_real: Number(loteFiscal.area_lote),
        numero_contribuinte: loteFiscal.numero_contribuinte,
        latitude: String(lat),
        longitude: String(lng),
        setor_quadra: loteFiscal.setor + '' + loteFiscal.quadra,
        uso_atual_terreno: loteFiscal.uso_atual,
        codlog: loteFiscal.codlog,
        terreno_esquina: loteFiscal.tipo_de_terreno,
        numero_lote: String(loteFiscal.id),
        marcacao: loteFiscal.geom,
        testada: Number(loteFiscal.testada),
        preco_m2: Number(loteFiscal.valor_do_m2_do_terreno),
        id_situacao: 1,
      })
    } else {
      const geocoder = new google.maps.Geocoder()

      if (lat && lng) {
        const latLng = new google.maps.LatLng(lat, lng)
        const geom = {
          type: 'Point',
          coordinates: [lng, lat],
        }
        geocoder.geocode({location: latLng}, async function (results, status) {
          if (status === 'OK') {
            var place = results[0]
            var adr_elements = formatAdressSeachGoogleMapa(place)
            const resultCreate = await createImovel({
              variables: {
                imovel: {
                  numero: adr_elements['numero'],
                  nome: adr_elements['logradouro'] + ',' + adr_elements['numero'],
                  endereco: adr_elements['logradouro'],
                  bairro: adr_elements['bairro'],
                  estado: adr_elements['estado'],
                  cidade: municipio['nome'],
                  terrain_type: 'IMOVEL',
                  postgis_municipio_id: municipio['id'],
                  complemento: '',
                  cep: adr_elements['cep'],
                  area_iptu: undefined,
                  area_real: undefined,
                  numero_contribuinte: '',
                  latitude: String(lat),
                  longitude: String(lng),
                  setor_quadra: '',
                  uso_atual_terreno: '',
                  codlog: '',
                  terreno_esquina: '',
                  numero_lote: '',
                  marcacao: JSON.stringify(geom),
                  testada: undefined,
                  preco_m2: undefined,
                  cod_condomio: '',
                  id_situacao: 1,
                },
              },
            })
              .then(async (result) => {
                if (!result.data) {
                  setMessage({
                    error: true,
                    message: errorHandler(result.errors),
                    data: null,
                  })
                } else {
                  setMessage({
                    error: true,
                    message: 'Endereço:' + result.data.SetImovel.nome,
                    data: result.data.SetImovel,
                  })
                }
              })
              .catch((result) => {
                setMessage({
                  error: true,
                  message: errorHandler(result.errors),
                  data: null,
                })
              })
          } else {
            alert(
              'Geocode não funcionou como deveria, tente novamente mais tarde ou entre em contato conosco.\r\n' +
                status
            )
          }
        })
      }
    }
  }

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  async function checkLocal() {
    setShow(true)
  }

  const handleClose = () => {
    setMessage({
      error: null,
      message: null,
      data: null,
    })
    setShow(false)
  }

  return (
    <>
      <button
        className='btn btn-flex fs-1 flex-center bg-body btn-color-gray-700 btn-active-light-success w-40px w-md-auto h-40px px-0 px-md-6'
        onClick={checkLocal}
      >
        <FaRegClipboard className='fs-3 me-1' /> <span className='mt-1'>FICHA</span>
      </button>
      <Modal centered animation show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Gerar Ficha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message.error ? (
            <>
              <p>{message.message}</p>
              {message.data ? (
                <p className='fw-bold'>
                  Deseja abrir a ficha deste imóvel? <br />{' '}
                </p>
              ) : (
                ''
              )}
            </>
          ) : municipio.lote_fiscal === 1 && !lote && message ? (
            <Loader text={'caregando...'}></Loader>
          ) : (
            <>
              <p>Esse imóvel será salvo nos "Meus Locais". Deseja continuar?</p>{' '}
              <p>
                {lote['logradouro']},{lote['numero']}.
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' data-dismiss='modal' onClick={handleClose}>
            Fechar
          </Button>
          {message.data ? (
            <button
              onClick={() => {
                openInNewTab(`/ficha/${message.data.uuid}?idCidade=${municipio.id}`)
                handleClose()
              }}
              className='btn btn-info '
            >
              Abrir Ficha
            </button>
          ) : (
            ''
          )}
          {!message.error ? (
            <Button variant='primary' data-dismiss='modal' onClick={salvarLocal}>
              Gerar Ficha
            </Button>
          ) : (
            ''
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}
