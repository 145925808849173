import { useState } from 'react'
import '../../../global.css'
import { Modal } from 'react-bootstrap'
import { BiAddToQueue } from 'react-icons/bi'
import { MutationSetBasicImovel } from '../../../../graphql'
import { FormInputAndLabel } from '../../../../components/UI/Inputs/FormInputAndLabel'
import { PathToCoordinates } from '../../../../components/UI/MapView'
import { AutocompleteGoogle } from '../../../../components/UI/Autocomplete/Autocomplete'
import { ErrorMessageComponent } from '../../../../backoffice/components/UI/ErrorMessage'
import { MapMarker } from '../../../../components/UI/MapMarker'
import { FormInputMaskAndLabel } from '../../../../components/UI/Inputs/FormInputMaskAndLabel'
import { ApiUrbit } from '../../../../../services/http/urbit'
import { formatAdressSeachGoogleMapa } from '../../../explorer/components/common'

export function CreateImovel() {
  const [show, setShow] = useState(false)
  const [latLng, setLatLng] = useState([])
  const [endereco, setEndereco] = useState()

  function closeModal() {
    setShow(false)
    setLatLng([])
    setEndereco(null)
    setValue(`endereco`, '')
    setValue(`numero`, '')
  }

  function openModal() {
    setShow(true)
  }

  const { FormImovel, errors, handleSubmit, loading, register, setValue } =
    MutationSetBasicImovel()

  async function onchangeaddres(result) {
    document.getElementById('endereco')['value'] = result.endereco_completo
    document.getElementById('latitude')['value'] = result.latitude
    document.getElementById('longitude')['value'] = result.longitude
    document.getElementById('cep')['value'] = result.cep
    document.getElementById('bairro')['value'] = result.bairro
    document.getElementById('cidade')['value'] = result.cidade
    document.getElementById('estado')['value'] = result.estado

    const municipio = await ApiUrbit.get(`municipios/${result.longitude}/${result.latitude}`)
    if (municipio.municipio_url === `sao-paulo`) {
      // console.log(`sp`)
      setValue(`endereco`, result.logradouro)
      setValue(`numero`, result.numero)
      setValue('nome', result.logradouro + ',' + result.numero)
    } else {
      // console.log(`outros`)
      if (result.logradouro) {
        setValue(`endereco`, result.logradouro)
        setValue(`numero`, result.numero)
        setValue('nome', result.logradouro + ',' + result.numero)
      } else {
        setValue(`endereco`, result.endereco_completo)
        setValue(`numero`, result.numero)
        setValue('nome', result.endereco_completo + ',' + result.numero)
      }
    }

    setValue('latitude', String(result.latitude))
    setValue('longitude', String(result.longitude))
    setValue('cep', String(result.cep))
    // console.log(`result`)
    // console.log(result)
    setValue('bairro', String(result.bairro))
    setValue('cidade', String(result.cidade))
    setValue('estado', String(result.estado))

    setValue('id_cidade', municipio.id)
    setValue('id_estado', municipio.estado_id)

    setEndereco(result.logradouro)
    setLatLng([...[result.latitude, result.longitude]])
  }

  async function onChangeMap(geom, lote, latLngEnter) {
    // console.log('onc ahgenmap')
    // console.log(geom)
    // console.log(lote)
    if (geom) {
      const geometria = PathToCoordinates(geom)
      const multiPolygon = {
        type: 'MultiPolygon',
        coordinates: [],
      }
      if (document.getElementById('marcacao')) {
        multiPolygon.coordinates.push([geometria])
        document.getElementById('marcacao')['value'] = JSON.stringify(multiPolygon)
        setValue('marcacao', JSON.stringify(multiPolygon))
      }

      if (lote) {
        setValue(`endereco`, lote.itens[0].logradouro)
        setValue(`numero`, lote.itens[0].numero)
        setValue(`cep`, lote.itens[0].cep)
        setValue('nome', lote.itens[0].logradouro + ',' + lote.itens[0].numero)
        setValue('area_iptu', Number(lote.itens[0].area_lote))
        setValue('numero_contribuinte', lote.itens[0].numero_contribuinte)
        setValue('setor_quadra', lote.itens[0].quadra + '' + lote.itens[0].quadra)
        setValue('uso_atual_terreno', lote.itens[0].quadra + '' + lote.itens[0].uso_atual)
        setValue('codlog', lote.itens[0].codlog)
        setEndereco(lote.itens[0].logradouro)
        // console.log(latLng)
        // setLatLng([...latLng, [latLng[0][1],latLng[0][0]]])
      }
    } else {
      // console.log(latLngEnter)
      const lat = latLngEnter[0]
      const lng = latLngEnter[1]
      const municipio = await ApiUrbit.get(`municipios/${lng}/${lat}`)
      // console.log(municipio)
      if (municipio.lote_fiscal === 0) {
        // console.log('asdas')
        const geocoder = new google.maps.Geocoder()
        const latLng = new google.maps.LatLng(lat, lng)
        geocoder.geocode({ location: latLng }, async function (results, status) {
          if (status === 'OK') {
            // console.log(results)
            var place = results[0]
            var adr_elements = formatAdressSeachGoogleMapa(place)
            // console.log(adr_elements)

            // console.log(`outros`)
            if (adr_elements.logradouro) {
              setValue(`endereco`, adr_elements.logradouro)
              setValue(`numero`, adr_elements.numero)
              setValue('nome', adr_elements.logradouro + ',' + adr_elements.numero)
            } else {
              setValue(`endereco`, adr_elements.endereco_completo)
              setValue(`numero`, adr_elements.numero)
              setValue('nome', adr_elements.endereco_completo + ',' + adr_elements.numero)
            }

            setValue('latitude', String(lat))
            setValue('longitude', String(lng))
            setValue('cep', String(adr_elements.cep))
            // console.log(`adr_elements`)
            // console.log(adr_elements)
            setValue('bairro', String(adr_elements.bairro))
            setValue('cidade', String(adr_elements.cidade))
            setValue('estado', String(adr_elements.estado))

            setValue('id_cidade', municipio.id)
            setValue('id_estado', municipio.estado_id)

            setEndereco(adr_elements.logradouro)
          }
        })
      }
    }
  }
  // console.log(endereco)
  return (
    <>
      <button onClick={openModal} className='btn btn-sm btn-light-primary'>
        <BiAddToQueue className='fs-2'></BiAddToQueue> Novo Imóvel
      </button>

      <Modal centered animation show={show} onHide={() => closeModal()} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Novo Imóvel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(FormImovel)} className='form-uso'>
            <div className='row'>
              <div className='col-12'>
                <input
                  type='hidden'
                  // hidden
                  name='latitude'
                  id='latitude'
                  {...register('latitude', { required: true })}
                ></input>
                <input
                  type='hidden'
                  // hidden
                  name='longitude'
                  id='longitude'
                  {...register('longitude', { required: true })}
                ></input>
                <input
                  type='hidden'
                  name='endereco'
                  id='endereco'
                  {...register('endereco', { required: true })}
                ></input>
                <input
                  type='hidden'
                  id='marcacao'
                  // defaultValue={JSON.stringify(data.GetImovel.marcacao)}
                  {...register('marcacao', { required: false })}
                ></input>

                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label className='col-form-label'>Endereço</label>
                      <AutocompleteGoogle
                        onChangeAddress={onchangeaddres}
                        endereco={endereco}
                      ></AutocompleteGoogle>
                      {errors.endereco && (
                        <ErrorMessageComponent message={errors.endereco?.message} />
                      )}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <FormInputAndLabel
                      label='Número'
                      id='numero'
                      placeholder='Número do Imóvel'
                      className='form-control form-control-solid'
                      register={register('numero')}
                      error={errors ? errors.numero?.message : ''}
                    />
                  </div>
                </div>

                <div className='row'>
                  {/* <div className='col-md-6'>
                    <FormInputAndLabel
                      label='Nome'
                      placeholder='Nome do Imóvel'
                      className='form-control form-control-solid'
                      register={register('nome')}
                      error={errors ? errors.nome?.message : ''}
                    />
                  </div> */}

                  <div className='col-md-6'>
                    <FormInputMaskAndLabel
                      maskChar=' '
                      mask='99999-999'
                      label='CEP'
                      id='cep'
                      placeholder='CEP do Imóvel'
                      className='form-control form-control-solid'
                      register={register('cep')}
                      error={errors ? errors.cep?.message : ''}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormInputAndLabel
                      label='Bairro'
                      id='bairro'
                      placeholder='Bairro do Imóvel'
                      className='form-control form-control-solid'
                      register={register('bairro')}
                      error={errors ? errors.bairro?.message : ''}
                    />
                  </div>
                </div>

                <div className='row'>
                  {/* <GetEstadoCidadeChosen
                    defaultValueCidade={}
                    defaultValueEstado={ }
                    classNameCity={'col-6 col-xs-12 mt-1'}
                    classNameEstado={'col-6 col-xs-12  mt-1'}
                    SetValueEstado={(value) => {
                      // setValueEstado(value)
                      setValue('id_estado', value)
                      // setValue('id_cidade', null)
                    }}
                    SetValueCidade={(value) => {
                      
                      // setValueCidade(value)
                      setValue('id_cidade', value)
                    }}
                  /> */}
                  <div className='col-md-6'>
                    <FormInputAndLabel
                      label='Cidade'
                      id='cidade'
                      placeholder='Cidade do Imóvel'
                      className='form-control form-control-solid'
                      register={register('cidade')}
                      error={errors ? errors.cidade?.message : ''}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormInputAndLabel
                      label='Estado'
                      id='estado'
                      placeholder='Estado'
                      className='form-control form-control-solid'
                      register={register('estado')}
                      error={errors ? errors.estado?.message : ''}
                    />
                  </div>
                </div>

                {/* <FormInputAndLabel
                  label="Endereço"
                  placeholder="Endereço do Imóvel"
                  className="form-control form-control-solid"
                  {...register('endereco')}
                /> */}
              </div>
            </div>

            <div className='row'>
              <div className='card-body p-6 pt-5'>
                <div className='col-md-12'>
                  <div className='row '>
                    <h4>Arraste o PIN para marcar um novo local</h4>
                  </div>
                  <div className='row '>
                    <MapMarker
                      latLng={latLng}
                      setLatLng={setLatLng}
                      onChange={onChangeMap}
                      showLotes={true}
                    ></MapMarker>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={closeModal}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
