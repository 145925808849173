import {BiInfoCircle} from 'react-icons/bi'
import {Buttons} from './Buttons'
import {BasicData} from './BasicaData'
import {PhonesExtended} from './PhonesExtended'
import {EmailsExtended} from './EmailsExtended'
import {RelatedPeople} from './RelatedPeople'
import {RelatedPeopleEmails} from './RelatedPeopleEmails'
import {RelatedPeoplePhones} from './RelatedPeoplePhones'
import {AddressesExtended} from './AddressesExtended'
import {dadosTabelas} from '../../../../../components/Reports/partials/EstrtuturaBDC'

export function Row({response, idImovel, clickRow, updateList}) {
  // var dadosTabelas = {}
  // dadosTabelas['cpf'] = {}
  // dadosTabelas['cnpj'] = {}

  // dadosTabelas['cpf']['BasicData'] = {
  //   'key': 'BasicData',
  //   'titulo': 'Dados Principais',
  //   'coluns': ['Name', 'Age', 'BirthDate', 'BirthCountry', 'Gender', 'FatherName', 'MotherName'],
  //   'colunsItens': []
  // };

  // dadosTabelas['cpf']['PhonesExtended'] = {
  //   'key': 'ExtendedPhones',
  //   'titulo': 'Telefones',
  //   'coluns': ['Phones'],
  //   'colunsItens': ['CountryCode', 'AreaCode', 'Number']
  // }
  // dadosTabelas['cpf']['EmailsExtended'] = {
  //   'key': 'ExtendedEmails',
  //   'titulo': 'E-mails',
  //   'coluns': ['Emails'],
  //   'colunsItens': ['EmailAddress', 'UserName', 'Type']
  // }
  // dadosTabelas['cpf']['AddressesExtended'] = {
  //   'key': 'ExtendedAddresses',
  //   'titulo': 'Endereços',
  //   'coluns': ['Addresses'],
  //   'colunsItens': ['Typology', 'AddressMain', 'Number', 'Neighborhood', 'City', 'State', 'ZipCode', 'Complement', 'Type']
  // }

  // dadosTabelas['cpf']['RelatedPeople'] = {
  //   'key': 'RelatedPeople',
  //   'titulo': 'Pessoas relacionadas',
  //   'coluns': ['PersonalRelationships'],
  //   'colunsItens': ['RelatedEntityName', 'RelatedEntityTaxIdNumber', 'RelationshipType']
  // }

  // dadosTabelas['cpf']['RelatedPeopleEmails'] = {
  //   'key': 'RelatedPeopleEmails',
  //   'titulo': 'E-mail pessoas relacionadas',
  //   'coluns': [],
  //   'colunsItens': ['EmailAddress', 'UserName', 'Type']
  // }

  // dadosTabelas['cpf']['RelatedPeoplePhones'] = {
  //   'key': 'RelatedPeoplePhones',
  //   'titulo': 'Telefones pessoas relacionadas',
  //   'coluns': [],
  //   'colunsItens': ['CountryCode', 'AreaCode', 'Number', 'Type']
  // }

  // dadosTabelas['cnpj']['BasicData'] = {
  //   'key': 'BasicData',
  //   'titulo': 'Dados Principais',
  //   'coluns': ['TradeName', 'OfficialName', 'TaxRegime', 'TaxIdStatusRegistrationDate', 'HeadquarterState', 'Age'],
  //   'colunsItens': [],
  //   'colunTotal': null
  // };

  // dadosTabelas['cnpj']['PhonesExtended'] = {
  //   'key': 'ExtendedPhones',
  //   'titulo': 'Telefones',
  //   'coluns': ['Phones'],
  //   'colunsItens': ['CountryCode', 'AreaCode', 'Number'],
  //   'colunTotal': 'TotalPhones'
  // }
  // dadosTabelas['cnpj']['EmailsExtended'] = {
  //   'key': 'ExtendedEmails',
  //   'titulo': 'E-mails',
  //   'coluns': ['Emails'],
  //   'colunsItens': ['EmailAddress', 'UserName', 'Type'],
  //   'colunTotal': 'TotalEmails'
  // }
  // dadosTabelas['cnpj']['AddressesExtended'] = {
  // 'key': 'ExtendedAddresses',
  // 'titulo': 'Endereços',
  // 'coluns': ['Addresses'],
  // 'colunsItens': ['Typology', 'AddressMain', 'Number', 'Neighborhood', 'City', 'State', 'ZipCode', 'Complement', 'Type'],
  // 'colunTotal': 'TotalAddresses'
  // }

  const typeDoc = response.cpf ? 'cpf' : 'cnpj'
  return (
    <>
      <tr>
        <td className='w-20px'>
          {response.basic_data ? (
            <button
              onClick={() => {
                clickRow(response.id)
              }}
              className='btn btn-icon btn-light-info btn-sm me-2'
            >
              <BiInfoCircle className='fs-1'></BiInfoCircle>
            </button>
          ) : (
            '-'
          )}
        </td>

        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-center flex-column'>
              <p className='text-dark  fs-6'>{response.nome || response.empresa}</p>
            </div>
          </div>
        </td>
        <td>
          <p className='text-dark  d-block fs-6'>{response.email}</p>
        </td>
        <td>
          <Buttons
            id={response.id_terreno_proprietario}
            idProprietario={response.id}
            idTerreno={idImovel}
            syncOk={response.basic_data ? true : false}
            updateList={updateList}
            showButtonSync={response.cpf || response.cnpj ? true : false}
          />
        </td>
      </tr>

      <tr className='hide ' id={`detalhes_${response.id}`}>
        <td colSpan={7}>
          <div>
            <div className='card card-body'>
              {response.basic_data ? (
                <BasicData
                  data={response.basic_data}
                  estrutura={dadosTabelas[typeDoc]['BasicData']}
                ></BasicData>
              ) : (
                ''
              )}
              {response.phones_extended ? (
                <PhonesExtended
                  data={response.phones_extended}
                  estrutura={dadosTabelas[typeDoc]['PhonesExtended']}
                ></PhonesExtended>
              ) : (
                ''
              )}
              {response.emails_extended ? (
                <EmailsExtended
                  data={response.emails_extended}
                  estrutura={dadosTabelas[typeDoc]['EmailsExtended']}
                ></EmailsExtended>
              ) : (
                ''
              )}
              {response.addresses_extended ? (
                <AddressesExtended
                  data={response.addresses_extended}
                  estrutura={dadosTabelas[typeDoc]['AddressesExtended']}
                ></AddressesExtended>
              ) : (
                ''
              )}
              {typeDoc === 'cpf' ? (
                <>
                  {response.related_people ? (
                    <RelatedPeople
                      data={response.related_people}
                      estrutura={dadosTabelas[typeDoc]['RelatedPeople']}
                    ></RelatedPeople>
                  ) : (
                    ''
                  )}
                  {response.related_people_emails ? (
                    <RelatedPeopleEmails
                      data={response.related_people_emails}
                      estrutura={dadosTabelas[typeDoc]['RelatedPeopleEmails']}
                    ></RelatedPeopleEmails>
                  ) : (
                    ''
                  )}
                  {response.related_people_phones ? (
                    <RelatedPeoplePhones
                      data={response.related_people_phones}
                      estrutura={dadosTabelas[typeDoc]['RelatedPeoplePhones']}
                    ></RelatedPeoplePhones>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}
