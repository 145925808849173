import { IoBedOutline, IoCarSportOutline, IoClose, IoConstructOutline } from 'react-icons/io5';
import { BsBuildings } from 'react-icons/bs';
import { BiArea } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { RealEstateLaunchFormData } from '../typologies/formValidations';
import { useFormContext } from 'react-hook-form';
import { useEmpreendimentos } from '../../context/useEmpreendimentos';
import { RealEstateLaunch, TypologyData } from '../../../../../../services/http/urbit-gis/routes/types';
import { Loader } from '../../../../../components/UI/Loader/Loader';
import { calculateDateDifference } from '../../../../../components/util';

interface Props {
  data?: RealEstateLaunch;
}

export function About({ data }: Props) {
  const [todos, setTodos] = useState<string[]>([]);
  const [newTodo, setNewTodo] = useState('');
  const { setValue } = useFormContext<RealEstateLaunchFormData>();
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const { typologies = [] } = useEmpreendimentos();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await new Promise(resolve => setTimeout(resolve, 1000));

      if (data) {
        setValue('data.features', data.features);
        setTodos(data.features || []);
      }
      setLoading(false);
    };

    fetchData();
  }, [data, setValue]);

  useEffect(() => {
    console.log('Todos Atualizados:', todos);
    setValue('data.features', todos);
  }, [todos, setValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTodo(event.target.value);
  };

  const handleAddTodo = () => {
    if (newTodo.trim() !== '') {
      setTodos(prevTodos => [...prevTodos, newTodo]);
      setNewTodo('');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddTodo();
      event.preventDefault();
    }
  };

  const handleRemoveTodo = (index: number) => {
    setTodos(prevTodos => prevTodos.filter((_, i) => i !== index));
  };

  // Funções para calcular os valores a partir das tipologias
  const getMinMaxValues = (field: keyof TypologyData) => {
    const values = typologies.map(t => t[field]).filter(v => v !== undefined && v !== null) as number[];
    const min = values.length > 0 ? Math.min(...values) : 0;
    const max = values.length > 0 ? Math.max(...values) : 0;
    return { min, max };
  };

  const total = (field: keyof TypologyData): number => {
    return typologies.reduce((sum, t) => {
      const value = t[field];
      return sum + (typeof value === 'number' ? value : 0);
    }, 0);
  };

  if (loading) {
    return <Loader text='Carregando empreendimento' />;
  }

  const areaValues = getMinMaxValues('private_area');
  const bedroomValues = getMinMaxValues('bedrooms');
  const totalVagas = total('parking');
  const totalTorres = total('total_units');


  return (
    <div>
      {typologies.length > 0 && (
        <div className="row">
          <div className="col-12 d-flex flex-wrap all-center-between px-8">
            <div>
              <IoCarSportOutline size={32} />
              <div>
                <span className="fts-1 fw-bolder text-muted">Vagas</span><br />
                <span className="fts-1 fw-bolder">{totalVagas}</span>
              </div>
            </div>
            <div className="contentAboutIcons">
              <IoBedOutline size={32} />
              <div>
                <span className="fts-1 fw-bolder text-muted">Quartos</span><br />
                <span className="fts-1 fw-bolder">
                  {typologies.length === 1
                    ? bedroomValues.min
                    : `${bedroomValues.min} a ${bedroomValues.max}`}
                </span>
              </div>
            </div>
            <div className="contentAboutIcons">
              <IoConstructOutline size={32} />
              <div>
                <span className="fts-1 fw-bolder text-muted">Construção</span><br />
                <span className="fts-1 fw-bolder">{data ? calculateDateDifference(data.launch_date) : 'Sem data'}</span>
              </div>
            </div>
            <div className="contentAboutIcons">
              <BsBuildings size={32} />
              <div>
                <span className="fts-1 fw-bolder text-muted">Torres</span><br />
                <span className="fts-1 fw-bolder">{data ? data.number_of_towers : 0}</span>
              </div>
            </div>
            <div className="contentAboutIcons">
              <BiArea size={32} />
              <div>
                <span className="fts-1 fw-bolder text-muted">Área Útil</span><br />
                <span className="fts-1 fw-bolder">
                  {typologies.length === 1
                    ? areaValues.min
                    : `${areaValues.min} a ${areaValues.max}`}m²
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row mt-4 px-4">
        <div className="d-flex flex-column">
          <div>
            <div className="form-group d-flex">
              <input
                type="text"
                className="form-control form-control-solid"
                placeholder="Ex: Churrasqueira, Jardim, Piscina ...."
                value={newTodo}
                disabled={!typologies || typologies.length === 0}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              <button onClick={handleAddTodo} type="button" disabled={!typologies || typologies.length === 0} className="btn btn-success">
                Adicionar
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="d-flex all-center-start flex-wrap mt-2 gap-2">
                {todos.map((todo, index) => (
                  <div className="badge badge-light-primary all-center-between" key={index}>
                    <span className="me-1">{todo}</span>
                    <button
                      onClick={() => handleRemoveTodo(index)}
                      className='bg-transparent border-none p-0'
                      style={{ border: 'none' }}
                    >
                      <IoClose className='text-muted' size={10} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
