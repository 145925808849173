import { gql } from "@apollo/client";

export const SET_ADMIN_EMPRESA_USUARIO = gql`
mutation SetUsuarioAdmin($usuario: usuario!) {
  SetUsuarioAdmin(usuario: $usuario) {
    id
  }
}
`


export const PUT_ADMIN_EMPRESA_USUARIO = gql`
mutation PutEmpresaUsuarioAdmin($data: empresaUsuario!) {
  PutEmpresaUsuarioAdmin(data: $data) {
    id
  }
}
`

export const DELETE_ADMIN_EMPRESA_USUARIO = gql`
mutation DeleteEmpresaUsuarioAdmin($deleteEmpresaUsuarioAdminId: Float!) {
  DeleteEmpresaUsuarioAdmin(id: $deleteEmpresaUsuarioAdminId) {
    id
  }
}
`