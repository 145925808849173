import {useLazyQuery, useMutation, useQuery} from '@apollo/client'
import {GET_PROPRIETARIOS, GET_PROPRIETARIO_BY_ID} from '../query/proprietarios'
import {
  TypesDeleteProprietariosResponse,
  TypesGetProprietarioByIDResponse,
  TypesGetProprietariosResponse,
  TypesPutProprietariosResponse,
  TypesSetProprietariosResponse,
} from '../type/proprietarios'
import {DELETE_PROPRIETARIO, PUT_PROPRIETARIO, SET_PROPRIETARIO} from '../mutations'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {
  FormCreateProprietariosFormData,
  FormCreateProprietariosSchema,
  FormPutProprietariosFormData,
  FormPutProprietariosSchema,
} from '../formValidations/Proprietarios'
import { GET_IMOVEL_PROPRIETARIO } from '../query'

interface QueryProps {
  variables: any
}

export function QueryGetProprietarios({variables}: QueryProps) {
  const {data, loading, error, refetch} = useQuery<TypesGetProprietariosResponse>(
    GET_PROPRIETARIOS,
    {
      variables: {...variables},
    }
  )
  return {data, loading, error, refetch}
}

export function QueryGetProprietarioByID({variables}: QueryProps) {
  const {data, loading, error} = useQuery<TypesGetProprietarioByIDResponse>(
    GET_PROPRIETARIO_BY_ID,
    {
      variables: {
        data: {
          ...variables,
        },
      },
    }
  )
  return {data, loading, error}
}

export function QueryGetProprietarioByData() {
  const [getData, {data, loading, error}] =
    useLazyQuery<TypesGetProprietariosResponse>(GET_PROPRIETARIOS)
  return {data, loading, error, getData}
}

export function MutationCreateProprietario() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: {errors},
  } = useForm<FormCreateProprietariosFormData>({
    resolver: zodResolver(FormCreateProprietariosSchema),
  })

  const [ProprietariosBody, {error, loading, data: DataProprietarios}] =
    useMutation<TypesSetProprietariosResponse>(SET_PROPRIETARIO)

  async function FormProprietarios(data: FormCreateProprietariosFormData) {
    delete data.tipo
    return await ProprietariosBody({
      variables: {
        proprietario: {...data},
      },
      refetchQueries: [GET_PROPRIETARIOS],
    })
  }

  return {
    register,
    handleSubmit,
    FormProprietarios,
    loading,
    errors,
    error,
    DataProprietarios,
    control,
    setValue,
    setError,
  }
}

export function MutationCreateProprietarioAndAssociationImovel() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: {errors},
  } = useForm<FormCreateProprietariosFormData>({
    resolver: zodResolver(FormCreateProprietariosSchema),
  })

  const [ProprietariosBody, {error, loading, data: DataProprietarios}] =
    useMutation<TypesSetProprietariosResponse>(SET_PROPRIETARIO)

  async function FormProprietarioImovel(idTerreno: number, data: FormCreateProprietariosFormData) {
    delete data.tipo
    return await ProprietariosBody({
      variables: {
        idTerreno: Number(idTerreno),
        proprietario: {...data},
      },
      refetchQueries: [GET_PROPRIETARIOS, GET_IMOVEL_PROPRIETARIO],
    })
  }

  return {
    register,
    handleSubmit,
    FormProprietarioImovel,
    loading,
    errors,
    error,
    DataProprietarios,
    control,
    setValue,
    setError,
  }
}

export function MutationPutProprietario() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    formState: {errors},
  } = useForm<FormPutProprietariosFormData>({
    resolver: zodResolver(FormPutProprietariosSchema),
  })
  console.log(errors)
  const [ProprietariosBody, {error, loading, data: DataProprietarios}] =
    useMutation<TypesPutProprietariosResponse>(PUT_PROPRIETARIO)

  async function FormProprietarios(data: FormPutProprietariosFormData) {
    console.log(data)
    delete data.tipo
    await ProprietariosBody({
      variables: {
        proprietario: {...data},
      },
      refetchQueries: [GET_PROPRIETARIOS],
    })
  }

  return {
    register,
    handleSubmit,
    FormProprietarios,
    loading,
    errors,
    error,
    DataProprietarios,
    control,
    setValue,
    setError,
    reset,
  }
}

export function MutationDeleteProprietarios() {
  const [deleteProprietarios, {loading, data, error}] =
    useMutation<TypesDeleteProprietariosResponse>(DELETE_PROPRIETARIO)

  async function HandleDeleteProprietarios({variables}: QueryProps) {
    try {
      const result = await deleteProprietarios({
        variables: {...variables},
        refetchQueries: [GET_PROPRIETARIOS],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteProprietarios, loading, error, data}
}
