import {Link} from 'react-router-dom'
import {
  FaUserAlt,
  FaBox,
  FaShoppingCart,
  FaTag,
  FaLayerGroup,
  FaHospitalUser,
  FaRegNewspaper,
  FaSuitcase,
  FaClipboardList,
} from 'react-icons/fa'
import {MutationSetLogAcessoRecurso} from '../../../../../app/graphql/services/LogAcesso'
import {BiBuildings, BiSolidBuildings} from 'react-icons/bi'

export function MenuBackoffice() {
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  return (
    <>
      <div className='m-0'>
        <h1 className='mb-5'>Backoffice</h1>
        <span className='menu-link pb-5 '>
          <span className='menu-icon'></span>
          <span className='menu-title'>Cadastros</span>
          <span className='menu-arrow'></span>
        </span>
        <ul className='nav nav-pills nav-pills-custom flex-column border-transparent fs-5 fw-bold pt-5 pb-5 mx-5'>
          <li className='nav-item'>
            <Link
              to='/backoffice/usuarios'
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <FaUserAlt
                // size={20}
                // color='#8a8a8a'
                className='pe-2 pb-1 fs-1 text-active-primary text-hover-success'
              />
              Usuários
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/empreendimentos'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'backoffice',
                  funcionalidade: 'empreendimentos',
                  action: 'list',
                  url: '/backoffice/empreendimentos',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <BiSolidBuildings
                // size={20}
                // color='#8a8a8a'
                className='pe-2 pb-1 fs-1 text-active-primary text-hover-success'
              />
              Empreendimentos
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/empresas'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'backoffice',
                  funcionalidade: 'empresas',
                  action: 'list',
                  url: '/backoffice/empresas',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <FaSuitcase
                // size={20}
                // color='#8a8a8a'
                className='pe-2 pb-1 fs-1 text-active-primary text-hover-success'
              />
              Empresas
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/cupons'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'backoffice',
                  funcionalidade: 'cupons',
                  action: 'list',
                  url: '/backoffice/cupons',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success  p-0 border-0 '
            >
              <FaTag className='pe-2  fs-1 pb-1 text-active-primary text-hover-success' />
              Cupons
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/pacotes'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'backoffice',
                  funcionalidade: 'pacotes',
                  action: 'list',
                  url: '/backoffice/pacotes',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success  p-0 border-0 '
            >
              <FaBox className='pe-2 pb-1  fs-1 text-active-primary text-hover-success' />
              Pacotes
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/produtos'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'backoffice',
                  funcionalidade: 'produtos',
                  action: 'list',
                  url: '/backoffice/produtos',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success  p-0 border-0 '
            >
              <FaShoppingCart className='pe-2 pb-1  fs-1 text-active-primary text-hover-success' />
              Produtos
            </Link>
          </li>

          <li className='nav-item'>
            <Link
              to='/backoffice/servicos'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'backoffice',
                  funcionalidade: 'servicos',
                  action: 'list',
                  url: '/backoffice/servicos',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success  p-0 border-0 '
            >
              <FaHospitalUser className='pe-2 pb-1  fs-1 text-active-primary text-hover-success' />
              Serviços
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/assinaturas'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'backoffice',
                  funcionalidade: 'assinaturas',
                  action: 'list',
                  url: '/backoffice/assinaturas',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success  p-0 border-0 '
            >
              <FaClipboardList className='pe-2 pb-1  fs-1 text-active-primary text-hover-success' />
              Assinaturas
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/explorer-layers'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'backoffice',
                  funcionalidade: 'explorer-layers',
                  action: 'list',
                  url: '/backoffice/explorer-layers',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success  p-0 border-0 '
            >
              <FaLayerGroup className='pe-2 pb-1  fs-1 text-active-primary text-hover-success' />
              Explorer Layer
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/news-feed'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'backoffice',
                  funcionalidade: 'news-feed',
                  action: 'list',
                  url: '/backoffice/news-feed',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success  p-0 border-0 '
            >
              <FaRegNewspaper className='pe-2 pb-1  fs-1 text-active-primary text-hover-success' />
              Feed de Noticias
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/servicos'
              className='disabled sub-menu-secondary nav-link text-muted text-active-primary text-hover-success  p-0 border-0 '
            >
              <FaTag className='pe-2 pb-1  fs-1 text-active-primary text-hover-success' />
              Layer PNG
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/dicionario'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'backoffice',
                  funcionalidade: 'dicionario',
                  action: 'list',
                  url: '/backoffice/dicionario',
                })
              }
              className='disabled sub-menu-secondary nav-link text-muted text-active-primary text-hover-success  p-0 border-0 '
            >
              <FaTag className='pe-2 pb-1  fs-1 text-active-primary text-hover-success' />
              Dicionário
            </Link>
          </li>

          <li className='nav-item'>
            <Link
              to='/backoffice/termos'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'backoffice',
                  funcionalidade: 'termos',
                  action: 'list',
                  url: '/backoffice/termos',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success  p-0 border-0 '
              aria-disabled={true}
            >
              <FaTag className='pe-2 pb-1  fs-1 text-active-primary text-hover-success' />
              Termos Politicas de Uso
            </Link>
          </li>
        </ul>

        <span className='menu-link pb-5 '>
          <span className='menu-icon'></span>
          <span className='menu-title'>Relatórios</span>
          <span className='menu-arrow'></span>
        </span>
        <ul className='nav nav-pills nav-pills-custom flex-column border-transparent fs-5 fw-bold pt-5 pb-5 mx-5'>
          <li className='nav-item'>
            <Link
              to='/backoffice/assinaturas'
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <FaUserAlt
                // size={20}
                // color='#8a8a8a'
                className='pe-2 pb-1 fs-1 text-active-primary text-hover-success'
              />
              Assinaturas
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/imoveis-cadastrados'
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <FaUserAlt
                // size={20}
                // color='#8a8a8a'
                className='pe-2 pb-1 fs-1 text-active-primary text-hover-success'
              />
              Imóveis Cadastrados
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/leads'
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <FaUserAlt
                // size={20}
                // color='#8a8a8a'
                className='pe-2 pb-1 fs-1 text-active-primary text-hover-success'
              />
              Leads
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/historico-atividades'
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <FaUserAlt
                // size={20}
                // color='#8a8a8a'
                className='pe-2 pb-1 fs-1 text-active-primary text-hover-success'
              />
              Histórico de Atividaeds
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/backoffice/pedidos'
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <FaUserAlt
                // size={20}
                // color='#8a8a8a'
                className='pe-2 pb-1 fs-1 text-active-primary text-hover-success'
              />
              Pedidos
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
