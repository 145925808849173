import React, { useEffect, useState } from "react";
import '../global.css'
import { Modal } from "react-bootstrap";
import { BiAddToQueue } from "react-icons/bi";
import { MutationCreateEmpreendimento } from "../../graphql";
import { FormInputAndLabel } from "../../components/UI/Inputs/FormInputAndLabel";
import { MapView } from "../../components/UI/MapView";
import Swal from "sweetalert2";


export function CreateEmpreendimentos() {

  let latlng = []
  const [show, setShow] = useState(false)

  function closeModal() {
    setShow(false)
  }
  function openModal() {
    setShow(true)
  }

  const {
    DataSetEmpreendimento,
    FormEmpreendimento,
    error,
    errors,
    handleSubmit,
    loading,
    register,
    reset
  }
    = MutationCreateEmpreendimento()


  useEffect(() => {
    if (DataSetEmpreendimento) {
      closeModal()
      Swal.fire('Sucesso!', 'Empreendimento criado com sucesso.', 'success')
    }
  }, [DataSetEmpreendimento, closeModal, error, loading, reset])


  return (
    <>
      <button onClick={openModal} className='btn btn-sm btn-light-primary'>
        <BiAddToQueue className='fs-2'></BiAddToQueue> Novo Empreendimento
      </button>
      {/* ALTERAR TAMANHO PRA XL QUANDO FOR POR O MAP E OS DADOS DO LOTE */}
      <Modal centered animation show={show} onHide={() => closeModal()} size='sm' backdrop="static" >
        <Modal.Header closeButton>
          <Modal.Title>Novo Empreendimento:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(FormEmpreendimento)} className='form-uso'>
            <div className="row">
              <div className="col-12">
                <FormInputAndLabel
                  label="Nome"
                  placeholder="Nome do Empreendimento"
                  className="form-control form-control-solid"
                  register={register('nome')}
                />
                <FormInputAndLabel
                  label="Endereço"
                  placeholder="Endereço do Empreendimento"
                  className="form-control form-control-solid"
                  register={register('endereco')}
                />
              </div>
              {/* CARD RESPONSAVEL POR RETORNAR INFOS DO LOTE */}
              {/* <div className="col-6">
                <h3 className="pt-6">Informações de Lote:</h3>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                  <div className="accordion-item"><h2 className="accordion-header" id="flush-headingOne0">
                    <button className="accordion-button py-4 px-5" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne0" aria-expanded="true" aria-controls="flush-collapseOne0">
                      <span className="badge bg-secondary">A1</span>
                      <p className="m-1"> R S CAETANO , nº 283</p>
                    </button>
                  </h2>
                    <div id="flush-collapseOne0" className="accordion-collapse collapse" aria-labelledby="flush-headingOne0" data-bs-parent="#accordionFlushExample" >
                      <div className="accordion-body">
                        <div className="flex text-right">
                          <button className="btn btn btn-light m-2">
                            <span className="remove">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"></path>
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"></path>
                              </svg>
                            </span>
                          </button>
                          <button className="btn btn btn-primary m-2">
                            <span className="pin">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                        <ul className="list-group">
                          <li className="list-group-item">Área do Terreno : <code>148.00</code></li>
                          <li className="list-group-item">Testada : <code>5.25</code></li>
                          <li className="list-group-item">Número do contribuinte : <code>0010060001-8</code></li>
                          <li className="list-group-item">Uso : <code>Loja e residência (predominância comercial)</code></li>
                          <li className="list-group-item">Quanditada de Pavimentos : <code>2</code></li>
                          <li className="list-group-item">Quanditada de esquinas :<code>1</code></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>



            <div className='row'>
              <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={closeModal}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

    </>
  );
}

