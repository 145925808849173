/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {RadialBar} from '../../../../../../../components/UI/Charts/RadialBar'
import {formatNumber} from '../../../../common'

type Props = {
  titulo: string
  subtitulo: string
  tipologia: any
}

export const BoxTipologiaResumo: React.FC<Props> = ({titulo, subtitulo, tipologia}) => {
  const valor = tipologia ? Number(tipologia.vendas_perc) : '0'
  return (
    <div className={`card `}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{titulo}</span>

          <span className='text-muted fw-semibold fs-7'>{subtitulo}</span>
        </h3>
      </div>

      <div className='card-body p-0 d-flex flex-column'>
        <div className='row'>
          <div className='col-3 h-150px'>
            <RadialBar
              className={'info'}
              chartColor={'alert'}
              chartHeight={'150'}
              descricao={'% vendas'}
              valor={Number(valor)}
            ></RadialBar>
          </div>

          <div className='col-9'>
            <div className='card-p pt-5 bg-body flex-grow-1'>
              <div className='row g-0'>
                <div className='col mr-8'>
                  <div className='fs-7 text-muted fw-semibold'>Total unidades</div>

                  <div className='d-flex align-items-center'>
                    <div className='fs-4 fw-bold'>{tipologia ? tipologia.total_unidades : 0}</div>
                  </div>
                </div>

                <div className='col'>
                  <div className='fs-7 text-muted fw-semibold'>Estoque</div>

                  <div className='fs-4 fw-bold'>{tipologia ? tipologia.estoque : 0}</div>
                </div>
              </div>

              <div className='row g-0 mt-8'>
                <div className='col mr-8'>
                  <div className='fs-7 text-muted fw-semibold'>Preço Atual</div>

                  <div className='fs-4 fw-bold '>
                    {tipologia ? formatNumber(tipologia.preco_medio, 'currency') : 0}
                  </div>
                </div>

                <div className='col'>
                  <div className='fs-7 text-muted fw-semibold'>Preço m2</div>

                  <div className='d-flex align-items-center'>
                    <div className='fs-4 fw-bold '>
                      {tipologia ? formatNumber(tipologia.preco_medio_m2, 'currency') : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
