/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { KTSVG, toAbsoluteUrl } from '../../../../helpers'
import { Link } from 'react-router-dom'
import { BiBarChart, BiBuildings, BiCog, BiCompass, BiFile, BiHome, BiMapPin, BiReceipt, BiWrench } from 'react-icons/bi'
import secureLocalStorage from 'react-secure-storage'
import { MutationSetLogAcessoRecurso } from '../../../../../app/graphql/services/LogAcesso'
import { AiOutlineUserSwitch } from 'react-icons/ai'

export function AsideMenuMain() {
  const intl = useIntl()
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  return (
    <>
      <div className='all-center my-6'>
        <Link to='/dashboard' className='d-lg-none'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-30px' />
        </Link>
      </div>
      <hr />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>MENU</span>
        </div>
      </div>
      {/* end::Logo */}
      <AsideMenuItem
        to='/dashboard'
        icon={<BiHome className='text-gray-700 btn-active-light'></BiHome>}
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/explorer'
        onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'explorer', funcionalidade: 'inicio', action: 'list', url: '/explorer' })}
        icon={<BiCompass className='text-gray-700 btn-active-light'></BiCompass>}
        title={intl.formatMessage({ id: 'MENU.EXPLORER' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/meus-imoveis'
        onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'listagem', action: 'list', url: '/meus-imoveis' })}
        icon={<BiMapPin className='text-gray-700'></BiMapPin>}
        title={intl.formatMessage({ id: 'MENU.MEUSIMOVEIS' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/empreendimentos'
        onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'empreendimentos', funcionalidade: 'listagem', action: 'list', url: '/empreendimentos' })}
        icon={<BiBuildings className='text-gray-700'></BiBuildings>}
        title={intl.formatMessage({ id: 'MENU.EMPREENDIMENTOS' })}
        fontIcon='bi-app-indicator'
      />

      {/* <AsideMenuItem
        to='/analise-mercado'
        onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'analise-mercado', funcionalidade: 'listagem', action: 'list', url: '/analise-mercado' })}
        icon={<BiBarChart className='text-gray-700'></BiBarChart>}
        title={intl.formatMessage({ id: 'MENU.ANALISE-MERCADO' })}
        fontIcon='bi-app-indicator'
      />
*/}
      <AsideMenuItem
        to='/inteligencia-mercado'
        onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'inteligencia-mercado', funcionalidade: 'listagem', action: 'list', url: '/inteligencia-mercado' })}
        icon={<BiBarChart className='text-gray-700'></BiBarChart>}
        title={intl.formatMessage({ id: 'MENU.INTELIGENCIA-MERCADO' })}
        fontIcon='bi-app-indicator'
      /> 

      <AsideMenuItem
        to='/pessoas'
        onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'pessoas', funcionalidade: 'listagem', action: 'list', url: '/pessoas' })}
        icon={<AiOutlineUserSwitch className='text-gray-700'></AiOutlineUserSwitch>}
        title={intl.formatMessage({ id: 'MENU.PESSOAS' })}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Serviços e Relatórios</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Cadastro'
        icon={<BiCog className='text-gray-700'></BiCog>}
      >

        <AsideMenuItem
          onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'corretores', action: 'list', url: '/cadastro/corretores' })}
          to='/cadastro/corretores' title={intl.formatMessage({ id: 'MENU.CORRETORES' })} hasBullet={true} />
        <AsideMenuItem
          onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'proprietarios', action: 'list', url: '/cadastro/proprietarios' })}
          to='/cadastro/proprietarios' title={intl.formatMessage({ id: 'MENU.PROPRIETARIOS' })} hasBullet={true} />
        <AsideMenuItem
          onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'grupos-servicos', action: 'list', url: '/cadastro/cadastro-grupos' })}
          to='/cadastro/cadastro-grupos' title={intl.formatMessage({ id: 'MENU.CADASTROGRUPOS' })} hasBullet={true} />
        <AsideMenuItem
          onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'situacao-terrenos', action: 'list', url: '/cadastro/situacao-terrenos' })}
          to='/cadastro/situacao-terrenos' title={intl.formatMessage({ id: 'MENU.SITUACAOTERRENOS' })} hasBullet={true} />

      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Relatórios'
        icon={<BiFile className='text-gray-700'></BiFile>}
      >
        <AsideMenuItem
          onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'relatorios', action: 'list', url: '/meus-imoveis/relatorios' })}
          to='/meus-imoveis/relatorios' title={intl.formatMessage({ id: 'MENU.MEUSIMOVEISRELATORIOS' })} hasBullet={true} />
        <AsideMenuItem
          onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'empreendimentos', funcionalidade: 'relatorios', action: 'list', url: '/empreendimentos/relatorios' })}
          to='/empreendimentos/relatorios' title={intl.formatMessage({ id: 'MENU.EMPREENDIMENTOSRELATORIOS' })} hasBullet={true} />

      </AsideMenuItemWithSub>


      {secureLocalStorage.getItem('profile_id') === '1' ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Backoffice</span>
            </div>
          </div>
          <AsideMenuItemWithSub
            to='/apps/chat'
            title='Backoffice'
            icon={<BiWrench className='text-gray-700 '></BiWrench>}
          >
            <AsideMenuItem
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'backoffice', funcionalidade: 'usuarios', action: 'list', url: '/backoffice/usuarios' })}
              to='/backoffice/usuarios' title={intl.formatMessage({ id: 'MENU.USUARIOS' })} hasBullet={true} />
            <AsideMenuItem
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'backoffice', funcionalidade: 'empresas', action: 'list', url: '/backoffice/empresas' })}
              to='/backoffice/empresas' title={intl.formatMessage({ id: 'MENU.EMPRESAS' })} hasBullet={true} />
            <AsideMenuItem
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'backoffice', funcionalidade: 'cupons', action: 'list', url: '/backoffice/cupons' })}
              to='/backoffice/cupons' title={intl.formatMessage({ id: 'MENU.CUPONS' })} hasBullet={true} />
            <AsideMenuItem
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'backoffice', funcionalidade: 'pacotes', action: 'list', url: '/backoffice/pacotes' })}
              to='/backoffice/pacotes' title={intl.formatMessage({ id: 'MENU.PACOTES' })} hasBullet={true} />
            <AsideMenuItem
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'backoffice', funcionalidade: 'produtos', action: 'list', url: '/backoffice/produtos' })}
              to='/backoffice/produtos' title={intl.formatMessage({ id: 'MENU.PRODUTOS' })} hasBullet={true} />
            <AsideMenuItem
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'backoffice', funcionalidade: 'servicos', action: 'list', url: '/backoffice/servicos' })}
              to='/backoffice/servicos' title={intl.formatMessage({ id: 'MENU.SERVICOS' })} hasBullet={true} />
            <AsideMenuItem
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'backoffice', funcionalidade: 'explorer-layers', action: 'list', url: '/backoffice/explorer-layers' })}
              to='/backoffice/explorer-layers' title={intl.formatMessage({ id: 'MENU.EXPLORERLAYERS' })} hasBullet={true} />
            <AsideMenuItem
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'backoffice', funcionalidade: 'news-feed', action: 'list', url: '/backoffice/news-feed' })}
              to='/backoffice/news-feed' title={intl.formatMessage({ id: 'MENU.NEWSFEED' })} hasBullet={true} />
            <AsideMenuItem
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'backoffice', funcionalidade: 'servicos', action: 'list', url: '/backoffice/servicos' })}
              to='/backoffice/servicos' title={intl.formatMessage({ id: 'MENU.LAYERPNG' })} hasBullet={true} />
            <AsideMenuItem
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'backoffice', funcionalidade: 'dicionario', action: 'list', url: '/backoffice/dicionario' })}
              to='/backoffice/dicionario' title={intl.formatMessage({ id: 'MENU.DICIONARIO' })} hasBullet={true} />
            <AsideMenuItem
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'backoffice', funcionalidade: 'termos', action: 'list', url: '/backoffice/termos' })}
              to='/backoffice/termos' title={intl.formatMessage({ id: 'MENU.TERMOS' })} hasBullet={true} />
          </AsideMenuItemWithSub>

        </>
      ) : (
        ''
      )}
    </>
  )
}
