// import axios from 'axios'

// const configValue : string | undefined = process.env.REACT_APP_API_URL_EXTRA_SERVICES

// // export const api = axios.create({
// //   baseURL: configValue
// // })

// // // export default ApiRoute;

export function ApiRoute(json, tipo, vehicle, callback) {
  fetch(`https://api.openrouteservice.org/v2/${tipo}/${vehicle}`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer 5b3ce3597851110001cf6248a34ff658f66444fdad78ce7c3cb7bb01',
      // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZTBmZTA2NzkzMmVkODQyODY5OTZjYjMiLCJyb2xlIjpbIlVzZXIiLCJBZG1pbiJdLCJpYXQiOjE2Mjg2OTUwNjR9.qJmY1qf0ioySnj0S9QizKfCSmL3jPYemPGJgEni60Kc',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(json),
  })
    .then((response) => {
      //.status
      if (response.status === 200) {
        return response.json()
      } else {
        return null
      }
    })
    .then((data) => {
      callback(data)
      // if (!data || typeof data === 'undefined') {

      //   setServicoResult({service: 'erro', message: data, itens: [], total: 0})
      // } else {
      //   setServicoResult(data)
      // }
    })
    .catch((err) => {
      console.log(err.message)

      // setServicoResult({service: 'error', message: err.message, itens: [], total: 0})
    })

  // return response
}
