export const BasicData = ({data, estrutura}) => {
  if (typeof data['Result'] !== 'undefined') {
    data = data['Result']
  } 

  let list = []
  for (var i in estrutura['coluns']) {
    for (var j in data[0]['BasicData']) {
      if (j === estrutura['coluns'][i]) {
        list.push({nome: j, valor: data[0]['BasicData'][j]})
      }
    }
  }

  return (
    <>
      <h3>{estrutura.titulo}</h3>
      <ul>
        {list?.map((item, index) => {
          return (
            <li key={index}>
              {item.nome} : {item.valor}
            </li>
          )
        })}
      </ul>
    </>
  )
}
