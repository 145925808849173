import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'

type Props = {
  id: number
}

export const Buttons: React.FC<Props> = ({id}) => {
 
  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <Link to={`/backoffice/servicos/edit/${id}`}
          className='btn btn-icon btn-light-success btn-sm me-1'>
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
        <button
          className='btn btn-icon btn-light-danger btn-sm' >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
