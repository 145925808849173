import {gql} from '@apollo/client'

export const GET_EMPREENDIMENTO_PROPRIETARIOS = gql`
  query GetEmpreendimentoProprietarios($idEmpreendimento: Float!) {
    GetEmpreendimentoProprietarios(idEmpreendimento: $idEmpreendimento) {
      cep
      cidade
      nome
      situacao
      telefone
      id_usuario_cadastro
      id_usuario_alteracao
      id
      endereco
      email
      cpf
      complemento
      cnpj
      empresa
      estado
      numero
      basic_data
      occupation_data
      emails_extended
      related_people_emails
      addresses_extended
      financial_data
      related_people
      profession_data
      phones_extended
      related_people_phones
      endereco_terreno
      id_terreno
      ph3a_data
    }
  }
`
