import {useEffect, useState} from 'react'
import '../../../main.css'
import {useLayer} from '../../../../../../hooks/useLayer'
import {Item} from './Itens/Index'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {BiPen} from 'react-icons/bi'
import Swal from 'sweetalert2'
import ProjetoInstance from '../../../Projeto'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {Explorer} from '../../Map/Explorer'

export function ProjectMenu() {
  const windowHeight = window.innerHeight - 315
  const [wsize, setWsite] = useState(windowHeight)
  const [nomeProjeto, setNomeProjeto] = useState('Meu Projeto')
  const [treeData, setTreeData] = useState([])
  const {layers} = useLayer()

  useEffect(() => {
    let treeDataInit = []
    if (layers.length > 0) {
      // eslint-disable-next-line array-callback-return
      layers.map((layer, y) => {
        treeDataInit.push({
          id: layer.id,
          uuid: layer.uuid,
          label: layer.nome,
          index: y,
          type: 'leaf',
          paint:
            layer.paint !== null && typeof layer.paint !== 'object'
              ? JSON.parse(layer.paint)
              : layer.paint,
          'type-layer': layer.type,
          fields: layer.explorer_tiles,
          description: layer.descricao_completa,
          visible: layer.visible,
          parentId: layer.parentId || null,
          chield: layers.filter((l) => l.parentId === layer.id).length > 0 ? true : false,
        })
      })
    }
    setTreeData(treeDataInit)
  }, [layers])

  window.addEventListener(
    'resize',
    function () {
      setWsite(window.innerHeight - 315)
    },
    true
  )
  function changeNome() {
    Swal.fire({
      title: 'Nome do Projeto',
      input: 'text',
      inputValue: nomeProjeto,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'Por favor, informe o nome do projeto'
        } else {
          setNomeProjeto(value)
          ProjetoInstance.setNome(value)
        }
      },
    })
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }
    const items = reorder(treeData, result.source.index, result.destination.index)
    Explorer.changePosition(items)
    setTreeData([...items])
  }

  return (
    <div className='ps-3' key={98}>
      {/* <button onClick={ (x)=>{  addFolderAction() } } className="form-control" value="asdas">Criar Pasta</button> */}
      <div className='d-flex justify-content-between'>
        <h1 className='px-2 py-3' id='nomeprojeto'>
          {nomeProjeto}
        </h1>
        <button
          className='btn btn-icon btn-active-color-success btn-active-light-success me-5'
          onClick={changeNome}
        >
          <BiPen className='fs-1 '></BiPen>
        </button>
        <input
          type='hidden'
          name='nomeProjeto'
          value={nomeProjeto}
          onChange={(e) => setNomeProjeto(e.target.value)}
        ></input>
      </div>

      <input
        className='form-control border-0 ps-2'
        onChange={(e) => e.target.value}
        placeholder='Buscar Camada'
        key={99}
      />
      <Scrollbars autoHide style={{width: '100%', height: wsize}} key={0}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='list'>
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className='rstm-tree-item-group'
                id='menu-layers'
              >
                {
                  // eslint-disable-next-line array-callback-return
                  treeData.map(function (item, y) {
                    if (item.chield === false && !item.parentId) {
                      return (
                        <Item
                          key={y}
                          index={item.index}
                          uuid={item.id}
                          label={item.label}
                          sub={false}
                          type={item['type-layer']}
                          visivel={item.visible}
                          listId={y}
                        />
                      )
                    } else {
                      if (item.chield === true) {
                        return (
                          <>
                            <Item
                              key={y}
                              index={item.index}
                              uuid={item.id}
                              label={item.label}
                              sub={false}
                              type={item['type-layer']}
                              visivel={item.visible}
                              listId={y}
                            />

                            {
                              // eslint-disable-next-line array-callback-return
                              treeData.map((layerChildren, x) => {
                                if (item.id === layerChildren.parentId) {
                                  return (
                                    <Item
                                      key={layerChildren.index}
                                      index={layerChildren.index}
                                      uuid={layerChildren.id}
                                      label={layerChildren.label}
                                      sub={true}
                                      type={layerChildren['type-layer']}
                                      visivel={layerChildren.visible}
                                      listId={y}
                                    />
                                  )
                                }
                              })
                            }
                          </>
                        )
                      }
                    }
                  })
                }
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </Scrollbars>
    </div>
  )
}
