import React from 'react';
import { ButtonsAction } from './ButtonsAction'
import { Loader } from '../../../../components/UI/Loader/Loader'
import Pagination from '../../../../components/UI/Pagination'
import { useState } from 'react'
import { ModalSetTermos } from './ModalSetTermos'
import { QueryGetTermos } from '../../../graphql/services/Termos';
import TextoCurto from '../../servicos/partials/TextoReduzido';

export function Table() {
  const [pages, setPages] = useState(0)

  const { data } = QueryGetTermos({ variables: { pagination: { pagina: pages, quantidade: 20 } } })

  if (!data) {
    return (
      <>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Textos</span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Até o momento 10 usuários registrados</span> */}
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          ></div>
          <div className='all-end'>
            <ModalSetTermos />
          </div>
        </div>
        {/* end::Header */}
        <div className='separator my-2'></div>
        <div>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-50px'># ID</th>
                  <th className='min-w-150px'>Titulo</th>
                  <th className='min-w-100px'>Breve Descrição</th>
                  <th className='min-w-100px text-end'>Ação</th>
                </tr>
              </thead>
              {/* end::Table head */}
              <tbody>
                <Loader text={'caregando...'}></Loader>
              </tbody>
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </>

    )
  }
  return (
    <>
      {/* begin::Header */}
      <div className='card-header border-0 px-6'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Termos</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Até o momento 10 usuários registrados</span> */}
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        ></div>
        <div className='all-end'>
          <ModalSetTermos />
        </div>
      </div>
      {/* end::Header */}
      <div className='separator my-2'></div>
      <div>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-50px'># ID</th>
                <th className='min-w-100px'>Nome</th>
                <th className='min-w-100px'>Titulo</th>
                <th className='min-w-100px'>Breve Descrição</th>
                <th className='min-w-100px text-end'>Ação</th>
              </tr>
            </thead>
            {/* end::Table head */}
            <tbody>
              {
                // eslint-disable-next-line array-callback-return
                data.GetTermos.map(function (termo, x) {
                  return (
                    <tr key={x}>
                      <td>{termo.id}</td>
                      <td>{termo.nome}</td>
                      <td>{termo.titulo}</td>
                      <td><TextoCurto texto={termo.texto} limiteCaracteres={90}  /></td>

                      <td>
                        <ButtonsAction id={termo.id} />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      <div className='all-end pb-6'>
        <Pagination setPages={setPages} pages={pages} total={data.GetTermos.length / 20} ></Pagination>
      </div>
      {/* begin::Body */}
    </>
  )
}
