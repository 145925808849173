import React, {useState} from 'react'
import {MultiSelect} from 'react-multi-select-component'
// import "./styles.css";

type Props = {
  idTipo: number
  onChange?
}

export const SelectTipoUsado: React.FC<Props> = ({idTipo, onChange}) => {
  const optionsGroup = [
    {label: 'Residencial Vertical', value: 'Residencial Vertical'},
    {label: 'Residencial Horizontal', value: 'Residencial Horizontal'},
    {label: 'Lojas - Fachadas ativas', value: 'Lojas - Fachadas ativas'},
    {label: 'Salas comerciais', value: 'Salas comerciais'},
    {label: 'Galpão/Logístico', value: 'Galpão/Logístico'},
    {label: 'Lote/Terreno', value: 'Lote/Terreno'},
    // { label: "Rural", value: "Rural" },
    {label: 'Flats', value: 'Flats'},
  ]

  const options = {
    'Residencial Vertical': ['apartamento', 'cobertura', 'lancamento'],
    'Residencial Horizontal': ['casa', 'casa-condominio', 'chacara'],
    'Lojas - Fachadas ativas': ['Loja-comercial', 'Ponto-comercial'],
    'Salas comerciais': ['Imovel-comercial', 'Sala-comercial'],
    Edifícios: ['edificio-residencial-comercial', 'Sala-comercial'],
    Flats: ['flat'],
    'Lote/Terreno': ['terreno'],
    'Galpão/Logístico': ['galpao-comercial'],
    Rural: ['fazenda', 'sitio'],
  }

  const [selected, setSelected] = useState([])

  return (
    <>
      <MultiSelect
        options={optionsGroup}
        value={selected}
        onChange={(e) => {
          let valor = []
          e.map(function (v) {
            let opt = options[v.value]
            valor = [...valor, ...opt]
            return valor
          })
          onChange(valor)
          setSelected(e)
        }}
        labelledBy={'Tipo'}
        isCreatable={false}
      />
    </>
  )
}
