import {useCallback, useState} from 'react'
import {createContext} from 'use-context-selector'
// import {v4 as uuid} from 'uuid'
interface Layer {
  tile: any
  type: any
  paint: any
  uuid: any
  id: string
  nome: string
  hashId: string
  posicao: Number
  layerParent?: Layer
  map: {}
  visible: boolean
}

// type ExplorerContextType = {
//   layers?: Layer[]
//   mapaBaseList?: MapaBase[]
//   explorer: {
//     map:{},
//     decker:{},
//     draw:{},
//     baseMap:{}
//   }
//   // explorerMap: {}
//   // explorerMapDraw: {}
//   mapaBaseActive:null
//   // onAddMapaBase: {}
//   // explorerDecker:{},
//   onAddLayer: (layer:any) => void
//   // onSetExplorerMap: (mapa:any) => void
//   // onSetMapaBaseActive: (mapa:any) => void
//   // onsetExplorerDecker: (mapa:any) => void
//   onUpdateLayer: (mapa:any) => void
//   // onSetExplorerMapDraw: (mapa:any) => void
//   onExplorer:(explorer:any) => void
//   onSetExplorer:(explorer:{}) => void
// }

export const ExplorerContext = createContext({})

export function ExplorerPorvider({children}) {
  const [explorer, setExplorer] = useState({
    map: {},
    decker: {},
    draw: {},
    baseMap: {},
  })

  const [history, setHistory] = useState({
    desenhos: {},
    camadas: {},
  })

  
  const [endereco, setEndereco] = useState({
    municipio: {},
    estado:{},
    bairro:{},
    logradouro:{},
    numero:{},
    cep:{},
    enderecoAtivo:null
  })

   const [mapaConfig, setMapaConfig] = useState({
    showContextMenu:false,
    showGeometryContextMenu:false,
    showClickInfo:false,
    position:{},
    info:{},
    showTerritorioMenu:false,
    showRelatorioMenu:false,
    showGeometryContextMenuArea:false,
    showAVM:false,
    modoComposicao:false,
    composicao:[],
    showEstatisticasMenu:false,
    layerSelected:null,
    geom:{},
    geomList:[],
    latLng:{},
    legendaLayerSelected:null,
    mapInfoBox:{
      show: false,
      componente:null,
      info:null
    },
    
   })


   const [explorerComposicao, setExplorerComposicao] = useState([])

  

  // const [explorerMap, setExplorerMap] = useState([])
  // const [explorerMapDraw, setExplorerMapDraw] = useState(null)
  // const [explorerDecker, setExplorerDecker] = useState(null)
  const [layers, setLayers] = useState<Layer[]>([])

  const onSetHistory = useCallback(
    (historyAdd: any) => {
      if (typeof historyAdd.desenhos !== 'undefined') {
        // eslint-disable-next-line no-self-assign
        history['draw'] = historyAdd.desenhos
      }

      if (typeof historyAdd.camadas !== 'undefined') {
        // eslint-disable-next-line no-self-assign
        history['camadas'] = historyAdd.camadas
      }
      setHistory(history)
    },
    [history]
  )


  


  const onSetExplorer = useCallback(
    (explorerItem: any) => {
      if (typeof explorerItem.draw !== 'undefined') {
        // eslint-disable-next-line no-self-assign
        explorer['draw'] = explorerItem.draw
      }

      if (typeof explorerItem.map !== 'undefined') {
        // eslint-disable-next-line no-self-assign
        explorer['map'] = explorerItem.map
      }

      if (typeof explorerItem.decker !== 'undefined') {
        // eslint-disable-next-line no-self-assign
        explorer['decker'] = explorerItem.decker
      }
      if (typeof explorerItem.baseMap !== 'undefined') {
        // eslint-disable-next-line no-self-assign
        explorer['baseMap'] = explorerItem.baseMap
      }

      if (typeof explorerItem.config !== 'undefined') {
        // eslint-disable-next-line no-self-assign
        explorer['config'] = explorerItem.config
      }

      setExplorer(explorer)
    },
    [explorer]
  )

  // const onSetExplorerMap = useCallback((mapaBaseAtivo) => {
  //   const mapaBaseActive2 = mapaBaseAtivo
  //   setExplorerMap(mapaBaseActive2)
  // }, [])

  // const onSetExplorerMapDraw = useCallback((mapaBaseAtivo) => {
  //   const mapaBaseActive2 = mapaBaseAtivo
  //   setExplorerMapDraw(mapaBaseActive2)
  // }, [])

  const onMapaConfig = useCallback((mapaconfigInput) => {
    // const mapaBaseActive2 = mapaBaseAtivo
// console.log(mapaconfigInput)  

    if (typeof mapaconfigInput.showContextMenu !== 'undefined') {
      mapaConfig['showContextMenu'] = mapaconfigInput.showContextMenu
    }

    if (typeof mapaconfigInput.showClickInfo !== 'undefined') {
      mapaConfig['showClickInfo'] = mapaconfigInput.showClickInfo
    }

    if (typeof mapaconfigInput.showTerritorioMenu !== 'undefined') {
      mapaConfig['showTerritorioMenu'] = mapaconfigInput.showTerritorioMenu
   
    }
    if (typeof mapaconfigInput.showRelatorioMenu !== 'undefined') {
      mapaConfig['showRelatorioMenu'] = mapaconfigInput.showRelatorioMenu
   
    }
    if (typeof mapaconfigInput.showEstatisticasMenu !== 'undefined') {
      mapaConfig['showEstatisticasMenu'] = mapaconfigInput.showEstatisticasMenu
   
    }
    if (typeof mapaconfigInput.showGeometryContextMenu !== 'undefined') {
      mapaConfig['showGeometryContextMenu'] = mapaconfigInput.showGeometryContextMenu
    }

    if (typeof mapaconfigInput.modoComposicao !== 'undefined') {
      mapaConfig['modoComposicao'] = mapaconfigInput.modoComposicao
    }

    if (typeof mapaconfigInput.composicao !== 'undefined') {
      mapaConfig['composicao'] = mapaconfigInput.composicao
    }

    if (typeof mapaconfigInput.showGeometryContextMenuArea !== 'undefined') {
      mapaConfig['showGeometryContextMenuArea'] = mapaconfigInput.showGeometryContextMenuArea
    }

    if (typeof mapaconfigInput.showAVM !== 'undefined') {
      mapaConfig['showAVM'] = mapaconfigInput.showAVM
    }
    if (typeof mapaconfigInput.geom !== 'undefined') {
      mapaConfig['geom'] = mapaconfigInput.geom
    }
    if (typeof mapaconfigInput.geomList !== 'undefined') {
      mapaConfig['geomList'] = mapaconfigInput.geomList
    }
    
    if (typeof mapaconfigInput.layerSelected !== 'undefined') {
      mapaConfig['layerSelected'] = mapaconfigInput.layerSelected
    }

    if (typeof mapaconfigInput.latLng !== 'undefined') {
      mapaConfig['latLng'] = mapaconfigInput.latLng
    }

    if (typeof mapaconfigInput.position !== 'undefined') {
      mapaConfig['position'] = mapaconfigInput.position
    }
    if (typeof mapaconfigInput.info !== 'undefined') {
      mapaConfig['info'] = mapaconfigInput.info
    }
    if (typeof mapaconfigInput.legendaLayerSelected !== 'undefined') {
      mapaConfig['legendaLayerSelected'] = mapaconfigInput.legendaLayerSelected
    }
    if (typeof mapaconfigInput.mapInfoBox !== 'undefined') {
      mapaConfig['mapInfoBox'] = mapaconfigInput.mapInfoBox
    }

    setMapaConfig({...mapaConfig})
    // console.log(mapaConfig)  
  }, [mapaConfig])


  const OnSetExplorerComposicao = useCallback(
    (composicaoInput: any) => {
      setExplorerComposicao([...composicaoInput] )
    }, []
  )


  const OnSetEndereco = useCallback((enderecoInput) => {

    if (typeof enderecoInput.municipio !== 'undefined') {
      endereco['municipio'] = enderecoInput.municipio
    }

    if (typeof enderecoInput.estado !== 'undefined') {
      endereco['estado'] = enderecoInput.estado
    }

    if (typeof enderecoInput.bairro !== 'undefined') {
      endereco['bairro'] = enderecoInput.bairro
    }

    if (typeof enderecoInput.logradoruo !== 'undefined') {
      endereco['logradoruo'] = enderecoInput.logradoruo
    }

    if (typeof enderecoInput.numero !== 'undefined') {
      endereco['numero'] = enderecoInput.numero
    }

    if (typeof enderecoInput.numero !== 'undefined') {
      endereco['cep'] = enderecoInput.cep
    }
    
    if (typeof enderecoInput.enderecoAtivo !== 'undefined') {
      endereco['enderecoAtivo'] = enderecoInput.enderecoAtivo
    }
    

    setEndereco({...endereco})
    // console.log(mapaConfig)  
  }, [endereco])


  
  const onAddLayer = useCallback((layer1) => {
    const layerItem = layer1
    //  console.log(layerItem)
    setLayers((layers) => {
    //  console.log(layers)
    //  console.log(layerItem)
      let l = [...layers, layerItem]
      // console.log(l)
      return l

    })
  }, [])

  const onUpdateLayer = useCallback((layer1) => {
    // const layerItem = layer1
    // console.log(layer1)
    setLayers((layers) => layer1)
  }, [])
  const onUpdateComposicao = useCallback((Composicao1) => {
    setExplorerComposicao([...Composicao1])
  }, [])
  return (
    <ExplorerContext.Provider
      value={{
        explorer,
        onSetExplorer,
        layers,
        onAddLayer,
        onUpdateLayer,
        history,
        onSetHistory,
        onMapaConfig,
        mapaConfig,
        OnSetExplorerComposicao,
        onUpdateComposicao,
        explorerComposicao,
        OnSetEndereco,
        endereco

      }}
    >
      {children}
    </ExplorerContext.Provider>
  )
}
