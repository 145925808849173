import { gql } from "@apollo/client";

export const GET_LOG_ACESSO_RECURSOS_BY_EMPRESA = gql`
query GetLogAcessoRecursosByEmpresa($pagination: Pagination!, $idEmpresa: Float!, $dataFim: String, $dataInicio: String, $filtro: filter) {
  GetLogAcessoRecursosByEmpresa(pagination: $pagination, id_empresa: $idEmpresa, data_fim: $dataFim, data_inicio: $dataInicio, filtro: $filtro) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      ferramenta
      funcionalidade
      id
      ip
      data_acesso
      user {
        name
        email
        cpf
        id
      }
    }
  }
}
`