import { gql } from "@apollo/client";

export const GET_SERVICO_PACOTE_RELATORIO = gql`
query GetServicoPacoteRelatorio($idMunicipio: Float!) {
  GetServicoPacoteRelatorio(idMunicipio: $idMunicipio) {
    id
    descricao
    nome
    servico_pacote {
      servico {
        id
        nome
        url_sistema
        url_api
      }
      
    }
  }
}
`