import { useContextSelector } from "use-context-selector";
import {ExplorerContext} from '../context/ExplorerContext'


export function useMapaConfig(){
    const mapaConfig = useContextSelector(ExplorerContext, mapa =>{
        return mapa['mapaConfig'] 
    } )
    const onMapaConfig = useContextSelector(ExplorerContext, mapa =>{
        return mapa['onMapaConfig'] 
    })
   return {mapaConfig, onMapaConfig}
}