import {Modal} from 'react-bootstrap'
import {Camadas} from './Camadas'
import {CamadasCliente} from './CamadasCliente'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useMapaConfig} from '../../../../../../../hooks/useMapaConfig'
import {Explorer} from '../../../Map/Explorer'

type Props = {
  handleClose: any
  show: boolean
  sublayer: boolean
  activeTab: string
}
export const ModalCamadas: React.FC<Props> = ({
  handleClose,
  show,
  sublayer = false,
  activeTab = 'camadas-urbit',
}) => {
  let nomeLayer = null
  const {mapaConfig} = useMapaConfig()
  if (sublayer) {
    const layerInfo = Explorer.getInfoLayer(mapaConfig.layerSelected)
    if (layerInfo) nomeLayer = layerInfo['nome']
  }
  //TODO: VErificar por que fica dando console log quando clica na formatação do layer
  // console.log(activeTab)
  if (!activeTab ){
    activeTab = 'camadas-urbit'
  }

  return (
    <Modal
      animation={false}
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-xl '
      show={show}
      onHide={handleClose}
    >
      <div className='container-xxl px-5 py-0'>
        <div className='modal-header d-flex border-0'>
          <div className='modal-title'>
            <h1>Adicionar nova Camada</h1>
          </div>
          <div className='btn btn-icon btn-sm btn-light-primary ' onClick={handleClose}>
            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-body py-0'>
         
          <ul
            className='nav nav-explorer d-flex justify-content-around mt-0'
            id='tab-camadas'
            role='tablist'
          >
            <li key='camadas-urbit' className='nav-item' role='presentation'>
              <button
                className={`nav-link ${activeTab === 'camadas-urbit' ? 'active' : ''}`}
                id='camadas-urbit'
                data-bs-toggle='pill'
                data-bs-target='#camadas-urbit-tab'
                type='button'
                role='tab'
                aria-controls='camadas-urbit-tab'
                aria-selected='true'
              >
                Camadas Urbit
              </button>
            </li>

            <li key='minhas-camadas' className='nav-item' role='presentation'>
              <button
                className={`nav-link  ${activeTab === 'minhas-camadas' ? 'active' : ''}`}
                id='minhas-camadas'
                data-bs-toggle='pill'
                data-bs-target='#minhas-camadas-tab'
                type='button'
                role='tab'
                aria-controls='minhas-camadas-tab'
                aria-selected='false'
              >
                Meus Locais
              </button>
            </li>
          </ul>
          <div className='tab-content' id='tabContent'>
            <div
              className={`tab-pane fade  ${activeTab === 'camadas-urbit' ? 'show active' : ''}`}
              id='camadas-urbit-tab'
              role='tabpanel'
              aria-labelledby='camadas-urbit-tab'
            >  {sublayer ? <h3 className="py-10">Adionar Sublayer para: {nomeLayer}</h3> : ''}
              <Camadas intersect={sublayer ? true : false} />
            </div>
            <div
              className={`tab-pane fade ${activeTab === 'minhas-camadas' ? 'show active' : ''} `}
              id='minhas-camadas-tab'
              role='tabpanel'
              aria-labelledby='minhas-camadas-tab'
            >
              <CamadasCliente />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
