import {useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import {ButtonVariant} from 'react-bootstrap/esm/types'

type Props = {
  icone: string | null
  texto: string
  variante: ButtonVariant
  onClick?: React.MouseEventHandler
}

export const ButtonHandleClickChangeIcon: React.FC<Props> = ({
  icone,
  texto,
  variante,
  onClick,
  children,
}) => {
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true
    function simulateNetworkRequest() {
      return new Promise((resolve) => setTimeout(resolve, 1000))
    }

    if (isLoading) {
      simulateNetworkRequest().then(() => {
        if (isMounted) setLoading(false)
      })
    }
    return () => {
      isMounted = false
    }
  }, [isLoading])

  const handleClick = () => setLoading(true)

  return (
    <Button
      variant={variante}
      disabled={isLoading}
      onClick={(e) => {
        if (!isLoading) {
          handleClick()
        }
        onClick(e)
      }}
    >
      {isLoading ? <span className='spinner-border text-primary' role='status'></span> : children}
    </Button>
  )
}
