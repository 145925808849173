import { InputHTMLAttributes } from 'react';
import { ErrorMessageComponent } from '../../../backoffice/components/UI/ErrorMessage';
import { useController, Control } from 'react-hook-form';

interface MoneyInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  control?: Control<any>;  // Usado com react-hook-form
  name: string;
  error?: any;
  hide?: boolean;
  valueDefault?: number;  // Corrigido para valueDefault
}

export function MoneyInput({ label, hide, control, valueDefault, name, error, ...props }: MoneyInputProps) {
  const { field } = useController({ name, control });

  // Função para formatar o valor como moeda
  const formatMoney = (value: number | string) => {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    return value ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '';
  };

  // Manipulador de mudança para garantir a formatação correta
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9]/g, ''); // Remove caracteres não numéricos
    const numberValue = parseFloat(value) / 100; // Converte para formato monetário
    field.onChange(numberValue); // Atualiza o valor no react-hook-form
  };

  return (
    <div className={hide ? 'hide' : 'form-group'} hidden={hide}>
      {label && <label className={hide ? 'd-none' : 'col-form-label'}>{label}</label>}
      <div className="input-group mb-3">
        <input
          {...props}
          {...field}
          className="form-control"
          placeholder="Ex: R$ 1.000,00"
          defaultValue={valueDefault}  // Corrigido para valueDefault
          value={formatMoney(field.value ?? valueDefault)} 
          onChange={handleChange}
        />
        <span className="input-group-text">R$</span>
      </div>
      {error && <ErrorMessageComponent message={error} />}
    </div>
  );
}
