import { LatLng } from "../../../../../components/util";
import { EmpreendimentoResponse } from "../../../../../graphql/type";
import { PainelImobiliarioContent } from "../../../../explorer/components/Map/LayoutControl/Relatorios/painelImobiliario/painel";


type Props = {
  data: EmpreendimentoResponse
  imoveis: any
  param: string
}

export const TabPainelMercadoEmpreendimento: React.FC<Props> = ({ imoveis, param }) => {

  let latlng = null
  if (imoveis.GetEmpreendimentoImovel.result.length > 0) {

    if (imoveis.GetEmpreendimentoImovel.result[0].terreno) {
      latlng = new LatLng(imoveis.GetEmpreendimentoImovel.result[0].terreno.latitude, imoveis.GetEmpreendimentoImovel.result[0].terreno.longitude)
    }
  }

  // if (!latlng){
  //   return ( <div className="tab-pane fade" id="TabPainelMercado" role="tabpanel" aria-labelledby="TabPainelMercado-tab">
  //   <div className='card'>
  //       <div className='card-header'>
  //         <h3 className='card-title'>Painel de Mercado</h3>
  //       </div>
  //       <div className='card-body pt-5'>
  //         <div className="row">
  //           <p>Nenhum Terreno cadastrado</p>
  //           </div>
  //           </div>
  //       </div>
  //       </div>)
  // }
  return (
    <div
      className={`tab-pane fade ${param === 'painel-mercado' ? ' show active' : ''}`}
      id="painel-mercado" role="tabpanel" aria-labelledby="painel-mercado">
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Painel de Mercado</h3>
        </div>
        <div className='card-body pt-5'>
          <div className="row">
            {latlng ?
              <PainelImobiliarioContent itens={[]} latLng={latlng} ></PainelImobiliarioContent>
              : <div className="text-center p-10">Nenhum registro encontrado</div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}