import { zodResolver } from "@hookform/resolvers/zod"
import { getCheckTokenVerifyFormData, getCheckTokenVerifyFormSchema, putEsqueciSenhaFormData, putEsqueciSenhaFormSchema, setRecuperarSenhaFormData, setRecuperarSenhaFormSchema } from "../formValidations/Senha"
import { useForm } from "react-hook-form"
import { TypesGetCheckTokenEsqueciSenhaResponse, TypesPutEsqueciSenhaResponse, TypesSetRecuperarSenhaResponse } from "../type/senha"
import { PUT_RECUPERAR_SENHA, SET_RECUPERAR_SENHA } from "../mutations/senha"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { useState } from "react"
import { GET_VERIFICAR_TOKEN } from "../query/senha"

interface Props {
  token: string
}


export function QueryCheckVerifyToken() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<getCheckTokenVerifyFormData>({
    resolver: zodResolver(getCheckTokenVerifyFormSchema),
  })

  const [checkVerifyToken, { loading, data, error }] = useLazyQuery<TypesGetCheckTokenEsqueciSenhaResponse>(GET_VERIFICAR_TOKEN, {

  })

  async function FormCheckTokenVerify(data: getCheckTokenVerifyFormData) {
    const email = localStorage.getItem('email')
    localStorage.setItem('tokenCode', data.tokenCode)
    try {
      await checkVerifyToken({
        variables: {
          tokenTrocaSenha: data.tokenCode,
          email: email
        },
      })
    } catch (error) {
      console.log(error)
    }


  }

  return { data, loading, error, FormCheckTokenVerify, register, handleSubmit, errors }

}

export function MutationSetRecuperarSenha() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<setRecuperarSenhaFormData>({
    resolver: zodResolver(setRecuperarSenhaFormSchema),
  })

  const [setRecuperarSenhaBody, { error, loading, data: DataSetRecuperarSenha }] =
    useMutation<TypesSetRecuperarSenhaResponse>(SET_RECUPERAR_SENHA)

  async function FormRecuperarSenha(data: setRecuperarSenhaFormData) {
    localStorage.setItem('email', data.email)
    try {
      await setRecuperarSenhaBody({
        variables: {
          usuario: {
            email: data.email
          }
        },
      })
    } catch (error) {
      console.log(error)
    }

  }

  return { register, handleSubmit, FormRecuperarSenha, loading, errors, error, DataSetRecuperarSenha }
}

export function MutationChangePassword() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<putEsqueciSenhaFormData>({
    resolver: zodResolver(putEsqueciSenhaFormSchema),
  })

  const [setRecuperarSenhaBody, { error, loading, data: DataPutAlterarSenha }] =
    useMutation<TypesPutEsqueciSenhaResponse>(PUT_RECUPERAR_SENHA)

  const getPasswordStrength = (password: string): number => {
    // Defina a lógica para calcular a força da senha aqui, considerando todas as quatro validações

    const lowerCaseRegex = /[a-z]/;
    const upperCaseRegex = /[A-Z]/;
    const numberRegex = /\d/;
    const specialCharRegex = /[@$!%*?&]/;

    let requisitosCumpridos = 0;

    // Verifica se a senha contém cada um dos requisitos individualmente
    if (password.length >= 8) requisitosCumpridos++;
    if (lowerCaseRegex.test(password)) requisitosCumpridos++;
    if (upperCaseRegex.test(password)) requisitosCumpridos++;
    if (numberRegex.test(password)) requisitosCumpridos++;
    if (specialCharRegex.test(password)) requisitosCumpridos++;

    const totalRequisitos = 5; // Total de requisitos (letra minúscula, letra maiúscula, número, caractere especial e pelo menos 8 caracteres)
    return (requisitosCumpridos / totalRequisitos) * 100;
  };

  async function FormAlterarSenha(data: putEsqueciSenhaFormData) {
    const email = localStorage.getItem('email')
    const tokenCode = localStorage.getItem('tokenCode')

    await setRecuperarSenhaBody({
      variables: {
        newPassword: data.password,
        email: email,
        tokenTrocaSenha: tokenCode
      },
    })

  }

  return { register, handleSubmit, FormAlterarSenha, loading, errors, error, DataPutAlterarSenha, getPasswordStrength, watch }
}


export function MutationReesendToken() {
  const [reesendToken, { loading, data, error }] = useMutation<TypesSetRecuperarSenhaResponse>(
    SET_RECUPERAR_SENHA)

  async function HandleReenviarToken() {
    const email = localStorage.getItem('email')

    try {
      const result = await reesendToken({
        variables: {
          usuario: {
            email: email
          }
        },
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return { HandleReenviarToken, loading, error, data }
}