import {useEffect, useState} from 'react'
import {APiUrbit} from '../../../services/api/apiUrbit'
import {AreaChart} from '../../../components/Charts/AreaChart'

type Props = {
  id: string
}

type ChartProps = {
  series: any[]
  categories: any[]
}

export const Chart1: React.FC<Props> = ({id}) => {
  const [result, setresult] = useState<ChartProps>({
    series: [],
    categories: [],
  })

  async function formatResult(result) {
    let labels = []
    let series = []
    console.log(result.itens)
    // eslint-disable-next-line array-callback-return
    result.itens.map(function (x) {
      labels.push(x.data_cadastro)
      series.push(Number(x.vendas_perc))
    })

    return {series: series, categories: labels}
  }
  useEffect(() => {
    async function getServico(idLancamento) {
      const api = new APiUrbit()
      const result = await api.get(
        'service/sales-flow-launches/-46.6/-23.6/?id=' + idLancamento
        // 'municipio/sao-paulo/fluxo-vendas-lancamento/-46.6/-23.6/?id=' + idLancamento
      )
      setresult(await formatResult(result))
      return result
    }

    getServico(id)
  }, [id])

  const data = [
    {
      name: '% Vendas',
      data: result.series,
    }
  ]

  return (
    <AreaChart
      series={data}
      categories={result.categories}
      title={'Percentual de venda no tempo'}
      subtitle={'Percentual de venda no tempo'}
    ></AreaChart>
  )
}
