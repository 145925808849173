/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

const Footer: FC = () => {
  return (

    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      <div className="content py-4 d-flex flex-lg-column" id="kt_footer">
        <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
          <div className="text-dark order-2 order-md-1">
            <span className="text-gray-400 fw-bold me-1">Created by</span>
            <a href="https://urbit.com.br/" target="_blank" rel="noreferrer" className="text-muted text-hover-primary fw-bold me-2 fs-6">Urbit</a>
          </div>
          <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
            <li className="menu-item">
              <a href="https://urbit.com.br/" target="_blank" rel="noreferrer" className="menu-link px-2">Sobre</a>
            </li>
            {/* <li className="menu-item">
              <a href="https://urbit.com.br/" target="_blank" rel="noreferrer" className="menu-link px-2">Suporte</a>
            </li> */}
            {/* <li className="menu-item">
              <a href="https://urbit.com.br/" target="_blank" rel="noreferrer" className="menu-link px-2">Planos</a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export { Footer }
