import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import './Buttons.scss';

// Definição das classes disponíveis e dos tamanhos de botão
const availableClasses = [
  'button-gray-heavy',
  'button-primary',
  'button-gray-blue',
  'button-primary',
  'button-secondary',
  'button-black',
  'button-gray-light',
  'button-danger',
  'button-info',
  'button-horizontal-gradient',
  'button-vertical-gradient',
] as const;

type AvailableClassName = typeof availableClasses[number];

// Tipos para a propriedade className e size
type ClassName = AvailableClassName;
type Size = 'lg' | 'md' | 'sm';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle?: ClassName; // Classes dos buttons predefinidos
  className?: string; // Classes não relacionadas aos buttons predefinidos
  size?: Size; // Tamanho do botão
  children?: ReactNode;
}

// Mapeamento dos tamanhos personalizados para suas respectivas classes de estilo
const buttonSizeMap: Record<Size, string> = {
  'lg': 'btn-lg',
  'md': 'btn-md',
  'sm': 'btn-sm',
};

export function Button({ buttonStyle, className, size = 'md', children, onClick, ...restProps }: Props) {
  // Gera a classe de tamanho com base na propriedade size
  const sizeClass = buttonSizeMap[size];

  // Combina as classes de estilo do botão
  const combinedClassName = `${buttonStyle ? `${buttonStyle} ` : ''}${className ? `${className} ` : ''}${sizeClass}`;

  return (
    <button  {...restProps} className={combinedClassName.trim()} onClick={onClick}>
      {children}
    </button>
  );
}
