import React from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { BiBookOpen } from 'react-icons/bi'
import { MutationDeleteExplorerUserProject } from '../../../../../../graphql/services/ExplorerLayerUsuarioProject'
// import { MutationDeleteImovelCorretores } from '../../../../../../graphql/services/ImovelCorretores'

type Props = {
  id: number
  setIdOpen?: any
}

export const Buttons: React.FC<Props> = ({ id, setIdOpen }) => {
  const { HandleDeleteExplorerUserProject, loading } = MutationDeleteExplorerUserProject()
//   if (loading) {
//     Swal.fire(
//       'Enviando Informações...','')
//     Swal.showLoading()
//   }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await HandleDeleteExplorerUserProject({ variables: { idExplorerLayer: id } }).then((result)=>{
          if (result) {
            Swal.fire('Sucesso!', 'Projeto deletado com sucesso.', 'success')
          }else{
            Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')  
          }
        }).catch(()=>{
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        })
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          onClick={(e) => {
            setIdOpen(id)
          }}
          className='btn btn-light-success btn-sm me-2'>
            <BiBookOpen className='fs-1'></BiBookOpen> Abrir
          {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' /> */}
        </button>
        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          className='btn btn-icon btn-light-danger btn-sm me-2' >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
