import { gql } from "@apollo/client";
// Busca de todos os funcionarios daquela empresa pelo ID do usuario
export const SET_FUNCIONARIOS = gql`
mutation SetFuncionario($funcionario: Funcionario!) {
    SetFuncionario(funcionario: $funcionario) {
      id
    }
  }
`

export const PUT_FUNCIONARIO= gql`
mutation PutFuncionario($funcionario: Funcionario!) {
  PutFuncionario(funcionario: $funcionario) {
    nome
  }
}`

export const DELETE_FUNCIONARIO = gql`
mutation DeleteFuncionario($funcionario: Funcionario!) {
  DeleteFuncionario(funcionario: $funcionario) {
    nome
  }
}
`