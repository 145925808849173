import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {mapStore} from '../../../../../../store/mapStore'

export class Meassure {
  active: boolean
  marker: any[]
  path: any[]
  infoWindow: any[]
  measureClickCount: number
  constructor() {
    this.active = false
    this.marker = []
    this.path = []
    this.infoWindow = []
    this.measureClickCount = 0
  }
  setActive(active) {
    const {map} = mapStore.getState()
    if (!active) {
      this.clearMarker()
    } else {
      map?._map.setOptions({draggableCursor: 'crosshair'})
      this.active = active
    }
  }
  setMeassureActive() {
    const {map} = mapStore.getState()
    this.active = true
    map?._map.setOptions({draggableCursor: 'crosshair'})
  }
  setMeassureDeactive() {
    const {map} = mapStore.getState()
    this.active = false
    map?._map.setOptions({draggableCursor: ''})
  }

  controllClick(event:any, callback:any) {
    if (this.active === true) {
      this.setMeassureClickCount()
      this.addMark(this.getMeassureClickCount(), event.latLng.toJSON())
    }
    callback(this.marker, this.active)
  }
  setMarker(m: any) {
    this.marker.push(m)
  }
  setPath(m: any) {
    this.path.push(m)
  }
  setInfoWindow(m: any) {
    this.infoWindow.push(m)
  }
  clearMarker() {
    // eslint-disable-next-line array-callback-return
    this.marker.map((itens) => {
      itens.setMap(null)
    })
    this.marker = []
    // eslint-disable-next-line array-callback-return
    this.path.map((itens) => {
      itens.setMap(null)
    })
    this.path = []

    // eslint-disable-next-line array-callback-return
    this.infoWindow.map((itens) => {
      itens.setMap(null)
    })
    this.infoWindow = []
  }

  addPath(path) {
    const {map} = mapStore.getState()
    const Path = new google.maps.Polyline({
      path: path,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    })
    this.setPath(Path)
    Path.setMap(map._map)
  }
  drawLines() {
    let result = 0
    const {map} = mapStore.getState()
    // eslint-disable-next-line array-callback-return
    this.marker.map((item, index, itens) => {
      if (index > 0) {
        var infowindow = new google.maps.InfoWindow({
          content: 'Loading...',
          pixelOffset: new google.maps.Size(0, -30),
        })
        let p1 = itens[index - 1]
        let p2 = itens[index]
        const pathCoordinates = [
          {lat: p1.getPosition().lat(), lng: p1.getPosition().lng()},
          {lat: p2.getPosition().lat(), lng: p2.getPosition().lng()},
        ]
        result += this.haversine_distance(p1, p2)
        infowindow.setPosition(p2.getPosition())
        infowindow.setContent(`<div>Distância: ${(result * 1000).toFixed(0)}m</div>`)
        infowindow.open(map._map)
        this.setInfoWindow(infowindow)
        this.addPath(pathCoordinates)
     
      }
    })
  }
  addMark(id, latlng) {
    const {map} = mapStore.getState()

    const mark = new google.maps.Marker({
      icon: toAbsoluteUrl('/media/map008.svg'),
      position: latlng,
      map: map._map,
      title: 'p' + id,
      optimized: true,
    })

    this.setMarker(mark)
    this.drawLines()
  }

  haversine_distance(mk1, mk2) {
    var R = 6371 // Radius of the Earth in miles
    var rlat1 = mk1.getPosition().lat() * (Math.PI / 180) // Convert degrees to radians
    var rlat2 = mk2.getPosition().lat() * (Math.PI / 180) // Convert degrees to radians
    var difflat = rlat2 - rlat1 // Radian difference (latitudes)
    var difflon = (mk2.getPosition().lng() - mk1.getPosition().lng()) * (Math.PI / 180) // Radian difference (longitudes)

    var d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
        )
      )
    return d
  }
  getMarkers() {
    return this.marker
  }
  getMeassureClickCount() {
    return this.measureClickCount
  }
  setMeassureClickCount() {
    this.measureClickCount = this.measureClickCount + 1
  }
  resetMeassureClickCount() {
    this.measureClickCount = -1
  }
  clearMeassureClickCount() {
    this.measureClickCount = -1
  }
}
