import { gql } from "@apollo/client";

export const SET_IMOVEL_OBSERVACAO = gql`
mutation SetImovelObservacao($imovelObservacao: imovelObservacao!) {
  SetImovelObservacao(imovelObservacao: $imovelObservacao) {
      titulo
    }
  }
`
export const DELETE_IMOVEL_OBVERVACOES = gql`
mutation DeleteImovelObservacao($deleteImovelObservacaoId: Float!) {
  DeleteImovelObservacao(id: $deleteImovelObservacaoId) {
    titulo
  }
}
`

export const PUT_IMOVEL_OBSERVACAO = gql`
mutation PutImovelObservacao($imovelObservacao: imovelObservacao!) {
  PutImovelObservacao(imovelObservacao: $imovelObservacao) {
    titulo
  }
}
`