import React, { useEffect } from "react";
import { useLocation } from 'react-router'
import clsx from 'clsx'
import { useLayout } from '../../../_metronic/layout/core'
import { DrawerComponent } from '../../../_metronic/assets/ts/components'
import { Footer } from '../../../_metronic/layout/components/Footer'

import './sugestoes-usuarios.css'
import '../global.css'
import { Link } from "react-router-dom";

export function Sugestoes() {
  const { classes } = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])


  return (
    <div className=' d-flex flex-column flex-row-fluid pt-6' id='kt_wrapper'>
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>

            <div className="page-title d-flex flex-column align-items-start pt-6 justify-content-center flex-wrap me-lg-2 pb-2 pb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
              <Link to="/sugestoes-usuarios" className="fts-1-5 text-gray-600 fw-bolder all-center">
                <span className="svg-icon svg-icon-3 mr-1">
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-600" viewBox="0 0 448 512" fill="none">
                    <path opacity="0.8" fill="black" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z" /></svg>
                </span>
                Todas as sugestões
              </Link>
            </div>

            <div className="content  d-flex flex-column flex-column-fluid" id="kt_content" >
              <div className="row g-6 g-xl-9 mt-6">
                <div className="col-md-6 col-xl-6">
                  <div className="card border-hover-primary">
                    <div className="card-header border-0 pt-9">
                      <div className="fs-3 fw-bolder text-dark">Ter estimativa de condomínio</div>
                    </div>
                    <div className="card-body">
                      <strong className="fs-6 text-gray-400 fw-bolder">Descrição:</strong>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a galley of
                        type and scrambled.
                      </p>
                      <div className="d-flex flex-wrap mb-5">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                          <div className="fs-6 text-gray-800 fw-bolder">Jun 24, 2021</div>
                          <div className="fw-bold text-gray-400">Data de criação</div>
                        </div>
                      </div>
                      <div className="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="" data-bs-original-title="This project 50% completed">
                      </div>
                      <div className="w-100 all-center-between">
                        <span className="badge badge-light-primary fw-bolder me-auto px-4 py-3">In Progress</span>

                        <div className="card-toolbar d-flex">
                          <p className="text-gray-400 fw-bold fs-5">Sugerido por <strong>Fernando Souza</strong></p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6">
                  <div className="card border-hover-primary">
                    <div className="card-header border-0 pt-9">
                      <div className="fs-3 fw-bolder text-dark">Adicionar seu comentario:</div>
                    </div>
                    <div className="card-body">
                      <div className="d-flex flex-column flex-column-fluid">
                        <input type="text" className="fs-6 text-gray-600 fw-bolder mb-5 pb-2 borderInput" placeholder="Nome" />
                        <input type="text" className="fs-6 text-gray-600 fw-bolder mb-5 pb-2 borderInput" placeholder="Email" />
                      </div>
                      <strong className="fs-6 text-gray-600 fw-bolder">Descrição:</strong>
                      <textarea name="teste" className="w-100 borderInput fs-6 text-gray-600 fw-bolder  mb-8" id="test" style={{ resize: 'none', outline: 'none' }} cols={30} rows={1}></textarea>

                      <div className="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="" data-bs-original-title="This project 50% completed">
                      </div>
                      <div className="w-100 all-center-between">
                        <div className="card-toolbar d-flex">
                          <button className="btn btn-primary">
                            Post
                          </button>
                        </div>

                        <div className="">
                          <span className="btn btn-icon btn-sm btn-active-color-primary pe-0 me-2">
                            <span className="svg-icon svg-icon-3 mb-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z" fill="black"></path>
                                <path d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z" fill="black"></path>
                              </svg>
                            </span>
                          </span>
                          <span className="btn btn-icon btn-sm btn-active-color-primary ps-0">
                            <span className="svg-icon svg-icon-2 mb-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="black"></path>
                                <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="black"></path>
                              </svg>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-6 g-xl-9 mt-6">
                <div className="col-12">
                  <div className="card mb-5 mb-xxl-8">
                    <div className="card-body pb-0">
                      <div className="d-flex align-items-center mb-5">
                        <div className="d-flex align-items-center flex-grow-1">
                          <div className="symbol symbol-45px me-5">
                            <img src="assets/media/avatars/150-13.jpg" alt="" />
                          </div>
                          <div className="d-flex flex-column">
                            <a href="#vazio" className="text-gray-900 text-hover-primary fs-6 fw-bolder">Carles Nilson</a>
                            <span className="text-gray-400 fw-bold">Last week at 10:00 PM</span>
                          </div>
                        </div>
                        <div className="my-0">
                          <button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            <span className="svg-icon svg-icon-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
                                  <rect x="5" y="5" width="5" height="5" rx="1" fill="#000000"></rect>
                                  <rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
                                  <rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
                                  <rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
                                </g>
                              </svg>
                            </span>
                          </button>
                          <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3" data-kt-menu="true" >
                            <div className="menu-item px-3">
                              <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                            </div>
                            <div className="menu-item px-3">
                              <a href="#vazio" className="menu-link px-3">Create Invoice</a>
                            </div>
                            <div className="menu-item px-3">
                              <a href="#vazio" className="menu-link flex-stack px-3">Create Payment
                                <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Specify a target name for future usage and reference" aria-label="Specify a target name for future usage and reference"></i></a>
                            </div>
                            <div className="menu-item px-3">
                              <a href="#vazio" className="menu-link px-3">Generate Bill</a>
                            </div>
                            <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                              <a href="#vazio" className="menu-link px-3">
                                <span className="menu-title">Subscription</span>
                                <span className="menu-arrow"></span>
                              </a>
                              <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                <div className="menu-item px-3">
                                  <a href="#vazio" className="menu-link px-3">Plans</a>
                                </div>
                                <div className="menu-item px-3">
                                  <a href="#vazio" className="menu-link px-3">Billing</a>
                                </div>
                                <div className="menu-item px-3">
                                  <a href="#vazio" className="menu-link px-3">Statements</a>
                                </div>
                                <div className="separator my-2"></div>
                                <div className="menu-item px-3">
                                  <div className="menu-content px-3">
                                    <label className="form-check form-switch form-check-custom form-check-solid">
                                      <input className="form-check-input w-30px h-20px" type="checkbox" value="1" name="notifications" />
                                      <span className="form-check-label text-muted fs-6">Recuring</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="menu-item px-3 my-1">
                              <a href="#vazio" className="menu-link px-3">Settings</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-7">
                        <div className="text-gray-800 mb-5">Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors about driving and keep you focused on the overall structure of your post</div>
                        <div className="d-flex align-items-center mb-5">
                          <a href="#vazio" className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4">
                            <span className="svg-icon svg-icon-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="black"></path>
                                <rect x="6" y="12" width="7" height="2" rx="1" fill="black"></rect>
                                <rect x="6" y="7" width="12" height="2" rx="1" fill="black"></rect>
                              </svg>
                            </span>
                            22</a>
                          <a href="#vazio" className="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2">
                            <span className="svg-icon svg-icon-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18.3721 4.65439C17.6415 4.23815 16.8052 4 15.9142 4C14.3444 4 12.9339 4.73924 12.003 5.89633C11.0657 4.73913 9.66 4 8.08626 4C7.19611 4 6.35789 4.23746 5.62804 4.65439C4.06148 5.54462 3 7.26056 3 9.24232C3 9.81001 3.08941 10.3491 3.25153 10.8593C4.12155 14.9013 9.69287 20 12.0034 20C14.2502 20 19.875 14.9013 20.7488 10.8593C20.9109 10.3491 21 9.81001 21 9.24232C21.0007 7.26056 19.9383 5.54462 18.3721 4.65439Z" fill="black"></path>
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="separator mb-4"></div>
                      <form className="position-relative mb-6">
                        <textarea className="form-control border-0 p-0 pe-10 resize-none min-h-25px"
                          data-kt-autosize="true" placeholder="Reply.."
                          style={{ overflow: 'hidden', overflowWrap: 'break-word', height: '25px' }}>

                        </textarea>
                        <div className="position-absolute top-0 end-0 me-n5">
                          <span className="btn btn-icon btn-sm btn-active-color-primary pe-0 me-2">
                            <span className="svg-icon svg-icon-3 mb-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z" fill="black"></path>
                                <path d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z" fill="black"></path>
                              </svg>
                            </span>
                          </span>
                          <span className="btn btn-icon btn-sm btn-active-color-primary ps-0">
                            <span className="svg-icon svg-icon-2 mb-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="black"></path>
                                <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="black"></path>
                              </svg>
                            </span>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}