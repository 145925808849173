import { useQuery } from "@apollo/client";
import { GET_EMPREENDIMENTO_TAGS, GET_IMOVEL_TAGS } from "../query";
import { TypesGetImovelTagsResponse, TypesGetEmpreendimentoTagsResponse } from "../type";


interface Props {
    variables?: any;
}

export function QueryGetImovelTags() {
    const { data, loading, error } = useQuery<TypesGetImovelTagsResponse>(GET_IMOVEL_TAGS)

    return {data, loading, error}
}


export function QueryGetEmpreendimentoTags() {
    const { data, loading, error } = useQuery<TypesGetEmpreendimentoTagsResponse>(GET_EMPREENDIMENTO_TAGS)

      return {data, loading, error}
}
