import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { BiPlus, BiTrash } from 'react-icons/bi'
import { MutationCreateInteligenciaMercadoVSO } from '../../../graphql/services/inteligencia-mercado'
import Swal from 'sweetalert2'
import { errorHandler } from '../../../graphql/errorHandler'
import { MutationSetLogAcessoRecurso } from '../../../graphql/services/LogAcesso'
import { FaPlus } from 'react-icons/fa'
import { ErrorMessageComponent } from '../../../backoffice/components/UI/ErrorMessage'

export function ModalCreateVSO() {
  const [show, setShow] = useState(false)

  const { FormInteligenciaMercadoVSO, loading, setValue, handleSubmit, register, reset } = MutationCreateInteligenciaMercadoVSO()
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  function closeModal() {
    setShow(false)

  }

  function openModal() {
    setShow(true)
  }

  // Alterara valores para 36
  const totalFases = 37

  type PropsVSO = {
    vso: number,
    modify: boolean;
  }

  type PropsToDo = {
    percentual: number;
    mes: number;
    modify: boolean;

  }

  const onSubmit = async (data) => {
    await FormInteligenciaMercadoVSO(data).then((result) => {
      if (!result.data) {
        let erroText = errorHandler(result.errors)
        Swal.fire('Ocorreu um erro durante o processo.!', erroText, 'warning')
      } else {
        Swal.fire('Sucesso!', 'Dados salvos com sucesso!', 'success')

        let DataSetVSO = result.data

        setMes(null);
        setPercentual(null);
        setTodo([])
        reset()
        FormSetLogAcessoRecurso({ ferramenta: 'Inteligencia Mercado', funcionalidade: 'VSO', action: 'create', url: '/inteligencia-mercado' })
        closeModal()
      }
    })
      .catch((err) => {
        Swal.fire('Erro!', 'Ocorreu um erro inesperado, tente novamente mais tarde.', 'warning')
      })
  }

  const [todo, setTodo] = useState<PropsToDo[]>([])
  const [errorTodo, setErrorTodo] = useState('')
  const [mes, setMes] = useState(null);
  const [percentual, setPercentual] = useState(null);

  const [arrayDeObjetos, setArrayDeObjetos] = useState<PropsVSO[]>([...Array.from(
    { length: totalFases },
    (_, index) => {
      return { vso: Math.round(((index + 1) / totalFases) * 100), modify: false }
    }
  )]);

  function handleBlur(index: number, value: number) {
    const numericValue = value
    let mesesRestantes = totalFases - index
    let percetualNovo = (100 - numericValue) / mesesRestantes
    let mesesPassados = totalFases - mesesRestantes + 1
    let acumulador = numericValue
    for (var i = mesesPassados; i <= totalFases - 1; i++) {
      acumulador = acumulador + percetualNovo
      arrayDeObjetos[i].vso = i === 35 ? 100 : Math.round(acumulador)
    }
    setArrayDeObjetos([...arrayDeObjetos])
  }

  const handleChange = (index: number, value: number, modify: boolean) => {
    setArrayDeObjetos(prevArray => {
      const newArray = [...prevArray];
      newArray[index] = {
        ...newArray[index],
        vso: index === 35 ? 100 : value,
        modify: index === 35 ? true : modify
      };
      setArrayDeObjetos(newArray);
      setValue('vso', newArray);

      return newArray;

    });
  };


  // TODO ACTCIONS

  const handleMesChange = (event) => {
    setErrorTodo('')
    const index = parseInt(event.target.value);
    setMes(index);
    if (!isNaN(index) && index >= 0 && index < arrayDeObjetos.length) {
      setPercentual(arrayDeObjetos[index].vso);
    } else {
      setPercentual(null);
    }
  };

  const handlePercentualChange = (event) => {
    const newPercentual = parseInt(event.target.value);
    setPercentual(newPercentual);
  };


  const AddTodoList = () => {
    if (mes !== null && percentual !== null && percentual >= 0 && percentual <= 100) {
      const existingTodo = todo.find(item => item.mes === mes);
      if (existingTodo) {
        setErrorTodo('Já existe uma fase com esse mês.');
      } else {
        const previousTodos = todo.filter(item => item.mes < mes);
        const hasError = previousTodos.some(item => percentual <= item.percentual);
        if (mes > 0 && hasError) {
          setErrorTodo('Não é permitido um valor menor que uma fase anterior.');
        } else {
          const newTodo = {
            mes: mes,
            percentual: percentual,
            modify: true // Modificado para true ao adicionar
          };
          setTodo([...todo, newTodo]);
          setMes(null);
          setPercentual(null);
          setErrorTodo('');
          handleChange(mes, percentual, true);
          handleBlur(mes, percentual);
        }
      }
    } else {
      setErrorTodo('Preencha os campos corretamente.');
    }
  };

  const deleteTodoList = (index) => {
    const newTodos = [...todo];
    newTodos.splice(index, 1);
    setTodo(newTodos);
  };

  return (
    <>
      <button
        className='btn btn-sm btn-light-primary fs-2 me-1'
        onClick={openModal}
      >
        <BiPlus className='fs-3'></BiPlus>
        Novo VSO
      </button>
      <Modal backdrop="static" centered animation show={show} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Configuração VSO</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-xl' style={{ width: '100%' }}>
          <div className='row'>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className="row mb-6">
                  <div className="col-lg-12 col-md-12 col-sm-12 pt-lg-0 pt-md-4 pt-sm-4">
                    <div className="form-group">
                      <label htmlFor="" className="form-label">Nome:</label>
                      <input type="text" required className="form-control" {...register('nome')} />
                    </div>
                  </div>
                </div>

                <div className="row mb-8 all-center-between">
                  <div className="col-lg-4 col-md-12 col-sm-12 pt-lg-0 pt-md-4 pt-sm-4">
                    <div className="form-group">
                      <label htmlFor="" className="form-label">Fase do Projeto:</label>
                      <select className='form-control' name="" id="" onChange={handleMesChange}>
                        <option value="">Selecione</option>
                        {arrayDeObjetos.map((value, index: number) => {
                          return (
                            <option value={index}> Mês {index + 1}</option>
                          )
                        })
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12 pt-lg-0 pt-md-4 pt-sm-4">
                    <div className="form-group">
                      <label htmlFor="" className="form-label">Percentual:</label>
                      <input
                        type='number'
                        value={percentual}
                        className='form-control'
                        placeholder='Percentual do Mês'
                        max={100}
                        onChange={(e) => {
                          handlePercentualChange(e);
                        }}
                      />

                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 pt-lg-0 pt-md-4 pt-sm-4 all-full-end ">
                    <button type='button' onClick={AddTodoList} disabled={loading} className='btn btn-success all-center gap-3'><FaPlus size={14} /> Adicionar</button>
                  </div>
                </div>
                {errorTodo ? <ErrorMessageComponent message={errorTodo} /> : ''}

                {todo.length > 0 ? (
                  <div className="row bg-light m-1 p-6 radius-4 flex-wrap ">

                    <span className='fs-3 mb-4'>Fases Adicionadas:</span>

                    {todo.sort((a, b) => a.mes - b.mes).map((response, index) => {
                      return (
                        <div className="px-2 py-2 col-lg-4 col-md-12 col-sm-12">
                          <div key={index} className="card radius-4 all-center-between p-3 flex-row bg-light-success text-success fw-bold ">
                            <span className='fts-1'>Fase Projeto: {response.mes + 1} <br /> Percentual: {response.percentual}</span>
                            <button className='btn btn-sm btn-secondary' type='button' onClick={() => deleteTodoList(index)}><BiTrash size={14} color='#666' /></button>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                ) : ('')}

                {todo.length > 0 ? (
                  <div className='w-100 all-end  pt-4 '>
                    <button type='submit' disabled={loading} className='btn d-block btn-success'>Salvar VSO</button>
                  </div>
                ) : ''}
              </form>
            </div>
          </div>
        </Modal.Body >

      </Modal >
    </>
  )
}
