import React from 'react';
import { toAbsoluteUrl } from '../../../helpers';

import './main.css'

type Props = {
  title: string,
  subtitle: string
}

const HeaderPage: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <>
      {/*begin:: Header*/}
      <div id="kt_header" className="header" data-kt-sticky="true" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
        {/*begin::Container*/}
        <div className="containerWrapper d-flex align-items-center justify-content-between " id="kt_header_container">
          {/*begin::Page title*/}
          <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-2 pb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
            {/*begin::Heading*/}
            <h1 className="text-dark fw-bolder my-0 fs-2">{title}</h1>
            {/*end::Heading*/}
            {/*begin::Breadcrumb*/}
            <ul className="breadcrumb fw-bold fs-base my-1">
              <li className="breadcrumb-item text-muted">
                <a href="../../demo7/dist/index.html" className="text-muted">Backoffice</a>
              </li>
              <li className="breadcrumb-item text-muted">Gestão</li>
              <li className="breadcrumb-item text-dark">{subtitle}</li>
            </ul>
            {/*end::Breadcrumb*/}
          </div>
          {/*end::Page title=*/}
          {/*begin::Wrapper*/}
          <div className="d-flex d-lg-none align-items-center ms-n2 me-2">
            {/*begin::Aside mobile toggle*/}
            <div className="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle">
              {/*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/}
              <span className="svg-icon svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                  <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Aside mobile toggle*/}
            {/*begin::Logo*/}
            <a href="../../demo7/dist/index.html" className="d-flex align-items-center">
              <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo.png')} className="h-30px" />
            </a>
            {/*end::Logo*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Toolbar wrapper*/}
        </div>
      </div>
      {/*end:: Header*/}
    </>
  );
}

export {HeaderPage};
