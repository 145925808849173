import { gql } from "@apollo/client";
// Busca de todos os funcionarios daquela empresa pelo ID do usuario
export const GET_FUNCIONARIOS = gql`
query GetFuncionario($pagination: Pagination!) {
    GetFuncionario(pagination: $pagination){
        id
        nome
        email
        cpf_cnpj
        rg
        id_cidade
        id_estado
    }
  }
`

export const GET_FUNCIONARIO_BY_ID = gql`
query GetFirstFuncionario($funcionario: Funcionario!) {
  GetFirstFuncionario(funcionario: $funcionario) {
    id
    nome
    email
    cpf_cnpj
    rg
    id_user
    id_cidade
    id_estado
  }
}
`