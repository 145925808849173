import {Text, View} from '@react-pdf/renderer'
import {Fragment} from 'react'
import {DecimalFormat} from '../../util'
import {styles} from './style'

export const Info = ({data}) => {
  return (
    <Fragment>
      <View style={styles.card}>
        <Text style={[styles.h1, styles.title]}>Informações do Lote</Text>
        <Text style={styles.subtitulo}>Endereço</Text>
        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={[styles.h4, styles.bold]}>
                Endereço:{' '}
                <Text style={styles.h4}>
                  {data.GetImovel.endereco},{data.GetImovel.numero}
                </Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Bairro: <Text style={styles.h4}>{data.GetImovel.bairro}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Cidade: <Text style={styles.h4}>{data.GetImovel.cidade}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Estado: <Text style={styles.h4}>{data.GetImovel.estado}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                CEP: <Text style={styles.h4}>{data.GetImovel.cep}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Número Contribuinte:{' '}
                <Text style={styles.h4}>{data.GetImovel.numero_contribuinte}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Área Iptu: <Text style={styles.h4}>{DecimalFormat(data.GetImovel.area_iptu)}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Área real: <Text style={styles.h4}>{DecimalFormat(data.GetImovel.area_real)}</Text>
              </Text>
            </View>
          </View>
        </View>

        <Text style={styles.subtitulo}>Parâmetros</Text>
        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Setor quadra: <Text style={styles.h4}>{data.GetImovel.setor_quadra}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Cod. Log: <Text style={styles.h4}>{data.GetImovel.cod_log}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                FPR: <Text style={styles.h4}>{data.GetImovel.fpr}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                FPNR: <Text style={styles.h4}>{data.GetImovel.fpnr}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Cabas: <Text style={styles.h4}>{data.GetImovel.cabas}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Camax: <Text style={styles.h4}>{data.GetImovel.camax}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Quadro 14: <Text style={styles.h4}>{data.GetImovel.quadro14}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Testada <Text style={styles.h4}>{data.GetImovel.testada}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Operação Urbana: <Text style={styles.h4}>{data.GetImovel.operacao_urbana}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.col6}>
            <View style={styles.groupInfo}>
              <Text style={styles.h4}>
                Uso atual do terreno:{' '}
                <Text style={styles.h4}>{data.GetImovel.uso_atual_terreno}</Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Fragment>
  )
}
