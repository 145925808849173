/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers/Utils'
import { TabsNavigation } from './MarketPlaces/TabsNavigation'
import { useLayout } from '../../../../_metronic/layout/core'
import { KTSVG } from '../../../../_metronic/helpers'
// import { Toolbar } from '../toolbar/Toolbar'


export default function Header() {
  const { config, classes, attributes } = useLayout()
  const { aside } = config

  return (
    <div
    id='kt_header'
    className={clsx('header relative bg-white border-bottom', classes.header.join(' '), 'align-items-stretch')}
    
    {...attributes.headerMenu}
  >
    <div
      className={clsx(
        classes.headerContainer.join(' '),
        'd-flex align-items-stretch justify-content-between'
      )}
    >
      {/* begin::Aside mobile toggle */}
      {aside.display && (
        <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_aside_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
          </div>
        </div>
      )}
      {/* end::Aside mobile toggle */}
      {/* begin::Logo */}
      {!aside.display && (
        <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
          <Link to='/dashboard' className='d-lg-none'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-30px' />
          </Link>
        </div>
      )}


      {/* begin::Wrapper */}
      <div className='d-flex align-items-center justify-content-between flex-lg-grow-1'>
        {/* begin::Navbar */}
        <div className='d-flex align-items-center me-4' id='kt_header_nav'>
          {/* <h1>{title}</h1> */}
          <div className='aside-logo lg-d-flex align-items-center py-10' id='kt_aside_logo'>

          </div>
        </div>

        <div className='d-flex align-items-center flex-shrink-0'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-50px pb-6' />
        </div>
      </div>
    </div>
  </div>
  )
}
