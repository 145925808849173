interface Props {
  features: string
  id: number
}

export function Row({features, id}: Props) {
  const featuresArray = features.split(',') // Dividindo a string em um array usando ',' como separador

  return (
    <tr className='hide ' id={`detalhes_${id}`}>
      <td colSpan={12} className='flex flex-wrap'>
        {featuresArray.map((feature) => (
          <span className='badge badge-light-primary me-2 my-2' key={feature}>
            {feature}
          </span> // Usando 'map()' para renderizar cada item do array como um parágrafo
        ))}
      </td>
    </tr>
  )
}
