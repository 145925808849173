import { useMutation, useQuery } from "@apollo/client";
import { TypesDeleteCupomResponse, TypesGetCupomResponse, TypesGetCuponsResponse, TypesPutCupomResponse, TypesSetCupomResponse } from "../types";
import { GET_CUPOM_BY_ID, GET_CUPONS } from "../querys";
import { DELETE_CUPOM, PUT_CUPOM, SET_CUPOM } from "../mutation";
import { useForm } from "react-hook-form";
import { CupomFormCreateData, CupomFormCreateSchema, CupomFormPutData, CupomFormPutSchema } from "../../formValidations";
import { zodResolver } from "@hookform/resolvers/zod";



interface CuponsProps {
  variables: any
}

export function QueryGetCupomByID({ variables }: CuponsProps) {
    const { data, loading } = useQuery<TypesGetCupomResponse>(GET_CUPOM_BY_ID, {
        variables: { ...variables },
    })

    return {data, loading}
}

export function QueryGetCupons({ variables }: CuponsProps) {

    const { data, loading, error } = useQuery<TypesGetCuponsResponse>(GET_CUPONS, {
        variables: { ...variables }
    })

  return {data, loading, error}
}

export function MutationDeleteCupom() {
  const [deleteCupomBody, {loading}] = useMutation<TypesDeleteCupomResponse>(DELETE_CUPOM)


  
  async function HandleDeleteCupom({variables}: CuponsProps) {
    const result = await deleteCupomBody({
      variables: {...variables},
      refetchQueries: [GET_CUPONS],
    })

    return result
  }

    return { HandleDeleteCupom, loading }
}

export function MutationSetCupom() {
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        control,
        formState: { errors }
    } = useForm<CupomFormCreateData>({
        resolver: zodResolver(CupomFormCreateSchema)
    });

    console.log(errors)

    const [setCupomBody, { loading, error, data: DataSetCupom }] = useMutation<TypesSetCupomResponse>(SET_CUPOM)

    
    async function FormSetCupom(data: CupomFormCreateData) {
        await setCupomBody({
            variables: {
                cupom: {...data}
            },
            refetchQueries: [GET_CUPONS]
        })
    };

    return { register, handleSubmit, errors, FormSetCupom, loading, error, 
        DataSetCupom, setValue, setError, control }
}


export function MutationPutCupom() {
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        control,
        formState: { errors }
    } = useForm<CupomFormPutData>({
        resolver: zodResolver(CupomFormPutSchema)
    });

    console.log(errors)

    const [putUsuario, {loading,error, data: DataPutCupom}] = useMutation<TypesPutCupomResponse>(PUT_CUPOM)

    async function FormPutCupom(data: CupomFormPutData) {
        const id = data.id
        delete data.id
        await putUsuario({
            variables: {
                putCupomId: id,
                cupom: {...data},
            },
            refetchQueries: [GET_CUPONS],
        })
    }

    return {
        FormPutCupom, errors, register, handleSubmit, loading, DataPutCupom,error,
        setError, setValue, control
    }
}