import React, {useEffect, useState} from 'react'
import {BiAddToQueue} from 'react-icons/bi'
import {FormInputAndLabel} from '../../../../components/UI/Inputs/FormInputAndLabel'
import Swal from 'sweetalert2'
import {MutationCreateExplorerLayer} from '../../../graphql'
import {FormTextareaAndLabel} from '../../../../components/UI/Inputs/FormTextareaAndLabel'
import {GetEstadoCidadeChosen} from '../../../../components/UI/Chosens/GetEstadoCidadeChosen'
import {AsideDefault} from '../../../../../_metronic/layout/components/aside/AsideDefault'
import {HeaderWrapperFluid} from '../../../../../_metronic/layout/components/header/HeaderWrapperFluid'
import {APiUrbit} from '../../../../services/api/apiUrbit'
import {GetTablesGisChosen} from '../../../components/UI/GetTablesGis'
import {Tr} from './Tr'
import {errorHandler} from '../../../../graphql/errorHandler'
import {setExplorerLayerFormData} from '../../../formValidations'
import {Link, useHistory} from 'react-router-dom'
import {MultiSelectTagsCreate} from './MultiSelectTagsCreate'

export function AdminExplorerLayersCreate() {
  const [tags, setTags] = useState([])
  const navigate = useHistory()
  console.log(tags)
  const {errors, handleSubmit, register, submitForm, setValue} = MutationCreateExplorerLayer()
  const [apiRes, setApiRes] = useState('')

  interface TableColunas {
    character_maximum_length: number
    column_name: string
    data_type: string
    type_data?: string
    data_return?: string
    step?: string
  }
  const [dataColunas, setDataColunas] = useState<TableColunas[] | null>(null) // Inicialize o estado com um array vazio para os dados da API
  const [selectedValue, setSelectedValue] = useState('')
  const [table_name, setTableName] = useState('')
  const [tableSchema, setTableSchema] = useState('')

  async function getColunas() {
    const api = new APiUrbit()
    const result = await api.get(`explorer/tables-info?table=${table_name}&schema=${tableSchema}`)

    setDataColunas(result)
  }
  async function generateFiles(idLayer) {
    Swal.fire('Gerar arquivo de configuração no servidor...', '')
    Swal.showLoading()
    const api = new APiUrbit()
    const result = await api.get(`explorer/operation/gererate-files?idLayer=${idLayer}`)
    Swal.close()
  }

  useEffect(() => {
    if (table_name && tableSchema) {
      getColunas()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table_name, tableSchema])

  const handleSelectChange = (value) => {
    setSelectedValue(value)
    const [newTableSchema, newTableName] = value.split('.')
    setTableName(newTableName)
    setTableSchema(newTableSchema)
    setValue('schema_table', newTableSchema)
    setValue('url', newTableName)
    setValue('source', newTableName)
    setValue('source_layer', newTableName)
  }

  const onSubmit = async (data: setExplorerLayerFormData) => {
    data['explorer_tiles'] = []
    data['explorer_layer_tags'] = []

    // eslint-disable-next-line array-callback-return
    data.column.map((el) => {
      data['explorer_tiles'].push({
        schema_name: data.schema_table,
        table_name: data.source,
        table_column: el.coluna,
        id_column: el.id ? el.coluna : undefined,
        data_return: el.data || undefined,
        description: el.descricao,
        select_columns: el.select ? el.coluna : undefined,
        situacao: 1,
        type_data: el.type ? el.type : undefined,
        step: el.step ? Number(el.step) : undefined,
        filtro_principal: el.filtro_principal ? el.coluna : undefined,
      })
    })

    // eslint-disable-next-line array-callback-return
    data.tags.map((el) => {
      data['explorer_layer_tags'].push({
        id_tag: el.id_tag,
      })
    })

    delete data.tags
    delete data.column

    submitForm(data)
      .then((result) => {
        if (!result.data) {
          let erroText = errorHandler(result.errors)
          Swal.fire('Erro!', erroText, 'error')
        } else {
          generateFiles(result.data.SetExplorerLayer.id)
          Swal.fire('Sucesso!', 'Explorer Layer criado com sucesso.', 'success').then(() => {
            navigate.push('/backoffice/explorer-layers')
          })
        }
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  function onupdate(campo, valor) {
    setValue(campo, valor)
  }

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                <div className='card mrt-2 mb-10'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h3>Criar Layer</h3>
                    </div>
                  </div>

                  <div className='border-0 pt-6 ps-5 pe-5'>
                    <div className='row gy-5 gx-xl-8'>
                      <div className='col-xl-12'>
                        <div className='card-xxl-stretch mb-5 mb-xl-8'>
                          <h2>Criar Layer:</h2>
                          <div className='form-group'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className='row'>
                                <div className='col-md-6'>
                                  <label htmlFor='' className='col-form-label'>
                                    Selecionar Tabela
                                  </label>
                                  <GetTablesGisChosen
                                    onChange={handleSelectChange}
                                    valueDefault={selectedValue}
                                  ></GetTablesGisChosen>
                                </div>
                                <div className='col-6'>
                                  <FormInputAndLabel
                                    label='Schema'
                                    className='form-control form-control-solid'
                                    placeholder='Schema Table'
                                    register={register('schema_table', {required: true})}
                                    error={errors ? errors.schema_table?.message : ''}
                                    defaultValue={tableSchema}
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-6'>
                                  <FormInputAndLabel
                                    label='Nome'
                                    className='form-control form-control-solid'
                                    placeholder='Nome do Layer'
                                    register={register('nome', {required: true})}
                                    error={errors ? errors.nome?.message : ''}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <label htmlFor='' className='col-form-label'>
                                    Tipo
                                  </label>
                                  <select
                                    className='form-control form-control-solid'
                                    {...register('type')}
                                  >
                                    <option value=''>--selecione--</option>
                                    <option value='MVTLayer'>MVTLayer</option>
                                    <option value='Fill'>Fill</option>
                                    <option value='Circle'>Circle</option>
                                    <option value='Geojson'>Geojson</option>
                                    <option value='Line'>Line</option>
                                    <option value='Symbol'>Symbol</option>
                                  </select>
                                  {errors ? errors.type?.message : ''}
                                </div>
                              </div>

                              <div className='row '>
                                <div className='col-md-6'>
                                  <FormInputAndLabel
                                    label='Url'
                                    className='form-control form-control-solid'
                                    placeholder='Ex: afforbility_sp'
                                    register={register('url', {required: true})}
                                    error={errors ? errors.url?.message : ''}
                                    defaultValue={table_name}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <FormInputAndLabel
                                    label='Source'
                                    className='form-control form-control-solid'
                                    placeholder='Source '
                                    error={errors ? errors.source?.message : ''}
                                    defaultValue={table_name}
                                    register={register('source', {required: true})}
                                  />
                                </div>
                              </div>
                              <div className='row '>
                                <div className='col-md-6'>
                                  <MultiSelectTagsCreate
                                    valueTags={tags}
                                    setValueTags={(value: []) => {
                                      setTags(value)
                                      setValue('tags', value)
                                    }}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <FormInputAndLabel
                                    label='Source Layer'
                                    className='form-control form-control-solid'
                                    placeholder='Source Layer'
                                    error={errors ? errors.source_layer?.message : ''}
                                    defaultValue={table_name}
                                    register={register('source_layer', {required: true})}
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-md-6'>
                                  <FormTextareaAndLabel
                                    label='Descrição Curta'
                                    rows={2}
                                    className='form-control form-control-solid'
                                    resize={true}
                                    register={register('descricao', {required: true})}
                                    error={errors ? errors.descricao?.message : ''}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <FormTextareaAndLabel
                                    label='Descrição Longa'
                                    rows={2}
                                    resize={true}
                                    register={register('descricao_completa', {required: true})}
                                    error={errors ? errors.descricao_completa?.message : ''}
                                    className='form-control form-control-solid'
                                  />
                                </div>
                              </div>
                              <div className='row mt-1'>
                                <GetEstadoCidadeChosen
                                  defaultValueCidade={''}
                                  defaultValueEstado={''}
                                  classNameCity={'col-6 col-xs-12 mt-1'}
                                  classNameEstado={'col-6 col-xs-12  mt-1'}
                                  SetValueEstado={(value) => {
                                    setValue('id_estado', Number(value))
                                  }}
                                  SetValueCidade={(value) => {
                                    setValue('id_municipio', Number(value))
                                  }}
                                />
                              </div>
                              <div className='row py-5'>
                                <div className='col-md-6 '>
                                  <div className='col-12 mrt-2'>
                                    <div className='col-md-6'>
                                      <label htmlFor='' className='col-form-label'>
                                        Situação
                                      </label>
                                      <select
                                        className='form-control form-control-solid'
                                        {...register('situacao', {valueAsNumber: true})}
                                      >
                                        <option value='0'>Inativo</option>
                                        <option value='1'>Ativo</option>
                                      </select>
                                      {errors ? errors.situacao?.message : ''}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='row  pb-10'>
                                <div className='col-md-6 '>
                                  <div className='col-3 mrt-2'>
                                    <input
                                      type='checkbox'
                                      className='me-2'
                                      {...register('nacional', {
                                        required: false,
                                      })}
                                    />
                                    <label htmlFor=''>Nacional</label>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <FormTextareaAndLabel
                                  rows={2}
                                  label='Paint'
                                  placeholder='CSS'
                                  className='form-control form-control-solid'
                                  resize={true}
                                  error={errors ? errors.paint?.message : ''}
                                  register={register('paint', {required: true})}
                                />
                              </div>

                              <div className='row mt-4'>
                                <div className='table-responsive'>
                                  {/* begin::Table */}
                                  <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                      <tr>
                                        <th className=''>Coluna</th>
                                        <th className=''>Coluna de Select</th>
                                        <th className=''>Coluna de id</th>
                                        <th className=''>Filtro Principal</th>
                                        <th className=''>Descrição da Coluna</th>
                                        <th className=''>Tipo de Valor</th>
                                        <th className=''>Data List</th>
                                        <th className=''>Step(range)</th>
                                        <th className=''>Sync</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {dataColunas ? (
                                        dataColunas.map((column, index) => {
                                          return (
                                            <Tr
                                              key={index}
                                              index={index}
                                              column={column}
                                              tableSchema={tableSchema}
                                              table_name={table_name}
                                              onUpdate={onupdate}
                                              register={register}
                                            ></Tr>
                                          )
                                        })
                                      ) : (
                                        <tr>
                                          <td colSpan={7} className='text-center'>
                                            <span>Selecione uma tabela.</span>
                                          </td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td colSpan={7} className='text-center'></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className='w-100 pt-6 all-end'>
                                <Link
                                  to='/backoffice/explorer-layers'
                                  type='button'
                                  className='btn btn-danger me-4'
                                >
                                  Cancelar
                                </Link>
                                <button type='submit' className='btn btn-success '>
                                  <BiAddToQueue size={20} className='fs-2 me-2' />
                                  Salvar
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
