import React, {useState} from 'react'
import {GetServicoPacoteRelatorioResponse} from '../../../../../../graphql/type/servicoPacoteRelatorio'
import {useQuery} from '@apollo/client'
import {GET_SERVICO_PACOTE_RELATORIO} from '../../../../../../graphql/query/servico-pacote-relatorio'
import Scrollbars from 'react-custom-scrollbars-2'
import {Layout} from '../../Layout'
import {Grupo} from './Grupo'
import {Offcanvas} from 'react-bootstrap'
import {MenuAction} from '../MenuAction'
import {Endereco} from '../../ComponentsControl/EnderecoControl/endereco'
type GeoJSON ={
  type:string
  coordinates:[]

}
type Props = {
  showHide: boolean
  latLng: google.maps.LatLng
  geom: GeoJSON
}

export const MenuRelatorio: React.FC<Props> = ({showHide, latLng, geom}) => {
  const windowHeight = window.innerHeight - 50
  const [wsize, setWsite] = useState(windowHeight)
  const [search, setSearch] = useState('')
  const handleClose = () => {
    Layout.setShowRelatorioMenu(false)
  }

  console.log(geom)
  
  window.addEventListener(
    'resize',
    function (event) {
      setWsite(window.innerHeight - 50)
    },
    true
  )
  const municipio = Endereco.getMunicipio()
  let idMunicipio = 5351
  if (municipio) {
    idMunicipio = municipio.id
  }
  const {data} = useQuery<GetServicoPacoteRelatorioResponse>(GET_SERVICO_PACOTE_RELATORIO, {
    variables: {idMunicipio: idMunicipio},
  })

  let filterData = []
  let filtroServico = []

  if (data) {
    //console.log(data.GetServicoPacoteRelatorio)
    filterData =
      search.length > 0
        ? // eslint-disable-next-line array-callback-return
          data.GetServicoPacoteRelatorio.filter((repo) => {
            // console.log(repo)
            filtroServico = repo.servico_pacote.filter(function (servico) {
              if (String(servico['nome']).toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                return true
              } else {
                return false
              }
            })
            if (
              repo.nome.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              filtroServico.length > 0
            ) {
              return true
            }
          })
        : data.GetServicoPacoteRelatorio
  }


  if (latLng == null || Object.entries(latLng).length === 0) {
    return <></>
  }
  const lat = latLng.lat()
  const lng = latLng.lng()
  // //console.log(showHide)
  return (
    <Offcanvas
      show={showHide}
      onHide={handleClose}
      id='offcanvas-direita-relatorio'
      backdrop='static'
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className='h1 ms-3'>Relatórios</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Scrollbars style={{width: '100%', height: wsize}}>
        <div style={{width: '100%', padding: '10px'}} className='menu-fit  p-0'>
          <MenuAction showHide={false} latLng={latLng}></MenuAction>
          <div className='' id='tabContent'>
            <div
              id='territorio'
              className='tab-pane fade show active px-5'
              role='tabpanel'
              aria-labelledby='territorio-tab'
            >
              <div>
                <div className='mb-0 mb-0'>
                  {/* <div className='d-flex flex-column  fv-row fv-plugins-icon-container p-5'>
                  <div className='form-group row'>
                    <label className='form-label col-form-label col-sm-2'> Distância</label>
                    <div className='col-sm-6'>
                      <select className='form-control'>
                        <option value='500'>em 500m</option>
                        <option value='1000'>em 1000m</option>
                        <option value='1500'>em 1500m</option>
                        <option value='2000'>em 2000m</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                  <input
                    className='form-control border-0 mt-2 mb-2'
                    id='busca'
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder='Buscar'
                  />
                </div>

                {/* <Scrollbars style={{width: '100%', height: wsize}}> */}
                  <div
                    className='accordion accordion-flush scroll'
                    id='accordionFlushExample_territorio'
                  >
                    {!data ? (
                      <>Carregando..</>
                    ) : (
                      // eslint-disable-next-line array-callback-return
                      filterData.map(function (response, x) {
                        return [
                          <div key={x} className=' titulo-servicos-grupo'>
                            <h2 className=' d-flex'  style={{float: 'left', paddingTop: '15px'}} >
                              {response['nome']}
                            </h2>
                          </div>,

                          response.servico_pacote.map(function (response2, y) {
                            if (
                              response2.servico['nome']
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) !== -1
                            ) {
                              return (
                                <div
                                  key={
                                    'acoordion4' +
                                    response.id +
                                    '_' +
                                    response2.servico.id +
                                    '_' +
                                    x +
                                    '_' +
                                    y
                                  }
                                  id={response.id + '_' + response2.servico.id + '_' + x}
                                  className='accordion-item border-radius-15 mb-3'
                                >
                                  <Grupo
                                    key={response.id + '_' + response2.servico.id + '_' + x}
                                    uuid={response.id + '_' + response2.servico.id + '_' + x}
                                    servico={response2.servico}
                                    label={response2.servico['nome']}
                                    tipoServico={2}
                                    lat={lat}
                                    lng={lng}
                                    distancia={response2.distancia}
                                    municipio={municipio.municipio_url}
                                    intersect={geom}
                                  ></Grupo>
                                </div>
                              )
                            } else {
                              return ''
                            }
                          }),
                        ]
                        // }
                      })
                    )}
                  </div>
                {/* </Scrollbars> */}
              </div>
            </div>
          </div>
        </div>
        </Scrollbars>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
