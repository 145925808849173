import { z } from "zod"

export const setCorretorFormSchema = z.object({
    id: z.string(),
    nome: z.string()
        .nonempty('O nome do corretor é obrigatório!'),
    email: z.string()
        .nonempty('Email do corretor é obrigatório!'),
    telefone: z.string(),
    empresa: z.string()
})

export type setCorretorFormData = z.infer<typeof setCorretorFormSchema>

export const putCorretorFormSchema = z.object({
    id: z.string(),
    nome: z.string()
        .nonempty('O nome do corretor é obrigatório!'),
    email: z.string()
        .nonempty('Email do corretor é obrigatório!'),
    telefone: z.string(),
    empresa: z.string()
})

export type putCorretorFormData = z.infer<typeof putCorretorFormSchema>