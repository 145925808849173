import {useState} from 'react'
import {SliderSingle} from '../../../../../components/UI/Slider'
import {Explorer} from '../Explorer'
import {ApiRoute} from '../../../../../../services/apiRoute'
import {Layout} from '../Layout'
import { LoaderButton } from '../../../../../components/UI/Loader/LoaderButton'

type PropsContextMenu = {
  latLng: google.maps.LatLng | null
  defaultValue: number
  addMarker: boolean
}

export const Isocota: React.FC<PropsContextMenu> = ({latLng, defaultValue, addMarker = false}) => {
  const [distancia, setDistancia] = useState(defaultValue)
  const [showLoader, setShowLoader] = useState(false)
  
  const onClickAdddistancia = () => {
    const lng = latLng.lng()
    const lat = latLng.lat()
    if (addMarker) {
      Explorer.addMarker(lat, lng, `Local - De  ${distancia}`, `distancia de  ${distancia}`)
    }
    const geom = {
      attributes: ['area', 'reachfactor', 'total_pop'],
      interval: distancia * 1000,
      location_type: 'start',
      locations: [[lng, lat]],
      range: [distancia * 1000],
      range_type: 'distance',
      area_units: 'm',
    }
    ApiRoute(geom, 'isochrones', 'foot-walking', function (data) {
      Explorer.addIscota(data.features[0], `distancia de  ${distancia}`)
      Layout.setShowGeometryMenu(false)
      setShowLoader(false)
    })
  }
  function onChange(value: any) {
    setDistancia(value)
  }
  let marks = {}
  marks[0] = '0km'
  // marks[] = 2
  // marks[10] = '10km'
  // marks[20] = '20km'
  marks[30] = '30km'
  return (
    <div className='modal fade' id='modal-isocota' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='modal-raui-toogle-label'>
              Isocota - Distância em km
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <p>Distância a partir de um ponto com um alcance em km, incluíndo barreiras e velocidade da via,  que delimita uma área de influência.</p>
            {/* <div className="row">
                <select name="modo" className='form-control' onChange={(value)=>{
                    //console.log(value.target.value                        )
                     setVeiculo(value.target.value )
                    
                    }}>
                    <option value=''>--selecione--</option>
                    <option value='driving-car'>Carro</option>
                    <option value='cycling-regular'>Bike</option>
                    <option value='foot-walking'>walking</option>
                </select>
            </div> */}
            <div className='row d-flex'>
              <label className='col-2  d-flex align-items-center'>Distância em km:</label>
              <div className='col-10'>
                <SliderSingle
                  defaultValue={defaultValue}
                  min={0}
                  max={30}
                  step={1}
                  onChange={onChange}
                  tipFormatter={(val) => `${val} km`} marks={marks}              ></SliderSingle>
                </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              className='btn btn-primary'
              onClick={() => {
                setShowLoader(true)
                onClickAdddistancia()
              }}
            >
               {showLoader ? <LoaderButton text={'Gerando...'}></LoaderButton> : 'Adicionar'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
