import TextoCurto from "../../../../../backoffice/pages/servicos/partials/TextoReduzido";
import { dadosFAQ } from "./dados";

export function RecentPosts() {
    return (
        <div className="m-0">
            <h4 className="text-dark mb-7">Recent Posts</h4>

            <div className="all-center-column mb-7">



                {dadosFAQ.MeusImoveis.map((item, index) => (
                    <div className="d-flex flex-stack">
                        <div className="symbol symbol-60px symbol-2by3 me-4">
                            <div className="symbol-label" style={{ backgroundImage: 'url(/metronic8/demo1/assets/media/stock/600x400/img-1.jpg)' }}></div>
                        </div>
                        <div className="m-0" key={index}>
                            <a href="#a" className="text-dark fw-bold text-hover-primary fs-6">{item.pergunta}</a>
                            <TextoCurto texto={item.resposta} limiteCaracteres={50} />
                        </div>
                        <br />
                    </div>

                ))}


            </div>


        </div>
    )
}