import React, { useState } from 'react'
import { QueryGetEmpreendimentos } from '../../../graphql'
import Pagination from '../../../components/UI/Pagination'
import { Link } from 'react-router-dom'
import { Loader } from '../../../components/UI/Loader/Loader'
import { KTSVG } from '../../../../_metronic/helpers'
import { formatNumber } from '../../explorer/components/common'
import { BiExport } from 'react-icons/bi'
import { ExportTable } from '../../../utils/ExportXLSX'
type Props = {
  active: boolean,
  filtro: any[]
  toggleFilter: any
  toggleInfos: any
}

const Table: React.FC<Props> = ({ active, filtro, toggleFilter, toggleInfos }) => {
  let result

  if (active === true) {
    result = 0
  } else if (active === false) {
    result = 1
  }

  const [checkDownload, setCheckDownload] = useState([])

  const isChecked = (id: number) => {
    return checkDownload.includes(id);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const checked = event.target.checked;
    if (checked) {
      setCheckDownload((prevCheckDownload) => [...prevCheckDownload, id]);
    } else {
      setCheckDownload((prevCheckDownload) => prevCheckDownload.filter((item) => item !== id));
    }
  };

  const [pages, setPages] = useState(0)

  const {data} = QueryGetEmpreendimentos({
    variables: {
      pagination: { pagina: pages, quantidade: 1000 },
      "filtro": {
        "fields": filtro
      }, situation: result
    },
  })

  // Tratativa de loading
  if (!data) {
    return (
      <div className='card  px-10 py-6'>
        <div className='border-0'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='card-xxl-stretch '>
                <div className='card-header border-0    p-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil003.svg'
                        className='svg-icon-1 svg-icon-gray-500 me-1'
                      />
                      Relatórios do Empreendimento</span>
                  </h3>
                  <div
                    className='card-toolbar all-end'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                  >

<button
                      className='me-4 btn btn-sm btn-flex  btn-primary'
                    >
                      <BiExport></BiExport>
                      Exportar
                    </button>

                    <button
                      className='me-4 btn btn-sm btn-flex btn-light btn-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={toggleInfos}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen046.svg'
                        className='svg-icon-5 svg-icon-gray-500 me-1'
                      />
                      Infos
                    </button>
                    <button
                      className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={toggleFilter}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen031.svg'
                        className='svg-icon-5 svg-icon-gray-500 me-1'
                      />
                      Filtros
                    </button>

                    {checkDownload.length !== 0 ?
                      <button
                        className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={toggleFilter}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen031.svg'
                          className='svg-icon-5 svg-icon-gray-500 me-1'
                        />
                        Download
                      </button>
                      :
                      ''
                    }
                  </div>
                  {/* <div className='all-end'></div> */}
                </div>
                {/* end::Header */}
                <div className='separator my-2'></div>
                <Loader text={'caregando...'}></Loader>
              </div>
              <div className='card-footer all-end'>
                <Pagination setPages={setPages} pages={pages} total={100} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  function exportTable(){

      const dataToExport= []
      // eslint-disable-next-line array-callback-return
      data.GetEmpreendimentos.result.map((Response) => {

        let terreno = Response.empreendimento_terreno.length > 0  ? Response.empreendimento_terreno[0].terreno : null
                         
        let situacao = null
        switch (Response.situacao_id) {
          case 1:
            situacao = 'Em planejamento';break;
            
          case 2:
            situacao = 'Em construção';break;
           
          case 3:
            situacao = 'Finalizado';break;
           
          case 4:
            situacao = 'Descartado';break;
           
              
          default:
            situacao = '-'
            break;
        }


          dataToExport.push({
              'nome':Response.nome,
              'Cidade':  terreno ? terreno.cidade ? terreno.cidade : '-' : '-',
              'Total de Lotes':Response?.empreendimento_terreno?.length,
              'Área do terreno (m²)': Response?.area_total_terreno ? formatNumber(Response?.area_total_terreno, 'decimal') : '-',
              'Área total (m²)':Response?.area_total_privativa ? formatNumber(Response?.area_total_privativa, 'decimal') : '-',
              'Zoneamento':terreno ? terreno.zoneamento ? terreno.zoneamento : '-' : '-',
              "Status":situacao,
              "Operação Urbana":terreno ? terreno.operacao_urbana ? terreno.operacao_urbana : '-' : '-',
              "VGV":Response?.vgv ? formatNumber(Response?.vgv, 'currency') : '-' ,
              "Cadastrado por":Response.users ? Response.users.name ? Response.users.name : '-' : '-',
              // "latitude":Response.empreendimento_terreno[0].terreno.latitude,
              // "longitude":Response.empreendimento_terreno[0].terreno.longitude
          })
      })
      ExportTable(dataToExport,'' ,'Relatório Empreendimentos.xlsx')
  
  }
  return (
    <>
      {' '}
      <div className='card  px-10 py-6'>
        <div className='border-0'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='card-xxl-stretch '>
                <div className='card-header border-0    p-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil003.svg'
                        className='svg-icon-1 svg-icon-gray-500 me-1'
                      />
                      Relatórios do Empreendimento</span>
                  </h3>
                  <div
                    className='card-toolbar all-end'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Exportar table'
                    onClick={exportTable}

                  >
                    
<button
                      className='me-4 btn btn-sm btn-flex  btn-primary'
                    >
                      <BiExport></BiExport>
                      Exportar
                    </button>

                    <button
                      className='me-4 btn btn-sm btn-flex btn-light btn-light-warning'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={toggleInfos}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen046.svg'
                        className='svg-icon-5 svg-icon-gray-500 me-1'
                      />
                      Infos
                    </button>
                    <button
                      className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={toggleFilter}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen031.svg'
                        className='svg-icon-5 svg-icon-gray-500 me-1'
                      />
                      Filtros
                    </button>
                    {checkDownload.length !== 0 ?
                      <button
                        className='me-4 btn btn-sm btn-flex btn-light btn-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={toggleFilter}
                      >
                        <KTSVG
                          path='/media/icons/duotune/files/fil009.svg'
                          className='svg-icon-5 svg-icon-gray-500 me-1'
                        />
                        Download
                      </button>
                      :
                      ''
                    }
                  </div>
                  {/* <div className='all-end'></div> */}
                </div>
                {/* end::Header */}
                <div className='separator my-2'></div>
                {/* begin::Body */}
                <div>
                  {/* begin::Table container */}
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                          {/* <th className=''>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input widget-9-check'
                                type='checkbox'
                                value='all'
                              />
                            </div>
                          </th> */}
                          <th className=''>Nome</th>
                          {/* <th className=''>Cidade</th> */}
                          <th className=''>Cidade</th>
                          <th className=''>Total de Lotes</th>
                          <th className=''>Área do terreno (m²)</th>
                          <th className=''>Área Privativa (m²)</th>
                          <th className=''>Zoneamento</th>
                          <th className=''>Status</th>
                          <th className=''>Operação Urbana</th>
                          <th className=''>VGV</th>
                          <th className=''>Cadastrado por</th>
                       
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {data.GetEmpreendimentos.result.map((Response) => {

                          let terreno = Response.empreendimento_terreno.length > 0  ? Response.empreendimento_terreno[0].terreno : null
                         
                          let situacao = null
                          switch (Response.situacao_id) {
                            case 1:
                              situacao = 'Em planejamento';break;
                              
                            case 2:
                              situacao = 'Em construção';break;
                             
                            case 3:
                              situacao = 'Finalizado';break;
                             
                            case 4:
                              situacao = 'Descartado';break;
                             
                                
                            default:
                              situacao = '-'
                              break;
                          }

                          return (
                            <tr key={Response.id}>
                              {/* <td className=''>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input widget-9-check'
                                    type='checkbox'
                                    checked={isChecked(Response.id)}
                                    onChange={(event) => handleCheckboxChange(event, Response.id)}
                                  />
                                </div>
                              </td> */}
                              <td>
                                {Response.nome}
                              </td>
                              <td>
                              {terreno ? terreno.cidade ? terreno.cidade : '-' : '-'}
                              </td>
                              {/* <td>
                              {terreno ? terreno.bairro ? terreno.bairro : '-' : '-'} 
                              </td> */}
                              <td>
                              {Response?.empreendimento_terreno?.length}
                              </td>
                              <td>
                              {Response?.area_total_terreno ? formatNumber(Response?.area_total_terreno, 'decimal') : '-' }
                              </td>
                              <td>
                              {Response?.area_total_privativa ? formatNumber(Response?.area_total_privativa, 'decimal') : '-' }
                
                              </td>
                             
                              <td>
                              {terreno ? terreno.zoneamento ? terreno.zoneamento : '-' : '-'} 
                              </td>
                              <td>
                              { 
                              situacao
                              }
                              </td>
                              <td>
                              {terreno ? terreno.operacao_urbana ? terreno.operacao_urbana : '-' : '-'} 
                              </td>
                              <td className='text-right'> {Response?.vgv ? formatNumber(Response?.vgv, 'currency') : '-' }</td>
                              <td>
                              {Response.users ? Response.users.name ? Response.users.name : '-' : '-'} 
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>

                      {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                  </div>
                  {/* end::Table container */}
                </div>
                <div className='card-footer all-end'>
                  <Pagination setPages={setPages} pages={pages} total={100} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Table }
