import { useParams } from 'react-router-dom'
import { Loader } from '../../../../../../components/UI/Loader/Loader'
import { Buttons } from './Tab2/buttons'
import { QueryGetEmpresaByIDEmployees } from '../../../../../graphql/services/Empresas'
import { ModalAssociationUsuario } from './Tab2/AssociationUser'
import { useState } from 'react'
import PaginationComponent from '../../../../../../components/UI/PaginationComponents'

export function TabFuncionarios() {
  const { id: IdEmpresa } = useParams<{ id: string }>()
  const [pagiancao, setPaginacao] = useState({ pagina: 0, quantidade: 10 })
  const [filter, setFilter] = useState([])
  const { data } = QueryGetEmpresaByIDEmployees({
    variables: {
      getEmpresaByIdFuncionariosId: parseInt(IdEmpresa),
      pagination: {
        pagina: pagiancao.pagina,
        quantidade: pagiancao.quantidade,
      },
      filtro: {
        fields: filter,
      }
    },
  })

  function addFilter(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const filterArray = [];

    const nome = formData.get('filter-input'); // Obtém o valor do input de filtro
    if (nome) {
      filterArray.push({
        field: ['users.name', 'users.email'],
        operator: 'LIKE',
        value: nome,
      });
    }

    setFilter([...filterArray]);
  }

  // Tratativa de loading
  if (!data) {
    return (
      <div className='tab-pane fade' id='tab2' role='tabpanel' aria-labelledby='tab2-tab'>
        <div className='card'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>Funcionários da Empresa</h3>
            </div>
            <div className='card-toolbar'>
              <form className=' form-froup all-center me-4' onSubmit={addFilter}>
                <input type="text" name="filter-input" placeholder='Procurar Funcionário' className='form-control me-2' />
                <button className='btn  d-block btn-sm btn-light-success' type="submit">Buscar</button>
              </form>
              <ModalAssociationUsuario dataFuncionarios={data} id_empresa={parseInt(IdEmpresa)} />
            </div>
          </div>
          <div className='pb-0'>
            <div className='table-responsive'>
              <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className=' text-muted'>
                    <th className='min-w-150px'>Nome</th>
                    <th className='min-w-140px'>CPF</th>
                    <th className='min-w-140px'>Visualização</th>
                    <th className='min-w-140px'>Edição</th>
                    <th className='min-w-140px'>Exclusão</th>
                    <th className='min-w-100px text-end'>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='text-center w-100' colSpan={6}>
                      <span>Nenhum funcionario associado.</span>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='tab-pane fade' id='tab2' role='tabpanel' aria-labelledby='tab2-tab'>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>
            <h3>Funcionários da Empresa</h3>
          </div>
          <div className='card-toolbar'>
            <form className='form-group all-center me-4' onSubmit={addFilter}>
              <input type="text" name="filter-input" placeholder='Procurar Funcionário' className='form-control me-2' />
              <button className='btn d-block btn-sm btn-light-success' type="submit">Buscar</button>
            </form>
            <ModalAssociationUsuario dataFuncionarios={data} id_empresa={parseInt(IdEmpresa)} />
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive card-xxl-stretch mb-5 mb-xl-8'>
            <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className=' text-muted'>
                  <th className='min-w-150px'>Nome</th>
                  <th className='min-w-150px'>Email</th>
                  <th className='min-w-140px'>CPF</th>
                  <th className='min-w-140px'>Visualização</th>
                  <th className='min-w-140px'>Edição</th>
                  <th className='min-w-140px'>Exclusão</th>
                  <th className='min-w-100px text-end'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {data.GetEmpresaByIdFuncionarios.funcionarios.length !== 0 ? (
                  data.GetEmpresaByIdFuncionarios.funcionarios.map((Response) => {
                    return (
                      <tr key={Response.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark  text-hover-primary fs-6'>
                                {Response.name ? Response.name : 'Não informado'}
                              </span>
                              <span className='text-muted fw-bold text-muted d-block fs-7'>
                                Função: {Response.empresa_usuario[0].tipo_usuario}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className='text-dark  text-hover-primary d-block fs-6'>
                            {Response.email ? Response.email : 'Não informado'}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark  text-hover-primary d-block fs-6'>
                            {Response.cpf ? Response.cpf : 'Não informado'}
                          </span>
                        </td>
                        <td className='text-start'>
                          <span className='text-dark  fs-6'>
                            {Response.visualizacao ? Response.visualizacao : 'Não informado'}
                          </span>
                        </td>
                        <td className='text-start'>
                          <span className='text-dark  fs-6'>
                            {Response.edicao ? Response.edicao : 'Não informado'}
                          </span>
                        </td>
                        <td className='text-start'>
                          <span className='text-dark  fs-6'>
                            {Response.exclusao ? Response.exclusao : 'Não informado'}
                          </span>
                        </td>
                        <td>
                          <Buttons
                            idEmpresa={parseInt(IdEmpresa)}
                            idAssociacao={Response.empresa_usuario[0].id}
                          />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr key={0}>
                    <td colSpan={12} className='text-center'>
                      <p className='text-dark fs-6'>Nenhum funcionario cadastrado</p>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
        </div>
        <div className='card-footer all-end p-0'>
          <PaginationComponent
            pagesInfo={data.GetEmpresaByIdFuncionarios.pageInfo}
            setPagesInfo={(pagina: number, quantidade: number) => {
              setPaginacao({ pagina: pagina, quantidade: quantidade })
            }}
          ></PaginationComponent>
        </div>
      </div>
    </div>
  )
}
