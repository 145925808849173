import DualListBox from 'react-dual-listbox'
import 'react-dual-listbox/lib/react-dual-listbox.css'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { TypesGetServicoCategoriaResponse } from '../../../../graphql/type/servicoCategoria'

import {
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
} from 'react-icons/md'
import {
  FaAngleDoubleDown,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleDoubleUp,
} from 'react-icons/fa'
import { GET_SERVICO_CATEGORIA } from '../../../../graphql'
import { Modal } from 'react-bootstrap'
import { Loader } from '../../../../components/UI/Loader/Loader'
import {
  MutationUpdateGrupoServico,
  QueryGetGruposServicosByID,
} from '../../../../graphql/services/GruposServicos'
import { FormInputAndLabel } from '../../../../components/UI/Inputs/FormInputAndLabel'
import Swal from 'sweetalert2'
import { MutationSetLogAcessoRecurso } from '../../../../graphql/services/LogAcesso'

type Props = {
  id: number
}

interface CondicaoProps {
  id: number
  show: boolean
  closeModal: any
}

export function EditForm({ id, show, closeModal }: CondicaoProps) {
  const { data } = useQuery<TypesGetServicoCategoriaResponse>(GET_SERVICO_CATEGORIA)

  const { data: dataServicosUsuario, loading: loadingGet } = QueryGetGruposServicosByID({
    variables: { getUsuarioGrupoServicoId: id },
  })

  const [servicos, setServicos] = useState([])
  const [selected, setSelected] = useState([])

  const onChange = (value) => {
    setSelected(value)
    setValue('servicosId', value)
  }

  useEffect(() => {
    if (data) {
      let dataServices = [];
      // eslint-disable-next-line array-callback-return
      data.GetServicoCategoria.map((Options) => {
        const services = Options.servico.map((servico) => {
          return { value: servico.id, label: servico.nome };
        });
  
        dataServices.push({
          label: Options.nome,
          options: services,
        });
      });
  
      // Limpe o estado servicos antes de definir os novos valores
      setServicos([]);
      setServicos(dataServices);
    }
  }, [data]);

  useEffect(() => {
    let dataServicesSelected = [];
    if (dataServicosUsuario) {
      // eslint-disable-next-line array-callback-return
      dataServicosUsuario.GetUsuarioGrupoServico.servico.map((item) => {
        dataServicesSelected.push(item.id);
      });
  
      // Limpe o estado selected antes de definir os novos valores
      setSelected([]);
      setSelected(dataServicesSelected);
    }
  }, [dataServicosUsuario]);

  const { register, handleSubmit, setError, setValue, errors, FormUpdateGrupoServico, DataGrupoServico, error, loading } =
    MutationUpdateGrupoServico()



  if (loading) {
    Swal.fire(
      'Enviando Informações...',
      '',
    )
    Swal.showLoading()
  }

  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const onSubmit = async (data) => {
    FormUpdateGrupoServico(data).then((result) => {
      Swal.fire('Sucesso!', 'Grupo de Serviços atualizado com sucesso.', 'success')
      FormSetLogAcessoRecurso({ferramenta: 'cadastro', funcionalidade: 'grupos-servicos', action: 'update', url: '/cadastro/cadastro-grupos'});
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')

    })
  }


  // Tratativa de loading
  if (!data || !dataServicosUsuario) {
    return (
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Grupo de Serviços</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader text={'caregando...'}></Loader>
        </Modal.Body>
      </Modal>
    )
  }


  return (
    <>
      <Modal centered animation show={show} size='xl' onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Grupo de Serviços</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id='kt_account_profile_details_form'
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
          >
            <input
              className='form-control form-control-solid'
              type='number'
              hidden={true}
              defaultValue={data ? dataServicosUsuario.GetUsuarioGrupoServico.id : ''}
              {...register('id', { required: true, valueAsNumber: true })}
            />

            <div className='row'>
              <div className='form-group'>
                <FormInputAndLabel
                  label='Nome do grupo'
                  defaultValue={dataServicosUsuario.GetUsuarioGrupoServico.name}
                  className='form-control required form-control-solid'
                  placeholder='Nome do grupo de serviços'
                  register={register('name')}
                  error={errors ? errors.name?.message : ''}
                />
              </div>
            </div>
            <div className='row'>
              <label className='col-md-4 pt-5 pb-5'>Serviços</label>
              <DualListBox
                canFilter
                options={servicos}
                selected={selected}
                preserveSelectOrder
                showOrderButtons
                onChange={onChange}
                icons={{
                  moveLeft: <MdKeyboardArrowLeft size={20} color='#125f44' />,
                  moveAllLeft: <FaAngleDoubleLeft size={20} color='#125f44' />,
                  moveRight: <MdKeyboardArrowRight size={20} color='#125f44' />,
                  moveAllRight: <FaAngleDoubleRight size={20} color='#125f44' />,
                  moveDown: <MdKeyboardArrowDown size={20} color='#125f44' />,
                  moveUp: <MdKeyboardArrowUp size={20} color='#125f44' />,
                  moveTop: <FaAngleDoubleUp size={20} color='#125f44' />,
                  moveBottom: <FaAngleDoubleDown size={20} color='#125f44' />,
                }}
              />
            </div>
            <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
              <button className='btn btnSecondary  pull-right ' type='button' onClick={closeModal}>
                Fechar
              </button>
              <button type='submit' className='btn btn-success mx-2'>
                Salvar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
