import React, {useCallback, useEffect, useRef} from 'react'
import {GoogleMap, Polygon, OverlayView, Marker} from '@react-google-maps/api'

import {ApiUrbit} from '../../../../services/http/urbit'
type PropsContextMenu = {
  latLng: any[]
  onChange: any
  showLotes: boolean
  setLatLng: any
}

export function CoordinatesToPath(geom) {
  let paths = geom.map(function (el: any[]) {
    return {lat: el[1], lng: el[0]}
  })
  return paths
}

export const MapMarker: React.FC<PropsContextMenu> = ({
  latLng,
  setLatLng,
  onChange,
  showLotes = true,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = React.useState(null)
  const [points, setPoints] = React.useState(latLng)
  const [poligono, setPoligono] = React.useState([])
  const innerRef = useRef()

  useEffect(() => {
    const syncLotes = async (latLng) => {
      // console.log(latLng)
      if (latLng.length > 0) {
        const lote = await getLote(latLng[1], latLng[0])
        let poly = {}
        // console.log(lote)
        if (lote.itens.length > 0) {
          // console.log(`asdasdasd`)
          poly = JSON.parse(lote.itens[0].geom)
          let path = CoordinatesToPath(poly['coordinates'][0][0])
          setPoligono(path)
          onChange(path, lote, latLng)
        }
        // console.log('latLng')
        // console.log(latLng)
        setPoints([...latLng])
      }
    }
    syncLotes(latLng)
    // return ()=>{}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latLng])

  const isLoaded = true
  const containerStyle = {
    // width: '400px',
    height: '400px',
  }
  const center = {
    lat: -23.56834983242366,
    lng: -46.65739491734664,
  }
  if (latLng.length > 0) {
    center.lat = latLng[0]
    center.lng = latLng[1]
  }

  const onLoad = React.useCallback(
    function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const imageMapType = new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return [
            process.env.REACT_APP_MAP_TILE + '/tile/lotesfinal/',
            zoom,
            '/',
            coord.x,
            '/',
            coord.y,
            '.png?type=png',
          ].join('')
        },
        tileSize: new google.maps.Size(256, 256),
      })
      if (showLotes) {
        map.overlayMapTypes.push(imageMapType)
        setMap(map)
      }
    },
    [showLotes]
  )
  const polygonRef = useRef(null)
  const listenersRef = useRef([])
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const onEdit = useCallback(
    (el) => {
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map((latLng) => {
            return {lat: latLng.lat(), lng: latLng.lng()}
          })
        onChange(nextPath)
      }
    },
    [onChange]
  )

  function disableEdit(e) {
    // eslint-disable-next-line array-callback-return
    poligono.map((el) => {
      el.editable = false
    })
    setPoligono([...poligono])
  }

  async function getLote(lat, lng) {
    const result = await ApiUrbit.get(`municipio/sao-paulo/lote-fiscal/${lat}/${lng}?distancia=300`)
    return result
  }

  async function onChangeMarker(data) {
    let lat = data.latLng.lat()
    let lng = data.latLng.lng()
    // const lote = await getLote(data.latLng.lng(),data.latLng.lat())
    // console.log('onChangeMarker')
    // console.log(lote)
    // let poly = {}
    // if (lote.itens.length > 0 ){
    //   poly = JSON.parse(lote.itens[0].geom)
    // }
    // let path = CoordinatesToPath(poly['coordinates'][0][0])
    // setPoligono(path)

    setPoints([lat, lng])
    setLatLng([lat, lng])
    onChange('', '', [lat, lng])
  }

  // Bind refs to current Polygon and listeners
  const onLoadPolygono = useCallback(
    (polygon) => {
      polygonRef.current = polygon
      const path = polygon.getPath()
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      )
    },
    [onEdit]
  )

  const divStyle = {
    background: 'transparent',
  }

  return isLoaded ? (
    <GoogleMap
      ref={innerRef}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={disableEdit}
    >
      {' '}
      {poligono && poligono.length > 0 ? (
        <Polygon
          // Make the Polygon editable / draggable
          editable
          draggable
          path={poligono}
          // Event used when manipulating and adding points
          onMouseUp={onEdit}
          // Event used when dragging the whole Polygon
          onDragEnd={onEdit}
          onLoad={onLoadPolygono}
          onUnmount={onUnmount}
        />
      ) : (
        ''
      )}
      {points ? (
        <Marker
          position={{lat: points[0], lng: points[1]}}
          draggable
          onDragEnd={(data) => {
            onChangeMarker(data)
          }}
        />
      ) : (
        ''
      )}
      <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <div style={divStyle}></div>
      </OverlayView>
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  )
}
