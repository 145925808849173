import {ColorMapboxToKepler, GetPropert} from '../../../../../common'
import {BaseLayer, DefaultLayerProps} from '../base-layer'
import {GeoJsonLayer as DeckGeoJsonLayer} from '@deck.gl/layers'
export class GeoJsonLayer extends BaseLayer {
  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-useless-constructor
  constructor(props: DefaultLayerProps) {
    super(props)
  }
  defaultProp() {}
  draw(propsInput: DefaultLayerProps) {
    let props = {...this.getDefaultLayerConfig()}
    props = {...propsInput}
    return [
      new DeckGeoJsonLayer({
        id: props.id,
        data: props.data,
        extruded: props.extruded ? true : false,
        stroked: true,
        pickable: true,
        visible: props.visible,
        style:props.style,
        lineWidthMinPixels:
        props.style && typeof props.style['line-width'] !== 'undefined'
          ? props.style['line-width']
          : 1,
        onClick: (info, event) => {
          this.clickEvent(info, event)
        },
        color: () => {
          return [252, 252, 25, 100]
        },
        getFillColor: (f) => {
          let colorFinal = ColorMapboxToKepler(props.style, f.properties, 'fill-color')
          //Aplica opacidade padrõa
          if (colorFinal.length === 3) {
            colorFinal.push(100)
          }
          return colorFinal
        },
        getLineColor: (f: any) => {
          if (props.style && typeof props.style['line-color'] !== 'undefined') {
            let colorFinal = GetPropert(props.style, 'line-color')
            return colorFinal
          } else {
            return false // [0, 0, 0]
          }
        },
        getLineWidth: () => {
          let valor = GetPropert(props.style, 'line-width')
          return valor || 0
        },
        // getLineColor: [255, 255, 255],
        updateTriggers: {
          getFillColor: (f: any) => {
            let colorFinal = ColorMapboxToKepler(props.style, f.properties, 'fill-color')
            return colorFinal
          },
          getLineColor: (f) => {
            if (props.style && typeof props.style['line-color'] !== 'undefined') {
              let colorFinal = GetPropert(props.style, 'line-color')
              return colorFinal
            } else {
              return GetPropert(props.style, 'fill-color')
            }
          },
          color: () => {
            return [252, 252, 25]
          },
          getLineWidth: () => {
            let valor = GetPropert(props.style, 'line-width')
            return valor || 0
          },
        },
      }),
    ]
  }
}
