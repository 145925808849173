import {useRef, useState} from 'react'
import {Layout} from '../../Layout'
import { BiCheck, BiCopy } from 'react-icons/bi'
import { ModalCamadas } from '../../../Menu/Projetos/Camadas/ModalCamadas'

type PropsContextMenu = {
  showHide: boolean
  latLng: google.maps.LatLng | null,
  geom:JSON
  geomList:[]
  layers:any
}

export const ContextMenuGeometryArea: React.FC<PropsContextMenu> = ({showHide, latLng, geom, layers, geomList}) => {
  const show = useRef<boolean>()
  show.current = showHide
  const [showModalCamadas, setShowModalCamadas] = useState(false)
  const [copy,setCopy] = useState(false)
  const handleClose = () => setShowModalCamadas(false)
  // //console.log(geom)
  function onClickTerritorioEmDados() {
    Layout.setShowTerritorioMenu(true, latLng)
  }

  function onClickRelatorio() {
    Layout.setShowRelatorioMenu(true, latLng, geom)
  }
  function onClickAddLayer(){
    // Layout.setShowGeometryMenuArea(false)
    Layout.setShowGeometryMenu(false)
    setShowModalCamadas(true)
    
  }
  function onClickEstatistica() {
    Layout.setShowGeometryMenuArea(false)
    Layout.setShowGeometryMenu(false)
   
    // console.log(layers)
    // // eslint-disable-next-line array-callback-return
    // let geomItem  = geomList.filter(function(l){
    //   console.log(l)
    //   console.log(layer)
    //   console.log(layer)
    //   if(l['layer']['id'] === layer || l['id'] === layer){
    //     return  l
    //   }
    // })
    // console.log(geomItem)


    Layout.setShowEstatisticasMenu(true, latLng, geom)
  }

  function onCopyLocation(text) {
    navigator.clipboard.writeText(text)
    setCopy(true)
    return false
  }

  function hideMenuOnClick(){
    Layout.setShowGeometryMenuArea(false)
    Layout.setShowGeometryMenu(false)
  }

  const url = '#'
  return ( latLng ? 
    <>
      <div>
        <ul className={`dropdown-menu dropdown-context show menu`}   onClick={(e) => {
            e.stopPropagation()
            //console.log('Click na ul do context')
          }}>
          <li className='nav-header'>Mais Informações</li>
          <li>
            <a href={url} className='context-event' onClick={onClickTerritorioEmDados}>
              Território em dados
            </a>
          </li>
          <li>
            <a href={url} className='context-event'        
              data-bs-target='#modal-painel-imobiliario'
              data-bs-toggle='modal' onClick={hideMenuOnClick}>
              Painel de Mercado
            </a>
          </li>
          <li>
            <a href={url} className='context-event' onClick={onClickRelatorio}>
             Relatórios
            </a>
          </li>
          <li className='divider'></li>
          <li className='nav-header'>Operações</li>
          <li>
            <a
              href={url}
              className='context-event'
              onClick={onClickAddLayer}
            >
              Adicionar Sublayer
            </a>
          </li>
          <li>
            <a
              href={url}
              className='context-event'
              // data-bs-target='#modal-intersect'
              // data-bs-toggle='modal'
              onClick={onClickEstatistica}
            >
              Estatisticas
            </a>
            {/* <ul className='dropdown-menu '>
           
            <li className='nav-header'>Da camada</li>
              {
              // eslint-disable-next-line array-callback-return
              layers.map(function(layer,y){
                if (layer.type === 'MVTLayer' || layer.type === 'LayerPolygon') {
                  return (<li key={y} ><a href={url}   onClick={()=>{
                    onClickEstatistica(layer.id)
                  }  } className='context-event'>{layer.nome}</a></li>)
                }
              })}
              
            </ul> */}
          </li>
          {/* <li>
            <a
              href={url}
              className='context-event'
              data-bs-target='#modal-isocrona'
              data-bs-toggle='modal'
            >
              Isócrona
            </a>
          </li>
          <li>
            <a
              href={url}
              className='context-event'
              data-bs-target='#modal-isocota'
              data-bs-toggle='modal'
            >
              Isócota
            </a>
          </li>
          <li>
            <a
              href={url}
              className='context-event'
              data-bs-target='#modal-raio'
              data-bs-toggle='modal'
            >
              Raio
            </a>
          </li> */}
          <li className='divider'></li>
          <li className='nav-header'>Local</li>
          <li>
          <a href={url} className='context-event' onClick={()=>{
              const text = latLng.lat().toFixed(5) +','+ latLng.lng().toFixed(5)
              onCopyLocation(text)
            }}>
            {copy ? <BiCheck className="fs-1 text-success"></BiCheck> : <BiCopy className="fs-1"></BiCopy>} {latLng.lat().toFixed(5)}, {latLng.lat().toFixed(5)} 
              
            </a>
            </li>
        </ul>
      </div>
      <ModalCamadas handleClose={handleClose} show={showModalCamadas} sublayer={true} activeTab={''} ></ModalCamadas>
    </> : <></>
  )
}
