import {Explorer} from '../../Explorer'
import {ColorPicker} from '../../../../../../components/UI/ColorPicker'

type Props = {
  uuid: any
  defaultColor: string
}

export const StrokeColor: React.FC<Props> = ({uuid, defaultColor = '#333'}) => {
  // const [c, setSizeBorder] = useState(defaultColor || 1)

  function onChange(color) {
    Explorer.changeStyle(uuid, 'line-color', color)
  }

  return (
    <>
      <div className='item-config'>
        <div className='item-config-align'>
          <div
            className='item-config-align-title'
            data-bs-toggle='collapse'
            data-bs-target={`#collapseExample_${uuid}`}
            aria-expanded='false'
            aria-controls={`collapseExample_${uuid}`}
          >
            Cor da borda
          </div>
          <div className='border-left-layers collapse' id={`collapseExample_${uuid}`}>
            <ColorPicker hex={defaultColor} onChange={onChange} hsl={undefined}></ColorPicker>
          </div>
        </div>
      </div>
    </>
  )
}
