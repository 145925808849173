import { gql } from '@apollo/client';

export const GET_CATEGORIAS = gql`
query GetCategorias($pagination: Pagination!) {
      GetCategorias(Pagination: $pagination) {
        pageInfo {
          currentPage
          totalPages
          totalItems
          hasNextPage
          hasPreviousPage
        }
        result {
          id
          nome
          descricao
          situacao
          ordem
          id_categoria_agrupamento
          subnivel
        }
      }
  }
`

