import React, { useCallback, useRef } from 'react'
import { GoogleMap, useJsApiLoader, Polygon, OverlayView } from '@react-google-maps/api'
import { APiUrbit } from '../../../services/api/apiUrbit'
type PropsContextMenu = {
  latLng: any[]
}

export function CoordinatesToPath(geom) {
  let paths = geom.map(function (el) {
    return { lat: el[1], lng: el[0] }
  })

  return paths
}

export function PathToCoordinates(path) {
  let paths = path.map(function (el) {
    return [el.lng, el.lat]
  })

  return paths
}
// const libraries: Libraries = ['drawing', 'places']
export const MapView: React.FC<PropsContextMenu> = ({ latLng }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = React.useState(null)
  const [paths, setPaths] = React.useState(latLng)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  })
  const containerStyle = {
    // width: '400px',
    height: '100vh',
  }
  const center = {
    lat: -23.56834983242366,
    lng: -46.65739491734664,
  }

  if (latLng.length > 0) {
    center.lat = latLng[0].center.geometry.coordinates[1]
    center.lng = latLng[0].center.geometry.coordinates[0]
  }

  const options = {
    fillColor: 'lightgreen',
    fillOpacity: 0.6,
    strokeColor: 'green',
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  }

  const optionsEditable = {
    fillColor: 'lightred',
    fillOpacity: 0.6,
    strokeColor: 'red',
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: true,
    editable: true,
    geodesic: false,
    zIndex: 1,
  }

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center)
    // map.fitBounds(bounds)

    const imageMapType = new google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        // if (
        //   zoom < 17 ||
        //   zoom > 20
        //   // bounds[zoom][0][0] > coord.x ||
        //   // coord.x > bounds[zoom][0][1] ||
        //   // bounds[zoom][1][0] > coord.y ||
        //   // coord.y > bounds[zoom][1][1]
        // ) {
        //   return "";
        // }

        return [
          process.env.REACT_APP_MAP_TILE + '/tile/lotesfinal/',
          zoom,
          '/',
          coord.x,
          '/',
          coord.y,
          '.png?type=png',
        ].join('')
      },
      tileSize: new google.maps.Size(256, 256),
    })

    map.overlayMapTypes.push(imageMapType)
    setMap(map)
  }, [])
  const polygonRef = useRef(null)
  const listenersRef = useRef([])
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  function dbClick(e, elementMap) {
    // eslint-disable-next-line array-callback-return
    paths.map((el) => {
      if (el.id === elementMap.id) {
        el.editable = true
      } else {
        el.editable = false
      }
    })
    setPaths([...paths])
  }
  function click(e, el) {
    clearSelecaoTable()
    let line = document.getElementById('row_' + el.id)
    line.classList.add('active')
  }
  function clearSelecaoTable() {
    document.querySelectorAll('.row-composicao').forEach(function (row) {
      row.classList.remove('active')
    })
  }

  // Call setPath with new edited path
  const onEdit = useCallback(
    (el) => {
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() }
          })
        // setPath(nextPath);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setPaths]
  )

  function disableEdit(e) {
    // eslint-disable-next-line array-callback-return
    paths.map((el) => {
      el.editable = false
    })
    setPaths([...paths])
    clearSelecaoTable()
    const lote = getLote(e.latLng.lat(), e.latLng.lng())
  }

  async function getLote(lat, lng) {
    const api = new APiUrbit()
    const result = await api.get(`municipio/sao-paulo/lote-fiscal/${lat}/${lng}?distancia=300`)
    return result
  }
  // Bind refs to current Polygon and listeners
  const onLoadPoligon = useCallback(
    (polygon) => {
      polygonRef.current = polygon
      const path = polygon.getPath()
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      )
    },
    [onEdit]
  )

  const divStyle = {
    background: 'transparent',
    // border: "1px solid #ccc",
    // padding: 15
  }
  // const onClick = () => {
  //   console.info('I have been clicked!')
  // }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ ...containerStyle, height: 'calc(100vh - 70px)' }}
      center={center}
      zoom={17}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={disableEdit}
    >
      {paths
        ? paths.map(function (el) {
          return (
            <Polygon
              onLoad={onLoadPoligon}
              paths={el.path}
              key={el.id}
              options={el.editable ? optionsEditable : options}
              onDblClick={(e) => {
                dbClick(e, el)
              }}
              onClick={(e) => {
                click(e, el)
              }}
              // Event used when manipulating and adding points
              onMouseUp={onEdit}
              // Event used when dragging the whole Polygon
              onDragEnd={onEdit}
            />
          )
        })
        : ''}
      <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <div style={divStyle}>
          {/* <h1>OverlayView</h1>

            <button onClick={onClick} type="button">
              Click me
            </button> */}
        </div>
      </OverlayView>
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  )
}
