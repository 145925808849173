import {gql} from '@apollo/client'

// Busca de todos as Situações de imovel pelo ID do usuario
export const GET_IMOVEL_SITUACAO_CLIENTE = gql`
  query GetImovelSituacaoCliente($pagination: Pagination!) {
    GetImovelSituacaoCliente(pagination: $pagination) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result
      {
        id
        nome
        status
        id_user
        descricao
      }
    }
  }
`
// Busca de uma unica Situação de imovel pelo ID do imovel
export const GET_ONE_IMOVEL_SITUACAO_CLIENTE = gql`
  query GetOneImovelSituacaoCliente($situacaoImovelClienteId: Float!) {
    GetOneImovelSituacaoCliente(Situacao_Imovel_Cliente_id: $situacaoImovelClienteId) {
      id
      nome
      status
      descricao
    }
  }
`
