import { useEffect, useState } from "react"
import Select from 'react-select'
import { QueryGetAdminEmpresaUsuarios } from "../../../../../../graphql/services/Empresas"

export function ChosenEmpresaUsuario({
  setValueUsuario,
  valueUsuario
}) {
  let usuariosArray = []

  const [paginacao, setPaginacao] = useState({ pagina: 0, quantidade: 10 })
  const [usuario, setUsuario] = useState(valueUsuario)

  useEffect(() => {
    setValueUsuario(usuario)
  }, [usuario, setValueUsuario])

  const { data } = QueryGetAdminEmpresaUsuarios({
    variables: {
      pagination: {
        pagina: paginacao.pagina,
        quantidade: paginacao.quantidade
      }
    }
  })


  if (!data) {
    return (
      <>
        <div className='form-group'>
          <label className='col-form-label'>Usuarios Ativos:</label>
          <Select
            options={usuariosArray}
            value={usuariosArray.filter((e) => e.value)[0]}
            name='id_user'
            placeholder='Usuarios Disponiveis'
          />
        </div>
      </>
    )
  }

  data.GetEmpresasUsuarioAdmin.result.map((Options) => {
    usuariosArray.push({ value: Options.id, label: Options.users.email ? `${Options.users.name}` : Options.users.name })
    return []
  })



  return (
    <>
      <div className='form-group'>
        <label className='col-form-label'>Usuarios Ativos:  </label>
        <Select
          options={usuariosArray}
          onChange={(e) => {
            setUsuario(e.label)
          }}
          name='id_user'
          placeholder='Usuarios Disponiveis'
        />
      </div>
    </>
  )
}
