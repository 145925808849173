import {ReactChild, ReactChildren, useContext} from 'react'
import {FilterContext} from '../../context/FilterContext'

type Props = {
  titulo:string
  children?:ReactChild
  showSubHeader?:boolean
}

export function HeaderPerformance({titulo,showSubHeader=true, children}:Props) {
  const {filter} = useContext(FilterContext)

  return (
    <>
      <div className='header-report  d-flex all-start-between '>
      <div>
        <h1 className='fw-bolder'>{titulo}</h1>{
          showSubHeader ? 
          <span>
          PERFORMANCE {filter.anoLancamento[0]} A {filter.anoLancamento[1]}
        </span>
        :<></>

        }
        
        </div>
        <div >
          {children}
        </div>
      </div>
    </>
  )
}
