import {gql} from '@apollo/client'

// Busca de todos as Situações de imovel pelo ID do usuario
export const GET_IMOVEL_PROPRIETARIO = gql`
  query GetImovelProprietarios($idImovel: Float!) {
    GetImovelProprietarios(id_Imovel: $idImovel) {
      cep
      cidade
      nome
      situacao
      telefone
      id_usuario_cadastro
      id_usuario_alteracao
      id_terreno_proprietario
      id
      endereco
      email
      data_cadastro
      data_alteracao
      cpf
      complemento
      cnpj
      empresa
      estado
      numero
      basic_data
      occupation_data
      emails_extended
      related_people_emails
      addresses_extended
      financial_data
      related_people
      profession_data
      phones_extended
      related_people_phones
      ph3a_data
    }
  }
`

// Busca os dados do proprietario pelo id
export const GET_IMOVEL_PROPRIETARIO_BY_ID = gql`
  query GetImovelProprietario($terrenoId: Float!, $getImovelProprietarioId: Float!) {
    GetImovelProprietario(terreno_id: $terrenoId, id: $getImovelProprietarioId) {
      id
      nome
      email
      telefone
      cnpj
      cpf
      endereco
      numero
      cep
      complemento
      estado
      cidade
      empresa
      situacao
      id_cidade
      id_estado
    }
  }
`
