import { Modal } from 'react-bootstrap'
import { MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowUp } from 'react-icons/md'
import { FaAngleDoubleDown, FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleDoubleUp } from 'react-icons/fa'
import { GET_GRUPOS_SERVICOS, GET_SERVICO_CATEGORIA, SET_GRUPOS_SERVICOS, TypesGetServicoCategoriaResponse, TypesSetGruposServicosResponse } from '../../../../graphql'
import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import DualListBox from 'react-dual-listbox'
import { Loader } from '../../../../components/UI/Loader/Loader'
import { MutationCreateCondicaoComercial } from '../../../../graphql/services/CondicaoComercial'
import { MutationCreateGrupoServico } from '../../../../graphql/services/GruposServicos'
import Swal from 'sweetalert2'
import { FormInputAndLabel } from '../../../../components/UI/Inputs/FormInputAndLabel'
import { MutationSetLogAcessoRecurso } from '../../../../graphql/services/LogAcesso'

interface situacaoTerrenoProps {
  closeModal: any
  show: boolean
}

export function CreateForm({ closeModal, show }: situacaoTerrenoProps) {


  const { data, loading: LoadingGet } = useQuery<TypesGetServicoCategoriaResponse>(GET_SERVICO_CATEGORIA)


  let dataServices = []

  const [selected, setSelected] = useState([])
  const onChange = (value) => {
    setSelected(value)
    setValue('servicosId', value)
  }

  const {
    FormGrupoServico, loading, handleSubmit, register,
    errors, error, DataGrupoServico,  setValue
  } = MutationCreateGrupoServico()


  if (loading) {
    Swal.fire(
      'Enviando Informações...',
      '',
    )
    Swal.showLoading()
  }
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  const onSubmit = async (data) => {
    FormGrupoServico(data).then((result) => {
      Swal.fire('Sucesso!', 'Grupo de Serviço criado com sucesso.', 'success')
      FormSetLogAcessoRecurso({ferramenta: 'cadastro', funcionalidade: 'grupos-servicos', action: 'create', url: '/cadastro/cadastro-grupos'});
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })
  }

  // Tratativa de loading
  if (!data) {
    return (
      <Loader text={'caregando...'}></Loader>
    )
  }

  // eslint-disable-next-line array-callback-return
  data.GetServicoCategoria.map((Options) => {
    const services = Options.servico.map((servico) => {
      return { value: servico.id, label: servico.nome }
    })

    dataServices.push({
      label: Options.nome,
      options: services,
    })
  })

  return (
    <Modal centered animation show={show} size='xl' onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Novo Grupo de Serviços</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id='kt_account_profile_details_form'
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
        >
          <div className='row'>
            <div className='form-group'>
              <FormInputAndLabel
                label='Nome do grupo'
                className='form-control required form-control-solid'
                placeholder='Nome do grupo de serviços'
                register={register("name")}
                error={errors ? errors.name?.message : ''}
              />
            </div>
          </div>
          <div className='row'>
            <label className='col-md-4 pt-5 pb-5'>Serviços</label>
            <DualListBox
              canFilter
              filterPlaceholder={'Search From List 1...' || 'Search From List 2...'}
              options={dataServices}
              selected={selected}
              className='form-control-dual-list'
              preserveSelectOrder
              showOrderButtons
              onChange={onChange}
              icons={{
                moveLeft: <MdKeyboardArrowLeft size={20} color='#125f44' />,
                moveAllLeft: <FaAngleDoubleLeft size={20} color='#125f44' />,
                moveRight: <MdKeyboardArrowRight size={20} color='#125f44' />,
                moveAllRight: <FaAngleDoubleRight size={20} color='#125f44' />,
                moveDown: <MdKeyboardArrowDown size={20} color='#125f44' />,
                moveUp: <MdKeyboardArrowUp size={20} color='#125f44' />,
                moveTop: <FaAngleDoubleUp size={20} color='#125f44' />,
                moveBottom: <FaAngleDoubleDown size={20} color='#125f44' />,
              }}
            />
          </div>
          <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
            <button className='btn btnSecondary  pull-right ' type='button' onClick={closeModal}>
              Fechar
            </button>
            <button disabled={loading} type='submit' className='btn btn-success mx-2'>
              Salvar
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
