import {Polygon} from '@react-google-maps/api'

import {TIsocrona} from '../../Types'
import {useContext, useEffect, useState} from 'react'
import {FilterContext} from '../../../context/FilterContext'
import Swal from 'sweetalert2'
import { CoordinatesToPath } from '../../Common/common'
import useImoveis from '../../../hooks/useImoveis'

const options = {
  fillColor: 'lightgreen',
  fillOpacity: 0.6,
  strokeColor: 'green',
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
}

function Isocrona({lat, lng, tempo, map}: {lat: number; lng: number; tempo: number; map: any}) {
  const {setGeometria,setGeometriaSelecionada} = useContext(FilterContext)
  const [paths, setPaths] = useState<TIsocrona>(null)
  const {loadImoveis} = useImoveis()
  useEffect(() => {
    if (lat && lng && map &&tempo) {
      loadIsocrona(map, lat, lng, tempo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng, tempo,map])

  async function loadIsocrona(map: any, lat: number, lng: number, tempo: number) {
    map.panTo(new google.maps.LatLng(lat, lng))
    map.setZoom(15)

    if (tempo === 0) {
      tempo = 900
    }

    let data = {
      locations: [[lng, lat]],
      area_units: 'm',
      range_type: 'time',
      range: [tempo * 60],
      interval: tempo * 60, // Aqui é o intervalo para cada geometria, por exemplo aqui é 5min a pé, o range é 15min (900), ou seja ele vai gerar 3 geometrias com 5min(300) , 10min(600) e 15min(900).
    }

    // ApiRoute(data, 'isochrones', 'foot-walking', function (data) {
    //   console.log(data)
    //   // Explorer.addIsocrona(data.features[0], `Tempo de  ${tempo}`)
    //   // Layout.setShowGeometryMenu(false)
    //   // setShowLoader(false)
    // })
    
    fetch('https://routing.urbit.com.br/ors/v2/isochrones/foot-walking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // referrerPolicy:  'unsafe-url',
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (typeof data.error !== 'undefined') {
          console.log(data.error)
        } else {
          let raio: TIsocrona = {
            geojson: data.features[0],
            id: Math.random(),
            paths: CoordinatesToPath(data.features[0].geometry.coordinates[0]),
            tempo: Number(tempo),
            selected: false,
            editable: false,
          }
          setPaths(raio)
          setGeometria({...data.features[0]})
          setGeometriaSelecionada({...data.features[0]})
          loadImoveis(data.features[0], 'isocrona')
        }
      }).catch((err) => {
        Swal.fire('','Erro ao carregar a isócrona.', 'warning')
      })
  }

  if (paths) {
    return (
      <>
        <Polygon paths={paths.paths} key={paths.id} options={options} />
      </>
    )
  } else {
    return <></>
  }
}

export default Isocrona
