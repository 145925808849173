/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { getCSSVariableValue } from '../../../../../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../../../../../_metronic/partials'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  titulo:string
  descricaoCurta:string
  valor:number
}

const BoxGaugue: React.FC<Props> = ({className, chartColor, chartHeight,titulo,descricaoCurta,valor}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight,valor))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode,valor])

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h5 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-5 mb-1'>{titulo}</span>
          <span className='text-muted fw-semibold fs-7'>Urbit Índices</span>
        </h5>

       
      </div>
      <div className='card-body d-flex flex-column py-1'>
        <div className='flex-grow-1'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
        </div>

        <div className='pt-5'>
          <p className='text-center fs-6 pb-5 '>
            <span className='badge badge-light-danger fs-8'>Sobre:</span>&nbsp; {descricaoCurta}
          </p>
        </div>
      </div>

    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, valor:number): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--bs-gray-700')

  return {
    series: [valor],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return Math.round(val) + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export {BoxGaugue}
