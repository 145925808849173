import { useMutation, useQuery } from "@apollo/client";
import { GET_PRODUTOS, GET_PRODUTO_BY_ID } from "../query";
import { TypesDeleteCorretorResponse, TypesGetOneProdutosResponse, TypesGetProdutosResponse, TypesSetProdutoResponse } from "../type";
import { DELETE_PRODUTO, SET_PRODUTOS } from "../mutations";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { setProdutoFormData, setProdutoFormSchema } from "../formValidations/Produtos";

interface Props {
  variables: any;
}

export function QueryGetProdutos({ variables }: Props) {
  const { data } = useQuery<TypesGetProdutosResponse>(GET_PRODUTOS, {
    variables: { ...variables }
  })

  return { data }
}

export function QueryGetProdutoByID({ variables }: Props) {
  const { data, error, loading } = useQuery<TypesGetOneProdutosResponse>(GET_PRODUTO_BY_ID, {
    variables: { ...variables }
  })

  return { data, error, loading }
}

export function MutationDeleteProdutos() {
  const [deleteCorretor, { loading }] = useMutation<TypesDeleteCorretorResponse>(DELETE_PRODUTO, {
    refetchQueries: [GET_PRODUTOS]
  })

  async function HandleDeleteProdutos({ variables }: Props) {
    try {
      const result = await deleteCorretor({
        variables: {...variables},
        refetchQueries: [GET_PRODUTOS],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return { HandleDeleteProdutos, loading }
}

export function MutationCreateProdutos() {

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
    formState: { errors }
  } = useForm<setProdutoFormData>({
    resolver: zodResolver(setProdutoFormSchema)
  });

  const [setProdutoBody, { loading }] = useMutation<TypesSetProdutoResponse>(SET_PRODUTOS, {
    refetchQueries: [GET_PRODUTOS]
  })

  async function submitForm(data: setProdutoFormData) {
    await setProdutoBody({
      variables: {
        produtoInput: {
          desconto: data.desconto ,
          descricao: data.descricao,
          nome: data.nome,
          valor: data.valor,
          subtitulo: data.subtitulo,
          tipo_produto: data.tipo_produto,
          situacao: data.situacao
        }
      }
    })
  };


  return { register, handleSubmit, submitForm, loading, errors, setError, setValue, getValues, control }
}