import { useQuery } from "@apollo/client"
import { GET_NEWS_FEED } from "../query/NewsFeed"
import { TypesGetNewsFeedResponse } from "../type/NewsFeed"

type Props = {
    variables: any
}

export function QueryGetNewsFeed({variables}: Props) {
    const {data, loading, error} = useQuery<TypesGetNewsFeedResponse>(GET_NEWS_FEED, {
      variables: {...variables},
    })
    return {data, loading, error}
  }
  
