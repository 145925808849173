import React, { useEffect, useState } from 'react'
import makeAnimated from 'react-select/animated';
import Select from 'react-select'
import { QueryGetExplorerTags } from '../../../graphql';

type Props = {
    setValueTags: any,
    valueTags: {
        value: any; 
        label:string
        id_tag: number 
}[];
}

export function MultiSelectTagsCreate({
    setValueTags,
    valueTags,
}: Props) {

    const animatedComponents = makeAnimated();

    const { data, loading } = QueryGetExplorerTags()

    let array = []
    const [tags, setTags] = useState(valueTags)

    useEffect(() => {
        console.log(tags)
      setValueTags(tags.map((tag) => ({ id_tag: tag.value })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags]);

    if (!data) {
        return (
            <>
                <div className='form-group'>
                    <label className='col-form-label'>Tags:</label>
                    <Select
                        isDisabled={loading}
                        options={array}
                        value={array.filter((e) => e.values)[0]}
                        name='id_tags'
                        placeholder='Opções Disponiveis'
                    />
                </div>
            </>
        )
    }

    data.GetExplorerTags.map((Options) => {
        array.push({ value: Options.id, label: Options.tag })
        return []
    })


    return (
        <>
            <div className='form-group'>
                <label className='col-form-label'>Tags:</label>
                <Select
                    isDisabled={loading}
                    isMulti
                    components={animatedComponents}
                    options={array}
                    onChange={(e: any) => {
                        setTags(e);
                    }}
                    name='id_tag'
                    placeholder='Opções Disponiveis'
                />
            </div>
        </>
    )
}