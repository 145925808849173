import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { ProdutosChosen } from './ProdutosChosen'
import { PeriodoChosen } from './PeriodoChosen'
import {
  MutationSetEmpresaProduto,
  QueryGetProdutosByEmpresaID,
} from '../../../graphql/services/Empresas'
import { BiShoppingBag } from 'react-icons/bi'
import DateRangePickerComponent from '../profile/components/partials/DataPickerProducts'
import moment from 'moment'
import { setEmpresaProdutoFormData } from '../../../formValidations/Empresa'

export function ModalAssociationProduto({ id_empresa }) {
  const [show, setShow] = useState(false)
  const { FormEmpresaProduto, handleSubmit, register, setValue, data, error } = MutationSetEmpresaProduto()
  const [valueUsuarioProduto, setValueUsuarioProduto] = useState(null)
  const [valuePeriodo, setValuePeriodo] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const [isPersonalizada, setIsPersonalizada] = useState(false)
  let isDisable = true

  if (valuePeriodo === null || valueUsuarioProduto === null) {
    isDisable = true
  } else if (valuePeriodo === 6 && (startDate === null || endDate === null)) {
    isDisable = true
  } else {
    isDisable = false
  }

  const { data: dataProduto } = QueryGetProdutosByEmpresaID({
    variables: { empresaId: parseInt(id_empresa) },
  })

  function closeModal() {
    setShow(false)
  }
  function openModal() {
    setShow(true)
  }

  function converterData(dataString, formato = 'YYYY-MM-DD') {
    return moment(dataString).toDate()
  }

  if (startDate && endDate) {
    const startDateConverted = converterData(startDate)
    const endDateConverted = converterData(endDate)

    setValue('data_inicio', startDateConverted)
    setValue('data_fim', endDateConverted)
  }

  const onSubmit = async (data: setEmpresaProdutoFormData) => {
    try {
      await FormEmpresaProduto(data);
  
      // Exibe mensagem de sucesso se a operação for bem-sucedida
      Swal.fire('Sucesso!', 'Novo produto associado com sucesso.', 'success');
      closeModal(); // Fecha o modal após sucesso
    } catch (err) {
      // Captura e exibe a mensagem de erro
      console.error('Erro durante o processo:', err);
  
      // Verifica se o erro tem uma mensagem
      const errorMessage = err instanceof Error ? err.message : 'Ocorreu um erro durante o processo.';
      Swal.fire('Erro!', errorMessage, 'error');
    }
  };
  

  if (!dataProduto) {
    return <></>
  }

 
  return (
    <>
      <button
        onClick={openModal}
        className='btn btn-light-primary btn-sm d-block me-2'
        style={{ width: '100px !important' }}
      >
        Adicionar Produto <BiShoppingBag className='fs-2' />
      </button>

      <Modal centered animation show={show} onHide={() => closeModal()} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Associar produtos:</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-lg'>
          <div className='row'>
            <div className='form-group'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <input
                      className='form-control form-control-solid'
                      placeholder='id'
                      hidden={true}
                      defaultValue={id_empresa}
                      {...register('id_empresa', { required: true, valueAsNumber: true })}
                    />

                    <ProdutosChosen
                      valueUsuarioProduto={valueUsuarioProduto}
                      setValueUsuarioProduto={(value: number) => {
                        setValue('id_produto', value)
                        setValueUsuarioProduto(value)
                      }}
                    />
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <PeriodoChosen
                      valuePeriodo={valuePeriodo}
                      setValuePeriodo={(value: number) => {
                        setValue('id_periodo', value)
                        setValuePeriodo(value)
                      }}
                      setIsPersonalizada={setIsPersonalizada} // Passando setIsPersonalizada como prop
                    />
                  </div>
                </div>

                {isPersonalizada ? (
                  <DateRangePickerComponent
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={setStartDate}
                    onEndDateChange={setEndDate}
                    focusedInput={focusedInput}
                    setFocusedInput={setFocusedInput}
                  />
                ) : (
                  <></>
                )}

                <div className='col-md-12 d-flex mrt-1' style={{ justifyContent: 'flex-end' }}>
                  <button
                    onClick={() => closeModal()}
                    className='btn btn-danger pull-right'
                    type='button'
                    data-bs-dismiss='modal'
                  >
                    Fechar
                  </button>
                  <button disabled={isDisable} type='submit' className='btn btn-success mx-2'>
                    Associar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
