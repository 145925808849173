type Props = {
  param: string
}

export function TabAtividades({param}: Props) {

  return (
    <div 
    className={`tab-pane fade ${param === 'atividades' ? 'show active' : ''}`}

    id="atividades" role="tabpanel" aria-labelledby="atividades">
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Atividades</h3>
        </div>
        <div className='card-body p-6 pt-5'>
        </div>
      </div>
    </div>
  )
}