import { useQuery } from '@apollo/client';
import React, { useState } from 'react';

import Select from 'react-select';
import { GET_EMPREENDIMENTO_TIPO } from '../../../../graphql/query/empreendimento-tipo';
import { GetEmpreendimentoTipo } from '../../../../graphql/type/empreendimento-tipo';



export function GetEmpreendimentoTipoChosen() {

    const { data } = useQuery<GetEmpreendimentoTipo>(GET_EMPREENDIMENTO_TIPO, {
        variables: {
            pagination: {
                pagina: null,
                quantidade: null
            }
        }
    })

    let options = []

    const [tipos, setTipos] = useState('')
    // Tratativa de loading
    if (!data) {
        return (
            <Select options={options} onChange={(e) => { setTipos(e.value) }} isDisabled={true} placeholder="Tipos de Empreendimento" />
        )
    }

    data.GetEmpreendimentoTipo.result.map(Options => {
        options.push({ value: Options.id, label: Options.nome })
        return []
    })

    return (
        <Select
            menuPortalTarget={document.body}
            // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            options={options}
            onChange={(e) => { setTipos(e.value) }}
            placeholder="Tipos de Empreendimento" />
    )
}