import {gql} from '@apollo/client'

export const GET_INTELIGENCIA_MERCADO = gql`
  query GetInteligenciaMercado($pagination: Pagination!, $filter: filter) {
    GetInteligenciaMercado(pagination: $pagination, filter: $filter) {
      result {
        id
        json
        nome
        situacao
        id_usuario_cadastro
        data_cadastro
        id_usuario_alteracao
        data_alteracao
        id_usario_exclusao
        data_exclusao
      }
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const GET_INTELIGENCIA_MERCADO_BY_ID = gql`
  query GetInteligenciaMercadoById($getInteligenciaMercadoById: Float!) {
    GetInteligenciaMercadoById(id: $getInteligenciaMercadoById) {
      id
      json
      nome
      situacao
      id_usuario_cadastro
      data_cadastro
      id_usuario_alteracao
      data_alteracao
      id_usario_exclusao
      data_exclusao
    }
  }
`

export const GET_INTELIGENCIA_MERCADO_VSO = gql`
  query GetInteligenciaMercadoVSO($pagination: Pagination) {
    GetInteligenciaMercadoVSO(pagination: $pagination) {
      result {
        id
        vso
        situacao
        id_usuario_cadastro
        data_cadastro
        id_usuario_alteracao
        data_alteracao
        id_usario_exclusao
        data_exclusao
        nome
      }
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const GET_INTELIGENCIA_MERCADO_VSO_BY_ID = gql`
  query GetInteligenciaMercadoVSOById($getInteligenciaMercadoVsoByIdId: Float) {
    GetInteligenciaMercadoVSOById(id: $getInteligenciaMercadoVsoByIdId) {
      id
      vso
      situacao
      id_usuario_cadastro
      data_cadastro
      id_usuario_alteracao
      data_alteracao
      id_usario_exclusao
      data_exclusao
      nome
    }
  }
`
