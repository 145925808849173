import {Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {MutationSetNewsFeed} from '../../../graphql'
import {FormInputAndLabel} from '../../../../components/UI/Inputs/FormInputAndLabel'
import {FormTextareaAndLabel} from '../../../../components/UI/Inputs/FormTextareaAndLabel'

interface Props {
  closeModal: any
  show: boolean
}

export function CreateForm({closeModal, show}: Props) {
  const {FormSetNewsFeed, errors, loading, register, handleSubmit} = MutationSetNewsFeed()

  const onSubmit = async (data) => {
    FormSetNewsFeed(data)
      .then((result) => {
        Swal.fire('Sucesso!', 'Notícia criado com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  if (loading) {
    Swal.fire('Enviando Informações...', '')
    Swal.showLoading()
  }
  return (
    <Modal centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Nova Notícia:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>
            <div className='col-md-12'>
              <div className='mb-6'>
                <FormInputAndLabel
                  label='Titulo da Notícia'
                  className='form-control required form-control-solid'
                  placeholder='Titulo da Notícia'
                  register={register('title')}
                  error={errors ? errors.title?.message : ''}
                />
              </div>
            </div>
            <div className='col-md-12'>
              <div className='mb-6'>
                <FormTextareaAndLabel
                  label='Descrição da Notícia'
                  className='form-control required form-control-solid'
                  placeholder='Descrição da Notícia'
                  register={register('description')}
                  error={errors ? errors.description?.message : ''}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label className='col-form-label'>Data de Início</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    {...register('start_date', {shouldUnregister: true})} // Use shouldUnregister: true
                    onChange={(e) => {
                      const selectedDate = e.target.value ? new Date(e.target.value) : null // Converta para Date apenas se houver um valor
                      console.log(selectedDate)
                      // setValue('data_expiracao', selectedDate);
                    }}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label className='col-form-label'>Data de Termino</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    {...register('end_date', {shouldUnregister: true})} // Use shouldUnregister: true
                    onChange={(e) => {
                      const selectedDate = e.target.value ? new Date(e.target.value) : null // Converta para Date apenas se houver um valor
                      console.log(selectedDate)
                      // setValue('data_expiracao', selectedDate);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12 py-6 d-flex' style={{justifyContent: 'flex-end'}}>
            <button
              className='btn btnSecondary  pull-right '
              type='button'
              onClick={() => closeModal()}
            >
              Fechar
            </button>
            <button disabled={loading} type='submit' className='btn btn-success mx-2'>
              Salvar
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
