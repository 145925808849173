export const dadosFAQ =  {
        MeusImoveis: [
            {
                pergunta: 'Pergunta 1',
                resposta: 'Primeiro, um avisoIMOVEIS - todo o processo de escrever um post de blog...',
                video: true,
                urlVideo: 'https://www.youtube.com/embed/pzfbcsRqUx8'
            },
            {
                pergunta: 'Pergunta 2',
                resposta: 'Primeiro, um avisoIMOVEIS - todo o processo de escrever um post de blog...',
            },
            {
                pergunta: 'Pergunta 3',
                resposta: 'Primeiro, um avisoIMOVEIS - todo o processo de escrever um post de blog...',
            }
        ],
        Empreendimentos: [
            {
                pergunta: 'Pergunta 1',
                resposta: 'Primeiro, um avisoIMOVEIS - todo o processo de escrever um post de blog...',
            },
            {
                pergunta: 'Pergunta 2',
                resposta: 'Primeiro, um avisoIMOVEIS - todo o processo de escrever um post de blog...',
            },
            {
                pergunta: 'Pergunta 3',
                resposta: 'Primeiro, um avisoIMOVEIS - todo o processo de escrever um post de blog...',
            }
        ],
        AVM: [
            {
                pergunta: 'Pergunta 1',
                resposta: 'Primeiro, um avisoIMOVEIS - todo o processo de escrever um post de blog...',
            },
            {
                pergunta: 'Pergunta 2',
                resposta: 'Primeiro, um avisoIMOVEIS - todo o processo de escrever um post de blog...',
            },
            {
                pergunta: 'Pergunta 3',
                resposta: 'Primeiro, um avisoIMOVEIS - todo o processo de escrever um post de blog...',
            }
        ]
    };
