import React from 'react'
import {useLayer} from '../../../../../../../hooks/useLayer'
import {getLayer} from '../Utils/LayersFuncions'
import {Explorer} from '../../Explorer'
import {Filter} from '../Config'
import {TypeScaterPlot} from './layerFormats/scaterplot'
import {TypeGeoJson} from './layerFormats/polygon'
import {TypeIconLayer} from './layerFormats/icon'
import { FillCategory } from '../Config/FillCategory'

type Props = {
  id: String
}

export const LayerFormat: React.FC<Props> = ({id}) => {
  let uuid = id.split('_')[0]
  const {layers} = useLayer()
  const l = getLayer(layers, uuid)
  if(typeof l === 'undefined'){
    return <></>
  }
  const fields = l.explorer_tiles
  let layerCompite = []
  if (typeof l.composite !== 'undefined') {
    layerCompite = l.composite
  }
  function onChangeFilter(key, value, type_data) {
    Explorer.changeFilter(uuid, key, value, type_data)
  }



  function checkIsCircle(style){
    for(var i in style){
        if (i.indexOf('circle') !== -1){
          return true
        }
    }  
    return false
  }
  let isCircle =  checkIsCircle(l.paint)
  return (
    <div className='layer-format-content'>
      {fields ? (
        <Filter
          id={id + '_1'}
          fields={fields}
          onChange={onChangeFilter}
          defaultValue={l.filter}
        ></Filter>
      ) : (
        ''
      )}
      {l.type === 'MVTLayer' ? (
        <>
          <FillCategory id={id}  layer={l}  defaultValue={l.filter}></FillCategory>
          <TypeGeoJson id={uuid} defaultValue={l.paint}></TypeGeoJson>
        </>
      ) : (
        ''
      )}
      {l.type === 'GeoJSONLayer' ? (
        <TypeGeoJson id={uuid} defaultValue={l.paint}></TypeGeoJson>
      ) : (
        <></>
      )}
      {l.type === 'LayerPolygon' ? (
        <TypeGeoJson id={uuid} defaultValue={l.paint}></TypeGeoJson>
      ) : (
        <></>
      )}
      {isCircle ? <TypeScaterPlot id={uuid}> </TypeScaterPlot> : <></>}
      {l.type === 'LayerIcon' || layerCompite.find((i) => i === 'LayerIcon') ? (
        <TypeIconLayer id={uuid} defaultValue={l.paint}> </TypeIconLayer>
      ) : (
        <></>
      )}
      {/* {l.type === 'IconeLayerText' ? (
        <TypeIconLayer id={uuid} defaultValue={undefined}>
          {' '}
        </TypeIconLayer>
      ) : (
        <></>
      )} */}
        {l.type === 'MVTLayer' && layerCompite.find((i) => i === 'ScatterplotLayer') ? (
             <TypeScaterPlot id={uuid}> </TypeScaterPlot> 
      ) : (
        <></>
      )}
  
      {/* <Height id={id + '_6'} onChange={onChangeHeigth}></Height> */}
    </div>
  )
}
