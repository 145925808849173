import {FaInfoCircle} from 'react-icons/fa'
import {useEffect, useState} from 'react'
import {Alert} from 'react-bootstrap'
import {APiUrbit} from '../../../../../services/api/apiUrbit'
import OverlayPopover from '../../../../../components/UI/Popover'
import {RenderItem} from './RenderFicha'
import {ApiUrbit} from '../../../../../../services/http/urbit'

type Props1Item = {
  url: any
  lat: any
  lng: any
  municipio: any
  servico: any
  distancia: number
  setResultExport: any
  imovelId: number
}

export const ServicoCard: React.FC<Props1Item> = ({
  url,
  servico,
  lat,
  lng,
  municipio,
  distancia,
  setResultExport,
  imovelId,
}) => {
  const [result, setResult] = useState(null)
  useEffect(() => {
    async function fetchData() {
      let data = null
      if (municipio) {
        data = await ApiUrbit.getServico(url, lat, lng, municipio, distancia)
        setResult(data)
      } else {
        const municipio = await ApiUrbit.get(`municipios/${lng}/${lat}`)
        data = await ApiUrbit.getServico(url, lat, lng, municipio.municipio_url, distancia)
        setResult(data)
      }
      // let servico = {}
      // if (typeof servico[imovelId] === 'undefined'){
      //   servico[imovelId] = {}
      // }
      // servico[imovelId][url] =  data
      setResultExport(imovelId, url, data)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return (
    <>
      {result ? (
        <div className=''>
          {result === null ? (
            <div>Carregando...</div>
          ) : typeof result.message != 'undefined' ? (
            <Alert>Erro ao carregar o serviço'</Alert>
          ) : (
            <RenderItem
              item={result['itens']}
              keyItem={servico['session_name']}
              text={servico['nome']}
            ></RenderItem>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
