import { Nav, Tab } from "react-bootstrap";
import { GetEstadoCidadeChosen } from "../../../../../components/UI/Chosens/GetEstadoCidadeChosen";

interface Props {
  clausula_limit: string;
  clausula_distance: string;
  clausula_geom: string;
  clausula_intersect: string;
}

export function TabsContainer({ clausula_distance, clausula_geom, clausula_intersect, clausula_limit }: Props) {
  return (
    <div className='col-md-12'>
      <div className='card'>
        <div className='card-header border-0 pt-9'>
          <div className='card-title m-0'>
            Configurações do serviço
            <div className='symbol symbol-50px w-50px bg-light'></div>
          </div>

          <div className='card-toolbar'>
            <span
              className={`badge badge-light-succes fw-bolder me-auto px-4 py-3`}
            ></span>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='fs-3 fw-bolder text-dark'>Local</div>
          <div className='row mb-6'>
            <GetEstadoCidadeChosen
              classNameCity={'col-6 mt-1'}
              classNameEstado={'col-6   mt-1'}
              defaultValueEstado={0}
              defaultValueCidade={0}
              SetValueEstado={(value) => {
                // console.log('SetValueEstado', value)
                // setValueEstado(value)
                // console.log('estado')
                // console.log(value)
                // setValue('id_estado', value)
                // setValue('id_cidade', null)
              }}
              SetValueCidade={(value) => {
                // setValueCidade(value)
                // setValue('id_cidade', value)
              }}
            />
          </div>
          <hr className="mt-6" />
          <div className='row'>
            <div className='col-12' id='horizontal-tabs-example'>
              <Tab.Container defaultActiveKey='first'>
                <div className='d-flex'>
                  <Nav variant='pills' className='flex-row'>
                    <Nav.Item>
                      <Nav.Link eventKey='first'>Query</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='second'>
                        Colunas para retorno ao cliente
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='third'>Template Exibição</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='fourt'>Replicar consulta </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="row">
                  <Tab.Content className='p-3 w-80'>
                    <Tab.Pane eventKey='first'>
                      <div className='col-md-12'>
                        <h3 className='mb-5'>Consulta</h3>
                        <div className='well'>
                          <div className=''>
                            <textarea
                              rows={10}
                              className='form-control w-100'
                              name='servico-cidade-api-query'
                              id='servico-cidade-api-query'
                              placeholder='Consulta do serviço'
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <h3 className='mt-10 mb-5'>Variáveis da query</h3>
                        <div className='well' id='fixed-variables'>
                          <table className='table table-hover'>
                            <thead>
                              <tr>
                                <th>Variável</th>
                                <th>Valor</th>
                              </tr>
                            </thead>
                            <tbody id='fixed-variables-tbody'>
                              <tr data-name='longitude'>
                                <td>
                                  {' '}
                                  <span className='var-status'>
                                    <i className='fa fa-times text-danger'></i>
                                  </span>{' '}
                                  longitude
                                </td>
                                <td className='d-flex align-items-center '>
                                  <div className='col-md-8'>
                                    <input
                                      className='form-control fixed-query-variable-value'
                                      id='input-lng'
                                      type='text'
                                      value=''
                                    />
                                  </div>
                                  <div className='col-md-4 ms-5'>
                                    <input
                                      className='fixed-query-variable-required'
                                      type='checkbox'
                                      value='1'
                                    />
                                    Obrigatório
                                  </div>
                                </td>
                              </tr>
                              <tr data-name='latitude'>
                                <td>
                                  {' '}
                                  <span className='var-status'>
                                    <i className='fa fa-times text-danger'></i>
                                  </span>{' '}
                                  latitude
                                </td>
                                <td className='d-flex align-items-center '>
                                  <div className='col-md-8'>
                                    <input
                                      className='form-control fixed-query-variable-value'
                                      id='input-lat'
                                      type='text'
                                      value=''
                                    />
                                  </div>
                                  <div className='col-md-4 ms-5'>
                                    <input
                                      className='fixed-query-variable-required'
                                      type='checkbox'
                                      value='1'
                                    />
                                    Obrigatório
                                  </div>
                                </td>
                              </tr>
                              <tr data-name='clausula_limit'>
                                <td>
                                  {' '}
                                  <span className='var-status'>
                                    <i className='fa fa-times text-danger'></i>
                                  </span>{' '}
                                  limit
                                  {clausula_limit}
                                </td>
                                <td className='d-flex align-items-center '>
                                  <div className='col-md-8'>
                                    <input
                                      className='form-control fixed-query-variable-value'
                                      type='number'
                                      value=''
                                    />
                                  </div>
                                  <div className='col-md-4 ms-5'>
                                    <input
                                      className='fixed-query-variable-required'
                                      type='checkbox'
                                      value='1'
                                    />
                                    Obrigatório
                                  </div>
                                </td>
                              </tr>
                              <tr data-name='clausula_distance'>
                                <td>
                                  {' '}
                                  <span className='var-status'>
                                    <i className='fa fa-times text-danger'></i>
                                  </span>{' '}
                                  distance
                                  {clausula_distance}
                                </td>
                                <td className='d-flex align-items-center '>
                                  <div className='col-md-8'>
                                    <input
                                      className='form-control fixed-query-variable-value'
                                      type='number'
                                      value=''
                                    />
                                  </div>
                                  <div className='col-md-4 ms-5'>
                                    <input
                                      className='fixed-query-variable-required'
                                      type='checkbox'
                                      value='1'
                                    />
                                    Obrigatório
                                  </div>
                                </td>
                              </tr>
                              <tr data-name='clausula_geom'>
                                <td>
                                  {' '}
                                  <span className='var-status'>
                                    <i className='fa fa-times text-danger'></i>
                                  </span>{' '}
                                  geom
                                  {clausula_geom}
                                </td>
                                <td className='d-flex align-items-center '>
                                  <div className='col-md-8'>
                                    <select className='form-control fixed-query-variable-value'>
                                      <option value=''> -- selecione --</option>
                                      <option value='1'>true</option>
                                      <option value='0'>false</option>
                                    </select>
                                  </div>
                                  <div className='col-md-4 ms-5'>
                                    <input
                                      className='fixed-query-variable-required'
                                      type='checkbox'
                                      value='1'
                                    />
                                    Obrigatório
                                  </div>
                                </td>
                              </tr>
                              <tr data-name='clausula_intersect'>
                                <td>
                                  {' '}
                                  <span className='var-status'>
                                    <i className='fa fa-times text-danger'></i>
                                  </span>{' '}
                                  geom intersect
                                  {clausula_intersect}
                                </td>
                                <td className='d-flex align-items-center '>
                                  <div className='col-md-8'>
                                    <input
                                      className='form-control fixed-query-variable-value'
                                      type='text'
                                      value=''
                                    />
                                  </div>
                                  <div className='col-md-4 ms-5'>
                                    <input
                                      className='fixed-query-variable-required'
                                      type='checkbox'
                                      value='1'
                                    />
                                    Obrigatório
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                            <tbody id='query-variables-tbody'>
                              <tr data-name='none'>
                                <td colSpan={2}>
                                  Nenhuma variável adicional encontrada.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='second'>
                      <div className='well'>
                        <h3>Resultado da consulta</h3>
                        <pre id='result-query-preview' style={{ maxHeight: 300 }}>
                          "Vazio"
                        </pre>
                        <h3>Colunas retornadas</h3>
                        <table id='' className='table table-hover'>
                          <tbody id='tbody-colunas'>
                            <tr>
                              <td> — </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className='form-group'>
                          <h3>Colunas para retorno</h3>
                          <div className='table-container' style={{ overflow: 'auto' }}>
                            <table
                              id='table-colums-config'
                              className='table table-hover'
                            >
                              <thead>
                                <tr>
                                  <th>Coluna</th>
                                  <th>Descrição</th>
                                  <th>Tipo de retorno</th>
                                  <th style={{ minWidth: 250 }}>Valores</th>
                                  <th>Status</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody id='tbody-colunas-retorno'>
                                <tr>
                                  <td colSpan={6}> Nenhuma coluna para retorno </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className='form-group'>
                              <button
                                className='btn btn-block btn-primary'
                                type='button'
                                id='btn-save-return-configs'
                              >
                                {' '}
                                Salvar configurações de retorno
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <button
                            className='btn btn-block btn-primary'
                            data-loading-text='Salvando query...'
                            type='button'
                            id='btn-save-query'
                          >
                            {' '}
                            Salvar consulta &amp; colunas para retorno
                          </button>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='third'>
                      <h3>Template de exibição</h3>

                      <div className='row'>
                        <div className='col-md-4 '>
                          <div className='col-md-6 '>
                            <div className='form-group'>
                              <label>Latitude</label>
                              <input
                                type='text'
                                className='form-control'
                                id='display-config-lat'
                              />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <label>Longitude</label>
                              <input
                                type='text'
                                className='form-control'
                                id='display-config-lng'
                              />
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='form-group'>
                              <button
                                className='btn btn-block btn-primary'
                                type='button'
                                id='btn-test-service'
                              >
                                {' '}
                                Testar retorno do serviço{' '}
                              </button>
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='well'>
                              <pre id='result-preview'>vazio</pre>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          Valor dinâmico para exibição no painel Urbit
                          <textarea
                            className='form-control'
                            cols={20}
                            rows={5}
                          ></textarea>
                        </div>
                        <div className='col-md-4'>
                          Informações adicionais do serviço
                          <textarea
                            className='form-control'
                            cols={20}
                            rows={5}
                          ></textarea>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='fourt'>
                      <h3>Replicar serviços</h3>

                      <div className='row'>
                        <div className='col-2'>
                          <button
                            className='btn btn-warning'
                            type='button'
                            id='btn-replicate-query'
                            data-servico-cidade-id=''
                            data-servico-cidade-nome=''
                          >
                            <i className='fa fa-copy'></i> Replicar consulta{' '}
                          </button>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='mirrors-table'>
                            <table className='table table-hover' id='table-mirrors'>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Criado em</th>
                                  <th>Criado por</th>
                                  <th>status</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan={5}> - </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}