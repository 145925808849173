import {useMemo} from 'react'
import {ImoveisMercadoSecundario} from '../../Types'
import {BarChart} from './BarChart'

type Props = {
  label?: string
  itens: ImoveisMercadoSecundario[]
}

export default function NumeroOfertas({label, itens}: Props) {
  let {chartDataVSOMetragem} = useMemo(() => {
    let totalUnidades = Array(7).fill(0, 0, 7)
  let chartDataVSOMetragem = [
      {
        name: 'Qtde Ofertas',
        data: [],
      },
    ]

    // eslint-disable-next-line array-callback-return
    itens.map((item) => {
      let i = item.quartos
      totalUnidades[i] = totalUnidades[i] + 1
    })

    totalUnidades.map((item, key) => {
      chartDataVSOMetragem[0].data.push({
        x: key +' Dorm.',
        y: item,
        total_unidades: item,
        id: key,
      })
    })

    return {chartDataVSOMetragem}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens])
  return (
    <>
      {/* <div className='col-6'> */}
        <BarChart
          data={chartDataVSOMetragem}
          className={''}
          title={'Qtde de ofertas por dormitórios'}
          subtitle={''}
          onChange={() => {}}
          legenda={['Total Unidades']}
          tooltip={''}
        ></BarChart>
      {/* </div>
      <div className='col-6'>Map</div> */}
    </>
  )
}
