import React, { useState } from 'react';
import { ModalCreateUser } from './TabFuncionarios-Partials/CreateUser';
import { useParams } from 'react-router-dom';
import { QueryGetAdminEmpresaUsuarios } from '../../../../graphql/services/Empresas';
import { Buttons } from './TabFuncionarios-Partials/buttons';
import PaginationComponent from '../../../../components/UI/PaginationComponents';

export function TabFuncionarios() {
  const { id: idEmpresa } = useParams<{ id: string }>();
  const [paginacao, setPaginacao] = useState({ pagina: 0, quantidade: 10 });
  const [filter, setFilter] = useState([]);

  const {data} = QueryGetAdminEmpresaUsuarios({
    variables: {
      pagination: {
        pagina: paginacao.pagina,
        quantidade: paginacao.quantidade
      },
      filtro: {
        fields: filter,
      }
    }
  });

  function addFilter(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const filterArray = [];

    const nome = formData.get('filter-input'); // Obtém o valor do input de filtro
    if (nome) {
      filterArray.push({
        field: ['users.name', 'users.email'],
        operator: 'LIKE',
        value: nome,
      });
    }

    setFilter([...filterArray]);
  }

  if (!data || !data.GetEmpresasUsuarioAdmin || !data.GetEmpresasUsuarioAdmin.result.length) {
    return (
      <div className="tab-pane fade" id="tab-funcionarios" role="tabpanel" aria-labelledby="tab-funcionarios-tab">
        <div className="card mb-5 mb-xl-10">
          <div className="card-header">
            <div className="card-title">
              <h3>Funcionários</h3>
            </div>
            <div className="card-toolbar">
              <form className='form-group all-center me-4' onSubmit={addFilter}>
                <input type="text" name="filter-input" placeholder='Procurar Funcionário' className='form-control me-2' />
                <button className='btn d-block btn-sm btn-light-success' type="submit">Buscar</button>
              </form>
              <ModalCreateUser id_empresa={idEmpresa} />
            </div>
          </div>
          <div className="card-body px-12">
            <div className="table-responsive">
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-150px'>Nome</th>
                    <th className='min-w-140px'>Visualização</th>
                    <th className='min-w-140px'>Edição</th>
                    <th className='min-w-100px'>Exclusão</th>
                    <th className='min-w-100px text-end'>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={5} className='text-center'>
                      <h1 className='fs-4 text-gray'>Nenhum usuário encontrado.</h1>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Renderização da tabela com dados
  return (
    <div className="tab-pane fade" id="tab-funcionarios" role="tabpanel" aria-labelledby="tab-funcionarios-tab">
      <div className="card mb-5 mb-xl-10">
        <div className="card-header">
          <div className="card-title">
            <h3>Funcionários</h3>
          </div>
          <div className="card-toolbar">
            <form className='form-group all-center me-4' onSubmit={addFilter}>
              <input type="text" name="filter-input" placeholder='Procurar Funcionário' className='form-control me-2' />
              <button className='btn d-block btn-sm btn-light-success' type="submit">Buscar</button>
            </form>
            <ModalCreateUser id_empresa={idEmpresa} />
          </div>
        </div>
        <div className="card-body px-12">
          <div className="table-responsive">
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px'>Nome</th>
                  <th className='min-w-140px'>Visualização</th>
                  <th className='min-w-140px'>Edição</th>
                  <th className='min-w-100px'>Exclusão</th>
                  <th className='min-w-100px text-end'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {data.GetEmpresasUsuarioAdmin.result.map(Response => (
                  <tr key={Response.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark text-hover-primary fs-6'>
                            {Response.users.name}
                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                              {Response.users.email}
                            </span>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='text-start'>
                      <span className='text-dark fs-6'>
                        {Response.visualizacao}
                      </span>
                    </td>
                    <td className='text-start'>
                      <span className='text-dark fs-6'>
                        {Response.edicao}
                      </span>
                    </td>
                    <td className='text-start'>
                      <span className='text-dark fs-6'>
                        {Response.exclusao}
                      </span>
                    </td>
                    <td>
                      <Buttons idAssociacao={Response.id} idUsuario={Response.users.id} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='all-end pb-6'>
            <PaginationComponent
              pagesInfo={data.GetEmpresasUsuarioAdmin.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({ pagina: pagina, quantidade: quantidade })
              }}
            ></PaginationComponent>
          </div>
        </div>
      </div>
    </div>
  );
}
