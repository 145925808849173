import {ProjectMenu} from './ProjectMenu'
import {ModalAdicionarCamada} from './Camadas/ModalAdicionarCamada'
import {ProjetoActions} from './ProjectActions'

export default function TabProjetos() {
  return (
    <div className='tab-pane fade show acive' id='home' role='tabpanel' aria-labelledby='home-tab'>
      <div className='' id='' role='tabpanel' aria-labelledby='contact-tab'>
        <ProjetoActions id={1} />
        <ModalAdicionarCamada />
        {/* <button className=' nav-link btn btn-light' onClick={criarCamada}>
                <FaPenNib color="#7e8299" className='pe-2 fs-1'></FaPenNib>
                Criar Camada
              </button> */}
        <ProjectMenu />
      </div>
    </div>
  )
}
