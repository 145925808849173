import { useEffect, useState } from 'react';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { QueryGetCategorias } from '../../../graphql/services/Categorias';

type Categoria = {
    id: number;
    nome: string;
}

type Props = {
    setValueCategoria: React.Dispatch<React.SetStateAction<number[]>>;
    valueCategoria: number[];
}

export function CategoriasChosen({
    setValueCategoria,
    valueCategoria
}: Props) {
    const animatedComponents = makeAnimated();

    const { data, loading } = QueryGetCategorias({
        variables: {
            pagination: {
                pagina: 0,
                quantidade: 400
            }
        }
    })

    const [categorias, setCategorias] = useState<Categoria[]>([]);

    useEffect(() => {
        if (data && data.GetCategorias && data.GetCategorias.result) {
            setCategorias(data.GetCategorias.result);
        }
    }, [data]);

    useEffect(() => {
        if (valueCategoria.length > 0 && categorias.length > 0) {
            const defaultValues = valueCategoria.map(id => categorias.find(categoria => categoria.id === id))
                                                .filter(categoria => categoria !== undefined)
                                                .map(categoria => ({
                                                    value: categoria.id,
                                                    label: categoria.nome
                                                }));
            setValueCategoria(valueCategoria);
            setSelectedOptions(defaultValues);
        }
    }, [valueCategoria, categorias, setValueCategoria]);

    const options = categorias.map(categoria => ({
        value: categoria.id,
        label: categoria.nome,
    }));
    console.log(options)
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

    return (
        <>
            <div className='form-group'>
                <label className='col-form-label'>Categorias:</label>
                <Select
                    isDisabled={loading}
                    isMulti
                    components={animatedComponents}
                    options={options}
                    value={selectedOptions}
                    onChange={(selectedOptions: any) => {
                        setSelectedOptions(selectedOptions);
                        const selectedIds = selectedOptions.map((option: any) => option.value);
                        setValueCategoria(selectedIds);
                    }}
                    name='id_categoria'
                    placeholder='Categorias Disponíveis'
                />
            </div>
        </>
    );
}
