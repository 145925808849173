export const RelatedPeople = ({ data, estrutura }) => {

  let dateReturn = null
  if (typeof data['Result'] !== 'undefined' && data['Result'].length > 0 && data['Result'][0]['RelatedPeople'] && data['Result'][0]['RelatedPeople']['PersonalRelationships']) {
    dateReturn = data['Result'][0]['RelatedPeople']['PersonalRelationships'];
  } else if (data.length > 0 && data[0]['RelatedPeople'] && data[0]['RelatedPeople']['PersonalRelationships']) {
    dateReturn = data[0]['RelatedPeople']['PersonalRelationships'];
  } else {
    dateReturn = [];  // or handle the case where data structure doesn't match expected format
  }
  

  return (
    <>
      <h3 className="pt-10">{estrutura.titulo}</h3>

      <table>
        <thead>
          <tr>
            {estrutura['colunsItens'].map((column, index) => {
              return (
                <th key={index} className='fs-bold'>
                  {column}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {dateReturn?.map((item, index) => {
            return (
              <tr key={index}>{estrutura['colunsItens'].map((column, index2) => {
                return <td key={index2}>{item[column]}</td>
              })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
