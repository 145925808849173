import { KTSVG } from "../../../../../../../_metronic/helpers";

export function TableDocumentos() {
  return (
    <div className='table-responsive'>
      {/* begin::Table */}
      <table className='table table-striped gs-7'>
        {/* begin::Table head */}
        <thead>
          <tr className='fw-bolder text-muted'>
            <th className='min-w-50px'>Arquivo</th>
            <th className='min-w-50px'>Data de envio</th>
            <th className='min-w-140px'>Exibir no cadastro</th>
            <th className='min-w-100px text-end'>Ações</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          <tr>
            <td>
              <div className='d-flex align-items-center'>
                <div className='d-flex justify-content-start flex-column'>
                  <a href='#vazio' className='text-dark fw-bolder text-hover-primary fs-6'>
                    Arquivo.pdf
                  </a>
                </div>
              </div>
            </td>
            <td>
              <a href='#vazio' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                23/04/2021
              </a>
            </td>

            <td>
              <a href='#vazio' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                Sim
              </a>
            </td>

            <td>
              <div className='d-flex justify-content-end flex-shrink-0'>
                <a
                  href='#vazio'
                  className='btn btn-icon btn-light-warning btn-active-color-white btn-sm  btn-sm me-2'
                >
                  <KTSVG path='/media/icons/duotune/files/fil018.svg' className='svg-icon-3' />
                </a>
                <a
                  href='#vazio'
                  className='btn btn-icon btn-light-success btn-active-color-white  btn-sm me-2'
                >
                  <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-3' />
                </a>
                <a
                  href='#vazio'
                  className='btn btn-icon btn-light-danger btn-active-color-white btn-sm me-1'
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen027.svg'
                    className='svg-icon-3'
                  />
                </a>
              </div>
            </td>
          </tr>
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table */}
    </div>
  )
}