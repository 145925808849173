import React from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import {MutationDeleteImovelCorretores} from '../../../../../../graphql/services/ImovelCorretores'
import {MutationSetLogAcessoRecurso} from '../../../../../../graphql/services/LogAcesso'
import {useParams} from 'react-router-dom'

type Props = {
  id: number
  setIdEdit?: any
}

export const Buttons: React.FC<Props> = ({id, setIdEdit}) => {
  const {id: idTerreno} = useParams<{id: string}>()
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  const {HandleDeleteImovelCorretores} = MutationDeleteImovelCorretores()

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteImovelCorretores({variables: {deleteImovelCorretorId: id}})
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          FormSetLogAcessoRecurso({
            ferramenta: 'meus-imoveis',
            funcionalidade: 'corretores',
            action: 'delete',
            url: '/meus-imoveis/details/' + idTerreno + '#corretores',
          })
          Swal.fire('Sucesso!', 'Corretor deletado com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          onClick={(e) => {
            setIdEdit(id)
          }}
          className='btn btn-icon btn-light-success btn-sm me-2'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button>
        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          className='btn btn-icon btn-light-danger btn-sm me-2'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
