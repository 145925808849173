import {BiX, BiXCircle} from 'react-icons/bi'
import TabProjetos from '../Menu/Projetos'
import {MeusLocais} from './MeusLocais'

function ExplorerMenu() {
  const showMenu = () => {
    if (document.getElementById('kt_aside_nav_tab_explorer').classList.contains('showMenu')) {
      document.getElementById('kt_aside_nav_tab_explorer').style.display = 'none'
      document.getElementById('kt_aside_nav_tab_explorer').classList.remove('showMenu')
    } else {
      document.getElementById('kt_aside_nav_tab_explorer').style.display = 'block'
      document.getElementById('kt_aside_nav_tab_explorer').classList.add('showMenu')
    }
  }

  return (
    <>
      <div
        className=' d-flex flex-column flex-row-fluid'
        id='kt_aside_nav_tab_explorer'
        style={{
          paddingLeft: '100px',
          width: '50rem',
          backgroundColor: '#fff',
          boxShadow: '0 1px 2px rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15)',
          zIndex: 10,
          minWidth: '26rem',
        }}
        role='tabpanel'
      >
        <div
          className='menu-title-gray-500 menu-icon-gray-300 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold fs-0 px-0 my-0 my-lg-0'
          id='kt_aside_menu'
          data-kt-menu='true'
        >
          <div id='kt_aside_menu_wrapper' style={{width: '100%'}} className='menu-fit'>
            <ul
              className='nav nav-explorer d-flex justify-content-start mt-0'
              id='tab'
              role='tablist'
            >
              <li
                className='nav-item d-flex  w-100 align-itens-center justify-content-between'
                role='presentation'
              >
                <button
                  className='nav-link active '
                  id='home-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#home'
                  type='button'
                  role='tab'
                  aria-controls='home'
                  aria-selected='true'
                >
                  Explorer
                </button>
                <div
                  className='btn btn-sm ms-5 mt-3'
                  id='btn-close-layer-explorer'
                  style={{display: 'none'}}
                  onClick={showMenu}
                >
                  {' '}
                  <BiX className='fs-1'></BiX> Fechar
                </div>
              </li>
            </ul>
            <div className='tab-content' id='tabContent'>
              <TabProjetos />
              <MeusLocais />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ExplorerMenu}
