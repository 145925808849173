import {useMapaConfig} from '../../../../../../hooks/useMapaConfig'
import {Layout} from '../Layout'
import {MenuTerritorio} from './MenuTerritorio'
import {MenuRelatorio} from './MenuRelatorio'
import {MenuEstatisticas} from './MenuStatisticas'
import {PainelImobiliario} from './Relatorios/painelImobiliario'
import {AvmLancamentos} from '../../../../../components/UI'
import {ClickInfo} from './ClickInfo/ClickInfo'
import {ModalAvm} from '../../../../../components/UI/Modals/modal-avm'
import {Endereco} from '../../../../inteligencia-mercado/components/Types'

export const LayoutControl: React.FC = () => {
  const {mapaConfig, onMapaConfig} = useMapaConfig()
  Layout.onMapaConfig = onMapaConfig

  let endereco: Endereco = {
    latitude: null,
    longitude: null,
    endereco_completo: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    cep: '',
  }

  if (mapaConfig.latLng && Object.keys(mapaConfig.latLng).length > 0) {
    endereco = {
      latitude: mapaConfig.latLng.lat(),
      longitude: mapaConfig.latLng.lng(),
      endereco_completo: 'Endereço selecionado no mapa',
      logradouro: 'Endereço selecionado no mapa',
      numero: '0',
      bairro: '',
      cidade: '',
      estado: '',
      cep: '',
    }
  }

  return (
    <>
      {mapaConfig.latLng && Object.keys(mapaConfig.latLng).length > 0 ? (
        <>
          <ClickInfo
            showHide={mapaConfig.showClickInfo}
            info={mapaConfig}
            latLng={mapaConfig.latLng}
          />
          <MenuTerritorio showHide={mapaConfig.showTerritorioMenu} latLng={mapaConfig.latLng} />
          <MenuRelatorio
            showHide={mapaConfig.showRelatorioMenu}
            latLng={mapaConfig.latLng}
            geom={mapaConfig.geom}
          />
          <MenuEstatisticas
            showHide={mapaConfig.showEstatisticasMenu}
            latLng={mapaConfig.latLng}
            geom={mapaConfig.geom}
            layer={mapaConfig.layerSelected}
          />

          {/* <ModalAVM itens={[]} latLng={mapaConfig.latLng} /> */}

          {mapaConfig.showAVM ? (
            <ModalAvm endereco={endereco} origem={'explorer'} show={true}></ModalAvm>
          ) : (
            <></>
          )}

          {/* <AvmLancamentos  lat={mapaConfig.latLng.lat()} lng={mapaConfig.latLng.lng()} endereco={'Teste'} origem='explorer'/> */}
          {/* <ModalAbrirProjeto show={false} close={undefined} /> */}
          <PainelImobiliario itens={[]} latLng={mapaConfig.latLng} show={true} />
        </>
      ) : (
        ''
      )}
    </>
  )
}
