import { gql } from "@apollo/client";

export const GET_IMOVEL_FICHAS = gql` 
  query GetImovelFicha($getImovelFichaId: Float!) {
    GetImovelFicha(id: $getImovelFichaId) {
      id
      nome
      endereco
      numero
      bairro
      cidade
      cep
      terreno_fichas {
        json_params
        id_terreno
        id_pacaote
      }
    }
  }
`


export const GET_IMOVEL_FICHAS_UUID = gql` 
  query GetImovelFichaUUID($getImovelFichaUUID: String!) {
    GetImovelFichaUUID(uuid: $getImovelFichaUUID) {
      id
      nome
      endereco
      numero
      bairro
      cidade
      cep
      latitude
      longitude
      terreno_fichas {
        json_params
        id_terreno
        id_pacaote
      }
    }
  }
`