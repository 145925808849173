import React, {useEffect, useState} from 'react'
import {BiAddToQueue} from 'react-icons/bi'
import {FormInputAndLabel} from '../../../../components/UI/Inputs/FormInputAndLabel'
import Swal from 'sweetalert2'
import {MutationEditExplorerLayer, QueryGetExplorerLayerByID} from '../../../graphql'
import {Loader} from '../../../../components/UI/Loader/Loader'
import {Link, useParams} from 'react-router-dom'
import {AsideDefault} from '../../../../../_metronic/layout/components/aside/AsideDefault'
import {HeaderWrapperFluid} from '../../../../../_metronic/layout/components/header/HeaderWrapperFluid'
import {FormTextareaAndLabel} from '../../../../components/UI/Inputs/FormTextareaAndLabel'
import {GetEstadoCidadeChosen} from '../../../../components/UI/Chosens/GetEstadoCidadeChosen'
import {Tr} from './Tr'
import {GetTablesGisChosen} from '../../../components/UI/GetTablesGis'
import {APiUrbit} from '../../../../services/api/apiUrbit'
import {errorHandler} from '../../../../graphql/errorHandler'
import {MultiSelectTags} from './MultiSelectTags'
import {putExplorerLayerFormData} from '../../../formValidations'
import {TablePermissions} from './TablePermissions'

interface TableColunas {
  character_maximum_length: number
  column_name: string
  data_type: string
  type_data?: string
  data_return?: string
  step?: string
}
export function AdminExplorerLayersEdit() {
  const {id} = useParams<{id: string}>()
  const idLayer = id
  const [showPermissions, setShowPermissions] = useState(false)
  const [tags, setTags] = useState([])
  const {errors, handleSubmit, loading, register, submitForm, setValue} =
    MutationEditExplorerLayer()

  const {data: DataGet} = QueryGetExplorerLayerByID({
    variables: {
      getExplorerLayerByIdId: parseInt(id),
    },
  })

  const [dataColunas, setDataColunas] = useState<TableColunas[] | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedValue, setSelectedValue] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showSwal, setShowSwal] = useState(false)
  const [table_name, setTableName] = useState('')
  const [tableSchema, setTableSchema] = useState('')

  async function getColunas(table_name: string, tableSchema: string) {
    const api = new APiUrbit()
    const result = await api.get(`explorer/tables-info?table=${table_name}&schema=${tableSchema}`)
    setDataColunas(result)
    setTableName(table_name)
    setTableSchema(tableSchema)
  }

  useEffect(() => {
    if (loading) {
      setShowSwal(true)
      Swal.fire('Enviando Informações...', '')
      Swal.showLoading()
    } else {
      setShowSwal(false) // Ocultar o Swal quando o carregamento estiver concluído
    }
  }, [loading])

  function onupdate(campo, valor) {
    setValue(campo, valor)
  }

  useEffect(() => {
    if (DataGet) {
      getColunas(DataGet.GetExplorerLayerById.source, DataGet.GetExplorerLayerById.schema_table)
    }
  }, [DataGet])
  if (!DataGet) {
    return (
      <>
        <AsideDefault />
        <HeaderWrapperFluid />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <div id='kt_content_container' className='container-fluid'>
                <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                  <div className='d-flex justify-content-end'>
                    <a href='/backoffice/explorer-layers' className='btn btn-secondary '>
                      Voltar
                    </a>
                  </div>
                  <div className='card mrt-2'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h3 className='py-4 px-4'>Editar Layer</h3>
                      </div>
                    </div>
                    <div className='card-body'>
                      <Loader text={'caregando...'}></Loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const handleSelectChange = (value) => {
    // const value = event.target.value;
    setSelectedValue(value)
    const [newTableSchema, newTableName] = value.split('.')
    setTableName(newTableName)
    setTableSchema(newTableSchema)
    setValue('schema_table', newTableSchema)
    setValue('url', newTableName)
    setValue('source', newTableName)
    setValue('source_layer', newTableName)
  }
  async function generateFiles(idLayer) {
    Swal.fire('Gerar arquivo de configuração no servidor...', '')
    Swal.showLoading()
    const api = new APiUrbit()
    const result = await api.get(`explorer/operation/gererate-files?idLayer=${idLayer}`)
    // Swal.close()
    if (!result.erro) {
      Swal.fire('Sucesso!', result.menssagem, 'success')
    } else {
      Swal.fire('Erro!', result.menssagem, 'error')
    }
  }

  const onSubmit = async (data: putExplorerLayerFormData) => {
    data['explorer_tiles'] = []
    data.column.map((el) => {
      data['explorer_tiles'].push({
        schema_name: data.schema_table,
        table_name: data.source,
        table_column: el.coluna,
        id_column: el.id ? el.coluna : undefined,
        // key_column:'',
        data_return: el.data || undefined,
        description: el.descricao,
        select_columns: el.select ? el.coluna : undefined,
        situacao: 1,
        // id_layer:'',
        type_data: el.type ? el.type : undefined,
        step: el.step ? Number(el.step) : undefined,
        filtro_principal: el.filtro_principal ? el.coluna : undefined,
      })
    })

    // data.tags.map((el) => {
    //   data['explorer_layer_tags'].push({
    //     id_tag: el.id_tag,
    //     id_layer: el.id_layer
    //   })
    // })

    delete data.column
    // delete data.tags

    submitForm(data)
      .then((result) => {
        if (!result.data) {
          let erroText = errorHandler(result.errors)
          Swal.fire('Erro!', erroText, 'error')
        } else {
          generateFiles(result.data.PutExplorerLayer.id)

          Swal.fire('Sucesso!', 'Explorer Layer criado com sucesso.', 'success')
        }
        // closeModal()
      })
      .catch(() => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                <div className='d-flex justify-content-end'>
                  <a href='/backoffice/explorer-layers' className='btn btn-secondary '>
                    Voltar
                  </a>
                </div>
                <div className='card mrt-2'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h3 className='py-4 px-4'>Editar Layer</h3>
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='border-0 '>
                      <div className='row gy-5 gx-xl-8'>
                        <div className='col-xl-12'>
                          <div className='card-xxl-stretch mb-5 mb-xl-8'>
                            <ul className='nav nav-tabs' id='myTab' role='tablist'>
                              <li className='nav-item' role='presentation'>
                                <button
                                  className='nav-link active'
                                  id='layer-tab'
                                  data-bs-toggle='tab'
                                  data-bs-target='#layer'
                                  type='button'
                                  role='tab'
                                  aria-controls='layer'
                                  aria-selected='true'
                                >
                                  Configurações
                                </button>
                              </li>
                              <li className='nav-item' role='presentation'>
                                <button
                                  className='nav-link'
                                  id='permissoes-tab'
                                  data-bs-toggle='tab'
                                  data-bs-target='#permissoes'
                                  type='button'
                                  role='tab'
                                  aria-controls='permissoes'
                                  aria-selected='false'
                                  onClick={() => setShowPermissions(true)}
                                >
                                  Permissões de visualização
                                </button>
                              </li>
                            </ul>
                            <div className='tab-content' id='myTabContent'>
                              <div
                                className='tab-pane fade show active'
                                id='layer'
                                role='tabpanel'
                                aria-labelledby='layer-tab'
                              >
                                <div className='form-group pt-6'>
                                  <form onSubmit={handleSubmit(onSubmit)}>
                                    <input
                                      type='hidden'
                                      className='form-control form-control-solid'
                                      value={id}
                                      {...register('id', {
                                        required: true,
                                        valueAsNumber: true,
                                        max: 100,
                                      })}
                                    />
                                    <div className='row'>
                                      <div className='col-md-6'>
                                        <label htmlFor='' className='col-form-label'>
                                          Selecionar Tabela
                                        </label>
                                        <GetTablesGisChosen
                                          onChange={handleSelectChange}
                                          valueDefault={
                                            DataGet
                                              ? DataGet.GetExplorerLayerById.schema_table +
                                                '.' +
                                                DataGet.GetExplorerLayerById.source
                                              : ''
                                          }
                                        ></GetTablesGisChosen>
                                        {/* {errors ? errors.schema_table?.message : ''} */}
                                      </div>
                                      <div className='col-6'>
                                        <FormInputAndLabel
                                          label='Schema'
                                          className='form-control form-control-solid'
                                          placeholder='nome_schema'
                                          register={register('schema_table', {required: true})}
                                          error={errors ? errors.schema_table?.message : ''}
                                          defaultValue={
                                            DataGet ? DataGet.GetExplorerLayerById.schema_table : ''
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='row'>
                                      <div className='col-6'>
                                        <FormInputAndLabel
                                          label='Nome'
                                          className='form-control form-control-solid'
                                          placeholder='Nome do Layer'
                                          register={register('nome', {required: true})}
                                          error={errors ? errors.nome?.message : ''}
                                          defaultValue={
                                            DataGet ? DataGet.GetExplorerLayerById.nome : ''
                                          }
                                        />
                                      </div>
                                      <div className='col-md-6'>
                                        <label htmlFor='' className='col-form-label'>
                                          Tipo
                                        </label>
                                        <select
                                          className='form-control form-control-solid'
                                          {...register('type')}
                                          defaultValue={
                                            DataGet ? DataGet.GetExplorerLayerById.type : ''
                                          }
                                        >
                                          <option value='MVTLayer'>MVTLayer</option>
                                          <option value='Fill'>Fill</option>
                                          <option value='Circle'>Circle</option>
                                          <option value='Geojson'>Geojson</option>
                                          <option value='Line'>Line</option>
                                          <option value='Symbol'>Symbol</option>
                                        </select>
                                        {errors ? errors.type?.message : ''}
                                      </div>
                                    </div>

                                    <div className='row '>
                                      <div className='col-md-6'>
                                        <FormInputAndLabel
                                          label='Url'
                                          className='form-control form-control-solid'
                                          placeholder='Ex: afforbility_sp'
                                          register={register('url', {required: true})}
                                          error={errors ? errors.url?.message : ''}
                                          defaultValue={
                                            DataGet ? DataGet.GetExplorerLayerById.url : ''
                                          }
                                        />
                                      </div>
                                      <div className='col-md-6'>
                                        <FormInputAndLabel
                                          label='Source'
                                          className='form-control form-control-solid'
                                          placeholder='Source Layer'
                                          error={errors ? errors.source?.message : ''}
                                          register={register('source', {required: true})}
                                          defaultValue={
                                            DataGet ? DataGet.GetExplorerLayerById.source : ''
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='row '>
                                      <div className='col-md-6'>
                                        <MultiSelectTags
                                          valueTags={tags}
                                          defaultValue={DataGet}
                                          setValueTags={(value: any[]) => {
                                            let tagsSave = value.map((el) => ({
                                              id_tag: el.value,
                                              id_layer: parseInt(idLayer),
                                            }))
                                            setValue('explorer_layer_tags', tagsSave)
                                          }}
                                        />
                                      </div>
                                      <div className='col-md-6'>
                                        <FormInputAndLabel
                                          label='Source'
                                          className='form-control form-control-solid'
                                          placeholder='Source Layer'
                                          error={errors ? errors.source_layer?.message : ''}
                                          register={register('source_layer', {required: true})}
                                          defaultValue={
                                            DataGet ? DataGet.GetExplorerLayerById.source_layer : ''
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='row'>
                                      <div className='col-md-6'>
                                        <FormTextareaAndLabel
                                          label='Descrição Curta'
                                          rows={2}
                                          className='form-control form-control-solid'
                                          resize={true}
                                          register={register('descricao', {required: true})}
                                          error={errors ? errors.descricao?.message : ''}
                                          defaultValue={
                                            DataGet ? DataGet.GetExplorerLayerById.descricao : ''
                                          }
                                        />
                                      </div>
                                      <div className='col-md-6'>
                                        <FormTextareaAndLabel
                                          label='Descrição Longa'
                                          rows={2}
                                          resize={true}
                                          className='form-control form-control-solid'
                                          register={register('descricao_completa', {
                                            required: true,
                                          })}
                                          error={errors ? errors.descricao_completa?.message : ''}
                                          defaultValue={
                                            DataGet
                                              ? DataGet.GetExplorerLayerById.descricao_completa
                                              : ''
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='row mt-1'>
                                      <GetEstadoCidadeChosen
                                        defaultValueCidade={
                                          DataGet ? DataGet.GetExplorerLayerById.id_municipio : ''
                                        }
                                        defaultValueEstado={
                                          DataGet ? DataGet.GetExplorerLayerById.id_estado : ''
                                        }
                                        classNameCity={'col-6 col-xs-12 mt-1'}
                                        classNameEstado={'col-6 col-xs-12  mt-1'}
                                        SetValueEstado={(value) => {
                                          setValue('id_estado', Number(value))
                                        }}
                                        SetValueCidade={(value) => {
                                          setValue('id_municipio', Number(value))
                                        }}
                                      />
                                    </div>
                                    <div className='row py-5'>
                                      <div className='col-md-6 '>
                                        <div className='col-12 mrt-2'>
                                          <div className='col-md-6'>
                                            <label htmlFor='' className='col-form-label'>
                                              Situação
                                            </label>
                                            <select
                                              className='form-control form-control-solid'
                                              {...register('situacao', {valueAsNumber: true})}
                                              defaultValue={
                                                DataGet
                                                  ? String(DataGet.GetExplorerLayerById.situacao)
                                                  : ''
                                              }
                                            >
                                              <option value='0'>Inativo</option>
                                              <option value='1'>Ativo</option>
                                            </select>
                                            {errors ? errors.situacao?.message : ''}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='row  pb-10'>
                                      <div className='col-md-6 '>
                                        <div className='col-3 mrt-2'>
                                          <input
                                            type='checkbox'
                                            className='me-2'
                                            {...register('nacional', {
                                              required: false,
                                            })}
                                            defaultChecked={
                                              DataGet
                                                ? String(DataGet.GetExplorerLayerById.nacional) ===
                                                  '1'
                                                  ? true
                                                  : false
                                                : false
                                            }
                                          />
                                          <label htmlFor=''>Nacional</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='row'>
                                      <FormTextareaAndLabel
                                        rows={2}
                                        label='Paint'
                                        placeholder='CSS'
                                        className='form-control form-control-solid'
                                        resize={true}
                                        register={register('paint', {required: true})}
                                        defaultValue={
                                          DataGet
                                            ? JSON.stringify(DataGet.GetExplorerLayerById.paint)
                                            : ''
                                        }
                                      />
                                    </div>
                                    <div className='row mt-4'>
                                      <div className='table-responsive'>
                                        <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
                                          <thead>
                                            <tr>
                                              <th>x</th>
                                              <th>Coluna</th>
                                              <th>Coluna de Select</th>
                                              <th>Coluna de id</th>
                                              <th>Filtro Principal</th>
                                              <th>Descrição da Coluna</th>
                                              <th>Tipo de Valor</th>
                                              <th>Step(range)</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {dataColunas ? (
                                              dataColunas.map((column, index) => {
                                                let values =
                                                  DataGet.GetExplorerLayerById.explorer_tiles.filter(
                                                    (el) => {
                                                      if (el.table_column === column.column_name) {
                                                        return el
                                                      }
                                                      return false
                                                    }
                                                  )

                                                return (
                                                  <Tr
                                                    key={index}
                                                    index={index}
                                                    column={column}
                                                    tableSchema={tableSchema}
                                                    table_name={table_name}
                                                    onUpdate={onupdate}
                                                    register={register}
                                                    values={values[0]}
                                                  ></Tr>
                                                )
                                              })
                                            ) : (
                                              <tr>
                                                <td colSpan={7} className='text-center'>
                                                  <span>Selecione uma tabela.</span>
                                                </td>
                                              </tr>
                                            )}
                                            {/* <tr>
                                              <td colSpan={7} className='text-center'></td>
                                            </tr> */}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div className='w-100 pt-6 all-end'>
                                      <Link
                                        to='/backoffice/explorer-layers'
                                        type='button'
                                        className='btn btn-danger me-4'
                                      >
                                        Cancelar
                                      </Link>
                                      <button type='submit' className='btn btn-success '>
                                        <BiAddToQueue size={20} className='fs-2 me-2' />
                                        Salvar
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                              <div
                                className='tab-pane fade'
                                id='permissoes'
                                role='tabpanel'
                                aria-labelledby='permissoes-tab'
                              >
                                <TablePermissions />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
