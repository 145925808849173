/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {BiPlus} from 'react-icons/bi'
import {CreateForm} from './CreateForm'
import {Row} from './Row'
import {QueryGetProprietarios} from '../../../graphql/services/Proprietarios'
import PaginationComponent from '../../../components/UI/PaginationComponents'
import {CardTables} from '../../../components/UI/CardTables'
import LoadingTable from '../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../components/UI/CardTables/ErrorTable'

type Props = {
  setUpdateList: any
  filtro: any[]
}

export const TablePessoas: React.FC<Props> = ({setUpdateList, filtro}) => {
  const [pagiancao, setPaginacao] = useState({pagina: 0, quantidade: 10})

  const {data, refetch, loading, error} = QueryGetProprietarios({
    variables: {
      pagination: {
        pagina: pagiancao.pagina,
        quantidade: pagiancao.quantidade,
      },
      filtro: {
        fields: filtro,
      },
    },
  })

  const [showCreate, setShowCreate] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [id, setId] = useState(null)

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }

  function clickRow(idProprietario) {
    if (document.getElementById(`detalhes_${idProprietario}`).classList.contains('hide')) {
      document.getElementById(`detalhes_${idProprietario}`).classList.remove('hide')
    } else {
      document.getElementById(`detalhes_${idProprietario}`).classList.add('hide')
    }
  }

  return (
    <>
      <CardTables
        headerTitle={'Pessoas'}
        headerSubtTitle={`Até o momento ${
          data ? data.GetProprietarios.pageInfo.totalItems : 0
        } pessoas registrados.`}
        headerToolbar={
          <button
            className='btn btn-sm btn-light-primary'
            onClick={(e) => {
              e.preventDefault()
              OpenModalCreate(null)
            }}
          >
            <BiPlus className='fs-3'></BiPlus>
            Nova Consulta
          </button>
        }
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetProprietarios.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({pagina: pagina, quantidade: quantidade})
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-50px'>Infos</th>
                <th className='min-w-150px'>Nome</th>
                {/* <th className='min-w-150px'>E-mail</th> */}
                <th className='min-w-100px text-center'>Ações</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetProprietarios.result.length > 0 ? (
                  data.GetProprietarios.result.map((response, item) => {
                    return (
                      <Row
                        key={item}
                        response={response}
                        idImovel={1}
                        clickRow={clickRow}
                        updateList={refetch}
                      ></Row>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
      {showCreate ? (
        <CreateForm origin='Pessoas' closeModal={CloseModalCreate} show={showCreate} />
      ) : (
        <></>
      )}
    </>
  )
}
