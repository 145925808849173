import { BiChevronLeft, BiShareAlt } from "react-icons/bi";
import { Card } from "./Cards"
import { TabsNavigation } from "./TabsNavigation"
import { Button } from "../../../../components/UI/Buttons.tsx/Button";
import { ChartWidgetColors1 } from "../Charts/ChartWidgetColors1";
import { MixedWidgetColors1 } from "../Mixeds/MixedWidgetColors1";
import { useState } from "react";
import { FaHeart, FaMapMarkedAlt, FaRegHeart } from "react-icons/fa";
import { useMarket } from "../../context/MarketContext";

export function Overview({ dataMarketResponse, handleMarketChange, showMap, toggleShowMap }) {
  const [isFavorite, setIsFavorite] = useState(false);
  const { currentMarket, goBack, goToSubmarket } = useMarket()

  const [key, setKey] = useState(0);
  const resetComponent = () => {
    // Incrementa a key para re-renderizar o componente
    setKey(prevKey => prevKey + 1);
  };

  const handleClick = () => {
    setIsFavorite(!isFavorite);
  };
  return (
    <div key={key} className="row">
      <div className="w-100 bg-white flex-column px-12 pt-4 ">
        <div className="all-center-between">
          <Button buttonStyle="button-gray-blue" className="text-gray-700 mb-4" onClick={goBack}>
            <BiChevronLeft size={18} className="me-2" />
            Voltar
          </Button>

          {!showMap && (
            <Button onClick={toggleShowMap} title='Ver mercado no mapa' buttonStyle='button-info' className='all-center sm-d-block d-block' size={'lg'} >
              <FaMapMarkedAlt className='me-1' color={'#fff'} size={20} /> <span className='p-0'>Ver no mapa</span>
            </Button>
          )}

        </div>




        <TabsNavigation dataMarketResponse={dataMarketResponse}>
          <div>
            <button title="Compartilhar" className="btn" onClick={handleClick}>
              <BiShareAlt size={18} color="#69C05B" />
            </button>

            <button title="Favoritar" className="btn" onClick={handleClick}>
              {dataMarketResponse.isFavorite ? <FaHeart size={18} /> : <FaRegHeart size={18} color="#69C05B" />}
            </button>
          </div>
        </TabsNavigation>

      </div>

      <div className="tab-content px-12 py-4" id="tabContent">
        <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
          <div className="row pt-6" style={{ overflow: 'auto', height: '80vh' }}>
            <div className="col-sm-12 col-lg-6 col-xl-6">
              <div className="card">
                <div className="card-header py-6">
                  <span className="fs-5 text-gray-500 pb-4">Overview: <span className="text-gray-800">{dataMarketResponse.nome}</span></span>
                  <span className="fs-6 pb-4">{dataMarketResponse.descricao}</span>
                  <div className=" w-100 all-end">
                    <div className='rating'>
                      <div className='rating-label me-2 checked'>
                        <i className='bi bi-star-fill fs-5'></i>
                      </div>
                      <div className='rating-label me-2 checked'>
                        <i className='bi bi-star-fill fs-5'></i>
                      </div>
                      <div className='rating-label me-2 checked'>
                        <i className='bi bi-star-fill fs-5'></i>
                      </div>
                      <div className='rating-label me-2 checked'>
                        <i className='bi bi-star-fill fs-5'></i>
                      </div>
                      <div className='rating-label me-2 '>
                        <i className='bi bi-star-fill fs-5'></i>
                      </div>
                    </div>
                    <span className="fs-2 me-2">{dataMarketResponse.Nota}</span>
                  </div>
                </div>
                <MixedWidgetColors1 baseColor="secondary" title="Desempenho do mercado" className="card-xl-stretch" chartHeight="200px" chartColor='primary' />
              </div>
            </div>
            <div className="col-sm-12 col-lg-6 col-xl-6">
              <div className="row">
                <div className="col-6">
                  <ChartWidgetColors1
                    title='Percentual Avarange'
                    baseColor="gray-light"
                    className='card-xl-stretch'
                    chartColor='primary'
                    chartHeight='200px'
                  >
                    <Button className="w-100" buttonStyle="button-primary">Ver mais</Button>
                  </ChartWidgetColors1>
                </div>
                <div className="col-6">
                  <ChartWidgetColors1
                    title='Percentual Avarange'
                    baseColor="gray-light"
                    className='card-xl-stretch mb-xl-8'
                    chartColor='secondary'
                    chartHeight='200px'
                  >
                    <Button className="w-100" buttonStyle="button-secondary">Ver mais</Button>
                  </ChartWidgetColors1>
                </div>
                <div className="col-6">
                  <ChartWidgetColors1
                    title='Percentual Avarange'
                    baseColor="gray-light"
                    className='card-xl-stretch mb-xl-8'
                    chartColor='danger'
                    chartHeight='200px'
                  >
                    <Button className="w-100" buttonStyle="button-danger">Ver mais</Button>
                  </ChartWidgetColors1>
                </div>
                <div className="col-6">
                  <ChartWidgetColors1
                    title='Percentual Avarange'
                    baseColor="gray-light"
                    className='card-xl-stretch mb-xl-8'
                    chartColor='info'
                    chartHeight='200px'
                  >
                    <Button className="w-100" buttonStyle="button-info">Ver mais</Button>
                  </ChartWidgetColors1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {dataMarketResponse && dataMarketResponse.submarket && (
          <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
            <div className="row py-6">
              {dataMarketResponse.submarket.map((data) => (
                <div key={data.id} className="col-4">
                  <Card
                    title={data.nome}
                    image={data.image}
                    description={data.descricao}
                    onClick={() => {
                      resetComponent()
                      goToSubmarket(data)
                      handleMarketChange(data.id, 'submarket', data)
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}