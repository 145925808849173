import { gql } from '@apollo/client'

export const GET_EMPRESAS = gql`
query GetEmpresas($situacao: Float!, $filter: filter, $pagination: Pagination) {
  GetEmpresas(situacao: $situacao, filter: $filter, pagination: $pagination) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      id
      uuid
      nome
      endereco
      numero
      complemento
      bairro
      cnpj
      cep
      estado
      id_estado
      id_cidade
      telefone
      email
      nome_fantasia
      created_at
      updated_at
      deleted_at
      user_logo_pic
      cor_fonte
      cor_background
      situacao
      empresa_produto {
        produto {
          id
          nome
        }
        id_produto
        data_fim
        data_inicio
      }
    }
  }
}
`

export const GET_EMPRESA_BY_ID = gql`
  query GetEmpresaById($getEmpresaByIdId: Float!) {
    GetEmpresaById(id: $getEmpresaByIdId) {
      id
      uuid
      nome
      endereco
      numero
      complemento
      bairro
      cnpj
      cep
      estado
      id_estado
      id_cidade
      telefone
      email
      situacao
      nome_fantasia
      created_at
      updated_at
      deleted_at
      user_logo_pic
      cor_fonte
      cor_background
    }
  }
`

export const GET_EMPRESA_USUARIO_BY_ID = gql`
  query GetEmpresaUsuarioById($getEmpresaUsuarioByIdId: Float!) {
    GetEmpresaUsuarioById(id: $getEmpresaUsuarioByIdId) {
      id
      uuid
      id_user
      id_empresa
      empresa {
        id
        uuid
        nome
        endereco
        numero
        complemento
        bairro
        cnpj
        cep
        estado
        id_estado
        id_cidade
        situacao
        telefone
        email
        nome_fantasia
        created_at
        updated_at
        deleted_at
        user_logo_pic
        cor_fonte
        cor_background
      }
      users {
        id
        name
        email
        uuid
        api_token
        profile_id
        telefone
        cpf
        cnpj
        created_at
        user_pic
        tipo_pessoa
        cor_fonte
        cor_background
        remember_token
        id_area_atuacao
        token_troca_senha
        first_login
        edicao
        exclusao
        visualizacao
      }
      created_at
      updated_at
      deleted_at
      tipo_usuario
      visualizacao
      exclusao
      edicao
    }
  }
`

export const GET_PRODUTOS_BY_EMPRESA_ID = gql`
  query GetEmpresaProdutoById($empresaId: Float!) {
    GetEmpresaProdutoById(empresa_id: $empresaId) {
      id
      produto {
        nome
        descricao
        id
      }
      deleted_at
      data_inicio
      data_fim
    }
  }
`

export const GET_EMPRESA_BY_ID_FUNCIONARIOS = gql`
  query GetEmpresaByIdFuncionarios(
    $getEmpresaByIdFuncionariosId: Float!
    $filtro: filter
    $pagination: Pagination
  ) {
    GetEmpresaByIdFuncionarios(
      id: $getEmpresaByIdFuncionariosId
      filtro: $filtro
      pagination: $pagination
    ) {
      empresa {
        id
        nome
        telefone
        email
      }
      funcionarios {
        id
        name
        email
        cpf
        telefone
        visualizacao
        exclusao
        edicao
        empresa_usuario {
          id
          tipo_usuario
        }
      }
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const GET_RELATORIO_ACESSO_USUARIO_EMPRESA = gql`
  query GetRelatorioAcessoUsuarioEmpresa(
    $pagination: Pagination
    $dataFim: String
    $dataInicio: String
  ) {
    GetRelatorioAcessoUsuarioEmpresa(
      pagination: $pagination
      data_fim: $dataFim
      data_inicio: $dataInicio
    ) {
      id
      hash_id
      nome
      endereco
      latitude
      longitude
      data_cadastro
      name
      email
      mes
      dia
      total
    }
  }
`
