import {Text, View, StyleSheet, Image} from '@react-pdf/renderer'
import secureLocalStorage from 'react-secure-storage'
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#dfdfdf',
    borderBottomWidth: 2,
    alignItems: 'center',
    height: 124,
    fontSize: 12,
    marginBottom: 15,
  },
  logo: {
    width: 200,
    height: 'auto',
  },
  sobre: {
    marginTop: 10,
    marginRight: 0,
    textAlign: 'right',
    paddingBottom: 2,
    fontSize: 8,
    width: 550,
  },
  nomeEmpresa: {
    flexDirection: 'column',
    textAlign: 'right',
    paddingBottom: 2,
    fontSize: 13,
  },
  space: {
    paddingBottom: 2,
  },
  h1: {
    fontSize: 18,
  },
  h2: {
    fontSize: 16,
  },
  h3: {
    fontSize: 14,
  },
  h4: {
    fontSize: 12,
  },
  h5: {
    fontSize: 10,
  },
  h6: {
    fontSize: 9,
  },
  p: {
    fontSize: 9,
  },
})

let logo = `https://app.urbit.com.br/media/logos/logo.png`
const logoEmpresa:any = secureLocalStorage.getItem('user_logo_empresa')

if (logoEmpresa){
  logo = logoEmpresa
}
const empresaData: any = secureLocalStorage.getItem('empresa') || null

let dadosEmpresa = null
if (empresaData) {
  dadosEmpresa = JSON.parse(empresaData)
}

export const Header = () => {
    return(    
       
      <View style={styles.row} fixed>
      <Image src={{ uri: logo, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} style={styles.logo} />
      {dadosEmpresa ? 
       <View fixed style={styles.sobre}>
        {dadosEmpresa.nome ? <Text style={styles.h2}>{dadosEmpresa.nome }</Text> : ''}
        {dadosEmpresa.nome_fantasia ? <Text style={styles.h6}> {dadosEmpresa.nome_fantasia }</Text> : ''}
        {dadosEmpresa.cnpj ? <Text style={styles.h6}>CNPJ: {dadosEmpresa.cnpj }</Text> : ''}
        {dadosEmpresa.endereco ? <Text style={styles.h6}>{dadosEmpresa.endereco }</Text> : ''}
        {dadosEmpresa.telefone ? <Text style={styles.h6}>Whatsapp: {dadosEmpresa.telefone }</Text> : ''}
        {dadosEmpresa.email ? <Text style={styles.h6}>Email: {dadosEmpresa.email }</Text> : ''}
      </View>
    :
      <View fixed style={styles.sobre}>
        <Text style={styles.h2}>Urbit Serviços de Informática Ltda.</Text>
        <Text style={styles.h6}>CNPJ: 31.687.326/0001-62</Text>
        <Text style={styles.h6}>
          R. Dr. Antônio Bento, 746 - Santo Amaro, São Paulo - SP, 04750-001
        </Text>
        <Text style={styles.h6}>Whatsapp: (11) 94813-4631</Text>
        <Text style={styles.h6}>Email: contato@urbit.com.br</Text>
      </View>
}


    </View>
        
    )
};