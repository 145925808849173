import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {DataContext} from '../../../context/DataContext'
import {FilterContext} from '../../../context/FilterContext'
import {Dicionario} from '../../../../../utils/Dicionario'
import {BarChart} from '../../Charts/BarChart'
import {formatValores} from '../../Common/common'
import {CacheResult} from '../../Types'

type Props = {
  label?: string
}

export default function FaixaTicket({label}: Props) {
  const {data, segmentacao, vsoIdeal, setCacheResult, cacheResult} = useContext(DataContext)
  const {filter} = useContext(FilterContext)
  const [selectedItem, setSelectedItem] = useState(null)
  let {chartDataVSOMetragem, chartDataVSOMetragemTable} = useMemo(() => {
    let totalUnidadesTipologias = Array(7).fill(0, 0, 7)
    let stockUnidadesTipologias = Array(7).fill(0, 0, 7)
    let totalUnidades = Array(7).fill(0, 0, 7)
    let chartDataVSOMetragemTable = []
    let vsoIdealTipologia = Array(7).fill(0, 0, 7)
    let chartDataVSOMetragem = [
      {
        name: 'VSO',
        data: [],
      },
      {
        name: 'Estoque',
        data: [],
      },
    ]
    if (data && filter.segmentacaoPreco !== null) {
      // eslint-disable-next-line array-callback-return
      data.map((item) => {
        // eslint-disable-next-line array-callback-return
        item.tipologias.map((itemTipologias) => {
          if (itemTipologias.computed && itemTipologias.original_price > 0) {
            segmentacao.preco[filter.segmentacaoPreco].forEach((faixas, i) => {
              if (i === 6) {
                if (itemTipologias.original_price >= faixas[0]) {
                  totalUnidadesTipologias[i] =
                    totalUnidadesTipologias[i] + Number(itemTipologias.total_units)
                  stockUnidadesTipologias[i] =
                    stockUnidadesTipologias[i] + Number(itemTipologias.stock)
                  totalUnidades[i] = totalUnidades[i] + Number(itemTipologias.total_units)
                  vsoIdealTipologia[i] += Math.floor(
                    (vsoIdeal[item.fase_projeto_ideal].vso / 100) *
                      Number(itemTipologias.total_units)
                  )
                }
              } else if (
                itemTipologias.original_price >= faixas[0] &&
                itemTipologias.original_price < faixas[1]
              ) {
                totalUnidadesTipologias[i] =
                  totalUnidadesTipologias[i] + Number(itemTipologias.total_units)
                stockUnidadesTipologias[i] =
                  stockUnidadesTipologias[i] + Number(itemTipologias.stock)
                totalUnidades[i] = totalUnidades[i] + Number(itemTipologias.total_units)
                vsoIdealTipologia[i] += Math.floor(
                  (vsoIdeal[item.fase_projeto_ideal].vso / 100) * Number(itemTipologias.total_units)
                )
              }
            })
          }
        })
      })

      // eslint-disable-next-line array-callback-return
      segmentacao.preco[filter.segmentacaoPreco].map((data, index) => {
        const id = `preco_${index}`
        let categoria = ''
        if (data[1] == null) {
          categoria = 'acima de ' + formatValores(data[0])
        } else if (data[0] === 0) {
          categoria = 'abaixo de ' + formatValores(data[1])
        } else {
          categoria = 'de ' + formatValores(data[0]) + ' até ' + formatValores(data[1])
        }

        if (totalUnidadesTipologias[index] > 0) {
          let vso = Math.round(
            ((totalUnidadesTipologias[index] - stockUnidadesTipologias[index]) /
              totalUnidades[index]) *
              100
          )

          let vsoIdeal = Math.round((vsoIdealTipologia[index] / totalUnidades[index]) * 100)
          // let categoria = `${data[1] == null ? 'acima de ' : ''}${data[0] === 0 ? `abaixo de ${data[0]}` : ${data[0]}}${ data[1] == null ? '' : '-' + data[1]}`

          chartDataVSOMetragem[0].data.push({
            x: categoria,
            y: vso,
            goals: [
              {
                name: 'VSO Ideal',
                value: vsoIdeal,
                strokeHeight: 5,
                strokeColor: '#6e2a2a',
              },
            ],
            id: id,
          })

          chartDataVSOMetragem[1].data.push({
            x: categoria,
            y: Math.round((stockUnidadesTipologias[index] / totalUnidades[index]) * 100),
            goals: [
              {
                name: 'VSO Ideal',
                value: vsoIdeal,
                strokeHeight: 5,
                strokeColor: '#6e2a2a',
              },
            ],
            total_unidades: totalUnidadesTipologias[index],
            id: id,
          })

          chartDataVSOMetragemTable.push({
            preco: categoria,
            total_unidades: totalUnidadesTipologias[index],
            estoque: stockUnidadesTipologias[index],
            vendidas: totalUnidadesTipologias[index] - stockUnidadesTipologias[index],
            id: id,
          })
        } else {
          chartDataVSOMetragem[0].data.push({
            x: categoria,
            y: 0,
            // goals: [
            //   {
            //     name: 'VSO Ideal',
            //     value: 0,
            //     strokeHeight: 5,
            //     strokeColor: '#6e2a2a',
            //   },
            // ],
            id: id,
          })

          chartDataVSOMetragem[1].data.push({
            x: categoria,
            y: 0,
            // goals: [
            //   {
            //     name: 'VSO Ideal',
            //     value: 0,
            //     strokeHeight: 5,
            //     strokeColor: '#6e2a2a',
            //   },
            // ],
            total_unidades: totalUnidadesTipologias[index],
            id: id,
          })

          chartDataVSOMetragemTable.push({
            preco: categoria,
            total_unidades: totalUnidadesTipologias[index],
            estoque: stockUnidadesTipologias[index],
            vendidas: totalUnidadesTipologias[index] - stockUnidadesTipologias[index],
            id: id,
          })
        }
      })
    }
    return {chartDataVSOMetragem, chartDataVSOMetragemTable}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filter.segmentacaoPreco, segmentacao.preco, vsoIdeal])

  useEffect(() => {
    cacheResult.performanceResumoMercado.faixaTicket = {
      chartData: chartDataVSOMetragem,
      tableData: chartDataVSOMetragemTable,
    }
    setCacheResult(cacheResult)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDataVSOMetragem])

  if (!data) {
    return <>Carregando..</>
  }
  if (filter.segmentacaoPreco === null || !filter.segmentacaoPreco === null) {
    return <>Carregando..</>
  }

  return (
    <BarChart
      className={'primary'}
      title={'Total de unidades por faixa de Preço'}
      subtitle={''}
      data={chartDataVSOMetragem}
      onChange={(id: number) => {
        setSelectedItem(id)
      }}
    >
      <table className='table  table-row-dashed table-row-gray-300 table-hover'>
        <thead>
          <tr className='text-dark fw-bold'>
            {chartDataVSOMetragemTable && chartDataVSOMetragemTable.length > 0
              ? Object.entries(chartDataVSOMetragemTable[0]).map((item, index) => {
                  return item[0] !== 'id' ? (
                    <th key={index} className='text-center'>
                      {Dicionario.translate(item[0])}
                    </th>
                  ) : null
                })
              : null}
          </tr>
        </thead>
        <tbody>
          {chartDataVSOMetragemTable
            ? chartDataVSOMetragemTable.map((item, index) => {
                return (
                  <tr key={index} className={item.id === selectedItem ? 'select-data-table' : ''}>
                    <td className='text-center'>{item.preco}</td>
                    <td className='text-center'>{item.total_unidades}</td>
                    <td className='text-center'>{item.estoque}</td>
                    <td className='text-center'>{item.vendidas}</td>
                  </tr>
                )
              })
            : null}
        </tbody>
      </table>
    </BarChart>
  )
}
