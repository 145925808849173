import React, {useEffect} from 'react'
import {RangeSlider} from '../../../../../../components/UI'
import {ColorPicker} from '../../../../../../components/UI/ColorPicker'
import {Modal} from 'react-bootstrap'
import {FaFilter} from 'react-icons/fa'
import {GetAcessoUsuarioProduto} from '../../../../../../utils/PermissoesAcesso'
import {MensagemAlertAcesso} from '../../../../../../components/UI/MensagemAlertAcesso'
import {SelectIncorporadoras} from './SelectIncorporadoras'
import {SelectTipoEmpreendimento} from './SelectTipoEmpreendimento'
import {SelectFinalidadeLancamento} from './SelectFinalidadeLancamento'

type Props = {
  show: boolean
  onChangeFiltro: any
  onAdicionarCamada: any
  onHandleClose: any
}

export const FiltrosImovelLancametoModal: React.FC<Props> = ({
  show,
  onChangeFiltro,
  onAdicionarCamada,
  onHandleClose,
}) => {
  useEffect(() => {
    onChangeFiltro('finalidade', '', 'update', 'select')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const permissao = GetAcessoUsuarioProduto(31)
  return (
    <Modal centered animation show={show} onHide={() => onHandleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Adicionar camada de Lançamentos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row px-10'>
          <div className='row g-1 align-items-center mt-5'>
            <div className='col-4'>
              <label className='col-form-label'>Nome:</label>
            </div>
            <div className='col-8'>
              <input
                type='text'
                className='form-control'
                name='nome_camada'
                id='nome_camada'
                onChange={(e) => {
                  onChangeFiltro('nomeCamada', e.target.value, 'update', 'text', '#ff0000')
                }}
                placeholder='Digite o nome da camada'
              />
            </div>
          </div>

          <div className='row g-1 mt-5 align-items-center'>
            <div className='col-4'>Tipo</div>
            <div className='col-8'>
              <SelectTipoEmpreendimento
                idTipo={0}
                onChange={(e) => {
                  onChangeFiltro('tipo', e, 'update', 'select')
                }}
              ></SelectTipoEmpreendimento>
            </div>
          </div>
          <div className='row g-1 mt-5 align-items-center'>
            <div className='col-4'>Finalidade</div>
            <div className='col-8'>
              <SelectFinalidadeLancamento
                idTipo={0}
                onChange={(e) => onChangeFiltro('finalidade', e, 'update', 'select')}
              ></SelectFinalidadeLancamento>
            </div>
          </div>

          <div className='row g-1 mt-5 align-items-center'>
            <div className='col-4'>Incorporadora</div>
            <div className='col-8'>
              <SelectIncorporadoras
                onChange={(e) => onChangeFiltro('incorporador', e, 'update', 'select')}
              ></SelectIncorporadoras>
            </div>
          </div>

          <div className='row g-1 pt-5'>
            <div className='col-4'>
              <label className='col-form-label'>Ano Lançamento</label>
            </div>
            <div className='col-8 p-0 '>
              <RangeSlider
                defaultValue={[2018, 2024]}
                min={2018}
                max={2024}
                step={1}
                formatTip={(value) => `${value} `}
                onChange={(e) => {
                  onChangeFiltro('ano_lancamento', e, 'update', 'range')
                }}
                showHideImput={false}
              />
            </div>
          </div>
          <div className='row g-1 align-items-center pt-5 pb-5'>
            <div className='col-4'>
              <label className='col-form-label'>Cor:</label>
            </div>
            <div className='col-8'>
              <ColorPicker
                hex={'#080606'}
                hsl={''}
                onChange={(e) => onChangeFiltro('cor', e, 'update', 'text', e)}
              ></ColorPicker>
              {/* <RangeSlider
                  value={}
                  onChange={(changeEvent) => {
                    let val = changeEvent.target.value
                    setOpacity(parseInt(val))
                    onChangeOpacity()
                  }}
                  min={0}
                  max={100}
                  step={5}
                /> */}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='row align-items-end pt-5'>
          {permissao ? (
            <button className='btn btn-sm btn-primary fw-bold' onClick={onAdicionarCamada}>
              <FaFilter></FaFilter>
              Adicionar Camada
            </button>
          ) : (
            <MensagemAlertAcesso mensagem='Somente para assinantes do Produto - Plano Órulo - Lançamentos.'></MensagemAlertAcesso>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}
