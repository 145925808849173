import QRCode from 'react-qr-code'
import {ValidaPermissaoAcesso} from '../../../../utils/PermissoesAcesso'
import {MapboxLayer} from '../../../../components/UI/Mapbox/MapboxLayer'
import {useRef} from 'react'

type Props1Item = {
  dados: any
}

export const HeaderDetalhes: React.FC<Props1Item> = ({dados}) => {
  const mapRef = useRef()

  if (!dados) {
    return <>Carregando...</>
  }

  // const layerConfig = {
  //   lotes: {
  //     tiles: ['https://tile.urbit.com.br/tile/view_explorer_lotes_acessoria/{z}/{x}/{y}.pbf?s=sao_paulo'],
  //     source: 'view_explorer_lotes_acessoria',
  //     paint: {'fill-color': '#d0d0d0','fill-outline-color': '#000', 'fill-opacity': 0.4},
  //     layout:{
  //       'text-field': ['get', 'endereco'], // Nome do atributo com o texto
  //       'text-size': 12, // Tamanho do texto
  //       'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
  //     },
  //     paintText:{
  //       'text-color': '#000000', // Cor do texto
  //     }
  //   },
  // }
  
  let dadosProdutos = ValidaPermissaoAcesso(11)
  return (
    <>
      <div className='d-flex'>
        <div className=' d-flex p-0   pt-10 pb-5   w-100'>
          <div className=' d-flex flex-column flex-column-fluid '>
            <h1 className='txt_verde'>{dadosProdutos ? 'Ficha completa' : 'Ficha Grátis'}</h1>
            <h3>Terreno Nº {dados.GetImovel.id} </h3>

            <div className='date'>
              <strong>Data solicitação</strong>: 11/01/2023
            </div>
            <div className='date'>
              <strong>Endereço</strong>:{dados.GetImovel.endereco},{dados.GetImovel.numero}
            </div>
            {dados.GetImovel.bairro ? (
              <div className='date'>
                <strong>Bairro</strong>: {dados.GetImovel.bairro}
              </div>
            ) : (
              ''
            )}
            <div className='date'>
              <strong>Cidade</strong>: {dados.GetImovel.cidade}
            </div>
          </div>
          <div className=' d-flex flex-column flex-column-end w-130px '>
            <div style={{float: 'right'}}>
              <div style={{background: 'white'}}>
                <QRCode
                  size={256}
                  style={{height: 'auto', width: '150px'}}
                  value={`${process.env.REACT_APP_HOST}/fichas/${dados.GetImovel.uuid}`}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <p style={{fontSize: '0.9em'}}>Escaneie para acessar</p>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <MapboxLayer
          latLng={[dados.GetImovel.latitude, dados.GetImovel.longitude]}
          inputRef={mapRef}
          onLoad={() => {}}
          options={{
            tiles: [
              'https://tile.urbit.com.br/tile/view_explorer_lotes_acessoria/{z}/{x}/{y}.pbf?s=sao_paulo',
            ],
            source: 'view_explorer_lotes_acessoria',
            paint: {'fill-color': '#d0d0d0', 'fill-outline-color': '#000', 'fill-opacity': 0.4},
            layout: {
              'text-field': ['get', 'endereco'], // Nome do atributo com o texto
              'text-size': 12, // Tamanho do texto
              'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
            },
            paintText: {
              'text-color': '#000000', // Cor do texto
            },
          }}
          zoom={20}
          style={{width: 'auto', height: 350}}
        ></MapboxLayer>
      </div>
      <div className='d-flex'>
        <div className='shadow-clean col-md-12 py-5'>
          <div>
            <strong style={{color: '#ff0000'}}>AVISO:</strong>
          </div>
          <div className='notice'>
            As informações contidas neste relatório foram elaboradas com base em informações
            públicas, disponibilizadas nos respectivos órgãos públicos. A obtenção desta ficha não
            substitiu as diligências normalmente necessárias para fins de compra e venda ou
            exploração comercial do imóvel. Considere que podem existir modificações nos imóveis,
            objeto da pesquisa, e que referidas modificações podem estar pendentes de regularização
            perante os órgãos públicos. A Urbit não assume qualquer responsabilidade ou outorga
            qualquer garantia de adequação ao fim pretendido pelo Cliente.
          </div>
        </div>
      </div>
    </>
  )
}
