import {Text, View, StyleSheet } from '@react-pdf/renderer'
// const borderColor = '#838383'

const styles = StyleSheet.create({
  table: {
    width: '100%',
    fontSize:11,
    fontWeight:'light'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
  },
  rowErro: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
    textAlign:'center'
  },
  title: {
    marginTop:25,
    marginBottom:15,
    fontSize: 16,
    fontWeight: 'bold',
    borderBottomWidth:2,
    borderBottomColor:"#2B8E57"
  },
  header: {
    borderTop: 'none',
    backgroundColor:"#dbdbdb",
    paddingHorizontal:10
  },
  bold: {
    fontWeight: 'black'
  },
  rowDefault: {
    width: '25%',
  },
  rowCenter: {
    width: '20%',
    textAlign:"center"
  },
  decimal: {
    width: '20%',
    textAlign:'center'
  },
  currency: {
    width: '20%',
    textAlign:'center'
  }
})
export const Corretores = ({corretores}) => {
if (corretores) {

  return (
    <View style={styles.table}>
      <Text style={styles.title}>Corretores</Text>
      <View style={[styles.row, styles.bold, styles.header]}>
        <Text style={styles.rowDefault}>Nome</Text>
        <Text style={styles.rowDefault}>E-mail</Text>
        <Text style={styles.rowDefault}>Telefone</Text>
        <Text style={styles.rowDefault}>Empresa</Text>
      </View>
      {corretores.GetEmpreendimentoCorretores.length === 0 ?    <Text style={styles.rowErro}>Nenhum registro encontrado</Text> : '' }
      { corretores.GetEmpreendimentoCorretores.map((row, i) => (
        <View key={i} style={styles.row} wrap={false}>
          <Text style={styles.rowDefault}>{row.nome}</Text>
          <Text style={styles.rowDefault}>{row.email}</Text>
          <Text style={styles.rowDefault}>{row.telefone}</Text>
          <Text style={styles.rowDefault}>{row.empresa}</Text>
        </View>
      ))}
    </View>
  )
  }
}