import { Modal } from 'react-bootstrap'

import Swal from 'sweetalert2'
import {
  MutationPutImovelObservacoes,
  QueryGetImovelObservacoesByID,
} from '../../../../../../graphql/services/Observacoes'
import { ErrorMessageComponent } from '../../../../../../backoffice/components/UI/ErrorMessage'
import { Loader } from '../../../../../../components/UI/Loader/Loader'
import { FormTextareaAndLabel } from '../../../../../../components/UI/Inputs/FormTextareaAndLabel'
import { FormInputAndLabel } from '../../../../../../components/UI/Inputs/FormInputAndLabel'
import { MutationSetLogAcessoRecurso } from '../../../../../../graphql/services/LogAcesso'

interface Props {
  id: number
  show: boolean
  closeModal: any
  idTerreno: string
}

export function EditForm({ id, show, closeModal, idTerreno }: Props) {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  let { data, loading: LoadingGet } = QueryGetImovelObservacoesByID({
    variables: { imovelObservacao: { id: id } },
  })

  const {
    FormImovelObservacoes,
    loading,
    handleSubmit,
    register,
    errors,
  } = MutationPutImovelObservacoes()

  const onSubmit = async (data) => {
    FormImovelObservacoes(data).then((result) => {
      FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'observacoes', action: 'update', url: '/meus-imoveis/details/' + idTerreno + '#observacoes' });
      Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })
  }

  // Tratativa de loading
  if (loading || LoadingGet) {
    return <Loader text={'caregando...'}></Loader>
  }

  return (
    <>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar observação: </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
          <input
              type='number'
              hidden
              className='form-control'
              defaultValue={id}
              {...register('id', { required: true, valueAsNumber: true })}
            />
            <input
              type='number'
              hidden
              className='form-control'
              defaultValue={parseInt(idTerreno)}
              {...register('id_terreno', { required: true, valueAsNumber: true })}
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputAndLabel
                    label='Titulo'
                    className='form-control form-control-solid'
                    placeholder='Titulo'
                    register={register("titulo")}
                    defaultValue={data.GetImovelObservacao.titulo}

                    error={errors ? errors.titulo?.message : ''}
                  />
                  {errors.titulo && <ErrorMessageComponent message={errors.titulo?.message} />}
                </div>
              </div>

              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormTextareaAndLabel
                    label='Descrição'
                    rows={6}
                    className='form-control form-control-solid'
                    placeholder='Descreva sua observação...'
                    defaultValue={data.GetImovelObservacao.descricao}
                    register={register("descricao")}
                    error={errors ? errors.descricao?.message : ''}
                  />
                </div>
              </div>

              <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={() => closeModal()}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
