import {useContext, useEffect, useState} from 'react'
import {ErrorMessageComponent} from '../../../../backoffice/components/UI/ErrorMessage'
import {AvmContext} from './context/AvmContext'
import {Form} from 'react-bootstrap'
import { GetAcessoUsuarioProduto } from '../../../../utils/PermissoesAcesso'
import { MensagemAlertAcesso } from '../../MensagemAlertAcesso'

export function AvmPasso1({refFormAvm, errors, setErrors}) {
  const {setFormAvm, formAvm} = useContext(AvmContext)
  const [showAnoConstrucao, SetShowAnoConstrucao] = useState(false)

  useEffect(() => {
    SetShowAnoConstrucao(formAvm.lancamento === 1 ? false : true)
  }, [formAvm])

  async function FormAVMAPI(event) {
    event.preventDefault()
    // setShowLoader(true)
    // setResultAVM(null)
    return false
  }

  function hideAnoConstrucao(showHide) {
    SetShowAnoConstrucao(showHide)
  }
  function handleChange(event) {
    formAvm.ano_construcao = event.target.value.replace(/([^\d\s/-])/g, '')
    setFormAvm({...formAvm})
  }

  function handleChangeArea(event) {
    const area = event.target.value
    if (area <= 2000) {
      formAvm.area = area.replace(/([^\d\s/-])/g, '')
      setFormAvm({...formAvm})
    } else {
      formAvm.area = 2000
      setFormAvm({...formAvm})
    }
  }

  function handleChangeQuartos(event) {
    const area = event.target.value
    if (area <= 20) {
      formAvm.quartos = area.replace(/([^\d\s/-])/g, '')
      setFormAvm({...formAvm})
    } else {
      formAvm.quartos = 20
      setFormAvm({...formAvm})
    }
  }

  function handleChangeSuites(event) {
    const area = event.target.value
    if (area <= 20) {
      formAvm.suites = area.replace(/([^\d\s/-])/g, '')
      setFormAvm({...formAvm})
    } else {
      formAvm.suites = 20
      setFormAvm({...formAvm})
    }
  }
  function handleChangeBanheiros(event) {
    const area = event.target.value
    if (area <= 20) {
      formAvm.banheiros = area.replace(/([^\d\s/-])/g, '')
      setFormAvm({...formAvm})
    } else {
      formAvm.banheiros = 20
      setFormAvm({...formAvm})
    }
  }
  function handleChangeVagas(event) {
    const area = event.target.value
    if (area <= 20) {
      formAvm.vagas = area.replace(/([^\d\s/-])/g, '')
      setFormAvm({...formAvm})
    } else {
      formAvm.vagas = 20
      setFormAvm({...formAvm})
    }
  }
  function handleChangeCondominio(event) {
    const area = event.target.value
    if (area <= 20000) {
      formAvm.condominio = area.replace(/([^\d\s/-])/g, '')
      setFormAvm({...formAvm})
    } else {
      formAvm.condominio = 20000
      setFormAvm({...formAvm})
    }
  }
  function handleChangeLancamento(event) {
    const lancamento = event.target.value
    formAvm.lancamento = Number(lancamento)
    setFormAvm({...formAvm})
  }
  const permissao = GetAcessoUsuarioProduto(21)
  return (
      !permissao ? (
        <>
      <div className='alert alert-warning'>
        <h3 className='pt-2 ps-2 pb-5'>
          Você não tem este produto contratado, por favor entre em contato no email:{' '}
          <a href='mailto:contato@urbit.com.br?subject=Sobre Avaliação de Imóveis&body=Gostaria de informações sobre o produto de avaliação de imóveis.'>
            contato@urbit.com.br
          </a>
        </h3>
        <MensagemAlertAcesso mensagem='Somente para assinantes do Produto - Urbit Pricing.' />
      </div>
      </>
    ) : (<>
        <form onSubmit={FormAVMAPI} id='frm-avm' ref={refFormAvm}>
          <div className='row mb-6'>
            <div className='col-lg-3'>
              <label className='fs-6'>Tipo:</label>
              <select
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                value={formAvm.tipo === null ? '' : formAvm.tipo}
                name='tipo'
                id='tipo'
                onChange={(e) => {
                  formAvm.tipo = e.target.value
                  setFormAvm({...formAvm})
                }}
              >
                <option value=''>Selecione uma opção</option>
                <option value='apartamento'>Apartamento</option>
                {/* <option value='casa'>Casa</option> */}
              </select>
              {errors.tipo && <ErrorMessageComponent message={errors.tipo} />}
            </div>
            <div className='col-lg-3'>
              <label className='fs-6'>Subtipo:</label>
              <select
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                value={formAvm.subtipo === null ? '' : formAvm.subtipo}
                name='subtipo'
                id='subtipo'
                onChange={(e) => {
                  formAvm.subtipo = e.target.value
                  setFormAvm({...formAvm})
                }}
              >
                <option value=''>Selecione uma opção</option>
                <option value='padrao'>Padrão</option>
                <option value='Studio / Kitnet'>Studio / Kitnet</option>
                <option value='Garden'>Garden</option>
                <option value='Loft'>Loft</option>
                <option value='Duplex'>Duplex</option>
                <option value='Triplex'>Triplex</option>
                <option value='Casa em Condominio'>Casa em Condominio</option>
                <option value='Sobrado'>Sobrado</option>
                <option value='Flat'>Flat</option>
              </select>
              {errors.subtipo && <ErrorMessageComponent message={errors.subtipo} />}
            </div>
            <div className='col-lg-3'>
              <label className='fs-6'>Lançamento </label>
              <div className='input-group mb-3 pt-3'>
                <Form.Check // prettier-ignore
                  type='radio'
                  id={'lancamento_sim'}
                  name={'lancamento'}
                  label={'Sim'}
                  value={1}
                  inline
                  onClick={() => {
                    hideAnoConstrucao(false)
                  }}
                  onChange={(e) => {
                    handleChangeLancamento(e)
                  }}
                  checked={formAvm.lancamento === 1 ? true : false}
                />
                <Form.Check // prettier-ignore
                  type='radio'
                  id={'lancamento_nao'}
                  name={'lancamento'}
                  label={'Não'}
                  value={0}
                  inline
                  onClick={() => {
                    hideAnoConstrucao(true)
                  }}
                  onChange={(e) => {
                    handleChangeLancamento(e)
                  }}
                  checked={formAvm.lancamento === 0 || formAvm.lancamento === null ? true : false}
                  // defaultChecked={ true }
                />
              </div>
            </div>
            <div className={`col-lg-3 ${!showAnoConstrucao ? 'hide' : ''}`}>
              <label className='fs-6'>Ano construção: (Ano Aproximado) </label>
              <div className='input-group mb-3'>
                <input
                  type='text'
                  className='form-control form-control-lg mb-3 mb-lg-0'
                  placeholder='0'
                  value={formAvm.ano_construcao}
                  name='ano_construcao'
                  id='ano_construcao'
                  maxLength={4}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                />
              </div>
              {errors.ano_construcao && <ErrorMessageComponent message={errors.ano_construcao} />}
            </div>
          </div>

          <div className='row mb-8'>
            <div className='col-lg-4'>
              <label className='fs-6'>Área Útil:</label>
              <div className='input-group mb-3'>
                <input
                  type='number'
                  className='form-control form-control-lg mb-3 mb-lg-0'
                  placeholder='0'
                  name='area'
                  id='area'
                  value={formAvm.area}
                  max={2000}
                  onChange={(e) => {
                    handleChangeArea(e)
                  }}
                />
                <span className='input-group-text' id='basic-addon1'>
                  m²
                </span>
              </div>
              {errors.area && <ErrorMessageComponent message={errors.area} />}
            </div>
            <div className='col-lg-4'>
              <label className='fs-6'>Numero de Quartos:(Incluíndo as suítes)</label>
              <input
                type='number'
                name='quartos'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='0'
                value={formAvm.quartos}
                onChange={(e) => {
                  handleChangeQuartos(e)
                }}
              />
              {errors.quartos && <ErrorMessageComponent message={errors.quartos} />}
            </div>
            <div className='col-lg-4'>
              <label className='fs-6'>Suítes:</label>
              <input
                type='text'
                name='suites'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='0'
                value={formAvm.suites}
                onChange={(e) => {
                  handleChangeSuites(e)
                }}
              />
              {errors.suites && <ErrorMessageComponent message={errors.suites} />}
            </div>
          </div>
          <div className='row mb-8'>
            <div className='col-lg-4'>
              <label className='fs-6'>Numero de Banheiros:(Incluíndo da suítes)</label>
              <input
                type='number'
                name='banheiros'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='0'
                value={formAvm.banheiros}
                onChange={(e) => {
                  handleChangeBanheiros(e)
                }}
              />

              {errors.banheiros && <ErrorMessageComponent message={errors.banheiros} />}
            </div>
            <div className='col-lg-4'>
              <label className='fs-6'>Numero de Vagas:</label>
              <input
                type='number'
                name='vagas'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='0'
                value={formAvm.vagas}
                onChange={(e) => {
                  handleChangeVagas(e)
                }}
              />
              {errors.vagas && <ErrorMessageComponent message={errors.vagas} />}
            </div>

            <div className='col-lg-4'>
              <label className='fs-6'>Condomínio:</label>
              <div className='input-group mb-3'>
                <span className='input-group-text' id='basic-addon1'>
                  R$
                </span>
                <input
                  type='number'
                  name='condominio'
                  id='condominio'
                  className='form-control form-control-lg mb-3 mb-lg-0'
                  placeholder='0'
                  value={formAvm.condominio}
                  onChange={(e) => {
                    handleChangeCondominio(e)
                  }}
                />
              </div>
              {errors.condominio && <ErrorMessageComponent message={errors.condominio} />}
            </div>
          </div>
        </form>
        </>
      )
      
  )
}
