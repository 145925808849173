import { gql } from "@apollo/client";

export const SET_EMPREENDIMENTO_MASSA = gql`
mutation SetEmpreendimentoMassa($massa: Massa!) {
  SetEmpreendimentoMassa(Massa: $massa) {
    nome
  }
}
`

export const PUT_EMPREENDIMENTO_MASSA = gql`
mutation PutEmpreendimentoMassa($massa: Massa!) {
  PutEmpreendimentoMassa(Massa: $massa) {
    id
    nome
  }
}
`

export const DELETE_EMPREENDIMENTO_MASSA = gql`
mutation DeleteEmpreendimentoMassa($deleteEmpreendimentoMassaId: Float!) {
  DeleteEmpreendimentoMassa(id: $deleteEmpreendimentoMassaId) {
    nome
  }
}
`