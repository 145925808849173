import { Explorer } from "../../Explorer";

export function createTile(id, callback) {
    this.id = id;
    this.type = 'tile';
    this.callback = callback;
    this.layer = null;
    this.index = null;
    this.zoomActive = null;
    this.maxZoom = 17;
    this.createLayer(id);
    this.callback();
}
createTile.prototype.getIndex = function() {
    return this.index;
}

createTile.prototype.setIndex = function(index) {
    this.index = index;
}
createTile.prototype.getMaxZoom = function() {
    return this.maxZoom;
}

createTile.prototype.remove = function() {
    Explorer.map['overlayMapTypes'].removeAt(this.getIndex());
}

// createTile.prototype.removeItens = function() {
//     var t = this;

//     return new Promise(function(resolve) {
//         // if (t.layer != null) {
//         //     t.layer.forEach(function(x) {
//         //         if (x.getProperty('zoom') !== MapControll.Gmap.map.getZoom()) {
//         //             t.layer.remove(x);
//         //         }
//         //     });
//         // }
//         return resolve(true);
//     });
// }

createTile.prototype.createLayer = function() {
    /** @constructor */
    function CoordMapType(tileSize, tile) {
        this.tileSize = tileSize;
        this.tile = tile;
    }
    CoordMapType.prototype.getTile = function(coord, zoom, ownerDocument) {
        var div = document.createElement('div');
        var image = document.createElement('IMG');
        image['src'] = process.env.REACT_APP_MAP_TILE + '/tile/' + this.tile.id + '/' + zoom + '/' + coord.x + '/' + coord.y + '.png?cor=ff0000&type=png';
        image['width'] = 256;
        image['height'] = 256;
        div.className = 'l_' + this.tile.id;
        div.dataset.layer = this.tile.id;
        // div.addEventListener('click', function(el) {
        //     console.log(el);
        // });
        div.appendChild(image);




        return div;
    };
    if (Explorer.checkExistMap()) {
        this.setIndex(Explorer.map['overlayMapTypes'].length);
        Explorer.map['overlayMapTypes'].insertAt(0, new CoordMapType(new google.maps.Size(256, 256), this));
    }
}

