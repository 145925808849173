import { useQuery } from "@apollo/client";
import { GET_CATEGORIAS } from "../querys/categoriasQuery";
import { TypesGetCategoriasResponse } from "../types/TypesCategorias";

interface QueryCategoriasProps {
    variables: any;
  }

export function QueryGetCategorias({ variables }: QueryCategoriasProps) {
    const { data, loading} = useQuery<TypesGetCategoriasResponse>(GET_CATEGORIAS, {
        variables: {...variables}
    })

    return {data, loading}
}
