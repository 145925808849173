import React, { useEffect } from "react";
import { HeaderPage } from '../../../_metronic/layout/components/HeaderPage'
import { CardHeaderText } from "../../components/UI/CardHeaderText";
import { Link } from 'react-router-dom'

import { useLocation } from 'react-router'
import clsx from 'clsx'
import { useLayout } from '../../../_metronic/layout/core'
import { DrawerComponent } from '../../../_metronic/assets/ts/components'


import '../global.css'

export default function CreatePacotes() {
  const { classes } = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])
  return (
    <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>
            <HeaderPage title="Cadastro dos Pacotes" subtitle='Cadastro' />
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <div className='post d-flex flex-column-fluid' id='kt_post'></div>
              {/*begin::Container*/}
              <CardHeaderText title='Cadastrar Pacote' />
              <br />
              {/*begin::Card*/}
              <div className="card" style={{ width: '100%' }}>
                {/*begin::Card header*/}
                <div className="card-header  border-0 pt-6">
                  {/*begin::Card title*/}
                  <div className="inboxContent" style={{ width: '100%' }}>
                    <h3 className="text-muted mt-1 fw-bold fs-7">*Cadastre as informações necessárias.</h3>
                    <div className="row pt-10">
                      {/* Profile */}
                      <div className="row">
                        {/* Name Field */}
                        <div className="form-group col-sm-3 ">
                          <label>Nome</label>
                          <input className="form-control" name="nome" type="text" id="nome" />
                        </div>

                        {/* Descrição Field */}
                        <div className="form-group col-sm-3">
                          <label>Descrição</label>
                          <input className="form-control" name="descricao" type="text" id="descricao" />
                        </div>

                        {/* Situacao Field */}
                        <div className="form-group col-sm-3">
                          <label>Situação</label>
                          <input className="form-control" name="situacao" type="text" id="situacao" />
                        </div>

                        <div className="form-group col-sm-3">
                          <label>Tipo</label>
                          <select className="form-select form-select-solid" id="select-tipo" name="tipo">
                            <optgroup label="0">
                              <option value="">Selecione</option>
                              <option value="GRATUITO">Gratuito</option>
                              <option value="GRATUITO_LOGIN">Gratuito Login</option>
                              <option value="PAGO">Pago</option></optgroup></select>
                        </div>
                      </div>


                      <div className="row pt-6">
                        {/* Valor Field */}
                        <div className="form-group col-sm-5">
                          <label>Valor</label>
                          <input className="form-control" name="valor" type="text" id="valor" />
                        </div>

                        {/* Desconto Field */}
                        <div className="form-group col-sm-4">
                          <label>Desconto</label>
                          <input className="form-control" name="desconto" type="text" id="desconto" />
                        </div>

                        {/* Modo Geração Field */}
                        <div className="form-group col-sm-3">
                          <label>Modo Geração</label>
                          <select className="form-select form-select-solid" id="select-modo-geracao" name="modo_geracao">
                            <optgroup label="0">
                              <option value="" >Selecione</option>
                              <option value="AUTOMATICO">Automatico</option>
                              <option value="COM_VALIDACAO">Com Validação</option>
                            </optgroup>
                          </select>
                        </div>
                      </div>


                      <div className="row pt-6">
                        {/* Tipo pacote Field */}
                        <div className="form-group col-sm-3">
                          <label>Tipo pacote</label>
                          <select className="form-select form-select-solid" id="select-tipo-pacote" name="tipo_pacote">
                            <optgroup label="0">
                              <option value="" >Selecione</option>
                              <option value="FICHA">Ficha</option>
                              <option value="PLANO">Plano</option>
                              <option value="RELATORIO">Relatório</option>
                            </optgroup>
                          </select>
                        </div>

                        {/* Debita pacote Field */}
                        <div className="form-group col-sm-5">
                          <label>Debita pacote</label>
                          <input className="form-control" name="debita_pacote" type="text" id="debita_pacote" />
                        </div>

                        {/* Ficha Exemplo Field */}
                        <div className="form-group col-sm-4">
                          <label>Ficha Exemplo</label>
                          <input className="form-control" name="ficha_exemplo" type="text" id="ficha_exemplo" />
                        </div>
                      </div>



                      {/* Submit Field */}
                      <div className="form-group col-sm-12 modal-footer mrt-2">
                        <button className="btn btn-primary">Salvar</button>
                        <Link to="/pacotes" className="btn btn-default">Cancelar</Link>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}