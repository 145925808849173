import {Text, View, StyleSheet} from '@react-pdf/renderer'
import {EmailsExtended} from './Proprietario/EmailsExtended'
import {BasicData} from './Proprietario/BasicaData'
import {PhonesExtended} from './Proprietario/PhonesExtended'
import {AddressesExtended} from './Proprietario/AddressesExtended'
import {RelatedPeople} from './Proprietario/RelatedPeople'
import {RelatedPeopleEmails} from './Proprietario/RelatedPeopleEmails'
import {RelatedPeoplePhones} from './Proprietario/RelatedPeoplePhones'
import {dadosTabelas} from '../Reports/partials/EstrtuturaBDC'
import {Ph3aData} from '../Reports/partials/Proprietario/Ph3aData'

const styles = StyleSheet.create({
  table: {
    width: '100%',
    fontSize: 11,
    fontWeight: 'light',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
  },
  rowErro: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
    textAlign: 'center',
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 16,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: '#2B8E57',
  },
  header: {
    borderTop: 'none',
    backgroundColor: '#dbdbdb',
    paddingHorizontal: 10,
  },
  bold: {
    fontWeight: 'black',
  },
  rowDefault: {
    width: '30%',
  },
  rowCenter: {
    width: '30%',
    textAlign: 'center',
  },
  decimal: {
    width: '20%',
    textAlign: 'center',
  },
  currency: {
    width: '20%',
    textAlign: 'center',
  },
})
export const Proprietarios = ({proprietarios}) => {
  // var dadosTabelas = {}
  // dadosTabelas['cpf'] = {}
  // dadosTabelas['cnpj'] = {}

  // dadosTabelas['cpf']['BasicData'] = {
  //   key: 'BasicData',
  //   titulo: 'Dados Principais',
  //   coluns: ['Name', 'Age', 'BirthDate', 'BirthCountry', 'Gender', 'FatherName', 'MotherName'],
  //   colunsItens: [],
  // }

  // dadosTabelas['cpf']['PhonesExtended'] = {
  //   key: 'ExtendedPhones',
  //   titulo: 'Telefones',
  //   coluns: ['Phones'],
  //   colunsItens: ['CountryCode', 'AreaCode', 'Number'],
  // }
  // dadosTabelas['cpf']['EmailsExtended'] = {
  //   key: 'ExtendedEmails',
  //   titulo: 'E-mails',
  //   coluns: ['Emails'],
  //   colunsItens: ['EmailAddress', 'UserName', 'Type'],
  // }
  // dadosTabelas['cpf']['AddressesExtended'] = {
  //   key: 'ExtendedAddresses',
  //   titulo: 'Endereços',
  //   coluns: ['Addresses'],
  //   colunsItens: ['AddressMain', 'Number', 'Neighborhood', 'City', 'ZipCode', 'Complement', 'Type'],
  // }

  // dadosTabelas['cpf']['RelatedPeople'] = {
  //   key: 'RelatedPeople',
  //   titulo: 'Pessoas relacionadas',
  //   coluns: ['PersonalRelationships'],
  //   colunsItens: ['RelatedEntityName', 'RelatedEntityTaxIdNumber', 'RelationshipType'],
  // }

  // dadosTabelas['cpf']['RelatedPeopleEmails'] = {
  //   key: 'RelatedPeopleEmails',
  //   titulo: 'E-mail pessoas relacionadas',
  //   coluns: [],
  //   colunsItens: ['EmailAddress', 'UserName', 'Type'],
  // }

  // dadosTabelas['cpf']['RelatedPeoplePhones'] = {
  //   key: 'RelatedPeoplePhones',
  //   titulo: 'Telefones pessoas relacionadas',
  //   coluns: [],
  //   colunsItens: ['CountryCode', 'AreaCode', 'Number', 'Type'],
  // }

  // dadosTabelas['cnpj']['BasicData'] = {
  //   'key': 'BasicData',
  //   'titulo': 'Dados Principais',
  //   'coluns': ['TradeName', 'OfficialName', 'TaxRegime', 'TaxIdStatusRegistrationDate', 'HeadquarterState', 'Age'],
  //   'colunsItens': [],
  //   'colunTotal': null
  // };

  // dadosTabelas['cnpj']['PhonesExtended'] = {
  //   'key': 'ExtendedPhones',
  //   'titulo': 'Telefones',
  //   'coluns': ['Phones'],
  //   'colunsItens': ['CountryCode', 'AreaCode', 'Number'],
  //   'colunTotal': 'TotalPhones'
  // }
  // dadosTabelas['cnpj']['EmailsExtended'] = {
  //   'key': 'ExtendedEmails',
  //   'titulo': 'E-mails',
  //   'coluns': ['Emails'],
  //   'colunsItens': ['EmailAddress', 'UserName', 'Type'],
  //   'colunTotal': 'TotalEmails'
  // }
  // dadosTabelas['cnpj']['AddressesExtended'] = {
  // 'key': 'ExtendedAddresses',
  // 'titulo': 'Endereços',
  // 'coluns': ['Addresses'],
  // 'colunsItens': ['Typology', 'AddressMain', 'Number', 'Neighborhood', 'City', 'State', 'ZipCode', 'Complement', 'Type'],
  // 'colunTotal': 'TotalAddresses'
  // }

  if (proprietarios) {
    console.log(proprietarios)

    return (
      <View style={styles.table}>
        <Text style={styles.title}>Proprietários</Text>

        {proprietarios.GetEmpreendimentoProprietarios.length === 0 ? (
          <Text style={styles.rowErro}>Nenhum registro encontrado</Text>
        ) : (
          ''
        )}
        {proprietarios.GetEmpreendimentoProprietarios.map((row, i) => {
          let typeDoc = row.cpf ? 'cpf' : 'cnpj'

          return (
            <View key={i}>
              <View style={[styles.row, {paddingTop: 10}]}>
                <Text style={styles.title}>{row.endereco_terreno}</Text>
              </View>

              {row.ph3a_data || row.basic_data ? (
                <Ph3aData
                  resultPeapleData={row.ph3a_data}
                  result={row}
                  typeDoc={typeDoc}
                ></Ph3aData>
              ) : (
                <></>
              )}

              {/* {row.basic_data ? (
                <BasicData
                  data={row.basic_data}
                  estrutura={dadosTabelas[typeDoc]['BasicData']}
                ></BasicData>
              ) : (
                ''
              )}
              {row.phones_extended ? (
                <PhonesExtended
                  data={row.phones_extended}
                  estrutura={dadosTabelas[typeDoc]['PhonesExtended']}
                ></PhonesExtended>
              ) : (
                ''
              )}
              {row.emails_extended ? (
                <EmailsExtended
                  data={row.emails_extended}
                  estrutura={dadosTabelas[typeDoc]['EmailsExtended']}
                ></EmailsExtended>
              ) : (
                ''
              )}
              {row.addresses_extended ? (
                <AddressesExtended
                  data={row.addresses_extended}
                  estrutura={dadosTabelas[typeDoc]['AddressesExtended']}
                ></AddressesExtended>
              ) : (
                ''
              )}

{typeDoc === 'cpf' ? (  <>
              {row.related_people ? (
                <RelatedPeople
                  data={row.related_people}
                  estrutura={dadosTabelas[typeDoc]['RelatedPeople']}
                ></RelatedPeople>
              ) : (
                ''
              )}
              {row.related_people_emails ? (
                <RelatedPeopleEmails
                  data={row.related_people_emails}
                  estrutura={dadosTabelas[typeDoc]['RelatedPeopleEmails']}
                ></RelatedPeopleEmails>
              ) : (
                ''
              )}
              {row.related_people_phones ? (
                <RelatedPeoplePhones
                  data={row.related_people_phones}
                  estrutura={dadosTabelas[typeDoc]['RelatedPeoplePhones']}
                ></RelatedPeoplePhones>
              ) : (
                ''
              )}
 </> ):'' */}
              {/* } */}
            </View>
          )
        })}
      </View>
    )
  }
}
