import { gql } from "@apollo/client";

export const GET_IMOVEIS = gql` 
query GetImoveis($pagination: Pagination!, $filtro: filter, $situation: Float) {
  GetImoveis(pagination: $pagination, filtro: $filtro, situation: $situation) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      id
      nome
      endereco
      numero
      bairro
      cidade
      estado
      zoneamento
      operacao_urbana
      area_real
      cep
      data_cadastro
      status_cliente
      latitude
      longitude
      marcacao
      users {name}
    }
  }
}

`

export const GET_IMOVEIS_RELATORIO = gql`query GetImoveisRelatorio($filtro: filter) {
  GetImoveisRelatorio(filtro: $filtro) {
    areaTotal
    totalImoveis
  }
}`
export const GET_IMOVEL_EMPREENDIMENTO = gql`
query GetImovelEmpreendimento($idImovel: Float) {
  GetImovelEmpreendimento(id_imovel: $idImovel) {
    nome
    id
    terreno {
      nome
      id
    }
  }
}`
export const GET_ONE_IMOVEL = gql`
query GetImovel($uuid: String, $getImovelId: Float, $situation: Float) {
  GetImovel(uuid: $uuid, id: $getImovelId, situation: $situation) {
      id
      uuid
      nome
      endereco
      numero
      complemento
      bairro
      cidade
      estado
      cep
      marcacao
      area_iptu
      area_real
      zoneamento
      numero_contribuinte
      nome_contribuinte
      numero_matricula
      id_user
      data_cadastro
      latitude
      longitude
      setor_quadra
      uso_atual_terreno
      macroarea
      macrozona
      codlog
      fpr
      fpnr
      cabas
      camax
      quadro14
      hash_id
      status_cliente
      tag
      id_cidade
      id_estado
      responsavel
      metragem
      preco_m2
      preco_total
      operacao_urbana
      testada
      relatorio_compra
      ficha_publica
    }
  }
`


export const GET_ONE_IMOVEL_BY_UUID = gql`
query GetImovelByUUID($uuid: String!) {
  GetImovelByUUID(uuid: $uuid) {
      id
      uuid
      nome
      endereco
      numero
      complemento
      bairro
      cidade
      estado
      cep
      marcacao
      area_iptu
      area_real
      zoneamento
      numero_contribuinte
      nome_contribuinte
      numero_matricula
      id_user
      data_cadastro
      latitude
      longitude
      setor_quadra
      uso_atual_terreno
      macroarea
      macrozona
      codlog
      fpr
      fpnr
      cabas
      camax
      quadro14
      hash_id
      status_cliente
      tag
      id_cidade
      id_estado
      responsavel
      metragem
      preco_m2
      preco_total
      operacao_urbana
      testada
    }
  }
`
