import { gql } from '@apollo/client'

export const GET_USUARIOS = gql`
query GetUsuarios($pagination: Pagination!, $filtro: filter) {
  GetUsuarios(pagination: $pagination, filtro: $filtro) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      id
      name
      email
      created_at
      uuid
      api_token
      telefone
      cpf
      cnpj
      tipo_pessoa
      cor_fonte
      cor_background
      remember_token
      id_area_atuacao
      token_troca_senha
      user_produto {
        produto {
          id
          nome
        }
        id_produto
        data_fim
        data_inicio
      }
    }
  }
}
`

export const GET_USUARIO_BY_ID = gql`
query GetUsuario($getUsuarioId: Float!) {
  GetUsuario(id: $getUsuarioId) {
    email
    id
    cpf
    cor_background
    cor_fonte
    cnpj
    area_atuacao {
      name
      id
    }
    id_area_atuacao
    name
    telefone
    tipo_pessoa
    user_pic
    empresa_usuario {
      id
      empresa {
        id
        nome
      }
    }
  }
}
`

export const GET_FUNCIONARIO_BY_ID = gql`
query GetUsuario($getUsuarioId: Float!) {
  GetUsuario(id: $getUsuarioId) {
    id
    name
    email
    edicao
    exclusao
    visualizacao
  }
}
`

export const ADMIN_GET_USUARIO_PRODUTO_BY_ID = gql`
query GetUsuarioProdutos($idUser: Float!) {
  GetUsuarioProduto(id_user: $idUser) {
    id
    name
    user_produto {
      id
      id_produto
      id_user
      uuid
      data_inicio
      data_fim
      produto {
        descricao
        nome
        tipo_produto
        valor
      }
    }
  }
}
`