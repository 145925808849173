import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

const API_URL: string | undefined = process.env.REACT_APP_API_URL


let openModalCallback: (path:any) => void;

export const setOpenModalCallback = (callback: (path:any) => void) => {
  openModalCallback = callback;
};

const errorLink = onError(({ graphQLErrors, networkError }) => {

  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (message === "Error: Ip's are difrent") {
        window.location.href = '/logout'
      }
      
      if (message === "TokenExpiredError: jwt expired") {
        if (openModalCallback) {
          openModalCallback(path); // Chama a função para abrir o modal
        }
      }
      // console.log(message)
      switch (extensions.code) {
        case "UNAUTHENTICATED": {
          console.log('...')
        }
      }

      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const httpLink = new HttpLink({
  uri: API_URL
})

const authMiddleware = new ApolloLink((operation, forward) => {

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: 'Bearer ' + localStorage.getItem('token') || null,
    } 
  }));

  return forward(operation);
})

export const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache(),
  link: from([authMiddleware, errorLink, httpLink]),
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
  },
})
