import {useQuery} from '@apollo/client'
import {GET_SERVICO_CATEGORIA} from '../query'
import {TypesGetServicoCategoriaResponse} from '../type'

interface QueryProps {
  variables: any
}

export function QueryGetServicosCatetorias({variables}: QueryProps) {
  const {data} = useQuery<TypesGetServicoCategoriaResponse>(GET_SERVICO_CATEGORIA, {
    variables: {...variables},
  })
  return data
}
