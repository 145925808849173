import {z} from 'zod'

export const setAVMFormSchema = z.object({
  endereco: z.string(),
  tipo: z.string(),
  padrao: z.string(),
  area: z.number(),
  suites: z.string(),
  quartos: z.number(),
  banheiros: z.number(),
  vagas: z.number(),
  numero_torres: z.number(),
  numero_andares: z.number(),
  aptos_andar: z.number(),
  latitude: z.number(),
  longitude: z.number(),
  // features: z.array(z.unknown()).optional(),
})

export type setAVMFormData = z.infer<typeof setAVMFormSchema>

// AVM IMOVEL V2

export const setAVMFormSchemaV2 = z.object({
  origem_lead: z.string().optional(),
  id_terreno: z.number().optional(),
  id_empreendimento: z.number().optional(),
  id_finalidade: z.number().optional(),
  endereco: z.string(),
  numero: z.string(),
  cep: z.string(),
  cidade: z.string(),
  estado: z.string(),
  uf: z.string(),
  tipo: z.string(),
  subtipo: z.string(),
  area: z.number(),
  suites: z.string(),
  quartos: z.number(),
  banheiros: z.number(),
  vagas: z.number(),
  condominio: z.number(),
  ano_construcao: z.number(),
  latitude: z.number(),
  longitude: z.number(),
  lancamento: z.number(),
  features: z.string().optional(),
  result_maximo: z.number(),
  result_medio: z.number(),
  result_minimo: z.number(),
  // features: z.array(z.unknown()).optional(),
})

export type setAVMFormDataV2 = z.infer<typeof setAVMFormSchemaV2>

// AVM IMOVEL

export const setAVMLeadImovelFormSchema = z.object({
  origem_lead: z.string(),
  // id_terreno: z.number(),
  // id_empreendimento: z.number(),
  // id_finalidade: z.number(),
  result_maximo: z.number(),
  result_medio: z.number(),
  result_minimo: z.number(),
  endereco: z.string(),
  tipo: z.string(),
  area: z.number(),
  suites: z.string(),
  quartos: z.number(),
  banheiros: z.number(),
  vagas: z.number(),
  latitude: z.number(),
  longitude: z.number(),
  features: z.string().optional(),
})

export type setAVMLeadImovelFormData = z.infer<typeof setAVMLeadImovelFormSchema>

export const putAVMLeadImovelFormSchema = z.object({
  origem_lead: z.string(),
  id_terreno: z.number(),
  id_finalidade: z.number(),
  id_tipo_imovel: z.number(),
  result_maximo: z.number(),
  result_medio: z.number(),
  result_minimo: z.number(),
  tipo: z.number(),
  padrao: z.string(),
  area: z.number(),
  suites: z.number(),
  quartos: z.number(),
  banheiros: z.number(),
  vagas: z.number(),
  numero_torres: z.number(),
  numero_andares: z.number(),
  aptos_andar: z.number(),
  id_situacao: z.number(),
  // features: z.array(z.unknown()).optional(),
})

export type putAVMLeadImovelFormData = z.infer<typeof putAVMLeadImovelFormSchema>

// EMPREENDIMENTO IMOVEL

export const setAVMLeadEmpreendimentoFormSchema = z.object({
  origem_lead: z.string(),
  id_empreendimento: z.number(),

  result_maximo: z.number(),
  result_medio: z.number(),
  result_minimo: z.number(),
  tipo: z.string(),
  padrao: z.string(),
  area: z.number(),
  suites: z.number(),
  quartos: z.number(),
  banheiros: z.number(),
  vagas: z.number(),
  numero_torres: z.number(),
  numero_andares: z.number(),
  aptos_andar: z.number(),
  modelo: z.string(),
  // features: z.array(z.unknown()).optional(),
})

export type setAVMLeadEmpreendimentoFormData = z.infer<typeof setAVMLeadEmpreendimentoFormSchema>

export const putAVMLeadEmpreendimentoFormSchema = z.object({
  origem_lead: z.string(),
  id_empreendimento: z.number(),

  result_maximo: z.number(),
  result_medio: z.number(),
  result_minimo: z.number(),
  tipo: z.string(),
  padrao: z.string(),
  area: z.number(),
  suites: z.number(),
  quartos: z.number(),
  banheiros: z.number(),
  vagas: z.number(),
  numero_torres: z.number(),
  numero_andares: z.number(),
  aptos_andar: z.number(),

  // features: z.array(z.unknown()).optional(),
})

export type putAVMLeadEmpreendimentoFormData = z.infer<typeof putAVMLeadEmpreendimentoFormSchema>

export const setAVMLeadInteligenciaFormSchema = z.object({
  id_terreno: z.number(),
  id_finalidade: z.number(),
  id_tipo_imovel: z.number(),
  id_situacao: z.number(),
  id_empreendimento: z.number(),
  id_inteligencia_mercado_projeto: z.number(),
  aquivo: z.string(),
  numero_torres: z.number(),
  numero_andares: z.number(),
  aptos_andar: z.number(),
  padrao: z.string(),
  ano_construcao_cliente: z.number(),
  area: z.number(),
  bairro: z.string(),
  banheiros: z.number(),
  cidade: z.string(),
  condominio: z.number(),
  email: z.string(),
  endereco: z.string(),
  estado: z.string(),
  features: z.string(),
  json_output: z.unknown(), // Altere para o tipo correto, se possível
  latitude: z.number(),
  logradouro: z.string(),
  longitude: z.number(),
  numero: z.string(),
  origem_lead: z.string(),
  quartos: z.number(),
  result_maximo: z.number(),
  result_medio: z.number(),
  result_minimo: z.number(),
  suites: z.string(),
  tipo: z.string(),
  subtipo: z.string(),
  vagas: z.number(),
  modelo: z.string(),
})

export type setAVMLeadInteligenciaFormData = z.infer<typeof setAVMLeadInteligenciaFormSchema>
