
import { PainelImobiliarioContent } from './painel'

interface Props {
  itens: []
  latLng: google.maps.LatLng
  show:boolean
}

export const PainelImobiliario: React.FC<Props> = ({itens, latLng, show=false}) => {
  function closeAction() {
    // setActiveTab({...activeTab, index:false,infra:false,venda:false,locacao:false, lancamentos:false })
  }

  if (!show){
    return <></>;
  }
  return (
    <div
      className='modal fade '
      id='modal-painel-imobiliario'
      tabIndex={-1}
      aria-hidden='true'
      style={{zIndex: 9999}}
    >
      <div className='modal-dialog  modal-90w'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='modal-raui-toogle-label'>
              Painel de Mercado
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={closeAction}
            ></button>
          </div>
          <div className='modal-body' style={{backgroundColor: '#f5f8fa'}}>
          {show ?  <PainelImobiliarioContent itens={[]} latLng={latLng}></PainelImobiliarioContent> :''}
          </div>
          <div className='modal-footer'></div>
        </div>
      </div>
    </div>
  )
}
