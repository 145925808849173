import {useState} from 'react'
import {SliderSingle} from '../../../../../components/UI/Slider'
import {Explorer} from '../Explorer'
import {ApiRoute} from '../../../../../../services/apiRoute'
import {Layout} from '../Layout'
import {LoaderButton} from '../../../../../components/UI/Loader/LoaderButton'
import { Alert } from 'react-bootstrap'
// import { ButtonLoading } from '../../../../../components/UI/Button'

type PropsContextMenu = {
  latLng: google.maps.LatLng | null
  defaultValue: number
  addMarker: boolean
}

export const Isocrona: React.FC<PropsContextMenu> = ({latLng, defaultValue, addMarker = false}) => {
  const [tempo, setTempo] = useState(defaultValue)
  const [veiculo, setVeiculo] = useState('')
  const [showLoader, setShowLoader] = useState(false)

  const onClickAddTempo = () => {
    const lng = latLng.lng()
    const lat = latLng.lat()
    if (addMarker) {
      Explorer.addMarker(lat, lng, `Local - De  ${tempo}`, `Tempo de  ${tempo}`)
    }
    const geom = {
      attributes: ['area', 'reachfactor', 'total_pop'],
      interval: tempo * 60,
      location_type: 'start',
      locations: [[lng, lat]],
      range: [tempo * 60],
      range_type: 'time',
      area_units: 'm',
    }
    if (veiculo){
      ApiRoute(geom, 'isochrones', veiculo, function (data) {
        Explorer.addIsocrona(data.features[0], `Tempo de  ${tempo}`)
        Layout.setShowGeometryMenu(false)
        setShowLoader(false)
      })
    }else{
      setShowLoader(false)
      alert('Informe o modo de locomoção.')

    }

    // Explorer.addIscocrona(lat, lng, tempo, `Tempo de  ${tempo}`)
  }
  function onChange(value: any) {
    setTempo(value)
  }
  let marks = {}
  marks[0] = '0 min'
  // marks[] = 2
  // marks[10] = '10 min'
  // marks[20] = '20 min'
  marks[30] = '30 min'
  return (
    <div className='modal fade' id='modal-isocrona' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='modal-raui-toogle-label'>
              Isócrona - Distância Tempo
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
          <p>Distância a partir de um ponto com um alcance em tempo, incluíndo barreiras e velocidade da via(não considerando as condições de trânsito local, apenas velocidade permitida) , que delimita uma área de influência.</p>
            <div className='row'>
              <label className='col-2'>Modo de locomoção:</label>
              <div className='col-10'>
                <select
                  name='modo'
                  className='form-control'
                  onChange={(value) => {
                    setVeiculo(value.target.value)
                  }}
                >
                  <option value=''>--selecione--</option>
                  <option value='driving-car'>Carro</option>
                  <option value='cycling-regular'>Bicicleta</option>
                  <option value='foot-walking'>A pé</option>
                </select>
              </div>
            </div>
            <div className='row pt-5'>
              <label className='col-2  d-flex align-items-center'>Tempo em min:</label>
              <div className='col-10'>
                <SliderSingle
                  defaultValue={defaultValue}
                  min={0}
                  max={30}
                  step={1}
                  onChange={onChange}
                  tipFormatter={(val) => `${val} minutos`}
                  marks={marks}
                ></SliderSingle>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            {/* <ButtonLoading  onClick={onClickAddTempo} className={'btn btn-primary'}></ButtonLoading> */}
            <button
              className='btn btn-primary'
              onClick={() => {
                setShowLoader(true)
                onClickAddTempo()
              }}
            >
              {showLoader ? <LoaderButton text={'Gerando...'}></LoaderButton> : 'Adicionar'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
