import { gql } from "@apollo/client";

export const GET_EMPREENDIMENTO_MASSA = gql`
query GetEmpreendimentoMassa($pagination: Pagination!, $massa: Massa!) {
  GetEmpreendimentoMassa(pagination: $pagination, Massa: $massa) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      nome
      id
      id_empreendimento
      uuid
      situacao
    }
  }
}
`

export const GET_BY_ID_EMPREENDIMENTO_MASSA = gql`
query GetEmpreendimentoMassaById($massa: Massa!) {
  GetEmpreendimentoMassaById(Massa: $massa) {
    id
    id_empreendimento
    nome
  }
}
`