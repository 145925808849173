import { useMutation, useQuery } from "@apollo/client";
import { GET_NEWS_FEED, GET_NEWS_FEED_BY_ID } from "../querys/newsFeedQuery";
import { TypesDeleteNewsFeedResponse, TypesGetNewsFeedByIDResponse, TypesGetNewsFeedResponse, TypesPutNewsFeedResponse, TypesSetNewsFeedResponse } from "../types/TypesNewsFeed";
import { DELETE_NEWS_FEED, PUT_NEWS_FEED, SET_NEWS_FEED } from "../mutation/newsFeedMutation";
import { zodResolver } from "@hookform/resolvers/zod";
import { NewsFeedFormCreateData, NewsFeedFormCreateSchema, NewsFeedFormPutData, NewsFeedFormPutSchema } from "../../formValidations/NewsFeed";
import { useForm } from "react-hook-form";
import { TypesPutCupomResponse } from "../types";



interface CuponsProps {
  variables: any
}

export function QueryGetNewsFeed({ variables }: CuponsProps) {
    const { data, loading, error } = useQuery<TypesGetNewsFeedResponse>(GET_NEWS_FEED, {
        variables: { ...variables },
    })

    return {data, loading, error}
}

export function QueryGetNewsFeedByID({ variables }: CuponsProps) {

    const { data, loading, error } = useQuery<TypesGetNewsFeedByIDResponse>(GET_NEWS_FEED_BY_ID, {
        variables: { ...variables }
    })

  return {data, loading, error}
}

export function MutationDeleteNewsFeed() {
  const [deleteNewsFeedBody, {loading}] = useMutation<TypesDeleteNewsFeedResponse>(DELETE_NEWS_FEED)

  async function HandleDeleteNewsFeed({variables}: CuponsProps) {
    const result = await deleteNewsFeedBody({
      variables: {...variables},
      refetchQueries: [GET_NEWS_FEED],
    })

    return result
  }

    return { HandleDeleteNewsFeed, loading }
}

export function MutationSetNewsFeed() {
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        control,
        formState: { errors }
    } = useForm<NewsFeedFormCreateData>({
        resolver: zodResolver(NewsFeedFormCreateSchema)
    });

    console.log(errors)

    const [setNewsFeedBody, { loading, error, data: DataSetNewsFeed }] = useMutation<TypesSetNewsFeedResponse>(SET_NEWS_FEED)

    
    async function FormSetNewsFeed(data: NewsFeedFormCreateData) {
        await setNewsFeedBody({
            variables: {
                data: {...data}
            },
            refetchQueries: [GET_NEWS_FEED]
        })
    };

    return { register, handleSubmit, errors, FormSetNewsFeed, loading, error, 
        DataSetNewsFeed, setValue, setError, control }
}


export function MutationPutNewsFeed() {
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        control,
        formState: { errors }
    } = useForm<NewsFeedFormPutData>({
        resolver: zodResolver(NewsFeedFormPutSchema)
    });

    const [putNewsFeed, {loading,error, data: DataPutNewsFeed}] = useMutation<TypesPutNewsFeedResponse>(PUT_NEWS_FEED)

    async function FormPutNewsFeed(data: NewsFeedFormPutData) {
        await putNewsFeed({
            variables: {
                data: {...data},
            },
            refetchQueries: [GET_NEWS_FEED],
        })
    }

    return {
        FormPutNewsFeed, errors, register, handleSubmit, loading, DataPutNewsFeed,error,
        setError, setValue, control
    }
}