import { InputHTMLAttributes, useRef } from 'react'
import { ErrorMessageComponent } from '../../../backoffice/components/UI/ErrorMessage'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  register?: any
  error?: any
  rows?: number
  resize?: boolean
}

export function FormTextareaAndLabel({ label, register, error, resize, rows, ...props }: InputProps) {
  const myRef = useRef(null)
  return (
    <div className='form-group'>
      <label className='col-form-label'>{label}</label>
      <textarea 
      rows={rows ? rows : 6} style={resize ? {} : { resize: 'none' }}  ref={myRef} refs={myRef} {...props} {...register} />
      {error && <ErrorMessageComponent message={error} />}
    </div>
  )

}
