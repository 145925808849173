import React from 'react'
import {ButtonNovo} from './components/ButtonNovo'
import {ButtonSalvar} from './components/ButtonSalvar'
import {ButtonAbrir} from './components/ButtonAbrir'

type Props = {
  id: Number
}

export const ProjetoActions: React.FC<Props> = ({id}) => {
  return (
    <>
      <div
        className='w-100  '
        style={{
          borderTop: '1px solid #f3f3f3 !important',
          borderBottom: '1px solid #F3F3F3 !important',
        }}
      >
        <div className='mt-0 mb-5 mx-5'>
          <div className='btn-group buttonGroups d-flex justify-content-between  '>
            <ButtonAbrir id={0}></ButtonAbrir>
            <ButtonSalvar id={0}></ButtonSalvar>
            <ButtonNovo id={0}></ButtonNovo>
          </div>
        </div>
      </div>
    </>
  )
}
