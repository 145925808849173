import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import { QueryGetUsuarios } from '../../../../../../graphql/services'


export function UsuariosChosen({
  setValueUsuario,
  valueUsuario
}) {
  let usuariosArray = []


  const [usuario, setUsuario] = useState(valueUsuario)

  useEffect(() => {
    setValueUsuario(usuario)
  }, [usuario, setValueUsuario])

  const { data } = QueryGetUsuarios({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 10000,
      }
    }
  })



  if (!data) {
    return (
      <>
        <div className='form-group'>
          <label className='col-form-label'>Usuarios Ativos:</label>
          <Select
            options={usuariosArray}
            value={usuariosArray.filter((e) => e.value)[0]}
            name='id_user'
            placeholder='Usuarios Disponiveis'
          />
        </div>
      </>
    )
  }

  data.GetUsuarios.result.map((Options) => {
    usuariosArray.push({ value: Options.id, label: Options.email ? `${Options.id}-${Options.name} - ${Options.email}` : Options.name })
    return []
  })



  return (
    <>
      <div className='form-group'>
        <label className='col-form-label'>Usuarios Ativos:  </label>
        <Select
          options={usuariosArray}
          onChange={(e) => {
            setUsuario(e.value)
          }}
          name='id_user'
          placeholder='Usuarios Disponiveis'
        />
      </div>
    </>
  )
}
