import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useEffect } from 'react'
import { MutationPutCorretores, QueryGetCorretoresByID } from '../../../../../graphql'
import { FormInputAndLabel } from '../../../../../components/UI/Inputs/FormInputAndLabel'
import { FormInputMaskAndLabel } from '../../../../../components/UI/Inputs/FormInputMaskAndLabel'
import { Loader } from '../../../../../components/UI/Loader/Loader'

interface Props {
  id: number
  show: boolean
  closeModal: any
}

export function EditFormCorretor({ id, show, closeModal }: Props) {
  const { data} = QueryGetCorretoresByID({
    variables: { getCorretorByIdId: id },
  })

  console.log(id)

  const { FormCorretor, loading, handleSubmit, register, errors, error, DataSetCorretor } =
    MutationPutCorretores()

  useEffect(() => {
    if (show) {
      if (error) {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'warning')
      } else if (DataSetCorretor) {
        closeModal()
        Swal.fire('Sucesso!', 'Informações atualizadas com sucesso.', 'success')
      }
    }
  }, [DataSetCorretor, closeModal, error, id, show])

  // Tratativa de loading
  if (loading) {
    Swal.fire(
      'Enviando Informações...', '')
    Swal.showLoading()
  }

  if(!data){
    return (
      <>
        <Modal centered animation show={show} onHide={() => closeModal()}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Corretor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Loader text='Carregando Informações...'  />
          </Modal.Body>
        </Modal>
      </>
    )
  }

  return (
    <>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Corretor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(FormCorretor)}>
            <input
              className='form-control form-control-solid'
              type='number'
              hidden={true}
              defaultValue={data ? data.GetCorretorById.id : ''}
              {...register('id', { required: true })}
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputAndLabel
                    label='Nome Completo'
                    className='form-control required form-control-solid'
                    placeholder='Nome Completo'
                    defaultValue={data ? data.GetCorretorById.nome : ''}
                    register={register("nome")}
                    error={errors ? errors.nome?.message : ''}
                  />
                </div>
              </div>

              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputAndLabel
                    label='Email'
                    className='form-control required form-control-solid'
                    placeholder='Ex: joao@email.com'
                    defaultValue={data ? data.GetCorretorById.email : ''}
                    register={register("email")}
                    error={errors ? errors.email?.message : ''}
                  />
                </div>
              </div>

              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputMaskAndLabel
                    label='Telefone:'
                    maskChar=' '
                    mask='(99) 99999-9999'
                    className='form-control form-control-solid'
                    placeholder='Ex: (00) 00000-0000'
                    defaultValue={data ? data.GetCorretorById.telefone : ''}
                    register={register("telefone")}
                    error={errors ? errors.telefone?.message : ''}
                  />
                </div>
              </div>

              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputAndLabel
                    label='Empresa'
                    className='form-control required form-control-solid'
                    placeholder='Empresa'
                    defaultValue={data ? data.GetCorretorById.empresa : ''}
                    register={register("empresa")}
                    error={errors ? errors.empresa?.message : ''}
                  />
                </div>
              </div>

              <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={() => closeModal()}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
