import { useEffect, useState } from 'react'
import { MutationDeleteEmpreendimentos, MutationPutEmpreendimento, MutationRecoveryEmpreendimento, QueryGetEmpreendimentoById } from '../../../../../graphql/services'
import Swal from 'sweetalert2'
import { Loader } from '../../../../../components/UI/Loader/Loader'
import { FormInputAndLabel } from '../../../../../components/UI/Inputs/FormInputAndLabel'
import { FormInputCurrencyFormat } from '../../../../../components/UI/Inputs/FormInputCurrencyFormat'
import { FormInputPercentFormat } from '../../../../../components/UI/Inputs/FormInputPercentFormat'
import { FormInputNumericFormat } from '../../../../../components/UI/Inputs/FormInputNumericFormat'
import { GetEmpreendimentoTagsCreatableSelect } from './partials/GetEmpreendimentoTagsCreatableSelect'
import { TypesGetEmpreendimentoImovelResponse } from '../../../../../graphql'
import { MutationSetLogAcessoRecurso } from '../../../../../graphql/services/LogAcesso'

type Props = {
  id: number
  imoveis: TypesGetEmpreendimentoImovelResponse
  param: string
}

export const TabInformacoesGerais: React.FC<Props> = ({ id, imoveis, param }) => {

  const [areaReal, setAreaReal] = useState(0);
  const [areaPrivativa, setAreaPrivativa] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()
 
  useEffect(() => {
    // Calcula as áreas totais iniciais
    setTotalAreas();
  }, []);


  const data = QueryGetEmpreendimentoById({ variables: { idEmpreendimento: id } })
  const {
    FormEmpreendimento,
    loading,
    handleSubmit,
    register,
    errors,
    error,
    DataPutEmpreendimento,
    setValue,
    control,
  } = MutationPutEmpreendimento()
  const { HandleRecoveryEmpreendimento, loading: LoadingRecovery } = MutationRecoveryEmpreendimento()
  const { HandleDeleteEmpreendimentos, loading: LoadingDelete } = MutationDeleteEmpreendimentos()
  
  useEffect(() => {
    register('area_total_terreno')
  }, [ register])


  // Tratativa de loading
  if (!data) {
    return <Loader text={'caregando...'}></Loader>
  }


  if (LoadingDelete || LoadingRecovery) {
    Swal.fire(
      'Enviando Informações...', '')
    Swal.showLoading()
  }


  async function setTotalAreas() {

    let totalAreaReal = 0;
    let totalAreaPrivativa = 0;

    // Percorre o array de imóveis e soma as áreas reais e privativas
    await imoveis.GetEmpreendimentoImovel.result.forEach((imovel) => {
      if (imovel.terreno) {
        totalAreaReal += imovel.terreno.area_iptu || 0; // Use area_iptu conforme a tipagem
        totalAreaPrivativa += imovel.terreno.area_real || 0; // Use area_real conforme a tipagem
      }
    });

    // Armazena as áreas nos estados
    setAreaReal(totalAreaReal);
    setAreaPrivativa(totalAreaPrivativa);
  }



  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteEmpreendimentos({ variables: { deleteEmpreendimentoId: id } })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Empreendimento deletado com sucesso.', 'success')
          FormSetLogAcessoRecurso({
            action: 'delete',
            ferramenta: 'empreendimento',
            funcionalidade: 'informacoes-gerais',
            url: `/empreendimentos/details/${id}#informacoes-gerais`
          })
        }
      }
    })
  }
  function confirmRecovery(id: number) {
    Swal.fire({
      title: 'Tem certeza?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Recuperar',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleRecoveryEmpreendimento({
          variables: {
            empreendimentos: {
              situacao_id: id,
              id: data.GetEmpreendimento.id
            }
          }
        })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Empreendimento recuperado com sucesso.', 'success')
        }
      }
    })
  }

  const onSubmit = async (data) => {
    FormEmpreendimento(data).then((result) => {
      console.log(result)
      Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
      FormSetLogAcessoRecurso({ferramenta: 'empreendimentos', funcionalidade: 'informacoes-gerais', action: 'update', url: `/empreendimentos/details/${result.data.PutEmpreendimento.id}#informacoes-gerais`});
    }).catch((error) => {
      console.log(error)
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })


  }

  return (
    <div
      className={`tab-pane fade ${param === 'informacoes-gerais' ? ' show active' : ''}`}
      id='informacoes-gerais'
      role='tabpanel'
      aria-labelledby='informacoes-gerais'
    >
      <div className='card'>
        <div className='card-header  d-flex all-center-between'>
          <h3 className='card-title'>Informações Gerais</h3>
          <div className='all-center'>
            <h3 className='text-white'>Situação:</h3>
            {data.GetEmpreendimento.situacao_id === 1 ?
              <span className='badge bg-success ml-4 fs-5 '>Ativo</span>
              :
              <span className='badge bg-danger ml-4 fs-5 '>Descartado</span>
            }
          </div>
        </div>

        <div className='card-body p-6 pt-0'>
          <div className='row'>
            <div className='col-md-12'>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className='horizontal-form  padding-top-25'
                autoComplete='off'
              >
                <input
                  type='number'
                  hidden
                  className='form-control'
                  defaultValue={data.GetEmpreendimento.id}
                  {...register('id', { required: true, valueAsNumber: true })}
                />
                <input
                  type='number'
                  hidden
                  className='form-control'
                  defaultValue={data.GetEmpreendimento.situacao_id}
                  {...register('situacao_id', { required: true, valueAsNumber: true })}
                />
                <div className='row pt-4'>
                  <div className='col-md-6'>
                    <FormInputAndLabel
                      label='Nome'
                      defaultValue={data.GetEmpreendimento.nome}
                      className='form-control'
                      placeholder='Nome'
                      register={register('nome')}
                      error={errors ? errors.nome?.message : ''}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormInputAndLabel
                      label='Endereço'
                      defaultValue={data.GetEmpreendimento.endereco}
                      className='form-control '
                      placeholder='Endereço'
                      register={register('endereco')}
                      error={errors ? errors.endereco?.message : ''}
                    />
                  </div>
                </div>

                <div className='row py-2'>
                  <div className='col-md-6 col-lg-3'>
                    <FormInputNumericFormat
                      control={control}
                      label=' Área total do terreno'
                      defaultValue={initialized ? areaReal : data.GetEmpreendimento.area_total_terreno}
                      className='form-control text-right'
                      placeholder='Área total do terreno'
                      register={register('area_total_terreno')}
                      error={errors ? errors.area_total_terreno?.message : ''}
                      onValueChange={(e) => {
                        setValue('area_total_terreno', e.floatValue)
                      }}
                    />
                    Área para fins de cálculo.
                    <a
                      href='#vazio'
                      className='pull-left'
                      id='usar-soma-area-tatal-terreno'
                      onClick={(e) => {
                        e.preventDefault();
                        setTotalAreas();
                        setInitialized(true)
                      }}
                    >
                      {' '}
                      Usar total das áreas dos terrenos{' '}
                    </a>
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    <FormInputNumericFormat
                      control={control}
                      label=' Área Privativa total (m²)'
                      defaultValue={initialized ? areaPrivativa : data.GetEmpreendimento.area_total_privativa}
                      className='form-control text-right'
                      placeholder='Área privativa total'
                      register={register('area_total_privativa')}
                      error={errors ? errors.area_total_privativa?.message : ''}
                      onValueChange={(e) => {
                        setValue('area_total_privativa', e.floatValue)
                      }}
                    />
                  </div>

                  <div className='col-md-6 col-lg-3'>
                    <FormInputCurrencyFormat
                      control={control}
                      label='Preço Médio(R$/m²)'
                      defaultValue={data.GetEmpreendimento.preco_medio_m2}
                      className='form-control text-right'
                      placeholder='Preço Médio'
                      register={register('preco_medio_m2')}
                      error={errors ? errors.preco_medio_m2?.message : ''}
                      onValueChange={(e) => {
                        setValue('preco_medio_m2', e.floatValue)
                      }}
                    />
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    <FormInputCurrencyFormat
                      control={control}
                      label='VGV (R$)'
                      defaultValue={data.GetEmpreendimento.vgv}
                      className='form-control text-right'
                      placeholder='VGV'
                      register={register('vgv')}
                      error={errors ? errors.vgv?.message : ''}
                      onValueChange={(e) => {
                        setValue('vgv', e.floatValue)
                      }}
                    />
                  </div>
                </div>
                <div className='row py-2'>
                  <div className='col-md-4'>
                    <FormInputAndLabel
                      label='Arquiteto'
                      defaultValue={data.GetEmpreendimento.arquiteto}
                      className='form-control'
                      placeholder='Arquiteto'
                      register={register('arquiteto')}
                      error={errors ? errors.arquiteto?.message : ''}
                    />
                  </div>
                  <div className='col-md-4'>
                    <FormInputAndLabel
                      label='Responsável'
                      defaultValue={data.GetEmpreendimento.responsavel}
                      className='form-control'
                      placeholder='Responsável'
                      register={register('responsavel')}
                      error={errors ? errors.responsavel?.message : ''}
                    />
                  </div>
                  <div className='col-md-4'>
                    <label className='col-form-label'>Tags </label>

                    <GetEmpreendimentoTagsCreatableSelect
                      defaultValue={
                        data.GetEmpreendimento.tag ? data.GetEmpreendimento.tag.split(',') : []}
                      onValueChange={(tagsResult) => {
                        setValue('tag', tagsResult)
                      }}
                      register={register('tag')}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <FormInputAndLabel
                      label='Nome Parceiro'
                      defaultValue={data.GetEmpreendimento.parceiro_nome}
                      className='form-control'
                      placeholder='Nomes dos Paceiros'
                      register={register('parceiro_nome')}
                      error={errors ? errors.parceiro_nome?.message : ''}
                    />
                  </div>
                  <div className='col-md-4'>
                    <FormInputPercentFormat
                      control={control}
                      label=' Parceiro %'
                      defaultValue={data.GetEmpreendimento.parceiro_percentual}
                      className='form-control text-right'
                      placeholder='Parceiro % '
                      register={register('parceiro_percentual_proprio', { required: false, valueAsNumber: true })}
                      error={errors ? errors.parceiro_percentual?.message : ''}
                      onValueChange={(e) => {
                        setValue('parceiro_percentual', e.floatValue)
                      }}
                    />

                  </div>
                  <div className='col-md-4'>

                    <FormInputPercentFormat
                      control={control}
                      label=' Parceiro % próprio'
                      defaultValue={data.GetEmpreendimento.parceiro_percentual_proprio}
                      className='form-control text-right'
                      placeholder='Parceiro % próprio'
                      register={register('parceiro_percentual_proprio', { required: false, valueAsNumber: true })}
                      error={errors ? errors.parceiro_percentual_proprio?.message : ''}
                      onValueChange={(e) => {
                        setValue('parceiro_percentual_proprio', e.floatValue)
                      }}
                    />


                  </div>
                </div>
                <div className='row pt-6'>
                  <div className='col-xs-12 d-flex all-end'>
                    {data.GetEmpreendimento.situacao_id !== 1 ?
                      <button
                        type='button'
                        className='btn btn-round btn-info me-4'
                        disabled={LoadingDelete ? true : false}
                        onClick={(e) => {
                          confirmRecovery(1)
                        }}
                      >
                        Recuperar Empreendimento
                      </button>
                      :
                      <button
                        type='button'
                        className='btn btn-round btnSecondary me-4'
                        disabled={LoadingDelete ? true : false}
                        onClick={(e) => {
                          confirmDelete(data.GetEmpreendimento.id)
                        }}
                      >
                        Descartar empreendimento
                      </button>
                    }

                    <button
                      type='submit'
                      className='btn btn-round btn-success'
                      disabled={loading}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
