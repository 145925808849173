import {gql} from '@apollo/client'

// Busca de todos as Situações de terreno pelo ID do usuario
export const FIND_EXPLORER_LAYER_USUARIO = gql`
  query GetExplorerLayersUsuario($idMunicipio: Float) {
    GetExplorerLayersUsuario(idMunicipio: $idMunicipio) {
      descricao
      zoom_maximo
      uuid
      url
      tile
      id
      is_favorite
      layout
      nacional
      nome
      nota_tecnica
      paint
      schema_table
      source_layer
      source
      type
      descricao_completa
      destaque
      id_municipio
      icon
      composite
      label
      explorer_layer_tags {
        explorer_tags {
          tag
          id
        }
      }
      explorer_tiles {
        id
        id_layer
        key_column
        label
        id_column
        uuid
        type_data
        table_name
        select_columns
        step
        table_column
        description
        data_return
        situacao
      }
    }
  }
`

export const GET_EXPLORER_LAYER_USER = gql`
  query GetExplorerLayerUser($filtro: filter, $pagination: Pagination) {
    GetExplorerLayerUser(filtro: $filtro, pagination: $pagination) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        id
        uuid
        user_id
        cod_plano
        data_inicio
        data_fim
        id_layer
        id_user
        status
        users {
          id
          name
        }
        produto {
          id
          nome
        }
        explorer_layer {
          id
          nome
        }
        is_favorite
      }
    }
  }
`
