import {ModalAvm} from '../../../../../components/UI/Modals/modal-avm'
import {AvmLancamentos} from '../../../../../components/UI/Modals/set-avm/ModalSetAVM'
import {ImovelResponse} from '../../../../../graphql'
import {Endereco} from '../../../../inteligencia-mercado/components/Types'
import {TableAvaliacoesImovel} from './partials/TableAvaliacoesImovel'

type Props = {
  data: ImovelResponse
  param: string
}

export const TabAvaliacoes: React.FC<Props> = ({data, param}) => {
  const teste = ''

  let endereco: Endereco = {
    latitude: null,
    longitude: null,
    endereco_completo: null,
    logradouro: null,
    numero: null,
    bairro: null,
    cidade: null,
    estado: null,
    cep: null,
  }

  if (data) {
    endereco = {
      latitude: data.GetImovel.latitude,
      longitude: data.GetImovel.longitude,
      endereco_completo: data.GetImovel.endereco + ', ' + data.GetImovel.numero,
      logradouro: data.GetImovel.endereco,
      numero: String(data.GetImovel.numero),
      bairro: data.GetImovel.bairro,
      cidade: data.GetImovel.cidade,
      estado: data.GetImovel.estado,
      cep: data.GetImovel.cep,
    }
  }

  return (
    <div
      className={`tab-pane fade ${param === 'avaliacoes' ? 'show active' : ''}`}
      id='avaliacoes'
      role='tabpanel'
      aria-labelledby='avaliacoes'
    >
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Avaliações</h3>
          <div className='card-toolbar'>
            {/* <button type="button" className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#kt_modal_set_avm">
              AVM Usados
            </button>
            <SetAvaliacoesImovel/> */}
            {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_avm">
              AVM Lançamentos
            </button> */}

            <ModalAvm
              endereco={endereco}
              origem={'imovel'}
              idTerreno={data.GetImovel.id}
            ></ModalAvm>

            {/* <AvmLancamentos lat={data.GetImovel.latitude} origem="imovel" lng={data.GetImovel.longitude} idTerreno={data.GetImovel.id} ></AvmLancamentos> */}
          </div>
        </div>
        <div className='card-body p-6 pt-5'>
          <TableAvaliacoesImovel idImovel={data.GetImovel.id} />
        </div>
      </div>
    </div>
  )
}
