import { TableDocumentos } from './partials/Table-Documentos'
import { Card4 } from '../../../../../../_metronic/partials/content/cards/Card4'

type Props = {
  param: string
}

export function TabDocumentos({param}: Props) {
  return (
    <div 
    className={`tab-pane fade ${param === 'documentos' ? 'show active' : ''}`}
    id='documentos' role='tabpanel' aria-labelledby='documentos'>
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Documentos</h3>
          <div className='card-toolbar'>
            <button
              className='btn btnSuccess btn-round'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_add_terreno_observacao'
              data-bs-offset='0,5'
              data-bs-dismiss-='click'
              style={{ color: 'white' }}
            >
              {/*begin::Svg Icon | path: icons/duotune/general/gen005.svg*/}
              <span className='svg-icon btn-icon svg-icon-4 ms-2'>
                +<span className='btn-label pd-left-1'>Nova Pasta</span>
              </span>
              {/*end::Svg Icon*/}
            </button>
          </div>
        </div>
        <div className='card-body p-0 pt-5'>
          <div className="row ">
            <div className='row mb-4'>
              <div className='col-12 col-sm-4 col-xl-2'>
                <Card4
                  icon='/media/svg/files/folder-document.svg'
                  title='Finance'
                  description='7 files'
                />
              </div>
              <div className='col-12 col-sm-4 col-xl-2'>
                <Card4
                  icon='/media/svg/files/folder-document.svg'
                  title='Customers'
                  description='3 files'
                />
              </div>
              <div className='col-12 col-sm-4 col-xl-2'>
                <Card4
                  icon='/media/svg/files/folder-document.svg'
                  title='CRM Project'
                  description='25 files'
                />
              </div>
              <div className='col-12 col-sm-4 col-xl-2'>
                <Card4
                  icon='/media/svg/files/folder-document.svg'
                  title='CRM Project'
                  description='25 files'
                />
              </div>
              <div className='col-12 col-sm-4 col-xl-2'>
                <Card4
                  icon='/media/svg/files/folder-document.svg'
                  title='CRM Project'
                  description='25 files'
                />
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <TableDocumentos />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
