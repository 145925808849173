import React from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { MutationDeleteImovelSituacaoCliente } from '../../../../graphql/services/ImovelSituacaoCliente'
import Swal from 'sweetalert2'
import { MutationSetLogAcessoRecurso } from '../../../../graphql/services/LogAcesso'

type Props = {
  id: number
  setIdEdit?: any
}

export const Buttons: React.FC<Props> = ({ id, setIdEdit }) => {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()
  const { HandleDeleteImovelSituacaoCliente, loading } = MutationDeleteImovelSituacaoCliente()
  if (loading) {
    Swal.fire('Enviando Informações...', '')
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteImovelSituacaoCliente({
          variables: { deleteImovelClienteId: id },
        })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'situacao-terrenos', action: 'delete', url: '/cadastro/situacao-terrenos' });
          Swal.fire('Sucesso!', 'Situação deletada com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          className='btn btn-icon btn-light-success btn-sm me-2'
          onClick={(e) => {
            setIdEdit(id)
          }}
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button>
        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          disabled={loading}
          className='btn btn-icon btn-light-danger btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
