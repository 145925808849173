import z from 'zod'

export const setPacoteFormSchema = z.object({
    nome: z.string()
    .nonempty('O código é obrigatório!'),
    descricao: z.string()
    .nonempty('O código é obrigatório!'),
    id_produto: z.number().optional(),
    idServicos:  z.array(z.unknown()),
})

export type setPacoteFormData = z.infer<typeof setPacoteFormSchema>

export const putPacoteFormSchema = z.object({
    id: z.number(),
    nome: z.string(),
    descricao: z.string(),
    id_produto: z.number().optional(),
    idServicos:  z.array(z.number()),

})

export type putPacoteFormData = z.infer<typeof putPacoteFormSchema>
