
export function Statics() {
    return (
        <div>
            {/* <h1 className="mt-6">Estatisticas</h1>
            <hr />
            <div className='row g-5 g-xl-8'>
                <div className='col-xl-6'>
                    <ChartsWidget5 className='card-xl-stretch mb-xl-8' />
                </div>
                <div className='col-xl-6'>
                    <ChartsWidget6 className='card-xl-stretch mb-5 mb-xl-8' />
                </div>
            </div>
            <div className='row g-5 g-xl-8'>
                <div className='col-xl-6'>
                    <ChartsWidget7 className='card-xl-stretch mb-xl-8' />
                </div>
                <div className='col-xl-6'>
                    <ChartsWidget8 className='card-xl-stretch mb-5 mb-xl-8' />
                </div>
            </div> */}
        </div>
    )
}