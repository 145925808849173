import {KTSVG} from '../../../../../_metronic/helpers'

export function ModalAction() {
  return (
    <>
      <div
        className='modal fade'
        id='modalDetailsPedidos'
        aria-hidden='true'
        aria-labelledby='modalDetailsPedidos'
      >
        <div className='modal-dialog modal-lg modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='modalDetailsPedidos'>
                Detalhes do Pedido: Relatório técnico do lote
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body text-center'>
              <h2>Kolx Engenharia e Software</h2>
              <h3> R. Dr. Antônio Bento, 746-Santo Amaro, São Paulo - SP, 04750-001</h3>

              <div className='row all-center'>
                <div className='col-4'>
                  <h3 className='m-0 mt-2'>Whatsapp:</h3>
                  <button className='btn btn-primary mt-1'>
                    <KTSVG path='/media/whatsapp.svg' className='svg-icon-3' /> (11) 94813-4631
                  </button>
                </div>
                <div className='col-4'>
                  <h3 className='m-0 mt-2'>Email:</h3>
                  <a
                    className='btn btn-primary mt-1'
                    href='mailto:contato@kolx.com.br?subject=Pedido #2131&body=Pedito #2135'
                  >
                    <KTSVG path='/media/mail.svg' className='svg-icon-3' />
                    contato@kolx.com.br
                  </a>
                </div>
              </div>
              <div className='accordion mt-6' id='accordionPanelsStayOpenExample'>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='panelsStayOpen-headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#panelsStayOpen-collapseOne'
                      aria-expanded='true'
                      aria-controls='panelsStayOpen-collapseOne'
                    >
                      Dados para NF
                    </button>
                  </h2>
                  <div
                    id='panelsStayOpen-collapseOne'
                    className='accordion-collapse collapse show'
                    aria-labelledby='panelsStayOpen-headingOne'
                  >
                    <div className='accordion-body'>
                      <div className='row'>
                        <div className='col-6'>
                          <h3>Emitido para Nome:</h3>
                          <p>Jose Mauro Veçoso</p>
                        </div>
                        <div className='col-6'>
                          <h3> Razão Social:</h3>
                          <p> Jose Mauro Veçoso</p>
                        </div>
                        <div className='col-6'>
                          <h3>Telefone:</h3>
                          <p>(11) 95901-5244</p>
                        </div>
                        <div className='col-6'>
                          <h3>Email:</h3>
                          <p> mauro@ideabr.com.br</p>
                        </div>
                        <div className='col-6'>
                          <h3>Tipo Pessoa:</h3>
                          <p>Pessoa Fisica</p>
                        </div>
                        <div className='col-6'>
                          <h3>Documentos:</h3>
                          <p>25868240839</p>
                        </div>
                        <div className='col-6'>
                          <h3>CEP:</h3>
                          <p>Não Informado</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='panelsStayOpen-headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#panelsStayOpen-collapseTwo'
                      aria-expanded='false'
                      aria-controls='panelsStayOpen-collapseTwo'
                    >
                      Lote
                    </button>
                  </h2>
                  <div
                    id='panelsStayOpen-collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='panelsStayOpen-headingTwo'
                  >
                    <div className='accordion-body'>
                      <div className='row'>
                        <div className='col-6'>
                          <h3> Situação:</h3>
                          <p> Aprovado</p>
                        </div>
                        <div className='col-6'>
                          <h3>Dados do pedido:</h3>
                          <p>ID 1647</p>
                        </div>
                        <div className='col-6'>
                          <h3>Data solicitação:</h3>
                          <p>26/04/2023</p>
                        </div>
                        <div className='col-6'>
                          <h3>Valor:</h3>
                          <p>R$ 60.00</p>
                        </div>
                        <div className='col-6'>
                          <h3>Quantidade:</h3>
                          <p>1 UND</p>
                        </div>
                        <div className='col-6'>
                          <h3>Produto:</h3>
                          <p>Ficha Completa</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='modal-footer all-center'>
                <button type='button' className='btn btnSecondary me-4' data-bs-dismiss='modal'>
                  Fechar
                </button>
                <button className='btn btn-primary'> Imprimir</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
