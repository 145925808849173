import { z } from "zod"

export const CondicaoComercialCreateFormSchema = z.object({
    id_terreno: z.number(),
    dinheiro_valor: z.number(),
    permuta_fisica_pecentual: z.number(),
    permuta_financeira_percentual: z.number(),
    valor_total: z.number(),
    observacoes: z.string(),

})

export type CondicaoComercialCreateFormData = z.infer<typeof CondicaoComercialCreateFormSchema>

export const CondicaoComercialPutFormSchema = z.object({
    id: z.number(),
    id_terreno: z.number(),
    dinheiro_valor: z.number(),
    permuta_fisica_pecentual: z.number(),
    permuta_financeira_percentual: z.number(),
    valor_total: z.number(),
    observacoes: z.string(),

})

export type CondicaoComercialPutFormData = z.infer<typeof CondicaoComercialPutFormSchema>