import {Text, View, StyleSheet} from '@react-pdf/renderer'
import {useEffect, useState} from 'react'
import {Dicionario} from '../../../../../utils/Dicionario'
import ReactHtmlParser from 'react-html-parser'
import {ApiUrbit} from '../../../../../../services/http/urbit'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#f4f4f4',
    borderBottomWidth: 1,
    paddingBottom: 10,
    alignItems: 'flex-start',
    fontSize: 13,
    marginTop: 10,
    lineHeight: 1.3,
    backgroundColor: '#fff',
    borderRadius: 0,
    paddingLeft: 0,
    paddingTop: 5,
  },
  logo: {
    width: 200,
  },
  sobre: {
    marginTop: 10,
    marginRight: 5,
    textAlign: 'right',
    paddingBottom: 2,
    fontSize: 8,
    width: 520,
  },
  nomeEmpresa: {
    flexDirection: 'column',
    textAlign: 'right',
    paddingBottom: 2,
    fontSize: 13,
  },
  space: {
    paddingBottom: 2,
  },
  h1: {
    fontSize: 18,
  },
  h2: {
    fontSize: 16,
  },
  h3: {
    fontSize: 14,
  },
  h4: {
    marginTop: 6,
    fontSize: 12,
  },
  h5: {
    fontSize: 10,
  },
  h6: {
    marginTop: 6,
    fontSize: 11,
  },
  p: {
    fontSize: 9,
  },
  col1: {
    fontSize: 9,
    width: 400,
  },
  spaceLeft: {
    marginLeft: -3,
  },
  col100: {
    fontSize: 9,
    width: 520,
    marginLeft: 0,
    paddingLeft: 0,
  },
  col2: {
    width: 100,
  },
  erro: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 12,
    fontWeight: 'bold',
  },
})

function unicodeToChar(text) {
  return text.replace(/\\u[\dA-F]{4}/gi, function (match) {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
  })
}

export const RenderListElement = ({url, lat, lng, municipio, distancia}) => {
  const [result, setResult] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const data = await ApiUrbit.getServico(url, lat, lng, municipio, distancia)
      setResult(data)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!result) {
    return ''
  }

  if (typeof result.message != 'undefined') {
    return <Text style={styles.erro}>Erro ao carregar o serviço</Text>
  } else if (result['itens'].length === 0) {
    return <Text style={styles.erro}>Nada Consta</Text>
  } else {
    return result['itens'].length > 0
      ? result['itens'].map((item: any, y: number) => {
          return (
            <View style={styles.row} key={y}>
              <View style={item['distancia'] ? styles.col1 : styles.col100}>
                {Array.isArray(item) && item.length === 0 ? (
                  <Text style={styles.h6}>Nada Consta</Text>
                ) : (
                  ''
                )}
                {Array.isArray(item) && item.length === 1
                  ? // eslint-disable-next-line array-callback-return
                    Object.keys(item[0]).map(function (key, index) {
                      if (
                        key !== 'id' &&
                        key !== 'gid' &&
                        key !== 'geom' &&
                        key !== 'distancia' &&
                        key !== 'geom_closest_point'
                      ) {
                        return (
                          <Text style={styles.h6}>
                            {Dicionario.translate(key)} :
                            {isNaN(item[0][key])
                              ? ReactHtmlParser(unicodeToChar(item[0][key]))
                              : ReactHtmlParser(item[0][key])}
                            asdasd
                          </Text>
                        )
                      }
                    })
                  : Object.keys(item).length > 1
                  ? // eslint-disable-next-line array-callback-return
                    Object.keys(item).map(function (list: any, indexList: number) {
                      if (list !== 'geom' && list !== 'gid' && list !== 'geom_closest_point') {
                        return (
                          <Text style={[styles.h6, styles.spaceLeft]} key={indexList}>
                            <Text style={{fontWeight: 600}}> {Dicionario.translate(list)}: </Text>
                            {isNaN(item[list])
                              ? ReactHtmlParser(unicodeToChar(item[list]))
                              : ReactHtmlParser(item[list])}
                          </Text>
                        )
                      }
                    })
                  : ''}
              </View>

              {item['distancia'] ? (
                <View style={styles.col2}>
                  <Text style={styles.h6}>
                    {Number(item['distancia']) > 1000
                      ? Number(item['distancia']) / 1000 + ' km'
                      : Number(item['distancia']) + ' metros'}
                  </Text>
                </View>
              ) : (
                ''
              )}
            </View>
          )
        })
      : ''
  }
}
