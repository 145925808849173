import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
// import "./styles.css";

type Props = {
  idTipo: number
    onChange?:any
  }
  
export const SelectFinalidadeLancamento: React.FC<Props> = ({idTipo, onChange}) => {
  const options = [
    { label: "Comercial", value: "Comercial" },
    { label: "Residencial", value: "Residencial" },
  ];
 
  const [selected, setSelected] = useState([]);

  return (
    <>
      <MultiSelect
        options={options}
        value={selected}
        onChange={(e)=>{
          let valor = []
          e.map(function(v){
            valor.push(v.value)
            return valor
          })
          onChange(valor)
          setSelected(e)
        }}
        labelledBy={"Finalidade"}
        isCreatable={false}
      />
    </>
  );
}
