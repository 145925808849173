import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ModalAssociationProduto} from './AssociationProduto'
// import { FaBuildingCircleCheck, FaBuildingCircleXmark } from 'react-icons/fa6';
import {MutationDeleteEmpresa, MutationRecoveryEmpresa} from '../../../graphql/services/Empresas'
import Swal from 'sweetalert2'
import {BiBuilding} from 'react-icons/bi'
type Props = {
  id: number
  situacao: number
}

export const Buttons: React.FC<Props> = ({id, situacao}) => {
  const {HandleDeleteEmpresa, loading} = MutationDeleteEmpresa()
  const {HandleRecoveryEmpresa} = MutationRecoveryEmpresa()

  function confirmDelete(id) {
    Swal.fire({
      title: 'Deseja desativar?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const result = await HandleDeleteEmpresa({variables: {deleteEmpresaId: id}})
        if (result) {
          Swal.fire('Sucesso!', 'Empresa desativada com sucesso.', 'success')
        }
      }
    })
  }

  function confirmRecovery(id) {
    Swal.fire({
      title: 'Deseja reativar?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      showCloseButton: true,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const result = await HandleRecoveryEmpresa({
          variables: {
            data: {
              id: id,
              situacao: 1,
            },
          },
        })
        if (result) {
          Swal.fire('Sucesso!', 'Empresa reativada com sucesso.', 'success')
        }
      }
    })
  }
  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <Link
          to={`/backoffice/empresas/edit/${id}`}
          className='btn btn-icon btn-light-success me-2'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
        {situacao === 1 ? (
          <button
            disabled={loading}
            onClick={() => confirmDelete(id)}
            className='btn btn-icon btn-light-danger me-1'
          >
            {/* <FaBuildingCircleXmark size={20} /> */}
            <BiBuilding size={20}></BiBuilding>
          </button>
        ) : (
          <button
            disabled={loading}
            onClick={() => confirmRecovery(id)}
            className='btn btn-icon btn-light-warning me-1'
          >
            <BiBuilding size={20} />
          </button>
        )}
      </div>
    </>
  )
}
