import { useState } from 'react'
import { ImovelResponse } from '../../../../../graphql'
import { TableProrietariosImovel } from './partials/partials/TableProrietariosImovel'

type Props = {
  data: ImovelResponse
  param: string
}

export const TabProprietarios: React.FC<Props> = ({ data, param }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateList, setUpdateList] = useState(false)
  return (
    <div
      className={`tab-pane fade ${param === 'proprietarios' ? 'show active' : ''}`}
      id='proprietarios'
      role='tabpanel'
      aria-labelledby='proprietarios'
    >
      <TableProrietariosImovel data={data} id={data.GetImovel.id} setUpdateList={setUpdateList} />
    </div>
  )
}
