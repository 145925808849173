import {useQuery} from '@apollo/client'
import React, {InputHTMLAttributes, useEffect, useState} from 'react'
import Select from 'react-select'
import {GET_IMOVEL_SITUACAO_CLIENTE} from '../../../../graphql/query/imovel-situacao-cliente'
import {TypesGetImovelSituacaoClienteResponse} from '../../../../graphql/type/imovel-situacao-cliente'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  defaultValue: number
  register?: any
  error?: any
  onValueChange: any
}

export function GetSituacaoClienteChosen({
  defaultValue,
  register,
  onValueChange,
  error,
  ...props
}: InputProps) {
  
  let options = []
  const [status, setStatus] = useState(defaultValue)
  const {data} = useQuery<TypesGetImovelSituacaoClienteResponse>(GET_IMOVEL_SITUACAO_CLIENTE, {
    variables: {
      pagination: {
        quantidade: null,
        pagina: null,
      },
    },
  })

  useEffect(() => {
    onValueChange(status)
  }, [onValueChange, status])

  // Tratativa de loading
  if (!data) {
    return (
      <Select
        options={options}
        onChange={(e) => {
            setStatus(e.value)
        }}
        isDisabled={false}
        placeholder='Situação do Terreno'
      />
    )
  }
  options.push({value:'', label: '--selecione--'})
  data.GetImovelSituacaoCliente.result.map((Options) => {
    options.push({value: Options.id, label: Options.nome})
    return []
  })
  const value = options.filter((e) => {
    return e.value === defaultValue
  })[0]

  return (
    <Select
      menuPortalTarget={document.body}
      // styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      options={options}
      value={value}
      name='status-cliente'
      onChange={(e) => {
        setStatus(e.value)
        // onValueChange(e.value)
      }}
      placeholder='Tipos do Terreno'
    />
  )
}
