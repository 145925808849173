import React from 'react';


import { QueryGetAvmLeadByImovelId } from '../../../../../../graphql/services/AVM'
import { Buttons } from './Buttons'
import { formatNumber } from '../../../../../explorer/components/common';

type Props = {
    idImovel: number;
}
export const TableAvaliacoesImovel: React.FC<Props> = ({ idImovel }) => {

    const { data: dataLead } = QueryGetAvmLeadByImovelId({
        variables: {
            idImovel: idImovel
        }
    })

    if (!dataLead) {
        return (
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bolder text-muted'>
                                <th className='min-w-50px'>Tipo de Avalição</th>
                                <th className='min-w-50px'>Quartos/Suítes</th>
                                <th className='min-w-50px'>Banheiros</th>
                                <th className='min-w-50px'>Vagas</th>
                                <th className='min-w-50px'>Area</th>

                                <th className='min-w-150px'>Valor Max</th>
                                <th className='min-w-100px '>Valor Med</th>
                                <th className='min-w-100px '>Valor Min</th>
                                <th className='min-w-100px text-end'>Ações</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}

                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
        )
    }

    return (
        <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-striped gs-7'>
                {/* begin::Table head */}
                <thead>
                    <tr className='fw-bolder text-muted'>
                        <th className='min-w-50px'>Tipo de Avalição</th>
                        <th className='min-w-50px'>Quartos/Suítes</th>
                        <th className='min-w-50px'>Banheiros</th>
                        <th className='min-w-50px'>Vagas</th>
                        <th className='min-w-50px'>Area</th>
                        <th className='min-w-150px'>Valor Max</th>
                        <th className='min-w-100px '>Valor Med</th>
                        <th className='min-w-100px '>Valor Min</th>
                        <th className='min-w-100px text-end'>Ações</th>
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                    {dataLead.GetAvmLeadByImovelId.map(Response => {

                        return (
                            <tr key={Response.id}>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex justify-content-start flex-column'>
                                            <p
                                                className='text-dark  fs-6'
                                            >
                                                {Response.id_tipo_imovel === 2 ? 'Apartamento' : ''}
                                                {Response.id_tipo_imovel === 6 ? 'Studio' : ''}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className='text-dark  d-block fs-6'>
                                        {Response.quartos}/{Response.suites}

                                    </p>
                                </td>
                                <td>
                                    <p className='text-dark  d-block fs-6'>
                                        {Response.banheiros}

                                    </p>
                                </td>
                                <td>
                                    <p className='text-dark  d-block fs-6'>
                                        {Response.vagas}

                                    </p>
                                </td>
                                <td>
                                    <p className='text-dark  d-block fs-6'>
                                        {Response.area}

                                    </p>
                                </td>

                                <td>
                                    <p className='text-dark  d-block fs-6'>
                                        {formatNumber(Response.result_maximo, 'currency')}

                                    </p>
                                </td>
                                <td>
                                    <p className='text-dark  d-block fs-6'>
                                        {formatNumber(Response.result_medio, 'currency')}
                                    </p>
                                </td>
                                <td>
                                    <p className='text-dark  d-block fs-6'>
                                        {formatNumber(Response.result_minimo, 'currency')}
                                    </p>
                                </td>
                                <td>
                                    <Buttons id={Response.id} />
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
                {/* end::Table body */}
            </table>
            {/* end::Table */}
        </div>
    )
}


