import BuscaAutocomplete from './BuscaAutocomplete'
import BuscaSql from './BuscaSql'

const Busca = () => {
  return (
    <div
      style={{
        // boxSizing: `border-box`,
        // border: `1px solid #d0d0d0`,
        // padding: `10px 19px`,
        margin: '10px 13px',
        position: 'absolute',
        // width: '33%',
        zIndex: 9,
        borderRadius: `5px`,
        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3) !important`,
        fontSize: `17px`,
        textOverflow: `ellipses`,
      }}
      className=''
    >
      <BuscaAutocomplete></BuscaAutocomplete>
      <BuscaSql></BuscaSql>
    </div>
  )
}

export default Busca
