import {gql} from '@apollo/client'

export const ADMIN_GET_PRODUTOS = gql`
  query GetProdutos($filtro: filter, $pagination: Pagination) {
    GetProdutos(filtro: $filtro, pagination: $pagination) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        nome
        ordem
        tipo_produto
        produto_id
        valor
        loja_id
        id
        descricao
        desconto
      }
    }
  }
`

export const ADMIN_GET_USUARIOS_PRODUTOS = gql`
  query GetUsuariosProdutos {
    GetUsuariosProdutos {
      email
      id
      name
      telefone
      user_produto {
        id
        produto {
          valor
          tipo_produto
          produto_id
          ordem
          nome
          loja_id
          id
          descricao
          desconto
        }
        id_produto
        id_user
        uuid
      }
      cpf
      cnpj
      id_area_atuacao
      tipo_pessoa
      uuid
    }
  }
`

export const ADMIN_GET_PRODUTO_BY_ID = gql`
  query GetProdutosById($getProdutosByIdId: Float!) {
    GetProdutosById(id: $getProdutosByIdId) {
      descricao
      id
      nome
      tipo_produto
      valor
      desconto
    }
  }
`
