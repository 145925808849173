import React, { useEffect, useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading'

import secureLocalStorage from 'react-secure-storage';
import { TypesGetEmpresaByIDResponse } from '../../../../graphql/types/TypesEmpresas';
import { UploadImageCompany } from '../../../../../../services/api';
import { FaSyncAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

type Props = {
  data: TypesGetEmpresaByIDResponse
}

export const CardHeaderNavigation: React.FC<Props> = ({ data }) => {
  const { id: IdEmpresa } = useParams<{ id: string }>()

  const [images, setImages] = useState(null)
  const [imagesP, setImagesP] = useState(data?.GetEmpresaById?.user_logo_pic || '');
  const [isLoading, setIsLoading] = useState(true);

  const empresaData: any = secureLocalStorage.getItem('empresa') || null
  console.log(data && data.GetEmpresaById?.user_logo_pic ? data.GetEmpresaById.user_logo_pic : '')
  let dadosEmpresa = null
  if (empresaData) {
    dadosEmpresa = JSON.parse(empresaData)
  }

  useEffect(() => {
    if (data && data.GetEmpresaById && data.GetEmpresaById.user_logo_pic) {
      const loadImage = new Image();
      loadImage.src = data.GetEmpresaById.user_logo_pic;
      loadImage.onload = () => {
        setImagesP(data.GetEmpresaById.user_logo_pic);
        setIsLoading(false);
      };
    } else {
      setIsLoading(false);
    }
  }, [data]);

  const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    imageList.forEach(async (item) => {
      let returnData = await UploadImageCompany(item.dataURL, IdEmpresa)
      console.log(item.dataURL)

      setImages(returnData)
      setImagesP(returnData)

    })
  }

  if (!data) {
    return (
      <>
        <div className=" mb-5 mt-6 mb-xl-10">

        </div>
      </>
    )
  }


  return (
    <div className="card mb-5  mt-6 mb-xl-10">
      <div className="card-body pt-0 pb-0">
        <div className="row">
          <div className="col-12">
            <div className="card-header pt-0 w-100 all-center-between">
              <div className="w-100 py-6 m-0 all-center-between">
                <h3 className="fw-bolder m-0">Detalhes da Empresa</h3>
                <div>
                  <div className="logo-company position-relative">
                    <ImageUploading
                     value={images} onChange={onChange} maxNumber={1}>
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        <>
                          {imagesP !== null && imagesP !== '' ? <img src={imagesP !== null ? imagesP : ''} alt="user" className='logo' />
                            : <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" style={{ fill: "rgba(0, 0, 0, 1)", "transform": "msFilter" }}><path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"></path></svg>}

                          <button
                            className="position-absolute mb-2 all-center translate-middle start-100 btn btnSuccess rounded-circle p-2 px-3"
                            // data-bs-toggle="modal" data-bs-target="#kt_modal_add_image_upload"
                            onClick={onImageUpload}
                            {...dragProps}
                            style={{
                              ...isDragging ? { color: 'red' } : {},
                              top: 80
                            }}
                            
                          >
                            <span className="svg-icon-primary" style={{ margin: 0 }}>
                              <FaSyncAlt size={18} />
                            </span>
                          </button>
                        </>

                      )}
                    </ImageUploading>

                  </div>
                </div>
              </div>

            </div>
            <div className="card-body w-100 px-9">
              <div className="row">
                <div className="col-6">
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Nome</label>
                    <div className="col-lg-8">
                      <span className="fw-bolder fs-6 text-gray-800">{data.GetEmpresaById.nome ? data.GetEmpresaById.nome : 'Não Informado'}</span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">CNPJ</label>
                    <div className="col-lg-8 d-flex align-items-center">
                      <span className="fw-bolder fs-6 text-gray-800 me-2">{data.GetEmpresaById.cnpj ? data.GetEmpresaById.cnpj : 'Não Informado'}</span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">CEP</label>
                    <div className="col-lg-8">
                      <span className="fw-bolder fs-6 text-gray-800">{data.GetEmpresaById.cep ? data.GetEmpresaById.cep : 'Não Informado'}</span>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Nome Fantasia</label>
                    <div className="col-lg-8">
                      <span className="fw-bolder fs-6 text-gray-800">{data.GetEmpresaById.nome_fantasia ? data.GetEmpresaById.nome_fantasia : 'Não Informado'}</span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Telefone
                      <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Phone number must be active" aria-label="Phone number must be active"></i></label>
                    <div className="col-lg-8 d-flex align-items-center">
                      <span className="fw-bolder fs-6 text-gray-800 me-2">{data.GetEmpresaById.telefone ? data.GetEmpresaById.telefone : 'Não Informado'}</span>
                      {/* <span className="badge badge-success">Verified</span> */}
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">E-mail</label>
                    <div className="col-lg-8">
                      <span className="fw-bolder fs-6 text-gray-800">{data.GetEmpresaById.email ? data.GetEmpresaById.email : 'Não Informado'}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <ul className="nav nav-map-info" id="tab" role="tablist">
          <li className="nav-item " role="presentation">
            <button className="nav-link text-active-primary ms-0 me-10 py-1 active" id="tab1-tab" data-bs-toggle="pill" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab2" aria-selected="false">Visão geral</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link text-active-primary ms-0 me-10 py-1" id="tab2-tab" data-bs-toggle="pill" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab4" aria-selected="false">Funcionários</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link text-active-primary ms-0 me-10 py-1" id="tab3-tab" data-bs-toggle="pill" data-bs-target="#tab3" type="button" role="tab" aria-controls="tab3" aria-selected="false">Produtos</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link text-active-primary ms-0 me-10 py-1" id="tab-logs-tab" data-bs-toggle="pill" data-bs-target="#tab-logs" type="button" role="tab" aria-controls="tab-logs" aria-selected="false">Logs</button>
          </li>
        </ul>
      </div>
    </div>
  )
}