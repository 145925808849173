import { Offcanvas } from "react-bootstrap"
import { ClickInfoLancamento } from "../../explorer/components/Map/LayoutControl/ClickInfo/ClickInfoLancamentos"
import Scrollbars from "react-custom-scrollbars-2"


type PropsContextMenu = {
  setShow:(show)=>void
  show:any
  height:any
}
export const MaisInfoEmpreendimentos: React.FC<PropsContextMenu> = ({show,setShow,height}) =>{

return (<Offcanvas show={show.show} className={ `w-60 zIndex-99 `}  tabIndex={null}  onHide={()=>{
        setShow({
          show:false,
          id:null
        })
      }} id='offcanvas-direita'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className=''>Informações do Empreendimento</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
      
          <Scrollbars style={{width: '100%', height: height}}>
          
             
              {show.show ? <ClickInfoLancamento id_empreendimento={show.id } ></ClickInfoLancamento> :<></>}
      
          </Scrollbars>
        
        </Offcanvas.Body>
      </Offcanvas>)

    }