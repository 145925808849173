import { useMutation, useQuery } from "@apollo/client"
import { TypesDeleteEmpreendimentoImovel, TypesGetEmpreendimentoImovelResponse,  } from "../type"
import { DELETE_EMPREENDIMENTO_IMOVEL } from "../mutations";
import { GET_EMPREENDIMENTO_IMOVEIS } from "../query";



interface Props {
    variables: any;
    skip?:any
  }

export function QueryGetEmpreendimentoImoveis({ variables, skip }: Props) {
    const { data, loading, error } = useQuery<TypesGetEmpreendimentoImovelResponse>(GET_EMPREENDIMENTO_IMOVEIS, {
        variables: { ...variables }
    })

    return { data, loading, error }
}

export function MutationDeleteEmpreendimentoImovel() {
    const [deleteEmpreendimentoImovel, { loading, data, error }] = useMutation<TypesDeleteEmpreendimentoImovel>(
        DELETE_EMPREENDIMENTO_IMOVEL,
        {
            refetchQueries: [GET_EMPREENDIMENTO_IMOVEIS],
        }
    )

    async function HandleDeleteEmpreendimentoImovel({ variables }: Props) {
  
        const result = await deleteEmpreendimentoImovel({
            variables: { ...variables },
            refetchQueries: [GET_EMPREENDIMENTO_IMOVEIS],
        })
        return result
       
    }

    return { HandleDeleteEmpreendimentoImovel, loading, error, data }
}