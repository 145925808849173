import { useEndereco } from "../../../../../../../hooks/useEndereco"
import {Endereco as EnderecoClass} from './endereco'
type PropsContextMenu = {}


export const EnderecoControl: React.FC<PropsContextMenu> = () => {
    const {OnSetEndereco,endereco} = useEndereco()
    EnderecoClass.endereco = endereco
    EnderecoClass.OnSetEndereco = OnSetEndereco
    return (<></>)
}