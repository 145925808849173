import {NavDropdown} from 'react-bootstrap'
// import Swal from 'sweetalert2'
import {Explorer} from '../../../Map/Explorer'
import { confirm } from '../../../../../../components/UI/Confirmation';
import {  confirm as confirmComplex } from '../../../../../../components/UI/ConfirmationInput';
// import withReactContent from 'sweetalert2-react-content'
// const MySwal = withReactContent(Swal)

function DroplistActions({dataId}) {
  const onClickInfo = async  (uuid: string) => {
    const layerInfo = Explorer.getInfoLayer(uuid)
   await confirm("Informações da camada",layerInfo['descricao'],'Fechar' )
  }
  const onClickRemoveLayer = async (uuid: string) => {
    const layerInfo = Explorer.getInfoLayer(uuid)

    if (await confirm('Remover Camada',`Deseja remover a camada ${layerInfo['nome']}`, 'Remover','Fechar')){
      Explorer.removeLayer(uuid)
    }
    // MySwal.fire({
    //   icon: 'warning',
    //   title: `Deseja remover a camada ${layerInfo['nome']}`,
    //   showCancelButton: true,
    //   confirmButtonText: 'OK',
    //   showLoaderOnConfirm: true
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     Explorer.removeLayer(uuid)
    //   }
    // })
  }

  const onClickRenameLayer = async(uuid: string) => {

    confirmComplex({title:"Alterar nome da camada.", message: `Digite o nome da camada.` }).then(
      ({ button, input }) => {
        Explorer.changeNameLayer(uuid, input)
        // this.setState({
        //   message: `${button} button pressed. input: ${input}`
        // });
      },
      () => {
        // this.setState({
        //   message: `canceled!`
        // });
      }
    );

    // MySwal.fire({
    //   title: 'Alterar nome da camada',
    //   input: 'text',
    //   showCancelButton: true,
    //   confirmButtonText: 'OK',
    //   showLoaderOnConfirm: true
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     Explorer.changeNameLayer(uuid, result.value)
    //   }
    // })
  }

  return (
    <>
    <NavDropdown
      id='nav-dropdown-dark-example'
      title='Ações'
      menuVariant='dark'
      style={{padding: '0rem 1rem'}}
    >
      <NavDropdown.Item
        onClick={() => {
          onClickRenameLayer(dataId)
        }}
      >
        <i className='fa bi bi-pen'></i> Renomear
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() => {
          onClickRemoveLayer(dataId)
        }}
      >
        <i className='fa bi bi-trash'></i> Remover
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() => {
          onClickInfo(dataId)
        }}
      >
        <i className='fa bi bi-info'></i> Info
      </NavDropdown.Item>
      {/* <NavDropdown.Divider /> */}
      {/* <NavDropdown.Item>Estisticas</NavDropdown.Item> */}
    </NavDropdown>
     
     </>
  )
}

export default DroplistActions
