import { useMutation, useQuery} from '@apollo/client'
import {GET_EMPRESA_USUARIO, GET_USUARIO_PRODUTOS} from '../query'
import { TypesGetUsuarioProdutosResponse } from '../type'
import { useForm } from 'react-hook-form'
import { putEmpresaUsuarioData, putEmpresaUsuarioSchema } from '../formValidations/User'
import { zodResolver } from '@hookform/resolvers/zod'
import { PUT_EMPRESA_USUARIO } from '../../backoffice/graphql'
import { TypesPutEmpresaUsuario } from '../../backoffice/graphql/types/TypesEmpresas'

interface QueryProps {
  variables: any
}

export function QueryGetUsuarioProdutos({variables}: QueryProps) {
  const {data} = useQuery<TypesGetUsuarioProdutosResponse>(GET_USUARIO_PRODUTOS, {
    variables: {...variables},
  })
  return data
}

export function MutationPutEmpresaUsuario() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<putEmpresaUsuarioData>({
    resolver: zodResolver(putEmpresaUsuarioSchema),
  })

  console.log(errors)

  const [bodyUsuario, { data, loading, error }] =
    useMutation<TypesPutEmpresaUsuario>(PUT_EMPRESA_USUARIO, {
      refetchQueries: [
        GET_EMPRESA_USUARIO
      ],
    })

  async function FormPutEmpresaUsuario(data: putEmpresaUsuarioData) {
    await bodyUsuario({
      variables: {
        data: { ...data },
      }
    })
  }


  return { register, handleSubmit, setValue, FormPutEmpresaUsuario, loading, errors, error, data }
}
