import { useState } from "react";
import RangeSlider from 'react-bootstrap-range-slider'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import { Explorer } from "../../Explorer";


export function Radius({uuid} ) {
  const [sizeBorder, setSizeBorder] = useState(10)

  function onChange(size){
    Explorer.changeStyle(uuid, 'circle-radius', parseInt(size ))
  }
  return (
    <>
      <div className='item-config'>
        <div className='item-config-align'>
          <div   data-bs-toggle='collapse'
              data-bs-target={`#collapseExample_${uuid}_radius`}
              aria-expanded='false'
              aria-controls={`collapseExample_${uuid}_radius`}>
            <div
              className='item-config-align-title'
            
            >
              Radius Size
            </div>
            {/* <label className='switch pull-right'>
              <input type='checkbox' id='text' name='text' />
              <span className='slider round'></span>
            </label> */}
          </div>
          <div
            className='border-left-layers w-100 collapse '
            id={`collapseExample_${uuid}_radius`}
          >
            <div className=''>
            <input
                        className='form-control'
                        type='text'
                        value={sizeBorder}
                        onChange={(changeEvent) => {
                          let val = changeEvent.target.value
                          setSizeBorder( parseInt(val) );
                          onChange(val)
                        }}
                      />
              <RangeSlider
                        value={sizeBorder}
                        onChange={(changeEvent) => {
                            let val = changeEvent.target.value
                            setSizeBorder( parseInt(val) );
                            onChange(val)
                          }
                        }
                        min={1}
                        max={20}
                        step={1}
                      />
                     
                      
             
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
