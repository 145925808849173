import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import { QueryGetCorretores } from '../../../../graphql/services/Corretores'

export function GetCorretoresChosen({
  SetValueCorretor,
  valueCorretor
}) {
  let corretoresArray = []

  const [corretor, setCorretor] = useState(valueCorretor)

  useEffect(() => {
    SetValueCorretor(corretor)
  }, [corretor, SetValueCorretor])

  const { data } = QueryGetCorretores({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      }
    }
  })



  if (!data) {
    return (
      <>
        <div className='form-group'>
          <label className='col-form-label'>Corretores Disponiveis:</label>
          <Select
            options={corretoresArray}
            onChange={(e) => {
              setCorretor(e.value)
            }}
            value={corretoresArray.filter((e) => e.value)[0]}
            name='id_corretor'
            placeholder='Corretores Disponiveis'
          />
        </div>
      </>
    )
  }

  data.GetCorretores.result.map((Options) => {
    corretoresArray.push({ value: Options.id, label: Options.nome })
    return []
  })



  return (
    <>
      <div className='form-group'>
        <label className='col-form-label'>Corretores Disponiveis:</label>
        <Select
          options={corretoresArray}
          onChange={(e) => {
            setCorretor(e.value)
          }}
          name='id_corretor'
          placeholder='Corretores Disponiveis'
        />
      </div>
    </>
  )
}
