import React, {useState} from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import {Layout} from '../../Layout'
import {Explorer} from '../../Explorer'
import {Estatisticas} from './Estatisticas'
import {formatNumber} from '../../../common'
import {Button, Offcanvas} from 'react-bootstrap'
import {MenuAction} from '../MenuAction'
import {Dicionario} from '../../../../../../utils/Dicionario'
import DataTable from 'react-data-table-component'
import * as turf from '@turf/turf'
// import {PivotTable} from './PivotTable'
import {MVTLayer} from '@deck.gl/geo-layers'
import styled from 'styled-components'
import * as XLSX from 'xlsx'
import {MapControll} from '../../../../../../../store/mapControll'
import {WebMercatorViewport} from '@deck.gl/core'
import {useMapaConfig} from '../../../../../../../hooks/useMapaConfig'
import {MdTurnLeft} from 'react-icons/md'
import TableContainer from '../../../../../../components/UI/TableContainer'
import {
  FilterRange,
  RangeColumnFilter,
} from '../../../../../../components/UI/TableContainer/filters'
type Props = {
  showHide: boolean
  latLng: google.maps.LatLng
  geom: JSON
  layer: string
}

export const MenuEstatisticas: React.FC<Props> = ({showHide, latLng, geom, layer}) => {
  const {mapaConfig} = useMapaConfig()
  const [data, setData] = useState({
    dados: [],
    layerInfo: {},
    resultData: [],
    row: [],
    filteredData: [],
  })
  const windowHeight = window.innerHeight - 50
  const [wsize, setWsite] = useState(windowHeight)
  const [showResult, setShowResult] = useState(false)
  const removeColunas = [
    'cor',
    //  'gid',
    'id',
    'distancia',
    'geom',
    'layerName',
    'centroid',
    'cor',
    'cor_zona',
  ]
  window.addEventListener(
    'resize',
    function (event) {
      setWsite(window.innerHeight - 50)
    },
    true
  )

  function handleClose() {
    Layout.setShowEstatisticasMenu(false)
  }
  function selectLayer(e) {
    if (e.target.value === '' || e.target.value === '0') {
      Explorer.removeAllIntersectLayer()
      setShowResult(false)
    } else {
      const id = e.target.value
      const layerInfo = Explorer.getInfoLayer(id)
      Explorer.addIntersectLayer(id, geom)

      if (typeof Explorer.deckgl['props'] !== 'undefined') {
        // eslint-disable-next-line array-callback-return
        Explorer.deckgl['props'].layers.map(function (item) {
          let idComp = null
          // if( typeof item[0].id !== 'undefined'){
          idComp = item[0].id
          // }else{
          //   idComp = item.id
          // }

          if (idComp === id) {
            if (item.length > 0) {
              if (item[0] instanceof MVTLayer) {
                const dadosFinais = getRenderedFeatures(item[0])
                setData({
                  dados: dadosFinais,
                  layerInfo: layerInfo,
                  resultData: [],
                  row: [],
                  filteredData: [],
                })
              }
            }
          }
        })
        setShowResult(true)
      }
    }
  }
  function getRenderedFeatures(item) {
    const {viewport} = item.context
    let dadosFinais = []
    const dadosFiltrados = item.getRenderedFeatures(50000)

    // eslint-disable-next-line array-callback-return
    dadosFiltrados.map((d) => {
      let latLng = []
      if (d.geometry.type === 'Polygon') {
        let center = turf.center(d)
        latLng = [center.geometry.coordinates[0], center.geometry.coordinates[1]]
      } else {
        latLng = [d.geometry.coordinates[0], d.geometry.coordinates[1]]
      }

      const [x, y] = viewport.project(latLng)
      const pickInfo = MapControll.pickObjects({
        x: x,
        y: y,
        width: viewport.width,
        height: viewport.height,
        depth: 500,
        layerIds: [item.id],
      })
      dadosFinais = [...dadosFinais, ...pickInfo]
    })
    return dadosFinais
  }
  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
  `

  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `

  const FilterComponent = ({filterText, onFilter, onClear}) => (
    <>
      <TextField
        id='search'
        type='text'
        placeholder='Filter By Name'
        aria-label='Search Input'
        value={filterText}
        onChange={onFilter}
      />
      {/* <ClearButton type='button' onClick={onClear}>
        X
      </ClearButton> */}
    </>
  )

  function onChangeCampo(obj) {
    if (obj.target.value === '' || obj.target.value === '0') {
      setShowResult(false)
    } else {
      // const id = obj.target.value
    }
    const layerFilter = document.getElementById('filtro-layer')['value']
    if (typeof Explorer.deckgl['props'] !== 'undefined') {
      // eslint-disable-next-line array-callback-return
      Explorer.deckgl['props'].layers.map(function (item) {
        // 6487e0-6d0-86cd-1b1-f6fd85de818
        if (item[0].id === data.layerInfo['id']) {
          const dadosFiltrados = getRenderedFeatures(item[0])
          let rowsUnique = []
          let rows = dadosFiltrados.filter((item) => {
            if (
              rowsUnique.find((x) => {
                // console.log(i.object.properties.gid)
                // console.log(x.gid,'=' ,item.object.properties.gid)
                return x.object.properties.gid === item.object.properties.gid
              })
            ) {
              // console.log('Existe')
            } else {
              rowsUnique.push(item)
              return item.object.properties
            }
            //  if (rowsUnique.)
          })
          console.log(rows)
          console.log(rowsUnique)
          const coluna = document.getElementById('filtro-estatisticas-colunas')['value']
          const resultCalc = Estatisticas.calcEstatisticas(rowsUnique, coluna)

          if (typeof resultCalc['sum'] != 'undefined') {
            document.getElementById('tableNumber').style.display = 'block'
            document.getElementById('tableGroup').style.display = 'none'
            document.getElementById('sum').innerHTML = formatNumber(resultCalc['sum'], 'decimal')
            document.getElementById('min').innerHTML = formatNumber(resultCalc['min'], 'decimal')
            document.getElementById('max').innerHTML = formatNumber(resultCalc['max'], 'decimal')
            document.getElementById('med').innerHTML = formatNumber(resultCalc['med'], 'decimal')
            document.getElementById('total').innerHTML = formatNumber(
              resultCalc['total'],
              'decimal'
            )
          } else {
            document.getElementById('tableNumber').style.display = 'none'
            document.getElementById('tableGroup').style.display = 'block'
            const resultData = resultCalc['group']
            setData({...data, ...{resultData: resultData, row: rowsUnique}})
          }
        }
      })
    }
  }

  const [filterText, setFilterText] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false)

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  const columns = []
  const data2 = []
  if (data) {
    if (data.dados.length > 0) {
      // eslint-disable-next-line array-callback-return
      Object.keys(data.dados[0].object.properties).map(function (i) {
        console.log(i)
        if (removeColunas.indexOf(i) < 0) {
          console.log(i, '-', Dicionario.getType(i))

          columns.push(
            {
              Header: () => <div style={{textAlign: 'center'}}>{Dicionario.translate(i)}</div>,
              accessor: `${i}`,
              Cell: ({row}) => {
                // console.log(row.original[i])
                console.log(i, row.original[i])
                return (
                  <div style={{textAlign: 'right'}}>
                    {' '}
                    {Dicionario.formatValue(i, row.original[i])}
                  </div>
                )
              },
              //  Filter: Dicionario.getType(i) === 'inteiro' ? RangeColumnFilter : false,
              //  filter:  Dicionario.getType(i) === 'inteiro' ? FilterRange : false
            }
            // name: Dicionario.translate(i),
            // name_original: i,
            // selector: (row) => {
            //   return Dicionario.formatValue(i, row[i])
            // },
            // sortable: true,
            // filterable: true,
          )

          // columns.push({
          //   name: Dicionario.translate(i),
          //   name_original: i,
          //   selector: (row) => {
          //     return Dicionario.formatValue(i, row[i])
          //   },
          //   sortable: true,
          //   filterable: true,
          // })
        }
      })
      console.log(columns)
      // eslint-disable-next-line array-callback-return
      data.dados.filter(function (i, index, array) {
        if (typeof i.object.properties['gid'] !== 'undefined') {
          if (
            data2.find((x) => {
              return x.gid === i.object.properties.gid
            })
          ) {
            // console.log('existe')
          } else {
            removeColunas.map((item) => {
              delete i.object.properties[item]
            })
            // Object.keys(i.object.properties)

            data2.push(i.object.properties)
            return i
          }
        } else {
          removeColunas.map((item) => {
            delete i.object.properties[item]
          })
          data2.push(i.object.properties)
          return i
        }
      })
    }
  }

  console.log(data2)
  // const filteredItems = columns.filter(
  //   (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  // )

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement('a')
    let csv = convertArrayOfObjectsToCSV(array)
    if (csv == null) return

    const filename = 'export.csv'

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`
    }

    link.setAttribute('href', encodeURI(csv))
    link.setAttribute('download', filename)
    link.click()
  }
  function removeItensObj(obj, colunasRemover) {
    let index
    let novoObj = {}
    for (var i in obj) {
      index = colunasRemover.indexOf(i)
      if (index === -1) {
        novoObj[i] = obj[i]
      }
    }
    return novoObj
  }
  const handleExport = () => {
    let dataexport = []
    console.log(data2)
    // eslint-disable-next-line array-callback-return
    data2.map(function (i) {
      dataexport.push(removeItensObj(i, removeColunas))
      // return removeItensObj(i, removeColunas)
    })

    const worksheet = XLSX.utils.json_to_sheet(dataexport)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Tabela de Dados')
    XLSX.writeFile(wb, 'dados.xlsx')
  }

  function convertArrayOfObjectsToCSV(array: any) {
    let result = null
    const columnDelimiter = ','
    const lineDelimiter = '\n'
    const keys = Object.keys(array.dados[0].object.properties).filter((i) => {
      if (removeColunas.indexOf(i) < 0) {
        i = Dicionario.translate(i)
        return true
      }
      return false
    })
    result = ''
    result += keys.join(columnDelimiter)
    result += lineDelimiter
    array.dados.forEach((item) => {
      let ctr = 0
      keys.forEach((key) => {
        if (removeColunas.indexOf(key) < 0) {
          if (ctr > 0) result += columnDelimiter
          result += Dicionario.translate(item.object.properties[key])

          ctr++
        }
      })
      result += lineDelimiter
    })

    return result
  }

  // const Export = ({onExport}) => (
  //   <Button onClick={(e) => onExport(e.target['value'])}>Export</Button>
  // )

  // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, [])

  // const customSort = (rows, selector, direction) => {
  //   return rows.sort((rowA, rowB) => {
  //     // use the selector function to resolve your field names by passing the sort comparitors
  //     const aField = selector(rowA)
  //     const bField = selector(rowB)

  //     let comparison = 0

  //     if (aField > bField) {
  //       comparison = 1
  //     } else if (aField < bField) {
  //       comparison = -1
  //     }

  //     return direction === 'desc' ? comparison * -1 : comparison
  //   })
  // }
  //    const customSort = (rows, selector, direction) => {
  //     return orderBy(rows, selector, direction);
  // };
  // const subHeaderComponent = (
  //   <div style={{display: 'flex', alignItems: 'center'}}>
  //     <TextField id='outlined-basic' style={{margin: '5px'}} />
  //     {/* <Icon1 style={{ margin: '5px' }} color="action" />
  //     <Icon2 style={{ margin: '5px' }} color="action" />
  //     <Icon3 style={{ margin: '5px' }} color="action" /> */}
  //   </div>
  // )
  return (
    <>
      <Offcanvas
        show={showHide}
        tabIndex={null}
        onHide={handleClose}
        id='offcanvas-direita-estatisticas'
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title className=''>Informações da Feição</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{width: '100%', padding: '10px'}} className='menu-fit  p-0'>
            <MenuAction showHide={false} latLng={latLng}></MenuAction>
            <div className='tab-content p-5 scroll' id='tabContent'>
              <div
                id='territorio'
                className='tab-pane fade show active'
                role='tabpanel'
                aria-labelledby='territorio-tab'
              >
                <div className=''>
                  <Scrollbars style={{width: '100%', height: wsize}}>
                    <div
                      className='accordion accordion-flush scroll'
                      id='accordionFlushExample_territorio'
                    >
                      {/* <div className='row pb-5'>
                        <div className='col-md-12'>
                          <h3> {'Camada Selecionada'}</h3>
                        </div>
                        <div className='col-md-12'>
                          <select className='form-control' onChange={selectLayer}>
                            <option value='0'>{'--selecione--'}</option>
                            {Explorer.layers.map(function (lay, index) {
                              return (
                                <option
                                  value={lay.id}
                                  key={index}
                                  selected={layer === lay.id ? true : false}
                                >
                                  {' '}
                                  {lay.nome}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div> */}

                      <div className='row'>
                        <div className='col-md-12'>
                          <h3> {'Selecione uma camada para fazer o intersect'}</h3>
                        </div>
                        <div className='col-md-12'>
                          <select className='form-control' onChange={selectLayer} id='filtro-layer'>
                            <option value='0'>{'--selecione--'}</option>
                            {Explorer.layers.map(function (lay, index) {
                              return (
                                <option value={lay.id} key={index}>
                                  {lay.nome}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>

                      {showResult ? (
                        <div className='p-0 scroll'>
                          <div
                            id='estatisticas'
                            className='tab-pane fade show active'
                            role='tabpanel'
                            aria-labelledby='estatisticas-tab'
                          >
                            <div className=' d-flex justify-content-end'>
                              <div className=' col-md-3 pt-5 pb-5'>
                                <button
                                  className='btn btn-info'
                                  data-bs-target='#modal-estaticas-dados'
                                  data-bs-toggle='modal'
                                >
                                  Tabela de Dados
                                </button>
                              </div>
                            </div>
                            <div className='form-group row'>
                              <label className='col-sm-2 col-form-label'>Campos</label>
                              <div className='col-sm-10'>
                                <select
                                  name='filtro-estatisticas-colunas'
                                  id='filtro-estatisticas-colunas'
                                  onChange={onChangeCampo}
                                  className='form-control field-column'
                                >
                                  <option value={0}>--Selecione--</option>

                                  {data.dados.length > 0 ? (
                                    // eslint-disable-next-line array-callback-return
                                    Object.keys(data.dados[0].object.properties).map(function (
                                      i,
                                      index
                                    ) {
                                      if (removeColunas.indexOf(i) < 0) {
                                        return (
                                          <option value={i} key={index}>
                                            {i}
                                          </option>
                                        )
                                      }
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className=''>
                            {/* {data.row.length > 0 ? <div>Imprimir table</div>  :'<div>sem dados</div>'} */}
                            <table className='table  table-striped p-5' id='tableNumber'>
                              <thead>
                                <tr>
                                  <th>Campo</th>
                                  <th>Valor</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Soma</td>
                                  <td>
                                    <span id='sum'></span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Minímo</td>
                                  <td>
                                    <span id='min'></span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Máximo</td>
                                  <td>
                                    <span id='max'></span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Média</td>
                                  <td>
                                    <span id='med'></span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total de Elementos</td>
                                  <td>
                                    <span id='total'></span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <table className='table table-striped p-5' id='tableGroup'>
                              <thead>
                                <tr>
                                  <th>Campo</th>
                                  <th>Valor</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.entries(data.resultData).length > 0 ? (
                                  Object.entries(data.resultData).map(function (result, y) {
                                    return (
                                      <tr key={y}>
                                        <td>{result[0]}</td>
                                        <td>{result[1]}</td>
                                      </tr>
                                    )
                                  })
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <div
        className='modal fade'
        id='modal-estaticas-dados'
        aria-hidden='true'
        style={{zIndex: 99999}}
      >
        <div className='modal-dialog modal-xl modal-dialog-centered  modal-lg'>
          <div className='modal-content  modal-lg'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='modal-raui-toogle-label'>
                Tabela de Dados
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <div>
                <div className=' d-flex justify-content-end pb-5'>
                  <button onClick={handleExport} className='btn btn-primary'>
                    Exportar para XLSX
                  </button>
                </div>
                <div className='row d-flex justify-content-around'>
                  {/* 
                  {data2 ? <PivotTable itens={data2} ></PivotTable> : ''} */}
                  {/* <TableFilter></TableFilter> */}

                  <TableContainer columns={columns} data={data2}></TableContainer>

                  {/* <DataTable
                    dense
                    columns={columns}
                    subHeader={true}
                    data={data2}
                    pagination
                    // responsive={"true"}
                    onColumnOrderChange={(cols) => console.log(cols)}

                    // subHeaderComponent={subHeaderComponentMemo}
                    // subHeaderComponent={
                    //   <input
                    //     type="text"
                    //     placeholder="Filtrar por qualquer coluna"
                    //     onChange={(e) => {
                    //       const value = e.target.value;

                    //       // Filtrar os dados com base no valor inserido
                    //       const filteredData = data.dados.filter((item) => {
                    //         // console.log(item)
                    //         // console.log(item.object.properties)
                    //         for (let key in item.object.properties) {

                    //           if (item[key] && isNaN(item[key])){
                    //               console.log(key)
                    //             console.log(item[key])
                    //             console.log(value.toLowerCase())
                    //             if (item[key].toString().toLowerCase().includes(value.toLowerCase())) {
                    //               return true;
                    //             }
                    //           }
                    //         }
                    //         return false;
                    //       });
                    //       console.log(filteredData)
                    //       setData({...data, ...{ filteredData: filteredData }} );
                    //     }}
                    //   />
                    // }
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
