import React from 'react';
import { FcInfo } from 'react-icons/fc';

interface Props {
    message?: string; // Supondo que a mensagem seja opcional
}

export const ErrorMessageComponent: React.FC<Props> = ({ message }) => {
    if (!message) return null;

    return (
        <div className="mt-2 d-flex align-items-start">
            <FcInfo className="me-2" size={18} />
            <span>{message}</span>
        </div>
    );
};
