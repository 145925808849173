import { InputHTMLAttributes } from 'react';
import { ErrorMessageComponent } from '../../../backoffice/components/UI/ErrorMessage';
import { useController, Control } from 'react-hook-form';

interface AreaInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  control?: Control<any>;  // Usado com react-hook-form
  name: string;
  error?: any;
  hide?: boolean;
  valueDefault?: number;  // Corrigido para valueDefault
  solid: boolean;
}

export function AreaInput({
  label,
  hide,
  control,
  name,
  error,
  solid,
  valueDefault,
  ...props
}: AreaInputProps) {
  const { field } = useController({ name, control });

  // Função para formatar o valor como área (m²)
  const formatArea = (value: number | string) => {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    return value ? `${value.toLocaleString('pt-BR')}` : '';
  };

  // Manipulador de mudança para garantir a formatação correta
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9]/g, ''); // Remove caracteres não numéricos
    const numberValue = parseFloat(value); // Converte para número
    field.onChange(numberValue); // Atualiza o valor no react-hook-form
  };

  return (
    <div className={hide ? 'hide' : 'form-group'} hidden={hide}>
      {label && <label className={hide ? 'd-none' : 'col-form-label'}>{label}</label>}
      <div className="input-group mb-3">
        <input
          {...props}
          {...field}
          className={`form-control${solid ? ' form-control-solid' : ''}`}
          placeholder="Ex: 100 m²"
          defaultValue={valueDefault} 
          value={formatArea(field.value ?? valueDefault)} 
          onChange={handleChange}
        />
        <span className="input-group-text bg-white border-1">m²</span>
      </div>
      {error && <ErrorMessageComponent message={error} />}
    </div>
  );
}
