import React from 'react';

const MAX_ITEMS = 9;
const MAX_LEFT = (MAX_ITEMS - 1) / 2;

const Pagination = ({ total, pages, setPages }) => {
    const current = pages ? (pages) + 1 : 1;
    const pagesTot = total;
    const first = Math.max(current - MAX_LEFT, 1);

    function onPageChange(page) {
        setPages((page - 1))
    }

    return (
        <nav aria-label="...">
            <ul className="pagination">
                <li className="page-item previous disabled">
                <button
                        onClick={() => onPageChange(current - 1)}
                        disabled={current === 1}
                        className="page-link">
                        <i className="previous"></i>
                    </button>
                </li>

                {Array.from({ length: Math.min(MAX_ITEMS, pagesTot) })
                    .map((_, index) => index + first)
                    .map((page) => (
                        <button key={page} onClick={() => setPages((page - 1))}
                            className={page === current
                                ? 'page-item btn btn-sm btnSuccess'
                                : 'page-item btn btn-sm '
                            }
                        >
                            {page}
                        </button>

                    ))}
                <li className="page-item next">
                    <button
                        onClick={() => onPageChange(current + 1)}
                        disabled={current === pagesTot}
                        className="page-link">
                         <i className="next"></i>
                    </button>
                </li>

            </ul>
        </nav>
    )
};

export default Pagination;