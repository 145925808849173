import {gql} from '@apollo/client'

export const GET_PRODUTOS = gql`
  query GetProdutos($pagination: Pagination) {
    GetProdutos(pagination: $pagination) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        nome
        ordem
        tipo_produto
        produto_id
        valor
        loja_id
        id
        descricao
        desconto
      }
    }
  }
`

export const GET_PRODUTO_BY_ID = gql`
  query GetProdutosById($getProdutosByIdId: Float!) {
    GetProdutosById(id: $getProdutosByIdId) {
      descricao
      id
      nome
      tipo_produto
      valor
      desconto
    }
  }
`
