import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import { ADMINQueryGetProdutos } from '../../../graphql/services/Produtos'


export function ProdutosChosen({
  setValueUsuarioProduto,
  valueUsuarioProduto
}) {
  let produtosArray = []


  const [usuarioProduto, setUsuarioProduto] = useState(valueUsuarioProduto)

  useEffect(() => {
    setValueUsuarioProduto(String(usuarioProduto))
  }, [usuarioProduto, setValueUsuarioProduto])

  const {data} = ADMINQueryGetProdutos({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      }
    }
  })



  if (!data) {
    return (
      <>
        <div className='form-group'>
          <label className='col-form-label'>Produtos Disponiveis:</label>
          <Select
            options={produtosArray}
            value={produtosArray.filter((e) => e.value)[0]}
            name='id_produto'
            placeholder='Produtos Disponiveis'
          />
        </div>
      </>
    )
  }

  data.GetProdutos.result.map((Options) => {
    produtosArray.push({ value: Options.id, label: Options.nome })
    return []
  })



  return (
    <>
      <div className='form-group'>
        <label className='col-form-label'>Produtos Disponiveis:</label>
        <Select
          options={produtosArray}
          onChange={(e) => {
            setUsuarioProduto(e.value)
          }}
          name='id_produto'
          placeholder='Produtos Disponiveis'
        />
      </div>
    </>
  )
}
