import {Explorer} from '../../Explorer'
import {ColorPicker} from '../../../../../../components/UI/ColorPicker'
import React, {useEffect, useState} from 'react'
import RangeSlider from 'react-bootstrap-range-slider'
import {GetPropert} from '../../../common'

type Props = {
  uuid: any
  defaultValue: any
}

export const FillColor: React.FC<Props> = ({uuid, defaultValue}) => {
 
  const corInicial = getColor(defaultValue)
  const [opacity, setOpacity] = useState(50)
  const [color, setColor] = useState([])

  function getOpacidade(style) {
    if (style) {
      // if (defaultValue.length === 3) {
      let opacidade = GetPropert(style, 'fill-opacity')
      if (opacidade) {
        if (opacidade <= 1) {
          return opacidade * 100
        } else {
          return opacidade
        }
      } else {
        return 100
      }
    }else{
      return 100
    }
    // 
  }
  useEffect(()=>{
    const opacidade = getOpacidade(defaultValue)
    setOpacity(opacidade)
  },[defaultValue])

  function getColor(style) {
    if (defaultValue) {
      let color = GetPropert(style, 'fill-color')
      return color
    }
    return '#21A576'
  }

  function onChangeOpacity() {
    color[3] = opacity * 2.4
    Explorer.changeStyle(uuid, 'fill-color', color)
  }

  function onChange(color) {
    color[3] = opacity * 2.4
    Explorer.changeStyle(uuid, 'fill-color', color)
    setColor(color)
  }

  return (
    <>
      <div className='item-config'>
        <div className='item-config-align'>
          <div
            className='item-config-align-title'
            data-bs-toggle='collapse'
            data-bs-target={`#collapseExample_${uuid}_fill`}
            aria-expanded='false'
            aria-controls={`collapseExample_${uuid}_fill`}
          >
            Cor Preenchimento
          </div>
          <div className='border-left-layers collapse' id={`collapseExample_${uuid}_fill`}>
            <ColorPicker hex={corInicial} hsl={''} onChange={onChange}></ColorPicker>
            <RangeSlider
              // defaultValue={opacity}
              value={opacity}
              onChange={(changeEvent) => {
                let val = changeEvent.target.value
                setOpacity(parseInt(val))
                onChangeOpacity()
              }}
              min={0}
              max={100}
              step={5}
            />
          </div>
        </div>
      </div>
    </>
  )
}
