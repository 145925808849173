import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { MutationPutAdminEmpresaUsuario, QueryGetAdminEmpresaUsuarioByID } from '../../../../../graphql/services/Empresas'


export function EditEmpresaUsuario({ id_usuario }) {
  const [show, setShow] = useState(false)

  const { data } = QueryGetAdminEmpresaUsuarioByID({
    variables: {
      getEmpresaUsuarioByIdAdminId: parseInt(id_usuario)
    }
  })

  const { FormAdminEmpresaUsuario, handleSubmit, register } = MutationPutAdminEmpresaUsuario()
  function closeModal() {
    setShow(false)
  }
  function openModal() {
    setShow(true)
  }


  const onSubmit = async (data) => {
    FormAdminEmpresaUsuario(data).then((result) => {
      Swal.fire('Sucesso!', 'Dados alterados com sucesso.', 'success');
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error');
    });

  }

  if (!data) {
    return (
      <>
        <button disabled onClick={openModal} className='btn btn-icon btn-light-success btn-sm me-2' style={{ width: '100px !important' }}>
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button>
      </>
    )
  }

  return (
    <>
      <button onClick={openModal} className='btn btn-icon btn-light-success btn-sm me-2' style={{ width: '100px !important' }}>
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </button>

      <Modal centered animation show={show} onHide={() => closeModal()} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Editar Permissões:</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-lg'>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <form onSubmit={handleSubmit(onSubmit)}>

                  <input
                    className='form-control form-control-solid'
                    hidden={true}
                    value={id_usuario}
                    {...register('id', { required: true, valueAsNumber: true })}
                  />

                  <input
                    type="text"
                    disabled
                    defaultValue={data.GetEmpresaUsuarioByIdAdmin.users.name ? data.GetEmpresaUsuarioByIdAdmin.users.name : 'Não Informado'}
                    className="form-control"
                  />

                  <div className='form-group mt-4'>
                    <label className='form-label' htmlFor="">Vizualização</label>
                    <select
                      defaultValue={data.GetEmpresaUsuarioByIdAdmin.visualizacao ? data.GetEmpresaUsuarioByIdAdmin.visualizacao : 'Não Informado'}
                      className='form-control' name="" id="" {...register('visualizacao')}>
                      <option value="">Selecione</option>
                      <option value="PARCIAL">Parcial</option>
                      <option value="TOTAL">Total</option>
                    </select>
                  </div>

                  <div className='form-group mt-4'>
                    <label className='form-label' htmlFor="">Edição</label>
                    <select
                      defaultValue={data.GetEmpresaUsuarioByIdAdmin.edicao ? data.GetEmpresaUsuarioByIdAdmin.edicao : 'Não Informado'}
                      className='form-control' name="" id="" {...register('edicao')}>
                      <option value="">Selecione</option>
                      <option value="PARCIAL">Parcial</option>
                      <option value="TOTAL">Total</option>
                    </select>
                  </div>


                  <div className='form-group mt-4'>
                    <label className='form-label' htmlFor="">Exclusão</label>
                    <select
                      defaultValue={data.GetEmpresaUsuarioByIdAdmin.exclusao ? data.GetEmpresaUsuarioByIdAdmin.exclusao : 'Não Informado'}
                      className='form-control' name="" id="" {...register('exclusao')}>
                      <option value="">Selecione</option>
                      <option value="PARCIAL">Parcial</option>
                      <option value="TOTAL">Total</option>
                    </select>
                  </div>



                  <div className="col-md-12 d-flex mrt-1" style={{ justifyContent: 'flex-end' }}>
                    <button onClick={() => closeModal()} className="btn btn-secondary pull-right btn-sm" type="button" data-bs-dismiss="modal">Fechar</button>
                    <button type="submit" className="btn btn-success mx-2 btn-sm" id="btnIncluirCorretor">Salvar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
