import {useState} from 'react'
import {Explorer} from '../Explorer'
import {Layout} from '../Layout'
// import { ButtonLoading } from '../../../../../components/UI/Button'

type PropsContextMenu = {
  latLng: google.maps.LatLng | null
  defaultValue: number
  addMarker: boolean
}

export const Intersect: React.FC<PropsContextMenu> = ({
  latLng,
  defaultValue,
  addMarker = false,
}) => {
  const [intersect] = useState(defaultValue)
  const onClickAddIntersect = () => {
    const lng = latLng.lng()
    const lat = latLng.lat()
    if (addMarker) {
      Explorer.addMarker(lat, lng, 'intersect', 'Local')
    }
    Explorer.addRadius(lat, lng, intersect, `intersect de  ${intersect}`)
    Layout.setShowGeometryMenu(false)
  }
  // function onChange(value: any) {
  //   setIntersect(value)
  // }
  return (
    <div className='modal fade' id='modal-intersect' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='modal-raui-toogle-label'>
              intersect
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <div>
              <label>Área x:</label>
              <select>
                <option value=''> --select--</option>
                <option value='x'>Camada x</option>
              </select>
            </div>
          </div>
          <div className='modal-footer'>
            {/* <ButtonLoading  onClick={onClickAddintersect} className={'btn btn-primary'}></ButtonLoading> */}

            <button
              className='btn btn-primary'
              onClick={() => {
                onClickAddIntersect()
              }}
            >
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
