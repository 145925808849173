import { gql } from "@apollo/client";

export const SET_USUARIO = gql`
mutation SetUsuario($usuario: usuario!) {
  SetUsuario(usuario: $usuario) {
    id
    name
  }
}
`

export const PUT_USUARIO = gql`
mutation PutUsuario($usuario: usuario!) {
  PutUsuario(usuario: $usuario) {
    name
  }
}
`

export const SET_USUARIO_ADMIN = gql`
mutation SetUsuarioAdmin($usuario: usuario!) {
  SetUsuarioAdmin(usuario: $usuario) {
    id
    name
  }
}
`