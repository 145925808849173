import { AsideDefault } from '../../../../_metronic/layout/components/aside/AsideDefault';
import { HeaderWrapperFluid } from '../../../../_metronic/layout/components/header/HeaderWrapperFluid';
import { FormProvider } from 'react-hook-form';
import { EmpreendimentosProvider } from './context/useEmpreendimentos';
import { Empreendimentos } from './empreendimentos';

export function AdminEditEmpreendimentos() {
  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <EmpreendimentosProvider >
                <Empreendimentos />
              </EmpreendimentosProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
