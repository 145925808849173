import {useMutation, useQuery} from '@apollo/client'
import {
  TypesGetImovelProprietariosByIDResponse,
  TypesGetImovelProprietariosResponse,
  TypesPutImovelProprietariosResponse,
  TypesSetImovelProprietariosResponse,
} from '../type/imovel-proprietario'
import {GET_IMOVEL_PROPRIETARIO, GET_IMOVEL_PROPRIETARIO_BY_ID} from '../query'
import {
  FormAssociationImovelProprietarioFormData,
  FormAssociationImovelProprietarioFormSchema,
  FormCreateImovelProprietarioPessoaFormData,
  FormCreateImovelProprietarioPessoaSchema,
  FormCreateImovelProprietariosFormData,
  FormCreateImovelProprietariosSchema,
  FormPutImovelProprietariosFormData,
  FormPutImovelProprietariosSchema,
} from '../formValidations/Imoveis'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {PUT_IMOVEL_PROPRIETARIO, } from '../mutations'
import {TypesDeleteProprietariosResponse, TypesSetProprietariosResponse} from '../type/proprietarios'
import {DELETE_IMOVEL_PROPRIETARIO, SET_IMOVEL_PROPRIETARIO, SET_PROPRIETARIO} from '../mutations/proprietarios'

interface QueryProps {
  variables: any
}

export function QueryGetImovelProprietarios({variables}: QueryProps) {
  const {data,refetch} = useQuery<TypesGetImovelProprietariosResponse>(
    GET_IMOVEL_PROPRIETARIO, 
    {
      variables: {...variables},
      fetchPolicy: "no-cache"
    }
    )
  return {data,refetch}
}

export function QueryGetImovelProprietariosByID({variables}: QueryProps) {
  const {data, loading} = useQuery<TypesGetImovelProprietariosByIDResponse>(
    GET_IMOVEL_PROPRIETARIO_BY_ID,
    {
      variables: {...variables},
    }
  )
  return {data, loading}
}

export function MutationCreateImovelProprietario() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: {errors},
  } = useForm<FormCreateImovelProprietariosFormData>({
    resolver: zodResolver(FormCreateImovelProprietariosSchema),
  })

  console.log(errors)

  const [ProprietariosBody, {error, loading, data: DataProprietarios}] =
    useMutation<TypesSetProprietariosResponse>(SET_PROPRIETARIO)

  async function FormProprietarios(data: FormCreateImovelProprietariosFormData, id_terreno: number) {
    delete data.tipo
    await ProprietariosBody({
      variables: {
        idTerreno: id_terreno,
        proprietario: {...data},
      },
      refetchQueries: [GET_IMOVEL_PROPRIETARIO],
    })
  }

  return {
    register,
    handleSubmit,
    FormProprietarios,
    loading,
    errors,
    error,
    DataProprietarios,
    control,
    setValue,
    setError,
  }
}

export function MutationAssociationImovelProprietario() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: {errors},
  } = useForm<FormAssociationImovelProprietarioFormData>({
    resolver: zodResolver(FormAssociationImovelProprietarioFormSchema),
  })

  console.log(errors)

  const [ProprietariosBody, {error, loading, data: DataProprietarios}] =
    useMutation<TypesSetImovelProprietariosResponse>(SET_IMOVEL_PROPRIETARIO)

  async function FormProprietarios(idTerreno, idProprietario) {
    console.log(idTerreno, idProprietario)
    await ProprietariosBody({
      variables: {
        imovelProprietario: {
          id_proprietario: idProprietario,
          id_terreno:idTerreno
        }
      },
      refetchQueries: [GET_IMOVEL_PROPRIETARIO],
    })
  }

  return {
    register,
    handleSubmit,
    FormProprietarios,
    loading,
    errors,
    error,
    DataProprietarios,
    control,
    setValue,
    setError,
  }
}


export function MutationCreateProprietarioPessoa() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: {errors},
  } = useForm<FormCreateImovelProprietarioPessoaFormData>({
    resolver: zodResolver(FormCreateImovelProprietarioPessoaSchema),
  })

  console.log(errors)

  const [ProprietariosBody, {error, loading, data: DataProprietarios}] =
    useMutation<TypesSetProprietariosResponse>(SET_PROPRIETARIO)

  async function FormProprietarios(data: FormCreateImovelProprietariosFormData, id_terreno: number) {
    delete data.tipo
    await ProprietariosBody({
      variables: {
        idTerreno: id_terreno,
        proprietario: {...data},
      },
      refetchQueries: [GET_IMOVEL_PROPRIETARIO],
    })
  }

  return {
    register,
    handleSubmit,
    FormProprietarios,
    loading,
    errors,
    error,
    DataProprietarios,
    control,
    setValue,
    setError,
  }
}

// export function MutationCreateImovelProprietario() {

//   const [ProprietariosBody, {error, loading, data: DataImovelProprietarios}] =
//     useMutation<TypesSetImovelProprietariosResponse>(SET_IMOVEL_PROPRIETARIO)

//   async function FormImovelProprietarios(data: any) {
//     try {
//       const result =  await ProprietariosBody({
//         variables: {
//           imovelProprietario: {
//             id_proprietario: data.SetImovelProprietario.id_proprietario,
//             id_terreno: data.SetImovelProprietario.id_terreno
//           }
//         },
//         refetchQueries: [GET_IMOVEL_PROPRIETARIO],
//       })
//       return result
//     } catch (e) {
//       if (e) {
//         return false
//       }
//     }
//   }

//   return {
//     FormImovelProprietarios,
//     loading,
//     error,
//     DataImovelProprietarios,

//   }
// }


export function MutationPutImovelProprietarios() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<FormPutImovelProprietariosFormData>({
    resolver: zodResolver(FormPutImovelProprietariosSchema),
  })

  const [ProprietariosBody, {error, loading, data: DataImovelProprietarios}] =
    useMutation<TypesPutImovelProprietariosResponse>(PUT_IMOVEL_PROPRIETARIO)

  async function FormImovelProprietarios(data: FormPutImovelProprietariosFormData) {
    delete data.tipo
    await ProprietariosBody({
      variables: {
        proprietario: {...data},
      },
      refetchQueries: [GET_IMOVEL_PROPRIETARIO, GET_IMOVEL_PROPRIETARIO_BY_ID],
    })
  }

  return {
    register,
    handleSubmit,
    FormImovelProprietarios,
    loading,
    errors,
    error,
    DataImovelProprietarios,
    setValue
  }
}

export function MutationUpdateImovelProprietarios() {
  const [ProprietariosBody, {error, loading, data: DataImovelProprietarios}] =
    useMutation<TypesPutImovelProprietariosResponse>(PUT_IMOVEL_PROPRIETARIO)

  async function FormImovelProprietarios(data: FormPutImovelProprietariosFormData) {
    await ProprietariosBody({
      variables: {
        proprietario: {...data},
      },
      refetchQueries: [GET_IMOVEL_PROPRIETARIO],
    })
  }
  return {FormImovelProprietarios, ProprietariosBody, error, loading, DataImovelProprietarios}
}

export function MutationDeleteImovelProprietarios() {
  const [deleteProprietario, {loading, data, error}] = useMutation<TypesDeleteProprietariosResponse>(
    DELETE_IMOVEL_PROPRIETARIO,
    {
      refetchQueries: [GET_IMOVEL_PROPRIETARIO, GET_IMOVEL_PROPRIETARIO_BY_ID],
    }
  )

  async function HandleDeleteProprietarios({variables}: QueryProps) {
    try {
      const result = await deleteProprietario({
        variables: {...variables},
        refetchQueries: [GET_IMOVEL_PROPRIETARIO, GET_IMOVEL_PROPRIETARIO_BY_ID],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteProprietarios, loading, error, data}
}
