import {FaInfoCircle} from 'react-icons/fa'
import OverlayPopover from '../../../../components/UI/Popover'
import {RenderItem} from './RenderFicha'
import {useEffect, useRef, useState} from 'react'
import {Alert} from 'react-bootstrap'
import {MapboxLayer} from '../../../../components/UI/Mapbox/MapboxLayer'
import {ApiUrbit} from '../../../../../services/http/urbit'

type Props1Item = {
  url: any
  lat: any
  lng: any
  municipio: any
  servico: any
  distancia: number
}

export const ServicoCard: React.FC<Props1Item> = ({
  url,
  servico,
  lat,
  lng,
  municipio,
  distancia,
}) => {
  const [result, setResult] = useState(null)
  const mapRef = useRef()
  useEffect(() => {
    async function fetchData() {
      const data = await ApiUrbit.getServico(url, lat, lng, municipio, distancia)
      setResult(data)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, lat, lng])
  if (!result) {
    return <>Carregando....</>
  }
  const showMapServices = [
    'zoneamento',
    'operacoes-urbanas',
    'areas-contaminadas',
    'area-vulnerabilidade',
  ]
  const layerConfig = {
    zoneamento: {
      tiles: ['https://tile.urbit.com.br/tile/zoneamento/{z}/{x}/{y}.pbf?s=sao_paulo'],
      source: 'zoneamento',
      paint: {'fill-color': ['get', 'cor_zona'], 'fill-opacity': 0.4},
      layout: {
        'text-field': ['get', 'zona'], // Nome do atributo com o texto
        'text-size': 12, // Tamanho do texto
        'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
      },
      paintText: {
        'text-color': '#000000', // Cor do texto
      },
    },
    'areas-contaminadas': {
      tiles: ['https://tile.urbit.com.br/tile/areas_contaminadas/{z}/{x}/{y}.pbf?s=sao_paulo'],
      source: 'areas_contaminadas',
      paint: {'fill-color': '#FF0000', 'fill-opacity': 0.4},
      layout: {
        'text-field': ['get', 'zona'], // Nome do atributo com o texto
        'text-size': 12, // Tamanho do texto
        'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
      },
      paintText: {
        'text-color': '#000000', // Cor do textoP
      },
    },
    'area-vulnerabilidade': {
      tiles: ['https://tile.urbit.com.br/tile/geosampa_assentamentos/{z}/{x}/{y}.pbf?s=sao_paulo'],
      source: 'geosampa_assentamentos',
      paint: {'fill-color': '#FF0000', 'fill-opacity': 0.4},
      layout: {
        // 'text-field': ['get', 'nome'], // Nome do atributo com o texto
        'text-size': 12, // Tamanho do texto
        'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
      },
      paintText: {
        'text-color': '#000000', // Cor do texto
      },
    },
    'risco-geologico': {
      tiles: ['https://tile.urbit.com.br/tile/areas_risco_geologico/{z}/{x}/{y}.pbf?s=sao_paulo'],
      source: 'areas_risco_geologico',
      paint: {'fill-color': '#FF0000', 'fill-opacity': 0.4},
      layout: {
        // 'text-field': ['get', 'nome'], // Nome do atributo com o texto
        'text-size': 12, // Tamanho do texto
        'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
      },
      paintText: {
        'text-color': '#000000', // Cor do texto
      },
    },
    'area-alagamento': {
      tiles: ['https://tile.urbit.com.br/tile/alagamentos/{z}/{x}/{y}.pbf?s=sao_paulo'],
      source: 'alagamentos',
      paint: {'fill-color': '#FF0000', 'fill-opacity': 0.4},
      layout: {
        // 'text-field': ['get', 'nome'], // Nome do atributo com o texto
        'text-size': 12, // Tamanho do texto
        'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
      },
      paintText: {
        'text-color': '#000000', // Cor do texto
      },
    },
    'operacoes-urbanas': {
      tiles: [
        'https://tile.urbit.com.br/tile/view_explorer_operacoes_urbanas/{z}/{x}/{y}.pbf?s=sao_paulo',
      ],
      source: 'view_explorer_operacoes_urbanas',
      paint: {
        'fill-color': [
          'match',
          ['get', 'zona'],
          'OUC AGUA BRANCA',
          '#d7191c',
          'OUC AGUA ESPRAIADA',
          '#fdae61',
          'OUC CENTRO',
          '#ffffbf',
          'OUC FARIA LIMA',
          '#abdda4',
          'PIU Arco Jurubatuba',
          '#2b83ba',
          '#FF0000',
        ],
        'fill-opacity': 0.4,
      },
      layout: {
        'text-field': ['get', 'zona'], // Nome do atributo com o texto
        'text-size': 12, // Tamanho do texto
        'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
      },
      paintText: {
        'text-color': '#000000', // Cor do texto
      },
    },
  }

  return (
    <>
      <div
        key={servico['id']}
        className='card hover-elevate-up shadow-clean parent-hover mb-5 card-servico'
        data-tags={servico['session_name']}
      >
        <div className='card-header p-2' style={{color: '#28a573'}}>
          <h2 className='card-title  fw-bold text-dark  '>
            <OverlayPopover
              key={servico['id'] + 'overlayPopover'}
              keyChield={servico['id'] + 'overlayPopover'}
              title={servico['nome']}
              content={servico['descricao']}
            >
              <div style={{paddingRight: '5px'}}>
                <FaInfoCircle color='#d0d0d0' />
              </div>
            </OverlayPopover>
            <div> {servico['nome']}</div>
          </h2>
        </div>
        <div className='card-body px-1 py-0'>
          {showMapServices.indexOf(url) !== -1 ? (
            <MapboxLayer
              latLng={[lat, lng]}
              inputRef={mapRef}
              onLoad={(map) => {}}
              options={layerConfig[url]}
              style={{width: 'auto', height: 350}}
            ></MapboxLayer>
          ) : (
            <></>
          )}

          {result === null ? (
            <div>Carregando...</div>
          ) : typeof result.message != 'undefined' ? (
            <Alert>Erro ao carregar o serviço'</Alert>
          ) : (
            <RenderItem
              item={result['itens']}
              keyItem={servico['session_name']}
              text={servico['nome']}
              template={servico['display_config']}
            ></RenderItem>
          )}
        </div>
      </div>
    </>
  )
}
