import React, { FC, useState } from 'react'
import { Footer } from '../../../_metronic/layout/components/Footer'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { AsideDefault } from '../../../_metronic/layout/components/aside/AsideDefault'
import { HeaderWrapperFluid } from '../../../_metronic/layout/components/header/HeaderWrapperFluid'
import { NewsFeed } from './partials/NewsFeed'
import { ImoveisList } from './partials/ImoveisList'
import { Link } from 'react-router-dom'
import { BiBarChart, BiBuildings, BiCog, BiCompass, BiMapPin } from 'react-icons/bi'
import { getLaunches } from '../../../services/http/urbit-gis/routes'
import { RealEstateLaunch } from '../../../services/http/urbit-gis/routes/types'

function DashboardPage() {

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid title='Dashboard' />
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid pt-10'>
              <div className='row'>
                <div className='col-12'>
                  <div className='row g-0 '>
                    <div className='col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-3  mb-5  h-100px'>
                      <Link to='/inteligencia-mercado' className=''>
                        <div className='text-center align-items-center bg-light-success me-5 px-6 py-5  rounded-2 me-xl-6 d-flex align-content-center justify-content-center h-100px'>
                          <BiBarChart
                            size={26}
                            className='fs-1 me-2 mt-1'
                            color='#248809'
                          ></BiBarChart>
                          <span
                            style={{ display: 'contents' }}
                            className='text-success fw-semibold fs-1 pt-15'
                          >
                            Inteligência de Mercado
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className='col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-3  mb-5  h-100px'>
                      <Link to='/explorer'>
                        <div className='text-center align-items-center bg-light-warning me-5 px-6 py-5  rounded-2 me-xl-6 d-flex align-content-center justify-content-center h-100px'>
                          <BiCompass
                            size={26}
                            className='fs-1 me-2 mt-1'
                            color='#ffce43'
                          ></BiCompass>
                          <span
                            style={{ display: 'contents' }}
                            className='text-warning fw-semibold fs-1 pt-15'
                          >
                            Explorer
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className='col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-3  mb-5  h-100px'>
                      <Link to='/meus-imoveis'>
                        <div className='text-center align-items-center bg-light-primary me-5 px-6 py-5 rounded-2 me-xl-6 d-flex align-content-center justify-content-center h-100px'>
                          <BiMapPin size={26} className='fs-1 me-2 mt-1' color='#004de5'></BiMapPin>
                          <span
                            style={{ display: 'contents' }}
                            className='text-primary fw-semibold  fs-1 pt-15'
                          >
                            Meus Imóveis
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className='col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-3  mb-5  h-100px'>
                      <Link to='/empreendimentos'>
                        <div className='text-center align-items-center bg-light-danger me-5 px-6 py-5 rounded-2 me-xl-6 d-flex align-content-center justify-content-center h-100px'>
                          <BiBuildings
                            size={26}
                            className='fs-1 me-2 mt-1'
                            color='#f14180'
                          ></BiBuildings>

                          <span
                            style={{ display: 'contents' }}
                            className='text-danger fw-semibold  fs-1 pt-15'
                          >
                            Empreendimentos
                          </span>
                        </div>
                      </Link>
                    </div>
                    {/* <div className="col-3">
                        <Link to="/cadastros">
                          <div className='text-center bg-light-success px-6 py-5 rounded-2 me-6'>

                            <BiCog size={44} color='#198c51'></BiCog>
                            <span className='text-success fw-semibold fs-3 ' color="success">
                              Cadastro
                            </span>
                          </div>
                        </Link>
                      </div> */}
                  </div>
                </div>
              </div>
              <div className='row py-4 '>
                <div className='col-12'>
                  <NewsFeed className='card-xxl-stretch' />
                </div>
              </div>

              <div className='row py-4 '>
                <div className='col-12'>
                  <ImoveisList className='card-xxl-stretch' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
