import { Loader } from '../../../../components/UI/Loader/Loader'
import { useState } from 'react'
import { BiAddToQueue } from 'react-icons/bi'
import { KTSVG } from '../../../../../_metronic/helpers'
import { MutationDeleteExplorerLayerUser, QueryGetExploreLayerUser } from '../../../../graphql/services/ExplorerLayerUser'
import { AdminAssociationCreateModal } from './createAssociationUser'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Spinner } from 'react-bootstrap'
import PaginationComponent from '../../../../components/UI/PaginationComponents'
import { errorHandler } from '../../../../graphql/errorHandler'

export function TablePermissions() {
  const { id: idLayer } = useParams<{ id: string }>()
  const [pagiancao, setPaginacao] = useState({ pagina: 0, quantidade: 10 })

  const { data } = QueryGetExploreLayerUser({
    variables: {
      pagination: {
        pagina: pagiancao.pagina,
        quantidade: pagiancao.quantidade
      },
      filtro: {
        fields: [
          {
            field: "id_layer",
            operator: "=",
            value: idLayer

          }
        ]
      }
    },
  })

  console.log(data)

  const { HandleDeleteExplorerLayerUser, loading, data: DataDelete } = MutationDeleteExplorerLayerUser()
  if (loading) {
    Swal.fire(
      'Enviando Informações...', '')
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {

        const result: any = await HandleDeleteExplorerLayerUser({ variables: { deleteExplorerLayerUserId: id } })
        if (!result.data) {
          let erroText = errorHandler(result.errors)
          Swal.fire('Erro!', erroText, 'warning')
        } else {
          Swal.fire('Sucesso!', ' Permissão deletada com sucesso.', 'success')
        }
      }
    })
      .catch((err) => {
        Swal.fire('Erro!', 'Ocorreu um erro inesperado, tente novamente mais tarde.', 'warning')
      })
  }

  const [showCreate, setShowCreate] = useState(false)


  function OpenModalCreate(id) {
    setShowCreate(true)
  }
  function CloseModalCreate() {
    setShowCreate(false)
  }

  if (!data) {
    return (
      <>
        <div className='card-header border-0 px-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Explorer Layers</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          ></div>
          <div className='all-end'>
            <button
              className='btn btn-light-primary btn-sm d-block'
              style={{ width: '100px !important' }}
              onClick={(e) => {
                e.preventDefault()
                OpenModalCreate(null)
              }}
            >
              <BiAddToQueue className='fs-2 me-1' />
              Incluir Permissão
            </button>
          </div>
        </div>
        <div className='separator my-2'></div>
        <div>
          <div className='table-responsive'>
            <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-50px'># ID</th>
                  <th className='min-w-150px'>Nome</th>
                  <th className='min-w-120px'>Schema</th>
                  <th className=''>Url</th>
                  <th className=''>Type</th>
                  <th className='min-w-100px text-end'>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='text-center' colSpan={6}>
                    <br />
                    <Spinner variant='success' animation='border' role='status'>
                      <span className='visually-hidden'>Carregando...</span>
                    </Spinner>
                    <br />
                    <span className='text-black font-semibold text-6xl px-2'>Carregando...</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className='card-header border-0 pt-6 px-6'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Permissões de Visualização dos Usuários</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        ></div>
        <div className='all-end'>
          <button
            className='btn btn-light-primary btn-sm d-block'
            style={{ width: '100px !important' }}
            onClick={(e) => {
              e.preventDefault()
              OpenModalCreate(null)
            }}
          >
            <BiAddToQueue className='fs-2 me-1' />
            Incluir Permissão
          </button>
        </div>
      </div>
      <div>
        <div className='table-responsive'>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-50px'><td># ID</td></th>
                <th className='min-w-150px'><td>Nome</td></th>
                <th className='min-w-120px text-center'>Situação</th>
                <th className='min-w-120px all-end'><td>Ações</td></th>
              </tr>
            </thead>
            <tbody>
              {data.GetExplorerLayerUser.result.length > 0 ? (
                data.GetExplorerLayerUser.result.map((layer) => {
                  const name = layer.produto ? layer.produto.nome : layer.users ? layer.users.name : 'Não informado';
                  return (
                    <tr key={layer.id}>
                      <td>{layer.id}</td>
                      <td>{name}</td>
                      <td className='text-center'>{layer.status === 1 ? (
                        <span className='badge badge-light-success fs-8 fw-bold my-2'>Ativo</span>
                      ) : (
                        <span className='badge badge-light-danger fs-8 fw-bold my-2'>Inativo</span>
                      )}</td>
                      <td className='all-end'>
                        <button
                          onClick={(e) => {
                            confirmDelete(layer.id)
                          }}
                          className='btn btn-icon btn-light-danger  btn-sm me-1' >
                          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                        </button>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td className='text-center' colSpan={6}>
                    <span>Nenhum registro adicionado.</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='all-end pb-6'>
          <PaginationComponent
            pagesInfo={data.GetExplorerLayerUser.pageInfo}
            setPagesInfo={(pagina: number, quantidade: number) => {
              setPaginacao({ pagina: pagina, quantidade: quantidade })
            }}
          ></PaginationComponent>
        </div>
      </div>
      {showCreate ? (
        <AdminAssociationCreateModal closeModal={CloseModalCreate} show={showCreate} />
      ) : (
        <></>
      )}
    </>
  )
}
