import {FillColor} from '../../../Config'
import { PositionZ } from '../../../Config/PositionZ'
import { Size } from '../../../Config/Size'
import { SizeText } from '../../../Config/SizeText'

type Props = {
  id: any
  defaultValue:any
}
export const TypeIconLayer: React.FC<Props> = ({id, defaultValue}) => {
  return (
    <>
      {/* <FillColor uuid={id} defaultValue={defaultValue}></FillColor> */}
      <Size uuid={id} defaulValue={defaultValue}></Size>
      {/* <PositionZ uuid={id} defaulValue={10} maxValue={100}></PositionZ> */}
      <SizeText uuid={id} defaulValue={14}></SizeText>
    
    </>
  )
}
