import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TypologiesPriceFormData, TypologiesPriceFormSchema } from './formValidations';
import { FormInputAndLabel } from '../../../../../../components/UI/Inputs/FormInputAndLabel';
import { useParams } from 'react-router-dom';
import { addTypologyPrice, getTypologyPriceByID, updateTypologyPrice } from '../../../../../../../services/http/urbit-gis/routes';
import Swal from 'sweetalert2';
import { MoneyInput } from '../../../../../../components/UI/Inputs/MoneyInput';
import { useEmpreendimentos } from '../../../context/useEmpreendimentos';
import { SelectTypologiy } from './SelectTypology';
import { TypologyPrice } from '../../../../../../../services/http/urbit-gis/routes/types';

interface Props {
  closeModal: () => void;
  show: boolean;
  id?: number;
  idTypology: number;
  handleRefetch: () => void;
  totalUnits: number;
  stockUnits: number;
  sales: number;
  area: number;
}

export function ModalHistoryUpdate({
  closeModal,
  show,
  id,
  handleRefetch,
  totalUnits,
  stockUnits,
  sales: salesDefault,
  area,
}: Props) {
  const { id: idLaunch } = useParams<{ id: string }>();
  const { typologies } = useEmpreendimentos();
  const [dataPrice, setDataPrice] = useState<TypologyPrice | null>(null);

  const { register, handleSubmit, formState: { errors }, setValue, reset, control, watch } = useForm<TypologiesPriceFormData>({
    resolver: zodResolver(TypologiesPriceFormSchema),
    defaultValues: {
      id_real_estate_launches: parseInt(idLaunch),
      id_real_estate_launches_typologies: null,
      price_date: '',
      price: null,
      price_m2: null,
      stock: stockUnits,
      sales: salesDefault,
      source: 'Urbit',
      launch_price: 0,  // Valor padrão para o checkbox
    },
  });

  const checkboxValue = watch('launch_price');
  const stock = watch('stock');
  const sales = watch('sales');
  const price = watch('price');
  const price_m2 = watch('price_m2');

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        const result = await getTypologyPriceByID(id);
        setDataPrice(result);
        reset({
          id_real_estate_launches: parseInt(idLaunch),
          id_real_estate_launches_typologies: result.id_real_estate_launches_typologies,
          price_date: result.price_date,
          price: result.price,
          price_m2: result.price_m2,
          stock: result.stock,
          sales: result.sales,
          source: 'Urbit',
          launch_price: result.launch_price || 0,  // Garantir valor padrão se result.launch_price for undefined
        });
      } catch (error) {
        console.error('Failed to fetch typology data', error);
      }
    };

    if (id) {
      fetchData(id);
    } else {
      setDataPrice(null);
    }
  }, [id, idLaunch, reset]);

  useEffect(() => {
    if (price && area) {
      setValue('price_m2', price / area);
    }
  }, [price, area, setValue]);

  useEffect(() => {
    if (price_m2 && area) {
      setValue('price', price_m2 * area);
    }
  }, [price_m2, area, setValue]);

  const onSubmit = async (data: TypologiesPriceFormData) => {
    try {
      const updatedData = { ...data, launch_price: checkboxValue ? 1 : 0 };
      const result = await updateTypologyPrice(id, updatedData)

      if (result && result.affected === 1) {
        Swal.fire({
          title: 'Sucesso!',
          text: id ? 'Preço atualizado com sucesso.' : 'Preço adicionado com sucesso.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          closeModal();
          reset();
          handleRefetch();
        });
      } else {
        throw new Error(result.message || 'Ocorreu um erro desconhecido');
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error.message || 'Houve um erro ao salvar o empreendimento.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleStockChange = (value: number) => {
    if (value >= 0 && value <= totalUnits) {
      setValue('stock', value);
      setValue('sales', Math.max(0, totalUnits - value));
    }
  };

  const handleSalesChange = (value: number) => {
    if (value >= 0 && value <= totalUnits) {
      setValue('sales', value);
      setValue('stock', Math.max(0, totalUnits - value));
    }
  };

  return (
    <Modal centered animation show={show} onHide={closeModal} size='sm'>
      <Modal.Header closeButton>
        <Modal.Title>{id ? 'Editar' : 'Cadastrar'}:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {!id && (
            <div className='mb-3'>
              <label className='form-label'>Tipologia</label>
              <Controller
                name='id_real_estate_launches_typologies'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectTypologiy data={typologies} selectedValue={value} onChange={onChange} />
                )}
              />
            </div>
          )}
          <div className='mb-3'>
            <label className='form-label'>Data</label>
            <input type='date' className='form-control' {...register('price_date')} />
            {errors.price_date && <span className='text-danger'>{errors.price_date.message}</span>}
          </div>

          <div className='mb-3'>
            <MoneyInput
              label='Preço'
              name='price'
              control={control}
              error={errors.price?.message}
            />
          </div>
          <div className='mb-3'>
            <MoneyInput
              label='Preço m²'
              name='price_m2'
              control={control}
              error={errors.price_m2?.message}
            />
          </div>

          <div className='mb-3 d-flex align-items-center'>
            <input
              type='checkbox'
              name='launch_price'
              checked={checkboxValue === 1}  // Garante que o valor padrão esteja refletido corretamente
              onChange={(e) => setValue('launch_price', e.target.checked ? 1 : 0)}
            />
            <span className='ms-2'>Preço de lançamento</span>
          </div>

          <div className='mb-3'>
            <span className='text-muted'>Total de unidades: {totalUnits}</span><br />
            <span className='text-muted'>Unidades em estoque: {stockUnits}</span>
          </div>

          <div className='row'>
            <div className='col-6'>
              <div className='mb-3'>
                <FormInputAndLabel
                  type='number'
                  className='form-control '
                  label='Estoque'
                  value={stock}
                  onChange={(e) => handleStockChange(Number(e.target.value))}
                  error={errors.stock?.message}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-3'>
                <FormInputAndLabel
                  type='number'
                  className='form-control '
                  disabled
                  label='Vendas'
                  value={sales}
                  onChange={(e) => handleSalesChange(Number(e.target.value))}
                  error={errors.sales?.message}
                />
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button type='button' onClick={closeModal} className='btn btn-danger me-2'>Cancelar</button>
            <button type='button' onClick={handleSubmit(onSubmit)} className='btn btn-success'>Salvar</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
