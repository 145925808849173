import React from 'react'

import {Link} from 'react-router-dom'
import {FaServer, FaCaretRight} from 'react-icons/fa'
import { MutationSetLogAcessoRecurso } from '../../../../../app/graphql/services/LogAcesso'

export function MenuRelatorios() {
const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  return (
    <>
      <span className='menu-link'>
        <span className='menu-icon'>
          <i className='ki-duotone ki-address-book fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        </span>
        <span className='menu-title'>Relatorios</span>
        <span className='menu-arrow'></span>
      </span>
      <div className='m-0'>
        <ul className='nav nav-pills nav-pills-custom flex-column border-transparent fs-5 fw-bold'>
          <li className='nav-item  mt-5'>
            <Link
              to='/meus-imoveis/relatorios'
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'relatorios', action: 'list', url: '/meus-imoveis/relatorios' })}
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-secondary p-0 border-0 '
            >
              <FaServer
                size={20}
                color='#8a8a8a'
                className='pe-2 pb-1 text-active-primary text-hover-secondary'
              />
              Imóveis
            </Link>
          </li>
          <li className='nav-item mt-2'>
            <Link
              to='/empreendimentos/relatorios'
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'empreendimentos', funcionalidade: 'relatorios', action: 'list', url: '/empreendimentos/relatorios' })}
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-secondary p-0 border-0 '
            >
              <FaCaretRight
                size={20}
                color='#8a8a8a'
                className='pe-2 pb-1 text-active-primary text-hover-secondary'
              />
              Empreendimentos
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
