import {useQuery} from '@apollo/client'
import {useEffect, useState} from 'react'
import Select from 'react-select'
import {GET_ESTADOS, GetEstadosResponse} from '../../../../graphql'


type Props = {
  SetValueEstado:any,
  defaultValueEstado:any,
  isMulti?:boolean
}

export const GetEstadoChosen: React.FC<Props> = ({ SetValueEstado,defaultValueEstado,isMulti=false }) => {


  let estadosArray = []
  const [estado, setEstado] = useState(defaultValueEstado)

  useEffect(() => {
    setEstado(estado)
    SetValueEstado(estado)

    return () => {
      setEstado('')
    }
  }, [estado, SetValueEstado])

  const {data} = useQuery<GetEstadosResponse>(GET_ESTADOS, {
    variables: {
      id: Number(estado),
    },
  })

  if (!data) {
    return (
      <div className='col-xs-12 col-md-4'>
        <div className='form-group'>
          <label className='col-form-label'>Estado</label>
          <Select
          
            options={estadosArray}
            isDisabled
            isMulti={isMulti || false}
            onChange={(e) => {
              setEstado(e.value)
            }}
            name='id_estado'
            placeholder='Estado'
          />
        </div>
      </div>
    )
  }

  data.GetEstados.map((Options) => {
    estadosArray.push({value: Options.id, label: Options.nome})
    return []
  })

  return (
    <>
      <Select
        options={estadosArray}
        onChange={(e) => {
          setEstado(e.value)
        }}
        value={estadosArray.filter((e) => e.value === parseInt(estado))[0]}
        name='id_estado'
        placeholder='Estado'
      />
    </>
  )
}
