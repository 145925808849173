import { gql } from "@apollo/client";

// Criação de um empreendimento com campos e com ID do usuario
export const CREATE_COMPOSICAO = gql`
mutation SetEmpreendimentos($empreendimentosInput: EmpreendimentosInput!) {
  SetEmpreendimentos(EmpreendimentosInput: $empreendimentosInput) {
    nome
  }
}
`
