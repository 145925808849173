import axios from 'axios'
import {HttpClient, HttpRequest, HttpResponse} from './type'

export class AxiosHttpClientAdapter implements HttpClient {
  async request(data: HttpRequest): Promise<HttpResponse<any>> {
    const response = await axios.request({
      url: data.url,
      method: data.method,
      data: data.body,
      headers: data.headers,
    })
    return {
      statusCode: response.status,
      body: response.data,
    }
  }
}
