import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useEffect, useState } from 'react'
import { Loader } from '../../../../../../components/UI/Loader/Loader'
import { GET_EMPREENDIMENTO, GET_EMPREENDIMENTO_IMOVEIS, QueryGetImoveis, SET_EMPREENDIMENTO_IMOVEL, TypesSetEmpreendimentoImovelResponse } from '../../../../../../graphql'
import Select from 'react-select';
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { GET_IMOVEL_CORRETORES } from '../../../../../../graphql/query/imovel-corretores'
import { errorHandler } from '../../../../../../graphql/errorHandler'

interface situacaoTerrenoProps {
  closeModal: any
  show: boolean
  idEmpreendimento: number
}

export function CreateForm({ closeModal, show, idEmpreendimento }: situacaoTerrenoProps) {


  const [bodyRequest, { data: dataEmpreendimentoImovel, loading, error }] = useMutation<TypesSetEmpreendimentoImovelResponse>(SET_EMPREENDIMENTO_IMOVEL,
    {
      refetchQueries: [GET_EMPREENDIMENTO_IMOVEIS],
    }
    )
  const {data} = QueryGetImoveis({ variables: { pagination: { pagina: 0, quantidade: 10 } } })

  const [success, setSuccess] = useState(0)
  const [idEmp, setIdEmp] = useState(0)

  useEffect(() => {
    if (dataEmpreendimentoImovel && success === 0) {
      closeModal()
      Swal.fire('Sucesso!', 'Imovel associado com sucesso.', 'success')
      setSuccess(1)
    }
    return ()=>{ }
  }, [dataEmpreendimentoImovel,success, closeModal, error, loading])


  useEffect(() => {
    setIdEmp( Number(idEmpreendimento))
    return ()=>{ }
  }, [idEmpreendimento])


  let options = []

  const [terreno, setTerreno] = useState('')
  
  async function handleSetTerreno(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    await bodyRequest({
      variables: {
        empreendimentoImovel: {
          id_empreendimento: idEmp,
          id_terreno: parseInt(terreno),
          id_incorporadora: 1,
          situacao: 1
        }
      }
    }).then((result) => {
      if (!result.data) {
        let erroText = errorHandler(result.errors)
        Swal.fire('Erro!', erroText, 'warning')
      }
    })
    .catch((err) => {
      Swal.fire('Erro!', 'Ocorreu um erro inesperado, tente novamente mais tarde.', 'warning')
    })
  }

  // Tratativa de loading
  if (!data) {
    return (
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Novo Imovel do Empreendimento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader text={'caregando...'}></Loader>
        </Modal.Body>
      </Modal>
    )
  }

  data.GetImoveis.result.map(Options => {
    options.push({ value: Options.id, label: Options.endereco })
    return []
  })

  return (
    <Modal centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Novo Imovel do Empreendimento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1>Selecione um terreno:</h1>
        <div>
          <form onSubmit={handleSetTerreno}>
          
            <Select options={options} onChange={(e) => { setTerreno(e.value) }} />
            <div className="all-end">
              <button type="button" className="btn btn-default mrt-1 me-2" onClick={() => closeModal()}>Cancelar</button>
              <button type="submit" className="btn btn-success mrt-1">Adicionar Terreno</button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
