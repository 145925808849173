import React, { useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { MutationDeleteProprietarios } from '../../../graphql/services/Proprietarios'
import { EditForm } from './Edit'
import { MutationSetLogAcessoRecurso } from '../../../graphql/services/LogAcesso'

type Props = {
  id: number
  setIdEdit?: any
}

export const Buttons: React.FC<Props> = ({ id, setIdEdit }) => {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()
  const { HandleDeleteProprietarios, loading } = MutationDeleteProprietarios()

  if (loading) {
    Swal.fire(
      'Enviando Informações...', '')
    Swal.showLoading()
  }

  const [idEditProprietario, setIdProprietario] = useState(null)
  const [showEdit, setShowEdit] = useState(false)
  console.log(idEditProprietario)
  function CloseModalEdit() {
    setShowEdit(false)
    setIdProprietario(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setIdProprietario(id)
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteProprietarios({ variables: { deleteProprietarioId: id } })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'proprietarios', action: 'delete', url: '/cadastro/proprietarios' });
          Swal.fire('Sucesso!', 'Proprietario deletado com sucesso.', 'success')
        }
      }
    })
  }


  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          onClick={(e) => {
            OpenModalEdit(id)
          }}
          className='btn btn-icon btn-light-success btn-sm me-2'>
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button>
        {showEdit ? <EditForm id={id} closeModal={CloseModalEdit} show={showEdit} /> : <></>}

        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          // disabled={loading}
          className='btn btn-icon btn-light-danger  btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
