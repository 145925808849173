export async function loadIsocrona(center: {lng: any; lat: any}, callback?: (arg0: any) => void) {
  if (!center.lng || !center.lat) {
    return
  }
  let dataPost = {
    locations: [[center.lng, center.lat]],
    area_units: 'm',
    range_type: 'time',
    range: [5 * 60, 10 * 60],
    interval: 5 * 60, // Aqui é o intervalo para cada geometria, por exemplo aqui é 5min a pé, o range é 15min (900), ou seja ele vai gerar 3 geometrias com 5min(300) , 10min(600) e 15min(900).
  }

  // ApiRoute(data, 'isochrones', 'foot-walking', function (data) {
  //   console.log(data)
  //   // Explorer.addIsocrona(data.features[0], `Tempo de  ${tempo}`)
  //   // Layout.setShowGeometryMenu(false)
  //   // setShowLoader(false)
  // })
  try {
    const fetchResponse = await fetch('https://routing.urbit.com.br/ors/v2/isochrones/foot-walking', {
    // const fetchResponse = await fetch('http://177.85.161.80:8080/ors/v2/isochrones/foot-walking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // referrerPolicy:  'unsafe-url',
      body: JSON.stringify(dataPost),
    })
    const data = await fetchResponse.json()
    if (callback) {
      callback(data)
    } else {
      return data
    }
  } catch (e) {
    return e
  }

  // .then((response) => response.json())
  // .then((data) => {
  //   if (data) {
  //       callback(data)
  //   }
  // })
}
