/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { AsideDefault } from '../../../_metronic/layout/components/aside/AsideDefault'
import { HeaderWrapperFluid } from '../../../_metronic/layout/components/header/HeaderWrapperFluid'
import { dataVideos } from './dataVideos'

function SupportPage() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(dataVideos);

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    const results = dataVideos.filter((video) =>
      video.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  };

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />
      <div className='wrapper mb-6 d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <div className="row mt-10 d-flex  all-center-between">
                <div className='col-sm-5 col-md-8'>
                  <h1>Tutoriais</h1>
                </div>
                <div className='col-sm-6 col-md-4'>
                  <form action="">
                    <form>
                      <input
                        type="text"
                        placeholder="Buscar com palavra chave"
                        className="form-control form-controlx"
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                    </form>
                  </form>
                </div>
              </div>

              <div className="row">
                {searchResults.length > 0 ? (
                  searchResults.map((video) => (
                    <div className="col-sm-6 mt-4 col-xxl-3 pb-5" key={video.id}>
                      <div className="card card-flush h-100">
                        <div className="card-body text-center pb-5">
                          <iframe title='IBTI' src={`https://player.vimeo.com/video/${video.link}?h=${video.link2}`} className='W-100' frameBorder={0} allow="autoplay; fullscreen" allowFullScreen></iframe>
                        </div>
                        <div className="card-footer d-flex flex-stack pt-0">
                          <div className="all-center-between w-100 flex-stack mb-1">
                            <div className="text-start">
                              <span className="fw-bold text-gray-800 cursor-pointer text-hover-primary fs-6 d-block">{video.title}</span>

                              <span className="text-gray-500 mt-1 fw-bold fs-6">{video.name}
                              </span>
                            </div>

                            <span className="text-gray-600 text-end fw-bold fs-6">{video.duration}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Nenhum vídeo disponível</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

const SuportWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <SupportPage />
    </>
  )
}

export { SuportWrapper }
