export var dadosTabelas = {}
dadosTabelas['cpf'] = {}
dadosTabelas['cnpj'] = {}

dadosTabelas['cpf']['BasicData'] = {
  key: 'BasicData',
  titulo: 'Dados Principais',
  coluns: ['Name', 'Age', 'Gender', 'DocumentFormatted', 'MotherName', 'HasObitIndication'],
  colunsItens: [],
}

dadosTabelas['cpf']['PhonesExtended'] = {
  key: 'ExtendedPhones',
  titulo: 'Telefones',
  coluns: ['Phones'],
  colunsItens: ['CountryCode', 'AreaCode', 'Number'],
}
dadosTabelas['cpf']['EmailsExtended'] = {
  key: 'ExtendedEmails',
  titulo: 'E-mails',
  coluns: ['Emails'],
  colunsItens: ['EmailAddress', 'Type'],
}
dadosTabelas['cpf']['AddressesExtended'] = {
  key: 'ExtendedAddresses',
  titulo: 'Endereços',
  coluns: ['Addresses'],
  colunsItens: [
    'Typology',
    'AddressMain',
    'Number',
    'Neighborhood',
    'City',
    'State',
    'ZipCode',
    'Complement',
    'Type',
  ],
}

dadosTabelas['cpf']['RelatedPeople'] = {
  key: 'RelatedPeople',
  titulo: 'Pessoas relacionadas',
  coluns: ['PersonalRelationships'],
  colunsItens: ['RelatedEntityName', 'RelationshipType', 'RelatedEntityTaxIdNumber'],
}

dadosTabelas['cpf']['RelatedPeopleEmails'] = {
  key: 'RelatedPeopleEmails',
  titulo: 'E-mail pessoas relacionadas',
  coluns: [],
  colunsItens: ['EmailAddress', 'RelationshipType'],
}

dadosTabelas['cpf']['RelatedPeoplePhones'] = {
  key: 'RelatedPeoplePhones',
  titulo: 'Telefones pessoas relacionadas',
  coluns: [],
  colunsItens: ['Number'],
}

dadosTabelas['cnpj']['BasicData'] = {
  key: 'BasicData',
  titulo: 'Dados Principais',
  coluns: [
    'TradeName',
    'OfficialName',
    'TaxRegime',
    'TaxIdStatusRegistrationDate',
    'HeadquarterState',
    'Age',
  ],
  colunsItens: [],
  colunTotal: null,
}
dadosTabelas['cnpj']['RelatedPeople'] = {
  key: 'RelatedPeople',
  titulo: 'Pessoas relacionadas',
  coluns: ['PersonalRelationships'],
  colunsItens: ['RelatedEntityName', 'RelationshipType', 'RelatedEntityTaxIdNumber'],
}

dadosTabelas['cnpj']['RelatedPeopleEmails'] = {
  key: 'RelatedPeopleEmails',
  titulo: 'E-mail pessoas relacionadas',
  coluns: [],
  colunsItens: ['EmailAddress', 'RelationshipType'],
}
dadosTabelas['cnpj']['RelatedPeoplePhones'] = {
  key: 'RelatedPeoplePhones',
  titulo: 'Telefones pessoas relacionadas',
  coluns: [],
  colunsItens: ['Number'],
}
dadosTabelas['cnpj']['PhonesExtended'] = {
  key: 'ExtendedPhones',
  titulo: 'Telefones',
  coluns: ['Phones'],
  colunsItens: ['CountryCode', 'AreaCode', 'Number'],
  colunTotal: 'TotalPhones',
}
dadosTabelas['cnpj']['EmailsExtended'] = {
  key: 'ExtendedEmails',
  titulo: 'E-mails',
  coluns: ['Emails'],
  colunsItens: ['EmailAddress', 'Type'],
  colunTotal: 'TotalEmails',
}
dadosTabelas['cnpj']['AddressesExtended'] = {
  key: 'ExtendedAddresses',
  titulo: 'Endereços',
  coluns: ['Addresses'],
  colunsItens: [
    'Typology',
    'AddressMain',
    'Number',
    'Neighborhood',
    'City',
    'State',
    'ZipCode',
    'Complement',
    'Type',
  ],
  colunTotal: 'TotalAddresses',
}
