import {Loader} from '../../../../../../components/UI/Loader/Loader'
import {
  MutationDeleteEmpresa,
  MutationPutEmpresa,
  MutationRecoveryEmpresa,
} from '../../../../../graphql/services/Empresas'
import Swal from 'sweetalert2'
import {TypesGetEmpresaByIDResponse} from '../../../../../graphql/types/TypesEmpresas'
import {FaBuildingCircleCheck, FaBuildingCircleXmark} from 'react-icons/fa6'

type Props = {
  data: TypesGetEmpresaByIDResponse
}

export function TabInfosProfile({data}: Props) {
  const {FormEmpresa, handleSubmit, loading, register} = MutationPutEmpresa()
  const {HandleDeleteEmpresa} = MutationDeleteEmpresa()
  const {HandleRecoveryEmpresa} = MutationRecoveryEmpresa()

  // Tratativa de loading
  if (!data) {
    return (
      <div
        className='tab-pane fade show active'
        id='tab1'
        role='tabpanel'
        aria-labelledby='tab1-tab'
      >
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>Atualizar detalhes da Empresa</h3>
            </div>
          </div>
          <div className='card-body pb-0'>
            <Loader text='Carregando...' />
          </div>
        </div>
      </div>
    )
  }

  function confirmDelete(id) {
    Swal.fire({
      title: 'Deseja desativar?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const result = await HandleDeleteEmpresa({variables: {deleteEmpresaId: id}})
        if (result) {
          Swal.fire('Sucesso!', 'Empresa desativada com sucesso.', 'success')
        }
      }
    })
  }

  function confirmRecovery(id) {
    Swal.fire({
      title: 'Deseja reativar?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      showCloseButton: true,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const result = await HandleRecoveryEmpresa({
          variables: {
            data: {
              id: id,
              situacao: 1,
            },
          },
        })
        if (result) {
          Swal.fire('Sucesso!', 'Empresa reativada com sucesso.', 'success')
        }
      }
    })
  }

  if (loading) {
    Swal.fire('Enviando Informações...', '')
    Swal.showLoading()
  }

  const onSubmit = async (data) => {
    FormEmpresa(data)
      .then((result) => {
        Swal.fire('Sucesso!', 'Informações atualizadas com sucesso.', 'success')
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  return (
    <div
      className='tab-pane fade pb-12  show active'
      id='tab1'
      role='tabpanel'
      aria-labelledby='tab1-tab'
    >
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title '>
            <h3>Atualizar detalhes da Empresa</h3>
          </div>
          <div className='card-toolbar'>
            {data.GetEmpresaById.situacao === 1 ? (
              <span className='badge fs-5 all-center badge-success'>Ativa</span>
            ) : (
              <span className='badge fs-5 all-center badge-warning'>Desativada</span>
            )}
          </div>
        </div>
        <div className='pb-0'>
          <form
            id='kt_account_profile_details_form'
            onSubmit={(e) => handleSubmit((data) => onSubmit(data))(e)}
          >
            <input
              type='text'
              hidden
              defaultValue={data.GetEmpresaById.id}
              {...register('id', {valueAsNumber: true, required: true})}
            />

            <div className='card-body'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Nome Completo
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        {...register('nome')}
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        defaultValue={data?.GetEmpresaById.nome}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Nome Fantasia</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        {...register('nome_fantasia')}
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        defaultValue={data?.GetEmpresaById.nome_fantasia}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Email</label>
                <div className='col-lg-8 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    {...register('email')}
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    defaultValue={data?.GetEmpresaById.email}
                  />
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Telefone</span>
                </label>
                <div className='col-lg-8 fv-row fv-plugins-icon-container'>
                  <input
                    type='tel'
                    {...register('telefone')}
                    className='form-control form-control-lg form-control-solid'
                    defaultValue={data?.GetEmpresaById.telefone}
                  />
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>CNPJ</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        {...register('cnpj')}
                        required
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        defaultValue={data?.GetEmpresaById.cnpj}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>CEP</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        {...register('cep')}
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        defaultValue={data?.GetEmpresaById.cep}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>Endereço</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        {...register('endereco')}
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        defaultValue={data?.GetEmpresaById.endereco}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>Complemento</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        {...register('complemento')}
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        defaultValue={data?.GetEmpresaById.complemento}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {data.GetEmpresaById.situacao === 1 ? (
                <button
                  disabled={loading}
                  type='button'
                  onClick={() => confirmDelete(data.GetEmpresaById.id)}
                  className='btn btn-light-danger me-4'
                >
                  <FaBuildingCircleXmark size={20} /> <span>Desativar Empresa</span>
                </button>
              ) : (
                <button
                  disabled={loading}
                  type='button'
                  onClick={() => confirmRecovery(data.GetEmpresaById.id)}
                  className='btn btn-light-warning me-4'
                >
                  <FaBuildingCircleCheck size={20} /> <span>Reativar Empresa</span>
                </button>
              )}
              <button
                type='submit'
                className='btn btn-primary'
                id='kt_account_profile_details_submit'
              >
                Salvar mudanças
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
