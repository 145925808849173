import {useQuery} from '@apollo/client'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { APiUrbit } from '../../../../services/api/apiUrbit'

type Props = {
  // idGrupo: number
  onChange?:any
  valueDefault:any
  // idCidade:number
}

interface TableData {
  table_name: string;
  table_schema: string;
}

interface TableColunas {
  character_maximum_length: number;
  column_name: string;
  data_type: string;
  defaultValue?:string
}

export const GetTablesGisChosen: React.FC<Props> = ({onChange,valueDefault}) => {
  let options = []
  const [dataTables, setDataTables] = useState<TableData[] | null>(null);
  const [value, setValue] = useState('')


  useEffect(() => {
    setValue(valueDefault)
  }, [valueDefault])

  useEffect(() => {
    const getTables = async () => {
      const api = new APiUrbit()
      const result = await api.get('explorer/tables')
      setDataTables(result)
    }
    getTables()
  }, [])

  if (!dataTables) {
    return (
      <Select
        options={options}
        isDisabled={false}
        placeholder='Selecione o grupo'
      />
    )
  }

  options[0] = {label: '--Selecione--', value: 0}
  dataTables.map((option, index) => (
    options[index] = {
      value: option.table_schema + '.' + option.table_name,
      label:option.table_schema + '.' + option.table_name
    }
  ))
  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      padding:'5px' ,
    })
  }

  let defaultv = null
  if(value){
    defaultv = options.filter((option, index) => {
        return option.value === value
      })
  }
  return (
    <Select
      options={options}
      // defaultValue={ {label: 'Todas as informações', value: 0}}
      // styles={CustomStyle}
      value={ defaultv ? defaultv[0] :  {label: 'Todas as informações', value: 0} }
      onChange={(e) => {
        let retorno = options.filter((option, index) => {
          return option.value === e['value']
        })[0]
        onChange(retorno.value)
        setValue(retorno)
      }}
      placeholder='Selecione o grupo'
    />
  )
}
