import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  GoogleMap,
  DrawingManager,
  useLoadScript,
  Autocomplete,
  InfoBox,
  Libraries,
  Marker,
  Polyline,
  InfoWindow,
  MarkerF,
  LoadScriptProps,
} from '@react-google-maps/api'
import {GoogleMapsOverlay} from '@deck.gl/google-maps'
import {useExplorer} from '../../../../../hooks/useExplorer'
import {Explorer} from './Explorer'
import * as turf from '@turf/turf'
import {Layout} from './Layout'
import {useMapaConfig} from '../../../../../hooks/useMapaConfig'
import {Intersect} from './Operacoes/Intersect'
import {formatAdressSeachGoogleMapa} from '../common'
import {MapTypes} from './MapTypes'
import {Isocrona} from './Operacoes/Isocrona'
import {Isocota} from './Operacoes/Isocota'
import {OpenLocal} from './OpenLocal'
import {ContexMenu} from './LayoutControl/MenuContext/MenuContext'
import {Raio} from './Operacoes/Raio'
import {CreateStyles} from './Style/MapStyles'
import {BiMapPin} from 'react-icons/bi'

import {ContextMenuGeometryArea} from './LayoutControl/MenuContext/MenuContextGeometryArea'
import {decodeUrl} from '../../../../components/util'
import {APiUrbit} from '../../../../services/api/apiUrbit'
import {Endereco} from './ComponentsControl/EnderecoControl/endereco'
import {Composicao} from './ComponentsControl/ComposicaoControl/composicao'
import {ContextMenuGeometry} from './LayoutControl/MenuContext/MenuContextGeometry'
import {Loader} from '../../../../components/UI/Loader/Loader'
import {Legenda} from './Legenda'
import {useLocation} from 'react-router-dom'
import {QueryGetEmpreendimentos, QueryGetImoveis} from '../../../../graphql/services'
import {CreateStreeStyle} from './Style/StreetStyle'
import {mapStore} from '../../../../../store/mapStore'
import {MapControll} from '../../../../../store/mapControll'

import Busca from './Busca'

// declare type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[]

export function MapsGoogle({children}) {
  const {setMap, getMap, changeStyle, center, setDraw} = mapStore()
  const {onSetExplorer} = useExplorer()
  const {mapaConfig} = useMapaConfig()
  const [marker, setMarker] = useState([])
  const mapref = useRef()
  const goto = Explorer.getGoTo()
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  let ids = []
  if (searchParams.get('ids')) {
    const idListDecoded = decodeUrl(searchParams.get('ids'))
    ids = idListDecoded.split(',')
  }
  let eids = []
  if (searchParams.get('eids')) {
    const idListDecoded = decodeUrl(searchParams.get('eids'))
    eids = idListDecoded.split(',')
  }

  // useEffect(()=>{
  //   setMarker([])
  // }, [])
  // if (Object.entries(Explorer.map).length > 0) {
  //   if (Object.entries(goto).length > 0) {
  //     ;(Explorer.map as any).panTo(new google.maps.LatLng(goto['lat'], goto['lng']))
  //   }
  // }

  // MapControll.panTo({lat: -23.6, lng: -46.6})

  const {data: dataEmp} = eids
    ? QueryGetEmpreendimentos({
        variables: {
          pagination: {pagina: 0, quantidade: 10},
          filtro: {
            fields: {
              field: ['id'],
              operator: 'IN',
              value: eids,
            },
          },
        },
      })
    : {data: null}

  const {data} = ids
    ? QueryGetImoveis({
        variables: {
          pagination: {pagina: 0, quantidade: 10},
          filtro: {
            fields: {
              field: ['id'],
              operator: 'IN',
              value: ids,
            },
          },
        },
      })
    : {data: null}

  const deckOverlay = new GoogleMapsOverlay({
    debug: true,
    style: {zIndex: 30},
    pickingRadius: 10,
    onmousemove: () => {
      console.log('onmouse')
    },
  })
  deckOverlay.setProps({
    layers: [Explorer.defaultLayers()],
  })

  const [showInfoWindow, setShowInfoWindow] = useState(false)

  const onLoad = useCallback(
    (map) => {
      deckOverlay.setMap(map)
      if (deckOverlay) {
        setMap(deckOverlay)
      }

      // TODO : Remover Explorer
      onSetExplorer({decker: deckOverlay, map: map})
      Explorer.setMap(map)
      Explorer.setDeckgl(deckOverlay)
      // Até aqui

      CreateStyles(map)
      CreateStreeStyle(map)
      changeStyle('simples')

      const local = new OpenLocal()
      if (data) {
        local.open(data)
      }
      if (dataEmp) {
        local.openEmpreendimento(dataEmp)
      }
      //      map.getDiv().addEventListener("mousemove", function(e) {
      //       // console.log(e)

      // //       if (getMode() === 'DRAW_CIRCLE'){
      // //         // console.log(e)

      // //         // console.log(getMap()._map        )
      // //         const map = getMap()._map

      //         const mapDiv = map.getDiv();
      //         const mapRect = mapDiv.getBoundingClientRect();
      //         // console.log(mapRect)

      //         // console.log('x',e.pageX - mapRect.x  - 256  )
      //         // console.log('y',e.pageY - mapRect.y  )
      //         const offsetX = e.pageY - mapRect.y  -256
      //         const offsetY = e.pageX - mapRect.x

      //         // const projection = map.getProjection()
      //         // const latLng = projection.fromPointToLatLng(new google.maps.Point(offsetX, offsetY));

      // // console.log("Latitude: " + latLng.lat());
      // // console.log("Longitude: " + latLng.lng());

      // //       }
      //     });
      // }
      // document.getElementById('urbit-map')
      // google.maps.event.addListener(map, "mousemove", (event) => {
      //   console.log('mousemove')
      //   // Use event.clientX e event.clientY para obter a posição do mouse
      //   const mouseX = event.clientX;
      //   const mouseY = event.clientY;

      //   // Faça algo com as coordenadas do mouse aqui
      //   console.log("Mouse move:", mouseX, mouseY);
      // });

      // var evtListnr = google.maps.event.addListener(map,"click",function(evt) {
      //   bounds.extend(evt.latLng);
      //   addLatLng(evt);
      //   if (poly.getPath().getLength() == 5) {
      //     google.maps.event.removeListener(evtListnr);
      //   }
      // });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    null
  )
  if (!data) {
    return <Loader text={'Carregando..'}></Loader>
  }
  if (!dataEmp) {
    return <Loader text={'Carregando..'}></Loader>
  }
  let isLoaded = true

  const onLoadDraw = (drawingManager) => {
    let infoWindow = new google.maps.InfoWindow()
    Explorer.setDraw(drawingManager)
    MapControll.setDraw(drawingManager)
  }

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    let GeoJSON = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [],
      },
      properties: {area: '0'},
    }

    const firstPath = polygon.getPath().getArray()[0]

    let coordenadas = []
    for (let point of polygon.getPath().getArray()) {
      coordenadas.push([point.lng(), point.lat()])
    }
    coordenadas.push([firstPath.lng(), firstPath.lat()])
    GeoJSON.geometry.coordinates.push(coordenadas)
    const areaTotal = turf.area(GeoJSON.geometry)
    const centroid = turf.centroid(GeoJSON.geometry)
    if (Layout.getComposicaoStatus()) {
      Explorer.setDrawMode('')

      const lng = centroid.geometry.coordinates[0]
      const lat = centroid.geometry.coordinates[1]
      const geocoder = new google.maps.Geocoder()

      if (lat && lng) {
        const latLng = new google.maps.LatLng(lat, lng)

        geocoder.geocode({location: latLng}, async function (results, status) {
          if (status === 'OK') {
            let municipio = Endereco.getMunicipio()
            if (municipio === '') {
              let municipioCidade = await Endereco.getMunicipioAPI(lat, lng)
              municipio = municipioCidade
            }
            var place = results[0]
            var adr_elements = formatAdressSeachGoogleMapa(place)
            let lote = {
              endereco: adr_elements['logradouro'] + ',' + adr_elements['numero'],
              numero: adr_elements['numero'],
              logradouro: adr_elements['logradouro'],
              bairro: adr_elements['bairro'],
              estado: adr_elements['estado'],
              cidade: adr_elements['cidade'],
              id_cidade: municipio.id,
              id_estado: municipio.id_estado,
              complemento: '',
              cep: adr_elements['cep'],
              area_lote: areaTotal ? areaTotal.toFixed(0) : 0,
              numero_contribuinte: '',
              setor_quadra: '',
              dc_tipo_us: '',
              codlog: '',
              tipo_de_terreno: '',
              numero_lote: '',
              geom: GeoJSON,
              testada_para_calculo: '',
              valor_do_m2_do_terreno: '',
              cd_condomi: '',
              quantidade_de_pavimentos: '',
              geom_closest_point: centroid,
              polygon: polygon,
              latitude: lat,
              longitude: lng,
            }
            Composicao.add(lote)
          }
        })
      }
    } else {
      let totalLayer = Explorer.countQtdeLayer('LayerPolygon')
      GeoJSON.properties.area = areaTotal.toFixed(2) + 'm2'
      Explorer.addGeom(GeoJSON, `Geometria  ${totalLayer}`)
      polygon.setMap(null)
      Explorer.clearDrawMode()
    }
  }

  const onCircleComplete = (polygon) => {
    document.querySelectorAll('.btn-action-map').forEach(function (x) {
      x.classList.remove('active')
    })

    // Explorer.clearDrawMode()
    const radius = polygon.getRadius()
    const center = polygon.getCenter()
    const point = turf.point([center.lng(), center.lat()])
    const geom = turf.circle(point, radius, {units: 'meters'})
    // MapControll.setInfoBox('distancia', '5m', true)
    // const totalLayer = Explorer.countQtdeLayer('LayerPolygon')
    Explorer.addGeom(geom, `Raio de ${Number(radius).toFixed(0)}m`)
    polygon.setMap(null)
    Explorer.clearDrawMode()
    MapControll.setMode('')
  }

  const onPolylineComplete = (polygon) => {
    Explorer.clearDrawMode()
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onRectangleComplete = (polygon) => {
    // const bboxPolygon = polygon.getBounds()
    // var bbox = [bboxPolygon.east, bboxPolygon.north, bboxPolygon.south, bboxPolygon.west]
    // var poly = turf.bboxPolygon(bbox)
    // let totalLayer = Explorer.countQtdeLayer('LayerPolygon')

    // // const data = geom

    // let layer = {}
    // layer['uuid'] = uuid()
    // layer['visible'] = true
    // layer['data'] = geom
    // layer['type'] = 'LayerPolygon'
    // layer['layout'] = ''
    // layer['nome'] = `Geometria  ${totalLayer}`
    // layer['descricao'] = `Local  ${totalLayer}`
    // // layer['tile'] = `https://tile.urbit.com.br/tile/${newLayer[0].source}/{z}/{x}/{y}.pbf?s=${newLayer[0].schema_table}`
    // layer['paint'] = {'fill-color': '#5dd059', 'fill-opacity': 0.5}

    // onAddLayer(layer)
    // polygon.setMap(null)

    // document.getElementById('sub-menu-relatorio').classList.remove('sub-menu-hide')
    // document.getElementById('sub-menu-relatorio').classList.add('sub-menu-show')
    Explorer.clearDrawMode()
  }
  const onMarkerComplete = (marker) => {
    let totalIcon = Explorer.countQtdeLayer('LayerIcon')
    const lat = marker.position.lat()
    const lng = marker.position.lng()
    Explorer.addMarker(lat, lng, `Local ${totalIcon}`, `Local ${totalIcon}`)
    const latlng = new google.maps.LatLng(lat, lng)
    Layout.setShowTerritorioMenu(true, latlng)
    marker.setMap(null)
    Explorer.clearDrawMode()
  }

  const onLoadInfobox = (infoBox) => {}

  async function print() {
    const eltoPrint = document.querySelector('div#urbit-map canvas')
    // const image =  eltoPrint.toDataURL('image/png', 1.0)
    // // await exportImage(eltoPrint,'teste')
    // // const image = canvas.toDataURL('image/png', 1.0)
    // // document.getElementById('map_image').setAttribute('src',image)
    // // await downloadImage(image, imageFileName)
    // const fakeLink = window.document.createElement('a')
    // // fakeLink.style = "display:none;";
    // fakeLink.download = 'teste'

    // fakeLink.href = image

    // document.body.appendChild(fakeLink)
    // fakeLink.click()
    // document.body.removeChild(fakeLink)

    // fakeLink.remove()
  }
  let count = 0
  let result = 0
  if (typeof google === 'undefined') {
    return <Loader text={'Carregando'}></Loader>
  }

  // console.log(getMode())
  const renderMap = () => {
    return (
      <>
        {/* <button onClick={print}>Printasasds</button> */}
        <GoogleMap
          ref={mapref}
          onLoad={onLoad}
          onDragStart={() => {}}
          //   onMouseUp={(event)=>{
          //     // console.log(event)
          //     // console.log(`event.latLng keyUP`)
          //     // console.log(event.latLng.lat())
          //     // console.log(event.latLng.lng())
          //     // console.log(event.pixel)
          //     const map = getMap()._map
          // // const projection = map.getProjection()
          // // // console.log(projection)
          // // const x = event.pixel.x
          // // const y = event.pixel.y

          //       // Converte as coordenadas de pixel em coordenadas de latitude e longitude
          // // const latLng = projection.fromPointToLatLng(new google.maps.Point(x, y));
          // // console.log("Latitude: " + latLng.lat());
          // // console.log("Longitude: " + latLng.lng());

          // // const point = projection.fromLatLngToPoint(new google.maps.LatLng( latLng.lat(),  latLng.lng()));
          // // console.log(point)

          //   }}
          onClick={(event) => {
            MapControll.mapActionsOnClick(event, (markers, state) => {})
            Layout.setShowContextMenu(false, null)
            Layout.setShowGeometryMenu(false, null)
            Layout.setShowGeometryMenuArea(false, null)
            Layout.setShowClickInfo(false)
            // Explorer.setGoTo(event.latLng.lat(),event.latLng.lng())
          }}
          onRightClick={(event) => {
            Layout.setShowContextMenu(true, event.latLng)
            Layout.setShowGeometryMenu(false, event.latLng)
          }}
          onIdle={() => {
            var latLng = Explorer.getCenter()
            if (latLng) {
              Explorer.setGoTo(latLng.lat(), latLng.lng())
            }
            if (typeof Explorer.map !== 'undefined') {
              if (Object.keys(Explorer.map).length > 0) {
                const bound = (Explorer.map as any).getBounds().toJSON()
                if (bound) {
                  if (!isNaN(bound.west)) {
                    const bbox =
                      bound.west + ',' + bound.south + ',' + bound.east + ',' + bound.north
                    const api = new APiUrbit()
                    const url = 'municipio-info?bbox=' + bbox
                    api.get(url, function (retorno) {
                      if (retorno.length === 1) {
                        if (retorno.length > 0 ){
                          Endereco.setEndereco({
                            municipio: retorno,
                            enderecoAtivo: retorno[0].id,
                          })
                        }
                      } else {
                        Endereco.setEndereco({
                          municipio: retorno,
                          enderecoAtivo: retorno[0].id,
                        })
                      }
                    })
                  }
                }
              }
            }
          }}
          options={{
            // mapId: 'ca5b3376f1ef5133',
            // mapId: 'abd5c4561f0720d4',
            mapId: 'abd5c4561f0720d4',
            mapTypeControl: false,

            mapTypeControlOptions: {
              mapTypeIds: [
                'roadmap',
                'satellite',
                'hybrid',
                'noturno',
                'comercios_map',
                'onibus_map',
                'ligth',
                'a28cbce2ae82f5e3',
              ],
              style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },

            streetViewControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            fullscreenControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            // tilt: 10,
            panControl: true,
          }}
          id='urbit-map'
          mapContainerStyle={{
            height: '100%',
            width: '100%',
          }}
          zoom={16}
          tilt={0}
          center={center}
          onMouseMove={(event) => {
            // console.log('mousemoveevent')
            MapControll.mapActionsOnMove(event, (markers, state) => {
              console.log('asdas')
            })
          }}
        >
          <Busca></Busca>

          {/* <button
              className='d-flex p-3 btn btn-success btn-action-map marker-add'
              onClick={(e) => {
                console.log(e)
                // onClickMapDraw(e, 'marker')
              }}
            >
              {' '}
              <BiMapPin className='fs-1 mx-2'></BiMapPin>
            </button> */}
          <DrawingManager
            onLoad={onLoadDraw}
            onPolygonComplete={onPolygonComplete}
            onCircleComplete={onCircleComplete}
            onPolylineComplete={onPolylineComplete}
            onMarkerComplete={onMarkerComplete}
            options={{
              drawingControl: false,
              drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
              },
              polygonOptions: {
                fillColor: `#20a745`,
                fillOpacity: 0.6,
                strokeWeight: 2,
                clickable: false,
                editable: true,
                zIndex: 1,
              },
              rectangleOptions: {
                fillColor: `#20a745`,
                fillOpacity: 0.6,
                strokeWeight: 2,
                clickable: false,
                editable: true,
                zIndex: 1,
              },
              circleOptions: {
                fillColor: `#20a745`,
                fillOpacity: 0.6,
                strokeWeight: 2,
                clickable: false,
                editable: true,
                zIndex: 1,
              },
            }}
          />
          <MapTypes></MapTypes>
          <Legenda></Legenda>
          {mapaConfig.showContextMenu ||
          mapaConfig.showGeometryContextMenu ||
          mapaConfig.showGeometryContextMenuArea ? (
            <InfoBox
              onLoad={onLoadInfobox}
              options={{closeBoxURL: '', enableEventPropagation: true}}
              position={mapaConfig.latLng ? mapaConfig.latLng : ''}
            >
              <>
                {mapaConfig.showGeometryContextMenu ? (
                  <ContextMenuGeometry
                    showHide={false}
                    latLng={mapaConfig.latLng}
                    geomList={mapaConfig.geomList}
                    layers={Explorer.getLayers()}
                  ></ContextMenuGeometry>
                ) : (
                  <></>
                )}
                {mapaConfig.showGeometryContextMenuArea ? (
                  <ContextMenuGeometryArea
                    showHide={false}
                    latLng={mapaConfig.latLng}
                    geom={mapaConfig.geom}
                    geomList={mapaConfig.geomList}
                    layers={Explorer.getLayers()}
                  ></ContextMenuGeometryArea>
                ) : (
                  <></>
                )}
                {mapaConfig.showContextMenu ? (
                  <ContexMenu showHide={false} latLng={mapaConfig.latLng}></ContexMenu>
                ) : (
                  <></>
                )}
              </>
            </InfoBox>
          ) : (
            <></>
          )}
        </GoogleMap>
        {children}
        <Raio
          latLng={
            mapaConfig.latLng && Object.entries(mapaConfig.latLng).length > 0
              ? mapaConfig.latLng
              : null
          }
          defaultValue={500}
          addMarker={mapaConfig.showGeometryContextMenu ? false : true}
        ></Raio>
        <Isocrona
          latLng={
            mapaConfig.latLng && Object.entries(mapaConfig.latLng).length > 0
              ? mapaConfig.latLng
              : null
          }
          defaultValue={5}
          addMarker={mapaConfig.showGeometryContextMenu ? false : true}
        ></Isocrona>
        <Isocota
          latLng={
            mapaConfig.latLng && Object.entries(mapaConfig.latLng).length > 0
              ? mapaConfig.latLng
              : null
          }
          defaultValue={5}
          addMarker={mapaConfig.showGeometryContextMenu ? false : true}
        ></Isocota>
        <Intersect
          latLng={
            mapaConfig.latLng && Object.entries(mapaConfig.latLng).length > 0
              ? mapaConfig.latLng
              : null
          }
          defaultValue={5}
          addMarker={mapaConfig.showGeometryContextMenu ? false : true}
        ></Intersect>
        {showInfoWindow && (
          <InfoWindow position={center} onCloseClick={() => setShowInfoWindow(false)}>
            <div>
              <p>Distância do raio: {} metros</p>
            </div>
          </InfoWindow>
        )}
        <div id='content'> </div>
      </>
    )
  }

  // if (loadError) {
  //   return <div>Map cannot be loaded right now, sorry.</div>
  // }

  return isLoaded ? renderMap() : <div>Carregando...</div>
}
