import { useEffect, useRef, useState } from "react"
import { Modal } from "react-bootstrap"
import { FormInputAndLabel } from "../../../../components/UI/Inputs/FormInputAndLabel"
import { KTSVG } from "../../../../../_metronic/helpers"
import Swal from "sweetalert2"
import { MutationPutTermo, QueryGetTermoByID } from "../../../graphql/services/Termos"
import JoditEditor from "jodit-react"

type Props = {
  id: number
}

export const ModalPutTermos: React.FC<Props> = ({ id }) => {
  const [show, setShow] = useState(false);
  const { data } = QueryGetTermoByID({
    variables: {
      termo: {
        id: id
      }
    }
  })

  useEffect(() => {
    if (data && data.GetTermoById) {
      const defaultValue = data.GetTermoById.texto || '<p>Seu texto inicial aqui...</p>';
      setContent(defaultValue);
    }
  }, [data]);

  const {
    handleSubmit,
    loading, register, setValue, FormPutTermo,
  } = MutationPutTermo();

  const editor = useRef(null)

  const [content, setContent] = useState('');

  const HandleUpdate = (newContent) => {
    setContent(newContent);
    setValue('texto', newContent)
  };

  const config = {
    readonly: false,
    heigth: 600
  }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  function closeModal() {
    setShow(false);
  }

  function openModal() {
    setShow(true);
  }


  if (loading) {
    Swal.fire(
      'Enviando Informações...', '')
    Swal.showLoading()
  }


  const onSubmit = async (data) => {
    data.texto = content;
    FormPutTermo(data)
      .then((result) => {
        Swal.fire('Sucesso!', 'Informações atualizadas com sucesso.', 'success');
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error');
      });
  };

  if (!data) {
    return (
      <button
        onClick={openModal}
        className='btn btn-icon btn-light-success btn-sm me-2'
      >
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </button>
    )
  }
  return (
    <>
      <button
        onClick={openModal}
        className='btn btn-icon btn-light-success btn-sm me-2'
      >
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </button>

      <Modal centered animation show={show} onHide={() => closeModal()} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Termo:</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-lg'>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <form onSubmit={(e) => handleSubmit((data) => onSubmit(data))(e)}>
                  <div className="row mb-4">
                    <input type='hidden' className='form-control form-control-solid' value={id}
                      {...register('id', { required: true, valueAsNumber: true, max: 100 })} />
                    <div className="col-6">
                      <FormInputAndLabel
                        label="Nome:"
                        placeholder="Nome do Termo"
                        className="form-control form-control-solid"
                        register={{ ...register('nome') }}
                        defaultValue={data.GetTermoById.nome ? data.GetTermoById.nome : ''}
                      />
                    </div>
                    <div className="col-6">
                      <FormInputAndLabel
                        label="Titulo:"
                        placeholder="Titulo do Termo"
                        className="form-control form-control-solid"
                        register={{ ...register('titulo') }}
                        defaultValue={data.GetTermoById.titulo ? data.GetTermoById.titulo : ''}
                      />
                    </div>


                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className='form-group'>
                        <label className='col-form-label'>Descrição:</label>
                        <JoditEditor
                          value={content}
                          ref={editor}
                          config={config}
                          onBlur={HandleUpdate}
                          onChange={(newContet) => {
                            setValue('texto', newContet)
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-100  mt-4 all-end">
                    <button type="button" onClick={() => closeModal()} className="btn btn-danger me-4">Cancelar</button>
                    <button type="submit" className="btn btn-success">Salvar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
