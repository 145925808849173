import { z } from "zod"

export const FormEmpreendimentoFormSchema = z.object({
    // id: z.number(),
    nome: z.string().nonempty('Digite o nome do empreendimento!'),
    endereco: z.string().nonempty('Digite o nome do empreendimento!'),
    // area_total_terreno: z.number(),
    // vgv: z.number(),
    // area_total_privativa: z.number(),
    // preco_medio_m2: z.number(),
    // responsavel: z.string(),
    // arquiteto: z.string(),
    // tag: z.string(),
    // parceiro: z.number(),
    // parceiro_nome: z.string(),
    // parceiro_percentual: z.number(),
    // parceiro_percentual_proprio: z.number(),
    // descricao: z.string(),
    // status: z.number(),
    // endereco: z.string()
    // .nonempty('o campo é obrigatório'),
    // complemento: z.string(),
    // bairro: z.string(),
    // cidade: z.number(),
    // estado: z.number(),
    // id_cidade: z.number(),
    // id_estado: z.number(),
    // status_cliente: z.number(), //status-cliente
    // numero: z.number(),
    // cep: z.string(),
    // area_iptu: z.string(),
    // area_real: z.string(),
    // zoneamento: z.string(),
    // numero_contribuinte: z.string(),
    // numero_matricula: z.string(),
    // setor_quadra: z.string(),
    // uso_atual_terreno:  z.string(),
    // macroarea: z.string(),
    // macrozona: z.string(),
    // codlog: z.string(),
    // fpr: z.string(),
    // fpnr: z.string(),
    // cabas: z.string(),
    // camax: z.string(),
    // metragem: z.string(),
    // testada: z.string(),
    // responsavel: z.string(),
    // operacao_urbana: z.string(),
    // quadro14: z.string(),
    // preco_m2:  z.string(),
    // preco_total:  z.string()
})


export type FormEmpreendimentoFormData = z.infer<typeof FormEmpreendimentoFormSchema>


export const FormEmpreendimentoFormUpdateSchema = z.object({
    id: z.number(),
    nome: z.string().nonempty('Digite o nome do empreendimento!'),
    endereco: z.string().nonempty('Digite o nome do empreendimento!'),
    area_total_terreno: z.number(),
    vgv: z.number(),
    area_total_privativa: z.number(),
    preco_medio_m2: z.number(),
    responsavel: z.string(),
    arquiteto: z.string(),
    tag: z.string(),
    situacao_id: z.number(),
    // parceiro: z.number(),
    parceiro_nome: z.string(),
    parceiro_percentual: z.number().optional(),
    parceiro_percentual_proprio: z.number().optional(),
    // descricao: z.string(),
    // status: z.number(),
    // endereco: z.string()
    // .nonempty('o campo é obrigatório'),
    // complemento: z.string(),
    // bairro: z.string(),
    // cidade: z.number(),
    // estado: z.number(),
    // id_cidade: z.number(),
    // id_estado: z.number(),
    // status_cliente: z.number(), //status-cliente
    // numero: z.number(),
    // cep: z.string(),
    // area_iptu: z.string(),
    // area_real: z.string(),
    // zoneamento: z.string(),
    // numero_contribuinte: z.string(),
    // numero_matricula: z.string(),
    // setor_quadra: z.string(),
    // uso_atual_terreno:  z.string(),
    // macroarea: z.string(),
    // macrozona: z.string(),
    // codlog: z.string(),
    // fpr: z.string(),
    // fpnr: z.string(),
    // cabas: z.string(),
    // camax: z.string(),
    // metragem: z.string(),
    // testada: z.string(),
    // responsavel: z.string(),
    // operacao_urbana: z.string(),
    // quadro14: z.string(),
    // preco_m2:  z.string(),
    // preco_total:  z.string()
})


export type FormEmpreendimentoUpdateFormData = z.infer<typeof FormEmpreendimentoFormUpdateSchema>


export const FormEmpreendimentoProprietariosSchema = z.object({
    id: z.number(),
    nome: z.string(),
    // email: z.string(),
    // telefone: z.string(),
    // cnpj: z.string(),
    // cpf: z.string(),
    // endereco: z.string(),
    // numero: z.string(),
    // cep: z.string(),
    // complemento: z.string(),
    // estado: z.string(),
    // cidade: z.string(),
    // empresa: z.string(),
    // situacao: z.string()
})

export type FormEmpreendimentoProprietariosFormData = z.infer<typeof FormEmpreendimentoProprietariosSchema>
