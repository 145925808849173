import { gql } from "@apollo/client";

export const SET_IMOVEL_CONDICAO_COMERCIAL = gql`
mutation SetImovelCondicaoComercial($condicaoComercial: condicaoComercial!) {
  SetImovelCondicaoComercial(condicaoComercial: $condicaoComercial) {
      id
    }
  }
`

export const PUT_IMOVEL_CONDICAO_COMERCIAL = gql`
mutation PutImovelCondicaoComercial($condicaoComercial: condicaoComercial!) {
  PutImovelCondicaoComercial(condicaoComercial: $condicaoComercial) {
    id
  }
}
`

export const DELETE_IMOVEL_CONDICAO_COMERCIAL = gql`
mutation Mutation($deleteImovelCondicaoComercialId: Float!, $idImovel: Float!) {
  DeleteImovelCondicaoComercial(id: $deleteImovelCondicaoComercialId, id_imovel: $idImovel) {
    id
    }
  }
`