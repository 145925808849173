import { BiLinkExternal } from "react-icons/bi"
import { Dicionario } from "../../../../../../utils/Dicionario"
import { Explorer } from "../../Explorer"


type PropsClickInfo = {
    info: any
  }

export const ClickInfoDefault: React.FC<PropsClickInfo> = ({info}) => {
return (<div className='accordion' id='accordion-info'>

  
  {
    // eslint-disable-next-line array-callback-return
    info.info.map(function (data: {}, item1: number) {
      let id = data['layer'].id
      let layer = Explorer.getInfoLayer(id)
   
      if (layer) {
        return typeof layer !== 'undefined' ? (
          <div key={item1} className='accordion-item'>
            <h2 className='accordion-header' id={'kt_accordion_1_header_' + item1}>
              <button
                className='accordion-button fs-4 fw-bold collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target={'#kt_accordion_1_body_' + item1}
                aria-expanded='false'
                aria-controls={'kt_accordion_1_body_' + item1}
              >
                {layer['nome']}{' '}
                {typeof data['object'].properties !== 'undefined'
                  ? layer['label_info']
                    ? '- ' + data['object'].properties[layer['label_info']]
                    : layer['label'] ? '-'+layer['label_info'] :''
                  : ''}
                <span></span>
              </button>
            </h2>
            <div
              id={'kt_accordion_1_body_' + item1}
              className='accordion-collapse collapse'
              aria-labelledby={'kt_accordion_1_header_' + item1}
              data-bs-parent={'#kt_accordion_' + item1}
            >
              <div className='accordion-body'>
                 <div className='d-flex justify-content-end'>
                 {
                  typeof layer['link'] !== 'undefined' && typeof data['object'].properties !== 'undefined' ? <a className='btn btn-light-success btn-small    ' href={ layer['link'].replace('[ID]', data['object'].properties[layer['link_field']] ) } target="_blank" rel="noreferrer" ><BiLinkExternal></BiLinkExternal> + Detalhes</a> :'' 
                  }
                  </div>
                <table className='table table-row-dashed table-row-gray-300 table-hover  gy-2'>
                 
                
                  
                  <tbody>
                    {typeof data['object'].properties !== 'undefined'
                      ? Object.entries(data['object'].properties).map(
                          // eslint-disable-next-line array-callback-return
                          function (item, item2) {
                            const itensNotShow = [
                              'geom',
                              'gid',
                              'id',
                              'centroid',
                              'layerName',
                              'cor',
                              'cor_zona',
                              // 'link_anunc',
                            ]
                            if (itensNotShow.indexOf(item[0]) !== -1) {
                            } else {
                              return (
                                <tr key={`tr${item2}`}>
                                  <td className='fw-bold'>{Dicionario.translate(item[0]) }</td>
                                  <td className='text-right'>{Dicionario.formatValue(item[0], item[1]) }</td>
                                </tr>
                              )
                            }
                          }
                        )
                      : ''}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          ''
        )
      }
    })
}
</div>)

}