import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { BiAddToQueue } from 'react-icons/bi'
import { MutationCreateAdminEmpresaUsuario } from '../../../../../graphql/services/Empresas'

export function ModalCreateUser({ id_empresa }) {
  const [show, setShow] = useState(false)
  const { FormAdminEmpresaUsuario, handleSubmit, register } = MutationCreateAdminEmpresaUsuario()

  function closeModal() {
    setShow(false)
  }
  function openModal() {
    setShow(true)
  }


  const onSubmit = async (data) => {
    FormAdminEmpresaUsuario(data).then((result) => {
      Swal.fire('Sucesso!', 'Nova usuário criado e associado com sucesso.', 'success');
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error');
    });

  }

  return (
    <>
      <button onClick={openModal} className='btn btn-light-primary btn-sm d-block me-2' style={{ width: '100px !important' }}>
        Registrar Usuário <BiAddToQueue className='fs-2'></BiAddToQueue>
      </button>

      <Modal centered animation show={show} onHide={() => closeModal()} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Registrar Usuário:</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-lg'>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='form-group mt-4'>
                    <label className='form-label' htmlFor="">Nome Completo</label>
                    <input
                      className='form-control form-control-solid'
                      placeholder='Nome Completo'

                      {...register('name', { required: true })}
                    />
                  </div>

                  <div className='form-group mt-4'>
                    <label className='form-label' htmlFor="">Email</label>
                    <input
                      type='email'
                      className='form-control form-control-solid'
                      placeholder='Email'
                      {...register('email', { required: true })}
                    />
                  </div>

                  <div className='form-group mt-4'>
                    <label className='form-label' htmlFor="">Telefone</label>
                    <input
                      className='form-control form-control-solid'
                      placeholder='Telefone'
                      {...register('telefone', { required: true })}
                    />
                  </div>

                  <div className='form-group mt-4'>
                    <label className='form-label' htmlFor="">Vizualização</label>
                    <select className='form-control' required name="" id="" {...register('visualizacao')}>
                      <option value="">Selecione</option>
                      <option value="PARCIAL">Parcial</option>
                      <option value="TOTAL">Total</option>
                    </select>
                  </div>

                  <div className='form-group mt-4'>
                    <label className='form-label' htmlFor="">Edição</label>
                    <select className='form-control' required name="" id="" {...register('edicao')}>
                      <option value="">Selecione</option>
                      <option value="PARCIAL">Parcial</option>
                      <option value="TOTAL">Total</option>
                    </select>
                  </div>


                  <div className='form-group mt-4'>
                    <label className='form-label' htmlFor="">Exclusão</label>
                    <select className='form-control' required name="" id="" {...register('exclusao')}>
                      <option value="">Selecione</option>
                      <option value="PARCIAL">Parcial</option>
                      <option value="TOTAL">Total</option>
                    </select>
                  </div>



                  <div className="col-md-12 d-flex mrt-1" style={{ justifyContent: 'flex-end' }}>
                    <button onClick={() => closeModal()} className="btn btn-danger pull-right" type="button" data-bs-dismiss="modal">Fechar</button>
                    <button type="submit" className="btn btn-success mx-2" id="btnIncluirCorretor">Associar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
