import React from 'react';

type Props = {
  texto: string;
  limiteCaracteres: number;
}

const TextoCurto = ({ texto, limiteCaracteres }: Props) => {
  if (texto.length <= limiteCaracteres) {
    return <p>{texto}</p>;
  }

  const textoCurto = texto.substring(0, limiteCaracteres) + '...';

  return <p>{textoCurto}</p>;
};

export default TextoCurto;