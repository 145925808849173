import {useMutation, useQuery} from '@apollo/client'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'


import {TypeGetEmpreendimentosMassaUsosResponse, TypeSetEmpreendimentoMassaUso, TypePutEmpreendimentoMassaUso, TypeDeleteEmpreendimentoMassaResponse, TypeDeleteEmpreendimentoMassaUso, TypeGetEmpreendimentosMassaUsosByIDResponse} from '../type'
import {GET_EMPREENDIMENTO_MASSA, GET_EMPREENDIMENTO_MASSA_USOS, GET_EMPREENDIMENTO_MASSA_USOS_BY_ID} from '../query'
import { DELETE_EMPREENDIMENTO_MASSA_USO, PUT_EMPREENDIMENTO_MASSA_USO, SET_EMPREENDIMENTO_MASSA_USO} from '../mutations'
import { FormCreateEmpreendimentoMassaUsoFormData, FormCreateEmpreendimentoMassaUsoFormSchema, FormUpdateEmpreendimentoMassaUsoFormData, FormUpdateEmpreendimentoMassaUsoFormSchema } from '../formValidations/EmpreendimentoMassaUso'

interface QueryProps {
  variables: any
  skip?: any
}

export function QueryGetEmpreendimentoMassaUsos({ variables }: QueryProps){
    const { data } = useQuery<TypeGetEmpreendimentosMassaUsosResponse>(GET_EMPREENDIMENTO_MASSA_USOS, {
        variables: {...variables}
    })

    return data
}

export function QueryGetEmpreendimentoMassaUsosByID({ variables }: QueryProps){
  const { data, loading, error
   } = useQuery<TypeGetEmpreendimentosMassaUsosByIDResponse>(GET_EMPREENDIMENTO_MASSA_USOS_BY_ID, {
      variables: {...variables}
  })

  return {data, loading, error
  }
}


export function MutationCreateEmpreendimentoMassaUso() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<FormCreateEmpreendimentoMassaUsoFormData>({
    resolver: zodResolver(FormCreateEmpreendimentoMassaUsoFormSchema),
  })

  const [setEmpreendimentoMassaUsoBody, {error, loading, data: DataSetEmpreendimentoMassaUso}] =
    useMutation<TypeSetEmpreendimentoMassaUso>(SET_EMPREENDIMENTO_MASSA_USO, {
      refetchQueries: [GET_EMPREENDIMENTO_MASSA,GET_EMPREENDIMENTO_MASSA_USOS ],
    })

  async function FormEmpreendimentoMassaUso(data: FormCreateEmpreendimentoMassaUsoFormData) {
    await setEmpreendimentoMassaUsoBody({
      variables: {massaUsos: {...data}},
    })
  }

  return {
    register,
    handleSubmit,
    FormEmpreendimentoMassaUso,
    loading,
    errors,
    error,
    setValue,
    control,
    getValues,

    DataSetEmpreendimentoMassaUso,
  }
}



export function MutationUpdateEmpreendimentoMassaUso() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<FormUpdateEmpreendimentoMassaUsoFormData>({
    resolver: zodResolver(FormUpdateEmpreendimentoMassaUsoFormSchema),
  })

  const [setEmpreendimentoMassaUsoBody, {error, loading, data: DataSetEmpreendimentoMassaUso}] =
    useMutation<TypeSetEmpreendimentoMassaUso>(PUT_EMPREENDIMENTO_MASSA_USO, {
      refetchQueries: [GET_EMPREENDIMENTO_MASSA,GET_EMPREENDIMENTO_MASSA_USOS ],
    })

  async function FormEmpreendimentoMassaUso(data: FormUpdateEmpreendimentoMassaUsoFormData) {
    await setEmpreendimentoMassaUsoBody({
      variables: {massaUsos: {...data}},
    })
  }

  return {
    register,
    handleSubmit,
    FormEmpreendimentoMassaUso,
    loading,
    errors,
    error,
    setValue,
    control,
    getValues,

    DataSetEmpreendimentoMassaUso,
  }
}

export function MutationPutEmpreendimentoUso() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: {errors},
  } = useForm<FormUpdateEmpreendimentoMassaUsoFormData>({
    resolver: zodResolver(FormUpdateEmpreendimentoMassaUsoFormSchema),
  })

  const [setEmpreendimentoBody, {error, loading, data: DataPutEmpreendimentoMassaUSo}] =
    useMutation<TypePutEmpreendimentoMassaUso>(PUT_EMPREENDIMENTO_MASSA_USO, {
      refetchQueries: [GET_EMPREENDIMENTO_MASSA,GET_EMPREENDIMENTO_MASSA_USOS],
    })

  async function FormUpdateEmpreendimentoMassaUso(data: FormUpdateEmpreendimentoMassaUsoFormData) {
    await setEmpreendimentoBody({
      variables: {massaUsos: {...data}},
    })
  }

  return {
    register,
    handleSubmit,
    FormUpdateEmpreendimentoMassaUso,
    loading,
    errors,
    error,
    control,
    setError,
    setValue,
    DataPutEmpreendimentoMassaUSo,
  }
}


export function MutationDeleteEmpreendimentoMassaUso() {

  const [deleteEmpreendimentoMassaUsos, {data, loading, error}] = useMutation<TypeDeleteEmpreendimentoMassaUso>(
    DELETE_EMPREENDIMENTO_MASSA_USO,
    {
      refetchQueries: [GET_EMPREENDIMENTO_MASSA,GET_EMPREENDIMENTO_MASSA_USOS],
    }
  )


  async function HandleDeleteEmpreendimentoMassaUso({ variables }: QueryProps) {
    try {
      const result = await deleteEmpreendimentoMassaUsos({
        variables: { ...variables },
        refetchQueries: [GET_EMPREENDIMENTO_MASSA, GET_EMPREENDIMENTO_MASSA_USOS],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return { HandleDeleteEmpreendimentoMassaUso, loading, error, data }
}



