import {useContext, useEffect, useState} from 'react'
import {MapViewRevenda} from '../MapViewRevenda'
import {FilterContext} from '../../context/FilterContext'
import {AllGeoJSON} from '@turf/turf'
import {PivotTable} from './PerformanceRevenda/PivotTable'
import {TResultAPI} from '../Types'
import {APiUrbit} from '../../../../services/api/apiUrbit'
import {getLayer} from '../MapLayers/Layer'
import {Legenda} from '../Legenda'
import {DataContext} from '../../context/DataContext'
import {quantile} from 'simple-statistics'
import {BiExport} from 'react-icons/bi'
import {ExportTable} from '../../../../utils/ExportXLSX'
import FaixaMetragem from './PerformanceRevenda/FaixaMetragem'
import FaixaTicket from './PerformanceRevenda/FaixaTicket'
import PrecoMedioM2 from './PerformanceRevenda/PrecoMedioM2'
import NumeroOfertas from './PerformanceRevenda/NumeroOfertas'
import TicketMedio from './PerformanceRevenda/TicketMedio'

type Props = {
  label?: string
}
const api = new APiUrbit()
export function PerformanceMercadoRevenda({label}: Props) {
  // const {data} = useContext(DataContext)
  const {geometria, endereco} = useContext(FilterContext)
  const {cacheResult, setCacheResult} = useContext(DataContext)
  const [dataImoveis, setDataImoveis] = useState(null)

  async function loadRevenda(geom) {
    // const centroid = turf.centroid(geom)
    const lat = endereco.latitude //centroid.geometry.coordinates[1]
    const lng = endereco.longitude //centroid.geometry.coordinates[0]

    if (geom.type === 'Feature') {
      geom = geom.geometry
    }

    // const intersectString = btoa(unescape(encodeURIComponent(JSON.stringify(geom))))

    let url = `service/apartamentos-ofertas/${lng}/${lat}?distance=700`
    // intersect=${intersectString}`
    await api.get(url, (result: TResultAPI) => {
      if (result.itens.length) {
        let arrayValores = []
        // let precomedioM2 = 0
        result.itens.map((item, index) => {
          item.geom = JSON.parse(item.geom)
          arrayValores.push(item.valor / item.area)

          return item
        })
        let quant = quantile(arrayValores, [0.25, 0.5, 0.75])

        cacheResult.performanceMercadoRevenda.precoMedianoM2 = quant[1]
        cacheResult.performanceMercadoRevenda.precoQ1m2 = quant[0]
        cacheResult.performanceMercadoRevenda.precoQ3m2 = quant[2]
        cacheResult.performanceMercadoRevenda.totalAmostras = result.total

        setCacheResult(cacheResult)

        setDataImoveis(result.itens)
      }
    })
  }
  useEffect(() => {
    if (geometria) {
      loadRevenda(geometria)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geometria])

  if (!endereco) {
    return <>Carregando...</>
  }

  if (!geometria) {
    return <>Carregando...</>
  }
  if (!dataImoveis) {
    return <>Carregando...</>
  }
  let json = geometria as AllGeoJSON
  const layersList = [] // getLayers(['view_explorer_apartamento_venda_'], json)
  const layerConfig = getLayer('view_explorer_apartamento_venda_')

  let center = {
    lat: endereco.latitude,
    lng: endereco.longitude,
  }

  function exportar() {
    let exportaFinal = dataImoveis.map((item) => {
      delete item.geom
      return item
    })
    ExportTable(exportaFinal, '', 'Resumo Mercado.xlsx')
  }
  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10' style={{height: '92.1vh'}}>
        <div className='header-report d-flex all-start-between '>
          <div>
            <h1 className='fw-bolder'>Mercado Revenda</h1>
            <span>Imóveis anunciados nos ultimos 60 dias.</span>
          </div>
          <div>
            <button className='btn btn-primary pull-right' onClick={exportar}>
              <BiExport></BiExport> Exportar
            </button>
          </div>
        </div>
        <div className='content pt-10'>
          <div className='row g-5 g-xl-8'>
            <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance p-0'>
              <MapViewRevenda
                center={center}
                layer={[layersList]}
                json={json}
                zoom={15}
                dataImoveis={dataImoveis}
                style={layerConfig.style}
              >
                <Legenda style={layerConfig.style} title={'Renda'} visivel={true}></Legenda>
              </MapViewRevenda>
              <div className='p-10'>

              <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
  <li className="nav-item">
    <a
      className="nav-link active"
      data-bs-toggle="tab"
      href="#kt_tab_pane_4"
    >
      Estatisticas gerais
    </a>
  </li>
  <li className="nav-item">
    <a
      className="nav-link"
      data-bs-toggle="tab"
      href="#kt_tab_pane_5"
    >
      Gráficos
    </a>
  </li>
  {/* <li className="nav-item">
    <a
      className="nav-link"
      data-bs-toggle="tab"
      href="#kt_tab_pane_6"
    >
      Link 3
    </a>
  </li> */}
</ul>
<div className="tab-content" id="myTabContent">
  <div
    className="tab-pane fade show active"
    id="kt_tab_pane_4"
    role="tabpanel"
  >
    <PivotTable itens={dataImoveis}></PivotTable>
  </div>
  <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
   
   <div className='row'>
    <div className='col-6'>
      <FaixaMetragem itens={dataImoveis}></FaixaMetragem>
     </div>
     <div className='col-6'>
      <FaixaTicket itens={dataImoveis}></FaixaTicket>
     </div>
   </div>
   <div className='row'>
    <div className='col-6'>
      <NumeroOfertas itens={dataImoveis} ></NumeroOfertas>
    </div>
    <div className='col-6'>
    <PrecoMedioM2 itens={dataImoveis} ></PrecoMedioM2>
    </div>
   </div>
   
   <div className='row'>
   <div className='col-6'>
    <TicketMedio itens={dataImoveis} ></TicketMedio>
   </div>
   </div>
  </div>
  {/* <div className="tab-pane fade" id="kt_tab_pane_6" role="tabpanel">
    Sint sit mollit irure quis est nostrud cillum consequat Lorem
    esse do quis dolor esse fugiat sunt do. Eu ex commodo veniam
    Lorem aliquip laborum occaecat qui Lorem esse mollit dolore anim
    cupidatat. eserunt officia id Lorem nostrud aute id commodo elit
    eiusmod enim irure amet eiusmod qui reprehenderit nostrud
    tempor. Fugiat ipsum excepteur in aliqua non et quis aliquip ad
    irure in labore cillum elit enim. Consequat aliquip incididunt
    ipsum et minim laborum laborum laborum et cillum labore.
    Deserunt adipisicing cillum id nulla minim nostrud labore
    eiusmod et amet.
  </div> */}
</div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
