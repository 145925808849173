import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormInputCurrencyFormat } from "../../../../components/UI/Inputs/FormInputCurrencyFormat";
import { BiAddToQueue } from "react-icons/bi";
import { ADMINMutationCreateProdutos } from "../../../graphql/services/Produtos";
import { FormInputAndLabel } from "../../../../components/UI/Inputs/FormInputAndLabel";
import { CurrencyFormat } from "../../../../components/util";
import { FormInputPercentFormat } from "../../../../components/UI/Inputs/FormInputPercentFormat";
import Swal from "sweetalert2";


export function ModalSetProduto() {
  const [show, setShow] = useState(false);
  const [valorProduto, setValorProduto] = useState(0); // Defina o estado para o valor do produto
  const [desconto, setDesconto] = useState(0)
  const [totalProduto, setTotalProduto] = useState(0)
  const { control, errors, handleSubmit, loading, register, setValue, submitForm, DataSetProduto, error } = ADMINMutationCreateProdutos();

  // Função para calcular o total com base no valor do produto e no desconto
  const calcularTotal = (valorProduto, desconto) => {
    const novoTotal = valorProduto - (valorProduto * desconto) / 100;
    setTotalProduto(novoTotal);
  };
  const handleValorProdutoChange = (e) => {
    setValue('valor', e.floatValue);
    const newValue = e.floatValue || 0;
    setValorProduto(newValue);
    calcularTotal(newValue, desconto);
  };

  const handleDescontoChange = (e) => {
    const newValue = e.floatValue || 0;
    setValue('desconto', e.floatValue);

    // Verifica se o valor não excede 100%
    if (newValue <= 100) {
      setDesconto(newValue);
      calcularTotal(valorProduto, newValue);
    } else {
      // Caso o valor exceda 100%, defina o desconto como 100%
      setDesconto(100);
      calcularTotal(valorProduto, 100);
    }
  };


  function closeModal() {
    setShow(false);
  }

  function openModal() {
    setShow(true);
  }



  if (loading) {
    Swal.fire(
      'Enviando Informações...','')
    Swal.showLoading()
  }

  useEffect(() => {
    if (show) {

      if (error) {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'warning').then((result) => {
          if (result.isConfirmed) {

          }
        });
      }else if (DataSetProduto) {
        closeModal()
        Swal.fire('Sucesso!', 'Produto criado com sucesso.', 'success')
      }
    }
  }, [DataSetProduto, error, show])

  return (
    <>
      <button onClick={openModal} className='btn btn-light-primary btn-sm d-block me-2' style={{ width: '100px !important' }}>
        <BiAddToQueue className='fs-2 me-1' />Adicionar Produto
      </button>

      <Modal centered animation show={show} onHide={() => closeModal()} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Produto:</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-lg'>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <form onSubmit={handleSubmit(submitForm)}>
                  <div className='row'>
                    <input type='hidden' className='form-control form-control-solid' value={1}
                      {...register('situacao', { required: true, valueAsNumber: true, max: 100 })} />
                    <div className='col-6'>
                      <label className="col-form-label">Nome</label>
                      <input type='text' className='form-control form-control-solid' placeholder='Nome do produto'
                        {...register('nome')} />
                    </div>
                    <div className='col-md-6'>
                      <label className="col-form-label">Valor(R$)</label>
                      <FormInputCurrencyFormat
                        control={control}
                        className='form-control form-control-solid text-right disabled'
                        placeholder='R$ 00,00'
                        register={register('valor')}
                        error={errors ? errors.valor?.message : ''}
                        onValueChange={handleValorProdutoChange} // Use a função para lidar com a mudança no valor do produto
                      />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-md-2'>
                      <FormInputPercentFormat
                        control={control}
                        label='Desconto(%)'
                        max={100}
                        maxLength={3}
                        className='form-control form-control-solid text-right'
                        placeholder='Desconto(%)'
                        register={register('desconto', { required: true, valueAsNumber: true, max: 100 })}
                        error={errors ? errors.desconto?.message : ''}
                        onValueChange={handleDescontoChange}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormInputAndLabel
                        label="Total(R$)"
                        value={CurrencyFormat(totalProduto)}
                        className='form-control form-control-solid text-right'
                        disabled
                        placeholder='R$ 00,00'
                      />
                    </div>
                    <div className='col-md-6'>
                      <label className="col-form-label" htmlFor=''>Selecione o tipo de Produto</label>
                      <select name='' className='form-control form-control-solid' id='' {...register('tipo_produto')}>
                        <option value='VENDA'>VENDA</option>
                        <option value='ASSINATURA'>ASSINATURA</option>
                        <option value='VENDA_APP'>VENDA_APP</option>
                        <option value='ASSINATURA_APP'>ASSINATURA_APP</option>
                      </select>
                    </div>
                  </div>

                  <div className='row mt-2'>
                    <div className='col-xl-12'>
                      <label htmlFor=''>Descrição:</label>
                      <textarea rows={6} style={{ resize: 'none' }} className='form-control form-control-solid' placeholder='Descrição do produto'
                        {...register('descricao')}
                      />
                    </div>
                  </div>
                  <div className="w-100  mt-4 all-end">
                    <button onClick={() => closeModal()} className="btn btn-danger me-4">Cancelar</button>
                    <button type="submit" className="btn btn-success">Salvar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
