import { ValidateData } from "./ValidateData";
export class Estatisticas {

    static calNumber(featuresRendered, field, operacao?){
        var calc = {
            'sum':0,
            'min':0,
            'max':0,
            'med':0,
            'total':0
        }
        var count = 1 ;
        for(var i in featuresRendered){
            if(featuresRendered[i].object.properties[field] !=='null'){
                calc.sum = this.sum(calc.sum,Number(featuresRendered[i].object.properties[field])) ;
                calc.min = this.min(calc.min,Number(featuresRendered[i].object.properties[field])) ;
                calc.max = this.max(calc.max,Number(featuresRendered[i].object.properties[field])) ;
                calc.med = this.med(calc.sum,count) ;
            }
            count++;
        }
        
        calc.total = count-1 ;

        return calc;
        
    }
    static  sum(valueIn,valueSum ){
        return valueIn + valueSum;
    }
    static min(valueIn,value){
        if(valueIn === 0 ) {
            return value;
        }
        if(value < valueIn   ){
            return value;
        }else{
            return valueIn;
        }
    }
    static max(valueIn,value){
        if(value > valueIn ){
            return value;
        }else{
            return valueIn;
        }
    }
    static med(valueIn, count){
        return valueIn/count;
    }
    static groupText(featuresRendered,field){
        // var count = 1 ;
        var valores =[]
        for(var i in featuresRendered){
            if(featuresRendered[i].object.properties[field] !=='null'){
                if(typeof valores[featuresRendered[i].object.properties[field]] == 'undefined'){
                    valores[featuresRendered[i].object.properties[field]] = 1
                }else{
                    valores[featuresRendered[i].object.properties[field]] = valores[featuresRendered[i].object.properties[field]] + 1;
                }
                // count++;
            }
        }        
        return {group:valores};
    }

    static calcEstatisticas(dados, field){
        var calc={};
        // var featuresRendered =  Map.mapbox.queryRenderedFeatures({ layers: [layer.layer.id] });
        var featuresRendered =  dados
        var firstData = featuresRendered[0].object.properties[field]
        if(firstData === 'null'){
            for (var i in featuresRendered){
                if( featuresRendered[i].object.properties[field] !== 'null' ){
                    firstData =  featuresRendered[0].object.properties[field]
                    break;
                }
            }
        }
       
        var typeData = ValidateData.getDataType(firstData);

        if(typeData === 'Integer' || typeData === 'Number'  || typeData=== 'Double') {
            calc = this.calNumber(featuresRendered,field);
        } else {
            calc = this.groupText(featuresRendered,field);
        }
        return calc;
    }

}