import {useEffect, useState} from 'react'
import 'rc-slider/assets/index.css'
import TooltipSlider from './Tooltip'

type Props = {
  defaultValue: number[]
  min: number
  max: number
  step: number
  onChange: any
  formatTip: any
  showHideImput?: boolean
}

export const RangeSlider: React.FC<Props> = ({
  defaultValue,
  min,
  max,
  step,
  onChange,
  formatTip,
  showHideImput = true,
}) => {
  const [minimo, setMinimo] = useState<number | undefined>(defaultValue[0] || min)
  const [maximo, setMaximo] = useState<number | undefined>(defaultValue[1] || max)

  useEffect(() => {
    if (defaultValue) {
      setMinimo(defaultValue[0])
      setMaximo(defaultValue[1])
    } else {
      setMinimo(min)
      setMaximo(max)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  let marks = {}
  marks[min] = formatTip(min)
  marks[max] = formatTip(max)
  // console.log(minimo)
  return (
    <div className='form-group pt-0 '>
      {showHideImput === true ? (
        <div className='row'>
          <div className='col-6'>
            <input
              type='number'
              name='area_min'
              id='area_min'
              value={minimo === 0 ? min : minimo}
              onChange={(e) => {
                let m = null
                if (Number(e.target.value) <= min) {
                  console.log('min')
                } else {
                  m = Number(e.target.value)
                }
                setMinimo(m)
                onChange([m, maximo])
              }}
              className='form-control '
            />
          </div>
          <div className='col-6'>
            <input
              type='number'
              name='area_max'
              id='area_max'
              value={maximo}
              onChange={(e) => {
                let m = null
                if (Number(e.target.value) >= max) {
                  m = max
                } else {
                  m = Number(e.target.value)
                }
                setMaximo(m)
                onChange([minimo, m])
              }}
              className='form-control '
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='row pt-5 pe-5 ps-2 mb-5'>
        <TooltipSlider
          range
          allowCross={false}
          min={min}
          max={max}
          tipFormatter={formatTip}
          defaultValue={defaultValue}
          value={[minimo, maximo]}
          step={step}
          onChange={(e) => {
            setMinimo(e[0])
            setMaximo(e[1])
            onChange(e)
          }}
          tipProps={undefined}
          marks={marks}
        />
      </div>
    </div>
  )
}
