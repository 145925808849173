import { gql } from "@apollo/client"

// PROPRIETARIOS/CONTRIBUENTES
export const SET_PROPRIETARIO = gql`
mutation SetProprietario($idTerreno: Float, $proprietario: proprietario!) {
  SetProprietario(id_terreno: $idTerreno, proprietario: $proprietario) {
    id
    id_terreno
    nome
  }
}
`

export const PUT_PROPRIETARIO = gql`
mutation PutProprietario($proprietario: proprietario!) {
  PutProprietario(proprietario: $proprietario) {
    id
    nome
    situacao
  }
}
`

export const DELETE_PROPRIETARIO = gql`
mutation DeleteProprietario($deleteProprietarioId: Float!) {
  DeleteProprietario(id: $deleteProprietarioId) {
    id
  }
}
`

// PROPRIETARIOS/CONTRIBUENTES
export const SET_IMOVEL_PROPRIETARIO = gql`
mutation SetImovelProprietario($imovelProprietario: imovelProprietario!) {
  SetImovelProprietario(imovelProprietario: $imovelProprietario) {
    id_proprietario
    id_terreno
  }
}
`


export const PUT_IMOVEL_PROPRIETARIO = gql`
mutation PutProprietario($proprietario: proprietario!) {
  PutProprietario(proprietario: $proprietario) {
    id
    nome
  }
}
`

export const DELETE_IMOVEL_PROPRIETARIO = gql`
mutation DeleteImovelProprietario($deleteImovelProprietarioId: Float!) {
  DeleteImovelProprietario(id: $deleteImovelProprietarioId) {
    id
  }
}
`