import { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { isExpired } from 'react-jwt'
import { api } from '../../../../../services/api'


interface AuthContextData {
  authenticated: boolean;
  // userId: number;
  updateToken(user: object): Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode
}


export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const UserDataContext = createContext<AuthContextData>({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [authenticated, setAuthenticated] = useState(false)
  
  useEffect(() => {
    const token: string | null = localStorage.getItem('token')
    const compare = isExpired(JSON.stringify(token))
    if (!compare) {
      api.defaults.headers.Authorization = `Bearer ${token}`
      setAuthenticated(true)
    }
  }, [])



  async function updateToken(userData: object,) {

    const data = await api.post('/api/login', userData['data'])

    api.defaults.headers.Authorization = `Bearer ${data.data[0].token}`

    localStorage.setItem('token', data.data[0].token)
    localStorage.setItem('name', data.data[0].name)
    localStorage.setItem('email', data.data[0].email)

  }

 


  return (
    <AuthContext.Provider value={{ authenticated,  updateToken }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext)

  return context
}
