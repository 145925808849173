import { gql } from "@apollo/client";

export const ADMIN_SET_PRODUTOS = gql`
mutation SetProdutos($produtoInput: produtos!) {
  SetProdutos(produtoInput: $produtoInput) {
    id
    nome
  }
}
`

export const ADMIN_PUT_PRODUTO = gql`
mutation PutProdutos($produto: produtos!) {
  PutProdutos(produto: $produto) {
    id
    nome
  }
}
`

export const ADMIN_DELETE_PRODUTO = gql`
mutation DeleteProdutos($deleteProdutosId: Float!) {
  DeleteProdutos(id: $deleteProdutosId) {
    id
    nome
  }
}
`