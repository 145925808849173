import { gql } from "@apollo/client";

export const GET_IMOVEL_TAGS = gql`
query GetImovelTags {
  GetImovelTags {
    id
    situacao
    tag
  }
}
`

// EMPREENDIMENTO TAGS

export const GET_EMPREENDIMENTO_TAGS = gql`
query GetEmpreendimentoTags {
  GetEmpreendimentoTags {
    id
    tag
    situacao
  }
}
`
