/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import Chart from 'react-apexcharts'
import { getCSS, getCSSVariableValue } from '../../../../../../../../_metronic/assets/ts/_utils'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import { Dropdown1 } from '../../../../../../../../_metronic/partials'

type Props = {
  title: string
  subtitle: string
  series: any[]
  categories: any[]
  formatter?: any
}

export const AreaChartPercentual: React.FC<Props> = ({title, subtitle, series, categories, formatter}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  if (!formatter) {
    formatter = function (val) {
      return '' + val.toFixed(0) + ' %'
    }
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, series, categories, formatter)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  let options = {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      min: 0,
      max: 100
    }
  }

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{subtitle}</span>
        </h3>
        {/* end::Title */}

        {/* begin::Toolbar */}
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div className='row'>
          <div className=''>
            <Chart options={options} series={series} type='area' />
          </div>
        </div>
        {/* <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} /> */}
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

function getChartOptions(height: number, series: any[], categories: any[], formatter): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  // const baseColor = '#ff0000' //getCSSVariableValue('--kt-primary')
  // const secondaryColor = getCSSVariableValue('--kt-gray-300')

  console.log('asdas render')

  const color1 = '#eaee00'
  // const color1Light = '#eaee0042'

  const color2 = '#43ee00'
  // const color2Light = '#43ee0049'

  const color3 = '#00abee'
  // const color3Light = '#00abee44'
  return {
    series: series,
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: formatter,
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: formatter,
      },
    },
    colors: [color1, color2, color3],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
