import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { Loader } from '../../../components/UI/Loader/Loader'
import { FormInputAndLabel } from '../../../components/UI/Inputs/FormInputAndLabel'
import { FormInputMaskAndLabel } from '../../../components/UI/Inputs/FormInputMaskAndLabel'
import { GetEstadoCidadeChosen } from '../../../components/UI/Chosens/GetEstadoCidadeChosen'
import { MutationPutImovelProprietarios } from '../../../graphql'
import { QueryGetProprietarioByID } from '../../../graphql/services/Proprietarios'
import { MutationSetLogAcessoRecurso } from '../../../graphql/services/LogAcesso'

interface situacaoTerrenoProps {
  id: number
  show: boolean
  closeModal: any
}

export function EditForm({ id, show, closeModal }: situacaoTerrenoProps) {
  const { data, loading: LoadingGET } = QueryGetProprietarioByID({
    variables: {
      id: id
    }
  })
  console.log(data)

  const {
    FormImovelProprietarios,
    loading,
    handleSubmit,
    register,
    errors,
    setValue
  } = MutationPutImovelProprietarios()

  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  const onSubmit = async (data) => {
    FormImovelProprietarios(data).then((result) => {
      FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'proprietarios', action: 'update', url: '/cadastro/proprietarios' });
      Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })
  };

  // Tratativa de loading
  if (loading || LoadingGET) {
    return (
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Proprietário:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader text={'caregando...'}></Loader>
        </Modal.Body>
      </Modal>
    )
  }

  if (!data) {
    return (
      <Modal size="lg" centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Proprietário:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader text={'caregando...'}></Loader>
        </Modal.Body>
      </Modal>
    )
  }


  return (
    <Modal size="lg" centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Proprietário:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="kt_account_profile_details_form"
          className="form fv-plugins-bootstrap5 fv-plugins-framework"
        >
          <input
            type='number'
            hidden
            className='form-control'
            defaultValue={id}
            {...register('id', { required: true, valueAsNumber: true })}
          />
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-6">
                <FormInputMaskAndLabel
                  label='CPF:'
                  maskChar=' '
                  mask='999.999.999-99'
                  className='form-control'
                  placeholder='Ex: 000.000.000-00'
                  register={register("cpf")}
                  error={errors ? errors.cpf?.message : ''}
                  showHide={data.GetProprietario.cpf ? true : false}
                  defaultValue={data.GetProprietario.cpf}

                />

                <FormInputMaskAndLabel
                  label='CNPJ:'
                  maskChar=' '
                  mask='99.999.999/9999-99'
                  className='form-control'
                  placeholder='Ex: 00.000.000/0000-00'
                  register={register("cnpj")}
                  error={errors ? errors.cnpj?.message : ''}
                  showHide={data.GetProprietario.cnpj ? true : false}
                  defaultValue={data.GetProprietario.cnpj}
                />
                {/* )} */}
              </div>
              <div className="col-6">
                <FormInputAndLabel
                  label='Nome Completo:'
                  className='form-control'
                  placeholder='Nome Completo'
                  hide={data.GetProprietario.cpf ? false : true}
                  register={register("nome")}
                  error={errors ? errors.nome?.message : ''}
                  defaultValue={data.GetProprietario.nome}

                />

                <FormInputAndLabel
                  label='Empresa:'
                  className='form-control'
                  placeholder='Empresa'
                  hide={data.GetProprietario.cnpj ? false : true}
                  register={register("empresa")}
                  error={errors ? errors.empresa?.message : ''}
                  defaultValue={data.GetProprietario.empresa}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <FormInputAndLabel
                  label='Email:'
                  type='email'
                  className='form-control'
                  placeholder='Email'
                  register={register("email")}
                  error={errors ? errors.email?.message : ''}
                  defaultValue={data.GetProprietario.email}

                />
              </div>
              <div className="col-6">
                <FormInputMaskAndLabel
                  label='Telefone:'
                  maskChar=' '
                  mask='(99) 99999-9999'
                  className='form-control'
                  placeholder='Ex: (00) 00000-0000'
                  register={register("telefone")}
                  error={errors ? errors.telefone?.message : ''}
                  defaultValue={data.GetProprietario.telefone}

                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-6">
                <FormInputAndLabel
                  label='Endereço:'
                  className='form-control'
                  placeholder='Logradouro'
                  register={register("endereco")}
                  error={errors ? errors.endereco?.message : ''}
                  defaultValue={data.GetProprietario.endereco}
                />
              </div>
              <div className="col-6">
                <FormInputAndLabel
                  label='Numero:'
                  className='form-control'
                  placeholder='N°'
                  register={register("numero")}
                  error={errors ? errors.numero?.message : ''}
                  defaultValue={data.GetProprietario.numero}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-6">
                <FormInputMaskAndLabel
                  label='CEP:'
                  maskChar=' '
                  mask='99999-999'
                  className='form-control'
                  placeholder='Ex: 00000-000'
                  register={register("cep")}
                  error={errors ? errors.cep?.message : ''}
                  defaultValue={data.GetProprietario.cep}

                />
              </div>
              <div className="col-6">
                <FormInputAndLabel
                  label='Complemento:'
                  className='form-control'
                  placeholder='Complemento'
                  register={register("complemento")}
                  error={errors ? errors.complemento?.message : ''}
                  defaultValue={data.GetProprietario.complemento}
                />
              </div>
            </div>

            <div className="row mb-3">
              <GetEstadoCidadeChosen
                classNameCity={'col-6 col-xs-12 mt-1'}
                classNameEstado={'col-6 col-xs-12  mt-1'}
                defaultValueEstado={data ? data.GetProprietario.id_estado : 0}
                defaultValueCidade={data ? data.GetProprietario.id_cidade : 0}
                SetValueEstado={(value) => {
                  // console.log('SetValueEstado', value)
                  // setValueEstado(value)
                  // console.log('estado')
                  // console.log(value)
                  setValue('id_estado', value)
                  // setValue('id_cidade', null)
                }}
                SetValueCidade={(value) => {

                  // setValueCidade(value)
                  setValue('id_cidade', value)
                }}
              />
            </div>


          </div>
          <div className="card-footer d-flex justify-content-end">
            <button type="reset" className="btn btn-light btn-active-light-primary me-2" onClick={() => closeModal()} >Cancelar</button>
            <button type="submit" className="btn btnSuccess" id="kt_account_profile_details_submit">Salvar</button>
          </div>
          <input type="hidden" /><div></div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
