import {Modal} from 'react-bootstrap'
import {MutationPutNewsFeed, QueryGetNewsFeedByID} from '../../../graphql'
import {Loader} from '../../../../components/UI/Loader/Loader'
import {FormInputAndLabel} from '../../../../components/UI/Inputs/FormInputAndLabel'
import {FormTextareaAndLabel} from '../../../../components/UI/Inputs/FormTextareaAndLabel'
import {ErrorMessageComponent} from '../../../components/UI/ErrorMessage'
import Swal from 'sweetalert2'

interface Props {
  id: number
  show: boolean
  closeModal: any
}

export function EditForm({id, show, closeModal}: Props) {
  let {data, loading: LoadingGet} = QueryGetNewsFeedByID({variables: {getNewsFeedByIdId: id}})
  console.log(data)
  const {FormPutNewsFeed, loading, handleSubmit, register, errors} = MutationPutNewsFeed()

  console.log(errors)

  const onSubmit = async (data) => {
    FormPutNewsFeed(data)
      .then((result) => {
        Swal.fire('Sucesso!', 'Notícia alterada com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  // Tratativa de loading
  if (loading || LoadingGet) {
    return (
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Notícia:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader text={'caregando...'}></Loader>
        </Modal.Body>
      </Modal>
    )
  }

  if (!data) {
    ;<>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Notícia:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader text={'caregando...'}></Loader>
        </Modal.Body>
      </Modal>
    </>
  }

  return (
    <>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Notícia:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type='number'
              hidden
              className='form-control'
              defaultValue={id}
              {...register('id', {required: true, valueAsNumber: true})}
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormInputAndLabel
                    label='Titulo'
                    className='form-control required form-control-solid'
                    placeholder='Titulo da Notícia'
                    register={register('title')}
                    defaultValue={data ? data.GetNewsFeedById[0].title : ''}
                    error={errors ? errors.title?.message : ''}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <FormTextareaAndLabel
                    label='Descrição'
                    className='form-control required form-control-solid'
                    placeholder='Descrição da Notícia'
                    register={register('description')}
                    defaultValue={data ? data.GetNewsFeedById[0].description : ''}
                    error={errors ? errors.description?.message : ''}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <input
                  type='date'
                  className='form-control form-control-solid'
                  {...register('start_date', {shouldUnregister: true})}
                  defaultValue={data.GetNewsFeedById[0].start_date.split('T')[0]}
                  onChange={(e) => {
                    const selectedDate = e.target.value ? new Date(e.target.value) : null
                    console.log(selectedDate) // Aqui você terá a data como objeto Date
                  }}
                />
                {errors.start_date && (
                  <ErrorMessageComponent message={errors.start_date?.message} />
                )}
              </div>
              <div className='col-6'>
                <input
                  type='date'
                  className='form-control form-control-solid'
                  {...register('end_date', {shouldUnregister: true})}
                  defaultValue={
                    data.GetNewsFeedById[0].end_date
                      ? data.GetNewsFeedById[0].end_date.split('T')[0]
                      : ''
                  }
                  onChange={(e) => {
                    const selectedDate = e.target.value ? new Date(e.target.value) : null
                    console.log(selectedDate) // Aqui você terá a data como objeto Date
                  }}
                />
                {errors.end_date && <ErrorMessageComponent message={errors.end_date?.message} />}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 py-6 d-flex' style={{justifyContent: 'flex-end'}}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={() => closeModal()}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
