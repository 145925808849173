import {useMutation, useQuery } from "@apollo/client"
import { GET_EXPLORER_USUARIO_PROJETOS, PUT_EXPLORER_USUARIO_PROJETOS, SET_EXPLORER_USUARIO_PROJETOS } from "../query/explorer"
import { TypeGetExplorerUserProject, TypesExplorerUserProjectResponse, TypesPutExplorerUserProjectResponse, TypesSetExplorerUserProjectResponse } from "../type/explorer-layer-usuario-project"
import { PutExplorerUserProjectFormSchema, SetExplorerUserProjectFormSchema, putExplorerUserProjectFormData, setExplorerUserProjectFormData } from "../formValidations/ExplorerUserProject"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { DELETE_EXPLORER_LAYER_USUARIO } from "../mutations"

interface Props {
  variables: any
}

export function QueryGetExplorerUserProject({ variables }: Props) {
  const { data, loading, error } = useQuery<TypeGetExplorerUserProject>(GET_EXPLORER_USUARIO_PROJETOS, {
    variables: { ...variables },
  })

  return { data, loading, error }
}


export function MutationPutExplorerUserProject() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<putExplorerUserProjectFormData>({
    resolver: zodResolver(PutExplorerUserProjectFormSchema),
  })

  const [setPutExplorerUserProjectBody, {error, loading, data: DataPutExplorerUserProject}] =
    useMutation<TypesPutExplorerUserProjectResponse>(PUT_EXPLORER_USUARIO_PROJETOS, {
       refetchQueries: [GET_EXPLORER_USUARIO_PROJETOS],
    })

  async function FormPutExplorerUserProject(data: putExplorerUserProjectFormData) {
    return await setPutExplorerUserProjectBody({
      variables: {
        explorerUserProject: {...data}
      },
    })

  }


  return {register, handleSubmit, FormPutExplorerUserProject, loading, errors, error, DataPutExplorerUserProject}
}



export function MutationSetExplorerUserProject() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<setExplorerUserProjectFormData>({
    resolver: zodResolver(SetExplorerUserProjectFormSchema),
  })

  const [setExplorerUserProjectBody, {error, loading, data: DataSetExplorerUserProject}] =
    useMutation<TypesSetExplorerUserProjectResponse>(SET_EXPLORER_USUARIO_PROJETOS, {
       refetchQueries: [GET_EXPLORER_USUARIO_PROJETOS],
    })

  async function FormSetExplorerUserProject(data: setExplorerUserProjectFormData) {
    return await setExplorerUserProjectBody({
      variables: {
        explorerUserProject: {...data}
      },
    })

  }


  return {register, handleSubmit, FormSetExplorerUserProject, loading, errors, error, DataSetExplorerUserProject}
}

// export function MutationSetExplorerUserProject() {
//   const {
//     register,
//     handleSubmit,
//     setValue,
//     formState: { errors },
//   } = useForm<setImovelCorretorFormData>({
//     resolver: zodResolver(setImovelCorretorFormSchema),
//   })

  
//   const [setCorretorBody, { error, loading, data: DataSetImovelCorretor }] =
//     useMutation<TypesSetImovelCorretorResponse>(SET_IMOVEL_CORRETOR, {
//       refetchQueries: [GET_IMOVEL_ExplorerUserProject, GET_EMPREENDIMENTO_ExplorerUserProject, GET_ExplorerUserProject],
//     })

//   async function FormImovelCorretor(data: setImovelCorretorFormData) {
//     await setCorretorBody({
//       variables: {
//         data: { ...data }
//       },
//       refetchQueries: [GET_IMOVEL_ExplorerUserProject, GET_EMPREENDIMENTO_ExplorerUserProject, GET_ExplorerUserProject],
//     })

//   }

//   return { register, handleSubmit, setValue, FormImovelCorretor, loading, errors, error, DataSetImovelCorretor }
// }

// export function MutationSetExplorerUserProjectByID() {
//   const {
//     register,
//     handleSubmit,
//     setValue,
//     formState: { errors },
//   } = useForm<setImovelCorretorByIDFormData>({
//     resolver: zodResolver(setImovelCorretorByIDFormSchema),
//   })

  
//   const [setCorretorBody, { error, loading, data: DataSetImovelCorretorByID }] =
//     useMutation<TypesSetImovelCorretorByIDResponse>(SET_IMOVEL_CORRETOR_BY_ID, {
//       refetchQueries: [GET_IMOVEL_ExplorerUserProject, GET_EMPREENDIMENTO_ExplorerUserProject, GET_ExplorerUserProject],
//     })

//   async function FormImovelCorretorByID(data: setImovelCorretorByIDFormData) {
//     await setCorretorBody({
//       variables: {
//         corretor: {
//           nome: data.nome,
//           email: data.email,
//           telefone: data.telefone,
//           empresa: data.empresa
//         },
//         idImovel: data.idImovel
//       },
//       refetchQueries: [GET_IMOVEL_ExplorerUserProject, GET_EMPREENDIMENTO_ExplorerUserProject, GET_ExplorerUserProject],
//     },
//     )
//   }

//   return { register, handleSubmit, setValue, FormImovelCorretorByID, loading, errors, error, DataSetImovelCorretorByID }
// }


export function MutationDeleteExplorerUserProject() {
  const [DeleteExplorerUserProject, { loading, data, error }] = useMutation<TypesExplorerUserProjectResponse>(
    DELETE_EXPLORER_LAYER_USUARIO,
    {
      refetchQueries: [GET_EXPLORER_USUARIO_PROJETOS],
    }
  )

  async function HandleDeleteExplorerUserProject({ variables }: Props) {
    try {
      const result = await DeleteExplorerUserProject({
        variables: { ...variables },
        refetchQueries: [GET_EXPLORER_USUARIO_PROJETOS],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return { HandleDeleteExplorerUserProject, loading, error, data }
}
