import {useState} from 'react'
import {APiUrbit} from '../../../../../services/api/apiUrbit'
import Swal from 'sweetalert2'
import {Geometry} from '../../../../inteligencia-mercado/components/Types'
import {CoordinatesToPath} from '../../../../../components/UI/MapView'
import {Marker, Polygon} from '@react-google-maps/api'
import {Explorer} from '../Explorer'
import {BiTrash} from 'react-icons/bi'

const api = new APiUrbit()

type Result = {
  ponto: Geometry
  quadra: Geometry
}
const BuscaSql = () => {
  const [setor, setSetor] = useState<string>('')
  const [quadra, setQuadra] = useState<string>('')
  const [lote, setLote] = useState<string>('')
  const [result, setResult] = useState<Result>(null)

  async function formSubmit() {
    if (validaForm()) {
      const resultado = await api.get(
        `busca-contribuinte/${setor}/${quadra}${lote ? '/' + lote : ''}`
      )

      if (resultado[0].ponto) {
        const ponto = JSON.parse(resultado[0].ponto)
        const quadra = JSON.parse(resultado[0].quadra)
        setResult({
          ponto,
          quadra,
        })
        if (Object.entries(Explorer.map).length > 0) {
          ;(Explorer.map as any).panTo(
            new google.maps.LatLng(ponto.coordinates[1], ponto.coordinates[0])
          )
        }
        // console.log(ponto.coordinates)
        // Explorer.setGoTo(ponto.coordinates[1],ponto.coordinates[0]        )
      } else {
        Swal.fire('Setor quadra não encontrado', '', 'warning')
      }
    }
    // return resultado
  }

  function validaForm() {
    if (!setor || !quadra) {
      Swal.fire('', 'Informe o setor e a quadra com 3 digitos.', 'warning')
      return false
    } else {
      if (setor.length < 3) {
        Swal.fire('', 'Informe o setor com 3 digitos.', 'warning')
        return false
      } else if (quadra.length < 3) {
        Swal.fire('', 'Informe a quadra com 3 digitos.', 'warning')
        return false
      } else {
        if (lote) {
          if (lote.length < 4) {
            Swal.fire('', 'Informe o lote com 4 digitos.', 'warning')
            return false
          }
        }
      }
    }
    return true
  }
  function limpar() {
    setLote('')
    setQuadra('')
    setSetor('')
    setResult(null)
  }
  const options = {
    fillColor: 'lightgreen',
    fillOpacity: 0.6,
    strokeColor: 'green',
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  }

  return (
    <div
      className='pt-2 d-flex  align-items-center justify-content-start'
      style={{maxWidth: '550px'}}
    >
      <div className=' '>
        <input
          type='text'
          className='form-control  '
          maxLength={3}
          placeholder='Setor'
          value={setor}
          onChange={(e) => setSetor(e.target.value)}
          style={{maxWidth: '100px'}}
        ></input>
      </div>
      <div className='ms-2'>
        <input
          type='text'
          className='form-control '
          maxLength={3}
          placeholder='Quadra'
          value={quadra}
          onChange={(e) => setQuadra(e.target.value)}
          style={{maxWidth: '100px'}}
        ></input>
      </div>
      <div className='ms-2'>
        <input
          type='text'
          className='form-control  '
          maxLength={4}
          placeholder='Lote'
          value={lote}
          onChange={(e) => setLote(e.target.value)}
          style={{maxWidth: '100px'}}
        ></input>
      </div>
      <div className='ms-2'>
        <button type='button' className='btn btn-sm  btn-light-success ' onClick={formSubmit}>
          Buscar
        </button>
      </div>

      <button type='button' className='btn btn-sm  btn-light-danger ms-2 ' onClick={limpar}>
        <BiTrash />
      </button>
      {result ? (
        <Polygon
          paths={CoordinatesToPath(
            result.quadra.type === 'Polygon'
              ? result.quadra.coordinates[0]
              : result.quadra.coordinates[0][0]
          )}
          key={1}
          options={options}
        />
      ) : (
        <></>
      )}
      {result ? (
        <Marker
          position={{lat: result.ponto.coordinates[1], lng: result.ponto.coordinates[0]}}
          //         icon={{
          // scaledSize: new google.maps.Size(20, 20),
          // url:toAbsoluteUrl('/media/75210de1-444b-4077-8a30-d4aa401ee39c.png'),
          // origin: new google.maps.Point(0, 0),
          // anchor: new google.maps.Point(0, 0),
          //   }}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default BuscaSql
