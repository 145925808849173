import {createContext, ReactNode, useState} from 'react'
import {Endereco} from '../../../../../pages/inteligencia-mercado/components/Types'

export type FormAVM = {
  latitude: number
  longitude: number
  endereco_completo: string
  logradouro: string
  numero: string
  bairro: string
  cidade: string
  estado: string
  cep: string
  tipo: string
  subtipo: string
  area: number
  quartos: number
  vagas: number
  suites: number
  banheiros: number
  condominio: number
  lancamento: number
  ano_construcao: number
  imovel_id: string
  id_tipo_imovel: number
}

type Dummies = {
  name: string
  key: string
  type: 'condominio' | 'apartamento'
  selecionado: boolean
  naoSei: boolean
}[]
export type AVMConfig = {
  avm: 'tajmahal' | 'gam'
  features: boolean
  etapaFeaturesPulada: boolean
  cidadeComAmostras: boolean
  ignoreDummies: boolean
}

export type Origem = {
  idTerreno?: number
  idEmpreendimento?: number
  idInteligenciaMercado?: number
  origem: 'inteligencia-mercado' | 'explorer' | 'imovel' | 'empreendimento' | 'avaliacoes'
  endereco?: Endereco | null
}
interface AvmPropsContext {
  setStep: (passo: number) => void
  setEndereco: (endereco: Endereco) => void
  setResult: (result: any) => void
  setFormAvm: (formAvm: any) => void
  setDummies: (dummies: Dummies) => void
  setOrigem: (origem: any) => void
  setAvmConfig: (avmConfig: AVMConfig) => void
  step: number
  endereco: Endereco
  result: any
  formAvm: FormAVM
  dummies: Dummies
  origem: Origem
  avmConfig: AVMConfig
}

export const AvmContext = createContext<AvmPropsContext>({} as AvmPropsContext)

interface FilterProviderProps {
  children: ReactNode
}

export function AvmContextProvider({children}: FilterProviderProps) {
  const [endereco, setEndereco] = useState<Endereco>({
    latitude: null,
    longitude: null,
    endereco_completo: null,
    logradouro: null,
    numero: null,
    bairro: null,
    cidade: null,
    estado: null,
    cep: null,
  })

  const [step, setStep] = useState<number>(0)
  const [formAvm, setFormAvm] = useState<FormAVM>({
    ano_construcao: null,
    area: null,
    bairro: null,
    banheiros: null,
    cep: null,
    cidade: null,
    condominio: null,
    quartos: 1,
    subtipo: null,
    suites: null,
    tipo: null,
    vagas: null,
    lancamento: null,
    endereco_completo: null,
    estado: null,
    latitude: null,
    logradouro: null,
    longitude: null,
    numero: null,
    imovel_id: null,
    id_tipo_imovel: 0,
  })
  const [result, setResult] = useState<any>(null)

  const [avmConfig, setAvmConfig] = useState<any>(null)

  const [origem, setOrigem] = useState<any>(null)

  const [dummies, setDummies] = useState<Dummies>([
    {
      name: 'Academia',
      key: 'academia',
      type: 'condominio',
      selecionado: true,
      naoSei: false,
    },
    {name: 'Acesso PCD', key: 'acesso_pcd', type: 'condominio', selecionado: false, naoSei: false},
    {
      name: 'Area de Lazer',
      key: 'area_lazer',
      type: 'condominio',
      selecionado: true,
      naoSei: false,
    },
    {
      name: 'Área de Serviço',
      key: 'area_servico',
      type: 'condominio',
      selecionado: false,
      naoSei: false,
    },
    {name: 'Área Verde', key: 'area_verde', type: 'condominio', selecionado: false, naoSei: false},
    {
      name: 'Brinquedoteca ',
      key: 'brinquedoteca',
      type: 'condominio',
      selecionado: true,
      naoSei: false,
    },
    {
      name: 'Churrasqueira',
      key: 'churrasqueira',
      type: 'condominio',
      selecionado: true,
      naoSei: false,
    },
    {
      name: 'Apartamento na Cobertura',
      key: 'cobertura',
      type: 'apartamento',
      selecionado: false,
      naoSei: false,
    },
    {name: 'Copa', key: 'copa', type: 'condominio', selecionado: false, naoSei: false},
    {name: 'Elevador', key: 'elevador', type: 'condominio', selecionado: true, naoSei: false},
    {
      name: 'Entrada de Serviço',
      key: 'entrada_servico',
      type: 'condominio',
      selecionado: false,
      naoSei: false,
    },
    {
      name: 'Espaço Gourmet',
      key: 'espaco_gourmet',
      type: 'condominio',
      selecionado: false,
      naoSei: false,
    },
    {name: 'Gerador', key: 'gerador', type: 'condominio', selecionado: false, naoSei: false},
    {
      name: 'Lavanderia no Prédio',
      key: 'lavanderia_predio',
      type: 'condominio',
      selecionado: false,
      naoSei: false,
    },
    {name: 'Pet Space', key: 'pet_space', type: 'condominio', selecionado: true, naoSei: false},
    {name: 'Piscina', key: 'piscina', type: 'condominio', selecionado: true, naoSei: false},
    {name: 'Playground', key: 'playground', type: 'condominio', selecionado: true, naoSei: false},
    {name: 'Portaria', key: 'portaria', type: 'condominio', selecionado: true, naoSei: false},
    {name: 'Quadra', key: 'quadra', type: 'condominio', selecionado: true, naoSei: false},
    {
      name: 'Salão de Festas',
      key: 'salao_festas',
      type: 'condominio',
      selecionado: true,
      naoSei: false,
    },
    {
      name: 'Salão de Jogos',
      key: 'salao_jogos',
      type: 'condominio',
      selecionado: true,
      naoSei: false,
    },
    {name: 'Segurança', key: 'seguranca', type: 'condominio', selecionado: true, naoSei: false},
    {name: 'Spa/Sauna', key: 'spa_sauna', type: 'condominio', selecionado: false, naoSei: false},
    {
      name: 'Armários Embutidos',
      key: 'armarios',
      type: 'apartamento',
      selecionado: false,
      naoSei: false,
    },
    {
      name: 'Closet',
      key: 'closet',
      type: 'apartamento',
      selecionado: false,
      naoSei: false,
    },
    {
      name: 'Cozinha Americana',
      key: 'cozinha_americana',
      type: 'apartamento',
      selecionado: false,
      naoSei: false,
    },
    {
      name: 'Depósito',
      key: 'deposito',
      type: 'apartamento',
      selecionado: false,
      naoSei: false,
    },
    {
      name: 'Mobiliado',
      key: 'mobiliado',
      type: 'apartamento',
      selecionado: false,
      naoSei: false,
    },
    {
      name: 'Piscina Privativa',
      key: 'piscina_privativa',
      type: 'apartamento',
      selecionado: false,
      naoSei: false,
    },
    {
      name: 'Varanda',
      key: 'varanda',
      type: 'apartamento',
      selecionado: false,
      naoSei: false,
    },
    {
      name: 'Vista para o Mar ',
      key: 'vista_mar',
      type: 'apartamento',
      selecionado: false,
      naoSei: false,
    },
    {
      name: 'Vista Livre',
      key: 'vista_livre',
      type: 'apartamento',
      selecionado: false,
      naoSei: false,
    },
  ])

  return (
    <AvmContext.Provider
      value={{
        step,
        endereco,
        result,
        setStep,
        setEndereco,
        setResult,
        formAvm,
        setFormAvm,
        dummies,
        setDummies,
        origem,
        setOrigem,
        avmConfig,
        setAvmConfig,
      }}
    >
      {children}
    </AvmContext.Provider>
  )
}
