import { gql } from "@apollo/client";

export const SET_AVM = gql`
mutation SetAVM($AVM: AVM!) {
  SetAVM(AVM: $AVM) {
    id
    nome
  }
}
`

export const SET_AVM_LEAD = gql`
mutation SetAvmLead($data: avmLead!) {
  SetAvmLead(data: $data) {
    id
  }
}
`

export const PUT_AVM = gql`
mutation PutAVM($AVM: AVM!, $putAVMId: Float!) {
  PutAVM(AVM: $AVM, id: $putAVMId) {
    id
    nome
  }
}
`

export const DELETE_AVM = gql`
mutation DeleteAvmLead($deleteAvmLeadId: Float!) {
  DeleteAvmLead(id: $deleteAvmLeadId) {
    id_empreendimento
    id
    id_terreno
    nome
  }
}
`