import { gql } from "@apollo/client";

export const ADMIN_SET_SERVICO = gql`
mutation SetServico($data: servico!, $idCategorias: [Float!], $idPacotes: [Float!]) {
  SetServico(data: $data, id_categorias: $idCategorias, id_pacotes: $idPacotes) {
    id
  }
}
`

export const ADMIN_PUT_SERVICO = gql`
mutation PutServico($data: servico!, $idPacotes: [Float!], $idCategorias: [Float!]) {
  PutServico(data: $data, id_pacotes: $idPacotes, id_categorias: $idCategorias) {
    id
  }
}
`
