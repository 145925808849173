import {useState} from 'react'
import {Buttons} from './ButtonsCorretores'
import {BiPlus} from 'react-icons/bi'
import {QueryGetCorretores} from '../../../graphql/services/Corretores'
import {EditForm} from './EditForm'
import {CreateForm} from './CreateForm'
import {Loader} from '../../../components/UI/Loader/Loader'
import PaginationComponent from '../../../components/UI/PaginationComponents'
import {CardTables} from '../../../components/UI/CardTables'
import LoadingTable from '../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../components/UI/CardTables/ErrorTable'

type Props = {
  filter: any[]
}

export function TableCorretores({filter}: Props) {
  const [pagiancao, setPaginacao] = useState({pagina: 0, quantidade: 10})

  console.log(filter)

  const {data, loading, error} = QueryGetCorretores({
    variables: {
      pagination: {
        pagina: pagiancao.pagina,
        quantidade: pagiancao.quantidade,
      },
      filtro: {
        fields: filter,
      },
    },
  })

  const [showCreate, setShowCreate] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [id, setId] = useState(null)

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }
  function CloseModalEdit() {
    setShowEdit(false)
    setId(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setId(id)
  }

  return (
    <>
      <CardTables
        headerTitle={'Corretores'}
        headerSubtTitle={`Listando ${
          data ? data.GetCorretores.pageInfo.totalItems : 0
        } corretores registrados.`}
        headerToolbar={
          <button
            className='btn btn-sm btn-light-primary'
            onClick={(e) => {
              e.preventDefault()
              OpenModalCreate(null)
            }}
          >
            <BiPlus className='fs-3'></BiPlus>
            Novo Corretor
          </button>
        }
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetCorretores.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({pagina: pagina, quantidade: quantidade})
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-150px'>Nome</th>
                <th className='min-w-140px'>Email</th>
                <th className='min-w-120px'>Telefone</th>
                <th className='min-w-100px text-end'>Ações</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetCorretores.result.length > 0 ? (
                  data.GetCorretores.result.map((response) => {
                    return (
                      <tr key={response.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {response.nome}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{response.email != null ? response.email : 'Não informado'}</td>
                        <td>
                          {response.telefone != null ? `${response.telefone}` : 'Não informado'}
                        </td>
                        <td>
                          <Buttons setIdEdit={OpenModalEdit} id={response.id} />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
      {showEdit ? <EditForm id={id} show={showEdit} closeModal={CloseModalEdit}></EditForm> : <></>}
      {showCreate ? (
        <CreateForm closeModal={CloseModalCreate} show={showCreate}></CreateForm>
      ) : (
        <></>
      )}
    </>
  )
}
