    import { SupportForm } from "..";
    import { FaqItem } from "../partials/FaqItem";
    import { dadosFAQ } from "../partials/dados";

    export function Tab1() {

        return (
            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" >
                <div className="flex-lg-row-fluid">
                    <div className="mb-13">
                        <div className="mb-15">
                            <h4 className="fs-2x text-gray-800 w-bolder mb-6">
                                Meus Imoveis
                            </h4>

                            <p className="fw-semibold text-justify fs-4 text-gray-600 mb-2">
                                Primeiro, um aviso – todo o processo de escrever uma postagem no blog geralmente leva mais de algumas horas,
                                mesmo que você possa digitar oitenta palavras por minuto e suas habilidades de escrita sejam nítidas.
                            </p>
                        </div>

                        <div className="mb-15">
                            <h3 className="text-gray-800 w-bolder mb-4">
                                Buying Product
                            </h3>

                            {dadosFAQ.MeusImoveis.map((item, index) => (
                                <FaqItem
                                    key={index}
                                    video={item.video}
                                    urlVideo={item.urlVideo}
                                    title={item.pergunta}
                                    description={item.resposta}
                                    index={index}
                                />
                            ))}


                        </div>

                    </div>
                </div>

                <div className="card mb-4 ">
                    <div className="card-body py-2">
                        <SupportForm />
                    </div>
                </div>
            </div>
        )
    }