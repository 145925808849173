import { useMutation, useQuery } from '@apollo/client'
import { TypeDeleteEmpreendimentoMassaResponse, TypeEmpreendimentoMassaResponse, TypeGetEmpreendimentoMassaByIdResponse, TypePutEmpreendimentoMassaResponse, TypeSetEmpreendimentoMassaResponse } from '../type'
import { GET_BY_ID_EMPREENDIMENTO_MASSA, GET_EMPREENDIMENTO_MASSA } from '../query'
import {
  FormCreateEmpreendimentoMassaFormData,
  FormCreateEmpreendimentoMassaFormSchema,
  FormEmpreendimentoFormData,
  FormEmpreendimentoFormSchema,
  FormUpdateEmpreendimentoMassaFormData,
  FormUpdateEmpreendimentoMassaFormSchema,
} from '../formValidations'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { DELETE_EMPREENDIMENTO_MASSA, PUT_EMPREENDIMENTO_MASSA, SET_EMPREENDIMENTO_MASSA } from '../mutations'

interface QueryProps {
  variables: any
  skip?: any
}

export function QueryGetEmpreendimentoMassa({ variables }: QueryProps) {
  const { data, loading, error } = useQuery<TypeEmpreendimentoMassaResponse>(GET_EMPREENDIMENTO_MASSA, {
    variables: { ...variables }
  })

  return { data, loading, error }
}

export function QueryGetEmpreendimentoMassaById({ variables }: QueryProps) {
  const { data, loading, error } = useQuery<TypeGetEmpreendimentoMassaByIdResponse>(GET_BY_ID_EMPREENDIMENTO_MASSA, {
    variables: { ...variables }
  })

  return { data, loading, error }
}

export function MutationCreateEmpreendimentoMassa() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormCreateEmpreendimentoMassaFormData>({
    resolver: zodResolver(FormCreateEmpreendimentoMassaFormSchema),
  })

  const [setEmpreendimentoMassaBody, { error, loading, data: DataSetEmpreendimentoMassa }] =
    useMutation<TypeSetEmpreendimentoMassaResponse>(SET_EMPREENDIMENTO_MASSA, {
      refetchQueries: [GET_EMPREENDIMENTO_MASSA],
    })

  async function FormSetEmpreendimentoMassa(data: FormCreateEmpreendimentoMassaFormData) {
    await setEmpreendimentoMassaBody({
      variables: { massa: { ...data } },
    })
  }

  return {
    register,
    handleSubmit,
    FormSetEmpreendimentoMassa,
    loading,
    errors,
    error,
    control,
    DataSetEmpreendimentoMassa,
  }
}

export function MutationPutEmpreendimentoMassa() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormUpdateEmpreendimentoMassaFormData>({
    resolver: zodResolver(FormUpdateEmpreendimentoMassaFormSchema),
  })

  
  const [putEmpreendimentoMassa, { error, loading, data: DataPutEmpreendimentoMassa }] =
    useMutation<TypePutEmpreendimentoMassaResponse>(PUT_EMPREENDIMENTO_MASSA, {
      refetchQueries: [GET_EMPREENDIMENTO_MASSA],
    })

  async function FormEmpreendimentoPutMassa(data: FormUpdateEmpreendimentoMassaFormData) {
    await putEmpreendimentoMassa({
      variables: {
        massa: { ...data }
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormEmpreendimentoPutMassa,
    loading,
    errors,
    error,
    control,
    setError,
    setValue,
    DataPutEmpreendimentoMassa,
  }
}


export function MutationDeleteEmpreendimentoMassa() {

  const [deleteEmpreendimentoMassa, {loading, error, data}] = useMutation<TypeDeleteEmpreendimentoMassaResponse>(
    DELETE_EMPREENDIMENTO_MASSA,
    {
      refetchQueries: [GET_EMPREENDIMENTO_MASSA],
    }
  )

  async function HandleDeleteEmpreendimentoMassa({ variables }: QueryProps) {
    try {
      const result = await deleteEmpreendimentoMassa({
        variables: { ...variables },
        refetchQueries: [GET_EMPREENDIMENTO_MASSA],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return { HandleDeleteEmpreendimentoMassa, loading, error, data }
}

