import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { BiCheck, BiError, BiSync } from 'react-icons/bi'
import { MutationDeleteImovelProprietarios } from '../../../../../graphql'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { ButtonLoading } from '../../../../../components/UI/Button/ButtonLoading'
import { EditFormImoveProprietarios } from './EditForm'
// import { APiBigDataCorp } from '../../../../../../../services/api/apiBigDataCorp'

type Props = {
  id: number
  idProprietario: number
  idTerreno: number
  syncOk: boolean
  updateList: any
  showButtonSync: boolean
}

export const Buttons: React.FC<Props> = ({ id, idProprietario, idTerreno, syncOk, updateList, showButtonSync }) => {
  const [sync, setSync] = useState(false)
  const [erroSync, setErrorSync] = useState(false)
  const { HandleDeleteProprietarios, loading } = MutationDeleteImovelProprietarios()

  if (loading) {
    Swal.fire(
      'Enviando Informações...', '')
    Swal.showLoading()
  }

  const [idEditProprietario, setIdProprietario] = useState(null)
  const [showEdit, setShowEdit] = useState(false)

  function CloseModalEdit() {
    setShowEdit(false)
    setIdProprietario(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setIdProprietario(id)
  }


  //   const {
  //     DataImovelProprietarios,
  //     FormImovelProprietarios,
  //     error,
  //     loading:loadProprietario,
  // } = MutationUpdateImovelProprietarios()

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteProprietarios({ variables: { deleteImovelProprietarioId: id } })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Proprietario deletado com sucesso.', 'success')
        }
      }
    })
  }

  async function syncBigDataCorp(idProprietario) {

    const response = await fetch(
      process.env.REACT_APP_API_URL_EXTRA_SERVICES + 'sync-proprietario/' + idProprietario,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    const data = await response.json()
    if (!data.error) {
      setErrorSync(false)
      updateList()
    } else {
      setErrorSync(data.mensagem)
    }
    setSync(false)
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        {showButtonSync ?
          <button
            onClick={(e) => {
              setSync(true)
              syncBigDataCorp(idProprietario)
            }}
            className={erroSync ? 'btn btn-light-danger btn-sm me-2' : syncOk ? 'btn btn-light-success btn-sm me-2' : erroSync ? 'btn btn-light-danger btn-sm me-2' : 'btn btn-light-warning btn-sm me-2'}>
            {erroSync ? <BiError className='fs-1' title={'Erro ao fazer o Sync: ' + erroSync}></BiError> :
              syncOk ? <BiCheck className="fs-1"></BiCheck> : <BiSync className="fs-1"></BiSync>}

            {sync ? <ButtonLoading text={'Sincronizando...'}  ></ButtonLoading> : ''}
          </button>
          : <button className='btn btn-light-info btn-sm me-2'><BiError className='fs-1' title={'Sem CPF ou CNPJ Cadastrado.'}></BiError></button>
        }
        <button
          onClick={(e) => {
            OpenModalEdit(id)
          }}
          className='btn btn-icon btn-light-success btn-sm me-2'>
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button>
        {showEdit ? <EditFormImoveProprietarios id={id} idProprietario={idProprietario} idTerreno={idTerreno} closeModal={CloseModalEdit} show={showEdit} /> : <></>}

        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          className='btn btn-icon btn-light-danger btn-sm me-2' >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
