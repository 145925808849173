import { gql } from "@apollo/client";

export const GET_PROFILE = gql`
query GetProfile{
  GetProfile{
    id
    name
    email
    telefone
    cpf
    cnpj
    tipo_pessoa
    cor_fonte
    cor_background
    user_pic
  }
}
`

export const GET_EMPRESA_USUARIO = gql`
query GetEmpresasUsuario($pagination: Pagination!) {
  GetEmpresasUsuario(pagination: $pagination) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      id
      uuid
      id_user
      id_empresa
      created_at
      updated_at
      deleted_at
      tipo_usuario
      visualizacao
      exclusao
      edicao
      users {
        id
        name
        email
      }
    }
  }
}
`

export const GET_USUARIO_BY_ID = gql`
query GetUsuario($getUsuarioId: Float!) {
  GetUsuario(id: $getUsuarioId) {
    id
    name
    email
    uuid
    api_token
    profile_id
    telefone
    cpf
    cnpj
    tipo_pessoa
    cor_fonte
    cor_background
    remember_token
    id_area_atuacao
   area_atuacao {
     name
     id
   }
    token_troca_senha
  }
}
`

