import { gql } from "@apollo/client";


export const SET_EXPLORER_LAYER_USUARIO = gql`
mutation SetExplorerLayerUserFavorito($explorerLayerUserFavorito: explorerLayerUserFavorito!) {
  SetExplorerLayerUserFavorito(explorerLayerUserFavorito: $explorerLayerUserFavorito) {
    id_layer
    id
  }
}
`

export const SET_EXPLORER_LAYER_USER = gql`
  mutation SetExplorerLayerUser($explorerLayerUser: explorerLayerUser!) {
    SetExplorerLayerUser(explorerLayerUser: $explorerLayerUser) {
      id
    }
  }
`

export const DELETE_EXPLORER_LAYER_USER = gql`
mutation DeleteExplorerLayerUser($deleteExplorerLayerUserId: Float!) {
  DeleteExplorerLayerUser(id: $deleteExplorerLayerUserId) {
    id
  }
}
`



export const DELETE_EXPLORER_LAYER_USUARIO = gql`
mutation DeleteExplorerUserProject($idExplorerLayer: Float!) {
  DeleteExplorerUserProject(idExplorerLayer: $idExplorerLayer) {
    id
    id_user
    json
    nome
  }
}
`
