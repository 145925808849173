import { z } from "zod"

export const setServicoFormSchema = z.object({
    filtro_isocota: z.number(),
    filtro_isocrona: z.number(),
    filtro_limit: z.number(),
    recorrencia: z.number(),
    limite: z.number(),
    distancia: z.number(),
    url_api: z.string(),
    url_sistema: z.string(),
    ordem: z.number(),
    session_name: z.string(),
    custo: z.number(),
    descricao: z.string(),
    nome: z.string(),
    situacao: z.number(),
    updated_at: z.string(),
    categorias: z.array(z.number()),
    pacotes: z.array(z.number()),
})

export type setServicoFormData = z.infer<typeof setServicoFormSchema>

export const putServicoFormSchema = z.object({
    idCategorias: z.array(z.number()),
    idPacotes: z.array(z.number()),
    data: z.object({
        id: z.number(),
        filtro_isocota: z.number(),
        filtro_isocrona: z.number(),
        filtro_limit: z.number(),
        recorrencia: z.number(),
        distancia: z.number(),
        url_api: z.string(),
        url_sistema: z.string(),
        ordem: z.number(),
        session_name: z.string(),
        custo: z.number(),
        descricao: z.string(),
        nome: z.string(),
        situacao: z.number(),
        limite: z.number(),
    }),
});


export type putServicoFormData = z.infer<typeof putServicoFormSchema>