import { gql } from "@apollo/client";

export const SET_EXPLORER_LAYER = gql`
mutation SetExplorerLayer($explorerLayer: explorerLayer!) {
  SetExplorerLayer(explorerLayer: $explorerLayer) {
    id
  }
}
`

export const PUT_EXPLORER_LAYER = gql`
mutation PutExplorerLayer($explorerLayer: explorerLayer!) {
  PutExplorerLayer(explorerLayer: $explorerLayer) {
    id
  }
}
`

export const DELETE_EXPLORER_LAYER = gql`
mutation DeleteExplorerLayer($deleteExplorerLayerId: Float!) {
  DeleteExplorerLayer(id: $deleteExplorerLayerId) {
    nome
  }
}
`