import { gql } from "@apollo/client";

// Criação de situação de imovel com campos e com ID do usuario
export const CREATE_IMOVEL_CLIENTE = gql`
mutation SetSituacaoImovelCliente( $status: Float!, $descricao: String!, $nome: String!) {
  SetSituacaoImovelCliente( status: $status, descricao: $descricao, nome: $nome) {
    id
    nome
  }
}
`

// Alterar alguma situação de imovel pelo ID do imovel
export const PUT_IMOVEL_SITUACAO_TERRENO = gql`
mutation PutSituacaoImovelCliente($putSituacaoImovelClienteId: Float!, $status: Float!, $description: String!, $nome: String!) {
  PutSituacaoImovelCliente(id: $putSituacaoImovelClienteId, status: $status, description: $description, nome: $nome) {
    id
    nome
  }
}
`

// Deletar Situação de terreno pelo ID do imovel
export const DELETE_IMOVEL_BY_ID = gql`
mutation DeleteImovelCliente($deleteImovelClienteId: Float!) {
  DeleteImovelCliente(id: $deleteImovelClienteId) {
    id
    nome
  }
}
`