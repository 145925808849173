import { gql } from "@apollo/client";

export const GET_IMOVEIS_OBSERVACOES = gql` 
query GetImovelsObservacao($pagination: Pagination!, $imovelObservacao: imovelObservacao!) {
  GetImovelsObservacao(pagination: $pagination, imovelObservacao: $imovelObservacao) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        id
        uuid
        id_usuario_cadastro
        id_usuario_alteracao
        titulo
        descricao
        data_cadastro
      }
    }
  }
`

export const ONE_IMOVEL_OBSERVACAO = gql`
query GetImovelObservacao($imovelObservacao: imovelObservacao!) {
  GetImovelObservacao(imovelObservacao: $imovelObservacao) {
    id
    uuid
    id_terreno
    id_usuario_cadastro
    id_usuario_alteracao
    data_cadastro
    data_alteracao
    titulo
    descricao
  }
}
`