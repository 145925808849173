import { z } from "zod"

export const setProdutoFormSchema = z.object({
    nome: z.string()
    .nonempty('O código é obrigatório!'),
    // subtitulo: z.string(),
    desconto: z.number()
        .min(0, 'Digite um valor entre 0 e 100.')
        .max(100, 'Digite um valor entre 0 e 100.')
        .optional(),
    descricao: z.string()
    .nonempty('O código é obrigatório!'),
    valor: z.number(),
    tipo_produto: z.string(),
    situacao: z.number()
})

export type setProdutoFormData = z.infer<typeof setProdutoFormSchema>

export const putProdutoFormSchema = z.object({
    id: z.number(),
    nome: z.string()
    .nonempty('O código é obrigatório!'),
    // subtitulo: z.string(),
    desconto: z.number()
        .min(0, 'Digite um valor entre 0 e 100.')
        .max(100, 'Digite um valor entre 0 e 100.')
        .optional(),
    descricao: z.string()
    .nonempty('O código é obrigatório!'),
    valor: z.number(),
    tipo_produto: z.string(),
    situacao: z.number()
})

export type putProdutoFormData = z.infer<typeof putProdutoFormSchema>

export const setUsuarioProdutoFormSchema = z.object({
    id_user: z.number(),
    id_produto: z.number(),
    id_periodo: z.number(),
    data_inicio: z.date().optional(),
    data_fim: z.date().optional()
})

export type setUsuarioProdutoFormData = z.infer<typeof setUsuarioProdutoFormSchema>


export const putUsuarioProdutoFormSchema = z.object({
    id: z.number(),
    id_user: z.number(),
    id_produto: z.number(),
    id_periodo: z.number(),
    data_inicio: z.date().optional(),
    data_fim: z.date().optional()
})

export type putUsuarioProdutoFormData = z.infer<typeof putUsuarioProdutoFormSchema>
