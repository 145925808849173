// apiMethods.ts
import {ApiUrbitGis} from '../index'
import {
  RealEstateTower,
  RealEstateTypology,
  RealEstateUnit,
  ApiResponse,
} from './types'

interface FilterField {
  field: string[]
  operator: string
  value: string[]
}

interface Filter {
  fields: FilterField[]
}

interface GetParams {
  page?: number
  limit?: number
  filter?: Filter
  [key: string]: any // Para outros parâmetros dinâmicos
}

async function getLaunches(params: GetParams) {
  const url = 'real_estate_launches/list' // Substitua pela URL correta

  // Configura o corpo da requisição
  const body = {
    page: params.page ?? 0,
    limit: params.limit ?? 10,
    filter: params.filter,
    ...params, // Adiciona outros parâmetros dinâmicos, se houver
  }

  try {
    // Usa a função post do ApiUrbitGis
    const result = await ApiUrbitGis.post(url, body)

    // Aqui você pode processar o resultado se necessário
    return result
  } catch (error) {
    console.error('Error:', error)
    throw error // Lança o erro para ser tratado onde a função getLaunches for chamada
  }
}

async function getLaunchByID(id: number) {
  try {
    const url = `real_estate_launches/list/${id}`
    const result = await ApiUrbitGis.get(url)
    return result
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}

async function addLaunch(data: any): Promise<any> {
  try {
    const result = await ApiUrbitGis.post('real_estate_launches/add', data)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function updateLaunch(id: number, data: any): Promise<any> {
  try {
    const result = await ApiUrbitGis.put(`real_estate_launches/update/${id}`, data)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function deleteLaunch(id: number): Promise<any> {
  try {
    const result = await ApiUrbitGis.delete(`real_estate_launches/delete/${id}`)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function getTowers(
  callback?: (response: ApiResponse<RealEstateTower>) => void
): Promise<ApiResponse<RealEstateTower>> {
  try {
    const result = await ApiUrbitGis.get('real_estate_launches_towers/list')
    if (callback) {
      callback(result)
    }
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error, itens: [], total: 0}
  }
}

async function addTower(data: any): Promise<any> {
  try {
    const result = await ApiUrbitGis.post('real_estate_launches_towers/add', data)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function updateTower(id: number, data: any): Promise<any> {
  try {
    const result = await ApiUrbitGis.put(`real_estate_launches_towers/update/${id}`, data)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function deleteTower(id: number): Promise<any> {
  try {
    const result = await ApiUrbitGis.delete(`real_estate_launches_towers/delete/${id}`)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function getTypologies(
  callback?: (response: ApiResponse<RealEstateTypology>) => void
): Promise<ApiResponse<RealEstateTypology>> {
  try {
    const result = await ApiUrbitGis.get('real_estate_launches_typologies/list')
    if (callback) {
      callback(result)
    }
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error, itens: [], total: 0}
  }
}

async function getTypologiesByID(id: number, page: number, quantity: number) {
  try {
    const url = `real_estate_launches_typologies/${id}/list?page=${page}&limit=${quantity}`
    const result = await ApiUrbitGis.get(url)
    return result
  } catch (error) {
    console.error('Error:', error)
    throw error // Lança o erro para ser tratado onde a função getLaunches for chamada
  }
}

async function getTypologyByID(id: number) {
  try {
    const url = `real_estate_launches_typologies/list/${id}`
    const result = await ApiUrbitGis.get(url)
    return result
  } catch (error) {
    console.error('Error:', error)
    throw error // Lança o erro para ser tratado onde a função getLaunches for chamada
  }
}

async function addTypology(data: any): Promise<any> {
  console.log(data)
  try {
    const result = await ApiUrbitGis.post('real_estate_launches_typologies/add', data)
    console.log(result)
    return result.data // Assuming result.data contains the response data
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error.message}
  }
}

async function updateTypology(id: number, data: any): Promise<any> {
  try {
    const result = await ApiUrbitGis.put(`real_estate_launches_typologies/update/${id}`, data)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function deleteTypology(id: number): Promise<any> {
  try {
    const result = await ApiUrbitGis.delete(`real_estate_launches_typologies/delete/${id}`)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function getUnitsByTowerId(
  id: number,
  callback?: (response: ApiResponse<RealEstateUnit>) => void
): Promise<ApiResponse<RealEstateUnit>> {
  try {
    const result = await ApiUrbitGis.get(`real_estate_launches_units/${id}/list`)
    if (callback) {
      callback(result)
    }
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error, itens: [], total: 0}
  }
}

async function addUnit(data: any): Promise<any> {
  try {
    const result = await ApiUrbitGis.post('real_estate_launches_units/add', data)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function updateUnit(id: number, data: any): Promise<any> {
  try {
    const result = await ApiUrbitGis.put(`real_estate_launches_units/update/${id}`, data)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function deleteUnit(id: number): Promise<any> {
  try {
    const result = await ApiUrbitGis.delete(`real_estate_launches_units/delete/${id}`)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function getTypologyPrices(id: number, params: GetParams) {
  const url = `real_estate_launches_typologies_prices/${id}/list`

  // Configura o corpo da requisição
  const body = {
    page: params.page ?? 0,
    limit: params.limit ?? 10,
    filter: params.filter,
    ...params, // Adiciona outros parâmetros dinâmicos, se houver
  }

  try {
    // Usa a função post do ApiUrbitGis
    const result = await ApiUrbitGis.post(url, body)

    // Aqui você pode processar o resultado se necessário
    return result
  } catch (error) {
    console.error('Error:', error)
    throw error // Lança o erro para ser tratado onde a função getLaunches for chamada
  }
}



async function getTypologyPriceByID(id: number) {
  try {
    const result = await ApiUrbitGis.get(`real_estate_launches_typologies_prices/list/${id}`)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error, itens: [], total: 0}
  }
}

async function addTypologyPrice(data: any): Promise<any> {
  try {
    const result = await ApiUrbitGis.post('real_estate_launches_typologies_prices/add', data)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function updateTypologyPrice(id: number, data: any): Promise<any> {
  try {
    const result = await ApiUrbitGis.put(
      `real_estate_launches_typologies_prices/update/${id}`,
      data
    )
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function deleteTypologyPrice(id: number): Promise<any> {
  try {
    const result = await ApiUrbitGis.delete(`real_estate_launches_typologies_prices/delete/${id}`)
    return result
  } catch (error) {
    console.error('Error:', error)
    return {service: 'error', message: error}
  }
}

async function getLaunchesPartners(page: number, quantity: number) {
  try {
    const url = `real_estate_launches_partners/list?page=${page}&limit=${quantity}`
    const result = await ApiUrbitGis.get(url)
    return result
  } catch (error) {
    console.error('Error:', error)
    throw error // Lança o erro para ser tratado onde a função getLaunches for chamada
  }
}

export {
  getLaunches,
  getTypologiesByID,
  getLaunchByID,
  addLaunch,
  updateLaunch,
  deleteLaunch,
  getTowers,
  addTower,
  updateTower,
  deleteTower,
  getTypologies,
  getTypologyByID,
  addTypology,
  updateTypology,
  deleteTypology,
  getUnitsByTowerId,
  addUnit,
  updateUnit,
  deleteUnit,
  getTypologyPrices,
  getTypologyPriceByID,
  addTypologyPrice,
  updateTypologyPrice,
  deleteTypologyPrice,
  getLaunchesPartners,
}
