import React from 'react'
import Select from 'react-select'
import { TypologyData } from '../../../../../../../services/http/urbit-gis/routes/types'

// Define the type for the options
type OptionType = {
  value: number
  label: string
}

type Props = {
  data: TypologyData[]
  selectedValue: number | null
  onChange: (value: number | null) => void
}

export function SelectTypologiy({ data, selectedValue, onChange }: Props) {
  // Convert data to the format expected by react-select
  const options: OptionType[] = data.map((item) => ({
    value: item.id,
    label: `${item.type} - Dormitórios ${item.bedrooms} - Vagas ${item.parking}`,
  }))

  // Find the selected option based on the selectedValue
  const selectedOption = options.find((option) => option.value === selectedValue) || null

  return (
    <Select
      className='mt-4'
      options={options}
      value={selectedOption} // Display the selected value
      onChange={(option) => onChange(option ? option.value : null)} // Handle value change
      placeholder='Selecione'
    />
  )
}
