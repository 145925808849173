import {useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {BiCheck, BiCopy} from 'react-icons/bi'
import {ModalSendEmail} from './ModalSendEmail'
import { GetProdutosUsuario } from '../../../../utils/PermissoesAcesso'

type Props1Item = {
  uuid: string
  ficha_publica: number
  id: number
  data:any
  grupo:number
}

export const HeaderActions: React.FC<Props1Item> = ({uuid, ficha_publica,data, grupo, id}) => {
  const [copy, setCopy] = useState(false)
  const [show, setShow] = useState(false)

  // const [setImovelBody] =
  //   useMutation<PutImovelResponse>(PUT_IMOVEL, {
  //     refetchQueries: [GET_ONE_IMOVEL],
  //   })

  function onCopyLocation(text) {
    navigator.clipboard.writeText(text)
    setCopy(true)
    return false
  }
  function hide() {
    setShow(false)
  }
  function downloadPDF(uuid: string): void {
    window.open('/ficha/' + uuid + '/download?idCidade=' + data.GetImovel.id_cidade +'&idGrupo='+grupo, '_blank')
  }

  // function downloadXLS(uuid: string): void {
  //   console.log('downloadXLS')
  // }

  // function downloadEmail(uuid: string): void {
  //   console.log('downloadEMAIL')
  //   setShow(true)
  // }
  // function fichaPublica(event, id) {
  //   if (event.target.checked) {
  //     // console.log(data)
  //     setImovelBody({
  //       variables: {
  //         putImovelId: id,
  //         imovel: {
  //           ficha_publica: 1,
  //         },
  //       },
  //     })
  //   }
  // }
  let dadosProdutos = GetProdutosUsuario()
  
  return (
    <>
      <div className=' d-flex no-print'>
       
        <div className='col-md-9 flex-column flex-column-fluid'>
 {dadosProdutos ? 
 <>
          {ficha_publica === 1 ?  
           (<div className='input-group'>
            <span className='input-group-btn'>
              <button
                className={copy ? 'btn btn-success btn-copy' : 'btn btn-primary btn-copy'}
                data-clipboard-target='#link-compartilhamento'
                type='button'
                data-original-title=''
                title=''
                onClick={() => onCopyLocation(`${process.env.REACT_APP_HOST}/fichas/${uuid}`)}
              >
                {copy ? <BiCheck className='fs-1'></BiCheck> : <BiCopy className='fs-1'></BiCopy>}
              </button>
            </span>
            <input
              type='text'
              className='form-control'
              id='link-compartilhamento'
              value={`${process.env.REACT_APP_HOST}/fichas/${uuid}`}
              placeholder='Link da Ficha'
              readOnly={true}
            />
          </div>) : ''

}

          {/* <div className='clearfix pt-5'>
            <div className='checkbox-custom'>
              <label className='fs-3'>
                
                <input
                  type='checkbox'
                  defaultChecked={ficha_publica ? true : false}
                  className='switch-web p-3 me-2'
                  name='ficha_publica'
                  onClick={(e) => {
                    fichaPublica(e, id)
                  }}
                />
                Ficha disponível na web (Visível para todos por meio do link).
              </label>
            </div>
          </div> */}
          
          </>
          : ''}
        </div>
        



        <div className='col-md-3  flex-column flex-column-end'>
          <Dropdown>
            <Dropdown.Toggle variant='light' id='dropdown-basic' style={{float: 'right'}}>
              Ações
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href='#/action-1' onClick={() => downloadPDF(uuid)}>
                Download
              </Dropdown.Item>
              {/* <Dropdown.Item href='#/action-1' onClick={()=>downloadXLS(uuid)} >Download Xls</Dropdown.Item> */}
              {/* <Dropdown.Item href='#/action-2'  onClick={()=>downloadEmail(uuid) }>Enviar link por e-mail</Dropdown.Item> */}
              {/* <Dropdown.Item href='#/action-3'>Something else</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <ModalSendEmail
          textDefault={`Link: ${process.env.REACT_APP_HOST}/fichas/${uuid}`}
          show={show}
          closeModal={hide}
        ></ModalSendEmail>
      </div>
    </>
  )
}
