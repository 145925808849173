// import { useQuery } from "@apollo/client";
import React, { useState } from 'react'
// import { TypeGetEmpreendimentosMassaUsosResponse } from "../../../../../../../graphql/type/empreendimento-massa-usos";
// import { GET_EMPREENDIMENTO_MASSA_USOS } from "../../../../../../../graphql/query/empreendimento-massa-usos";
import { Buttons } from './partials/Buttons'
import { QueryGetEmpreendimentoMassaUsos } from '../../../../../../../graphql/services/EmpreendimentoMassaUsos'
import { Loader } from '../../../../../../../components/UI/Loader/Loader'
import { ModalMassaUsoUpdate } from './Modals/Update'

type Props = {
  id_massa: number
  imoveis: any
}

export const TableEmpreendimentosMassaUsos: React.FC<Props> = ({ id_massa, imoveis }) => {
  const data = QueryGetEmpreendimentoMassaUsos({
    variables: {
      pagination: {
        quantidade: null,
        pagina: null,
      },
      massaId: id_massa,
    },
  })

  const [id, setId] = useState(null)
  const [showEdit, setShowEdit] = useState(false)

  function CloseModalEdit() {
    setShowEdit(false)
    setId(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setId(id)
  }

  if (!data) {
    return <Loader text={'caregando...'}></Loader>
  }

  return (
    <>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead>
          <tr className='fw-bolder text-muted'>
            <th className='min-w-50px'>ID</th>
            <th className='min-w-100px'> Nome</th>
            <th className='min-w-100px'> Uso</th>
            <th className='min-w-50px '>Área de terreno</th>
            <th className='min-w-50px '>Área de computavel</th>
            <th className='min-w-50px '>Área de excedente</th>
            <th className='min-w-50px '>Área de privativa</th>
            <th className='min-w-100px text-end'>Ações</th>
          </tr>
        </thead>
        {/* end::Table head */}

        <tbody>
          {data ? (
            data.GetEmpreendimentoMassaUsos.result.map((response, item) => {
              return (
                <tr key={item}>
                  <td>
                    <p className='text-dark fs-6'>{response.id}</p>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#vazio' className='text-dark fs-6'>
                          {response.nome}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#vazio' className='text-dark fs-6'>
                          {response.uso}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className='text-dark fs-6'>{response.area_terreno}</p>
                  </td>
                  <td>
                    <p className='text-dark fs-6'>{response.area_computavel}</p>
                  </td>
                  <td>
                    <p className='text-dark fs-6'>{response.area_excedente}</p>
                  </td>
                  <td>
                    <p className='text-dark fs-6'>{response.area_privativa}</p>
                  </td>
                  <td>
                    <Buttons imoveis={imoveis}  id={response.id} />
                  </td>
                </tr>
              )
            })
          ) : (
            <tr key={0}>
              <td>
                <p className='text-dark fs-6'>Nenhum uso cadastrado</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>

  )
}
