import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { BiAddToQueue } from "react-icons/bi";
import JoditEditor from "jodit-react";
import { FormInputAndLabel } from "../../../../components/UI/Inputs/FormInputAndLabel";
import { MutationSetTermo } from "../../../graphql/services/Termos";
import Swal from "sweetalert2";
import { ErrorMessageComponent } from "../../../components/UI/ErrorMessage";

export function ModalSetTermos() {
  const [show, setShow] = useState(false);

  const editor = useRef(null)
  const [content, setContent] = useState('')

  const HandleUpdate = (newContent) => {
    setContent(newContent);
    setValue('texto', newContent)
  };

  const config = {
    readonly: false,
    heigth: 600
  }

  const { FormSetTermo, handleSubmit, register, setValue, errors } = MutationSetTermo()


  const onSubmit = async (data) => {
    data.texto = content;
    FormSetTermo(data)
      .then((result) => {
        Swal.fire('Sucesso!', 'Termo criado com sucesso.', 'success');
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error');
      });
  };


  function closeModal() {
    setShow(false);
  }

  function openModal() {
    setShow(true);
  }




  return (
    <>
      <button onClick={openModal} className='btn btn-light-primary btn-sm d-block me-2' style={{ width: '100px !important' }}>
        <BiAddToQueue className='fs-2 me-1' />Adicionar Termos
      </button>

      <Modal centered animation show={show} onHide={() => closeModal()} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Termos:</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-lg'>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <form onSubmit={(e) => handleSubmit((data) => onSubmit(data))(e)}>
                  <div className="row mb-4">
                    <div className="col-6">
                      <FormInputAndLabel
                        label="Nome:"
                        placeholder="Nome do Termo"
                        className="form-control form-control-solid"
                        register={{ ...register('nome') }}
                        error={errors.nome}
                      />

                    </div>
                    <div className="col-6">
                      <FormInputAndLabel
                        label="Titulo:"
                        placeholder="Titulo do Termo"
                        className="form-control form-control-solid"
                        defaultValue={'<h1></h1>'}
                        register={{ ...register('titulo') }}
                        error={errors.titulo}

                      />
                    </div>
             

                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className='form-group'>
                        <label className='col-form-label'>Descrição:</label>
                        <JoditEditor
                          value={content}
                          ref={editor}
                          config={config}
                          onBlur={HandleUpdate}
                          onChange={(newContet) => {
                            setValue('texto', newContet)
                          }}
                        />
                        {errors.texto && <ErrorMessageComponent message={String(errors.texto)} />}
                      </div>
                    </div>
                  </div>

                  <div className="w-100  mt-4 all-end">
                    <button type="button" onClick={() => closeModal()} className="btn btn-danger me-4">Cancelar</button>
                    <button type="submit" className="btn btn-success">Salvar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
