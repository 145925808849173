import {Text, View} from '@react-pdf/renderer'
import {styles} from '../style'

export const RelatedPeople = ({data, estrutura}) => {
  let dateReturn = null

  if (typeof data['Result'] !== 'undefined') {
    dateReturn = data['Result'][0]['RelatedPeople']['PersonalRelationships']
  } else {
    dateReturn = data[0]['RelatedPeople']['PersonalRelationships']
  }

  return dateReturn ? (
    <>
      <View style={[styles.table, {paddingTop: 15}]}>
        <View style={[styles.row, {paddingTop: 15, paddingBottom: 10}]}>
          <Text style={[styles.subtitulo]}>Pessoas Relacionados</Text>
        </View>
        <View style={[styles.row, styles.bold, styles.header]}>
          <Text style={styles.rowDefault}>Nome</Text>
          <Text style={styles.rowDefault}>Grau Parentesco</Text>
          <Text style={styles.rowDefault}>Documento</Text>
        </View>
      </View>

      <View>
        {dateReturn?.map((item, index) => {
          return (
            <View key={index} style={styles.row} wrap={false}>
              <Text style={styles.rowDefault}>{item['RelatedEntityName']}</Text>
              <Text style={styles.rowDefault}>{item['RelationshipType']}</Text>
              <Text style={styles.rowDefault}>{item['RelatedEntityTaxIdNumber']}</Text>
            </View>
          )
        })}
      </View>
    </>
  ) : (
    <></>
  )
}
