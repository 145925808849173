import React from 'react'
import Swal from 'sweetalert2'
import {useParams} from 'react-router-dom'
import {MutationDeleteAVM} from '../../../graphql'
import {MutationSetLogAcessoRecurso} from '../../../graphql/services/LogAcesso'
import {KTSVG} from '../../../../_metronic/helpers'
import {IoLayers} from 'react-icons/io5'

type Props = {
  id: number
  clickRow: (id: number) => void // Adicionando a função clickRow ao tipo Props
}

export const Buttons: React.FC<Props> = ({id, clickRow}) => {
  // Recebendo clickRow como propriedade
  const {id: idTerreno} = useParams<{id: string}>()
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const {HandleDeleteAVM, loading} = MutationDeleteAVM()
  if (loading) {
    Swal.fire('Enviando Informações...', '')
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteAVM({variables: {deleteAvmLeadId: id}})
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          FormSetLogAcessoRecurso({
            ferramenta: 'meus-imoveis',
            funcionalidade: 'avaliacoes',
            action: 'delete',
            url: '/meus-imoveis/details/' + idTerreno + '#avaliacoes',
          })
          Swal.fire('Sucesso!', 'Avaliação deletado com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          title='Features'
          onClick={() => {
            clickRow(id) // Chamando a função clickRow com o ID
          }}
          className='btn btn-icon btn-light-primary  btn-sm me-1'
        >
          <IoLayers className='svg-icon-1' />
        </button>
        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          // disabled={loading}
          className='btn btn-icon btn-light-danger  btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
