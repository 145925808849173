import axios from 'axios'

const configValue: string | undefined = process.env.REACT_APP_API_URL_EXTRA_SERVICES

export const api = axios.create({
  baseURL: configValue
})

export default api;

export async function UploadImage(image) {

  const formData = new FormData();
  const fileName = dataURItoBlob(image)

  var file = new File([fileName], 'profile.jpg', { type: 'image/jpeg' });
  console.log(file)
  formData.append("file", file)
  return await fetch( process.env.REACT_APP_API_URL_EXTRA_SERVICES +"image", {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      // 'Content-Type': 'multipart/form-data',
      // 'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  }).then((response) => {
    return response.json();
  })

}

export async function UploadImageCompany(image, id) {

  const formData = new FormData();
  const fileName = dataURItoBlob(image)

  var file = new File([fileName], 'profile.jpg', { type: 'image/jpeg' });
  console.log(file)

  formData.append("file", file)
  return await fetch( process.env.REACT_APP_API_URL_EXTRA_SERVICES +"image-company/" + id, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  }).then((response) => {
    return response.json();
  })

}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export async function UploadImageTerreno(id, image) {

  const formData = new FormData();
  const fileName = dataURItoBlob(image)

  var file = new File([fileName], 'canvasImage.jpg', { type: 'image/jpeg' });



  formData.append("file", file)
  formData.append("id_terreno", id)
  
  return await fetch("http://localhost:4001/terrenoImagem", {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      // 'Content-Type': 'multipart/form-data',
      // 'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  }).then((response) => {
    return response.json();
  })

}

