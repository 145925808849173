import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import { ModalRecoveryPassword } from './RecoveryPassword'
import { MutationDeleteUsuario } from '../../../graphql'
import Swal from 'sweetalert2'

type Props = {
  id: any
}

export const Buttons: React.FC<Props> = ({ id }) => {
  const { HandleDeleteUsuario, loading } = MutationDeleteUsuario()

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteUsuario({ variables: { deleteUsuarioId: id } })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Usuario deletado com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <ModalRecoveryPassword id_user={id} />
        <Link to={`/backoffice/usuarios/edit/${id}`}
          className='btn btn-icon btn-light-success me-2'>
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
        <button
          disabled={loading}
          onClick={(e) => {
            confirmDelete(parseInt(id))
          }}
          className='btn btn-icon btn-light-danger me-1' >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
