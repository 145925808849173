import { DataMarket, Market, Pais, submarkets } from "../data"

export function getEstadoById(estadoId: string): Market | undefined {
  return DataMarket.markets.find(estado => estado.id === estadoId);
}

export function getCidadeById(cidadeId: string, cidades: Market[]): Market | undefined {
  console.log(cidades)
  return cidades.find(cidade => cidade.id === cidadeId);
}
// Função para obter as cidades de um estado pelo ID do estado
export function getCidadesByEstadoId(estadoId: string): Market[] {
  const estado = DataMarket.markets.find(estado => estado.id === estadoId);
  return estado ? estado.submarket || [] : [];
}

// Função para obter os bairros de uma cidade pelo ID do estado e da cidade
export function getBairrosByCidadeId(estadoId: string, cidadeId: string): submarkets[] {
  const estado = DataMarket.markets.find(estado => estado.id === estadoId);
  if (estado) {
    const cidade = estado.submarket?.find(cidade => cidade.id === cidadeId);
    return cidade ? cidade.submarket || [] : [];
  }
  return [];
}


export function getAllCitiesAndNeighborhoods(): (Market | submarkets)[] {
  const allCitiesAndNeighborhoods: (Market | submarkets)[] = [];

  DataMarket.markets.forEach((estado: Market) => {
      if (estado.submarket) {
          estado.submarket.forEach((cidade: Market) => {
              allCitiesAndNeighborhoods.push(cidade);
              if (cidade.submarket) {
                  cidade.submarket.forEach((bairro: submarkets) => {
                      allCitiesAndNeighborhoods.push(bairro);
                  });
              }
          });
      }
  });

  return allCitiesAndNeighborhoods;
}





