import React, {useEffect, useState} from 'react'
import Select, {ActionMeta} from 'react-select'

type Props = {
  itensSelected?: any[] | null
  onChange?: any
}

export interface Options {
  readonly value: string
  readonly label: string
  readonly color?: string
  readonly isFixed?: boolean
  readonly isDisabled?: boolean
}
export const SelectIncorporadoras: React.FC<Props> = ({itensSelected, onChange}) => {
  const options = [
    {label: '3z Realty', value: '3z Realty'},
    {label: '3z Realty SP', value: '3z Realty SP'},
    {label: 'A88 Empreendimentos', value: 'A88 Empreendimentos'},
    {label: 'AAM', value: 'AAM'},
    {label: 'Abiatar Construtora e Incorporadora', value: 'Abiatar Construtora e Incorporadora'},
    {label: 'Abud Wagner', value: 'Abud Wagner'},
    {label: 'ACH Engenharia e Construtora', value: 'ACH Engenharia e Construtora'},
    {label: 'Adore Incorporações', value: 'Adore Incorporações'},
    {label: 'AgapeeUrb', value: 'AgapeeUrb'},
    {label: 'Agaru', value: 'Agaru'},
    {label: 'Ager', value: 'Ager'},
    {label: 'Agnes Construção', value: 'Agnes Construção'},
    {label: 'AK Realty', value: 'AK Realty'},
    {label: 'Alfa Realty', value: 'Alfa Realty'},
    {label: 'Alinc SP', value: 'Alinc SP'},
    {label: 'Amici Incorporadora', value: 'Amici Incorporadora'},
    {label: 'Ampla Incorporadora', value: 'Ampla Incorporadora'},
    {label: 'Antuérpia', value: 'Antuérpia'},
    {label: 'Apê55', value: 'Apê55'},
    {label: 'Aporte Empreendimentos', value: 'Aporte Empreendimentos'},
    {label: 'Arbbo', value: 'Arbbo'},
    {label: 'Archtech', value: 'Archtech'},
    {label: 'Aros Inc', value: 'Aros Inc'},
    {label: 'Arquiplan', value: 'Arquiplan'},
    {label: 'Articon', value: 'Articon'},
    {label: 'ArtLar', value: 'ArtLar'},
    {label: 'ARV Construtora', value: 'ARV Construtora'},
    {label: 'Atlantis Associados', value: 'Atlantis Associados'},
    {label: 'Aurinova', value: 'Aurinova'},
    {label: 'Avanço Realizações', value: 'Avanço Realizações'},
    {label: 'AW Realty', value: 'AW Realty'},
    {label: 'Azure Incorporadora', value: 'Azure Incorporadora'},
    {label: 'Bait Inc', value: 'Bait Inc'},
    {label: 'Balassiano', value: 'Balassiano'},
    {label: 'Barbara', value: 'Barbara'},
    {label: 'Barroco', value: 'Barroco'},
    {label: 'Belletti Engenharia', value: 'Belletti Engenharia'},
    {label: 'Bewiki', value: 'Bewiki'},
    {label: 'Bianchi Realty', value: 'Bianchi Realty'},
    {label: 'Bioma Incorporadora', value: 'Bioma Incorporadora'},
    {label: 'BKO', value: 'BKO'},
    {label: 'Bolsa de Imóveis', value: 'Bolsa de Imóveis'},
    {label: 'BR Corp', value: 'BR Corp'},
    {label: 'Bracco', value: 'Bracco'},
    {label: 'Bracon', value: 'Bracon'},
    {label: 'Brasilincorp', value: 'Brasilincorp'},
    {label: 'Brio Incorporadora', value: 'Brio Incorporadora'},
    {label: 'Brio Investimentos', value: 'Brio Investimentos'},
    {label: 'Brix', value: 'Brix'},
    {label: 'Bueno Netto', value: 'Bueno Netto'},
    {label: 'BUILDER', value: 'BUILDER'},
    {label: 'CAC Engenharia', value: 'CAC Engenharia'},
    {label: 'Calçada', value: 'Calçada'},
    {label: 'Calper', value: 'Calper'},
    {
      label: 'Camargo Corrêa Desenvolvimento Imobiliário',
      value: 'Camargo Corrêa Desenvolvimento Imobiliário',
    },
    {label: 'Cambur Construtora', value: 'Cambur Construtora'},
    {label: 'Candemil', value: 'Candemil'},
    {label: 'Canopus', value: 'Canopus'},
    {label: 'Canopus RJ', value: 'Canopus RJ'},
    {label: 'Capital Concreto', value: 'Capital Concreto'},
    {label: 'Cardim', value: 'Cardim'},
    {label: 'Carvalho Hosken', value: 'Carvalho Hosken'},
    {label: 'Casa Viva', value: 'Casa Viva'},
    {label: 'Casa8', value: 'Casa8'},
    {label: 'Casenge Engenharia', value: 'Casenge Engenharia'},
    {label: 'Caucaso', value: 'Caucaso'},
    {label: 'Celta Engenharia', value: 'Celta Engenharia'},
    {label: 'CFL SC', value: 'CFL SC'},
    {label: 'Charles Bigot', value: 'Charles Bigot'},
    {label: 'CIAPLAN RJ', value: 'CIAPLAN RJ'},
    {label: 'Conartes', value: 'Conartes'},
    {label: 'Concal', value: 'Concal'},
    {label: 'Concord', value: 'Concord'},
    {label: 'Consthruir Engenharia', value: 'Consthruir Engenharia'},
    {label: 'Constrac', value: 'Constrac'},
    {label: 'Construtora Você', value: 'Construtora Você'},
    {label: 'Contracta', value: 'Contracta'},
    {label: 'Conx', value: 'Conx'},
    {label: 'Conx SP', value: 'Conx SP'},
    {label: 'Cope Engenharia', value: 'Cope Engenharia'},
    {label: 'Coplam', value: 'Coplam'},
    {label: 'Coral Construtora', value: 'Coral Construtora'},
    {label: 'Corbetta Construtora', value: 'Corbetta Construtora'},
    {label: 'Cordoba Construtora', value: 'Cordoba Construtora'},
    {label: 'Costa Hirota', value: 'Costa Hirota'},
    {label: 'Cota Empreendimentos', value: 'Cota Empreendimentos'},
    {label: 'CP Construplan', value: 'CP Construplan'},
    {label: 'CPD', value: 'CPD'},
    {label: 'CSA Empreendimentos', value: 'CSA Empreendimentos'},
    {label: 'CTV', value: 'CTV'},
    {label: 'Cube', value: 'Cube'},
    {label: 'Cury', value: 'Cury'},
    {label: 'Cury RJ', value: 'Cury RJ'},
    {label: 'Cury SP', value: 'Cury SP'},
    {label: 'CWHG', value: 'CWHG'},
    {label: 'Cyrela', value: 'Cyrela'},
    {label: 'Cyrela RJZ', value: 'Cyrela RJZ'},
    {label: 'Cyrela SC', value: 'Cyrela SC'},
    {label: 'Cyrela SP', value: 'Cyrela SP'},
    {label: 'D2J', value: 'D2J'},
    {label: 'Dama Engenharia', value: 'Dama Engenharia'},
    {label: 'DC4 Empreendimentos', value: 'DC4 Empreendimentos'},
    {label: 'DDK Engenharia', value: 'DDK Engenharia'},
    {label: 'DEM Incorporadora', value: 'DEM Incorporadora'},
    {label: 'Diálogo', value: 'Diálogo'},
    {label: 'Dimas Construções', value: 'Dimas Construções'},
    {label: 'Direcional', value: 'Direcional'},
    {label: 'Direcional RJ', value: 'Direcional RJ'},
    {label: 'Direcional SP', value: 'Direcional SP'},
    {label: 'Disa Catisa', value: 'Disa Catisa'},
    {label: 'Dissei Engenharia', value: 'Dissei Engenharia'},
    {label: 'Ditolvo', value: 'Ditolvo'},
    {label: 'Dois Continentes', value: 'Dois Continentes'},
    {label: 'Dome', value: 'Dome'},
    {label: 'Domma Incorporações', value: 'Domma Incorporações'},
    {label: 'Domum Construções', value: 'Domum Construções'},
    {label: 'DW Incorp', value: 'DW Incorp'},
    {label: 'EAS Desenvolvimento Imobiliário', value: 'EAS Desenvolvimento Imobiliário'},
    {label: 'EBM SP', value: 'EBM SP'},
    {label: 'Eccoviver', value: 'Eccoviver'},
    {label: 'ECM5', value: 'ECM5'},
    {label: 'Econ Construtora', value: 'Econ Construtora'},
    {label: 'EDGE', value: 'EDGE'},
    {label: 'Eduana', value: 'Eduana'},
    {label: 'Ekko', value: 'Ekko'},
    {label: 'Emccamp', value: 'Emccamp'},
    {label: 'Emccamp RJ', value: 'Emccamp RJ'},
    {label: 'Emccamp SP', value: 'Emccamp SP'},
    {label: 'Engefex', value: 'Engefex'},
    {label: 'Engelux', value: 'Engelux'},
    {label: 'Enseg', value: 'Enseg'},
    {label: 'EPH', value: 'EPH'},
    {label: 'Epson Engenharia', value: 'Epson Engenharia'},
    {label: 'Épura', value: 'Épura'},
    {label: 'Esser', value: 'Esser'},
    {label: 'Etaplan', value: 'Etaplan'},
    {label: 'Even', value: 'Even'},
    {label: 'Even RJ', value: 'Even RJ'},
    {label: 'Exemplar Construções', value: 'Exemplar Construções'},
    {label: 'Exemplar Construções ', value: 'Exemplar Construções '},
    {label: 'Exto', value: 'Exto'},
    {label: 'EZTEC', value: 'EZTEC'},
    {label: 'Fernandes Araujo', value: 'Fernandes Araujo'},
    {label: 'Fibra Experts', value: 'Fibra Experts'},
    {label: 'FMAC', value: 'FMAC'},
    {label: 'Focal Inc', value: 'Focal Inc'},
    {label: 'Fontana', value: 'Fontana'},
    {label: 'ForCasa', value: 'ForCasa'},
    {label: 'Fraiha', value: 'Fraiha'},
    {label: 'Fratta Construtora e Incorporadora', value: 'Fratta Construtora e Incorporadora'},
    {label: 'Gabbai', value: 'Gabbai'},
    {label: 'Gafisa', value: 'Gafisa'},
    {label: 'Gafisa RJ', value: 'Gafisa RJ'},
    {label: 'Gafisa SP', value: 'Gafisa SP'},
    {label: 'Gamapro', value: 'Gamapro'},
    {label: 'Gamaro', value: 'Gamaro'},
    {label: 'Gattaz Engenharia', value: 'Gattaz Engenharia'},
    {label: 'GIP Invest', value: 'GIP Invest'},
    {label: 'Global Realty Brasil', value: 'Global Realty Brasil'},
    {label: 'Globo Construtora', value: 'Globo Construtora'},
    {label: 'GN Empreendimentos', value: 'GN Empreendimentos'},
    {label: 'GND SC', value: 'GND SC'},
    {label: 'GPinheiro SC', value: 'GPinheiro SC'},
    {label: 'GR Properties SP', value: 'GR Properties SP'},
    {label: 'Graal', value: 'Graal'},
    {label: 'Grupo Lar', value: 'Grupo Lar'},
    {label: 'Grupo Lar SP', value: 'Grupo Lar SP'},
    {label: 'Grupo OAD', value: 'Grupo OAD'},
    {label: 'Grupo RG', value: 'Grupo RG'},
    {label: 'GTB Empreendimentos', value: 'GTB Empreendimentos'},
    {label: 'Habitat Arquitetura', value: 'Habitat Arquitetura'},
    {label: 'Habitram', value: 'Habitram'},
    {label: 'Hadid Incorporações', value: 'Hadid Incorporações'},
    {label: 'Hantei Engenharia', value: 'Hantei Engenharia'},
    {label: 'Hausbau', value: 'Hausbau'},
    {label: 'Hausbau SP', value: 'Hausbau SP'},
    {label: 'Helbor RJ', value: 'Helbor RJ'},
    {label: 'Helbor SP', value: 'Helbor SP'},
    {label: 'Herc Construtora', value: 'Herc Construtora'},
    {label: 'HM Engenharia', value: 'HM Engenharia'},
    {label: 'HM Engenharia SP', value: 'HM Engenharia SP'},
    {label: 'HM Engenharia SP Interior', value: 'HM Engenharia SP Interior'},
    {label: 'Hoepcke', value: 'Hoepcke'},
    {label: 'Hogar', value: 'Hogar'},
    {label: 'Holos Incorporadora', value: 'Holos Incorporadora'},
    {label: 'Horto Empreendimentos', value: 'Horto Empreendimentos'},
    {label: 'Huma', value: 'Huma'},
    {label: 'i9 Empreendimentos', value: 'i9 Empreendimentos'},
    {label: 'i9 Realty', value: 'i9 Realty'},
    {label: 'Idea Zarvos', value: 'Idea Zarvos'},
    {label: 'Idea! Zarvos', value: 'Idea! Zarvos'},
    {label: 'Ideale', value: 'Ideale'},
    {label: 'ihouseDi', value: 'ihouseDi'},
    {label: 'Imangai', value: 'Imangai'},
    {label: 'Imobel', value: 'Imobel'},
    {label: 'Incorpotec', value: 'Incorpotec'},
    {label: 'Inkoa', value: 'Inkoa'},
    {label: 'Inner Investimentos', value: 'Inner Investimentos'},
    {label: 'Inovcorp', value: 'Inovcorp'},
    {label: 'INTI', value: 'INTI'},
    {label: 'Ipap', value: 'Ipap'},
    {label: 'Itten', value: 'Itten'},
    {label: 'ix Incorporadora', value: 'ix Incorporadora'},
    {label: 'J. Gaidzinski', value: 'J. Gaidzinski'},
    {label: 'J4S Empreendimentos', value: 'J4S Empreendimentos'},
    {label: 'JÁ Império Empreendimentos', value: 'JÁ Império Empreendimentos'},
    {label: 'Jacutinga', value: 'Jacutinga'},
    {label: 'Jafet Inc.', value: 'Jafet Inc.'},
    {label: 'Jal', value: 'Jal'},
    {label: 'Jalico', value: 'Jalico'},
    {label: 'JDG Construtora', value: 'JDG Construtora'},
    {label: 'JF Construtora e Incorporadora', value: 'JF Construtora e Incorporadora'},
    {label: 'Jive', value: 'Jive'},
    {label: 'JLJ', value: 'JLJ'},
    {label: 'João Fortes', value: 'João Fortes'},
    {label: 'JRG Incorporadora', value: 'JRG Incorporadora'},
    {label: 'JSG Construtora', value: 'JSG Construtora'},
    {label: 'Júlio De David', value: 'Júlio De David'},
    {label: 'Kallas', value: 'Kallas'},
    {label: 'Kaslik', value: 'Kaslik'},
    {label: 'Kauffmann', value: 'Kauffmann'},
    {label: 'KDS Engenharia', value: 'KDS Engenharia'},
    {label: 'Kopstein e Liv', value: 'Kopstein e Liv'},
    {label: 'Lavvi', value: 'Lavvi'},
    {label: 'LCM Participações', value: 'LCM Participações'},
    {label: 'Legítima Incorporadora', value: 'Legítima Incorporadora'},
    {label: 'Libcorp', value: 'Libcorp'},
    {label: 'Liga Empreendimentos', value: 'Liga Empreendimentos'},
    {label: 'Lindenberg', value: 'Lindenberg'},
    {label: 'Lindenberg SP', value: 'Lindenberg SP'},
    {label: 'Living', value: 'Living'},
    {label: 'Living RJ', value: 'Living RJ'},
    {label: 'Living SP', value: 'Living SP'},
    {label: 'Livus Inc SP', value: 'Livus Inc SP'},
    {label: 'Locus Inc', value: 'Locus Inc'},
    {label: 'Lógica Engenharia', value: 'Lógica Engenharia'},
    {label: 'Lopez Marinho', value: 'Lopez Marinho'},
    {label: 'Lorenzini', value: 'Lorenzini'},
    {label: 'Lote 5 SP', value: 'Lote 5 SP'},
    {label: 'Luciano Wertheim', value: 'Luciano Wertheim'},
    {label: 'Lucio Engenharia', value: 'Lucio Engenharia'},
    {label: 'Lumis Construtora', value: 'Lumis Construtora'},
    {label: 'Lumma Construtora', value: 'Lumma Construtora'},
    {label: 'Lumy', value: 'Lumy'},
    {label: 'M2 Empreendimentos', value: 'M2 Empreendimentos'},
    {label: 'MAC', value: 'MAC'},
    {label: 'Maestra', value: 'Maestra'},
    {label: 'Mágica Construtora', value: 'Mágica Construtora'},
    {label: 'Magik JC', value: 'Magik JC'},
    {label: 'Maker Incorporadora', value: 'Maker Incorporadora'},
    {label: 'Making Engenharia', value: 'Making Engenharia'},
    {label: 'Mamute Incorporadora', value: 'Mamute Incorporadora'},
    {label: 'Marka Prime', value: 'Marka Prime'},
    {label: 'Marques', value: 'Marques'},
    {label: 'Marquise Incorporações', value: 'Marquise Incorporações'},
    {label: 'Martinelli Construtora', value: 'Martinelli Construtora'},
    {label: 'Maskan', value: 'Maskan'},
    {label: 'Matos Guimarães', value: 'Matos Guimarães'},
    {label: 'Matushita Engenharia', value: 'Matushita Engenharia'},
    {label: 'Max Incorporadora', value: 'Max Incorporadora'},
    {label: 'MB Construtora', value: 'MB Construtora'},
    {label: 'Mbigucci', value: 'Mbigucci'},
    {label: 'MC3 Engenharia SP', value: 'MC3 Engenharia SP'},
    {label: 'MCP Realty', value: 'MCP Realty'},
    {label: 'MDL', value: 'MDL'},
    {label: 'MDL RJ', value: 'MDL RJ'},
    {label: 'Meta', value: 'Meta'},
    {label: 'Metaforma Inc', value: 'Metaforma Inc'},
    {label: 'Metrocasa', value: 'Metrocasa'},
    {label: 'MF7', value: 'MF7'},
    {label: 'MGTEC Construtora', value: 'MGTEC Construtora'},
    {label: 'Migras', value: 'Migras'},
    {label: 'Migras ', value: 'Migras '},
    {label: 'Milan Reis Realty', value: 'Milan Reis Realty'},
    {label: 'Minerva', value: 'Minerva'},
    {label: 'Mitre Realty', value: 'Mitre Realty'},
    {label: 'Moby', value: 'Moby'},
    {label: 'Modulare Construtora', value: 'Modulare Construtora'},
    {label: 'Montrecon', value: 'Montrecon'},
    {label: 'MOS', value: 'MOS'},
    {label: 'Mozak', value: 'Mozak'},
    {label: 'MPD', value: 'MPD'},
    {label: 'MPD SP', value: 'MPD SP'},
    {label: 'MR2 Construtora', value: 'MR2 Construtora'},
    {label: 'MRV RJ', value: 'MRV RJ'},
    {label: 'MRV SP', value: 'MRV SP'},
    {label: 'MS Arandu', value: 'MS Arandu'},
    {label: 'MSB Sanchez', value: 'MSB Sanchez'},
    {label: 'Mundo Apto', value: 'Mundo Apto'},
    {label: 'Munir Abbud SP', value: 'Munir Abbud SP'},
    {label: 'Munte', value: 'Munte'},
    {label: 'My Inc', value: 'My Inc'},
    {label: 'MZM', value: 'MZM'},
    {label: 'Nacional Global', value: 'Nacional Global'},
    {label: 'Netcorp Realize', value: 'Netcorp Realize'},
    {label: 'New Properties', value: 'New Properties'},
    {label: 'Next Realty', value: 'Next Realty'},
    {label: 'Niss', value: 'Niss'},
    {label: 'Norbex', value: 'Norbex'},
    {label: 'Nortis', value: 'Nortis'},
    {label: 'Novação', value: 'Novação'},
    {label: 'Novação Engenharia', value: 'Novação Engenharia'},
    {label: 'Novolar', value: 'Novolar'},
    {label: 'One', value: 'One'},
    {label: 'Opportunity', value: 'Opportunity'},
    {label: 'OR', value: 'OR'},
    {label: 'OR RJ', value: 'OR RJ'},
    {label: 'OUZ Group', value: 'OUZ Group'},
    {label: 'Paes & Gregori', value: 'Paes & Gregori'},
    {label: 'Paes & Gregori SP', value: 'Paes & Gregori SP'},
    {label: 'Patriani', value: 'Patriani'},
    {label: 'Patriani SP', value: 'Patriani SP'},
    {label: 'Patrimar', value: 'Patrimar'},
    {label: 'Patrimônio Incorporadora', value: 'Patrimônio Incorporadora'},
    {label: 'Paulo Mauro', value: 'Paulo Mauro'},
    {label: 'PDG RJ', value: 'PDG RJ'},
    {label: 'PDG SP', value: 'PDG SP'},
    {label: 'Pereda Incorp', value: 'Pereda Incorp'},
    {label: 'Performance', value: 'Performance'},
    {label: 'Piemonte SC', value: 'Piemonte SC'},
    {label: 'Piimo', value: 'Piimo'},
    {label: 'Pilastter', value: 'Pilastter'},
    {
      label: 'Pires e Silva Construtora e Incorporadora',
      value: 'Pires e Silva Construtora e Incorporadora',
    },
    {label: 'Pivô', value: 'Pivô'},
    {label: 'Planik', value: 'Planik'},
    {label: 'Plano W Empreendimentos', value: 'Plano W Empreendimentos'},
    {label: 'Plano&Plano', value: 'Plano&Plano'},
    {label: 'Platô', value: 'Platô'},
    {label: 'PlazzaCorp', value: 'PlazzaCorp'},
    {label: 'Pluma Construtora', value: 'Pluma Construtora'},
    {label: 'Porte', value: 'Porte'},
    {label: 'Portus', value: 'Portus'},
    {label: 'Pratica Construtora', value: 'Pratica Construtora'},
    {label: 'Prima', value: 'Prima'},
    {label: 'Priori', value: 'Priori'},
    {label: 'Pró Lotes', value: 'Pró Lotes'},
    {label: 'Prodomo', value: 'Prodomo'},
    {label: 'Projeto9', value: 'Projeto9'},
    {label: 'Queiroz Galvão', value: 'Queiroz Galvão'},
    {label: 'Queiroz Galvão RJ', value: 'Queiroz Galvão RJ'},
    {label: 'Quéops Construtora', value: 'Quéops Construtora'},
    {label: 'R. Yazbek', value: 'R. Yazbek'},
    {label: 'R.A.S', value: 'R.A.S'},
    {label: 'R.A.S.', value: 'R.A.S.'},
    {label: 'R.Lico', value: 'R.Lico'},
    {label: 'RDO Empreendimentos', value: 'RDO Empreendimentos'},
    {label: 'RDR Engenharia', value: 'RDR Engenharia'},
    {label: 'Reacty Incorporadora', value: 'Reacty Incorporadora'},
    {label: 'Reitzfeld', value: 'Reitzfeld'},
    {label: 'Reitzfeld SP', value: 'Reitzfeld SP'},
    {label: 'REM', value: 'REM'},
    {label: 'Result', value: 'Result'},
    {label: 'REV3 Incorporadora', value: 'REV3 Incorporadora'},
    {label: 'Rezende', value: 'Rezende'},
    {label: 'RFM Incorporadora', value: 'RFM Incorporadora'},
    {label: 'Riformato', value: 'Riformato'},
    {label: 'Riva', value: 'Riva'},
    {label: 'Riva Incorporadora RJ', value: 'Riva Incorporadora RJ'},
    {label: 'Riva RJ', value: 'Riva RJ'},
    {label: 'Riva SP', value: 'Riva SP'},
    {label: 'Rodrik Construtora e Administradora', value: 'Rodrik Construtora e Administradora'},
    {label: 'Rossi RJ', value: 'Rossi RJ'},
    {label: 'Rovic', value: 'Rovic'},
    {label: 'RRG', value: 'RRG'},
    {label: 'S.Quatro', value: 'S.Quatro'},
    {label: 'SÁ Cavalcante', value: 'SÁ Cavalcante'},
    {label: 'Sacisa', value: 'Sacisa'},
    {label: 'Santa Isabel', value: 'Santa Isabel'},
    {label: 'São José', value: 'São José'},
    {label: 'SDAL', value: 'SDAL'},
    {label: 'Seed', value: 'Seed'},
    {
      label: 'Seisa Mester Empreendimentos Imobiliários',
      value: 'Seisa Mester Empreendimentos Imobiliários',
    },
    {label: 'Senpro', value: 'Senpro'},
    {label: 'Sensia Incorporadora RJ', value: 'Sensia Incorporadora RJ'},
    {label: 'Senziani Incorporadora', value: 'Senziani Incorporadora'},
    {label: 'Sepag Construtora', value: 'Sepag Construtora'},
    {label: 'Sequóia', value: 'Sequóia'},
    {label: 'Setin', value: 'Setin'},
    {label: 'Setin SP', value: 'Setin SP'},
    {label: 'Shpaisman', value: 'Shpaisman'},
    {label: 'SIG', value: 'SIG'},
    {label: 'Sinco RJ', value: 'Sinco RJ'},
    {label: 'Sinco SP', value: 'Sinco SP'},
    {label: 'Sindona', value: 'Sindona'},
    {label: 'SKR', value: 'SKR'},
    {label: 'Solidi', value: 'Solidi'},
    {label: 'Solv', value: 'Solv'},
    {label: 'Solv SP', value: 'Solv SP'},
    {label: 'Somattos Engenharia', value: 'Somattos Engenharia'},
    {label: 'Spin', value: 'Spin'},
    {label: 'Stan', value: 'Stan'},
    {label: 'Start Investimentos', value: 'Start Investimentos'},
    {label: 'Steffens Construtora', value: 'Steffens Construtora'},
    {label: 'Stiefelmann', value: 'Stiefelmann'},
    {label: 'Stuhlberger', value: 'Stuhlberger'},
    {label: 'STV Construções', value: 'STV Construções'},
    {label: 'STX', value: 'STX'},
    {label: 'Sugoi', value: 'Sugoi'},
    {label: 'Sugoi SP', value: 'Sugoi SP'},
    {label: 'Sulplan', value: 'Sulplan'},
    {label: 'Supervisa Construtora', value: 'Supervisa Construtora'},
    {label: 'SWA Realty', value: 'SWA Realty'},
    {label: 'TAO', value: 'TAO'},
    {label: 'Tarjab', value: 'Tarjab'},
    {label: 'Tavitec', value: 'Tavitec'},
    {label: 'TBO Construção e Incorporação', value: 'TBO Construção e Incorporação'},
    {label: 'Tech ZN', value: 'Tech ZN'},
    {label: 'Tecnisa', value: 'Tecnisa'},
    {label: 'Tecnisa SP', value: 'Tecnisa SP'},
    {label: 'Tecno Apê', value: 'Tecno Apê'},
    {label: 'Tegra', value: 'Tegra'},
    {label: 'Tegra RJ', value: 'Tegra RJ'},
    {label: 'Tegra SP', value: 'Tegra SP'},
    {label: 'Teixeira Duarte', value: 'Teixeira Duarte'},
    {label: 'Telar Desenvolvimento', value: 'Telar Desenvolvimento'},
    {label: 'Tenda RJ', value: 'Tenda RJ'},
    {label: 'Tenda SP', value: 'Tenda SP'},
    {
      label: 'Tenerife Empreendimentos Imobiliários',
      value: 'Tenerife Empreendimentos Imobiliários',
    },
    {label: 'Terras e Terras Incorporações', value: 'Terras e Terras Incorporações'},
    {label: 'TGB', value: 'TGB'},
    {label: 'Thema', value: 'Thema'},
    {label: 'Thera Empreendimentos', value: 'Thera Empreendimentos'},
    {label: 'Think', value: 'Think'},
    {label: 'THREE', value: 'THREE'},
    {label: 'Tibério', value: 'Tibério'},
    {label: 'TiCO', value: 'TiCO'},
    {label: 'Tishman Speyer', value: 'Tishman Speyer'},
    {label: 'Toca 55', value: 'Toca 55'},
    {label: 'Toledo Ferrari', value: 'Toledo Ferrari'},
    {label: 'Torres Barboza', value: 'Torres Barboza'},
    {label: 'TPA', value: 'TPA'},
    {label: 'Tríplice Engenharia', value: 'Tríplice Engenharia'},
    {label: 'Trisul', value: 'Trisul'},
    {label: 'Tropical', value: 'Tropical'},
    {label: 'TS Incorporação e Construção', value: 'TS Incorporação e Construção'},
    {label: 'UMÃ Inc', value: 'UMÃ Inc'},
    {label: 'Unique MCA', value: 'Unique MCA'},
    {label: 'Unity Engenharia', value: 'Unity Engenharia'},
    {label: 'Upcon', value: 'Upcon'},
    {label: 'Urbic', value: 'Urbic'},
    {label: 'V&P', value: 'V&P'},
    {label: 'V1 Empreendimentos', value: 'V1 Empreendimentos'},
    {label: 'V2 Empreendimentos', value: 'V2 Empreendimentos'},
    {label: 'Valente', value: 'Valente'},
    {label: 'Veiga Junior', value: 'Veiga Junior'},
    {label: 'Vibe', value: 'Vibe'},
    {label: 'Vibra', value: 'Vibra'},
    {label: 'Vic Engenharia', value: 'Vic Engenharia'},
    {label: 'Vic Engenharia RJ', value: 'Vic Engenharia RJ'},
    {label: 'Vic Engenharia SP', value: 'Vic Engenharia SP'},
    {label: 'VIEWCO', value: 'VIEWCO'},
    {label: 'Vila Brasil Engenharia RJ', value: 'Vila Brasil Engenharia RJ'},
    {label: 'Vinx', value: 'Vinx'},
    {label: 'Virtus', value: 'Virtus'},
    {label: 'Vitacon', value: 'Vitacon'},
    {label: 'Vitale', value: 'Vitale'},
    {label: 'Vitaurbana', value: 'Vitaurbana'},
    {label: 'Vitoria Invest', value: 'Vitoria Invest'},
    {label: 'Vitta Residencial SP', value: 'Vitta Residencial SP'},
    {label: 'Viva 4 Empreendimentos', value: 'Viva 4 Empreendimentos'},
    {label: 'Vivaz RJ', value: 'Vivaz RJ'},
    {label: 'Vivaz SP', value: 'Vivaz SP'},
    {label: 'Vivenda Nobre', value: 'Vivenda Nobre'},
    {label: 'V-LOFT', value: 'V-LOFT'},
    {label: 'VNW', value: 'VNW'},
    {label: 'Você Construtora', value: 'Você Construtora'},
    {label: 'W3 Engenharia', value: 'W3 Engenharia'},
    {label: 'WDS', value: 'WDS'},
    {label: 'WJB Empreendimentos', value: 'WJB Empreendimentos'},
    {label: 'WTorre', value: 'WTorre'},
    {label: 'WZarzur', value: 'WZarzur'},
    {label: 'Xavier Engenharia', value: 'Xavier Engenharia'},
    {label: 'Yes Empreendimentos', value: 'Yes Empreendimentos'},
    {label: 'You Inc', value: 'You Inc'},
    {label: 'You Inc.', value: 'You Inc.'},
    {label: 'Yuny', value: 'Yuny'},
    {label: 'Zabo', value: 'Zabo'},
    {label: 'ZKF', value: 'ZKF'},
    {label: 'Zogbi', value: 'Zogbi'},
  ]

  const [selected, setSelected] = useState([])

  useEffect(() => {
    if (selected) {
      let itens = selected.map((item) => {
        return item.value
      })
      onChange(itens)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  useEffect(() => {
    if (itensSelected) {
      const residencialSelecionados = options.filter((itens) => {
        return itensSelected.indexOf(itens.value) !== -1 ? true : false
      })
      setSelected([...residencialSelecionados])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itensSelected])

  return (
    <>
      <Select
        options={options}
        isMulti
        onChange={(option: Options[], actionMeta: ActionMeta<Options>) => {
          setSelected(option)
        }}
        value={selected}
      />
    </>
  )
}
