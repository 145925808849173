import {useForm} from 'react-hook-form'
import {FormSituacaoClienteFormData, FormSituacaoImovelFormSchema} from '../formValidations'
import {zodResolver} from '@hookform/resolvers/zod'
import {useMutation, useQuery} from '@apollo/client'
import {
  TypesDeleteImovelSituacaoClienteResponse,
  TypesSetImovelSituacaoResponse,
  TypesGetImovelSituacaoClienteResponse,
  TypesGetImovelSituacaoClienteByIDResponse,
  TypesPutImovelSituacaoResponse,
} from '../type'
import {CREATE_IMOVEL_CLIENTE, DELETE_IMOVEL_BY_ID, PUT_IMOVEL_SITUACAO_TERRENO} from '../mutations'
import {
  GET_IMOVEL_CONDICAO_COMERCIAL_BY_ID,
  GET_IMOVEL_SITUACAO_CLIENTE,
  GET_ONE_IMOVEL_SITUACAO_CLIENTE,
} from '../query'

interface QueryImovelSituacaoCliente {
  variables: any
}
//GET ALL
export function QueryGetImovelSituacaoCliente({variables}: QueryImovelSituacaoCliente) {
  const {data, loading, error} = useQuery<TypesGetImovelSituacaoClienteResponse>(GET_IMOVEL_SITUACAO_CLIENTE, {
    variables: {...variables},
  })

  return {data, loading, error}
}
//GET One
export function QueryGetImovelSituacaoClienteByID({variables}: QueryImovelSituacaoCliente) {
  const {data, loading} = useQuery<TypesGetImovelSituacaoClienteByIDResponse>(
    GET_ONE_IMOVEL_SITUACAO_CLIENTE,
    {
      variables: {...variables},
    }
  )
  return {data, loading}
}
//CREATE
export function MutationCreateImovelSituacaoCliente() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormSituacaoClienteFormData>({
    resolver: zodResolver(FormSituacaoImovelFormSchema),
  })

  const [setSituacaoImovelBody, {error, loading, data: DataSetSituacaoImovel}] =
    useMutation<TypesSetImovelSituacaoResponse>(CREATE_IMOVEL_CLIENTE, {
      refetchQueries: [GET_IMOVEL_SITUACAO_CLIENTE],
    })

  async function FormImovelSituacaoCliente(data: FormSituacaoClienteFormData) {
    delete data.id
    await setSituacaoImovelBody({
      variables: {
        status: data.status ? 1 : 0,
        descricao: data.descricao,
        nome: data.nome,
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormImovelSituacaoCliente,
    loading,
    errors,
    error,
    DataSetSituacaoImovel,
  }
}

//UPDATE
export function MutationPutImovelSituacaoCliente() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormSituacaoClienteFormData>({
    resolver: zodResolver(FormSituacaoImovelFormSchema),
  })

  const [setCorretorBody, {error, loading, data: DataSetSituacaoImovel}] =
    useMutation<TypesPutImovelSituacaoResponse>(PUT_IMOVEL_SITUACAO_TERRENO, {
      refetchQueries: [GET_IMOVEL_SITUACAO_CLIENTE, GET_IMOVEL_CONDICAO_COMERCIAL_BY_ID],
    })

  async function FormImovelSituacaoCliente(data: FormSituacaoClienteFormData) {
    await setCorretorBody({
      variables: {
        putSituacaoImovelClienteId: parseInt(data.id),
        status: data.status ? 1 : 0,
        description: data.descricao,
        nome: data.nome,
      },
    })
  }
  return {
    register,
    handleSubmit,
    FormImovelSituacaoCliente,
    loading,
    errors,
    error,
    DataSetSituacaoImovel,
  }
}
//DELETE
export function MutationDeleteImovelSituacaoCliente() {
  const [deleteImovelSituacaoCliente, {loading, data, error}] =
    useMutation<TypesDeleteImovelSituacaoClienteResponse>(DELETE_IMOVEL_BY_ID)
  async function HandleDeleteImovelSituacaoCliente({variables}: QueryImovelSituacaoCliente) {
    try {
      const result = await deleteImovelSituacaoCliente({
        variables: {...variables},
        refetchQueries: [GET_IMOVEL_SITUACAO_CLIENTE],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteImovelSituacaoCliente, loading, error, data}
}
