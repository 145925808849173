import { gql } from "@apollo/client";

export const SET_NEWS_FEED = gql`
mutation SetNewsFeed($data: newsFeed) {
  SetNewsFeed(data: $data) {
    id
  }
}
`

export const PUT_NEWS_FEED = gql`
mutation PutNewsFeed($data: newsFeed) {
  PutNewsFeed(data: $data) {
    id
  }
}
`

export const DELETE_NEWS_FEED = gql`
mutation DeleteNewsFeed($deleteNewsFeedId: Float) {
  DeleteNewsFeed(id: $deleteNewsFeedId) {
    id
    title
  }
}
`
