import {FaCaretRight} from 'react-icons/fa'
import {ServicoCard} from './ServicoCard'
import {useEffect, useState} from 'react'
import {APiUrbit} from '../../../../services/api/apiUrbit'

type Props1Item = {
  servicos: any
  idCategoria: number
  titulo: string
  dadosImovel: any
}

export const ServicoResult: React.FC<Props1Item> = ({
  servicos,
  idCategoria,
  titulo,
  dadosImovel,
}) => {
  const [serivcoResult, setServicoResult] = useState([])
  const lat = dadosImovel.GetImovel.latitude
  const lng = dadosImovel.GetImovel.longitude
  const [municipio, setMunicipio] = useState(null)
  
  useEffect(() => {
    async function getMunicipio(lat, lng) {
      const api = new APiUrbit()
      const municipio = await api.get(`municipios/${lng}/${lat}`)
      setMunicipio(municipio)
    }
    if (lat) {
      getMunicipio(lat, lng)
    }
  }, [lat, lng])

  if (!municipio) {
    return <></>
  }
  function loadService(servicos) {
    setServicoResult(servicos)
  }

  return (<>
      <h4 className='accordion-header ' id={'kt_accordion_1_header_1' + idCategoria}>
        <button
          className='accordion-button px-2 collapsed ficha-header'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target={'#kt_accordion_1_body_1' + idCategoria}
          aria-expanded='false'
          aria-controls={'#kt_accordion_1_body_1' + idCategoria}
          id={'kt_accordion_1_btn_' + idCategoria}
          onClick={(e) => {
            if (!e.target['classList'].contains('collapsed')) {
              console.log(servicos)
              loadService(servicos)
            }
          }}
        >
          <FaCaretRight color='#d0d0d0' />
          {titulo}
        </button>
      </h4>
      <div
        id={'kt_accordion_1_body_1' + idCategoria}
        className='accordion-collapse collapse '
        aria-labelledby={'kt_accordion_1_header_1' + idCategoria}
        data-bs-parent={'#kt_accordion_1' + idCategoria}
      >
        <div className='accordion-body p-1 pt-2'>
          {serivcoResult
            ? serivcoResult.map(function (result, item) {
                return (
                  <ServicoCard
                    key={item}
                    url={result.session_name}
                    lat={lat}
                    lng={lng}
                    municipio={municipio.municipio_url}
                    servico={result}
                    distancia={result.distancia ? result.distancia : 500}
                  ></ServicoCard>
                )
              })
            : 'Carregando...'}
        </div>
      </div>
    </>
  )
}
