import {RangeSlider} from '../../../../../../components/UI'
import Select from 'react-select'
import {Dicionario} from '../../../../../../utils/Dicionario'

export function Filter({id, fields, onChange, defaultValue}) {
  return (
    <>
      <div className='item-config'>
        <div className='item-config-align'>
          <div
            data-bs-toggle='collapse'
            data-bs-target={`#collapseFilter_${id}`}
            aria-expanded='false'
            aria-controls={`collapseFilter_${id}`}
          >
            <div className='item-config-align-title'>Filtros</div>
          </div>
          <div className='border-left-layers w-100 collapse' id={`collapseFilter_${id}`}>
            {fields.map((field, x) => {
              // console.log(field)
              // console.log(field.type_data)

              if (!field.type_data) {
                return ''
              }
              switch (field.type_data) {
                case 'select': {
                  let options = JSON.parse(field.data_return)
                  
                  let opt = options.map((opt, x) => {
                    return {value: opt, label: opt}
                  })

                  let defaultvalue = []

                  if (typeof defaultValue !== 'undefined') {
                    if (typeof defaultValue.select !== 'undefined') {
                      if (Object.keys(defaultValue.select).length > 0) {
                        // eslint-disable-next-line array-callback-return
                        defaultvalue = opt.filter((item) => {
                          if (typeof defaultValue.select[field.select_columns] !== 'undefined') {
                            if (
                              defaultValue.select[field.select_columns].indexOf(item.value) !== -1
                            ) {
                              return true
                            }
                          }
                        })
                      }
                    }
                  }
                  return (
                    <div className=' pb-5' key={'filter_' + x}>
                      <label> {Dicionario.translate(field.description)}</label>
                      <Select
                        options={opt}
                        isMulti
                        value={defaultvalue}
                        onChange={(event) => {
                          let itens = event.map((i) => {
                            return i['value']
                          })
                          onChange(field.table_column, itens, field.type_data)
                        }}
                      ></Select>
                    </div>
                  )
                }
                case 'text':
                  return (
                    <div className=' pb-5' key={'filter_' + x}>
                      <label>{Dicionario.translate(field.description)}</label>
                      <input
                        className='form-control'
                        type='text'
                        name={field.select_columns}
                        onChange={(changeEvent) => {
                          onChange(field.table_column, changeEvent.target.value, field.type_data)
                        }}
                      />
                    </div>
                  )
                case 'range': {
                  // console.log(field.data_return)
                  let options = JSON.parse(field.data_return)[0]
                  let default2 = [parseInt(options.minimo), parseInt(options.maximo)]
                  if (defaultValue) {
                    if (typeof defaultValue.range !== 'undefined') {
                      if (typeof defaultValue.range[field.table_column] !== 'undefined') {
                        default2 = defaultValue.range[field.table_column]
                      }
                    }
                  }
                  return (
                    <div className=' pb-5' key={'filter_' + x}>
                      <label> {Dicionario.translate(field.description)}</label>
                      <RangeSlider
                        defaultValue={default2}
                        step={parseInt(field.step)}
                        formatTip={(value) => `${value}`}
                        onChange={(changeEvent) => {
                          onChange(field.table_column, changeEvent, field.type_data)
                        }}
                        min={parseInt(options.minimo)}
                        max={parseInt(options.maximo)}
                      />
                      {/* <Slider 
                      //   defaultMinBudget={options.minimo}
                      //   defaultMaxBudget={options.maximo}
                      //   onChange={(changeEvent) =>{
                      //     setValue(changeEvent.target.value)
                      //     onChange(field.table_column, changeEvent.target.value)
                      //   } 
                      // }
                      //   min={parseInt(options.minimo) }
                      //   max={parseInt(options.maximo)}
                      //   step={parseInt(options.step)}
                      //   setSliderMin={setSliderMin}
                      //   setSliderMax={setSliderMax}
                      // />
                      // <input
                      //   className='form-control'
                      //   type='text'
                      //   value={String(value) }
                      //   onChange={(changeEvent) => {
                      //     setValue(changeEvent.target.value)
                      //     onChange(field.table_column,changeEvent.target.value)
                      //   }}
                      // />*/}
                    </div>
                  )
                }
                default:
                  return ''
              }
            })}
          </div>
        </div>
      </div>
    </>
  )
}
