import {HeaderPerformance} from '../Common/HeaderPerformance'
import VSOPrograma from './PerformanceResumoMercado/VSOPrograma'
import FaixaMetragem from './PerformanceResumoMercado/FaixaMetragem'
import PrecoMedioEstoque from './PerformanceResumoMercado/PrecoMedioEstoque'
import FaixaTicket from './PerformanceResumoMercado/FaixaTicket'
import PrecoMedioVendas from './PerformanceResumoMercado/PrecoMedioVendas'
import PrecoMedioUnidades from './PerformanceResumoMercado/PrecoMedioUnidades'

export function PerformanceResumoMercado() {
  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10'>
        <HeaderPerformance titulo={'Resumo de Mercado'}></HeaderPerformance>
        <div className='content pt-10'>
          <div className='row g-5 g-xl-8'>
            {/* <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <PrecoMedioUnidades></PrecoMedioUnidades>
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <PrecoMedioVendas></PrecoMedioVendas>
              </div>
            </div> */}
            <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <PrecoMedioEstoque></PrecoMedioEstoque>
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <VSOPrograma></VSOPrograma>
              </div>
            </div>
          </div>

          <div className='row g-5 g-xl-8'>
            <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <FaixaMetragem></FaixaMetragem>
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <FaixaTicket></FaixaTicket>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
