import { useEffect, useState } from 'react';
import { RealEstateLaunch, RealEstateLaunchPartners } from "../../../../../services/http/urbit-gis/routes/types";
import { FormInputAndLabel } from "../../../../components/UI/Inputs/FormInputAndLabel";
import { useFormContext } from 'react-hook-form';
import { RealEstateLaunchFormData } from './typologies/formValidations';
import { getLaunchesPartners } from '../../../../../services/http/urbit-gis/routes';
import { PageInfo } from '../../../../components/UI/PaginationComponents';
import { ErrorMessageComponent } from '../../../components/UI/ErrorMessage';
import { AreaInput } from '../../../../components/UI/Inputs/AreaInput';
import { useEmpreendimentos } from '../context/useEmpreendimentos';

interface Props {
  data?: RealEstateLaunch;
}

export function CardHeaderNavigation({ data }: Props) {
  const { partnersLaunch, fetchPartnersLaunch } = useEmpreendimentos()
  const { register, setValue, control, formState: { errors } } = useFormContext<RealEstateLaunchFormData>();
  console.log(errors)
  console.log(partnersLaunch)
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedMCMV, setIsCheckedMCMV] = useState(false);


  // Sync checkbox state with the prop data when data changes
  useEffect(() => {
    setIsChecked(data ? data.future_launch === 1 ? true : false : false);
    console.log(isChecked)
    console.log(isChecked ? 1 : 0)
    setValue('data.future_launch', isChecked ? 1 : 0);
  }, [data]);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    console.log(checked)
    setValue('data.future_launch', checked ? 1 : 0);
  };

  const handleCheckboxMCMVChange = (e) => {
    const checked = e.target.checked;
    setIsCheckedMCMV(checked);
    console.log(checked)
    setValue('data.affordable_program', checked ? 1 : 0);
  };


  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [paginacao, setPaginacao] = useState({ pagina: 1, quantidade: 10 });



  useEffect(() => {
    // Atualiza os campos com os valores iniciais quando o 'data' for alterado
    if (data?.launch_date) {
      setValue('data.launch_date', data.launch_date);
    }
    if (data?.opening_date) {
      setValue('data.opening_date', data.opening_date);
    }
  }, [data, setValue]);

  const handleLaunchDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Se houver uma data válida, formate-a antes de definir o valor
    if (value) {
      const formattedDate = formatDate(new Date(value));
      setValue('data.launch_date', formattedDate);
    } else {
      setValue('data.launch_date', '');
    }
  };

  const handleOpeningDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Se houver uma data válida, formate-a antes de definir o valor
    if (value) {
      const formattedDate = formatDate(new Date(value));
      setValue('data.opening_date', formattedDate);
    } else {
      setValue('data.opening_date', '');
    }
  };

  // Função para formatar a data como AAAA-MM-DD
  const formatDate = (date: Date): string => {
    const isoDate = date.toISOString().split('T')[0];
    return isoDate;
  };

  return (
    <div className="card mb-5 mt-6 mb-xl-10">
      <div className="card-body pb-0">
        <h2 className='fw-bolder pb-4 m-0'>Informações Gerais</h2>
        <div className="row">
          <div className="form-group col-md-12 col-lg-2">
            <FormInputAndLabel
              label="Nome do condomínio"
              className="form-control form-control-solid"
              placeholder="Nome do condomínio"
              type='text'
              defaultValue={data ? data.name : ""}
              register={{ ...register('data.name') }}
              error={errors?.data?.name?.message || ''}
            />
          </div>

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <label className="col-form-label">Construtoras</label>
            <select
              className="form-select form-select-solid"
              name="id_developer"
              {...register('data.id_developer', { valueAsNumber: true })}
              defaultValue={data ? data.id_developer : null}
            >
              <option value={0}>Selecione</option>
              {partnersLaunch.map((response) => (
                <option key={response.id} value={response.id}>
                  {response.nome}
                </option>
              ))}

            </select>
            {errors && <ErrorMessageComponent message={errors?.data?.id_developer?.message} />}
          </div>

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <label className="col-form-label">Situação do Empreendimento</label>
            <select
              className="form-select form-select-solid"
              defaultValue={data ? data.status : ""}
              {...register('data.status')}
            >
              <option value="">Selecione</option>
              <option value="Pronto Novo">Pronto Novo</option>
              <option value="Em construção">Em construção</option>
              <option value="Breve Lançamento">Breve Lançamento</option>
            </select>
            {errors && <ErrorMessageComponent message={errors?.data?.status?.message} />}

          </div>

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <FormInputAndLabel
              type="date"
              label="Data de lançamento"
              className="form-control form-control-solid"
              onChange={handleLaunchDateChange}
              defaultValue={data ? data.launch_date : ''}
              error={errors ? errors?.data?.launch_date?.message : ''}
            />
          </div>

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <FormInputAndLabel
              type="date"
              label="Data de entrega"
              className="form-control form-control-solid"
              defaultValue={data ? data.opening_date : ''}
              onChange={handleOpeningDateChange}
              error={errors ? errors?.data?.opening_date?.message : ''}
            />
          </div>

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <FormInputAndLabel
              type="number"
              label="Apartamentos por Andar"
              className="form-control form-control-solid"
              placeholder="Ex: 0"
              defaultValue={data ? data.apts_per_floor : null}
              register={{ ...register('data.apts_per_floor', { valueAsNumber: true }) }}
              error={errors ? errors?.data?.apts_per_floor?.message : ''}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <FormInputAndLabel
              type="number"
              label="Número de Torres"
              className="form-control form-control-solid"
              placeholder="Ex: 0"
              defaultValue={data ? data.number_of_towers : ""}
              register={{ ...register('data.number_of_towers', { valueAsNumber: true }) }}
              error={errors ? errors?.data?.number_of_towers?.message : ''}

            />
          </div>

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <FormInputAndLabel
              type="number"
              label="Número de Andares"
              className="form-control form-control-solid"
              placeholder="Ex: 0"
              defaultValue={data ? data.number_of_floors : ""}
              register={{ ...register('data.number_of_floors', { valueAsNumber: true }) }}
              error={errors ? errors?.data?.number_of_floors?.message : ''}
            />
          </div>


          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <FormInputAndLabel
              type="number"
              label="Total Unidades"
              className="form-control form-control-solid"
              placeholder="Ex: 0"
              defaultValue={data ? data.total_units : ""}
              register={{ ...register('data.total_units', { valueAsNumber: true }) }}
              error={errors ? errors?.data?.total_units?.message : ''}
            />
          </div>

          <input type="hidden" {...register('data.latitude')} value={'-145345'} />
          <input type="hidden" {...register('data.longitude')} value={'+1425345'} />

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <AreaInput
              solid={true}
              label="Área Andar:"
              name="data.floor_area"
              control={control}
              error={errors?.data?.floor_area?.message}
            />
          </div>
          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <AreaInput
              solid={true}
              label="Área total:"
              name="data.total_area"
              control={control}
              error={errors?.data?.total_area?.message}
            />
          </div>
          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <FormInputAndLabel
              type="text"
              label="Qtd. Meses de entrega"
              className="form-control form-control-solid"
              placeholder="Ex: 0"
              defaultValue={data ? data.opening_months : ""}
              register={{ ...register('data.opening_months', { valueAsNumber: true }) }}
              error={errors ? errors?.data?.opening_months?.message : ''}
            />
          </div>
        </div>

        <div className="row mt-4">

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <FormInputAndLabel
              type="text"
              label="Número de alvará"
              className="form-control form-control-solid"
              placeholder="Ex: 12345"
              defaultValue={data ? data.notary_registration_number : ""}
              register={{ ...register('data.notary_registration_number') }}
              error={errors ? errors?.data?.notary_registration_number?.message : ''}
            />
          </div>

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <label className="col-form-label">Finalidade</label>
            <select
              className="form-select form-select-solid"
              defaultValue={data ? data.finality : ""}
              {...register('data.finality')}
            >
              <option value="">Selecione</option>
              <option value="Residencial">Residencial</option>
              <option value="Comercial">Comercial</option>
            </select>
            {errors && <ErrorMessageComponent message={errors?.data?.finality?.message} />}

          </div>

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <FormInputAndLabel
              type="text"
              label="Web Page"
              className="form-control form-control-solid"
              placeholder="Ex: https://www.site.com.br"
              defaultValue={data ? data.web_page : ""}
              register={{ ...register('data.webpage') }}
              error={errors ? errors?.data?.webpage?.message : ''}
            />
          </div>

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <label className="col-form-label">Status da Publicação</label>
            <select
              className="form-select form-select-solid"
              defaultValue={data ? data.id_status : ""}
              {...register('data.id_status', { valueAsNumber: true })}
            >
              <option value="">Selecione</option>
              <option value={1}>Publicado</option>
              <option value={2}>Em Edição</option>
              <option value={3}>Pendente Pulicação</option>
              <option value={4}>Decartado</option>
            </select>
            {errors && <ErrorMessageComponent message={errors?.data?.id_status?.message} />}

          </div>

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <label className="col-form-label">Futuro lançamento</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="futuroLancamento"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              {errors && <ErrorMessageComponent message={errors?.data?.future_launch?.message} />}
            </div>
          </div>

          <div className="form-group col-sm-12 col-md-6 col-lg-2">
            <label className="col-form-label">MCMV</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="affordable_program"
                checked={isCheckedMCMV}
                onChange={handleCheckboxMCMVChange}
              />
              {errors && <ErrorMessageComponent message={errors?.data?.affordable_program?.message} />}
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className={`form-group col-md-12 ${data ? '' : 'pb-8'}`}>
            <label className="col-form-label">Descrição</label>
            <textarea
              className="form-control form-control-solid"
              style={{ resize: 'none' }}
              defaultValue={data ? data.description : ""}
              {...register('data.description')}
            ></textarea>
            {errors && <ErrorMessageComponent message={errors?.data?.description?.message} />}
          </div>
        </div>
      </div>

      {data ? (
        <ul className="nav nav-map-info mt-6" id="tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link text-active-primary ms-0 me-10 py-1 active" id="tab-ab" data-bs-toggle="pill" data-bs-target="#tab-perfil" type="button" role="tab" aria-controls="tab-perfil" aria-selected="false">Dados Cadastrais</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link text-active-primary ms-0 me-10 py-1" id="tab-historico-tab" data-bs-toggle="pill" data-bs-target="#tab-historico" type="button" role="tab" aria-controls="tab-historico" aria-selected="false">Historico de preços</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link text-active-primary ms-0 me-10 py-1" id="tab-media-tab" data-bs-toggle="pill" data-bs-target="#tab-media" type="button" role="tab" aria-controls="tab-media" aria-selected="false">Mídia</button>
          </li>
        </ul>
      ) : (
        <></>
      )}

    </div>
  );
}
