import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import { QueryGetEmpresas } from '../../../../../graphql/services/Empresas'

export function GetEmpresasChosen({
  SetValueEmpresa,
  valueEmpresa
}) {
  let empresasArray = []

  const [empresa, setEmpresa] = useState(valueEmpresa)

  useEffect(() => {
    SetValueEmpresa(empresa)
  }, [empresa, SetValueEmpresa])

  const { data } = QueryGetEmpresas({
    variables: {
      pagination: {
        pagina: null,
        quantidade: 300
      },
      situacao: 1
    }
  })



  if (!data) {
    return (
      <>
        <div className='form-group'>
          <Select
            options={empresasArray}
            onChange={(e) => {
              setEmpresa(e.value)
            }}
            value={empresasArray.filter((e) => e.value)[0]}
            name='id_empresa'
            placeholder='Empresas Disponiveis'
          />
        </div>
      </>
    )
  }

  data.GetEmpresas.result.map((Options) => {
    empresasArray.push({ value: Options.id, label: Options.nome })
    return []
  })



  return (
    <>
      <div className='form-group'>
        <Select
          options={empresasArray}
          onChange={(e) => {
            setEmpresa(e.value)
          }}
          name='id_empresa'
          placeholder='Empresas Disponiveis'
        />
      </div>
    </>
  )
}
