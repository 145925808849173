import {useParams} from 'react-router-dom'
import '../../global.css'
import styles from './ficha.module.css'
import {FichaContent} from './fichaContent'
import {Footer} from '../../../../_metronic/layout/components/Footer'
import { QueryGetImovel} from '../../../graphql'
import {Loader} from '../../../components/UI/Loader/Loader'

export function FichaPublic() {
  const {uuid} = useParams<{uuid: string}>()
  // let data: ImovelResponse
  // const {uuid} = useParams<{uudi: string}>()

  // const data = QueryGetImovel({variables: {uuid: uuid}})
  const {data} = QueryGetImovel({variables: {uuid: uuid}})
  // data.GetImovelByUUID
  // verificar se o usuário tem plano
  // sim
  // exibir ficha com o logo
  // não
  //exibir ficha gratis com as infos bloqueadas

 
  // Tratativa de loading
  if (!data) {
    return <Loader text={'caregando...'}></Loader>
  } 
  return (
    <div className='book'>
      <div className={styles['ficha-header']} >
        <div className={styles['subpage']}>
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
              {/* {"ficha"} */}
              {data ? 
              <FichaContent data={data}></FichaContent>
              : ''}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
