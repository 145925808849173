import { useEffect } from "react";
import { useLocation } from 'react-router'
import clsx from 'clsx'
import { useLayout } from '../../../_metronic/layout/core'
import { DrawerComponent } from '../../../_metronic/assets/ts/components'
import { Footer } from '../../../_metronic/layout/components/Footer'
import { CardHeaderNavigation } from './components/CardHeader'
import '../global.css'
import { AsideDefault } from "../../../_metronic/layout/components/aside/AsideDefault";
import { GetProfileData } from "../../graphql/services/User";
import { HeaderWrapperFluid } from "../../../_metronic/layout/components/header/HeaderWrapperFluid";
import { TabFuncionarios } from "./components/Tabs/TabFuncionarios";
import { TabPerfil } from "./components/Tabs/TabPerfil";
import { TabPayments } from "./components/Tabs/TabPayments";
import { TabLogsAcessoRecurso } from "./components/Tabs/TabLogsAcessoRecurso";
import { SplashScreenComponent } from "../../components/UI";


export function Profile() {
  const { classes } = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])


  const { data } = GetProfileData()
  
  if (!data) {
    return (<SplashScreenComponent />)
  }

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />

      <div className='wrapper d-flex flex-column flex-row-fluid'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>

              <CardHeaderNavigation data={data} />

              {/*begin::Card*/}
              <div className="card" style={{ width: '100%' }}>
                {/*begin::Card header*/}
                <div className="card-header  border-0 pt-6">
                  {/*begin::Card title*/}
                  <div className="inboxContent" style={{ width: '100%' }}>
                    {/* begin::Row */}
                    <div className='row gy-5 gx-xl-8'>
                      <div className='col-xl-12'>
                        <div className="tab-content" id="tabContent">
                          <TabPerfil data={data} />
                          <TabFuncionarios />
                          <TabPayments />
                          <TabLogsAcessoRecurso />
                        </div>
                        <br /><br />
                      </div>
                    </div>
                    {/* end::Row */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}