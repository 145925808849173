import { gql } from '@apollo/client';

export const GET_SERVICOS = gql`
query GetServicoCategoria {
  GetServicoCategoria {
    id
    nome
    servico {
      url_api
      id
      descricao
      observacoes
      situacao
      custo
      nome
    }
  }
}
`

export const GET_SERVICO_BY_ID = gql`
query GetServico($getServicoId: Float!) {
  GetServico(id: $getServicoId) {
    id
    uuid
    nome
    descricao
    custo
    id_servico_categoria
    situacao
    session_name
    ordem
    url_api
    url_sistema
    exibe_alerta
    exibe_filtro_incorporadora
    observacoes
    depende_validacao
    distancia
    tempo
    limite
    atualizacao_url
    data_verificacao
    recorrencia
    exibe_planilha
    sync_imovel
    legenda
    situacao_dev
    filtro_isocota
    filtro_isocrona
    filtro_limit
    created_at
    updated_at
    deleted_at
    nacional
    modo_visualizacao
    icone
    servico_categoria {
      id
      id_categoria
      categoria {
        nome
        id
      }
    }
    servico_pacote {
      id
      pacote {
        id
        nome
      }
    }
  }
}
`

export const GET_SERVICO_CATEGORIA = gql`
query GetServicoCategoria {
  GetServicoCategoria {
    nome
    id
    servico {
      id
      nome
      exibe_planilha
      exibe_filtro_incorporadora
      session_name
      observacoes
      nacional
      descricao
    }
    
  }
}
`
