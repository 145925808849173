import React, { ReactNode, useEffect, useRef } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils';

// Define um tipo para representar os valores permitidos de cor do gráfico
type ChartColor = 'gray-heavy' | 'primary' | 'secondary' | 'black' | 'gray-light' | 'danger' | 'info';

// Define as cores
const Colors: Record<ChartColor, string> = {
  'gray-heavy': '#7B7B7A',
  'primary': '#69C05B',
  'secondary': '#0F997D',
  'black': '#000000',
  'gray-light': '#D9DCDD',
  'danger': '#B02A37',
  'info': '#087990',
};

type Props = {
  title: string;
  children: ReactNode;
  className: string;
  chartColor?: ChartColor; // Cor padrão para o gráfico
  baseColor?: ChartColor; // Cor base para o gráfico
  chartHeight?: string; // Altura padrão para o gráfico
}

const ChartWidgetColors1: React.FC<Props> = ({ title, className, chartColor, baseColor, chartHeight, children }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  const defaultChartColor: ChartColor = 'primary'; // Define sua cor padrão aqui
  const defaultBaseColor: ChartColor = 'primary'; // Define sua cor base padrão aqui
  const defaultChartHeight = '200px'; // Define sua altura padrão aqui

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef]);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const color: ChartColor = chartColor || defaultChartColor;
    const base: ChartColor = baseColor || defaultBaseColor;
    const height = chartHeight || defaultChartHeight;

    const chart = new ApexCharts(chartRef.current, chartOptions(color, base, height));
    chart.render();

    return chart;
  }

  const chartOptions = (color: ChartColor, base: ChartColor, height: string): ApexOptions => {
    const baseColor = Colors[base];
    const chartColor = Colors[color];
    const lightColor = getCSSVariableValue('--kt-' + color + '-light');
    const labelColor = getCSSVariableValue('--kt-gray-700');

    return {
      series: [74],
      chart: {
        fontFamily: 'inherit',
        height: height,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '65%',
          },
          dataLabels: {
            name: {
              show: false,
              fontWeight: '700',
            },
            value: {
              color: labelColor,
              fontSize: '30px',
              fontWeight: '700',
              offsetY: 12,
              show: true,
              formatter: function (val) {
                return val + '%'
              },
            },
          },
          track: {
            background: baseColor,
            strokeWidth: '100%',
          },
        },
      },
      colors: [chartColor],
      stroke: {
        lineCap: 'round',
      },
      labels: ['Progress'],
    };
  }

  return (
    <div className={`card ${className}`}>
      <div className="card-header p-0 all-center">
        <span className="fs-6">{title}</span>
      </div>
      <div className='card-body px-2 py-2'>
        <div ref={chartRef} className='mixed-widget-1-chart'></div>
      </div>
      <div className="card-footer p-0">
        {children}
      </div>
    </div>
  );
}

export { ChartWidgetColors1 };
