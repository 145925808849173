import { gql } from "@apollo/client";

export const GET_CIDADES = gql`
query GetCidadesByEstado($getCidadesByEstadoId: Float) {
  GetCidadesByEstado(id: $getCidadesByEstadoId) {
    id
    nome
    id_estado
  }
}
`