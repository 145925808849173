import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {QueryGetInteligenciaMercadoVSO} from '../../../../graphql/services/inteligencia-mercado'

type Props = {
  idVso: number
  setValue: any
}

export const GetVSOChosen: React.FC<Props> = ({idVso, setValue}) => {
  let options = []
  const [inteligenciaMercadoVSO, setInteligenciaMercadoVSO] = useState(null)

  const {data} = QueryGetInteligenciaMercadoVSO({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      },
    },
  })
  const vsoDefault = [
    {vso: 0, min: 0, max: 0},
    {vso: 30, min: 4, max: 5},
    {vso: 42, min: 9, max: 10},
    {vso: 48, min: 13, max: 14},
    {vso: 54, min: 17, max: 19},
    {vso: 60, min: 22, max: 24},
    {vso: 62, min: 26, max: 29},
    {vso: 64, min: 30, max: 34},
    {vso: 66, min: 35, max: 39},
    {vso: 68, min: 39, max: 43},
    {vso: 70, min: 44, max: 48},
    {vso: 72, min: 48, max: 53},
    {vso: 73, min: 52, max: 58},
    {vso: 74, min: 54, max: 60},
    {vso: 75, min: 56, max: 62},
    {vso: 77, min: 59, max: 65},
    {vso: 78, min: 61, max: 67},
    {vso: 79, min: 63, max: 69},
    {vso: 80, min: 65, max: 72},
    {vso: 81, min: 67, max: 74},
    {vso: 82, min: 69, max: 76},
    {vso: 84, min: 71, max: 79},
    {vso: 85, min: 73, max: 81},
    {vso: 86, min: 75, max: 83},
    {vso: 87, min: 78, max: 86},
    {vso: 88, min: 80, max: 88},
    {vso: 89, min: 82, max: 90},
    {vso: 91, min: 84, max: 93},
    {vso: 92, min: 86, max: 95},
    {vso: 93, min: 88, max: 97},
    {vso: 94, min: 90, max: 100},
    {vso: 95, min: 91, max: 100},
    {vso: 96, min: 92, max: 100},
    {vso: 97, min: 93, max: 100},
    {vso: 98, min: 93, max: 100},
    {vso: 99, min: 94, max: 100},
    {vso: 100, min: 95, max: 100},
  ]
  useEffect(() => {
    if (inteligenciaMercadoVSO) {
      if (inteligenciaMercadoVSO.value === 0) {
        setValue(vsoDefault)
      } else {
        if (data) {
          const result = data.GetInteligenciaMercadoVSO.result.find((item) => {
            return item.id === inteligenciaMercadoVSO.value
          })
          setValue(result.vso)
        }
      }
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inteligenciaMercadoVSO])

  if (!data) {
    return (
      <Select
        options={options}
        onChange={(e) => {
          setInteligenciaMercadoVSO(e)
        }}
        isDisabled={false}
        placeholder='Selecione o pacote'
      />
    )
  }
  options.push({value: 0, label: 'Padrão'})

  data.GetInteligenciaMercadoVSO.result.map((Options) => {
    options.push({value: Options.id, label: Options.nome})
    return []
  })

  return (
    <Select
      options={options}
      defaultValue={idVso}
      onChange={(e) => {
        setInteligenciaMercadoVSO(e)
      }}
      placeholder='Selecione o pacote'
    />
  )
}
