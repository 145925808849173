import { useState } from "react"
import { HeaderPage } from "./Header"
import { MapView } from "./MapView"
import { toAbsoluteUrl } from "../../../../_metronic/helpers"

export function ExplorerPage() {
    let latlng = []

    const [show, setShow] = useState(true)


    return (
        <>
            <div
                className='MapContainer d-flex flex-column flex-row-fluid'
                id='kt_wrapper'
                style={{ width: '100%', height: '100vh', overflow: 'hidden' }}
            >
                <HeaderPage setShow={setShow} show={show} />
                <div className="row">
                    <div className={show ? 'col-8' : 'col-12'}>
                        <div
                            id='map'
                            className=' flex-row'
                            style={{ width: '100%', height: '100%', position: 'relative' }}
                        >
                            <MapView latLng={latlng}></MapView>
                        </div>
                    </div>
                    <div className={show ? 'col-4 all-center' : 'd-none'}>
                        <div className=" px-16">
                            <h1 className='fts-2 mb-3'>Rua Diogo Jacome</h1>
                            <span className='bullet bullet-horizontal h-5px w-100 bg-success me-2 mb-6'></span>
                            <span className="fs-4 mt-6 text-justify" style={{textAlign: 'justify'}}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae modi maxime sit nostrum, exercitationem accusamus tempora eum voluptatem. Quisquam iure, vero nulla odio amet corrupti dolores enim quia soluta assumenda!
                                Aut optio similique eius consectetur iusto assumenda, totam possimus! Aspernatur eius sint provident illum similique, adipisci nemo odio ea rem a veritatis dignissimos architecto hic natus fuga sapiente optio error.</span>
                            <img alt='Logo' src={toAbsoluteUrl('/media/illustrations/sketchy-1/2.png')} className='h-400px' />
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}