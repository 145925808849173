import React, {FC} from 'react'
// import {useMap} from '../../../../../../hooks/useMap'
import {useExplorer} from '../../../../../../hooks/useExplorer'
import {Explorer} from '../Explorer'
import {Dicionario} from '../../../../../utils/Dicionario'

interface MapControlProps {}

export const MapTooltip: FC<MapControlProps> = (x) => {
  const {explorer} = useExplorer()

  if (Object.keys(explorer.decker).length > 0) {
    explorer.decker['setProps']({
      getTooltip: getTooltip,
    })
  }

  return <></>
}

function getTooltip({x, y, picked, object, layer}) {
  const camposNaoListados = ['gid', 'geom', 'centroid', 'cor_zona', 'cor', 'id']
  if (typeof layer !== 'undefined' && layer !== null && picked) {
    const campos = Explorer.getFields(layer['id'])
    if (campos.lenght === 0) {
      return null
    }
    if (!object) {
      return null
    }
    var template = ''
    let descricao = ''

    if (typeof object.properties === 'undefined') {
      return null
    } else {
      if (Object.entries(object.properties).length === 0) {
        return null
      }

      if (typeof object.properties['layerName'] !== 'undefined') {
        if (object.properties.layerName === 'municipios') {
          return null
        }
      }

      for (var i in object.properties) {
        if (camposNaoListados.indexOf(i) === -1) {
          descricao = formatTooltip(i, campos)
          if (descricao !== '') {
            template += ` <b>${Dicionario.translate(formatTooltip(i, campos))}</b> : ${
              object.properties[i]
            }</br>`
          }
        }
      }
      return {
        html: `${template}`,
        style: {
          backgroundColor: '#eeeeee',
          fontSize: '1.3em',
          color: '#333',
          padding: 10,
          width: 300,
          border: 1,
          borderColor: '#d0d0d0',
          boxShadow: '0px 1px 2px 2px #8888',
          zIndex: 9999999999,
        },
      }
    }
  }
}

function formatTooltip(propert, object) {
  // eslint-disable-next-line array-callback-return
  const retorno = object.filter((item: any, x: any) => {
    if (item.table_column.trim() === propert.trim()) {
      return item
    }
  })[0]
  if (typeof retorno !== 'undefined' && retorno !== null) {
    return retorno.description
  } else {
    return ''
  }
}
