import React from 'react';

type Props = {
  title: string,
}

const CardHeader: React.FC<Props> = ({ title }) => {
  return (
    <>
      {/*begin::Card*/}
      <div className="card mrt-1" style={{ width: '100%' }}>
        {/*begin::Card header*/}
        <div className="card-header ">
          {/*begin::Card title*/}
          <div className="card-title contentHeader">
            <h1 className="text-dark fw-bolder my-0 fs-2">{title}</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export { CardHeader };