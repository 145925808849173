import {useEffect, useState} from 'react'

export default function SelectDormitoriosFiltro({
  onChange,
  selected,
}: {
  onChange: any
  selected?: [number]
}) {
  const [checked, setChecked] = useState([])

  useEffect(() => {
    if (selected) {
      setChecked(selected)
    }
  }, [selected])

  useEffect(() => {
    onChange(checked)
  }, [checked, onChange])

  function changeCheck(e) {
    const value = Number(e.target.value)
    const checkSelected = e.target.checked

    if (checkSelected) {
      setChecked([...checked, value])
    } else {
      let filter = checked.findIndex((item) => item === value)
      console.log(filter)
      console.log(checked)
      checked.splice(filter, 1)
      setChecked([...checked])
    }
  }

  return (
    <div className='mydict'>
      <div>
        <label>
          <input
            type='checkbox'
            name='radio'
            value='1'
            checked={checked.find((el) => el === 1)}
            onClick={(e) => changeCheck(e)}
          />
          <span>1 </span>
        </label>
        <label>
          <input
            type='checkbox'
            name='radio'
            value='2'
            checked={checked.find((el) => el === 2)}
            onClick={(e) => changeCheck(e)}
          />
          <span>2 </span>
        </label>
        <label>
          <input
            type='checkbox'
            name='radio'
            value='3'
            checked={checked.find((el) => el === 3)}
            onClick={(e) => changeCheck(e)}
          />
          <span>3 </span>
        </label>
        <label>
          <input
            type='checkbox'
            name='radio'
            value='4'
            checked={checked.find((el) => el === 4)}
            onClick={(e) => changeCheck(e)}
          />
          <span>4 </span>
        </label>
        <label>
          <input
            type='checkbox'
            name='radio'
            value='5'
            checked={checked.find((el) => el === 5)}
            onClick={(e) => changeCheck(e)}
          />
          <span>5 + </span>
        </label>
      </div>
    </div>
  )
}
