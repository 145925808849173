import { gql } from "@apollo/client";


export const SET_PEDIDOS = gql`
mutation Mutation($pedido: PedidoInput!) {
    SetPedido(pedido: $pedido) {
      id
      total
      qtde_itens
      desconto
      id_situacao
    }
  }
`