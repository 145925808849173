import {QueryGetLogAcessoRecursosByEmpresa} from '../../../../backoffice/graphql/services/LogAcesso'
import {useState} from 'react'
import secureLocalStorage from 'react-secure-storage'
import {Spinner} from 'react-bootstrap'
import {ModalExportLogs} from './TabLogsAcessoRecurso-Partials/ModalExportLogs'
import {DateTimToView} from '../../../../components/util'
import PaginationComponent from '../../../../components/UI/PaginationComponents'

export function TabLogsAcessoRecurso() {
  const IdEmpresa = secureLocalStorage.getItem('id_empresa')
  const [pagiancao, setPaginacao] = useState({pagina: 0, quantidade: 10})
  const {data, loading} = QueryGetLogAcessoRecursosByEmpresa({
    variables: {
      idEmpresa: IdEmpresa,
      pagination: {
        pagina: pagiancao.pagina,
        quantidade: pagiancao.quantidade,
      },
      dataFim: null,
      dataInicio: null,
    },
  })

  if (loading) {
    ;<Spinner variant='success' animation='border' role='status'>
      <span className='visually-hidden'>Carregando...</span>
    </Spinner>
  }

  if (!data) {
    return (
      <div className='tab-pane fade' id='tab-logs' role='tabpanel' aria-labelledby='tab-logs-tab'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-100px'> ID</th>
              <th className='min-w-100px'> Nome Usuário</th>
              <th className='min-w-100px'> Ferramenta</th>
              <th className='min-w-50px '>Funcionalidade</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='text-center' colSpan={4}>
                <Spinner variant='success' animation='border' role='status'>
                  <span className='visually-hidden'>Carregando...</span>
                </Spinner>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <div className='tab-pane fade' id='tab-logs' role='tabpanel' aria-labelledby='tab-logs-tab'>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title'>
            <h3>Logs dos funcionarios</h3>
          </div>
          <div className='card-toolbar'>
            <ModalExportLogs idEmpresa={IdEmpresa} />
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive card-xxl-stretch mb-5 mb-xl-8'>
            <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-100px'> ID</th>
                  <th className='min-w-100px'> Nome Usuário</th>
                  <th className='min-w-100px'> Ferramenta</th>
                  <th className='min-w-50px '>Funcionalidade</th>
                  <th className='min-w-50px'>Data Acesso</th>
                </tr>
              </thead>
              <tbody>
                {data.GetLogAcessoRecursosByEmpresa.result.length !== 0 ? (
                  data.GetLogAcessoRecursosByEmpresa.result.map((response, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#vazio' className='text-dark fs-6'>
                                {response.id === null ? 'Não informado' : response.id}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#vazio' className='text-dark fs-6'>
                                {response.user.name === null ? 'Não informado' : response.user.name}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {response.ferramenta === null ? 'Não informado' : response.ferramenta}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {response.funcionalidade === null
                                ? 'Não informado'
                                : response.funcionalidade}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {response.data_acesso === null
                                ? 'Não informado'
                                : DateTimToView(response.data_acesso)}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr key={0}>
                    <td colSpan={12} className='text-center'>
                      <p className='text-dark fs-6'>Nenhum acesso registrado.</p>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
        </div>
        <div className='card-footer all-end px-0'>
          <PaginationComponent
            pagesInfo={data.GetLogAcessoRecursosByEmpresa.pageInfo}
            setPagesInfo={(pagina: number, quantidade: number) => {
              setPaginacao({pagina: pagina, quantidade: quantidade})
            }}
          ></PaginationComponent>
        </div>
      </div>
    </div>
  )
}
