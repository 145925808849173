import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ADMINQueryGetProdutos } from '../../../graphql/services/Produtos';

interface ProdutosChosenProps {
  setValueUsuarioProduto: React.Dispatch<React.SetStateAction<number>>;
  valueUsuarioProduto: number;
  id_produto: number;
}

export function PutProdutosChosen({
  setValueUsuarioProduto,
  valueUsuarioProduto,
  id_produto
}: ProdutosChosenProps) {
  const [produtosArray, setProdutosArray] = useState<{ value: number; label: string }[]>([]);
  const [selectedProduto, setSelectedProduto] = useState<{ value: number; label: string } | null>(null);

  const { data } = ADMINQueryGetProdutos({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      }
    }
  });

  useEffect(() => {
    if (data) {
      const produtos = data.GetProdutos.result.map((Options) => ({
        value: Options.id,
        label: Options.nome,
      }));
      setProdutosArray(produtos);

      // Set the default selected product
      const selected = produtos.find((produto) => produto.value === id_produto);
      setSelectedProduto(selected || null);
    }
  }, [data, id_produto]);

  useEffect(() => {
    // Update parent state with selected product
    if (selectedProduto) {
      setValueUsuarioProduto(selectedProduto.value);
    }
  }, [selectedProduto, setValueUsuarioProduto]);

  return (
    <div className='form-group'>
      <label className='col-form-label'>Produtos Disponíveis:</label>
      <Select
        isDisabled={true}
        options={produtosArray}
        value={selectedProduto} // Use the selectedProduto state to show the default value
        onChange={(e) => {
          setSelectedProduto(e ? { value: e.value, label: e.label } : null);
        }}
        name='id_produto'
        placeholder='Produtos Disponíveis'
      />
    </div>
  );
}
