import { Modal } from 'react-bootstrap'

import { DetailsImovelSimples } from '../imovel-simples'
import { MeusImoveisCorretores } from './Corretores'
import { useState } from 'react'
import { MeusImoveisProprietarios } from './Proprietarios'
import { CoordinatesToPath, MapStatic } from '../../../../components/UI/MapStatic'
import * as turf from '@turf/turf'
import { MeusImoveisObservacoes } from './Observacoes'
import { ImovelResponse } from '../../../../graphql'
type Props = {
  response: any
  closeModal: any
  show: boolean
}

export const MeusImoveisModal: React.FC<Props> = ({ response, closeModal, show }) => {
  const [showCorretores, setShowCorretores] = useState(false)
  const [showProprietarios, setShowProprietarios] = useState(false)
  const [showObservacoes, setShowObservacoes] = useState(false)

    
  const latlng = []
  if (response.terreno.marcacao) {
    let path = null
    if (response.terreno.marcacao.type === 'MultiPolygon') {
      path = CoordinatesToPath(response.terreno.marcacao['coordinates'][0][0])
    } else if (response.terreno.marcacao.type === 'Polygon') {
      path = CoordinatesToPath(response.terreno.marcacao['coordinates'][0])
    }
    latlng.push({
      path: path,
      center: turf.centroid(response.terreno.marcacao),
      id: response.terreno.id,
      endereco: response.terreno.endereco,
      area: response.terreno.area_iptu,
      editable: false,
    })
  }


  
  return (
    <Modal size='xl' fullscreen centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Meu Imóvel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='card'>
          <div className='card-header'>
            <h3 className='card-title'>{response.terreno.endereco}</h3>
          </div>
          <div className='card-body'>
            <div>
              <div className='col-lg-12 col-md-12 col-sm-12 rounded pb-10 '>
                {response ? <MapStatic latLng={latlng} onChange={() => { }}></MapStatic> : ''}
              </div>

              <ul className='nav nav-tabs' id='myTab' role='tablist'>
                <li className='nav-item' role='presentation'>
                  <button
                    className='nav-link active'
                    id='home-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#home'
                    type='button'
                    role='tab'
                    aria-controls='home'
                    aria-selected='true'
                  >
                    Dados do Imóvel
                  </button>
                </li>
                <li className='nav-item' role='presentation'>
                  <button
                    className='nav-link'
                    id='profile-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#profile'
                    type='button'
                    role='tab'
                    aria-controls='profile'
                    aria-selected='false'
                    onClick={() => setShowCorretores(true)}
                  >
                    Corretores
                  </button>
                </li>
                <li className='nav-item' role='presentation'>
                  <button
                    className='nav-link'
                    id='contact-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#contact'
                    type='button'
                    role='tab'
                    aria-controls='contact'
                    aria-selected='false'
                    onClick={() => setShowProprietarios(true)}
                  >
                    Proprietários
                  </button>
                </li>
                <li className='nav-item' role='presentation'>
                  <button
                    className='nav-link'
                    id='obs-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#obs'
                    type='button'
                    role='tab'
                    aria-controls='obs'
                    aria-selected='false'
                    onClick={() => setShowObservacoes(true)}
                  >
                    Observações
                  </button>
                </li>
              </ul>
              <div className='tab-content' id='myTabContent'>
                <div
                  className='tab-pane fade show active'
                  id='home'
                  role='tabpanel'
                  aria-labelledby='home-tab'
                >
                  <div className='card card-body'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>
                            Numero Contribuinte
                          </label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={
                                response.terreno.numero_contribuinte
                                  ? response.terreno.numero_contribuinte
                                  : '-'
                              }
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Nome Contribuinte</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.nome_contribuinte}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <label className='col-sm-3 col-form- text-bold'>Endereço</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.endereco ? response.terreno.endereco : '-'}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Bairro</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.bairro ? response.terreno.bairro : '-'}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Cidade</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.cidade ? response.terreno.cidade : '-'}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>CEP</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.cep ? response.terreno.cep : '-'}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Área Iptu</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.area_iptu ? response.terreno.area_iptu : '-'}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Área real</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.area_real ? response.terreno.area_real : '-'}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Zoneamento</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.zoneamento ? response.terreno.zoneamento : '-'}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>
                            Condição Comercial
                          </label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              id='condicaoComercial'
                              defaultValue='-'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Setor Quadra</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={
                                response.terreno.setor_quadra ? response.terreno.setor_quadra : '-'
                              }
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>
                            Uso atual do terreno
                          </label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={
                                response.terreno.uso_atual_terreno
                                  ? response.terreno.uso_atual_terreno
                                  : '-'
                              }
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Cod Log</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.codlog ? response.terreno.codlog : '-'}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form- text-bold'>FPR</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.fpr ? response.terreno.fpr : '-'}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>FPNR</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.fpnr ? response.terreno.fpnr : '-'}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Cabas</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.cabas ? response.terreno.cabas : '-'}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Camax</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.camax ? response.terreno.camax : '-'}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Quadro 14</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.quadro14 ? response.terreno.quadro14 : '-'}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Testada</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={response.terreno.testada ? response.terreno.testada : '-'}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label className='col-sm-3 col-form-label text-bold'>Operação Urbana</label>
                          <div className='col-sm-9'>
                            <input
                              type='text'
                              readOnly
                              className='form-control-plaintext'
                              defaultValue={
                                response.terreno.operacao_urbana
                                  ? response.terreno.operacao_urbana
                                  : '-'
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='profile'
                  role='tabpanel'
                  aria-labelledby='profile-tab'
                >
                  {showCorretores ? (
                    <MeusImoveisCorretores id={response.terreno.id}></MeusImoveisCorretores>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className='tab-pane fade'
                  id='contact'
                  role='tabpanel'
                  aria-labelledby='contact-tab'
                >
                  {showProprietarios ? (
                    <MeusImoveisProprietarios idImovel={response.terreno.id} dataImovel={response.terreno} ></MeusImoveisProprietarios>
                  ) : (
                    <></>
                  )}
                </div>

                <div
                  className='tab-pane fade'
                  id='obs'
                  role='tabpanel'
                  aria-labelledby='obs-tab'
                >
                  {showObservacoes ? (
                    <MeusImoveisObservacoes idImovel={response.terreno.id} ></MeusImoveisObservacoes>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <DetailsImovelSimples id={id}></DetailsImovelSimples> */}
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn btn-default'
          id='kt_account_profile_details_submit'
          onClick={closeModal}
        >
          Fechar
        </button>
      </Modal.Footer>
    </Modal>
  )
}
