export const LoaderInfoOverlay = (text, title='') => {
    document.getElementById('loader-text').innerHTML = text
    document.getElementById('loader').classList.add('show')
    document.getElementById('loader').classList.add('overlay-full-page')
    document.getElementById('loader').classList.remove('hide')
}

export const CloseLoaderInfoOverlay = () => {
    document.getElementById('loader-text').innerHTML = ''
    document.getElementById('loader').classList.add('hide')
    document.getElementById('loader').classList.remove('show')
    document.getElementById('loader').classList.remove('overlay-full-page')
}