import {Text, View, StyleSheet} from '@react-pdf/renderer'

const styles = StyleSheet.create({
  table: {
    width: '100%',
    fontSize: 11,
    fontWeight: 'light',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
  },
  rowErro: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
    textAlign: 'center',
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 16,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: '#2B8E57',
  },
  header: {
    borderTop: 'none',
    backgroundColor: '#dbdbdb',
    paddingHorizontal: 10,
  },
  bold: {
    fontWeight: 'black',
  },
  rowDefault: {
    width: '25%',
  },
  rowCenter: {
    width: '20%',
    textAlign: 'center',
  },
  decimal: {
    width: '20%',
    textAlign: 'center',
  },
  currency: {
    width: '20%',
    textAlign: 'center',
  },
})
export const Corretores = ({corretores}) => {
  if (corretores) {
    return (
      <View style={styles.table}>
        <Text style={styles.title}>Corretores</Text>
        <View style={[styles.row, styles.bold, styles.header]}>
          <Text style={styles.rowDefault}>Nome</Text>
          <Text style={styles.rowDefault}>E-mail</Text>
          <Text style={styles.rowDefault}>Telefone</Text>
          <Text style={styles.rowDefault}>Empresa</Text>
        </View>
        {corretores.GetImovelCorretor.length === 0 ? (
          <Text style={styles.rowErro}>Nenhum registro encontrado</Text>
        ) : (
          ''
        )}
        {corretores.GetImovelCorretor.map((row, i) => (
          <View key={i} style={styles.row} wrap={false}>
            <Text style={styles.rowDefault}>{row.corretor.nome}</Text>
            <Text style={styles.rowDefault}>{row.corretor.email}</Text>
            <Text style={styles.rowDefault}>{row.corretor.telefone}</Text>
            <Text style={styles.rowDefault}>{row.corretor.empresa}</Text>
          </View>
        ))}
      </View>
    )
  }
}
