import {useQuery} from '@apollo/client'
import {useState} from 'react'
import {GetServicoPacotePainelResponse} from '../../../../../../../graphql/type/servicoPainel'
import {GET_SERVICO_PAINEL} from '../../../../../../../graphql/query/servico-painel'
import {Index} from './Indices'
import {InfraestruturaRiscos} from './InfraestruturaRiscos'
import {ApartamentosVenda} from './ApartamentosVenda'
import {ApartamentosLocacao} from './ApartamentosLocacao'
import {Lancamentos} from './Lancamentos'
import { Endereco } from '../../../ComponentsControl/EnderecoControl/endereco'

interface Props {
  itens: []
  latLng: any
}

export const PainelImobiliarioContent: React.FC<Props> = ({itens, latLng}) => {
  const [activeTab, setActiveTab] = useState({
    index: true,
    infra: false,
    venda: false,
    locacao: false,
    lancamentos: false,
  })

  const municipio = Endereco.getMunicipio()
  let idMunicipio = 5351
  if (municipio) {
    idMunicipio = municipio.id
  }
  const {data} = useQuery<GetServicoPacotePainelResponse>(GET_SERVICO_PAINEL, {
    variables: {
      idProduto: 27,
      idMunicipio: idMunicipio,
    },
    fetchPolicy: 'no-cache',
  })
  // console.log(data)

  let indicesServices = null
  let infraServices = null
  let apVendaServices = null
  let apLocacaoServices = null
  let lancamentosServices = null
  if (data) {
    indicesServices = data.GetServicoPacotePainel[0]
    infraServices = data.GetServicoPacotePainel[1]
    apVendaServices = data.GetServicoPacotePainel[2]
    apLocacaoServices = data.GetServicoPacotePainel[3]
    lancamentosServices = data.GetServicoPacotePainel[4]
  } else {
    return <>Carregando</>
  }

  // console.log('Executando')

  // setActiveTab({...activeTab,index:true})

  function changeActiveTab(tab) {
    
    switch (tab) {
      case 'index':
        setActiveTab({
          ...activeTab,
          index: true,
          infra: false,
          venda: false,
          locacao: false,
          lancamentos: false,
        })
        break
      case 'infra':
        setActiveTab({
          ...activeTab,
          index: false,
          infra: true,
          venda: false,
          locacao: false,
          lancamentos: false,
        })
        break
      case 'venda':
        setActiveTab({
          ...activeTab,
          index: false,
          infra: false,
          venda: true,
          locacao: false,
          lancamentos: false,
        })
        break
      case 'locacao':
        setActiveTab({
          ...activeTab,
          index: false,
          infra: false,
          venda: false,
          locacao: true,
          lancamentos: false,
        })
        break
      case 'lancamentos':
        setActiveTab({
          ...activeTab,
          index: false,
          infra: false,
          venda: false,
          locacao: false,
          lancamentos: true,
        })
        break
    }
  }
  
  return (
    <>
      <ul
        className='nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-5'
        style={{backgroundColor: '#ffff', padding: '20px'}}
      >
        <li className='nav-item'>
          <a
            className={`nav-link active }`}
            data-bs-toggle='tab'
            href={`#tab_painel_imobiliario_indice`}
            onClick={() => {
              changeActiveTab('index')
            }}
          >
            Índice Urbit
          </a>
        </li>

        <li className='nav-item'>
          <a
            className={`nav-link`}
            data-bs-toggle='tab'
            href={`#tab_painel_imobiliario_InfraestruturaRiscos`}
            onClick={() => {
              changeActiveTab('infra')
            }}
          >
            Infraestrutura e Riscos
          </a>
        </li>

        <li className='nav-item'>
          <a
            className={`nav-link`}
            data-bs-toggle='tab'
            href={`#tab_painel_imobiliario_apartamentosVenda`}
            onClick={() => {
              changeActiveTab('venda')
            }}
          >
            Apartamentos à Venda
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={`nav-link`}
            data-bs-toggle='tab'
            href={`#tab_painel_imobiliario_apartamentosLocacao`}
            onClick={() => {
              changeActiveTab('locacao')
            }}
          >
            Apartamentos para Locação
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={`nav-link`}
            data-bs-toggle='tab'
            href={`#tab_painel_imobiliario_lancamentos`}
            onClick={() => {
              changeActiveTab('lancamentos')
            }}
          >
            Lançamentos
          </a>
        </li>
      </ul>
      <div className='tab-content' id='tab_painel_imobiliario'>
        <div
          className={`tab-pane fade  show active`}
          id={`tab_painel_imobiliario_indice`}
          role='tabpanel'
          key={'tabpanel_indice'}
        >
          {activeTab.index ? (
          <Index itens={indicesServices} latLng={latLng} activeTab={activeTab}></Index>
           ) : (
            <></>
          )} 
        </div>
        <div
          className={`tab-pane fade `}
          id={`tab_painel_imobiliario_InfraestruturaRiscos`}
          role='tabpanel'
          key={'tabpanel_InfraestruturaRiscos'}
        >
          {activeTab.infra ? (
            <InfraestruturaRiscos itens={infraServices} latLng={latLng}></InfraestruturaRiscos>
          ) : (
            <></>
          )}
        </div>
        <div
          className={`tab-pane fade `}
          id={`tab_painel_imobiliario_apartamentosVenda`}
          role='tabpanel'
          key={'tabpanel_apartamentosVenda'}
        >
          {activeTab.venda ? (
            <ApartamentosVenda itens={apVendaServices} latLng={latLng}></ApartamentosVenda>
          ) : (
            <></>
          )}
        </div>
        <div
          className={`tab-pane fade `}
          id={`tab_painel_imobiliario_apartamentosLocacao`}
          role='tabpanel'
          key={'tabpanel_apartamentosLocacao'}
        >
          {activeTab.locacao ? (
            <ApartamentosLocacao itens={apLocacaoServices} latLng={latLng}></ApartamentosLocacao>
          ) : (
            <></>
          )}
        </div>
        <div
          className={`tab-pane fade `}
          id={`tab_painel_imobiliario_lancamentos`}
          role='tabpanel'
          key={'tabpanel_lancamentos'}
        >
          {activeTab.lancamentos ? (
            <Lancamentos itens={lancamentosServices} latLng={latLng}></Lancamentos>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}
