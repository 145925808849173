import { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BiAddToQueue } from 'react-icons/bi';
import { zodResolver } from '@hookform/resolvers/zod';
import Swal from 'sweetalert2';
import { TypologyFormSchema, TypologyFormData } from './formValidations';
import { addTypology } from '../../../../../../services/http/urbit-gis/routes';
import { useParams } from 'react-router-dom';
import { useEmpreendimentos } from '../../context/useEmpreendimentos';
import { MoneyInput } from '../../../../../components/UI/Inputs/MoneyInput';
import { AreaInput } from '../../../../../components/UI/Inputs/AreaInput';

export function ModalCreateTypologies() {
  const { id: idEmpreendimento } = useParams<{ id: string }>();
  const { refetchTypologies } = useEmpreendimentos(); // Hook importado

  const [show, setShow] = useState(false);
  const formRef = useRef<HTMLFormElement>(null); // Adicione esta linha

  const closeModal = () => setShow(false);
  const openModal = () => setShow(true);

  const { register, handleSubmit, setValue, formState: { errors }, reset, control } = useForm<TypologyFormData>({
    resolver: zodResolver(TypologyFormSchema),
  });

  const onSubmit = async (data: TypologyFormData) => {
    try {
      data.id_real_estate_launches = parseInt(idEmpreendimento);
      const result = await addTypology(data);
      if (result && result.service !== 'error') {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Tipologia adicionada com sucesso.',
          icon: 'success',
          confirmButtonText: 'OK'
        });
        closeModal();
        reset();
        if (idEmpreendimento) {
          await refetchTypologies(parseInt(idEmpreendimento)); // Atualiza a lista de tipologias
        }
      } else {
        throw new Error(result.message || 'Ocorreu um erro desconhecido');
      }
    } catch (error) {
      let errorMessage = 'Houve um erro ao adicionar a tipologia.';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      Swal.fire({
        title: 'Erro!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleExternalSubmit = () => {
    if (formRef.current) {
      handleSubmit(onSubmit)(); // Chama a função handleSubmit manualmente
    }
  };

  return (
    <>
      <button
        type='button'
        onClick={openModal}
        disabled={!idEmpreendimento}
        className='btn btn-light-success btn-sm d-block me-2'
        style={{ width: '100px !important' }}
        aria-disabled={!idEmpreendimento}
      >
        <BiAddToQueue className='fs-4 me-1' />
        Nova Tipologia
      </button>

      <Modal
        centered
        animation
        show={show}
        onHide={closeModal}
        size={'lg'}
        aria-labelledby="modal-create-typologies"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal-create-typologies">Associar Tipologia</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-lg pt-2'>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}> {/* Adicionei ref */}
            <div>
              <input
                type="hidden"
                value={parseInt(idEmpreendimento)}
                {...register('id_real_estate_launches', { valueAsNumber: true })}
              />
            </div>
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label className='col-form-label' htmlFor="type">Tipo:</label>
                  <select id="type" className='form-select' {...register('type')}>
                    <option value="">Selecione</option>
                    {/* Opcões de tipo */}
                    <option value="Casa">Casa</option>
                    <option value="Sala">Sala</option>
                    <option value="Loft">Loft</option>
                    <option value="Loja">Loja</option>
                    <option value="Duplex">Duplex</option>
                    <option value="Studio">Studio</option>
                    <option value="Garden">Garden</option>
                    <option value="Triplex">Triplex</option>
                    <option value="Apartamento">Apartamento</option>
                    <option value="Cobertura Duplex">Cobertura Duplex</option>
                    <option value="Cobertura Horizontal">Cobertura Horizontal</option>
                    <option value="Galpão/Pavilhão">Galpão/Pavilhão</option>
                    <option value="Casa em Condomínio">Casa em Condomínio</option>
                    <option value="Sala com Área Ext.">Sala com Área Ext.</option>
                    <option value="Terreno/Lote Comercial">Terreno/Lote Comercial</option>
                    <option value="Terreno/Lote Residencial">Terreno/Lote Residencial</option>
                  </select>
                  {errors.type && <span className="text-danger">{errors.type.message}</span>}
                </div>
              </div>
              <div className="col-4">
                <AreaInput
                  solid={false}
                  label="Área:"
                  name="private_area"
                  control={control}
                  error={errors.private_area?.message}
                />
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label className='col-form-label' htmlFor="total_units">Total de Unidades:</label>
                  <input
                    id="total_units"
                    className='form-control'
                    placeholder='Ex: 100'
                    type="number"
                    {...register('total_units', { valueAsNumber: true })}
                    onChange={(e) => {
                      setValue('stock', parseInt(e.target.value));
                      setValue('sales', 0);
                    }}
                    aria-describedby="total_units_helper"
                  />
                  {errors.total_units && <span className="text-danger">{errors.total_units.message}</span>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  <label className='col-form-label' htmlFor="bedrooms">Quartos:</label>
                  <select id="bedrooms" className='form-select' {...register('bedrooms', { valueAsNumber: true })}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5+</option>
                  </select>
                  {errors.bedrooms && <span className="text-danger">{errors.bedrooms.message}</span>}
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className='col-form-label' htmlFor="suites">Suítes:</label>
                  <select id="suites" className='form-select' {...register('suites', { valueAsNumber: true })}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5+</option>
                  </select>
                  {errors.suites && <span className="text-danger">{errors.suites.message}</span>}
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className='col-form-label' htmlFor="parking">Vagas:</label>
                  <select id="parking" className='form-select' {...register('parking', { valueAsNumber: true })}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5+</option>
                  </select>
                  {errors.parking && <span className="text-danger">{errors.parking.message}</span>}
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className='col-form-label' htmlFor="bathrooms">Banheiros:</label>
                  <select id="bathrooms" className='form-select' {...register('bathrooms', { valueAsNumber: true })}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5+</option>
                  </select>
                  {errors.bathrooms && <span className="text-danger">{errors.bathrooms.message}</span>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <MoneyInput
                  label="Valor do Condomínio:"
                  name="condominium_value"
                  control={control}
                  error={errors.condominium_value?.message}
                />
              </div>
              <div className="col-6">
                <MoneyInput
                  label="Valor do IPTU:"
                  name="urban_land_tax"
                  control={control}
                  error={errors.urban_land_tax?.message}
                />
              </div>
            </div>
            <div className="text-end mt-4">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleExternalSubmit} // Chama a função de submit diretamente
              >
                Salvar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
