import React, { useEffect, useState } from 'react';
import OffCanvas from './OffCanvas';
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi"

interface Props {
    show: boolean;
    setShow: any
}

export function HeaderPage({ setShow, show }: Props) {
    const [isHeader, setIsHeader] = useState(false)

    useEffect(() => {
        const scrollListener = () => {
            if (window.scrollY > 5) {
                setIsHeader(true);
            } else {
                setIsHeader(false);
            }
        }

        window.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('scroll', scrollListener);
        }

    }, []);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleFullscreen = () => {
        const element = document.documentElement;

        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen().then(() => {
                    setIsFullscreen(true);
                }).catch(error => {
                    console.error('Erro ao entrar no modo de tela cheia:', error);
                });
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen().then(() => {
                    setIsFullscreen(false);
                }).catch(error => {
                    console.error('Erro ao sair do modo de tela cheia:', error);
                });
            }
        }
    };

    const toggleOffCanvas = () => {
        setShow(!show);
    };


    return (
        <>
            <nav className='navbar fixed-top px-5 py-6 bg-white' >
                <div className="container-fluid w-100 all-center-between">
                    <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2   mrl-4" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                        {/*begin::Heading*/}
                        <h1 className="text-dark fw-bolder m-0 fs-1">Apresentação: Apresentação semestral dos imoveis</h1>
                        {/*end::Heading*/}
                    </div>
                    <div className='d-flex'>

                        <button className='btn btn-success me-4' onClick={toggleOffCanvas}>
                            Informações
                        </button>

                        <button className="btn btn-icon rounded bg-dark"
                            onClick={toggleFullscreen}
                        >
                            {isFullscreen ? (
                                <BiExitFullscreen size={32} className="text-success" />
                            ) : (
                                <BiFullscreen size={32} className="text-success" />
                            )}
                        </button>
                    </div>
                </div>
            </nav>

        </>
    )
}