import { gql } from "@apollo/client";
// Busca de todos as Situações de terreno pelo ID do usuario
export const FIND_EXPLORER_TAGS_GRUPO_TAGAS = gql`
query GetExplorerTagsGrupoTags {
  GetExplorerTagsGrupoTags {
    explorer_tags {
      id
      tag
      situacao
    }
    explorer_tags_grupo {
      nome
      descricao
      id,
      icone
    }
    id
  }
}
`