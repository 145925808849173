export class ValidateData {
  static isDate = (data) => {
    return false
  }
  static isInteger(data) {
    // console.log(isNaN(parseInt(data)))

    if (isNaN(parseInt(data))) {
      return false
    } else {
      return true
    }
  }
  static isDouble(data) {
    if (isNaN(parseInt(data))) {
      return false
    } else {
      return true
    }
  }
  static isText(data) {
    if (isNaN(parseInt(data))) {
      return true
    } else {
      return false
    }
  }
  static checkString(data: any) {
    if (this.isDate(data)) {
      // console.log('date')
      return 'Date'
    } else if (this.isInteger(data)) {
      // console.log('Integer')
      return 'Integer'
    } else if (this.isDouble(data)) {
      // console.log('Double')
      return 'Double'
    } else if (this.isText(data)) {
      // console.log('Text')
      return 'Text'
    }
  }
  static checkNumber(data) {
    if (this.isInteger(data)) {
      return 'Integer'
    } else {
      return 'Text'
    }
  }

  static getDataType(data: any) {
    // console.log(data)
    var type = typeof data
    // console.log(type)

    switch (type) {
      case 'string':
        return this.checkString(data)
      case 'number':
        return this.checkNumber(data)
    }
  }
}
