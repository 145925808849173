import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../_metronic/partials'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  descricao:string
  valor:number
}

export const RadialBar: React.FC<Props> = ({className, chartColor, chartHeight,descricao,valor}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight,valor))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div className=' d-flex flex-column'>
      {/* <div className='align-items-center flex-column-fluid justify-content-center'> */}
        <div ref={chartRef} className='mixed-widget-4-chart'></div>
        <div className=' justify-content-center' style={{    margin: '0 auto'}}>{descricao}</div>
      {/* </div> */}
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string,valor:number): ApexOptions => {
//   const baseColor = getCSSVariableValue('--kt-primary')
  const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--kt-gray-700')
  return {
    series: [valor],
    chart: {
      fontFamily: 'inherit',
      height: 160,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '22px',
            fontWeight: '700',
            offsetY: 8,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: ['#1e6929'],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}
