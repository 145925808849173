import React from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
    imageUrl: string;
    isOpen: () => void;
    onRequestClose: () => void;
}

const ImageViewerModal = ({ imageUrl, isOpen, onRequestClose }) => {
    return (
        <Modal
            show={isOpen} 
            centered
            onHide={() => onRequestClose()}
        >
            <img src={imageUrl} alt="Full-size" style={{ width: '100%' }} />
        </Modal>
    );
};

export default ImageViewerModal;