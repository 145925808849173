import {useState} from 'react'

import './galery.css'
import ImageViewerModal from './ImageView'

type Props = {
  images: {}
}

export function Fotos({images}: Props) {
  const [selectedImageUrl, setSelectedImageUrl] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const openModal = (imageUrl: string) => {
    setSelectedImageUrl(imageUrl)
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <div className='kt_content_container  d-flex flex-column flex-column-fluid'>
    <div className='card card-custom'>
      <div className='card-header all-start'>
        <h1 className='fts-2 pt-5'>Fotos</h1>
      </div>
      {/* begin::Body */}
      <div className='card-body'>
      <div className='row g-5 g-xl-8 '>
        <div className='col-xl-12'>
          <div className='row'>
            <div className='contentGalery'>
              <div className='gallery'>
                {images &&
                  typeof images === 'object' &&
                  Object.entries(images).map(([imageName, imageUrl]) => (
                    <img
                      key={imageName}
                      className='zoom pointer'
                      src={typeof imageUrl === 'string' ? imageUrl : ''}
                      alt={imageName}
                      onClick={() => openModal(imageUrl)}
                    />
                  ))}
                <ImageViewerModal
                  imageUrl={selectedImageUrl}
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                />
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    </div>
    </div>
  )
}
