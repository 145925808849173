import { gql } from "@apollo/client";

export const GET_IMOVEL_CONDICAO_COMERCIAL = gql` 
query GetImovelCondicaoComercialByTerreno($idImovel: Float!, $pagination: Pagination) {
  GetImovelCondicaoComercialByTerreno(id_imovel: $idImovel, pagination: $pagination) 
  {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        id
        dinheiro_valor
        permuta_fisica_pecentual
        permuta_financeira_percentual
        observacoes
        valor_total
        situacao
      }
    }
  }
`

export const GET_IMOVEL_CONDICAO_COMERCIAL_BY_ID = gql` 
query GetImovelCondicaoComercial($condicaoComercial: condicaoComercial!) {
  GetImovelCondicaoComercial(condicaoComercial: $condicaoComercial) {
      id
      dinheiro_valor
      permuta_fisica_pecentual
      permuta_financeira_percentual
      observacoes
      valor_total
      situacao
    }
  }
`