import React from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import './react_dates_overrides.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';

interface DateRangePickerProps {
  startDate: Moment | null;
  endDate: Moment | null;
  onStartDateChange: (date: Moment | null) => void;
  onEndDateChange: (date: Moment | null) => void;
  focusedInput: FocusedInputShape | null;
  setFocusedInput: (focusedInput: FocusedInputShape | null) => void;
}

const DateRangePickerComponent: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  focusedInput,
  setFocusedInput,
}) => {
  const minDate = moment('2024-01-01');

  const handleDatesChange = ({ startDate, endDate }: { startDate: Moment | null; endDate: Moment | null }) => {
    onStartDateChange(startDate);
    onEndDateChange(endDate);
  };

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="start_date"
      endDate={endDate}
      endDateId="end_date"
      onDatesChange={handleDatesChange}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      startDatePlaceholderText="Data Inícial"
      endDatePlaceholderText="Término"
      displayFormat="DD/MM/YYYY"
      isOutsideRange={(day) => day.isBefore(minDate)}
      daySize={50}
    />
  );
};

export default DateRangePickerComponent;
