import secureLocalStorage from 'react-secure-storage'

export const ValidaPermissaoAcessoCorporativo = (usuarioProdutos) => {
  const assinaturas = usuarioProdutos.GetUsuarioProdutos.filter((item) => {
    if (item.produto) {
      if (item.produto.tipo_produto === 'ASSINATURA' && item.produto.id === 6) {
        return true
      }
    }
    return false
  })
  return assinaturas.length > 0 ? true : false
}

export const ValidaPermissaoAcesso = (produto) => {
  const produtosUsuario: any = secureLocalStorage.getItem('produtos')
  if (produtosUsuario) {
    let r = JSON.parse(produtosUsuario)
    if (r) {
      const assinaturas = r.filter((item) => {
        if (item) {
          if (item.id === produto) {
            return true
          }
        }
        return false
      })
      return assinaturas.length > 0 ? true : false
    } 
  } 
  
  return false
  
}

export const GetEmpresaUsuario = () => {
  const empresaUsuario: any = secureLocalStorage.getItem('empresa') || null
  if (empresaUsuario) {
    let r = JSON.parse(empresaUsuario)
    if (Object.entries(r).length > 0) {
      return r
    } else {
      return false
    }
  }
}

export const GetAcessoUsuarioProduto = (produto) => {
  const produtosUsuario: any = secureLocalStorage.getItem('produtos') || []
  if (produtosUsuario) {
    if (produtosUsuario.length > 0 ){
    let r = JSON.parse(produtosUsuario)
    if (r.length > 0) {
      const result = r.filter((el) => {
        return el.id === produto
      })
      if (result.length === 0) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }else{
    return false
  }
  }
}

export const GetProdutosUsuario = () => {
  const produtosUsuario: any = secureLocalStorage.getItem('produtos') || null
  if (produtosUsuario) {
    let r = JSON.parse(produtosUsuario)
    if (r.length > 0) {
      return r
    } else {
      return []
    }
  }
}

export const ValidaProdutoAtivo = () => {
  const produtosUsuario: any = secureLocalStorage.getItem('produtos') || null
  const empresaData: any = secureLocalStorage.getItem('empresa') || null
  if (empresaData) {
    let r = JSON.parse(produtosUsuario)

    if (r.length > 0) {
      return r
    } else {
      return false
    }
  }

  if (typeof produtosUsuario !== 'undefined' && produtosUsuario !== 'undefined') {
    let r = JSON.parse(produtosUsuario)
    if (r.length > 0) {
      return empresaData
    } else {
      return false
    }
  }

  return false
}
