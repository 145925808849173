import {Button, Modal} from 'react-bootstrap'
import ProjetoInstance from '../../../../Projeto'
import {Explorer} from '../../../Map/Explorer'
import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {MutationSetLogAcessoRecurso} from '../../../../../../graphql/services/LogAcesso'

type Message = {
  error: boolean
  message: string
  data: any
}

type Props = {
  id: number
  setIdOpen?: any
}

export const ButtonNovo: React.FC<Props> = ({id, setIdOpen}) => {
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState<Message>({error: null, message: null, data: null})
  const history = useHistory()

  function confirmar() {
    if (Explorer.getCountLayers() > 0) {
      history.push('/explorer')
      Explorer.removeALlLayers()
      ProjetoInstance.novoProjeto()
      document.getElementById('nomeprojeto').innerHTML = 'Meu Projeto'
      document.getElementsByName('nomeProjeto')[0].innerHTML = 'Meu Projeto'
    } else {
      Explorer.removeALlLayers()
      ProjetoInstance.novoProjeto()
      document.getElementById('nomeprojeto').innerHTML = 'Meu Projeto'
      document.getElementsByName('nomeProjeto')[0].innerHTML = 'Meu Projeto'
    }
    setShow(false)
  }
  const handleClose = () => {
    setMessage({
      error: null,
      message: null,
      data: null,
    })
    setShow(false)
  }

  return (
    <>
      <button
        className=' btn btn-bg-light btn-active-color-success btn-sm'
        data-id={id}
        onClick={() => {
          setShow(true)

          FormSetLogAcessoRecurso({
            ferramenta: 'explorer',
            funcionalidade: 'novo-projeto',
            action: 'click',
            url: '/explorer',
          })
        }}
      >
        <i className='fs-5  bi bi-folder-plus'></i>
        Novo
      </button>
      <Modal centered animation show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Novo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message.error ? (
            <>
              <p>{message.message}</p>
              {message.data ? (
                <p className='fw-bold'>
                  Deseja abrir a ficha deste imóvel? <br />{' '}
                </p>
              ) : (
                ''
              )}
            </>
          ) : Explorer.getCountLayers() > 0 ? (
            <p>Atenção, você ira perder o projeto atual! Deseja continuar?</p>
          ) : (
            <p>Criar novo projeto?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' data-dismiss='modal' onClick={handleClose}>
            Fechar
          </Button>

          <Button variant='primary' data-dismiss='modal' onClick={confirmar}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
