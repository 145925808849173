import {z} from 'zod'
import {validarCPF} from '../../components/util'

const FormInputsImovel = {
  id: z.number(),
  // nome: z.string(),
  // descricao: z.string(),
  // status: z.number(),
  tag: z.string(),
  endereco: z.string().nonempty('o campo é obrigatório'),
  complemento: z.string(),
  bairro: z.string(),
  marcacao: z.string(),
  // cidade: z.number(),
  // estado: z.number(),
  id_cidade: z.number(),
  id_estado: z.number(),
  status_cliente: z.number().nullable(), //status-cliente
  numero: z.string(),
  cep: z.string(),
  area_iptu: z.number(),
  area_real: z.number(),
  zoneamento: z.string(),
  numero_contribuinte: z.string(),
  nome_contribuinte: z.string(),
  numero_matricula: z.string(),
  setor_quadra: z.string(),
  uso_atual_terreno: z.string(),
  macroarea: z.string(),
  macrozona: z.string(),
  codlog: z.string(),
  fpr: z.number(),
  fpnr: z.number(),
  cabas: z.number(),
  camax: z.number(),

  testada: z.number(),
  responsavel: z.string(),
  operacao_urbana: z.string(),
  quadro14: z.number(),
  preco_m2: z.number(),
  preco_total: z.number(),
}
export const FormEditImovelFormSchema = z.object(FormInputsImovel)

export const FormImovelFormSchema = z.object(FormInputsImovel)

export type FormUpdateImovelFormData = z.infer<typeof FormEditImovelFormSchema>
export type FormImovelFormData = z.infer<typeof FormImovelFormSchema>

const FormCreateInputsImovel = {
  nome: z.string(),
  // descricao: z.string(),
  // status: z.number(),
  endereco: z.string().nonempty('o campo é obrigatório'),
  complemento: z.string(),
  bairro: z.string(),
  marcacao: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  id_cidade: z.number().optional(),
  id_estado: z.number().optional(),
  status_cliente: z.number().nullable(), //status-cliente
  numero: z.string(),
  cep: z.string(),
  area_iptu: z.number(),
  area_real: z.number(),
  zoneamento: z.string(),
  numero_contribuinte: z.string(),
  nome_contribuinte: z.string(),
  numero_matricula: z.string(),
  setor_quadra: z.string(),

  uso_atual_terreno: z.string(),
  macroarea: z.string(),
  macrozona: z.string(),
  codlog: z.string(),
  fpr: z.number(),
  fpnr: z.number(),
  cabas: z.number(),
  camax: z.number(),

  testada: z.number(),
  responsavel: z.string(),
  operacao_urbana: z.string(),
  quadro14: z.number(),
  preco_m2: z.number(),
  preco_total: z.number(),
}
export const FormCreateImovelFormSchema = z.object(FormCreateInputsImovel)
export type FormCreateImovelFormData = z.infer<typeof FormCreateImovelFormSchema>

const FormCreateImovelAddEmprendimento = {
  idEmpreendimento: z.number(),
  nome: z.string(),
  // descricao: z.string(),
  // status: z.number(),
  endereco: z.string().nonempty('o campo é obrigatório'),
  complemento: z.string(),
  bairro: z.string(),
  marcacao: z.string(),
  latitude: z.string(),
  longitude: z.string(),
  id_cidade: z.number().optional(),
  id_estado: z.number().optional(),
  status_cliente: z.number().nullable(), //status-cliente
  numero: z.string(),
  cep: z.string(),
  area_iptu: z.number(),
  area_real: z.number(),
  zoneamento: z.string(),
  numero_contribuinte: z.string(),
  nome_contribuinte: z.string(),
  numero_matricula: z.string(),
  setor_quadra: z.string(),

  uso_atual_terreno: z.string(),
  macroarea: z.string(),
  macrozona: z.string(),
  codlog: z.string(),
  fpr: z.number(),
  fpnr: z.number(),
  cabas: z.number(),
  camax: z.number(),

  testada: z.number(),
  responsavel: z.string(),
  operacao_urbana: z.string(),
  quadro14: z.number(),
  preco_m2: z.number(),
  preco_total: z.number(),
}
export const FormCreateImovelAddEmprendimentoSchema = z.object(FormCreateImovelAddEmprendimento)
export type FormCreateImovelAddEmprendimentoData = z.infer<
  typeof FormCreateImovelAddEmprendimentoSchema
>

const FormCreateBasicInputsImovel = {
  nome: z.string(),
  // descricao: z.string(),
  // status: z.number(),
  endereco: z.string().nonempty('o campo é obrigatório'),
  // complemento: z.string(),
  bairro: z.string(),
  marcacao: z.string(),
  latitude: z.string(),
  longitude: z.string(),
  cidade: z.string(),
  estado: z.string(),
  id_cidade: z.number(),
  id_estado: z.number(),
  numero: z.string(),
  cep: z.string(),
  area_iptu: z.number().optional(),
  // area_real: z.number(),
  // zoneamento: z.string(),
  numero_contribuinte: z.string().optional(),
  // nome_contribuinte: z.string(),
  // numero_matricula: z.string(),
  setor_quadra: z.string().optional(),
  uso_atual_terreno: z.string().optional(),
  // macroarea: z.string(),
  // macrozona: z.string(),
  codlog: z.string().optional(),
  // fpr: z.number(),
  // fpnr: z.number(),
  // cabas: z.number(),
  // camax: z.number(),
  // metragem: z.number(),
  // testada: z.number(),
  // responsavel: z.string(),
  // operacao_urbana: z.string(),
  // quadro14: z.number(),
  // preco_m2: z.number(),
  // preco_total: z.number(),
}
export const FormCreateBasicImovelFormSchema = z.object(FormCreateBasicInputsImovel)
export type FormCreateBasicImovelFormData = z.infer<typeof FormCreateBasicImovelFormSchema>

// CREATE

export const FormCreateImovelProprietariosSchema = z
  .object({
    // id: z.string(),
    nome: z.string(),
    email: z.string(),
    telefone: z.string(),
    // cnpj: z.string(),
    cpf: z.string().refine((cpf) => validarCPF(cpf), {
      message: 'CPF inválido',
    }),
    cnpj: z.string(),
    endereco: z.string(),
    numero: z.string(),
    cep: z.string(),
    complemento: z.string(),
    id_cidade: z.number().nullable(),
    id_estado: z.number().nullable(),
    // estado: z.string(),
    // cidade: z.string(),
    empresa: z.string(),
    situacao: z.number(),
    tipo: z.string(),
  })
  .refine(
    (input) => {
      if (input.tipo === 'pf' && input.nome === '') {
        return false
      }
      return true
    },
    {
      message: 'Nome é obrigatório',
      path: ['nome'],
    }
  )
  .refine(
    (input) => {
      if (input.tipo === 'pj' && input.empresa === '') {
        return false
      }
      return true
    },
    {
      message: 'Nome da empresa é obrigatório',
      path: ['empresa'],
    }
  )

export type FormCreateImovelProprietariosFormData = z.infer<
  typeof FormCreateImovelProprietariosSchema
>

export const FormAssociationImovelProprietarioFormSchema = z.object({
  id_proprietario: z.number(),
  id_terreno: z.number(),
})

export type FormAssociationImovelProprietarioFormData = z.infer<
  typeof FormAssociationImovelProprietarioFormSchema
>

export const FormCreateImovelProprietarioPessoaSchema = z
  .object({
    nome: z.string(),
    email: z.string(),
    telefone: z.string(),
    cnpj: z.string(),
    endereco: z.string(),
    numero: z.string(),
    cep: z.string(),
    complemento: z.string(),
    empresa: z.string(),
    situacao: z.number(),
    tipo: z.string(),
  })
  .refine(
    (input) => {
      if (input.tipo === 'pf' && input.nome === '') {
        return false
      }
      return true
    },
    {
      message: 'Nome é obrigatório',
      path: ['nome'],
    }
  )
  .refine(
    (input) => {
      if (input.tipo === 'pj' && input.empresa === '') {
        return false
      }
      return true
    },
    {
      message: 'Nome da empresa é obrigatório',
      path: ['empresa'],
    }
  )

export type FormCreateImovelProprietarioPessoaFormData = z.infer<
  typeof FormCreateImovelProprietarioPessoaSchema
>

// UPDATE

export const FormPutImovelProprietariosSchema = z
  .object({
    id: z.number(),
    nome: z.string(),
    email: z.string(),
    telefone: z.string(),
    cnpj: z.string(),
    cpf: z.string(),
    endereco: z.string(),
    numero: z.string(),
    cep: z.string(),
    complemento: z.string(),
    id_cidade: z.number(),
    id_estado: z.number(),
    // estado: z.string(),
    // cidade: z.string(),
    empresa: z.string(),
    tipo: z.string().optional(),
    // situacao: z.number()
  })
  .refine(
    (input) => {
      if (input.tipo === 'pf' && input.nome === '') {
        return false
      }
      return true
    },
    {
      message: 'Nome é obrigatório',
      path: ['nome'],
    }
  )
  .refine(
    (input) => {
      if (input.tipo === 'pj' && input.empresa === '') {
        return false
      }
      return true
    },
    {
      message: 'Nome da empresa é obrigatório',
      path: ['empresa'],
    }
  )

export type FormPutImovelProprietariosFormData = z.infer<typeof FormPutImovelProprietariosSchema>
