import { gql } from "@apollo/client";

export const GET_SERVICO_PAINEL = gql`
query GetServicoPacotePainel($idProduto: Float!, $idMunicipio: Float) {
  GetServicoPacotePainel(idProduto: $idProduto, idMunicipio: $idMunicipio) {
    agrupamento
    descricao
    id
    id_agrupamento
    nome

    categoria {
      id
      nome
      descricao
      subnivel
      servico {
        id_categoria
        nome_categoria
        id
        nome
        chart_column_x
        chart_type
        descricao
        icone
        chart_column_y
        display_config
        legenda
        modo_visualizacao
        nacional
        observacoes
        propriedade_valor
        table_header
        url_sistema
        visualizacao
        url_api
        titulo
        tipo_saida
        table_body
        template
        calc_destaque
        distancia
      }
      
    }
  }
}
`


export const GET_SERVICO_PAINEL_LANCAMENTO = gql`
query GetServicoPacotePainelLancamento($idProduto: Float!, $idMunicipio: Float) {
  GetServicoPacotePainelLancamento(idProduto: $idProduto, idMunicipio: $idMunicipio) {
    agrupamento
    descricao
    id
    id_agrupamento
    nome

    categoria {
      id
      nome
      descricao
      subnivel
      servico {
        id_categoria
        nome_categoria
        id
        nome
        chart_column_x
        chart_type
        descricao
        icone
        chart_column_y
        display_config
        legenda
        modo_visualizacao
        nacional
        observacoes
        propriedade_valor
        table_header
        url_sistema
        visualizacao
        url_api
        titulo
        tipo_saida
        table_body
        template
        calc_destaque
        distancia
      }
      
    }
  }
}
`