import React from 'react'
import Swal from 'sweetalert2'
import { MutationDeleteImovelObservaçoes } from '../../../../../graphql'
import { KTSVG } from '../../../../../../_metronic/helpers'

type Props = {
  id: number
  setIdEdit?: any
}

export const Buttons: React.FC<Props> = ({ id, setIdEdit }) => {

  const { HandleDeleteImovelObservacoes, loading } = MutationDeleteImovelObservaçoes()
  if (loading) {
    Swal.fire(
      'Enviando Informações...',
      '',
    )
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteImovelObservacoes({variables: { deleteImovelObservacaoId: id }})
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Observação deletada com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          onClick={(e) => {
            setIdEdit(id)
          }}
          className='btn btn-icon btn-light-success btn-sm me-2'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button>
        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          disabled={loading}
          className='btn btn-icon btn-light-danger btn-sm me-2'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
