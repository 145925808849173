type props = {
  onChange: any
  defaultValue?: number
}

export default function SelectDormitorios({onChange, defaultValue}: props) {
  return (
    <div className='mydict'>
      <div>
        <select className='form-control' onChange={onChange} defaultValue={defaultValue}>
          <option value='0'>Todos</option>
          <option value='1'>1 Dorm.</option>
          <option value='2'>2 Dorm.</option>
          <option value='3'>3 Dorm.</option>
          <option value='4'>4 Dorm.</option>
          <option value='5'>5 Dorm.</option>
          <option value='6'>6 Dorm.</option>
        </select>
      </div>
    </div>
  )
}
