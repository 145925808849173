import { gql } from '@apollo/client';

export const GET_CUPONS = gql`
query GetCupom($filtro: filter, $pagination: Pagination) {
  GetCupom(filtro: $filtro, pagination: $pagination) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      id
      uuid
      id_user
      codigo
      usado
      desconto
      data_expiracao
      tipo
      created_at
      updated_at
      deleted_at
      utilizado_em
    }
  }
}
`

export const GET_CUPOM_BY_ID = gql`
query GetCupomById($getCupomByIdId: Float!) {
  GetCupomById(id: $getCupomByIdId) {
    id
    uuid
    id_user
    codigo
    usado
    desconto
    data_expiracao
    tipo
    created_at
    updated_at
    deleted_at
    utilizado_em
  }
}
`