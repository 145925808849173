import {useMutation} from '@apollo/client'
import {Endereco} from '../../ComponentsControl/EnderecoControl/endereco'
import {APiUrbit} from '../../../../../../services/api/apiUrbit'
import {SetImovelResponse} from '../../../../../../graphql/type/imovel'
import {SET_IMOVEL} from '../../../../../../graphql/mutations/imovel'
import {FaRegClipboard} from 'react-icons/fa'
import {GET_IMOVEIS} from '../../../../../../graphql'
import {errorHandler} from '../../../../../../graphql/errorHandler'
import {formatAdressSeachGoogleMapa} from '../../../common'
import {useEffect, useState} from 'react'
import {Loader} from '../../../../../../components/UI/Loader/Loader'
import {Button, Modal} from 'react-bootstrap'

interface Props {
  itens: []
  latLng: google.maps.LatLng
  municipio: any
}
interface Lote {
  ano_da_construcao_corrigido: number | null
  area_construida: number | null
  area_lote: number | null
  area_ocupada: number | null
  bairro: string
  cartorio: string
  cep: string
  cidade: string
  codlog: string
  complemento: string
  data_do_cadastramento: string
  distancia: number
  estado: string
  geom: string
  geom_closest_point: string
  id: string
  id_cidade: string
  id_estado: string
  logradouro: string
  lote: string
  matricula: string
  nome_contribuinte: string
  numero: string
  numero_contribuinte: string
  quadra: string
  quantidade_de_esquinas_frentes: number
  quantidade_de_pavimentos: number
  setor: string
  testada: string
  tipo_de_terreno: string
  uso_atual: string
  valor_do_m2_do_terreno: string
}
type Message = {
  error: boolean
  message: string
  data: any
}

export const ModalFichaSemLote: React.FC<Props> = ({itens, latLng, municipio}) => {
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState<Message>({error: null, message: null, data: null})
  const [createImovel] = useMutation<SetImovelResponse>(SET_IMOVEL, {
    refetchQueries: [GET_IMOVEIS],
  })

  function salvarLocal() {
    const lat = latLng.lat()
    const lng = latLng.lng()
    const municipio = Endereco.getMunicipio()

    const geocoder = new google.maps.Geocoder()

    if (lat && lng) {
      const latLng = new google.maps.LatLng(lat, lng)
      const geom = {
        type: 'Point',
        coordinates: [lng, lat],
      }
      geocoder.geocode({location: latLng}, async function (results, status) {
        if (status === 'OK') {
          var place = results[0]
          var adr_elements = formatAdressSeachGoogleMapa(place)

          await createImovel({
            variables: {
              imovel: {
                numero: adr_elements['numero'] || 'S/N',
                nome: adr_elements['logradouro']
                  ? adr_elements['logradouro'] + ',' + adr_elements['numero']
                  : adr_elements['endereco_completo'] + ',S/N',
                endereco: adr_elements['logradouro'] || adr_elements['endereco_completo'],
                bairro: adr_elements['bairro'],
                estado: adr_elements['estado'],
                cidade: municipio['nome'],
                terrain_type: 'IMOVEL',
                postgis_municipio_id: municipio['id'],
                complemento: '',
                cep: adr_elements['cep'],
                area_iptu: undefined,
                area_real: undefined,
                numero_contribuinte: '',
                latitude: String(lat),
                longitude: String(lng),
                setor_quadra: '',
                uso_atual_terreno: '',
                codlog: '',
                terreno_esquina: '',
                numero_lote: '',
                marcacao: JSON.stringify(geom),
                testada: undefined,
                preco_m2: undefined,
                cod_condomio: '',
                id_situacao: 1,
              },
            },
          })
            .then(async (result) => {
              if (!result.data) {
                setMessage({
                  error: true,
                  message: errorHandler(result.errors),
                  data: result.errors[0]['extensions']['exception']['data'],
                })
              } else {
                setMessage({
                  error: true,
                  message: 'Endereço:' + result.data.SetImovel.nome,
                  data: result.data.SetImovel,
                })
              }
            })
            .catch((result) => {
              setMessage({
                error: true,
                message: errorHandler(result.errors),
                data: null,
              })
            })
        }
      })
    }
  }

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  async function checkLocal() {
    setShow(true)
  }

  const handleClose = () => {
    setMessage({
      error: null,
      message: null,
      data: null,
    })
    setShow(false)
  }

  console.log(message)
  return (
    <>
      <button
        className='btn btn-flex fs-1 flex-center bg-body btn-color-gray-700 btn-active-light-success w-md-auto h-40px px-0 px-md-6'
        onClick={checkLocal}
      >
        <FaRegClipboard className='fs-3 me-1' /> <span className='mt-1'>FICHA</span>
      </button>
      <Modal centered animation show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Gerar Ficha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message.error ? (
            <>
              <p>{message.message}</p>
              {message.data ? (
                <p className='fw-bold'>
                  Deseja abrir a ficha deste imóvel? <br />{' '}
                </p>
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              <p>Esse imóvel será salvo nos "Meus Locais". Deseja continuar?</p>
              <p>
                Para regiões que não contém lote utilizamos o geocoder para consultar o endereço.
              </p>
              {/* <p>
                {lote['logradouro']},{lote['numero']}.
              </p> */}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' data-dismiss='modal' onClick={handleClose}>
            Fechar
          </Button>
          {message.data ? (
            <button
              onClick={() => {
                openInNewTab(`/ficha/${message.data.uuid}?idCidade=${municipio.id}`)
                handleClose()
              }}
              className='btn btn-info '
            >
              Abrir Ficha
            </button>
          ) : (
            ''
          )}
          {!message.error ? (
            <Button variant='primary' data-dismiss='modal' onClick={salvarLocal}>
              Gerar Ficha
            </Button>
          ) : (
            ''
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}
