import {Modal} from 'react-bootstrap'
import {useState} from 'react'
import {APiUrbit} from '../../../../services/api/apiUrbit'
import {LoaderButton} from '../../../../components/UI/Loader/LoaderButton'

interface Props {
  textDefault: string
  show: boolean
  closeModal: any
}

export function ModalSendEmail({textDefault, show, closeModal}: Props) {
  const [loading, setLoading] = useState(false)
  async function enviarPorEmail(e) {
    e.preventDefault()
    setLoading(true)
    const data = {
      email: e.target.emails.value,
      assunto: e.target.titulo.value,
      corpo: e.target.body.value,
    }
    const formData = new FormData()
    formData.append('email', e.target.emails.value)
    formData.append('assunto', e.target.titulo.value)
    formData.append('corpo', e.target.body.value)

    await fetch('http://localhost:4001/send-mail', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        // 'Content-Type': 'multipart/form-data',
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    }).then((response) => {
      return response.json()
    })

    const api = new APiUrbit()
    await api.post(`/send-mail`, data)
    setLoading(false)

    return false
  }

  return (
    <>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar ficha por e-mail:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={enviarPorEmail}
            id='kt_account_profile_details_form'
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
          >
            <div className='card-body'>
              <div className='row mb-6'>
                <label className='col-md-4 col-form-label fw-bold fs-6'>Emails:</label>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-lg-12 '>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='contato@gmail.com.br,contato2@gmail.com.br'
                        id='emails'
                        name='emails'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-md-4 col-form-label fw-bold fs-6'>Titulo:</label>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-lg-12 '>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='titulo'
                        id='titulo'
                        name='titulo'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-md-4 col-form-label fw-bold fs-6' htmlFor='body'>
                  Texto:
                </label>

                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-lg-12 '>
                      <textarea name='body' id='body' className='form-control'></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-12 py-6 d-flex' style={{justifyContent: 'flex-end'}}>
              <button className='btn btnSecondary  pull-right ' type='button' onClick={closeModal}>
                Fechar
              </button>
              <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                {loading ? <LoaderButton text={'Consultando...'}></LoaderButton> : ' Enviar'}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
