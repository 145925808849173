import React from 'react'
import Map, {Layer, Marker, Source} from 'react-map-gl'
import type {FeatureCollection} from 'geojson'
import type {FillLayer} from 'react-map-gl'
import * as turf from '@turf/turf'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
// import Pin from './Pin'
import 'mapbox-gl/dist/mapbox-gl.css'
import {marcadoresMap} from '../../util'
import mapboxgl from 'mapbox-gl'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl['workerClass'] = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

type PropsContextMenu = {
  latLng: number[]
  inputRef: any
  onLoad: any
  zoom?: number
  style?: any
  options: {
    tiles: string[]
    source: string
    paint: object
    layout: object
    paintText: object
  }
}

const geojson: FeatureCollection = {
  type: 'FeatureCollection',
  features: [],
}

const layerStyle: FillLayer = {
  id: 'point',
  type: 'fill',
  paint: {
    'fill-color': '#8a8a8a',
    'fill-outline-color': '#333',
    'fill-opacity': 0.5,
  },
}
async function fit(map, callback) {
  if (geojson) {
    const envelope = turf.bbox(geojson)

    map.target.fitBounds(
      [
        [envelope[0], envelope[1]],
        [envelope[2], envelope[3]],
      ],
      {padding: 10}
    )
  }
  callback(map)
}
export const MapboxLayer: React.FC<PropsContextMenu> = ({
  inputRef,
  onLoad,
  latLng,
  zoom = 17,
  style = {width: 600, height: 350},
  options,
}) => {
  return (
    <Map
      ref={inputRef}
      mapboxAccessToken='pk.eyJ1Ijoia29seCIsImEiOiJjajI0eHA1MzMwMDVuMzNvNWRlMDBnd2U0In0.ZPJhRHqplNUji9ZRi89wCg'
      initialViewState={{
        longitude: latLng[1],
        latitude: latLng[0],
        zoom: zoom,
      }}
      style={style}
      mapStyle='mapbox://styles/kolx/cko8xwo2p07g218mpjig6a0fd'
      onIdle={(map) => {
        onLoad(map)
      }}
      onLoad={async (map) => {
        map.target.resize()
        // onLoad(map)
        //   await fit(map, (map2) => {})
      }}
    >
      <Source id={options.source} type='vector' format='pbf' tiles={options.tiles}>
        <Layer
          id='my-layer'
          type='fill'
          source={options.source}
          source-layer={options.source}
          paint={options.paint}
        />

        <Layer
          id='text-layer'
          type='symbol'
          source={options.source}
          source-layer={options.source}
          layout={options.layout}
          paint={options.paintText}
        />
      </Source>

      <Marker longitude={latLng[1]} latitude={latLng[0]}></Marker>
    </Map>
  )
}
