

import pptxgen from 'pptxgenjs'
import PptxGenJS from 'pptxgenjs'

const STARLABS_LOGO_SM = '/media/logos/logo.png'



export const formatLogo={path: STARLABS_LOGO_SM, x: 11, y: 0.3, w: 1.87, h: 0.55}
export const formatHeader:PptxGenJS.TextPropsOptions = {
  x: 0.5,
  y: 0.1,
  w: 10,
  h: 1,
  align: 'left',
  fontSize: 22,
  color: '333',
  fontFace: 'Arial',
  //   fill: {color: 'EBEDEE'},
}
export const formatSubHeader:PptxGenJS.TextPropsOptions = {
  x: 0.5,
  y: 0.7,
  w: 10,
  h: 0.8,
  lineSpacing: 2,
  align: 'left',
  fontSize: 12,
  color: '#333',

  fontFace: 'Arial',
  //   fill: {color: 'EBEDEE'},
}
