import { useMutation, useQuery } from "@apollo/client";
import { TypesADMINPutProdutoResponse, TypesDeletePacoteResponse, TypesGetPacoteByIDResponse, TypesGetPacotesResponse, TypesSetPacotesResponse } from "../types";
import { GET_PACOTES, GET_PACOTE_BY_ID } from "../querys/pacotesQuery";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ADMIN_PUT_PRODUTO, DELETE_PACOTE, PUT_PACOTE, SET_PACOTE } from "../mutation";
import { putPacoteFormData, putPacoteFormSchema, putProdutoFormData, putProdutoFormSchema, setPacoteFormData, setPacoteFormSchema, setProdutoFormData, setProdutoFormSchema } from "../../formValidations";
import { ADMIN_GET_PRODUTOS } from "../querys";


  interface QueryPacotesProps {
    variables: any;
  }

export function QueryGetPacotes({ variables }: QueryPacotesProps) {

    const { data, loading, error } = useQuery<TypesGetPacotesResponse>(GET_PACOTES, {
        variables: {...variables}
    })

    return {data, loading, error}

}

export function QueryGetPacoteById({variables}: QueryPacotesProps) {
    const { data } = useQuery<TypesGetPacoteByIDResponse>(GET_PACOTE_BY_ID, {
        variables: {...variables}
    })

    return data
}


export function ADMINMutationCreatePacote() {

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
    formState: { errors }
  } = useForm<setPacoteFormData>({
    resolver: zodResolver(setPacoteFormSchema)
  });

  console.log('Erro:',errors )

  const [setPacoteBody, { loading, data: DataSetPacote,error }] = useMutation<TypesSetPacotesResponse>(SET_PACOTE, {
    refetchQueries: [GET_PACOTES]
  })

  async function FormPacote(data: setPacoteFormData) {
    await setPacoteBody({
      variables: {
        data: {
          descricao: data.descricao,
          nome: data.nome,
          id_produto: data.id_produto,
        },
        idServicos: data.idServicos
      }
    })
  };


  return { register, handleSubmit, FormPacote, loading, errors, setError, setValue, getValues, control, error, DataSetPacote }
}

export function ADMINMutationPutPacote() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<putPacoteFormData>({
    resolver: zodResolver(putPacoteFormSchema),
  })

  console.log(errors)

  const [putUsuario, { loading, error, data: DataPutProduto }] = useMutation<TypesADMINPutProdutoResponse>(PUT_PACOTE)

  async function FormPacote(data: putPacoteFormData) {
    try {
      return  await putUsuario({
        variables:  {
          data: {
            id: data.id,
            descricao: data.descricao,
            nome: data.nome,
            id_produto: data.id_produto,
          },
          idServicos: data.idServicos
        },
        refetchQueries: [ADMIN_GET_PRODUTOS],
      })

    } catch (e) {
      console.error('Erro na requisição:', e.message);
      return e.message
     }
   
  }

  return {
    FormPacote, error, register, handleSubmit, loading,
    setError, setValue, getValues, control, DataPutProduto, errors
  }
}


export function MutationDeletePacote() {
  const [deletePacoteBody, {loading}] = useMutation<TypesDeletePacoteResponse>(DELETE_PACOTE)


  
  async function HandleDeletePacote({variables}: QueryPacotesProps) {
    const result = await deletePacoteBody({
      variables: {...variables},
      refetchQueries: [GET_PACOTES],
    })

    return result
  }

    return { HandleDeletePacote, loading }
}