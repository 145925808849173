import {useContext, useEffect, useState} from 'react'
import {Endereco} from '../components/Types'
import {FilterContext} from '../context/FilterContext'
import {formatAdressSeachGoogleMapa} from '../../explorer/components/common'

const useAutocomplete = (map) => {
  const [marcador, setMarcador] = useState([])
  const [buscaPosition, setBuscaPosition] = useState('busca-mapa')
  const {setEndereco} = useContext(FilterContext)
  const [autocomplete, setAutocomplete] = useState(null)

  // function onchangeAdress(map, enderecoFormatado: Endereco) {
  //   if (map) {
      
     
  //   }
  // }

  function setMarcadorAddress(map, endereco) {
   
    if (Object.entries(map).length > 0) {
      map.panTo(new google.maps.LatLng(endereco.latitude, endereco.longitude))
    }

    let m = [
      {
        lat: endereco.latitude,
        lng: endereco.longitude,
        endereco: endereco.endereco_completo,
      },
    ]
    setMarcador([...marcador, ...m])
  }

  function onPlaceChanged(map) {
    setBuscaPosition('')
    if (autocomplete !== null) {
      const place = autocomplete.getPlace()
      if (typeof place.geometry !== 'undefined') {
        const enderecoFormatado = formatAdressSeachGoogleMapa(autocomplete.getPlace())
        // filter.endereco = enderecoFormatado
        // onchangeAdress(map, enderecoFormatado)
        setMarcadorAddress(map, enderecoFormatado)
        setEndereco({...enderecoFormatado})
      }
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  function onLoadAutoComplete(autocomplete) {
    setAutocomplete(autocomplete)
  }

  return {
    // onchangeAdress,
    marcador,
    setMarcador,
    buscaPosition,
    setBuscaPosition,
    setAutocomplete,
    onPlaceChanged,
    onLoadAutoComplete,
    setMarcadorAddress
  }
}

export default useAutocomplete
