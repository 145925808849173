import { useEffect, useState } from 'react'
import {
  TypesGetUsuarioByID
} from '../../../../../../graphql'
import { useParams } from 'react-router-dom'
import { Loader } from '../../../../../../components/UI/Loader/Loader'
import { MutationPutEmpresaUsuarioByID, MutationSetEmpresaUsuarioByID } from '../../../../../graphql/services/Empresas'
import { HexToRgb, RgbToHex } from '../../../../../../pages/explorer/components/common'
import { MutationPutUsuarioByID } from '../../../../../graphql'
import Swal from 'sweetalert2'
import { setEmpresaUsuarioFormData, putEmpresaUsuarioFormData } from '../../../../../formValidations/Empresa'
import { GetEmpresasChosen } from '../partials/EmpresasChosen'

type Props = {
  data: TypesGetUsuarioByID
}

export function Tab1({ data }: Props) {
  console.log(data)
  const { id } = useParams<{ id: string }>()
  const idUser = parseInt(id)
  const idEmpresaUsuario = data.GetUsuario.empresa_usuario && data.GetUsuario.empresa_usuario[0] ? data.GetUsuario.empresa_usuario[0].id : null;
  let idEmpresa = data.GetUsuario.empresa_usuario[0]?.empresa?.id || null;
  const [colorSelect, setColorSelect] = useState('')

  const [color, setColor] = useState({
    r: 241,
    g: 112,
    b: 19,
    a: 255,
  })
  const [colorFont, setColorFont] = useState({
    r: 241,
    g: 112,
    b: 19,
    a: 255,
  })

  const [empresaSelecionada, setEmpresaSelecionada] = useState(idEmpresa);
  const { FormSetEmpresaUsuario } = MutationSetEmpresaUsuarioByID()
  const { FormPutEmpresaUsuario } = MutationPutEmpresaUsuarioByID()
  const { FormUpdateUser, handleSubmit, loading, register, setValue } = MutationPutUsuarioByID()

  const styles = {
    default: {
      color: {
        width: '36px',
        height: '28px',
        borderRadius: '5px',
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      colorFont: {
        width: '36px',
        height: '28px',
        borderRadius: '5px',
        background: `rgba(${colorFont.r}, ${colorFont.g}, ${colorFont.b}, ${colorFont.a})`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  }

  useEffect(() => {
    if (data) {
      if (data.GetUsuario.cor_background) {
        let corBg = HexToRgb(data.GetUsuario.cor_background)
        if (corBg) {
          setColor({
            r: corBg[0],
            g: corBg[1],
            b: corBg[2],
            a: corBg[3],
          })
        }
      }
      if (data.GetUsuario.cor_fonte) {
        let corFont = HexToRgb(data.GetUsuario.cor_fonte)
        if (corFont) {
          setColorFont({
            r: corFont[0],
            g: corFont[1],
            b: corFont[2],
            a: corFont[3],
          })
        }
        // setColor)
        // setColorFont(data.GetProfile.cor_fonte)
      }
    }
  }, [data])

  // Tratativa de loading
  if (!data) {
    return <Loader text={'caregando...'}></Loader>
  }

  if (loading) {
    Swal.fire(
      'Enviando Informações...', '')
    Swal.showLoading()
  }

  const handleClick = () => {
    // setShow(1)
  }

  const onSubmit = async (data, id_user: number, idEmpresa: string, id: number) => {
    let id_empresa = parseInt(idEmpresa)
    if (colorSelect !== null) {
      document.documentElement.style.setProperty("--page-bg", colorSelect);
    }

    const dataSetEmpresas: setEmpresaUsuarioFormData = { id_empresa, id_user };
    const dataPutEmpresas: putEmpresaUsuarioFormData = { id, id_empresa, id_user };

    console.log(id, id_empresa, id_user, data)

    if (id !== null) {
      FormPutEmpresaUsuario(dataPutEmpresas).then((result) => {
        console.log(result, `Mudou a empresa para:${idEmpresa}`);
      }).catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error');
      });
    } else {
      FormSetEmpresaUsuario(dataSetEmpresas).then((result) => {
        Swal.fire('Sucesso!', 'Nova empresa associada com sucesso.', 'success');
      }).catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error');
      });
    }

    FormUpdateUser(data).then((result) => {
      Swal.fire('Sucesso!', 'Informações atualizadas com sucesso.', 'success')
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')

    })
  }

  return (
    <div className='tab-pane fade pb-12  show active' id='tab1' role='tabpanel' aria-labelledby='tab1-tab'>
      <div className="card mb-5 mb-xl-10">
        <div className="card-header">
          <div className="card-title ">
            <h3>Atualizar detalhes de perfil</h3>
          </div>
        </div>
        <div className="pb-0">
          <form
            id='kt_account_profile_details_form'
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
            onSubmit={(e) => handleSubmit((data) => onSubmit(data, idUser, empresaSelecionada, idEmpresaUsuario))(e)}
          >
            <input type="text" hidden defaultValue={data.GetUsuario.id} {...register('id', { valueAsNumber: true, required: true })} />
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Nome Completo
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        name='nome'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        defaultValue={data?.GetUsuario.name}
                        {...register('name')}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
                <div className='col-lg-8 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    name='email'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    defaultValue={data?.GetUsuario.email}
                    {...register('email')}
                  />
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Telefone</span>
                  <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title=''
                    data-bs-original-title='Phone number must be active'
                    aria-label='Phone number must be active'
                  ></i>
                </label>
                <div className='col-lg-8 fv-row fv-plugins-icon-container'>
                  <input
                    type='tel'
                    name='telefone'
                    className='form-control form-control-lg form-control-solid'
                    defaultValue={data?.GetUsuario.telefone}
                    {...register('telefone')}
                  />
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Tipo de Pessoa:</span>
                  <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title=''
                    data-bs-original-title='Phone number must be active'
                    aria-label='Phone number must be active'
                  ></i>
                </label>
                <div className='col-lg-2 fv-row fv-plugins-icon-container'>
                  <input
                    type='tel'
                    name='tipo_pessoa'
                    className='form-control form-control-lg form-control-solid'
                    disabled
                    defaultValue={data?.GetUsuario.tipo_pessoa}
                    {...register('tipo_pessoa')}

                  />
                </div>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>
                  <span className='required'>Area de Atuação:</span>
                  <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title=''
                    data-bs-original-title='Phone number must be active'
                    aria-label='Phone number must be active'
                  ></i>
                </label>
                <div className='col-lg-2 fv-row fv-plugins-icon-container'>
                  <input
                    type='tel'
                    name='area_atuacao'
                    className='form-control form-control-lg form-control-solid'
                  // defaultValue={data?.GetUsuario.area_atuacao?.name}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Empresa do Usuario
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                      <GetEmpresasChosen valueEmpresa={empresaSelecionada} SetValueEmpresa={setEmpresaSelecionada} />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-4'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Cor Background:</span>
                    <i
                      className='fas fa-exclamation-circle ms-1 fs-7'
                      data-bs-toggle='tooltip'
                      title=''
                      data-bs-original-title='Phone number must be active'
                      aria-label='Phone number must be active'
                    ></i>
                  </label>
                </div>
                <div className='col-8'>
                  <div className='d-flex flex'>
                    <input
                      type='text'
                      maxLength={7}
                      name='cor_background'
                      className='form-control col-3 w-100px'
                      defaultValue={RgbToHex(color.r, color.g, color.b, color.a)}
                      onChange={(e) => {
                        if (e.target.value.length >= 7) {
                          let cor = HexToRgb(e.target.value)
                          if (cor) {
                            setColor({ r: cor[0], g: cor[1], b: cor[2], a: cor[3] })
                          }
                        }
                        setColorSelect(e.target.value)
                        setValue('cor_background', e.target.value)
                      }}
                    ></input>
                    <div style={styles.default.swatch} onClick={handleClick}>
                      <div style={styles.default.color} />
                    </div>
                  </div>
                  {/* {show ? <Block
                       color={color}
                        onChangeComplete={(color) => {
                          setColor(color.rgb)
                        }} 
                      />: ''} */}
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-4'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Cor Fonte:</span>
                    <i
                      className='fas fa-exclamation-circle ms-1 fs-7'
                      data-bs-toggle='tooltip'
                      title='Cor da fonte do sistema'
                    ></i>
                  </label>
                </div>
                <div className='col-8'>
                  <div className='d-flex flex'>
                    <input
                      type='text'
                      maxLength={7}
                      name='cor_fonte'
                      className='form-control col-3  w-100px'
                      defaultValue={RgbToHex(
                        colorFont.r,
                        colorFont.g,
                        colorFont.b,
                        colorFont.a
                      )}
                      onChange={(e) => {
                        if (e.target.value.length >= 7) {
                          let cor = HexToRgb(e.target.value)
                          if (cor) {
                            setColorFont({ r: cor[0], g: cor[1], b: cor[2], a: cor[3] })
                          }
                        }
                        setValue('cor_fonte', e.target.value)
                      }}
                    ></input>
                    <div style={styles.default.swatch} onClick={handleClick}>
                      <div style={styles.default.colorFont} />
                    </div>
                  </div>
                  {/* {show ? <Block
                       color={color}
                        onChangeComplete={(color) => {
                          setColor(color.rgb)
                        }} 
                      />: ''} */}
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {/* <button type='reset' className='btn btn-light btn-active-light-primary me-2'>
                    Descartar
                  </button> */}
              <button
                type='submit'
                className='btn btn-primary'
                id='kt_account_profile_details_submit'
              >
                Salvar mudanças
              </button>
            </div>
            <input type='hidden' />
            <div></div>
          </form>
        </div>
      </div>
    </div >
  )
}

