import {useContext, useEffect, useRef, useState} from 'react'
import {loadIsocrona} from '../Common/Isocrona'
import {AllGeoJSON, FeatureCollection} from '@turf/turf'
import {FilterContext} from '../../context/FilterContext'

import {encodeJsonIntersect, getLayer} from '../MapLayers/Layer'
import { Mapa1 } from './Mapa1'
import { Mapa2 } from './Mapa2'
import { Mapa3 } from './Mapa3'

export function Mapas({data, setMapImageExport}: {data: any; setMapImageExport: any}) {
  const {geometriaSelecionada, endereco} = useContext(FilterContext)
  const [isocronas, setIsocronas] = useState<FeatureCollection>(null)
  const [mapImage, setMapImage] = useState({
    map1: null,
    map2: null,
    map3: null,
  })


  useEffect(() => {
    loadIsocrona({lat: endereco.latitude, lng: endereco.longitude}, (data) => {
      const geojson: FeatureCollection = {
        type: 'FeatureCollection',
        features: data.features,
      }

      setIsocronas(geojson)
    })
  }, [endereco])

  useEffect(() => {
    if (mapImage.map1 && mapImage.map2 && mapImage.map3){
      setMapImageExport(mapImage)
    }
  }, [mapImage, setMapImageExport])


  const geojson = {
    type: 'FeatureCollection',
    features: [],
  }
  useEffect(() => {

      //eslint-disable-next-line array-callback-return
      data?.map((obj, i) => {
        if (obj.computed && !obj.removed) {
          geojson.features.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [obj.geom.coordinates[0], obj.geom.coordinates[1]],
            },
            properties: {}
          })
        }
      })
  },[data])

  
  function setMapaToExport1(map){
    mapImage.map1 = map
    setMapImage({...mapImage})
  }

  function setMapaToExport2(map){
    mapImage.map2 = map
    setMapImage({...mapImage})
  }

  function setMapaToExport3(map){
    mapImage.map3 = map
    setMapImage({...mapImage})
  }

  

  let json = geometriaSelecionada as AllGeoJSON
  const layerConfig = getLayer('view_ibge_explorer_renda_mensal_domiciliar')
  const jsonEncoded = encodeJsonIntersect(json)
  let intersect = `${layerConfig.config.tile}layer=${layerConfig.name}_operacao&l1=${layerConfig.name}&s=${layerConfig.schema}&l2=&g1=${jsonEncoded}`
  layerConfig.config.data = intersect

  if (!geometriaSelecionada){
    return <></>
  }

  return (
    <div style={{position: 'fixed', width: '1000', height: '850', left: '-1000px', top: 0}}>
      <Mapa1 data={data} setMapImageExport={setMapaToExport1} isocronas={isocronas} geometria={geometriaSelecionada.geometry} endereco={endereco} ></Mapa1>
      <Mapa2 data={data} setMapImageExport={setMapaToExport2} isocronas={isocronas} geometria={geometriaSelecionada.geometry} endereco={endereco}></Mapa2>
      <Mapa3 data={data} setMapImageExport={setMapaToExport3} isocronas={isocronas} geometria={geometriaSelecionada.geometry} endereco={endereco}></Mapa3>
    </div>
  )
}
