export {default as Pin} from '../../../../../../../../../img/icon-atlas.png'
export {default as Building} from '../../../../../../../../../img/icon-buildings.png'

export const ICON_MAPPING = {
  marker: {
    x: 0,
    y: 0,
    width: 128,
    height: 128,
    anchorY: 128,
    mask: true,
  },
  alert: {
    x: 128,
    y: 0,
    width: 128,
    height: 128,
    anchorY: 128,
    mask: true,
  },
  store: {
    x: 254,
    y: 0,
    width: 128,
    height: 128,
    anchorY: 128,
    mask: true,
  },
  build: {
    x: 372,
    y: 0,
    width: 128,
    height: 128,
    anchorY: 128,
    mask: true,
  },
  plane: {
    x: 498,
    y: 0,
    width: 128,
    height: 128,
    anchorY: 128,
    mask: true,
  },
  water: {
    x: 620,
    y: 0,
    width: 128,
    height: 128,
    anchorY: 128,
    mask: true,
  }
}
