export const RelatedPeoplePhones = ({ data, estrutura }) => {

  let dateReturn = null

  if (typeof data['Result'] !== 'undefined') {
    dateReturn = data['Result'][0]['RelatedPeoplePhones']
  } else {
    if (data.length > 0) {
      if (data[0]['RelatedPeoplePhones']) {
        dateReturn = data[0]['RelatedPeoplePhones']
      }
    }
  }


  return (
    <>
      <h3 className="pt-10">{estrutura.titulo}</h3>
      <table>
        <thead>
          <tr>
            {estrutura['colunsItens'].map((column, index) => {
              return (
                <th key={index} className='fs-bold'>
                  {column}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {dateReturn?.map((item, index) => {
            return (
              <tr key={index}>{estrutura['colunsItens'].map((column, index2) => {
                return <td key={index2}>{item[column]}</td>
              })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
