import {useState} from 'react'
import {ListItens} from './ListItens'

interface Props {
  itens: []
  latLng: google.maps.LatLng
}
export const InfraestruturaRiscos: React.FC<Props> = ({itens, latLng}) => {
  const [distancia, setDistancia] = useState(500)

  function onChangeDistancia(e) {
    setDistancia(Number(e.target.value))
  }

  return (
    <>
      <div className='row d-flex justify-content-between'>
        <h1 className='text-gray-800 fs-2 fw-bolder py-10 mx-1 m-0 col-5'>
          Infraestrutura e Riscos
        </h1>
        <div className='col-4 mt-6'>
          <div className='d-flex justify-content-between'>
            <label className='col-6 mt-3'>Distância</label>
            <select
              id='distance'
              className='form-control distance col-2 w-150px'
              onChange={onChangeDistancia}
            >
              <option value='500'>500 m</option>
              <option value='1000'>1 km</option>
              <option value='1500'>1.5 km</option>
              <option value='2000'>2 km</option>
            </select>
          </div>
        </div>
      </div>

      <div className='row'>
        {itens
          ? itens['categoria'].map(function (categoria, index) {
              return (
                <ListItens
                  key={'listItens_' + index}
                  latLng={latLng}
                  itens={categoria.servico.map((servico, index) => {
                    servico.distancia = distancia
                    return {
                      icone: 'i',
                      nome: servico.nome,
                      descricao: servico.descricao,
                      valor: '',
                      url: servico.url_api,
                      distancia: servico.distancia,
                    }
                  })}
                  titulo={categoria.nome}
                ></ListItens>
              )
            })
          : ''}
      </div>
    </>
  )
}
