import { gql } from "@apollo/client";

export const GET_IMOVEL_IMAGENS = gql` 
query GetImovelImagens($idImovel: Float!) {
  GetImovelImagens(idImovel: $idImovel) {
    id 
uuid 
id_terreno
id_album
caminho
situacao
caminho_thumb
titulo
id_usuario
nome_original
size
    }
  }
`