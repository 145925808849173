import { gql } from "@apollo/client";
// Busca de todos as Situações de terreno pelo ID do usuario
export const GET_EXPLORER_USUARIO_PROJETOS = gql`
query GetExplorerUserProject($all: Boolean,$pagination: Pagination) {
  GetExplorerUserProject(all: $all,pagination: $pagination) {
    id
    id_user
    json
    nome
    status
    data_cadastro
    descricao
    data_alteracao
  }
}
`

export const PUT_EXPLORER_USUARIO_PROJETOS = gql`
  mutation Mutation($explorerUserProject: explorerUserProject!) {
    PutExplorerUserProject(explorerUserProject: $explorerUserProject) {
      data_alteracao
      id
      id_user
    }
  }
`


export const SET_EXPLORER_USUARIO_PROJETOS = gql`
  mutation Mutation($explorerUserProject: explorerUserProject!) {
    SetExplorerUserProject(explorerUserProject: $explorerUserProject) {
      data_alteracao
      id
      id_user
    }
  }
`
