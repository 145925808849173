
import { InputHTMLAttributes, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { QueryGetEmpreendimentoTags } from '../../../../../../graphql/services/Tags'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  defaultValue: any
  register?: any
  error?: any
  onValueChange: any
}

const GetEmpreendimentoTagsCreatableSelect = ({
  defaultValue,
  register,
  onValueChange,
  error,
  ...props
}: InputProps) => {
  const [tags, setTags] = useState([])
  const [tagsSelected, setTagsSelected] = useState([])
  const { data } = QueryGetEmpreendimentoTags()

  useEffect(() => {
  
    if (data && tags.length === 0) {
      let tagTemp = []
      let tagSelectedTemp = []
      // eslint-disable-next-line array-callback-return
      data.GetEmpreendimentoTags.map((Options) => {
        tagTemp.push({ value: Options.id, label: Options.tag })
        if (defaultValue.indexOf(Options.tag) >= 0) {
          tagSelectedTemp.push({ value: Options.id, label: Options.tag })
        }
      })
      
      if (data.GetEmpreendimentoTags.length > 0){
        setTags(tagTemp)
        setTagsSelected(tagSelectedTemp)
        onValueChange(defaultValue.join(','))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defaultValue, onValueChange])

  useEffect(() => {
    let resultTags = tagsSelected.map((item) => {
      return item.label
    })
    onValueChange(resultTags.join())
  },[onValueChange, tagsSelected])

  if (!data) {
    return (
      <CreatableSelect
        options={[]}
        isMulti
        onChange={handleChange}
        isDisabled={true}
        placeholder='Tags do Empreendimento'
      />
    )
  }
  function handleChange(selectedOption) {
    setTagsSelected(selectedOption)
  }

  function handleAdd(option) {
    setTags([...tags, ...[{ value: '', label: option }]])
    setTagsSelected([...tagsSelected, ...[{ value: '', label: option }]])
    let resultTags = tagsSelected.map((item) => {
      return item.label
    })
    onValueChange(resultTags.join())
  }
 

  return (
    <CreatableSelect
      menuPortalTarget={document.body}
      // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      options={tags}
      isMulti
      value={tagsSelected}
      defaultValue={tagsSelected}
      onChange={handleChange}
      onCreateOption={handleAdd}
      placeholder='Tags do Empreendimento'
    />
  )
}

export { GetEmpreendimentoTagsCreatableSelect }
