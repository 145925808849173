import React from 'react'
import { Dicionario } from '../../../../../../utils/Dicionario'

type Props = {
  header: any
  body: []
}
export const RelatorioTable: React.FC<Props> = ({header, body}) => {
  var removeColunas = ['cor', 'gid', 'id', 'distancia', 'geom']
  return (
    <>
      <div className='table-responsive my-1'>
        <table className='table_vertical'>
          <thead>
            <tr>
              {
                // eslint-disable-next-line array-callback-return
                header.map(function (i,index) {
                  if (removeColunas.indexOf(i) < 0) {
                    return <th key={index}>{Dicionario.translate(i)}</th>
                  }
                })
              }
            </tr>
          </thead>
          <tbody>
            {body.map(function (i) {
              return (
                <tr>
                  {
                    // eslint-disable-next-line array-callback-return
                    Object.entries(i).map(function (j,index) {
                      if (removeColunas.indexOf(j[0]) < 0) {
                        return <td key={index} className='text-right'> {Dicionario.formatValue(j[0], j[1] )}</td>
                      }
                    })
                  }
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
