import { useState } from 'react';
import { DataMarket, Market } from '../../data';
import { getAllCitiesAndNeighborhoods } from '../../functions';
import { Button } from '../../../../components/UI/Buttons.tsx/Button';
import { BiGridAlt, BiListUl, BiMapPin } from 'react-icons/bi';
import { TabsNavigation } from './TabsNavigation';
import { Card } from './Cards';
import { CardHorizontal } from './Card-Horizontal';
import { FaMap, FaMapMarkedAlt, FaMapPin } from 'react-icons/fa';
import { useMarket } from '../../context/MarketContext';
import OffCanvas from './OffCanvas';

interface Props {
  handleMarketChange: (id: string, type: string, data: Market) => void;
  showMap: boolean;
  toggleShowMap: () => void;
}

export function MarketPlaces({ handleMarketChange, showMap, toggleShowMap }: Props) {
  const submarketsAll = getAllCitiesAndNeighborhoods();
  const [key, setKey] = useState(0);
  const {goToSubmarket} = useMarket()

  const resetComponent = () => {
    setKey(prevKey => prevKey + 1);
  };

  const [cardsView, setCardsView] = useState<'grid' | 'list'>('grid')

  const toggleView = () => {
    setCardsView(cardsView === 'grid' ? 'list' : 'grid');
  };

  return (
    <div className='row mx-0'>
      <div className="w-100 bg-white flex-column px-12 pt-4 ">
        <div className="w-100 pt-4 all-center-between">
          <div className="row pb-0 all-center-space">
            <h2 className="fs-3 text-black pb-0 m-0 mb-4 ">Overview: <span className="text-gray-500">{DataMarket.nome}</span></h2>
          </div>
          {!showMap && (
            <Button onClick={toggleShowMap} title='Ver mercado no mapa' buttonStyle='button-info' className='all-center d-sm-block d-lg-none' size={'lg'} >
              <FaMapMarkedAlt className='me-1' color={'#fff'} size={20} /> <span className='p-0'>Ver no mapa</span>
            </Button>
          )}

          <OffCanvas />
        </div>

        <TabsNavigation submarketsAll={submarketsAll}>
          <div className="w-100 all-end justify-content-sm-between">
            <div className='all-center form-control btn pointer me-2'>
              <select className='btn p-0' name="" id="">
                <option value="">Market Score</option>
                {/* Adicione outras opções aqui, se necessário */}
              </select>
            </div>
            <Button onClick={() => toggleView()} title={cardsView === 'grid' ? 'Lista' : 'Grade'} className='p-2 btn sm-d-none'>
              {cardsView === 'grid' ? <BiListUl color={'#69C05B'} size={30} /> : <BiGridAlt color={'69C05B'} size={28} />}
            </Button>
          </div>
        </TabsNavigation>
      </div>
      <div className="tab-content px-12 py-6" id="tabContent">
        <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
          <div className="row pt-6" style={{ overflow: 'auto', height: 'calc(80vh - 70px)' }}>
            {cardsView === 'grid' ? (
              DataMarket.markets.map((data) => {
                return (
                  <div key={data.id} className="col-lg-6 col-md-6 col-sm-6 mb-4">
                    <Card
                      title={data.nome}
                      image={data.image}
                      description={data.descricao}
                      onClick={() => {
                        goToSubmarket(data);
                        handleMarketChange(data.id, 'estado', data);
                      }}
                    />
                  </div>
                )
              })
            ) : (
              DataMarket.markets.map((data) => {
                return (
                  <div key={data.id} className="col-12 mb-4">
                    <CardHorizontal
                      title={data.nome}
                      image={data.image}
                      description={data.descricao}
                      onClick={() => {
                        handleMarketChange(data.id, 'estado', data)
                      }}
                    />
                  </div>
                )
              })
            )}
          </div>
        </div>
        <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
          <div className="row pt-6" style={{ overflow: 'auto', height: 'calc(80vh - 70px)' }}>
            {cardsView === 'grid' ? (
              submarketsAll.map((data) => (
                <div key={data.id} className="col-lg-6 col-md-6 col-sm-6  mb-4">
                  <Card
                    title={data.nome}
                    image={data.image}
                    description={data.descricao}
                    onClick={() => {
                      handleMarketChange(data.id, 'submarket', data)
                      resetComponent()
                    }}
                  />
                </div>
              ))
            ) : (
              submarketsAll.map((data) => {
                return (
                  <div key={data.id} className="col-12 mb-4">
                    <CardHorizontal
                      title={data.nome}
                      image={data.image}
                      description={data.descricao}
                      onClick={() => {
                        handleMarketChange(data.id, 'submarket', data)
                      }}
                    />
                  </div>
                )
              })
            )}
          </div>
        </div>
      </div>
    </div>
  )
}