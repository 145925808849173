import React, {useEffect} from 'react'
import {Redirect, Switch} from 'react-router-dom'

export function Logout() {
  useEffect(() => {
    localStorage.clear()
    document.location.reload()
  },)

  return (
    <Switch>
      <Redirect to='/' />
    </Switch>
  )
}
