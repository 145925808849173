import {gql} from '@apollo/client'

export const GET_PROPRIETARIOS = gql`
  query GetProprietarios($filtro: filter, $pagination: Pagination) {
    GetProprietarios(filtro: $filtro, pagination: $pagination) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        cep
        cidade
        nome
        situacao
        telefone
        id_usuario_cadastro
        id_usuario_alteracao
        id_terreno_proprietario
        id_estado
        id_cidade
        id
        endereco
        email
        data_cadastro
        data_alteracao
        cpf
        complemento
        cnpj
        empresa
        estado
        numero
        basic_data
        occupation_data
        emails_extended
        related_people_emails
        addresses_extended
        financial_data
        related_people
        profession_data
        phones_extended
        related_people_phones
        ph3a_data
      }
    }
  }
`

export const GET_PROPRIETARIO_BY_ID = gql`
  query GetProprietario($data: proprietario!) {
    GetProprietario(data: $data) {
      id_usuario_cadastro
      id_usuario_alteracao
      cep
      cidade
      nome
      situacao
      telefone
      id
      id_terreno_proprietario
      endereco
      email
      data_cadastro
      data_alteracao
      id_estado
      id_cidade
      cpf
      complemento
      cnpj
      empresa
      estado
      numero
      basic_data
      occupation_data
      emails_extended
      related_people_emails
      addresses_extended
      financial_data
      related_people
      profession_data
      phones_extended
      related_people_phones
      ph3a_data
    }
  }
`
