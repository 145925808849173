import { useState } from 'react'
import { QueryGetEmpreendimentoObservacoes } from '../../../../../graphql/services/Observacoes'
import { EditForm } from './components/EditForm'
import { CreateForm } from './components/CreateForm'
import { Buttons } from './components/Buttons'
import { DateTimToView } from '../../../../../components/util'
import { EmpreendimentoResponse } from '../../../../../graphql/type'
import { Loader } from '../../../../../components/UI/Loader/Loader'

type Props = {
  data: EmpreendimentoResponse
  param: string
}

export const TabObservacoes: React.FC<Props> = ({ data, param }) => {
  const id_empreendimento = data.GetEmpreendimento.id
  const { data: obs, loading } = QueryGetEmpreendimentoObservacoes({
    variables: {
      pagination: {
        quantidade: null,
        pagina: null,
      },
      idEmpreendimento: data.GetEmpreendimento.id,
    },
  })

  const [showCreate, setShowCreate] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [id, setId] = useState(null)

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }
  function CloseModalEdit() {
    setShowEdit(false)
    setId(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setId(id)
  }

  if (!data || loading) {
    return <Loader text={'caregando...'}></Loader>
  }

  return (
    <div
      className={`tab-pane fade ${param === 'observacoes' ? ' show active' : ''}`}
      id='observacoes'
      role='tabpanel'
      aria-labelledby='observacoes'
    >
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Observações</h3>
          <div className='card-toolbar'>
            <button
              className='btn btnSuccess btn-round'
              onClick={(e) => {
                e.preventDefault()
                OpenModalCreate(null)
              }}
              style={{ color: 'white' }}
            >
              {/*begin::Svg Icon | path: icons/duotune/general/gen005.svg*/}
              <span className='svg-icon btn-icon svg-icon-4 ms-2'>
                +<span className='pd-left-1'>Adicionar Observação</span>
              </span>
              {/*end::Svg Icon*/}
            </button>
          </div>
        </div>
        <div className='card-body p-0 pt-5'>
          <div className='table-responsive'>
            <table className='table table-striped gs-7'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-50px'>Titulo</th>
                  <th className=''>Observações</th>
                  <th className='min-w-100px '>Data de Criação</th>
                  <th className='min-w-100px text-end'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {obs
                  ? obs.GetEmpreendimentosObservacao.result.map((Response) => {
                    return (
                      <tr key={Response.id}>
                        <td>{Response.titulo}</td>
                        <td>{Response.descricao}</td>
                        <td>{DateTimToView(Response.data_cadastro)}</td>
                        <td>
                          <Buttons setIdEdit={OpenModalEdit} id={Response.id} />
                        </td>
                      </tr>
                    )
                  })
                  : ''}
              </tbody>
            </table>
          </div>
        </div>
        {showEdit ? (
          <EditForm
            id={id}
            id_empreendimento={id_empreendimento}
            show={showEdit}
            closeModal={CloseModalEdit}
          ></EditForm>
        ) : (
          <></>
        )}
        {showCreate ? (
          <CreateForm
            id_empreendimento={id_empreendimento}
            closeModal={CloseModalCreate}
            show={showCreate}
          ></CreateForm>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
