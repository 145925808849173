import {ButtonsAction} from './ButtonsAction'
import {CurrencyFormat} from '../../../../components/util'
import {ADMINQueryGetProdutos} from '../../../graphql/services/Produtos'
import {useState} from 'react'
import {ModalSetProduto} from './ModalSetProduto'
import PaginationComponent from '../../../../components/UI/PaginationComponents'
import {CardTables} from '../../../../components/UI/CardTables'
import LoadingTable from '../../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../../components/UI/CardTables/ErrorTable'

type Props = {
  filtro: any[]
}

export function Table({filtro}: Props) {
  const [pagiancao, setPaginacao] = useState({pagina: 0, quantidade: 10})

  const {data, loading, error} = ADMINQueryGetProdutos({
    variables: {
      pagination: {
        pagina: pagiancao.pagina,
        quantidade: pagiancao.quantidade,
      },
      filtro: {
        fields: filtro,
      },
    },
  })

  return (
    <>
      <CardTables
        headerTitle={'Produtos'}
        headerSubtTitle={`Listando ${
          data ? data.GetProdutos.pageInfo.totalItems : 0
        } produtos registrados.`}
        headerToolbar={
          <div className='all-end'>
            <ModalSetProduto />
          </div>
        }
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetProdutos.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({pagina: pagina, quantidade: quantidade})
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-50px'># ID</th>
                <th className='min-w-150px'>Nome</th>
                <th className='min-w-100px'>Tipo do Produto</th>
                <th className='text-center'>Valor</th>
                <th className='text-center'>Desconto</th>
                <th className='min-w-100px text-end'>Ação</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetProdutos.result.length > 0 ? (
                  data.GetProdutos.result.map((response) => {
                    return (
                      <tr key={response.id}>
                        <td>{response.id}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {response.nome}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          {response.tipo_produto != null ? response.tipo_produto : 'Não informado'}
                        </td>
                        <td>
                          {response.valor != null
                            ? CurrencyFormat(response.valor)
                            : 'Não informado'}
                        </td>
                        <td>
                          {response.desconto != null ? `${response.desconto}%` : 'Não informado'}
                        </td>
                        <td>
                          <ButtonsAction id={response.id} />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
    </>
  )
}
