import { gql } from "@apollo/client";

export const GET_EXPLORER_TAGS = gql`
query GetExplorerTags {
  GetExplorerTags {
    id
    tag
    descricao
  }
}
`