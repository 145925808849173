/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import { KTSVG } from '../../../../../../../_metronic/helpers';

interface Props {
  setFilter: React.Dispatch<React.SetStateAction<any[]>>;
}

export function DropdownFilters({ setFilter }: Props) {
  const formRef = useRef<HTMLFormElement>(null);

  function addFilter(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    console.log('Disparou a função');
    const data = new FormData(e.target as HTMLFormElement);
    const filterArray = [];

    // Data Inicial e Data Final
    const initialDate = data.get('data_inicio');
    const finalDate = data.get('data_fim');

    if (initialDate || finalDate) {
      filterArray.push({
        field: ['price_date'],
        operator: 'DATE',
        value: [
          {
            initial_date: initialDate || '',
            final_date: finalDate || '',
          },
        ],
      });
    }

    // Atualizar o estado do filtro
    setFilter([...filterArray]);

    // Limpar os campos do formulário
    if (formRef.current) {
      formRef.current.reset();
    }

    return false;
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-sm me-4 btn-icon btn-color-primary btn-active-light-primary'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='top-end'
        data-kt-menu-flip='top-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filtros</div>
        </div>

        <div className='separator border-gray-200'></div>
        <form ref={formRef} onSubmit={addFilter}>
          <div className='px-7 py-5'>
            <div className='gap-2'>
              <div className="form-group">
                <label className='col-form-label'>Data Inicial</label>
                <input name='data_inicio' type="date" className='form-control form-control-solid' />
              </div>
              <div className="form-group">
                <label className='col-form-label'>Data Final</label>
                <input name='data_fim' type="date" className='form-control form-control-solid' />
              </div>
            </div>

            <div className='d-flex mt-6 justify-content-end'>
              <button
                type='reset'
                className='btn btn-sm btn-warning btn-active-light-primary me-2'
                data-kt-menu-dismiss='true'
                onClick={() => setFilter([])}
              >
                Limpar
              </button>

              <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                Aplicar
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
