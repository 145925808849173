import { Link } from 'react-router-dom';
import { QueryGetPacotes } from '../../../graphql';
import { Buttons } from './ButtonsPacotes'
import { BiAddToQueue } from 'react-icons/bi';
import { useState } from 'react';
import PaginationComponent from '../../../../components/UI/PaginationComponents';
import { CardTables } from '../../../../components/UI/CardTables';
import LoadingTable from '../../../../components/UI/CardTables/LoadingTable';
import ErrorTable from '../../../../components/UI/CardTables/ErrorTable';


export function TableAdminPacotes() {
    const [pagiancao, setPaginacao] = useState({ pagina: 0, quantidade: 10 })

    const { data, loading, error } = QueryGetPacotes({
        variables: {
            pagination: {
                pagina: pagiancao.pagina,
                quantidade: pagiancao.quantidade
            }
        }
    });


    return (
        <>
            <CardTables
                headerTitle={'Pacotes'}
                headerSubtTitle={`Listando ${data ? data.GetPacotes.pageInfo.totalItems : 0} pacotes registrados.`}
                headerToolbar={
                    <Link
                        to='/backoffice/pacotes/create'
                        className="btn btn-sm btn-light-primary"
                    >
                        <BiAddToQueue size={20} className="fs-2 me-2" />
                        Adicionar Pacote
                    </Link>
                }
                footer={
                    data ? (
                        <PaginationComponent
                            pagesInfo={data.GetPacotes.pageInfo}
                            setPagesInfo={(pagina: number, quantidade: number) => {
                                setPaginacao({ pagina: pagina, quantidade: quantidade })
                            }}
                        ></PaginationComponent>
                    ) : (
                        <></>
                    )
                }
            >
                <div className='table-responsive  '>
                    <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className='min-w-100px'>Nome</th>
                                <th className='min-w-140px'>Produtos</th>
                                <th className='min-w-120px'>Situação</th>
                                <th className='min-w-100px text-end'>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <LoadingTable loading={loading} />
                            <ErrorTable error={error} />
                            {data ? (
                                data.GetPacotes.result.length > 0 ? (
                                    data.GetPacotes.result.map((response) => {
                                        return (
                                            <tr key={response.id}>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='d-flex justify-content-start flex-column'>
                                                            <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                                {response.nome}
                                                            </span>

                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{response.produtos}</td>
                                                <td>
                                                    <span className={`badge ${response.situacao === 0 ? 'badge-light-danger' : 'badge-light-primary'}`}>
                                                        {response.situacao === 0 ? 'Inativo' : 'Ativo'}
                                                    </span>
                                                </td>
                                                <td>
                                                    <Buttons id={response.id} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={10} className='text-center'>
                                            Nenhum registro encontrado
                                        </td>
                                    </tr>
                                )
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>
                </div>
            </CardTables >

        </>
    )
}

