import secureLocalStorage from 'react-secure-storage';
export function TabPayments() {

  const produtos: any  = secureLocalStorage.getItem('produtos')

  return (
    <div className="tab-pane fade" id="tab-payments" role="tabpanel" aria-labelledby="tab-payments-tab">
      <div className="card mb-5 mb-xl-10">
        <div className="card-header card-header-stretch pb-0">

          <div className="card-toolbar m-0">
            <ul className="nav nav-stretch nav-line-tabs border-transparent" role="tablist">
              <li className="nav-item" role="presentation">
                <a id="kt_billing_paypal_tab" className="nav-link fs-5 fw-bolder active" data-bs-toggle="tab" role="tab" href="#kt_billing_paypal">Produtos</a>
              </li>
              {/* <li className="nav-item" role="presentation">
                <a id="kt_billing_creditcard_tab" className="nav-link fs-5 fw-bolder me-5" data-bs-toggle="tab" role="tab" href="#kt_billing_creditcard">Cartão de Crédito / Débito</a>
              </li> */}
            </ul>
          </div>
        </div>
        <div id="kt_billing_payment_tab_content" className="card-body tab-content">
          <div id="kt_billing_creditcard" className="tab-pane fade" role="tabpanel">
            <h3 className="mb-5">Meus cartões</h3>
            <div className="row gx-9 gy-6">
              <div className="col-xl-6">
                <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
                  <div className="d-flex flex-column py-2">
                    <div className="d-flex align-items-center fs-4 fw-bolder mb-5">Marcus Morris
                      <span className="badge badge-light-success fs-7 ms-2">Primário</span></div>
                    <div className="d-flex align-items-center">
                      <img src="assets/media/svg/card-logos/visa.svg" alt="" className="me-4" />
                      <div>
                        <div className="fs-4 fw-bolder">Visto **** 1679</div>
                        <div className="fs-6 fw-bold text-gray-400">O cartão expira em 24/09</div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2">
                    <button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-3">Excluir</button>
                    <button className="btn btn-sm btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Editar</button>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
                  <div className="d-flex flex-column py-2">
                    <div className="d-flex align-items-center fs-4 fw-bolder mb-5">Detentor de Jacob</div>
                    <div className="d-flex align-items-center">
                      <img src="assets/media/svg/card-logos/american-express.svg" alt="" className="me-4" />
                      <div>
                        <div className="fs-4 fw-bolder">Mastercard **** 2040</div>
                        <div className="fs-6 fw-bold text-gray-400">O cartão expira em 22/10</div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2">
                    <button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-3">Excluir</button>
                    <button className="btn btn-sm btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Editar</button>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
                  <div className="d-flex flex-column py-2">
                    <div className="d-flex align-items-center fs-4 fw-bolder mb-5">Jhon Larson</div>
                    <div className="d-flex align-items-center">
                      <img src="assets/media/svg/card-logos/mastercard.svg" alt="" className="me-4" />
                      <div>
                        <div className="fs-4 fw-bolder">Mastercard **** 1290</div>
                        <div className="fs-6 fw-bold text-gray-400">Cartão expira em 23/03</div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2">
                    <button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-3">Excluir</button>
                    <button className="btn btn-sm btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Editar</button>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6">
                  <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                    <div className="mb-3 mb-md-0 fw-bold">
                      <h4 className="text-gray-900 fw-bolder">Nota importante!</h4>
                      <div className="fs-6 text-gray-700 pe-7">Leia atentamente
                        <a href="#vazio" className="fw-bolder me-1">os Termos Metronic</a> adicionando seu novo cartão de pagamento</div>
                    </div>
                    <a href="#vazio" className="btn btn-primary px-6 align-self-center text-nowrap" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Adicionar cartão</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="kt_billing_paypal" className="tab-pane fade  show active" role="tabpanel" aria-labelledby="kt_billing_paypal_tab">
            {!produtos ? (
              <>
                <h3 className="mb-5">Produto Ativo:</h3>
                produtos
              </>
            ) : (
              <>
                <h2>Nenhum plano ativo.</h2>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}