import React, {useState} from 'react'
import {Button} from 'react-bootstrap'
import {ModalCamadas} from './ModalCamadas'
import {BiLayerPlus} from 'react-icons/bi'
import {MutationSetLogAcessoRecurso} from '../../../../../../graphql/services/LogAcesso'

export const ModalAdicionarCamada: React.FC = () => {
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const [show, setShow] = useState(false)
  const [showActiveTag, setShowActiveTag] = useState('minhas-camadas')

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = (tab:string) => {
    setShow(true)
    setShowActiveTag(tab)
    FormSetLogAcessoRecurso({
      ferramenta: 'explorer',
      funcionalidade: 'btn-'+tab,
      action: 'click',
      url: `/explorer`,
    })

  }

  return (
    <>
      <div className='w-100'>
        <div className='mt-3 mb-5 ps-5 pe-5'>
          <div className=' d-flex justify-content-between'>
            <Button
              onClick={() => {
                handleShow('camadas-urbit')

               
              }}
              className=' w-100 nav-link btn btn-light  '
            >
              <BiLayerPlus className='fs-2 me-2'></BiLayerPlus>
              Adicionar Camada
            </Button>
            <Button
              onClick={() => {
                handleShow('minhas-camadas')
               
              }}
              className='w-100  nav-link btn btn-info ms-2 '
            >
              <BiLayerPlus className='fs-2 me-2'></BiLayerPlus>
              Meus Locais
            </Button>
            <ModalCamadas
              handleClose={handleClose}
              show={show}
              sublayer={false}
              activeTab={showActiveTag}
            ></ModalCamadas>
          </div>
        </div>
      </div>
    </>
  )
}
