import {useContext, useEffect, useMemo, useState} from 'react'
import {DataContext} from '../../../context/DataContext'
import {FilterContext} from '../../../context/FilterContext'
import {Dicionario} from '../../../../../utils/Dicionario'
import {formatValores} from '../../Common/common'
import {ImoveisMercadoSecundario} from '../../Types'
import {BarChart} from './BarChart'
import {formatNumber} from '../../../../../components/util'

type Props = {
  label?: string
  itens: ImoveisMercadoSecundario[]
}

export default function FaixaTicket({label, itens}: Props) {
  const {segmentacao, setCacheResult, cacheResult} = useContext(DataContext)
  const {filter} = useContext(FilterContext)
  const [selectedItem, setSelectedItem] = useState(null)
  let {chartDataVSOMetragem, chartDataVSOMetragemTable} = useMemo(() => {
    let ticketMedio = Array(7).fill(0, 0, 7)
    let precoMedioM2 = Array(7).fill(0, 0, 7)
    let totalUnidades = Array(7).fill(0, 0, 7)
    let chartDataVSOMetragemTable = []

    let chartDataVSOMetragem = [
      {
        name: 'Qtde',
        data: [],
      },
    ]
    if (itens && filter.segmentacaoPreco !== null) {
      // eslint-disable-next-line array-callback-return
      itens.map((item) => {
        segmentacao.area[filter.segmentacaoArea].forEach((faixas, i) => {
          if (i === 6) {
            if (item.area >= faixas[0]) {
              ticketMedio[i] = ticketMedio[i] + Number(item.valor)
              precoMedioM2[i] = precoMedioM2[i] + Number(item.valor) / Number(item.area)
              totalUnidades[i] = totalUnidades[i] + 1
            }
          } else if (item.area >= faixas[0] && item.area < faixas[1]) {
            ticketMedio[i] = ticketMedio[i] + Number(item.valor)
            precoMedioM2[i] = precoMedioM2[i] + Number(item.valor) / Number(item.area)
            totalUnidades[i] = totalUnidades[i] + 1
          }
        })
      })

      // eslint-disable-next-line array-callback-return
      segmentacao.area[filter.segmentacaoArea].map((data, index) => {
        const id = `metragem_${index}`

        let categoria = ''
        if (data[1] == null) {
          categoria = 'acima de ' + data[0]
        } else if (data[0] === 0) {
          categoria = 'abaixo de ' + data[1] + 'm²'
        } else {
          categoria = 'de ' + data[0] + 'm² até ' + data[1] + 'm²'
        }

        chartDataVSOMetragemTable.push({
          preco: categoria,
          ticketMedio: ticketMedio[index]/totalUnidades[index],
          precoMedioM2: precoMedioM2[index]/totalUnidades[index],
          totalUnidades: totalUnidades[index],
          id: id,
        })

        chartDataVSOMetragem[0].data.push({
          x: categoria,
          y: totalUnidades[index],
          total_unidades: totalUnidades[index],
          id: id,
        })
      })
    }
    return {chartDataVSOMetragem, chartDataVSOMetragemTable}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens, filter.segmentacaoArea, segmentacao.preco])

  useEffect(() => {
    cacheResult.performanceMercadoRevenda.faixaMetragem = {
      chartData: chartDataVSOMetragem,
      tableData: chartDataVSOMetragemTable,
    }
    setCacheResult(cacheResult)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDataVSOMetragem])

  if (!itens) {
    return <>Carregando..</>
  }
  if (filter.segmentacaoPreco === null || !filter.segmentacaoPreco === null) {
    return <>Carregando..</>
  }

  return (
    <BarChart
      className={'primary'}
      title={'Total de unidades por faixa de área privativa'}
      subtitle={''}
      data={chartDataVSOMetragem}
      onChange={(id: number) => {
        setSelectedItem(id)
      } } legenda={['Total Unidades']}    >
      <table className='table  table-row-dashed table-row-gray-300 table-hover'>
        <thead>
          <tr className='text-dark fw-bold'>
            {chartDataVSOMetragemTable && chartDataVSOMetragemTable.length > 0
              ? Object.entries(chartDataVSOMetragemTable[0]).map((item, index) => {
                  return item[0] !== 'id' ? (
                    <th key={index} className='text-center'>
                      {Dicionario.translate(item[0])}
                    </th>
                  ) : null
                })
              : null}
          </tr>
        </thead>
        <tbody>
          {chartDataVSOMetragemTable
            ? chartDataVSOMetragemTable.map((item, index) => {
                return (
                  <tr key={index} className={item.id === selectedItem ? 'select-data-table' : ''}>
                    <td className='text-center'>{item.preco}</td>
                    <td className='text-center'>
                      {formatNumber(item.ticketMedio, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>
                      {formatNumber(item.precoMedioM2, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>{item.totalUnidades}</td>
                  </tr>
                )
              })
            : null}
        </tbody>
      </table>
    </BarChart>
  )
}
