import React, {useEffect, useRef, useState} from 'react'
import DroplistActions from './DroplistActions'
import {LayerFormat} from '../../../Map/Layer/Layers/LayerFormat'
import {Explorer} from '../../../Map/Explorer'
import secureLocalStorage from 'react-secure-storage'
import {useMapaConfig} from '../../../../../../../hooks/useMapaConfig'
import {BiGridVertical, BiLayer, BiLowVision, BiPin, BiSelection} from 'react-icons/bi'
import {Draggable} from 'react-beautiful-dnd'

type Props = {
  uuid: string
  label: string
  index: number
  sub: boolean
  type: string
  visivel: boolean
  listId: number
}

export const Item: React.FC<Props> = ({
  uuid,
  label,
  index,
  listId,
  sub = false,
  type,
  visivel = true,
}) => {
  const [visible, setVisible] = useState(true)
  const {onMapaConfig} = useMapaConfig()

  useEffect(() => {
    setVisible(visivel)
  }, [visivel])

  const onClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let itens = document.querySelectorAll('.item-list')

    for (var i in itens) {
      if (typeof itens[i].classList != 'undefined') {
        itens[i].classList.remove('active')
      }
    }

    e.currentTarget.classList.add('active')

    Explorer.goToMapItem(uuid)
    secureLocalStorage.setItem('lActive', uuid)
    onMapaConfig({legendaLayerSelected: uuid})
  }

  const onClickCollapse = (e, uuid) => {
    let itensCollapse = document.querySelectorAll('.item-list .collapse')

    for (var i2 in itensCollapse) {
      if (typeof itensCollapse[i2].classList != 'undefined') {
        itensCollapse[i2].classList.remove('show')
      }
    }
  }

  const onClickAlteraVisibilidadeLayer = (e, uuid) => {
    e.preventDefault()
    if (e.currentTarget.classList.contains('active')) {
      e.currentTarget.classList.remove('active')
      setVisible(false)
      Explorer.changeVisibility(uuid, false)
    } else {
      setVisible(true)
      e.currentTarget.classList.add('active')
      Explorer.changeVisibility(uuid, true)
    }
  }

  const getLayerIcon = (type) => {
    switch (type) {
      case 'LayerIcon':
        return <BiPin className='fs-5'></BiPin>
      case 'LayerPolygon':
        return <BiSelection className='fs-5'></BiSelection>
      default:
        return <BiLayer className='fs-5'></BiLayer>
    }
  }

  return (
    <Draggable key={index} draggableId={'item-' + index} index={index}>
      {(provided) => (
        <li
          key={index}
          data-id={index}
          onClick={onClick}
          className='rstm-tree-item rstm-tree-item-level1 item-list py-1 px-2 pt-3'
        >
          <div
            key={'listdiv' + uuid + '_' + index}
            className={sub ? 'p-1 ms-10  me-2' : 'p-1  me-2 '}
          >
            <div
              style={{width: 20, height: 20}}
              data-id={index}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className='d-flex align-items-start p5'
            >
              <BiGridVertical className='fs-1'></BiGridVertical>

              <div className='rstm-toggle-icon pe-1'>
                <div role='img' aria-label='Toggle' className='rstm-toggle-icon-symbol'>
                  {getLayerIcon(type)}
                </div>
              </div>
              <span
                className={'layer-name pt-1 '}
                data-id={uuid}
                data-bs-toggle='collapse'
                data-bs-target={`#collapse${uuid + '_' + index}`}
                aria-expanded='false'
                aria-controls={`collapse${uuid + '_' + index}`}
                onClick={(e) => {
                  onClickCollapse(e, uuid)
                }}
              >
                {label}
              </span>
              <div className='rstm-toggle-icon-action'>
                <DroplistActions dataId={uuid}></DroplistActions>
              </div>
              <div
                className='rstm-toggle-icon-action-eye active'
                onClick={(e) => {
                  onClickAlteraVisibilidadeLayer(e, uuid)
                }}
              >
                {visible ? (
                  <i className='fs-5 bi bi-eye'></i>
                ) : (
                  <BiLowVision className='fs-3'></BiLowVision>
                )}
              </div>
            </div>
          </div>
          <div className='collapse' id={`collapse${uuid + '_' + index}`}>
            <LayerFormat id={uuid}></LayerFormat>
          </div>
        </li>
      )}
    </Draggable>
  )
}
