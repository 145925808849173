import {useRef} from 'react'
import {MapboxLayer} from './MapBox/MapBox'
import html2canvas from 'html2canvas'
import {Endereco} from '../Types'

export function Mapa2({
  data,
  setMapImageExport,
  isocronas,
  geometria,
  endereco,
}: {
  data: any
  setMapImageExport: any
  isocronas: any
  geometria: any
  endereco: Endereco
}) {
  const refLegenda = useRef<HTMLDivElement>(null)

  // let json = geometria as AllGeoJSON
  // const layerConfig = getLayer('view_ibge_explorer_renda_mensal_domiciliar')
  // const jsonEncoded = encodeJsonIntersect(json)
  // let intersect = `${layerConfig.config.tile}layer=${layerConfig.name}_operacao&l1=${layerConfig.name}&s=${layerConfig.schema}&l2=&g1=${jsonEncoded}`
  // layerConfig.config.data = intersect

  async function onload(map) {
    html2canvas(map.target.getCanvasContainer(), {
      useCORS: true,
      scale: 1,
      width: 700,
      height: 562,
    }).then((canvas) => {
      let canvasImage = canvas.toDataURL('image/png', 1.0)
      setMapImageExport(canvasImage)
    })
  }

  const geojson = {
    type: 'FeatureCollection',
    features: [],
  }

  data?.sort(function (a, b) {
    return a.distancia - b.distancia
  })

  console.log(data)

  let count = 1
  //eslint-disable-next-line array-callback-return
  data?.map((obj, i) => {
    if (obj.computed && !obj.removed) {
      if (count <= 15) {
        geojson.features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [obj.geom.coordinates[0], obj.geom.coordinates[1]],
          },
          properties: {
            id: i + 1,
            distance: obj.distance,
          },
        })
      }
      count++
    }
  })
  return (
    <MapboxLayer
      latLng={[endereco.latitude, endereco.longitude]}
      zoom={15}
      onLoad={onload}
      style={{width: 700, height: 562}}
      layers={[
        {
          data: isocronas,
          paint: {
            'line-color': [
              'case',
              ['==', ['get', 'value'], 300],
              '#3e6b2e',
              ['==', ['get', 'value'], 600],
              '#f10808',
              '#f10808',
            ],
            'line-width': 2,
            'line-dasharray': [3, 5],
          },
          paintText: {
            'text-color': '#000000', // Cor do texto
          },
          type: 'geojson',
          layout: {},
          typeLayer: 'line',
        },
        {
          data: geojson,
          paint: {
            // 'circle-color': '#ff0000',
            // 'circle-radius': 5,
            // 'circle-stroke-width': 2,
            // 'circle-stroke-color': '#ffffff',

            'text-color': '#000000',
            'text-halo-color': '#ffffff',
            'text-halo-width': 1,
            // 'text-anchor': 'top',
            // 'text-allow-overlap': true,
            // 'icon-allow-overlap': true,
            // 'text-ignore-placement': true,

            // 'line-color': [
            //   "case",
            //   ["==", ["get", "value"], 300], "#3e6b2e",
            //   ["==", ["get", "value"], 600], "#f10808",
            //   "#f10808"
            // ],
            // 'line-width': 2,
            // 'line-dasharray': [3, 5],
          },
          paintText: {},
          type: 'geojson',
          layout: {
            'text-field': ['get', 'id'],
            'text-size': 14,
            'text-offset': [0, 0.1],
            'text-anchor': 'top',
            'text-allow-overlap': true,
            'icon-allow-overlap': true,
            'text-ignore-placement': true,
          },
          typeLayer: 'symbol',
        },
      ]}
      inputRefLeganda={refLegenda}
      areaInfluencia={geometria}
    ></MapboxLayer>
  )
}
