import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Error404: FC = () => {
  return (
    <>
      <img
        alt='404 Error'
        src={toAbsoluteUrl('/media/illustrations/sketchy-1/18-dark.png')}
        className='h-80px mb-5'
      />
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Página não Encontrada.</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
      A página que você procurou não foi encontrada! <br /> Volte para a tela inicial com botão abaixo
      </div>
    </>
  )
}

export { Error404 }
