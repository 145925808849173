/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../../_metronic/partials'
import {getCSS, getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {formatNumber} from '../../../explorer/components/common'
// import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
// import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  title: string
  subtitle: string
  data: number[]
  onChange: any
}

const BoxPlotChart: React.FC<Props> = ({title, subtitle, data, className, onChange, children}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(data, height, onChange))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data,chartRef, mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{subtitle}</span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          {/* <a
            className='btn btn-sm btn-color-muted btn-active btn-active-success active px-4 me-1'
            id='kt_charts_widget_5_year_btn'
          >
            Year
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-success px-4 me-1'
            id='kt_charts_widget_5_month_btn'
          >
            Month
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-success px-4'
            id='kt_charts_widget_5_week_btn'
          >
            Week
          </a> */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_5_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
        <div className='table-responsive'>{children}</div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {BoxPlotChart}

function getChartOptions(data, height: number, onChange: any): ApexOptions {
  return {
    series: [
      {
        data: data,
      },
    ],
    chart: {
      type: 'boxPlot',
      height: 350,
      events: {
        dataPointMouseEnter: function (event, chartContext, config) {
          let data = config.w.config.series[0].data[config.dataPointIndex]
          onChange(data.id)
        },
      },
    },
    tooltip: {
      theme: 'dark',

      custom: function ({series, seriesIndex, dataPointIndex, w}) {
        const values = w.config.series[seriesIndex].data[dataPointIndex].y
        return (
          '<div class="apexcharts-tooltip-box">' +
          '<div>Min: ' +
          formatNumber(values[0], 'currency') +
          '/m²</div>' +
          '<div>Q1: ' +
          formatNumber(values[1], 'currency') +
          '/m²</div>' +
          '<div>Median: ' +
          formatNumber(values[2], 'currency') +
          '/m²</div>' +
          '<div>Q3: ' +
          formatNumber(values[3], 'currency') +
          '/m²</div>' +
          '<div>Max: ' +
          formatNumber(values[4], 'currency') +
          '/m²</div>' +
          '</div>'
        )
      },
    },
    xaxis: {
      title: {
        text: 'Preço m²',
        // rotate:90
      },
      labels: {
        formatter: function (value) {
          return formatNumber(value, 'currency') // formatar os valores do eixo Y, se necessário
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
      },
      boxPlot: {
        colors: {
          upper: '#e9ecef',
          lower: '#f8f9fa',
        },
      },
    },
    stroke: {
      colors: ['#6c757d'],
    },
  }
}
