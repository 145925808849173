export function HexToRgb(hex: any) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), 200]
    : null
}

function _componentToHex(c) {
  var hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export function RgbToHex(r, g, b, a) {
  return '#' + _componentToHex(r) + _componentToHex(g) + _componentToHex(b)
}

export function RgbCSSToHex(rgbCSS) {
  if (rgbCSS) {
    let rgb = rgbCSS
      .replace('rgba', '')
      .replace('rgb', '')
      .replace('(', '')
      .replace(')', '')
      .split(',')
    if (rgb.length === 4) {
      return (
        '#' +
        _componentToHex(parseInt(rgb[0].trim())) +
        _componentToHex(parseInt(rgb[1].trim())) +
        _componentToHex(parseInt(rgb[2].trim() + _componentToHex(parseInt(rgb[3].trim()))))
      )
    } else {
      return (
        '#' +
        _componentToHex(parseInt(rgb[0].trim())) +
        _componentToHex(parseInt(rgb[1].trim())) +
        _componentToHex(parseInt(rgb[2].trim()))
      )
    }
  }
}

export function ConvertMapboxColorToKepler(colorObject: []) {
  let color = []
  let range = []
  colorObject.forEach((element, index) => {
    if (index >= 3) {
      if (isNaN(element)) {
        color.push(HexToRgb(element))
      } else if (typeof element == 'object') {
      } else {
        range.push(element)
      }
    }
  })

  return {color, range}
}

export function formatDistancia(distancia) {
  if (distancia > 999) {
    return (distancia / 1000).toFixed(2) + ' km'
  } else {
    return distancia + 'm'
  }
}

function GetColor(series, properties, type = 'fill-color') {
  // let cores = {cor:{},opacity:{}}
  let campoFiltro = null

  if (typeof series[type] !== 'undefined') {
    if (series[type][0] === 'get') {
      campoFiltro = series[type][1]
      // console.log(campoFiltro)
      // console.log(properties)
      // console.log(HexToRgb(properties[campoFiltro]))
      // console.log(typeof properties[campoFiltro] !== 'undefined' ? HexToRgb(properties[campoFiltro] ):'nnn' )
      return typeof properties[campoFiltro] !== 'undefined' ? HexToRgb(properties[campoFiltro]) : ''
    } else if (series[type][0] === 'interpolate') {
      campoFiltro = series[type][1]
      // let typeInterpolate = series[type][1][0]
      let conversaoCampo = series[type][2][1][1]
      let conversaoType = series[type][2][0]
      let valor = properties[conversaoCampo]
      let color = interpolateColor(series, type, valor, conversaoType)
      return typeof properties[conversaoCampo] !== 'undefined' ? HexToRgb(color) : ''
    } else if (series[type][0] === 'match') {
      campoFiltro = series[type][1][1]

      let valor = properties[campoFiltro]
      let color = match(series, type, valor)
      if (color) {
        if (color.indexOf('rgb') >= 0) {
          return color
        } else {
          return HexToRgb(color)
        }
      } else {
        return HexToRgb('#ff0000')
      }
    } else {
      if (typeof series[type] == 'object') {
        return series[type]
      } else {
        let colorFinal = []
        if (typeof series['fill-opacity'] !== 'undefined') {
          if (series['fill-opacity'] !== '') {
            let opacidade = series['fill-opacity']

            if (series[type].indexOf('NaN') !== -1) {
              series[type].replace('NaN', '00')
            }
            colorFinal = HexToRgb(series[type])

            if (opacidade < 1) {
              opacidade = opacidade * 250
            } else if (opacidade === 1) {
              opacidade = 255
            }

            if (colorFinal)
              if (colorFinal.length === 3) {
                colorFinal.push(opacidade)
              } else {
                colorFinal[3] = opacidade
              }
          }
        } else {
          colorFinal = HexToRgb(series[type])
          if (colorFinal) {
            if (colorFinal.length === 3) {
              colorFinal.push(100)
            }
          }
        }

        return colorFinal
      }
    }
  } else {
    return [234, 234, 234, 100]
  }
}

function match(series, type, valor) {
  let corFinal = ''
  let cores = series[type].filter((valor, index) => index % 2 !== 0 && index > 1)
  let valores = series[type].filter((valor, index) => index % 2 === 0 && index > 1)

  if (Array.isArray(valores[0])) {
    for (var i in valores) {
      if (valor === valores[i][0]) {
        corFinal = cores[i]
        break
      } else {
        corFinal = valores[valores.length - 1]
        break
      }
    }
    return corFinal
  } else {
    for (var i in valores) {
      if (valor === valores[i]) {
        corFinal = cores[i]
        break
      }
    }
  }
  return corFinal
}

function interpolateColor(series, type, valor, conversaoCampo) {
  let corFinal = ''

  let cores = series[type].filter((valor, index) => index % 2 === 0 && index > 2)
  let valores = series[type].filter((valor, index) => index % 2 !== 0 && index > 2)

  let count = 1
  for (var i in valores) {
    if (valores.length === count) {
      if (Number(valor) >= valores[i]) {
        corFinal = cores[i]
        break
      }
    } else {
      if (Number(valor) >= valores[i] && Number(valor) <= valores[Number(i) + 1]) {
        corFinal = cores[i]
        break
      }
    }
    count++
  }
  return corFinal
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function formatLineFill(series, properties) {
  // let cores = {cor:{},opacity:{}}
  let campoFiltro = null
  if (typeof series['line-color'] !== 'undefined') {
    if (series['line-color'][0] === 'get') {
      campoFiltro = series['line-color'][1]
      return typeof properties[campoFiltro] !== 'undefined' ? HexToRgb(properties[campoFiltro]) : ''
    } else {
      return HexToRgb(series['line-color'])
    }
  }
  return series
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function formatCircle(series) {
  return series
}

export function ColorMapboxToKepler(colorSeries: {}, properties: [], type = 'MVTLayer') {
  // var type = getTypeFill(colorSeries)
  if (Array.isArray(colorSeries)) {
    return colorSeries
  }
  if (Object.entries(colorSeries).length > 0) {
    var getType = ''
    if (type !== '') {
      getType = Object.entries(colorSeries)[0][0]
    } else {
      getType = type
    }
    return GetColor(colorSeries, properties, getType)
  } else {
    return [234, 234, 234, 100]
  }
  // switch (type) {
  //   case 'MVTLayer':
  //     return formatFill(colorSeries,properties)
  //   case 'line-color':
  //       return formatLineFill(colorSeries,properties)
  //   case 'circle':
  //     return formatCircle(colorSeries)
  // }
}

export function GetPropert(style: {}, type = 'line-width', properties = '') {
  if (typeof style[type] !== 'undefined') {
    if (style[type][0] === 'get') {
      return style[type][1]
      // return typeof properties[campoFiltro]  !=='undefined' ? HexToRgb(properties[campoFiltro]) : ''
    } else if (style[type][0] === 'interpolate') {
      let campoFiltro = style[type][2][1][1]
      let valor = properties[campoFiltro]
      return valor
    } else {
      return style[type]
    }
  } else {
    return ''
  }
}

export function ClearText(str) {
  var map = {
    // '-' : '',
    '-': '_',
    a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
    e: 'é|è|ê|ë|É|È|Ê|Ë',
    i: 'í|ì|î|ï|Í|Ì|Î|Ï',
    o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
    '': "'",
    // "": " "
  }

  for (var pattern in map) {
    str = str.replace(new RegExp(map[pattern], 'g'), pattern)
  }

  return str
}

type PropsStyle = 'decimal' | 'currency' | 'currency_inteiro' | 'inteiro' | 'default'

export function formatNumber(valor: any, style: PropsStyle = 'default') {
  if (style === 'decimal') {
    return Number(valor).toLocaleString('pt-br', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  } else if (style === 'currency_inteiro') {
    return Number(valor).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  } else if (style === 'inteiro') {
    return Number(valor).toLocaleString('pt-br', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
  } else {
    return Number(valor).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
  }

  //    return  parseFloat(valor).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
export type Endereco = {
  latitude: number
  longitude: number
  endereco_completo: string
  logradouro: string
  numero: string
  bairro: string
  cidade: string
  estado: string
  cep: string
}
export function formatAdressSeachGoogleMapa(place) {
  let addr = {
    latitude: null,
    longitude: null,
    endereco_completo: null,
    logradouro: null,
    numero: null,
    bairro: null,
    cidade: null,
    estado: null,
    cep: null,
  }
  if (typeof place.geometry !== 'undefined') {
    addr.latitude = place.geometry.location.lat()
    addr.longitude = place.geometry.location.lng()
    addr.endereco_completo = place.formatted_address

    for (var i in place.address_components) {
      var types = place.address_components[i].types
      if (types.includes('route')) {
        addr.logradouro = place.address_components[i].long_name
      }
      if (types.includes('street_number')) {
        addr.numero = place.address_components[i].long_name
      }
      if (types.includes('sublocality')) {
        addr.bairro = place.address_components[i].long_name
      }
      if (types.includes('administrative_area_level_2')) {
        addr.cidade = place.address_components[i].long_name
      }
      if (types.includes('administrative_area_level_1')) {
        addr.estado = place.address_components[i].short_name
      }
      if (types.includes('postal_code') || types.includes('postal_code_prefix')) {
        addr.cep = place.address_components[i].long_name
      }
    }
    return addr
  } else {
    return addr
  }
}

export function formatDate(data) {
  try {
    return new Date(data).toLocaleDateString('pt-BR', {timeZone: 'UTC'})
  } catch {
    return '-'
  }
}
