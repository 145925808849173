// eslint-disable-next-line jsx-a11y/anchor-is-valid
import { TableAdminNewsFeed } from './components/TableAdminNewsFeed'
import { HeaderWrapperFluid } from '../../../../_metronic/layout/components/header/HeaderWrapperFluid'
import { AsideDefault } from '../../../../_metronic/layout/components/aside/AsideDefault'
import { Footer } from '../../../../_metronic/layout/components/Footer'
import { Form, FormLabel } from 'react-bootstrap'
import { useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'

const href = '#'
export function AdminNewsFeed() {
  const [toogleFilter, setToogleFilter] = useState(true)
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [filter, setFilter] = useState({});

  function handleFilter(event) {
    event.preventDefault();

    // Crie um objeto inicialmente vazio
    const filterObject = {};

    // Adicione as chaves e valores ao objeto se startDate e endDate não estiverem vazios
    if (startDate) {
      filterObject['data_inicio'] = startDate;
    }

    if (endDate) {
      filterObject['data_fim'] = endDate;
    }

    // Configure o estado com o objeto
    setFilter(filterObject);
  }


  console.log(filter)

  const handleStartDateChange = (event) => {
    const inputDate = event.target.value;

    // Converte a data do formato padrão (AAAA-MM-DD) para MM/DD/AAAA
    const formattedDate = new Date(inputDate).toLocaleDateString('en-US');

    setStartDate(formattedDate);
  };

  const handleEndDateChange = (event) => {
    const inputDate = event.target.value;

    // Converte a data do formato padrão (AAAA-MM-DD) para MM/DD/AAAA
    const formattedDate = new Date(inputDate).toLocaleDateString('en-US');

    setEndDate(formattedDate);
  };



  function toggleFilter() {
    if (toogleFilter === false) {
      setToogleFilter(true)
    } else if (toogleFilter === true) {
      setToogleFilter(false)
    }
  }

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <div
                className='py-6  d-flex align-items-center justify-content-between'
                id='kt_header_container'
              >
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  data-kt-swapper='true'
                  data-kt-swapper-mode='prepend'
                  data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Listagem</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href={href} className='text-muted'>
                        Feed Notícias
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='d-flex align-items-center ms-3'>
                  <button
                    className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={toggleFilter}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filtros
                  </button>
                </div>
              </div>
              <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                <div id='acc' className={toogleFilter === true ? 'show' : ''}>
                  <div className='card-form px-12 py-12 mb-6'>
                    <Form className='row form form-horizontal' onSubmit={handleFilter}>
                      <div className='row'>
                        <div className='col-md-3'>
                          <FormLabel className='form-label'>Nome</FormLabel>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Digite o nome da notícia'
                            name='nome'
                          />
                        </div>
                        <div className='col-md-3'>
                          <FormLabel className='form-label'>Data Inicial</FormLabel>
                          <input
                            type='date'
                            className='form-control'
                            name='date-init'
                            onChange={handleStartDateChange}
                          />
                        </div>
                        <div className='col-md-3'>
                          <FormLabel className='form-label'>Data de Expiração</FormLabel>
                          <input
                            type='date'
                            className='form-control'
                            name='date-init'
                            onChange={handleEndDateChange}
                          />
                        </div>
                        <div className='col-md-3'>
                          <FormLabel className='form-label'>Data de Cadastro</FormLabel>
                          <input
                            type='date'
                            className='form-control'
                            name='date'
                          />
                        </div>

                        <div className='w-100 all-end mrt-2'>
                          <button type='submit' className='btn btn-primary'>
                            Buscar
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>

                <div className='col-xl-12'>
                  <TableAdminNewsFeed datas={filter} />
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
