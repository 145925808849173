import {MapDrawControl, MapTooltip} from '.'
import {MapsGoogle} from './MapsGoogle'
import {LayoutControl} from './LayoutControl'
import {useLayer} from '../../../../../hooks/useLayer'
import {Explorer} from './Explorer'
import {ComposicaoControl} from './ComponentsControl/ComposicaoControl'
import {EnderecoControl} from './ComponentsControl/EnderecoControl'
import { MapInfoBox } from './InfoBox/MapInfoBox'

export const Maps = () => {
  const {layers, onUpdateLayer, onAddLayer} = useLayer()
  Explorer.layers = layers
  Explorer.onUpdateLayer = onUpdateLayer
  Explorer.onAddLayer = onAddLayer
  Explorer.renderLayers(layers)
  
  return (
    <>
      <MapsGoogle>
        <MapDrawControl></MapDrawControl>
        <MapInfoBox ></MapInfoBox> 
      </MapsGoogle>
  
      <MapTooltip></MapTooltip>
      <LayoutControl></LayoutControl>
      <ComposicaoControl></ComposicaoControl>
      <EnderecoControl></EnderecoControl>
      {/* <Chart uuid={''} label={''} itens={''}></Chart> */}
    </>
  )
}
