import React from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { ModalMassaUsoCreate } from './EmpreendimentoMassaUso/Modals/Create'
import { MutationDeleteEmpreendimentoMassa } from '../../../../../../graphql/services/EmpreendimentoMassa'
import { useParams } from 'react-router-dom'
import { MutationSetLogAcessoRecurso } from '../../../../../../graphql/services/LogAcesso'

type Props = {
  id: number
  imoveis: any
  setIdEdit?: any
}

export const Buttons: React.FC<Props> = ({ id, imoveis, setIdEdit }) => {
  const { id: idEmpreendimento } = useParams<{ id: string }>()
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()
  const { HandleDeleteEmpreendimentoMassa, loading } = MutationDeleteEmpreendimentoMassa()
  if (loading) {
    Swal.fire(
      'Enviando Informações...', '')
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteEmpreendimentoMassa({ variables: { deleteEmpreendimentoMassaId: id } })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          FormSetLogAcessoRecurso({ ferramenta: 'empreendimentos', funcionalidade: 'massa', action: 'delete', url: '/empreendimentos/details/' + idEmpreendimento + '#massa' });
          Swal.fire('Sucesso!', 'Massa deletada com sucesso.', 'success')
        }
      }
    })
  }



  return (
    <>
      <div className='d-flex justify-content-end  '>
        <ModalMassaUsoCreate id_massa={id} imoveis={imoveis} />
        <button
          onClick={(e) => {
            setIdEdit(id)
          }}
          className='btn  btn-light-success  me-2'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button>

        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          className='btn  btn-light-danger  me-2'>
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>

      </div>
    </>
  )
}
