import { Modal, Tab, Tabs } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { MutationSetImovelCorretores, MutationSetImovelCorretoresByID } from '../../../../../graphql';
import { GetCorretoresChosen } from '../../../../../components/UI/Chosens/GetCorretoresChosen';
import { FormInputAndLabel } from '../../../../../components/UI/Inputs/FormInputAndLabel';
import { FormInputMaskAndLabel } from '../../../../../components/UI/Inputs/FormInputMaskAndLabel';

interface QueryGetCorretores {
    id_terreno: number;
}

export function CreateFormImovelCorretor({ id_terreno }: QueryGetCorretores) {

    const [show, setShow] = useState(false)

    const {
        DataSetImovelCorretor, FormImovelCorretor, error: ErrorImovelCorretor, errors: ErrorsImovelCorretor, setValue,
        handleSubmit: HabdleSubmitImovelCorretor, loading: LoadingImovelCorretor, register: RegisterImovelCorretor } =
        MutationSetImovelCorretores()

    const {
        error: ErrorImovelCorretorByID, errors: ErrorsImovelCorretorByID, handleSubmit: HabdleSubmitImovelCorretorByID,
        loading: LoadingImovelCorretorByID, register: RegisterImovelCorretorByID, DataSetImovelCorretorByID, FormImovelCorretorByID } =
        MutationSetImovelCorretoresByID()

    const [valueCorretor, setValueCorretor] = useState(0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function closeModal() {
        setShow(false)
    }
    function openModal() {
        setShow(true)
    }


    return (
        <>
            <button
                onClick={openModal}
                className='btn  btn-light-info btn-sm me-2'>
                <span className="svg-icon svg-icon-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="currentColor"></path>
                        <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="currentColor"></rect>
                    </svg>
                </span>
                Adicionar Corretor

            </button>

            <Modal centered animation show={show} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Associar Corretor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        defaultActiveKey="search"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="search" title="Buscar">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <form onSubmit={HabdleSubmitImovelCorretor(FormImovelCorretor)} >
                                            <input
                                                className='form-control form-control-solid'
                                                placeholder='id'
                                                hidden={true}
                                                defaultValue={id_terreno}
                                                {...RegisterImovelCorretor('id_terreno', { required: true, valueAsNumber: true })}
                                            />
                                            <GetCorretoresChosen
                                                valueCorretor={valueCorretor}
                                                SetValueCorretor={(value: number) => {
                                                    setValue('id_corretor', value)
                                                    setValueCorretor(value)
                                                }}
                                            />
                                            <div className="col-md-12 d-flex mrt-1" style={{ justifyContent: 'flex-end' }}>
                                                <button type="button" className="btn btn-default pull-right" data-bs-dismiss="modal" onClick={() => closeModal()}>Fechar</button>
                                                <button type="submit" className="btn btn-success mx-2" id="btnIncluirCorretor">Associar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey="novo" title="Incluir Novo">
                            <form onSubmit={HabdleSubmitImovelCorretorByID(FormImovelCorretorByID)}>
                                <input
                                    className='form-control form-control-solid'
                                    placeholder='id'
                                    hidden={true}
                                    defaultValue={id_terreno}
                                    {...RegisterImovelCorretorByID('idImovel', { required: true, valueAsNumber: true })}
                                />
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='mb-6'>
                                            <FormInputAndLabel
                                                label='Nome Completo'
                                                className='form-control required form-control-solid'
                                                placeholder='Nome Completo'
                                                register={RegisterImovelCorretorByID("nome")}
                                                error={ErrorsImovelCorretorByID ? ErrorsImovelCorretorByID.nome?.message : ''}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='mb-6'>
                                            <FormInputAndLabel
                                                label='Email'
                                                className='form-control required form-control-solid'
                                                placeholder='Ex: joao@email.com'
                                                register={RegisterImovelCorretorByID("email")}
                                                error={ErrorsImovelCorretorByID ? ErrorsImovelCorretorByID.email?.message : ''}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='mb-6'>
                                            <FormInputMaskAndLabel
                                                label='Telefone:'
                                                maskChar=' '
                                                mask='(99) 99999-9999'
                                                className='form-control form-control-solid'
                                                placeholder='Ex: (00) 00000-0000'
                                                register={RegisterImovelCorretorByID("telefone")}
                                                error={ErrorsImovelCorretorByID ? ErrorsImovelCorretorByID.telefone?.message : ''}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='mb-6'>
                                            <FormInputAndLabel
                                                label='Empresa'
                                                className='form-control required form-control-solid'
                                                placeholder='Empresa'
                                                register={RegisterImovelCorretorByID("empresa")}
                                                error={ErrorsImovelCorretorByID ? ErrorsImovelCorretorByID.empresa?.message : ''}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
                                        <button className='btn btnSecondary pull-right ' type='button' onClick={closeModal}>
                                            Fechar
                                        </button>
                                        <button disabled={LoadingImovelCorretorByID} type='submit' className='btn btn-success mx-2'>
                                            Salvar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    )
}
