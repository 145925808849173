import {useMemo} from 'react'
import {ImoveisMercadoSecundario} from '../../Types'
import {BarChart} from './BarChart'
import {average} from 'simple-statistics'
import {formatNumber} from '../../../../../components/util'
type Props = {
  label?: string
  itens: ImoveisMercadoSecundario[]
}

export default function PrecoMedioM2({label, itens}: Props) {
  let {chartDataVSOMetragem} = useMemo(() => {
    let precoMedioM2 = Array.from({length: 7}, (e) => [])
    let totalUnidades = Array(7).fill(0, 0, 7)

    let chartDataVSOMetragem = [
      {
        name: 'Preço M²',
        data: [],
      },
    ]

    // eslint-disable-next-line array-callback-return
    itens.map((item) => {
      let i = item.quartos
      totalUnidades[i] = totalUnidades[i] + 1
      precoMedioM2[i].push(Number(item.valor) / Number(item.area))
    })
    totalUnidades.map((item, key) => {
      chartDataVSOMetragem[0].data.push({
        x: key +' Dorm.',
        y: precoMedioM2[key].length > 0 ? average(precoMedioM2[key]): 0,
        total_unidades: item,
        id: key,
      })
    })
    return {chartDataVSOMetragem}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens])
  return (
    <>
      {/* <div className='col-6'> */}
        <BarChart
          data={chartDataVSOMetragem}
          className={''}
          title={'Preço médio de domitórios por m²'}
          subtitle={''}
          onChange={() => {}}
          legenda={['Preço médio m²']}
        ></BarChart>
      {/* </div>
      <div className='col-6'>Map</div> */}
    </>
  )
}
