import React from 'react'
import { KTSVG } from '../../../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { ModalMassaUsoUpdate } from '../Modals/Update'
import { MutationDeleteEmpreendimentoMassaUso } from '../../../../../../../../graphql/services/EmpreendimentoMassaUsos'
import { MutationSetLogAcessoRecurso } from '../../../../../../../../graphql/services/LogAcesso'
import { useParams } from 'react-router-dom'


type Props = {
  id: number
  imoveis: any
}

export const Buttons: React.FC<Props> = ({ id, imoveis }) => {
  const {id: idEmpreendimento} = useParams<{id: string}>()
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()
  const idMassaUsos = id

  const { HandleDeleteEmpreendimentoMassaUso, loading } = MutationDeleteEmpreendimentoMassaUso()
  if (loading) {
    Swal.fire(
      'Enviando Informações...', '')
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteEmpreendimentoMassaUso({
          variables: {
            massaUsos: {
              id: idMassaUsos,
            }
          }
        })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          FormSetLogAcessoRecurso({ ferramenta: 'empreendimentos', funcionalidade: 'massa-usos', action: 'delete', url: '/empreendimentos/details/' + idEmpreendimento + '#massa' });
          Swal.fire('Sucesso!', 'Uso da massa deletado com sucesso.', 'success')
        }
      }
    })
  }


  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <ModalMassaUsoUpdate imoveis={imoveis} id_massa={idMassaUsos} />
        {/* <ModalPutEmpreendimentoMassa idMassa={idMassaUsos}  /> */}
        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          className='btn btn-icon btn-light-danger btn-sm me-2'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
