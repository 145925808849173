import { useState } from "react";
import { Market, submarkets } from "../data";
import { getEstadoById } from "../functions";
import { Overview } from "./MarketPlaces/Overview";
import { MarketPlaces } from "./MarketPlaces/MarketsPlaces";
import { useMarket } from "../context/MarketContext";

export default function AnaliseMercadoMenu({ showMap, toggleShowMap}) {
  const [showOverview, setShowOverview] = useState(false);
  const [dataMarketResponse, setDataMarketResponse] = useState(null)
  const { currentMarket, overview} = useMarket()

  const handleMarketClick = () => {
    setShowOverview(true);
  };

  const handleMarketChange = (id: string, type: 'estado' | 'submarket', data?: Market | submarkets) => {
    console.log(id)
    console.log(type)
    console.log(data)

    if (type === 'estado') {
      // Aqui você pode atualizar o estado com os dados do estado
      const estado = getEstadoById(id);
      setDataMarketResponse(estado);
    } else if (type === 'submarket') {
      setDataMarketResponse(data);
    }
    setShowOverview(true);
  };


  const handleBackButtonClick = () => {
    setShowOverview(false);
  };

  return (
    <>
      <div
          style={{
          backgroundColor: '#fff',
          zIndex: 9,
          height: 'calc(100vh - 70px)'
        }}
        role='tabpanel'
      >

        {!overview && <MarketPlaces showMap={showMap} toggleShowMap={toggleShowMap} handleMarketChange={handleMarketChange} />}
        {overview && <Overview showMap={showMap} toggleShowMap={toggleShowMap} handleMarketChange={handleMarketChange} dataMarketResponse={currentMarket} />}
      </div>
    </>
  )
}