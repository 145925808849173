import { gql } from "@apollo/client";

export const SET_IMOVEL_CORRETOR = gql`
mutation SetImovelCorretor($data: imovelCorretor!) {
  SetImovelCorretor(data: $data) {
      id
  }
}
`;

export const SET_IMOVEL_CORRETOR_BY_ID = gql`
mutation SetImovelCorretorById($corretor: corretor!, $idImovel: Float!) {
  SetImovelCorretorById(corretor: $corretor, id_imovel: $idImovel) {
    corretor {
      id
      nome
    }
  }
}
`;

export const DELETE_IMOVEL_CORRETOR = gql`
mutation DeleteImovelCorretor($deleteImovelCorretorId: Float!) {
  DeleteImovelCorretor(id: $deleteImovelCorretorId) {
    id
  }
}
`