import {ReactChild} from 'react'
import {Card} from 'react-bootstrap'

type Props = {
  headerTitle?: string
  headerSubtTitle?: string
  headerToolbar?: React.ReactNode
  children?: ReactChild
  footer?: React.ReactChild
}

export const CardTables: React.FC<Props> = ({
  headerTitle,
  headerSubtTitle,
  headerToolbar,
  children,
  footer,
}) => {
  return (
    <Card>
      <Card.Header className='align-items-center p-8 '>
        <div>
          <div className='h1'>{headerTitle}</div>
          <span className='text-muted mt-1 fw-bold fs-7'>{headerSubtTitle}</span>
        </div>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <div className='all-end'>{headerToolbar}</div>
        </div>
      </Card.Header>
      <Card.Body className='p-0'>{children}</Card.Body>
      <Card.Footer className=' all-end '>{footer}</Card.Footer>
    </Card>
  )
}
