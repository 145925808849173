import {FaChartBar} from 'react-icons/fa'
import {Endereco} from '../ComponentsControl/EnderecoControl/endereco'
import {ModalFicha} from './ModalAction'
import {SaveImovel} from './ModalAction/SaveImovel'
import {ModalFichaSemLote} from './ModalAction/ModalFichaSemLote'
import {Layout} from '../Layout'

type Props = {
  showHide: boolean
  latLng: google.maps.LatLng
}

export const MenuAction: React.FC<Props> = ({showHide, latLng}) => {
  const municipio = Endereco.getMunicipio()

  function setShowModalAvm() {
    Layout.setShowContextMenu(false)
    Layout.setShowGeometryMenu(false)
    Layout.setShowContextAVM(true, latLng)
  }
  return (
    <>
      <div
        className='px-3 py2 text-rigth '
        style={{
          borderBottom: '1px dashed #d0d0d0',
          borderTop: '1px dashed #d0d0d0',
          paddingTop: '20px',
        }}
      >
        <ul className='nav nav-pills nav-pills-custom mb-3 d-flex flex-row flex-wrap align-items-center d-flex justify-content-around'>
          <li className='nav-item mb-3 d-flex'>
            {municipio.lote_fiscal === 1 ? (
              <ModalFicha itens={[]} latLng={latLng} municipio={municipio} />
            ) : (
              <ModalFichaSemLote
                itens={[]}
                latLng={latLng}
                municipio={municipio}
              ></ModalFichaSemLote>
            )}
          </li>
          <li className='nav-item mb-3'>
            <button
              className='btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-light-success w-md-auto h-40px px-0 px-md-4'
              id='btn-painel-imobiliario'
              aria-selected='true'
              role='tab'
              data-bs-target='#modal-painel-imobiliario'
              data-bs-toggle='modal'
            >
              <div className='nav-icon '>
                <FaChartBar className='fs-3 me-1'></FaChartBar>
              </div>

              <span className='mt-1'>PAINEL </span>
            </button>
          </li>
          <li className='nav-item mb-3'>
            <button
              className='btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-light-success  w-md-auto h-40px px-0 px-md-6'
              // id='btn-avm'
              // aria-selected='true'
              // role='tab'
              onClick={setShowModalAvm}
            >
              <div className='nav-icon '>
                <FaChartBar className='fs-3 me-1'></FaChartBar>
              </div>
              <span className='mt-1'>AVM </span>
            </button>
          </li>
          <li className='nav-item mb-3 me-3 me-lg-6 justify-content-end '>
            <SaveImovel latLng={latLng} municipio={municipio}></SaveImovel>
          </li>
        </ul>
      </div>
    </>
  )
}
