import {z} from 'zod'

const SetUsuario = {
  nome: z.string().nonempty('Digite o Nome Completo é obrigatório!'),
  email: z.string().nonempty('O Email é obrigatório!'),
  senha: z.string().nonempty('A senha é obrigatório!'),
  confirmarSenha: z.string().nonempty('A confirmação da senha é obrigatório!'),
  telefone: z.string().nonempty('O Telefone é obrigatório!'),
  aceite: z.literal(true, {
    errorMap: () => ({message: 'Você precisa aceitar os termos e condições.'}),
  }),
}
export const FormSetUsuarioFormSchema = z.object(SetUsuario)
export type FormSetUsuarioFormData = z.infer<typeof FormSetUsuarioFormSchema>

const SetUsuarioAdmin = {
  nome: z.string(),
  email: z.string(),
  telefone: z.string(),
  visualizacao: z.string(),
  edicao: z.string(),
  exclusao: z.string(),
}
export const FormSetUsuarioAdminFormSchema = z.object(SetUsuarioAdmin)
export type FormSetUsuarioAdminFormData = z.infer<typeof FormSetUsuarioAdminFormSchema>

export const putUsuarioFormSchema = z.object({
  name: z.string().optional(),
  email: z.string().optional(),
  telefone: z.string().optional(),
  empresa: z.string().optional(),
  tipo_pessoa: z.string().optional(),
  cor_background: z.string().optional(),
})

export type putUsuarioFormData = z.infer<typeof putUsuarioFormSchema>

export const putEmpresaUsuarioSchema = z.object({
  id: z.number(),
  visualizacao: z.string(),
  edicao: z.string(),
  exclusao: z.string(),
})

export type putEmpresaUsuarioData = z.infer<typeof putEmpresaUsuarioSchema>
