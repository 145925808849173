import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BiAddToQueue } from "react-icons/bi";
import { FormInputAndLabel } from "../../../../components/UI/Inputs/FormInputAndLabel";
import Swal from "sweetalert2";
import { GetEstadoCidadeChosen } from "../../../../components/UI/Chosens/GetEstadoCidadeChosen";
import { MutationSetEmpresa } from "../../../graphql/services/Empresas";
import { FormInputMaskAndLabel } from "../../../../components/UI/Inputs/FormInputMaskAndLabel";
import { SliderPicker } from "react-color";
import SwitchSituationComponent from "../../../../components/UI/Switchs/SituationSwitch";
import { validarCNPJ } from "../../../../components/util";


export function ModalSetEmpresa() {
    const [show, setShow] = useState(false);
    const { FormEmpresa, handleSubmit, loading, register, errors, setValue } = MutationSetEmpresa();
    const [situation, setSituation] = useState(false)

    useEffect(() => {
        const newValue = situation ? 1 : 0;
        setValue('situacao', newValue);
    }, [situation, setValue]);

    const handleChangeSituation = (newSituation) => {
        setSituation(newSituation);
    };

    function closeModal() {
        setShow(false);
    }

    function openModal() {
        setShow(true);
    }

    const [colors, setColors] = useState({
        background: '#fff',
        font: '#000'
    });
    console.log(colors)
    const handleChangeComplete = (color, key) => {
        setColors({
            ...colors,
            [key]: color.hex
        });
        setValue(key, color.hex); // Atualize o valor do campo do formulário com a nova cor
    };

    
    const handleChangeColors = (e) => {
        const inputValue = e.target.value;
        const isValidHex = /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i.test(inputValue);
        if (isValidHex || inputValue === "") {
            setColors(prevColors => ({ ...prevColors, font: inputValue }));
        }
    };

    if (loading) {
        Swal.fire(
            'Enviando Informações...', '')
        Swal.showLoading()
    }

    const onSubmit = async (data) => {
        FormEmpresa(data).then((result) => {
            Swal.fire('Sucesso!', 'Nova empresa associada com sucesso.', 'success');
            closeModal()
        }).catch((error) => {
            Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error');
        });
    }


    return (
        <>
            <button onClick={openModal} className='btn btn-light-primary btn-sm d-block me-2' style={{ width: '100px !important' }}>
                <BiAddToQueue className='fs-2 me-1' />Nova Empresa
            </button>

            <Modal centered animation show={show} onHide={() => closeModal()} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Nova Empresa:</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-lg'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-6">
                                            <FormInputAndLabel
                                                label='Nome:'
                                                type='text'
                                                required
                                                className='form-control'
                                                placeholder='Nome da Empresa'
                                                register={register("nome")}
                                                error={errors ? errors.nome?.message : ''}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <FormInputAndLabel
                                                label="Nome Fantasia"
                                                type='text'
                                                required
                                                className='form-control '
                                                placeholder='Nome Fantasia'
                                                register={register("nome_fantasia")}
                                                error={errors ? errors.nome_fantasia?.message : ''}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <FormInputAndLabel
                                                label="Email"
                                                type="email"
                                                className='form-control'
                                                placeholder='Ex: email@email.com'
                                                register={register("email")}
                                                error={errors ? errors.email?.message : ''}

                                            />
                                        </div>
                                        <div className="col-6">
                                            <FormInputMaskAndLabel
                                                label='Telefone:'
                                                maskChar=' '
                                                mask='(99) 99999-9999'
                                                className='form-control'
                                                placeholder='Ex: (00) 00000-0000'
                                                register={register("telefone")}
                                                error={errors ? errors.telefone?.message : ''}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <FormInputMaskAndLabel
                                                label='CNPJ:'
                                                maskChar=' '
                                                required
                                                mask='99.999.999/9999-99'
                                                className='form-control'
                                                placeholder='Ex: 00.000.000/0000-00'
                                                register={register("cnpj", {
                                                    required: "O CNPJ é obrigatório.",
                                                    validate: value => validarCNPJ(value) || "CNPJ inválido"
                                                })}
                                                error={errors ? errors.cnpj?.message : ''}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="col-form-label">Situação</label>
                                                <SwitchSituationComponent
                                                    situation={situation}
                                                    onChange={handleChangeSituation}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row mt-2">

                                        <div className="col-6">
                                            <FormInputAndLabel
                                                label="Endereço"
                                                className='form-control'
                                                placeholder='Ex: Rua Novo Horizontino'
                                                register={register("endereco")}
                                                error={errors ? errors.endereco?.message : ''}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <FormInputAndLabel
                                                label="Numero °"
                                                className='form-control'
                                                placeholder='Numero °'
                                                register={register("numero")}
                                                error={errors ? errors.numero?.message : ''}

                                            />
                                        </div>
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <FormInputMaskAndLabel
                                                label='CEP:'
                                                maskChar=' '
                                                mask='99999-999'
                                                className='form-control'
                                                placeholder='Ex: 00000-000'
                                                register={register("cep")}
                                                error={errors ? errors.cep?.message : ''}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <FormInputAndLabel
                                                label="Complemento"
                                                className='form-control'
                                                placeholder='Ex: Casa 1° Andar'
                                                register={register("complemento")}
                                                error={errors ? errors.complemento?.message : ''}

                                            />
                                        </div>

                                    </div>
                                    <div className="row mt-2">
                                        <GetEstadoCidadeChosen
                                            defaultValueCidade={null}
                                            defaultValueEstado={null}
                                            classNameCity={'col-6 col-xs-12 mt-1'}
                                            classNameEstado={'col-6 col-xs-12  mt-1'}
                                            SetValueEstado={(value) => {
                                                setValue('id_estado', Number(value))
                                            }}
                                            SetValueCidade={(value) => {
                                                setValue('id_cidade', Number(value))
                                            }}
                                        />
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <label className="col-form-label">Cor Background</label>
                                            <SliderPicker
                                                color={colors.background}
                                                onChangeComplete={(color) => {
                                                    handleChangeComplete(color, 'background')
                                                    console.log(color.hex)
                                                    setValue('cor_background', color.hex)
                                                }}
                                            />
                                            <input type="text" className="form-control mt-2" onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const isValidHex = /^#[0-9A-F]{6}$/i.test(inputValue);
                                                if (isValidHex || inputValue === "") {
                                                    setColors(prevColors => ({ ...prevColors, background: inputValue }));
                                                }
                                            }} value={colors.background} />
                                        </div>
                                        <div className="col-6">
                                            <label className="col-form-label">Cor Fonte</label>
                                            <SliderPicker
                                                color={colors.font}
                                                onChangeComplete={(color) => {
                                                    handleChangeComplete(color, 'font');
                                                    console.log(color.hex);
                                                    setValue('cor_fonte', color.hex);
                                                }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control mt-2"
                                                onChange={handleChangeColors} 
                                                value={colors.font}
                                            />
                                        </div>

                                    </div>

                                    <div className="w-100  mt-4 all-end">
                                        <button type="button" onClick={() => closeModal()} className="btn btn-danger me-4">Cancelar</button>
                                        <button type="submit" className="btn btn-success">Salvar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
