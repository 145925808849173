import React from 'react'
// import ReactHtmlParser from 'react-html-parser'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

type Props = {
  distancia: Number
  servico: {}
  template: string
}

export const MenuItemGrupoLegal: React.FC<Props> = ({distancia, template, servico}) => {
  // //console.log(servico)
  //   const [servicoResult, setServicoResult] = useState([]);
  //   useEffect(() => {
  //     //console.log(servico['url_sistema'])
  //     fetch(servico['url_sistema'])
  //        .then((response) => response.json())
  //        .then((data) => {
  //           //console.log(data);
  //           setServicoResult(data);
  //        })
  //        .catch((err) => {
  //           //console.log(err.message);
  //        });
  //  }, []);

  let templateResult = template
  Object.entries(servico).map(function (x) {
    // //console.log(x[0])
    let chave = x[0]
    let valor = x[1]
    templateResult = templateResult.replace('${' + chave + '}', (valor as string) )
    return x
  })

  return (
    <div>
      <div className='menu-item my-1'>
        <div className='menu-link pe-4'>
          {/* <span className='menu-icon'>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
</svg>
          </span> */}
          <span className='' style={{flex: 'auto'}}>
            <ReactMarkdown
              children={templateResult}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                // Map `h1` (`# heading`) to use `h2`s.
                br: 'br',
                // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                em: ({node, ...props}) => {
                  return <i style={{color: 'red'}} {...props} />
                },
              }}
            ></ReactMarkdown>{' '}
          </span>

          {/* <span className='menu-actions'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-sign-turn-left'
              viewBox='0 0 16 16'
            >
              <path d='M11 8.5A2.5 2.5 0 0 0 8.5 6H7V4.534a.25.25 0 0 0-.41-.192L4.23 6.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 7 8.466V7h1.5A1.5 1.5 0 0 1 10 8.5V11h1V8.5Z' />
              <path
                fillRule='evenodd'
                d='M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435Zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134Z'
              />
            </svg>
          </span>
          <span className='menu-actions'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-circle'
              viewBox='0 0 16 16'
            >
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
            </svg>
          </span> */}
        </div>
      </div>
    </div>
  )
}
