import {MVTLayer} from '@deck.gl/geo-layers'
import {LayersData} from './LayersData'
import {AllGeoJSON} from '@turf/turf'

export function getLayers(layerAdd: any[], json: AllGeoJSON) {
  // const FeatureCollection = {
  //   type: 'FeatureCollection',
  //   features: [{type: 'Feature', properties: {}, geometry: json}],
  // }
  // btoa(JSON.stringify(FeatureCollection))
  var jsonEncoded = encodeJsonIntersect(json) 

  return layerAdd.map((item) => {
    let layer = getLayer(item)
    let intersect = `${layer.config.tile}layer=${layer.name}_operacao&l1=${layer.name}&s=${layer.schema}&l2=&g1=${jsonEncoded}`
    layer.config.data = intersect
    return createLayer(layer.config)
  })
}

export function encodeJsonIntersect(json:AllGeoJSON){
  const FeatureCollection = {
    type: 'FeatureCollection',
    features: [{type: 'Feature', properties: {}, geometry: json}],
  }

  var jsonEncoded = btoa(JSON.stringify(FeatureCollection))
  return jsonEncoded

}
export function getLayer(layer: string) {
  const l = LayersData.filter((item) => {
    return item.name === layer
  })[0]
  return l //Layers.filter((item)=>  item.name === layer  )[0]
}
export function createLayer(layer) {
  return new MVTLayer(layer)
}
