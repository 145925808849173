import { useParams } from 'react-router-dom'
import { Buttons } from './Buttons'
import { DateTimToView } from '../../../../../../components/util'
import { QueryGetImovelObservacoes } from '../../../../../../graphql/services/Observacoes'
import { useState } from 'react'
import { EditForm } from './EditForm'
import { CreateForm } from './CreateForm'

export function TableObservacoes() {
  const { id: idTerreno } = useParams<{ id: string }>()
  const [showCreate, setShowCreate] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [id, setId] = useState(null)

  const { data } = QueryGetImovelObservacoes({
    variables: {
      imovelObservacao: {
        id_terreno: parseInt(idTerreno),
      },
      pagination: {
        quantidade: null,
        pagina: null,
      },
    }
  })

  // console.log(data)


  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }
  function CloseModalEdit() {
    setShowEdit(false)
    setId(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setId(id)
  }



  if (!data) {
    return (
      <div className='table-responsive'>
        <table className='table table-striped gs-7'>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-150px'>Titulo</th>
              <th className='min-w-120px'>Data de Criação</th>
              <th className=''>Descrição</th>
              <th className='min-w-100px text-end'>Ações</th>
            </tr>
          </thead>
        </table>
      </div>
    )
  }
  return (
    <>
      <div className='card-header'>
        <h3 className='card-title'>Observações Adicionadas</h3>
        <div className='card-toolbar'>
          <button
            className='btn btnSuccess btn-round'
            onClick={(e) => {
              e.preventDefault()
              OpenModalCreate(null)
            }}
            style={{ color: 'white' }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen005.svg*/}
            <span className='svg-icon btn-icon svg-icon-4 ms-2'>
              +<span className='btn-label pd-left-1'>Adicionar Observação</span>
            </span>
            {/*end::Svg Icon*/}
          </button>
        </div>
      </div>
      <div className='card-body p-0 pt-5'>
      </div>
      <div className='table-responsive'>
        <table className='table table-striped gs-7'>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-150px'>Titulo</th>
              <th className='min-w-120px'>Data de Criação</th>
              <th className=''>Descrição</th>
              <th className='min-w-100px text-end'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data.GetImovelsObservacao.result.map((Obs) => {
              return (
                <tr key={Obs.id}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <p className='text-dark fs-6'>{Obs.titulo}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className='text-dark  d-block fs-6'>
                      {DateTimToView(Obs.data_cadastro)}
                    </p>
                  </td>
                  <td>
                    <p className='text-dark  d-block fs-6'>{Obs.descricao}</p>
                  </td>
                  <td>
                    <div className='text-dark fs-6'>
                      <Buttons setIdEdit={OpenModalEdit} id={Obs.id} />
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {showEdit ? <EditForm id={id} idTerreno={idTerreno} show={showEdit} closeModal={CloseModalEdit}></EditForm> : <></>}
      {showCreate ? <CreateForm idTerreno={idTerreno} closeModal={CloseModalCreate} show={showCreate}></CreateForm> : <></>}
    </>
  )
}
