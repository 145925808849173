import {ColorMapboxToKepler, GetPropert} from '../../../../../common'
import {BaseLayer, DefaultLayerProps} from '../base-layer'
import {IconLayer as DeckIconLayer,TextLayer} from '@deck.gl/layers'
import Pin from '../../../../../../../../../img/icon-atlas.png'
import {Explorer} from '../../../../Explorer'
import {Layout} from '../../../../Layout'

const ICON_MAPPING = {
  marker: {
    x: 0,
    y: 0,
    width: 128,
    height: 128,
    anchorY: 128,
    mask: true,
  },
}

export class IconLayerText extends BaseLayer {

  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-useless-constructor
  constructor(props: DefaultLayerProps | any) {
    super(props)
  }

  draw(propsInput: DefaultLayerProps | any) {
    let props = {...this.getDefaultLayerConfig(propsInput)}
    props = {...propsInput}
    
    // if (props.tooltip) {
    //   if (props.visible){
    //     // props.tooltip.setContent(props.label)
    //     props.tooltip.apen()
    //   }else{
    //       props.tooltip.close()
    //     }
    // }


    const icone = [
      new DeckIconLayer({
        id: props.id,
        data: props.data,
        pickable: true,
        visible: props.visible,
        //TODO : InconLayer - Incluir posibilidade de escolher o PIN
        iconAtlas: Pin,
        iconMapping: ICON_MAPPING,
        getIcon: (d) => 'marker',
        zIndex: 999999999,
        sizeScale: 1.5,
        opacity: 1,
        getPosition: (d: any) => {
          if(props.tooltip && !d.properties.tooltip){
            if (d.properties){
              d.properties.tooltip = Explorer.drawTooltip(d.coordinates[1],d.coordinates[0], d.properties['nome'] )
            }else{
              d.properties.tooltip =  Explorer.drawTooltip(d.coordinates[1],d.coordinates[0], d.nome )
            }
          }

          let value = GetPropert(props.style, 'positionZ')
          if (typeof d.coordinates ==='undefined'){
            if (d.geometry.coordinates.length === 2) {
              d.geometry.coordinates.push(value || 20)
            } else {
              d.geometry.coordinates[2] = value || 20
            }
            return d.geometry.coordinates
          }else{
            if (d.coordinates.length === 2) {
              d.coordinates.push(value || 20)
            } else {
              d.coordinates[2] = value || 20
            }
            return d.coordinates
          }
        },
        getSize: (f: any) => {
          let value = GetPropert(props.style, 'size')
          return value * 10 || 30
        },
        getColor: (f: any) => {
          let colorFinal = ColorMapboxToKepler(props.style, f.properties,'fill-color')
          return colorFinal
        },
        parameters: {depthTest: false},
        //TODO : InconLayer - Incluir Opções de edição no click do ícone
        onClick: (info: any, event: any) => {
          this.clickEventMarker(info, event, () => {
            // if (event.srcEvent.domEvent.button === 0) {
            //   const lng = info.coordinate[0]
            //   const lat = info.coordinate[1]
            //   const latlng = new google.maps.LatLng(lat, lng)
            //   Layout.setShowTerritorioMenu(true, latlng)
            // }

            if (event.srcEvent.domEvent.button === 2) {
              const lng = info.coordinate[0]
              const lat = info.coordinate[1]
              const latlng = new google.maps.LatLng(lat, lng)
              Layout.setShowContextMenu(false, latlng)
              Layout.setShowGeometryMenu(true, latlng)
            }

            props.tooltip.setMap(Explorer.map)
            Explorer.goToIconToMenu(info.layer.id)
          })
        },
        
        updateTriggers: {
          getColor: (f: any) => {
            let colorFinal = ColorMapboxToKepler(props.style, f.properties,'fill-color')
            return colorFinal
          },
          getSize: (f: any) => {
            let value = GetPropert(props.style, 'size')
            return value || 24
          },
          getPosition: (d: any) => {
            let value = GetPropert(props.style, 'positionZ')
            d.coordinates.push(value)
            return d.coordinates
          },
        },
      }),
      new TextLayer(props, {
        id: 'text_' + props.id,
        visible: props.visible,
        getAngle: 0,
        sizeMaxPixels: 20,
        sizeUnits: 'pixels',
        getPixelOffset: [0, 10],
        getText: (f) => {
          return Buffer.from(f.properties['nome'], 'utf-8').toString()
        },
        getColor: (f: any) => {
          if (
            typeof props.selectedPolygon !== 'undefined' &&
            props.selectedPolygon !== null &&
            props.selectedPolygon.length > 0
          ) {
            const id = f.properties['gid']
            if (props.selectedPolygon.find((i)=>i===id)) {
              return [50, 167, 82,80]
            }else{
              let colorFinal = ColorMapboxToKepler(props.style, f.properties, 'fill-color')
              return colorFinal
            }
          } else {
            let colorFinal = ColorMapboxToKepler(props.style, f.properties, 'fill-color')
            return colorFinal
          }
        },
        getSize: (f: any) => {
          let value = GetPropert(props.style, 'size')
          return value * 10 || 30
        },
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        getPosition: (p) => {
          // console.log
          return p.coordinates 
        },
        getBorderColor: [0, 0, 0, 255],
        updateTriggers: {
          getText:(f) => {
            return Buffer.from(f.properties['nome'], 'utf-8').toString()
          },
          getColor: (f: any) => {
            if (
              typeof props.selectedPolygon !== 'undefined' &&
              props.selectedPolygon !== null &&
              props.selectedPolygon.length > 0
            ) {
              const id = f.properties['gid']
              if (props.selectedPolygon.find((i)=>i===id)) {
                return [50, 167, 82,80]
              }else{
                let colorFinal = ColorMapboxToKepler(props.style, f.properties, 'fill-color')
                return colorFinal
              }
            } else {
              let colorFinal = ColorMapboxToKepler(props.style, f.properties, 'fill-color')
              return colorFinal
            }
          },
          getSize:  (f: any) => {
            let value = GetPropert(props.style, 'size')
            return value * 10 || 30
          },
        },
      })
    ]
    
    return icone
  }
}
