/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState} from 'react'
import {MutationCreateEmpreendimentoMassa} from '../../../../../../../graphql/services/EmpreendimentoMassa'
import {ErrorMessageComponent} from '../../../../../../../backoffice/components/UI/ErrorMessage'
import {Modal} from 'react-bootstrap'
import {Loader} from '../../../../../../../components/UI/Loader/Loader'
import Swal from 'sweetalert2'
import {BiPlus} from 'react-icons/bi'
import { MutationSetLogAcessoRecurso } from '../../../../../../../graphql/services/LogAcesso'

type Props = {
  id_empreendimento: number
}
const CreateForm: React.FC<Props> = ({id_empreendimento}) => {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()
  const [show, setShow] = useState(false)

  const {
    loading,
    handleSubmit,
    register,
    errors,
    error,
    DataSetEmpreendimentoMassa,
    FormSetEmpreendimentoMassa
  } = MutationCreateEmpreendimentoMassa()

  useEffect(() => {
    if (DataSetEmpreendimentoMassa) {
      closeModal()
      Swal.fire('Sucesso!', 'Corretor criado com sucesso.', 'success')
    }
  }, [DataSetEmpreendimentoMassa, error, loading])


  function closeModal() {
    setShow(false)
  }
  function openModal() {
    setShow(true)
  }

  const onSubmit = async (data) => {
    FormSetEmpreendimentoMassa(data).then((result) => {
      FormSetLogAcessoRecurso({ferramenta: 'empreendimentos', funcionalidade: 'massa', action: 'create', url: '/empreendimentos/details/'+ id_empreendimento +'#massa'});
      Swal.fire('Sucesso!', 'Massa criado com sucesso.', 'success')
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })
  }

  return (
    <>
      <button className='btn btn-sm btn-light-success' onClick={openModal}>
        <BiPlus className='fs-3'></BiPlus>
        Nova Massa
      </button>

      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Nova Massa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              className='form-control form-control-solid'
              placeholder='id'
              defaultValue={id_empreendimento}
              hidden={true}
              {...register('id_empreendimento', {required: true, valueAsNumber: true})}
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <label htmlFor='nome' className='required form-label'>
                    Nome:
                  </label>
                  <input
                    className='form-control form-control-solid'
                    placeholder='Nome da Massa'
                    defaultValue={''}
                    {...register('nome', {
                      pattern: /^[A-Za-z]+$/i,
                    })}
                  />
                  {errors.nome && <ErrorMessageComponent message={errors.nome?.message} />}
                </div>
              </div>

              <div className='col-md-12 py-6 d-flex' style={{justifyContent: 'flex-end'}}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={closeModal}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {CreateForm}
