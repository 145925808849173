import { gql } from "@apollo/client";

export const SET_EMPREENDIMENTO_OBVERVACOES = gql`
mutation SetEmpreendimentoObservacao($empreendimentoObservacao: empreendimentoObservacao!) {
  SetEmpreendimentoObservacao(empreendimentoObservacao: $empreendimentoObservacao) {
      id_empreendimento
    }
  }
`

export const PUT_EMPREENDIMENTO_OBSERVACAO = gql`
mutation PutEmpreendimentoObservacao($empreendimentoObservacao: empreendimentoObservacao!) {
  PutEmpreendimentoObservacao(empreendimentoObservacao: $empreendimentoObservacao) {
    titulo
  }
}
`

export const DELETE_EMPREENDIMENTO_OBSERVACAO = gql`
mutation DeleteEmpreendimentoTerreno($deleteEmpreendimentoObservacaoId: Float!) {
  DeleteEmpreendimentoObservacao(id: $deleteEmpreendimentoObservacaoId) {
    titulo
    id
  }
}
`