import {useParams} from 'react-router-dom'
import {Loader} from '../../../../../../components/UI/Loader/Loader'
import {Buttons} from './Tab2/buttons'
import {
  MutationDeleteEmpresaProduto,
  QueryGetProdutosByEmpresaID,
} from '../../../../../graphql/services/Empresas'
import {ModalAssociationUsuario} from './Tab2/AssociationUser'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import {DateTimToView, DifferenceDate} from '../../../../../../components/util'
import {ModalAssociationProduto} from '../../../partials/AssociationProduto'
import { ModalPutAssociationProduto } from '../../../partials/PutAssociationProduto'

export function TabProdutos() {
  const {id: IdEmpresa} = useParams<{id: string}>()
 
  const {
    data,
    error: errorProduto,
    loading: loadingProduto,
  } = QueryGetProdutosByEmpresaID({
    variables: {empresaId: parseInt(IdEmpresa)},
  })

  const {HandleDeleteEmpresaProduto, loading} = MutationDeleteEmpresaProduto()


  if (loading) {
    Swal.fire('Enviando Informações...', '')
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteEmpresaProduto({variables: {deleteEmpresaProdutoId: id}})
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Produto desassociado com sucesso.', 'success')
        }
      }
    })
  }

  if (!data) {
    return (
      <div className='tab-pane fade pb-12' id='tab3' role='tabpanel' aria-labelledby='tab3-tab'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-50px'>ID</th>
              <th className='min-w-100px'> Nome</th>
              <th className='min-w-50px '>Data de Ínicio - Fim</th>
              <th className='min-w-100px text-end'>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={12} className='text-center all-center'>
                <Loader text={'caregando...'}></Loader>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <div className='tab-pane fade' id='tab3' role='tabpanel' aria-labelledby='tab3-tab'>
      <div className='card mb-5'>
        <div className='card-header'>
          <div className='card-title'>
            <h3>Produtos da Empresa</h3>
          </div>
          <div className='card-toolbar'>
            <ModalAssociationProduto id_empresa={parseInt(IdEmpresa)} />
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive card-xxl-stretch'>
            <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-50px'>ID</th>
                  <th className='min-w-100px'> Nome</th>
                  <th className='min-w-50px '>Data de Ínicio - Fim</th>
                  <th className='min-w-100px text-end'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {data.GetEmpresaProdutoById.length !== 0 ? (
                  data.GetEmpresaProdutoById.map((response, item) => {
                    let diasRestantes = DifferenceDate(response.data_fim)

                    return (
                      <tr key={item}>
                        <td>
                          <p className='text-dark fs-6'>{response.id}</p>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#vazio' className='text-dark fs-6'>
                                {response.produto.nome}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex'>
                            <p className='text-dark fs-6 me-2'>
                              {DateTimToView(
                                response.data_inicio ? response.data_inicio : 'Não informada'
                              )}{' '}
                              -{' '}
                              {DateTimToView(
                                response.data_fim ? response.data_fim : 'Não informada'
                              )}
                            </p>
                            <span>
                              {response.data_inicio !== null && response.data_fim !== null
                                ? diasRestantes
                                : ''}
                            </span>
                          </div>
                        </td>
                        <td className='all-end'>
                          <ModalPutAssociationProduto id_produto={response.produto.id} id_association={response.id} id_empresa={IdEmpresa} />

                          <button
                            onClick={() => {
                              confirmDelete(response.id)
                            }}
                            className='btn btn-icon btn-light-danger'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </button>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr key={0}>
                    <td colSpan={12} className='text-center'>
                      <p className='text-dark fs-6'>Nenhum Produto cadastrado</p>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
