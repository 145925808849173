import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { useState } from 'react';
import swal from 'sweetalert2';
import InputCode from './partials/InputCode';
import Swal from 'sweetalert2';
import { MutationReesendToken, QueryCheckVerifyToken } from '../../../graphql/services/Senha';
import { stringify } from 'querystring';
import { useLazyQuery } from '@apollo/client';
import { FormInputAndLabel } from '../../../components/UI/Inputs/FormInputAndLabel';



export function VerifyToken() {
  const [loading, setLoading] = useState(false);

  const initialSeconds = 190;
  const [seconds, setSeconds] = useState(initialSeconds);
  const [running, setRunning] = useState(true);
  const navigate = useHistory()


  const { FormCheckTokenVerify, data: dataGet, error: errorGet, handleSubmit, loading: LoadingGet, register, errors } = QueryCheckVerifyToken();
  const TokenCode = localStorage.getItem('tokenCode');

  useEffect(() => {

    if (errorGet) {
      Swal.fire('ERRO!', error.message, 'warning')
    }

    if (dataGet) {
      Swal.fire({
        title: 'Token Verificado!',
        text: 'Token validado com sucesso, agora basta trocar a senha.',
        showCancelButton: false,
        confirmButtonText: 'Enviar',
        showCloseButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate.push(`/auth/change-password/${TokenCode}`)
        } else {
          Swal.fire('ERRO!', 'Email invalido ou conta não encontrada!.', 'warning')
        }
      })
    }
  }, [dataGet, navigate, errorGet, LoadingGet])



  const { HandleReenviarToken, data, error, loading: loadingSet } = MutationReesendToken()

  useEffect(() => {
    let timer;

    if (running && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    if (seconds === 0) {
      Swal.fire('Token expirado!', 'Tente corrigir o email ou reenviar o token.', 'warning')
      setRunning(false);
    }


    // Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(timer);
  }, [running, seconds]);

  const handleRestart = () => {
    Swal.fire({
      title: 'Token reenviado com sucesso! ',
      icon: 'success',
      showCancelButton: false,
      confirmButtonText: 'Enviar',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleReenviarToken()
        if (result) {
          Swal.fire('Sucesso!', 'Verifique o seu email.', 'success')
          setSeconds(initialSeconds);
          setRunning(true);
        }
      }
    })
  };

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        onSubmit={handleSubmit(FormCheckTokenVerify)}
        id='kt_login_password_reset_form'
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Verifique o token no email</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {seconds === 0 ? (
              'Tempo expirado, Reenvie o token para o email.'
            ) :
              'Digite o token recebido no campo abaixo.'
            }
          </div>
          {seconds === 0 ? (
            <button className='link-success fs-6 fw-bolder' onClick={handleRestart}>Reenviar E-mail</button>
          ) :
            <p>{seconds} segundos</p>
          }
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <FormInputAndLabel
            className='form-control'
            placeholder='Digite o token recebido no campo.'
            register={register('tokenCode')}
            error={errors ? errors.tokenCode?.message : ''}
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>

          <Link to='/'>
            <button
              type='button'
              onClick={() => { localStorage.clear() }}
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-secondary fw-bolder'
            >
              Cancelar
            </button>
          </Link>{' '}
          <button
            type='submit'
            disabled={seconds === 0 ? true : false && LoadingGet}
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder mrl-4'
          >
            {loading ? <span className='spinner-border text-info' role='status'></span> : 'Enviar'}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
