/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { FormInputAndLabel } from '../../../../components/UI/Inputs/FormInputAndLabel';
import { FormTextareaAndLabel } from '../../../../components/UI/Inputs/FormTextareaAndLabel';


export function SupportForm() {

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
    >
      {/* begin::Heading */}
      <div className='mb-4'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Entre em contato conosco:</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>

        <div className='col-xl-12 mb-3'>
          <FormInputAndLabel
            label='Nome Completo'
            className='form-control form-control-solid'
            placeholder='Ex: João da Silva'
          />
        </div>
        <div className='col-xl-12 mb-3'>
          <FormInputAndLabel
            label='E-mail'
            className='form-control form-control-solid'
            placeholder='Ex: exemplo@email.com'
          />
        </div>
        <div className='col-xl-12 mb-3'>
          <FormInputAndLabel
            label='Assunto'
            className='form-control form-control-solid'
            placeholder='Razão pela qual está entrando em contato.'
          />
        </div><div className='col-xl-12 mb-3'>
          <FormTextareaAndLabel
            label='Descrição'
            className='form-control form-control-solid'
            placeholder='Decreva o motivo pelo qual está entrando em contato.'
          />
        </div>
  
  
      </div>
      {/* end::Form group */}



      {/* begin::Form group */}
      <div className='text-center all-end '>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary  mb-5 me-10'
        >
          <span className='indicator-label'>Enviar</span>
        </button>

        <Link to='/'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Retornar
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
