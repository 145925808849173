import { gql } from "@apollo/client";


export const SET_TERMO = gql`
mutation SetTermo($termo: termo!) {
  SetTermo(termo: $termo) {
    id
  }
}
`

export const PUT_TERMO = gql`
mutation PutTermo($termo: termo!) {
  PutTermo(termo: $termo) {
    id
  }
}
`

export const DELETE_TERMO = gql`
mutation DeleteTermo($deleteTermoId: Float!) {
  DeleteTermo(id: $deleteTermoId) {
    id
  }
}
`