import { useMutation, useQuery } from "@apollo/client"
import { DeleteImovelCondicaoComercial, GetImovelCondicaoComercialByIdResponse, GetImovelCondicaoComercialResponse, PutImovelCondicaoComercial, SetImovelCondicaoComercial } from "../type"
import { GET_IMOVEL_CONDICAO_COMERCIAL, GET_IMOVEL_CONDICAO_COMERCIAL_BY_ID } from "../query"
import { DELETE_IMOVEL_CONDICAO_COMERCIAL, PUT_IMOVEL_CONDICAO_COMERCIAL, SET_IMOVEL_CONDICAO_COMERCIAL } from "../mutations"
import {
  CondicaoComercialCreateFormData, CondicaoComercialCreateFormSchema,
  CondicaoComercialPutFormData, CondicaoComercialPutFormSchema
} from "../formValidations/CondicaoComercial"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"


interface QueryProps {
  variables: any
}


export function QueryGetCondicaoComercial({ variables }: QueryProps) {
  const { data, loading } = useQuery<GetImovelCondicaoComercialResponse>(GET_IMOVEL_CONDICAO_COMERCIAL, {
    variables: { ...variables },
  })

  return { data, loading }

}

export function QueryGetCondicaoComercialByID({ variables }: QueryProps) {
  const { data, loading } = useQuery<GetImovelCondicaoComercialByIdResponse>(GET_IMOVEL_CONDICAO_COMERCIAL_BY_ID, {
    variables: { ...variables },
  })

  return { data, loading }

}




export function MutationDeleteCondicaoComercial() {


  const [deleteCondicao, { loading, data, error }] = useMutation<DeleteImovelCondicaoComercial>(
    DELETE_IMOVEL_CONDICAO_COMERCIAL,
    {
      refetchQueries: [GET_IMOVEL_CONDICAO_COMERCIAL],
    }
  )

  async function HandleDeleteCondicaoComercial({ variables }: QueryProps) {
    try {
      const result = await deleteCondicao({
        variables: { ...variables },
        refetchQueries: [GET_IMOVEL_CONDICAO_COMERCIAL],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return { HandleDeleteCondicaoComercial, loading, error, data }
}

export function MutationCreateCondicaoComercial() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<CondicaoComercialCreateFormData>({
    resolver: zodResolver(CondicaoComercialCreateFormSchema),
  })

  const [CondicaoComercial, { error, loading, data: DataCondicaoComercial }] =
    useMutation<SetImovelCondicaoComercial>(SET_IMOVEL_CONDICAO_COMERCIAL, {
      refetchQueries: [GET_IMOVEL_CONDICAO_COMERCIAL, GET_IMOVEL_CONDICAO_COMERCIAL_BY_ID],
    })

  async function FormCondicaoComercial(data: CondicaoComercialCreateFormData) {
    await CondicaoComercial({
      variables: {
        condicaoComercial: { ...data }
      },
    })

  }

  return {
    register, handleSubmit, FormCondicaoComercial, loading,
    errors, error, DataCondicaoComercial, setError, setValue,
    control,getValues
  }
}


export function MutationPutCondicaoComercial() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm<CondicaoComercialPutFormData>({
    resolver: zodResolver(CondicaoComercialPutFormSchema),
  })

  const [CondicaoComercial, { error, loading, data: DataCondicaoComercial }] =
    useMutation<PutImovelCondicaoComercial>(PUT_IMOVEL_CONDICAO_COMERCIAL, {
      refetchQueries: [GET_IMOVEL_CONDICAO_COMERCIAL],
    })

  async function FormCondicaoComercial(data: CondicaoComercialPutFormData) {

    await CondicaoComercial({
      variables: {
        condicaoComercial: { ...data }
      },
    })

  }


  return {
    register, handleSubmit, FormCondicaoComercial, loading,
    errors, error, DataCondicaoComercial, setError, setValue,
    control
  }
}
