import { z } from "zod"

const campos = {
    id: z.number(),
    nome: z.string().nonempty('Digite o nome do empreendimento!'),
    massa_id: z.number(),
    manual: z.number().optional(),
    area_terreno: z.number(),
    area_computavel: z.number(),
    area_excedente: z.number(),
    area_privativa: z.number(),
    fator_area_privativa: z.number(),
    // area_oodc: z.number(),
    fs_id: z.number(),
    fs: z.union([z.number().positive(), z.nan()]).optional(),
    // fp: z.union([z.number().positive(), z.nan()]).optional(),
    cabas: z.number(),
    camax: z.number(),
    uso: z.string().nonempty('Informe o uso!'),
    // has_beneficio: z.number(),
    // quadro14: z.number(),
    // ben_melhoramento: z.number(),
    // ben_fruicao: z.number(),
    unidades_imobiliarias: z.number(),
    area_privativa_por_unidade_imob: z.number(),
    // cepac_ben: z.number(),
    // cepac_area_onerosa: z.number(),
    // cepac_fc: z.number(),
    // cepac_qtd_cepac: z.number(),
    // cepac_valor_cepac: z.number(),
    // cepac_valor_total: z.number(),
    // uso_residencial: z.number(),
    // manual: z.number()
}

export const FormUpdateEmpreendimentoMassaUsoFormSchema = z.object(campos)
export type FormUpdateEmpreendimentoMassaUsoFormData = z.infer<typeof FormUpdateEmpreendimentoMassaUsoFormSchema>


delete campos.id
export const FormCreateEmpreendimentoMassaUsoFormSchema = z.object(campos)
export type FormCreateEmpreendimentoMassaUsoFormData = z.infer<typeof FormCreateEmpreendimentoMassaUsoFormSchema>

