import { useMutation, useQuery } from '@apollo/client'
import {
  TypesADMINGetProdutosResponse,
  TypesADMINDeleteProdutoResponse,
  TypesADMINGetProdutoResponse,
  TypesADMINGetUsuariosProdutosResponse,
  TypesADMINPutProdutoResponse,
  TypesADMINSetProdutoResponse,
} from '../types'
import { ADMIN_GET_PRODUTOS, ADMIN_GET_PRODUTO_BY_ID, ADMIN_GET_USUARIOS_PRODUTOS } from '../querys'
import { ADMIN_SET_PRODUTOS, DELETE_CUPOM, ADMIN_PUT_PRODUTO, ADMIN_DELETE_PRODUTO } from '../mutation'
import Swal from 'sweetalert2'
import { useForm } from 'react-hook-form'
import { putProdutoFormData, putProdutoFormSchema, setProdutoFormData, setProdutoFormSchema } from '../../formValidations'
import { zodResolver } from '@hookform/resolvers/zod'

interface ProdutosProps {
  variables: any
}

export function QueryGetProdutoByID({ variables }: ProdutosProps) {
  const { data, loading, error } = useQuery<TypesADMINGetProdutoResponse>(ADMIN_GET_PRODUTO_BY_ID, {
    variables: { ...variables },
  })

  return { data, loading, error }
}

export function ADMINQueryGetProdutos({ variables }: ProdutosProps) {
  const { data, loading, error } = useQuery<TypesADMINGetProdutosResponse>(ADMIN_GET_PRODUTOS, {
    variables: { ...variables },
  })

  return { data, loading, error }
}


export function ADMINQueryGetUsuariosProdutos({ variables }: ProdutosProps) {
  const { data, loading, error } = useQuery<TypesADMINGetUsuariosProdutosResponse>(ADMIN_GET_USUARIOS_PRODUTOS, {
    variables: { ...variables },
  })

  return {data, loading, error}
}



export function ADMINMutationDeleteProduto() {
  const [deleteProdutoBody, { loading, error }] = useMutation<TypesADMINDeleteProdutoResponse>(ADMIN_DELETE_PRODUTO)

  async function HandleDeleteProduto({ variables }: ProdutosProps) {
    try {
      const result = await deleteProdutoBody({
        variables: {...variables},
        refetchQueries: [ADMIN_GET_PRODUTOS],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return { HandleDeleteProduto, loading, error }
}


export function ADMINMutationCreateProdutos() {

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
    formState: { errors }
  } = useForm<setProdutoFormData>({
    resolver: zodResolver(setProdutoFormSchema)
  });

  console.log('Erro:',errors )

  const [setProdutoBody, { loading, data: DataSetProduto,error }] = useMutation<TypesADMINSetProdutoResponse>(ADMIN_SET_PRODUTOS, {
    refetchQueries: [ADMIN_GET_PRODUTOS]
  })

  async function submitForm(data: setProdutoFormData) {
    await setProdutoBody({
      variables: {
        produtoInput: {
          desconto: data.desconto,
          descricao: data.descricao,
          nome: data.nome,
          valor: data.valor,
          // subtitulo: data.subtitulo,
          tipo_produto: data.tipo_produto,
          situacao: data.situacao
        }
      }
    })
  };


  return { register, handleSubmit, submitForm, loading, errors, setError, setValue, getValues, control, error, DataSetProduto }
}

export function ADMINMutationPutProduto() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<putProdutoFormData>({
    resolver: zodResolver(putProdutoFormSchema),
  })

  const [putUsuario, { loading, error, data: DataPutProduto }] = useMutation<TypesADMINPutProdutoResponse>(ADMIN_PUT_PRODUTO)

  async function submitForm(data: putProdutoFormData) {
    await putUsuario({
      variables: {
        produto: {
          id: data.id,
          desconto: data.desconto,
          descricao: data.descricao,
          nome: data.nome,
          valor: data.valor,
          // subtitulo: data.subtitulo,
          tipo_produto: data.tipo_produto,
          situacao: data.situacao
        },
      },
      refetchQueries: [ADMIN_GET_PRODUTOS],
    })
  }

  return {
    submitForm, error, register, handleSubmit, loading,
    setError, setValue, getValues, control, DataPutProduto, errors
  }
}
