import {Modal} from 'react-bootstrap'
import {QueryGetExplorerUserProject} from '../../../../../../graphql/services/ExplorerLayerUsuarioProject'
import {Buttons} from './Buttons'
import { GetExplorerLayersUsuario } from '../../../../../../graphql/type/explorer-layer-usuario'
import { FIND_EXPLORER_LAYER_USUARIO } from '../../../../../../graphql/query/explorer-layer-usuario'
import { useQuery } from '@apollo/client'
import ProjetoInstance  from '../../../../Projeto'
import { Explorer } from '../../../Map/Explorer'
import { Endereco } from '../../../Map/ComponentsControl/EnderecoControl/endereco'
import { useEffect, useState } from 'react'
import { Loader } from '../../../../../../components/UI/Loader/Loader'

type Props = {
  show: boolean
  close: any
}

export const ModalAbrirProjeto: React.FC<Props> = ({show, close}) => {

  const [showDescatados,setShowDescatados] = useState(false)
  const {data} = QueryGetExplorerUserProject({
    variables: {all:showDescatados,pagination: {pagina: 0, quantidade: 100}},
  })

  useEffect(()=>{
    setShowDescatados(false)
  },[show])

  const municipio = Endereco.getMunicipio()
  let idMunicipio = 5351
  if (municipio) {
    idMunicipio = municipio.id
  }
    // const {onAddLayer} = useLayer()
    const {data:LayersUsuario} = useQuery<GetExplorerLayersUsuario>(FIND_EXPLORER_LAYER_USUARIO, {
        variables: { idMunicipio: idMunicipio },
    })

  if (!data) {
    return <Modal centered size='xl' animation show={show} onHide={() => close()}>
    <Modal.Header closeButton>
      <Modal.Title> Abrir Projeto</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Loader text={'Carregando...'}></Loader>
    </Modal.Body>
    </Modal>

  }
  if (!LayersUsuario) {
    return <Modal centered size='xl' animation show={show} onHide={() => close()}>
    <Modal.Header closeButton>
      <Modal.Title> Abrir Projeto</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Loader text={'Carregando...'}></Loader>
    </Modal.Body>
    </Modal>
  }

  function setIdOpen(id) {
    Explorer.removeALlLayers()
    ProjetoInstance.novoProjeto()
    const openuId = data.GetExplorerUserProject.filter((el) => el.id === id)[0]
    ProjetoInstance.setUsuarioProjeto(openuId)
    ProjetoInstance.setID(id)
    ProjetoInstance.setUsuarioLayers(LayersUsuario)
    ProjetoInstance.open()
  }

  function showHideDescartados(e) {
      if (e.target.checked){
        setShowDescatados(true)
      }else{
        setShowDescatados(false)
      }
  }

  return (
    <Modal centered size='xl' animation show={show} onHide={() => close()}>
      <Modal.Header closeButton>
        <Modal.Title> Abrir Projeto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h1>Lista camadas</h1> */}
        <div className='row'>
        <div className=' col-3 pb-6 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Exibir descartados
                </span>

                <input className='form-check-input' type='checkbox' value='1' defaultChecked={showDescatados} onClick={showHideDescartados} />
              </label>
            </div>
        </div>
        <table className='table table-striped pt-10'>
          <thead>
            <tr>
              <th>Id</th>
              <th>Nome</th>
              <th>Data Cadastro</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data.GetExplorerUserProject.map((el) => {
              return (
                <tr key={el.id}>
                  <td>{el.id}</td>
                  <td>{el.nome}</td>
                  <td>{el.data_cadastro}</td>
                  <td>
                    <Buttons id={el.id} setIdOpen={setIdOpen}></Buttons>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  )
}
