import React, { FormEvent, useEffect, useState } from "react";
import { TableProprietarios } from "./components/TableProprietarios";
import { useLocation } from 'react-router'
import { DrawerComponent } from '../../../_metronic/assets/ts/components'
import { Footer } from '../../../_metronic/layout/components/Footer'
import { AsideDefault } from "../../../_metronic/layout/components/aside/AsideDefault";
import { HeaderWrapperFluid } from "../../../_metronic/layout/components/header/HeaderWrapperFluid";
import { Form, FormLabel } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";

export function Proprietarios() {
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  const [toogleFilter, setToogleFilter] = useState(false)


  const [filter, setFilter] = useState([])


  function addFilter(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const filterArray = [];

    const fields = ['nome', 'email', 'telefone'];

    fields.forEach(field => {
      const value = form.querySelector<HTMLInputElement>(`[name="${field}"]`).value;
      if (value) {
        filterArray.push({ field: [field], operator: 'LIKE', value });
      }
    });

    setFilter(filterArray);
    return false;
  }


  function toggleTeste() {
    if (toogleFilter === false) {
      setToogleFilter(true)
    } else if (toogleFilter === true) {
      setToogleFilter(false)
    }
  }


  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />

      {/* <Empreendimentos /> */}
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <div className="py-6  d-flex align-items-center justify-content-between" id="kt_header_container">
                <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                  <h1 className="text-dark fw-bold my-0 fs-2">
                    Listagem
                  </h1>
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">
                      <a href="/metronic8/demo7/../demo7/index.html" className="text-muted">
                        Proprietários
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='d-flex align-items-center ms-3'>
                  <button
                    className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={toggleTeste}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filtros
                  </button>
                </div>
              </div>
              <div className="content  d-flex flex-column flex-column-fluid" id="kt_content" >
                <div id='acc' className={toogleFilter === true ? 'show' : ''}>
                  <div className='card-form px-4 py-12 mb-6'>
                    <Form className='row form form-horizontal' onSubmit={addFilter}>
                      <div className='row'>
                        <div className='col-md-3 mb-2'>
                          <FormLabel className='form-label'>Nome/Email</FormLabel>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Digite o nome'
                            name='nome'
                          />
                        </div>
                        <div className='col-md-3 mb-2'>
                          <FormLabel className='form-label'>Email</FormLabel>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Digite o E-mail'
                            name='email'
                          />
                        </div>
                        <div className='col-md-3 mb-2'>
                          <FormLabel className='form-label'>Telefone</FormLabel>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Digite o telefone do correotr'
                            name='telefone'
                          />
                        </div>
                        <div className='col-md-3 mb-2'>
                          <FormLabel className='form-label'>Empresa</FormLabel>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Digite o nome da empresa'
                            name='empresa'
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <div className=" all-end">
                          <button type='submit' className='btn btn-primary mt-2'>
                            Buscar
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className='col-xl-12'>
                  <TableProprietarios filter={filter} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}