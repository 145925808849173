import secureLocalStorage from 'react-secure-storage'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {HeaderCliente} from './Headers/HeaderCliente'
import {HeaderUrbit} from './Headers/HeaderUrbit'

type Props1Item = {
  dados: any
  acesso: boolean
}

export const HeaderFicha: React.FC<Props1Item> = ({dados, acesso}) => {
  // secureLocalStorage.setItem('cor_fonte',data.Login.empresa.cor_fonte)
  // secureLocalStorage.setItem('cor_background',data.Login.empresa.cor_background)
  // secureLocalStorage.setItem('user_pic_thumb',data.Login.user_pic_thumb)
  // secureLocalStorage.setItem('user_logo_empresa',data.Login.empresa.user_logo_pic)
  const empresa: any = secureLocalStorage.getItem('empresa')
  let dataEmpresa: any
  if (empresa) {
    dataEmpresa = JSON.parse(empresa)
  }
  let dadosEmpresa = null
  if (dataEmpresa) {
    dadosEmpresa = {
      nomeFantasia: dataEmpresa.nome,
      cnpj: dataEmpresa.cnpj,
      endereco:
        dataEmpresa.endereco +
        ',' +
        dataEmpresa.numero +
        ' - ' +
        dataEmpresa.bairro +
        ', ' +
        dataEmpresa.estado +
        ' - ' +
        dataEmpresa.cep,
      whatsapp: dataEmpresa.telefone,
      emailContato: dataEmpresa.email,
    }
  }

  return (
    <>
      <div className='d-flex'>
        <div className=' d-flex flex-column flex-column-fluid'>
          {dadosEmpresa ? (
            <img alt='Logo' src={dataEmpresa.user_logo_pic} className='w-200px' />
          ) : (
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='w-200px' />
          )}
        </div>
        {dadosEmpresa ? (
          <HeaderCliente dadosEmpresa={dadosEmpresa}></HeaderCliente>
        ) : (
          <HeaderUrbit></HeaderUrbit>
        )}
      </div>
      <hr className='shadow-clean border-dashad mb-10 mt-8' />
    </>
  )
}
