import { z } from "zod"

export const TermosFormSchema = z.object({
    nome: z.string()
        .nonempty('O Nome é obrigatório!'),
    titulo: z.string()
        .nonempty('O Titulo é obrigatório!'),
    texto: z.string()
    .nonempty('O texto é obrigatório!'),
});

export type TermosFormData = z.infer<typeof TermosFormSchema>

export const TermosPutFormSchema = z.object({
    id: z.number(),
    nome: z.string()
        .nonempty('O Nome é obrigatório!'),
    titulo: z.string()
        .nonempty('O Titulo é obrigatório!'),
    texto: z.string()
    .nonempty('O texto é obrigatório!'),
});

export type TermosPutFormData = z.infer<typeof TermosPutFormSchema>