/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Tipology } from './types'
import { ExportTable } from '../../../utils/ExportXLSX'
import { CurrencyFormat } from '../../../components/util'

type Props = {
    className: string
    tipologies: Tipology[]
}



const TableLancamento: React.FC<Props> = ({ tipologies }) => {

    function handleExport (){

        const dataToExport= []
        // eslint-disable-next-line array-callback-return
        tipologies.map((Response) => {
            dataToExport.push({
                'Tipo':Response.type,
                'Área Privativa':Response.private_area,
                'Quartos':Response.bedrooms,
                'Banheiros':Response.bathrooms,
                'Suítes':Response.suites,
                'Vagas':Response.parking,
                "Total de Unidades":Response.total_units,
                "Estoque":Response.stock,
                "Preço Original":Response.original_price,
                "Preço com desconto":Response.discount_price
            })
        })
        ExportTable(dataToExport)
    }

    return (<>
    <div className='d-flex justify-content-end'>
        <button onClick={handleExport} className='btn btn-primary my-5' >
        Exportar para XLSX
      </button>
      </div>
        <div className='table-responsive'>
        <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4' >
            {/* begin::Table head */}
            <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                    <th className=' min-w-100px rounded-start'>Tipo</th>
                    <th className='min-w-105px text-center'>Área Privativa</th>
                    <th className='min-w-80px text-center'>Quartos</th>
                    <th className='min-w-80px text-center '>Banheiros</th>
                    <th className='min-w-80px text-center'>Suítes</th>
                    <th className='min-w-80px text-center'>Vagas</th>
                    <th className='min-w-80px text-center '>Total de Unidades</th>
                    <th className='min-w-80px text-center '>Estoque</th>
                    <th className='min-w-80px text-center '>Vendidas</th>
                    <th className='min-w-80px text-center'>% Vendas</th>
                    <th className='min-w-150px text-right'>Preço Original</th>
                    <th className='min-w-150px text-right'>Preço m2</th>
                    
                </tr>
            </thead>
            <tbody>
                {tipologies ? tipologies.map((Response) => (
                    <tr key={Response.id_tipologia}>
                      
                            <td>
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                        <p className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                            {Response.type}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p className='text-dark fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                                    {Response.private_area}
                                </p>
                            </td>
                            <td>
                                <p className='text-dark fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                                    {Response.bedrooms}

                                </p>
                                </td>
                            <td>
                            <p className='text-dark fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                                    {Response.bathrooms}

                                    </p>
                            </td>
                            <td>
                                <p className='text-dark fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                                    {Response.suites}
                                </p>
                            </td>
                            <td>
                                <p className='text-dark fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                                    {Response.parking}
                                </p>
                            </td>
                            <td>
                                <p className='text-dark fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                                    {Response.total_units}
                                </p>
                            </td>
                            <td>
                                <p className='text-dark fw-bold text-hover-primary d-block mb-1 text-center fs-6'>
                                    {Response.stock}

                                </p>
                            </td>
                            <td>
                                <p className='text-dark fw-bold text-hover-primary d-block mb-1 text-center fs-6'>
                                    {Response.total_units - Response.stock}

                                </p>
                            </td>
                            <td>
                                <p className='text-dark fw-bold text-hover-primary d-block mb-1 text-center fs-6'>
                                    {(
                                ((Response.total_units - Response.stock) /
                                Response.total_units) *
                                100
                              ).toFixed(0) + '%'}

                                </p>
                            </td>
                            <td>
                                <p className='text-dark fw-bold text-hover-primary d-block mb-1 text-right fs-6'>
                                    {CurrencyFormat(Response.original_price)}
                                </p>
                            </td>
                            <td>
                                <p className='text-dark fw-bold text-hover-primary d-block mb-1  text-right fs-6'>
                                    {CurrencyFormat(Response.original_price/Response.private_area) }
                                </p>
                            </td>
                        
                    </tr>
                )) : <></>}

            </tbody>
        </table>
        </div>
        </>
    )
}

export { TableLancamento }
