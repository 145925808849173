import React, { useState } from 'react'
// import { TableEmpreendimentosOff } from './TableEmpreendimentosOff'
import { QueryGetImoveis } from '../../../../graphql'
import { KTSVG } from '../../../../../_metronic/helpers'
import Pagination from '../../../../components/UI/Pagination'
import { Loader } from '../../../../components/UI/Loader/Loader'
import { DateTimToView } from '../../../../components/util'

// import {GetEmpreendimentoTipoChosen} from '../components/Chosen/GetEmpreendimentoTipoChosen'
type Props = {
  active: boolean,
  filtro: any[]
  toggleFilter: any
  toggleInfos: any
  statusCliete:any
}

const TableRelatorio: React.FC<Props> = ({ active, filtro, toggleFilter, toggleInfos,statusCliete }) => {
  let result

  if (active === true) {
    result = 0
  } else if (active === false) {
    result = 1
  }
  const [checkDownload, setCheckDownload] = useState([])
  const isChecked = (id: number) => {
    return checkDownload.includes(id);
  };

  function getStatusCliente(statusCliete, idItem){
    let status
    statusCliete.GetImovelSituacaoCliente.result.map((el)=>{
      if  (el.id === idItem){
        status = el.nome
        return el.nome
      }
    })

    return status

  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const checked = event.target.checked;
    if (checked) {
      setCheckDownload((prevCheckDownload) => [...prevCheckDownload, id]);
    } else {
      setCheckDownload((prevCheckDownload) => prevCheckDownload.filter((item) => item !== id));
    }
  };

  const [pages, setPages] = useState(0)

  const {data} = QueryGetImoveis({
    variables: {
      pagination: { pagina: pages, quantidade: 10 },
      "filtro": {
        "fields": filtro
      }, situation: result
    },
  })

  // Tratativa de loading
  if (!data) {
    return (
      <div className='card  px-10 py-6'>
        <div className='border-0'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='card-xxl-stretch '>
                <div className='card-header border-0    p-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil003.svg'
                        className='svg-icon-1 svg-icon-gray-500 me-1'
                      />
                      Relatórios do Empreendimento</span>
                  </h3>
                  <div
                    className='card-toolbar all-end'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                  >
                    <button
                      className='me-4 btn btn-sm btn-flex btn-light btn-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={toggleInfos}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen046.svg'
                        className='svg-icon-5 svg-icon-gray-500 me-1'
                      />
                      Infos
                    </button>
                    <button
                      className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={toggleFilter}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen031.svg'
                        className='svg-icon-5 svg-icon-gray-500 me-1'
                      />
                      Filtros
                    </button>

                    {checkDownload.length !== 0 ?
                      <button
                        className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={toggleFilter}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen031.svg'
                          className='svg-icon-5 svg-icon-gray-500 me-1'
                        />
                        Download
                      </button>
                      :
                      ''
                    }
                  </div>
                  {/* <div className='all-end'></div> */}
                </div>
                {/* end::Header */}
                <div className='separator my-2'></div>
                <Loader text={'caregando...'}></Loader>
              </div>
              <div className='card-footer all-end'>
                <Pagination setPages={setPages} pages={pages} total={100} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {' '}
      <div className='card  px-10 py-6'>
        <div className='border-0'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='card-xxl-stretch '>
                <div className='card-header border-0    p-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil003.svg'
                        className='svg-icon-1 svg-icon-gray-500 me-1'
                      />
                      Relatórios do Lote</span>
                  </h3>
                  <div
                    className='card-toolbar all-end'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                  >
                    <button
                      className='me-4 btn btn-sm btn-flex btn-light btn-light-warning'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={toggleInfos}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen046.svg'
                        className='svg-icon-5 svg-icon-gray-500 me-1'
                      />
                      Infos
                    </button>
                    <button
                      className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={toggleFilter}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen031.svg'
                        className='svg-icon-5 svg-icon-gray-500 me-1'
                      />
                      Filtros
                    </button>
                    {checkDownload.length !== 0 ?
                      <button
                        className='me-4 btn btn-sm btn-flex btn-light btn-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={toggleFilter}
                      >
                        <KTSVG
                          path='/media/icons/duotune/files/fil009.svg'
                          className='svg-icon-5 svg-icon-gray-500 me-1'
                        />
                        Download
                      </button>
                      :
                      ''
                    }
                  </div>
                  {/* <div className='all-end'></div> */}
                </div>
                {/* end::Header */}
                <div className='separator my-2'></div>
                {/* begin::Body */}
                <div>
                  {/* begin::Table container */}
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                          <th className=''>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input widget-9-check'
                                type='checkbox'
                                value='all'
                              />
                            </div>
                          </th>
                          <th className=''>Nome do Lote</th>
                          <th className=''>Número</th>
                          <th className=''>Cidade</th>
                          <th className=''>Bairro</th>
                          <th className=''>Área do Lote (m²)</th>
                          <th className=''>Zona</th>
                          <th className=''>Operação Urbana</th>
                          <th className=''>Status</th>
                          <th className=''>Cadastrado Por</th>
                          <th className=' text-end'>Data Cadastro</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {data.GetImoveis.result.map((Response) => {
                          return (
                            <tr key={Response.id}>
                              <td className=''>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input widget-9-check'
                                    type='checkbox'
                                    checked={isChecked(Response.id)}
                                    onChange={(event) => handleCheckboxChange(event, Response.id)}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="d-flex justify-content-start flex-column">
                                  <span className="text-dark fw-bold text-hover-primary fs-6">{Response.nome}</span>
                                  <span className="text-muted fw-semiboldd-block fs-7">{Response.endereco}</span>
                                </div>

                              </td>
                              <td className='fw-bolder'>
                                {Response.numero}
                              </td>
                              <td className='fw-bolder'>
                                {Response.cidade}
                              </td>
                              <td className='fw-bolder'>
                                {Response.bairro}
                              </td>
                              <td className='fw-bolder'>
                                {Response.area_real ? Response.area_real + 'm²' : ''}
                              </td>
                              <td className='fw-bolder'>
                                {Response.zoneamento}
                              </td>
                              <td className='fw-bolder'>
                                {Response.operacao_urbana}
                              </td>
                              <td className='fw-bolder'>
                                {getStatusCliente(statusCliete,Response.status_cliente)}
                              </td>
                              <td className='fw-bolder'>{Response.users.name}</td>
                              <td className='all-end text-muted fw-bolder'>
                                <td>{DateTimToView(Response.data_cadastro)}</td>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>

                      {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                  </div>
                  {/* end::Table container */}
                </div>
                <div className='card-footer all-end'>
                  <Pagination setPages={setPages} pages={pages} total={100} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { TableRelatorio }
