import { gql } from "@apollo/client";

export const GET_IMOVEL_TIPOS = gql` 
query GetImovelTipo {
    GetImovelTipo {
      id
      nome
      situacao
    }
  }
`