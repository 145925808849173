import {Button, Modal} from 'react-bootstrap'
import ProjetoInstance from '../../../../Projeto'
import {Explorer} from '../../../Map/Explorer'
import {useEffect, useState} from 'react'
import {
  MutationPutExplorerUserProject,
  MutationSetExplorerUserProject,
} from '../../../../../../graphql/services'
import {errorHandler} from '../../../../../../graphql/errorHandler'
import {MutationSetLogAcessoRecurso} from '../../../../../../graphql/services/LogAcesso'
import Swal from 'sweetalert2'

type Message = {
  error: boolean
  message: string
  data: any
}

type Props = {
  id: number
  setIdOpen?: any
}

export const ButtonSalvar: React.FC<Props> = ({id, setIdOpen}) => {
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const [show, setShow] = useState(false)
  const [nome, setNome] = useState('')
  const [message, setMessage] = useState<Message>({error: null, message: null, data: null})
  const {FormPutExplorerUserProject, loading} = MutationPutExplorerUserProject()
  const {FormSetExplorerUserProject, loading: loadingSet} = MutationSetExplorerUserProject()

  useEffect(() => {
    if (nome) {
      ProjetoInstance.setNome(nome)
      document.getElementById('nomeprojeto').innerHTML = nome
      document.getElementsByName('nomeProjeto')[0].innerHTML = nome
    }
  }, [nome])

  if (!loading) {
    // Swal.close()
  }

  if (!loadingSet) {
    // Swal.close()
  }
  function salvar() {


    Swal.fire({
      title: 'Deseja salvar o projeto?',  
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Salvar',
      showCloseButton: true,
  }).then(async (result) => {
      if (result.isConfirmed) {
        FormSetLogAcessoRecurso({
          ferramenta: 'explorer',
          funcionalidade: 'salvar-projeto',
          action: 'click',
          url: '/explorer',
        })

        const data = ProjetoInstance.salvar(ProjetoInstance.getNome())
        if (ProjetoInstance.getID()) {
          setShow(false)
          FormPutExplorerUserProject({
            id: ProjetoInstance.getID(),
            nome: ProjetoInstance.getNome(),
            json: data,
          })
            .then(async (result) => {
              if (!result.data) {
                setMessage({
                  error: true,
                  message: errorHandler(result.errors),
                  data: null,
                })
              } else {
                ProjetoInstance.setID(result.data.PutExplorerUserProject.id)
                alert('Projeto Salvo.')
              }
            })
            .catch((result) => {
              console.log(result)
            })
        } else {
          setShow(true)
        }
      }
    }
  )
  }
  function salvarProjeto() {
    const data = ProjetoInstance.salvar(ProjetoInstance.getNome())
    if (ProjetoInstance.getID()) {
      FormPutExplorerUserProject({
        id: ProjetoInstance.getID(),
        nome: ProjetoInstance.getNome(),
        json: data,
      })
        .then(async (result) => {
          if (!result.data) {
            setMessage({
              error: true,
              message: errorHandler(result.errors),
              data: null,
            })
          } else {
            ProjetoInstance.setID(result.data.PutExplorerUserProject.id)
            alert('Projeto atualizado.')
          }
        })
        .catch((result) => {
          setMessage({
            error: true,
            message: errorHandler(result.errors),
            data: null,
          })
        })
      setShow(false)
    } else {
      FormSetExplorerUserProject({
        nome: ProjetoInstance.getNome(),
        json: data,
      })
        .then(async (result) => {
          if (!result.data) {
            setMessage({
              error: true,
              message: errorHandler(result.errors),
              data: null,
            })
          } else {
            ProjetoInstance.setID(result.data.SetExplorerUserProject.id)
            alert('Projeto Criado.')
          }
        })
        .catch((result) => {
          console.log(result)
        })

      setShow(false)
    }
  }
  const handleClose = () => {
    setMessage({
      error: null,
      message: null,
      data: null,
    })
    setShow(false)
  }

  return (
    <>
      <button
        className=' btn btn-bg-light btn-active-color-success btn-sm'
        data-id={id}
        onClick={() => {
          salvar()
        }}
      >
        <i className='fs-5  bi bi-folder-plus'></i>
        Salvar
      </button>
      <Modal centered animation show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Salvar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message.error ? (
            <>
              <p>{message.message}</p>
            </>
          ) : Explorer.getCountLayers() === 0 ? (
            <>
              <p>O Projeto está vazio, deseja salvar mesmo assim?</p>
              <label>Nome do projeto</label>
              <input
                type='nome'
                id='nome'
                defaultValue={ProjetoInstance.getNome()}
                className='form-control'
                onChange={(e) => {
                  setNome(e.target.value)
                }}
              />
            </>
          ) : (
            <>
              <p>Salvar Projeto?</p>
              <label>Nome do projeto</label>
              <input
                type='nome'
                id='nome'
                defaultValue={ProjetoInstance.getNome()}
                className='form-control'
                onChange={(e) => {
                  setNome(e.target.value)
                }}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' data-dismiss='modal' onClick={handleClose}>
            Fechar
          </Button>
          <Button variant='primary' data-dismiss='modal' onClick={salvarProjeto}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
