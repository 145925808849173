import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import {Explorer} from '../../Explorer'
import {formatDistancia} from '../../../common'

type Props = {
  distancia: Number
  servico: {}
  template: string
  label: string
  activeAll: boolean
}

export const MenuItemGrupo: React.FC<Props> = ({
  distancia,
  template,
  servico,
  label,
  activeAll,
}) => {

   const [active,setActive]  =  useState(false)

  let templateResult = template

  Object.entries(servico).map(function (x) {
    let chave = x[0]
    let valor: any
    valor = x[1]
    templateResult = templateResult.replace('${' + chave + '}', valor)
    return x
  })

  const templateParsed = ReactHtmlParser(templateResult)

  function addGeom(templateText, parsed, event) {
    if (event.target.checked) {
      if (typeof servico['geom'] !== 'undefined') {
        const geom = JSON.parse(servico['geom'])
        const type = geom.type
        let GeoJSON = {
          type: 'Feature',
          geometry: geom,
          properties: {area: 0},
        }
        let layer = ''
        if (type === 'Point') {
          layer = Explorer.addMarker(geom.coordinates[1], geom.coordinates[0], parsed, templateText)
        } else if (type === 'MultiLineString') {
          layer = Explorer.addGeom(GeoJSON, ` ${templateText}`, null, {
            'fill-color': '#004DE5',
            'line-width': 2,
            'line-color': '#004DE5',
          })
        } else {
          layer = Explorer.addGeom(GeoJSON, ` ${templateText}`)
        }
        event.target.dataset.id = layer
      }
      setActive(true)
    } else {
      Explorer.removeLayer(event.target.dataset.id)
      delete event.target.dataset.id
      setActive(false)
    }
  }


  return (
    <div>
      <div className='menu-item my-1 mx-2'>
        <div className='menu-link ps-2 align-items-start'>
          <span className='menu-icon ps-5 pe-8  pt-2'>
            <div className='form-check form-switch form-check-custom form-check-solid'>
              <input
                className='form-check-input h-20px w-30px'
                type='checkbox'
                value={1}
                checked={activeAll || active}
                id='flexSwitchDefault'
                onClick={(e) => {

                 
                    
                    addGeom(
                      templateResult,
                      Array.isArray(templateParsed) ? templateParsed[0] : templateResult,
                      e
                    )
                
                  
                  
                }}
                onChange={() => {}}
              />
            </div>
          </span>
          <span
            className='d-flex justify-content-between justify-content-start '
            style={{flex: 'auto'}}
          >
            {templateParsed}
            <span className='text-success pl-5 pull-right w-20 text-right '>
              {' '}
              {formatDistancia(servico['distancia'])}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}
