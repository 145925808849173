export let produtosArray = [
    {
      id_periodo: 1,
      name: 'Free'
    },
    {
      id_periodo: 2,
      name: 'Mensal'
    },
    {
      id_periodo: 3,
      name: 'Trimestral (em 3x sem juros)'
    },
    {
      id_periodo: 4,
      name: 'Semestral'
    },
    {
      id_periodo: 5,
      name: 'Anual'
    },
    {
      id_periodo: 6,
      name: 'Personalizada'
    }
  ]