import { gql } from "@apollo/client";

export const GET_PACOTES = gql`
query GetPacotes($filtro: filter, $pagination: Pagination) {
  GetPacotes(filtro: $filtro, pagination: $pagination) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      id
      nome
      descricao
      situacao
      users {
        email
        id
        name
      }
      produtos {
        nome
        id
        valor
      }
    }
  }
}
`

export const GET_PACOTE_BY_ID = gql`
query GetPacote($getPacoteId: Float!) {
  GetPacote(id: $getPacoteId) {
    id
    id_servico
    id_pacote
    situacao
    pacote {
      id
      nome
      descricao
      id_produto
    }
    service {
      id
      nome
    }
  }
}
`