import {Text, View, StyleSheet} from '@react-pdf/renderer'

const styles = StyleSheet.create({
  row: {
    position: 'absolute',
    flexDirection: 'row',
    borderTopColor: '#dfdfdf',
    borderTopWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize: 8,
    fontStyle: 'bold',
    margin: 20,
    bottom: 0,
    color: 'grey',
    left: 0,
    right: 0,
  },
  description: {
    fontSize: 8,
    width: '75%',
    textAlign: 'right',
    paddingRight: 8,
  },
  total: {
    fontSize: 8,
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
  pageNumber: {
    fontSize: 8,
  },
  sobre: {
    fontSize: 8,
    marginLeft: 15,
    flexDirection: 'row',
    width: '15%',
  },
})

export const Footer = () => {
  return (
    <View style={styles.row} fixed>
      <Text style={styles.sobre}>Powered by Urbit</Text>
      <Text style={styles.description}>TOTAL</Text>
      <Text
        style={styles.pageNumber}
        render={({pageNumber, totalPages}) => {
          return `${pageNumber} / ${totalPages ? totalPages : pageNumber}`
        }}
        fixed
      />
    </View>
  )
}
