import React, {useState} from 'react'
import Swal from 'sweetalert2'
import {EditFormImoveProprietarios} from './EditForm'
import {KTSVG} from '../../../../_metronic/helpers'
import {MutationDeleteProprietarios} from '../../../graphql/services/Proprietarios'
// import { APiBigDataCorp } from '../../../../../../../services/api/apiBigDataCorp'

type Props = {
  id: number
  idProprietario: number
  idTerreno: number
}

export const Buttons: React.FC<Props> = ({id, idProprietario, idTerreno}) => {
  const {HandleDeleteProprietarios, loading} = MutationDeleteProprietarios()

  if (loading) {
    Swal.fire('Enviando Informações...', '')
    Swal.showLoading()
  }

  const [idEditProprietario, setIdProprietario] = useState(null)
  const [showEdit, setShowEdit] = useState(false)

  function CloseModalEdit() {
    setShowEdit(false)
    setIdProprietario(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setIdProprietario(id)
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(idEditProprietario)

        const result = await HandleDeleteProprietarios({
          variables: {deleteProprietarioId: idProprietario},
        })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Proprietario deletado com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-center flex-shrink-0'>
        <button
          onClick={(e) => {
            OpenModalEdit(id)
          }}
          className='btn btn-icon btn-light-success btn-sm me-2'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button>
        {showEdit ? (
          <EditFormImoveProprietarios
            idProprietario={idProprietario}
            closeModal={CloseModalEdit}
            show={showEdit}
          />
        ) : (
          <></>
        )}

        <button
          onClick={(e) => {
            confirmDelete(id)
          }}
          className='btn btn-icon btn-light-danger btn-sm me-2'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
