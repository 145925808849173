/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {FaUser} from 'react-icons/fa'
import { GetEmpresaUsuario, GetProdutosUsuario } from '../../../../app/utils/PermissoesAcesso'

type Props = {
  imageProfile: string
}

function HeaderUserMenu({imageProfile}: Props) {
 
  const nome: string | null = localStorage.getItem('name')
  const email: string | null = localStorage.getItem('email')
  let dadosEmpresa = GetEmpresaUsuario()
  let dadosProdutos = GetProdutosUsuario()
  
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      id={'kt_header_user_menu_toggle'}
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol  me-5 '>
            {imageProfile !== 'null'  && imageProfile !== null ? (
              <img src={imageProfile} alt='' className='logo' />
            ) : (
              <FaUser size={24} className='fs-4 text-secondary border-1 p-0' />
            )}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {nome}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          Meu Perfil
        </Link>
      </div>

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>Meus Locais</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>Meus Produtos</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          {dadosProdutos && dadosProdutos.length === 0 ? (
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-5'>
                Plano Grátis
              </a>
            </div>
          ) : (
            ''
          )}
          {
            // eslint-disable-next-line array-callback-return
            dadosProdutos ?  dadosProdutos.map((item, y) => {
              return (
                <div className='menu-item px-3' key={y}>
                  <a href='#' className='menu-link px-5'>
                    {item.nome}
                  </a>
                </div>
              )
            }) : ''
          }

          {/* <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div> */}

          {/* <div className='separator my-2'></div> */}

          {/* <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div> */}
        </div>
      </div>
      {dadosEmpresa ? (
        <>
          <div className='separator my-2'></div>
          <div className='menu-item px-3 pb-5user_pic_thumb'>
            <div className='menu-content d-flex align-items-center px-3'>
              <div className='symbol symbol-50px me-5'>
                {dadosEmpresa.user_logo_pic ? (
                  <img src={`${dadosEmpresa.user_logo_pic}`} alt='' className='logo' />
                ) : (
                  <FaUser className='fs-2 border-1' />
                )}
              </div>

              <div className='d-flex flex-column'>
                <div className='fw-bolder d-flex align-items-center fs-5'>
                  {dadosEmpresa.nome}
                  {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
                </div>
                <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                  CNPJ:{dadosEmpresa.cnpj}
                  {/* {dadosEmpresa.tipo_usuario} */}
                </a>
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          Empreendimentos
        </a>
      </div> */}

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <Link to='/logout' className='menu-link px-5'>
          Sair
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
