import { TableObservacoes } from './partials/TableObservacoes'

type Props = {
  param: string
}

export function TabObservacoes({ param }: Props) {

  return (
    <div
      className={`tab-pane fade ${param === 'observacoes' ? 'show active' : ''}`}
      id='observacoes' role='tabpanel' aria-labelledby='observacoes'>
      <div className='card'>
        <TableObservacoes />
      </div>
    </div>
  )
}
