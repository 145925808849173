import { z } from "zod"

export const FormCreateEmpreendimentoMassaFormSchema = z.object({
    nome: z.string().nonempty('Digite o nome do empreendimento!'),
    id_empreendimento: z.number(),
})

export type FormCreateEmpreendimentoMassaFormData = z.infer<typeof FormCreateEmpreendimentoMassaFormSchema>

export const FormUpdateEmpreendimentoMassaFormSchema = z.object({
    id: z.number(),
    nome: z.string().nonempty('Digite o nome do empreendimento!'),
    id_empreendimento: z.number(),
})

export type FormUpdateEmpreendimentoMassaFormData = z.infer<typeof FormUpdateEmpreendimentoMassaFormSchema>

