import {useContextSelector} from 'use-context-selector'
import {ExplorerContext} from '../context/ExplorerContext'

export function useExplorer() {
  const explorer = useContextSelector(ExplorerContext, (mapa) => { 
    return mapa['explorer']
  })
  const onSetExplorer = useContextSelector(ExplorerContext, (mp) => {
    return mp['onSetExplorer']
  } )
  return {explorer, onSetExplorer}
}
