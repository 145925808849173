import {useEffect, useState} from 'react'
import {RenderComponent} from '../../components'

interface Props {
  itens: []
  latLng: google.maps.LatLng
  activeTab: any
}
export const Index: React.FC<Props> = ({itens, latLng, activeTab}) => {
  const [result, setResult] = useState(null)

  useEffect(() => {
    setResult(true)
  }, [latLng])


  return (
    <>
      <div className='row d-flex justify-content-between'>
        <h1 className='text-gray-800 fs-2 fw-bolder py-10 mx-1 m-0 col-5'>
          URBIT Índices Territoriais
        </h1>
        
      </div>
      <div className='row'>
        {result ? (
          itens ? (
            // eslint-disable-next-line array-callback-return
            itens['categoria'].map(function (categoria: {subnivel: number; servico: any[]}) {
              if (categoria.subnivel === 0) {
                return categoria.servico.map((servico, index) => {
                  return (
                    <div
                      className='col-md-4 col-lg-4 col-xl-3 col-sm-6  '
                      key={'renderComponent_' + index}
                    >
                      <RenderComponent
                        tipo_saida={servico.tipo_saida}
                        chart_type={servico.chart_type}
                        servico={servico}
                        latLng={latLng}
                      ></RenderComponent>
                    </div>
                  )
                })
              }
            })
          ) : (
            ''
          )
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
