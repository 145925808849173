import React from 'react'
import { ButtonsAction } from './ButtonsAction'

export function Table() {

    return (
        <>
            <div>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-striped gs-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bolder text-muted'>
                                <th className='min-w-20px'>Id</th>
                                <th className='min-w-140px'>Dt. de Criação</th>
                                <th className='min-w-80px'>CPF</th>
                                <th className='min-w-150px'>Cliente</th>
                                <th className='min-w-100px'>Valor do Pedido</th>
                                <th className='min-w-180px'>Situação</th>

                                <th className='min-w-100px text-end'>Ação</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        <tbody>
                            <tr>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex justify-content-start flex-column'>
                                            <p className='text-dark text-hover-primary fs-6'>
                                                323
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className='text-dark text-hover-primary d-block fs-6'>
                                        00/00/0000
                                    </p>
                                </td>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex justify-content-start flex-column'>
                                            <p className='text-dark text-hover-primary fs-6'>
                                                000.000.000-00
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className='text-start'>
                                    <span className='text-dark fs-6'>
                                        JÕAO SANTOS
                                    </span>
                                </td>
                                <td className='text-start'>
                                    <span className='text-dark fs-6'>
                                        R$: 100,00
                                    </span>
                                </td>
                                <td className='text-start'>
                                    <p className='text-dark fs-6'>
                                        o pagamento foi aprovado e quitado
                                    </p>
                                </td>
                                <td>
                                    <ButtonsAction id={1} />
                                </td>
                            </tr>
                        </tbody>

                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </>
    )
}

