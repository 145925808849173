import React, {useState} from 'react'
import {Grupo} from './Grupo'
import {GetServicoPacoteResponse} from '../../../../../../graphql/type/servicoPacote'
import {useQuery} from '@apollo/client'
import {GET_SERVICO_PACOTE} from '../../../../../../graphql/query/servico-pacote'
import Scrollbars from 'react-custom-scrollbars-2'
import {Layout} from '../../Layout'
import {Offcanvas} from 'react-bootstrap'
import {MenuAction} from '../MenuAction'
import {Endereco} from '../../ComponentsControl/EnderecoControl/endereco'

type Props = {
  showHide: boolean
  latLng: google.maps.LatLng
}

export const MenuTerritorio: React.FC<Props> = ({showHide, latLng}) => {
  const windowHeight = window.innerHeight - 250
  const [search, setSearch] = useState('')
  const [wsize, setWsite] = useState(windowHeight)
  const handleClose = () => {
    Layout.setShowTerritorioMenu(false)
  }
  window.addEventListener(
    'resize',
    function (event) {
      setWsite(window.innerHeight - 250)
    },
    true
  )
  const municipio = Endereco.getMunicipio()
  const {data} = useQuery<GetServicoPacoteResponse>(GET_SERVICO_PACOTE, {
    variables: {idMunicipio: municipio.id},
  })

  let filterData = []
  let filtroServico = []

  if (data) {
    filterData =
      search.length > 0
        ? // eslint-disable-next-line array-callback-return
          data.GetServicoPacote.filter((repo) => {
            filtroServico = repo.servico.filter(function (servico) {
              if (String(servico['nome']).toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                return true
              } else {
                return false
              }
            })
            if (
              repo.nome.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              filtroServico.length > 0
            ) {
              return true
            }
          })
        : data.GetServicoPacote
  }

  if (latLng == null || Object.entries(latLng).length === 0) {
    return <></>
  }
  const lat = latLng.lat()
  const lng = latLng.lng()
  
  return (
    <Offcanvas show={showHide} tabIndex={null} onHide={handleClose} id='offcanvas-direita-feicao'>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Dados territoriais</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {/* <Scrollbars style={{width: '100%', height: wsize}}> */}
          <div style={{width: '100%', padding: '10px'}} className='menu-fit  p-0'>
            <MenuAction showHide={false} latLng={latLng}></MenuAction>
            <ul className='nav nav-explorer d-flex justify-content-around mt-0' role='tablist'>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link active '
                  id='territorio-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#territorio'
                  type='button'
                  role='tab'
                  aria-controls='territorio'
                  aria-selected='true'
                >
                  Território
                </button>
              </li>

              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link'
                  id='legal-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#legal-base'
                  type='button'
                  role='tab'
                  aria-controls='legal-base'
                  aria-selected='false'
                >
                  Legal
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link'
                  id='cadastro-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#cadastro-base'
                  type='button'
                  role='tab'
                  aria-controls='cadastro-base'
                  aria-selected='false'
                >
                  Cadastro
                </button>
              </li>
            </ul>
            <div className='tab-content p-0 scroll p-5' id='tabContent'>
              <div
                id='territorio'
                className='tab-pane fade show active'
                role='tabpanel'
                aria-labelledby='territorio-tab'
              >
                <div>
                  <div className='mb-0 mb-0'>
                    {/* <div className='d-flex flex-column  fv-row fv-plugins-icon-container p-5'>
                  <div className='form-group row'>
                    <label className='form-label col-form-label col-sm-2'> Distância</label>
                    <div className='col-sm-6'>
                      <select className='form-control'>
                        <option value='500'>em 500m</option>
                        <option value='1000'>em 1000m</option>
                        <option value='1500'>em 1500m</option>
                        <option value='2000'>em 2000m</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                    <input
                      className='form-control border-0 mt-2 mb-2'
                      id='busca'
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder='Buscar'
                    />
                  </div>

                  <Scrollbars style={{width: '100%', height: wsize}}>
                    <div
                      className='accordion accordion-flush scroll'
                      id='accordionFlushExample_territorio'
                    >
                      {!data ? (
                        <>Carregando..</>
                      ) : (
                        // eslint-disable-next-line array-callback-return
                        filterData.map(function (response, x) {
                          if (response.id_agrupamento === 2) {
                            // eslint-disable-next-line array-callback-return
                            return [
                              <div
                                key={x}
                                className=' titulo-servicos-grupo'
                                // style={{borderBottom: '1px solid #eeeeee'}}
                              >
                                <h2
                                  className=' d-flex fs-4'
                                  style={{float: 'left', marginLeft: '15px', paddingTop: '15px'}}
                                >
                                  {response['nome']}
                                </h2>
                                {/* <Dropdown className='   ' style={{float: 'right'}}>
                                  <Dropdown.Toggle className='btn btn-light py-1 px-3 dropdown-toggle'>
                                    <i className='bi bi-binoculars'></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item href='#'>500</Dropdown.Item>
                                    <Dropdown.Item href='#'>1000</Dropdown.Item>
                                    <Dropdown.Item href='#'>1500</Dropdown.Item>
                                    <Dropdown.Item href='#'>2000</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown> */}
                              </div>,

                              // eslint-disable-next-line array-callback-return
                              response.servico.map(function (response2, y) {

                                if (response2.url_api !== '') {
                                  if (
                                    response2['nome']
                                      .toLowerCase()
                                      .indexOf(search.toLowerCase()) !== -1
                                  ) {
                                    return (
                                      <div
                                        key={
                                          'acoordion4' +
                                          response.id_agrupamento +
                                          '_' +
                                          response2.id +
                                          '_' +
                                          x +
                                          '_' +
                                          y
                                        }
                                        id={response.id_agrupamento + '_' + response2.id + '_' + x}
                                        className='accordion-item border-radius-15 mb-3'
                                      >
                                        <Grupo
                                          key={
                                            response.id_agrupamento + '_' + response2.id + '_' + x
                                          }
                                          uuid={
                                            response.id_agrupamento + '_' + response2.id + '_' + x
                                          }
                                          servico={response2}
                                          label={response2['nome']}
                                          tipoServico={2}
                                          lat={lat}
                                          lng={lng}
                                          distancia={response2.distancia ? response2.distancia : 500}
                                          municipio={municipio.municipio_url}
                                        ></Grupo>
                                      </div>
                                    )
                                  } else {
                                    return ''
                                  }
                                }
                              }),
                            ]
                          }
                        })
                      )}
                    </div>
                  </Scrollbars>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='legal-base'
                role='tabpanel'
                aria-labelledby='legal-tab'
              >
                <div>
                  <input
                    className='form-control border-0 mt-2 mb-2'
                    id='busca'
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder='Buscar'
                  />

                  <Scrollbars style={{width: '100%', height: wsize}}>
                    <div
                      className='accordion accordion-flush scroll'
                      id='accordionFlushExample_legal'
                    >
                      {!data ? (
                        <>Carregando..</>
                      ) : (
                        // eslint-disable-next-line array-callback-return
                        filterData.map(function (response, x) {
                          if (response.id_agrupamento === 5) {
                            // eslint-disable-next-line array-callback-return
                            return [
                              <div
                                key={x}
                                className=' titulo-servicos-grupo'
                                // style={{borderBottom: '1px solid #eeeeee'}}
                              >
                                <h2
                                  className=' d-flex fs-4'
                                  style={{float: 'left', marginLeft: '15px', paddingTop: '15px'}}
                                >
                                  {response['nome']}
                                </h2>
                              </div>,
                              response.servico.map(function (response2, y) {
                                if (
                                  response2['nome'].toLowerCase().indexOf(search.toLowerCase()) !==
                                  -1
                                ) {
                                  return (
                                    <div
                                      key={
                                        'acoordion1x' +
                                        response.id_agrupamento +
                                        '_' +
                                        response2.id +
                                        '_' +
                                        x +
                                        '_' +
                                        y
                                      }
                                      id={response.id_agrupamento + '_' + response2.id + '_' + x}
                                      className='accordion-item border-radius-15 mb-3' 
                                    >
                                      <Grupo
                                        key={
                                          response.id_agrupamento +
                                          '_' +
                                          response2.id +
                                          '_' +
                                          x +
                                          '_' +
                                          y
                                        }
                                        uuid={
                                          response.id_agrupamento +
                                          '_' +
                                          response2.id +
                                          '_' +
                                          x +
                                          '_' +
                                          y
                                        }
                                        servico={response2}
                                        label={response2['nome']}
                                        tipoServico={5}
                                        lat={lat}
                                        lng={lng}
                                        distancia={response2.distancia ? response2.distancia : 0}
                                        municipio={municipio.municipio_url}
                                        // itensList={servicos}
                                      ></Grupo>
                                    </div>
                                  )
                                } else {
                                  return ''
                                }
                              }),
                            ]
                          }
                        })
                      )}
                    </div>
                  </Scrollbars>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='cadastro-base'
                role='tabpanel'
                aria-labelledby='cadastro-tab'
              >
                <div>
                 
                 <div className='info info-secondary px-4' id="infoimovelsemlote"></div>
                  <input
                    className='form-control border-0 mt-5 mb-5'
                    id='busca'
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder='Buscar '
                  />
                  <Scrollbars style={{width: '100%', height: wsize}}>
                    <div
                      className='accordion accordion-flush scroll'
                      id='accordionFlushExample_legal'
                    >
                      {!data ? (
                        <>Carregando..</>
                      ) : (
                        // eslint-disable-next-line array-callback-return
                        filterData.map(function (response, x) {
                          if (response.id_agrupamento === 8) {
                       
                            // eslint-disable-next-line array-callback-return
                            return [
                              <div
                                key={x}
                                className=' titulo-servicos-grupo'
                                // style={{borderBottom: '1px solid #eeeeee'}}
                              >
                                <h2
                                  className=' d-flex fs-4'
                                  style={{float: 'left', marginLeft: '15px', paddingTop: '15px'}}
                                >
                                  {response['nome']}
                                </h2>
                              </div>,
                              response.servico.map(function (response2, y) {
                                if (
                                  response2['nome'].toLowerCase().indexOf(search.toLowerCase()) !==
                                  -1
                                ) {
                                  return (
                                    <div
                                      key={
                                        'acoordion3' +
                                        response.id_agrupamento +
                                        '_' +
                                        response2.id +
                                        '_' +
                                        x +
                                        '_' +
                                        y
                                      }
                                      id={response.id_agrupamento + '_' + response2.id + '_' + x}
                                      className='accordion-item border-radius-15 mb-3'
                                    >
                                      <Grupo
                                        key={response.id_agrupamento + '_' + response2.id + '_' + x}
                                        uuid={
                                          response.id_agrupamento + '_' + response2.id + '_' + x
                                        }
                                        servico={response2}
                                        label={response2['nome']}
                                        tipoServico={5}
                                        lat={lat}
                                        lng={lng}
                                        distancia={response2.distancia ? response2.distancia : 0}
                                        municipio={municipio.municipio_url}
                                        // itensList={servicos}
                                      ></Grupo>
                                    </div>
                                  )
                                } else {
                                  return ''
                                }
                              }),
                            ]
                          }
                        })
                      )}
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
        {/* </Scrollbars> */}
      </Offcanvas.Body>
    </Offcanvas>
  )
}
