import React, { useEffect } from "react";
import { useLocation } from 'react-router'
import clsx from 'clsx'
import { useLayout } from '../../../_metronic/layout/core'
import { DrawerComponent } from '../../../_metronic/assets/ts/components'
import { Footer } from '../../../_metronic/layout/components/Footer'

import './sugestoes-usuarios.css'
import '../global.css'
import { Link } from "react-router-dom";

export function SugestoesUsuarios() {
	const { classes } = useLayout()
	const location = useLocation()
	useEffect(() => {
		DrawerComponent.hideAll()
	}, [location])


	return (
		<div className=' d-flex flex-column flex-row-fluid pt-6' id='kt_wrapper'>
			<div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
				<div className='post d-flex flex-column-fluid' id='kt_post'>
					<div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>

						<div className="page-title d-flex flex-column align-items-start pt-6 justify-content-center flex-wrap me-lg-2 pb-2 pb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
							<h1>Sugestões dos usuários. </h1>
							<p>Aqui você pode nos enviar suas sugestões de melhorias
								e novas funcionalidades assim como contribuir com as
								sugestões dadas por outros usuários da plataforma.
								Antes de adicionar, é sempre bom ver se já não existe alguma
								solicitação para o que você está querendo. Quanto mais votada
								a solicitação, maior a chance dela ser priorizada pela nossa equipe!
							</p>
						</div>

						<div className="content  d-flex flex-column flex-column-fluid" id="kt_content" >
							<div className="d-flex flex-wrap flex-stack">
								<div className="btn-group">
									<button className="btn btn-primary" aria-current="page">Topo</button>
									<button className="btn btn-secondary">Recentes</button>
								</div>
								<div className="d-flex flex-wrap my-2">
									<div className="me-4 d-flex">
										<input className="form-control mr-1" type="text" placeholder="Pesquisa" />

										<select name="status" data-control="select2" data-hide-search="true" className="form-select form-select-sm form-select-white w-125px select2-hidden-accessible mr-1" data-select2-id="select2-data-10-kdly" aria-hidden="true">
											<option value="Active" data-select2-id="select2-data-12-rvi7">Active</option>
											<option value="Approved">In Progress</option>
											<option value="Declined">To Do</option>
											<option value="In Progress">Completed</option>
										</select>

									</div>
									<a href="#vazio" className="btn btn-secondary btn-sm mr-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">Nova Sugestão</a>
								</div>
							</div>

							<div className="h-4px w-100  mb-5" data-bs-toggle="tooltip" title="" data-bs-original-title="This project 50% completed" style={{backgroundColor: '#20a475'}}>
							</div>

							<div className="row g-6 g-xl-9">
								<div className="col-md-6 col-xl-4">
									<Link to="/sugestoes" className="card border-hover-primary">
										<div className="card-header border-0 pt-9">
											<div className="card-title m-0">
												<div className="d-flex all-space-between">
													<h2 className="text-gray-400 fw-bold px-1">Votos: </h2>
													<h2> 76</h2>
												</div>
											</div>
											<div className="card-toolbar">
												<span className="badge badge-light-primary fw-bolder me-auto px-4 py-3">In Progress</span>
											</div>
										</div>
										<div className="card-body p-9">
											<div className="fs-3 fw-bolder text-dark">Ter estimativa de condomínio</div>
											<p className="text-gray-400 fw-bold fs-5 mt-1 mb-7">Sugerido por <strong>Fernando Souza</strong></p>
											<div className="d-flex flex-wrap mb-5">
												<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
													<div className="fs-6 text-gray-800 fw-bolder">Jun 24, 2021</div>
													<div className="fw-bold text-gray-400">Data de criação</div>
												</div>
												<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
													<div className="fs-6 text-gray-800 fw-bolder">17</div>
													<div className="fw-bold text-gray-400">Comentarios</div>
												</div>
											</div>
											<div className="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="" data-bs-original-title="This project 50% completed">
											</div>
											<div className="w-100 all-space-between">

												<div className="symbol-group symbol-hover">
													<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Emma Smith">
														<span className="symbol-label bg-primary text-inverse-primary fw-bolder">A</span>
													</div>
													<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Rudy Stone">
														<span className="symbol-label bg-primary text-inverse-primary fw-bolder">R</span>
													</div>
													<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Susan Redwood">
														<span className="symbol-label bg-primary text-inverse-primary fw-bolder">S</span>
													</div>
												</div>
											</div>

										</div>
									</Link>
								</div>
								<div className="col-md-6 col-xl-4">
									<Link to="/sugestoes" className="card border-hover-primary">
										<div className="card-header border-0 pt-9">
											<div className="card-title m-0">
												<div className="d-flex all-space-between">
													<h2 className="text-gray-400 fw-bold px-1">Votos: </h2>
													<h2> 76</h2>
												</div>
											</div>
											<div className="card-toolbar">
												<span className="badge badge-light-primary fw-bolder me-auto px-4 py-3">In Progress</span>
											</div>
										</div>
										<div className="card-body p-9">
											<div className="fs-3 fw-bolder text-dark">Ter estimativa de condomínio</div>
											<p className="text-gray-400 fw-bold fs-5 mt-1 mb-7">Sugerido por <strong>Fernando Souza</strong></p>
											<div className="d-flex flex-wrap mb-5">
												<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
													<div className="fs-6 text-gray-800 fw-bolder">Jun 24, 2021</div>
													<div className="fw-bold text-gray-400">Data de criação</div>
												</div>
												<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
													<div className="fs-6 text-gray-800 fw-bolder">17</div>
													<div className="fw-bold text-gray-400">Comentarios</div>
												</div>
											</div>
											<div className="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="" data-bs-original-title="This project 50% completed">
											</div>
											<div className="w-100 all-space-between">

												<div className="symbol-group symbol-hover">
													<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Emma Smith">
														<span className="symbol-label bg-primary text-inverse-primary fw-bolder">A</span>
													</div>
													<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Rudy Stone">
														<span className="symbol-label bg-primary text-inverse-primary fw-bolder">R</span>
													</div>
													<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Susan Redwood">
														<span className="symbol-label bg-primary text-inverse-primary fw-bolder">S</span>
													</div>
												</div>
											</div>

										</div>
									</Link>
								</div>
								<div className="col-md-6 col-xl-4">
									<Link to="/sugestoes" className="card border-hover-primary">
										<div className="card-header border-0 pt-9">
											<div className="card-title m-0">
												<div className="d-flex all-space-between">
													<h2 className="text-gray-400 fw-bold px-1">Votos: </h2>
													<h2> 76</h2>
												</div>
											</div>
											<div className="card-toolbar">
												<span className="badge badge-light-primary fw-bolder me-auto px-4 py-3">In Progress</span>
											</div>
										</div>
										<div className="card-body p-9">
											<div className="fs-3 fw-bolder text-dark">Ter estimativa de condomínio</div>
											<p className="text-gray-400 fw-bold fs-5 mt-1 mb-7">Sugerido por <strong>Fernando Souza</strong></p>
											<div className="d-flex flex-wrap mb-5">
												<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
													<div className="fs-6 text-gray-800 fw-bolder">Jun 24, 2021</div>
													<div className="fw-bold text-gray-400">Data de criação</div>
												</div>
												<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
													<div className="fs-6 text-gray-800 fw-bolder">17</div>
													<div className="fw-bold text-gray-400">Comentarios</div>
												</div>
											</div>
											<div className="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="" data-bs-original-title="This project 50% completed">
											</div>
											<div className="w-100 all-space-between">

												<div className="symbol-group symbol-hover">
													<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Emma Smith">
														<span className="symbol-label bg-primary text-inverse-primary fw-bolder">A</span>
													</div>
													<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Rudy Stone">
														<span className="symbol-label bg-primary text-inverse-primary fw-bolder">R</span>
													</div>
													<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Susan Redwood">
														<span className="symbol-label bg-primary text-inverse-primary fw-bolder">S</span>
													</div>
												</div>
											</div>

										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>



				</div>
			</div>
			<Footer />
		</div>
	);
}