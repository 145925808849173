import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { MutationSetRecuperarSenha } from '../../../graphql/services/Senha';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom'

export function ForgotPassword() {
  const { DataSetRecuperarSenha, FormRecuperarSenha, error, errors, handleSubmit, loading, register } =
    MutationSetRecuperarSenha()
    
  const navigate = useHistory()

  useEffect(() => {
    if(error){
      Swal.fire('ERRO!', error.message, 'warning')
    }

    if (DataSetRecuperarSenha) {
      Swal.fire({
        title: 'Token enviado com sucesso! ',
        text:'Verifique o seu email.',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        showCloseButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate.push('/auth/verify-token')
        } else {
          Swal.fire('ERRO!', 'Email invalido ou conta não encontrada!.', 'warning')
        }
      })
    }
  }, [DataSetRecuperarSenha, navigate, error, loading])


  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        onSubmit={handleSubmit(FormRecuperarSenha)}
        id='kt_login_password_reset_form'
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Esqueceu sua senha ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Insira seu e-mail para redefinir sua senha.</div>
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder='email@demo.com'
            autoComplete='off'
            className='form-control form-control-lg form-control-solid'
            {...register('email')}
          />
        </div>
        {/* end::Form group */}
        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>

          <Link to='/'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-secondary fw-bolder'
            >
              Cancelar
            </button>
          </Link>{' '}
          <button
            type='submit'
            disabled={loading}
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder mrl-4'
          >
            {loading ? <span className='spinner-border text-info' role='status'></span> : 'Recuperar'}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
