export class Template {

    static templateReplace(template, item){
        let item_html_placeholders = template;
        let placeholders = item_html_placeholders.match(/\${\w+\}/g).map(s => s.slice(2, -1));
        let li_content = item_html_placeholders;
        for (let j in placeholders) {
            let placeholder = '${' + placeholders[j] + '}';
            li_content = li_content.replace(placeholder, item[placeholders[j]]);
        }
        // var htmlTemp = marked(li_content);
        // return htmlTemp;
        
        
        // return template.replace('${'+variavel+'}', valor)
    
        return li_content
    
        
    }

    static formatNumber(valor, style) {
        if (style === 'decimal') {
            return Number(valor).toLocaleString('pt-br', {
                style: 'decimal', minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })
        } else if (style === 'inteiro') {
            return Number(valor).toLocaleString('pt-br', {
                style: 'decimal', minimumFractionDigits: 0,
                maximumFractionDigits: 2
            })
        } else {
            return Number(valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        }
    }

}

