import {useMutation, useQuery} from '@apollo/client'
import {
  GET_EMPRESAS,
  GET_EMPRESA_BY_ID,
  GET_EMPRESA_BY_ID_FUNCIONARIOS,
  GET_EMPRESA_USUARIO_BY_ID,
  GET_PRODUTOS_BY_EMPRESA_ID,
  GET_RELATORIO_ACESSO_USUARIO_EMPRESA,
} from '../querys/empresaQuery'
import {
  TypesDeleteEmpresa,
  TypesDeleteEmpresaProduto,
  TypesDeleteEmpresaUsusario,
  TypesGetEmpresaByIDEmployees,
  TypesGetEmpresaByIDResponse,
  TypesGetEmpresaUsuarioByID,
  TypesGetEmpresasResponse,
  TypesGetProdutoByEmpresaID,
  TypesGetRelatorioAcessoUsuarioEmpresa,
  TypesPutEmpresa,
  TypesPutEmpresaUsuario,
  TypesSetEmpresaProduto,
  TypesSetEmpresaUsuario,
} from '../types/TypesEmpresas'
import {useForm} from 'react-hook-form'
import {
  setEmpresaFormSchema,
  setEmpresaFormData,
  putEmpresaProdutoFormData,
  putEmpresaProdutoFormSchema,
  putEmpresaUsuarioFormData,
  putEmpresaUsuarioFormSchema,
  setEmpresaProdutoFormData,
  setEmpresaProdutoFormSchema,
  setEmpresaUsuarioFormData,
  setEmpresaUsuarioFormSchema,
  putEmpresaFormData,
  putEmpresaFormSchema,
  putEmpresaUsuarioSchema,
  putEmpresaUsuarioData,
} from '../../formValidations/Empresa'
import {zodResolver} from '@hookform/resolvers/zod'
import {
  DELETE_EMPRESA,
  DELETE_EMPRESA_PRODUTO,
  DELETE_EMPRESA_USUARIO,
  PUT_EMPRESA,
  PUT_EMPRESA_PRODUTO,
  PUT_EMPRESA_USUARIO,
  SET_EMPRESA,
  SET_EMPRESA_PRODUTO,
  SET_EMPRESA_USUARIO,
} from '../mutation/empresaMutation'
import {GET_USUARIO_BY_ID} from '../querys'
import {useState} from 'react'
import {GET_ADMIN_EMPRESA_USUARIOS} from '../../../graphql/query/empresa'

interface Props {
  variables: any
}

export function QueryGetEmpresas({variables}: Props) {
  const {data, error, loading} = useQuery<TypesGetEmpresasResponse>(GET_EMPRESAS, {
    variables: {...variables},
  })

  return {data, error, loading}
}

export function QueryGetEmpresaByID({variables}: Props) {
  const {data, error, loading} = useQuery<TypesGetEmpresaByIDResponse>(GET_EMPRESA_BY_ID, {
    variables: {...variables},
  })

  return {data, error, loading}
}

export function QueryGetEmpresaByIDEmployees({variables}: Props) {
  const {data, error, loading} = useQuery<TypesGetEmpresaByIDEmployees>(
    GET_EMPRESA_BY_ID_FUNCIONARIOS,
    {
      variables: {...variables},
    }
  )

  return {data, error, loading}
}

export function QueryGetRelatorioAcessoUsuarioEmpresa({variables}: Props) {
  const {data, error, loading} = useQuery<TypesGetRelatorioAcessoUsuarioEmpresa>(
    GET_RELATORIO_ACESSO_USUARIO_EMPRESA,
    {
      variables: {...variables},
    }
  )

  return {data, error, loading}
}

export function MutationSetEmpresa() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<setEmpresaFormData>({
    resolver: zodResolver(setEmpresaFormSchema),
  })
  console.log(errors)

  const [bodyEmpresa, {data, loading, error}] = useMutation<TypesSetEmpresaProduto>(SET_EMPRESA, {
    refetchQueries: [GET_EMPRESAS],
  })

  async function FormEmpresa(data: setEmpresaFormData) {
    await bodyEmpresa({
      variables: {
        data: {...data},
      },
      refetchQueries: [GET_EMPRESAS],
    })
  }

  return {register, handleSubmit, setValue, FormEmpresa, loading, errors, error, data}
}

export function MutationPutEmpresa() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<putEmpresaFormData>({
    resolver: zodResolver(putEmpresaFormSchema),
  })

  console.log(errors)

  const [bodyEmpresaProdutos, {data, loading, error}] = useMutation<TypesSetEmpresaProduto>(
    PUT_EMPRESA,
    {
      refetchQueries: [GET_EMPRESAS, GET_EMPRESA_BY_ID],
    }
  )

  async function FormEmpresa(data: putEmpresaFormData) {
    await bodyEmpresaProdutos({
      variables: {
        data: {...data},
      },
      refetchQueries: [GET_EMPRESAS, GET_EMPRESA_BY_ID],
    })
  }

  return {register, handleSubmit, setValue, FormEmpresa, loading, errors, error, data}
}

export function MutationRecoveryEmpresa() {
  const [recoveryEmpresa, {loading, data, error}] = useMutation<TypesPutEmpresa>(PUT_EMPRESA, {
    refetchQueries: [GET_EMPRESAS, GET_EMPRESA_BY_ID],
  })

  async function HandleRecoveryEmpresa({variables}: Props) {
    try {
      const result = await recoveryEmpresa({
        variables: {...variables},
        refetchQueries: [GET_EMPRESAS, GET_EMPRESA_BY_ID],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleRecoveryEmpresa, loading, error, data}
}

export function MutationDeleteEmpresa() {
  const [deleteEmpresa, {loading, data, error}] = useMutation<TypesDeleteEmpresa>(DELETE_EMPRESA, {
    refetchQueries: [GET_EMPRESAS, GET_EMPRESA_BY_ID],
  })

  async function HandleDeleteEmpresa({variables}: Props) {
    try {
      const result = await deleteEmpresa({
        variables: {...variables},
        refetchQueries: [GET_EMPRESAS, GET_EMPRESA_BY_ID],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteEmpresa, loading, error, data}
}

// EMPRESA USUARIOS

export function QueryGetEmpresaUsuarioByID({variables}: Props) {
  const {data, error, loading} = useQuery<TypesGetEmpresaUsuarioByID>(GET_EMPRESA_USUARIO_BY_ID, {
    variables: {...variables},
  })

  return {data, error, loading}
}

export function MutationSetEmpresaUsuarioByID() {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: {errors},
  } = useForm<setEmpresaUsuarioFormData>({
    resolver: zodResolver(setEmpresaUsuarioFormSchema),
  })

  console.log(errors)

  const [bodyUsuario, {data, loading, error}] = useMutation<TypesSetEmpresaUsuario>(
    SET_EMPRESA_USUARIO,
    {
      refetchQueries: [
        GET_EMPRESA_USUARIO_BY_ID,
        GET_USUARIO_BY_ID,
        GET_EMPRESA_BY_ID_FUNCIONARIOS,
      ],
    }
  )

  async function FormSetEmpresaUsuario(data: setEmpresaUsuarioFormData) {
    return await bodyUsuario({
      variables: {
        data: {...data},
      },
      refetchQueries: [
        GET_EMPRESA_USUARIO_BY_ID,
        GET_USUARIO_BY_ID,
        GET_EMPRESA_BY_ID_FUNCIONARIOS,
      ],
    })
  }

  return {
    register,
    handleSubmit,
    setValue,
    FormSetEmpresaUsuario,
    loading,
    errors,
    error,
    data,
    control,
  }
}

export function MutationPutEmpresaUsuarioByID() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<putEmpresaUsuarioFormData>({
    resolver: zodResolver(putEmpresaUsuarioFormSchema),
  })

  console.log(errors)

  const [bodyUsuario, {data, loading, error}] = useMutation<TypesPutEmpresaUsuario>(
    PUT_EMPRESA_USUARIO,
    {
      refetchQueries: [GET_EMPRESA_USUARIO_BY_ID, GET_USUARIO_BY_ID],
    }
  )

  async function FormPutEmpresaUsuario(data: putEmpresaUsuarioFormData) {
    await bodyUsuario({
      variables: {
        data: {...data},
      },
      refetchQueries: [GET_EMPRESA_USUARIO_BY_ID, GET_USUARIO_BY_ID],
    })
  }

  return {register, handleSubmit, setValue, FormPutEmpresaUsuario, loading, errors, error, data}
}

export function MutationDeleteEmpresaUsuario() {
  const [deleteEmpresaProduto, {loading, data, error}] = useMutation<TypesDeleteEmpresaUsusario>(
    DELETE_EMPRESA_USUARIO,
    {
      refetchQueries: [
        GET_PRODUTOS_BY_EMPRESA_ID,
        GET_EMPRESA_BY_ID_FUNCIONARIOS,
        GET_ADMIN_EMPRESA_USUARIOS,
      ],
    }
  )

  async function HandleDeleteAdminEmpresaUsuario({variables}: Props) {
    try {
      const result = await deleteEmpresaProduto({
        variables: {...variables},
        refetchQueries: [
          GET_PRODUTOS_BY_EMPRESA_ID,
          GET_EMPRESA_BY_ID_FUNCIONARIOS,
          GET_ADMIN_EMPRESA_USUARIOS,
        ],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteAdminEmpresaUsuario, loading, error, data}
}

// EMPRESA PRODUTOS

export function QueryGetProdutosByEmpresaID({variables}: Props) {
  const {data, error, loading} = useQuery<TypesGetProdutoByEmpresaID>(GET_PRODUTOS_BY_EMPRESA_ID, {
    variables: {...variables},
  })

  return {data, error, loading}
}

export function MutationSetEmpresaProduto() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<setEmpresaProdutoFormData>({
    resolver: zodResolver(setEmpresaProdutoFormSchema),
  });

  const [bodyEmpresaProdutos, { data, loading, error }] = useMutation<TypesSetEmpresaProduto>(
    SET_EMPRESA_PRODUTO,
    {
      refetchQueries: [GET_PRODUTOS_BY_EMPRESA_ID],
      onError: (err) => {
        // Captura erros da mutação e os loga
        console.error('Erro na mutação:', err);
      },
    }
  );

  async function FormEmpresaProduto(data: setEmpresaProdutoFormData) {
    try {
      const response = await bodyEmpresaProdutos({
        variables: {
          data: { ...data },
        },
        refetchQueries: [GET_PRODUTOS_BY_EMPRESA_ID],
      });

      // Verifica se há erros na resposta da mutação
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return response.data;
    } catch (err) {
      // Captura e trata erros lançados durante a mutação
      console.error('Ocorreu um erro durante o processo:', err);
      throw err;
    }
  }

  return { register, handleSubmit, setValue, FormEmpresaProduto, loading, errors, error, data };
}

export function MutationPutEmpresaProduto() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<putEmpresaProdutoFormData>({
    resolver: zodResolver(putEmpresaProdutoFormSchema),
  })

  const [bodyEmpresaProdutos, {data, loading, error}] = useMutation<TypesSetEmpresaProduto>(
    PUT_EMPRESA_PRODUTO,
    {
      refetchQueries: [GET_PRODUTOS_BY_EMPRESA_ID],
    }
  )

  async function FormEmpresaProduto(data: putEmpresaProdutoFormData) {
    await bodyEmpresaProdutos({
      variables: {
        data: {...data},
      },
      refetchQueries: [GET_PRODUTOS_BY_EMPRESA_ID],
    })
  }

  return {register, handleSubmit, setValue, FormEmpresaProduto, loading, errors, error, data}
}

export function MutationDeleteEmpresaProduto() {
  const [deleteEmpresaProduto, {loading, data, error}] = useMutation<TypesDeleteEmpresaProduto>(
    DELETE_EMPRESA_PRODUTO,
    {
      refetchQueries: [GET_PRODUTOS_BY_EMPRESA_ID],
    }
  )

  async function HandleDeleteEmpresaProduto({variables}: Props) {
    try {
      const result = await deleteEmpresaProduto({
        variables: {...variables},
        refetchQueries: [GET_PRODUTOS_BY_EMPRESA_ID],
      })
      console.log(result)
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteEmpresaProduto, loading, error, data}
}

export function MutationPutEmpresaUsuario() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<putEmpresaUsuarioData>({
    resolver: zodResolver(putEmpresaUsuarioSchema),
  })

  console.log(errors)

  const [bodyUsuario, {data, loading, error}] = useMutation<TypesPutEmpresaUsuario>(
    PUT_EMPRESA_USUARIO,
    {
      refetchQueries: [GET_EMPRESA_USUARIO_BY_ID, GET_USUARIO_BY_ID],
    }
  )

  async function FormPutEmpresaUsuario(data: putEmpresaUsuarioFormData) {
    await bodyUsuario({
      variables: {
        data: {...data},
      },
      refetchQueries: [GET_EMPRESA_USUARIO_BY_ID, GET_USUARIO_BY_ID],
    })
  }

  return {register, handleSubmit, setValue, FormPutEmpresaUsuario, loading, errors, error, data}
}
