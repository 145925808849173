import {useQuery} from '@apollo/client'
import {GetExplorerLayersUsuario} from '../../../../../../graphql/type/explorer-layer-usuario'
import {FIND_EXPLORER_LAYER_USUARIO} from '../../../../../../graphql/query/explorer-layer-usuario'
import {useEffect, useState} from 'react'
import {Explorer} from '../../Explorer'
import {Endereco} from '../../ComponentsControl/EnderecoControl/endereco'
import { BiLayer } from 'react-icons/bi'

export const ListLayers: React.FC = () => {
  const [layers, setLayers] = useState([])
  const [selected, setSelected]= useState([])
  const municipio = Endereco.getMunicipio()
  let idMunicipio = 5351
  if (municipio) {
    idMunicipio = municipio.id
  }

  const {data} = useQuery<GetExplorerLayersUsuario>(FIND_EXPLORER_LAYER_USUARIO, {
    variables: {idMunicipio: idMunicipio},
  })

  useEffect(() => {
    if (data) {
      const itens = []
      // eslint-disable-next-line array-callback-return
      data.GetExplorerLayersUsuario.map((item) => {
        const isTagIndex = item.explorer_layer_tags.filter((tags) => {
          if (tags.explorer_tags.tag.indexOf('Índices Destaque') !== -1) {
            return true
          }
          return false
        })
        if (isTagIndex.length > 0) {
          if (itens.filter((i) => i.id === item.id).length === 0) {
            itens.push(item)
          }
        }
      })
      setLayers([...itens])
    }
  }, [data])

  if (!data) {
    return <>Carregando..</>
  }

  function onClick(item) {
    const layerSelected = item.target.value
    let uuid = ''
    let layer = []
    let label = ''
    
    if (item.target.checked) {
      // eslint-disable-next-line array-callback-return
      layer = layers.filter((element) => {
        if (element.id === parseInt(layerSelected)) {
          return true
        }
      })

      let layerTile = {...layer[0]}
      label = Explorer._getLabel(layerTile['explorer_tiles'])
      layerTile['visible'] = true
      layerTile[
        'tile'
      ] = `${process.env.REACT_APP_MAP_VECTORTILE}/tile/${layerTile.source}/{z}/{x}/{y}.pbf?s=${layerTile.schema_table}`
      layerTile['paint'] = {...layerTile.paint}
      uuid = Explorer.addLayerTile(layerTile, label)
      Explorer.addLayerLotes()
      setSelected([...selected,...[layerSelected]])
      localStorage.setItem('shortcut' + layerSelected, uuid)
    } else {
      const layerUUID = localStorage.getItem('shortcut' + layerSelected)
      Explorer.removeLayer(layerUUID)
      let index = selected.indexOf(layerSelected);
      localStorage.removeItem('shortcut' + layerSelected)
      selected.splice(index, 1);
      setSelected([...selected])
    }
  }
  return (
    <>
      <div className='card h-100 w-450px '>
        <div className='card-header p-0' style={{minHeight: '38px'}}>
          <div className='card-title height-10px px-5 py-2 m-0'>
            <h5>Layers</h5>
          </div>
        </div>
        <div className='card-body p-0'>
          <div className=' me-5'>
            <div className='menu menu-column w-450px'>
              {data
                ? layers.map((item, y) => {
                  return (
                      <div className='menu-item py-1' key={item.id}>
                        <div className='menu-link justify-content-between aling-top'>
                          <span className='menu-icon- mx-2'><BiLayer className='fs-1'></BiLayer></span>
                          <span className='menu-title'>{item.nome}</span>
                          <span className='menu-badge justify-content-start'>
                            <div className='form-check form-switch form-check-custom form-check-solid'>
                              <input
                                className='form-check-input h-20px w-30px'
                                type='checkbox'
                                value={item.id}
                                onChange={onClick}
                                checked={ selected.indexOf(item.id+'') !== -1 ? true : false  }
                              />
                            </div>
                          </span>
                        </div>
                      </div>
                    )
                  })
                : ''}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
