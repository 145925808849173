import React, {useCallback, useEffect, useRef} from 'react'
import {
  GoogleMap,
  Polygon,
  OverlayView,
  Marker,
  DrawingManager,
} from '@react-google-maps/api'
import {APiUrbit} from '../../../services/api/apiUrbit'
import Swal from 'sweetalert2'
import {Endereco} from '../../../pages/explorer/components/Map/ComponentsControl/EnderecoControl/endereco'
import {marcadoresMap} from '../../util'
import * as turf from '@turf/turf'
import {formatAdressSeachGoogleMapa} from '../../../pages/explorer/components/common'
import {MutationPutImovel, MutationSetImovelAddEmpreedimento} from '../../../graphql'
import {MutationSetLogAcessoRecurso} from '../../../graphql/services/LogAcesso'
import {errorHandler} from '../../../graphql/errorHandler'

type PropsContextMenu = {
  latLng: any[]
  showLotes?: boolean
  showMarker?: boolean
  onClickMap?: any
  idEmpreendimento: number
}

export function CoordinatesToPath(geom) {
  let pathsCtoP = geom.map(function (el) {
    return {lat: el[1], lng: el[0]}
  })

  return pathsCtoP
}

export function PathToCoordinates(path) {
  let pathsToC = path.map(function (el) {
    return [el.lng, el.lat]
  })

  return pathsToC
}

export const MapView: React.FC<PropsContextMenu> = ({
  latLng,
  showLotes = true,
  showMarker = false,
  onClickMap,
  idEmpreendimento,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = React.useState(null)
  const drawingManager = useRef<any>()
  const [paths, setPaths] = React.useState([])
  const [edit, setEdit] = React.useState({edit: false, id: null})
  const polygonRef = useRef(null)
  const listenersRef = useRef([])
  const {FormImovelAddEmpreendimento} = MutationSetImovelAddEmpreedimento()
  const {FormImovel} = MutationPutImovel()
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  useEffect(() => {
    setPaths(latLng)
    return () => {
      setPaths([])
    }
  }, [latLng])

  const containerStyle = {
    height: '400px',
  }

  const center = {
    lat: -23.56834983242366,
    lng: -46.65739491734664,
  }

  if (latLng.length > 0) {
    if (typeof latLng[latLng.length - 1].center !== 'undefined') {
      center.lat = latLng[latLng.length - 1].center.geometry.coordinates[1]
      center.lng = latLng[latLng.length - 1].center.geometry.coordinates[0]
    } else {
      center.lat = latLng[0]
      center.lng = latLng[1]
    }
  }

  const options = {
    fillColor: 'lightgreen',
    fillOpacity: 0.6,
    strokeColor: 'green',
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  }

  const optionsEditable = {
    fillColor: 'lightred',
    fillOpacity: 0.6,
    strokeColor: 'red',
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: true,
    editable: true,
    geodesic: false,
    zIndex: 1,
  }

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center)
    // map.fitBounds(bounds)

    const imageMapType = new google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        // if (
        //   zoom < 17 ||
        //   zoom > 20
        //   // bounds[zoom][0][0] > coord.x ||
        //   // coord.x > bounds[zoom][0][1] ||
        //   // bounds[zoom][1][0] > coord.y ||
        //   // coord.y > bounds[zoom][1][1]
        // ) {
        //   return "";
        // }

        return [
          process.env.REACT_APP_MAP_TILE + '/tile/lotesfinal/',
          zoom,
          '/',
          coord.x,
          '/',
          coord.y,
          '.png?type=png',
        ].join('')
      },
      tileSize: new google.maps.Size(256, 256),
    })
    if (showLotes) {
      map.overlayMapTypes.push(imageMapType)
      setMap(map)
    }
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  function dbClick(e, elementMap, id) {
    // eslint-disable-next-line array-callback-return
    paths.map((el) => {
      if (el.id === elementMap.id) {
        el.editable = true
      } else {
        el.editable = false
      }
    })

    setEdit({edit: true, id: id})

    setPaths([...paths])
  }

  function click(e, el) {
    clearSelecaoTable()
    let line = document.getElementById('row_' + el.id)
    if (line) {
      line.classList.add('active')
    }
  }

  function clearSelecaoTable() {
    document.querySelectorAll('.row-composicao').forEach(function (row) {
      row.classList.remove('active')
    })
  }

  // Call setPath with new edited path
  const onEdit = useCallback(
    (el, id, pathsState) => {
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map((latLng) => {
            return {lat: latLng.lat(), lng: latLng.lng()}
          })
        updatePaths(nextPath, id, pathsState)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setPaths]
  )

  function updatePaths(nextPath, id, pathsState) {
    // eslint-disable-next-line array-callback-return
    const newPaths = pathsState.map((el) => {
      if (el.id === id) {
        el.path = nextPath
      }
      return el
    })

    if (newPaths.length > 0) {
      setPaths([...newPaths])
    }
  }

  async function disableEdit(e) {
    // eslint-disable-next-line array-callback-return
    paths.map((el) => {
      el.editable = false
    })

    setPaths([...paths])
    clearSelecaoTable()
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()

    if (!edit.edit) {
      if (onClickMap) {
        Swal.fire({
          title: 'Carregando',
          text: 'Carregando informações do lote.',
          showCancelButton: false,
          showConfirmButton: false,
        })

        let municipioCidade = await Endereco.getMunicipioAPI(lat, lng)
        let municipio = municipioCidade.municipio_url
        console.log(municipio)
        onClickMap(lat, lng, municipio)
      }
    }
  }

  // Bind refs to current Polygon and listeners
  const onLoadPoligon = useCallback(
    (polygon, id) => {
      polygonRef.current = polygon
      const path = polygon.getPath()
      listenersRef.current.push(
        path.addListener('set_at', (e) => {
          onEdit(e, id, paths)
        }),
        path.addListener('insert_at', (e) => {
          onEdit(e, id, paths)
        }),
        path.addListener('remove_at', (e) => {
          onEdit(e, id, paths)
        })
      )
    },
    [onEdit]
  )

  let isLoaded = true
  const divStyle = {
    background: 'transparent',
    // border: "1px solid #ccc",
    // padding: 15
  }

  const onPolygonComplete = (polygon) => {
    let GeoJSON = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [],
      },
      properties: {area: '0'},
    }

    const firstPath = polygon.getPath().getArray()[0]

    let coordenadas = []
    for (let point of polygon.getPath().getArray()) {
      coordenadas.push([point.lng(), point.lat()])
    }
    coordenadas.push([firstPath.lng(), firstPath.lat()])
    GeoJSON.geometry.coordinates.push(coordenadas)
    const areaTotal = turf.area(GeoJSON.geometry)
    const centroid = turf.centroid(GeoJSON.geometry)
    const lng = centroid.geometry.coordinates[0]
    const lat = centroid.geometry.coordinates[1]
    const geocoder = new google.maps.Geocoder()

    if (lat && lng) {
      const latLng = new google.maps.LatLng(lat, lng)
      geocoder.geocode({location: latLng}, async function (results, status) {
        if (status === 'OK') {
          let municipio = Endereco.getMunicipio()
          if (municipio === '') {
            let municipioCidade = await Endereco.getMunicipioAPI(lat, lng)
            municipio = municipioCidade
          }
          var place = results[0]
          var adr_elements = formatAdressSeachGoogleMapa(place)
          let lote = {
            endereco: adr_elements['logradouro'] + ',' + adr_elements['numero'],
            numero: adr_elements['numero'],
            logradouro: adr_elements['logradouro'],
            bairro: adr_elements['bairro'],
            estado: adr_elements['estado'],
            cidade: adr_elements['cidade'],
            id_cidade: municipio.id,
            id_estado: municipio.id_estado,
            complemento: '',
            cep: adr_elements['cep'],
            area_lote: areaTotal ? areaTotal.toFixed(0) : 0,
            numero_contribuinte: '',
            setor_quadra: '',
            dc_tipo_us: '',
            codlog: '',
            tipo_de_terreno: '',
            numero_lote: '',
            marcacao: GeoJSON,
            testada_para_calculo: '',
            valor_do_m2_do_terreno: '',
            cd_condomi: '',
            quantidade_de_pavimentos: '',
            geom_closest_point: centroid,
            polygon: polygon,
            latitude: lat,
            longitude: lng,
          }
          Swal.fire({
            title: 'Adicionar este lote ao meu empreendimento?',
            confirmButtonText: 'Adicionar',
            showConfirmButton: true,
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
          }).then((resultQuestion) => {
            if (resultQuestion.value === true) {
              const data = {
                idEmpreendimento: idEmpreendimento,
                nome: lote.logradouro + ',' + lote.numero,
                endereco: lote.logradouro,
                numero: lote.numero,
                complemento: lote.complemento,
                cep: lote.cep,
                area_iptu: Number(lote.area_lote),
                area_real: Number(lote.area_lote),
                numero_contribuinte: lote.numero_contribuinte,
                id_situacao: 1,
                latitude: String(lat),
                longitude: String(lng),
                setor_quadra: lote.setor_quadra,
                // uso_atual_terreno: lote.uso_atual,
                codlog: lote.codlog,
                // terreno_esquina: String(lote.quantidade_de_esquinas_frentes),
                postgis_municipio_id: Number(lote.id_cidade),
                terrain_type: 'IMOVEL',
                testada: Number(lote.testada_para_calculo), // Coloquei esse acreditando ser o campo correspondende
                metragem: Number(lote.area_lote),
                cod_condomio: lote.cd_condomi === '0000' ? '00' : null,
                id_estado: Number(lote.id_estado),
                id_cidade: Number(lote.id_cidade),
                // nome_contribuinte: lote.nome_contribuinte,
                numero_lote: String(lote.numero_lote),
                marcacao: JSON.stringify(lote.marcacao.geometry),
              }
              FormImovelAddEmpreendimento(data)
              FormSetLogAcessoRecurso({
                action: 'create',
                ferramenta: 'imovel-empreendimento',
                funcionalidade: 'composicao-terrenos',
                url: `/empreendimentos/details/${idEmpreendimento}#composicao-terrenos`,
              })

              polygon.setMap(null)
              if (drawingManager.current) {
                console.log(drawingManager)
                drawingManager.current.setDrawingMode('')
              }
            }
          })
        }
      })
    }
  }

  function handleSave() {
    let terrenoEdit = null
    // eslint-disable-next-line array-callback-return
    paths.map((el) => {
      if (edit.id === el.id) {
        terrenoEdit = el
      }
      el.editable = false
    })

    if (terrenoEdit) {
      const geometria = PathToCoordinates(terrenoEdit.path)
      const multiPolygon = {
        type: 'MultiPolygon',
        coordinates: [],
      }

      multiPolygon.coordinates.push([geometria])

      Swal.fire({
        title: 'Salvar alterações?',
        confirmButtonText: 'Salvar',
        showConfirmButton: true,
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then((resultQuestion) => {
        if (resultQuestion.isConfirmed === true) {
          const data = {
            id: edit.id,
            marcacao: JSON.stringify(multiPolygon),
          }
          FormImovel(data)
            .then((result) => {
              if (!result.data) {
                let erroText = errorHandler(result.errors)
                Swal.fire('Erro!', erroText, 'warning')
              } else {
                Swal.fire('Alterado!', 'Geometria alterada com sucesso!', 'success')
                FormSetLogAcessoRecurso({
                  action: 'update',
                  ferramenta: 'imovel-empreendimento',
                  funcionalidade: 'composicao-terrenos',
                  url: `/empreendimentos/details/${idEmpreendimento}#composicao-terrenos`,
                })
              }
            })
            .catch((err) => {
              Swal.fire(
                'Erro!',
                'Ocorreu um erro inesperado, tente novamente mais tarde.',
                'warning'
              )
            })
        }
      })
    }

    setPaths([...paths])
    clearSelecaoTable()
    setEdit({edit: false, id: null})
  }
  function onLoadDraw(drawingManagerInstance) {
    drawingManager.current = drawingManagerInstance
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={disableEdit}
    >
      {edit.edit ? (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <button
            className='btn btn-success'
            onClick={handleSave}
            style={{position: 'absolute', top: '10px'}}
          >
            Salvar Geometria
          </button>
        </div>
      ) : (
        ''
      )}
      <DrawingManager
        onLoad={onLoadDraw}
        onPolygonComplete={onPolygonComplete}
        options={{
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },

          polylineOptions: {
            visible: false,
          },
          markerOptions: {
            visible: false,
          },
          polygonOptions: {
            fillColor: `#20a745`,
            fillOpacity: 0.6,
            strokeWeight: 2,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
          // rectangleOptions: {
          //   fillColor: `#20a745`,
          //   fillOpacity: 0.6,
          //   strokeWeight: 2,
          //   clickable: false,
          //   editable: true,
          //   zIndex: 1,
          // },
          // circleOptions: {
          //   fillColor: `#20a745`,
          //   fillOpacity: 0.6,
          //   strokeWeight: 2,
          //   clickable: false,
          //   editable: true,
          //   zIndex: 1,
          // },
        }}
      />
      {paths
        ? // paths.length > 2 ?
          paths.map(function (el, index) {
            let lng = el.center.geometry.coordinates[0]
            let lat = el.center.geometry.coordinates[1]

            return (
              <div key={index}>
                <Polygon
                  key={index}
                  onLoad={(e) => onLoadPoligon(e, el.id)}
                  // onMouseDown={onLoadPoligonUpdate}
                  paths={el.path}
                  options={el.editable ? optionsEditable : options}
                  onDblClick={(e) => {
                    dbClick(e, el, el.id)
                  }}
                  onClick={(e) => {
                    click(e, el)
                  }}
                  // Event used when manipulating and adding points
                  onMouseUp={(e) => onEdit(e, el.id, paths)}
                  // Event used when dragging the whole Polygon
                  onDragEnd={(e) => onEdit(e, el.id, paths)}
                />
                <Marker
                  key={'marker' + index}
                  label={marcadoresMap[index]}
                  position={{lat: lat, lng: lng}}
                  draggable
                  onDragEnd={(data) => {
                    // onChangeMarker(data)
                  }}
                />
              </div>
            )
          })
        : // :
          //   <Marker
          // // key={p}

          // position={{ lat: paths[0], lng: paths[1] }}
          // draggable
          // onDragEnd={(data) =>{
          //   // onChangeMarker(data)
          // } }
          // />

          ''}
      <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <div style={divStyle}>
          {/* <h1>OverlayView</h1>

            <button onClick={onClick} type="button">
              Click me
            </button> */}
        </div>
      </OverlayView>
      {/* Child components, such as markers, info windows, etc. */}
      {showMarker ? (
        <Marker
          // id={el.id}
          // onLoad={onLoadPoligon}
          // key={p}
          position={{lat: center.lat, lng: center.lng}}
          draggable
          onDragEnd={(data) => {
            // onChangeMarker(data)
            // onMarkerDragEnd(coord, key, markers)
          }}
        />
      ) : (
        <></>
      )}
      <></>
    </GoogleMap>
  ) : (
    <></>
  )
}
