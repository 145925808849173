import React, {useState} from 'react'
import {HeaderFicha} from './components/HeaderFicha'
import {HeaderActions} from './components/HeaderActions'
import {HeaderDetalhes} from './components/HeaderDetalhes'
import {Filtro} from './components/Filtro'
import {ServicoResult} from './components/ServicoResult'
import {ImovelResponse, QueryGetServicosCatetorias} from '../../../graphql'
import {QueryGetUsuarioProdutos} from '../../../graphql/services/Usuario'
import {ValidaPermissaoAcessoCorporativo} from '../../../utils/PermissoesAcesso'

interface Props {
  //   latLng: google.maps.LatLng
  data: ImovelResponse
}

export const FichaContent: React.FC<Props> = ({data}) => {
  const [busca, setBusca] = useState('')
  const [grupo, setGrupo] = useState()
  const uuid = data.GetImovel.uuid
  let tagsList = []
  const usuarioProdutos = QueryGetUsuarioProdutos({variables: {}})
  

  
  const dataServicoCategoria = QueryGetServicosCatetorias({variables: {
    idMunicipio:data.GetImovel.id_cidade
  }})

  if (!usuarioProdutos) {
    return <>Carregando..</>
  }

  const acessoCoporativo = ValidaPermissaoAcessoCorporativo(usuarioProdutos)
  if (!dataServicoCategoria) {
    return <>Carregando Serviços</>
  } else {
    dataServicoCategoria.GetServicoCategoria.map(function (servicoCategoria, index) {
      tagsList[index] = servicoCategoria.nome + ''
      servicoCategoria.servico.map(function (result) {
        if (typeof tagsList[index] == 'undefined') {
          tagsList[index] = ''
        }
        const textTagsList = tagsList[index].toLowerCase() + '|' + result.session_name.toLowerCase()
        tagsList[index] = tagsList[index] !== '' ? textTagsList : ''
        return []
      })
      return []
    })
  }

  const onChangeUsuarioGrupo = (result) => {
    

    var servicosList = []
    if (result && result.length > 0) {
      if (result[0].id){
        setGrupo(result[0].id)
      }

      document.querySelectorAll('.accordion').forEach(function (x) {
        x.classList.add('hide')
      })
      document.querySelectorAll('.card-servico').forEach(function (x) {
        x.classList.add('hide')
      })

      if (result[0].servico.length === 0) {
        document.querySelectorAll('.accordion').forEach(function (x) {
          x.classList.remove('hide')
        })
      } else {
        result[0].servico.map(function (element) {
          servicosList.push(element.session_name)
          return element
        })

        document.querySelectorAll('.accordion').forEach(function (x) {
          for (var j in servicosList) {
            if (x.getAttribute('data-tags').indexOf(servicosList[j]) !== -1) {
              x.classList.remove('hide')
            }
          }
        })
        document.querySelectorAll('.card-servico').forEach(function (x) {
          for (var j2 in servicosList) {
            if (x.getAttribute('data-tags').indexOf(servicosList[j2]) !== -1) {
              x.classList.remove('hide')
            }
          }
        })
      }
    } else {
      document.querySelectorAll('.accordion').forEach(function (x) {
        x.classList.remove('hide')
      })
    }
  }

  const showHide = (element) => {

    // dataServicoCategoria.GetServicoCategoria.map((categoriasServicos, index) => {
      
              // console.log(categoriasServicos.id)
              // console.log('#kt_accordion_1_btn_'+categoriasServicos.id)
              // // document.getElementById('kt_accordion_1_btn_'+categoriasServicos.id).click()
              // categoriasServicos.servico.map(function(){

              // })
      
    // })
  
    let showhide = element.target.classList.contains('showall')
    document.querySelectorAll('.accordion .accordion-collapse').forEach(function (el) {
      if (showhide) {
        el.classList.add('show')
        element.target.classList.remove('showall')
        // el.inputElement.click();
      } else {
        el.classList.remove('show')
        element.target.classList.add('showall')
      }
    })
  }

  function onBusca(e) {
    const text = e.target.value
    setBusca(text.toLowerCase())
  }

  return (
    <>
      <HeaderFicha dados={data} acesso={acessoCoporativo} />
      <HeaderActions uuid={uuid} id={data.GetImovel.id} data={data} grupo={grupo} ficha_publica={data.GetImovel.ficha_publica} />
      <HeaderDetalhes dados={data} />
      <Filtro
        onChangeUsuarioGrupo={onChangeUsuarioGrupo}
        text={''}
        expandAll={showHide}
        onBusca={onBusca}
        idCidade={data.GetImovel.id_cidade}
      />

      <div className='w-100 all-center-between'>
        <div className='  w-100'>
          <div className='card-body p-0 mt-5 '>
            <div className='row '>
              <div className='col-md-12'>
                {!dataServicoCategoria
                  ? '' : 
                  // eslint-disable-next-line array-callback-return
                  dataServicoCategoria.GetServicoCategoria.map((categoriasServicos, index) => {
                      if (tagsList[index].toLowerCase().indexOf(busca.toLowerCase()) !== -1) {
                        return (
                          <div
                            className='accordion pb-2 '
                            data-tags={tagsList[index]}
                            id={'kt_accordion_1' + categoriasServicos.id}
                            key={'kt_accordion_1' + categoriasServicos.id}
                          >
                            <div className='accordion-item' style={{border: 0}}>
                              <ServicoResult
                                idCategoria={categoriasServicos.id}
                                titulo={categoriasServicos.nome}
                                dadosImovel={data}
                                servicos={categoriasServicos.servico}
                              ></ServicoResult>
                            </div>
                          </div>
                        )
                      }
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
