import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {BiAddToQueue} from 'react-icons/bi'
import {MutationSetEmpresaUsuarioByID} from '../../../../../../graphql'
import {TypesGetEmpresaByIDEmployees} from '../../../../../../graphql/types/TypesEmpresas'
import {UsuariosChosen} from './UsuariosChosen'

interface Props {
  id_empresa: number // Altere o tipo conforme necessário
  dataFuncionarios: TypesGetEmpresaByIDEmployees // Tipo da propriedade dataFuncionarios
}

export function ModalAssociationUsuario({id_empresa, dataFuncionarios}: Props) {
  const [show, setShow] = useState(false)
  const [disabledFields, setDisabledFields] = useState(false)
  const {FormSetEmpresaUsuario, handleSubmit, register, setValue} = MutationSetEmpresaUsuarioByID()
  const [valueUsuario, setValueUsuario] = useState(0)
  const [tipoPessoa, setTipoPessoa] = useState('')

  const closeModal = () => setShow(false)
  const openModal = () => setShow(true)

  const handleTipoPessoaChange = (event) => {
    const newTipoPessoa = event.target.value
    setTipoPessoa(newTipoPessoa)

    if (newTipoPessoa === 'ADMIN') {
      setValue('visualizacao', 'TOTAL')
      setValue('edicao', 'TOTAL')
      setValue('exclusao', 'TOTAL')
      setDisabledFields(true)
    } else {
      setDisabledFields(false)
    }
  }

  const onSubmit = async (data) => {
    if (Array.isArray(dataFuncionarios.GetEmpresaByIdFuncionarios.funcionarios)) {
      // Verifica se o id do usuário existe no array dataFuncionarios
      const isUserInDataFuncionarios =
        dataFuncionarios.GetEmpresaByIdFuncionarios.funcionarios.some(
          (funcionario) => funcionario.id === valueUsuario
        )

      if (isUserInDataFuncionarios) {
        // Se o usuário estiver no array, exiba uma mensagem de erro ou tome outra ação apropriada
        Swal.fire('Erro!', 'Este usuário já está associado a esta empresa.', 'error')
      } else {
        // Se o usuário não estiver no array, prossiga com a solicitação GraphQL
        FormSetEmpresaUsuario(data)
          .then((result) => {
            console.log(result)
            if (result.data) {
              // Se a solicitação GraphQL for bem-sucedida, exiba uma mensagem de sucesso
              Swal.fire('Sucesso!', 'Novo usuário associado com sucesso.', 'success')
            } else {
              Swal.fire('Atenção!', result.errors[0].message, 'info')
            }
            closeModal()
          })
          .catch((error) => {
            console.log(error)
            // Se ocorrer um erro durante a solicitação GraphQL, exiba uma mensagem de erro
            Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
          })
      }
    } else {
      // Se dataFuncionarios não for um array ou for nulo, exiba uma mensagem de erro ou tome outra ação apropriada
      console.error('dataFuncionarios não é um array ou é nulo')
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    }
  }

  return (
    <>
      <button
        onClick={openModal}
        className='btn btn-light-primary btn-sm d-block me-2'
        style={{width: '100px !important'}}
      >
        Adicionar Usuário <BiAddToQueue className='fs-2'></BiAddToQueue>
      </button>

      <Modal centered animation show={show} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Associar funcionários:</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-lg'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    className='form-control form-control-solid'
                    placeholder='id'
                    hidden={true}
                    defaultValue={id_empresa}
                    {...register('id_empresa', {required: true, valueAsNumber: true})}
                  />

                  <input
                    className='form-control form-control-solid'
                    hidden={true}
                    value={valueUsuario}
                    {...register('id_user', {required: true, valueAsNumber: true})}
                  />
                  <div className='row'>
                    <div className='col-8'>
                      <UsuariosChosen
                        valueUsuario={valueUsuario}
                        setValueUsuario={(value: number) => {
                          setValueUsuario(value)
                          setValue('id_user', value)
                        }}
                      />
                    </div>
                    <div className='col-4'>
                      <div className='form-group'>
                        <label className='form-label' htmlFor=''>
                          Função:
                        </label>
                        <select
                          name=''
                          id=''
                          className='form-select'
                          {...register('tipo_usuario')}
                          onChange={handleTipoPessoaChange}
                        >
                          <option value=''>Selecione</option>
                          <option value='ADMIN'>Administrador</option>
                          <option value='FUNCIONARIO'>Funcionário</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='form-group col-4 mt-4'>
                      <label className='form-label' htmlFor=''>
                        Visualização
                      </label>
                      <select
                        className='form-control'
                        required
                        name=''
                        id=''
                        {...register('visualizacao')}
                        disabled={disabledFields}
                      >
                        <option value=''>Selecione</option>
                        <option value='PARCIAL'>Parcial</option>
                        <option value='TOTAL'>Total</option>
                      </select>
                    </div>

                    <div className='form-group col-4 mt-4'>
                      <label className='form-label' htmlFor=''>
                        Edição
                      </label>
                      <select
                        className='form-control'
                        required
                        name=''
                        id=''
                        {...register('edicao')}
                        disabled={disabledFields}
                      >
                        <option value=''>Selecione</option>
                        <option value='PARCIAL'>Parcial</option>
                        <option value='TOTAL'>Total</option>
                      </select>
                    </div>

                    <div className='form-group col-4 mt-4'>
                      <label className='form-label' htmlFor=''>
                        Exclusão
                      </label>
                      <select
                        className='form-control'
                        required
                        name=''
                        id=''
                        {...register('exclusao')}
                        disabled={disabledFields}
                      >
                        <option value=''>Selecione</option>
                        <option value='PARCIAL'>Parcial</option>
                        <option value='TOTAL'>Total</option>
                      </select>
                    </div>
                  </div>

                  <div className='col-md-12 d-flex mrt-1' style={{justifyContent: 'flex-end'}}>
                    <button
                      onClick={closeModal}
                      className='btn btn-danger pull-right'
                      type='button'
                      data-bs-dismiss='modal'
                    >
                      Fechar
                    </button>
                    <button type='submit' className='btn btn-success mx-2' id='btnIncluirCorretor'>
                      Associar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
