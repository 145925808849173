import { z } from "zod"

export const setAdminEmpresaUsuarioFormSchema = z.object({
  name: z.string().nonempty("O nome do funcionário é obrigatório!"),
  email: z.string().nonempty("Email do funcionário é obrigatório!"),
  telefone: z.string(),
  visualizacao: z.union([z.literal("TOTAL"), z.literal("PARCIAL")]),
  edicao: z.union([z.literal("TOTAL"), z.literal("PARCIAL")]),
  exclusao: z.union([z.literal("TOTAL"), z.literal("PARCIAL")]),
});
export type setAdminEmpresaUsuarioFormData = z.infer<typeof setAdminEmpresaUsuarioFormSchema>


export const putAdminEmpresaUsuarioFormSchema = z.object({
  id: z.number(),
  visualizacao: z.union([z.literal("TOTAL"), z.literal("PARCIAL")]),
  edicao: z.union([z.literal("TOTAL"), z.literal("PARCIAL")]),
  exclusao: z.union([z.literal("TOTAL"), z.literal("PARCIAL")]),
})
export type putAdminEmpresaUsuarioFormData = z.infer<typeof putAdminEmpresaUsuarioFormSchema>


