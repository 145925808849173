import { QueryGetExplorerLayer } from '../../../graphql/services'
import { Buttons } from './Buttons'
import { Loader } from '../../../../components/UI/Loader/Loader'
import { useState } from 'react'
import { BiAddToQueue } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import PaginationComponent from '../../../../components/UI/PaginationComponents'
import { CardTables } from '../../../../components/UI/CardTables'
import LoadingTable from '../../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../../components/UI/CardTables/ErrorTable'

type Props = {
  filtro: any[]
}

export function Table({ filtro }: Props) {

  const [paginacao, setPaginacao] = useState({ pagina: 0, quantidade: 10 })

  const { data, error, loading } = QueryGetExplorerLayer({
    variables: {
      pagination: {
        pagina: paginacao.pagina,
        quantidade: paginacao.quantidade
      },
      filter: {
        fields: filtro,
      },
    },
  })

  return (
    <>
      <CardTables
        headerTitle={'Explorer Layers'}
        headerSubtTitle={`Listando ${data ? data.GetExplorerLayers.pageInfo.totalItems : 0} layers registrados.`}
        headerToolbar={
          <Link
            to={'/backoffice/explorer-layers/create'}
            className='btn btn-light-primary btn-sm d-block'
            style={{ width: '100px !important' }}
          >
            <BiAddToQueue className='fs-2 me-1' />
            Adicionar Layer
          </Link>
        }
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetExplorerLayers.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({ pagina: pagina, quantidade: quantidade })
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-50px'># ID</th>
                <th className='min-w-150px'>Nome</th>
                <th className='min-w-120px'>Schema</th>
                <th className=''>Url</th>
                <th className=''>Type</th>
                <th className='min-w-100px text-end'>Ação</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetExplorerLayers.result.length > 0 ? (
                  data.GetExplorerLayers.result.map((response) => {
                    return (
                      <tr key={response.id}>
                        <td>{response.id}</td>
                        <td>{response.nome}</td>
                        <td>{response.schema_table}</td>
                        <td>{response.url}</td>
                        <td>{response.type}</td>
                        <td>
                          <Buttons id={response.id} schema={response.schema_table} source={response.source} />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>

    </>
  )
}
