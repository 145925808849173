import { Loader } from '../../../../components/UI/Loader/Loader'
import { MutationDeleteImovelCorretores, QueryGetImovelCorretores } from '../../../../graphql/services'
import { useState } from 'react'
import Swal from 'sweetalert2'
import { KTSVG } from '../../../../../_metronic/helpers'
import { CreateFormImovelCorretor } from './Corretores/Meus-Imoveis-Corretor'
import { EditFormCorretor } from './Corretores/EditForm'

type Props = {
  id: number
}
export const MeusImoveisCorretores: React.FC<Props> = ({ id }) => {
  const { data } = QueryGetImovelCorretores({ variables: { idTerreno: id } })
  const { HandleDeleteImovelCorretores, loading } = MutationDeleteImovelCorretores()


  const [idCorretor, setIdCorretor] = useState(null)
  const [showEdit, setShowEdit] = useState(false)

  function CloseModalEdit() {
    setShowEdit(false)
    setIdCorretor(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setIdCorretor(id)
  }

  if (!data) {
    return <Loader text={'Carregando'}></Loader>
  }

 if (loading) {
  Swal.fire(
    'Enviando Informações...','')
  Swal.showLoading()
 }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteImovelCorretores({ variables: { deleteImovelCorretorId: id } })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Corretor deletado com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <div className='card-body p-0 py-3'>

      <div className='table-responsive'>
        <div className='card-header'>
          <h3 className='card-title'>Corretores Associados</h3>
          <div className='card-toolbar'>
            <CreateFormImovelCorretor id_terreno={id} />
          </div>
        </div>
        <table className='table table-striped table-row-dashed  gs-6 gy-4 table-row-bordered  table-hover table-row-gray-300 align-middle'>
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='min-w-150px'>Nome</th>
              <th className='min-w-140px'>Email</th>
              <th className='min-w-120px'>Telefone</th>
              <th className='min-w-100px text-end'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data
              ? data.GetImovelCorretor.map((Response, item) => {
                return (
                  <tr key={Response.corretor.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {Response.corretor.nome}
                          </span>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {Response.corretor.empresa}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {Response.corretor.email}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {Response.corretor.telefone}
                      </span>
                    </td>
                    <td className='all-end'>
                      <button
                        onClick={(e) => {
                          OpenModalEdit(Response.corretor.id)
                        }}
                        className='btn btn-icon btn-light-success btn-sm me-2'>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </button>


                      <button
                        onClick={(e) => {
                          confirmDelete(Response.id)
                        }}
                        className='btn btn-icon btn-light-danger btn-sm me-2' >
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                      </button>
                    </td>
                  </tr>
                )
              })
              : ''}
          </tbody>
        </table>
        {showEdit ? <EditFormCorretor id={idCorretor}  closeModal={CloseModalEdit} show={showEdit} /> : <></>}

      </div>
    </div>
  )
}
