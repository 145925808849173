import { ReactNode } from "react";
import { Market, submarkets } from "../../data";

interface Props {
  dataMarketResponse?: Market;
  submarketsAll?: submarkets[];
  children?: ReactNode;
}

export function TabsNavigation({ dataMarketResponse, submarketsAll, children }: Props) {
  return (
    <ul className="nav nav-map-analise all-center-between" id="tab" role="tablist">
      <div>
        <li className="nav-item" role="presentation">
          <button className="nav-link fs-4 active text-active-primary ms-0 me-10 py-1" id="tab1-tab" data-bs-toggle="pill" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="false">Visão geral</button>
        </li>
        {dataMarketResponse ? dataMarketResponse.submarket && (
          <li className="nav-item" role="presentation">
            <button className="nav-link fs-4 text-active-primary ms-0 me-10 py-1" id="tab2-tab" data-bs-toggle="pill" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false">
              <span className="badge badge-secondary">{dataMarketResponse.submarket.length}</span> Submarkets
            </button>
          </li>
        ) : ('')}
        {submarketsAll ? (
          <li className="nav-item" role="presentation">
            <button className="nav-link fs-4 text-active-primary ms-0 me-10 py-1" id="tab2-tab" data-bs-toggle="pill" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false">
              <span className="badge badge-secondary">{submarketsAll.length}</span> Submarkets
            </button>
          </li>
        ) : ('')}
      </div>

      <div>
        {children}
      </div>
    </ul>
  )
}