import React, { useEffect, useState } from 'react';
import { AsideDefault } from '../../../../_metronic/layout/components/aside/AsideDefault';
import { HeaderWrapperFluid } from '../../../../_metronic/layout/components/header/HeaderWrapperFluid';
import { Footer } from '../../../../_metronic/layout/components/Footer';
import { MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowUp } from 'react-icons/md';
import { FaAngleDoubleDown, FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleDoubleUp } from 'react-icons/fa';
import DualListBox from 'react-dual-listbox';
import { Link, useParams } from 'react-router-dom';
import { ADMINMutationPutPacote, QueryGetPacoteById, QueryGetServicosCategoria } from '../../graphql';
import { Loader } from '../../../components/UI/Loader/Loader';
import { QueryGetProdutos } from '../../../graphql';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { putPacoteFormData } from '../../formValidations';


export function AdminEditPacotes() {
    const { id: idPacote } = useParams<{ id: string }>()
    const history = useHistory()

    const [servicos, setServicos] = useState([])
    const [selected, setSelected] = useState([])
    const [idProdutoSelecionado, setIdProdutoSelecionado] = useState('');

    const dataServcoCategoria = QueryGetServicosCategoria()
    const data = QueryGetPacoteById({ variables: { getPacoteId: parseInt(idPacote) } });
    const { data: dataProdutos } = QueryGetProdutos({
        variables: {
            pagina: 0,
            quantidade: 400,
        }
    })

    const { FormPacote, handleSubmit, register, setValue, loading } = ADMINMutationPutPacote()


    const handleIdProdutoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = e.target.value;
        setIdProdutoSelecionado(selectedId);
        setValue('id_produto', parseInt(selectedId))
    };

    const onChange = (value) => {
        setSelected(value);
        setValue('idServicos', value)
    };

    useEffect(() => {
        if (dataServcoCategoria) {
            let dataServices = [];
            // eslint-disable-next-line array-callback-return
            dataServcoCategoria.GetServicoCategoria.map((Options) => {
                const services = Options.servico.map((servico) => {
                    return { value: servico.id, label: servico.nome };
                });

                dataServices.push({
                    label: Options.nome,
                    options: services,
                });
            });

            // Limpe o estado servicos antes de definir os novos valores
            setServicos([]);
            setServicos(dataServices);
        }
    }, [dataServcoCategoria]);

    useEffect(() => {
        let dataServicesSelected = [];
        if (data && data.GetPacote && Array.isArray(data.GetPacote.service)) {
            data.GetPacote.service.map((item) => {
                dataServicesSelected.push(item.id);
            });
        }

        setSelected([]);
        if (data && data.GetPacote && data.GetPacote.pacote) {
            setIdProdutoSelecionado(`${data.GetPacote.pacote.id_produto}`);
        }
        setSelected(dataServicesSelected);
        setValue('idServicos', dataServicesSelected)
    }, [data]);

    const onSubmit = async (data: putPacoteFormData) => {
        await FormPacote(data)
            .then((result) => {
                console.log(result)
                if (!result.data) {
                    Swal.fire('Erro!', 'Erro ao alterar os dados', 'warning')
                } else {
                    Swal.fire('Sucesso!', 'Pacote Atualizado com sucesso.', 'success')
                    history.push('/backoffice/pacotes')
                }
            }).catch((err) => {
                console.log(err)
                Swal.fire('Erro!', 'Ocorreu um erro inesperado, tente novamente mais tarde.', 'warning')
            })
    }

    if (loading) {
        Swal.fire(
            'Enviando Informações...',
            '',
        )
        Swal.showLoading()
    }


    // Tratativa de loading
    if (!data || !dataServcoCategoria) {
        return (
            <>
                <AsideDefault />
                <HeaderWrapperFluid />
                <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                    <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                        <div className='post d-flex flex-column-fluid' id='kt_post'>
                            <div id='kt_content_container' className="container-fluid">
                                <div className="py-6  d-flex align-items-center justify-content-between" id="kt_header_container">
                                    <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                        <h1 className="text-dark fw-bold my-0 fs-2">
                                            Editar
                                        </h1>
                                        <ul className="breadcrumb fw-semibold fs-base my-1">
                                            <li className="breadcrumb-item text-muted">
                                                <a href="/metronic8/demo7/../demo7/index.html" className="text-muted">
                                                    Pacotes
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="content  d-flex flex-column flex-column-fluid" id="kt_content" >
                                    <div className='card p-6'>
                                        {/*begin::Card header*/}
                                        <div className='border-0'>
                                            {/* begin::Row */}
                                            <div className='row gy-5 gx-xl-8'>
                                                <div className='col-xl-12'>
                                                    <Loader text='Carregando...' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        )
    }


    return (
        <>
            <AsideDefault />
            <HeaderWrapperFluid />
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                    <div className='post d-flex flex-column-fluid' id='kt_post'>
                        <div id='kt_content_container' className="container-fluid">
                            <div className="py-6  d-flex align-items-center justify-content-between" id="kt_header_container">
                                <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                    <h1 className="text-dark fw-bold my-0 fs-2">
                                        Editar
                                    </h1>
                                    <ul className="breadcrumb fw-semibold fs-base my-1">
                                        <li className="breadcrumb-item text-muted">
                                            <a href="/metronic8/demo7/../demo7/index.html" className="text-muted">
                                                Pacotes
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="content  d-flex flex-column flex-column-fluid" id="kt_content" >
                                <div className='card p-6'>
                                    {/*begin::Card header*/}
                                    <div className='border-0'>
                                        {/* begin::Row */}
                                        <div className='row gy-5 gx-xl-8'>
                                            <div className='col-xl-12'>
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <input type="number" hidden {...register('id', { valueAsNumber: true, required: true })} defaultValue={data.GetPacote.pacote.id} />
                                                    <div className="row mt-4">
                                                        <div className="col-6">
                                                            <label htmlFor="" >Nome</label>
                                                            <input type="text" className="form-control" placeholder='Nome do Pacote'
                                                                {...register('nome')}
                                                                defaultValue={data.GetPacote.pacote.nome} />
                                                        </div>
                                                        <div className="col-6">
                                                            <label htmlFor="" >Produtos</label>
                                                            <select
                                                                value={parseInt(idProdutoSelecionado)}
                                                                onChange={handleIdProdutoChange}
                                                                className='form-control'>
                                                                <option value="">Selecione um Produto</option>
                                                                {dataProdutos !== null ? dataProdutos.GetProdutos.result.map((produto) => (
                                                                    <option key={produto.id} value={produto.id}>
                                                                        {produto.nome}
                                                                    </option>
                                                                )) : ''}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                            <label htmlFor="" >Descrição</label>
                                                            <textarea style={{ resize: 'none' }}
                                                                className='form-control' cols={30} rows={5}
                                                                {...register('descricao')}
                                                                defaultValue={data.GetPacote.pacote.descricao}></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                            <label htmlFor="" >Serviços incluidos no pacote</label>
                                                            <DualListBox
                                                                className='h-400px'
                                                                canFilter
                                                                filterPlaceholder={'Search From List 1...' || 'Search From List 2...'}
                                                                options={servicos}
                                                                selected={selected}
                                                                preserveSelectOrder
                                                                onChange={onChange}
                                                                showOrderButtons
                                                                icons={{
                                                                    moveLeft: <MdKeyboardArrowLeft size={20} color='#125f44' />,
                                                                    moveAllLeft: [
                                                                        <FaAngleDoubleLeft size={20} color='#125f44' />
                                                                    ],
                                                                    moveRight: <MdKeyboardArrowRight size={20} color='#125f44' />,
                                                                    moveAllRight: [
                                                                        <FaAngleDoubleRight size={20} color='#125f44' />

                                                                    ],
                                                                    moveDown: <MdKeyboardArrowDown size={20} color='#125f44' />,
                                                                    moveUp: <MdKeyboardArrowUp size={20} color='#125f44' />,
                                                                    moveTop: <FaAngleDoubleUp size={20} color='#125f44' />,
                                                                    moveBottom: <FaAngleDoubleDown size={20} color='#125f44' />,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-6">
                                                        <div className="col-12 all-end">
                                                            <Link to="/backoffice/pacotes" type='button' className='btn btnSecondary me-4'>Cancelar</Link>
                                                            <button className='btn btn-success' type='submit'>Salvar</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}