import { gql } from "@apollo/client";

export const GET_ADMIN_EMPRESA_USUARIOS = gql`
query GetEmpresasUsuarioAdmin($filtro: filter, $pagination: Pagination!) {
  GetEmpresasUsuarioAdmin(filtro: $filtro, pagination: $pagination) {
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
    result {
      id
      uuid
      id_user
      id_empresa
      empresa {
        nome
      }
      users {
        id
        name
        email
      }
      created_at
      updated_at
      deleted_at
      tipo_usuario
      visualizacao
      exclusao
      edicao
    }
  }
}
`

export const GET_ADMIN_EMPRESA_USUARIO_BY_ID = gql`
query GetEmpresaUsuarioByIdAdmin($getEmpresaUsuarioByIdAdminId: Float!) {
  GetEmpresaUsuarioByIdAdmin(id: $getEmpresaUsuarioByIdAdminId) {
      id
      uuid
      id_user
      id_empresa
      empresa {
        nome
      }
      users {
        id
        name
        email
      }
      created_at
      updated_at
      deleted_at
      tipo_usuario
      visualizacao
      exclusao
      edicao
  }
}
`