import ImageUploading, { ImageListType } from 'react-images-uploading'
import { useEffect, useState } from 'react'
import { BiPen, BiTrash } from 'react-icons/bi'
import { useFormContext } from 'react-hook-form';
import { RealEstateLaunchFormData } from '../../../partials/typologies/formValidations';
import { useEmpreendimentos } from '../../../context/useEmpreendimentos';

interface ImageType {
  dataURL: string;
}

export function MediaCard() {
  const { register, setValue, formState: { errors } } = useFormContext<RealEstateLaunchFormData>();
  const { launch } = useEmpreendimentos()
  const [images, setImages] = useState<ImageType[]>([]);
  console.log(images);
  const maxNumber = 69;

  useEffect(() => {
    if (launch && launch.images && typeof launch.images === 'object') {
      // Transforme o objeto em um array de ImageType
      const imageArray = Object.keys(launch.images).map(key => ({
        dataURL: launch.images[key].dataURL // ou como você precisa mapear os valores
      }));
      
      setImages(imageArray);
    } else {
      setImages([]);
    }
  }, [launch]);
  

  const onChange = (imageList: ImageType[], addUpdateIndex: number[]) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    setValue('files', imageList);
  }

  return (
    <div className='card flex-grow-1 mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <h2 className='fw-bolder pt-4 px-8 m-0'>Fotos e Videos</h2>
      </div>
      <div className='card-body py-3'>
        <div className='row px-0'>
          <div className='form-group col-md-12 form-line '>
            <div className='row'>
              <div className='col-md-12'>
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  acceptType={['jpg', 'png']}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <div className='w-100'>
                      <button
                        type='button'
                        className='btn btn-primary float-end'
                        style={isDragging ? { color: 'red' } : null}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Enviar fotos
                      </button>

                      <div className='upload__image-wrapper clearfix pt-15'>
                        {imageList.map((image, index) => (
                          <div className='col-12 col-sm-4 col-xl-4 image-item' key={index}>
                            <div className='card h-100'>
                              <div className='card-body d-flex justify-content-center text-center flex-column'>
                                <div className='symbol symbol-75px mb-6'>
                                  <img src={image.dataURL} alt='' width='100' />
                                </div>
                                <div className='fs-5 fw-bolder mb-2'>
                                  <input type="text" className="form-control" placeholder="Sem informação" />
                                </div>
                                <div className='fs-7 fw-bold text-gray-500 mt-auto'>
                                  <div className='image-item__btn-wrapper'>
                                    <button
                                      className='btn btn-icon btn-sm btn-light-primary me-3'
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <BiPen />
                                    </button>
                                    <button
                                      className='btn btn-icon btn-light-danger'
                                      onClick={() => onImageRemove(index)}
                                    >
                                      <BiTrash />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
