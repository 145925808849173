import { gql } from "@apollo/client";

// Busca de todos os contribuentespelo ID do terreno
export const GET_IMOVEL_CONTRIBUINTES = gql`
query GetImovelContribuintes($idImovel: Float!) {
  GetImovelContribuintes(id_imovel: $idImovel) {
    id
    situacao
    contribuintes {
      cpf
      id
      nome
      numero_contribuinte
      situacao
    }
  }
}
`

// Busca de todos os dados do contribuente pelo ID
export const GET_IMOVEL_CONTRIBUINTE_BY_ID = gql`
query GetImovelContribuinteById($getImovelContribuinteByIdId: Float!) {
  GetImovelContribuinteById(id: $getImovelContribuinteByIdId) {
    id
    situacao
    contribuinte {
      cpf
      id
      nome
      numero_contribuinte
      situacao
    }
  }
}
`
